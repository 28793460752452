import { Typography, Chip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Truncate from 'react-truncate'
import { TFunction } from 'i18next'
import {
  isRetailProduct,
  RetailListItemUI,
  isPricerProduct,
  ProductTags
} from '@seesignage/seesignage-utils'
import Media from '../../../../components/Media'
import { getPricerProductProperties } from '../../../../utils/products'
import ItemTitle from './ItemTitle'

const useStyles = makeStyles(() => ({
  item: {
    height: 113
  },
  itemTitleText: {
    padding: '4px 4px 2px 4px',
    overflow: 'hidden'
  },
  media: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  mediaBox: {
    backgroundColor: 'white',
    maxWidth: '100%',
    maxHeight: '100%'
  },
  itemDescriptionArea: {
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)'
  },
  scaleNumber: {
    borderRadius: '50%',
    width: 50,
    height: 50,
    background: 'white',
    lineHeight: '50px',
    border: '1px solid black',
    margin: 'auto'
  },
  productNotFound: {
    paddingTop: 10
  }
}))

interface GeneratePriceTextParams {
  price?: number | null
  unit?: string | null
  t: TFunction
}

const generatePriceText = ({ price, unit, t }: GeneratePriceTextParams) => {
  if (price) {
    const euros = price / 100
    const translatedUnit = unit ? t(`product.unit.${unit}`, { defaultValue: unit }) : undefined
    return translatedUnit ? `${euros} € / ${translatedUnit}` : `${euros} €`
  }
  return ''
}

const ProductTagsChip = ({ tags, t }: { t: TFunction; tags: ProductTags[] }) => (
  <div style={{ margin: '4px 0px 4px 0px' }}>
    {tags.map(tag => (
      <Chip color='primary' label={t(`product.tag.${tag}`)} key={tag} />
    ))}
  </div>
)

interface ListItemProps {
  item: RetailListItemUI
}

const ListItem = ({ item }: ListItemProps) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const { product, productId } = item
  if (isRetailProduct(product)) {
    const {
      name,
      country,
      qualityClass,
      unit,
      price,
      scaleNumber,
      description,
      imageUrl,
      tags
    } = product
    return (
      <Grid container className={classes.item}>
        <Grid item xs={3} sm={4} className={classes.mediaBox}>
          <Media
            imageClass={classes.media}
            videoClass={classes.media}
            type='image'
            url={imageUrl}
          />
        </Grid>
        <Grid item xs={6} sm={6} className={classes.itemDescriptionArea}>
          <ItemTitle containerClass={classes.itemTitleText} name={name} />
          <Typography display='block' variant='caption'>{`${country || ''}  ${qualityClass ||
            ''}`}</Typography>
          <Typography display='block' variant='caption'>
            <Truncate width={185} lines={1}>
              {description || ''}
            </Truncate>
          </Typography>
          <Typography variant='caption'>{generatePriceText({ price, unit, t })}</Typography>
        </Grid>
        <Grid item xs={3} sm={2}>
          {tags && <ProductTagsChip tags={tags} t={t} />}
          {scaleNumber && <div className={classes.scaleNumber}>{scaleNumber}</div>}
        </Grid>
      </Grid>
    )
  } else if (isPricerProduct(product)) {
    const { name, imageUrl, description, price } = getPricerProductProperties(product)
    return (
      <Grid container className={classes.item}>
        <Grid item xs={4} className={classes.mediaBox}>
          <Media
            imageClass={classes.media}
            videoClass={classes.media}
            type='image'
            url={imageUrl}
          />
        </Grid>
        <Grid item xs={8} className={classes.itemDescriptionArea}>
          <ItemTitle containerClass={classes.itemTitleText} name={name} />
          {description && (
            <Typography display='block' variant='caption'>
              <Truncate width={185} lines={1}>
                {description}
              </Truncate>
            </Typography>
          )}
          {price && <Typography variant='caption'>{price} €</Typography>}
        </Grid>
      </Grid>
    )
  }
  return (
    <Grid container className={classes.item}>
      <Grid item xs={12}>
        <Typography
          noWrap
          variant='subtitle1'
          color='red'
          gutterBottom
          className={classes.productNotFound}>
          {t('lists.productNotFound', { productId })}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default ListItem
