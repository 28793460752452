import React, { Fragment, useState, useEffect, useCallback } from 'react'
import { Environment, EnvironmentUI, SubEnvironments } from '@seesignage/seesignage-utils'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  IconButton,
  TextField,
  InputAdornment,
  Typography,
  Drawer,
  Avatar,
  Tooltip,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Badge,
  ListItemText
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import DomainIcon from 'mdi-react/DomainIcon'
import MagnifyIcon from 'mdi-react/MagnifyIcon'
import CloseIcon from 'mdi-react/CloseIcon'
import { navigateToEnvironment as navigateToEnvironmentAction } from '../../actions/routes'
import { compareStrings } from '../../utils/sorting'
import { selectEnvironmentsAsArray, selectSelectedEnvironment } from '../../selectors/environments'
import { listEnvironments as listEnvironmentsAction } from '../../actions/environments'
import colors from '../../styles/common/colors'
import { getEnvironmentDisplayName } from '../../utils/environments'
import { isUserSystemAdmin } from '../../selectors/users'

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: colors.seesignageColor,
    marginRight: 10
  },
  changeEnvironmentDrawer: {
    width: 400,
    top: 64,
    textAlign: 'center' as any,
    [theme.breakpoints.down('sm')]: {
      top: 56,
      width: '100%'
    }
  },
  closeDrawerButton: {
    position: 'absolute',
    left: 0,
    top: 0
  },
  drawerToolbar: {
    position: 'sticky',
    top: 0,
    zIndex: 999,
    backgroundColor: '#ffffff',
    padding: '0px 10px 0px 5px'
  },
  drawerTitle: {
    position: 'relative',
    width: '100%',
    textAlign: 'center',
    height: 50,
    paddingTop: 6
  }
}))

const getEnvironmentIconColor = ({
  parentEnvironment,
  subEnvironments
}: Environment | EnvironmentUI) => {
  if (subEnvironments || parentEnvironment) {
    return colors.seesignageColor
  }
}

const ChangeEnvironment: React.FC = () => {
  const classes = useStyles()
  const [t] = useTranslation()

  const dispatch = useDispatch()
  const navigateToEnvironment = ({ environmentId }) =>
    dispatch(navigateToEnvironmentAction({ environmentId }))
  const listEnvironments = useCallback(() => () => dispatch(listEnvironmentsAction()), [dispatch])

  const environments = useSelector(selectEnvironmentsAsArray).sort(compareStrings('name'))
  const currentEnvironment = useSelector(selectSelectedEnvironment)
  const isSystemAdmin = useSelector(isUserSystemAdmin)

  const [isDrawerOpen, setDrawerOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  useEffect(() => {
    listEnvironments()
  }, [listEnvironments])

  const visibleEnvironments =
    searchTerm.length > 0
      ? environments.filter(
          ({ name, displayName }) =>
            name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
            displayName?.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
        )
      : environments

  const handleSelect = (environmentId: string) => {
    setDrawerOpen(false) // close drawer
    setSearchTerm('') // clear search
    navigateToEnvironment({ environmentId })
  }
  return (
    <Fragment>
      <Tooltip
        disableInteractive
        title={t('environment.changeEnvironmentDrawer.changeEnvironment')}>
        <Avatar className={classes.avatar}>
          <IconButton
            disabled={environments.length === 1}
            onClick={() => setDrawerOpen(!isDrawerOpen)}
            size='large'>
            <DomainIcon color='#fafafa' />
          </IconButton>
        </Avatar>
      </Tooltip>
      <Drawer
        classes={{ paper: classes.changeEnvironmentDrawer }}
        anchor='right'
        open={isDrawerOpen}
        onClose={() => setDrawerOpen(false)}>
        <div className={classes.drawerToolbar}>
          <div className={classes.drawerTitle}>
            <Tooltip disableInteractive title={t('general.close')}>
              <IconButton
                onClick={() => setDrawerOpen(false)}
                className={classes.closeDrawerButton}
                size='large'>
                <CloseIcon />
              </IconButton>
            </Tooltip>
            <Typography variant='h6'>
              {t(`environment.changeEnvironmentDrawer.changeEnvironment`)}
            </Typography>
            <Typography color='primary' variant='caption'>
              ({currentEnvironment ? getEnvironmentDisplayName(currentEnvironment) : ''})
            </Typography>
          </div>
          <TextField
            variant='standard'
            fullWidth
            autoFocus
            style={{ margin: 8 }}
            label={t('general.search')}
            onChange={e => setSearchTerm(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <MagnifyIcon />
                </InputAdornment>
              )
            }}
          />
        </div>
        <List>
          {visibleEnvironments.map(environment => {
            const { environmentId, subEnvironments } = environment
            const environmentColor = getEnvironmentIconColor(environment)
            return (
              <ListItem disablePadding key={environmentId}>
                <ListItemButton key={environmentId} onClick={() => handleSelect(environmentId)}>
                  <ListItemIcon>
                    {subEnvironments ? (
                      <Badge
                        badgeContent={Object.keys(subEnvironments as SubEnvironments).length}
                        color='primary'>
                        <DomainIcon color={environmentColor} />
                      </Badge>
                    ) : (
                      <DomainIcon color={environmentColor} />
                    )}
                  </ListItemIcon>
                  {isSystemAdmin ? (
                    <ListItemText primary={environment.name} secondary={environment.displayName} />
                  ) : (
                    <ListItemText primary={getEnvironmentDisplayName(environment)} />
                  )}
                </ListItemButton>
              </ListItem>
            )
          })}
        </List>
      </Drawer>
    </Fragment>
  )
}

export default ChangeEnvironment
