import { Button, DialogActions, Grid, Typography } from '@mui/material'
import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Field, InjectedFormProps, reduxForm, formValueSelector } from 'redux-form'
import { PageType } from '../../../../../types/playlists'
import { StateInterface } from '../../../../../types/states'
import { required } from '../../../../../validation'
import SelectInfopage from '../../../../../components/FormInput/SelectInfopage'
import { selectIsUserDeviceMobile } from '../../../../../selectors/users'

interface OwnProps {
  submitAction: (formData: any) => void
  selectPage: (page: PageType) => void
}

interface StateProps {
  infopageSelected: boolean
  isMobile: boolean
}

type ComponentProps = OwnProps & StateProps

type AddExistingInfopageFormProps = ComponentProps & WithTranslation

const AddExistingInfopageForm: React.FC<AddExistingInfopageFormProps &
  InjectedFormProps<{}, ComponentProps>> = ({
  submitAction,
  selectPage,
  handleSubmit,
  error,
  t,
  infopageSelected,
  isMobile
}) => {
  return (
    <form onSubmit={handleSubmit(submitAction)}>
      <Grid container>
        <Grid item xs={12}>
          <Field
            name='infopage'
            placeholder={t('playlists.editPlaylist.selectExistingInfopage')}
            component={SelectInfopage}
            t={t}
            required
            validate={[required]}
            isMobile={isMobile}
            infopageSelected={infopageSelected}
            selectPage={selectPage}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body1' color='error'>
            {error || ''}
          </Typography>
        </Grid>
        <Typography variant='body1' color='error'>
          {error || ''}
        </Typography>
      </Grid>
      <DialogActions>
        <Button onClick={() => selectPage(PageType.selectType)} color='primary'>
          {t('general.back')}
        </Button>
        <Button color='primary' type='submit'>
          {t('general.add')}
        </Button>
      </DialogActions>
    </form>
  )
}

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    infopageSelected: formValueSelector('AddExistingInfopageForm')(state, 'infopage')
      ? true
      : false,
    isMobile: selectIsUserDeviceMobile(state)
  }
}

export default connect(mapStateToProps)(
  reduxForm<{}, ComponentProps>({
    form: 'AddExistingInfopageForm'
  })(withTranslation()(AddExistingInfopageForm))
)
