import React from 'react'
import { WrappedFieldInputProps } from 'redux-form'
import classNames from 'classnames'
import ImageTextIcon from 'mdi-react/ImageTextIcon'
import { Checkbox } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex'
  },
  checkedIcon: {
    borderRadius: '50%',
    border: '1px solid #1e88e5'
  },
  horizontalFlip: {
    transform: 'scaleX(-1)'
  }
}))

const toolbarIconSize = 25

interface TextAlignCheckBoxProps {
  input: WrappedFieldInputProps
  disabled?: boolean
}

const TextAlignCheckBox = ({ input: { onChange, value }, disabled }: TextAlignCheckBoxProps) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Checkbox
        disabled={disabled}
        checked={value === 0 ? true : false}
        icon={<ImageTextIcon className={classes.horizontalFlip} size={toolbarIconSize} />}
        checkedIcon={
          <ImageTextIcon
            size={toolbarIconSize}
            color='#1e88e5'
            className={classNames(classes.checkedIcon, classes.horizontalFlip)}
          />
        }
        onChange={() => onChange(0)}
      />
      <Checkbox
        disabled={disabled}
        checked={value === 1 ? true : false}
        icon={<ImageTextIcon size={toolbarIconSize} />}
        checkedIcon={
          <ImageTextIcon size={toolbarIconSize} color='#1e88e5' className={classes.checkedIcon} />
        }
        onChange={() => onChange(1)}
      />
    </div>
  )
}

export default TextAlignCheckBox
