import { createSelector } from 'reselect'
import { path } from 'ramda'
import { ListType, CustomerTableType, ProductForm } from '@seesignage/seesignage-utils'
import { StateInterface } from '../types/states'

const selectCustomersDomain = (state: StateInterface) => state.customers

const selectCustomers = createSelector(selectCustomersDomain, domain => domain.customers)

const selectAllCustomers = createSelector(selectCustomersDomain, domain => domain.allCustomers)

const selectIsListAllLoading = createSelector(
  selectCustomersDomain,
  customers => customers.isListAllLoading
)

const selectSelectedCustomerId = createSelector(
  selectCustomersDomain,
  customers => customers.selectedCustomerId
)

const selectCustomerById = (customerId?: string) =>
  createSelector(selectCustomers, customers => (customerId ? customers[customerId] : undefined))

const selectCustomerByIdFromAllCustomers = (customerId?: string) =>
  createSelector(selectAllCustomers, customers => (customerId ? customers[customerId] : undefined))

const selectSelectedCustomer = createSelector(
  selectSelectedCustomerId,
  selectCustomers,
  (customerId, customers) => (customerId ? customers[customerId] : undefined)
)

const selectSelectedCustomerProductForm = createSelector(
  selectSelectedCustomer,
  customer => customer?.productForm
)

const selectCustomersAsArray = createSelector(selectCustomers, customersById =>
  Object.values(customersById)
)

const selectAllCustomersAsArray = createSelector(selectAllCustomers, customersById =>
  Object.values(customersById)
)

const selectCustomersAsOptions = createSelector(selectCustomersAsArray, customers =>
  customers.map(({ name, customerId }) => ({
    value: customerId,
    label: name
  }))
)

const selectCustomerTables = (customerId: string) =>
  createSelector(selectCustomers, customers => {
    const customer = customers[path(['value'], customerId) as string]
    if (customer && customer.tables) {
      return Object.keys(customer.tables)
    }
  })

const selectMenuOrRetailTables = (customerId: string) =>
  createSelector(selectCustomerTables(customerId), tables => {
    if (tables) {
      return tables.filter(table => table === 'menu' || table === 'retail')
    }
  })

const selectCustomerProductType = (customerId: string) =>
  createSelector(selectCustomerById(customerId), customer => customer && customer.productForm)

const selectCustomersWithRetailTables = createSelector(selectCustomersAsArray, customers =>
  customers.filter(customer =>
    customer.tables ? Object.keys(customer.tables).includes('retail') : false
  )
)

const selectCustomersWithEditableRetailProducts = createSelector(
  selectCustomersAsArray,
  customers =>
    customers.filter(
      ({ productForm }) => productForm === ProductForm.general || productForm === ProductForm.retail
    )
)

const selectEditableProductCustomersAsOptions = createSelector(
  selectCustomersWithEditableRetailProducts,
  customers =>
    customers.map(({ name, customerId }) => ({
      value: customerId,
      label: name
    }))
)

const selectRetailCustomersAsOptions = createSelector(selectCustomersWithRetailTables, customers =>
  customers.map(({ name, customerId }) => ({
    value: customerId,
    label: name
  }))
)

const selectCustomersWithGeneralProductForm = createSelector(
  selectCustomersWithRetailTables,
  customers => customers.filter(({ productForm }) => productForm === ProductForm.general)
)

/**
 * Select customers with productForm general or pharmacy (products added to playlist)
 */
const selectCustomersWithProductForm = createSelector(selectCustomersAsArray, customers =>
  customers.filter(
    ({ productForm }) => productForm === ProductForm.general || productForm === ProductForm.pharmacy
  )
)

const selectPharmacyCustomers = createSelector(selectCustomersAsArray, customers =>
  customers.filter(({ productForm }) => productForm === ProductForm.pharmacy)
)

/**
 * Environment has customers with productForm value as 'retail' or 'general'
 */
const selectAreProductsEditable = createSelector(
  selectCustomersWithEditableRetailProducts,
  customers => customers && customers.length > 0
)

const selectCustomerProductForm = (customerId?: string) =>
  createSelector(selectCustomerById(customerId), customer => customer && customer.productForm)

const selectCustomerEMMiUrl = createSelector(selectCustomersAsArray, customers => {
  const customerWithEMMi = customers.find(({ integration }) => integration?.emmi)
  return customerWithEMMi ? customerWithEMMi.integration?.emmi?.url : undefined
})

const selectAvailableCustomersAsOptions = (listType?: ListType) =>
  createSelector(selectCustomersAsArray, customers => {
    if (listType && customers.length > 0) {
      return customers // note: lunch does not exist in CustomerTableType
        .filter(
          ({ tables, productForm }) =>
            tables &&
            tables[`${listType}` as CustomerTableType] &&
            productForm !== ProductForm.general
        )
        .map(({ customerId, name }) => ({ value: customerId, label: name }))
    }
    return []
  })

const selectSelectedCustomerRow = createSelector(
  selectCustomersDomain,
  domain => domain.selectedCustomerRow
)

/**
 * Select first matching customer of environment with `ProductForm.pharmacy` or `ProductForm.retail`.
 * Used e.g in prefilling `CreateCampaignForm` with customer id
 */
const selectRetailOrPharmacyCustomer = createSelector(selectCustomersAsArray, customers =>
  customers.find(
    ({ productForm }) => productForm === ProductForm.pharmacy || productForm === ProductForm.retail
  )
)

const selectCustomerWithApiIntegration = createSelector(selectCustomersAsArray, customers =>
  customers.find(customer => customer.integration?.api)
)

/**
 * Select EMMi integration supporting customer from customers array
 */
const selectEmmiCustomer = createSelector(selectCustomersAsArray, customers =>
  customers.find(({ integration }) => integration?.emmi?.username)
)

export {
  selectCustomersAsArray,
  selectAllCustomersAsArray,
  selectCustomerById,
  selectCustomerByIdFromAllCustomers,
  selectIsListAllLoading,
  selectSelectedCustomerId,
  selectSelectedCustomer,
  selectSelectedCustomerProductForm,
  selectCustomersAsOptions,
  selectCustomersWithRetailTables,
  selectCustomerTables,
  selectCustomerProductType,
  selectEditableProductCustomersAsOptions,
  selectRetailCustomersAsOptions,
  selectCustomersWithGeneralProductForm,
  selectCustomersWithProductForm,
  selectAreProductsEditable,
  selectCustomerProductForm,
  selectMenuOrRetailTables,
  selectCustomersWithEditableRetailProducts,
  selectCustomerEMMiUrl,
  selectAvailableCustomersAsOptions,
  selectPharmacyCustomers,
  selectSelectedCustomerRow,
  selectRetailOrPharmacyCustomer,
  selectCustomerWithApiIntegration,
  selectEmmiCustomer
}
