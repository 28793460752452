import React, { Dispatch, Fragment } from 'react'
import {
  reduxForm,
  InjectedFormProps,
  formValueSelector,
  change as changeFormAction,
  Field
} from 'redux-form'
import { useTranslation } from 'react-i18next'
import { Button, MenuItem } from '@mui/material'
import ViewCarouselOutlineIcon from 'mdi-react/ViewCarouselOutlineIcon'
import { useDispatch, useSelector } from 'react-redux'
import { Resolutions } from '@seesignage/seesignage-utils'
import { ContentRssFeedFormData, ContentEditorDialog } from '../../../../../types/contents'
import { OpenContentEditorDialog } from '../../../../../types/dialogs'
import ContentToolbarItem from '../ContentToolbarItem/ContentToolbarItem'
import { required } from '../../../../../validation'
import { ReduxTextField } from '../../../../../components/FormInput/ReduxWrappers'
import { getContentResolutionValue } from '../../../../../utils/fabric/resolution'
import CommonContentFields from './CommonContentFields'

const FORM_NAME = 'ContentMediaCarouselToolbarForm'

const updateDimension = (dispatch: Dispatch<any>, value: Resolutions) => {
  if (value && value !== Resolutions.custom) {
    const contentResolution = getContentResolutionValue(value)
    if (contentResolution) {
      const { width, height } = contentResolution
      dispatch(changeFormAction(FORM_NAME, 'width', width))
      dispatch(changeFormAction(FORM_NAME, 'height', height))
    }
  }
}
interface ContentMediaCarouselProps {
  openDialog: OpenContentEditorDialog
}
type FormProps = ContentRssFeedFormData

const ContentMediaCarouselToolbarForm: React.FC<ContentMediaCarouselProps &
  InjectedFormProps<FormProps, ContentMediaCarouselProps>> = ({ openDialog }) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const contentResolution = useSelector(state =>
    formValueSelector(FORM_NAME)(state, 'contentResolution')
  ) as Resolutions

  return (
    <Fragment>
      <CommonContentFields
        formName={FORM_NAME}
        t={t}
        enableSize={contentResolution === Resolutions.custom}
      />
      <ContentToolbarItem tooltip={t('contents.resolution.resolution')}>
        <Field
          required
          validate={[required]}
          name='contentResolution'
          onChange={(_, value) => updateDimension(dispatch, value)}
          variant='outlined'
          // @ts-ignore not an error
          size='small'
          component={ReduxTextField}
          select>
          <MenuItem value={Resolutions.landscape}>{t('contents.resolution.landscape')}</MenuItem>
          <MenuItem value={Resolutions.portrait}>{t('contents.resolution.portrait')}</MenuItem>
          <MenuItem value={Resolutions.landscapeHalf}>
            {t('contents.resolution.landscapeHalf')}
          </MenuItem>
          <MenuItem value={Resolutions.portraitHalf}>
            {t('contents.resolution.portraitHalf')}
          </MenuItem>
          <MenuItem value={Resolutions.a4landscape}>
            {t('contents.resolution.a4landscape')}
          </MenuItem>
          <MenuItem value={Resolutions.a4portrait}>{t('contents.resolution.a4portrait')}</MenuItem>
          <MenuItem value={Resolutions.square}>{t('contents.resolution.square')}</MenuItem>
          <MenuItem value={Resolutions.custom}>{t('contents.resolution.custom')}</MenuItem>
        </Field>
      </ContentToolbarItem>
      <ContentToolbarItem>
        <Button
          variant='contained'
          color='primary'
          startIcon={<ViewCarouselOutlineIcon />}
          onClick={() =>
            openDialog({
              dialogId: ContentEditorDialog.MediaCarouselDialog,
              isUpdateMode: true
            })
          }>
          {t('contents.forms.updateMediaCarousel')}
        </Button>
      </ContentToolbarItem>
    </Fragment>
  )
}

export default reduxForm<FormProps, ContentMediaCarouselProps>({
  form: FORM_NAME,
  enableReinitialize: true
})(ContentMediaCarouselToolbarForm)
