import React from 'react'
import { Tooltip, Fab } from '@mui/material'
import PlusIcon from 'mdi-react/PlusIcon'

interface AddButtonProps {
  onClick: () => void
  className?: string
  tooltipTitle: string
  id?: string
  size?: 'small' | 'medium' | 'large'
  disabled?: boolean
}

/**
 * Common Add Button to be used in App
 */
const AddButton: React.FC<AddButtonProps> = ({ tooltipTitle, ...props }) => (
  <Tooltip disableInteractive title={tooltipTitle}>
    <span>
      <Fab size='small' color='primary' {...props}>
        <PlusIcon />
      </Fab>
    </span>
  </Tooltip>
)

export default AddButton
