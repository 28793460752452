import { isEmpty, isNil, path } from 'ramda'
import {
  Range,
  Schedule,
  ScheduleStageUI,
  isLegacySchedule,
  ScheduleLegacy,
  convertLegacySchedule,
  getDateFromScheduleDateTime,
  getDateFromScheduleTime
} from '@seesignage/seesignage-utils'
import { getCurrentDay, getCurrentMsByTime } from './time'

/**
 * Check if current time matches to playlist item schedule
 */
const visibleSchedule = (currentTimeMs: number, currentDay: string, ranges: Range[]) => {
  const match = ranges.find(({ start, end, conditions }) => {
    const startD = getDateFromScheduleDateTime(start).getTime()
    const endD = getDateFromScheduleDateTime(end).getTime()
    if (currentTimeMs <= endD && currentTimeMs >= startD) {
      if (isNil(conditions) || isEmpty(conditions)) {
        return true
      }
      const matchConditions = conditions.find(({ startTime, endTime, days }) => {
        const isDayVisible = path([currentDay], days)
        if (isDayVisible) {
          const startTimeMs = getCurrentMsByTime(
            currentTimeMs,
            getDateFromScheduleTime(startTime).toISOString()
          )
          const endTimeMs = getCurrentMsByTime(
            currentTimeMs,
            getDateFromScheduleTime(endTime).toISOString()
          )
          if (currentTimeMs <= endTimeMs && currentTimeMs >= startTimeMs) {
            return true
          }
        }
        return false
      })
      if (matchConditions) {
        return true
      }
    }
    return false
  })
  return match
}

const endedSchedule = (currentTimeMs: number, ranges: Range[]) => {
  const validSchedule = ranges.find(({ end }) => {
    const endD = getDateFromScheduleDateTime(end).getTime()
    return currentTimeMs <= endD
  })
  return validSchedule ? undefined : ranges[ranges.length - 1]
}

const nextFutureSchedule = (currentTimeMs: number, ranges: Range[]) => {
  const future = ranges.find(({ start, end }) => {
    const startD = getDateFromScheduleDateTime(start).getTime()
    const endD = getDateFromScheduleDateTime(end).getTime()
    return currentTimeMs <= startD || currentTimeMs <= endD
  })
  return future
}

const getPlaylistItemScheduleStage = (itemSchedule: Schedule | ScheduleLegacy): ScheduleStageUI => {
  const { ranges } = isLegacySchedule(itemSchedule)
    ? convertLegacySchedule(itemSchedule)
    : itemSchedule
  const currentTimeMs = new Date().getTime()
  const currentDay = getCurrentDay(currentTimeMs)
  const visible = visibleSchedule(currentTimeMs, currentDay, ranges)
  if (visible) {
    return { visibility: 'visible', range: visible }
  } else {
    const ended = endedSchedule(currentTimeMs, ranges)
    return ended
      ? { visibility: 'ended', range: ended }
      : { visibility: 'future', range: nextFutureSchedule(currentTimeMs, ranges) }
  }
}

const getVisibleDaysFromScheduleStage = (scheduleStage: ScheduleStageUI) => {
  if (scheduleStage.range?.conditions) {
    const visibleDays: any = {
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
      Sunday: false
    }
    for (const condition of scheduleStage.range.conditions) {
      if (condition.days) {
        for (const [day, isVisible] of Object.entries(condition.days)) {
          if (isVisible) {
            visibleDays[day] = true
          }
        }
      }
    }
    // visible days are true
    return Object.keys(visibleDays).filter(k => visibleDays[k])
  }
  return undefined
}

export { getPlaylistItemScheduleStage, getVisibleDaysFromScheduleStage }
