import React, { useEffect, useState } from 'react'
import { reduxForm, InjectedFormProps, Field, change, formValueSelector } from 'redux-form'
import {
  Grid,
  InputAdornment,
  TextField,
  CircularProgress,
  Typography,
  MenuItem
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { TFunction } from 'i18next'
import { connect } from 'react-redux'
import { Stop, VehicleMode } from '@seesignage/seesignage-utils'
import { Dispatch } from 'redux'
import FormatHeader1Icon from 'mdi-react/FormatHeader1Icon'
import Autocomplete from '@mui/material/Autocomplete'
import TramIcon from 'mdi-react/TramIcon'
import TrainIcon from 'mdi-react/TrainIcon'
import FerryIcon from 'mdi-react/FerryIcon'
import SubwayIcon from 'mdi-react/SubwayIcon'
import BusStopIcon from 'mdi-react/BusStopIcon'
import FormatFontIcon from 'mdi-react/FormatFontIcon'
import MapMarkerPathIcon from 'mdi-react/MapMarkerPathIcon'
import BusIcon from 'mdi-react/BusIcon'
import ClockDigitalIcon from 'mdi-react/ClockDigitalIcon'
import MapMarkerMultipleIcon from 'mdi-react/MapMarkerMultipleIcon'
import { getSupportedFontFamilies } from '@seesignage/seesignage-player-utils'
import { ContentStopScheduleFormData } from '../../../../../../types/contents'
import {
  ReduxTextField,
  ReduxSelectWithAutocomplete
} from '../../../../../../components/FormInput/ReduxWrappers'
import ContentColorPicker from '../../../../../../components/FormInput/ColorPicker/ContentColorPicker'
import SimpleExtensionPanel from '../../../../../../components/ExtensionPanels/SimpleExtensionPanel'
import { StateInterface } from '../../../../../../types/states'
import ContentsApi from '../../../../../../services/api/contents'
import TooltipSpan from '../../../../../../components/Tooltip/TooltipSpan'
import useDebounce from '../../../../../../hooks/debounce'
import ReduxIconCheckbox from '../../../../../../components/FormInput/ReduxWrappers/ReduxIconCheckbox'
import { DEBOUNCE_DELAY } from '../../../../../../config/constants'
import { parseStringToIntNumber } from '../../../../../../utils/forms'

const supportedFonts = getSupportedFontFamilies()
const fontSizes = [...Array(151).keys()].splice(9)

const useStyles = makeStyles(() => ({
  accordionSummaryRootClass: {
    backgroundColor: '#F5F5F5',
    borderBottom: '1px solid rgba(0, 0, 0, 0.09)'
  },
  stopsField: {
    margin: '0px 4px 0px 4px',
    padding: '3px 0px 2px 0px'
  },
  moreOptionsContainer: {
    padding: 8,
    width: '500px'
  }
}))

const ColorFields = ({ t }: { t: TFunction }) => (
  <Grid container spacing={2}>
    <Grid item xs={6}>
      <Field
        name='cStopProps.backgroundColor'
        label={t('contents.widgets.stopSchedule.backgroundColor')}
        component={ContentColorPicker}
        tooltip={t('contents.actions.selectColorTooltip')}
      />
    </Grid>
    <Grid item xs={6}>
      <Field
        name='cStopProps.titleColor'
        label={t('contents.widgets.stopSchedule.titleText')}
        component={ContentColorPicker}
        tooltip={t('contents.actions.selectColorTooltip')}
      />
    </Grid>
    <Grid item xs={6}>
      <Field
        name='cStopProps.tableHeaderTextColor'
        label={t('contents.widgets.stopSchedule.headerText')}
        component={ContentColorPicker}
        tooltip={t('contents.actions.selectColorTooltip')}
      />
    </Grid>
    <Grid item xs={6}>
      <Field
        name='cStopProps.tableHeaderBackgroundColor'
        label={t('contents.widgets.stopSchedule.headerBackgroundColor')}
        component={ContentColorPicker}
        tooltip={t('contents.actions.selectColorTooltip')}
      />
    </Grid>
    <Grid item xs={6}>
      <Field
        name='cStopProps.bodyTextColor'
        label={t('contents.widgets.stopSchedule.bodyText')}
        component={ContentColorPicker}
        tooltip={t('contents.actions.selectColorTooltip')}
      />
    </Grid>
  </Grid>
)

const FontFields = ({ t }: { t: TFunction }) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Field
        name='cStopProps.fontFamily'
        label={t('contents.properties.fontFamily')}
        component={ReduxTextField}
        select
        fullWidth
        variant='outlined'
        size='small'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <FormatFontIcon color='rgba(0, 0, 0, 0.54)' />
            </InputAdornment>
          )
        }}>
        {supportedFonts.map(val => (
          <MenuItem value={val} key={`fontFamily-${val}`} style={{ fontFamily: val }}>
            {val}
          </MenuItem>
        ))}
      </Field>
    </Grid>
    <Grid item xs={12}>
      <Typography>{t('contents.widgets.stopSchedule.fontSize')}</Typography>
    </Grid>
    <Grid item xs={6}>
      <Field
        fullWidth
        name='cStopProps.titleFontSize'
        label={t('contents.widgets.stopSchedule.titleText')}
        component={ReduxTextField}
        parse={parseStringToIntNumber}
        variant='outlined'
        size='small'
        select>
        {fontSizes.map(val => (
          <MenuItem value={val} key={`fontSize-${val}`}>
            {val}
          </MenuItem>
        ))}
      </Field>
    </Grid>
    <Grid item xs={6}>
      <Field
        fullWidth
        name='cStopProps.tableHeaderFontSize'
        label={t('contents.widgets.stopSchedule.headerText')}
        component={ReduxTextField}
        parse={parseStringToIntNumber}
        variant='outlined'
        size='small'
        select>
        {fontSizes.map(val => (
          <MenuItem value={val} key={`fontSize-${val}`}>
            {val}
          </MenuItem>
        ))}
      </Field>
    </Grid>
    <Grid item xs={6}>
      <Field
        fullWidth
        name='cStopProps.bodyFontSize'
        label={t('contents.widgets.stopSchedule.bodyText')}
        component={ReduxTextField}
        parse={parseStringToIntNumber}
        variant='outlined'
        size='small'
        select>
        {fontSizes.map(val => (
          <MenuItem value={val} key={`fontSize-${val}`}>
            {val}
          </MenuItem>
        ))}
      </Field>
    </Grid>
  </Grid>
)

const stopAmounts = [...Array(30).keys()].splice(5)

interface DispatchProps {
  changeValue: (field: string, value: any) => void
}
interface StateProps {
  stopsValue?: Stop[]
}

interface OwnProps {
  t: TFunction
}

type FormProps = ContentStopScheduleFormData

type ComponentProps = StateProps & DispatchProps & OwnProps

const StopScheduleForm: React.FC<ComponentProps & InjectedFormProps<FormProps, ComponentProps>> = ({
  t,
  changeValue,
  stopsValue
}) => {
  const [loading, setLoading] = useState(false)
  const [searchResults, setSearchResults] = useState<Stop[]>([])
  const [searchFieldValue, setSearchFieldValue] = useState('')
  const searchTerm = useDebounce(searchFieldValue, DEBOUNCE_DELAY)
  useEffect(() => {
    const getStops = async () => {
      setLoading(true)
      const stops = await ContentsApi.getDigitransitStops(searchTerm)
      setSearchResults(stops)
      setLoading(false)
    }
    if (searchTerm.length >= 3) {
      getStops()
    }
  }, [searchTerm])
  const classes = useStyles()
  const stopsLabel =
    searchFieldValue === '' && stopsValue && stopsValue.length === 0
      ? t('contents.widgets.stopSchedule.searchStops')
      : ''
  return (
    <Grid container>
      <Grid item xs={12}>
        <SimpleExtensionPanel
          title={t('contents.widgets.stopSchedule.title')}
          isOpen={true}
          accordionSummaryRootClass={classes.accordionSummaryRootClass}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                value={stopsValue}
                size='small'
                id='stops'
                className={classes.stopsField}
                multiple
                fullWidth
                noOptionsText={t('general.noOptions')}
                loadingText={`${t('general.loading')}...`}
                onChange={(e, val) => changeValue('cStopProps.stops', val)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={option =>
                  `${option.name.substring(0, 6)}.  ${option.code ? `(${option.code})` : ''}`
                }
                filterOptions={x => x}
                options={searchResults}
                loading={loading}
                renderOption={(props, { name, vehicleMode, code }) => (
                  <li {...props}>
                    <Grid container>
                      <Grid item xs={2}>
                        {vehicleMode === VehicleMode.bus && <BusIcon />}
                        {vehicleMode === VehicleMode.tram && <TramIcon />}
                        {vehicleMode === VehicleMode.subway && <SubwayIcon />}
                        {vehicleMode === VehicleMode.rail && <TrainIcon />}
                        {vehicleMode === VehicleMode.ferry && <FerryIcon />}
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant='body2'>{name}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant='body2'>{code}</Typography>
                      </Grid>
                    </Grid>
                  </li>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    value={searchFieldValue}
                    onChange={e => setSearchFieldValue(e.target.value)}
                    label={stopsLabel}
                    variant='outlined'
                    InputLabelProps={{ shrink: false }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? <CircularProgress color='inherit' size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <TooltipSpan title={t('contents.properties.showTime')}>
                <Field
                  name='cStopProps.showTime'
                  component={ReduxIconCheckbox}
                  Icon={ClockDigitalIcon}
                  iconSize={25}
                />
              </TooltipSpan>
            </Grid>
            <Grid item xs={3}>
              <TooltipSpan title={t('contents.properties.showVia')}>
                <Field
                  name='cStopProps.showVia'
                  component={ReduxIconCheckbox}
                  Icon={MapMarkerPathIcon}
                  iconSize={25}
                />
              </TooltipSpan>
            </Grid>
            <Grid item xs={3}>
              <TooltipSpan title={t('contents.properties.useStopNameAsTitle')}>
                <Field
                  name='cStopProps.useStopNameAsTitle'
                  component={ReduxIconCheckbox}
                  Icon={BusStopIcon}
                  iconSize={25}
                />
              </TooltipSpan>
            </Grid>
            <Grid item xs={3}>
              <TooltipSpan title={t('contents.properties.showStopNameColumn')}>
                <Field
                  name='cStopProps.showStopNameColumn'
                  component={ReduxIconCheckbox}
                  Icon={MapMarkerMultipleIcon}
                  iconSize={25}
                />
              </TooltipSpan>
            </Grid>
            <Grid item xs={12}>
              <Field
                name='cStopProps.title'
                component={ReduxTextField}
                type='text'
                variant='outlined'
                size='small'
                fullWidth
                placeholder={t('contents.properties.tableTitle')}
                InputProps={{
                  inputProps: { step: 1 },
                  startAdornment: (
                    <InputAdornment position='start'>
                      <FormatHeader1Icon />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name='cStopProps.numberOfDepartures'
                label={t('contents.properties.numberOfDepartures')}
                component={ReduxSelectWithAutocomplete}
                options={stopAmounts.map(value => ({ value, label: `${value}` }))}
                disableClearable
                variant='outlined'
                size='small'
              />
            </Grid>
          </Grid>
        </SimpleExtensionPanel>
      </Grid>
      <Grid item xs={12}>
        <SimpleExtensionPanel
          title={t('contents.widgets.stopSchedule.fonts')}
          isOpen={false}
          accordionSummaryRootClass={classes.accordionSummaryRootClass}>
          <FontFields t={t} />
        </SimpleExtensionPanel>
      </Grid>
      <Grid item xs={12}>
        <SimpleExtensionPanel
          title={t('contents.widgets.stopSchedule.colors')}
          isOpen={false}
          accordionSummaryRootClass={classes.accordionSummaryRootClass}>
          <ColorFields t={t} />
        </SimpleExtensionPanel>
      </Grid>
    </Grid>
  )
}

const selector = formValueSelector('StopScheduleForm')

const mapStateToProps = (state: StateInterface): StateProps => ({
  stopsValue: selector(state, 'cStopProps.stops') || []
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  changeValue: (field: string, value: any) => dispatch(change('StopScheduleForm', field, value))
})

export default connect<StateProps, DispatchProps, {}, StateInterface>(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<{}, ComponentProps>({
    form: 'StopScheduleForm',
    enableReinitialize: true
  })(StopScheduleForm)
)
