/* eslint-disable @typescript-eslint/camelcase */
/**
 * Google analytics integration
 * https://developers.google.com/analytics/devguides/collection/analyticsjs
 * https://developers.google.com/analytics/devguides/collection/gtagjs
 */
const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID

declare let gtag: any

const initializeGoogleAnalytics = () => {
  const elem = document.createElement('script')
  elem.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`
  elem.async = true

  const elem2 = document.createElement('script')
  elem2.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', "${GA_TRACKING_ID}");
  `

  document.getElementsByTagName('head')[0].appendChild(elem)
  document.getElementsByTagName('head')[0].appendChild(elem2)
}

interface EventDataProps {
  /** string that will appear as the event category */
  category: string
  /** string that will appear as the event label */
  label?: string
  /** a non-negative integer that will appear as the event value */
  value?: number
}

/**
 * Send Google Analytics events.
 */
const setGaPageView = (path: string) => {
  gtag('config', GA_TRACKING_ID, {
    page_path: path
  })
}
/**
 * Send Google Analytics events.
 */
const sendGaEvent = (action: string, { category, label, value }: EventDataProps) => {
  gtag('event', action, {
    event_category: category,
    event_label: label,
    value
  })
}

/**
 * Send default Google Analytics event.
 * see: https://developers.google.com/analytics/devguides/collection/gtagjs/events
 */
const sendDefaultGaEvent = (action: string, data: any) => {
  gtag('event', action, data)
}

export { initializeGoogleAnalytics, setGaPageView, sendGaEvent, sendDefaultGaEvent }
