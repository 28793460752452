import React from 'react'
import { StepIconProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import CheckIcon from 'mdi-react/CheckIcon'
import classnames from 'classnames'

const useStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 35,
    height: 35,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  active: {
    backgroundImage: 'linear-gradient(135deg, #00D0EF, #00AEEF 30%, #0067F4)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.5)'
  },
  completed: {
    backgroundImage: 'linear-gradient(135deg, #00D0EF, #00AEEF 30%, #0067F4)'
  }
})

const StepIcon: React.FC<StepIconProps> = ({ active, completed, icon }) => {
  const classes = useStepIconStyles()
  return (
    <div
      className={classnames(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}>
      {completed ? <CheckIcon /> : icon}
    </div>
  )
}

export default StepIcon
