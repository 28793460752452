import { AppBar, Toolbar } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import React from 'react'
import { TFunction } from 'i18next'
import { toolbarStyles } from '../../../styles/common'
import CreatePlaylistForm from '../Forms/CreatePlaylistForm'
import Dialog from '../../Dialog'
import { CloseDialog } from '../../../types/actions'
import { UserInterface } from '../../../types/users'
import { PlaylistFilter, PlaylistFilterTypes } from '../../../types/playlists'
import SearchField from '../../../components/SearchField'
import { OpenDialogButtonType } from '../../../types/dialogs'
import PlaylistFilterMenu from './PlaylistFilterMenu'

interface PlaylistsToolbarProps extends WithStyles<typeof toolbarStyles> {
  t: TFunction
  search: React.Dispatch<React.SetStateAction<string>>
  setFilterType: React.Dispatch<React.SetStateAction<PlaylistFilterTypes>>
  filterType: PlaylistFilter
  closeDialog: CloseDialog
  createPlaylist: (formData: any) => void
  user: UserInterface
}

const PlaylistsToolbar = ({
  classes,
  t,
  search,
  setFilterType,
  filterType,
  createPlaylist,
  closeDialog,
  user
}: PlaylistsToolbarProps) => {
  const filterMenuProps = {
    setFilterType,
    filterType
  }
  return (
    <AppBar className={classes.appBar} position='sticky' color='default'>
      <Toolbar>
        <SearchField placeholder={t('general.search')} onChange={e => search(e.target.value)} />
        <PlaylistFilterMenu {...filterMenuProps} />
        <div className={classes.spacer} />
        <div className={classes.actions}>
          <Dialog
            buttonId='create-playlist-button'
            dialogId='CreatePlaylistDialog'
            title={t('playlists.actions.createNew')}
            openDialogButtonType={OpenDialogButtonType.add}
            tooltipTitle={t('playlists.actions.createNew')}
            Content={
              <CreatePlaylistForm
                dialogId='createPlaylist'
                submitAction={createPlaylist}
                closeDialog={closeDialog}
                submitButtonLabel={t('general.create')}
                initialValues={{
                  defaultInterval: 7
                }}
                user={user}
              />
            }
          />
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default withStyles(toolbarStyles)(PlaylistsToolbar)
