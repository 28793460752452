import { Grid, MenuItem, Button, DialogActions, InputAdornment } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, reduxForm, InjectedFormProps, formValueSelector } from 'redux-form'
import { Resolutions, Template } from '@seesignage/seesignage-utils'
import BookOutlineIcon from 'mdi-react/BookOutlineIcon'
import { useSelector, useDispatch } from 'react-redux'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@mui/material/ToggleButton'
import PaletteAdvancedIcon from 'mdi-react/PaletteAdvancedIcon'
import ErrorMessage from '../../../components/Errors/ErrorMessage'
import { greaterThanZero, maxValue, required } from '../../../validation'
import { ReduxTextField } from '../../../components/FormInput/ReduxWrappers'
import SelectTemplateField from '../../../components/FormInput/SelectTemplate'
import { bindSubmitActionToPromise, parseNumberField } from '../../../utils/forms'
import {
  createContent as createContentAction,
  updateContent as updateContentAction
} from '../../../actions/contents'
import { createInfopage as createInfopageAction } from '../../../actions/infopages'
import { closeDialog as closeDialogAction } from '../../../actions/dialogs'
import { SelectedInfopageType } from '../../../types/infopages'
import { PageType } from '../../../types/playlists'
import { compareStrings } from '../../../utils/sorting'
import { selectTemplatesByType } from '../../../selectors/templates'

const useStyles = makeStyles(theme => ({
  toggleButtonGroup: {
    width: '100%',
    justifyContent: 'center',
    marginTop: theme.spacing(2)
  },
  formContainer: {
    marginBottom: theme.spacing()
  },
  selectProductContainer: {
    marginTop: theme.spacing(2)
  },
  toggleIcon: {
    marginRight: 4
  }
}))

interface OwnProps {
  submitButtonLabel: string
  dialogId?: string
  isUpdate?: boolean
  infopageType?: SelectedInfopageType
  /** AddPlaylistItemWizard selectPage function if being created to playlist*/
  selectPage?: (page: PageType) => void
  /** AddPlaylistItemWizard back function */
  goBack?: () => void
}

type CreateInfopageContentFormProps = OwnProps

/**
 * Form to create a new Infopage or Content. Can also be used to update Content name and resolution.
 * @param param0
 */
const CreateInfopageOrContentForm: React.FC<CreateInfopageContentFormProps &
  InjectedFormProps<{}, CreateInfopageContentFormProps>> = ({
  handleSubmit,
  submitButtonLabel,
  submitting,
  dialogId,
  error,
  infopageType,
  isUpdate,
  goBack
}) => {
  const dispatch = useDispatch()
  const createContent = bindSubmitActionToPromise(dispatch, createContentAction)
  const createInfopage = bindSubmitActionToPromise(dispatch, createInfopageAction)
  const updateContent = bindSubmitActionToPromise(dispatch, updateContentAction)
  const closeDialog = (dialogId?: string) => dispatch(closeDialogAction(dialogId))

  const templates = useSelector(selectTemplatesByType('info')).sort(
    compareStrings('name')
  ) as Template[]

  const [type, setType] = useState<string>(
    infopageType === SelectedInfopageType.content || isUpdate
      ? SelectedInfopageType.content
      : SelectedInfopageType.infopage
  )
  const submitAction = isUpdate
    ? updateContent
    : type === SelectedInfopageType.infopage
    ? createInfopage
    : createContent
  const classes = useStyles()
  const [t] = useTranslation()

  const selectedResolution = useSelector(state =>
    formValueSelector('CreateInfopageOrContentForm')(state, 'selectedResolution')
  ) as Resolutions

  // useMemo must be used to prevent causing an infinite loop when the component is re-rendered.
  const validateResolution = useMemo(() => maxValue(1920), [])

  return (
    <form onSubmit={handleSubmit(submitAction)}>
      {templates.length > 0 && !isUpdate && (
        <ToggleButtonGroup
          className={classes.toggleButtonGroup}
          exclusive
          value={type}
          onChange={(e, val) => {
            if (val !== null) {
              setType(val)
            }
          }}>
          <ToggleButton value={SelectedInfopageType.content}>
            <BookOutlineIcon className={classes.toggleIcon} />
            {t('infopages.form.emptyInfopage')}
          </ToggleButton>
          <ToggleButton value={SelectedInfopageType.infopage}>
            <PaletteAdvancedIcon className={classes.toggleIcon} />
            {t('infopages.form.fromTemplate')}
          </ToggleButton>
        </ToggleButtonGroup>
      )}
      <Grid container spacing={2} className={classes.formContainer}>
        <Grid item xs={12}>
          <Field
            fullWidth
            name='name'
            label={t('general.name')}
            component={ReduxTextField}
            required
            validate={[required]}
          />
        </Grid>
        {type === SelectedInfopageType.infopage && (
          <Grid item xs={12}>
            <Field
              id='template'
              name='template'
              component={SelectTemplateField}
              initialTemplates={templates}
              required
              validate={[required]}
            />
          </Grid>
        )}
        {type === SelectedInfopageType.content && (
          <>
            <Grid item xs={12}>
              <Field
                required
                validate={[required]}
                name='selectedResolution'
                fullWidth
                label={t('contents.resolution.resolution')}
                component={ReduxTextField}
                select
                disabled={isUpdate}>
                <MenuItem value={Resolutions.landscape}>
                  {t('contents.resolution.landscape')}
                </MenuItem>
                <MenuItem value={Resolutions.portrait}>
                  {t('contents.resolution.portrait')}
                </MenuItem>
                <MenuItem value={Resolutions.landscapeHalf}>
                  {t('contents.resolution.landscapeHalf')}
                </MenuItem>
                <MenuItem value={Resolutions.portraitHalf}>
                  {t('contents.resolution.portraitHalf')}
                </MenuItem>
                <MenuItem value={Resolutions.a4landscape}>
                  {t('contents.resolution.a4landscape')}
                </MenuItem>
                <MenuItem value={Resolutions.a4portrait}>
                  {t('contents.resolution.a4portrait')}
                </MenuItem>
                <MenuItem value={Resolutions.square}>{t('contents.resolution.square')}</MenuItem>
                <MenuItem value={Resolutions.custom}>{t('contents.resolution.custom')}</MenuItem>
              </Field>
            </Grid>
            {selectedResolution === Resolutions.custom && (
              <Grid container item xs={12} columnSpacing={1}>
                <Grid item xs={6}>
                  <Field
                    name='width'
                    component={ReduxTextField}
                    type='number'
                    required
                    parse={parseNumberField}
                    validate={[required, greaterThanZero, validateResolution]}
                    disabled={isUpdate}
                    variant='outlined'
                    fullWidth
                    label={t('contents.resolution.width')}
                    placeholder={t('contents.resolution.width')}
                    InputProps={{
                      inputProps: { min: 1, max: 1920, step: 1 },
                      endAdornment: <InputAdornment position='start'>px</InputAdornment>
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name='height'
                    component={ReduxTextField}
                    required
                    parse={parseNumberField}
                    validate={[required, greaterThanZero, validateResolution]}
                    disabled={isUpdate}
                    type='number'
                    variant='outlined'
                    fullWidth
                    label={t('contents.resolution.height')}
                    placeholder={t('contents.resolution.height')}
                    InputProps={{
                      inputProps: { min: 1, max: 1920, step: 1 },
                      endAdornment: <InputAdornment position='start'>px</InputAdornment>
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Grid>
      <ErrorMessage message={error} />
      <DialogActions>
        {goBack && (
          <Button onClick={goBack} color='primary'>
            {t('general.back')}
          </Button>
        )}
        {closeDialog && dialogId && (
          <Button onClick={() => closeDialog(dialogId)} color='primary'>
            {t('general.cancel')}
          </Button>
        )}
        <Button disabled={submitting} color='primary' type='submit'>
          {submitButtonLabel}
        </Button>
      </DialogActions>
    </form>
  )
}

export default reduxForm<{}, CreateInfopageContentFormProps>({
  form: 'CreateInfopageOrContentForm',
  onSubmitSuccess: (result, dispatch, props) => {
    if (props.selectPage) {
      props.selectPage(PageType.createInfopageItem)
    }
  }
})(CreateInfopageOrContentForm)
