import { Grid, Typography, Tooltip } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import dateFormat from 'dateformat'
import CalendarIcon from 'mdi-react/CalendarIcon'
import ClockOutlineIcon from 'mdi-react/ClockOutlineIcon'
import { path } from 'ramda'
import React, { Fragment } from 'react'
import { PlaylistItemUI, ScheduleDateTime, ScheduleTime } from '@seesignage/seesignage-utils'
import { TFunction } from 'i18next'
import {
  getPlaylistItemScheduleStage,
  getVisibleDaysFromScheduleStage
} from '../../../../../utils/shcedule'
import {
  convertScheduleDateTimeToDate,
  convertScheduleTimeToDate
} from '../../../../../utils/forms'

const styles = () => ({
  container: {
    width: 165,
    textAlign: 'left' as 'left',
    paddingLeft: 8
  },
  clockIcon: { top: '3px', position: 'relative' as 'relative', marginRight: '4px' },
  mobileText: {
    fontSize: '9px'
  },
  text: {
    fontSize: '11px'
  }
})

const formatDate = (scheduleDate: ScheduleDateTime, isMobile: boolean) => {
  const date = convertScheduleDateTimeToDate(scheduleDate)
  if (isMobile) {
    return dateFormat(date, 'dd.mm.yy')
  }
  return dateFormat(date, 'dd.mm.yyyy')
}

interface ScheduleIndicatorProps extends WithStyles<typeof styles> {
  item: PlaylistItemUI
  t: TFunction
  isMobile: boolean
}

const ScheduleIndicator: React.FC<ScheduleIndicatorProps> = ({ item, t, classes, isMobile }) => {
  const scheduleStage = item.schedule?.ranges
    ? getPlaylistItemScheduleStage(item.schedule)
    : undefined
  if (scheduleStage) {
    const endDate = scheduleStage.range?.end
    const startDate = scheduleStage.range?.start
    const firstStartTime: ScheduleTime | undefined = path(
      ['conditions', 0, 'startTime'],
      scheduleStage.range
    )
    const firstEndTime: ScheduleTime | undefined = path(
      ['conditions', 0, 'endTime'],
      scheduleStage.range
    )
    const startDay = startDate ? formatDate(startDate, isMobile) : ''
    const endDay = endDate ? formatDate(endDate, isMobile) : ''
    const startTime = firstStartTime
      ? dateFormat(convertScheduleTimeToDate(firstStartTime), 'HH:MM')
      : undefined
    const endTime = firstEndTime
      ? dateFormat(convertScheduleTimeToDate(firstEndTime), 'HH:MM')
      : undefined
    const visibleDays = getVisibleDaysFromScheduleStage(scheduleStage)
    const textClass = isMobile ? classes.mobileText : classes.text
    return (
      <Tooltip
        disableInteractive
        title={
          scheduleStage?.visibility === 'ended'
            ? (t('playlists.card.itemScheduleEnded') as string)
            : (t('playlists.card.itemIsScheduled') as string)
        }>
        <Grid container spacing={0} alignContent={'flex-end'} className={classes.container}>
          <Grid item xs={12}>
            <Typography
              className={textClass}
              color={scheduleStage.visibility === 'ended' ? 'error' : 'primary'}
              variant='caption'>
              <CalendarIcon
                className={classes.clockIcon}
                color={scheduleStage.visibility === 'ended' ? 'red' : '#1e88e5'}
                size='16px'
              />
              {`${startDay}-${endDay === '31.12.2099' ? '>' : endDay}`}
            </Typography>
          </Grid>
          {startTime && endTime && (
            <Grid item xs={12}>
              <Typography
                className={textClass}
                color={scheduleStage.visibility === 'ended' ? 'error' : 'primary'}
                variant='caption'>
                <ClockOutlineIcon
                  className={classes.clockIcon}
                  color={scheduleStage.visibility === 'ended' ? 'red' : '#1e88e5'}
                  size='16px'
                />
                {`${startTime}-${endTime}`}
              </Typography>
            </Grid>
          )}
          {visibleDays && visibleDays.length > 0 && (
            <Grid item xs={12}>
              <Typography
                className={textClass}
                color={scheduleStage.visibility === 'ended' ? 'error' : 'primary'}
                variant='caption'>
                {visibleDays.map(day => (
                  <span key={`${item.itemId}-${day}`}>
                    {t(`general.time.scheduleDays.${day}`)}{' '}
                  </span>
                ))}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Tooltip>
    )
  }
  return <Fragment />
}

export default withStyles(styles)(ScheduleIndicator)
