import { createAction, createAsyncActions } from './utils'

const reducer = 'USERS'

const {
  action: initializeSession,
  actionSuccess: initializeSessionSuccess,
  actionFail: initializeSessionFail
} = createAsyncActions(reducer, 'INITIALIZE_SESSION')

const { action: login, actionSuccess: loginSuccess, actionFail: loginFail } = createAsyncActions(
  reducer,
  'LOGIN'
)

const { action: logout, actionSuccess: logoutSuccess, actionFail: logoutFail } = createAsyncActions(
  reducer,
  'LOGOUT'
)

const { action: signup, actionSuccess: signupSuccess, actionFail: signupFail } = createAsyncActions(
  reducer,
  'SIGNUP'
)

const {
  action: updateUserInfo,
  actionSuccess: updateUserInfoSuccess,
  actionFail: updateUserInfoFail
} = createAsyncActions(reducer, 'UPDATE_USER_INFO')

const {
  action: deleteUser,
  actionSuccess: deleteUserSuccess,
  actionFail: deleteUserFail
} = createAsyncActions(reducer, 'DELETE_USER')

const {
  action: changePassword,
  actionSuccess: changePasswordSuccess,
  actionFail: changePasswordFail
} = createAsyncActions(reducer, 'CHANGE_PASSWORD')

const {
  action: forgotPassword,
  actionSuccess: forgotPasswordSuccess,
  actionFail: forgotPasswordFail
} = createAsyncActions(reducer, 'FORGOT_PASSWORD')

const {
  action: forgotPasswordSubmit,
  actionSuccess: forgotPasswordSubmitSuccess,
  actionFail: forgotPasswordSubmitFail
} = createAsyncActions(reducer, 'FORGOT_PASSWORD_SUBMIT')

const {
  action: createPreviewSession,
  actionSuccess: createPreviewSessionSuccess,
  actionFail: createPreviewSessionFail
} = createAsyncActions(reducer, 'CREATE_PREVIEW_SESSION')

const updatePermissions = createAction(reducer, 'UPDATE_PERMISSIONS')
const setDeviceType = createAction(reducer, 'SET_DEVICE_TYPE')

const {
  action: addSocialMediaAccount,
  actionSuccess: addSocialMediaAccountSuccess,
  actionFail: addSocialMediaAccountFail
} = createAsyncActions(reducer, 'ADD_SOCIAL_MEDIA_ACCOUNT')

const {
  action: deleteSocialMediaAccount,
  actionSuccess: deleteSocialMediaAccountSuccess,
  actionFail: deleteSocialMediaAccountFail
} = createAsyncActions(reducer, 'DELETE_SOCIAL_MEDIA_ACCOUNT')

const {
  action: openSocialMediaAuthWindow,
  actionSuccess: openSocialMediaAuthWindowSuccess,
  actionFail: openSocialMediaAuthWindowFail
} = createAsyncActions(reducer, 'OPEN_SOCIAL_MEDIA_AUTH_WINDOW')

const {
  action: closeSocialMediaAuthWindow,
  actionSuccess: closeSocialMediaAuthWindowSuccess,
  actionFail: closeSocialMediaAuthWindowFail
} = createAsyncActions(reducer, 'CLOSE_SOCIAL_MEDIA_AUTH_WINDOW')

const {
  action: subscribeUserToNewsLetter,
  actionSuccess: subscribeUserToNewsLetterSuccess,
  actionFail: subscribeUserToNewsLetterFail
} = createAsyncActions(reducer, 'SUBSCRIBE_TO_NEWSLETTER')

const {
  action: downloadSubscribersToNewsLetterList,
  actionSuccess: downloadSubscribersToNewsLetterListSuccess,
  actionFail: downloadSubscribersToNewsLetterListFail
} = createAsyncActions(reducer, 'DOWNLOAD_SUBSCRIBERS_TO_NEWSLETTER')

const {
  action: changeLanguage,
  actionSuccess: changeLanguageSuccess,
  actionFail: changeLanguageFail
} = createAsyncActions(reducer, 'CHANGE_LANGUAGE')

export {
  initializeSession,
  initializeSessionSuccess,
  initializeSessionFail,
  login,
  loginSuccess,
  loginFail,
  logout,
  logoutSuccess,
  logoutFail,
  signup,
  signupSuccess,
  signupFail,
  updateUserInfo,
  updateUserInfoSuccess,
  updateUserInfoFail,
  deleteUser,
  deleteUserSuccess,
  deleteUserFail,
  changePassword,
  changePasswordSuccess,
  changePasswordFail,
  forgotPassword,
  forgotPasswordSuccess,
  forgotPasswordFail,
  forgotPasswordSubmit,
  forgotPasswordSubmitFail,
  forgotPasswordSubmitSuccess,
  updatePermissions,
  setDeviceType,
  createPreviewSession,
  createPreviewSessionSuccess,
  createPreviewSessionFail,
  addSocialMediaAccount,
  addSocialMediaAccountSuccess,
  addSocialMediaAccountFail,
  deleteSocialMediaAccount,
  deleteSocialMediaAccountSuccess,
  deleteSocialMediaAccountFail,
  openSocialMediaAuthWindow,
  openSocialMediaAuthWindowSuccess,
  openSocialMediaAuthWindowFail,
  closeSocialMediaAuthWindow,
  closeSocialMediaAuthWindowSuccess,
  closeSocialMediaAuthWindowFail,
  subscribeUserToNewsLetter,
  subscribeUserToNewsLetterSuccess,
  subscribeUserToNewsLetterFail,
  downloadSubscribersToNewsLetterList,
  downloadSubscribersToNewsLetterListSuccess,
  downloadSubscribersToNewsLetterListFail,
  changeLanguage,
  changeLanguageSuccess,
  changeLanguageFail
}
