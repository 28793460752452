import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { WithStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import withStyles from '@mui/styles/withStyles'
import dateFormat from 'dateformat'
import { path } from 'ramda'
import React, { Fragment } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { FileSystemFile, ScreenResponse } from '@seesignage/seesignage-utils'
import { closeDialog } from '../../../../actions/dialogs'
import { sendScreenCommand } from '../../../../actions/screens'
import { selectKeys } from '../../../../selectors/media'
import { SendScreenCommand, SendScreenCommandParams } from '../../../../types/actions'
import { compareStrings } from '../../../../utils/sorting'
import Dialog from '../../../Dialog'

const styles = (theme: Theme) => ({
  actions: {
    float: 'right' as any,
    padding: theme.spacing(),
    display: 'flex'
  },
  fileViewer: {
    width: '100%',
    height: 450,
    overflow: 'scroll'
  },
  button: {
    marginLeft: theme.spacing()
  }
})

const getSortedFiles = (files: FileSystemFile[] | undefined, filesByName: any) =>
  files
    ? files
        .map(file => {
          const curFilename: string | undefined = path([file.name, 'name'], filesByName)
          return {
            ...file,
            prettyFilename: curFilename ? curFilename : ''
          }
        })
        .sort(compareStrings('prettyFilename'))
    : undefined

interface FilesInformationProps extends WithStyles<typeof styles>, WithTranslation {
  doCloseDialog: any
  sendScreenCommand: SendScreenCommand
  screen: ScreenResponse
  updatedAt?: Date
  filesByName?: any
}

const FilesInformation: React.FC<FilesInformationProps> = ({
  doCloseDialog,
  sendScreenCommand,
  screen: { screenId, name, files },
  classes,
  t,
  filesByName
}) => {
  const sortedFiles = getSortedFiles(files, filesByName)
  return (
    <Paper>
      <Typography variant='h6' style={{ padding: 12 }}>
        {t('screens.files.filesInformation')}
      </Typography>
      <div className={classes.actions}>
        <Dialog
          dialogId='DeleteFilesDialog'
          title={t('screens.files.deleteFiles')}
          buttonLabel={t('screens.files.deleteFiles')}
          color='secondary'
          Content={
            <Fragment>
              <DialogContent>
                <DialogContentText>{t('screens.deleteFilesConfirm', { name })}</DialogContentText>
              </DialogContent>
              <DialogActions style={{ justifyContent: 'center' }}>
                <Button onClick={() => doCloseDialog('DeleteFilesDialog')} color='primary'>
                  {t('general.disagree')}
                </Button>
                <Button
                  onClick={() => {
                    sendScreenCommand({ screenId, command: 'deleteFiles' })
                    doCloseDialog('DeleteFilesDialog')
                  }}
                  color='primary'
                  autoFocus>
                  {t('general.agree')}
                </Button>
              </DialogActions>
            </Fragment>
          }
        />
        <Button
          variant='contained'
          onClick={() => sendScreenCommand({ screenId, command: 'files' })}
          className={classes.button}>
          {t('screens.files.getFiles')}
        </Button>
      </div>

      <div className={classes.fileViewer}>
        <Table padding='normal'>
          <TableHead>
            <TableRow>
              <TableCell>{t('screens.files.name')}</TableCell>
              <TableCell>{t('screens.files.deviceFilename')}</TableCell>
              <TableCell>{t('screens.files.modified')}</TableCell>
              <TableCell>{t('screens.files.size')}</TableCell>
              <TableCell>{t('screens.files.fullPath')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedFiles &&
              sortedFiles.map(({ prettyFilename, name, modified, size, fullPath }) => {
                return (
                  <TableRow key={fullPath}>
                    <TableCell>{prettyFilename}</TableCell>
                    <TableCell>{name}</TableCell>
                    <TableCell>{dateFormat(modified, 'HH:MM dd.mm.yyyy')}</TableCell>
                    <TableCell>{size}</TableCell>
                    <TableCell>{fullPath}</TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </div>
    </Paper>
  )
}

const mapStateToProps = (state: any) => {
  const filenames = Object.values(selectKeys(state)).map(({ key, name }: any) => ({
    filename: key.split('/').pop(),
    name
  }))
  const filesByName = filenames.reduce((acc: any, cur: any) => {
    acc[cur.filename] = cur
    return acc
  }, {})
  return {
    filesByName
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  doCloseDialog: (dialogId: string) => dispatch(closeDialog(dialogId)),
  sendScreenCommand: (command: SendScreenCommandParams) => dispatch(sendScreenCommand(command))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(FilesInformation)))
