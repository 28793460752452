import { Tooltip, IconButton, Popover } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { Fragment, useState } from 'react'
import PencilIcon from 'mdi-react/PencilIcon'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import RenameMediaForm from '../../../containers/MediaBrowser/Forms/RenameMediaForm'
import { getMediaNameAndExtension } from '../../../utils/media'
import { selectMediaByKey } from '../../../selectors/media'

const useStyles = makeStyles(theme => ({
  popoverContent: {
    width: 220,
    padding: theme.spacing(1),
    position: 'relative'
  }
}))

interface RenameMediaFormPopoverProps {
  mediaKey: string
}

const RenameMediaFormPopover: React.FC<RenameMediaFormPopoverProps> = ({ mediaKey }) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const media = useSelector(selectMediaByKey(mediaKey))
  const [renameMediaPopoverAnchorEl, setRenameMediaPopoverAnchorEl] = useState<Element | null>(null)
  const closeRenameMediaPopover = () => {
    setRenameMediaPopoverAnchorEl(null)
  }
  if (!media) {
    return <Fragment />
  }
  const { name, extension } = getMediaNameAndExtension(media.name)
  return (
    <Fragment>
      <Tooltip disableInteractive title={t('media.rename')}>
        <IconButton
          id='rename-media-button'
          onClick={event => {
            setRenameMediaPopoverAnchorEl(event.currentTarget)
          }}
          size='large'>
          <PencilIcon />
        </IconButton>
      </Tooltip>
      <Popover
        open={Boolean(renameMediaPopoverAnchorEl)}
        anchorEl={renameMediaPopoverAnchorEl}
        onClose={closeRenameMediaPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        <div className={classes.popoverContent}>
          <RenameMediaForm
            initialValues={{
              name,
              fileType: extension,
              key: mediaKey
            }}
            onClose={closeRenameMediaPopover}
            useFormOnSubmit={false}
          />
        </div>
      </Popover>
    </Fragment>
  )
}
export default RenameMediaFormPopover
