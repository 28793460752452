import { handleActions } from 'redux-actions'
import {
  GeneralProductUI,
  ListType,
  Product,
  RetailProduct,
  isPharmacyProduct
} from '@seesignage/seesignage-utils'
import {
  getProductsSuccess,
  clearSuggestions,
  listProductsSuccess,
  listProductsFromForm,
  listProductsFromFormFail,
  clearCustomerProducts,
  deleteProductSuccess,
  listProducts,
  listProductsFail,
  getProducts,
  getProductsFail,
  createOrUpdateGeneralProductSuccess,
  createOrUpdateRetailProductSuccess,
  resetProducts,
  prefillRetailProductForm,
  prefillRetailProductFormFail,
  prefillRetailProductFormSuccess,
  updateProductDetails,
  updateProductDetailsSuccess,
  updateProductDetailsFail
} from '../actions/products'
import { ProductsState } from '../types/states'
import { Action } from '../types/actions'
import { ProductsIndexedById } from '../types/products'

const initialState: ProductsState = {
  suggestions: {},
  customerProducts: {},
  productsLoading: false,
  productsSearching: false,
  customerProductType: undefined,
  prefillLoading: false,
  sokProductsUpdating: false
}

const products = handleActions<ProductsState, any>(
  {
    [resetProducts]: () => initialState,
    [getProductsSuccess]: (state, { payload: productsArray }: Action<Product[]>) => {
      const suggestions = productsArray.reduce((products: ProductsIndexedById, product) => {
        if (!isPharmacyProduct(product)) {
          products[product.productId] = {
            ...product
          }
        }

        return products
      }, {})
      return {
        ...state,
        suggestions,
        productsSearching: false
      }
    },
    [clearSuggestions]: state => ({
      ...state,
      suggestions: {}
    }),
    [createOrUpdateGeneralProductSuccess]: (
      state,
      { payload: product }: Action<GeneralProductUI>
    ) => ({
      ...state,
      customerProducts: {
        ...state.customerProducts,
        [product.productId]: product
      }
    }),
    [createOrUpdateRetailProductSuccess]: (state, { payload: product }: Action<RetailProduct>) => ({
      ...state,
      customerProducts: {
        ...state.customerProducts,
        [product.productId]: product
      }
    }),
    [listProducts]: state => ({
      ...state,
      productsLoading: true
    }),
    [listProductsFail]: state => ({
      ...state,
      productsLoading: false
    }),
    [listProductsFromForm]: state => ({
      ...state,
      productsLoading: true
    }),
    [listProductsFromFormFail]: state => ({
      ...state,
      productsLoading: false
    }),
    [listProductsSuccess]: (
      state,
      { payload: { productsArray, type } }: Action<{ productsArray: Product[]; type: ListType }>
    ) => {
      const customerProducts = productsArray.reduce((products: ProductsIndexedById, product) => {
        if (!isPharmacyProduct(product)) {
          products[product.productId] = {
            ...product
          }
        }
        return products
      }, {})
      return {
        ...state,
        customerProducts,
        customerProductType: type,
        productsLoading: false
      }
    },
    [clearCustomerProducts]: state => ({
      ...state,
      customerProducts: {},
      customerProductType: undefined
    }),
    [deleteProductSuccess]: (state, { payload: productId }: Action<string>) => {
      const products = { ...state.customerProducts }
      delete products[productId]
      return {
        ...state,
        customerProducts: products
      }
    },
    [getProducts]: state => ({
      ...state,
      productsSearching: true
    }),
    [getProductsFail]: state => ({
      ...state,
      productsSearching: false
    }),
    [prefillRetailProductForm]: state => ({
      ...state,
      prefillLoading: true
    }),
    [prefillRetailProductFormFail]: state => ({
      ...state,
      prefillLoading: false
    }),
    [prefillRetailProductFormSuccess]: state => ({
      ...state,
      prefillLoading: false
    }),
    [updateProductDetails]: state => ({
      ...state,
      sokProductsUpdating: true
    }),
    [updateProductDetailsSuccess]: state => ({
      ...state,
      sokProductsUpdating: false
    }),
    [updateProductDetailsFail]: state => ({
      ...state,
      sokProductsUpdating: false
    })
  },
  initialState
)

export default products
