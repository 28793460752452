import React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import LocationOnIcon from 'mdi-react/MapMarkerIcon'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import throttle from 'lodash/throttle'
import { AutocompleteOption } from '@seesignage/seesignage-utils'
import { getPlacesApiUrl } from '../../../services/api/googlePlaces'

/* eslint-disable @typescript-eslint/camelcase */

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return
  }

  const script = document.createElement('script')
  script.setAttribute('async', '')
  script.setAttribute('id', id)
  script.src = src
  position.appendChild(script)
}

const autocompleteService = { current: null }

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2)
  }
}))

const ReduxGoogleAutocomplete = ({ input, label, disabled, ...custom }: any) => {
  const classes = useStyles()
  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState<AutocompleteOption[]>(custom.existingLocations)
  const loaded = React.useRef(false)
  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(getPlacesApiUrl(), document.querySelector('head'), 'google-maps')
    }

    loaded.current = true
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  const fetch = React.useMemo(
    () =>
      throttle((input: any, callback: any) => {
        ;(autocompleteService.current as any).getPlacePredictions(input, callback)
      }, 200),
    []
  )

  React.useEffect(() => {
    let active = true

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService()
    }
    if (!autocompleteService.current) {
      return undefined
    }

    if (inputValue === '') {
      return undefined
    }

    fetch({ input: inputValue }, (results?: any[]) => {
      if (active) {
        setOptions(
          results
            ? results.map(({ place_id, description }) => ({
                value: place_id,
                label: description
              }))
            : []
        )
      }
    })

    return () => {
      active = false
    }
  }, [inputValue, fetch])

  return (
    <Autocomplete
      disabled={disabled}
      id='google-map-location'
      value={input.value || null}
      getOptionLabel={option => (typeof option === 'string' ? option : option.label)}
      filterOptions={x => x}
      onChange={(event: any, value: any) => input.onChange(value)}
      options={options}
      autoComplete
      includeInputInList
      freeSolo
      renderInput={params => (
        <TextField variant='standard' {...params} label={label} fullWidth onChange={handleChange} />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Grid container alignItems='center'>
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                <Typography variant='body2'>{option.label}</Typography>
              </Grid>
            </Grid>
          </li>
        )
      }}
    />
  )
}

export default ReduxGoogleAutocomplete
