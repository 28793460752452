import { createAction } from './utils'

const reducer = 'TOURS'

const runTour = createAction(reducer, 'RUN_TOUR')
const forceRunTour = createAction(reducer, 'FORCE_RUN_TOUR')
const setTourStatus = createAction(reducer, 'SET_TOUR_STATUS')
const clearTour = createAction(reducer, 'CLEAR_TOUR')

export { runTour, forceRunTour, setTourStatus, clearTour }
