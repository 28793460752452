import React from 'react'
import { Field, reduxForm, InjectedFormProps, formValueSelector } from 'redux-form'
import DialogActions from '@mui/material/DialogActions'
import LinearProgress from '@mui/material/LinearProgress'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { MenuItem } from '@mui/material'
import { required } from '../../../../validation'
import { CloseDialog, AddTemplateMockData } from '../../../../types/actions'
import { ReduxTextField } from '../../../../components/FormInput/ReduxWrappers'
import { StateInterface } from '../../../../types/states'
import { addTemplateMockData } from '../../../../actions/mocks'
import { closeDialog } from '../../../../actions/dialogs'
import { bindSubmitActionToPromise } from '../../../../utils/forms'
import { MockProductType } from '../../../../types/mocks'
import ErrorMessage from '../../../../components/Errors/ErrorMessage'
import PricerFormFields from './PricerFormFields'
import RetailFormFields from './RetailFormFields'
import DrugFormFields from './DrugFormFields'
import GeneralRetailFormFields from './GeneralRetailFormFields'
import FishFormFields from './FishFormFields'
import MeatFormFields from './MeatFormFields'
import LunchFormFields from './LunchFormFields'

const MockProductTypes = Object.values(MockProductType)

const renderFormProductFields = (mockProductType: MockProductType) => {
  switch (mockProductType) {
    case MockProductType.retail:
      return <RetailFormFields />
    case MockProductType.generalRetail:
      return <GeneralRetailFormFields />
    case MockProductType.pricerVerkkokauppa:
    case MockProductType.pricerHankkija:
      return <PricerFormFields mockProductType={mockProductType} />
    case MockProductType.pharmacy:
      return <DrugFormFields />
    case MockProductType.fish:
      return <FishFormFields />
    case MockProductType.meat:
      return <MeatFormFields />
    case MockProductType.lunch:
      return <LunchFormFields />
    default:
      return <div />
  }
}

interface OwnProps {
  dialogId: string
}

interface StateProps {
  currentMockProductType: MockProductType
}

interface DispatchProps {
  addTemplateMockData: AddTemplateMockData
  closeDialog: CloseDialog
}

type ComponentProps = OwnProps & StateProps & DispatchProps
type TemplateMockDataFormProps = ComponentProps & WithTranslation

const TemplateMockDataForm: React.FC<TemplateMockDataFormProps &
  InjectedFormProps<{}, ComponentProps>> = ({
  handleSubmit,
  addTemplateMockData,
  submitting,
  closeDialog,
  dialogId,
  error,
  currentMockProductType,
  t
}) => {
  return (
    <form onSubmit={handleSubmit(addTemplateMockData)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Field
            label={t('templates.templateMockDataForm.currentMockProductType')}
            name='currentMockProductType'
            component={ReduxTextField}
            required
            validate={[required]}
            fullWidth
            select>
            {MockProductTypes.map(mockType => (
              <MenuItem key={mockType} value={mockType}>
                {mockType}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={12}>
          {renderFormProductFields(currentMockProductType)}
        </Grid>
        <Grid item xs={12} sm={12}>
          {submitting && <LinearProgress />}
          <ErrorMessage message={error} />
        </Grid>
      </Grid>
      <DialogActions>
        <Button onClick={() => closeDialog(dialogId)} color='primary'>
          {t('general.cancel')}
        </Button>
        <Button disabled={submitting} color='primary' type='submit'>
          {t('general.save')}
        </Button>
      </DialogActions>
    </form>
  )
}

const selector = formValueSelector('TemplateMockDataForm')

const mapStateToProps = (state: StateInterface): StateProps => ({
  currentMockProductType: selector(state, 'currentMockProductType')
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  addTemplateMockData: bindSubmitActionToPromise(dispatch, addTemplateMockData),
  closeDialog: () => dispatch(closeDialog())
})

export default connect<StateProps, DispatchProps, OwnProps, StateInterface>(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<{}, ComponentProps>({
    form: 'TemplateMockDataForm'
  })(withTranslation()(TemplateMockDataForm))
)
