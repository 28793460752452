import React, { Fragment } from 'react'
import { reduxForm, InjectedFormProps, Field } from 'redux-form'
import PlayIcon from 'mdi-react/PlayIcon'
import PauseIcon from 'mdi-react/PauseIcon'
import VideoImageIcon from 'mdi-react/VideoImageIcon'
import { IconButton, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { MediaType } from '@seesignage/seesignage-utils'
import { ReduxSwitch } from '../../../../../components/FormInput/ReduxWrappers'
import { SelectedObjectAction } from '../../../../../types/actions'
import {
  ContentVideoFormData,
  SelectedObjectActionType,
  ContentEditorDialog
} from '../../../../../types/contents'
import { OpenContentEditorDialog } from '../../../../../types/dialogs'
import ContentToolbarItem from '../ContentToolbarItem/ContentToolbarItem'
import CommonContentFields from './CommonContentFields'

type FormProps = ContentVideoFormData

interface OwnProps {
  selectedObjectAction: SelectedObjectAction
  openDialog: OpenContentEditorDialog
}

const ContentVideoForm: React.FC<OwnProps & InjectedFormProps<FormProps, OwnProps>> = ({
  selectedObjectAction,
  openDialog
}) => {
  const [t] = useTranslation()
  return (
    <Fragment>
      <CommonContentFields formName={'ContentVideoForm'} t={t} />
      <ContentToolbarItem>
        <Button
          variant='contained'
          color='primary'
          startIcon={<VideoImageIcon />}
          onClick={() =>
            openDialog({
              dialogId: ContentEditorDialog.AddMediaContentFormDialog,
              mediaType: MediaType.video
            })
          }>
          {t('contents.forms.updateVideo')}
        </Button>
      </ContentToolbarItem>
      {/* might be unnecessary because we always want to autoplay since video controls does not exists
    <Field name='cVideoAutoplay' component={ReduxSwitch} label='Autoplay' /> */}
      <ContentToolbarItem>
        <Field name='cVideoProps.loop' component={ReduxSwitch} label='Loop' />
      </ContentToolbarItem>
      <ContentToolbarItem>
        <Field name='cVideoProps.muted' component={ReduxSwitch} label='Muted' />
      </ContentToolbarItem>
      <ContentToolbarItem>
        <IconButton
          onClick={() => selectedObjectAction(SelectedObjectActionType.play)}
          size='small'>
          <PlayIcon color='rgba(0, 0, 0, 0.54)' />
        </IconButton>
      </ContentToolbarItem>
      <ContentToolbarItem>
        <IconButton
          onClick={() => selectedObjectAction(SelectedObjectActionType.pause)}
          size='small'>
          <PauseIcon color='rgba(0, 0, 0, 0.54)' />
        </IconButton>
      </ContentToolbarItem>
    </Fragment>
  )
}
export default reduxForm<FormProps, OwnProps>({
  form: 'ContentVideoForm'
})(ContentVideoForm)
