import { List, ListItem, ListItemButton, ListItemIcon, Tabs, Tab, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon'
import ChevronRightIcon from 'mdi-react/ChevronRightIcon'
import LayersTripleIcon from 'mdi-react/LayersTripleIcon'
import CogIcon from 'mdi-react/CogIcon'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import HelpCircleIcon from 'mdi-react/HelpCircleIcon'
import {
  isFabricDateObject,
  isFabricFalconyAnnouncementsObject,
  isFabricIframeObject,
  isFabricMediaCarouselObject,
  isFabricQRCodeObject,
  isFabricRssFeedObject,
  isFabricSocialMediaObject,
  isFabricStopScheduleObject,
  isFabricTableObject,
  isFabricWeatherObject
} from '@seesignage/seesignage-player-utils'
import { DrawerViews } from '../../../../types/contents'
import { selectSelectedObjects } from '../../../../selectors/contents'
import { openContentObjectsView } from '../../../../actions/contents'
import { isShadowAbleObject } from '../../../../utils/fabric/canvasObjectUtils'
import Layers from './Views/Layers'
import Settings from './Views/Settings'

const OPEN_PANEL_WIDTH = 350
const CLOSE_PANEL_WIDTH = 55

const useStyles = makeStyles({
  rightPanel: {
    position: 'relative',
    overflow: 'auto',
    right: 0,
    '-webkit-box-shadow': '-1px 0 5px rgba(0, 0, 0, 0.3)',
    '-moz-box-shadow': '-1px 0 5px rgba(0, 0, 0, 0.3)',
    boxShadow: '-1px 0 5px rgba(0, 0, 0, 0.3)',
    background: '#fff',
    transition: 'width .5s ease',
    zIndex: 10,
    minWidth: CLOSE_PANEL_WIDTH
  },
  panelHeader: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: 500,
    fontSize: '0.875rem',
    padding: '8px 16px',
    height: 48,
    alignItems: 'center'
  },
  subHeaderText: {
    padding: 8
  },
  listItem: {
    overflow: 'hidden',
    textWrap: 'nowrap',
    paddingTop: 12,
    paddingBottom: 12
  },
  tabsHeader: {
    width: '100%',
    height: '100%'
  },
  tabHeader: {
    minHeight: 48
  },
  tabPanel: {
    paddingLeft: 5,
    paddingRight: 5,
    flex: 1
  }
})

interface TabPanelProps {
  children?: React.ReactNode
  activeTab: DrawerViews
  value: DrawerViews
  className?: string
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, activeTab, className, ...other } = props

  return value === activeTab ? (
    <div className={className} {...other}>
      {children}
    </div>
  ) : null
}

function a11yProps(value: DrawerViews) {
  return {
    id: `simple-tab-${value}`,
    'aria-controls': `simple-tabpanel-${value}`,
    value
  }
}

interface ContentDrawerProps {
  isSystemAdmin: boolean
  editorLoaded: boolean
}

const ContentDrawer = ({ isSystemAdmin, editorLoaded }: ContentDrawerProps) => {
  const [t] = useTranslation()
  const classes = useStyles()
  const [openPanel, setOpenPanel] = useState(true)
  const [selectedDrawerView, selectDrawerView] = useState<DrawerViews>(DrawerViews.layers)
  const [selectedObjectHasSettings, setSelectedObjectHasSettings] = useState(false)
  const dispatch = useDispatch()

  const selectedObjects = useSelector(selectSelectedObjects)
  const selectedObject =
    selectedObjects && selectedObjects.length === 1 ? selectedObjects[0] : undefined

  const handleChange = (event: React.SyntheticEvent, newValue: DrawerViews) => {
    selectDrawerView(newValue)
  }

  const handleSwitchView = (newView: DrawerViews) => {
    selectDrawerView(newView)
    if (!openPanel) {
      setOpenPanel(true)
    }
  }

  // Enable setting only when selected object has settings
  useEffect(() => {
    if (
      selectedObject &&
      [
        isShadowAbleObject,
        isFabricTableObject,
        isFabricStopScheduleObject,
        isFabricRssFeedObject,
        isFabricDateObject,
        isFabricQRCodeObject,
        isFabricWeatherObject,
        isFabricSocialMediaObject,
        isFabricMediaCarouselObject,
        isFabricIframeObject,
        isFabricFalconyAnnouncementsObject
      ].some(fn => fn(selectedObject))
    ) {
      setSelectedObjectHasSettings(true)
    } else {
      setSelectedObjectHasSettings(false)
      selectDrawerView(DrawerViews.layers)
    }
  }, [selectedObject])

  // Refresh object views when layers panel is open
  useEffect(() => {
    const openObjectsView = () => dispatch(openContentObjectsView())
    if (editorLoaded && openPanel && selectedDrawerView === DrawerViews.layers) {
      openObjectsView()
    }
  }, [dispatch, openPanel, selectedDrawerView, editorLoaded])

  return (
    <div
      className={classes.rightPanel}
      style={{
        width: openPanel ? OPEN_PANEL_WIDTH : CLOSE_PANEL_WIDTH
      }}>
      {editorLoaded && (
        <List aria-labelledby='nested-list-subheader' disablePadding>
          <ListItem disablePadding divider>
            <ListItemButton
              className={classes.panelHeader}
              onClick={() => setOpenPanel(prevValue => !prevValue)}>
              {openPanel ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </ListItemButton>
          </ListItem>
          {openPanel ? (
            <>
              <ListItem disablePadding divider>
                <Tabs
                  value={selectedDrawerView}
                  onChange={handleChange}
                  variant='fullWidth'
                  className={classes.tabsHeader}
                  sx={{ height: '100%' }}
                  aria-label='basic tabs example'>
                  <Tab
                    label={t('contents.contentDrawer.layers')}
                    className={classes.tabHeader}
                    icon={
                      <Tooltip disableInteractive title={t('contents.contentDrawer.layersInfo')}>
                        <span>
                          <HelpCircleIcon color='grey' size={16} />
                        </span>
                      </Tooltip>
                    }
                    iconPosition='end'
                    {...a11yProps(DrawerViews.layers)}
                  />
                  <Tab
                    disabled={!selectedObjectHasSettings}
                    className={classes.tabHeader}
                    label={t('contents.contentDrawer.settings')}
                    {...a11yProps(DrawerViews.settings)}
                  />
                </Tabs>
              </ListItem>
              <ListItem disablePadding>
                <CustomTabPanel
                  className={classes.tabPanel}
                  value={DrawerViews.layers}
                  activeTab={selectedDrawerView}>
                  <Layers selectedObject={selectedObject} />
                </CustomTabPanel>
                <CustomTabPanel
                  className={classes.tabPanel}
                  value={DrawerViews.settings}
                  activeTab={selectedDrawerView}>
                  <Settings selectedObject={selectedObject} isSystemAdmin={isSystemAdmin} />
                </CustomTabPanel>
              </ListItem>
            </>
          ) : (
            <>
              <ListItem disablePadding>
                <ListItemButton
                  selected={selectedDrawerView === DrawerViews.layers}
                  className={classes.listItem}
                  onClick={() => handleSwitchView(DrawerViews.layers)}>
                  <ListItemIcon>
                    <LayersTripleIcon />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  selected={selectedDrawerView === DrawerViews.settings}
                  disabled={!selectedObjectHasSettings}
                  className={classes.listItem}
                  onClick={() => handleSwitchView(DrawerViews.settings)}>
                  <ListItemIcon>
                    <CogIcon />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            </>
          )}
        </List>
      )}
    </div>
  )
}

export default ContentDrawer
