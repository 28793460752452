import React from 'react'
import AlphaDBoxIcon from 'mdi-react/AlphaDBoxIcon'
import CardTextOutlineIcon from 'mdi-react/CardTextOutlineIcon'
import ImageIcon from 'mdi-react/ImageIcon'
import VideoIcon from 'mdi-react/VideoBoxIcon'
import CalendarIcon from 'mdi-react/CalendarIcon'
import QrcodeIcon from 'mdi-react/QrcodeIcon'
import ShoppingIcon from 'mdi-react/ShoppingIcon'
import HelpBoxIcon from 'mdi-react/HelpBoxIcon'
import DollarIcon from 'mdi-react/CurrencyUsdIcon'
import PastaIcon from 'mdi-react/PastaIcon'
import FishIcon from 'mdi-react/FishIcon'
import FoodSteakIcon from 'mdi-react/FoodSteakIcon'
import { ComponentType } from '@seesignage/seesignage-utils'
import SilverwareForkKnifeIcon from 'mdi-react/SilverwareForkKnifeIcon'
import CartIcon from 'mdi-react/CartIcon'

const Icon = ({ type }: { type: ComponentType }) => {
  switch (type) {
    case ComponentType.div:
      return <AlphaDBoxIcon />
    case ComponentType.text:
      return <CardTextOutlineIcon />
    case ComponentType.image:
      return <ImageIcon />
    case ComponentType.video:
      return <VideoIcon />
    case ComponentType.date:
      return <CalendarIcon />
    case ComponentType.qrCode:
      return <QrcodeIcon />
    case ComponentType.products:
      return <CartIcon />
    case ComponentType.productData:
      return <ShoppingIcon />
    case ComponentType.priceItems:
    case ComponentType.lunchGroupPrice:
    case ComponentType.listPrice:
      return <DollarIcon />
    case ComponentType.lunchItemContainer:
    case ComponentType.lunchItemsContainer:
      return <SilverwareForkKnifeIcon />
    case ComponentType.lunchItemSpecialDiet:
      return <PastaIcon />
    case ComponentType.fishItems:
      return <FishIcon />
    case ComponentType.meatItems:
      return <FoodSteakIcon />
    default:
      return <HelpBoxIcon />
  }
}

export default Icon
