import { Grid } from '@mui/material'
import React from 'react'
import { Field } from 'redux-form'
import FieldsExpansionPanel from '../../../../../components/ExtensionPanels/FieldsExpansionPanel'
import { generateFieldPrefix, gridItemProps } from '../utils'
import { ReduxTextField } from '../../../../../components/FormInput/ReduxWrappers'
import MenuField from '../../../../../components/MenuField'
import cssProperties from '../cssProperties'

interface SizeFieldsProps {
  level: number
  childIndexes: number[]
}

const SizeFields: React.FC<SizeFieldsProps> = ({ level, childIndexes }) => (
  <FieldsExpansionPanel label='size'>
    <Grid container spacing={2} alignItems='center'>
      <Grid {...gridItemProps}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'width')}
          label='Width'
          component={ReduxTextField}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'height')}
          label='Height'
          component={ReduxTextField}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'maxWidth')}
          label='Max width'
          component={ReduxTextField}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'maxHeight')}
          label='Max height'
          component={ReduxTextField}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <MenuField
          name={generateFieldPrefix(level, childIndexes, 'objectFit')}
          label='object-fit'
          items={cssProperties.objectFit}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <Field
          name={generateFieldPrefix(level, childIndexes, 'objectPosition')}
          label='object-position'
          component={ReduxTextField}
        />
      </Grid>
    </Grid>
  </FieldsExpansionPanel>
)

export default SizeFields
