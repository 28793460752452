import { Tooltip, Chip, Typography } from '@mui/material'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import LockIcon from 'mdi-react/LockIcon'
import { ChannelItemPriority } from '@seesignage/seesignage-utils'
import { CalendarEvent } from '../../../types/channels'
import { eventChipStyleByPriority } from '../../../utils/channels'

const EventTags = ({ tags, priority }: { tags: string[]; priority?: ChannelItemPriority }) => (
  <div
    style={{
      margin: '0px 4px 0px 4px',
      display: 'flex'
    }}>
    {tags.map(tag => (
      <Chip
        style={{
          ...eventChipStyleByPriority[priority || ChannelItemPriority.low],
          margin: 2
        }}
        size='small'
        key={tag}
        label={tag}
      />
    ))}
  </div>
)

interface EventProps extends WithTranslation {
  event: CalendarEvent
}

const Event = ({
  event: { title, tags, playlistItemsCount, userHasAccess, priority },
  t
}: EventProps) => (
  <span>
    <Tooltip
      disableInteractive
      title={
        userHasAccess
          ? (t('channels.actions.updateItem', { title }) as string)
          : (t('channels.actions.noAccess') as string)
      }>
      <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
        {!userHasAccess && <LockIcon color='white' size={16} />}
        <Typography>
          {title} {playlistItemsCount && `(${playlistItemsCount})`}
        </Typography>

        {tags && <EventTags tags={tags} priority={priority} />}
      </div>
    </Tooltip>
  </span>
)

export default withTranslation()(Event)
