import { handleActions } from 'redux-actions'
import { Confirmation } from '@seesignage/seesignage-utils'
import {
  listConfirmationsSuccess,
  createConfirmationsSuccess,
  deleteConfirmationSuccess
} from '../actions/confirmations'
import { indexConfirmationsByEnvironmentId } from '../selectors/confirmations'
import { Action } from '../types/actions'
import { ConfirmationsState } from '../types/states'

const initialState: ConfirmationsState = {
  confirmationsByEnvironmentId: {}
}

const confirmations = handleActions<ConfirmationsState, any>(
  {
    [listConfirmationsSuccess]: (state, { payload: confirmations }: Action<Confirmation[]>) => {
      const confirmationsByEnvironmentId = indexConfirmationsByEnvironmentId(confirmations)
      return {
        ...state,
        confirmationsByEnvironmentId: {
          ...state.confirmationsByEnvironmentId,
          ...confirmationsByEnvironmentId
        }
      }
    },
    [createConfirmationsSuccess]: (state, { payload: confirmations }: Action<Confirmation[]>) => {
      const confirmationsByEnvironmentId = indexConfirmationsByEnvironmentId(confirmations)
      return {
        ...state,
        confirmationsByEnvironmentId: {
          ...state.confirmationsByEnvironmentId,
          ...confirmationsByEnvironmentId
        }
      }
    },
    [deleteConfirmationSuccess]: (
      state,
      {
        payload: { environmentId, confirmationId }
      }: Action<{ environmentId: string; confirmationId: string }>
    ) => {
      const confirmationsByEnvironmentId = [...state.confirmationsByEnvironmentId[environmentId]]
      const i = confirmationsByEnvironmentId.findIndex(c => c.confirmationId === confirmationId)
      confirmationsByEnvironmentId.splice(i, 1)
      return {
        ...state,
        confirmationsByEnvironmentId: {
          ...state.confirmationsByEnvironmentId,
          [environmentId]: confirmationsByEnvironmentId
        }
      }
    }
  },
  initialState
)

export default confirmations
