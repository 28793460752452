import React from 'react'
import { Field } from 'redux-form'
import { Grid, InputAdornment } from '@mui/material'
import { TFunction } from 'i18next'
import FormatBoldIcon from 'mdi-react/FormatBoldIcon'
import FormatLineSpacingIcon from 'mdi-react/FormatLineSpacingIcon'
import FormatItalicIcon from 'mdi-react/FormatItalicIcon'
import FormatFontIcon from 'mdi-react/FormatFontIcon'
import FormatSizeIcon from 'mdi-react/FormatSizeIcon'
import { getSupportedFontFamilies } from '@seesignage/seesignage-player-utils/lib'
import FormatTextWrappingOverflowIcon from 'mdi-react/FormatTextWrappingOverflowIcon'
import { ReduxSelect } from '../../../../../../components/FormInput/ReduxWrappers'
import ContentColorPicker from '../../../../../../components/FormInput/ColorPicker/ContentColorPicker'
import TooltipSpan from '../../../../../../components/Tooltip/TooltipSpan'
import TextAlignCheckBox from '../../../../../../components/FormInput/ReduxWrappers/Contents/TextAlignCheckBox'
import StringCheckBox from '../../../../../../components/FormInput/ReduxWrappers/Contents/StringCheckBox'
import TextDecorationCheckBox from '../../../../../../components/FormInput/ReduxWrappers/Contents/TextDecorationCheckBox'

interface TextFieldsProps {
  namePrefix: string
  t: TFunction
  isFieldsDisabled: boolean
}

const lineHeightOptions = [1, 1.15, 1.5, 2].map(value => ({
  value,
  label: value
}))
const charSpacingValuesOptions = [0, 10, 20, 40, 60, 80, 100, 200].map(value => ({
  value,
  label: value
}))
const fontSizes = [...Array(151).keys()].splice(9)
const fontSizesOptions = fontSizes.map(fontSize => ({
  value: fontSize,
  label: fontSize
}))
const supportedFonts = getSupportedFontFamilies()
const fontsOptions = supportedFonts.map(font => ({
  value: font,
  label: font,
  style: { fontFamily: font }
}))

const TextFields: React.FC<TextFieldsProps> = ({ namePrefix, t, isFieldsDisabled }) => (
  <Grid container>
    <Grid item xs={8}>
      <Field
        name={`${namePrefix}.fontFamily`}
        component={ReduxSelect}
        tooltip={t('contents.properties.fontFamily')}
        fullWidth
        disabled={isFieldsDisabled}
        variant='outlined'
        size='small'
        options={fontsOptions}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <FormatFontIcon color='rgba(0, 0, 0, 0.54)' />
            </InputAdornment>
          )
        }}></Field>
    </Grid>
    <Grid item xs={4}>
      <Field
        name={`${namePrefix}.fontSize`}
        component={ReduxSelect}
        options={fontSizesOptions}
        tooltip={t('contents.properties.fontSize')}
        variant='outlined'
        size='small'
        fullWidth
        disabled={isFieldsDisabled}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <FormatSizeIcon color='rgba(0, 0, 0, 0.54)' />
            </InputAdornment>
          )
        }}
      />
    </Grid>
    <Field
      name={`${namePrefix}.color`}
      component={ContentColorPicker}
      tooltip={t('contents.actions.selectTextColor')}
      disabled={isFieldsDisabled}
    />
    <Field
      name={`${namePrefix}.textAlign`}
      t={t}
      component={TextAlignCheckBox}
      disabled={isFieldsDisabled}
    />
    <TooltipSpan title={t('contents.properties.fontWeight')}>
      <Field
        name={`${namePrefix}.fontWeight`}
        component={StringCheckBox}
        Icon={FormatBoldIcon}
        checkedValue='bold'
        uncheckedValue='normal'
        disabled={isFieldsDisabled}
      />
    </TooltipSpan>
    <TooltipSpan title={t('contents.properties.fontStyle')}>
      <Field
        name={`${namePrefix}.fontStyle`}
        component={StringCheckBox}
        Icon={FormatItalicIcon}
        checkedValue='italic'
        uncheckedValue='normal'
        disabled={isFieldsDisabled}
      />
    </TooltipSpan>
    <Field
      name={`${namePrefix}.textDecoration`}
      component={TextDecorationCheckBox}
      disabled={isFieldsDisabled}
    />
    <Field
      component={ReduxSelect}
      disabled={isFieldsDisabled}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <FormatLineSpacingIcon color='rgba(0, 0, 0, 0.54)' />
          </InputAdornment>
        )
      }}
      options={lineHeightOptions}
      name={`${namePrefix}.lineHeight`}
      tooltip={t('contents.properties.lineHeight')}
      variant='outlined'
      size='small'
    />
    <Field
      component={ReduxSelect}
      disabled={isFieldsDisabled}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <FormatTextWrappingOverflowIcon color='rgba(0, 0, 0, 0.54)' />
          </InputAdornment>
        )
      }}
      name={`${namePrefix}.letterSpacing`}
      options={charSpacingValuesOptions}
      size='small'
      tooltip={t('contents.properties.charSpacing')}
      variant='outlined'
    />
  </Grid>
)

export default TextFields
