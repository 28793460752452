import React, { Fragment } from 'react'
import { Chip, ListItem, ListItemText, TableCell, TableRow, Typography } from '@mui/material'
import {
  Customer,
  CustomerImages,
  CustomerTables,
  CustomerTableType,
  Environment,
  Integration
} from '@seesignage/seesignage-utils'
import ShoppingIcon from 'mdi-react/ShoppingIcon'
import FishIcon from 'mdi-react/FishIcon'
import FoodSteakIcon from 'mdi-react/FoodSteakIcon'
import PillIcon from 'mdi-react/PillIcon'
import { useDispatch } from 'react-redux'
import { deselectCustomerRow, selectCustomerRow } from '../../actions/customers'

const getTableIcon = (tableType: CustomerTableType | undefined) => {
  if (tableType === CustomerTableType.fish) {
    return <FishIcon />
  }
  if (tableType === CustomerTableType.meat) {
    return <FoodSteakIcon />
  }
  if (tableType === CustomerTableType.retail) {
    return <ShoppingIcon />
  }
  if (tableType === CustomerTableType.pharmacy) {
    return <PillIcon />
  }
}

const renderTables = (tables: CustomerTables) => (
  <Fragment>
    {Object.entries(tables).map(arr =>
      arr[1] ? (
        <Chip
          key={arr[0]}
          label={arr[1]}
          icon={getTableIcon(arr[0] as CustomerTableType | undefined)}
        />
      ) : (
        <Fragment key={arr[0]} />
      )
    )}
  </Fragment>
)

const renderIntegration = ({ retail, emmi }: Integration) => {
  if (emmi) {
    return <Fragment>{`EMMi ${emmi.url}`}</Fragment>
  } else if (retail) {
    const { dataSource, storeId, prefill } = retail
    return (
      <Fragment>
        {dataSource} {storeId} {prefill ? 'prefill' : ''}
      </Fragment>
    )
  }
  return <Fragment />
}

const renderImages = ({ foodie, kRuoka }: CustomerImages) => (
  <Fragment>
    {foodie ? 'foodie' : ''}
    {kRuoka ? 'kRuoka' : ''}
  </Fragment>
)

export interface CustomersTableRowProps {
  customer: Customer
  environment: Environment
  selectedCustomerId?: string
}

const CustomersTableRow = ({
  environment,
  customer: { customerId, name, productForm, tables, integration, images },
  selectedCustomerId
}: CustomersTableRowProps) => {
  const isSelected = selectedCustomerId === customerId
  const dispatch = useDispatch()
  return (
    <Fragment key={customerId}>
      <TableRow
        hover
        // className={classes.row}
        onClick={() => {
          if (customerId === selectedCustomerId) {
            dispatch(deselectCustomerRow())
          } else {
            dispatch(selectCustomerRow(customerId))
          }
        }}
        tabIndex={-1}
        key={customerId}
        selected={isSelected}>
        <TableCell>{name}</TableCell>
        <TableCell>
          <ListItem disablePadding>
            <ListItemText
              sx={{ fontSize: '0.875rem' }}
              primary={<Typography fontSize='0.875rem'>{environment.name}</Typography>}
              secondary={environment.displayName}
            />
          </ListItem>
        </TableCell>
        <TableCell>{productForm}</TableCell>
        <TableCell>{integration ? renderIntegration(integration) : ''}</TableCell>
        <TableCell>{images ? renderImages(images) : ''}</TableCell>
        <TableCell>{tables ? renderTables(tables) : ''}</TableCell>
      </TableRow>
    </Fragment>
  )
}

export default CustomersTableRow
