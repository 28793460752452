import LinearProgress from '@mui/material/LinearProgress'
import React from 'react'
import { reduxForm, InjectedFormProps } from 'redux-form'
import ErrorMessage from '../../../components/Errors/ErrorMessage'
import TemplateFormFieldsContainer from './TemplateFormFieldsContainer'

type TemplateFormProps = InjectedFormProps

const TemplateForm: React.FC<TemplateFormProps> = ({ submitting, error, form }) => (
  <div style={{ margin: 16 }}>
    <form>
      {submitting && <LinearProgress />}
      <TemplateFormFieldsContainer formName={form} />
      <ErrorMessage message={error} />
    </form>
  </div>
)

export default reduxForm({
  form: 'TemplateForm',
  enableReinitialize: true,
  updateUnregisteredFields: true,
  keepDirtyOnReinitialize: true
})(TemplateForm)
