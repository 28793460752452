import { FileWithPreview } from '../types/media'

const downloadFile = async (url: string, filename: string) => {
  const response = await fetch(url)
  return {
    filename,
    blob: await response.blob()
  }
}

const getFilesWithPreview = (files: File[]) =>
  files.map(file => Object.assign(file, { preview: URL.createObjectURL(file) }) as FileWithPreview)

export { downloadFile, getFilesWithPreview }
