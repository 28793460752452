import React, { useEffect, useState, Fragment } from 'react'
import {
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Chip,
  LinearProgress
} from '@mui/material'
import { TFunction } from 'i18next'
import { connect } from 'react-redux'
import { Channel } from '@seesignage/seesignage-utils'
import Api from '../../../services/api/channels'
import { CloseDialog } from '../../../types/actions'
import { StateInterface } from '../../../types/states'
import { selectPlaylistIsDeleting } from '../../../selectors/playlists'
import { isStringArray } from '../../../types/arrays'

interface OwnProps {
  environmentId: string
  closeDialog: CloseDialog
  deletePlaylist: (playlistId: string) => void
  playlistId: string
  t: TFunction
}

interface StateProps {
  playlistIsDeleting: boolean
}

type DeleteConfirmationProps = OwnProps & StateProps

const DeleteConfirmation: React.FC<DeleteConfirmationProps> = ({
  environmentId,
  playlistId,
  closeDialog,
  deletePlaylist,
  playlistIsDeleting,
  t
}) => {
  const [channels, setChannels] = useState<Channel[] | string[]>([])
  const [channelsLoading, setChannelsLoading] = useState(true)
  useEffect(() => {
    const getChannelsByPlaylistId = async () => {
      const responseChannels = await Api.listChannelsBy(environmentId, playlistId, 'name')
      setChannels(responseChannels)
      setChannelsLoading(false)
    }
    getChannelsByPlaylistId()
  }, [environmentId, playlistId])
  return (
    <Fragment>
      <DialogContent>
        {playlistIsDeleting && <LinearProgress />}
        <Typography display='block' variant='body1'>
          {t('playlists.actions.dialogText')}
        </Typography>

        {channels.length > 0 && isStringArray(channels) && (
          <Fragment>
            <Typography variant='body1' color='secondary'>
              {t('playlists.actions.deletedFromChannels')}
            </Typography>
            {channels.map(channel => (
              <Chip
                style={{ margin: 4 }}
                key={channel}
                label={channel}
                size='small'
                color='primary'
              />
            ))}
          </Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeDialog(`removePlaylistDialog.${playlistId}`)} color='primary'>
          {t('general.cancel')}
        </Button>
        <Button
          disabled={channelsLoading || playlistIsDeleting}
          onClick={() => deletePlaylist(playlistId)}
          color='primary'
          autoFocus>
          {t('general.delete')}
        </Button>
      </DialogActions>
    </Fragment>
  )
}

const mapStateToProps = (state: StateInterface): StateProps => ({
  playlistIsDeleting: selectPlaylistIsDeleting(state)
})

export default connect(mapStateToProps)(DeleteConfirmation)
