import { API } from 'aws-amplify'
import { ListUI, MiscListItemRequest, MiscListUI } from '@seesignage/seesignage-utils'
import {
  CreateLunchGroupBody,
  CreateLunchProductBody,
  UpdateLunchListItemsBody,
  LunchList
} from '@seesignage/seesignage-utils/lib/types/lunchLists'
import { CreateListRequestBody } from '../../types/lists'

const getLists = async (environmentId: string) => {
  const lists: ListUI[] = await API.get('lists', `/environments/${environmentId}/lists`, {})
  return lists
}

const getList = async (environmentId: string, listId: string) => {
  const list: ListUI = await API.get('lists', `/environments/${environmentId}/lists/${listId}`, {})
  return list
}

const createList = async (environmentId: string, list: CreateListRequestBody) => {
  const newList: ListUI = await API.post('lists', `/environments/${environmentId}/lists`, {
    body: list
  })
  return newList
}

const updateList = async (environmentId: string, listId: string, list: CreateListRequestBody) => {
  const updatedAttributes = await API.put(
    'lists',
    `/environments/${environmentId}/lists/${listId}`,
    { body: list }
  )
  return updatedAttributes
}

const deleteList = async (environmentId: string, listId: string) => {
  await API.del('lists', `/environments/${environmentId}/lists/${listId}`, {})
}

const updateListItems = async (environmentId: string, listId: string, items: any[]) => {
  const newItems = await API.put('lists', `/environments/${environmentId}/lists/${listId}/items`, {
    body: { items }
  })
  return newItems
}

const updateMiscListItems = async (
  environmentId: string,
  listId: string,
  items: MiscListItemRequest[]
) => {
  const miscListUI: MiscListUI = await API.put(
    'lists',
    `/environments/${environmentId}/lists/${listId}/miscItems`,
    {
      body: { items }
    }
  )
  return miscListUI
}

const createLunchListGroup = async (
  environmentId: string,
  listId: string,
  group: CreateLunchGroupBody
) => {
  const newGroup = await API.post(
    'lists',
    `/environments/${environmentId}/lists/${listId}/groups`,
    {
      body: group
    }
  )
  return newGroup
}

const updateLunchListGroup = async (
  environmentId: string,
  listId: string,
  groupId: string,
  group: CreateLunchGroupBody
) => {
  const updatedGroup = await API.put(
    'lists',
    `/environments/${environmentId}/lists/${listId}/groups/${groupId}`,
    {
      body: group
    }
  )
  return updatedGroup
}

const createLunchListProduct = async (
  environmentId: string,
  listId: string,
  product: CreateLunchProductBody
) => {
  const newProduct = await API.post(
    'lists',
    `/environments/${environmentId}/lists/${listId}/products`,
    {
      body: product
    }
  )
  return newProduct
}

const updateLunchListProduct = async (
  environmentId: string,
  listId: string,
  productId: string,
  product: CreateLunchProductBody
) => {
  const updatedProduct = await API.put(
    'lists',
    `/environments/${environmentId}/lists/${listId}/products/${productId}`,
    {
      body: product
    }
  )
  return updatedProduct
}

const deleteLunchListGroup = async (environmentId: string, listId: string, groupId: string) => {
  const list: LunchList = await API.del(
    'lists',
    `/environments/${environmentId}/lists/${listId}/groups/${groupId}`,
    {}
  )
  return list
}

const deleteLunchListProduct = async (environmentId: string, listId: string, productId: string) => {
  const list: LunchList = await API.del(
    'lists',
    `/environments/${environmentId}/lists/${listId}/products/${productId}`,
    {}
  )
  return list
}

const updateLunchListItems = async (
  environmentId: string,
  listId: string,
  lunchListItemsBody: UpdateLunchListItemsBody
) => {
  const updatedList: LunchList = await API.put(
    'lists',
    `/environments/${environmentId}/lists/${listId}/lunchItems`,
    {
      body: lunchListItemsBody
    }
  )
  return updatedList
}

const initializeSubLunchList = async (
  subEnvironmentId: string,
  parentListId: string,
  parentEnvironmentId: string
) => {
  const newList: LunchList = await API.put(
    'lists',
    `/environments/${subEnvironmentId}/lists/${parentListId}/initializeSubList`,
    {
      body: {
        parentEnvironmentId
      }
    }
  )
  return newList
}

const copyList = async (environmentId: string, listId: string) => {
  const list = await API.post('lists', `/environments/${environmentId}/lists/${listId}`, {})
  return list
}

export default {
  getLists,
  getList,
  createList,
  updateList,
  deleteList,
  updateListItems,
  updateMiscListItems,
  updateLunchListItems,
  createLunchListGroup,
  updateLunchListGroup,
  deleteLunchListGroup,
  createLunchListProduct,
  updateLunchListProduct,
  deleteLunchListProduct,
  initializeSubLunchList,
  copyList
}
