import React from 'react'
import { TFunction } from 'i18next'
import { Typography } from '@mui/material'
import { WrappedFieldProps, Field } from 'redux-form'
import SimpleExtensionPanel from '../../../../../../../components/ExtensionPanels/SimpleExtensionPanel'
import StylesFields from './StylesFields'

interface NameFieldProps extends WrappedFieldProps {
  t: TFunction
  rowNumber: number
}

const NameFieldComponent: React.FC<NameFieldProps> = ({ input, t, rowNumber }) => {
  return (
    <Typography>
      {t('contents.widgets.table.fieldTitle', { name: input.value, rowNumber })}
    </Typography>
  )
}

const NameField = ({
  item,
  t,
  rowNumber
}: {
  /** Name of field */
  item: string
  t: TFunction
  rowNumber: number
}) => <Field name={`${item}.name`} component={NameFieldComponent} t={t} rowNumber={rowNumber} />

interface TableStylesFieldsProps {
  /** Name of field */
  item: string
  rowNumber: number
  t: TFunction
  remove: () => void
  isFieldsDisabled: boolean
}

const TableFieldStyles: React.FC<TableStylesFieldsProps> = ({
  t,
  item,
  rowNumber,
  isFieldsDisabled
}) => {
  return (
    <SimpleExtensionPanel
      TitleComponent={<NameField t={t} item={item} rowNumber={rowNumber} />}
      isOpen={false}>
      <StylesFields item={item} t={t} isFieldsDisabled={isFieldsDisabled} />
    </SimpleExtensionPanel>
  )
}

export default TableFieldStyles
