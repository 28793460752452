import { MenuItem, TextField, TextFieldProps } from '@mui/material'
import React, { useState } from 'react'
import { WrappedFieldProps } from 'redux-form'
import TooltipSpan from '../../Tooltip/TooltipSpan'

interface ReduxSelectBaseProps extends Omit<ReduxSelectProps, 'tooltip'> {
  setTooltipVisibility?: React.Dispatch<React.SetStateAction<boolean>>
}

const ReduxSelectBase = ({
  options,
  input,
  setTooltipVisibility,
  ...props
}: ReduxSelectBaseProps) => {
  const tooltipProps =
    typeof setTooltipVisibility === 'function'
      ? {
          onMouseEnter: () => setTooltipVisibility(true),
          onMouseLeave: () => setTooltipVisibility(false),
          SelectProps: { MenuProps: { onMouseEnter: () => setTooltipVisibility(false) } }
        }
      : {}
  return (
    <TextField select {...props} {...input} {...tooltipProps}>
      {options.map(({ label, value, ...rest }) => (
        <MenuItem key={label} value={value} {...rest}>
          {label}
        </MenuItem>
      ))}
    </TextField>
  )
}

interface ReduxSelectProps extends WrappedFieldProps, Omit<TextFieldProps, 'input'> {
  options: { label: string; value: any }[]
  tooltip?: string
}

const ReduxSelect = ({ tooltip, ...props }: ReduxSelectProps) => {
  const [isTooltipVisible, setTooltipVisibility] = useState(false)
  return tooltip ? (
    <TooltipSpan title={tooltip} open={isTooltipVisible}>
      <ReduxSelectBase {...props} setTooltipVisibility={setTooltipVisibility} />
    </TooltipSpan>
  ) : (
    <ReduxSelectBase {...props} />
  )
}

export default ReduxSelect
