import { Typography, Grid } from '@mui/material'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import { TFunction } from 'i18next'
import { ReduxCheckbox } from '../../../components/FormInput/ReduxWrappers'
import { CloseDialog } from '../../../types/actions'
import DialogActions from '../../../components/DialogActions'
import { LunchListWeekFormDays } from '../../../types/lists'

const getWeekdays = (t: TFunction) => [
  {
    label: t('lists.lunch.weekdays.mon'),
    name: 'mon'
  },
  {
    label: t('lists.lunch.weekdays.tue'),
    name: 'tue'
  },
  {
    label: t('lists.lunch.weekdays.wed'),
    name: 'wed'
  },
  {
    label: t('lists.lunch.weekdays.thu'),
    name: 'thu'
  },
  {
    label: t('lists.lunch.weekdays.fri'),
    name: 'fri'
  },
  {
    label: t('lists.lunch.weekdays.sat'),
    name: 'sat'
  },
  {
    label: t('lists.lunch.weekdays.sun'),
    name: 'sun'
  }
]

interface OwnProps {
  submitAction: (formData: any) => void
  closeDialog: CloseDialog
  dialogId: string
}

interface FormProps {
  weekdays?: LunchListWeekFormDays
}

type CreateLunchWeekFormProps = OwnProps & WithTranslation

const CreateLunchWeekForm: React.FC<CreateLunchWeekFormProps &
  InjectedFormProps<FormProps, OwnProps>> = ({
  error,
  handleSubmit,
  submitting,
  submitAction,
  closeDialog,
  dialogId,
  t
}) => (
  <form onSubmit={handleSubmit(submitAction)}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='subtitle1'>{t('lists.lunch.weekdays.title')}</Typography>
      </Grid>
      {getWeekdays(t).map(({ label, name }) => (
        <Grid item xs={3} key={name}>
          <Field name={`weekdays.${name}`} label={label} component={ReduxCheckbox} />
        </Grid>
      ))}
    </Grid>
    <Typography variant='body1' color='error'>
      {error || ''}
    </Typography>
    <DialogActions
      dialogId={dialogId}
      submitting={submitting}
      closeDialog={closeDialog}
      submitButtonLabel={t('general.create')}
    />
  </form>
)

export default reduxForm<FormProps, OwnProps>({
  form: 'CreateLunchWeekForm'
})(withTranslation()(CreateLunchWeekForm))
