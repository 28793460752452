import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Tooltip from '@mui/material/Tooltip'
import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'

interface ColumnData {
  id: string
  disablePadding: boolean
  label: string
  sortable?: boolean
}

interface EnhancedTableHeadProps extends WithTranslation {
  columnData: ColumnData[]
  orderBy: string
  order: 'asc' | 'desc'
  handleSortRequest: (id: string) => any
}

const EnhancedTableHead: React.FC<EnhancedTableHeadProps> = ({
  columnData,
  orderBy,
  order,
  handleSortRequest,
  t
}) => (
  <TableHead>
    <TableRow>
      {columnData.map(({ id, disablePadding, label, sortable }) => {
        return (
          <TableCell
            key={id}
            padding={disablePadding ? 'none' : 'normal'}
            style={id === 'actions' ? { width: 150 } : undefined}>
            {sortable ? (
              <Tooltip disableInteractive title='Sort' placement='bottom-start' enterDelay={300}>
                <TableSortLabel
                  active={orderBy === id}
                  direction={order}
                  onClick={() => handleSortRequest(id)}>
                  {t(label)}
                </TableSortLabel>
              </Tooltip>
            ) : (
              t(label)
            )}
          </TableCell>
        )
      })}
    </TableRow>
  </TableHead>
)

export default withTranslation()(EnhancedTableHead)
