import { API } from 'aws-amplify'
import { toast } from 'react-toastify'
import { EMMiPreviewFolder, EmmiErrorCode } from '@seesignage/seesignage-utils'
import i18n from '../../translations/i18n'
import {
  ImportFolderResponse,
  EMMiFolderReport,
  EMMiFolderReportError
} from '../../types/integrations'

const getEMMiFolders = async (environmentId: string, customerId: string) => {
  try {
    const folders: EMMiPreviewFolder[] = await API.get(
      'integrations',
      `/environments/${environmentId}/customers/${customerId}/emmi/preview`,
      {}
    )
    return folders
  } catch (error) {
    return undefined
  }
}

const getEMMiFolderById = async (environmentId: string, customerId: string, folderId: string) => {
  try {
    const report: EMMiFolderReport = await API.get(
      'integrations',
      `/environments/${environmentId}/customers/${customerId}/emmi/preview`,
      {
        queryStringParameters: {
          folderId
        }
      }
    )
    return report
  } catch (error) {
    const errorResponse: EMMiFolderReportError = {
      ...error?.response?.data,
      isError: true
    }
    return errorResponse
  }
}

const importEMMiFolder = async (environmentId: string, customerId: string, folderId: string) => {
  try {
    const response = await API.get(
      'integrations',
      `/environments/${environmentId}/customers/${customerId}/emmi/folders/${folderId}`,
      {}
    )
    response.success = true
    return response as ImportFolderResponse
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data as ImportFolderResponse
    }
    const errorResponse: ImportFolderResponse = {
      success: false,
      message: error.message,
      errorCode: EmmiErrorCode.importFolderFailed
    }
    return errorResponse
  }
}

const updateIntegrationScreenData = async (environmentId: string, customerId: string) => {
  try {
    const response = await API.put(
      'integrations',
      `/environments/${environmentId}/customers/${customerId}/updateScreenData`,
      {}
    )
    toast.success(i18n.t('integrations.api.screenDataUpdateSuccess'))
    return response
  } catch (error) {
    toast.error(i18n.t('integrations.api.screenDataUpdateFail'))
    return undefined
  }
}

export default {
  getEMMiFolders,
  getEMMiFolderById,
  importEMMiFolder,
  updateIntegrationScreenData
}
