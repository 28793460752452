import { useState, useEffect } from 'react'

/**
 * Custom hook to debounce value for given delay time
 * @param value
 * @param delay
 */
const useDebounce = (value: string, delay: number) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    // Set debouncedValue to value (passed in) after the specified delay
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value]) // eslint-disable-line

  return debouncedValue
}

export default useDebounce
