import { handleActions } from 'redux-actions'
import { IntegrationIoTData } from '@seesignage/seesignage-utils'
import { ConnectionState } from '@aws-amplify/pubsub'
import { IotState } from '../types/states'
import {
  setIntegrationIotData,
  resetIntegrationIotData,
  setIotConnectionState
} from '../actions/iot'
import { Action } from '../types/actions'

const initialState: IotState = {
  iotData: undefined,
  connectionState: undefined
}

const iotReducer = handleActions<IotState, any>(
  {
    [setIotConnectionState]: (state, { payload: connectionState }: Action<ConnectionState>) => {
      return {
        ...state,
        connectionState
      }
    },
    [setIntegrationIotData]: (state, { payload: iotData }: Action<IntegrationIoTData>) => {
      return {
        ...state,
        iotData
      }
    },
    [resetIntegrationIotData]: state => {
      return {
        ...state,
        iotData: undefined
      }
    }
  },
  initialState
)

export default iotReducer
