import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { WifiNetwork as WifiNetworkInterface } from '@seesignage/seesignage-utils'

interface WifiNetworkProps extends WithTranslation {
  wifiNetwork?: WifiNetworkInterface
}

const WifiNetwork: React.FC<WifiNetworkProps> = ({ wifiNetwork, t }) => {
  if (!wifiNetwork) {
    return (
      <Paper>
        <Typography variant='body1'>{t('network.notAvailable')}</Typography>
      </Paper>
    )
  }

  const {
    dns,
    ipAddress,
    macAddress,
    securityMode,
    signalStrength,
    ssid,
    subnetMask,
    encryptionType,
    status
  } = wifiNetwork
  return (
    <Paper>
      <Typography variant='h6' style={{ padding: 12 }}>
        WiFi {t('network.information')}
      </Typography>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>WiFi</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>DNS</TableCell>
            <TableCell>{dns}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>IP {t('network.address')}</TableCell>
            <TableCell>{ipAddress}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>MAC {t('network.address')}</TableCell>
            <TableCell>{macAddress}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t('wifi.secMode')}</TableCell>
            <TableCell>
              {securityMode} ({encryptionType})
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t('wifi.sigStrength')}</TableCell>
            <TableCell>{signalStrength}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>SSID</TableCell>
            <TableCell>{ssid}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t('network.subnet')}</TableCell>
            <TableCell>{subnetMask}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell style={status === 'ON' ? { color: 'green' } : { color: 'red' }}>
              {status}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  )
}

export default withTranslation()(WifiNetwork)
