import {
  Button,
  DialogActions,
  Grid,
  InputAdornment,
  LinearProgress,
  Typography,
  MenuItem,
  Paper
} from '@mui/material'
import React, { Fragment } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Field, reduxForm, InjectedFormProps, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import {
  formatDecimalsToCurrency,
  PackageUnit,
  parseCurrencyStringToNumber,
  parseStringToNumber
} from '@seesignage/seesignage-utils'
import { isPriceValid, required } from '../../../../validation'
import { ReduxTextField, ReduxSwitch } from '../../../../components/FormInput/ReduxWrappers'
import { StateInterface } from '../../../../types/states'
import { selectComparisonUnitsFromPackageUnit } from '../../../../selectors/products'
import { parseNumberField } from '../../../../utils/forms'

const packageUnits = ['', ...Object.values(PackageUnit)]
interface OwnProps {
  isMobile: boolean
  previousPage: () => void
  submitButtonLabel: string
}

interface StateProps {
  discountSelected?: boolean
  packageUnit?: PackageUnit
}

type ComponentProps = OwnProps & StateProps

type AddProductDetailsProps = ComponentProps & WithTranslation

const AddProductDetails: React.FC<AddProductDetailsProps &
  InjectedFormProps<{}, ComponentProps>> = ({
  isMobile,
  handleSubmit,
  previousPage,
  submitting,
  error,
  submitButtonLabel,
  discountSelected,
  packageUnit,
  t
}) => (
  <form onSubmit={handleSubmit}>
    <Paper
      variant='outlined'
      style={
        isMobile
          ? { height: '40vh', overflowX: 'visible', overflowY: 'auto', padding: 16 }
          : { padding: 16 }
      }>
      {submitting && <LinearProgress />}
      <Grid container spacing={2} style={{ width: '100%' }}>
        <Grid item xs={12}>
          <Field
            fullWidth
            name='name'
            multiline
            maxRows='4'
            label={t('general.name')}
            component={ReduxTextField}
            required
            validate={[required]}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            fullWidth
            multiline
            maxRows='4'
            name='description'
            label={t('product.description')}
            component={ReduxTextField}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4}>
              <Field
                fullWidth
                name='price'
                label={t('product.sellingPrice')}
                component={ReduxTextField}
                validate={[isPriceValid]}
                type='number'
                InputProps={{
                  inputProps: { min: 0, max: 100000, step: 0.01 },
                  endAdornment: <InputAdornment position='end'>€</InputAdornment>
                }}
                format={formatDecimalsToCurrency}
                parse={parseCurrencyStringToNumber}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <Field
                fullWidth
                name='unit'
                label={t('product.sellingUnit')}
                component={ReduxTextField}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <Field
                fullWidth
                name='deposit'
                label={t('product.deposit')}
                helperText={t('product.depositHelper')}
                component={ReduxTextField}
                validate={[isPriceValid]}
                type='number'
                InputProps={{
                  inputProps: { min: 0, max: 100000, step: 0.01 },
                  endAdornment: <InputAdornment position='end'>€</InputAdornment>
                }}
                format={formatDecimalsToCurrency}
                parse={parseCurrencyStringToNumber}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Field
            fullWidth
            name='packageSize'
            label={t('product.packageSize')}
            component={ReduxTextField}
            type='number'
            InputProps={{
              inputProps: { min: 0, max: 100000, step: 0.001 }
            }}
            parse={parseStringToNumber}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <Field
            fullWidth
            name='packageUnit'
            label={t('product.packageUnit')}
            component={ReduxTextField}
            select>
            {packageUnits.map(value => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Field
            fullWidth
            name='comparisonUnit'
            label={t('product.comparisonUnit')}
            component={ReduxTextField}
            helperText={t('product.comparisonUnitHelper')}
            select>
            {selectComparisonUnitsFromPackageUnit(packageUnits as PackageUnit[], packageUnit).map(
              value => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              )
            )}
          </Field>
        </Grid>
        <Grid item xs={12}>
          <Field name='discount' component={ReduxSwitch} label={t('product.discount')} />
        </Grid>
        {discountSelected && (
          <Fragment>
            <Grid item xs={6}>
              <Field
                fullWidth
                name='discountPrice'
                label={t('product.discountPrice')}
                component={ReduxTextField}
                required
                validate={[isPriceValid, required]}
                type='number'
                InputProps={{
                  inputProps: { min: 0, max: 100000, step: 0.01 },
                  endAdornment: <InputAdornment position='end'>€</InputAdornment>
                }}
                format={formatDecimalsToCurrency}
                parse={parseCurrencyStringToNumber}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                fullWidth
                name='discountBatchSize'
                label={t('product.discountBatch')}
                component={ReduxTextField}
                type='number'
                parse={parseNumberField}
                InputProps={{
                  inputProps: { min: 0, max: 100000, step: 1 }
                }}
                helperText={t('product.discountBatchHelper')}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                fullWidth
                name='discountValidString'
                label={t('product.discountValid')}
                component={ReduxTextField}
                helperText={t('product.discountValidHelper')}
              />
            </Grid>
          </Fragment>
        )}
      </Grid>
    </Paper>
    <Typography variant='body1' color='error'>
      {error || ''}
    </Typography>
    <DialogActions style={isMobile ? { justifyContent: 'center' } : undefined}>
      <Button onClick={previousPage} color='primary'>
        {t('general.back')}
      </Button>
      <Button disabled={submitting} color='primary' type='submit'>
        {submitButtonLabel}
      </Button>
    </DialogActions>
  </form>
)

const mapStateToProps = (state: StateInterface): StateProps => ({
  discountSelected: formValueSelector('AddProductWizardForm')(state, 'discount'),
  packageUnit: formValueSelector('AddProductWizardForm')(state, 'packageUnit')
})

export default connect<StateProps, {}, OwnProps, StateInterface>(mapStateToProps)(
  reduxForm<{}, ComponentProps>({
    form: 'AddProductWizardForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmitSuccess: (_result, _dispatch, props) => {
      if (props.destroy) {
        props.destroy()
      }
    }
  })(withTranslation()(AddProductDetails))
)
