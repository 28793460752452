import { Button, DialogActions, Grid, Typography } from '@mui/material'
import React from 'react'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import { TFunction } from 'i18next'
import { AutocompleteOption } from '@seesignage/seesignage-utils'
import { required } from '../../../../validation'
import { ReduxSelectWithAutocomplete } from '../../../../components/FormInput/ReduxWrappers'
import { PageType } from '../../../../types/playlists'
import SelectTemplate from '../../../../components/FormInput/SelectTemplate'
import { StateTemplate } from '../../../../types/templates'

interface OwnProps {
  isMobile: boolean
  backFunction?: (page: PageType) => void
  onSubmit: (formData: any) => void
  nextPage: () => void
  submitButtonLabel: string
  customers?: AutocompleteOption[]
  templates?: StateTemplate[]
  customerDisabled?: boolean
  isEditProduct?: boolean
  t: TFunction
}

const SelectCustomerAndTemplate: React.FC<OwnProps & InjectedFormProps<{}, OwnProps>> = ({
  isMobile,
  handleSubmit,
  onSubmit,
  nextPage,
  backFunction,
  submitting,
  error,
  invalid,
  submitButtonLabel,
  customers,
  templates,
  customerDisabled,
  t
}) => {
  const isSubmitDisabled = submitting || invalid
  return (
    <form>
      <Grid style={{ padding: 10 }} container spacing={2}>
        <Grid item xs={12}>
          <Field
            name='customerId'
            label={t('product.selectCustomer')}
            options={customers}
            disabled={customerDisabled}
            component={ReduxSelectWithAutocomplete}
            required
            validate={[required]}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name='template'
            initialTemplates={templates}
            component={SelectTemplate}
            required
            validate={[required]}
          />
        </Grid>
      </Grid>
      <Typography variant='body1' color='error'>
        {error || ''}
      </Typography>
      <DialogActions style={isMobile ? { justifyContent: 'center' } : undefined}>
        {backFunction ? (
          <Button onClick={() => backFunction(PageType.productType)} color='primary'>
            {t('general.back')}
          </Button>
        ) : (
          <Button onClick={handleSubmit(onSubmit)} disabled={isSubmitDisabled} color='primary'>
            {t('general.update')}
          </Button>
        )}
        <Button onClick={handleSubmit(nextPage)} color='primary' disabled={isSubmitDisabled}>
          {submitButtonLabel}
        </Button>
      </DialogActions>
    </form>
  )
}

export default reduxForm<{}, OwnProps>({
  form: 'AddProductWizardForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(SelectCustomerAndTemplate)
