import React, { Fragment } from 'react'
import { MdiReactIconProps } from 'mdi-react'
import { ChipProps } from '@mui/material/Chip'
import TruncatedChip from '.'

interface TagsProps {
  icon?: React.ComponentType<MdiReactIconProps>
  color?: ChipProps['color']
  tags?: string[] | null
}
const Tags = ({ icon, color, tags }: TagsProps) => (
  <Fragment>
    {tags &&
      tags.map((tag, i) => (
        <TruncatedChip key={`${tag}-${i}`} tag={tag} Icon={icon} color={color} truncateLimit={30} />
      ))}
  </Fragment>
)

export default Tags
