import { LunchItemForPlayer } from '@seesignage/seesignage-utils'

const lunchItemsMock: LunchItemForPlayer[] = [
  {
    itemId: '599590a5',
    productId: '222d9ede',
    name: 'Kalaa maustepippurikastikkeessa',
    specialDiet: {
      lactoseFree: true,
      dairyFree: true
    },
    group: {
      name: 'Kotiruokaa'
    }
  },
  {
    itemId: 'ef8ff864',
    productId: '074fdcb9',
    name: 'Falafel tomaattikeitto',
    group: {
      name: 'Makuja maailmalta'
    }
  },
  {
    itemId: 'a999212f',
    productId: '9305bb7b',
    name: 'Jambalaya',
    group: {
      name: 'Makuja maailmalta'
    }
  },
  {
    itemId: 'ed1eac5b',
    productId: '51b47103',
    name: 'Kasvis-kikherne -pyttipannu',
    group: {
      name: 'Makuja maailmalta'
    }
  }
]

export { lunchItemsMock }
