import React, { Fragment } from 'react'
import TableColumnIcon from 'mdi-react/TableColumnIcon'
import CalendarMultipleIcon from 'mdi-react/CalendarMultipleIcon'
import PlaylistPlayIcon from 'mdi-react/PlaylistPlayIcon'
import ExclamationIcon from 'mdi-react/ExclamationIcon'
import { Tooltip, Typography } from '@mui/material'
import { MdiReactIconProps } from 'mdi-react'
import { PlaylistUI, Channel, ListUI, ScreenChannel } from '@seesignage/seesignage-utils'
import { TFunction } from 'i18next'
import colors from '../../../styles/common/colors'

const textStyle = {
  position: 'relative' as 'relative',
  top: '-6px',
  marginLeft: 4,
  fontFamily: 'Roboto',
  fontSize: 14
}

interface ContentProps {
  Icon: React.ComponentType<MdiReactIconProps>
  /** is content inherited from parent */
  isParent?: boolean
  name: string
  secondaryTitle?: string
  title?: string
}

const Content = ({ Icon, isParent, name, title, secondaryTitle }: ContentProps) => (
  <div>
    {title ? (
      <Tooltip disableInteractive title={title}>
        <div>
          <Icon color={isParent ? colors.parentLunchListColor : undefined} />
          <span style={textStyle}>{name}</span>
          {secondaryTitle && (
            <Typography variant='caption' display='block'>
              {secondaryTitle}
            </Typography>
          )}
        </div>
      </Tooltip>
    ) : (
      <div>
        <Icon /> <span style={textStyle}>{name}</span>
        {secondaryTitle && (
          <Typography variant='caption' display='block'>
            {secondaryTitle}
          </Typography>
        )}
      </div>
    )}
  </div>
)

interface ScreenCardContentProps {
  environmentId: string
  playlist?: PlaylistUI
  channel?: Channel
  channelIds?: ScreenChannel[] | null
  list?: ListUI
  t: TFunction
}

const ScreenCardContent = ({
  environmentId,
  channel,
  channelIds,
  playlist,
  list,
  t
}: ScreenCardContentProps) => (
  <Fragment>
    {channel && (
      <Content
        Icon={CalendarMultipleIcon}
        isParent={environmentId !== channel.environmentId}
        name={channel.name}
        secondaryTitle={
          channelIds && channelIds.length > 1
            ? t('calendar.more', { count: channelIds.length - 1 })
            : undefined
        }
        title={
          environmentId === channel.environmentId
            ? t('screens.content.channel')
            : `${t('screens.content.channel')}. ${t('general.isParentItem')}`
        }
      />
    )}
    {playlist && (
      <Content
        isParent={environmentId !== playlist.environmentId}
        Icon={PlaylistPlayIcon}
        name={playlist.name}
        title={
          environmentId === playlist.environmentId
            ? t('screens.content.playlist')
            : `${t('screens.content.playlist')} (${t('general.isParentItem')})`
        }
      />
    )}
    {list && <Content Icon={TableColumnIcon} name={list.name} title={t('screens.content.list')} />}
    {!channel && !playlist && !list && (
      <Content Icon={ExclamationIcon} name={t('screens.content.noContent')} />
    )}
  </Fragment>
)

export default ScreenCardContent
