import React, { Fragment } from 'react'
import { FieldArrayFieldsProps, WrappedFieldArrayProps } from 'redux-form'
import { Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { MainTableField } from '@seesignage/seesignage-utils'
import SubFields from './SubFields'

const useStyles = makeStyles(theme => ({
  item: {
    backgroundColor: '#fafafa',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  itemsContainer: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    overflow: 'auto'
  }
}))

interface MainTableFieldsProps extends WrappedFieldArrayProps {
  fields: FieldArrayFieldsProps<MainTableField>
  isFieldsDisabled: boolean
}

const MainTableFields: React.FC<MainTableFieldsProps> = ({ fields, isFieldsDisabled }) => {
  const classes = useStyles()
  return (
    <Fragment>
      {fields.length > 0 && (
        <div className={classes.itemsContainer} id='campaign-items'>
          {fields.map((item, index) => {
            const itemProps = {
              item,
              index,
              removeItem: () => fields.remove(index),
              isFieldsDisabled
            }
            return (
              <Paper className={classes.item} key={`${item}.${index}`}>
                <SubFields {...itemProps} />
              </Paper>
            )
          })}
        </div>
      )}
    </Fragment>
  )
}

export default MainTableFields
