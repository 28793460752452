import { createSelector } from 'reselect'
import { StateInterface } from '../types/states'

const selectAnalyticsDomain = (state: StateInterface) => state.analytics
const selectIsQueryProofOfPlayLoading = createSelector(
  selectAnalyticsDomain,
  analytics => analytics?.isQueryProofOfPlayLoading
)
const selectIsGetProofOfPlayOptionsLoading = createSelector(
  selectAnalyticsDomain,
  analytics => analytics?.isGetProofOfPlayOptionsLoading
)
const selectProofOfPlay = createSelector(selectAnalyticsDomain, analytics => analytics.proofOfPlay)
const selectProofOfPlayItems = createSelector(selectProofOfPlay, proofOfPlay => proofOfPlay?.items)
const selectProofOfPlayQuery = createSelector(selectProofOfPlay, proofOfPlay => proofOfPlay?.query)
const selectProofOfPlayOptions = createSelector(
  selectProofOfPlay,
  proofOfPlay => proofOfPlay?.options
)

export {
  selectProofOfPlay,
  selectIsQueryProofOfPlayLoading,
  selectIsGetProofOfPlayOptionsLoading,
  selectProofOfPlayItems,
  selectProofOfPlayQuery,
  selectProofOfPlayOptions
}
