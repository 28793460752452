import React from 'react'
import { useTranslation } from 'react-i18next'
import { Breadcrumbs as MuiBreadcrumbs, Link, Typography, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import NavigateNextIcon from 'mdi-react/NavigateNextIcon'
import HomeIcon from 'mdi-react/HomeIcon'
import MediaIcon from 'mdi-react/FolderMultipleImageIcon'
import InfopagesIcon from 'mdi-react/BookInformationVariantIcon'
import MonitorsIcon from 'mdi-react/MonitorMultipleIcon'
import { useDispatch, useSelector } from 'react-redux'
import PlaylistPlayIcon from 'mdi-react/PlaylistPlayIcon'
import CalendarMultipleIcon from 'mdi-react/CalendarMultipleIcon'
import ShoppingIcon from 'mdi-react/ShoppingIcon'
import ChartLineIcon from 'mdi-react/ChartLineIcon'
import PaletteAdvancedIcon from 'mdi-react/PaletteAdvancedIcon'
import AccountIcon from 'mdi-react/AccountIcon'
import SettingsIcon from 'mdi-react/SettingsIcon'
import CloudIcon from 'mdi-react/CloudIcon'
import TableColumnIcon from 'mdi-react/TableColumnIcon'
import AccessPointIcon from 'mdi-react/AccessPointIcon'
import { StateInterface } from '../../types/states'
import { navigate as navigateAction } from '../../actions/routes'
import {
  selectEnvironmentDisplayName,
  selectSelectedEnvironmentId
} from '../../selectors/environments'
import { selectEnvironmentIdFromPathname } from '../../selectors/routing'
import { BreadcrumbContentType } from '../../types/breadcrumbs'
import { selectIsUserDeviceMobile } from '../../selectors/users'

const getBreadcrumbIcon = (type: BreadcrumbContentType, className: string) => {
  switch (type) {
    case BreadcrumbContentType.home:
      return <HomeIcon className={className} />
    case BreadcrumbContentType.playlists:
      return <PlaylistPlayIcon className={className} />
    case BreadcrumbContentType.lists:
      return <TableColumnIcon className={className} />
    case BreadcrumbContentType.media:
      return <MediaIcon className={className} />
    case BreadcrumbContentType.infopages:
      return <InfopagesIcon className={className} />
    case BreadcrumbContentType.screens:
      return <MonitorsIcon className={className} />
    case BreadcrumbContentType.channels:
      return <CalendarMultipleIcon className={className} />
    case BreadcrumbContentType.products:
      return <ShoppingIcon className={className} />
    case BreadcrumbContentType.templates:
      return <PaletteAdvancedIcon className={className} />
    case BreadcrumbContentType.profile:
      return <AccountIcon className={className} />
    case BreadcrumbContentType.environments:
      return <SettingsIcon className={className} />
    case BreadcrumbContentType.integrations:
      return <CloudIcon className={className} />
    case BreadcrumbContentType.connections:
      return <AccessPointIcon className={className} />
    case BreadcrumbContentType.analytics:
      return <ChartLineIcon className={className} />
    default:
      return undefined
  }
}

const styles = (theme: Theme) => ({
  link: {
    display: 'flex',
    cursor: 'pointer' as any
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20
  },
  root: {
    padding: 8
  },
  rootMobile: {
    padding: 10
  }
})

const useStyles = makeStyles(styles)

interface BreadcrumbsProps {
  contentType?: BreadcrumbContentType
  contentId?: string
  contentName?: string
  additionalBreadcrumbs?: JSX.Element[]
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  contentType,
  contentId,
  contentName,
  additionalBreadcrumbs
}) => {
  const [t] = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = (path: string) => dispatch(navigateAction(path))
  const environmentId = useSelector(
    (state: StateInterface) =>
      selectEnvironmentIdFromPathname(state) || selectSelectedEnvironmentId(state)
  )
  const environmentDisplayName = useSelector(selectEnvironmentDisplayName(environmentId))
  const isMobile = useSelector(selectIsUserDeviceMobile)
  return (
    <MuiBreadcrumbs
      maxItems={4}
      className={isMobile ? classes.rootMobile : classes.root}
      separator={<NavigateNextIcon fontSize='small' />}
      aria-label='breadcrumb'>
      <Link className={classes.link} onClick={() => navigate(`/home`)}>
        {getBreadcrumbIcon(BreadcrumbContentType.home, classes.icon)}
        {environmentDisplayName || t('home.home')}
      </Link>
      {contentType &&
        (contentId ? (
          <Link
            onClick={() => navigate(`/environments/${environmentId}/${contentType}`)}
            className={classes.link}>
            {getBreadcrumbIcon(contentType, classes.icon)}
            {t(`navigation.${contentType}`)}
          </Link>
        ) : (
          <div style={{ display: 'flex' }}>
            {getBreadcrumbIcon(contentType, classes.icon)}
            <Typography style={{ textTransform: 'capitalize' }}>
              {t(`navigation.${contentType}`)}
            </Typography>
          </div>
        ))}
      {contentId && !additionalBreadcrumbs && <Typography>{contentName || contentId}</Typography>}
      {additionalBreadcrumbs}
    </MuiBreadcrumbs>
  )
}

export default Breadcrumbs
