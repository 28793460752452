/**
 * Format url to use autoplay features, if YouTube embed url.
 * Used as redux form format function.
 * @example
 * const url = 'https://www.youtube.com/embed/vYud9sZ91Mc'
 * parseUrl(url)
 * // => 'https://www.youtube.com/embed/vYud9sZ91Mc?autoplay=1&controls=0'
 * @example
 * const url = 'https://www.yle.fi'
 * parseUrl(url) 'https://www.yle.fi'
 * // => 'https://www.yle.fi'
 * @param url
 */
const parseUrl = (url?: string) => {
  if (url?.startsWith('https://www.youtube.com/embed')) {
    const queryParams = `?autoplay=1&controls=0`
    return `${url}${queryParams}`
  }
  return url === undefined ? '' : url
}

/**
 * Return youtube url without queryparams
 * * @example
 * const url = 'https://www.youtube.com/embed/vYud9sZ91Mc?autoplay=1&controls=0'
 * formatUrl(url)
 * // => 'https://www.youtube.com/embed/vYud9sZ91Mc'
 * @param url
 */
const formatUrl = (url?: string) => {
  if (url?.startsWith('https://www.youtube.com/embed')) {
    return url.split('?')[0]
  }
  return url === undefined ? '' : url
}

export { formatUrl, parseUrl }
