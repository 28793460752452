import { Button, DialogActions, Grid, Theme, Typography } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import TableColumnIcon from 'mdi-react/TableColumnIcon'
import { ListUI, AutocompleteOption } from '@seesignage/seesignage-utils'
import { getLists } from '../../../../actions/lists'
import { selectListsAsArray } from '../../../../selectors/lists'
import { required } from '../../../../validation'
import { ReduxSelectWithAutocomplete } from '../../../../components/FormInput/ReduxWrappers'

import { StateInterface } from '../../../../types/states'
import { PageType } from '../../../../types/playlists'
import { compareStrings } from '../../../../utils/sorting'

interface OwnProps {
  onSubmit: (formData: any) => void
  selectPage: (page: PageType) => void
}

interface StateProps {
  options: ListUI[]
}

interface DispatchProps {
  getLists: () => void
}

interface FormProps {
  list: AutocompleteOption
  type: string
}

const styles = (theme: Theme) => ({
  formContainer: {
    marginBottom: theme.spacing()
  },
  selectProductContainer: {
    marginTop: theme.spacing(2)
  }
})

type AddListAsItemFormProps = OwnProps &
  StateProps &
  DispatchProps &
  WithStyles<typeof styles> &
  WithTranslation

class AddListAsItemForm extends React.Component<
  InjectedFormProps<FormProps, OwnProps & StateProps> & AddListAsItemFormProps
> {
  public componentDidMount() {
    this.props.getLists()
  }

  public render() {
    const {
      classes,
      selectPage,
      handleSubmit,
      onSubmit,
      submitting,
      options,
      error,
      t
    } = this.props
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} className={classes.formContainer}>
          <Grid item xs={12} sm={12}>
            <Field
              name='list'
              placeholder={t('lists.select')}
              options={options.map(({ listId, name }) => ({
                value: listId,
                label: name
              }))}
              OptionIcon={TableColumnIcon}
              component={ReduxSelectWithAutocomplete}
              required
              validate={[required]}
            />
          </Grid>
          <Typography variant='body1' color='error'>
            {error || ''}
          </Typography>
        </Grid>
        <DialogActions>
          <Button onClick={() => selectPage(PageType.selectType)} color='primary'>
            {t('general.back')}
          </Button>
          <Button disabled={submitting} color='primary' type='submit'>
            {t('general.add')}
          </Button>
        </DialogActions>
      </form>
    )
  }
}

const mapStateToProps = (state: StateInterface): StateProps => ({
  options: selectListsAsArray(state).sort(compareStrings('name'))
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  getLists: () => dispatch(getLists())
})

export default connect<StateProps, DispatchProps, OwnProps, StateInterface>(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<FormProps, OwnProps & StateProps & DispatchProps>({
    form: 'AddListAsItemForm'
  })(withTranslation()(withStyles(styles)(AddListAsItemForm)) as any)
)
