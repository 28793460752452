import {
  AutocompleteOption,
  CarouselItemPlayer,
  FabricWidgetObjectFit,
  isCarouselMediaItemPlayer,
  isCarouselPlaylistItemPlayer,
  isCarouselContentItemPlayer,
  FalconyAnnouncementsProps
} from '@seesignage/seesignage-utils'
import {
  ICircleOptions,
  IImageOptions,
  IRectOptions,
  ITextboxOptions,
  ITriangleOptions
} from 'fabric/fabric-impl'
import { change } from 'redux-form'
import {
  FabricDateObject,
  FabricFalconyAnnouncementsObject,
  FabricIframeObject,
  FabricLineObject,
  FabricMediaCarouselObject,
  FabricPathObject,
  FabricPolygonObject,
  FabricQRCodeObject,
  FabricRssFeedObject,
  FabricSocialMediaObject,
  FabricStopScheduleObject,
  FabricTableObject,
  FabricVideoObject,
  FabricWeatherObject,
  isFabricMediaCarouselObject,
  isFabricWorkareaObject
} from '@seesignage/seesignage-player-utils'
import { getStore } from '../../configureStore'
import {
  CanvasBackgroundFormData,
  ContentCircleFormData,
  ContentIframeFormData,
  ContentImageFormData,
  ContentRectangleFormData,
  ContentTextBoxFormData,
  ContentTriangleFormData,
  ContentVideoFormData,
  ContentStopScheduleFormData,
  ContentRssFeedFormData,
  StopPropsFormData,
  ContentTableFormData,
  ContentDateWidgetFormData,
  ContentWeatherWidgetFormData,
  WeatherPropsFormData,
  ContentQRCodeWidgetFormData,
  MediaCarouselMediaWizardFormData,
  MediaCarouselWizardFormData,
  MediaCarouselPlaylistWizardFormData,
  SocialMediaWidgetSettings,
  ContentPathFormData,
  MediaCarouselRevolver,
  MediaCarouselRevolverInfopageItem,
  MediaCarouselRevolverMediaItem,
  ContentMediaCarouselSettingsFormData,
  ContentMediaCarouselToolbarFormData,
  ContentSocialMediaWidgetSettingsFormData,
  ContentSocialMediaWidgetToolbarFormData,
  ContentLineFormData,
  CommonPolygonFields
} from '../../types/contents'
import { getContentResolutionType } from './resolution'

const generateTextBoxInitialValues = ({
  fontSize,
  fontFamily,
  textAlign,
  underline,
  linethrough,
  fontWeight,
  fontStyle,
  fill,
  left,
  top,
  lineHeight,
  charSpacing,
  angle,
  scaleX,
  scaleY,
  lockMovementX,
  lockMovementY
}: ITextboxOptions): ContentTextBoxFormData => {
  return {
    fontSize: { value: fontSize, label: `${fontSize}` } || { value: 40, label: '40' },
    fontFamily,
    textAlign,
    underline,
    linethrough,
    fontWeight,
    fontStyle,
    fill,
    left,
    top,
    lineHeight,
    charSpacing,
    angle,
    scaleX,
    scaleY,
    lockMovementX,
    lockMovementY
  }
}

const generateImageInitialValues = ({
  width,
  height,
  left,
  top,
  angle,
  scaleX,
  scaleY,
  lockMovementX,
  lockMovementY
}: IImageOptions): ContentImageFormData => {
  return {
    width,
    height,
    left,
    top,
    angle,
    scaleX,
    scaleY,
    lockMovementX,
    lockMovementY
  }
}

const generateVideoInitialValues = (obj: FabricVideoObject): ContentVideoFormData => {
  const {
    left,
    top,
    angle,
    scaleX,
    scaleY,
    customOptions: { widgetProps: cVideoProps },
    lockMovementX,
    lockMovementY
  } = obj
  return {
    left,
    top,
    angle,
    scaleX,
    scaleY,
    lockMovementX,
    lockMovementY,
    cVideoProps
  }
}

const generateIframeInitialValues = (obj: FabricIframeObject): ContentIframeFormData => {
  const {
    width,
    height,
    left,
    top,
    angle,
    scaleX,
    scaleY,
    customOptions: { widgetProps: cIframeProps },
    lockMovementX,
    lockMovementY
  } = obj
  return {
    width,
    height,
    left,
    top,
    angle,
    scaleX,
    scaleY,
    cIframeProps,
    lockMovementX,
    lockMovementY
  }
}

const generateStopScheduleInitialValues = (
  obj: FabricStopScheduleObject
): ContentStopScheduleFormData => {
  const {
    width,
    height,
    left,
    top,
    angle,
    scaleX,
    scaleY,
    customOptions: { widgetProps: cStopProps },
    lockMovementX,
    lockMovementY
  } = obj
  const { numberOfDepartures, ...cStopPropsTemp } = cStopProps
  const cStopPropsFormData: StopPropsFormData = {
    ...cStopPropsTemp
  }

  cStopPropsFormData.numberOfDepartures = numberOfDepartures
    ? {
        value: numberOfDepartures,
        label: `${numberOfDepartures}`
      }
    : { value: 10, label: '10' } // some default value for numberOfDepartures

  return {
    width,
    height,
    left,
    top,
    angle,
    scaleX,
    scaleY,
    lockMovementX,
    lockMovementY,
    cStopProps: cStopPropsFormData
  }
}

const generateRssFeedInitialValues = (obj: FabricRssFeedObject): ContentRssFeedFormData => {
  const {
    width,
    height,
    left,
    top,
    angle,
    scaleX,
    scaleY,
    lockMovementX,
    lockMovementY,
    customOptions: { widgetProps: cRssFeedProps }
  } = obj
  return {
    width,
    height,
    left,
    top,
    angle,
    scaleX,
    scaleY,
    lockMovementX,
    lockMovementY,
    cRssFeedProps
  }
}

const generateMediaCarouselWizardInitialValues = (
  obj: any,
  isUpdateMediaCarousel: boolean | undefined
): MediaCarouselWizardFormData => {
  if (isUpdateMediaCarousel && isFabricMediaCarouselObject(obj)) {
    // updating media carousel
    const {
      customOptions: { widgetProps: cMediaCarouselProps }
    } = obj
    if (isCarouselMediaItemPlayer(cMediaCarouselProps)) {
      const { carouselItems } = cMediaCarouselProps
      const formData: MediaCarouselMediaWizardFormData = {
        carouselItems: convertCarouselItemsToMediaItems(carouselItems)
      }
      return formData
    } else if (isCarouselPlaylistItemPlayer(cMediaCarouselProps)) {
      // playlist media carousel
      const { carouselItems, playlistId, environmentId, defaultInterval } = cMediaCarouselProps
      const formData: MediaCarouselPlaylistWizardFormData = {
        playlistId: {
          label: '',
          value: playlistId
        },
        environmentId,
        defaultInterval,
        carouselItems
      }
      return formData
    }
  }
  // creating new media carousel and default intial values
  return {
    carouselItems: []
  }
}

const generateMediaCarouselToolbarInitialValues = (
  obj: FabricMediaCarouselObject
): ContentMediaCarouselToolbarFormData => {
  // updating media carousel
  const { lockMovementX, lockMovementY, width = 1, height = 1, top, left } = obj
  const formData = {
    top,
    left,
    lockMovementX,
    lockMovementY,
    width,
    height,
    contentResolution: getContentResolutionType({ width, height })
  }
  return formData
}

const generateMediaCarouselSettingsInitialValues = (
  obj: FabricMediaCarouselObject
): ContentMediaCarouselSettingsFormData => {
  const {
    customOptions: { widgetProps }
  } = obj
  const { background, videosMuted, objectFit } = widgetProps
  if (isCarouselMediaItemPlayer(widgetProps)) {
    // updating media carousel
    const { interval } = widgetProps
    const formData: ContentMediaCarouselSettingsFormData = {
      background,
      videosMuted,
      objectFit,
      interval
    }
    return formData
  } else {
    // updating media playlist carousel
    const formData: ContentMediaCarouselSettingsFormData = {
      background,
      videosMuted,
      objectFit
    }
    return formData
  }
}

const generateTableInitialValues = (obj: FabricTableObject): ContentTableFormData => {
  const {
    width,
    height,
    left,
    top,
    angle,
    scaleX,
    scaleY,
    customOptions: { widgetProps: cTableProps },
    lockMovementX,
    lockMovementY
  } = obj
  return {
    width,
    height,
    left,
    top,
    angle,
    scaleX,
    scaleY,
    cTableProps,
    lockMovementX,
    lockMovementY
  }
}

const generateDateWidgetInitialValues = (obj: FabricDateObject): ContentDateWidgetFormData => {
  const {
    width,
    height,
    left,
    top,
    angle,
    scaleX,
    scaleY,
    customOptions: { widgetProps: cDateProps },
    lockMovementX,
    lockMovementY
  } = obj
  return {
    width,
    height,
    left,
    top,
    angle,
    scaleX,
    scaleY,
    cDateProps,
    lockMovementX,
    lockMovementY
  }
}

const generateWeatherWidgetInitialValues = (
  obj: FabricWeatherObject
): ContentWeatherWidgetFormData => {
  const {
    width,
    height,
    left,
    top,
    angle,
    scaleX,
    scaleY,
    customOptions: { widgetProps: cWeatherProps },
    lockMovementX,
    lockMovementY
  } = obj
  const cWeatherPropsForm: WeatherPropsFormData = {
    ...cWeatherProps,
    ...(cWeatherProps.location // conver location to Autocomplete option
      ? {
          location: {
            value: cWeatherProps.location.id,
            label: cWeatherProps.location.label
          } as AutocompleteOption
        }
      : { location: undefined })
  }
  return {
    width,
    height,
    left,
    top,
    angle,
    scaleX,
    scaleY,
    lockMovementX,
    lockMovementY,
    cWeatherProps: cWeatherPropsForm
  }
}

const generateRectangleInitialValues = ({
  fill,
  width,
  height,
  left,
  top,
  angle,
  scaleX,
  scaleY,
  lockMovementX,
  lockMovementY
}: IRectOptions): ContentRectangleFormData => ({
  fill,
  width,
  height,
  left,
  top,
  angle,
  scaleX,
  scaleY,
  lockMovementX,
  lockMovementY
})

const generatePathInitialValues = ({
  stroke,
  width,
  height,
  left,
  top,
  angle,
  scaleX,
  scaleY,
  lockMovementX,
  lockMovementY
}: FabricPathObject): ContentPathFormData => ({
  stroke,
  width,
  height,
  left,
  top,
  angle,
  scaleX,
  scaleY,
  lockMovementX,
  lockMovementY
})

const generateTriangleInitialValues = ({
  fill,
  width,
  height,
  left,
  top,
  angle,
  scaleX,
  scaleY,
  lockMovementX,
  lockMovementY
}: ITriangleOptions): ContentTriangleFormData => {
  return {
    fill,
    width,
    height,
    left,
    top,
    angle,
    scaleX,
    scaleY,
    lockMovementX,
    lockMovementY
  }
}

const generateCircleInitialValues = ({
  fill,
  radius,
  left,
  top,
  angle,
  scaleX,
  scaleY,
  lockMovementX,
  lockMovementY
}: ICircleOptions) => {
  const formData: ContentCircleFormData = {
    fill,
    radius,
    left,
    top,
    angle,
    scaleX,
    scaleY,
    lockMovementX,
    lockMovementY
  }
  return formData
}

const generateLineDrawingFormInitialValues = ({
  fill,
  stroke,
  width,
  left,
  top,
  angle,
  scaleX,
  scaleY,
  lockMovementX,
  lockMovementY,
  strokeWidth
}: FabricLineObject): ContentLineFormData => {
  const formData: any = {
    fill,
    left,
    top,
    angle,
    scaleX,
    scaleY,
    width,
    stroke,
    strokeWidth,
    lockMovementX,
    lockMovementY
  }
  return formData
}
const generatePolygonInitialValues = (obj: FabricPolygonObject) => {
  const {
    fill,
    stroke,
    width,
    left,
    top,
    angle,
    scaleX,
    scaleY,
    lockMovementX,
    lockMovementY,
    strokeWidth,
    customOptions: { pointEditMode }
  } = obj
  const formData: any = {
    fill,
    left,
    top,
    angle,
    scaleX,
    scaleY,
    width,
    stroke,
    strokeWidth,
    lockMovementX,
    lockMovementY,
    pointEditMode
  }
  return formData
}

const generateSocialMediaToolbarInitialValues = (
  obj: FabricSocialMediaObject
): ContentSocialMediaWidgetToolbarFormData => {
  const { width = 1, height = 1, left, top, lockMovementX, lockMovementY } = obj

  return {
    width,
    height,
    left,
    top,
    lockMovementX,
    lockMovementY,
    contentResolution: getContentResolutionType({ width, height })
  }
}

const generateSocialMediaSettingsInitialValues = (
  obj: FabricSocialMediaObject
): ContentSocialMediaWidgetSettingsFormData => {
  const {
    customOptions: { widgetProps }
  } = obj

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mediaConfig, mediaItems, mediaProfile, ...formData } = widgetProps

  return formData
}

const generateQRCodeInitialValues = (obj: FabricQRCodeObject): ContentQRCodeWidgetFormData => {
  const {
    width,
    height,
    left,
    top,
    angle,
    scaleX,
    scaleY,
    customOptions: { widgetProps: cQRCodeProps },
    lockMovementX,
    lockMovementY
  } = obj

  return {
    width,
    height,
    left,
    top,
    angle,
    scaleX,
    scaleY,
    QRCodeScale: scaleX || 1,
    lockMovementX,
    lockMovementY,
    cQRCodeProps
  }
}

const generateFalconyAnnouncementsToolbarInitialValues = (
  obj: FabricFalconyAnnouncementsObject
): CommonPolygonFields => {
  const { width, height, left, top, scaleX, scaleY, lockMovementX, lockMovementY } = obj

  return {
    width,
    height,
    left,
    top,
    scaleX,
    scaleY,
    lockMovementX,
    lockMovementY
  }
}

const generateFalconyAnnouncementsSettingsInitialValues = (
  obj: FabricFalconyAnnouncementsObject
): FalconyAnnouncementsProps => {
  const {
    customOptions: { widgetProps }
  } = obj

  return widgetProps
}

const generateBackgroundInitialValues = (): CanvasBackgroundFormData => {
  const workarea = window.workarea
  if (isFabricWorkareaObject(workarea)) {
    return {
      backgroundColor: workarea.backgroundColor
    }
  }
  return {}
}

const clearCanvasBackgroundFormValues = () => {
  const store = getStore()
  store.dispatch(change('CanvasBackgroundForm', 'backgroundColor', null))
}

/** media carousel form required items in media format */
const convertCarouselItemsToMediaItems = (items: CarouselItemPlayer[]): MediaCarouselRevolver[] =>
  items.map(item => {
    if (isCarouselContentItemPlayer(item)) {
      const {
        contentId,
        environmentId,
        type,
        content: { name, fileMetadata }
      } = item
      const mediaItem: MediaCarouselRevolverInfopageItem = {
        id: contentId,
        environmentId,
        type,
        name,
        url: fileMetadata?.url || ''
      }
      return mediaItem
    } else {
      const {
        key,
        type,
        fileMetadata: { filename, size, url }
      } = item
      const mediaItem: MediaCarouselRevolverMediaItem = {
        id: key,
        type,
        name: filename,
        size,
        url
      }
      return mediaItem
    }
  })

const generateSocialMediaDefaultValues = () => ({
  [SocialMediaWidgetSettings.slideShowInterval]: 7000,
  [SocialMediaWidgetSettings.videosMuted]: true,
  [SocialMediaWidgetSettings.showCaption]: true,
  [SocialMediaWidgetSettings.showHeader]: true,
  [SocialMediaWidgetSettings.widgetObjectFit]: FabricWidgetObjectFit.contain,
  [SocialMediaWidgetSettings.widgetBackground]: 'rgba(0,0,0,1)',
  [SocialMediaWidgetSettings.widgetAspectRatio]: { x: 1, y: 1 }
})

const generateFreeDrawingSettingsInitialValues = () => ({
  width: window.canvas.freeDrawingBrush.width || 40,
  color: window.canvas.freeDrawingBrush.color || 'black'
})

export {
  generateTextBoxInitialValues,
  generateImageInitialValues,
  generateVideoInitialValues,
  generateIframeInitialValues,
  generateTableInitialValues,
  generateStopScheduleInitialValues,
  generateRectangleInitialValues,
  generateTriangleInitialValues,
  generateCircleInitialValues,
  generateBackgroundInitialValues,
  clearCanvasBackgroundFormValues,
  generateDateWidgetInitialValues,
  generateWeatherWidgetInitialValues,
  generateRssFeedInitialValues,
  generateMediaCarouselToolbarInitialValues,
  generateMediaCarouselWizardInitialValues,
  generateMediaCarouselSettingsInitialValues,
  convertCarouselItemsToMediaItems,
  generateQRCodeInitialValues,
  generateSocialMediaDefaultValues,
  generateSocialMediaToolbarInitialValues,
  generateSocialMediaSettingsInitialValues,
  generateFreeDrawingSettingsInitialValues,
  generatePolygonInitialValues,
  generateLineDrawingFormInitialValues,
  generatePathInitialValues,
  generateFalconyAnnouncementsToolbarInitialValues,
  generateFalconyAnnouncementsSettingsInitialValues
}
