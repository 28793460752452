import { createAsyncActions, createAction } from './utils'

const reducer = 'CONFIRMATIONS'

const {
  action: listConfirmations,
  actionSuccess: listConfirmationsSuccess,
  actionFail: listConfirmationsFail
} = createAsyncActions(reducer, 'LIST')

const createConfirmationsSuccess = createAction(reducer, 'CREATE_SUCCESS')

const {
  action: deleteConfirmation,
  actionSuccess: deleteConfirmationSuccess,
  actionFail: deleteConfirmationFail
} = createAsyncActions(reducer, 'DELETE')

export {
  listConfirmations,
  listConfirmationsSuccess,
  listConfirmationsFail,
  createConfirmationsSuccess,
  deleteConfirmation,
  deleteConfirmationSuccess,
  deleteConfirmationFail
}
