import React, { Fragment } from 'react'
import MonitorIcon from 'mdi-react/MonitorIcon'
import {
  AppBar,
  Button,
  Toolbar,
  Theme,
  Typography,
  IconButton,
  Tooltip,
  DialogContent,
  DialogContentText,
  DialogActions,
  LinearProgress
} from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import { withTranslation, WithTranslation } from 'react-i18next'
import {
  TemplateType,
  AdditionalPlayerItemProps,
  TemplateOrientation
} from '@seesignage/seesignage-utils'
import ShoppingIcon from 'mdi-react/ShoppingIcon'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { submit } from 'redux-form'
import DeleteIcon from 'mdi-react/DeleteIcon'
import { toolbarStyles } from '../../../styles/common'
import { SelectOrientation, SubmitForm, CloseDialog } from '../../../types/actions'
import Dialog from '../../Dialog'
import TemplateMockDataForm from '../Forms/Mocks/TemplateMockDataForm'
import { StateInterface } from '../../../types/states'
import {
  selectMockItems,
  selectMockProductType,
  selectMockAdditionalProps
} from '../../../selectors/mocks'
import { MockProductType, MockItems } from '../../../types/mocks'
import {
  selectTemplateOrientation,
  getTemplate,
  deleteChildTemplate
} from '../../../actions/templates'
import { closeDialog } from '../../../actions/dialogs'
import { selectIsChildTemplateDeleting } from '../../../selectors/templates'

const styles = (theme: Theme) => ({
  ...toolbarStyles(theme),
  formControl: {
    width: 170,
    marginRight: 10,
    paddingBottom: 5,
    float: 'left' as 'left'
  },
  buttonContainer: {
    float: 'right' as 'right',
    padding: 12
  },
  button: {
    marginRight: 8
  },
  monitorLanscape: {
    margin: 2
  },
  monitorPortrait: {
    margin: 2,
    transform: 'rotate(90deg)'
  },
  grow: {
    flexGrow: 1
  }
})

export interface EditTemplateToolbarOwnProps {
  selectedOrientation: TemplateOrientation
  userHasPermission?: boolean
  type: TemplateType
  formEnabled: boolean
  isDirty: boolean
  saveDisabled?: boolean
  showDeleteChildTemplate: boolean
  productCount?: number
}

interface StateProps {
  childTemplateIsDeleting: boolean
  mockProductType: MockProductType
  mockAdditionalProps?: AdditionalPlayerItemProps
  mockItems: MockItems
}

interface DispatchProps {
  selectOrientation: SelectOrientation
  cancelChanges: () => void
  submitForm: SubmitForm
  closeDialog: CloseDialog
  deleteChildTemplate: () => void
}

type ComponentProps = EditTemplateToolbarOwnProps & StateProps & DispatchProps

type EditTemplateToolbarProps = ComponentProps & WithStyles<typeof styles> & WithTranslation

const EditTemplateToolbar = ({
  classes,
  t,
  selectOrientation,
  selectedOrientation,
  cancelChanges,
  submitForm,
  formEnabled,
  type,
  isDirty,
  userHasPermission,
  mockProductType,
  mockItems,
  mockAdditionalProps,
  saveDisabled,
  showDeleteChildTemplate,
  deleteChildTemplate,
  productCount,
  childTemplateIsDeleting
}: EditTemplateToolbarProps) => {
  return (
    <div className={classes.grow}>
      <AppBar className={classes.appBar} position='sticky' color='default'>
        <Toolbar>
          <Tooltip disableInteractive title={t('templates.changeOrientation')}>
            <IconButton
              onClick={() =>
                selectOrientation(
                  selectedOrientation === TemplateOrientation.landscape
                    ? TemplateOrientation.portrait
                    : TemplateOrientation.landscape
                )
              }
              size='large'>
              {selectedOrientation === 'portrait' ? (
                <MonitorIcon />
              ) : (
                <MonitorIcon className={classes.monitorPortrait} />
              )}
            </IconButton>
          </Tooltip>
          {(type === TemplateType.retail ||
            type === TemplateType.lunch ||
            type === TemplateType.fish ||
            type === TemplateType.meat) && (
            <Dialog
              dialogId='EditTemplateMockData'
              title={t('templates.addMockData')}
              tooltipTitle={t('templates.addMockData')}
              ButtonIcon={ShoppingIcon}
              maxWidth='md'
              Content={
                <TemplateMockDataForm
                  dialogId='EditTemplateMockData'
                  initialValues={{
                    ...mockItems,
                    currentMockProductType: mockProductType,
                    additionalProps: mockAdditionalProps
                  }}
                />
              }
            />
          )}
          {showDeleteChildTemplate && (
            <Dialog
              dialogId='DeleteChildTemplatedDialog'
              title={t('templates.editor.deleteChildTitle', {
                productCount
              })}
              tooltipTitle={t('templates.editor.deleteChildTitle', {
                productCount
              })}
              ButtonIcon={DeleteIcon}
              Content={
                <Fragment>
                  <DialogContent>
                    <DialogContentText>
                      {t('templates.editor.deleteChildConfirmation', {
                        productCount
                      })}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      disabled={childTemplateIsDeleting}
                      onClick={() => closeDialog('RemoveEnvironmentDialog')}
                      color='primary'>
                      {t('general.disagree')}
                    </Button>
                    <Button
                      disabled={childTemplateIsDeleting}
                      onClick={() => deleteChildTemplate()}
                      color='primary'
                      autoFocus>
                      {t('general.delete')}
                    </Button>
                  </DialogActions>
                  {childTemplateIsDeleting && <LinearProgress />}
                </Fragment>
              }
            />
          )}
          <div className={classes.grow} />
          {!userHasPermission && (
            <Typography variant='caption' color='primary' style={{ float: 'right', padding: 8 }}>
              {t('templates.noPermission')}
            </Typography>
          )}
          {userHasPermission && (
            <div className={classes.buttonContainer}>
              <Button
                className={classes.button}
                variant='contained'
                onClick={cancelChanges}
                disabled={!formEnabled || !isDirty}>
                {t('general.cancel')}
              </Button>
              <Button
                className={classes.button}
                disabled={!formEnabled || saveDisabled}
                variant='contained'
                color='primary'
                onClick={submitForm}>
                {t('templates.forms.save')}
              </Button>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  )
}

const mapStateToProps = (state: StateInterface): StateProps => ({
  mockItems: selectMockItems(state),
  mockProductType: selectMockProductType(state),
  mockAdditionalProps: selectMockAdditionalProps(state),
  childTemplateIsDeleting: selectIsChildTemplateDeleting(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  submitForm: () => dispatch(submit('TemplateForm')),
  selectOrientation: (orientation: string) => dispatch(selectTemplateOrientation(orientation)),
  cancelChanges: () => dispatch(getTemplate({})),
  closeDialog: (dialogId: string) => dispatch(closeDialog(dialogId)),
  deleteChildTemplate: () => dispatch(deleteChildTemplate())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(EditTemplateToolbar)))
