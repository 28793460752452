import {
  Content,
  ContentResolution,
  CreateContent,
  UpdateContent,
  Media,
  ContentPlaylistItemUI,
  PlaylistItemType,
  MediaType,
  QRCodeProps,
  CanvasSaveMode,
  UpdateContentCanvas,
  ObjectType,
  PlaylistResponseForContentMediaCarousel,
  PlaylistResponseType,
  isContentPlaylistItemPlayer,
  ContentUI
} from '@seesignage/seesignage-utils'
import { toast } from 'react-toastify'
import { path } from 'ramda'
import { SubmissionError } from 'redux-form'
import { all, put, select, takeLatest, call, takeEvery } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import {
  initFabricCanvas,
  isFabricQRCodeObject,
  isFabricWeatherObject
} from '@seesignage/seesignage-player-utils/lib'
import nanoid from 'nanoid'
import {
  createContent,
  createContentFail,
  createContentSuccess,
  createObject,
  createObjectFail,
  createObjectSuccess,
  deleteContent,
  deleteContentFail,
  deleteContentSuccess,
  getContent,
  getContentFail,
  getContentSuccess,
  initContentEditor,
  initContentEditorFail,
  listContents,
  listContentsFail,
  listContentsSuccess,
  updateContent,
  updateContentFail,
  updateContentSuccess,
  saveCanvas,
  saveCanvasFail,
  saveCanvasSuccess,
  addMediaContent,
  addMediaContentFail,
  addMediaContentSuccess,
  updateMediaSource,
  updateMediaSourceFail,
  updateMediaSourceSuccess,
  selectedObjectAction,
  selectedObjectActionSuccess,
  selectedObjectActionFail,
  downloadCanvas,
  downloadCanvasSuccess,
  downloadCanvasFail,
  canvasAction,
  canvasActionSuccess,
  canvasActionFail,
  setBackgroundImage,
  setBackgroundImageFail,
  setBackgroundImageSuccess,
  objectActionById,
  objectActionByIdSuccess,
  objectActionByIdFail,
  duplicateContent,
  duplicateContentSuccess,
  duplicateContentFail,
  copyContentToEnvironmentsSuccess,
  copyContentToEnvironmentsFail,
  copyContentToEnvironments,
  saveCanvasAsPlaylistItemFail,
  saveCanvasAsPlaylistItem,
  contentDrawerFormChange,
  contentDrawerFormChangeSuccess,
  contentDrawerFormChangeFail,
  updateSelectedWidget,
  updateSelectedWidgetSuccess,
  updateSelectedWidgetFail,
  setContentModified,
  setEditorCursorModeFail,
  setEditorCursorModeSuccess,
  setEditorCursorMode,
  contentToolFormChange,
  contentToolFormChangeSuccess,
  contentToolFormChangeFail,
  reorderObjectsFail,
  reorderObjectsSuccess,
  reorderObjects,
  zoomCanvas as zoomCanvasAction,
  zoomCanvasSuccess,
  zoomCanvasFail
} from '../actions/contents'
import {
  selectContentIdFromPathname,
  selectEnvironmentIdFromPathname,
  selectViewFromPathname
} from '../selectors/routing'
import {
  SelectedObjectActionType,
  CanvasActionType,
  MediaCarouselWizardFormData,
  CreateObjectActionPayload,
  EditorCursorMode,
  isMediaCarouselPlaylistFormData,
  EditorOptions,
  ZoomType,
  UpdateSocialMediaProps,
  isMediaCarouselMediaFormData
} from '../types/contents'

import {
  cloneActiveObjects,
  deleteActiveObjects,
  sendActiveObjectsForward,
  sendActiveObjectsBacwards,
  clearCanvas,
  setActiveObjectHorizontalAlign,
  setActiveObjectVerticalAlign,
  deleteObjectById,
  initSuccessCallback,
  addFabricObject,
  updateMediaSource as updateMediaSourceUtil,
  updateSelectedWidgetProps,
  countLimitedObjects,
  reorderObjectsUtil,
  getCanvasAsJSON
} from '../utils/fabric/canvas'
import Api from '../services/api/contents'
import i18n from '../translations/i18n'
import {
  HandleAddMediaContent,
  HandleUpdateMediaSource,
  handleCopyContentToEnvironmentsParams,
  handleCreateContentParams,
  HandleSetBackgroundImage,
  handleUpdateContentParams
} from '../types/formData'
import { closeDialog } from '../actions/dialogs'
import {
  Action,
  ObjectActionByIdProps,
  ContentDrawerFormChangePayload,
  ReorderObjectsParams
} from '../types/actions'
import Storage from '../services/api/storage'
import { selectMediaByKey } from '../selectors/media'
import { selectContentById, selectContentId } from '../selectors/contents'
import { downloadCanvasAsImage } from '../utils/fabric/canvasFileUtils'
import { clearWorkareaBackground } from '../utils/fabric/canvasWorkarea'
import { contentDeletionFail } from '../utils/message'
import { createDefaultFabricObject } from '../utils/fabric/canvasCreateObject'
import { deselectInfopage } from '../actions/infopages'
import PlaylistsApi from '../services/api/playlists'
import { createEmptyInfopageToPlaylist, addPlaylistItemSuccess } from '../actions/playlists'
import { SelectedInfopageType } from '../types/infopages'
import { selectUserSub } from '../selectors/users'
import { getLocationForScreen } from '../services/api/googlePlaces'
import { pauseActiveVideoObject, playActiveVideoObject } from '../utils/fabric/canvasObjectUtils'
import { setEditorCursorModeUtil } from '../utils/fabric/drawingUtils'
import {
  zoomCanvas,
  zoomCanvasToFitWorkarea,
  zoomCanvasToInitial
} from '../utils/fabric/canvasZoom'
import { getContentResolutionFromCreateInfopageContentFormData } from '../utils/forms'

function* handleCreateContent({
  payload: { formData, resolve, reject }
}: handleCreateContentParams) {
  try {
    const environmentId: string | undefined = yield select(selectEnvironmentIdFromPathname)
    if (environmentId) {
      const { name } = formData
      const contentData: CreateContent = {
        name,
        resolution: getContentResolutionFromCreateInfopageContentFormData(formData)
      }
      const content: Content = yield call(Api.createContent, environmentId, contentData)
      yield put(createContentSuccess(content))
      const currentView: string = yield select(selectViewFromPathname)

      if (currentView === 'infopages') {
        // navigate to content editor
        yield put(push(`/environments/${content.environmentId}/contents/${content.contentId}`))
        yield put(closeDialog())
      } else {
        yield put(
          createEmptyInfopageToPlaylist({
            type: SelectedInfopageType.content,
            id: content.contentId
          })
        )
      }
      resolve()
    } else {
      throw new Error('EnvironmentId missing')
    }
  } catch (error) {
    yield put(createContentFail(error.message))
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.content.somethingWrongCreate')
      })
    )
  }
}

function* handleUpdateContent({
  payload: { formData, resolve, reject }
}: handleUpdateContentParams) {
  try {
    const environmentId: string | undefined = yield select(selectEnvironmentIdFromPathname)
    if (environmentId) {
      const { name, contentId } = formData
      const contentData: UpdateContent = {
        name
      }
      const content: Content = yield call(Api.updateContent, environmentId, contentId, contentData)
      yield put(updateContentSuccess(content))
      yield put(closeDialog())
      resolve()
    }
  } catch (error) {
    yield put(updateContentFail(error.message))
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.content.somethingWrongUpdate')
      })
    )
  }
}

function* handleDeleteContent({
  payload: { environmentId, contentId }
}: {
  payload: {
    environmentId: string
    contentId: string
  }
}) {
  try {
    yield call(Api.deleteContent, environmentId, contentId)
    yield put(deselectInfopage(contentId))
    yield put(deleteContentSuccess(contentId))
  } catch (error) {
    const playlists: string[] | undefined = path(['response', 'data', 'playlists'], error)
    const contents: string[] | undefined = path(['response', 'data', 'contents'], error)
    const errorMessage = contentDeletionFail({ playlists, contents })
    if (errorMessage) {
      toast.error(errorMessage)
    }
    yield put(deleteContentFail(error.message))
  }
}

interface HandleListContentsParams {
  payload?: boolean
}

function* handleListContents({ payload: includeInUseData }: HandleListContentsParams) {
  try {
    const environmentId: string | undefined = yield select(selectEnvironmentIdFromPathname)
    if (environmentId) {
      const contents: Content[] = yield call(Api.getContents, environmentId, includeInUseData)
      yield put(listContentsSuccess(contents))
    }
  } catch (error) {
    yield put(listContentsFail(error.message))
  }
}

interface HandleGetContentParams {
  /** Payload contains contentId if called from EditPlaylist */
  payload?: string
}

function* handleGetContent({ payload }: HandleGetContentParams) {
  try {
    const environmentId: string | undefined = yield select(selectEnvironmentIdFromPathname)
    const pathContentId: string | undefined = yield select(selectContentIdFromPathname)
    const contentId = payload || pathContentId
    if (environmentId && contentId) {
      const content: Content = yield call(Api.getContent, environmentId, contentId)
      yield put(getContentSuccess(content))
      yield put(initContentEditor({ resolution: content.resolution, canvas: content.canvas }))
    } else {
      throw new Error('environmentId or contentId missing')
    }
  } catch (error) {
    yield put(getContentFail(error.message))
  }
}

interface HandleZoomCanvasParams {
  payload: ZoomType
}

function* handleZoomCanvas({ payload }: HandleZoomCanvasParams) {
  try {
    let zoom: number | undefined
    if (payload === ZoomType.in) {
      zoom = yield call(zoomCanvas, true)
    } else if (payload === ZoomType.out) {
      zoom = yield call(zoomCanvas, false)
    } else if (payload === ZoomType.fit) {
      zoom = yield call(zoomCanvasToFitWorkarea)
    } else {
      zoom = yield call(zoomCanvasToInitial)
    }
    if (zoom) {
      yield put(zoomCanvasSuccess({ zoom }))
    } else {
      throw new Error('Unable to zoom canvas')
    }
  } catch (error) {
    yield put(zoomCanvasFail())
  }
}

interface HandleSaveCanvasParams {
  /** Payload contains contentId if called from EditPlaylist */
  payload?: string
}

function* handleSaveCanvas({ payload }: HandleSaveCanvasParams) {
  try {
    const environmentId: string | undefined = yield select(selectEnvironmentIdFromPathname)
    const pathContentId: string | undefined = yield select(selectContentIdFromPathname)
    const contentId = payload || pathContentId
    if (environmentId && contentId) {
      const canvas = getCanvasAsJSON(CanvasSaveMode.toDB)
      yield call(Storage.uploadCurrentCanvasToS3, environmentId, contentId)
      const content: Content = yield call(Api.updateCanvas, environmentId, contentId, {
        canvas
      } as UpdateContentCanvas)
      yield put(saveCanvasSuccess(content))
      yield put(closeDialog())
    }
  } catch (error) {
    yield put(saveCanvasFail(error.message))
  }
}

function* handleSaveCanvasAsPlaylistItem({ payload: contentId }: { payload: string }) {
  try {
    const environmentId: string | undefined = yield select(selectEnvironmentIdFromPathname)
    if (environmentId && contentId) {
      const canvas = getCanvasAsJSON(CanvasSaveMode.toDB)
      yield call(Storage.uploadCurrentCanvasToS3, environmentId, contentId)
      const updatedContent: Content = yield call(Api.updateCanvas, environmentId, contentId, {
        canvas
      } as UpdateContentCanvas)
      yield put(saveCanvasSuccess(updatedContent))
      const content: ContentUI = yield select(selectContentById(contentId))
      const playlistItem: ContentPlaylistItemUI = {
        type: PlaylistItemType.content,
        itemId: nanoid(),
        content,
        contentId,
        userSub: yield select(selectUserSub)
      }
      yield put(addPlaylistItemSuccess(playlistItem))
      yield put(closeDialog())
    }
  } catch (error) {
    yield put(saveCanvasAsPlaylistItemFail(error.message))
  }
}

function* handleDownloadCanvas() {
  try {
    const contentId: string | undefined = yield select(selectContentIdFromPathname)
    if (contentId) {
      const content: Content = yield select(selectContentById(contentId))
      downloadCanvasAsImage(content.name)
      yield put(downloadCanvasSuccess())
    }
  } catch (error) {
    yield put(downloadCanvasFail(error.message))
  }
}

function* handleInitContentEditor({
  payload: { resolution, canvas }
}: Action<{ resolution: ContentResolution; canvas: any }>) {
  const options: EditorOptions = {
    resolution,
    cursorMode: EditorCursorMode.move
  }
  try {
    initFabricCanvas({
      resolution,
      canvas,
      onSuccessCallback: () => {
        initSuccessCallback(options)
      }
    })
    // Note:
    // initContentEditorSuccess action is dispatched in the callback function when loading canvas
  } catch (error) {
    yield put(initContentEditorFail(error.message))
  }
}

function* handleCreateObject({ payload }: { payload: CreateObjectActionPayload }) {
  const { type, props } = payload
  try {
    const transformFormData =
      type === ObjectType.CustomMediaCarousel
        ? yield call(getMediaCarouselData, props as MediaCarouselWizardFormData)
        : props
    const fabricObject = createDefaultFabricObject({
      type,
      props: transformFormData
    })
    addFabricObject(fabricObject)
    yield put(createObjectSuccess(fabricObject))
  } catch (error) {
    yield put(createObjectFail(error.message))
  }
}

/** Handle selected object(s) actions */
function* handleSelectedObjectAction({ payload: type }: { payload: SelectedObjectActionType }) {
  try {
    switch (type) {
      case SelectedObjectActionType.delete:
        deleteActiveObjects()
        break
      case SelectedObjectActionType.clone:
        cloneActiveObjects()
        break
      case SelectedObjectActionType.forward:
        sendActiveObjectsForward()
        break
      case SelectedObjectActionType.backward:
        sendActiveObjectsBacwards()
        break
      case SelectedObjectActionType.play:
        playActiveVideoObject()
        break
      case SelectedObjectActionType.pause:
        pauseActiveVideoObject()
        break
      case SelectedObjectActionType.horizontalCenter:
        setActiveObjectHorizontalAlign()
        break
      case SelectedObjectActionType.verticalCenter:
        setActiveObjectVerticalAlign()
        break
      default:
        break
    }
    yield put(selectedObjectActionSuccess())
  } catch (error) {
    yield put(selectedObjectActionFail(error.message))
  }
}

/** Handle full canvas related actions */
function* handleCanvasAction({ payload: type }: { payload: CanvasActionType }) {
  try {
    switch (type) {
      case CanvasActionType.clear:
        clearCanvas()
        const contentId: string | undefined = yield select(selectContentIdFromPathname)
        const { resolution }: Content = yield select(selectContentById(contentId))
        yield put(initContentEditor({ resolution, canvas: {} }))
        break
      case CanvasActionType.clearBackground:
        clearWorkareaBackground()
        break
      default:
        break
    }
    yield put(canvasActionSuccess())
  } catch (error) {
    yield put(canvasActionFail(error.message))
  }
}

function* handleObjectActionById({ payload: { action, cId } }: Action<ObjectActionByIdProps>) {
  try {
    switch (action) {
      case SelectedObjectActionType.delete:
        deleteObjectById(cId)
        break
      default:
        break
    }
    yield put(objectActionByIdSuccess())
  } catch (error) {
    yield put(objectActionByIdFail(error.message))
  }
}

const validateCanvasLimitedObjectCount = () => {
  // make sure that content does not have too many limited widgets or videos
  if (countLimitedObjects() >= 3) {
    throw new SubmissionError({
      _error: i18n.t('error.content.tooManyLimitedObjects')
    })
  }
}

function* validateAddMediaToContent(key: string, isUpdateMediaSource?: boolean) {
  const media: Media | undefined = yield select(selectMediaByKey(key))
  if (media) {
    if (media.type === MediaType.video && !isUpdateMediaSource) {
      validateCanvasLimitedObjectCount()
    }
    if (media.url) {
      return media
    } else {
      throw new Error('Url missing from media')
    }
  } else {
    throw new Error('Media not found from store')
  }
}

function* handleAddMediaContent({
  payload: {
    formData: { key },
    resolve,
    reject
  }
}: HandleAddMediaContent) {
  try {
    const media: Media = yield call(validateAddMediaToContent, key)
    yield put(addMediaContentSuccess(media))
    resolve()
  } catch (error) {
    yield put(addMediaContentFail())
    if (error instanceof SubmissionError) {
      yield call(reject, new SubmissionError({ _error: path(['errors', '_error'], error) }))
    } else {
      yield call(
        reject,
        new SubmissionError({
          _error: i18n.t('error.media.somethingWentWrong')
        })
      )
    }
  }
}

function* handleUpdateMediaSource({
  payload: {
    formData: { key },
    resolve,
    reject
  }
}: HandleUpdateMediaSource) {
  try {
    const media: Media = yield call(validateAddMediaToContent, key, true)
    yield call(updateMediaSourceUtil, media)
    yield put(updateMediaSourceSuccess(media))
    resolve()
  } catch (error) {
    yield put(updateMediaSourceFail())
    if (error instanceof SubmissionError) {
      yield call(reject, new SubmissionError({ _error: path(['errors', '_error'], error) }))
    } else {
      yield call(
        reject,
        new SubmissionError({
          _error: i18n.t('error.media.somethingWentWrong')
        })
      )
    }
  }
}

function* getMediaCarouselData(formData: MediaCarouselWizardFormData) {
  const contentId: string | undefined = yield select(selectContentId)
  if (isMediaCarouselPlaylistFormData(formData)) {
    const {
      playlistId: { value: playlistId },
      environmentId
    } = formData
    const playlist: PlaylistResponseForContentMediaCarousel = yield call(
      PlaylistsApi.getPlaylist,
      environmentId,
      playlistId,
      PlaylistResponseType.mediaCarousel
    )
    const content: Content = yield select(selectContentById(contentId))
    formData.carouselItems = playlist.items.filter(item => {
      if (isContentPlaylistItemPlayer(item) && item.contentId === contentId) {
        toast.warn(
          i18n.t('error.content.playlistInfiniteRecursiveItem', {
            playlistName: playlist.name,
            contentName: content?.name
          })
        )
        return false
      }
      return true
    })
    return formData
  } else if (isMediaCarouselMediaFormData(formData)) {
    const environmentId: string | undefined = yield select(selectEnvironmentIdFromPathname)
    if (environmentId) {
      formData.carouselItems = yield call(
        Api.convertMediaCarouselRevolverItemsToCarouselItemPlayer,
        formData.carouselItems,
        environmentId
      )
      return formData
    }
  }
  return formData
}

function* handleUpdateSelectedWidget({
  payload: { type, props }
}: {
  payload: { type: ObjectType; props: MediaCarouselWizardFormData | UpdateSocialMediaProps }
}) {
  try {
    if (type === ObjectType.CustomMediaCarousel) {
      const updatedFormData: MediaCarouselWizardFormData = yield call(
        getMediaCarouselData,
        props as MediaCarouselWizardFormData
      )
      yield call(updateSelectedWidgetProps, updatedFormData)
    } else {
      yield call(updateSelectedWidgetProps, props)
    }
    yield put(setContentModified())
    yield put(updateSelectedWidgetSuccess())
  } catch (error) {
    yield put(updateSelectedWidgetFail())
  }
}

function* handleSetBackgroundImage({
  payload: {
    formData: { key },
    resolve,
    reject
  }
}: HandleSetBackgroundImage) {
  try {
    const media: Media = yield call(validateAddMediaToContent, key)
    yield put(setBackgroundImageSuccess(media))
    resolve()
  } catch (error) {
    yield put(setBackgroundImageFail())
    reject(error)
  }
}

function* handleDuplicateContent({ payload: contentId }: { payload: string }) {
  try {
    const environmentId: string | undefined = yield select(selectEnvironmentIdFromPathname)
    if (environmentId) {
      const duplicate: Content = yield call(Api.duplicateContent, environmentId, contentId)
      yield put(duplicateContentSuccess(duplicate))
    }
  } catch (error) {
    toast.error(i18n.t('error.content.duplicateFailed'))
    yield put(duplicateContentFail())
  }
}

function* handleCopyContentToEnvironments({
  payload: { formData, resolve, reject }
}: handleCopyContentToEnvironmentsParams) {
  try {
    const environmentId: string | undefined = yield select(selectEnvironmentIdFromPathname)
    if (environmentId) {
      const { contentId, environments } = formData
      const environmentIds = environments.map(({ value }) => value)
      yield call(Api.copyContentToEnvironments, environmentId, contentId, environmentIds)
      toast.success(i18n.t('contents.actions.copyContentToEnvironmentsSuccess'))
      yield put(copyContentToEnvironmentsSuccess())
      yield put(closeDialog())
      resolve()
    }
  } catch (error) {
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.content.somethingWrongCopyToEnvironments')
      })
    )
  }
  yield put(copyContentToEnvironmentsFail())
}

function* handleContentDrawerFormChange({ payload }: Action<ContentDrawerFormChangePayload>) {
  try {
    if (payload.form === 'WeatherWidgetForm') {
      // if WeatherWidgetForm, we need to fetch location information
      if (payload.field === 'cWeatherProps.location') {
        // changing location field
        const location: Location = yield call(
          getLocationForScreen,
          payload.value.value,
          payload.value.label
        )
        payload.allValues.cWeatherProps.location = location
      } else {
        // use existing location when changing other fields
        const activeObject = window.canvas.getActiveObject()
        if (
          isFabricWeatherObject(activeObject) &&
          activeObject.customOptions.widgetProps.location
        ) {
          payload.allValues.cWeatherProps.location = activeObject.customOptions.widgetProps.location
        }
      }
    } else if (payload.form === 'QRCodeWidgetForm') {
      const activeObject = window.canvas.getActiveObject()
      const { field, value } = payload
      const attributeName = field.split('.').pop()
      if (attributeName && isFabricQRCodeObject(activeObject)) {
        const qrCodeProps: QRCodeProps = {
          ...activeObject.customOptions.widgetProps,
          [attributeName]: value
        }
        activeObject.setWidgetProps(qrCodeProps)
        window.canvas.requestRenderAll()
      }
    }
    yield put(contentDrawerFormChangeSuccess(payload))
  } catch (error) {
    yield put(contentDrawerFormChangeFail(error.message))
  }
}

function* handleSetEditorCursorMode({ payload: cursorMode }: { payload: EditorCursorMode }) {
  try {
    yield call(setEditorCursorModeUtil, cursorMode)
    yield put(setEditorCursorModeSuccess(cursorMode))
  } catch (error) {
    yield put(setEditorCursorModeFail())
  }
}

function* handleContentToolFormChange({ payload: { field, value, form } }: any) {
  try {
    if (form === 'FreeDrawingSettingsForm') {
      switch (field) {
        case 'width':
          window.canvas.freeDrawingBrush.width = value
          // update custom cursors width
          const cursors = window.temporayDrawingCanvas.getObjects() as fabric.Circle[]
          if (cursors) {
            cursors.forEach(cursor => {
              cursor
                .set({
                  radius: value / 2
                })
                .setCoords()
            })
            window.temporayDrawingCanvas.requestRenderAll()
          }

          break
        case 'color':
          window.canvas.freeDrawingBrush.color = value
          break
        default:
          break
      }
      yield put(contentToolFormChangeSuccess({ field, value, form }))
    } else if (form === 'PolygonDrawingSettingsForm') {
      // do nothing for now
    }
  } catch (error) {
    yield put(contentToolFormChangeFail())
  }
}

function* handleReorderObjects({ payload }: Action<ReorderObjectsParams>) {
  try {
    yield call(reorderObjectsUtil, payload)
    yield put(reorderObjectsSuccess())
  } catch (error) {
    yield put(reorderObjectsFail())
  }
}

function* watchEnvironmentsActions() {
  yield all([
    takeLatest(createContent, handleCreateContent),
    takeLatest(updateContent, handleUpdateContent),
    takeEvery(deleteContent, handleDeleteContent),
    takeLatest(listContents, handleListContents),
    takeLatest(getContent, handleGetContent),
    takeLatest(zoomCanvasAction, handleZoomCanvas),
    takeLatest(saveCanvas, handleSaveCanvas),
    takeLatest(saveCanvasAsPlaylistItem, handleSaveCanvasAsPlaylistItem),
    takeLatest(initContentEditor, handleInitContentEditor),
    takeLatest(createObject, handleCreateObject),
    takeLatest(selectedObjectAction, handleSelectedObjectAction),
    takeLatest(canvasAction, handleCanvasAction),
    takeLatest(contentDrawerFormChange, handleContentDrawerFormChange),
    takeLatest(objectActionById, handleObjectActionById),
    takeLatest(addMediaContent, handleAddMediaContent),
    takeLatest(updateMediaSource, handleUpdateMediaSource),
    takeLatest(setBackgroundImage, handleSetBackgroundImage),
    takeLatest(downloadCanvas, handleDownloadCanvas),
    takeLatest(duplicateContent, handleDuplicateContent),
    takeLatest(copyContentToEnvironments, handleCopyContentToEnvironments),
    takeLatest(updateSelectedWidget, handleUpdateSelectedWidget),
    takeLatest(setEditorCursorMode, handleSetEditorCursorMode),
    takeLatest(contentToolFormChange, handleContentToolFormChange),
    takeLatest(reorderObjects, handleReorderObjects)
  ])
}

export default [watchEnvironmentsActions]
