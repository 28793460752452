import React from 'react'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import { Button, DialogContent, DialogActions, Grid } from '@mui/material'
import { TFunction } from 'i18next'
import FieldContainer from '../../components/FieldContainer'
import { required } from '../../validation'
import { ReduxTextField } from '../../components/FormInput/ReduxWrappers'
import ErrorMessage from '../../components/Errors/ErrorMessage'
import { UpdateUserInfoFormData } from '../../types/users'

interface EditProfileInfoFormProps {
  updateUserInfo: (formData: any) => Promise<unknown>
  closeDialog: () => void
  t: TFunction
}

const EditProfileInfoForm: React.FC<EditProfileInfoFormProps &
  InjectedFormProps<UpdateUserInfoFormData, EditProfileInfoFormProps>> = ({
  closeDialog,
  handleSubmit,
  updateUserInfo,
  submitting,
  error,
  t
}) => {
  const handleSubmitFormData = (formData: UpdateUserInfoFormData) =>
    updateUserInfo(formData).finally(closeDialog)
  return (
    <form onSubmit={handleSubmit(handleSubmitFormData)}>
      <DialogContent>
        <Grid container rowGap={2}>
          <Grid item xs={12}>
            <FieldContainer>
              <Field
                fullWidth
                name='name'
                variant='outlined'
                label={t('editProfile.name')}
                component={ReduxTextField}
                required
                validate={[required]}
              />
            </FieldContainer>
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name='phone_number'
              variant='outlined'
              label={t('editProfile.phone')}
              helperText={t('editProfile.helperText')}
              component={ReduxTextField}
              required
              validate={[required]}
            />
          </Grid>
          {error && (
            <Grid item xs={12}>
              <ErrorMessage message={error} />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>{t('general.cancel')}</Button>
        <Button type='submit' disabled={submitting}>
          {t('general.save')}
        </Button>
      </DialogActions>
    </form>
  )
}

export default reduxForm<UpdateUserInfoFormData, EditProfileInfoFormProps>({
  form: 'EditProfileInfoForm'
})(EditProfileInfoForm)
