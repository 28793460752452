import { handleActions } from 'redux-actions'
import {
  AutocompleteOption,
  QueryProofOfPlayRequest,
  QueryProofOfPlayResponse
} from '@seesignage/seesignage-utils'
import { AnalyticsState } from '../types/states'
import {
  getProofOfPlayOptions,
  getProofOfPlayOptionsFail,
  getProofOfPlayOptionsSuccess,
  queryProofOfPlay,
  queryProofOfPlayFail,
  queryProofOfPlaySuccess,
  resetAnalytics
} from '../actions/analytics'
import { Action } from '../types/actions'

const initialState: AnalyticsState = {
  isQueryProofOfPlayLoading: false,
  isGetProofOfPlayOptionsLoading: false
}

const analytics = handleActions<AnalyticsState, any>(
  {
    [resetAnalytics]: () => initialState,
    [queryProofOfPlay]: state => ({
      ...state,
      isQueryProofOfPlayLoading: true,
      proofOfPlay: {
        ...state.proofOfPlay,
        query: undefined,
        items: undefined
      }
    }),
    [queryProofOfPlayFail]: state => ({
      ...state,
      isQueryProofOfPlayLoading: false,
      proofOfPlay: {
        ...state.proofOfPlay,
        query: undefined,
        items: undefined
      }
    }),
    [queryProofOfPlaySuccess]: (
      state,
      {
        payload: {
          query,
          response: { items }
        }
      }: Action<{ query: QueryProofOfPlayRequest; response: QueryProofOfPlayResponse }>
    ) => {
      return {
        ...state,
        isQueryProofOfPlayLoading: false,
        proofOfPlay: {
          ...state.proofOfPlay,
          query,
          items
        }
      }
    },
    [getProofOfPlayOptions]: state => ({
      ...state,
      isGetProofOfPlayOptionsLoading: true,
      proofOfPlay: {
        ...state.proofOfPlay,
        options: undefined
      }
    }),
    [getProofOfPlayOptionsFail]: state => ({
      ...state,
      isGetProofOfPlayOptionsLoading: false,
      proofOfPlay: {
        ...state.proofOfPlay,
        options: undefined
      }
    }),
    [getProofOfPlayOptionsSuccess]: (
      state,
      { payload: options }: Action<{ channels: AutocompleteOption; playlists: AutocompleteOption }>
    ) => {
      return {
        ...state,
        isGetProofOfPlayOptionsLoading: false,
        proofOfPlay: {
          ...state.proofOfPlay,
          options
        }
      }
    }
  },
  initialState
)

export default analytics
