import React, { Fragment } from 'react'
import { Typography, Button, Paper } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import { TFunction } from 'i18next'
import classNames from 'classnames'
import {
  LunchListWeek,
  LunchListWeekDay,
  LunchProductById,
  LunchGroupById,
  LunchItem,
  LunchProduct,
  getCurrentWeek,
  getCurrentDay,
  Template
} from '@seesignage/seesignage-utils'
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import {
  CloseDialog,
  CancelLunchListChanges,
  UpdateLunchListItems,
  SetSelectedLunchItem,
  AddLunchListWeek,
  DeleteLunchListWeek
} from '../../../../types/actions'
import Dialog from '../../../Dialog'
import CreateLunchWeekForm from '../../Forms/CreateLunchWeekForm'
import { getOpacity, getBackground } from '../../../../components/Draggable/DraggableItems'
import { SelectedLunchItem, SelectedLunchItemType } from '../../../../types/lists'
import SimpleExtensionPanel from '../../../../components/ExtensionPanels/SimpleExtensionPanel'
import { isOverMaxItems } from '../../../../utils/lunchLists'
import colors from '../../../../styles/common/colors'
import WeekActions from './WeekActions'
import Product, { OwnProductProps } from './Product'

const MAX_WEEKS_LIMIT = 10

const styles = () => ({
  listPaper: {
    height: '100%'
  },
  createWeekAction: {
    display: 'inline-block'
  },
  saveActions: {
    display: 'inline-block',
    float: 'right' as 'right'
  },
  button: {
    marginRight: 4
  },
  weekActions: {
    minHeight: 50,
    marginBottom: 8
  },
  currentDayTitle: {
    fontWeight: 600
  },
  paper: {
    padding: 10
  },
  dayContainer: {
    minHeight: 400
  }
})

const offset1 = '0px 4px 8px 0px'
const offset2 = '0px 2px 2px 0px'
const offset3 = '0px 3px 1px -2px'
const getItemShadow = (isDragging: boolean, isSelected?: boolean) => {
  if (isDragging) {
    return `${offset1} rgba(80, 195, 237, 3),
    ${offset2} rgba(80, 195, 237, 3), ${offset3} rgba(80, 195, 237, 3)`
  } else if (isSelected) {
    return `0px 0px 3px 4px ${colors.lunchListDefautlColor}`
  } else {
    return `${offset1} rgba(0, 0, 0, 0.2),
    ${offset2} rgba(0, 0, 0, 0.14), ${offset3} rgba(0, 0, 0, 0.12)`
  }
}

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: any,
  isSelected?: boolean,
  isHidden?: boolean
) => ({
  userSelect: 'none',
  margin: '10px 0px 16px',
  width: '100%',
  boxShadow: getItemShadow(isDragging, isSelected),
  ...draggableStyle,
  opacity: getOpacity(isHidden),
  background: getBackground(isHidden)
})

const getListStyle = (isDraggingOver: boolean, isCurrentList: boolean): React.CSSProperties => ({
  background: isDraggingOver ? 'rgb(145, 225, 255)' : isCurrentList ? '#DAEEFF' : '#fafafa',
  padding: '8px 8px 20px',
  marginRight: '4px',
  width: 200,
  height: '90%',
  float: 'left'
})

const orderedDays = [
  LunchListWeekDay.mon,
  LunchListWeekDay.tue,
  LunchListWeekDay.wed,
  LunchListWeekDay.thu,
  LunchListWeekDay.fri,
  LunchListWeekDay.sat,
  LunchListWeekDay.sun
]

export interface WeeksProps {
  t: TFunction
  weeks?: LunchListWeek[]
  products: LunchProductById
  groups: LunchGroupById
  selectedItem?: SelectedLunchItem
  addLunchListWeek: AddLunchListWeek
  deleteLunchListWeek: DeleteLunchListWeek
  closeDialog: CloseDialog
  setSelectedItem: SetSelectedLunchItem
  lunchListModified: boolean
  cancelLunchListChanges: CancelLunchListChanges
  updateLunchListItems: UpdateLunchListItems
  isSubList: boolean
  /** Start of the rotational LunchList (as UTC string) */
  startDate: string
  template: Template
}

type OwnProps = WeeksProps & WithStyles<typeof styles>

const Weeks = ({
  classes,
  t,
  weeks,
  products,
  groups,
  addLunchListWeek,
  deleteLunchListWeek,
  selectedItem,
  setSelectedItem,
  lunchListModified,
  closeDialog,
  cancelLunchListChanges,
  updateLunchListItems,
  isSubList,
  startDate,
  template
}: OwnProps) => {
  const maxProductsInDay = template.maxItems
  const dateNow = new Date()
  const currentWeekIndex = weeks ? getCurrentWeek(startDate, dateNow, weeks.length) : 0
  const currentDay = getCurrentDay(dateNow)
  return (
    <div>
      <div className={classes.weekActions}>
        <div className={classes.createWeekAction}>
          {// Sub list cant add weeks to list
          ((weeks && weeks.length < MAX_WEEKS_LIMIT) || !weeks) && !isSubList && (
            <Dialog
              dialogId='createLunchWeekFormDialog'
              title={t('lists.lunch.addWeek')}
              buttonLabel={t('lists.lunch.addWeek')}
              color='primary'
              ButtonIcon={PlusCircleOutlineIcon}
              Content={
                <CreateLunchWeekForm
                  dialogId='createLunchWeekFormDialog'
                  submitAction={addLunchListWeek}
                  closeDialog={closeDialog}
                  initialValues={{
                    weekdays: {
                      mon: true,
                      tue: true,
                      wed: true,
                      thu: true,
                      fri: true
                    }
                  }}
                />
              }
            />
          )}
          {!isSubList && weeks && weeks.length === MAX_WEEKS_LIMIT && (
            <Typography>{t('lists.lunch.maxWeeksLimit', { maxWeeks: MAX_WEEKS_LIMIT })}</Typography>
          )}
        </div>
        <div className={classes.saveActions}>
          <Button
            className={classes.button}
            variant='contained'
            onClick={cancelLunchListChanges}
            disabled={!lunchListModified}>
            {t('general.cancel')}
          </Button>
          <Button
            className={classes.button}
            disabled={!lunchListModified}
            variant='contained'
            color='primary'
            onClick={updateLunchListItems}>
            {t('templates.forms.save')}
          </Button>
        </div>
      </div>

      {weeks && weeks.length > 0 ? (
        weeks.map((week: LunchListWeek, weekIndex: number) => {
          const isCurrentWeek = weekIndex === currentWeekIndex
          return (
            <SimpleExtensionPanel
              key={`week-${weekIndex}`}
              boldTitle={isCurrentWeek}
              title={t(`lists.lunch.${isCurrentWeek ? 'currentWeekTitle' : 'weekTitle'}`, {
                weekNumber: weekIndex + 1
              })}
              // open current week by default
              isOpen={currentWeekIndex === weekIndex}
              Actions={isSubList ? undefined : WeekActions}
              actionsProps={{
                weekIndex,
                deleteLunchListWeek,
                closeDialog
              }}
              isScrollableContent
              isFlexContent
              renderOnlyExpandedChildren>
              {orderedDays.map(day => {
                const lunchListDay = week.days[day as LunchListWeekDay]
                const isCurrentList = isCurrentWeek && day === currentDay
                const dayChanges = lunchListDay?.changes
                if (lunchListDay) {
                  return (
                    <div key={`${weekIndex}.${day}`} className={classes.dayContainer}>
                      <Typography
                        className={classNames({
                          [classes.currentDayTitle]: isCurrentList
                        })}>
                        {t(`lists.lunch.weekdays.${day}`)}
                      </Typography>
                      <Droppable
                        droppableId={`droppable.week.${weekIndex}.day.${day}`}
                        type='droppableItem'>
                        {({ innerRef, placeholder }, snapshot) => {
                          const { isDraggingOver } = snapshot
                          return (
                            <div ref={innerRef} style={getListStyle(isDraggingOver, isCurrentList)}>
                              <div>
                                {lunchListDay.lunchItems.map((lunchItem, lunchItemIndex) => {
                                  const draggableId = `draggable.week.${weekIndex}.day.${day}.item.${lunchItemIndex}`
                                  const product: LunchProduct | undefined =
                                    products[lunchItem.productId]
                                  if (product === undefined) {
                                    // eslint-disable-next-line no-console
                                    console.log(
                                      'Warning lunchItem s product undefined',
                                      lunchItem.productId
                                    )
                                    return <Fragment key={lunchItem.productId} />
                                  }
                                  const { groupId } = product
                                  const group = groupId ? groups[groupId] : undefined

                                  const productChanges = dayChanges
                                    ? dayChanges[lunchItem.productId]
                                    : undefined

                                  const isHidden = productChanges?.isHidden ? true : false
                                  const changeProduct = productChanges?.productId
                                    ? products[productChanges.productId]
                                    : undefined
                                  const productProps: OwnProductProps = {
                                    lunchItem,
                                    product,
                                    group,
                                    isSubList,
                                    t,
                                    changeProduct,
                                    changeGroup: changeProduct?.groupId
                                      ? groups[changeProduct.groupId]
                                      : undefined,
                                    isHidden,
                                    isOverMaxItems: isOverMaxItems({
                                      currentLunchItems: lunchListDay.lunchItems,
                                      currentIndex: lunchItemIndex,
                                      maxProductsInDay,
                                      dayChanges
                                    })
                                  }

                                  const isLunchItemSelected =
                                    selectedItem?.type === SelectedLunchItemType.lunchItem &&
                                    (selectedItem.item as LunchItem).itemId === lunchItem.itemId
                                  return (
                                    <Draggable
                                      key={draggableId}
                                      draggableId={draggableId}
                                      index={lunchItemIndex}
                                      isDragDisabled={isSubList && lunchItem.isParentItem}>
                                      {(
                                        { innerRef, draggableProps, dragHandleProps },
                                        { isDragging }
                                      ) => (
                                        <div
                                          ref={innerRef}
                                          {...draggableProps}
                                          {...dragHandleProps}
                                          style={getItemStyle(
                                            isDragging,
                                            draggableProps.style,
                                            isLunchItemSelected
                                          )}
                                          onClick={() => {
                                            setSelectedItem(
                                              isLunchItemSelected
                                                ? undefined
                                                : {
                                                    item: lunchItem,
                                                    type: SelectedLunchItemType.lunchItem,
                                                    lunchItemIndex,
                                                    weekIndex,
                                                    day
                                                  }
                                            )
                                          }}>
                                          <Product {...productProps} />
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                                })}
                              </div>
                              {placeholder}
                            </div>
                          )
                        }}
                      </Droppable>
                    </div>
                  )
                }
                return <Fragment key={day} />
              })}
            </SimpleExtensionPanel>
          )
        })
      ) : (
        <Paper className={classes.paper}>
          <Typography>{t('lists.lunch.instructions.start')}</Typography>
        </Paper>
      )}
    </div>
  )
}

export default withStyles(styles)(Weeks)
