import React from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { Field, FieldArrayFieldsProps } from 'redux-form'
import DeleteIcon from 'mdi-react/DeleteIcon'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'

import { TFunction } from 'i18next'
import { EnvironmentUserRole } from '@seesignage/seesignage-utils'
import { required, email, emailsUnique } from '../../../validation'
import { ReduxTextField } from '../ReduxWrappers'
import { parseEmailField } from '../../../utils/forms'
import { InitialEnvironmentUser } from '../../../types/environments'

interface EnvironmentUsersFieldComponentProps {
  fields: FieldArrayFieldsProps<InitialEnvironmentUser | {}>
  t: TFunction
}

/**
 * Environment users component for redux-form FieldArray
 */
const EnvironmentUsersFieldComponent: React.FC<EnvironmentUsersFieldComponentProps> = ({
  fields,
  t
}) => (
  <Grid container style={{ marginBottom: '20px' }}>
    <Grid item xs={12} sm={12}>
      <Button
        onClick={() => fields.push({})}
        fullWidth
        color='primary'
        variant='contained'
        style={{ marginTop: '12px' }}>
        {t('environment.linkNewUser')}
      </Button>
    </Grid>
    {fields.map((member: string, index: number) => (
      <Grid container key={index}>
        <Grid item xs={7}>
          <Field
            fullWidth
            name={`${member}.email`}
            label={t('login.email')}
            component={ReduxTextField}
            required
            validate={[required, email, emailsUnique]}
            parse={parseEmailField}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            label={t('environment.role')}
            name={`${member}.role`}
            component={ReduxTextField}
            required
            validate={[required]}
            fullWidth
            select>
            <MenuItem value={EnvironmentUserRole.admin}>{t('environment.admin')}</MenuItem>
            <MenuItem value={EnvironmentUserRole.user}>{t('environment.user')}</MenuItem>
          </Field>
        </Grid>
        <Grid item xs={2}>
          <IconButton
            style={{ paddingTop: '15px' }}
            onClick={() => fields.remove(index)}
            aria-label={t('general.delete')}
            size='large'>
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    ))}
  </Grid>
)

export default EnvironmentUsersFieldComponent
