import { Paper, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { Fragment } from 'react'
import { ScreenResponse } from '@seesignage/seesignage-utils'
import { useTranslation } from 'react-i18next'
import { getScreentWarningIcon } from '../../utils/screens'

const useStyles = makeStyles(() => ({
  container: {
    margin: '8px 0px 4px'
  },
  screensList: {
    maxHeight: '400px',
    overflowY: 'auto',
    textAlign: 'left'
  },
  screen: {
    padding: 4,
    marginBottom: 3
  },
  screenName: {
    fontWeight: 600
  },
  warning: {
    marginBottom: 2
  },
  warningIcon: {
    padding: 4,
    verticalAlign: 'middle'
  },
  warningText: {
    padding: 4,
    verticalAlign: 'middle'
  }
}))

interface ScreenWarningsProps {
  /** screen with warnings only */
  screens: ScreenResponse[]
}

const ScreenWarnings = ({ screens }: ScreenWarningsProps) => {
  const [t] = useTranslation()
  const classes = useStyles()
  return (
    <Fragment>
      {screens.length > 0 && (
        <Paper className={classes.container}>
          <Typography variant='h5' gutterBottom>
            {t('home.recommendations.title')}
          </Typography>
          <div className={classes.screensList}>
            {screens.map(({ screenId, name, warnings }) => {
              return (
                <Paper key={screenId} className={classes.screen} variant='outlined'>
                  <Typography className={classes.screenName}>{name}</Typography>
                  {warnings &&
                    warnings.map(({ warningType }) => {
                      const WarningIcon = getScreentWarningIcon(warningType)
                      return (
                        <div key={`${screenId}-${warningType}`} className={classes.warning}>
                          <span className={classes.warningIcon}>
                            <WarningIcon />
                          </span>
                          <span className={classes.warningText}>
                            {t(`home.recommendations.screens.warningType.${warningType}`)}
                          </span>
                        </div>
                      )
                    })}
                </Paper>
              )
            })}
          </div>
        </Paper>
      )}
    </Fragment>
  )
}

export default ScreenWarnings
