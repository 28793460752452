import React, { Fragment } from 'react'
import {
  EnvironmentUserRole,
  EnvironmentUI,
  AutocompleteOption
} from '@seesignage/seesignage-utils'
import AdminIcon from 'mdi-react/AccountKeyIcon'
import UserIcon from 'mdi-react/AccountIcon'
import {
  TableRow,
  TableContainer,
  Paper,
  TableHead,
  Tooltip,
  TableBody,
  Table,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@mui/material'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import LockQuestionIcon from 'mdi-react/LockQuestionIcon'
import DeleteIcon from 'mdi-react/DeleteIcon'
import { bindSubmitActionToPromise } from '../../../../utils/forms'
import { UserInterface } from '../../../../types/users'
import { isAdminRole, checkAdminAccess } from '../../../../utils/permissions'
import Dialog from '../../../Dialog'
import EditEnvironmentPermissionsForm from '../../Forms/EditEnvironmentPermissionsForm'
import { StateInterface } from '../../../../types/states'
import { closeDialog } from '../../../../actions/dialogs'
import { CloseDialog } from '../../../../types/actions'
import { editEnvironmentPermissions, removeEnvironmentUser } from '../../../../actions/environments'
import { StyledTableCell, StyledTableRow } from '../../../../components/Table'
import { selectCurrentEnvironmentPermissionTagsAsOptions } from '../../../../selectors/environments'
import { convertTagsForFormField } from '../../../../utils/conversion'

interface OwnProps {
  currentUser: UserInterface
  environment: EnvironmentUI
}

interface StateProps {
  permissionTagsAsOptions: AutocompleteOption[]
}

interface DispatchProps {
  removeUser: (sub: string) => void
  editPermissions: (formData: any) => void
  closeDialog: CloseDialog
}

type UsersTableProps = OwnProps & StateProps & DispatchProps

const UsersTable: React.FC<UsersTableProps> = ({
  closeDialog,
  currentUser,
  editPermissions,
  environment,
  permissionTagsAsOptions,
  removeUser
}) => {
  const [t] = useTranslation()
  return (
    <TableContainer component={Paper}>
      <Table aria-label='customized table'>
        <TableHead>
          <TableRow>
            <StyledTableCell>{t('environment.role')}</StyledTableCell>
            <StyledTableCell align='left'>{t('signup.email')}</StyledTableCell>
            <StyledTableCell align='right'></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {environment.users.map(user => (
            <StyledTableRow key={user.sub}>
              <StyledTableCell component='th' scope='row'>
                <Tooltip
                  disableInteractive
                  placement='right'
                  title={t(`environment.${user.role}`) as string}>
                  <span>
                    {user.role === EnvironmentUserRole.admin ? <AdminIcon /> : <UserIcon />}
                  </span>
                </Tooltip>
              </StyledTableCell>
              <StyledTableCell align='left'>{user.email}</StyledTableCell>
              <StyledTableCell align='right'>
                <div style={{ display: 'flex', float: 'right' }}>
                  {(isAdminRole(currentUser.role) ||
                    checkAdminAccess(environment, currentUser.sub)) && (
                    <Dialog
                      dialogId={`editPermissionsForm.${user.sub}`}
                      title={t('environment.permissions.edit', { email: user.email })}
                      tooltipTitle={t('environment.permissions.edit', { email: user.email })}
                      ButtonIcon={LockQuestionIcon}
                      Content={
                        <EditEnvironmentPermissionsForm
                          dialogId={`editPermissionsForm.${user.sub}`}
                          onSubmit={editPermissions}
                          submitButtonLabel={t('general.save')}
                          closeDialog={closeDialog}
                          options={permissionTagsAsOptions}
                          currentUser={currentUser}
                          user={user}
                          initialValues={{
                            environmentId: environment.environmentId,
                            role: user.role,
                            sub: user.sub,
                            permissions: {
                              lists: user.permissions?.lists,
                              templates: user.permissions?.templates,
                              tags: convertTagsForFormField(user?.permissions?.tags)
                            }
                          }}
                        />
                      }
                    />
                  )}
                  <Dialog
                    dialogId={`RemoveUserDialog.${user.sub}`}
                    title={t('environment.removeUser')}
                    ButtonIcon={DeleteIcon}
                    ButtonIconProps={{ color: 'red' }}
                    tooltipTitle={t('environment.removeUser')}
                    Content={
                      <Fragment>
                        <DialogContent>
                          <DialogContentText>
                            {t('environment.removeUserConfirm', { email: user.email })}
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={() => closeDialog(`RemoveUserDialog.${user.sub}`)}
                            color='primary'>
                            {t('general.disagree')}
                          </Button>
                          <Button onClick={() => removeUser(user.sub)} color='primary' autoFocus>
                            {t('general.agree')}
                          </Button>
                        </DialogActions>
                      </Fragment>
                    }
                  />
                </div>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const mapStateToProps = (state: StateInterface): StateProps => ({
  permissionTagsAsOptions: selectCurrentEnvironmentPermissionTagsAsOptions(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  removeUser: (sub: string) => dispatch(removeEnvironmentUser(sub)),
  editPermissions: bindSubmitActionToPromise(dispatch, editEnvironmentPermissions),
  closeDialog: (dialogId: string) => dispatch(closeDialog(dialogId))
})

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable)
