import React from 'react'
import { Field } from 'redux-form'
import { ReduxCheckbox } from '../../../../components/FormInput/ReduxWrappers'

/**
 * Checkbox component to activate field in BatchUpdateScreensForm.
 * - Only activated fields will be updated in batch
 * @param param0
 */
const ActivateField = ({ name }: { name: string }) => (
  <Field id={name} name={`properties.${name}`} component={ReduxCheckbox} color='primary' />
)

export default ActivateField
