import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment'
import {
  ListItemText,
  ListItem,
  List,
  LinearProgress,
  ListItemAvatar,
  Avatar,
  Stepper,
  Step,
  StepLabel,
  StepIcon
} from '@mui/material'
import FolderIcon from 'mdi-react/FolderIcon'
import FolderMultipleIcon from 'mdi-react/FolderMultipleIcon'
import { useTranslation } from 'react-i18next'
import { EMMiPreviewFolder, Customer } from '@seesignage/seesignage-utils'
import { TFunction } from 'i18next'
import IntegrationsService from '../../../../services/api/integrations'
import { ImportFolderWizardPage } from '../../../../types/integrations'
import PreviewFolder from './PreviewFolder'

const getSteps = (t: TFunction) => [
  {
    icon: <FolderMultipleIcon />,
    text: t('integrations.emmi.selectFolderForImport')
  },
  {
    icon: <FolderIcon />,
    text: t('integrations.emmi.previewFolder')
  }
]

interface ImportFolderProps {
  emmiCustomer: Customer
}

const ImportFolder: React.FC<ImportFolderProps> = ({ emmiCustomer }) => {
  const [folders, setFolders] = useState<EMMiPreviewFolder[] | undefined>(undefined)
  const [selectedFolder, setSelectedFolder] = useState<EMMiPreviewFolder | undefined>(undefined)
  const [page, setPage] = useState<ImportFolderWizardPage>(ImportFolderWizardPage.selectFolder)
  const [isLoading, setLoadingState] = useState(false)
  const [t] = useTranslation()

  useEffect(() => {
    const getFolders = async () => {
      setLoadingState(true)
      const folders = await IntegrationsService.getEMMiFolders(
        emmiCustomer.environmentId,
        emmiCustomer.customerId
      )
      setFolders(folders)
      setLoadingState(false)
    }
    getFolders()
  }, [emmiCustomer])

  const getActiveStep = () => {
    switch (page) {
      case ImportFolderWizardPage.selectFolder:
        return 0
      case ImportFolderWizardPage.previewFolder:
        return 1
      default:
        return 0
    }
  }

  const selectFolder = (folder: EMMiPreviewFolder) => {
    setSelectedFolder(folder)
    setPage(ImportFolderWizardPage.previewFolder)
  }

  return (
    <Fragment>
      <Stepper alternativeLabel activeStep={getActiveStep()}>
        {getSteps(t).map(({ text, icon }) => (
          <Step key={text}>
            <StepLabel StepIconComponent={props => <StepIcon {...props} icon={icon} />}>
              {text}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      {isLoading && <LinearProgress />}
      {page === ImportFolderWizardPage.selectFolder && folders && !isLoading && (
        <Fragment>
          <List component='nav'>
            {folders.map(folder => {
              const { name, folderId, startDate, endDate } = folder
              return (
                <ListItem onClick={() => selectFolder(folder)} key={folderId} button>
                  <ListItemAvatar>
                    <Avatar>
                      <FolderIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={name}
                    secondaryTypographyProps={
                      new Date(endDate).getTime() < Date.now() ? { color: 'secondary' } : undefined
                    }
                    secondary={t('integrations.emmi.folderValid', {
                      startDate: moment(startDate).format('DD/MM/YYYY'),
                      endDate: moment(endDate).format('DD/MM/YYYY')
                    })}
                  />
                </ListItem>
              )
            })}
          </List>
        </Fragment>
      )}
      {page === ImportFolderWizardPage.previewFolder && selectedFolder && (
        <PreviewFolder
          selectedFolder={selectedFolder}
          emmiCustomer={emmiCustomer}
          setPage={setPage}
        />
      )}
    </Fragment>
  )
}

export default ImportFolder
