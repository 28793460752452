import { createSelector } from 'reselect'
import { Template, StylesChild, isMasterTemplate } from '@seesignage/seesignage-utils'
import { StateInterface, IndexById } from '../types/states'

const selectTemplatesDomain = (state: StateInterface) => state.templates

const selectTemplates = createSelector(selectTemplatesDomain, domain => domain.templates)

const selectTemplatesIsLoading = createSelector(
  selectTemplatesDomain,
  domain => domain.templatesIsLoading
)

const selectTemplateIsLoading = createSelector(
  selectTemplatesDomain,
  domain => domain.templateIsLoading
)

const selectSchemaHasChanges = createSelector(
  selectTemplatesDomain,
  domain => domain.schemaHasChanges
)

const selectTemplateById = (templateId: string | undefined) =>
  createSelector(selectTemplates, templates => (templateId ? templates[templateId] : undefined))

const selectTemplatesAsArray = createSelector(selectTemplates, templatesById =>
  Object.values(templatesById)
)

const selectEnvironmentTemplates = (environmentId?: string) =>
  createSelector(selectTemplatesAsArray, templates =>
    templates.filter(
      ({ environmentId: templateEnvironment }) => environmentId === templateEnvironment
    )
  )

const selectSelectedTemplateOrientation = createSelector(
  selectTemplatesDomain,
  domain => domain.selectedTemplateOrientation
)

const selectTemplatesByType = (type: string) =>
  createSelector(selectTemplatesAsArray, templates =>
    templates.filter(template => template.type === type)
  )

/**
 * Select only master templates (for multi product support)
 */
const selectMasterTemplates = createSelector(selectTemplatesAsArray, templates =>
  templates.filter(template => isMasterTemplate(template))
)

const selectKeysFromComponent = (component: StylesChild, keys: string[] = []) => {
  if (component.backgroundImage) {
    keys.push(component.backgroundImage)
  }
  const key = component.key
  if (key) {
    keys.push(key)
  }
  if (!component.children) {
    return keys
  }
  component.children.forEach(child => {
    if (child) {
      selectKeysFromComponent(child, keys)
    }
  })
  return keys
}

const selectTemplateKeys = ({ components: { landscape, portrait } }: Template) => {
  const landscapeKeys = selectKeysFromComponent(landscape)
  const portraitKeys = selectKeysFromComponent(portrait)
  const keys = [...landscapeKeys, ...portraitKeys]
  return keys.reduce<IndexById<string>>((keys, key) => {
    keys[key] = key
    return keys
  }, {})
}

const selectTemplatesByTypeAsOptions = (type: string) =>
  createSelector(selectTemplatesByType(type), templates =>
    templates.map(({ name, templateId }) => ({
      value: templateId,
      label: name
    }))
  )

const selectTemplatesTypesArray = createSelector(selectTemplatesAsArray, templates => {
  const arr = templates.map(({ type }) => type).flat(1)
  return [...new Set(arr)]
})

const selectTemplatesAsOptions = (templates: Template[], currentEnvironmentId?: string) =>
  templates.map(({ environmentId, name, templateId }) => ({
    label: name,
    value: templateId,
    isParent: environmentId !== currentEnvironmentId
  }))

const selectSelectedTemplateChildIndex = createSelector(
  selectTemplatesDomain,
  domain => domain.selectedChildIndex
)

const selectIsChildTemplateDeleting = createSelector(
  selectTemplatesDomain,
  domain => domain.childTemplateIsDeleting
)

export {
  selectTemplates,
  selectTemplatesAsArray,
  selectEnvironmentTemplates,
  selectTemplateById,
  selectTemplatesIsLoading,
  selectTemplateIsLoading,
  selectSchemaHasChanges,
  selectSelectedTemplateOrientation,
  selectTemplatesByType,
  selectMasterTemplates,
  selectTemplateKeys,
  selectTemplatesByTypeAsOptions,
  selectTemplatesTypesArray,
  selectTemplatesAsOptions,
  selectSelectedTemplateChildIndex,
  selectIsChildTemplateDeleting
}
