import { Button, DialogActions, Grid, IconButton, LinearProgress, Tooltip } from '@mui/material'
import DeleteIcon from 'mdi-react/DeleteIcon'
import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import { customInterval } from '../../../../validation'
import { CloseDialog } from '../../../../types/actions'
import { parseNumberField } from '../../../../utils/forms'
import { ReduxDurationField } from '../../../../components/FormInput/ReduxWrappers'
import ErrorMessage from '../../../../components/Errors/ErrorMessage'

interface OwnProps {
  submitAction: (formData: any) => void
  removeItemInterval: () => void
  closeDialog: CloseDialog
  dialogId: string
  submitButtonLabel: string
  defaultInterval: number
}

type AddItemIntervalFormProps = OwnProps & WithTranslation

const AddItemIntervalForm: React.FC<AddItemIntervalFormProps & InjectedFormProps<{}, OwnProps>> = ({
  handleSubmit,
  submitting,
  submitAction,
  removeItemInterval,
  error,
  closeDialog,
  dialogId,
  submitButtonLabel,
  defaultInterval,
  t
}) => {
  return (
    <form onSubmit={handleSubmit(submitAction)}>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <Field
            fullWidth
            name='interval'
            label={t('playlists.editPlaylist.addItemInterval.interval')}
            component={ReduxDurationField}
            parse={parseNumberField}
            type='number'
            required
            validate={customInterval}
            defaultValue={defaultInterval}
          />
        </Grid>
        <Grid item xs={2}>
          <Tooltip disableInteractive title={t('playlists.editPlaylist.addItemInterval.remove')}>
            <IconButton
              onClick={() => removeItemInterval()}
              aria-label={t('playlists.editPlaylist.addItemInterval.remove')}
              style={{ float: 'right' }}
              size='large'>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        {submitting && <LinearProgress />}
        <Grid item xs={12}>
          <ErrorMessage message={error} />
        </Grid>
      </Grid>
      <DialogActions>
        <Button onClick={() => closeDialog(dialogId)} color='primary'>
          {t('general.cancel')}
        </Button>
        <Button disabled={submitting} color='primary' type='submit'>
          {submitButtonLabel}
        </Button>
      </DialogActions>
    </form>
  )
}
export default reduxForm<{}, OwnProps>({
  form: 'addItemIntervalForm'
})(withTranslation()(AddItemIntervalForm))
