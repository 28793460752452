import { Button, DialogActions, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Field, formValueSelector, reduxForm, InjectedFormProps } from 'redux-form'
import { MediaType } from '@seesignage/seesignage-utils'
import { CloseDialog } from '../../../../../types/actions'
import SelectMultipleMediaField from '../../../../../components/FormInput/SelectMedia/SelectMultipleMediaField'
import { selectIsUserDeviceMobile } from '../../../../../selectors/users'
import { AddMediaContentFormData } from '../../../../../types/contents'

export const addMediaContentFormName = 'AddMediaContentForm'

const useStyles = makeStyles(() => ({
  dialogActions: {
    margin: '15px 5px 15px 0px'
  },
  dialogActionsMobile: {
    margin: '15px 5px 15px 0px',
    justifyContent: 'center'
  }
}))

interface AddMediaContentFormOwnProps {
  closeDialog: CloseDialog
  dialogId: string
  mediaType?: MediaType
  onSubmit: (formData: any) => Promise<unknown>
}

type FormDataProps = AddMediaContentFormData

const AddMediaContentForm: React.FC<AddMediaContentFormOwnProps &
  InjectedFormProps<FormDataProps, AddMediaContentFormOwnProps>> = ({
  error,
  mediaType,
  handleSubmit,
  submitting,
  onSubmit,
  closeDialog,
  dialogId,
  form
}) => {
  const [t] = useTranslation()
  const classes = useStyles()
  const isMobile = useSelector(selectIsUserDeviceMobile)
  const key: string | undefined = useSelector(state =>
    formValueSelector(addMediaContentFormName)(state, 'key')
  )

  const isKeySelected = typeof key === 'string'
  const isSubmitDisabled = submitting || !isKeySelected

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Field
        fullWidth
        mediaType={mediaType}
        isSingleFileInput
        name='key'
        component={SelectMultipleMediaField}
        formName={form}
      />
      <Typography variant='body1' color='error'>
        {error || ''}
      </Typography>
      <DialogActions className={isMobile ? classes.dialogActionsMobile : classes.dialogActions}>
        <Button
          onClick={() => {
            closeDialog(dialogId)
          }}
          color='primary'>
          {t('general.cancel')}
        </Button>
        <Button disabled={isSubmitDisabled} color='primary' type='submit'>
          {t('general.add')}
        </Button>
      </DialogActions>
    </form>
  )
}

export default reduxForm<FormDataProps, AddMediaContentFormOwnProps>({
  form: addMediaContentFormName,
  onSubmitSuccess: (result, dispatch, props) => props.closeDialog(props.dialogId)
})(AddMediaContentForm)
