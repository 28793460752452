import React, { Fragment } from 'react'
import { ScreenResponse } from '@seesignage/seesignage-utils'
import PoundBoxIcon from 'mdi-react/PoundBoxIcon'
import LockIcon from 'mdi-react/LockIcon'
import { ChipProps } from '@mui/material/Chip'
import { MdiReactIconProps } from 'mdi-react'
import Tags from '../../../components/Chips/Tags'

interface TagsProps {
  icon: React.ComponentType<MdiReactIconProps>
  color?: ChipProps['color']
  tags?: string[] | null
}

interface ScreenCardChipsProps {
  screen: ScreenResponse
}

const ScreenCardChips = ({ screen: { tags, permissionTags } }: ScreenCardChipsProps) => (
  <Fragment>
    <Tags icon={PoundBoxIcon} tags={tags} color='primary' />
    <Tags icon={LockIcon} tags={permissionTags} />
  </Fragment>
)

export default ScreenCardChips
