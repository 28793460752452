import React, { useState, useEffect, Fragment } from 'react'
import {
  CampaignSubUI,
  CampaignUI,
  BatchProductsPlaylistItemPlayer,
  ProductPlaylistItemPlayer,
  TemplateOrientation,
  isBatchProductsPlaylistItemPlayer
} from '@seesignage/seesignage-utils'
import { Tooltip, IconButton, LinearProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import MonitorIcon from 'mdi-react/MonitorIcon'
import { useTranslation } from 'react-i18next'
import { PreviewFrame } from '@seesignage/seesignage-player-utils/lib'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@mui/material/ToggleButton'
import CampaignsService from '../../../services/api/campaigns'
import ErrorMessage from '../../../components/Errors/ErrorMessage'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  monitorLanscape: {
    margin: 2
  },
  monitorPortrait: {
    margin: 2,
    transform: 'rotate(90deg)'
  },
  landscapeContainer: {
    transform: 'scale(0.6)',
    transformOrigin: 'top left'
  },
  portraitContainer: {
    transform: 'scale(0.32)',
    transformOrigin: 'top left',
    position: 'relative',
    left: '35%'
  }
}))

const getResolution = (orientation: TemplateOrientation) =>
  orientation === TemplateOrientation.landscape
    ? {
        width: 1920,
        height: 1080
      }
    : {
        width: 1080,
        height: 1920
      }

interface OwnProps {
  campaign: CampaignSubUI | CampaignUI
  previewItemIndex: number
  setPreviewItemIndex: React.Dispatch<React.SetStateAction<number>>
}

const PreviewCampaign: React.FC<OwnProps> = ({
  campaign: { environmentId, campaignId },
  previewItemIndex,
  setPreviewItemIndex
}) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const [selectedOrientation, selectOrientation] = useState<TemplateOrientation>(
    TemplateOrientation.landscape
  )
  const [campaignAsPlaylistItems, setCampaignAsPlaylistItems] = useState<
    (ProductPlaylistItemPlayer | BatchProductsPlaylistItemPlayer)[] | undefined
  >(undefined)
  const [error, setError] = useState<string | undefined>(undefined)

  useEffect(() => {
    const getCampaignAsPlaylistItems = async (environmentId: string, campaignId: string) => {
      const campaign = await CampaignsService.getCampaignForPreview(
        environmentId,
        campaignId,
        selectedOrientation === TemplateOrientation.landscape ? 0 : 90
      )
      if (campaign) {
        setCampaignAsPlaylistItems(campaign)
      } else {
        setError('Unable to get campaign')
      }
    }
    getCampaignAsPlaylistItems(environmentId, campaignId)
  }, [selectedOrientation]) // eslint-disable-line
  const selectedCampaignItem = campaignAsPlaylistItems
    ? campaignAsPlaylistItems[previewItemIndex]
    : undefined
  const { width, height } = getResolution(selectedOrientation)
  return (
    <div className={classes.root}>
      {campaignAsPlaylistItems && selectedCampaignItem ? (
        <Fragment>
          <Tooltip disableInteractive title={t('templates.changeOrientation')}>
            <IconButton
              onClick={() =>
                selectOrientation(
                  selectedOrientation === TemplateOrientation.landscape
                    ? TemplateOrientation.portrait
                    : TemplateOrientation.landscape
                )
              }
              size='large'>
              {selectedOrientation === 'portrait' ? (
                <MonitorIcon />
              ) : (
                <MonitorIcon className={classes.monitorPortrait} />
              )}
            </IconButton>
          </Tooltip>
          <div style={{ height: 700 }}>
            <div
              className={
                selectedOrientation === 'portrait'
                  ? classes.portraitContainer
                  : classes.landscapeContainer
              }>
              <PreviewFrame
                id='campaignPreview'
                content={{
                  orientation: selectedOrientation,
                  items: selectedCampaignItem.products,
                  additionalProps: isBatchProductsPlaylistItemPlayer(selectedCampaignItem)
                    ? selectedCampaignItem.additionalProps
                    : undefined,
                  template: selectedCampaignItem.template
                }}
                width={width}
                height={height}
              />
            </div>
          </div>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <ToggleButtonGroup
              exclusive
              value={previewItemIndex}
              onChange={(e, val) => {
                if (val !== null) {
                  setPreviewItemIndex(val)
                }
              }}>
              {campaignAsPlaylistItems?.map((id, i) => (
                <ToggleButton key={i} value={i}>
                  {i + 1}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <LinearProgress />
          <ErrorMessage message={error} />
        </Fragment>
      )}
    </div>
  )
}

export default PreviewCampaign
