import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import Menu, { MenuProps } from '@mui/material/Menu'
import MoreVertIcon from 'mdi-react/MoreVertIcon'
import React from 'react'
import { MdiReactIconProps } from 'mdi-react'
import { Tooltip } from '@mui/material'

interface BasicMenuProps {
  items: any[]
  CustomIcon?: React.ComponentType<MdiReactIconProps>
  tooltipTitle: string
}

const BasicMenu: React.FC<BasicMenuProps> = ({ items, CustomIcon, tooltipTitle }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const iconButtonProps: IconButtonProps = {
    'aria-label': 'More',
    'aria-owns': open ? 'long-menu' : undefined,
    'aria-haspopup': 'true',
    onClick: handleClick
  }
  const menuProps: MenuProps = {
    disableAutoFocusItem: true,
    id: 'basic-menu',
    anchorEl,
    open,
    onClose: handleClose
  }
  return (
    <div>
      <Tooltip disableInteractive title={tooltipTitle}>
        <IconButton {...iconButtonProps} size='large'>
          {CustomIcon ? <CustomIcon /> : <MoreVertIcon />}
        </IconButton>
      </Tooltip>
      <Menu {...menuProps}>{items}</Menu>
    </div>
  )
}

export default BasicMenu
