import React from 'react'
import { reduxForm, InjectedFormProps } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { ContentStopScheduleFormData } from '../../../../../types/contents'
import CommonContentFields from './CommonContentFields'

type FormProps = ContentStopScheduleFormData

const ContentStopScheduleForm: React.FC<InjectedFormProps<FormProps>> = () => {
  const [t] = useTranslation()
  return <CommonContentFields formName={'ContentStopScheduleForm'} enableSize t={t} />
}

export default reduxForm<FormProps>({
  form: 'ContentStopScheduleForm',
  enableReinitialize: true
})(ContentStopScheduleForm)
