import {
  ContentResolution,
  Resolutions,
  ResolutionA4Landscape,
  ResolutionA4Portrait,
  ResolutionLandscape,
  ResolutionPortrait,
  ResolutionLandscapeHalf,
  ResolutionPortraitHalf,
  ResolutionSquare
} from '@seesignage/seesignage-utils'

const getContentResolutionType = ({ width, height }: ContentResolution): Resolutions => {
  if (width === ResolutionLandscape.width && height === ResolutionLandscape.height) {
    return Resolutions.landscape
  } else if (width === ResolutionLandscapeHalf.width && height === ResolutionLandscapeHalf.height) {
    return Resolutions.landscapeHalf
  } else if (width === ResolutionPortrait.width && height === ResolutionPortrait.height) {
    return Resolutions.portrait
  } else if (width === ResolutionPortraitHalf.width && height === ResolutionPortraitHalf.height) {
    return Resolutions.portraitHalf
  } else if (width === ResolutionA4Landscape.width && height === ResolutionA4Landscape.height) {
    return Resolutions.a4landscape
  } else if (width === ResolutionA4Portrait.width && height === ResolutionA4Portrait.height) {
    return Resolutions.a4portrait
  } else if (width === ResolutionSquare.width && height === ResolutionSquare.height) {
    return Resolutions.square
  } else {
    return Resolutions.custom
  }
}

const getContentResolutionValue = (resolution: Resolutions) => {
  switch (resolution) {
    case Resolutions.landscape:
      return ResolutionLandscape
    case Resolutions.landscapeHalf:
      return ResolutionLandscapeHalf
    case Resolutions.portrait:
      return ResolutionPortrait
    case Resolutions.portraitHalf:
      return ResolutionPortraitHalf
    case Resolutions.a4landscape:
      return ResolutionA4Landscape
    case Resolutions.a4portrait:
      return ResolutionA4Portrait
    case Resolutions.square:
      return ResolutionSquare
    default:
      return ResolutionLandscape
  }
}

export { getContentResolutionType, getContentResolutionValue }
