import { saveAs } from 'file-saver'
import JSZip from 'jszip'

/**
 * Generates a zip file from provided JSZip object and initiates a download in the browser.
 * @param zip JSZip object
 * @param fileName
 */
const generateAndDownloadZip = async (zip: JSZip, fileName: string) => {
  const content = await zip.generateAsync({ type: 'blob' })
  saveAs(content, fileName)
}

export { generateAndDownloadZip }
