import React, { Fragment, useState, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  Grid,
  ImageList,
  TablePagination,
  LinearProgress,
  MenuItem,
  Typography,
  Link
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { Dispatch } from 'redux'
import { Media, MediaFolder } from '@seesignage/seesignage-utils'
import { useDropzone } from 'react-dropzone'

import {
  selectMedia,
  deselectMedia,
  changePage,
  changeItemsPerPage,
  listFiles,
  selectFolder,
  downloadMediaFiles,
  setDropzoneFiles
} from '../../actions/media'
import {
  selectSelectedMediaKeys,
  selectListFilesIsLoading,
  selectCurrentPage,
  selectItemsPerPage,
  selectSearchTerm,
  selectKeysAsArray,
  filterMediaByTerms,
  selectMediaFoldersArray,
  selectMediaFolders,
  selectMediaFolderById,
  selectAllMediaFoldersArray
} from '../../selectors/media'
import {
  selectFolderIdFromPathname,
  selectEnvironmentIdFromPathname
} from '../../selectors/routing'
import { StateInterface } from '../../types/states'
import { openDialog } from '../../actions/dialogs'
import { OpenDialog } from '../../types/actions'
import Dialog from '../Dialog'
import { compareBySortType } from '../../utils/sorting'
import { BreadcrumbContentType } from '../../types/breadcrumbs'
import PageTitle from '../../components/PageTitle'
import { SortByValue, SortDirection } from '../../types/sortings'
import Sorter from '../../components/Sorting'
import { getFoldersPathArray } from '../../utils/media'
import { navigate } from '../../actions/routes'
import DragDropSnackbar from '../../components/Media/DragDropSnackbar'
import { getFilesWithPreview } from '../../utils/files'
import { selectDialogVisibility } from '../../selectors/dialogs'
import useIsWidthUp from '../../utils/hooks/useIsWidthUp'
import useWidth from '../../utils/hooks/useWidth'
import { SetDropzoneFilesActionParams } from '../../types/media'
import MediaDialog from './MediaDialog'
import MediaFolderCard from './MediaFolders'
import MediaToolbar from './MediaToolbar/MediaToolbar'
import MediaQuota from './MediaQuota'
import MediaTile, { MediaTileProps } from './MediaTile'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: '#fff',
    margin: '10px 10px 75px 10px',
    position: 'relative',
    boxSizing: 'border-box',
    transition: '100ms'
  },
  foldersContainer: {
    padding: theme.spacing()
  },
  link: {
    display: 'flex',
    cursor: 'pointer' as any
  },
  text: {
    margin: theme.spacing(),
    fontWeight: 500,
    width: '100%',
    float: 'left'
  },
  imageList: { width: '100%' }
}))

interface StateProps {
  folders: MediaFolder[]
  foldersPathArray: { id: string; name: string }[]
  environmentId?: string
  isLoading: boolean
  itemsPerPage: number
  keys: Media[]
  page: number
  searchTerm?: string
  selectedFolder?: MediaFolder
  selectedMediaKeys: string[]
}

interface DispatchProps {
  changeItemsPerPage: (amount: string) => void
  changePage: (page: number) => void
  deselectMedia: (key: string) => void
  openDialog: OpenDialog
  selectMedia: (key: string) => void
  downloadMediaFiles: (mediaFiles: Media[]) => void
}

type MediaBrowserProps = StateProps & DispatchProps

const dragStyle = {
  background: 'rgba(0, 174, 239, .05)',
  outline: 'thick solid rgba(0, 174, 239, .7)'
}

const MediaBrowser: React.FC<MediaBrowserProps> = ({
  folders,
  environmentId,
  foldersPathArray,
  isLoading,
  itemsPerPage,
  keys,
  page,
  selectedFolder,
  selectedMediaKeys,
  changeItemsPerPage,
  changePage,
  deselectMedia,
  openDialog,
  selectMedia,
  downloadMediaFiles
}) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const width = useWidth()
  const [openedMedia, setOpenedMedia] = useState<Media | undefined>(undefined)
  const [sortBy, setSortBy] = useState<SortByValue>(SortByValue.createdAt)
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.asc)
  const dialogVisibility = useSelector(selectDialogVisibility)
  const [draggingOverFolderId, setDraggingOverFolderId] = useState<string | undefined>(undefined)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(listFiles())
  }, [environmentId]) // eslint-disable-line

  useEffect(() => {
    dispatch(selectFolder(selectedFolder?.folderId))
  }, [selectedFolder]) // eslint-disable-line

  const sorterProps = {
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    t
  }
  const media = keys
    .sort(compareBySortType(sortBy, sortDirection))
    .slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage)
  const sortedFolders = folders.sort(compareBySortType(sortBy, sortDirection))
  const isWidthUpXL = useIsWidthUp('xl', width)
  const isWidthUpLG = useIsWidthUp('lg', width)
  const isWidthUpMD = useIsWidthUp('md', width)
  const getGridListCols = () => {
    if (isWidthUpXL) {
      return 6
    }

    if (isWidthUpLG) {
      return 5
    }

    if (isWidthUpMD) {
      return 4
    }

    return 2
  }

  const handleClick = (key: Media) => {
    setOpenedMedia(key)
    openDialog('FullSizeImageDialog')
  }
  const additionalBreadcrumbs = foldersPathArray.map((parent, i) => {
    if (i === foldersPathArray.length - 1) {
      return <Typography key={parent.id}>{parent.name}</Typography>
    }
    return (
      <Link
        key={parent.id}
        onClick={() =>
          dispatch(navigate(`/environments/${environmentId}/media/folders/${parent.id}`))
        }
        className={classes.link}>
        {parent.name}
      </Link>
    )
  })

  const mediaTargetFolder =
    folders?.find(folder => folder.folderId === draggingOverFolderId) || selectedFolder
  const mediaTargetFolderName = mediaTargetFolder?.name || 'Root'

  const { getRootProps, isDragActive } = useDropzone({
    onDragLeave: () => {
      setDraggingOverFolderId(undefined)
    },
    onDrop: files => {
      const setDropzoneFilesActionParams: SetDropzoneFilesActionParams = {
        files: getFilesWithPreview(files),
        parentFolderId: mediaTargetFolder?.folderId,
        formName: 'AddMediaForm',
        formFieldName: 'files'
      }
      dispatch(setDropzoneFiles(setDropzoneFilesActionParams))
      openDialog('addMediaDialog')
      setDraggingOverFolderId(undefined)
    },
    noClick: true,
    noKeyboard: true,
    disabled: dialogVisibility.isVisible // disable dropzone when dialog is open.
    // NOTE: do not use accept here because we want to allow all files because redux-form
    // handles the file type validation.
  })

  return (
    <Fragment>
      <PageTitle
        contentType={BreadcrumbContentType.media}
        contentId={selectedFolder?.folderId}
        additionalBreadcrumbs={additionalBreadcrumbs}
      />
      <MediaToolbar visibleMedia={media} />
      <Dialog
        dialogId='FullSizeImageDialog'
        title={openedMedia?.name || ''}
        maxWidth='md'
        noOpenDialogButton
        Content={
          openedMedia ? (
            <MediaDialog
              selectedMedia={openedMedia}
              t={t}
              downloadMediaFiles={downloadMediaFiles}
            />
          ) : (
            <Fragment />
          )
        }
      />
      {isLoading && <LinearProgress />}
      <div
        {...getRootProps({
          className: 'dropzone'
        })}>
        <div
          className={classes.root}
          style={isDragActive && !draggingOverFolderId ? dragStyle : {}}>
          <div style={{ width: '100%' }}>
            <Sorter {...sorterProps}>
              <MenuItem value='name'>{t('sorting.sortBy.name')}</MenuItem>
              <MenuItem value='createdAt'>{t('sorting.sortBy.createdAt')}</MenuItem>
              <MenuItem value='size'>{t('sorting.sortBy.size')}</MenuItem>
            </Sorter>
          </div>

          {sortedFolders.length > 0 && (
            <Typography className={classes.text} variant='caption'>
              {t('media.folders.folders')}
            </Typography>
          )}

          <Grid container spacing={2} className={classes.foldersContainer}>
            {sortedFolders.map(folder => (
              <Grid item key={folder.folderId}>
                <MediaFolderCard
                  folder={folder}
                  setDraggingOverFolderId={setDraggingOverFolderId}
                />
              </Grid>
            ))}
          </Grid>
          <Typography className={classes.text} variant='caption'>
            {t('media.files')}
          </Typography>
          <ImageList cols={getGridListCols()} className={classes.imageList} gap={8} rowHeight={188}>
            {media.map(key => {
              const tileProps: MediaTileProps = {
                media: key,
                handleClick,
                selectMedia,
                deselectMedia,
                selectedMediaKeys
              }
              return <MediaTile key={key.key} {...tileProps} />
            })}
          </ImageList>

          <TablePagination
            component='div'
            count={keys.length}
            rowsPerPage={itemsPerPage}
            page={page}
            labelRowsPerPage={t('media.itemsPerPage')}
            onPageChange={(e, page) => changePage(page)}
            onRowsPerPageChange={e => changeItemsPerPage(e.target.value)}
          />
        </div>
        <DragDropSnackbar
          open={isDragActive}
          label={t('media.uploadToFolder')}
          mediaTargetFolderName={mediaTargetFolderName}
        />
      </div>
      <MediaQuota />
    </Fragment>
  )
}

const mapStateToProps = (state: StateInterface): StateProps => {
  const selectedFolderId = selectFolderIdFromPathname(state)
  const searchTerm = selectSearchTerm(state)
  const keys = selectKeysAsArray(state)
  const folders = searchTerm
    ? selectAllMediaFoldersArray(state)
    : selectMediaFoldersArray(selectedFolderId)(state)
  return {
    environmentId: selectEnvironmentIdFromPathname(state),
    selectedMediaKeys: selectSelectedMediaKeys(state),
    foldersPathArray: getFoldersPathArray(selectMediaFolders(state), selectedFolderId),
    searchTerm,
    // Note: Media grid support only normal Media and not Common media
    keys: filterMediaByTerms({ selectedFolderId, searchTerm, keys }) as Media[],
    isLoading: selectListFilesIsLoading(state),
    page: selectCurrentPage(state),
    itemsPerPage: selectItemsPerPage(state),
    selectedFolder: selectMediaFolderById(selectedFolderId)(state),
    folders: searchTerm
      ? folders.filter(({ name }) =>
          name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
        )
      : folders
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  selectMedia: (mediaKey: string) => dispatch(selectMedia(mediaKey)),
  deselectMedia: (mediaKey: string) => dispatch(deselectMedia(mediaKey)),
  changePage: (page: number) => dispatch(changePage(page)),
  changeItemsPerPage: (amount: string) => dispatch(changeItemsPerPage(amount)),
  openDialog: (dialogId: string) => dispatch(openDialog(dialogId)),
  downloadMediaFiles: (mediaFiles: Media[]) => dispatch(downloadMediaFiles(mediaFiles))
})

export default connect(mapStateToProps, mapDispatchToProps)(MediaBrowser)
