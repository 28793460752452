import { createSelector } from 'reselect'
import { path } from 'ramda'
import { StylesChild } from '@seesignage/seesignage-utils'
import { StateInterface } from '../types/states'

const selectFormDomain = (state: StateInterface) => state.form

const selectCreateScreenFormType = createSelector(selectFormDomain, (form): string | undefined =>
  path(['CreateScreenForm', 'values', 'type'], form)
)

const selectTemplateFormValues = (formName: string) =>
  createSelector(selectFormDomain, (form): StylesChild | undefined =>
    path([formName, 'values'], form)
  )

const selectAddProductAsPlaylistItemCustomerId = createSelector(selectFormDomain, (form):
  | string
  | undefined => path(['AddProductAsItemForm', 'values', 'customerId', 'value'], form))

const selectFormFieldValue = (form: string, field: string): any =>
  createSelector(selectFormDomain, forms => path([form, 'values', field], forms))

const selectFormValues = (form: string) =>
  createSelector(selectFormDomain, forms => path([form, 'values'], forms))

/** Check if form exists in redux store */
const checkIfFormIsVisible = (form: string) =>
  createSelector(selectFormDomain, forms => !!forms[form])

export {
  selectCreateScreenFormType,
  selectTemplateFormValues,
  selectAddProductAsPlaylistItemCustomerId,
  selectFormFieldValue,
  selectFormValues,
  checkIfFormIsVisible
}
