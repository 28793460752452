import React from 'react'
import { InjectedFormProps, Field, reduxForm } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { Grid, DialogActions, Button } from '@mui/material'
import { connect } from 'react-redux'
import { AutocompleteOption } from '@seesignage/seesignage-utils'
import { CloseDialog } from '../../../types/actions'
import { required } from '../../../validation'
import {
  ReduxDatePicker,
  ReduxTextField,
  ReduxCreatableAutocomplete
} from '../../../components/FormInput/ReduxWrappers'
import SelectTemplate from '../../../components/FormInput/SelectTemplate'
import { StateInterface } from '../../../types/states'
import { selectMasterTemplates } from '../../../selectors/templates'
import { StateTemplate } from '../../../types/templates'
import { CreateCampaignFormData } from '../../../types/campaigns'
import { selectCurrentEnvironmentTagsAsOptions } from '../../../selectors/environments'
import i18n from '../../../translations/i18n'
import ErrorMessage from '../../../components/Errors/ErrorMessage'

interface OwnProps {
  dialogId: string
  submitButtonLabel: string
  submitAction: (formData: any) => void
  closeDialog: CloseDialog
}

interface StateProps {
  templates: StateTemplate[]
  tags: AutocompleteOption[]
}

type CreateCampaignFormProps = OwnProps & StateProps

const CreateCampaignForm: React.FC<CreateCampaignFormProps &
  InjectedFormProps<CreateCampaignFormData, CreateCampaignFormProps>> = ({
  dialogId,
  submitButtonLabel,
  submitAction,
  closeDialog,
  handleSubmit,
  submitting,
  templates,
  tags,
  error
}) => {
  const [t] = useTranslation()

  return (
    <form onSubmit={handleSubmit(submitAction)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Field
            fullWidth
            name='name'
            label={t('general.name')}
            component={ReduxTextField}
            required
            validate={[required]}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            fullWidth
            name='startDate'
            component={ReduxDatePicker}
            label={t('date.startDate')}
            dateformat='DD/MM/YYYY'
            required
            validate={[required]}
            startOfTheDay
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            fullWidth
            name='endDate'
            component={ReduxDatePicker}
            label={t('date.endDate')}
            dateformat='DD/MM/YYYY'
            required
            validate={[required]}
            endOfTheDay
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            fullWidth
            name='tags'
            label={t('screens.tags')}
            helperText={t('campaigns.tagsHelper')}
            component={ReduxCreatableAutocomplete}
            required
            validate={[required]}
            options={tags}
            multiple
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            id='template'
            name='template'
            component={SelectTemplate}
            initialTemplates={templates}
            required
            validate={[required]}
          />
        </Grid>
        <Grid item xs={12}>
          <ErrorMessage message={error} />
        </Grid>
      </Grid>
      <DialogActions>
        <Button color='primary' onClick={() => closeDialog(dialogId)}>
          {t('general.cancel')}
        </Button>
        <Button disabled={submitting} color='primary' type='submit'>
          {submitButtonLabel}
        </Button>
      </DialogActions>
    </form>
  )
}

const validate = (values: any) => {
  const errors: any = {}
  if (values) {
    const { startDate, endDate } = values
    if (startDate > endDate) {
      errors.endDate = i18n.t('error.playlist.scheduleEndDate')
    }
  }
  return errors
}

const mapStateToProps = (state: StateInterface): StateProps => ({
  templates: selectMasterTemplates(state),
  tags: selectCurrentEnvironmentTagsAsOptions(state)
})

export default connect(mapStateToProps)(
  reduxForm<CreateCampaignFormData, CreateCampaignFormProps>({
    form: 'CreateCampaignForm',
    validate
  })(CreateCampaignForm)
)
