import React, { useState } from 'react'
import { Media } from '@seesignage/seesignage-utils'
import { ImageListItem, ImageListItemBar, IconButton, Tooltip, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import CheckCircleIcon from 'mdi-react/CheckCircleIcon'
import CloseCircleIcon from 'mdi-react/CloseCircleIcon'
import { useTranslation } from 'react-i18next'
import useIsUserDeviceMobile from '../../hooks/deviceDetect'
import MediaSkeleton from '../../components/Media/MediaSkeleton'
import VideoQualityIndicator from '../../components/Media/VideoQualityIndicator'
import useLongPress from '../../hooks/longPress'

const useStyles = makeStyles(() => ({
  imageListItem: {
    // overwrite material-ui's list item's image elment
    '& .MuiImageListItem-img': {
      display: 'block',
      margin: 'auto',
      height: '100%',
      width: '100%',
      objectFit: 'contain'
    }
  },
  titleBar: {
    background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)' as any
  },
  video: {
    height: '100%',
    width: '100%',
    display: 'block',
    margin: 'auto'
  },
  selectButton: {
    float: 'right',
    position: 'absolute',
    backgroundColor: 'rgba(255,255,255,0.9)',
    borderRadius: '50%',
    padding: 0,
    top: 6,
    zIndex: 99,
    right: 5
  }
}))

export interface MediaTileProps {
  media: Media
  className?: string
  /** Passed from parent component */
  style?: React.CSSProperties
  handleClick: (media: Media) => void
  selectMedia: (key: string) => void
  deselectMedia: (key: string) => void
  selectedMediaKeys: string[]
}

const MediaTile: React.FC<MediaTileProps> = ({
  media,
  style,
  handleClick,
  selectMedia,
  deselectMedia,
  selectedMediaKeys
}) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const isMobile = useIsUserDeviceMobile()
  const isSelected = selectedMediaKeys.length && selectedMediaKeys.includes(media.key)
  const [checkboxStyles, setCheckboxStyles] = useState({ display: 'none ' })
  const touchEvents = useLongPress(() => selectMedia(media.key))

  const selectionEvents = isMobile
    ? touchEvents
    : {
        onMouseEnter: () => setCheckboxStyles({ display: 'block' }),
        onMouseLeave: () => setCheckboxStyles({ display: 'none' })
      }
  return (
    <ImageListItem
      {...selectionEvents}
      style={{ ...style, cursor: 'pointer' }}
      className={classes.imageListItem}>
      {media.thumbnailUrl || media.url ? (
        media.type === 'image' ? (
          <img onClick={() => handleClick(media)} src={media.thumbnailUrl || media.url} alt='' />
        ) : (
          <video
            className={classes.video}
            onClick={() => handleClick(media)}
            preload='none'
            poster={media.thumbnailUrl}
            src={media.url}
          />
        )
      ) : (
        <MediaSkeleton />
      )}
      <div
        onClick={() =>
          selectedMediaKeys.includes(media.key) ? deselectMedia(media.key) : selectMedia(media.key)
        }>
        <ImageListItemBar
          title={
            <Typography noWrap>
              <VideoQualityIndicator quality={media.quality} /> {media.name}
            </Typography>
          }
          position='top'
          actionIcon={
            <Tooltip
              disableInteractive
              title={t(isSelected ? 'general.deselect' : 'general.select')}>
              <div style={selectedMediaKeys.length > 0 ? { display: 'block' } : checkboxStyles}>
                <IconButton
                  style={{
                    color: !isSelected ? '#AAA' : 'rgba(0, 174, 239, .7)'
                  }}
                  className={classes.selectButton}
                  onClick={() => (isSelected ? selectMedia(media.key) : deselectMedia(media.key))}
                  size='large'>
                  {isSelected ? <CloseCircleIcon /> : <CheckCircleIcon />}
                </IconButton>
              </div>
            </Tooltip>
          }
          actionPosition='right'
          className={classes.titleBar}
        />
      </div>
    </ImageListItem>
  )
}

export default MediaTile
