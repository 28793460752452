import React, { Fragment } from 'react'
import { Paper } from '@mui/material'
import { useSelector } from 'react-redux'
import {
  DataGrid,
  GridColDef,
  GridRowId,
  GridSortDirection,
  GridSortModel,
  GridValueFormatterParams
} from '@mui/x-data-grid'
import {
  CountPlaysByAttribute,
  ProofOfPlayQueryResultRowByChannel,
  ProofOfPlayQueryResultRowByPlaylist,
  QueryProofOfPlayRequest,
  TimeInterval
} from '@seesignage/seesignage-utils'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import dateFormat from 'dateformat'
import { selectProofOfPlayItems, selectProofOfPlayQuery } from '../../../../../selectors/analytics'
import ProofOfPlayTableToolbar from './ProofOfPlayTableToolbar'

const getProofOfPlayTableColumns = ({
  query: { countPlaysByAttribute, interval },
  t
}: {
  query: QueryProofOfPlayRequest
  t: TFunction
}) =>
  [
    ...(countPlaysByAttribute === CountPlaysByAttribute.playlist_id
      ? [
          {
            field: 'playlist_name',
            headerName: t('analytics.proofOfPlay.tableColumn.playlist_name'),
            sortable: true,
            width: 300
          }
        ]
      : []),
    ...(countPlaysByAttribute === CountPlaysByAttribute.channel_id
      ? [
          {
            field: 'channel_name',
            headerName: t('analytics.proofOfPlay.tableColumn.channel_name'),
            sortable: true,
            width: 300
          }
        ]
      : []),
    {
      field: 'screen_name',
      headerName: t('analytics.proofOfPlay.tableColumn.screen_name'),
      width: 200
    },
    {
      field: 'code',
      headerName: t('analytics.proofOfPlay.tableColumn.code'),
      width: 150
    },
    {
      field: 'date_time',
      headerName: t('analytics.proofOfPlay.tableColumn.date_time'),
      width: 150,
      valueFormatter: ({ value }: GridValueFormatterParams<number>) =>
        dateFormat(new Date(value), interval === TimeInterval.day ? 'd.m.yyyy' : 'd.m.yyyy HH:MM')
    },
    {
      field: 'play_count',
      headerName: t('analytics.proofOfPlay.tableColumn.play_count'),
      width: 120,
      type: 'number'
    },
    {
      field: 'total_duration',
      headerName: t('analytics.proofOfPlay.tableColumn.total_duration'),
      width: 150,
      type: 'number',
      // format beacuse we do not want numbers to be formatted with ',' and we need to conver ms to s.
      valueFormatter: ({ value }: GridValueFormatterParams<number>) => `${Math.round(value / 1000)}`
    }
  ] as GridColDef[]

interface PaginationModel {
  page: number
  pageSize: number
}

const ProofOfPlayTable = () => {
  const [t] = useTranslation()
  const items = useSelector(selectProofOfPlayItems)
  const query = useSelector(selectProofOfPlayQuery)
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'date_time',
      sort: 'asc' as GridSortDirection
    }
  ])
  const [paginationModel, setPaginationModel] = React.useState<PaginationModel>({
    page: 0,
    pageSize: 100
  })
  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([])

  return (
    <Fragment>
      {items && query && (
        <Paper>
          <DataGrid
            slots={{ toolbar: ProofOfPlayTableToolbar }}
            density='compact'
            autoHeight
            rowSelectionModel={selectionModel}
            onRowSelectionModelChange={newSelectionModel => {
              setSelectionModel(newSelectionModel)
            }}
            sortModel={sortModel}
            onSortModelChange={model => setSortModel(model)}
            rows={items}
            columns={getProofOfPlayTableColumns({ query, t })}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[25, 50, 100]}
            getRowId={(
              row: ProofOfPlayQueryResultRowByPlaylist | ProofOfPlayQueryResultRowByChannel
            ) => {
              if (query.countPlaysByAttribute === CountPlaysByAttribute.playlist_id) {
                return `${row.screen_id}_${
                  (row as ProofOfPlayQueryResultRowByPlaylist).playlist_id
                }_${row.date_time}_${row.total_duration}`
              }
              if (query.countPlaysByAttribute === CountPlaysByAttribute.channel_id) {
                return `${row.screen_id}_${
                  (row as ProofOfPlayQueryResultRowByChannel).channel_id
                }_${row.date_time}_${row.total_duration}`
              }
              return 'todo'
            }}
          />
        </Paper>
      )}
    </Fragment>
  )
}

export default ProofOfPlayTable
