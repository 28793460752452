import { Button, DialogActions } from '@mui/material'
import { Template } from '@seesignage/seesignage-utils'

import { TFunction } from 'i18next'
import React, { useEffect } from 'react'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { ReduxTextField } from '../../../components/FormInput/ReduxWrappers'
import { InfopageSettingsFormData } from '../../../types/infopages'
import { required } from '../../../validation'
import { StateInterface } from '../../../types/states'
import { listTemplates } from '../../../actions/templates'
import { compareStrings } from '../../../utils/sorting'
import ErrorMessage from '../../../components/Errors/ErrorMessage'
import SelectTemplateField from '../../../components/FormInput/SelectTemplate'
import { selectTemplatesByType } from '../../../selectors/templates'

interface OwnProps {
  submitAction: (formData: any) => void
  submitButtonLabel: string
  t: TFunction
}

interface StateProps {
  templates: Template[]
}

interface DispatchProps {
  listTemplates: () => void
}

type InfopageSettingsProps = OwnProps & StateProps & DispatchProps

/**
 * Form component to edit name and template of infopage.
 *  - Must include:
 *    - Template options
 *    - Submit action
 */
const InfopageSettings: React.FC<InfopageSettingsProps &
  InjectedFormProps<InfopageSettingsFormData, InfopageSettingsProps>> = ({
  templates,
  handleSubmit,
  submitting,
  submitAction,
  listTemplates,
  submitButtonLabel,
  error,
  t
}) => {
  useEffect(() => {
    listTemplates()
  }, [listTemplates])
  return (
    <form onSubmit={handleSubmit(submitAction)}>
      <Field
        id='name'
        name='name'
        label={t('general.name')}
        component={ReduxTextField}
        fullWidth
        required
        validate={[required]}
      />
      <div style={{ marginTop: 10 }}>
        <Field
          id='template'
          name='template'
          component={SelectTemplateField}
          initialTemplates={templates}
          required
          validate={[required]}
        />
      </div>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button disabled={submitting} type='submit' color='primary'>
          {submitButtonLabel}
        </Button>
      </DialogActions>
      {error && <ErrorMessage message={error} />}
    </form>
  )
}
const mapStateToProps = (state: StateInterface): StateProps => ({
  templates: selectTemplatesByType('info')(state).sort(compareStrings('name')) as Template[]
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  listTemplates: () => dispatch(listTemplates())
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<InfopageSettingsFormData, InfopageSettingsProps>({
    form: 'InfopageSettingsForm'
  })(InfopageSettings)
)
