import users from './users'
import routes from './routes'
import environments from './environments'
import screens from './screens'
import confirmations from './confirmations'
import playlists from './playlists'
import media from './media'
import iot from './iot'
import lists from './lists'
import lunchLists from './lunchLists'
import feedback from './feedback'
import products from './products'
import templates from './templates'
import customers from './customers'
import channels from './channels'
import dialog from './dialog'
import forms from './forms'
import infopages from './infopages'
import mocks from './mocks'
import contents from './contents'
import campaigns from './campaigns'
import analytics from './analytics'

export default [
  ...users,
  ...routes,
  ...environments,
  ...screens,
  ...confirmations,
  ...lists,
  ...lunchLists,
  ...playlists,
  ...media,
  ...iot,
  ...feedback,
  ...products,
  ...templates,
  ...customers,
  ...channels,
  ...dialog,
  ...forms,
  ...infopages,
  ...mocks,
  ...contents,
  ...campaigns,
  ...analytics
]
