import {
  AppBar,
  Button,
  DialogActions,
  DialogContent,
  LinearProgress,
  Toolbar
} from '@mui/material'
import PlusIcon from 'mdi-react/PlusIcon'
import SettingsIcon from 'mdi-react/SettingsIcon'
import RefreshIcon from 'mdi-react/RefreshIcon'
import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { TFunction } from 'i18next'
import { ListType, Customer, ProductForm } from '@seesignage/seesignage-utils'
import { closeDialog } from '../../actions/dialogs'
import {
  createOrUpdateGeneralProduct,
  createOrUpdateRetailProduct,
  updateProductDetails
} from '../../actions/products'
import {
  CloseDialog,
  CreateOrUpdateGeneralProduct,
  CreateOrUpdateRetailProduct
} from '../../types/actions'
import { bindSubmitActionToPromise } from '../../utils/forms'
import Dialog from '../Dialog'
import CreateProductWizard from '../Lists/Forms/CreateProductWizard/CreateProductWizard'
import CreateRetailProductForm from '../Lists/Forms/CreateRetailProductForm'
import { StateInterface } from '../../types/states'

import { selectAreSokProductsUpdating } from '../../selectors/products'
import SearchField from '../../components/SearchField'
import SelectCustomerForm from './Forms/SelectCustomerForm'

interface RenderCreateButtonProps {
  productForm?: ProductForm
  createOrUpdateGeneralProduct: CreateOrUpdateGeneralProduct
  createOrUpdateRetailProduct: CreateOrUpdateRetailProduct
  closeDialog: CloseDialog
  t: TFunction
}

const renderCreateButton = ({
  productForm,
  createOrUpdateGeneralProduct,
  createOrUpdateRetailProduct,
  closeDialog,
  t
}: RenderCreateButtonProps) => (
  <Dialog
    dialogId={`CreateProductDialog`}
    title={t('lists.createProduct')}
    tooltipTitle={t('lists.createProduct')}
    ButtonIcon={PlusIcon}
    maxWidth={productForm === ProductForm.general ? 'xl' : 'md'}
    Content={
      productForm === ProductForm.general ? (
        <CreateProductWizard
          onSubmit={createOrUpdateGeneralProduct}
          submitButtonLabel={t('general.create')}
        />
      ) : (
        <CreateRetailProductForm
          dialogId={`CreateProductDialog`}
          onSubmit={createOrUpdateRetailProduct}
          submitButtonLabel={t('general.create')}
          closeDialog={closeDialog}
        />
      )
    }
  />
)

export interface ProductsTableToolbarOwnProps {
  selectedCustomer?: Customer
  selectedProductType?: ListType
  search: React.Dispatch<React.SetStateAction<string>>
  t: TFunction
}

interface StateProps {
  productsUpdating: boolean
}

interface DispatchProps {
  createOrUpdateGeneralProduct: CreateOrUpdateGeneralProduct
  createOrUpdateRetailProduct: CreateOrUpdateRetailProduct
  closeDialog: CloseDialog
  updateProductDetails: () => void
}

type ProductsTableToolbalProps = StateProps & DispatchProps & ProductsTableToolbarOwnProps

const ProductsTableToolbar: React.FC<ProductsTableToolbalProps> = ({
  selectedCustomer,
  selectedProductType,
  search,
  productsUpdating,
  createOrUpdateGeneralProduct,
  createOrUpdateRetailProduct,
  closeDialog,
  updateProductDetails,
  t
}) => (
  <AppBar position='sticky' color='default'>
    <Toolbar>
      <SearchField onChange={e => search(e.target.value)} placeholder={t('general.search')} />
      <div style={{ flex: '1 1 100%' }} />
      {selectedCustomer?.images?.foodie && (
        <Dialog
          dialogId='UpdateSokProducts'
          title={t('customers.updateProducts')}
          tooltipTitle={t('customers.updateProducts')}
          ButtonIcon={RefreshIcon}
          Content={
            <div>
              <DialogContent>
                {t('customers.updateProductImages', { customerName: selectedCustomer.name })}
              </DialogContent>
              <DialogActions>
                <Button
                  disabled={productsUpdating}
                  color='primary'
                  onClick={() => closeDialog('UpdateSokProducts')}>
                  {t('general.cancel')}
                </Button>
                <Button
                  disabled={productsUpdating}
                  color='primary'
                  onClick={() => updateProductDetails()}>
                  {t('general.update')}
                </Button>
              </DialogActions>
              {productsUpdating && <LinearProgress />}
            </div>
          }
        />
      )}
      {selectedCustomer?.productForm &&
        renderCreateButton({
          productForm: selectedCustomer.productForm,
          createOrUpdateGeneralProduct,
          createOrUpdateRetailProduct,
          closeDialog,
          t
        })}
      <Dialog
        dialogId='SelectCustomerDialog'
        title={t('product.selectCustomer')}
        tooltipTitle={t('product.selectCustomer')}
        ButtonIcon={SettingsIcon}
        Content={
          <SelectCustomerForm
            initialValues={{
              customerId: selectedCustomer
                ? { value: selectedCustomer.customerId, label: selectedCustomer.name }
                : undefined,
              type: selectedProductType
            }}
          />
        }
      />
    </Toolbar>
  </AppBar>
)

const mapStateToProps = (state: StateInterface): StateProps => ({
  productsUpdating: selectAreSokProductsUpdating(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  createOrUpdateGeneralProduct: bindSubmitActionToPromise(dispatch, createOrUpdateGeneralProduct),
  createOrUpdateRetailProduct: bindSubmitActionToPromise(dispatch, createOrUpdateRetailProduct),
  closeDialog: (dialogId: string) => dispatch(closeDialog(dialogId)),
  updateProductDetails: () => dispatch(updateProductDetails())
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductsTableToolbar)
