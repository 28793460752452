import React, { Fragment } from 'react'
import { Field } from 'redux-form'
import { Grid } from '@mui/material'
import { ComponentType } from '@seesignage/seesignage-utils'
import { useTranslation } from 'react-i18next'
import { ReduxTextField } from '../../../../components/FormInput/ReduxWrappers'
import { generateKeyFieldPrefix, gridItemProps } from './utils'
import SpacingFields from './CommonFields/SpacingFields'
import SizeFields from './CommonFields/SizeFields'
import PositionFields from './CommonFields/PositionFields'
import BorderFields from './CommonFields/BorderFields'
import EffectsFields from './CommonFields/EffectsFields'

interface QrCodeFieldsProps {
  id: string
  level: number
  childIndexes: number[]
  type: ComponentType
}

const QrCodeFields = ({ level, childIndexes, id }: QrCodeFieldsProps) => {
  const [t] = useTranslation()
  const fieldProps = {
    level,
    childIndexes,
    t
  }
  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid {...gridItemProps}>
          <Field
            fullWidth
            name={generateKeyFieldPrefix(level, childIndexes, 'qrCodeOptions.url')}
            label='Url'
            component={ReduxTextField}
          />
        </Grid>
        <Grid {...gridItemProps}>
          <Field
            fullWidth
            name={generateKeyFieldPrefix(level, childIndexes, 'qrCodeOptions.size')}
            label='Size (px)'
            component={ReduxTextField}
            type='number'
          />
        </Grid>
        <Grid {...gridItemProps}>
          <Field
            fullWidth
            name={generateKeyFieldPrefix(level, childIndexes, 'qrCodeOptions.bgColor')}
            label='Background color'
            component={ReduxTextField}
          />
        </Grid>
        <Grid {...gridItemProps}>
          <Field
            fullWidth
            name={generateKeyFieldPrefix(level, childIndexes, 'qrCodeOptions.fgColor')}
            label='Foreground color'
            component={ReduxTextField}
          />
        </Grid>
      </Grid>

      <PositionFields {...fieldProps} />
      <SpacingFields {...fieldProps} />
      <SizeFields {...fieldProps} />
      <BorderFields {...fieldProps} />
      {/* TODO */}
      <EffectsFields id={id} {...fieldProps} type={ComponentType.image} />
    </Fragment>
  )
}

export default QrCodeFields
