import {
  LunchListWeek,
  LunchListWeekDay,
  LunchGroupById,
  LunchProductById,
  LunchItem,
  LunchProduct,
  LunchListWeekDays,
  SpecialDiet,
  LunchListDayChanges,
  ProductChangeForDay
} from '@seesignage/seesignage-utils/lib/types/lunchLists'
import { path } from 'ramda'
import { TFunction } from 'i18next'
import {
  LunchListWeekFormDays,
  LunchListDayUI,
  SelectedLunchItem,
  SelectedLunchItemType,
  AddLunchProductChangesFormData
} from '../types/lists'
import { compareStrings } from './sorting'

const generateEmptyLunchListWeekDays = (weekdays: LunchListWeekFormDays) =>
  Object.keys(weekdays).reduce((newWeekdays, day) => {
    // if day is selected in form
    if (weekdays[day as LunchListWeekDay]) {
      newWeekdays[day as LunchListWeekDay] = {
        lunchItems: []
      }
    }
    return newWeekdays
  }, {} as LunchListWeekDays)

const getLunchListDaysArray = (week: LunchListWeek) =>
  Object.keys(week.days).reduce((days, day) => {
    const lunchListDay = week.days[day as LunchListWeekDay]
    if (lunchListDay) {
      days.push({ ...lunchListDay, day: `${day}` as LunchListWeekDay })
    }
    return days
  }, [] as LunchListDayUI[])

const selectLunchListGroupsAsOptions = (groups: LunchGroupById) =>
  Object.values(groups)
    .sort(compareStrings('name'))
    .map(({ groupId, name }) => ({ value: groupId, label: name }))

const selectLunchListProductsAsOptions = (products: LunchProductById) =>
  Object.values(products)
    .sort(compareStrings('name'))
    .map(({ productId, name }) => ({ value: productId, label: name }))

const checkIfProductExistsInWeeks = (productId: string, weeks?: LunchListWeek[]) =>
  weeks?.some(week => {
    Object.values(week.days).some(day =>
      day?.lunchItems.some(({ productId: id }) => id === productId)
    )
  })

const getProductsThatDoesNotExistInWeeks = (
  productsById: LunchProductById,
  weeks?: LunchListWeek[]
) => {
  const weekProductsById = weeks
    ? Object.values(weeks).reduce((existingProductsById, week) => {
        for (const day of Object.values(week.days)) {
          if (day) {
            for (const item of day.lunchItems) {
              if (productsById[item.productId]) {
                existingProductsById[item.productId] = productsById[item.productId]
              }
            }
          }
        }
        return existingProductsById
      }, {} as LunchProductById)
    : ({} as LunchProductById)

  return Object.values(productsById).filter(({ productId }) => !weekProductsById[productId])
}

const selectAddLunchProductChangesFormInitialValues = (
  selectedItem: SelectedLunchItem,
  weeks: LunchListWeek[]
) => {
  if (
    selectedItem?.type === SelectedLunchItemType.lunchItem &&
    selectedItem.day &&
    selectedItem.weekIndex !== undefined
  ) {
    const productId = (selectedItem.item as LunchItem).productId

    const initialValues: AddLunchProductChangesFormData = {
      weekIndex: selectedItem.weekIndex,
      day: selectedItem.day,
      existingProductId: productId
    }

    const currentProductChange: ProductChangeForDay | undefined = path(
      [selectedItem.weekIndex, 'days', selectedItem.day, 'changes', productId],
      weeks
    )

    if (currentProductChange) {
      const initialValues: AddLunchProductChangesFormData = {
        weekIndex: selectedItem.weekIndex,
        day: selectedItem.day,
        existingProductId: productId,
        isHidden: currentProductChange.isHidden
      }
      if (currentProductChange.productId) {
        // autcomplete option
        initialValues.temporaryProductId = {
          value: currentProductChange.productId
        } as any
      }
      return initialValues
    }
    return initialValues
  }
  return {}
}

const selectUpdateLunchListProductFormInitialValues = ({
  name,
  specialDiet,
  productId,
  groupId
}: LunchProduct) => {
  return {
    // group must be autocomplete option value
    groupId: groupId
      ? {
          label: '',
          value: groupId
        }
      : undefined,
    name,
    specialDiet,
    // add productId so handleCreateOrUpdateLunchListProduct knows to update
    productId
  }
}

const getLunchListSpecialDietsOrder = () => [
  SpecialDiet.lactoseFree,
  SpecialDiet.lowLactose,
  SpecialDiet.glutenFree,
  SpecialDiet.vegetarian,
  SpecialDiet.vegan,
  SpecialDiet.dairyFree,
  SpecialDiet.eggFree
]

interface IsOverMaxItemsParams {
  currentLunchItems: LunchItem[]
  currentIndex: number
  maxProductsInDay?: number
  dayChanges?: LunchListDayChanges
}

/**
 * Check if current lunch list item is over max products in a day limit. This function
 * needs to be called in every item render because keeping count in Weeks render function and map
 * caused some problems that changes did not occour always...
 */
const isOverMaxItems = ({
  currentLunchItems,
  currentIndex,
  maxProductsInDay,
  dayChanges
}: IsOverMaxItemsParams) => {
  if (maxProductsInDay !== undefined) {
    let visibleItems = 0
    for (let index = 0; index < currentIndex; index++) {
      const lunchItem = currentLunchItems[index]
      const productChanges = dayChanges ? dayChanges[lunchItem.productId] : undefined
      const isHidden = productChanges?.isHidden ? true : false
      if (!isHidden) {
        ++visibleItems
      }
    }
    return visibleItems >= maxProductsInDay
  }
  return false
}

const getDiets = (t: TFunction) => [
  {
    label: t('lists.lunch.diets.lactoseFree'),
    name: 'lactoseFree'
  },
  {
    label: t('lists.lunch.diets.lowLactose'),
    name: 'lowLactose'
  },
  {
    label: t('lists.lunch.diets.glutenFree'),
    name: 'glutenFree'
  },
  {
    label: t('lists.lunch.diets.vegetarian'),
    name: 'vegetarian'
  },
  {
    label: t('lists.lunch.diets.vegan'),
    name: 'vegan'
  },
  {
    label: t('lists.lunch.diets.dairyFree'),
    name: 'dairyFree'
  },
  {
    label: t('lists.lunch.diets.eggFree'),
    name: 'eggFree'
  }
]

export {
  generateEmptyLunchListWeekDays,
  getLunchListDaysArray,
  selectLunchListGroupsAsOptions,
  selectLunchListProductsAsOptions,
  checkIfProductExistsInWeeks,
  getProductsThatDoesNotExistInWeeks,
  selectAddLunchProductChangesFormInitialValues,
  selectUpdateLunchListProductFormInitialValues,
  getLunchListSpecialDietsOrder,
  isOverMaxItems,
  getDiets
}
