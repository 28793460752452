import React, { Fragment, useEffect } from 'react'
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon'
import { ImageList, IconButton, ImageListItemBar, ImageListItem, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { MediaType, Media } from '@seesignage/seesignage-utils'
import { useSelector, useDispatch } from 'react-redux'
import { selectMediaByKey } from '../../../selectors/media'
import { getFileUrls } from '../../../actions/media'

const useStyles = makeStyles(() => ({
  title: {
    fontSize: '0.8rem'
  },
  imageListItem: {
    // overwrite material-ui's list item's image elment
    '& .MuiImageListItem-img': {
      display: 'block',
      margin: 'auto',
      height: '100%',
      width: '100%',
      objectFit: 'contain'
    }
  },
  video: {
    height: '100%',
    width: '100%',
    display: 'block',
    margin: 'auto'
  },
  imageListItemBarTop: {
    background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)' as any
  }
}))

interface SingleMediaPreviewProps {
  isMobile: boolean
  mediaKey: string
  deselectMedia: (key: string) => void
}

const SingleMediaPreview: React.FC<SingleMediaPreviewProps> = ({
  isMobile,
  mediaKey,
  deselectMedia
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const media: Media | undefined = useSelector(selectMediaByKey(mediaKey))

  useEffect(() => {
    if (media && media.url === undefined) {
      // when media exists but does not have url, get url for media to store.
      dispatch(getFileUrls({ keys: [media.key] }))
    }
  }, [media, dispatch])

  return (
    <Fragment>
      {media && (
        <ImageList rowHeight={isMobile ? 125 : 188}>
          <ImageListItem className={classes.imageListItem}>
            {media.type === MediaType.image && <img src={media.thumbnailUrl || media.url} alt='' />}
            {media.type === MediaType.video && (
              <video
                className={classes.video}
                src={media.url}
                poster={media.thumbnailUrl}
                controls
                preload='none'
              />
            )}
            <ImageListItemBar
              className={classes.imageListItemBarTop}
              title={<Typography noWrap>{media.name}</Typography>}
              classes={{ title: classes.title }}
              position='top'
              actionIcon={
                <IconButton
                  onClick={() => {
                    deselectMedia(mediaKey)
                  }}
                  size='large'>
                  <CloseCircleOutlineIcon color='white' />
                </IconButton>
              }
              actionPosition='right'
            />
          </ImageListItem>
        </ImageList>
      )}
    </Fragment>
  )
}

export default SingleMediaPreview
