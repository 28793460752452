import React, { useState, useEffect } from 'react'
import { SwipeableDrawer, Typography, IconButton, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useSelector, useDispatch } from 'react-redux'
import CloseIcon from 'mdi-react/CloseIcon'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useDropzone } from 'react-dropzone'
import {
  selectQueryParamFromSearch,
  selectEnvironmentIdFromPathname
} from '../../../selectors/routing'
import PlaylistUnsavedChanges from '../Notification/PlaylistUnsavedChanges'
import { selectSelectedPlaylist, selectIsPlaylistModified } from '../../../selectors/playlists'
import { deselectPlaylist, setPlaylistItemWizardPage } from '../../../actions/playlists'
import DragDropInfo from '../../../components/Media/DragDropInfo'
import { openDialog, setDialogSize } from '../../../actions/dialogs'
import { PageType } from '../../../types/playlists'
import { getFilesWithPreview } from '../../../utils/files'
import { selectDialogVisibility } from '../../../selectors/dialogs'
import { setDropzoneFiles } from '../../../actions/media'
import { SetDropzoneFilesActionParams } from '../../../types/media'
import EditPlaylist from './EditPlaylist'

const useStyles = makeStyles(theme => ({
  drawerArea: {
    height: '100%'
  },
  editPlaylistDrawer: {
    padding: '8px 0px 8px 0px',
    width: 700,
    [theme.breakpoints.down('lg')]: {
      width: '90vw'
    },
    [theme.breakpoints.down('md')]: {
      width: '100vw'
    },
    height: '90%'
  },
  drawerTitle: {
    position: 'relative',
    width: '100%',
    textAlign: 'center',
    height: 50
  },
  closeDrawerButton: {
    position: 'absolute',
    left: 0,
    top: 0
  }
}))

const EditPlaylistDrawer = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const environmentId = useSelector(selectEnvironmentIdFromPathname)
  const selectedPlaylist = useSelector(selectSelectedPlaylist)
  const isModified = useSelector(selectIsPlaylistModified)
  const dialogVisibility = useSelector(selectDialogVisibility)
  const pathPlaylistId = useSelector(selectQueryParamFromSearch('playlistId'))

  const [alertOpen, setAlertOpen] = useState(false)

  const handleCloseDrawer = () => {
    if (!isModified) {
      dispatch(deselectPlaylist())
      history.replace({ search: undefined }) // remove queryparam
      setAlertOpen(false)
    } else {
      setAlertOpen(true)
    }
  }

  useEffect(() => {
    //component will unmount
    return () => {
      handleCloseDrawer()
    }
  }, [environmentId]) // eslint-disable-line

  // media drag and drop to playlist
  const { getRootProps, isDragActive } = useDropzone({
    onDrop: files => {
      dispatch(setDialogSize({ maxWidth: 'xl' }))
      dispatch(openDialog('AddPlaylistItemWizard'))
      dispatch(setPlaylistItemWizardPage(PageType.media))
      const setDropzoneFilesActionParams: SetDropzoneFilesActionParams = {
        files: getFilesWithPreview(files),
        formName: 'AddExistingMediaForm',
        formFieldName: 'existingFiles'
      }
      dispatch(setDropzoneFiles(setDropzoneFilesActionParams))
    },
    noClick: true,
    noKeyboard: true,
    disabled: dialogVisibility.isVisible // disable dropzone when dialog is open.
    // NOTE: do not use accept here because we want to allow all files because redux-form
    // handles the file type validation.
  })

  return (
    <SwipeableDrawer
      anchor='right'
      open={pathPlaylistId ? true : false}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onOpen={() => {}}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClose={handleCloseDrawer}
      disableSwipeToOpen>
      <div
        {...getRootProps({
          className: 'dropzone'
        })}
        style={{ height: '100%' }}>
        <div className={classes.drawerArea} id='drawer-area'>
          <div className={classes.editPlaylistDrawer}>
            <div className={classes.drawerTitle}>
              <Tooltip disableInteractive title={t('general.close')}>
                <IconButton
                  onClick={handleCloseDrawer}
                  className={classes.closeDrawerButton}
                  size='large'>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <Typography variant='h6'>{selectedPlaylist?.name}</Typography>
            </div>
            <div>
              <EditPlaylist />
              <PlaylistUnsavedChanges
                open={alertOpen}
                closeNotification={() => setAlertOpen(false)}
              />
            </div>
            <DragDropInfo
              open={isDragActive}
              label={t('playlists.editPlaylist.dragAndDropMedia')}
            />
          </div>
        </div>
      </div>
    </SwipeableDrawer>
  )
}

export default EditPlaylistDrawer
