const getFishLatinNamesAsOptions = () =>
  [
    {
      label: 'Sulkava',
      value: 'Abramis ballerus'
    },
    {
      label: 'Lahna',
      value: 'Abramis brama'
    },
    {
      label: 'Siperiansampi',
      value: 'Acipenser baerii'
    },
    {
      label: 'Venäjänsampi',
      value: 'Acipenser gueldenstaedti'
    },
    {
      label: 'Sterletti',
      value: 'Acipenser ruthenus'
    },
    {
      label: 'Tähtisampi',
      value: 'Acipenser stellatus'
    },
    {
      label: 'Sampi',
      value: 'Acipenser sturio'
    },
    {
      label: 'Valkosampi',
      value: 'Acipenser transmontanus'
    },
    {
      label: 'Salakka',
      value: 'Alburnus alburnus'
    },
    {
      label: 'Täpläsilli',
      value: 'Alosa agone'
    },
    {
      label: 'Pilkkusilli',
      value: 'Alosa alosa'
    },
    {
      label: 'Sarviviilakala',
      value: 'Aluterus monoceros'
    },
    {
      label: 'Pikkutuulenkala',
      value: 'Ammodytes tobianus'
    },
    {
      label: 'Merikissa',
      value: 'Anarhichas lupus'
    },
    {
      label: 'Kirjomerikissa',
      value: 'Anarhichas minor'
    },
    {
      label: 'Raita-anjovis',
      value: 'Anchoa hepsetus'
    },
    {
      label: 'Länsi-Atlantin anjovis',
      value: 'Anchoa mitchilli'
    },
    {
      label: 'Ankerias',
      value: 'Anguilla anguilla'
    },
    {
      label: 'Japaninankerias',
      value: 'Anguilla japonica'
    },
    {
      label: 'Amerikanankerias',
      value: 'Anguilla rostrata'
    },
    {
      label: 'Sinisiimaturska',
      value: 'Antimora rostrata'
    },
    {
      label: 'Mustahuotrakala',
      value: 'Aphanopus carbo'
    },
    {
      label: 'Kultakuore',
      value: 'Argentina silus'
    },
    {
      label: 'Hopeakuore',
      value: 'Argentina sphyraena'
    },
    {
      label: 'Kotkakala',
      value: 'Argyrosomus regius'
    },
    {
      label: 'Suomukampela',
      value: 'Arnoglossus laterna'
    },
    {
      label: 'Toutain',
      value: 'Aspius aspius'
    },
    {
      label: 'Jokirapu, rapu',
      value: 'Astacus astacus'
    },
    {
      label: 'Kapeasaksirapu',
      value: 'Astacus leptodactylus'
    },
    {
      label: 'Hopeakylki',
      value: 'Atherina presbyter'
    },
    {
      label: 'Auksidi',
      value: 'Auxis thazard'
    },
    {
      label: 'Kivennuoliainen',
      value: 'Barbatula barbatula'
    },
    {
      label: 'Nokkakala',
      value: 'Belone belone'
    },
    {
      label: 'Pohjanlimapää',
      value: 'Beryx decadactylus'
    },
    {
      label: 'Pasuri',
      value: 'Blicca bjoerkna'
    },
    {
      label: 'Boga',
      value: 'Boops boops'
    },
    {
      label: 'Jäämerenseiti',
      value: 'Boreogadus saida'
    },
    {
      label: 'Merilahna',
      value: 'Brama brama'
    },
    {
      label: 'Keila',
      value: 'Brosme brosme'
    },
    {
      label: 'Metsästystorvikotilo',
      value: 'Buccinum undatum'
    },
    {
      label: 'Sinitaskurapu',
      value: 'Callinectes sapidus'
    },
    {
      label: 'Isotaskurapu',
      value: 'Cancer pagurus'
    },
    {
      label: 'Ruutana',
      value: 'Carassius carassius'
    },
    {
      label: 'Liekkikala',
      value: 'Cepola macrophthalma'
    },
    {
      label: 'Sydänsimpukka',
      value: 'Cerastoderma edule'
    },
    {
      label: 'Jättiläishai',
      value: 'Cetorhinus maximus'
    },
    {
      label: 'Isoabaloni, punataskurapu',
      value: 'Chaceon maritae'
    },
    {
      label: 'Kyhmyhuulikala',
      value: 'Cheilinus undulatus'
    },
    {
      label: 'Isokurnusimppu',
      value: 'Chelidonichthys lucernus'
    },
    {
      label: 'Jappirapu',
      value: 'Cherax destructor'
    },
    {
      label: 'Marronirapu',
      value: 'Cherax tenuimanus'
    },
    {
      label: 'Sillikuningas',
      value: 'Chimaera monstrosa'
    },
    {
      label: 'Lumitaskurapu',
      value: 'Chionoecoetes opilio'
    },
    {
      label: 'Jalokampasimpukka',
      value: 'Chlamys nobilis'
    },
    {
      label: 'Kuningatarkampasimpukka',
      value: 'Chlamys opercularis'
    },
    {
      label: 'Purppurahuulikala',
      value: 'Choerodon cephalotes'
    },
    {
      label: 'Silli',
      value: 'Clupea harengus harengus'
    },
    {
      label: 'Silakka',
      value: 'Clupea harengus membras'
    },
    {
      label: 'Tyynenmerensilli',
      value: 'Clupea pallasii'
    },
    {
      label: 'Saira',
      value: 'Cololabis saira'
    },
    {
      label: 'Meriankerias',
      value: 'Conger conger'
    },
    {
      label: 'Muikku, maiva, hottamuikku',
      value: 'Coregonus albula'
    },
    {
      label: 'Amerikanmuikku',
      value: 'Coregonus artedi'
    },
    {
      label: 'Kanadansiika, sillisiika',
      value: 'Coregonus clupeaformis'
    },
    {
      label: 'Siika',
      value: 'Coregonus lavaretus'
    },
    {
      label: 'Peledsiika',
      value: 'Coregonus peled'
    },
    {
      label: 'Dolfiini',
      value: 'Coryphaena hippurus'
    },
    {
      label: 'Lestikala',
      value: 'Coryphaenoides rupestris'
    },
    {
      label: 'Kivisimppu',
      value: 'Cottus gobio'
    },
    {
      label: 'Hietakatkarapu',
      value: 'Crangon crangon'
    },
    {
      label: 'Osteri',
      value: 'Crassostrea gigas'
    },
    {
      label: 'Ruohokarppi',
      value: 'Ctenopharyngodon idella'
    },
    {
      label: 'Rasvakala',
      value: 'Cyclopterus lumpus'
    },
    {
      label: 'Etelänkielikampela',
      value: 'Cynoglossus bilineatus'
    },
    {
      label: 'Karppi',
      value: 'Cyprinus carpio'
    },
    {
      label: 'Hammasahven',
      value: 'Dentex dentex'
    },
    {
      label: 'Isosilmähammasahven',
      value: 'Dentex macrophtalmus'
    },
    {
      label: 'Kultamurisija',
      value: 'Diagramma pictum'
    },
    {
      label: 'Meribassi',
      value: 'Dicentrarchus labrax'
    },
    {
      label: 'Täpläbassi',
      value: 'Dicentrarchus punctatus'
    },
    {
      label: 'Silorausku',
      value: 'Dipturus batis'
    },
    {
      label: 'Hammasjääahven',
      value: 'Dissostichus eleginoides'
    },
    {
      label: 'Jättiläiskalmari',
      value: 'Dosidicus gigas'
    },
    {
      label: 'Myskitursas',
      value: 'Eledone moschata'
    },
    {
      label: 'Navaga',
      value: 'Eleginus navaga'
    },
    {
      label: 'Jättisiimaevä',
      value: 'Eleutheronema tetradactylum'
    },
    {
      label: 'Argentiinan anjovis',
      value: 'Engraulis anchoita'
    },
    {
      label: 'Anjovis',
      value: 'Engraulis encrasicolus'
    },
    {
      label: 'Perun anjovis',
      value: 'Engraulis ringens'
    },
    {
      label: 'Mustakardinaaliahven',
      value: 'Epigonus telescopus'
    },
    {
      label: 'Valkomeriahven',
      value: 'Epinephelus auneus'
    },
    {
      label: 'Oranssitäplämeriahven',
      value: 'Epinephelus coioides'
    },
    {
      label: 'Tummameriahven',
      value: 'Epinephelus marginatus'
    },
    {
      label: 'Punameriahven',
      value: 'Epinephelus morio'
    },
    {
      label: 'Keganinrapu',
      value: 'Erimacrus isenbecki'
    },
    {
      label: 'Hauki',
      value: 'Esox lucius'
    },
    {
      label: 'Etelänkrilli',
      value: 'Euphausia superba'
    },
    {
      label: 'Kawakawa',
      value: 'Euthynnus affinis'
    },
    {
      label: 'Tunniina',
      value: 'Euthynnus alletteratus'
    },
    {
      label: 'Mustaboniitti',
      value: 'Euthynnus lineatus'
    },
    {
      label: 'Kyhmykurnusimppu',
      value: 'Eutrigla gurnardus'
    },
    {
      label: 'Intian katkarapu',
      value: 'Fenneropenaeus indicus'
    },
    {
      label: 'Banaanikatkarapu',
      value: 'Fenneropenaeus merguiensis'
    },
    {
      label: 'Tyynenmerenturska',
      value: 'Gadus macrocephalus'
    },
    {
      label: 'Turska',
      value: 'Gadus morhua'
    },
    {
      label: 'Grönlanninturska',
      value: 'Gadus ogac'
    },
    {
      label: 'Kolmipiikki',
      value: 'Gasterosteus aculeatus'
    },
    {
      label: 'Punarihmanilkka',
      value: 'Genypterus blacodes'
    },
    {
      label: 'Mustaeväkampela',
      value: 'Glyptocephalus cynoglossus'
    },
    {
      label: 'Törö',
      value: 'Gobio gobio'
    },
    {
      label: 'Haimakrilli',
      value: 'Grammatorcynus bicarinatus'
    },
    {
      label: 'Kiiski',
      value: 'Gymnocephalus cernuus'
    },
    {
      label: 'Liejukampela',
      value: 'Hippoglossoides platessoides'
    },
    {
      label: 'Ruijanpallas, pallas',
      value: 'Hippoglossus hippoglossus'
    },
    {
      label: 'Tyynenmerenpallas',
      value: 'Hippoglossus stenolepis'
    },
    {
      label: 'Amerikanhummeri',
      value: 'Homarus americanus'
    },
    {
      label: 'Hummeri',
      value: 'Homarus gammarus'
    },
    {
      label: 'Tonavanjokilohi',
      value: 'Hucho hucho'
    },
    {
      label: 'Kitasampi',
      value: 'Huso huso'
    },
    {
      label: 'Isotuulenkala',
      value: 'Hyperoplus lanceolatus'
    },
    {
      label: 'Hopeakarppi, hopeapaksuotsa',
      value: 'Hypophthalmichthys molitrix'
    },
    {
      label: 'Marmorikarppi, marmoripaksuotsa',
      value: 'Hypophthalmichthys nobilis'
    },
    {
      label: 'Piikkimonni',
      value: 'Ictalurus nebulosus'
    },
    {
      label: 'Pohjankalmari',
      value: 'Illex illecebrosus'
    },
    {
      label: 'Tyynenmeren langusti',
      value: 'Jasus lalandii'
    },
    {
      label: 'Boniitti',
      value: 'Katsuwonus pelamis'
    },
    {
      label: 'Viherhuulikala',
      value: 'Labrus bergylta'
    },
    {
      label: 'Ruskohuulikala',
      value: 'Labrus merula'
    },
    {
      label: 'Sillihai',
      value: 'Lamna nasus'
    },
    {
      label: 'Nahkiainen',
      value: 'Lampetra fluviatilis'
    },
    {
      label: 'Pikkunahkiainen',
      value: 'Lampetra planeri'
    },
    {
      label: 'Barramundi',
      value: 'Lates calcarifer'
    },
    {
      label: 'Eskolaari',
      value: 'Lepidocybium flavobrunneum'
    },
    {
      label: 'Kivikampela',
      value: 'Lepidopsetta bilineata'
    },
    {
      label: 'Hopeahuotrakala',
      value: 'Lepidopus caudatus'
    },
    {
      label: 'Nelitäplälasikampela',
      value: 'Lepidorhombus boscii'
    },
    {
      label: 'Lasikampela',
      value: 'Lepidorhombus whiffiagonis'
    },
    {
      label: 'Punatäpläputsari',
      value: 'Lethrinus lentjan'
    },
    {
      label: 'Trumpettiputsari',
      value: 'Lethrinus miniatus'
    },
    {
      label: 'Hietaputsari',
      value: 'Lethrinus nebulosus'
    },
    {
      label: 'Turpa',
      value: 'Leuciscus cephalus'
    },
    {
      label: 'Säyne',
      value: 'Leuciscus idus'
    },
    {
      label: 'Seipi',
      value: 'Leuciscus leuciscus'
    },
    {
      label: 'Amiapompano',
      value: 'Lichia amia'
    },
    {
      label: 'Japaninhietakampela',
      value: 'Limanda aspera'
    },
    {
      label: 'Ruostekampela',
      value: 'Limanda ferrugina'
    },
    {
      label: 'Hietakampela',
      value: 'Limanda limanda'
    },
    {
      label: 'Samppanjahummeri',
      value: 'Linuparus sordidus'
    },
    {
      label: 'Etelän kuningasrapu',
      value: 'Lithodes antarcticus'
    },
    {
      label: 'Piikikäs kivirapu',
      value: 'Lithodes maja'
    },
    {
      label: 'Valkopagelli',
      value: 'Lithognathus lithognathus'
    },
    {
      label: 'Marmoripagelli',
      value: 'Lithognathus mormyrus'
    },
    {
      label: 'Pohjan valkokatkarapu',
      value: 'Litopenaeus setiferus'
    },
    {
      label: 'Valkokatkarapu',
      value: 'Litopenaeus vannamei'
    },
    {
      label: 'Kalmari, mustekala',
      value: 'Loligo chinensis'
    },
    {
      label: 'Kalmari, mustekala',
      value: 'Loligo duvauceli'
    },
    {
      label: 'Kalmari, mustekala',
      value: 'Loligo patagonica'
    },
    {
      label: 'Kalmari, mustekala',
      value: 'Loligo pealei'
    },
    {
      label: 'Kalmari, mustekala',
      value: 'Loligo vulgaris'
    },
    {
      label: 'Pienimerikrotti',
      value: 'Lophius budegassa'
    },
    {
      label: 'Merikrotti',
      value: 'Lophius piscatorius'
    },
    {
      label: 'Made',
      value: 'Lota lota'
    },
    {
      label: 'Punanapsija',
      value: 'Lutjanus campechanus'
    },
    {
      label: 'Ruostejuovanapsija',
      value: 'Lutjanus carponotatus'
    },
    {
      label: 'Karmiininapsija',
      value: 'Lutjanus erythropterus'
    },
    {
      label: 'Mustatäplänapsija',
      value: 'Lutjanus johnii'
    },
    {
      label: 'Napsija',
      value: 'Lutjanus lutjanus'
    },
    {
      label: 'Malabarinnapsija',
      value: 'Lutjanus malabaricus'
    },
    {
      label: 'Purppuranapsija',
      value: 'Lutjanus purpureus'
    },
    {
      label: 'Juovaselkänapsija',
      value: 'Lutjanus russellii'
    },
    {
      label: 'Kyhmynapsija',
      value: 'Lutjanus sanguineus'
    },
    {
      label: 'Keisarinapsija',
      value: 'Lutjanus sebae'
    },
    {
      label: 'Juovanapsija',
      value: 'Lutjanus synagris'
    },
    {
      label: 'Ruskojuovanapsija',
      value: 'Lutjanus vitta'
    },
    {
      label: 'Isokatkarapu, jättiläiskatkarapu',
      value: 'Macrobrachium rosenbergii'
    },
    {
      label: 'Patagonianhoki',
      value: 'Macruronus magellanicus'
    },
    {
      label: 'Hoki',
      value: 'Macruronus novaezelandiae'
    },
    {
      label: 'Hämähäkkirapu',
      value: 'Maja squinado'
    },
    {
      label: 'Sinimarliini',
      value: 'Makaira nigricans'
    },
    {
      label: 'Villakuore',
      value: 'Mallotus villosus'
    },
    {
      label: 'Kurumakatkarapu',
      value: 'Marsupenaeus japonicus'
    },
    {
      label: 'Norjankrilli',
      value: 'Meganyctiphanes norvegica'
    },
    {
      label: 'Kolja',
      value: 'Melanogrammus aeglefinus'
    },
    {
      label: 'Kuningaskatkarapu',
      value: 'Melicertus latisulcatus'
    },
    {
      label: 'Idän kuningaskatkarapu',
      value: 'Melicertus plebejus'
    },
    {
      label: 'Venussimpukka',
      value: 'Meretrix meretrix'
    },
    {
      label: 'Valkoturska',
      value: 'Merlangius merlangus'
    },
    {
      label: 'Etelänkummeliturska',
      value: 'Merluccius australis'
    },
    {
      label: 'Kapinkummeliturska',
      value: 'Merluccius capensis'
    },
    {
      label: 'Argentiinankummeliturska',
      value: 'Merluccius hubbsi'
    },
    {
      label: 'Kummeliturska',
      value: 'Merluccius merluccius'
    },
    {
      label: 'Afrikankummeliturska',
      value: 'Merluccius paradoxus'
    },
    {
      label: 'Mustakummeliturska',
      value: 'Merluccius polli'
    },
    {
      label: 'Lahtihummeri',
      value: 'Metanephrops boschmai'
    },
    {
      label: 'Jingakatkarapu',
      value: 'Metapenaeus affinis'
    },
    {
      label: 'Ruskokatkarapu',
      value: 'Metapenaeus endeavouri'
    },
    {
      label: 'Hiekkakatkarapu',
      value: 'Metapenaeus ensis'
    },
    {
      label: 'Täpläkatkarapu',
      value: 'Metapenaeus monoceros'
    },
    {
      label: 'Jääturska',
      value: 'Microgadus tomcod'
    },
    {
      label: 'Etelän mustakitaturska',
      value: 'Micromesistius australis'
    },
    {
      label: 'Mustakitaturska',
      value: 'Micromesistius poutassou'
    },
    {
      label: 'Pikkubassi',
      value: 'Micropterus dolomieu'
    },
    {
      label: 'Isobassi',
      value: 'Micropterus salmoides'
    },
    {
      label: 'Pikkupääkampela',
      value: 'Microstomus kitt'
    },
    {
      label: 'Tylppäpyrstömolva',
      value: 'Molva dypterygia'
    },
    {
      label: 'Molva',
      value: 'Molva molva'
    },
    {
      label: 'Amerikanbassi',
      value: 'Morone americana'
    },
    {
      label: 'Juovabassi',
      value: 'Morone saxatilis'
    },
    {
      label: 'Juovakeltti',
      value: 'Mugil cephalus'
    },
    {
      label: 'Pikkumullo',
      value: 'Mullus barbatus'
    },
    {
      label: 'Keltajuovamullo',
      value: 'Mullus surmuletus'
    },
    {
      label: 'Härkäsimppu',
      value: 'Myoxocephalus quadricornis'
    },
    {
      label: 'Isosimppu',
      value: 'Myoxocephalus scorpius'
    },
    {
      label: 'Chilen sinisimpukka',
      value: 'Mytilus chinensis'
    },
    {
      label: 'Sinisimpukka',
      value: 'Mytilus edulis'
    },
    {
      label: 'Ruusuviiriahven',
      value: 'Nemipterus furcosus'
    },
    {
      label: 'Kultaviiriahven',
      value: 'Nemipterus virgatus'
    },
    {
      label: 'Keisarihummeri',
      value: 'Nephrops norvegicus'
    },
    {
      label: 'Uudenseelannin kalmari, mustekala',
      value: 'Nototodarus sloani'
    },
    {
      label: 'Meritursas, mustekala',
      value: 'Octopus vulgaris'
    },
    {
      label: 'Meksikonlohi',
      value: 'Oncorhynchus chrysogaster'
    },
    {
      label: 'Punakurkkulohi',
      value: 'Oncorhynchus clarkii'
    },
    {
      label: 'Gilataimen',
      value: 'Oncorhynchus gilae'
    },
    {
      label: 'Arizonanlohi',
      value: 'Oncorhynchus gilae apache'
    },
    {
      label: 'Kyttyrälohi',
      value: 'Oncorhynchus gorbuscha'
    },
    {
      label: 'Koiralohi',
      value: 'Oncorhynchus keta'
    },
    {
      label: 'Hopealohi',
      value: 'Oncorhynchus kisutch'
    },
    {
      label: 'Masulohi',
      value: 'Oncorhynchus masou'
    },
    {
      label: 'Kirjolohi',
      value: 'Oncorhynchus mykiss'
    },
    {
      label: 'Kultakirjolohi',
      value: 'Oncorhynchus mykiss aquabonita'
    },
    {
      label: 'Punalohi, intiaanilohi',
      value: 'Oncorhynchus nerka'
    },
    {
      label: 'Kuningaslohi',
      value: 'Oncorhynchus tshawytscha'
    },
    {
      label: 'Partanilkka',
      value: 'Ophidion barbatum'
    },
    {
      label: 'Juovaton sarda',
      value: 'Orcynopsis unicolor'
    },
    {
      label: 'Mosambikintilapia',
      value: 'Oreochromis mossambicus'
    },
    {
      label: 'Niilintilapia',
      value: 'Oreochromis niloticus'
    },
    {
      label: 'Kuore, norssi',
      value: 'Osmerus eperlanus'
    },
    {
      label: 'Osteri',
      value: 'Ostrea edulis'
    },
    {
      label: 'Täplärapu',
      value: 'Pacifastacus leniusculus'
    },
    {
      label: 'Pagelli',
      value: 'Pagellus acarne'
    },
    {
      label: 'Pilkkupagelli',
      value: 'Pagellus bogaraveo'
    },
    {
      label: 'Punapagelli',
      value: 'Pagellus erythrinus'
    },
    {
      label: 'Pargo',
      value: 'Pagrus pagrus'
    },
    {
      label: 'Katkarapu',
      value: 'Palaemon serratus'
    },
    {
      label: 'Langusti',
      value: 'Palinurus elephas'
    },
    {
      label: 'Katkarapu, Pohjankatkarapu',
      value: 'Pandalus borealis'
    },
    {
      label: 'Pangasius, haimonni',
      value: 'Pangasius hypophthalmus'
    },
    {
      label: 'Australian langusti, kivihummeri',
      value: 'Panulirus cygnus'
    },
    {
      label: 'Transkein langusti',
      value: 'Panulirus homarus'
    },
    {
      label: 'Mattosimpukka',
      value: 'Paphia undulata'
    },
    {
      label: 'Kiinan kuningasrapu',
      value: 'Paralithodes brevipes'
    },
    {
      label: 'Kuningasrapu',
      value: 'Paralithodes camtschaticus'
    },
    {
      label: 'Syvänmeren katkarapu',
      value: 'Parapenaeus longirostris'
    },
    {
      label: 'Japaninkampasimpukka',
      value: 'Patinopecten yessoensis'
    },
    {
      label: 'Isokampasimpukka',
      value: 'Pecten maximus'
    },
    {
      label: 'Ruskea tiikerikatkarapu',
      value: 'Penaeus esculentus'
    },
    {
      label: 'Tiikerikatkarapu, jättiläiskatkarapu',
      value: 'Penaeus monodon'
    },
    {
      label: 'Vihreä tiikerikatkarapu',
      value: 'Penaeus semisulcatus'
    },
    {
      label: 'Ahven',
      value: 'Perca fluviatilis'
    },
    {
      label: 'Vihersimpukka',
      value: 'Perna perna'
    },
    {
      label: 'Merinahkiainen',
      value: 'Petromyzon marinus'
    },
    {
      label: 'Mutu',
      value: 'Phoxinus phoxinus'
    },
    {
      label: 'Pikkukampela',
      value: 'Phrynorhombus norvegicus'
    },
    {
      label: 'Suomuturska',
      value: 'Phycis blennoides'
    },
    {
      label: 'Kampasimpukka',
      value: 'Placopecten magellanicus'
    },
    {
      label: 'Kampela',
      value: 'Platichthys flesus'
    },
    {
      label: 'Safiirimeriahven',
      value: 'Plectropomus leopardus'
    },
    {
      label: 'Napakampela',
      value: 'Pleuronectes glacialis'
    },
    {
      label: 'Punakampela',
      value: 'Pleuronectes platessa'
    },
    {
      label: 'Lyyraturska',
      value: 'Pollachius pollachius'
    },
    {
      label: 'Seiti',
      value: 'Pollachius virens'
    },
    {
      label: 'Keisarisiimaevä',
      value: 'Polydactylus macrochir'
    },
    {
      label: 'Hylkyahven',
      value: 'Polyprion americanus'
    },
    {
      label: 'Satulamurisija',
      value: 'Pomadasys maculatus'
    },
    {
      label: 'Sinikala',
      value: 'Pomatomus saltatrix'
    },
    {
      label: 'Ruskonapsija',
      value: 'Pristipomoides filamentosus'
    },
    {
      label: 'Kultaviirunapsija',
      value: 'Pristipomoides multidens'
    },
    {
      label: 'Punarapu, Louisiananrapu',
      value: 'Procambarus clarkii'
    },
    {
      label: 'Piikkikampela',
      value: 'Psetta maxima'
    },
    {
      label: 'Sinitrevalli',
      value: 'Pseudocaranx dentex'
    },
    {
      label: 'Hyppijätrevalli',
      value: 'Pseudocaranx wrighti'
    },
    {
      label: 'Okarausku',
      value: 'Raja clavata'
    },
    {
      label: 'Grönlanninpallas',
      value: 'Reinhardtius hippoglossoides'
    },
    {
      label: 'Naskalipallas',
      value: 'Reinhardtius stomias'
    },
    {
      label: 'Hiekkakampela',
      value: 'Rhombosolea plebeia'
    },
    {
      label: 'Välimerenseepia',
      value: 'Rossia macrosoma'
    },
    {
      label: 'Särki',
      value: 'Rutilus rutilus'
    },
    {
      label: 'Lohi, merilohi',
      value: 'Salmo salar'
    },
    {
      label: 'Lohi, järvilohi, saimaanlohi',
      value: 'Salmo salar sebago'
    },
    {
      label: 'Taimen, purotaimen, tammukka',
      value: 'Salmo trutta fario'
    },
    {
      label: 'Taimen, järvitaimen',
      value: 'Salmo trutta lacustris'
    },
    {
      label: 'Taimen, meritaimen',
      value: 'Salmo trutta trutta'
    },
    {
      label: 'Nieriä, rautu, saimaannieriä',
      value: 'Salvelinus alpinus'
    },
    {
      label: 'Puronieriä',
      value: 'Salvelinus fontinalis'
    },
    {
      label: 'Harmaanieriä',
      value: 'Salvelinus namaycush'
    },
    {
      label: 'Kuha',
      value: 'Sander lucioperca'
    },
    {
      label: 'Sarda',
      value: 'Sarda sarda'
    },
    {
      label: 'Sardiini',
      value: 'Sardina pilchardus'
    },
    {
      label: 'Pyörösardiini',
      value: 'Sardinella aurita'
    },
    {
      label: 'Japaninsardiini',
      value: 'Sardinops melanostictus'
    },
    {
      label: 'Perunsardiini',
      value: 'Sardinops sagax'
    },
    {
      label: 'Sorva',
      value: 'Scardinius erythropthalmus'
    },
    {
      label: 'Tyynenmerenmakrilli',
      value: 'Scomber australasicus'
    },
    {
      label: 'Japaninmakrilli',
      value: 'Scomber japonicus'
    },
    {
      label: 'Makrilli',
      value: 'Scomber scombrus'
    },
    {
      label: 'Makrillihauki',
      value: 'Scomberesox saurus'
    },
    {
      label: 'Kuningasmakrilli',
      value: 'Scomberomorus cavalla'
    },
    {
      label: 'Seepramakrilli',
      value: 'Scomberomorus commerson'
    },
    {
      label: 'Tiikerimakrilli',
      value: 'Scomberomorus semifasciatus'
    },
    {
      label: 'Silokampela',
      value: 'Scophthalmus rhombus'
    },
    {
      label: 'Täpläpunahai',
      value: 'Scyliorhinus stellaris'
    },
    {
      label: 'Mangroverapu, mutataskurapu',
      value: 'Scylla serrata'
    },
    {
      label: 'Syvänmerenpunasimppu',
      value: 'Sebastes mentella'
    },
    {
      label: 'Seepia',
      value: 'Sepia aculeata'
    },
    {
      label: 'Seepia',
      value: 'Sepia officinalis'
    },
    {
      label: 'Faaraoseepia',
      value: 'Sepia pharaonis'
    },
    {
      label: 'Pikkuseepia',
      value: 'Sepiola rondeletti'
    },
    {
      label: 'Karibianseepia',
      value: 'Sepioteuthis sepioidea'
    },
    {
      label: 'Punasimppu, puna-ahven',
      value: 'Serranus scriba'
    },
    {
      label: 'Monni',
      value: 'Silurus glanis'
    },
    {
      label: 'Hietakielikampela',
      value: 'Solea lascaris'
    },
    {
      label: 'Kielikampela, meriantura',
      value: 'Solea solea'
    },
    {
      label: 'Kultaotsa-ahven',
      value: 'Sparus aurata'
    },
    {
      label: 'Pikarelli',
      value: 'Spicara maena'
    },
    {
      label: 'Pikkupikarelli',
      value: 'Spicara smaris'
    },
    {
      label: 'Meriruutana',
      value: 'Spondyliosoma cantharus'
    },
    {
      label: 'Kilohaili',
      value: 'Sprattus sprattus'
    },
    {
      label: 'Piikkihai',
      value: 'Squalus acanthias'
    },
    {
      label: 'Karhurapu',
      value: 'Thenus orientalis'
    },
    {
      label: 'Alaskanseiti',
      value: 'Theragra chalcogramma'
    },
    {
      label: 'Valkotonnikala',
      value: 'Thunnus alalunga'
    },
    {
      label: 'Keltaevätonnikala',
      value: 'Thunnus albacares'
    },
    {
      label: 'Mustaevätonnikala',
      value: 'Thunnus atlanticus'
    },
    {
      label: 'Eteläntonnikala',
      value: 'Thunnus maccoyii'
    },
    {
      label: 'Isosilmätonnikala',
      value: 'Thunnus obesus'
    },
    {
      label: 'Tonnikala',
      value: 'Thunnus thynnus'
    },
    {
      label: 'Pitkäpyrstötonnikala',
      value: 'Thunnus tonggol'
    },
    {
      label: 'Harjus',
      value: 'Thymallus thymallus'
    },
    {
      label: 'Suutari',
      value: 'Tinca tinca'
    },
    {
      label: 'Liitokalmari, mustekala',
      value: 'Todarodes sagittatus'
    },
    {
      label: 'Louhikala',
      value: 'Trachinus draco'
    },
    {
      label: 'Piikkimakrilli',
      value: 'Trachurus trachurus'
    },
    {
      label: 'Harmaaturska',
      value: 'Trisopterus esmarki'
    },
    {
      label: 'Partaturska',
      value: 'Trisopterus luscus'
    },
    {
      label: 'Pikkuturska',
      value: 'Trisopterus minutus'
    },
    {
      label: 'Etelänmullo',
      value: 'Upeneichthys vlamingii'
    },
    {
      label: 'Punasuomuturska',
      value: 'Urophycis chuss'
    },
    {
      label: 'Valkosuomuturska',
      value: 'Urophycis tenuis'
    },
    {
      label: 'Isomattosimpukka',
      value: 'Venerupis decussata'
    },
    {
      label: 'Vimpa',
      value: 'Vimba vimba'
    },
    {
      label: 'Miekkakala',
      value: 'Xiphias gladius'
    },
    {
      label: 'Pietarinkala',
      value: 'Zeus faber'
    },
    {
      label: 'Kivinilkka',
      value: 'Zoarces viviparus'
    },
    {
      label: 'Patagonian kampasimpukka',
      value: 'Zygochlamys patagonica'
    }
  ].map(({ label, value }) => ({
    label: `${value} (${label})`,
    value
  }))

export { getFishLatinNamesAsOptions }
