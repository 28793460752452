import React from 'react'
import { MediaFolder } from '@seesignage/seesignage-utils'
import { useDropzone } from 'react-dropzone'
import { useDispatch } from 'react-redux'
import FolderIcon from 'mdi-react/FolderIcon'
import classNames from 'classnames'
import { Card, CardActionArea, CardContent, CardHeader, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Truncate from 'react-truncate'
import { selectFolder } from '../../../actions/media'

const useStyles = makeStyles(() => ({
  folderContainer: {
    margin: 4
  },
  folderDragActive: {
    background: 'rgba(0, 174, 239, .05)',
    boxShadow: '0px 0px 0px 5px rgba(0, 174, 239, .7)',
    transition: '200ms'
  },
  folderCard: {
    width: 150,
    height: 90
  },
  folderCardHeader: {
    padding: 4
  }
}))

interface FolderCardProps {
  folder: MediaFolder
  setDraggingOverFolderId: React.Dispatch<React.SetStateAction<string | undefined>>
}

const FolderCard: React.FC<FolderCardProps> = ({ folder, setDraggingOverFolderId }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { getRootProps, isDragActive } = useDropzone({
    onDragEnter: () => {
      setDraggingOverFolderId(folder.folderId)
    },
    onDragLeave: () => {
      setDraggingOverFolderId(undefined)
    },
    noClick: true,
    noKeyboard: true
  })
  return (
    <div
      {...getRootProps({ className: 'folder-drop-zone' })}
      className={classNames(classes.folderContainer, {
        [classes.folderDragActive]: isDragActive
      })}>
      <Card className={classes.folderCard} key={folder.folderId}>
        <Tooltip disableInteractive title={folder.name}>
          <CardActionArea onClick={() => dispatch(selectFolder(folder.folderId))}>
            <CardHeader className={classes.folderCardHeader} avatar={<FolderIcon />} />
            <CardContent>
              <Truncate lines={2} width={140}>
                {folder.name}
              </Truncate>
            </CardContent>
          </CardActionArea>
        </Tooltip>
      </Card>
    </div>
  )
}

export default FolderCard
