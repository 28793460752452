import React, { useState, Fragment } from 'react'
import {
  CampaignUI,
  CampaignSubUI,
  isParentCampaign,
  AutocompleteOption
} from '@seesignage/seesignage-utils'
import {
  InjectedFormProps,
  FieldArray,
  reduxForm,
  Field,
  Form,
  isDirty,
  formValueSelector
} from 'redux-form'
import { LinearProgress, Grid, Paper, Typography, DialogActions, Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Dispatch } from 'redux'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { StateInterface } from '../../../types/states'
import { updateCampaign, updateCampaignTemplate } from '../../../actions/campaigns'
import { bindSubmitActionToPromise } from '../../../utils/forms'
import {
  ReduxTextField,
  ReduxDatePicker,
  ReduxCreatableAutocomplete
} from '../../../components/FormInput/ReduxWrappers'
import { required, minItems } from '../../../validation'
import SelectTemplate from '../../../components/FormInput/SelectTemplate'
import { selectMasterTemplates } from '../../../selectors/templates'
import { StateTemplate } from '../../../types/templates'
import { isCampaignSubUI } from '../../../types/campaigns'
import {
  selectCurrentEnvironmentTagsAsOptions,
  selectCurrentEnvironmentType
} from '../../../selectors/environments'
import { ForceRunTour } from '../../../types/actions'
import { EnvironmentType } from '../../../types/environments'
import { Tour } from '../../../types/tours'
import { forceRunTour } from '../../../actions/tours'
import Dialog from '../../Dialog'
import ErrorMessage from '../../../components/Errors/ErrorMessage'
import PreviewCampaign from '../Preview/PreviewCampaign'
import CreateMasterDrugForm from '../../Products/Pharmacy/CreateMasterDrugForm'
import { closeDialog, openDialog } from '../../../actions/dialogs'
import i18n from '../../../translations/i18n'
import { CampaignItemsField } from './Fields'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%)',
    zIndex: 999,
    position: 'relative'
  }
}))

interface OwnProps {
  campaign?: CampaignUI | CampaignSubUI
}

interface StateProps {
  templates: StateTemplate[]
  tags: AutocompleteOption[]
  environmentType: EnvironmentType
}

interface DispatchProps {
  updateCampaign: (formData: any) => void
  updateCampaignTemplate: (formData: any) => void
  forceRunTour: ForceRunTour
}

type EditCampaignFormProps = OwnProps & StateProps & DispatchProps

const EditCampaignForm: React.FC<EditCampaignFormProps &
  InjectedFormProps<{}, EditCampaignFormProps>> = ({
  campaign,
  updateCampaign,
  updateCampaignTemplate,
  templates,
  tags,
  handleSubmit,
  error
}) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const [previewItemIndex, setPreviewItemIndex] = useState(0)
  const isCampaignSub = isCampaignSubUI(campaign)
  const isPublished = isParentCampaign(campaign) && campaign?.published
  const dispatch = useDispatch()

  // use selector to get current isDirty value. Using it from component 'dirty' prop gives wrong value.
  const isFormDirty = useSelector(isDirty('EditCampaignForm'))
  // note: "isDirty" not working with price fields because handleEditCampaignFormChange saga
  // dispatch initialize from action that initialized the whole form to initial state.
  // therefore, we have custom "changed" prop that is true when prices are changed
  const hasFormChanged = useSelector(state =>
    formValueSelector('EditCampaignForm')(state, 'changed')
  )

  const isPreviewDisabled = isFormDirty || hasFormChanged

  // note: if published parent campaign, only its template can be changed afterwards.
  const formOnSubmit = handleSubmit(isPublished ? updateCampaignTemplate : updateCampaign)
  return !campaign ? (
    <LinearProgress />
  ) : (
    <Fragment>
      <Dialog
        dialogId='ConfirmCreateNewMasterProductDialog'
        title={t('error.product.notFound')}
        noOpenDialogButton
        Content={
          <Fragment>
            <Typography gutterBottom>
              {t('products.pharmacy.createNewConfirmationLine1')}
            </Typography>
            <Typography gutterBottom>
              {t('products.pharmacy.createNewConfirmationLine2')}
            </Typography>
            <DialogActions>
              <Button
                color='primary'
                onClick={() => dispatch(closeDialog('ConfirmCreateNewMasterProductDialog'))}>
                {t('general.no')}
              </Button>
              <Button
                color='primary'
                onClick={() => {
                  dispatch(closeDialog('ConfirmCreateNewMasterProductDialog'))
                  dispatch(openDialog('CreateMasterProductDialog'))
                }}>
                {t('general.yes')}
              </Button>
            </DialogActions>
          </Fragment>
        }
      />
      <Dialog
        dialogId='CreateMasterProductDialog'
        title={t('playlists.editPlaylist.createNewProduct')}
        noOpenDialogButton
        maxWidth='md'
        Content={
          <CreateMasterDrugForm
            dialogId='CreateMasterProductDialog'
            initialValues={{ customerId: campaign.customerId }}
          />
        }
      />
      <Form onSubmit={formOnSubmit}>
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Field
                name='name'
                disabled={isCampaignSub || isPublished}
                label={t('general.name')}
                component={ReduxTextField}
                required
                validate={[required]}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Field
                name='startDate'
                disabled={isCampaignSub || isPublished}
                component={ReduxDatePicker}
                label={t('date.startDate')}
                required
                validate={[required]}
                weekNumbers
                startOfTheDay
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Field
                name='endDate'
                disabled={isCampaignSub || isPublished}
                component={ReduxDatePicker}
                label={t('date.endDate')}
                required
                validate={[required]}
                weekNumbers
                endOfTheDay
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name='tags'
                label={t('screens.tags')}
                helperText={t('campaigns.tagsHelper')}
                component={ReduxCreatableAutocomplete}
                required
                validate={[required]}
                options={tags}
                multiple
                disabled={isCampaignSub || isPublished}
              />
            </Grid>
            {// Only parent campaign template can be modified
            !isCampaignSub && (
              <Grid item xs={12}>
                <Field
                  id='template'
                  name='template'
                  component={SelectTemplate}
                  showOnlySelectedName
                  initialTemplates={templates}
                  required
                  validate={[required]}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Dialog
                dialogId='PreviewCampaignDialog'
                title={`${t('campaigns.preview.campaign')} ${campaign.name}`}
                tooltipTitle={isPreviewDisabled ? t('campaigns.preview.unsavedChanges') : undefined}
                buttonLabel={t('campaigns.preview.campaign')}
                buttonDisabled={isPreviewDisabled}
                Content={
                  <PreviewCampaign
                    campaign={campaign}
                    previewItemIndex={previewItemIndex}
                    setPreviewItemIndex={setPreviewItemIndex}
                  />
                }
                maxWidth='xl'
              />
            </Grid>
            <Grid item xs={12}>
              <ErrorMessage message={error} />
            </Grid>
          </Grid>
        </Paper>
        <FieldArray
          name='items'
          component={CampaignItemsField}
          props={{
            isCampaignSub,
            isDisabled: isCampaignSub || isPublished,
            setPreviewItemIndex,
            isPreviewDisabled
          }}
          validate={minItems(1)}
        />
      </Form>
    </Fragment>
  )
}

const validate = (values: any) => {
  const errors: any = {}
  if (values.startDate && values.endDate) {
    // convert to Date because can be ISO Date string or JS Date (before submitting form).
    const start = new Date(values.startDate)
    const end = new Date(values.endDate)
    if (start > end) {
      errors.startDate = i18n.t('error.playlist.scheduleStartDate')
      errors.endDate = i18n.t('error.playlist.scheduleEndDate')
    }
  }
  return errors
}

const mapStateToProps = (state: StateInterface): StateProps => ({
  templates: selectMasterTemplates(state),
  tags: selectCurrentEnvironmentTagsAsOptions(state),
  environmentType: selectCurrentEnvironmentType(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  updateCampaign: bindSubmitActionToPromise(dispatch, updateCampaign),
  updateCampaignTemplate: bindSubmitActionToPromise(dispatch, updateCampaignTemplate),
  forceRunTour: (tour: Tour) => dispatch(forceRunTour(tour))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<{}, EditCampaignFormProps>({
    form: 'EditCampaignForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    updateUnregisteredFields: true,
    validate
  })(EditCampaignForm)
)
