import { createAsyncActions, createAction } from './utils'

const reducer = 'ENVIRONMENTS'

const {
  action: createEnvironment,
  actionFail: createEnvironmentFail,
  actionSuccess: createEnvironmentSuccess
} = createAsyncActions(reducer, 'CREATE')

const {
  action: updateEnvironment,
  actionFail: updateEnvironmentFail,
  actionSuccess: updateEnvironmentSuccess
} = createAsyncActions(reducer, 'UPDATE')

const {
  action: listEnvironments,
  actionFail: listEnvironmentsFail,
  actionSuccess: listEnvironmentsSuccess
} = createAsyncActions(reducer, 'LIST')

const {
  action: getEnvironment,
  actionFail: getEnvironmentFail,
  actionSuccess: getEnvironmentSuccess
} = createAsyncActions(reducer, 'GET')

const {
  action: deleteEnvironment,
  actionFail: deleteEnvironmentFail,
  actionSuccess: deleteEnvironmentSuccess
} = createAsyncActions(reducer, 'DELETE')

const {
  action: editEnvironmentPermissions,
  actionFail: editEnvironmentPermissionsFail,
  actionSuccess: editEnvironmentPermissionsSuccess
} = createAsyncActions(reducer, 'EDIT_PERMISSIONS')

const {
  action: addEnvironmentUsers,
  actionFail: addEnvironmentUsersFail,
  actionSuccess: addEnvironmentUsersSuccess
} = createAsyncActions(reducer, 'ADD_USERS')

const {
  action: removeEnvironmentUser,
  actionFail: removeEnvironmentUserFail,
  actionSuccess: removeEnvironmentUserSuccess
} = createAsyncActions(reducer, 'REMOVE_USER')

const {
  action: editEnvironment,
  actionFail: editEnvironmentFail,
  actionSuccess: editEnvironmentSuccess
} = createAsyncActions(reducer, 'EDIT_ENVIRONMENT')

const {
  action: updateAdditionalEnvironmentSettings,
  actionFail: updateAdditionalEnvironmentSettingsFail,
  actionSuccess: updateAdditionalEnvironmentSettingsSuccess
} = createAsyncActions(reducer, 'UPDATE_ADDITIONAL_SETTINGS')

const {
  action: checkEmailIsUnique,
  actionFail: checkEmailIsUniqueFail,
  actionSuccess: checkEmailIsUniqueSuccess
} = createAsyncActions(reducer, 'CHECK_EMAIL_UNIQUE')

const {
  action: updateSubEnvironments,
  actionFail: updateSubEnvironmentsFail,
  actionSuccess: updateSubEnvironmentsSuccess
} = createAsyncActions(reducer, 'UPDATE_SUB_ENVIRONMENTS')

const {
  action: removeAsSubEnvironment,
  actionFail: removeAsSubEnvironmentFail,
  actionSuccess: removeAsSubEnvironmentSuccess
} = createAsyncActions(reducer, 'REMOVE_AS_SUB')

const deselectEnvironment = createAction(reducer, 'DESELECT')

export {
  deselectEnvironment,
  createEnvironment,
  createEnvironmentSuccess,
  createEnvironmentFail,
  updateEnvironment,
  updateEnvironmentSuccess,
  updateEnvironmentFail,
  listEnvironments,
  listEnvironmentsSuccess,
  listEnvironmentsFail,
  getEnvironment,
  getEnvironmentSuccess,
  getEnvironmentFail,
  deleteEnvironment,
  deleteEnvironmentSuccess,
  deleteEnvironmentFail,
  editEnvironmentPermissions,
  editEnvironmentPermissionsSuccess,
  editEnvironmentPermissionsFail,
  updateAdditionalEnvironmentSettings,
  updateAdditionalEnvironmentSettingsFail,
  updateAdditionalEnvironmentSettingsSuccess,
  addEnvironmentUsers,
  addEnvironmentUsersSuccess,
  addEnvironmentUsersFail,
  removeEnvironmentUser,
  removeEnvironmentUserFail,
  removeEnvironmentUserSuccess,
  editEnvironment,
  editEnvironmentSuccess,
  editEnvironmentFail,
  checkEmailIsUnique,
  checkEmailIsUniqueFail,
  checkEmailIsUniqueSuccess,
  updateSubEnvironments,
  updateSubEnvironmentsFail,
  updateSubEnvironmentsSuccess,
  removeAsSubEnvironment,
  removeAsSubEnvironmentFail,
  removeAsSubEnvironmentSuccess
}
