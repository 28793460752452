import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  isFabricDateObject,
  isFabricFalconyAnnouncementsObject,
  isFabricIframeObject,
  isFabricMediaCarouselObject,
  isFabricQRCodeObject,
  isFabricRssFeedObject,
  isFabricSocialMediaObject,
  isFabricStopScheduleObject,
  isFabricTableObject,
  isFabricWeatherObject
} from '@seesignage/seesignage-player-utils'
import { isCarouselPlaylistItemPlayer } from '@seesignage/seesignage-utils'
import {
  generateTableInitialValues,
  generateIframeInitialValues,
  generateStopScheduleInitialValues,
  generateDateWidgetInitialValues,
  generateWeatherWidgetInitialValues,
  generateRssFeedInitialValues,
  generateSocialMediaSettingsInitialValues,
  generateQRCodeInitialValues,
  generateMediaCarouselSettingsInitialValues,
  generateFalconyAnnouncementsSettingsInitialValues
} from '../../../../../utils/fabric/canvasFormValues'
import TableForm from '../Forms/Table/TableForm'
import IframeForm from '../Forms/Iframe/IframeForm'
import StopScheduleForm from '../Forms/StopSchedule/StopScheduleForm'
import DateWidgetForm from '../Forms/Date/DateWidgetForm'
import WeatherWidgetForm from '../Forms/Weather/WeatherWidgetForm'
import CommonBoxSettingsForm from '../Forms/CommonBoxSettingsForm/CommonBoxSettingsForm'
import QRCodeWidgetForm from '../Forms/QRCode/QRCodeWidgetForm'
import RssFeedForm from '../Forms/RssFeed/RssFeedForm'
import ContentSocialMediaSettingsForm from '../Forms/SocialMedia/ContentSocialMediaSettingsForm'
import ContentMediaCarouselSettingsForm from '../Forms/MediaCarousel/ContentMediaCarouselSettingsForm'
import { isShadowAbleObject } from '../../../../../utils/fabric/canvasObjectUtils'
import ContentFalconyAnnouncementsSettingsForm from '../Forms/FalconyAnnouncements/ContentFalconyAnnouncementsSettingsForm'

interface SettingsProps {
  selectedObject?: fabric.Object
  isSystemAdmin: boolean
}

const Settings: React.FC<SettingsProps> = ({ selectedObject, isSystemAdmin }) => {
  const [t] = useTranslation()
  return (
    <div>
      {isFabricTableObject(selectedObject) && (
        <TableForm
          initialValues={generateTableInitialValues(selectedObject)}
          t={t}
          isSystemAdmin={isSystemAdmin}
        />
      )}
      {isFabricIframeObject(selectedObject) && (
        <IframeForm initialValues={generateIframeInitialValues(selectedObject)} t={t} />
      )}
      {isFabricStopScheduleObject(selectedObject) && (
        <StopScheduleForm initialValues={generateStopScheduleInitialValues(selectedObject)} t={t} />
      )}
      {isFabricDateObject(selectedObject) && (
        <DateWidgetForm initialValues={generateDateWidgetInitialValues(selectedObject)} t={t} />
      )}
      {isFabricWeatherObject(selectedObject) && (
        <WeatherWidgetForm
          initialValues={generateWeatherWidgetInitialValues(selectedObject)}
          t={t}
        />
      )}
      {isFabricRssFeedObject(selectedObject) && (
        <RssFeedForm initialValues={generateRssFeedInitialValues(selectedObject)} t={t} />
      )}
      {isFabricSocialMediaObject(selectedObject) && (
        <ContentSocialMediaSettingsForm
          initialValues={generateSocialMediaSettingsInitialValues(selectedObject)}
          t={t}
        />
      )}
      {isFabricQRCodeObject(selectedObject) && (
        <QRCodeWidgetForm initialValues={generateQRCodeInitialValues(selectedObject)} t={t} />
      )}
      {isFabricMediaCarouselObject(selectedObject) && (
        <ContentMediaCarouselSettingsForm
          initialValues={generateMediaCarouselSettingsInitialValues(selectedObject)}
          isPlaylistType={isCarouselPlaylistItemPlayer(selectedObject.customOptions.widgetProps)}
          t={t}
        />
      )}
      {isFabricFalconyAnnouncementsObject(selectedObject) && (
        <ContentFalconyAnnouncementsSettingsForm
          initialValues={generateFalconyAnnouncementsSettingsInitialValues(selectedObject)}
          t={t}
        />
      )}
      {isShadowAbleObject(selectedObject) && <CommonBoxSettingsForm />}
    </div>
  )
}

export default Settings
