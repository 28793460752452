import { IconButton, Typography, Grid } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import CheckCircleIcon from 'mdi-react/CheckCircleIcon'
import classNames from 'classnames'
import React from 'react'

const styles = () => ({
  button: {
    padding: 6
  },
  selected: {
    color: 'green',
    float: 'right' as 'right'
  },
  notSelected: {
    color: 'lightgrey ',
    opacity: 0.8,
    float: 'right' as 'right'
  },
  selectIndicatorContainer: {
    position: 'relative' as any
  },
  percentageContainer: {
    position: 'absolute' as any,
    right: 4,
    top: 35
  }
})

interface SelectedIndicatorProps extends WithStyles<typeof styles> {
  isItemSelected: boolean
  showPercentage: boolean
  itemPercentage: string
}

const SelectedIndicator: React.FC<SelectedIndicatorProps> = ({
  isItemSelected,
  showPercentage,
  itemPercentage,
  classes
}) => (
  <Grid item xs={1} className={classes.selectIndicatorContainer}>
    {isItemSelected ? (
      <IconButton className={classNames(classes.button, classes.selected)} size='large'>
        <CheckCircleIcon />
      </IconButton>
    ) : (
      <IconButton className={classNames(classes.button, classes.notSelected)} size='large'>
        <CheckCircleIcon />
      </IconButton>
    )}
    {showPercentage && (
      <Typography
        className={classes.percentageContainer}
        display='block'
        color='primary'
        variant='caption'>
        {itemPercentage}
      </Typography>
    )}
  </Grid>
)

export default withStyles(styles)(SelectedIndicator)
