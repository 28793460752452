import React from 'react'
import { Confirmation, UserRole } from '@seesignage/seesignage-utils'
import AdminIcon from 'mdi-react/AccountKeyIcon'
import UserIcon from 'mdi-react/AccountIcon'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
  Paper,
  Tooltip,
  ListItem,
  ListItemText
} from '@mui/material'
import DeleteIcon from 'mdi-react/DeleteIcon'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { StyledTableCell, StyledTableRow } from '../../../../components/Table'
import { selectEnvironmentIdFromPathname } from '../../../../selectors/routing'
import { StateInterface } from '../../../../types/states'
import { deleteConfirmation } from '../../../../actions/confirmations'
import { selectConfirmationsByEnvironmentId } from '../../../../selectors/confirmations'

interface OwnProps {
  /** Is user system admin or environment admin */
  isAdminUser: boolean
}

interface StateProps {
  confirmations: Confirmation[]
}

interface DispatchProps {
  deleteConfirmation: (id: string) => void
}

type ConfirmationsTableProps = OwnProps & StateProps & DispatchProps

const ConfirmationsTable: React.FC<ConfirmationsTableProps> = ({
  isAdminUser,
  confirmations,
  deleteConfirmation
}) => {
  const [t] = useTranslation()
  return (
    <TableContainer component={Paper}>
      <Table aria-label='customized table'>
        <TableHead>
          <TableRow>
            <StyledTableCell>{t('environment.role')}</StyledTableCell>
            <StyledTableCell align='left'>{t('signup.email')}</StyledTableCell>
            <StyledTableCell align='left'>{t('environment.invited')}</StyledTableCell>
            <StyledTableCell align='right'></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {confirmations.map(confirmation => (
            <StyledTableRow key={confirmation.confirmationId}>
              <StyledTableCell component='th' scope='row'>
                <Tooltip disableInteractive placement='right' title={confirmation.role}>
                  <span>{confirmation.role === UserRole.admin ? <AdminIcon /> : <UserIcon />}</span>
                </Tooltip>
              </StyledTableCell>
              <StyledTableCell align='left'>
                <ListItem>
                  <ListItemText
                    primary={confirmation.email}
                    secondary={t('environment.notVerified')}
                  />
                </ListItem>
              </StyledTableCell>
              <StyledTableCell align='left'>
                {moment(confirmation.createdAt).format('DD.MM.YYYY')}
              </StyledTableCell>
              <StyledTableCell align='right'>
                {isAdminUser && (
                  <IconButton
                    onClick={() => deleteConfirmation(confirmation.confirmationId)}
                    size='large'>
                    <DeleteIcon />
                  </IconButton>
                )}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const mapStateToProps = (state: StateInterface): StateProps => {
  const environmentId = selectEnvironmentIdFromPathname(state)
  return {
    confirmations: selectConfirmationsByEnvironmentId(environmentId)(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  deleteConfirmation: (id: string) => dispatch(deleteConfirmation(id))
})
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationsTable)
