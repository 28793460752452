import React, { Fragment } from 'react'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { InputAdornment, MenuItem } from '@mui/material'
import FormatBoldIcon from 'mdi-react/FormatBoldIcon'
import FormatUnderlineIcon from 'mdi-react/FormatUnderlineIcon'
import FormatStrikethroughIcon from 'mdi-react/FormatStrikethroughIcon'
import FormatLineSpacingIcon from 'mdi-react/FormatLineSpacingIcon'
import FormatItalicIcon from 'mdi-react/FormatItalicIcon'
import FormatTextWrappingOverflowIcon from 'mdi-react/FormatTextWrappingOverflowIcon'
import { getSupportedFontFamilies } from '@seesignage/seesignage-player-utils/lib'
import {
  ReduxSelectWithAutocomplete,
  ReduxTextField
} from '../../../../../components/FormInput/ReduxWrappers'
import { ContentTextBoxFormData } from '../../../../../types/contents'
import ContentColorPicker from '../../../../../components/FormInput/ColorPicker/ContentColorPicker'
import ReduxIconCheckbox from '../../../../../components/FormInput/ReduxWrappers/ReduxIconCheckbox'
import TextAlignCheckBox from '../../../../../components/FormInput/ReduxWrappers/Contents/TextAlignCheckBox'
import StringCheckBox from '../../../../../components/FormInput/ReduxWrappers/Contents/StringCheckBox'
import ContentToolbarItem from '../ContentToolbarItem/ContentToolbarItem'
import CommonContentFields from './CommonContentFields'

const toolbarIconSize = 25

const lineHeightValues = [1, 1.15, 1.5, 2]
const charSpacingValues = [-200, -100, -80, -60, -40, -20, -10, 0, 10, 20, 40, 60, 80, 100, 200]

type FormProps = ContentTextBoxFormData

const fontSizes = [...Array(150).keys()].splice(9)
const supportedFonts = getSupportedFontFamilies()

const ContentTextBoxForm: React.FC<InjectedFormProps<FormProps>> = () => {
  const [t] = useTranslation()
  return (
    <Fragment>
      <CommonContentFields formName={'ContentTextBoxForm'} enableRotation enableScale t={t} />
      <ContentToolbarItem>
        <Field
          name='fill'
          component={ContentColorPicker}
          tooltip={t('contents.actions.selectTextColor')}
        />
      </ContentToolbarItem>
      <ContentToolbarItem tooltip={t('contents.properties.fontSize')}>
        <Field
          name='fontSize'
          component={ReduxSelectWithAutocomplete}
          options={fontSizes.map(value => ({ value, label: `${value}` }))}
          disableClearable
          variant='outlined'
          size='small'
        />
      </ContentToolbarItem>
      <ContentToolbarItem tooltip={t('contents.properties.fontFamily')}>
        <Field name='fontFamily' component={ReduxTextField} select isToolbar hasSpacing>
          {supportedFonts.map(val => (
            <MenuItem value={val} key={`fontFamily-${val}`} style={{ fontFamily: val }}>
              {val}
            </MenuItem>
          ))}
        </Field>
      </ContentToolbarItem>
      <ContentToolbarItem>
        <Field name='textAlign' component={TextAlignCheckBox} t={t} />
      </ContentToolbarItem>
      <ContentToolbarItem tooltip={t('contents.properties.fontWeight')}>
        <Field
          name='fontWeight'
          component={StringCheckBox}
          Icon={FormatBoldIcon}
          checkedValue='bold'
          uncheckedValue='normal'
        />
      </ContentToolbarItem>
      <ContentToolbarItem tooltip={t('contents.properties.fontStyle')}>
        <Field
          name='fontStyle'
          component={StringCheckBox}
          Icon={FormatItalicIcon}
          checkedValue='italic'
          uncheckedValue='normal'
        />
      </ContentToolbarItem>
      <ContentToolbarItem tooltip={t('contents.properties.underline')}>
        <Field
          name='underline'
          component={ReduxIconCheckbox}
          Icon={FormatUnderlineIcon}
          iconSize={toolbarIconSize}
        />
      </ContentToolbarItem>
      <ContentToolbarItem tooltip={t('contents.properties.linethrough')}>
        <Field
          name='linethrough'
          component={ReduxIconCheckbox}
          Icon={FormatStrikethroughIcon}
          iconSize={toolbarIconSize}
        />
      </ContentToolbarItem>
      <ContentToolbarItem tooltip={t('contents.properties.lineHeight')}>
        <Field
          name='lineHeight'
          component={ReduxTextField}
          isToolbar
          hasSpacing
          select
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <FormatLineSpacingIcon color='rgba(0, 0, 0, 0.54)' />
              </InputAdornment>
            )
          }}>
          {lineHeightValues.map(val => (
            <MenuItem value={val} key={`lineHeight-${val}`}>
              {val}
            </MenuItem>
          ))}
        </Field>
      </ContentToolbarItem>
      <ContentToolbarItem tooltip={t('contents.properties.charSpacing')}>
        <Field
          name='charSpacing'
          component={ReduxTextField}
          hasSpacing
          isToolbar
          select
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <FormatTextWrappingOverflowIcon color='rgba(0, 0, 0, 0.54)' />
              </InputAdornment>
            )
          }}>
          {charSpacingValues.map(val => (
            <MenuItem value={val} key={`charSpacing-${val}`}>
              {val}
            </MenuItem>
          ))}
        </Field>
      </ContentToolbarItem>
    </Fragment>
  )
}

export default reduxForm<FormProps>({
  form: 'ContentTextBoxForm'
})(ContentTextBoxForm)
