import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction'

import { fork } from 'redux-saga/effects'
import createRootReducer from './reducers'
import sagas from './sagas'

function* sagaRunner(sagas: any) {
  for (const saga of sagas) {
    yield fork(saga)
  }
}

const sagaMiddleware = createSagaMiddleware()

const history = createBrowserHistory()
const rMiddleware = routerMiddleware(history)

const middleware = [rMiddleware, sagaMiddleware]

const store = createStore(
  createRootReducer(history),
  composeWithDevTools(applyMiddleware(...middleware))
)

const getStore = () => store

const getHistory = () => history

sagaMiddleware.run(sagaRunner, sagas)

export { getStore, getHistory }
