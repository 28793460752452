import React from 'react'
import { InjectedFormProps, Field, reduxForm } from 'redux-form'
import { useDispatch } from 'react-redux'
import { DialogActions, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CreateMediaFolderFormData } from '../../../types/media'
import { ReduxTextField } from '../../../components/FormInput/ReduxWrappers'
import { required } from '../../../validation'
import { bindSubmitActionToPromise } from '../../../utils/forms'
import { createFolder, updateFolder } from '../../../actions/media'
import { closeDialog } from '../../../actions/dialogs'
import ErrorMessage from '../../../components/Errors/ErrorMessage'
import FormContainer from '../../../components/FormContainer/FormContainer'

interface OwnProps {
  dialogId?: string
  submitButtonLabel: string
  /** when true, dispatch update folder action. Otherwise, create folder action. */
  isUpdate?: boolean
  /** We do not want to use form onSubmit when form is under another form in React DOM. Otherwise, it would submit also other forms. */
  useFormOnSubmit: boolean
  onClose?: () => void
}

type CreateFolderFormProps = OwnProps

const CreateFolderForm: React.FC<CreateFolderFormProps &
  InjectedFormProps<CreateMediaFolderFormData, CreateFolderFormProps>> = ({
  handleSubmit,
  dialogId,
  submitting,
  submitButtonLabel,
  error,
  isUpdate,
  onClose,
  useFormOnSubmit
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const submitAction = bindSubmitActionToPromise(dispatch, isUpdate ? updateFolder : createFolder)

  const handleCloseClick = () => {
    if (dialogId) {
      dispatch(closeDialog(dialogId))
    }
    if (typeof onClose === 'function') {
      onClose()
    }
  }

  return (
    <FormContainer onSubmit={useFormOnSubmit ? handleSubmit(submitAction) : undefined}>
      <Field
        fullWidth
        name='name'
        component={ReduxTextField}
        required
        validate={[required]}
        label={t('media.folderName')}
      />
      {error && <ErrorMessage message={error} />}
      <DialogActions>
        <Button onClick={handleCloseClick} color='primary'>
          {t('general.cancel')}
        </Button>
        <Button
          data-cy='submitRenameFolderButton'
          disabled={submitting}
          color='primary'
          type={useFormOnSubmit ? 'submit' : 'button'}
          onClick={!useFormOnSubmit ? handleSubmit(submitAction) : undefined}>
          {submitButtonLabel}
        </Button>
      </DialogActions>
    </FormContainer>
  )
}

export default reduxForm<CreateMediaFolderFormData, CreateFolderFormProps>({
  form: 'CreateFolderForm',
  onSubmitSuccess: (result, dispatch, props) => {
    // close Popover create folder when submit on success.
    if (typeof props.onClose === 'function') {
      props.onClose()
    }
  }
})(CreateFolderForm)
