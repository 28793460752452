import React, { Fragment } from 'react'
import { Field } from 'redux-form'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ComponentType, MediaType, parseStringToNumber } from '@seesignage/seesignage-utils'
import { ReduxSwitch, ReduxTextField } from '../../../../components/FormInput/ReduxWrappers'
import SelectMultipleMediaField from '../../../../components/FormInput/SelectMedia/SelectMultipleMediaField'
import { generateKeyFieldPrefix, gridItemProps } from './utils'
import SpacingFields from './CommonFields/SpacingFields'
import SizeFields from './CommonFields/SizeFields'
import PositionFields from './CommonFields/PositionFields'
import BorderFields from './CommonFields/BorderFields'
import EffectsFields from './CommonFields/EffectsFields'

interface VideoFieldsProps {
  id: string
  level: number
  childIndexes: number[]
  type: ComponentType
  formName: string
}

const VideoFields = ({ level, childIndexes, id, formName }: VideoFieldsProps) => {
  const [t] = useTranslation()
  const fieldProps = {
    level,
    childIndexes,
    t
  }
  return (
    <Fragment>
      <Grid container spacing={8} alignItems='center'>
        <Grid item xs={12} style={{ overflow: 'hidden' }}>
          <Field
            isSingleFileInput
            formName={formName}
            name={generateKeyFieldPrefix(level, childIndexes, 'key')}
            component={SelectMultipleMediaField}
            mediaType={MediaType.video}
          />
        </Grid>
        <Grid {...gridItemProps}>
          <Field
            fullWidth
            name={generateKeyFieldPrefix(level, childIndexes, 'videoAttributes.width')}
            label='Width (px)'
            component={ReduxTextField}
            type='number'
            parse={parseStringToNumber}
          />
        </Grid>
        <Grid {...gridItemProps}>
          <Field
            fullWidth
            name={generateKeyFieldPrefix(level, childIndexes, 'videoAttributes.height')}
            label='Height (px)'
            component={ReduxTextField}
            type='number'
            parse={parseStringToNumber}
          />
        </Grid>
        <Grid {...gridItemProps}>
          <Field
            name={generateKeyFieldPrefix(level, childIndexes, 'videoAttributes.autoPlay')}
            component={ReduxSwitch}
            label='Autoplay'
          />
        </Grid>
        <Grid {...gridItemProps}>
          <Field
            name={generateKeyFieldPrefix(level, childIndexes, 'videoAttributes.controls')}
            component={ReduxSwitch}
            label='Controls'
          />
        </Grid>
        <Grid {...gridItemProps}>
          <Field
            name={generateKeyFieldPrefix(level, childIndexes, 'videoAttributes.muted')}
            component={ReduxSwitch}
            label='Muted'
          />
        </Grid>
        <Grid {...gridItemProps}>
          <Field
            name={generateKeyFieldPrefix(level, childIndexes, 'videoAttributes.loop')}
            component={ReduxSwitch}
            label='Loop'
          />
        </Grid>
      </Grid>
      <PositionFields {...fieldProps} />
      <SpacingFields {...fieldProps} />
      <SizeFields {...fieldProps} />
      <BorderFields {...fieldProps} />
      <EffectsFields id={id} {...fieldProps} type={ComponentType.image} />
    </Fragment>
  )
}

export default VideoFields
