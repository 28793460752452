import { useTheme, Breakpoints } from '@mui/material/styles'

function useIsWidthUp(
  breakpoint: keyof Breakpoints['values'],
  width: keyof Breakpoints['values']
): boolean {
  const theme = useTheme()
  const breakpointWidth = theme.breakpoints.values[breakpoint]
  const currentWidth = theme.breakpoints.values[width]
  return currentWidth >= breakpointWidth
}

export default useIsWidthUp
