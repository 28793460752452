import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import MonitorIcon from 'mdi-react/MonitorIcon'
import React from 'react'
import { ScreenResponse } from '@seesignage/seesignage-utils'
import { TFunction } from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getScreensByStatuses } from '../../utils/screens'
import { selectSelectedEnvironmentId } from '../../selectors/environments'
import { navigate } from '../../actions/routes'
import { FilterByStatus } from '../../types/screens'
import colors from '../../styles/common/colors'

const styles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing(),
    width: '100%'
  },
  wrapIcon: {
    paddingTop: '6px',
    verticalAlign: 'top !important' as any,
    display: 'inline-flex' as any
  },
  screensOnline: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer'
    },
    padding: 0,
    border: '4px solid white',
    width: '25%'
  },
  screensOffline: {
    backgroundColor: theme.palette.secondary.main,
    padding: 0,
    '&:hover': {
      cursor: 'pointer'
    },
    border: '4px solid white',
    width: '25%'
  },
  screensStandby: {
    backgroundColor: colors.screenStatuses.standbyBg,
    padding: 0,
    '&:hover': {
      cursor: 'pointer'
    },
    border: '4px solid white',
    width: '25%'
  },
  screensTotal: {
    padding: 0,
    '&:hover': {
      cursor: 'pointer'
    },
    border: '4px solid white',
    width: '25%'
  },
  tableHeadRow: {
    padding: 'auto' as any,
    width: '25%'
  }
})

const useStyles = makeStyles(styles)

interface ScreenStatusesProps {
  screens: ScreenResponse[]
  t: TFunction
}

const ScreenStatuses: React.FC<ScreenStatusesProps> = ({ screens, t }) => {
  const classes = useStyles()
  const { online, offline, standby } = getScreensByStatuses(screens)
  const dispatch = useDispatch()
  const environmentId = useSelector(selectSelectedEnvironmentId)
  const handleRowClick = (status?: FilterByStatus) => {
    const queryParams = status ? `?status=${status}` : ''
    dispatch(navigate(`/environments/${environmentId}/screens${queryParams}`))
  }
  return (
    <Paper className={classes.root}>
      <Typography className={classes.wrapIcon}>
        <MonitorIcon /> &nbsp; {t('home.screenStatuses.title')}
      </Typography>
      <Table style={{ width: '98%', height: 105, margin: 'auto' }}>
        <TableHead>
          <TableRow>
            <TableCell align='center' className={classes.tableHeadRow}>
              {t('home.screenStatuses.online')}
            </TableCell>
            <TableCell align='center' className={classes.tableHeadRow}>
              {t('home.screenStatuses.standby')}
            </TableCell>
            <TableCell align='center' className={classes.tableHeadRow}>
              {t('home.screenStatuses.offline')}
            </TableCell>
            <TableCell align='center' className={classes.tableHeadRow}>
              {t('home.screenStatuses.total')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
              onClick={() => handleRowClick(FilterByStatus.online)}
              align='center'
              className={classes.screensOnline}>
              <Typography variant='h5' style={{ fontWeight: 'bold', color: '#fafafa' }}>
                {online.length}
              </Typography>
            </TableCell>
            <TableCell
              onClick={() => handleRowClick(FilterByStatus.standby)}
              align='center'
              className={classes.screensStandby}>
              <Typography variant='h5' style={{ fontWeight: 'bold', color: '#fafafa' }}>
                {standby.length}
              </Typography>
            </TableCell>
            <TableCell
              onClick={() => handleRowClick(FilterByStatus.offline)}
              align='center'
              className={classes.screensOffline}>
              <Typography variant='h5' style={{ fontWeight: 'bold', color: '#fafafa' }}>
                {offline.length}
              </Typography>
            </TableCell>
            <TableCell
              onClick={() => handleRowClick()}
              align='center'
              className={classes.screensTotal}>
              <Typography variant='h5' style={{ fontWeight: 'bold' }}>
                {screens.length}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  )
}

export default ScreenStatuses
