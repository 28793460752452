import { handleActions } from 'redux-actions'
import {
  createChannelItemSuccess,
  createChannelSuccess,
  deleteChannelItemSuccess,
  deleteChannelSuccess,
  getChannel,
  getChannelFail,
  getChannelSuccess,
  listChannels,
  listChannelsFail,
  listChannelsSuccess,
  openChannelsDialog,
  updateChannelItemSuccess,
  updateChannelSuccess,
  resetChannels,
  openEditChannelItem,
  closeEditChannelItem
} from '../actions/channels'
import {
  Action,
  CreateChannelItemSuccess,
  CreateChannelSuccess,
  DeleteChannelItemSuccess,
  DeleteChannelSuccess,
  GetChannelSuccess,
  ListChannelsSuccess,
  UpdateChannelItemSuccess
} from '../types/actions'
import { ChannelsState } from '../types/states'
import { AllChannels } from '../types/channels'

const initialState: ChannelsState = {
  channels: {},
  parentChannels: {},
  isLoading: false,
  editChannelItemOpen: false
}

const channelsReducer = handleActions<ChannelsState, any>(
  {
    [resetChannels]: () => initialState,
    [createChannelSuccess]: (state, { payload: channel }: CreateChannelSuccess) => ({
      ...state,
      channels: {
        ...state.channels,
        [channel.channelId]: channel
      }
    }),
    [createChannelItemSuccess]: (
      state,
      { payload: { channelId, items } }: CreateChannelItemSuccess
    ) => ({
      ...state,
      channels: {
        ...state.channels,
        [channelId]: {
          ...state.channels[channelId],
          items
        }
      }
    }),
    [updateChannelItemSuccess]: (
      state,
      { payload: { channelId, items } }: UpdateChannelItemSuccess
    ) => ({
      ...state,
      channels: {
        ...state.channels,
        [channelId]: {
          ...state.channels[channelId],
          items
        }
      }
    }),
    [deleteChannelItemSuccess]: (
      state,
      { payload: { channelId, items } }: DeleteChannelItemSuccess
    ) => ({
      ...state,
      channels: {
        ...state.channels,
        [channelId]: {
          ...state.channels[channelId],
          items
        }
      }
    }),
    [getChannel]: state => ({
      ...state,
      isLoading: true
    }),
    [getChannelSuccess]: (state, { payload: channel }: GetChannelSuccess) => ({
      ...state,
      channels: {
        ...state.channels,
        [channel.channelId]: channel
      },
      isLoading: false
    }),
    [getChannelFail]: state => ({
      ...state,
      isLoading: false
    }),
    [listChannels]: state => ({
      ...state,
      isLoading: true
    }),
    [listChannelsFail]: state => ({
      ...state,
      isLoading: false
    }),
    [listChannelsSuccess]: (
      state,
      { payload: { environmentId, channelsArray } }: ListChannelsSuccess
    ) => {
      const { channels, parentChannels } = channelsArray.reduce(
        (channelsAcc: AllChannels, channel) => {
          if (environmentId === channel.environmentId) {
            channelsAcc.channels[channel.channelId] = {
              ...channel
            }
          } else {
            channelsAcc.parentChannels[channel.channelId] = {
              ...channel
            }
          }

          return channelsAcc
        },
        { channels: {}, parentChannels: {} }
      )
      return {
        ...state,
        channels,
        parentChannels,
        isLoading: false
      }
    },
    [deleteChannelSuccess]: (state, { payload: channelId }: DeleteChannelSuccess) => {
      const channels = { ...state.channels }
      delete channels[channelId]
      return {
        ...state,
        channels
      }
    },
    [openChannelsDialog]: (state, { payload: { event } }: Action<any>) => ({
      ...state,
      selectedCalendarEvent: event
    }),
    [updateChannelSuccess]: (state, { payload: updatedChannel }: Action<any>) => ({
      ...state,
      channels: {
        ...state.channels,
        [updatedChannel.channelId]: updatedChannel
      }
    }),
    [openEditChannelItem]: (state, { payload: { event } }: Action<any>) => ({
      ...state,
      selectedCalendarEvent: event,
      editChannelItemOpen: true
    }),
    [closeEditChannelItem]: state => ({
      ...state,
      editChannelItemOpen: false
    })
  },
  initialState
)

export default channelsReducer
