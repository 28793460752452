import React from 'react'
import { ButtonGroup, Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { LogicalOperator } from '@seesignage/seesignage-utils'
import { useTranslation } from 'react-i18next'
import colors from '../../../styles/common/colors'

const useStyles = makeStyles(() => ({
  selected: {
    background: colors.seesignageColor,
    color: 'white',
    transition: '100ms',
    '&:hover': {
      background: colors.seesignageColor,
      filter: 'brightness(0.92)'
    }
  }
}))

const ReduxLogicalOperatorSelector = ({ input: { value, onChange } }: any) => {
  const classes = useStyles()
  const [t] = useTranslation()
  return (
    <ButtonGroup size='small' disableElevation>
      <Button
        className={classNames({
          [classes.selected]: value === LogicalOperator.or
        })}
        onClick={() => onChange(LogicalOperator.or)}>
        {t('playlists.editPlaylist.logicalOr')}
      </Button>
      <Button
        className={classNames({
          [classes.selected]: value === LogicalOperator.and
        })}
        onClick={() => onChange(LogicalOperator.and)}>
        {t('playlists.editPlaylist.logicalAnd')}
      </Button>
    </ButtonGroup>
  )
}

export default ReduxLogicalOperatorSelector
