import { Typography, Grid } from '@mui/material'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import { SpecialDiets, LunchGroupById } from '@seesignage/seesignage-utils/lib/types/lunchLists'
import { AutocompleteOption } from '@seesignage/seesignage-utils'
import { required } from '../../../validation'
import {
  ReduxTextField,
  ReduxCheckbox,
  ReduxSelectWithAutocomplete
} from '../../../components/FormInput/ReduxWrappers'
import { CloseDialog } from '../../../types/actions'
import DialogActions from '../../../components/DialogActions'
import { selectLunchListGroupsAsOptions, getDiets } from '../../../utils/lunchLists'

interface OwnProps {
  submitAction: (formData: any) => void
  closeDialog: CloseDialog
  dialogId: string
  groups: LunchGroupById
  isUpdate?: boolean
}

interface FormProps {
  name: string
  specialDiet?: SpecialDiets
  groupId?: AutocompleteOption
  /** exists when updating product */
  productId?: string
}

type CreateLunchProductFormProps = OwnProps & WithTranslation

const CreateLunchProductForm: React.FC<CreateLunchProductFormProps &
  InjectedFormProps<FormProps, OwnProps>> = ({
  error,
  handleSubmit,
  submitting,
  submitAction,
  closeDialog,
  dialogId,
  groups,
  isUpdate,
  t
}) => (
  <form onSubmit={handleSubmit(submitAction)}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Field
          fullWidth
          name='name'
          label={t('general.name')}
          component={ReduxTextField}
          required
          validate={[required]}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='body1'>{t('lists.lunch.specialDiets')}</Typography>
        <Grid container>
          {getDiets(t).map(({ label, name }) => (
            <Grid key={name} item xs={4}>
              <Field name={`specialDiet.${name}`} label={label} component={ReduxCheckbox} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Field
        name='groupId'
        placeholder={t('lists.lunch.createProductForm.selectGroup')}
        label={t('lists.lunch.createProductForm.selectGroup')}
        options={[
          {
            value: 'none',
            label: t('lists.lunch.createProductForm.noGroup')
          },
          ...selectLunchListGroupsAsOptions(groups)
        ]}
        component={ReduxSelectWithAutocomplete}
      />
    </Grid>
    <Typography variant='body1' color='error'>
      {error || ''}
    </Typography>
    <DialogActions
      dialogId={dialogId}
      submitting={submitting}
      closeDialog={closeDialog}
      submitButtonLabel={t(`general.${isUpdate ? 'update' : 'add'}`)}
    />
  </form>
)

export default reduxForm<FormProps, OwnProps>({
  form: 'CreateLunchProductForm'
})(withTranslation()(CreateLunchProductForm))
