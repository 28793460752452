import React, { Fragment } from 'react'
import { TFunction } from 'i18next'
import { Grid } from '@mui/material'
import { Field, formValueSelector } from 'redux-form'
import { formatDecimalsToCurrency, parseCurrencyStringToNumber } from '@seesignage/seesignage-utils'
import { useSelector } from 'react-redux'
import { ReduxTextField } from '../../../../components/FormInput/ReduxWrappers'
import { required } from '../../../../validation'
import { BatchPriceFieldsProps } from '../../../../types/campaigns'
import { parseNumberField } from '../../../../utils/forms'

interface CampaignSubItemPriceFields {
  /** Item name (path) in form */
  item: string
  t: TFunction
  priceRequired: boolean
  /** disabled/hidden individual fields in BatchItem */
  hiddenPriceFields?: BatchPriceFieldsProps
}

const CampaignSubItemPriceFields: React.FC<CampaignSubItemPriceFields> = ({
  item,
  priceRequired,
  hiddenPriceFields,
  t
}) => {
  const showPercentage = useSelector(state =>
    formValueSelector('EditCampaignForm')(state, `${item}.showPercentage`)
  )
  const useBatchPercentage = useSelector(state =>
    formValueSelector('EditCampaignForm')(state, `${item}.useBatchPercentage`)
  )
  const price = useSelector(state => formValueSelector('EditCampaignForm')(state, `${item}.price`))
  const hiddenStyle = {
    display: 'none'
  }
  return (
    <Fragment>
      <Grid item xs={2}>
        <Field
          fullWidth
          name={`${item}.price`}
          label={t('campaigns.editCampaign.price')}
          style={hiddenPriceFields?.price?.hidden ? hiddenStyle : undefined}
          type='number'
          component={ReduxTextField}
          format={formatDecimalsToCurrency}
          parse={parseCurrencyStringToNumber}
          warn={priceRequired ? [required] : undefined}
          InputProps={{
            endAdornment: '€'
          }}
          size='small'
        />
      </Grid>
      <Grid item xs={2}>
        <Field
          fullWidth
          name={`${item}.discountPrice`}
          label={t('campaigns.editCampaign.discountPrice')}
          component={ReduxTextField}
          style={hiddenPriceFields?.discountPrice?.hidden ? hiddenStyle : undefined}
          disabled={showPercentage || !price || hiddenPriceFields?.discountPrice?.disabled}
          type='number'
          format={formatDecimalsToCurrency}
          parse={parseCurrencyStringToNumber}
          InputProps={{
            endAdornment: '€'
          }}
          size='small'
        />
      </Grid>
      <Grid item xs={1}>
        <Field
          fullWidth
          type='number'
          parse={parseNumberField}
          name={`${item}.discountPercentage`}
          label={t('campaigns.editCampaign.discountPercentage')}
          component={ReduxTextField}
          style={hiddenPriceFields?.discountPercentage?.hidden ? hiddenStyle : undefined}
          disabled={!showPercentage || (priceRequired && !price)}
          warn={useBatchPercentage ? [required] : undefined}
          size='small'
        />
      </Grid>
    </Fragment>
  )
}
export default CampaignSubItemPriceFields
