import React from 'react'
import { Grid, Card, Typography, CardActionArea } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { SocialMediaPlatform } from '@seesignage/seesignage-utils'
import FacebookIcon from 'mdi-react/FacebookIcon'
import InstagramIcon from 'mdi-react/InstagramIcon'

const useStyles = makeStyles(() => ({
  selectPlatformContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '100%',
    height: '100%'
  },
  title: {
    fontWeight: 500,
    marginBottom: 10,
    textAlign: 'center'
  },
  card: {
    width: '250px',
    height: '100px',
    margin: '20px'
  },
  cardAction: {
    height: '100%',
    width: '100%',
    display: 'flex',
    transition: '0.2s'
  },
  text: {
    textAlign: 'center',
    marginLeft: '10px'
  }
}))

interface OwnProps {
  onGoToNextStep: () => void
  setPlatform: (platform: SocialMediaPlatform) => void
}

type SelectPlatformProps = OwnProps

const SelectPlatform = ({ onGoToNextStep, setPlatform }: SelectPlatformProps) => {
  const [t] = useTranslation()
  const classes = useStyles()
  const handleSelectPlatform = (platform: SocialMediaPlatform) => {
    setPlatform(platform)
    onGoToNextStep()
  }

  return (
    <div className={classes.selectPlatformContainer}>
      <Grid container justifyItems='center' rowGap={2}>
        <Grid item xs={12}>
          <Typography variant='h5' className={classes.title}>
            {t('contents.widgets.socialMedia.platform')}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='body1' textAlign='center'>
            {t('contents.widgets.socialMedia.choosePlatform')}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container direction='row' justifyContent='center' alignItems='center'>
            {process.env.REACT_APP_ENVIRONMENT !== 'prod' && (
              <Grid item>
                <Card
                  className={classes.card}
                  onClick={() => handleSelectPlatform(SocialMediaPlatform.instagram)}>
                  <CardActionArea className={classes.cardAction}>
                    <InstagramIcon size='40px' />
                    <Typography className={classes.text} variant='h6'>
                      Instagram
                    </Typography>
                  </CardActionArea>
                </Card>
              </Grid>
            )}
            <Grid item>
              <Card
                className={classes.card}
                onClick={() => handleSelectPlatform(SocialMediaPlatform.facebook)}>
                <CardActionArea className={classes.cardAction}>
                  <FacebookIcon size='40px' />
                  <Typography className={classes.text} variant='h6'>
                    Facebook
                  </Typography>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default SelectPlatform
