import {
  Button,
  CardActions,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Tooltip
} from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import { ContentUI, InfopageStore } from '@seesignage/seesignage-utils'
import { TFunction } from 'i18next'
import React, { Fragment } from 'react'
import SettingsIcon from 'mdi-react/SettingsIcon'
import DeleteIcon from 'mdi-react/DeleteIcon'
import ShareIcon from 'mdi-react/ShareIcon'
import ContentCopyIcon from 'mdi-react/ContentCopyIcon'
import { CloseDialog, DeleteContent, DuplicateContent } from '../../../types/actions'
import Dialog from '../../Dialog'
import CreateInfopageOrContentForm from '../Forms/CreateInfopageOrContentForm'
import CopyContentToEnvironmentsForm from '../../Contents/Forms/CopyContentToEnvironmentsForm'
import { isContent } from '../../../types/contents'
import { SelectedInfopageType } from '../../../types/infopages'
import { getContentResolutionType } from '../../../utils/fabric/resolution'

const styles = () => ({
  actions: {
    float: 'right' as 'right'
  }
})

export interface InfopageCardActionsOwnProps {
  infopage: ContentUI | InfopageStore
  deleteInfopage: DeleteContent
  duplicateContent: DuplicateContent
  closeDialog: CloseDialog
  type: SelectedInfopageType
  isSystemAdmin: boolean
  t: TFunction
}

type InfopageCardActionsProps = InfopageCardActionsOwnProps & WithStyles<typeof styles>

const InfopageCardActions = ({
  classes,
  infopage,
  deleteInfopage,
  duplicateContent,
  closeDialog,
  type,
  isSystemAdmin,
  t
}: InfopageCardActionsProps) => {
  const id = isContent(infopage) ? infopage.contentId : infopage.infopageId
  const { name } = infopage
  return (
    <CardActions className={classes.actions}>
      <Tooltip disableInteractive title={t('contents.actions.duplicate')}>
        <IconButton onClick={() => duplicateContent(id)} size='large'>
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>
      {isSystemAdmin && isContent(infopage) && (
        <Dialog
          dialogId={`CopyContentToEnvironments.${id}`}
          title={t('contents.actions.copyContentToEnvironments', { name })}
          ButtonIcon={ShareIcon}
          tooltipTitle={t('contents.actions.copyContentToEnvironments')}
          Content={
            <CopyContentToEnvironmentsForm
              dialogId={`CopyContentToEnvironments.${id}`}
              initialValues={{
                contentId: id
              }}
            />
          }
        />
      )}

      {isContent(infopage) && (
        <Dialog
          dialogId={`UpdateContentDialog.${id}`}
          title={`${t('contents.updateContent')} (${name})`}
          ButtonIcon={SettingsIcon}
          tooltipTitle={t('contents.updateContent')}
          Content={
            <CreateInfopageOrContentForm
              isUpdate
              dialogId={`UpdateContentDialog.${id}`}
              submitButtonLabel={t('general.update')}
              initialValues={{
                contentId: id,
                name,
                selectedResolution: getContentResolutionType(infopage.resolution),
                width: infopage.resolution.width,
                height: infopage.resolution.height
              }}
            />
          }
        />
      )}

      <Dialog
        dialogId={`removeContentDialog.${id}`}
        title={`${t('contents.deleteContent')} (${name})`}
        ButtonIcon={DeleteIcon}
        tooltipTitle={t('contents.deleteContent')}
        Content={
          <Fragment>
            <DialogContent>
              <DialogContentText>{t('contents.deleteContentText')}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => closeDialog(`removeContentDialog.${id}`)} color='primary'>
                {t('general.cancel')}
              </Button>
              <Button onClick={() => deleteInfopage(type, id)} color='primary' autoFocus>
                {t('general.delete')}
              </Button>
            </DialogActions>
          </Fragment>
        }
      />
    </CardActions>
  )
}
export default withStyles(styles)(InfopageCardActions)
