import React, { ReactNode } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import signageBackground from '../../images/SeeSignage_background.jpg'

const useStyles = makeStyles(() => ({
  root: {
    backgroundImage: `url(${signageBackground})`,
    position: 'absolute',
    top: 0,
    zIndex: 1300,
    width: '100vw',
    height: '100vh',
    backgroundSize: 'cover',
    textAlign: 'center'
  }
}))

interface BackgroundProps {
  children: ReactNode
}

/**
 * Background wrapper component for logged out views:
 * - Login
 * - Reset password
 * - Signup
 */
const Background = ({ children }: BackgroundProps) => {
  const classes = useStyles()

  return <div className={classes.root}>{children}</div>
}

export default Background
