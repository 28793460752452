import {
  RecurringRule,
  AutocompleteOption,
  ChannelItemPriority,
  LogicalOperator,
  Channel,
  DocumentPermissions
} from '@seesignage/seesignage-utils'
import { CreatableOptionType } from '../components/FormInput/ReduxWrappers/ReduxCreateableAutocomplete'
import { IndexById } from './states'

export enum ChannelItemType {
  playlist = 'playlist',
  campaign = 'campaign'
}
export interface ChannelCardItem {
  id: string
  type: ChannelItemType
  name: string
  startDate: Date
  endDate: Date
}

export interface RecurringRuleFormValue extends RecurringRule {
  noEndDate: boolean
}

export interface CreateChannelFormData {
  name: string
  tags?: AutocompleteOption[]
  permissionTags?: AutocompleteOption[]
  syncPlay?: boolean
  permissions?: DocumentPermissions
  defaultPlaylistInterval?: number | null
}

export interface CreateChannelInterface {
  name: string
  tags?: string[] | null
  permissionTags?: string[] | null
  syncPlay?: boolean
  permissions?: DocumentPermissions
  defaultPlaylistInterval?: number | null
}

export interface UpdateChannelFormData extends CreateChannelFormData {
  channelId: string
}

export interface ScreensFormValueAutocompleteOption extends AutocompleteOption {
  data: {
    environmentId: string
    screenId: string
    code: string
    /** Exists only when validating screen code. This is not stored to backend. */
    name?: string
  }
}

export interface ChannelItemConditionsFormValue {
  tags?: CreatableOptionType[]
  operator?: LogicalOperator
  screens?: ScreensFormValueAutocompleteOption[] | null
}

export interface UpdateChannelItemFormData {
  playlistId: AutocompleteOption
  start: Date
  end: Date
  startTime?: Date
  endTime?: Date
  /** new playlist name if creating new one */
  name?: string
  /** new playlist default interval if creating new one */
  defaultInterval?: string
  /** might be null if toggled between visible and not */
  recurringRule?: RecurringRuleFormValue | null
  /** recurring event boolean */
  recurringEvent?: boolean
  priority?: ChannelItemPriority
  conditions?: ChannelItemConditionsFormValue
}

export interface CreateChannelItemsFormData {
  playlist: {
    name: string
    defaultInterval: number
    permissionTags?: AutocompleteOption[] | null
    syncPlay?: boolean
  }
  channelItem: UpdateChannelItemFormData
  isNewPlaylist: boolean
}

export interface UpdateChannelItemsFormData extends CreateChannelItemsFormData {
  itemId: string
}

export interface CalendarEvent {
  itemId: string
  playlistId?: string
  campaignId?: string
  environmentId: string
  title: string
  start: Date
  end: Date
  startTime?: Date
  endTime?: Date
  playlistItemsCount?: number
  recurringRule?: RecurringRule
  priority?: ChannelItemPriority
  userHasAccess?: boolean
  tags?: string[]
}

export interface CalendarSlot {
  start: string | Date
  end: string | Date
  slots: Date[] | string[]
  action: 'select' | 'click' | 'doubleClick'
}

export interface AllChannels {
  channels: IndexById<Channel>
  parentChannels: IndexById<Channel>
}
