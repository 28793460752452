import React from 'react'
import { reduxForm, InjectedFormProps } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { ContentWeatherWidgetFormData } from '../../../../../types/contents'
import CommonContentFields from './CommonContentFields'

type FormProps = ContentWeatherWidgetFormData

const ContentWeatherForm: React.FC<InjectedFormProps<FormProps>> = () => {
  const [t] = useTranslation()
  return <CommonContentFields formName={'ContentWeatherForm'} enableSize t={t} />
}
export default reduxForm<FormProps>({
  form: 'ContentWeatherForm'
})(ContentWeatherForm)
