/* eslint-disable @typescript-eslint/camelcase */
import { all, takeLatest, put, call } from 'redux-saga/effects'
import { SubmissionError, change } from 'redux-form'
import {
  AutocompleteOption,
  GetProofOfPlayOptionsResponse,
  ProofOfPlayChannelOption,
  ProofOfPlayPlaylistOption,
  QueryProofOfPlayRequest,
  QueryProofOfPlayResponse
} from '@seesignage/seesignage-utils'
import Api from '../services/api/screens'
import {
  getProofOfPlayOptions,
  getProofOfPlayOptionsFail,
  getProofOfPlayOptionsSuccess,
  queryProofOfPlay,
  queryProofOfPlayFail,
  queryProofOfPlaySuccess
} from '../actions/analytics'
import i18n from '../translations/i18n'
import { HandleQueryProofOfPlayParams } from '../types/formData'
import { compareStrings } from '../utils/sorting'
import { PROOF_OF_PLAY_FORM_NAME } from '../containers/Analytics/Reports/ProofOfPlay/Forms/ProofOfPlayForm'

export function* handleQueryProofOfPlay({
  payload: { formData, resolve, reject }
}: HandleQueryProofOfPlayParams) {
  try {
    const {
      environmentId,
      startDate,
      endDate,
      playlistIds,
      channelIds,
      interval,
      countPlaysByAttribute
    } = formData
    if (environmentId) {
      const query: QueryProofOfPlayRequest = {
        environmentId,
        startDate,
        endDate,
        interval,
        countPlaysByAttribute,
        playlistIds: playlistIds?.map(({ value }) => value),
        channelIds: channelIds?.map(({ value }) => value)
      }
      const response: QueryProofOfPlayResponse = yield call(
        Api.queryProofOfPlay,
        environmentId,
        query
      )
      yield put(queryProofOfPlaySuccess({ query, response }))
    }
    resolve()
  } catch (error) {
    yield put(queryProofOfPlayFail(error.message))
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.analytics.somethingWentWrongQueryProofOfPlay')
      })
    )
  }
}

const convertPlaylistsAsAutocompleOptions = (items: ProofOfPlayPlaylistOption[]) =>
  items
    .map(
      ({ playlist_id, playlist_name }) =>
        ({ value: playlist_id, label: playlist_name } as AutocompleteOption)
    )
    .sort(compareStrings('label'))

const convertChannelsAsAutocompleOptions = (items: ProofOfPlayChannelOption[]) =>
  items
    .map(
      ({ channel_id, channel_name }) =>
        ({ value: channel_id, label: channel_name } as AutocompleteOption)
    )
    .sort(compareStrings('label'))

interface HandleGetProofOfPlayOptionsParams {
  payload: {
    /** ISO date string */
    startDate: string
    /** ISO date string */
    endDate: string
    environmentId: string
    screenEnvironmentId: string
  }
}

export function* handleGetProofOfPlayOptions({
  payload: { startDate, endDate, environmentId, screenEnvironmentId }
}: HandleGetProofOfPlayOptionsParams) {
  try {
    // reset form's selected 'channelIds' and 'playlistIds' because those might not exist
    // in the selected date range.
    yield put(change(PROOF_OF_PLAY_FORM_NAME, 'channelIds', undefined))
    yield put(change(PROOF_OF_PLAY_FORM_NAME, 'playlistIds', undefined))
    const { channels, playlists }: GetProofOfPlayOptionsResponse = yield call(
      Api.getProofOfPlayOptions,
      environmentId, // Get options for environment selected from the form field.
      screenEnvironmentId,
      startDate,
      endDate
    )
    yield put(
      getProofOfPlayOptionsSuccess({
        // Show options (channels/playlists) that are from currently navigated environment
        channels: convertChannelsAsAutocompleOptions(channels),
        playlists: convertPlaylistsAsAutocompleOptions(playlists)
      })
    )
  } catch (error) {
    yield put(getProofOfPlayOptionsFail(error.message))
  }
}

function* watchAnalyticsActions() {
  yield all([
    takeLatest(queryProofOfPlay, handleQueryProofOfPlay),
    takeLatest(getProofOfPlayOptions, handleGetProofOfPlayOptions)
  ])
}

export default [watchAnalyticsActions]
