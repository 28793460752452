import TableRow from '@mui/material/TableRow'
import withStyles from '@mui/styles/withStyles'

const CustomStyledTableRow = withStyles({
  selected: {
    backgroundColor: 'rgb(145, 225, 255, 0.5) !important'
  }
})(TableRow)

export default CustomStyledTableRow
