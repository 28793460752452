import React from 'react'
import { Typography } from '@mui/material'
import { PlaylistItemDuration } from '../../../../../types/playlists'
import { millisToMinutesAndSeconds } from '../../../../../utils/time'

const getDurationRangeText = (min: number, max: number) =>
  min === max
    ? millisToMinutesAndSeconds(min)
    : `${millisToMinutesAndSeconds(min)}-${millisToMinutesAndSeconds(max)}`

interface ItemDurationProps {
  itemDuration: PlaylistItemDuration
}

const ItemDuration: React.FC<ItemDurationProps> = ({ itemDuration: { duration, range } }) => (
  <div style={{ position: 'absolute', right: 5, bottom: 5 }}>
    <Typography variant='caption'>
      {range ? getDurationRangeText(range.min, range.max) : millisToMinutesAndSeconds(duration)}
    </Typography>
  </div>
)

export default ItemDuration
