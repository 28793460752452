import React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Grid, IconButton, Typography, Tooltip } from '@mui/material'
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon'
import Truncate from 'react-truncate'
import { FieldArrayFieldsProps } from 'redux-form'
import { RevolverItemUI, Media } from '@seesignage/seesignage-utils'
import { TFunction } from 'i18next'
import { getItemStyle } from '../../../../components/Draggable/DraggableItems'
import { MediaCarouselRevolver, isMediaCarouselRevolverItem } from '../../../../types/contents'

const imageStyle = {
  display: 'block',
  margin: 'auto',
  height: '100%',
  width: '100%',
  objectFit: 'contain' as any
}

const videoStyle = {
  height: '100%',
  width: '100%'
}

const helperTextStyle = {
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

interface SelectedRevolverItemsProps {
  selectedMedia: FieldArrayFieldsProps<RevolverItemUI | Media | MediaCarouselRevolver>
  isMobile: boolean
  t: TFunction
}

const SelectedRevolverItems = ({ selectedMedia, isMobile, t }: SelectedRevolverItemsProps) => {
  return (
    <div style={{ height: 500, overflow: 'auto', padding: 8 }}>
      {selectedMedia?.length ? (
        <DragDropContext
          onDragEnd={({ destination, source }) => {
            if (destination && destination.index !== source.index) {
              selectedMedia.move(source.index, destination.index)
            }
          }}>
          <Droppable droppableId='doppableRevolverItems'>
            {({ innerRef, placeholder }) => (
              <div ref={innerRef}>
                {selectedMedia.getAll().map((item, index) => {
                  const id = isMediaCarouselRevolverItem(item) ? item.id : item.key
                  // note: itemId does not exist on mediaCarousel items
                  const draggableKey = (item as RevolverItemUI).itemId || `${id}-${index}`
                  const mediaName = item.name || ''
                  const type = item.type
                  const url = isMediaCarouselRevolverItem(item)
                    ? item.url
                    : item.thumbnailUrl || item.url
                  return (
                    <Draggable key={draggableKey} draggableId={draggableKey} index={index}>
                      {({ innerRef, draggableProps, dragHandleProps }, { isDragging }) => (
                        <div
                          ref={innerRef}
                          {...draggableProps}
                          {...dragHandleProps}
                          style={getItemStyle(isDragging, draggableProps.style)}>
                          <div style={{ position: 'relative', height: 150, margin: 4 }}>
                            <Grid container style={{ height: '100%' }} alignContent='center'>
                              <Grid item xs={5} style={{ height: '100%' }}>
                                {type === 'video' ? (
                                  <video style={videoStyle} controls src={url} />
                                ) : (
                                  <img style={imageStyle} src={url} alt='' />
                                )}
                              </Grid>
                              <Grid item xs={6} style={{ padding: 8 }}>
                                <Tooltip disableInteractive title={mediaName}>
                                  <Typography variant='caption' style={{ paddingTop: 10 }}>
                                    <Truncate width={isMobile ? 150 : 190} lines={1}>
                                      {mediaName}
                                    </Truncate>
                                  </Typography>
                                </Tooltip>
                                <Typography style={{ color: 'lightGrey', fontSize: 36 }}>
                                  {index + 1}
                                </Typography>
                              </Grid>
                            </Grid>
                            <IconButton
                              style={{ position: 'absolute', top: 0, right: 0 }}
                              onClick={() => selectedMedia.remove(index)}
                              size='large'>
                              <CloseCircleOutlineIcon />
                            </IconButton>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  )
                })}
                {placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <div style={helperTextStyle}>
          {t('contents.widgets.mediaCarousel.selectAtleastOneMedia')}
        </div>
      )}
    </div>
  )
}
export default SelectedRevolverItems
