import React from 'react'
import { reduxForm, Field, InjectedFormProps } from 'redux-form'
import { Grid, Typography, Divider } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { TFunction } from 'i18next'
import { FabricWidgetObjectFit } from '@seesignage/seesignage-utils'
import {
  ContentSocialMediaWidgetSettingsFormData,
  SocialMediaWidgetSettings
} from '../../../../../../types/contents'
import {
  ReduxCheckbox,
  ReduxDropDownSelection
} from '../../../../../../components/FormInput/ReduxWrappers'
import SimpleExtensionPanel from '../../../../../../components/ExtensionPanels/SimpleExtensionPanel'
import VolumeControl from '../../../../../../components/FormInput/ReduxWrappers/Contents/VolumeControl'
import ContentColorPicker from '../../../../../../components/FormInput/ColorPicker/ContentColorPicker'

const useStyles = makeStyles(() => ({
  divider: { marginTop: '10px' },
  settingsTitle: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '12px',
    marginBottom: '4px'
  },
  titleMarginLeft: {
    marginLeft: 10
  },
  accordionSummaryRootClass: {
    backgroundColor: '#F5F5F5',
    borderBottom: '1px solid rgba(0, 0, 0, 0.09)'
  },
  gridItem: {
    display: 'flex',
    justifyContent: 'center'
  },
  shortTextfield: {
    maxWidth: '85px'
  },
  littleTopMargin: {
    marginTop: '9px'
  }
}))

const FORM_NAME = 'ContentSocialMediaSettingsForm'

interface OwnProps {
  t: TFunction
}

type FormProps = ContentSocialMediaWidgetSettingsFormData
type ComponentProps = OwnProps

const ContentSocialMediaSettingsForm: React.FC<ComponentProps &
  InjectedFormProps<FormProps, ComponentProps>> = ({ t }) => {
  const classes = useStyles()
  return (
    <SimpleExtensionPanel
      title={t('contents.widgets.socialMedia.generalSettings')}
      isOpen={true}
      accordionSummaryRootClass={classes.accordionSummaryRootClass}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className={classes.settingsTitle}>
            {t('contents.widgets.socialMedia.objectFit')}
          </Typography>
          <Field
            name={SocialMediaWidgetSettings.widgetObjectFit}
            component={ReduxDropDownSelection}
            labelId='social-media-widget-objectFit'
            size='small'
            label=''
            options={Object.keys(FabricWidgetObjectFit).map(item => ({
              label: t(`contents.widgets.socialMedia.${item}`),
              value: item
            }))}
          />
          <Divider className={classes.divider} />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={6}>
            <Typography className={classes.settingsTitle}>
              {t('contents.widgets.socialMedia.showHeader')}
            </Typography>
            <Field
              name={SocialMediaWidgetSettings.showHeader}
              type='checkbox'
              component={ReduxCheckbox}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.settingsTitle}>
              {t('contents.widgets.socialMedia.showCaption')}
            </Typography>
            <Field
              name={SocialMediaWidgetSettings.showCaption}
              type='checkbox'
              component={ReduxCheckbox}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.settingsTitle}>
            {t('contents.widgets.socialMedia.slideshowInterval')}
          </Typography>
          <Field
            name={SocialMediaWidgetSettings.slideShowInterval}
            component={ReduxDropDownSelection}
            size='small'
            label=''
            options={[5, 10, 15, 20, 30, 45, 60].map(value => ({
              label: `${value} seconds`,
              value
            }))}
          />
          <Divider className={classes.divider} />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.settingsTitle}>
            {t('contents.widgets.socialMedia.backgroundColor')}
          </Typography>
          <Field
            name={SocialMediaWidgetSettings.widgetBackground}
            size='small'
            component={ContentColorPicker}
          />
          <Divider className={classes.divider} />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.settingsTitle}>
            {t('contents.widgets.socialMedia.videoAudio')}
          </Typography>
          <Field
            name={SocialMediaWidgetSettings.videosMuted}
            component={VolumeControl}
            format={(value: boolean) => !value}
            parse={(value: boolean) => !value}
            type='checkbox'
          />
          <Divider className={classes.divider} />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.settingsTitle}>
            {t('contents.widgets.socialMedia.showContentOnlyIfPostExists')}
          </Typography>
          <Field
            name={SocialMediaWidgetSettings.showContentOnlyIfPostExists}
            type='checkbox'
            component={ReduxCheckbox}
          />
        </Grid>
      </Grid>
    </SimpleExtensionPanel>
  )
}

export default reduxForm<FormProps, ComponentProps>({
  form: FORM_NAME,
  enableReinitialize: true
})(ContentSocialMediaSettingsForm)
