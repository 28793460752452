import { Divider, LinearProgress, Typography } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { selectEnvironmentStorageQuota } from '../../../selectors/environments'
import { selectStorageUsed } from '../../../selectors/media'
import { selectEnvironmentIdFromPathname } from '../../../selectors/routing'
import { StateInterface } from '../../../types/states'
import { convertBytesToGB } from '../../../utils/media'

const styles = () => ({
  mediaQuotaContainer: {
    maxWidth: 300,
    margin: 'auto',
    padding: '20px 5px'
  }
})

interface StateProps {
  storageUsedInGB: number
  /** environment quota in Giga Bytes */
  environmentQuota?: number
}

type MediaQuotaProps = StateProps & WithStyles<typeof styles> & WithTranslation

const MediaQuota: React.FC<MediaQuotaProps> = ({ storageUsedInGB, environmentQuota, classes, t }) =>
  environmentQuota ? (
    <Fragment>
      <Divider />
      <div className={classes.mediaQuotaContainer}>
        <LinearProgress variant='determinate' value={(storageUsedInGB / environmentQuota) * 100} />
        <Typography variant='caption'>
          {t('media.quota.storageUsed', {
            amount: storageUsedInGB.toFixed(2),
            quota: environmentQuota
          })}
        </Typography>
      </div>
    </Fragment>
  ) : (
    <Fragment />
  )

const mapStateToProps = (state: StateInterface): StateProps => {
  const environmentId = selectEnvironmentIdFromPathname(state)
  const storageUsedInBytes = selectStorageUsed(state)
  return {
    storageUsedInGB: convertBytesToGB(storageUsedInBytes),
    environmentQuota: selectEnvironmentStorageQuota(environmentId)(state)
  }
}

export default connect(mapStateToProps)(withStyles(styles)(withTranslation()(MediaQuota)))
