import React from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import TextFields from '../CommonFields/TextFields'
import SimpleExtensionPanel from '../../../../../../components/ExtensionPanels/SimpleExtensionPanel'

const useStyles = makeStyles(() => ({
  accordionSummaryRootClass: {
    backgroundColor: '#F5F5F5',
    borderBottom: '1px solid rgba(0, 0, 0, 0.09)'
  }
}))

interface TextSettingsProps {
  title: string
  namePrefix: string
}

const TextSettings = ({ title, namePrefix }: TextSettingsProps) => {
  const [t] = useTranslation()
  const classes = useStyles()
  return (
    <SimpleExtensionPanel
      title={title}
      isOpen={false}
      accordionSummaryRootClass={classes.accordionSummaryRootClass}>
      <div>
        <TextFields isFieldsDisabled={false} t={t} namePrefix={namePrefix} />
      </div>
    </SimpleExtensionPanel>
  )
}

export default TextSettings
