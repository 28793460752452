import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker'
import React from 'react'
import { IconButton } from '@mui/material'
import ClearIcon from 'mdi-react/ClearIcon'
import { WrappedFieldProps } from 'redux-form'
import { PickersActionBarAction } from '@mui/x-date-pickers'
import { convertDatePickerValue } from '../../../utils/forms'

interface ReduxTimePickerProps extends WrappedFieldProps {
  label: string
  clearable?: boolean
  fullWidth?: boolean
  required?: boolean
  size?: 'small' | 'medium'
}

const ReduxTimePicker: React.FC<ReduxTimePickerProps> = ({
  input: { value, onChange, onBlur, onFocus },
  label,
  meta: { invalid, touched, error },
  clearable,
  required,
  size,
  ...custom
}) => (
  <MobileTimePicker
    ampm={false}
    label={label}
    format='HH:mm'
    value={convertDatePickerValue(value)}
    onChange={onChange}
    slotProps={{
      actionBar: {
        actions: [...(required ? [] : ['clear' as PickersActionBarAction]), 'cancel', 'accept']
      },
      textField: {
        error: invalid && touched,
        helperText: invalid && touched ? error : undefined,
        required,
        size,
        variant: 'standard',
        InputProps: {
          endAdornment:
            clearable && value ? (
              <IconButton
                onClick={e => {
                  e.stopPropagation()
                  onChange(null)
                }}
                size='large'>
                <ClearIcon size={16} />
              </IconButton>
            ) : (
              undefined
            )
        },
        onBlur: () => onBlur(value),
        onFocus: () => onFocus(value)
      }
    }}
    {...custom}
  />
)

export default ReduxTimePicker
