import React, { ComponentType } from 'react'
import { TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { MdiReactIconProps } from 'mdi-react'
import { AutocompleteOption } from '@seesignage/seesignage-utils'
import { WrappedFieldMetaProps, WrappedFieldInputProps, WrappedFieldProps } from 'redux-form'

interface ReduxTagSelectorProps extends WrappedFieldProps {
  input: WrappedFieldInputProps
  label: string
  meta: WrappedFieldMetaProps
  options: AutocompleteOption[]
  disabled?: boolean
  OptionIcon?: ComponentType<MdiReactIconProps>
  popupIcon?: JSX.Element
}

const ReduxTagSelector = ({
  input,
  label,
  meta: { error, touched, invalid },
  ...custom
}: ReduxTagSelectorProps) => (
  <>
    <Autocomplete
      value={input.value || []}
      getOptionLabel={option => option}
      onChange={(event: any, value: any) => {
        input.onChange(value)
      }}
      renderInput={params => (
        <TextField
          variant='standard'
          {...params}
          label={label}
          fullWidth
          error={touched && invalid}
          helperText={touched && typeof error === 'string' ? error : undefined}
        />
      )}
      filterSelectedOptions
      {...custom}
    />
  </>
)

export default ReduxTagSelector
