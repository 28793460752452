import { Typography, Grid } from '@mui/material'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Field, reduxForm, InjectedFormProps, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { LunchProductById } from '@seesignage/seesignage-utils/lib/types/lunchLists'
import {
  ReduxSelectWithAutocomplete,
  ReduxSwitch
} from '../../../components/FormInput/ReduxWrappers'
import { CloseDialog } from '../../../types/actions'
import DialogActions from '../../../components/DialogActions'
import { selectLunchListProductsAsOptions } from '../../../utils/lunchLists'
import { StateInterface } from '../../../types/states'

interface StateProps {
  isHidden?: boolean
}

interface OwnProps {
  submitAction: (formData: any) => void
  closeDialog: CloseDialog
  dialogId: string
  products: LunchProductById
}

interface FormProps {
  isHidden?: boolean
  productId?: string
}

type ComponentProps = OwnProps & StateProps

type AddLunchProductChangesFormProps = ComponentProps & WithTranslation

const AddLunchProductChangesForm: React.FC<AddLunchProductChangesFormProps &
  InjectedFormProps<{}, ComponentProps>> = ({
  error,
  handleSubmit,
  submitting,
  submitAction,
  closeDialog,
  dialogId,
  products,
  isHidden,
  t
}) => (
  <form onSubmit={handleSubmit(submitAction)}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Field
          name='isHidden'
          component={ReduxSwitch}
          label={t('lists.lunch.addLunchProductChangesForm.isHidden')}
        />
        <Typography display='block' variant='caption'>
          {t('lists.lunch.addLunchProductChangesForm.isHiddenInfo')}
        </Typography>
      </Grid>
      {!isHidden && (
        <Grid item xs={12}>
          <Field
            name='temporaryProductId'
            placeholder={t('lists.lunch.addLunchProductChangesForm.productId')}
            label={t('lists.lunch.addLunchProductChangesForm.productId')}
            options={[
              {
                value: 'none',
                label: t('lists.lunch.addLunchProductChangesForm.noProduct')
              },
              ...selectLunchListProductsAsOptions(products)
            ]}
            component={ReduxSelectWithAutocomplete}
          />
        </Grid>
      )}
    </Grid>
    <Typography variant='body1' color='error'>
      {error || ''}
    </Typography>
    <DialogActions
      dialogId={dialogId}
      submitting={submitting}
      closeDialog={closeDialog}
      submitButtonLabel={t('general.save')}
    />
  </form>
)

const selector = formValueSelector('AddLunchProductChangesForm')

const mapStateToProps = (state: StateInterface): StateProps => ({
  isHidden: selector(state, 'isHidden')
})

export default connect<StateProps, {}, OwnProps, StateInterface>(mapStateToProps)(
  reduxForm<{}, ComponentProps>({
    form: 'AddLunchProductChangesForm'
  })(withTranslation()(AddLunchProductChangesForm))
)
