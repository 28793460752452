import React, { Fragment } from 'react'
import { WrappedFieldInputProps } from 'redux-form'
import FormatUnderlineIcon from 'mdi-react/FormatUnderlineIcon'
import FormatOverlineIcon from 'mdi-react/FormatOverlineIcon'
import { Checkbox } from '@mui/material'

const toolbarIconSize = 25
const textAligns = [
  {
    Icon: FormatUnderlineIcon,
    decoration: 'underline'
  },
  {
    Icon: FormatOverlineIcon,
    decoration: 'overline'
  }
]

interface TextDecorationCheckBoxProps {
  input: WrappedFieldInputProps
  disabled?: boolean
}

const TextDecorationCheckBox = ({
  input: { onChange, value },
  disabled
}: TextDecorationCheckBoxProps) => (
  <Fragment>
    {textAligns.map(({ Icon, decoration }) => (
      <Checkbox
        disabled={disabled}
        key={decoration}
        checked={value === decoration ? true : false}
        icon={<Icon size={toolbarIconSize} />}
        checkedIcon={
          <Icon
            size={toolbarIconSize}
            color='#1e88e5'
            style={{ borderRadius: '50%', border: '1px solid #1e88e5' }}
          />
        }
        onChange={() => onChange(decoration !== value ? decoration : undefined)}
      />
    ))}
  </Fragment>
)

export default TextDecorationCheckBox
