import React, { Fragment } from 'react'
import { reduxForm, InjectedFormProps, Field } from 'redux-form'
import { useTranslation } from 'react-i18next'
import ContentColorPicker from '../../../../../components/FormInput/ColorPicker/ContentColorPicker'
import { ContentPathFormData } from '../../../../../types/contents'
import ContentToolbarItem from '../ContentToolbarItem/ContentToolbarItem'
import CommonContentFields from './CommonContentFields'

type FormProps = ContentPathFormData

const ContentPathForm: React.FC<InjectedFormProps<FormProps>> = () => {
  const [t] = useTranslation()
  return (
    <Fragment>
      <CommonContentFields
        formName={'ContentPathForm'}
        enableRotation
        enableSize
        enableScale
        t={t}
      />
      <ContentToolbarItem>
        <Field
          name='stroke'
          component={ContentColorPicker}
          tooltip={t('contents.properties.lineColor')}
        />
      </ContentToolbarItem>
    </Fragment>
  )
}
export default reduxForm<FormProps>({
  form: 'ContentPathForm'
})(ContentPathForm)
