import ImageIcon from 'mdi-react/FileImageIcon'
import VideoIcon from 'mdi-react/FileVideoIcon'
import React, { Fragment } from 'react'
import Truncate from 'react-truncate'
import QualityHighIcon from 'mdi-react/QualityHighIcon'
import UhdIcon from 'mdi-react/Video4kBoxIcon'
import { Tooltip, Typography } from '@mui/material'
import { MediaQuality, PlaylistItemType } from '@seesignage/seesignage-utils'
import { TFunction } from 'i18next'

interface VideoQualityIndicatorProps {
  quality?: MediaQuality | 'fullhd'
}

const VideoQualityIndicator = ({ quality }: VideoQualityIndicatorProps) =>
  quality ? (
    quality === MediaQuality.hq ? (
      <QualityHighIcon color='red' />
    ) : (
      <UhdIcon color='blue' />
    )
  ) : (
    <Fragment />
  )

interface ItemTitleProps {
  containerClass: string
  fileIconClass: string
  mediaTextClass: string
  type: PlaylistItemType
  name?: string
  quality?: MediaQuality | 'fullhd'
  isItemTranscoding: boolean
  t: TFunction
}

const ItemTitle: React.FC<ItemTitleProps> = ({
  containerClass,
  fileIconClass,
  mediaTextClass,
  type,
  name,
  quality,
  isItemTranscoding,
  t
}) => (
  <div className={containerClass}>
    <div className={fileIconClass}>
      {type === 'image' ? <ImageIcon /> : <VideoIcon />}
      <VideoQualityIndicator quality={quality} />
    </div>
    <div className={mediaTextClass}>
      <Tooltip disableInteractive title={name || ''}>
        <>
          <Truncate width={280} lines={1}>
            {name || '-'}
          </Truncate>
        </>
      </Tooltip>
      {isItemTranscoding && (
        <Typography color='secondary'>{t('media.videoTranscoding')}</Typography>
      )}
    </div>
  </div>
)

export default ItemTitle
