import {
  Button,
  DialogActions,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  LinearProgress
} from '@mui/material'
import CloseCircleIcon from 'mdi-react/CloseCircleOutlineIcon'
import React, { Fragment, useState, useEffect } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import Truncate from 'react-truncate'
import { Dispatch } from 'redux'
import { PlaylistUI } from '@seesignage/seesignage-utils'
import { pushMediaToPlaylists } from '../../../actions/media'
import {
  selectPlaylistsByAccessAsArray,
  selectListPlaylistsIsLoading
} from '../../../selectors/playlists'
import { selectIsUserDeviceMobile } from '../../../selectors/users'
import { compareStrings } from '../../../utils/sorting'
import { StateInterface } from '../../../types/states'
import { CloseDialog } from '../../../types/actions'
import { listPlaylists } from '../../../actions/playlists'

interface OwnProps {
  closeDialog: CloseDialog
  dialogId: string
  submitButtonLabel: string
}
interface StateProps {
  playlistsLoading: boolean
  playlists: PlaylistUI[]
  isMobile: boolean
}

interface DispatchProps {
  pushMediaToPlaylists: (selectedPlaylists: PlaylistUI[]) => void
}
type PushToPlaylistsFormProps = OwnProps & StateProps & DispatchProps & WithTranslation

const PushToPlaylistsForm: React.FC<PushToPlaylistsFormProps> = ({
  playlists,
  playlistsLoading,
  pushMediaToPlaylists,
  closeDialog,
  dialogId,
  submitButtonLabel,
  isMobile,
  t
}) => {
  const [searchTerm, search] = useState('')
  const [selectedPlaylists, setSelectedPlaylists] = useState<PlaylistUI[]>([])

  const selectPlaylist = (playlist: PlaylistUI) => {
    setSelectedPlaylists([...selectedPlaylists, playlist])
  }

  const deselectPlaylist = (deselectedPlaylistId: string) => {
    setSelectedPlaylists(
      selectedPlaylists.filter(({ playlistId }) => playlistId !== deselectedPlaylistId)
    )
  }
  const visiblePlaylists = searchTerm.length
    ? playlists.filter(({ name }) => name.toLowerCase().includes(searchTerm.toLowerCase()))
    : playlists

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(listPlaylists())
  }, []) // eslint-disable-line
  return (
    <Fragment>
      <Grid container>
        <Grid item xs={5} sm={6}>
          <Grid item xs={12}>
            <TextField
              variant='standard'
              label={t('general.search')}
              onChange={(e: any) => search(e.target.value)}
            />
          </Grid>
          <div style={{ overflow: 'auto', height: '480px' }}>
            {playlistsLoading && <LinearProgress />}
            {visiblePlaylists.map(playlist => (
              <Grid key={playlist.playlistId} item xs={12}>
                <Button onClick={() => selectPlaylist(playlist)}>
                  <Truncate lines={1}>{playlist.name}</Truncate>
                </Button>
                <Divider />
              </Grid>
            ))}
          </div>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5}>
          <Typography style={{ right: '50%' }} variant='body1'>
            {t('playlists.actions.selectedPlaylists')}
          </Typography>
          <div style={{ overflow: 'auto', height: '480px' }}>
            {selectedPlaylists &&
              selectedPlaylists.map(({ name, playlistId }) => (
                <Fragment key={playlistId}>
                  <Grid container>
                    <Grid item xs={10}>
                      <Typography variant='body2' style={{ marginTop: '10px' }}>
                        <Truncate lines={1}>{name}</Truncate>
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton onClick={() => deselectPlaylist(playlistId)} size='large'>
                        <CloseCircleIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider />
                </Fragment>
              ))}
          </div>
        </Grid>
      </Grid>
      <DialogActions style={isMobile ? { justifyContent: 'center' } : undefined}>
        <Button onClick={() => closeDialog(dialogId)} color='primary'>
          {t('general.cancel')}
        </Button>
        <Button
          disabled={!selectedPlaylists.length}
          onClick={() => pushMediaToPlaylists(selectedPlaylists)}
          color='primary'>
          {submitButtonLabel}
        </Button>
      </DialogActions>
    </Fragment>
  )
}
const mapStateToProps = (state: StateInterface): StateProps => ({
  playlistsLoading: selectListPlaylistsIsLoading(state),
  playlists: selectPlaylistsByAccessAsArray(state).sort(compareStrings('name')),
  isMobile: selectIsUserDeviceMobile(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  pushMediaToPlaylists: (selectedPlaylists: PlaylistUI[]) =>
    dispatch(pushMediaToPlaylists(selectedPlaylists))
})

export default connect<StateProps, DispatchProps, OwnProps, StateInterface>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(PushToPlaylistsForm))
