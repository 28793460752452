import { Step, StepLabel, Stepper, LinearProgress } from '@mui/material'
import React, { Fragment, useEffect } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { Dispatch } from 'redux'
import { PlaylistItemType, PlaylistUI } from '@seesignage/seesignage-utils'
import QuestionMarkIcon from 'mdi-react/QuestionMarkIcon'
import BookCogIcon from 'mdi-react/BookCogIcon'
import ItemIcon from 'mdi-react/ImagePlusIcon'
import UrlIcon from 'mdi-react/LinkPlusIcon'
import ShoppingIcon from 'mdi-react/ShoppingIcon'
import TableColumnPlusAfterIcon from 'mdi-react/TableColumnPlusAfterIcon'
import InfopageIcon from 'mdi-react/BookInformationVariantIcon'
import RevolverIcon from 'mdi-react/FerrisWheelIcon'
import { createOrUpdateGeneralProduct } from '../../../../actions/products'
import { bindSubmitActionToPromise } from '../../../../utils/forms'
import CreateProductWizard from '../../../Lists/Forms/CreateProductWizard/CreateProductWizard'
import AddListAsItemForm from '../Items/AddListAsItemForm'
import AddProductAsItemForm from '../Items/AddProductAsItemForm'
import AddUrlItemForm from '../Items/AddUrlItemForm'
import { StateInterface } from '../../../../types/states'
import { selectIsUserDeviceMobile } from '../../../../selectors/users'
import { restoreDialogMaxWidth, setDialogSize } from '../../../../actions/dialogs'
import { createInfopageToPlaylist, updateInfopage } from '../../../../actions/infopages'
import { PageType } from '../../../../types/playlists'
import { listTemplates } from '../../../../actions/templates'
import { listContents } from '../../../../actions/contents'
import { StepIcon, ColorConnector } from '../../../../components/Stepper'
import { selectIsContentsLoading } from '../../../../selectors/contents'
import { selectTemplatesIsLoading } from '../../../../selectors/templates'
import CreateInfopageItem from '../Items/Infopage/CreateInfopageItem'
import CreateInfopageOrContentForm from '../../../Infopages/Forms/CreateInfopageOrContentForm'
import AddExistingInfopageForm from '../Items/Infopage/AddExistingInfopageForm'
import {
  setPlaylistItemWizardPage,
  addPlaylistItems,
  closePlaylistItemWizard
} from '../../../../actions/playlists'
import { selectPlaylistItemWizardPage } from '../../../../selectors/playlists'
import AddRevolverItem from '../Revolver/AddRevolverItem'
import AddExistingMediaForm from '../Media/AddExistingMediaForm'
import { DialogSize } from '../../../../types/dialogs'
import { SelectedInfopageType } from '../../../../types/infopages'
import SelectItemType from './SelectItemType'
import AddProductAsItem from './AddProductAsItem'

const WizardStep = {
  [PageType.selectType]: {
    icon: <QuestionMarkIcon />,
    text: 'playlists.editPlaylist.selectType'
  },
  [PageType.media]: {
    icon: <ItemIcon />,
    text: 'playlists.editPlaylist.selectMedia'
  },
  [PageType.list]: {
    icon: <TableColumnPlusAfterIcon />,
    text: 'playlists.editPlaylist.selectList'
  },
  [PageType.url]: {
    icon: <UrlIcon />,
    text: 'playlists.editPlaylist.addUrl'
  },
  [PageType.productType]: {
    icon: <ShoppingIcon />,
    text: 'playlists.editPlaylist.addProduct'
  },
  [PageType.existingProduct]: {
    icon: <ShoppingIcon />,
    text: 'playlists.editPlaylist.selectExistingProduct'
  },
  [PageType.createProduct]: {
    icon: <ShoppingIcon />,
    text: 'playlists.editPlaylist.createNewProduct'
  },
  [PageType.existingInfopage]: {
    icon: <InfopageIcon />,
    text: 'playlists.editPlaylist.addInfopage'
  },
  [PageType.newInfopage]: {
    icon: <BookCogIcon />,
    text: 'media.details'
  },
  [PageType.createInfopageItem]: {
    icon: <InfopageIcon />,
    text: 'playlists.editPlaylist.createInfopageItem'
  },
  [PageType.revolver]: {
    icon: <RevolverIcon />,
    text: 'playlists.revolver.revolver'
  },
  [PageType.selectContent]: {
    icon: <QuestionMarkIcon />,
    text: 'playlists.editPlaylist.selectContent'
  }
}

const useStyles = makeStyles(() => ({
  stepper: {
    marginBottom: 5
  }
}))

const getSteps = (page: PageType) => {
  return page === PageType.newInfopage || page === PageType.createInfopageItem
    ? [
        WizardStep[PageType.selectType],
        WizardStep[PageType.newInfopage],
        WizardStep[PageType.createInfopageItem]
      ]
    : [
        WizardStep[PageType.selectType],
        page === 'selectType' ? WizardStep[PageType.selectContent] : WizardStep[page]
      ]
}

interface OwnProps {
  playlist: PlaylistUI
}

interface StateProps {
  isMobile: boolean
  isLoading: boolean
}

interface DispatchProps {
  createProduct: (formData: any) => void
  setDialogSize: (size: DialogSize) => void
  restoreDialogMaxWidth: () => void
  createInfopage: (formData: any) => void
  updateInfopage: (formData: any) => void
  addPlaylistItems: (formData: any) => void
  listTemplates: () => void
  listContents: () => void
}

type AddPlaylistItemWizardProps = OwnProps & StateProps & DispatchProps & WithTranslation

const AddPlaylistItemWizard: React.FC<AddPlaylistItemWizardProps> = ({
  addPlaylistItems,
  createProduct,
  isMobile,
  setDialogSize,
  restoreDialogMaxWidth,
  listTemplates,
  listContents,
  playlist,
  isLoading,
  t
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const setPage = (page: PageType) => {
    dispatch(setPlaylistItemWizardPage(page))
  }
  const page = useSelector(selectPlaylistItemWizardPage)

  const handleMountActions = () => {
    listTemplates()
    listContents()
    return () => {
      // unmount component
      dispatch(closePlaylistItemWizard())
    }
  }
  useEffect(handleMountActions, [])

  const selectPage = (page: PageType) => {
    switch (page) {
      // start and media
      case PageType.selectType:
        restoreDialogMaxWidth()
        setPage(page)
        break
      case PageType.createInfopageItem:
        setDialogSize({ fullScreen: true })
        setPage(page)
        break
      case PageType.media:
      case PageType.revolver:
      case PageType.createProduct:
        setDialogSize({ maxWidth: 'xl' })
        setPage(page)
        break
      default:
        restoreDialogMaxWidth()
        setPage(page)
    }
  }
  return (
    <Fragment>
      {!isMobile && page !== PageType.createInfopageItem && (
        <Stepper
          className={classes.stepper}
          alternativeLabel
          connector={<ColorConnector />}
          activeStep={page === PageType.selectType ? 0 : 1}>
          {getSteps(page).map(({ text, icon }) => (
            <Step key={text}>
              <StepLabel StepIconComponent={props => <StepIcon {...props} icon={icon} />}>
                {t(text)}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
      {isLoading && <LinearProgress />}
      {page === PageType.selectType && (
        <SelectItemType selectPage={selectPage} playlist={playlist} />
      )}
      {page === PageType.media && (
        <AddExistingMediaForm
          onSubmit={addPlaylistItems}
          selectPage={selectPage}
          initialValues={{
            type: PageType.media,
            interval: 7,
            existingFiles: []
          }}
        />
      )}
      {page === PageType.existingInfopage && (
        <AddExistingInfopageForm
          selectPage={selectPage}
          submitAction={addPlaylistItems}
          initialValues={{ type: PlaylistItemType.infopage }}
        />
      )}
      {page === PageType.newInfopage && (
        <CreateInfopageOrContentForm
          selectPage={selectPage}
          infopageType={SelectedInfopageType.content}
          goBack={() => selectPage(PageType.existingInfopage)}
          submitButtonLabel={t('general.next')}
        />
      )}
      {page === PageType.createInfopageItem && <CreateInfopageItem selectPage={selectPage} />}
      {page === PageType.revolver && (
        <AddRevolverItem
          onSubmitRevolverItem={addPlaylistItems}
          selectPage={selectPage}
          submitButtonLabel={t('general.add')}
          initialValues={{ revolverItems: [], type: PlaylistItemType.revolver }}
        />
      )}
      {page === PageType.productType && <AddProductAsItem selectPage={selectPage} />}
      {page === PageType.existingProduct && (
        <AddProductAsItemForm
          submitAction={addPlaylistItems}
          selectPage={selectPage}
          initialValues={{
            type: PlaylistItemType.product
          }}
        />
      )}
      {page === PageType.createProduct && (
        <CreateProductWizard
          playlistItem
          onSubmit={createProduct}
          submitButtonLabel={t('general.create')}
          backFunction={selectPage}
        />
      )}
      {page === PageType.list && (
        <AddListAsItemForm
          onSubmit={addPlaylistItems}
          selectPage={selectPage}
          initialValues={{
            type: PlaylistItemType.list
          }}
        />
      )}
      {page === PageType.url && (
        <AddUrlItemForm
          onSubmit={addPlaylistItems}
          selectPage={selectPage}
          submitButtonLabel={t('general.add')}
          initialValues={{
            type: PlaylistItemType.url
          }}
          defaultInterval={playlist.defaultInterval}
        />
      )}
    </Fragment>
  )
}

const mapStateToProps = (state: StateInterface): StateProps => ({
  isMobile: selectIsUserDeviceMobile(state),
  isLoading: selectIsContentsLoading(state) || selectTemplatesIsLoading(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  createProduct: bindSubmitActionToPromise(dispatch, createOrUpdateGeneralProduct),
  setDialogSize: (size: DialogSize) => dispatch(setDialogSize(size)),
  restoreDialogMaxWidth: () => dispatch(restoreDialogMaxWidth()),
  createInfopage: bindSubmitActionToPromise(dispatch, createInfopageToPlaylist),
  updateInfopage: bindSubmitActionToPromise(dispatch, updateInfopage),
  listTemplates: () => dispatch(listTemplates()),
  listContents: () => dispatch(listContents()),
  addPlaylistItems: bindSubmitActionToPromise(dispatch, addPlaylistItems)
})

export default connect<StateProps, DispatchProps, OwnProps, StateInterface>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AddPlaylistItemWizard))
