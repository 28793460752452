import { Grid } from '@mui/material'
import React, { Fragment } from 'react'
import { Field, FieldArray, WrappedFieldArrayProps } from 'redux-form'
import { gridItemProps } from '../Fields/utils'
import { ReduxTextField } from '../../../../components/FormInput/ReduxWrappers'
import { MockProductType } from '../../../../types/mocks'
import FieldsExpansionPanel from '../../../../components/ExtensionPanels/FieldsExpansionPanel'

const productType = MockProductType.meat
const stringProperties = ['name', 'origin', 'batchNumber', 'slaughterId', 'cutId', 'useBefore']

const renderItems = ({ fields, meta: { error, submitFailed } }: WrappedFieldArrayProps) => (
  <Fragment>
    {submitFailed && error && <span>{error}</span>}
    {fields.map((member: string, index: number) => (
      <FieldsExpansionPanel customLabel={`Product ${index + 1}`} key={`${index}-${member}`}>
        <Grid container spacing={2}>
          {stringProperties.map((name, i) => (
            <Grid key={`${i}-${name}`} {...gridItemProps}>
              <Field fullWidth name={`${member}.${name}`} label={name} component={ReduxTextField} />
            </Grid>
          ))}
        </Grid>
      </FieldsExpansionPanel>
    ))}
  </Fragment>
)

const MeatFormFields = () => (
  <Grid container spacing={2} alignItems='center'>
    <FieldArray name={productType} component={renderItems} props={{}} />
  </Grid>
)

export default MeatFormFields
