import { createAction, createAsyncActions } from './utils'

const reducer = 'MEDIA'

const resetMedia = createAction(reducer, 'RESET')

const selectMedia = createAction(reducer, 'SELECT')
const deselectMedia = createAction(reducer, 'DESELECT')
const clearSelection = createAction(reducer, 'CLEAR_SELECTION')

const selectAllKeys = createAction(reducer, 'SELECT_ALL')
const selectFolder = createAction(reducer, 'SELECT_FOLDER')
const deselectFolder = createAction(reducer, 'DESELECT_FOLDER')

const searchMedia = createAction(reducer, 'SEARCH_MEDIA')
const clearSearchMedia = createAction(reducer, 'CLEAR_SEARCH')

const changePage = createAction(reducer, 'CHANGE_PAGE')
const changeItemsPerPage = createAction(reducer, 'CHANGE_ITEMS_PER_PAGE')

const setUploadProgress = createAction(reducer, 'SET_UPLOAD_PROGRESS')
const clearUploadProgress = createAction(reducer, 'CLEAR_UPLOAD_PROGRESS')

const deleteMediaFilesSuccess = createAction(reducer, 'DELETE_MEDIA_FILES_SUCCESS')

const setDropzoneFiles = createAction(reducer, 'SET_DROPZONE_FILES')
const clearDropzone = createAction(reducer, 'CLEAR_DROPZONE')

const {
  action: addMedia,
  actionSuccess: addMediaSuccess,
  actionFail: addMediaFail
} = createAsyncActions(reducer, 'ADD')

const {
  action: listFiles,
  actionSuccess: listFilesSuccess,
  actionFail: listFilesFail
} = createAsyncActions(reducer, 'LIST_FILES')

const {
  action: listFolders,
  actionSuccess: listFoldersSuccess,
  actionFail: listFoldersFail
} = createAsyncActions(reducer, 'LIST_FOLDERS')

const {
  action: deleteMedia,
  actionSuccess: deleteMediaSuccess,
  actionFail: deleteMediaFail
} = createAsyncActions(reducer, 'DELETE')

const {
  action: getFileUrls,
  actionSuccess: getFileUrlsSuccess,
  actionFail: getFileUrlsFail
} = createAsyncActions(reducer, 'GET_FILE_URLS')

const {
  action: moveMediaToFolder,
  actionSuccess: moveMediaToFolderSuccess,
  actionFail: moveMediaToFolderFail
} = createAsyncActions(reducer, 'MOVE_MEDIA_TO_FOLDER')

const {
  action: pushMediaToPlaylists,
  actionSuccess: pushMediaToPlaylistsSuccess,
  actionFail: pushMediaToPlaylistsFail
} = createAsyncActions(reducer, 'PUSH_TO_PLAYLISTS')

const {
  action: renameMedia,
  actionSuccess: renameMediaSuccess,
  actionFail: renameMediaFail
} = createAsyncActions(reducer, 'RENAME')

const {
  action: addEMMiMedia,
  actionFail: addEMMiMediaFail,
  actionSuccess: addEMMiMediaSuccess
} = createAsyncActions(reducer, 'ADD_EMMI_FILE')

const {
  action: createFolder,
  actionFail: createFolderFail,
  actionSuccess: createFolderSuccess
} = createAsyncActions(reducer, 'CREATE_FOLDER')

const {
  action: deleteFolder,
  actionFail: deleteFolderFail,
  actionSuccess: deleteFolderSuccess
} = createAsyncActions(reducer, 'DELETE_FOLDER')

const {
  action: updateFolder,
  actionFail: updateFolderFail,
  actionSuccess: updateFolderSuccess
} = createAsyncActions(reducer, 'UPDATE_FOLDER')

const {
  action: moveFolder,
  actionFail: moveFolderFail,
  actionSuccess: moveFolderSuccess
} = createAsyncActions(reducer, 'MOVE_FOLDER')

const {
  action: downloadMediaFiles,
  actionFail: downloadMediaFilesFail,
  actionSuccess: downloadMediaFilesSuccess
} = createAsyncActions(reducer, 'DOWNLOAD_MEDIA_FILES')

export {
  resetMedia,
  selectMedia,
  deselectMedia,
  clearSelection,
  addMedia,
  addMediaSuccess,
  addMediaFail,
  listFiles,
  listFilesSuccess,
  listFilesFail,
  listFolders,
  listFoldersSuccess,
  listFoldersFail,
  deleteMedia,
  deleteMediaSuccess,
  deleteMediaFail,
  deleteMediaFilesSuccess,
  changePage,
  changeItemsPerPage,
  getFileUrls,
  getFileUrlsSuccess,
  getFileUrlsFail,
  moveMediaToFolder,
  moveMediaToFolderSuccess,
  moveMediaToFolderFail,
  selectFolder,
  deselectFolder,
  searchMedia,
  clearSearchMedia,
  pushMediaToPlaylists,
  pushMediaToPlaylistsSuccess,
  pushMediaToPlaylistsFail,
  renameMedia,
  renameMediaSuccess,
  renameMediaFail,
  selectAllKeys,
  setUploadProgress,
  clearUploadProgress,
  addEMMiMedia,
  addEMMiMediaSuccess,
  addEMMiMediaFail,
  createFolder,
  createFolderFail,
  createFolderSuccess,
  deleteFolder,
  deleteFolderFail,
  deleteFolderSuccess,
  updateFolder,
  updateFolderSuccess,
  updateFolderFail,
  moveFolder,
  moveFolderFail,
  moveFolderSuccess,
  downloadMediaFiles,
  downloadMediaFilesSuccess,
  downloadMediaFilesFail,
  setDropzoneFiles,
  clearDropzone
}
