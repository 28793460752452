import { DefaultTheme } from '@mui/styles'

const toolbarStyles = (theme: DefaultTheme) => ({
  appBar: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14)',
    top: 56,
    [theme.breakpoints.up('sm')]: {
      top: 64
    }
  },
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    color: theme.palette.text.secondary,
    marginRight: 4,
    display: 'flex'
  },
  title: {
    flex: '0 0 auto'
  }
})

const specialDietsStyles = () => ({
  dairyFree: {
    backgroundColor: '#1f254e !important'
  },
  glutenFree: {
    backgroundColor: '#876131 !important'
  },
  lactoseFree: {
    backgroundColor: '#338FF6 !important'
  },
  lowLactose: {
    backgroundColor: '#A1D3FF !important'
  },
  // V
  vegetarian: {
    backgroundColor: '#75E448 !important'
  },
  // VE
  vegan: {
    backgroundColor: '#55ce24 !important'
  },
  eggFree: {
    backgroundColor: '#FFAB00 !important'
  }
})

export { toolbarStyles, specialDietsStyles }
