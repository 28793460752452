import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { selectUserIsAuthenticated } from '../../selectors/users'
import { selectScreensViewMode } from '../../selectors/screens'
import { BreadcrumbContentType } from '../../types/breadcrumbs'
import PageTitle from '../../components/PageTitle'
import { selectQueryParamFromSearch } from '../../selectors/routing'
import { FilterByStatus } from '../../types/screens'
import ScreensTable from './ScreensTable'
import ScreensCardView from './CardView/ScreensCardView'
import ScreensToolbar from './ScreensToolbar/ScreensToolbar'

const Screens = () => {
  const searchTerm = useSelector(selectQueryParamFromSearch('searchTerm')) || ''
  const queryParamTags = useSelector(selectQueryParamFromSearch('tags'))
  const selectedTags = queryParamTags ? queryParamTags.split(',') : []
  const selectedStatus = useSelector(selectQueryParamFromSearch('status')) as FilterByStatus | null
  const isAuthenticated = useSelector(selectUserIsAuthenticated)
  const isListMode = useSelector(selectScreensViewMode)
  const screensViewProps = {
    searchTerm,
    selectedStatus,
    selectedTags
  }
  const screensTableToolbarProps = {
    isListMode
  }

  return (
    <Fragment>
      <PageTitle contentType={BreadcrumbContentType.screens} />
      {isAuthenticated && (
        <Fragment>
          <ScreensToolbar {...screensTableToolbarProps} />

          {isListMode ? (
            <ScreensTable {...screensViewProps} />
          ) : (
            <ScreensCardView {...screensViewProps} />
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

export default Screens
