import React, { Fragment, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid, LinearProgress, MenuItem } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  selectListPlaylistsIsLoading,
  selectPlaylistsBySelectedFilter,
  selectPlaylistsByEnvrionmentIdAndAccessAsArray
} from '../../selectors/playlists'
import Dialog from '../Dialog'
import { closeDialog as closeDialogAction } from '../../actions/dialogs'
import {
  createPlaylist as createPlaylistAction,
  deletePlaylist as deletePlaylistAction,
  updatePlaylist as updatePlaylistAction,
  copyPlaylist as copyPlaylistAction,
  listPlaylists as listPlaylistsAction,
  deselectPlaylist as deselectPlaylistAction
} from '../../actions/playlists'
import { bindSubmitActionToPromise } from '../../utils/forms'
import { compareBySortType } from '../../utils/sorting'
import { selectIsUserDeviceMobile, selectUser } from '../../selectors/users'
import { createPreviewSession as createPreviewSessionAction } from '../../actions/users'
import { CreatePreviewSessionParams } from '../../types/actions'
import { selectScreensAsArray } from '../../selectors/screens'
import { PlaylistFilter } from '../../types/playlists'
import { BreadcrumbContentType } from '../../types/breadcrumbs'
import PageTitle from '../../components/PageTitle'
import { getPlaylistFilter } from '../../services/localStorage/filters'
import { SortByValue, SortDirection } from '../../types/sortings'
import Sorter from '../../components/Sorting'
import { selectEnvironmentIdFromPathname } from '../../selectors/routing'
import { navigate as navigateAction } from '../../actions/routes'
import { listScreens as listScreensAction } from '../../actions/screens'
import PreviewPlayer from '../PreviewPlayer'
import CreateNewPlaylistSign from './Signs/CreateNewPlaylistSign'
import PlaylistsToolbar from './PlaylistsToolbar'
import PlaylistCard from './PlaylistCard'
import EditPlaylistDrawer from './EditPlaylist/EditPlaylistDrawer'

const useStyles = makeStyles(() => ({
  playlists: {
    padding: '10px 0px 10px',
    marginBottom: 100 // better mobile usability
  }
}))

const Playlists = () => {
  const classes = useStyles()
  const [t] = useTranslation()
  const dispatch = useDispatch()

  const [searchTerm, search] = useState('')
  const [filterType, setFilterType] = useState<PlaylistFilter>(getPlaylistFilter())
  const [sortBy, setSortBy] = useState<SortByValue>(SortByValue.name)
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.asc)

  // selectors
  const environmentId = useSelector(selectEnvironmentIdFromPathname)
  const playlistForCurrentEnvironment = useSelector(
    selectPlaylistsByEnvrionmentIdAndAccessAsArray(environmentId)
  ) // select playlists from current environment because we do not want to show parent environment playlists

  const screens = useSelector(selectScreensAsArray)
  const isMobile = useSelector(selectIsUserDeviceMobile)
  const isLoading = useSelector(selectListPlaylistsIsLoading)
  const user = useSelector(selectUser)

  // actions
  const closeDialog = (dialogId: string) => dispatch(closeDialogAction(dialogId))
  const listPlaylists = () => dispatch(listPlaylistsAction())
  const deselectPlaylist = () => dispatch(deselectPlaylistAction())
  const deletePlaylist = (playlistId: string) => dispatch(deletePlaylistAction(playlistId))
  const listScreens = () => dispatch(listScreensAction())
  const navigate = (path: string) => dispatch(navigateAction(path))
  const copyPlaylist = (playlistId: string) => dispatch(copyPlaylistAction(playlistId))
  const createPreviewSession = (createPreviewSessionParams: CreatePreviewSessionParams) =>
    dispatch(createPreviewSessionAction(createPreviewSessionParams))
  const createPlaylist = bindSubmitActionToPromise(dispatch, createPlaylistAction)
  const updatePlaylist = bindSubmitActionToPromise(dispatch, updatePlaylistAction)

  useEffect(() => {
    listPlaylists()
    listScreens()
  }, [environmentId]) // eslint-disable-line

  if (!user) {
    return <Fragment />
  }

  const visiblePlaylists = selectPlaylistsBySelectedFilter({
    playlists: playlistForCurrentEnvironment,
    filterType,
    searchTerm,
    screens
  })

  const playlists = visiblePlaylists.sort(compareBySortType(sortBy, sortDirection))
  const toolbarProps = {
    t,
    search,
    setFilterType,
    filterType,
    createPlaylist,
    deselectPlaylist: () => dispatch(deselectPlaylist()),
    closeDialog,
    user
  }
  const sorterProps = {
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    t
  }
  return (
    <Fragment>
      <PageTitle contentType={BreadcrumbContentType.playlists} />
      <PlaylistsToolbar {...toolbarProps} />
      {isLoading && <LinearProgress />}
      {playlists.length === 0 && !isLoading ? (
        <CreateNewPlaylistSign />
      ) : (
        <Sorter {...sorterProps}>
          <MenuItem value='name'>{t('sorting.sortBy.name')}</MenuItem>
          <MenuItem value='createdAt'>{t('sorting.sortBy.createdAt')}</MenuItem>
          <MenuItem value='updatedAt'>{t('sorting.sortBy.updatedAt')}</MenuItem>
        </Sorter>
      )}
      <Grid
        container
        className={classes.playlists}
        alignItems={isMobile ? 'center' : 'flex-start'}
        justifyContent={isMobile ? 'center' : 'flex-start'}>
        {playlists.length > 0 && (
          <Fragment>
            {playlists.map(playlist => {
              const cardProps = {
                playlist,
                navigate,
                updatePlaylist,
                deletePlaylist,
                closeDialog,
                user,
                copyPlaylist,
                createPreviewSession
              }
              return <PlaylistCard key={playlist.playlistId} {...cardProps} />
            })}
          </Fragment>
        )}
      </Grid>
      <Dialog
        dialogId='previewPlaylistDialog'
        title={t('playlists.editPlaylist.previewPlaylist')}
        tooltipTitle={t('playlists.editPlaylist.previewPlaylist')}
        maxWidth='xl'
        noOpenDialogButton
        Content={<PreviewPlayer />}
      />
      <EditPlaylistDrawer />
    </Fragment>
  )
}

export default Playlists
