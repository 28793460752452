import { createSelector } from 'reselect'
import { PackageUnit } from '@seesignage/seesignage-utils'
import { isPricerProduct } from '@seesignage/seesignage-utils/lib/types/productsPricer'
import { StateInterface } from '../types/states'

const selectProductsDomain = (state: StateInterface) => state.products

const selectProducts = createSelector(selectProductsDomain, domain => domain.suggestions)

const selectProductsAsArray = createSelector(selectProducts, productsById =>
  Object.values(productsById)
)

const selectProductsAsOptions = createSelector(selectProductsAsArray, products =>
  products.map(product => {
    if (isPricerProduct(product)) {
      return {
        value: JSON.stringify(product),
        label: `${product.itemName} (${product.sics?.join(', ')})`
      }
    } else {
      const { productId, preWeighedId, name, country }: any = product
      const label = `${name} ${country || ''} ${
        productId.length <= 13 ? preWeighedId || productId : ''
      }`
      return {
        value: JSON.stringify(product),
        label
      }
    }
  })
)

const selectCustomerProducts = createSelector(
  selectProductsDomain,
  domain => domain.customerProducts
)

const selectCustomerProductsAsArray = createSelector(selectCustomerProducts, customerProducts =>
  Object.values(customerProducts)
)

const selectCustomerProductsType = createSelector(
  selectProductsDomain,
  domain => domain.customerProductType
)

const selectProductsAreLoading = createSelector(
  selectProductsDomain,
  domain => domain.productsLoading
)

const selectProductById = (productId: string) =>
  createSelector(selectProducts, products => products[productId])

const selectIsProductsSearching = createSelector(
  selectProductsDomain,
  domain => domain.productsSearching
)

/**
 * Based on selected packageUnit, return array of possible comparison units
 */
const selectComparisonUnitsFromPackageUnit = (
  packageUnits: PackageUnit[],
  packageUnit?: PackageUnit
) => {
  if (packageUnit) {
    switch (packageUnit) {
      case PackageUnit.g:
      case PackageUnit.kg:
        return ['g', 'kg']
      case PackageUnit.ml:
      case PackageUnit.cl:
      case PackageUnit.dl:
      case PackageUnit.l:
        return ['ml', 'cl', 'dl', 'l']
      default:
        return [packageUnit]
    }
  }
  return packageUnits
}

const selectIsPrefillLoading = createSelector(
  selectProductsDomain,
  products => products.prefillLoading
)

const selectAreSokProductsUpdating = createSelector(
  selectProductsDomain,
  domain => domain.sokProductsUpdating
)

export {
  selectProductsAsArray,
  selectProductsAsOptions,
  selectCustomerProducts,
  selectCustomerProductsAsArray,
  selectCustomerProductsType,
  selectProductsAreLoading,
  selectProductById,
  selectIsProductsSearching,
  selectComparisonUnitsFromPackageUnit,
  selectIsPrefillLoading,
  selectAreSokProductsUpdating
}
