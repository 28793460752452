const fishProductsMock = [
  {
    productType: 'fish',
    catchType: 'hook',
    latinName: 'Perca Fluviatilis',
    catchDate: '20.9.2019',
    origin: 'Suomi',
    catchArea: 'Merenkurkku',
    name: 'Ahvenfile',
    productionMethod: 'farmed',
    productId: '20690fdd-3c3e-438b-ab3c-7c2115fcfd16',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'fish',
    catchType: 'seine',
    latinName: 'Salmo Salar',
    catchDate: '21.9.2019',
    origin: 'Norge',
    catchArea: 'Oulunjärvi',
    name: 'Graavilohifile',
    productionMethod: 'catched',
    productId: 'bca6360b-75b8-4cbb-931c-2f718ad2c5f2',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'fish',
    catchType: 'trawl',
    latinName: 'Coregonus Lavaretus',
    catchDate: '20.9.2019',
    origin: 'Suomi',
    catchArea: 'Oulunjärvi',
    name: 'Siika',
    productionMethod: 'farmed',
    productId: '20690fdd-3c3e-438b-ab3c-7c2115fcfd16',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'fish',
    catchType: 'fyke',
    latinName: 'Sander Lucioperca',
    catchDate: '19.9',
    origin: 'Norja',
    catchArea: 'Oulunjärvi',
    name: 'Kuhafile',
    productionMethod: 'farmed',
    productId: '321dde30-c311-4757-8077-ead29c51c5a7',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'fish',
    catchType: 'net',
    latinName: 'Salmo Salar',
    catchDate: '20.9.2019',
    origin: 'Suomi',
    catchArea: 'Saimaa',
    name: 'Lohi',
    productionMethod: 'catched',
    productId: '1676b2b5-6fe0-4795-9005-ccbc27493024',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'fish',
    catchType: 'net',
    latinName: 'Salmo Salar',
    catchDate: '20.9.2019',
    origin: 'Suomi',
    catchArea: 'Itämeri',
    name: 'Merilohi',
    productionMethod: 'catched',
    productId: '1676b2b5-6fe0-4795-9005-ccbc27493028',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'fish',
    catchType: 'net',
    latinName: 'Salvelinus alpinus',
    catchDate: '20.9.2019',
    origin: 'Suomi',
    catchArea: 'Oulunjärvi',
    name: 'Nieriä',
    productionMethod: 'catched',
    productId: '1676b2b5-6fe0-4795-9005-asdadsf1',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'fish',
    catchType: 'trap',
    latinName: '-',
    catchDate: '20.9.2019',
    origin: 'Suomi',
    catchArea: 'Itämeri',
    name: 'Pallokala',
    productionMethod: 'catched',
    productId: '1676b2b5-6fe0-4795-9005-afdsfsdfs',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'fish',
    catchType: 'trap',
    latinName: 'Salmo Salar',
    catchDate: '22.9.2019',
    origin: 'Suomi',
    catchArea: 'Perämeri',
    name: 'Lohifile Suomi',
    productionMethod: 'catched',
    productId: '1676b2b5-6fe0-4795-9005-afdsfsdf7',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'fish',
    catchType: 'net',
    latinName: 'Pleuronectes',
    catchDate: '20.9.2019',
    origin: 'Hollanti',
    catchArea: '',
    name: 'Punakampela',
    productionMethod: 'catched',
    productId: '1676b2b5-6fe0-4795-9005-afdsfsdf9',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'fish',
    catchType: 'net',
    latinName: '-',
    catchDate: '25.9.2019',
    origin: 'Suomi',
    catchArea: 'Ahvenanmaa',
    name: 'Kirjolohifile',
    productionMethod: 'catched',
    productId: '1676b2b5-6fe0-4795-9005-awdsfsdf6',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'fish',
    catchType: 'trap',
    latinName: '-',
    catchDate: '20.9.2019',
    origin: 'Suomi',
    catchArea: 'Itämeri',
    name: 'Pallokala',
    productionMethod: 'catched',
    productId: '1676b2b5-6fe0-4795-9005-afdsesdfs',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'fish',
    catchType: 'trap',
    latinName: 'Salmo Salar',
    catchDate: '22.9.2019',
    origin: 'Suomi',
    catchArea: 'Perämeri',
    name: 'Lohifile Suomi',
    productionMethod: 'catched',
    productId: '1676b2b5-6fe0-4795-9005-afdsfydf7',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'fish',
    catchType: 'net',
    latinName: 'Pleuronectes',
    catchDate: '20.9.2019',
    origin: 'Hollanti',
    catchArea: '',
    name: 'Punakampela',
    productionMethod: 'catched',
    productId: '1676b2b5-6fe0-4795-9005-afdsfsdg9',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  }
]

const meatProductsMock = [
  {
    productType: 'meat',
    name: 'Rotukarjan entrecotee',
    origin: 'Suomi',
    batchNumber: '1223423',
    slaughterId: '43',
    cutId: '12AB',
    useBefore: '20.9.2019',
    productId: '20690fdd-3c3e-438b-ab3c-7c2115fcfd16',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'meat',
    name: 'Dryage entrecotee',
    origin: 'Suomi',
    batchNumber: '1223423',
    slaughterId: '43',
    cutId: '12AB',
    useBefore: '20.9.2019',
    productId: '20690fdd-3c3e-438b-ab3c-7c2115fcfd16',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'meat',
    name: 'Dryage ulkofile',
    origin: 'Suomi',
    batchNumber: '1223423',
    slaughterId: '43',
    cutId: '12AB',
    useBefore: '20.9.2019',
    productId: '20690fdd-3c3e-438b-ab3c-7c2115fcfd16',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'meat',
    name: 'Black angus entrecote',
    origin: 'Suomi',
    batchNumber: '1223423',
    slaughterId: '43',
    cutId: '12AB',
    useBefore: '20.9.2019',
    productId: '20690fdd-3c3e-438b-ab3c-7c2115fcfd16',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'meat',
    name: 'Naudan entrecotee',
    origin: 'Suomi',
    batchNumber: '1223423',
    slaughterId: '43',
    cutId: '12AB',
    useBefore: '20.9.2019',
    productId: '20690fdd-3c3e-438b-ab3c-7c2115fcfd16',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'meat',
    name: 'Naudan fileelastu',
    origin: 'Suomi',
    batchNumber: '1223423',
    slaughterId: '43',
    cutId: '12AB',
    useBefore: '20.9.2019',
    productId: '20690fdd-3c3e-438b-ab3c-7c2115fcfd16',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'meat',
    name: 'Black angus ulkofilee',
    origin: 'Suomi',
    batchNumber: '1223423',
    slaughterId: '43',
    cutId: '12AB',
    useBefore: '20.9.2019',
    productId: '20690fdd-3c3e-438b-ab3c-7c2115fcfd16',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'meat',
    name: 'Black angus chuck roll',
    origin: 'Suomi',
    batchNumber: '1223423',
    slaughterId: '43',
    cutId: '12AB',
    useBefore: '20.9.2019',
    productId: '20690fdd-3c3e-438b-ab3c-7c2115fcfd16',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'meat',
    name: 'Rotukarjan entrecotee',
    origin: 'Suomi',
    batchNumber: '1223423',
    slaughterId: '43',
    cutId: '12AB',
    useBefore: '20.9.2019',
    productId: '20690fdd-3c3e-438b-ab3c-7c2115fcfd16',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'meat',
    name: 'Rotukarjan sisäpaisti',
    origin: 'Suomi',
    batchNumber: '1223423',
    slaughterId: '43',
    cutId: '12AB',
    useBefore: '20.9.2019',
    productId: '20690fdd-3c3e-438b-ab3c-7c2115fcfd16',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'meat',
    name: 'Rotukarjan paistijauhelliha',
    origin: 'Suomi',
    batchNumber: '1223423',
    slaughterId: '43',
    cutId: '12AB',
    useBefore: '20.9.2019',
    productId: '20690fdd-3c3e-438b-ab3c-7c2115fcfd16',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'meat',
    name: 'Rotukarjan entrecotee',
    origin: 'Suomi',
    batchNumber: '1223423',
    slaughterId: '43',
    cutId: '12AB',
    useBefore: '20.9.2019',
    productId: '20690fdd-3c3e-438b-ab3c-7c2115fcfd16',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'meat',
    name: 'Rotukarjan sisäfile',
    origin: 'Suomi',
    batchNumber: '1223423',
    slaughterId: '43',
    cutId: '12AB',
    useBefore: '20.9.2019',
    productId: '20690fdd-3c3e-438b-ab3c-7c2115fcfd16',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'meat',
    name: 'Rotukarjan entrecotee',
    origin: 'Suomi',
    batchNumber: '1223423',
    slaughterId: '43',
    cutId: '12AB',
    useBefore: '20.9.2019',
    productId: '20690fdd-3c3e-438b-ab3c-7c2115fcfd16',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'meat',
    name: 'Dryage entrecotee',
    origin: 'Suomi',
    batchNumber: '1223423',
    slaughterId: '43',
    cutId: '12AB',
    useBefore: '20.9.2019',
    productId: '20690fdd-3c3e-438b-ab3c-7c2115fcfd16',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'meat',
    name: 'Dryage ulkofile',
    origin: 'Suomi',
    batchNumber: '1223423',
    slaughterId: '43',
    cutId: '12AB',
    useBefore: '20.9.2019',
    productId: '20690fdd-3c3e-438b-ab3c-7c2115fcfd16',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'meat',
    name: 'Rotukarjan entrecotee',
    origin: 'Suomi',
    batchNumber: '1223423',
    slaughterId: '43',
    cutId: '12AB',
    useBefore: '20.9.2019',
    productId: '20690fdd-3c3e-438b-ab3c-7c2115fcfd16',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'meat',
    name: 'Rotukarjan entrecotee',
    origin: 'Suomi',
    batchNumber: '1223423',
    slaughterId: '43',
    cutId: '12AB',
    useBefore: '20.9.2019',
    productId: '20690fdd-3c3e-438b-ab3c-7c2115fcfd16',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'meat',
    name: 'Dryage entrecotee',
    origin: 'Suomi',
    batchNumber: '1223423',
    slaughterId: '43',
    cutId: '12AB',
    useBefore: '20.9.2019',
    productId: '20690fdd-3c3e-438b-ab3c-7c2115fcfd16',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'meat',
    name: 'Rotukarjan entrecotee',
    origin: 'Suomi',
    batchNumber: '1223423',
    slaughterId: '43',
    cutId: '12AB',
    useBefore: '20.9.2019',
    productId: '20690fdd-3c3e-438b-ab3c-7c2115fcfd16',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'meat',
    name: 'Dryage entrecotee',
    origin: 'Suomi',
    batchNumber: '1223423',
    slaughterId: '43',
    cutId: '12AB',
    useBefore: '20.9.2019',
    productId: '20690fdd-3c3e-438b-ab3c-7c2115fcfd16',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'meat',
    name: 'Dryage ulkofile',
    origin: 'Suomi',
    batchNumber: '1223423',
    slaughterId: '43',
    cutId: '12AB',
    useBefore: '20.9.2019',
    productId: '20690fdd-3c3e-438b-ab3c-7c2115fcfd16',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'meat',
    name: 'Rotukarjan entrecotee',
    origin: 'Suomi',
    batchNumber: '1223423',
    slaughterId: '43',
    cutId: '12AB',
    useBefore: '20.9.2019',
    productId: '20690fdd-3c3e-438b-ab3c-7c2115fcfd16',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'meat',
    name: 'Rotukarjan entrecotee',
    origin: 'Suomi',
    batchNumber: '1223423',
    slaughterId: '43',
    cutId: '12AB',
    useBefore: '20.9.2019',
    productId: '20690fdd-3c3e-438b-ab3c-7c2115fcfd16',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'meat',
    name: 'Dryage entrecotee',
    origin: 'Suomi',
    batchNumber: '1223423',
    slaughterId: '43',
    cutId: '12AB',
    useBefore: '20.9.2019',
    productId: '20690fdd-3c3e-438b-ab3c-7c2115fcfd16',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'meat',
    name: 'Rotukarjan entrecotee',
    origin: 'Suomi',
    batchNumber: '1223423',
    slaughterId: '43',
    cutId: '12AB',
    useBefore: '20.9.2019',
    productId: '20690fdd-3c3e-438b-ab3c-7c2115fcfd16',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'meat',
    name: 'Dryage entrecotee',
    origin: 'Suomi',
    batchNumber: '1223423',
    slaughterId: '43',
    cutId: '12AB',
    useBefore: '20.9.2019',
    productId: '20690fdd-3c3e-438b-ab3c-7c2115fcfd16',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  },
  {
    productType: 'meat',
    name: 'Dryage ulkofile',
    origin: 'Suomi',
    batchNumber: '1223423',
    slaughterId: '43',
    cutId: '12AB',
    useBefore: '20.9.2019',
    productId: '20690fdd-3c3e-438b-ab3c-7c2115fcfd16',
    customerId: '1bb1ce3c-38c7-4569-81df-f3bb22aaeeec'
  }
]

export { fishProductsMock, meatProductsMock }
