import { IconButton, Tooltip, DialogActions, Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import isEqual from 'lodash.isequal'
import CalendarClockIcon from 'mdi-react/CalendarClockIcon'
import CloseIcon from 'mdi-react/CloseIcon'
import DeleteIcon from 'mdi-react/DeleteIcon'
import PencilIcon from 'mdi-react/PencilIcon'
import TimerIcon from 'mdi-react/TimerIcon'
import ContentCopyIcon from 'mdi-react/ContentCopyIcon'
import WeatherLightningRainyIcon from 'mdi-react/WeatherLightningRainyIcon'
import { path } from 'ramda'
import React, { Fragment, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { TFunction } from 'i18next'
import {
  Customer,
  ProductPlaylistItemUI,
  InfopagePlaylistItemUI,
  RevolverPlaylistItemUI,
  PlaylistItemUI,
  isGeneralRetailProduct,
  isProductPlaylistItem,
  isInfopagePlaylistItem,
  isRevolverPlaylistItem,
  isUrlPlaylistItem,
  isVideoPlaylistItem,
  ProductForm,
  isPharmacyProduct,
  isContentPlaylistItem
} from '@seesignage/seesignage-utils'
import PaletteAdvancedIcon from 'mdi-react/PaletteAdvancedIcon'
import TagMultipleIcon from 'mdi-react/TagMultipleIcon'
import { closeDialog as closeDialogAction } from '../../../../actions/dialogs'
import {
  addPlaylistItemCondition as addPlaylistItemConditionAction,
  deletePlaylistItem as deletePlaylistItemAction,
  deselectAllPlaylistItems as deselectAllPlaylistItemsAction,
  hidePlaylistItem as hidePlaylistItemAction,
  intervalPlaylistItem as intervalPlaylistItemAction,
  removePlaylistItemInterval as removePlaylistItemIntervalAction,
  schedulePlaylistItem as schedulePlaylistItemAction,
  updateRevolverItem as updateRevolverItemAction,
  updateUrlItem as updateUrlItemAction,
  duplicatePlaylistItem as duplicatePlaylistItemAction
} from '../../../../actions/playlists'
import {
  createOrUpdateGeneralProduct as createOrUpdateGeneralProductAction,
  updatePharmacyProduct as updatePharmacyProductAction
} from '../../../../actions/products'
import Menu from '../../../../components/Menu'
import { selectCustomerById } from '../../../../selectors/customers'
import { selectIsUserDeviceMobile } from '../../../../selectors/users'
import { bindSubmitActionToPromise } from '../../../../utils/forms'
import Dialog from '../../../Dialog'
import CreateProductWizard from '../../../Lists/Forms/CreateProductWizard/CreateProductWizard'
import AddItemWeatherConditionForm from '../../Forms/Items/AddItemWeatherConditionForm'
import AddItemIntervalForm from '../../Forms/Items/AddItemIntervalForm'
import SchedulePlaylistItemForm from '../../Forms/SchedulePlaylistItemForm'
import AddRevolverItem from '../../Forms/Revolver/AddRevolverItem'
import Spacer from '../../../../components/Spacer/Spacer'
import {
  getGeneralProductFormInitialValuesForPlaylist,
  getUpdatePharmacyProductInitialValuesForPlaylist
} from '../../../../utils/products'
import UpdateInfopageItem from '../../Forms/Items/Infopage/UpdateInfopageItem'
import { formatFormContent } from '../../../../utils/infopages'
import AddUrlItemForm from '../../Forms/Items/AddUrlItemForm'
import EditCustomerDrugForm from '../../../Products/Pharmacy/EditCustomerDrugForm'
import ContentEditor from '../../../Contents/ContentEditor'
import { saveCanvas } from '../../../../actions/contents'
import SelectItemTemplateForm from '../../Forms/Items/SelectItemTemplateForm'
import {
  getPlaylistItemScheduleInitialValues,
  getAddItemTagsConditionFormInitialValues,
  getAddItemWeatherConditionFormInitialValues
} from '../../../../utils/playlists'
import AddItemTagsForm from '../../Forms/Items/AddItemTagsForm'
import HideItem from './HideItem'

const useStyles = makeStyles(() => ({
  dialogActions: {
    position: 'absolute',
    bottom: '0px',
    background: 'white',
    zIndex: 1000,
    right: '24px',
    left: '24px',
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)'
  }
}))

/**
 * Return example item for condition form initalValues if all items have matching property
 */
const exampleItem = (items: PlaylistItemUI[], property: string) => {
  if (items.length === 1) {
    return items[0]
  }
  const properties = items.map((item: any) => item[property])
  return properties.every((val: any, i: number, arr: any) => isEqual(val, arr[0]))
    ? items[0]
    : undefined
}

const allItemsHaveInterval = (items: PlaylistItemUI[]) => {
  const nonVideoItems = items.filter(
    item => !isVideoPlaylistItem(item) && !isRevolverPlaylistItem(item)
  )
  return items.length === nonVideoItems.length
}

const allItemsAreProductItems = (items: PlaylistItemUI[]) =>
  items.every(item => isProductPlaylistItem(item))

const renderEditProductButton = (
  item: ProductPlaylistItemUI,
  customer: Customer,
  updateGeneralProduct: (formData: any) => void,
  updatePharmacyProduct: (formData: any) => void,
  t: TFunction
) => {
  const { templateId, templateEnvironmentId, itemId, product } = item
  const generalProduct = isGeneralRetailProduct(product) ? product : undefined
  switch (customer.productForm) {
    case ProductForm.general: {
      if (generalProduct) {
        return (
          <div key={`generalProduct-updateProduct-${itemId}`}>
            <Dialog
              dialogId={`UpdateProductDialog.${generalProduct.productId}`}
              title={t('lists.updateProduct')}
              tooltipTitle={t('lists.updateProduct')}
              ButtonIcon={PencilIcon}
              maxWidth='xl'
              Content={
                <CreateProductWizard
                  onSubmit={updateGeneralProduct}
                  submitButtonLabel={t('general.update')}
                  playlistItem
                  customerDisabled
                  initialValues={getGeneralProductFormInitialValuesForPlaylist(
                    generalProduct,
                    itemId,
                    {
                      templateId,
                      templateEnvironmentId: templateEnvironmentId || customer.environmentId
                    }
                  )}
                />
              }
            />
          </div>
        )
      }
      return <Fragment key='noEditProductButtonGeneralProduct' />
    }
    case ProductForm.pharmacy: {
      if (isPharmacyProduct(product)) {
        return (
          <div key={`pharmacy-updateProduct-${itemId}`}>
            <Dialog
              dialogId={`UpdateProductDialog.${product.ean}`}
              title={t('lists.updateProduct')}
              tooltipTitle={t('lists.updateProduct')}
              ButtonIcon={PencilIcon}
              Content={
                <EditCustomerDrugForm
                  product={product}
                  submitAction={updatePharmacyProduct}
                  initialValues={getUpdatePharmacyProductInitialValuesForPlaylist(product, itemId, {
                    templateId,
                    templateEnvironmentId: customer.environmentId
                  })}
                />
              }
            />
          </div>
        )
      }

      return <Fragment key='noEditProductButtonPharmacy' />
    }
    default:
      return <Fragment key='noEditProductButton' />
  }
}

const renderEditInfopageButton = (item: InfopagePlaylistItemUI, t: TFunction) => {
  const { infopage } = item
  if (infopage) {
    const { infopageId, content, contentMetadata } = infopage
    formatFormContent(content, contentMetadata)
    return (
      <div key={`updateInfopage-${infopageId}`}>
        <Dialog
          dialogId={`UpdateInfopageDialog.${infopageId}`}
          title={t('infopages.update')}
          tooltipTitle={t('infopages.update')}
          ButtonIcon={PencilIcon}
          maxWidth='xl'
          Content={
            <UpdateInfopageItem
              isUpdateItem
              submitButtonLabel={t('general.update')}
              infopage={{
                ...infopage,
                content
              }}
            />
          }
        />
      </div>
    )
  }
  return <Fragment key={item.itemId} />
}

const renderEditRevolverButton = (
  item: RevolverPlaylistItemUI,
  updateRevolverItem: (formData: any) => void,
  t: TFunction
) => {
  const { revolverItems, name, itemId } = item
  return (
    <div key={`editRevolver-${itemId}`}>
      <Dialog
        dialogId={`UpdateRevolverItem.${name}`}
        title={t('playlists.revolver.update')}
        tooltipTitle={t('playlists.revolver.update')}
        ButtonIcon={PencilIcon}
        maxWidth='lg'
        Content={
          <AddRevolverItem
            onSubmitRevolverItem={updateRevolverItem}
            initialValues={{
              itemId,
              name,
              revolverItems
            }}
            submitButtonLabel={t('general.update')}
          />
        }
      />
    </div>
  )
}

interface SelectedItemActionsProps {
  defaultInterval: number
  selectedPlaylistItems: PlaylistItemUI[]
}

const SelectedItemActions: React.FC<SelectedItemActionsProps> = ({
  selectedPlaylistItems,
  defaultInterval
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [t] = useTranslation()

  const closeDialog = (dialogId: string) => dispatch(closeDialogAction(dialogId))
  const removeItemInterval = () => dispatch(removePlaylistItemIntervalAction())
  const deletePlaylistItem = () => dispatch(deletePlaylistItemAction())
  const duplicatePlaylistItem = () => dispatch(duplicatePlaylistItemAction())
  const hidePlaylistItem = () => dispatch(hidePlaylistItemAction())
  const deselectAll = () => dispatch(deselectAllPlaylistItemsAction())
  const updateContentItem = (contentId: string) => dispatch(saveCanvas(contentId))

  const schedulePlaylistItem = bindSubmitActionToPromise(dispatch, schedulePlaylistItemAction)
  const intervalPlaylistItem = bindSubmitActionToPromise(dispatch, intervalPlaylistItemAction)
  const addItemCondition = bindSubmitActionToPromise(dispatch, addPlaylistItemConditionAction)
  const updateGeneralProduct = bindSubmitActionToPromise(
    dispatch,
    createOrUpdateGeneralProductAction
  )
  const updatePharmacyProduct = bindSubmitActionToPromise(dispatch, updatePharmacyProductAction)
  const updateRevolverItem = bindSubmitActionToPromise(dispatch, updateRevolverItemAction)
  const updateUrlItem = bindSubmitActionToPromise(dispatch, updateUrlItemAction)

  const selectedPlaylistItem =
    selectedPlaylistItems?.length === 1 ? selectedPlaylistItems[0] : undefined

  const customerId = isProductPlaylistItem(selectedPlaylistItem)
    ? selectedPlaylistItem.customerId
    : undefined

  const customer = useSelector(selectCustomerById(customerId))
  const isMobile = useSelector(selectIsUserDeviceMobile)

  const intervalItem = exampleItem(selectedPlaylistItems, 'interval')
  const conditionsItem = exampleItem(selectedPlaylistItems, 'conditions')
  const scheduleItem = exampleItem(selectedPlaylistItems, 'schedule')
  const actions: ReactNode[] = []

  if (isProductPlaylistItem(selectedPlaylistItem) && customer) {
    actions.push(
      renderEditProductButton(
        selectedPlaylistItem,
        customer,
        updateGeneralProduct,
        updatePharmacyProduct,
        t
      )
    )
  } else if (isInfopagePlaylistItem(selectedPlaylistItem)) {
    actions.push(renderEditInfopageButton(selectedPlaylistItem, t))
  } else if (isRevolverPlaylistItem(selectedPlaylistItem)) {
    actions.push(renderEditRevolverButton(selectedPlaylistItem, updateRevolverItem, t))
  } else if (isUrlPlaylistItem(selectedPlaylistItem)) {
    const { itemId, url, interval } = selectedPlaylistItem
    actions.push(
      <div key='EditUrlItemDialog'>
        <Dialog
          dialogId='EditUrlItemDialog'
          title={t('playlists.editPlaylist.editUrl')}
          tooltipTitle={t('general.edit')}
          ButtonIcon={PencilIcon}
          Content={
            <AddUrlItemForm
              onSubmit={updateUrlItem}
              initialValues={{
                type: 'url',
                itemId,
                url,
                interval
              }}
              submitButtonLabel={t('general.update')}
              defaultInterval={defaultInterval}
            />
          }
        />
      </div>
    )
  } else if (isContentPlaylistItem(selectedPlaylistItem)) {
    const { contentId } = selectedPlaylistItem
    actions.push(
      <div key='EditContentItemDialog'>
        <Dialog
          disableEnforceFocus
          maxWidth='xl'
          fullScreen
          dialogId='EditContentItemDialog'
          title={t('Edit content')}
          tooltipTitle={t('general.edit')}
          ButtonIcon={PencilIcon}
          Content={
            <div style={{ backgroundColor: '#fafafa' }}>
              <ContentEditor contentId={contentId} isUpdatePlaylistItem />
              <DialogActions className={classes.dialogActions}>
                <Button color='primary' onClick={() => closeDialog('EditContentItemDialog')}>
                  {t('general.cancel')}
                </Button>
                <Button color='primary' onClick={() => updateContentItem(contentId)}>
                  {t('general.save')}
                </Button>
              </DialogActions>
            </div>
          }
        />
      </div>
    )
  }
  if (allItemsAreProductItems(selectedPlaylistItems)) {
    actions.push(
      <div key='SelectItemTemplateDialog'>
        <Dialog
          dialogId='SelectItemTemplateDialog'
          title={t('playlists.editPlaylist.selectTemplate', {
            count: selectedPlaylistItems.length
          })}
          tooltipTitle={t('playlists.editPlaylist.selectTemplate', {
            count: selectedPlaylistItems.length
          })}
          ButtonIcon={PaletteAdvancedIcon}
          maxWidth='sm'
          Content={<SelectItemTemplateForm dialogId='SelectItemTemplateDialog' />}
        />
      </div>
    )
  }
  if (allItemsHaveInterval(selectedPlaylistItems)) {
    actions.push(
      <div key='intervalPlaylistItemDialog'>
        <Dialog
          dialogId='intervalPlaylistItemDialog'
          title={t('playlists.editPlaylist.interval')}
          tooltipTitle={t('playlists.editPlaylist.interval')}
          ButtonIcon={TimerIcon}
          maxWidth='xs'
          Content={
            <AddItemIntervalForm
              dialogId='intervalPlaylistItemDialog'
              submitButtonLabel={t('general.save')}
              submitAction={intervalPlaylistItem}
              removeItemInterval={removeItemInterval}
              closeDialog={closeDialog}
              defaultInterval={defaultInterval}
              initialValues={{
                interval: path(['interval'], intervalItem)
              }}
            />
          }
        />
      </div>
    )
  }
  const otherActions = [
    <div key='addTagsCondition'>
      <Dialog
        dialogId='addTagsCondition'
        title={t('playlists.editPlaylist.tags')}
        tooltipTitle={t('playlists.editPlaylist.tags')}
        ButtonIcon={TagMultipleIcon}
        Content={
          <AddItemTagsForm
            dialogId='addTagsCondition'
            submitButtonLabel={t('general.save')}
            closeDialog={closeDialog}
            submitAction={addItemCondition}
            initialValues={getAddItemTagsConditionFormInitialValues(selectedPlaylistItem)}
          />
        }
      />
    </div>,
    <div key='addWeatherCondition'>
      <Dialog
        dialogId='addWeatherCondition'
        title={t('playlists.editPlaylist.weather')}
        tooltipTitle={t('playlists.editPlaylist.weather')}
        ButtonIcon={WeatherLightningRainyIcon}
        Content={
          <AddItemWeatherConditionForm
            dialogId='weatherConditionForm'
            submitButtonLabel={t('general.save')}
            submitAction={addItemCondition}
            closeDialog={closeDialog}
            initialValues={getAddItemWeatherConditionFormInitialValues(conditionsItem)}
          />
        }
      />
    </div>,
    <div key='schedulePlaylistItemDialog'>
      <Dialog
        dialogId='schedulePlaylistItemDialog'
        title={t('playlists.editPlaylist.schedule')}
        tooltipTitle={t('playlists.editPlaylist.schedule')}
        ButtonIcon={CalendarClockIcon}
        Content={
          <SchedulePlaylistItemForm
            dialogId='schedulePlaylistItemDialog'
            submitButtonLabel={t('general.save')}
            submitAction={schedulePlaylistItem}
            closeDialog={closeDialog}
            isMobile={isMobile}
            initialValues={getPlaylistItemScheduleInitialValues(scheduleItem?.schedule)}
          />
        }
      />
    </div>,
    <HideItem
      key='hide'
      t={t}
      isHidden={path(['isHidden'], selectedPlaylistItems[0])}
      hidePlaylistItem={hidePlaylistItem}
    />,
    <Tooltip disableInteractive key='duplicate' title={t('playlists.editPlaylist.duplicateItem')}>
      <div>
        <IconButton
          onClick={() => duplicatePlaylistItem()}
          aria-label={t('playlists.editPlaylist.duplicateItem')}
          size='large'>
          <ContentCopyIcon />
        </IconButton>
      </div>
    </Tooltip>,
    <Tooltip disableInteractive key='delete' title={t('playlists.editPlaylist.deleteItem')}>
      <div>
        <IconButton
          onClick={() => deletePlaylistItem()}
          aria-label={t('playlists.editPlaylist.deleteItem')}
          size='large'>
          <DeleteIcon />
        </IconButton>
      </div>
    </Tooltip>
  ]

  const toolbarActions = [...actions, ...otherActions]
  return (
    <Fragment>
      {isMobile ? (
        <Menu tooltipTitle={t('playlists.actions.playlistItemOptions')} items={toolbarActions} />
      ) : (
        toolbarActions
      )}
      <Tooltip disableInteractive key='deselectAll' title={t('general.deselect')}>
        <IconButton onClick={() => deselectAll()} size='large'>
          <CloseIcon />
        </IconButton>
      </Tooltip>
      <Spacer />
    </Fragment>
  )
}

export default SelectedItemActions
