import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'core-js/features/array/flat'
import * as React from 'react'
import { render } from 'react-dom'
import './config/fonts'
import 'react-toastify/dist/ReactToastify.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import { configureAmplify } from './config/amplify'
import { configureTawk } from './config/tawk'
import { browserCheck } from './config/browserCheck'
import './translations/i18n'
import App from './App'
import './index.css'
import { initializeGoogleAnalytics } from './config/ga'
import * as serviceWorker from './serviceWorker'
import { getStore } from './configureStore'
import { notifyUpdate } from './actions/notifications'

configureAmplify()
configureTawk()
browserCheck()
initializeGoogleAnalytics()
const store = getStore()

render(<App />, document.getElementById('root'))
serviceWorker.register({
  onUpdate: registration => {
    store.dispatch(notifyUpdate())
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    }
  }
})
