import { Grid, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import { TFunction } from 'i18next'
import { selectIsUserDeviceMobile } from '../../../../selectors/users'
import SelectInfopages from '../../../../components/FormInput/SelectInfopage/SelectInfopages'

interface OwnProps {
  pushInfoPageToList: (item: any) => void
  /** contentId of the content that user is currently editing. */
  currentContentId: string
  t: TFunction
}

type AddExistingInfopageFormProps = OwnProps

const SelectExistingInfopagesForm: React.FC<AddExistingInfopageFormProps &
  InjectedFormProps<{}, AddExistingInfopageFormProps>> = ({
  pushInfoPageToList,
  t,
  error,
  currentContentId
}) => {
  const isMobile = useSelector(selectIsUserDeviceMobile)
  return (
    <Grid container>
      <Grid item xs={12}>
        <Field
          onChange={pushInfoPageToList}
          name='infopage'
          component={SelectInfopages}
          currentContentId={currentContentId}
          t={t}
          isMobile={isMobile}
          infopageSelected={true}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='body1' color='error'>
          {error || ''}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default reduxForm<{}, AddExistingInfopageFormProps>({
  form: 'SelectExistingInfopagesForm'
})(SelectExistingInfopagesForm)
