import { Grid } from '@mui/material'
import React from 'react'
import { Media } from '@seesignage/seesignage-utils'
import { useDispatch } from 'react-redux'
import { arrayPush } from 'redux-form'
import {
  PageType,
  UpdateAddRevolverItemFormData,
  CreateAddRevolverItemFormData
} from '../../../../types/playlists'
import AddExistingMediaForm from '../Media/AddExistingMediaForm'
import { createRevolverItemFromMedia } from '../../../../utils/playlists'
import AddRevolverItemForm from './AddRevolverItemForm'

interface AddRevolverItemProps {
  selectPage?: (page: PageType) => void
  onSubmitRevolverItem: (formData: any) => void
  submitButtonLabel: string
  initialValues: UpdateAddRevolverItemFormData | Omit<CreateAddRevolverItemFormData, 'name'>
}

/**
 * This component is used to create or update add revolver item in playlist.
 */
const AddRevolverItem: React.FC<AddRevolverItemProps> = ({
  selectPage,
  onSubmitRevolverItem,
  submitButtonLabel,
  initialValues
}) => {
  const dispatch = useDispatch()

  const pushMediaToList = (media: Media) => {
    dispatch(arrayPush('AddRevolverItemForm', 'revolverItems', createRevolverItemFromMedia(media)))
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={7}>
        <AddExistingMediaForm selectPage={selectPage} pushMediaToList={pushMediaToList} />
      </Grid>
      <Grid item xs={12} sm={5}>
        <AddRevolverItemForm
          onSubmit={onSubmitRevolverItem}
          selectPage={selectPage}
          initialValues={initialValues}
          submitButtonLabel={submitButtonLabel}
        />
      </Grid>
    </Grid>
  )
}

export default AddRevolverItem
