import {
  ObjectType,
  StopApi,
  BorderStyle,
  TableFieldType,
  WeatherWidgetType,
  WeatherIconsTheme,
  Language,
  RssFeedTheme,
  RssFeedLayout,
  RssFeedTransition,
  FabricWidgetObjectFit,
  MediaCarouselPropsPlayer,
  MediaCarouselPlaylistPropsPlayer,
  Media,
  FabricWidgetCustomOptions,
  IframeProps,
  DateProps,
  WeatherProps,
  CommonFabricObjectCustomOptions,
  StopProps,
  TableProps,
  QRCodeProps,
  RssFeedProps,
  FabricMediaObjectCustomOptions,
  VideoProps,
  SocialMediaProps,
  ResolutionSquare,
  ResolutionLandscape,
  FalconyAnnouncementsProps
} from '@seesignage/seesignage-utils'
import nanoid from 'nanoid'
import { fabric } from 'fabric'
import {
  FabricStopSchedule,
  FabricTable,
  FabricDate,
  FabricWeather,
  FabricMediaCarousel,
  FabricQRCode,
  FabricIframe,
  FabricRssFeed,
  FabricTextbox,
  FabricRect,
  FabricCircle,
  FabricTriangle,
  FabricImage,
  FabricVideo,
  FabricSocialMedia,
  FabricFalconyAnnouncements
} from '@seesignage/seesignage-player-utils/lib'

import {
  MediaCarouselWizardFormData,
  UpdateSocialMediaProps,
  isMediaCarouselMediaFormDataTransformed,
  isMediaCarouselPlaylistFormData
} from '../../types/contents'
import { contentColors } from '../../styles/common/colors'
import { addFabricObject } from './canvas'

const {
  widgets: {
    rssFeed: {
      semiDarkColor: rssFeedSemiDarkColor,
      lightColor: rssFeedLightColor,
      white: rssFeedWhiteColor
    }
  }
} = contentColors

/**
 * Create default fabric object
 */
const createDefaultFabricObject = ({
  type,
  props
}: {
  type: ObjectType
  props?: MediaCarouselWizardFormData | UpdateSocialMediaProps
}) => {
  switch (type) {
    case ObjectType.CustomTextbox: {
      return new FabricTextbox(
        {
          id: nanoid(),
          version: '1.0'
        } as CommonFabricObjectCustomOptions,
        {
          fontSize: 40,
          fontFamily: 'Roboto',
          fill: '#000000',
          width: 300,
          height: 100,
          lineHeight: 1.15,
          charSpacing: 0
        } as fabric.ITextboxOptions
      )
    }
    case ObjectType.CustomRect: {
      return new FabricRect(
        {
          id: nanoid(),
          version: '1.0'
        } as CommonFabricObjectCustomOptions,
        {
          width: 100,
          height: 100,
          fill: '#000000',
          strokeWidth: 0
        } as fabric.IRectOptions
      )
    }
    case ObjectType.CustomCircle: {
      return new FabricCircle(
        {
          id: nanoid(),
          version: '1.0'
        } as CommonFabricObjectCustomOptions,
        {
          radius: 100,
          fill: '#000000',
          strokeWidth: 0
        } as fabric.ICircleOptions
      )
    }
    case ObjectType.CustomTriangle: {
      return new FabricTriangle(
        {
          id: nanoid(),
          version: '1.0'
        } as CommonFabricObjectCustomOptions,
        {
          width: 100,
          height: 100,
          fill: '#000000',
          strokeWidth: 0
        } as fabric.ITriangleOptions
      )
    }
    case ObjectType.CustomIframe: {
      return new FabricIframe(
        {
          id: nanoid(),
          version: '1.0',
          widgetProps: {
            src: '',
            styles: {
              borderStyle: BorderStyle.solid,
              borderWidth: '1px',
              borderColor: '#E0E0E0'
            }
          }
        } as FabricWidgetCustomOptions<IframeProps>,
        {
          width: 500,
          height: 320
        } as fabric.IRectOptions
      )
    }
    case ObjectType.CustomDate: {
      return new FabricDate(
        {
          id: nanoid(),
          version: '1.0',
          widgetProps: {
            language: Language.fi,
            format: 'D.M.YYYY',
            styles: {
              borderStyle: 'none',
              borderWidth: 0
            }
          }
        } as FabricWidgetCustomOptions<DateProps>,
        {
          width: 350,
          height: 50
        } as fabric.IRectOptions
      )
    }
    case ObjectType.CustomWeather: {
      return new FabricWeather(
        {
          id: nanoid(),
          version: '1.0',
          widgetProps: {
            type: WeatherWidgetType.day,
            iconsTheme: WeatherIconsTheme.fmiLight,
            styles: {
              color: 'black'
            },
            location: {
              coordinates: { lat: 60.16985569999999, lng: 24.938379 },
              id: 'ChIJkQYhlscLkkYRY_fiO4S9Ts0',
              label: 'Helsinki, Suomi',
              timeZone: 'Europe/Helsinki'
            }
          }
        } as FabricWidgetCustomOptions<WeatherProps>,
        {
          width: 220,
          height: 120
        } as fabric.IRectOptions
      )
    }
    case ObjectType.CustomStopSchedule: {
      return new FabricStopSchedule(
        {
          id: nanoid(),
          version: '1.0',
          widgetProps: {
            stopApi: StopApi.hsl,
            stops: [],
            numberOfDepartures: 10,
            fontFamily: 'Roboto'
          }
        } as FabricWidgetCustomOptions<StopProps>,
        {
          width: 500,
          height: 320
        } as fabric.IRectOptions
      )
    }
    case ObjectType.CustomTable: {
      return new FabricTable(
        {
          id: nanoid(),
          version: '1.0',
          widgetProps: {
            schema: {
              fields: [
                {
                  id: 'b0813016-4496-48bb-94a3-2b5ebbd525a9',
                  styles: {},
                  subFields: [
                    {
                      id: 'a2a49b2d-3145-4fe0-a643-cbf9e84aecab',
                      name: 'Sample',
                      type: TableFieldType.string,
                      styles: {}
                    }
                  ]
                }
              ],
              styles: {}
            },
            rows: []
          }
        } as FabricWidgetCustomOptions<TableProps>,
        {
          width: 800,
          height: 500,
          scaleX: 1.2,
          scaleY: 1.2
        } as fabric.IRectOptions
      )
    }
    case ObjectType.CustomQRCode: {
      return new FabricQRCode(
        {
          id: nanoid(),
          version: '1.0',
          widgetProps: {
            value: '',
            size: 500,
            backgroundColor: 'white',
            codeColor: 'black'
          }
        } as FabricWidgetCustomOptions<QRCodeProps>,
        {
          width: 500,
          height: 500
        } as fabric.IRectOptions
      )
    }
    case ObjectType.CustomFalconyAnnouncements: {
      return new FabricFalconyAnnouncements(
        {
          id: nanoid(),
          version: '1.0',
          widgetProps: {
            url: '',
            slideShowInterval: 5,
            latestItemCount: 10,
            visibleItemCount: 5,
            cardStyles: { backgroundColor: 'rgba(245,245,245,1)' }
          }
        } as FabricWidgetCustomOptions<FalconyAnnouncementsProps>,
        {
          width: 1000,
          height: 1500
        } as fabric.IRectOptions
      )
    }
    case ObjectType.CustomRssFeed: {
      return new FabricRssFeed(
        {
          id: nanoid(),
          version: '1.0',
          widgetProps: {
            url: '',
            theme: RssFeedTheme.default,
            layout: RssFeedLayout.slides,
            visibleProperties: {
              channelTitle: true,
              channelDescription: false,
              itemTitle: true,
              itemDescription: false,
              itemPubDate: false,
              itemImage: false
            },
            interval: 7000,
            itemCount: 20,
            refreshInterval: 30,
            transition: RssFeedTransition.slideLeft,
            styles: {
              mainContainerStyles: {
                background: rssFeedWhiteColor
              },
              channelHeaderContainerStyles: {
                background: rssFeedLightColor
              },
              channelTitleStyles: {
                fontSize: 25,
                fontFamily: 'Montserrat',
                fontStyle: 'bold',
                color: rssFeedSemiDarkColor,
                textAlign: 'left',
                lineHeight: 1.15,
                letterSpacing: 0
              },
              channelDescriptionStyles: {},
              itemCardStyles: {
                flexDirection: 'row'
              },
              itemTitleStyles: {
                fontSize: 14,
                fontFamily: 'Montserrat',
                fontWeight: 'bold',
                color: rssFeedSemiDarkColor,
                margin: '0px 0px 15px 0px',
                textAlign: 'left',
                lineHeight: 1.15,
                letterSpacing: 0
              },
              itemDescriptionStyles: {
                fontFamily: 'Montserrat',
                fontSize: 10,
                fontWeight: 'normal',
                color: rssFeedSemiDarkColor,
                margin: '0px 0px 15px 0px',
                textAlign: 'left',
                lineHeight: 1.15,
                letterSpacing: 0
              },
              itemImageStyles: {
                width: '100%',
                borderRadius: 0
              },
              imageWrapperStyles: {
                width: '47%'
              },
              itemContentStyles: {},
              itemPubDateStyles: {
                fontFamily: 'Montserrat',
                color: rssFeedSemiDarkColor,
                fontSize: 9,
                textAlign: 'left',
                lineHeight: 1.15,
                letterSpacing: 0
              },
              textContainerStyles: {
                order: 0
              },
              styles: {}
            }
          }
        } as FabricWidgetCustomOptions<RssFeedProps>,
        {
          width: 500,
          height: 300
        } as fabric.IRectOptions
      )
    }
    case ObjectType.CustomMediaCarousel:
      const mediaCarouselformProps = props as MediaCarouselWizardFormData
      if (mediaCarouselformProps) {
        if (isMediaCarouselMediaFormDataTransformed(mediaCarouselformProps)) {
          const { carouselItems } = mediaCarouselformProps
          const widgetProps: MediaCarouselPropsPlayer = {
            carouselItems,
            interval: 7000,
            objectFit: FabricWidgetObjectFit.contain, // not in form currently, but added if future support needed
            videosMuted: true,
            background: undefined
          }
          return new FabricMediaCarousel(
            {
              id: nanoid(),
              version: '1.0',
              widgetProps
            } as FabricWidgetCustomOptions<MediaCarouselPropsPlayer>,
            {
              width: ResolutionLandscape.width,
              height: ResolutionLandscape.height
            } as fabric.IRectOptions
          )
        } else if (isMediaCarouselPlaylistFormData(mediaCarouselformProps)) {
          const {
            carouselItems,
            playlistId: { value: playlistId },
            environmentId,
            defaultInterval
          } = mediaCarouselformProps
          const widgetProps: MediaCarouselPlaylistPropsPlayer = {
            playlistId,
            environmentId,
            defaultInterval,
            carouselItems,
            objectFit: FabricWidgetObjectFit.contain, // not in form currently, but added if future support needed
            videosMuted: true,
            background: undefined
          }
          return new FabricMediaCarousel(
            {
              id: nanoid(),
              version: '1.0',
              widgetProps
            } as FabricWidgetCustomOptions<MediaCarouselPlaylistPropsPlayer>,
            {
              width: ResolutionLandscape.width,
              height: ResolutionLandscape.height
            } as fabric.IRectOptions
          )
        }
      }
      break
    case ObjectType.CustomSocialMedia: {
      const socialMediaProps = props as UpdateSocialMediaProps
      if (socialMediaProps) {
        // const [width, height] = getSocialMediaDimensions(socialMediaProps.orientation)
        const socialMediaObject = new FabricSocialMedia(
          {
            id: nanoid(),
            version: '1.0',
            widgetProps: {
              background: '#000',
              showCaption: true,
              showHeader: true,
              slideShowInterval: 5,
              videosMuted: true,
              showContentOnlyIfPostExists: false,
              objectFit: FabricWidgetObjectFit.contain,
              ...socialMediaProps
            }
          } as FabricWidgetCustomOptions<SocialMediaProps>,
          {
            width: ResolutionSquare.width,
            height: ResolutionSquare.height,
            scaleX: 1,
            scaleY: 1
          } as fabric.IRectOptions
        )
        return socialMediaObject
      }
      break
    }
  }
}

const addNewImageObjectToCanvas = (media: Media) => {
  const { url = '', key, name } = media
  fabric.Image.fromURL(
    url,
    image => {
      const fabricImage = new FabricImage(
        { id: nanoid(), version: '1.0', src: url, key, name } as FabricMediaObjectCustomOptions,
        {} as fabric.IImageOptions
      )
      fabricImage.set({
        ...image
      })
      addFabricObject(fabricImage)
    },
    {
      crossOrigin: 'anonymous',
      scaleX: 0.5,
      scaleY: 0.5
    }
  )
}

/**
 * Add new custom Fabric video element to canvas
 */
const addNewVideoObjectToCanvas = ({ url, dimensions, key, name }: Media) => {
  // note: dimensions are missing from older videos
  const width = dimensions?.width || window.canvas.width || 1920
  const height = dimensions?.height || window.canvas.height || 1080
  const videoObject = new FabricVideo(
    {
      id: nanoid(),
      version: '1.0',
      key,
      name,
      widgetProps: {
        src: url,
        loop: false, // loop false by default
        autoplay: true, // autoplay by default
        muted: true
      }
    } as FabricWidgetCustomOptions<VideoProps>,
    {
      width,
      height,
      scaleX: 0.5,
      scaleY: 0.5
    } as fabric.IRectOptions
  )
  setTimeout(() => {
    // temporary fix to "Error: Reducers may not dispatch actions."
    addFabricObject(videoObject)
  }, 1)
}

export { createDefaultFabricObject, addNewImageObjectToCanvas, addNewVideoObjectToCanvas }
