import { CampaignSubUI, CampaignUI, CampaignBatchProducts } from '@seesignage/seesignage-utils'
import {
  isCampaignBatchProductsUI,
  CampaignBatchPriceType,
  BatchPriceFieldsProps
} from '../types/campaigns'
import { convertTagsForFormField } from './conversion'

const getBatchPriceTypeFromCampaign = (item: CampaignBatchProducts) => {
  const { useBatchPrice, usePackagePrice, useBatchPercentage } = item
  if (useBatchPercentage) {
    return CampaignBatchPriceType.batchPercentage
  } else if (useBatchPrice) {
    return CampaignBatchPriceType.batchPrice
  } else if (usePackagePrice) {
    return CampaignBatchPriceType.packagePrice
  }
  return CampaignBatchPriceType.none
}

/**
 * Parse batch price properties from EditCampaignForm CampaignBatchProducts item.
 * @returns `boolean` values for `useBatchPrice` and `usePackagePrice`
 * @param batchPriceType
 */
const getBatchPricePropertiesFromFormValue = (batchPriceType: CampaignBatchPriceType) => {
  switch (batchPriceType) {
    case CampaignBatchPriceType.none: {
      return {
        useBatchPrice: false,
        usePackagePrice: false,
        useBatchPercentage: false
      }
    }
    case CampaignBatchPriceType.batchPrice: {
      return {
        useBatchPrice: true,
        usePackagePrice: false,
        useBatchPercentage: false
      }
    }
    case CampaignBatchPriceType.packagePrice: {
      return {
        useBatchPrice: false,
        usePackagePrice: true,
        useBatchPercentage: false
      }
    }
    case CampaignBatchPriceType.batchPercentage: {
      return {
        useBatchPercentage: true,
        useBatchPrice: false,
        usePackagePrice: false
      }
    }
  }
}

/**
 * Get formatted initialValues for EditCampaignForm
 * @param campaign
 * @param isUpdate true when function called from updateItems saga, items are already in correct format and
 * do not need to be parsed
 *
 */
const getCampaignInitialValues = (campaign: CampaignSubUI | CampaignUI, isUpdate?: boolean) => {
  const { name, startDate, endDate, tags, templateEnvironmentId, templateId, items } = campaign
  return {
    name,
    startDate,
    endDate,
    tags: convertTagsForFormField(tags),
    template: {
      templateId,
      templateEnvironmentId
    },
    items: isUpdate
      ? items
      : items.map(item => {
          if (isCampaignBatchProductsUI(item)) {
        const { useBatchPrice, usePackagePrice, ...rest } = item //eslint-disable-line
            return {
              ...rest,
              batchPriceType: getBatchPriceTypeFromCampaign(item)
            }
          }
          return item
        })
  }
}

/**
 * Get price fields that are hidden in `CampaignItemPriceFields` component
 * @param batchPriceType
 * @param itemType
 */
const getHiddenBatchPriceFields = (
  batchPriceType?: CampaignBatchPriceType,
  itemType?: 'batch' | 'batchProduct'
): BatchPriceFieldsProps | undefined => {
  if (!batchPriceType || !itemType) {
    return undefined
  }
  switch (batchPriceType) {
    case CampaignBatchPriceType.none:
      if (itemType === 'batch') {
        return {
          price: {
            hidden: true,
            disabled: true
          },
          discountPrice: {
            hidden: true,
            disabled: true
          },
          discountPercentage: {
            hidden: true,
            disabled: true
          }
        }
      }
      break
    case CampaignBatchPriceType.batchPrice:
    case CampaignBatchPriceType.packagePrice:
      if (itemType === 'batchProduct') {
        return {
          price: {
            hidden: true
          },
          discountPrice: {
            hidden: true
          },
          discountPercentage: {
            hidden: true
          }
        }
      }
      break
    case CampaignBatchPriceType.batchPercentage:
      if (itemType === 'batch') {
        return {
          price: {
            hidden: true
          },
          discountPrice: {
            hidden: true
          }
        }
      }
      return {
        discountPercentage: {
          hidden: true
        },
        discountPrice: {
          disabled: true
        }
      }
  }
}
export { getCampaignInitialValues, getBatchPricePropertiesFromFormValue, getHiddenBatchPriceFields }
