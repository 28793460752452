import { createSelector } from 'reselect'
import { Screen, AutocompleteOption, PlaylistUI } from '@seesignage/seesignage-utils'
import { StateInterface } from '../types/states'
import { PlaylistFilterTypes, PlaylistFilter } from '../types/playlists'

const selectPlaylistsDomain = (state: StateInterface) => state.playlists

const selectPlaylists = createSelector(selectPlaylistsDomain, domain => domain.playlists)
const selectParentPlaylists = createSelector(
  selectPlaylistsDomain,
  domain => domain.parentPlaylists
)

const selectAllPlaylists = createSelector(
  selectPlaylists,
  selectParentPlaylists,
  (playlists, parentPlaylists) => ({ ...playlists, ...parentPlaylists })
)

const selectPlaylistIsLoading = createSelector(
  selectPlaylistsDomain,
  domain => domain.playlistIsLoading
)

const selectListPlaylistsIsLoading = createSelector(
  selectPlaylistsDomain,
  domain => domain.listPlaylistsIsLoading
)

const selectPlaylistById = (playlistId?: string | null) =>
  createSelector(selectAllPlaylists, playlists => (playlistId ? playlists[playlistId] : undefined))

const selectPlaylistsAsArray = createSelector(selectPlaylists, playlistsById =>
  Object.values(playlistsById)
)

const selectAllPlaylistsAsArray = createSelector(selectAllPlaylists, playlists =>
  Object.values(playlists)
)

const selectSelectedPlaylistItemIds = createSelector(
  selectPlaylistsDomain,
  domain => domain.selectedPlaylistItemIds
)

const selectAddPlaylistItemSource = createSelector(
  selectPlaylistsDomain,
  domain => domain.viewMode.isExistingItem
)

const selectPlaylistsBySearchTerm = (searchTerm?: string) =>
  createSelector(selectPlaylistsAsArray, playlists =>
    playlists && searchTerm
      ? playlists.filter(playlist => playlist.name.toLowerCase().includes(searchTerm.toLowerCase()))
      : playlists
  )

const selectSelectedPlaylist = createSelector(
  selectPlaylistsDomain,
  domain => domain.selectedPlaylist
)

const selectIsPlaylistModified = createSelector(selectPlaylistsDomain, domain => domain.isModified)

const selectIsPlaylistSubmitting = createSelector(
  selectPlaylistsDomain,
  domain => domain.isPlaylistItemsSubmitting
)

const selectPlaylistsAsOptions = (
  playlists: PlaylistUI[],
  currentEnvironmentId?: string
): AutocompleteOption[] =>
  playlists.map(({ playlistId, name, environmentId }) => {
    if (currentEnvironmentId && currentEnvironmentId !== environmentId) {
      return { value: playlistId, label: name, isParent: true }
    }
    return { value: playlistId, label: name }
  })

const selectIsPlaylistMediaUploading = createSelector(
  selectPlaylistsDomain,
  domain => domain.isMediaUploading
)

interface SelectPlaylistsLinkedToScreensParams {
  playlists?: PlaylistUI[]
  screens?: Screen[]
}

/**
 * Returns playlists that are found as 'playlistId' prop in screens.
 * Returns empty array if both screens and playlists do not exist.
 */
const selectPlaylistsLinkedToScreens = ({
  playlists,
  screens
}: SelectPlaylistsLinkedToScreensParams) => {
  if (playlists && screens) {
    const screenPlaylistIds = screens.reduce<Record<string, boolean>>((playlistIds, screen) => {
      if (screen.playlistId) {
        playlistIds[screen.playlistId] = true
      }
      return playlistIds
    }, {})

    return playlists.filter(({ playlistId }) => screenPlaylistIds[playlistId])
  }
  return []
}
interface SelectPlaylistsBySelectedFilter {
  playlists: PlaylistUI[]
  filterType: PlaylistFilter
  searchTerm: string
  screens?: Screen[]
}

/**
 * Filter playlists based on selected filter
 */
const selectPlaylistsBySelectedFilter = ({
  playlists,
  filterType,
  searchTerm,
  screens
}: SelectPlaylistsBySelectedFilter) => {
  const searchMatches =
    searchTerm.length > 0
      ? playlists.filter(({ name }) => name.toLowerCase().includes(searchTerm.toLowerCase()))
      : playlists
  switch (filterType) {
    case PlaylistFilterTypes.all:
      return searchMatches
    case PlaylistFilterTypes.linkedToScreens:
      return screens
        ? searchTerm.length > 0
          ? selectPlaylistsLinkedToScreens({ playlists: searchMatches, screens })
          : selectPlaylistsLinkedToScreens({ playlists, screens })
        : []
    default:
      return playlists
  }
}

const selectPlaylistsByAccessAsArray = createSelector(selectAllPlaylistsAsArray, playlists =>
  playlists.filter(playlist => playlist.userHasAccess)
)

const selectPlaylistsByEnvrionmentIdAndAccessAsArray = (environmentId?: string) =>
  createSelector(selectAllPlaylistsAsArray, playlists =>
    playlists.filter(playlist => playlist.userHasAccess && environmentId === playlist.environmentId)
  )

const selectPlaylistIsDeleting = createSelector(
  selectPlaylistsDomain,
  domain => domain.playlistIsDeleting
)

const selectInfopageCreatedToPlaylist = createSelector(
  selectPlaylistsDomain,
  domain => domain.createdInfopage
)

const selectPlaylistItemWizard = createSelector(
  selectPlaylistsDomain,
  domain => domain.playlistItemWizard
)

const selectPlaylistItemWizardPage = createSelector(
  selectPlaylistItemWizard,
  playlistItemWizard => playlistItemWizard.page
)
export {
  selectPlaylistsDomain,
  selectPlaylistIsLoading,
  selectListPlaylistsIsLoading,
  selectPlaylists,
  selectPlaylistsAsArray,
  selectAllPlaylistsAsArray,
  selectPlaylistById,
  selectSelectedPlaylistItemIds,
  selectAddPlaylistItemSource,
  selectPlaylistsBySearchTerm,
  selectSelectedPlaylist,
  selectIsPlaylistModified,
  selectIsPlaylistSubmitting,
  selectPlaylistsAsOptions,
  selectPlaylistsLinkedToScreens,
  selectPlaylistsBySelectedFilter,
  selectIsPlaylistMediaUploading,
  selectPlaylistsByAccessAsArray,
  selectPlaylistsByEnvrionmentIdAndAccessAsArray,
  selectPlaylistIsDeleting,
  selectInfopageCreatedToPlaylist,
  selectPlaylistItemWizardPage
}
