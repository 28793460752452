import React from 'react'
import {
  EnvironmentFeatures,
  EnvironmentScreenFeatures,
  TemplateType
} from '@seesignage/seesignage-utils'
import { List } from '@mui/material'
import { UserInterface } from '../../types/users'
import { validateRenderItem } from '../../utils/navigation'
import NavigationMenuItem from './NavigationMenuItem'

interface MenuItemsProps {
  menuItems: any[]
  currentPath: string
  hasEditableProducts?: boolean
  user: UserInterface
  environmentTemplateTypes: TemplateType[]
  environmentFeatures?: EnvironmentFeatures | null
  environmentScreenFeatures?: EnvironmentScreenFeatures | undefined
  isMenuOpen: boolean
}

const NavigationMenuItems = ({
  menuItems,
  currentPath,
  hasEditableProducts,
  environmentTemplateTypes,
  user,
  environmentFeatures,
  environmentScreenFeatures,
  isMenuOpen
}: MenuItemsProps) => {
  const items = menuItems.filter(item => {
    const feature = item.path.split('/')[1]
    return validateRenderItem({
      feature,
      user,
      hasEditableProducts,
      templateTypes: environmentTemplateTypes,
      environmentFeatures,
      environmentScreenFeatures
    })
  })
  return (
    <List sx={{ paddingTop: '2px', paddingBottom: '2px' }}>
      {items.map(({ path, label, icon }) => (
        <NavigationMenuItem
          key={path}
          path={path}
          labelKey={label}
          Icon={icon}
          currentPath={currentPath}
          isMenuOpen={isMenuOpen}
        />
      ))}
    </List>
  )
}

export default NavigationMenuItems
