import React from 'react'
import styled, { keyframes } from 'styled-components'
import Typography from '@mui/material/Typography'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import ArrowIcon from 'mdi-react/ArrowUpIcon'
import { WithTranslation, withTranslation } from 'react-i18next'

const styles: any = () => ({
  root: {
    float: 'right',
    width: '200px'
  }
})

const fade = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const FadeIn = styled.div`
  -webkit-animation-duration: 2s;
  -webkit-animation-name: fadeIn;
  animation-duration: 2s;
  animation-name: fadeIn;
  animation: ${fade} 4s;
`

type CreateNewPlaylistSignProps = WithStyles<typeof styles> & WithTranslation

const CreateNewPlaylistSign: React.FC<CreateNewPlaylistSignProps> = ({ classes, t }) => (
  <div className={classes.root}>
    <FadeIn>
      <Typography variant='subtitle1' gutterBottom>
        {t('playlists.actions.createNew')} <ArrowIcon />
      </Typography>
    </FadeIn>
  </div>
)

export default withStyles(styles)(withTranslation()(CreateNewPlaylistSign))
