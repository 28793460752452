import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Table, TableBody, TablePagination, LinearProgress } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Dispatch } from 'redux'
import { ScreenResponse, PlaylistUI, Channel, ListUI } from '@seesignage/seesignage-utils'
import {
  selectListScreensIsLoading,
  selectScreensBySearch,
  selectSelectedScreen
} from '../../../selectors/screens'
import { deselectScreen, selectScreenRow } from '../../../actions/screens'
import { isUserAdmin, isUserSystemAdmin } from '../../../selectors/users'
import { selectPlaylistById } from '../../../selectors/playlists'
import { selectListById } from '../../../selectors/lists'
import { EnhancedTableHead } from '../../../components/Table'
import { selectChannelById } from '../../../selectors/channels'
import { StateInterface } from '../../../types/states'
import { countEmptyRows } from '../../../utils/tables'
import EmptyRows from '../../../components/Table/EmptyRows'
import { FilterByStatus } from '../../../types/screens'
import { sortScreensByTableColumnsOrder } from '../../../utils/screens'
import { SortDirection } from '../../../types/sortings'
import { sortTable } from '../../../utils/sorting'
import ScreensTableRow from './ScreensTableRow'

interface ColumnData {
  id: string
  disablePadding: boolean
  label: string
  sortable?: boolean
}

const columnData: ColumnData[] = [
  { id: 'name', disablePadding: false, label: 'screens.screen', sortable: true },
  { id: 'code', disablePadding: false, label: 'screens.code' },
  { id: 'playlist', disablePadding: false, label: 'screens.content.content' },
  {
    id: 'productNumber',

    disablePadding: false,
    label: 'screens.productNo',
    sortable: true
  },
  {
    id: 'identifier',

    disablePadding: false,
    label: 'screens.identifier',
    sortable: true
  },
  { id: 'lastContact', disablePadding: false, label: 'screens.lastContact', sortable: true },
  { id: 'warnings', disablePadding: false, label: 'screens.warnings.title' },
  { id: 'playerVersion', disablePadding: false, label: 'screens.playerVersion', sortable: true },
  {
    id: 'build.firmwareVersion',
    disablePadding: false,
    label: 'screens.build.firmwareVersion',
    sortable: true
  },
  {
    id: 'availableCapacity',

    disablePadding: false,
    label: 'screens.availableCap',
    sortable: true
  },
  { id: 'orientation', disablePadding: false, label: 'screens.settings' },
  { id: 'connection', disablePadding: false, label: 'screens.connection' },
  { id: 'isRegistered', disablePadding: false, label: 'screens.registered' }
]

const getColumnsByAccess = (columnData: ColumnData[], isSystemAdminAccess: boolean) => {
  if (isSystemAdminAccess) {
    columnData[columnData.length - 1] = {
      id: 'isBillable',
      disablePadding: false,
      label: 'screens.billable'
    }
  }
  return columnData
}

const colSpan = columnData.length

const styles = () => ({
  root: {
    width: '100%',
    marginBottom: 70
  },
  table: {
    minWidth: 350
  },
  tableWrapper: {
    overflowX: 'auto' as any
  }
})

interface OwnProps {
  searchTerm: string
  selectedTags: string[]
  selectedStatus: FilterByStatus | null
}

interface StateProps {
  isAdminAccess: boolean
  selectedScreen?: ScreenResponse
  screens: ScreenResponse[]
  isLoading: boolean
  selectPlaylistById: (playlistId?: string | null) => PlaylistUI | undefined
  selectListById: (listId?: string | null) => ListUI | undefined
  selectChannelById: (channelId?: string | null) => Channel | undefined
  isSystemAdminAccess: boolean
}

interface DispatchProps {
  selectScreen: (screenId: string) => void
  deselectScreen: (screenId: string) => void
}

type ScreensTableProps = OwnProps &
  StateProps &
  DispatchProps &
  WithStyles<typeof styles> &
  WithTranslation

const ScreensTable: React.FC<ScreensTableProps> = ({
  classes,
  isLoading,
  screens,
  t,
  selectedScreen,
  deselectScreen,
  selectScreen,
  selectPlaylistById,
  selectListById,
  selectChannelById,
  isSystemAdminAccess
}) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [order, setOrder] = useState<SortDirection>(SortDirection.asc)
  const [orderBy, setOrderBy] = useState('name')

  const handleSortRequest = (property: string) => {
    sortTable(property, order, orderBy, setOrder, setOrderBy)
  }

  const handleRowClick = (event: any, screenId: string) => {
    if (screenId === selectedScreen?.screenId) {
      deselectScreen(screenId)
    } else {
      selectScreen(screenId)
    }
  }

  const tableHeadProps = {
    columnData: getColumnsByAccess(columnData, isSystemAdminAccess),
    order,
    orderBy,
    handleSortRequest
  }

  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table} size='small'>
          <EnhancedTableHead {...tableHeadProps} />
          <TableBody>
            {sortScreensByTableColumnsOrder(screens, orderBy, order)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(screen => (
                <ScreensTableRow
                  key={screen.screenId}
                  screen={screen}
                  isSelected={screen.screenId === selectedScreen?.screenId}
                  t={t}
                  handleRowClick={handleRowClick}
                  colSpan={colSpan}
                  isSystemAdminAccess={isSystemAdminAccess}
                  selectPlaylistById={selectPlaylistById}
                  selectListById={selectListById}
                  selectChannelById={selectChannelById}
                />
              ))}
            <EmptyRows
              emptyRows={countEmptyRows(rowsPerPage, screens.length, page)}
              colSpan={colSpan}
            />
          </TableBody>
        </Table>
      </div>
      {isLoading && <LinearProgress />}
      <TablePagination
        component='div'
        count={screens.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page'
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page'
        }}
        onPageChange={(event, page) => setPage(page)}
        onRowsPerPageChange={event => setRowsPerPage(parseInt(event.target.value, 10))}
      />
    </div>
  )
}

const mapStateToProps = (state: StateInterface, ownProps: OwnProps): StateProps => ({
  isAdminAccess: isUserAdmin(state),
  isSystemAdminAccess: isUserSystemAdmin(state),
  selectedScreen: selectSelectedScreen(state),
  screens: selectScreensBySearch(
    ownProps.searchTerm,
    ownProps.selectedTags,
    ownProps.selectedStatus
  )(state),
  isLoading: selectListScreensIsLoading(state),
  selectPlaylistById: (playlistId?: string | null) => selectPlaylistById(playlistId)(state),
  selectListById: (listId?: string | null) => selectListById(listId)(state),
  selectChannelById: (channelId?: string | null) => selectChannelById(channelId)(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  selectScreen: screenId => dispatch(selectScreenRow(screenId)),
  deselectScreen: screenId => dispatch(deselectScreen(screenId))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(ScreensTable)))
