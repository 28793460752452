/**
 * Common values to sort by
 */
export enum SortByValue {
  name = 'name',
  size = 'size',
  startDate = 'startDate',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  lastOnline = 'lastOnline'
}

export enum SortDirection {
  asc = 'asc',
  desc = 'desc'
}
