import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Theme,
  Grid
} from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import { PreviewFrame } from '@seesignage/seesignage-player-utils'
import React, { useState, Fragment } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import {
  TemplateType,
  Template,
  StylesChild,
  PlayerProduct,
  AdditionalPlayerItemProps,
  TemplateOrientation
} from '@seesignage/seesignage-utils'
import { Dispatch } from 'redux'
import { ListProps } from '@seesignage/seesignage-player-utils/lib/Components/Templates/List/List'
import { selectTemplateFormValues } from '../../../../selectors/forms'
import { selectIsUserDeviceMobile } from '../../../../selectors/users'
import { StateInterface } from '../../../../types/states'
import {
  selectMockProductType,
  selectMockItemsByProductType,
  selectMockAdditionalProps
} from '../../../../selectors/mocks'
import { setMockProductType } from '../../../../actions/mocks'
import { generateComponentStyles } from '../../../../utils/preview'
import ColorPicker from '../../../../components/FormInput/ColorPicker'
import { MockProductType } from '../../../../types/mocks'

const styles = (theme: Theme) => ({
  container: {
    padding: theme.spacing(),
    height: '100%'
  },
  screenContainer: {
    transform: 'scale(0.4)',
    transformOrigin: 'top left'
  },
  screenContainerMobile: {
    transform: 'scale(0.2)',
    transformOrigin: 'top left'
  },
  formControl: {
    margin: theme.spacing(),
    minWidth: 120
  },
  toolbarGrid: {
    minHeight: 60
  }
})

const getResolution = (orientation: TemplateOrientation) =>
  orientation === TemplateOrientation.landscape
    ? {
        width: 1920,
        height: 1080
      }
    : {
        width: 1080,
        height: 1920
      }

const filterMockItems = (mockItems: PlayerProduct[], mockProductsCount: number) =>
  mockItems.slice(0, mockProductsCount)

interface OwnProps {
  orientation: TemplateOrientation
  // selectedProductType: TemplateEditorProductType
  template: Template
  formName: string
  /** Child template index + 1 is product count for template */
  selectedChildIndex: number
  /** User can select product count (only with legacy HeVi templates) */
  showProductCount: boolean
}

interface StateProps {
  styles?: StylesChild
  isMobile: boolean
  mockItems: PlayerProduct[]
  mockAdditionalProps?: AdditionalPlayerItemProps
  mockProductType: MockProductType
}

interface DispatchProps {
  setMockProductType: (type: MockProductType) => void
}

type TemplatePreviewProps = OwnProps &
  StateProps &
  DispatchProps &
  WithStyles<typeof styles> &
  WithTranslation

const TemplatePreview: React.FC<TemplatePreviewProps> = ({
  orientation,
  template: { schema, keys, chain, language, type },
  styles,
  isMobile,
  classes,
  mockItems,
  mockAdditionalProps,
  setMockProductType,
  mockProductType,
  selectedChildIndex,
  showProductCount,
  t
}) => {
  const [mockProductsCount, setMockProductsCount] = useState(1)
  const [isFullSize, toggleFullSize] = useState(false)
  const [highlightElements, toggleHighlightElements] = useState(false)
  const [highlightElementsColor, setHighlightElementsColor] = useState()
  const filteredMockItems = filterMockItems(
    mockItems,
    !showProductCount ? selectedChildIndex + 1 : mockProductsCount
  )
  const listProps: ListProps = {
    orientation: orientation ? orientation : TemplateOrientation.landscape,
    items: filteredMockItems,
    additionalProps: mockAdditionalProps,
    template: {
      createdAt: 123,
      language,
      schema,
      environmentId: 'fe0e5877-87be-4ffe-9120-f172a3164431',
      keys,
      name: 'Mock template',
      templateId: '986a2660-7643-4e16-a315-2a4af97808ca',
      type: TemplateType.retail,
      components: generateComponentStyles(orientation, styles),
      chain
    },
    listPrice: 1090,
    editorConfigurations: {
      highlightElements,
      highlightElementsColor
    }
  }
  const numbers = [...Array(mockItems.length + 1).keys()]
  const { width, height } = getResolution(orientation)
  const showMockDataOptions =
    type === TemplateType.retail ||
    type === TemplateType.lunch ||
    type === TemplateType.fish ||
    type === TemplateType.meat

  return (
    <div className={isFullSize ? '' : classes.container}>
      <Grid container spacing={2} className={classes.toolbarGrid}>
        {showMockDataOptions && (
          <Fragment>
            <FormControl variant='standard' className={classes.formControl}>
              <InputLabel>{t('templates.templateMockDataForm.currentMockProductType')}</InputLabel>
              <Select
                variant='standard'
                value={mockProductType}
                onChange={(event: any) => {
                  setMockProductType(event.target.value)
                }}>
                {Object.values(MockProductType).map(type => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {showProductCount && (
              <FormControl variant='standard' className={classes.formControl}>
                <InputLabel>{t('templates.preview.mockProductsCount')}</InputLabel>
                <Select
                  variant='standard'
                  value={mockProductsCount}
                  onChange={(event: any) => {
                    setMockProductsCount(event.target.value)
                  }}>
                  {numbers.map((i: number) => (
                    <MenuItem key={`products-count-${i}`} value={i}>
                      {i}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Fragment>
        )}
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                checked={isFullSize}
                onChange={() => {
                  toggleFullSize(!isFullSize)
                }}
                color='primary'
              />
            }
            label={t('templates.preview.fullSize')}
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                checked={highlightElements}
                onChange={() => {
                  toggleHighlightElements(!highlightElements)
                }}
                color='primary'
              />
            }
            label={t('templates.preview.highlightElements')}
          />
        </Grid>
        {highlightElements && (
          <Grid item>
            <ColorPicker
              input={
                {
                  value: highlightElementsColor,
                  onChange: setHighlightElementsColor as any
                } as any
              }
            />
          </Grid>
        )}
      </Grid>
      <div
        className={
          isMobile ? classes.screenContainerMobile : isFullSize ? '' : classes.screenContainer
        }>
        <div>
          <PreviewFrame id='templatePreview' content={listProps} width={width} height={height} />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: StateInterface, ownProps: OwnProps): StateProps => {
  const mockProductType = selectMockProductType(state)
  return {
    styles: selectTemplateFormValues(ownProps.formName)(state),
    mockItems: selectMockItemsByProductType(mockProductType)(state),
    mockAdditionalProps: selectMockAdditionalProps(state),
    isMobile: selectIsUserDeviceMobile(state),
    mockProductType
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  setMockProductType: (type: MockProductType) => dispatch(setMockProductType(type))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(TemplatePreview)))
