import { Dispatch } from 'redux'
import { checkProductExists, checkMasterProductExists } from '../actions/products'
import { checkUrlIsValid } from '../actions/playlists'
import { checkEmailIsUnique } from '../actions/environments'
import { CreatePharmacyMasterProductFormData } from '../types/products'

const asyncValidateCreateRetailProductForm = (values: any, dispatch: Dispatch, props: any) => {
  const { disableProductIdField } = props
  return disableProductIdField
    ? new Promise(resolve => resolve(undefined))
    : new Promise((resolve, reject) =>
        dispatch(checkProductExists({ productId: values.productId, resolve, reject }))
      )
}

const asyncValidateUrl = (values: any, dispatch: Dispatch) =>
  new Promise((resolve, reject) => dispatch(checkUrlIsValid({ url: values.url, resolve, reject })))

const asyncValidateEmail = (values: any, dispatch: Dispatch) =>
  new Promise((resolve, reject) =>
    dispatch(checkEmailIsUnique({ users: values.users, resolve, reject }))
  )

const asyncValidateCreateMasterProduct = (
  values: CreatePharmacyMasterProductFormData,
  dispatch: Dispatch,
  _props: any,
  field: string
) => {
  if (field === 'product.ean') {
    return new Promise((resolve, reject) =>
      dispatch(
        checkMasterProductExists({
          productId: values.product.ean,
          resolve,
          reject
        })
      )
    )
  } else if (field === 'product.vnr') {
    return new Promise((resolve, reject) =>
      dispatch(
        checkMasterProductExists({
          productId: values.product.vnr,
          resolve,
          reject
        })
      )
    )
  }
  return new Promise(resolve => resolve(undefined))
}

export {
  asyncValidateCreateRetailProductForm,
  asyncValidateUrl,
  asyncValidateEmail,
  asyncValidateCreateMasterProduct
}
