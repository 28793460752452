import React, { Fragment } from 'react'
import { ScreenStatus, Status } from '@seesignage/seesignage-utils'
import dateFormat from 'dateformat'
import classNames from 'classnames'
import { Typography } from '@mui/material'

import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'

const styles = () => ({
  status: {
    borderRadius: '8px',
    textAlign: 'center' as 'center',
    padding: '2px 4px'
  },
  onlineStatus: {
    backgroundColor: '#1F88E5',
    color: '#ffffff'
  },
  offlineStatus: {
    backgroundColor: '#f50057',
    color: '#ffffff'
  },
  notLinkedStatus: {
    backgroundColor: '#ffab00',
    color: '#000000'
  },
  standbyStatus: {
    backgroundColor: '#265093',
    color: '#ffffff'
  }
})

interface LastContactCellProps extends WithStyles<typeof styles> {
  screenStatus: ScreenStatus
  lastStatus?: Status
}

const LastContactCell: React.FC<LastContactCellProps> = ({ classes, screenStatus, lastStatus }) => {
  if (lastStatus?.updatedAt) {
    return (
      <Typography
        variant='body2'
        gutterBottom
        className={classNames({
          [classes.status]: true,
          [classes.onlineStatus]: screenStatus === ScreenStatus.online,
          [classes.offlineStatus]: screenStatus === ScreenStatus.offline,
          [classes.notLinkedStatus]: screenStatus === ScreenStatus.notLinked,
          [classes.standbyStatus]: screenStatus === ScreenStatus.standby
        })}>
        {dateFormat(new Date(lastStatus.updatedAt), 'HH:MM dd.mm.')}
      </Typography>
    )
  }
  return <Fragment>-</Fragment>
}

export default withStyles(styles)(LastContactCell)
