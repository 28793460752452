import React, { Fragment } from 'react'

import { Switch, FormControlLabel, FormHelperText } from '@mui/material'
import { WrappedFieldProps } from 'redux-form'

interface ReduxSwitchProps extends WrappedFieldProps {
  label?: string
  disabled?: boolean
  helperText?: boolean
}

const ReduxSwitch = ({ input, label, disabled, helperText }: ReduxSwitchProps) => (
  <Fragment>
    {label ? (
      <FormControlLabel
        control={
          <Switch
            checked={input.value ? input.value : false}
            onChange={input.onChange}
            color='primary'
            disabled={disabled}
          />
        }
        label={label}
      />
    ) : (
      <Switch
        checked={input.value ? input.value : false}
        onChange={input.onChange}
        color='primary'
        disabled={disabled}
      />
    )}
    {helperText && <FormHelperText>{helperText}</FormHelperText>}
  </Fragment>
)
export default ReduxSwitch
