import React from 'react'
import PencilIcon from 'mdi-react/PencilIcon'
import { useDispatch } from 'react-redux'
import { RetailProduct } from '@seesignage/seesignage-utils'
import { useTranslation } from 'react-i18next'
import CreateRetailProductForm from '../../Forms/CreateRetailProductForm'
import { bindSubmitActionToPromise } from '../../../../utils/forms'
import { createOrUpdateRetailProduct } from '../../../../actions/products'
import Dialog from '../../../Dialog'
import { getRetailProductFormInitialValues } from '../../../../utils/products'
import { closeDialog } from '../../../../actions/dialogs'

interface EditListItemProps {
  listId: string
  product: RetailProduct
}

const EditListItem = ({ listId, product }: EditListItemProps) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const { productId } = product
  return (
    <Dialog
      dialogId={`UpdateProductDialog.${productId}`}
      title={t('lists.updateProduct')}
      tooltipTitle={t('lists.updateProduct')}
      ButtonIcon={PencilIcon}
      Content={
        <CreateRetailProductForm
          dialogId={`UpdateProductDialog.${productId}`}
          onSubmit={bindSubmitActionToPromise(dispatch, createOrUpdateRetailProduct)}
          closeDialog={(dialogId: string) => dispatch(closeDialog(dialogId))}
          submitButtonLabel={t('general.update')}
          disableProductIdField
          initialValues={getRetailProductFormInitialValues(product, listId)}
        />
      }
    />
  )
}

export default EditListItem
