import { Environment } from '@seesignage/seesignage-utils'
import { EnvironmentNames } from '../types/environments'

/**
 * Get environment displayName if exists, otherwise use environment name.
 */
const getEnvironmentDisplayName = ({ displayName, name }: EnvironmentNames | Environment) =>
  displayName || name

export { getEnvironmentDisplayName }
