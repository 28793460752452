import React from 'react'
import { Grid, Paper, Typography } from '@mui/material'
import { CurrencyOutputUnit, CurrencyInputUnit } from '@seesignage/seesignage-utils'
import { Field } from 'redux-form'
import { ReduxTextField } from '../../../../components/FormInput/ReduxWrappers'
import MenuField from '../../../../components/MenuField'

const EditSchemaComponentFormatOptions = () => (
  <Paper style={{ padding: 8, margin: 4 }}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='body1'>Format options</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='caption'>
          Note: You can not use both Split and Currency format options at the same time.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='subtitle2'>Split</Typography>
      </Grid>
      <Grid item xs={6}>
        <Field
          fullWidth
          name='formatOptions.split.separator'
          label='Separator for split'
          helperText='For example, use "," as separator to divide "1,00" price to ["1", "00"] list.'
          component={ReduxTextField}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          fullWidth
          name='formatOptions.split.arrayIndex'
          label='Element index'
          helperText='For example, use "0" index to select "1" from ["1", "00"] list.'
          type='number'
          component={ReduxTextField}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='subtitle2'>Currency</Typography>
      </Grid>
      <Grid item xs={12}>
        <MenuField
          label='Input format'
          name='formatOptions.currency.input'
          items={['', ...Object.values(CurrencyInputUnit)]}
        />
      </Grid>
      <Grid item xs={12}>
        <MenuField
          label='Output format'
          name='formatOptions.currency.output'
          items={['', ...Object.values(CurrencyOutputUnit)]}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='caption'>
          Note: retail products (normal, general and pharmacy) &quot;price&quot; attribute is
          automatically converted from cents to &quot;xx,yy €&quot; format and retail product
          discountPrice attribute will be used if it exists (NOTE: then you should use
          &quot;price&quot; attiribute as &quot;id&quot;). If you want to manually format integers
          and decimals, use above split or input and output format option.
        </Typography>
      </Grid>
    </Grid>
  </Paper>
)

export default EditSchemaComponentFormatOptions
