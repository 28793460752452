import React from 'react'
import { reduxForm, InjectedFormProps } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { ContentTableFormData } from '../../../../../types/contents'
import CommonContentFields from './CommonContentFields'

interface OwnProps {
  fieldsDisabled: boolean
}

type FormProps = ContentTableFormData

type ComponentProps = OwnProps

const ContentTableForm: React.FC<ComponentProps & InjectedFormProps<FormProps, ComponentProps>> = ({
  fieldsDisabled
}) => {
  const [t] = useTranslation()
  return (
    <CommonContentFields
      formName={'ContentTableForm'}
      enableSize
      t={t}
      fieldsDisabled={fieldsDisabled}
    />
  )
}

export default reduxForm<FormProps, ComponentProps>({
  form: 'ContentTableForm'
})(ContentTableForm)
