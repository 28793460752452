import { LinearProgress, Paper, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import dateformat from 'dateformat'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectListScreensIsLoading, selectScreensAsArray } from '../../selectors/screens'
import {
  selectUserIsAuthenticated,
  selectUserLastLogin,
  selectUserName
} from '../../selectors/users'
import Version from '../../components/Version'
import PageTitle from '../../components/PageTitle'
import ReleaseNotes from './ReleaseNotes'
import ScreenStatuses from './ScreenStatuses'
import ScreenWarnings from './ScreenWarnings'

const styles = (theme: Theme) => ({
  container: {
    maxWidth: '700px',
    textAlign: 'center' as 'center',
    padding: theme.spacing(),
    margin: 'auto'
  },
  versionContainer: {
    padding: theme.spacing(),
    textAlign: 'center' as 'center',
    marginTop: 10
  },
  downloadButton: {
    marginTop: theme.spacing()
  },
  welcomeItem: {
    padding: theme.spacing(1),
    textAlign: 'center' as any
  }
})

const useStyles = makeStyles(styles)

const HomePage: React.FC = () => {
  const classes = useStyles()
  const [t] = useTranslation()
  const isUserAuthenticated = useSelector(selectUserIsAuthenticated)
  const user = useSelector(selectUserName)
  const lastLogin = useSelector(selectUserLastLogin)
  const screens = useSelector(selectScreensAsArray)
  const screensLoading = useSelector(selectListScreensIsLoading)
  return !isUserAuthenticated ? (
    <Fragment />
  ) : (
    <Fragment>
      <PageTitle />
      {screensLoading ? (
        <LinearProgress />
      ) : (
        <div className={classes.container}>
          <Paper className={classes.welcomeItem}>
            <Typography variant={'h5'}>{t('home.welcome', { user })}</Typography>
            <Typography variant='caption'>
              {t('home.lastLogin', {
                lastLogin: dateformat(new Date(lastLogin), 'dd.mm.yyyy HH:MM:ss')
              })}
            </Typography>
          </Paper>
          <ScreenStatuses screens={screens} t={t} />
          <ScreenWarnings
            screens={screens.filter(
              (
                { warnings } // show only screens with warnings
              ) => warnings && warnings.length > 0
            )}
          />
          <ReleaseNotes />
        </div>
      )}
      <Version className={classes.versionContainer} />
    </Fragment>
  )
}

export default HomePage
