import React from 'react'
import { Grid, Paper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FieldArrayFieldsProps, WrappedFieldArrayProps } from 'redux-form'
import { RevolverItemUI, Media } from '@seesignage/seesignage-utils'
import { useSelector } from 'react-redux'
import { selectIsUserDeviceMobile } from '../../../../selectors/users'
import SelectedRevolverItems from './SelectedRevolverItems'

interface RevolverItemsFieldProps extends WrappedFieldArrayProps {
  fields: FieldArrayFieldsProps<RevolverItemUI | Media>
}

const RevolverItemsField: React.FC<RevolverItemsFieldProps> = ({ fields }) => {
  const [t] = useTranslation()
  const isMobile = useSelector(selectIsUserDeviceMobile)
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Paper elevation={6} id='revolver-items'>
          <SelectedRevolverItems selectedMedia={fields} isMobile={isMobile} t={t} />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default RevolverItemsField
