import React, { Fragment } from 'react'
import { Paper, AppBar, Typography, Toolbar, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import SettingsIcon from 'mdi-react/SettingsIcon'
import { isSubEnvironmentUI } from '@seesignage/seesignage-utils'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PencilIcon from 'mdi-react/PencilIcon'
import { BreadcrumbContentType } from '../../../types/breadcrumbs'
import PageTitle from '../../../components/PageTitle'
import { selectSelectedEnvironment } from '../../../selectors/environments'
import { toolbarStyles } from '../../../styles/common'
import { selectUser } from '../../../selectors/users'
import { editEnvironment as editEnvironmentAction } from '../../../actions/environments'
import { bindSubmitActionToPromise } from '../../../utils/forms'
import Dialog from '../../Dialog'
import {
  closeDialog as closeDialogAction,
  openDialog as openDialogAction
} from '../../../actions/dialogs'
import { isAdminRole, isAdminUser } from '../../../utils/permissions'
import CreateEnvironmentForm from '../Forms/CreateEnvironmentForm'
import AdditionalEnvironmentSettingsForm from '../Forms/AdditionalEnvironmentSettingsForm'
import EnvironmentsToolbar from './EnvironmentsToolbar'
import EnvironmentUsers from './Users/EnvironmentUsers'
import EnvironmentDetails from './EnvironmentDetails'

const useStyles = makeStyles(theme => ({
  ...toolbarStyles(theme),
  gridContainer: {
    margin: 10,
    width: '99%'
  },
  paper: {
    position: 'relative'
  }
}))

const EditEnvironment: React.FC = () => {
  const classes = useStyles()
  const [t] = useTranslation()

  const dispatch = useDispatch()
  const updateEnvironment = bindSubmitActionToPromise(dispatch, editEnvironmentAction)
  const closeDialog = (dialogId: string) => dispatch(closeDialogAction(dialogId))
  const openDialog = (dialogId: string) => dispatch(openDialogAction(dialogId))

  const user = useSelector(selectUser)
  const environment = useSelector(selectSelectedEnvironment)

  const isAdmin = user ? isAdminRole(user.role) : false
  const parentTags =
    isSubEnvironmentUI(environment) && environment.parentTags && environment.parentTags.length > 0
      ? environment.parentTags.map(tag => ({ value: tag, label: tag }))
      : []
  return user && isAdminUser(user) && environment ? (
    <Fragment>
      <PageTitle contentType={BreadcrumbContentType.environments} />
      <EnvironmentsToolbar environment={environment} />
      <Grid className={classes.gridContainer} container spacing={2}>
        <Grid item sm={12} md={6}>
          <Paper elevation={4} className={classes.paper}>
            <EnvironmentUsers currentUser={user} environment={environment} />
          </Paper>
        </Grid>
        <Grid item sm={12} md={6}>
          <Paper elevation={4} className={classes.paper}>
            <AppBar className={classes.appBar} position='sticky' color='default'>
              <Toolbar>
                <Typography variant='h6'>{t('environment.details')}</Typography>
                <div className={classes.spacer} />
                <div className={classes.actions}>
                  <Dialog
                    dialogId='EditAdditionalEnvironmentSettingsDialog'
                    title={t('environment.settings')}
                    tooltipTitle={t('environment.settings')}
                    ButtonIcon={PencilIcon}
                    Content={
                      <AdditionalEnvironmentSettingsForm
                        closeDialog={() => closeDialog('EditAdditionalEnvironmentSettingsDialog')}
                        showParentTags={parentTags.length > 0}
                        initialValues={{
                          name: environment.name,
                          displayName: environment.displayName,
                          tags: environment.tags
                            ? environment.tags.map(tag => ({ value: tag, label: tag }))
                            : [],
                          parentTags
                        }}
                      />
                    }
                  />
                  {isAdmin && (
                    <Dialog
                      dialogId='EditEnvironmentDialog'
                      title={t('environment.settings')}
                      tooltipTitle={t('environment.settings')}
                      ButtonIcon={SettingsIcon}
                      Content={
                        <CreateEnvironmentForm
                          closeDialog={() => closeDialog('EditEnvironmentDialog')}
                          submitAction={updateEnvironment}
                          submitButtonLabel={t('general.update')}
                          isUpdate={true}
                          initialValues={{
                            name: environment.name,
                            displayName: environment.displayName,
                            features: environment.features,
                            pricing: environment.pricing,
                            colors: environment.colors || [],
                            parentColors: isSubEnvironmentUI(environment)
                              ? environment.parentProperties.colors || []
                              : [],
                            notes: environment.notes
                          }}
                        />
                      }
                    />
                  )}
                </div>
              </Toolbar>
            </AppBar>
            <EnvironmentDetails
              isSystemAdmin={isAdmin}
              environment={environment}
              openDialog={openDialog}
              closeDialog={closeDialog}
            />
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  ) : (
    <Fragment />
  )
}

export default EditEnvironment
