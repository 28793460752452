import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

type AlertType = 'delete' | 'create' | 'edit' | 'basic'

interface AlertDialogProps {
  type?: AlertType
  cancelText: string
  confirmText: string
  contentText?: string
  Content?: JSX.Element
  onCancel: () => void
  onConfirm: () => void
  open: boolean
  title: string
}

const color: { [key in AlertType]: 'error' | 'success' | 'secondary' | 'primary' } = {
  delete: 'error',
  create: 'success',
  edit: 'secondary',
  basic: 'primary'
}

const AlertDialog = ({
  type = 'basic',
  cancelText,
  confirmText,
  contentText,
  Content,
  onCancel,
  onConfirm,
  open,
  title
}: AlertDialogProps) => (
  <Dialog
    open={open}
    onClose={onCancel}
    aria-labelledby='alert-dialog-title'
    aria-describedby='alert-dialog-description'>
    <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
    <DialogContent>
      {contentText && (
        <DialogContentText id='alert-dialog-description'>{contentText}</DialogContentText>
      )}
      {Content && Content}
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel}>{cancelText}</Button>
      <Button color={color[type]} onClick={onConfirm} autoFocus>
        {confirmText}
      </Button>
    </DialogActions>
  </Dialog>
)

export default AlertDialog
