import React, { Fragment, useState } from 'react'
import { Typography, IconButton, SwipeableDrawer, Toolbar } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { WithTranslation, withTranslation } from 'react-i18next'
import {
  ChannelItem,
  PlaylistUI,
  isChannelPlaylist,
  isChannelCampaign,
  CampaignSubUI,
  CampaignUI,
  isLegacyChannelPlaylist,
  isLegacyChannelCampaign
} from '@seesignage/seesignage-utils'
import { connect } from 'react-redux'
import ArrowRightIcon from 'mdi-react/ArrowRightIcon'
import CreateChannelItemForm from '../Forms/CreateChannelItemForm'
import EditPlaylist from '../../Playlists/EditPlaylist'
import { UpdateChannelItem } from '../../../types/actions'
import { CalendarEvent } from '../../../types/channels'
import { getUpdateChannelItemFormInitialValues } from '../../../utils/channels'
import { StateInterface } from '../../../types/states'
import { selectContentIdFromPathname } from '../../../selectors/routing'
import { selectChannelItemById } from '../../../selectors/channels'
import { selectSelectedPlaylist, selectIsPlaylistModified } from '../../../selectors/playlists'
import PlaylistUnsavedChanges from '../../Playlists/Notification/PlaylistUnsavedChanges'
import EditCampaign from '../../Campaigns/EditCampaign/EditCampaignForm'
import { selectSelectedCampaign } from '../../../selectors/campaigns'
import { getCampaignInitialValues } from '../../../utils/campaigns'
import { validatePlaylistWriteAccess } from '../../../utils/permissions'
import { selectUser } from '../../../selectors/users'
import EditCampaignButtons from '../../Campaigns/EditCampaign/EditCampaignButtons'

const useStyles = makeStyles(() => ({
  playlistSettings: {
    maxWidth: 700
  }
}))

interface OwnProps {
  isEditChannelItemOpen: boolean
  closeEditPlaylist: () => void
  updateItem: UpdateChannelItem
  deleteItem: (itemId: string) => void
  selectedCalendarEvent?: CalendarEvent
}

interface StateProps {
  selectedChannelItem?: ChannelItem
  selectedPlaylist?: PlaylistUI
  selectedCampaign?: CampaignSubUI | CampaignUI
  isPlaylistModified: boolean
  userHasWriteAccess: boolean
}

const EditChannelItem: React.FC<OwnProps & StateProps & WithTranslation> = ({
  isEditChannelItemOpen,
  closeEditPlaylist,
  updateItem,
  deleteItem,
  selectedCalendarEvent,
  selectedChannelItem,
  selectedPlaylist,
  selectedCampaign,
  isPlaylistModified,
  userHasWriteAccess,
  t
}) => {
  const userHasAccess = selectedCalendarEvent?.userHasAccess
  const [alertOpen, setAlertOpen] = useState(false)
  const classes = useStyles()
  const handleCloseDrawer = () => {
    if (!isPlaylistModified) {
      closeEditPlaylist()
      setAlertOpen(false)
    } else {
      setAlertOpen(true)
    }
  }
  return (
    <SwipeableDrawer
      anchor='right'
      open={isEditChannelItemOpen}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onOpen={() => {}}
      onClose={handleCloseDrawer}
      disableSwipeToOpen>
      <Fragment>
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='close drawer'
            onClick={handleCloseDrawer}
            edge='start'
            size='large'>
            <ArrowRightIcon />
          </IconButton>
          <Typography variant='h6' noWrap>
            {selectedPlaylist ? selectedPlaylist.name : ''}
            {selectedCampaign ? selectedCampaign.name : ''}
          </Typography>
        </Toolbar>
        {(isChannelPlaylist(selectedChannelItem) || isLegacyChannelPlaylist(selectedChannelItem)) &&
        userHasAccess &&
        isEditChannelItemOpen && ( // render only when open, otherwise form field might not be reseted
            <Fragment>
              <div className={classes.playlistSettings}>
                {selectedChannelItem.recurringRule && (
                  <Typography style={{ padding: 8, margin: 8 }} color='primary' variant='body1'>
                    {t('channels.recurrency.recurringEvent')}
                  </Typography>
                )}
                {!userHasAccess && (
                  <Typography style={{ padding: 8, margin: 8 }} color='secondary' variant='body1'>
                    {t('channels.forms.noAccess')}
                  </Typography>
                )}
                <div style={{ padding: 8, margin: 8 }}>
                  <CreateChannelItemForm
                    name='UpdateChannelItemForm'
                    isUpdate
                    userHasAccess={userHasAccess && userHasWriteAccess}
                    submitButtonLabel={t('general.update')}
                    onSubmit={updateItem}
                    deleteItem={deleteItem}
                    event={selectedCalendarEvent}
                    initialValues={{
                      ...getUpdateChannelItemFormInitialValues(
                        selectedPlaylist,
                        selectedChannelItem,
                        selectedCalendarEvent
                      ),
                      itemId: selectedCalendarEvent?.itemId
                    }}
                  />
                </div>
              </div>
              <EditPlaylist />
            </Fragment>
          )}
        {(isChannelCampaign(selectedChannelItem) || isLegacyChannelCampaign(selectedChannelItem)) &&
          selectedCampaign &&
          userHasAccess && (
            <div>
              <EditCampaignButtons campaign={selectedCampaign} />
              <EditCampaign
                campaign={selectedCampaign}
                initialValues={getCampaignInitialValues(selectedCampaign)}
              />
            </div>
          )}
        <PlaylistUnsavedChanges open={alertOpen} closeNotification={() => setAlertOpen(false)} />
      </Fragment>
    </SwipeableDrawer>
  )
}
const mapStateToProps = (state: StateInterface, ownProps: OwnProps): StateProps => {
  const channelId = selectContentIdFromPathname(state)
  const selectedPlaylist = selectSelectedPlaylist(state)
  const user = selectUser(state)
  return {
    selectedChannelItem: selectChannelItemById(
      channelId,
      ownProps.selectedCalendarEvent?.itemId
    )(state),
    selectedPlaylist,
    selectedCampaign: selectSelectedCampaign(state),
    isPlaylistModified: selectIsPlaylistModified(state),
    userHasWriteAccess:
      selectedPlaylist && user ? validatePlaylistWriteAccess(selectedPlaylist, user) : false
  }
}
export default connect(mapStateToProps)(withTranslation()(EditChannelItem))
