import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { formValueSelector, Field, FieldArray } from 'redux-form'
import { CampaignItemType } from '@seesignage/seesignage-utils'
import { Grid, IconButton, Tooltip, FormControlLabel, Radio, FormHelperText } from '@mui/material'
import DeleteIcon from 'mdi-react/DeleteIcon'
import PlayIcon from 'mdi-react/PlayIcon'
import { required, validateCampaignProductName } from '../../../../validation'
import { ReduxTextField, ReduxRadioGroup } from '../../../../components/FormInput/ReduxWrappers'
import { getBackground } from '../../../../components/Draggable/DraggableItems'
import { openDialog } from '../../../../actions/dialogs'
import { CampaignBatchPriceType } from '../../../../types/campaigns'
import { getHiddenBatchPriceFields } from '../../../../utils/campaigns'
import CampaignSubItemPriceFields from './CampaignItemPriceFields'
import ShowPercentageField from './ShowPercentageField'
import { ProductIdField, BatchProductsField, HideItemField } from '.'

const isPriceRequired = (batchPriceType?: CampaignBatchPriceType) => {
  if (batchPriceType === CampaignBatchPriceType.batchPercentage) {
    return false
  }
  return batchPriceType !== CampaignBatchPriceType.none
}

interface CampaignItemFieldProps {
  /** redux-form field name prefix */
  item: string
  /** redux-form fieldArray item index */
  index: number
  removeItem: () => void
  classes: Record<string, string>
  isCampaignSub: boolean
  /** Are buttons and fields disabled. True if `isCampaignSub = true` or `published = true` */
  isDisabled: boolean
  isPreviewDisabled?: boolean
  setPreviewItemIndex: React.Dispatch<React.SetStateAction<number>>
}

const CampaignItemField: React.FC<CampaignItemFieldProps> = ({
  classes,
  item,
  index,
  removeItem,
  isCampaignSub,
  isDisabled,
  setPreviewItemIndex,
  isPreviewDisabled
}) => {
  const [t] = useTranslation()
  const itemType = useSelector(state =>
    formValueSelector('EditCampaignForm')(state, `${item}.type`)
  )
  const batchPriceType: CampaignBatchPriceType = useSelector(state =>
    formValueSelector('EditCampaignForm')(state, `${item}.batchPriceType`)
  )

  const isHidden = useSelector(state =>
    formValueSelector('EditCampaignForm')(state, `${item}.isHidden`)
  )

  const vnrCode = useSelector(state => formValueSelector('EditCampaignForm')(state, `${item}.vnr`))

  const dispatch = useDispatch()
  const previewItem = () => {
    setPreviewItemIndex(index)
    dispatch(openDialog('PreviewCampaignDialog'))
  }

  const PreviewItemButton = () => (
    <div style={{ height: 48 }}>
      <Tooltip
        title={
          t(
            isPreviewDisabled ? 'campaigns.preview.unsavedChanges' : 'campaigns.preview.item'
          ) as string
        }>
        <span>
          <IconButton disabled={isPreviewDisabled} onClick={() => previewItem()} size='large'>
            <PlayIcon />
          </IconButton>
        </span>
      </Tooltip>
    </div>
  )
  return itemType === CampaignItemType.product ? (
    <div className={classes.itemContainer}>
      <Grid
        style={{ background: getBackground(isHidden), marginTop: 8 }}
        container
        spacing={1}
        key={`${item}.${index}`}>
        <Grid item xs={3}>
          <Grid container spacing={1}>
            <Grid item xs={vnrCode ? 6 : 12}>
              <Field
                fullWidth
                disabled={isDisabled}
                name={`${item}.productId`}
                label={t('campaigns.editCampaign.productId')}
                component={ProductIdField}
                item={item}
                required
                validate={[required]}
              />
            </Grid>
            {vnrCode && (
              <Grid item xs={6}>
                <Field
                  fullWidth
                  name={`${item}.vnr`}
                  label={t('vnr')}
                  disabled
                  component={ReduxTextField}
                  size='small'
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Field
            disabled={isDisabled}
            fullWidth
            name={`${item}.name`}
            label={t('general.name')}
            component={ReduxTextField}
            required
            validate={[validateCampaignProductName]}
            size='small'
          />
        </Grid>
        {isCampaignSub && <CampaignSubItemPriceFields priceRequired item={item} t={t} />}
        <Grid item xs={12}>
          <Field
            fullWidth
            multiline
            disabled={isDisabled}
            maxRows={4}
            variant='outlined'
            name={`${item}.description`}
            label={t('products.pharmacy.description')}
            component={ReduxTextField}
          />
        </Grid>
      </Grid>
      {!isDisabled && (
        <IconButton style={{ height: 48 }} onClick={() => removeItem()} size='large'>
          <DeleteIcon />
        </IconButton>
      )}
      {isCampaignSub && <Field name={`${item}.isHidden`} component={HideItemField} />}
      {isCampaignSub && <Field name={`${item}.showPercentage`} component={ShowPercentageField} />}
      <PreviewItemButton />
    </div>
  ) : (
    <div key={`${item}.products`} className={classes.batchProductsContainer}>
      <div style={{ display: 'flex', padding: 8, margin: 8 }}>
        <Grid style={{ width: '95%', marginRight: 10 }} container spacing={2}>
          <Grid item md={isCampaignSub ? 7 : 11} xs={12}>
            <Field
              fullWidth
              disabled={isDisabled}
              required
              validate={[required]}
              variant='outlined'
              name={`${item}.name`}
              label={t('campaigns.editCampaign.batchName')}
              component={ReduxTextField}
              size='small'
            />
          </Grid>
          {isCampaignSub && (
            <CampaignSubItemPriceFields
              priceRequired={isPriceRequired(batchPriceType)}
              item={item}
              t={t}
              hiddenPriceFields={getHiddenBatchPriceFields(batchPriceType, 'batch')}
            />
          )}
          <Grid item xs={12}>
            <Field
              fullWidth
              multiline
              disabled={isDisabled}
              maxRows={4}
              variant='outlined'
              name={`${item}.description`}
              label={t('products.pharmacy.description')}
              component={ReduxTextField}
            />
          </Grid>
          <Grid item xs={12}>
            {isCampaignSub ? (
              <Fragment>
                <FormControlLabel
                  value={CampaignBatchPriceType.packagePrice}
                  label={t(`campaigns.editCampaign.batchPrice.${batchPriceType}`)}
                  control={<Radio color='primary' disabled checked />}
                />
                <FormHelperText style={{ marginLeft: 32, marginTop: -10 }}>
                  {t(`campaigns.editCampaign.batchPrice.${batchPriceType}Helper`)}
                </FormHelperText>
              </Fragment>
            ) : (
              <Field
                name={`${item}.batchPriceType`}
                label={t('campaigns.editCampaign.batchPrice.batchPrice')}
                component={ReduxRadioGroup}
                style={{ display: 'flex', flexDirection: 'row' }}>
                {Object.values(CampaignBatchPriceType).map(type => (
                  <div key={type} style={{ marginLeft: 16 }}>
                    <FormControlLabel
                      value={type}
                      label={t(`campaigns.editCampaign.batchPrice.${type}`)}
                      control={<Radio color='primary' disabled={isDisabled} />}
                    />
                    <FormHelperText style={{ marginLeft: 32, marginTop: -10 }}>
                      {t(`campaigns.editCampaign.batchPrice.${type}Helper`)}
                    </FormHelperText>
                  </div>
                ))}
              </Field>
            )}
          </Grid>
        </Grid>
        {!isDisabled && (
          <IconButton style={{ height: 48 }} onClick={() => removeItem()} size='large'>
            <DeleteIcon />
          </IconButton>
        )}
        {isCampaignSub &&
          (batchPriceType !== CampaignBatchPriceType.batchPercentage ? (
            <Field name={`${item}.showPercentage`} component={ShowPercentageField} />
          ) : (
            <div style={{ width: 48 }} />
          ))}
        <PreviewItemButton />
      </div>
      <Grid item xs={12}>
        <FieldArray
          props={{ isCampaignSub, isDisabled, batchPriceType, t }}
          name={`${item}.products`}
          component={BatchProductsField}
        />
      </Grid>
    </div>
  )
}

export default CampaignItemField
