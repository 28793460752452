import { LinearProgress } from '@mui/material'
import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import PageTitle from '../../../../components/PageTitle'
import { selectCurrentMiscList, selectListIsLoading } from '../../../../selectors/lists'
import { BreadcrumbContentType } from '../../../../types/breadcrumbs'
import MiscListForm from './MiscListForm'
import MiscListToolbar from './MiscListToolbar'

const EditMiscList = () => {
  const list = useSelector(selectCurrentMiscList)
  const isLoading = useSelector(selectListIsLoading)

  return (
    <Fragment>
      <PageTitle
        contentType={BreadcrumbContentType.lists}
        contentId={list?.listId}
        contentName={list?.name}
      />
      <MiscListToolbar />
      {isLoading && <LinearProgress />}
      {list && !isLoading && (
        <MiscListForm
          type={list.type}
          initialValues={{
            items: [...list.items]
          }}
        />
      )}
    </Fragment>
  )
}

export default EditMiscList
