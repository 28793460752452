import React, { ComponentType } from 'react'
import { CircularProgress, TextField, TextFieldVariants } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { MdiReactIconProps } from 'mdi-react'
import { AutocompleteOption } from '@seesignage/seesignage-utils'
import { WrappedFieldMetaProps, WrappedFieldInputProps } from 'redux-form'
import { useTranslation } from 'react-i18next'

interface ReduxSelectWithAutocompleteProps {
  input: WrappedFieldInputProps
  label: string
  meta: WrappedFieldMetaProps
  options: AutocompleteOption[]
  disabled?: boolean
  OptionIcon?: ComponentType<MdiReactIconProps>
  popupIcon?: JSX.Element
  variant?: TextFieldVariants | undefined
  size?: 'small' | 'medium'
  loading?: boolean
}

const ReduxAutocompleteMulti = ({
  input,
  label,
  loading,
  ...custom
}: ReduxSelectWithAutocompleteProps) => {
  const [t] = useTranslation()
  return (
    <Autocomplete
      value={input.value || []}
      getOptionLabel={option => option.label}
      onChange={(event: any, value: any) => {
        input.onChange(value)
      }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      size={custom?.size}
      renderInput={params => (
        <TextField
          variant={custom?.variant || 'standard'}
          {...params}
          label={label}
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
      filterSelectedOptions
      loadingText={loading ? `${t('general.loading')}...` : undefined}
      {...custom}
    />
  )
}

export default ReduxAutocompleteMulti
