import React from 'react'
import { WrappedFieldInputProps } from 'redux-form'
import VolumeHighIcon from 'mdi-react/VolumeHighIcon'
import VolumeOffIcon from 'mdi-react/VolumeOffIcon'
import { Checkbox, Typography, FormControlLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start'
  },
  title: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '12px'
  },
  formControl: {
    maxHeight: '35px'
  }
}))

const toolbarIconSize = 25

interface TextAlignCheckBoxProps {
  input: WrappedFieldInputProps
  disabled?: boolean
  title?: string
}

const VolumeControl = ({ input: { onChange, value }, disabled, title }: TextAlignCheckBoxProps) => {
  const [t] = useTranslation()
  const classes = useStyles()
  return (
    <div className={classes.container}>
      {title && <Typography className={classes.title}>{title}</Typography>}
      <FormControlLabel
        className={classes.formControl}
        control={
          <Checkbox
            disabled={disabled}
            checked={!!value}
            icon={<VolumeOffIcon size={toolbarIconSize} />}
            checkedIcon={<VolumeHighIcon size={toolbarIconSize} color='#1e88e5' />}
            onChange={() => onChange(!value)}
          />
        }
        label={value ? t('general.on') : t('general.off')}
      />
    </div>
  )
}

export default VolumeControl
