import React from 'react'
import { makeStyles } from '@mui/styles'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { Typography } from '@mui/material'
import Breadcrumbs from '../../containers/Navigation/Breadcrumbs'
import { BreadcrumbContentType } from '../../types/breadcrumbs'

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  }
})

interface PageTitleProps {
  title?: string
  contentType?: BreadcrumbContentType
  contentId?: string
  contentName?: string
  additionalBreadcrumbs?: JSX.Element[]
}

const PageTitle: React.FC<PageTitleProps> = ({
  contentType,
  title,
  contentId,
  contentName,
  additionalBreadcrumbs
}) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <AppBar position='static' color='default'>
        <Toolbar>
          {title ? (
            <Typography variant='h6'>{title}</Typography>
          ) : (
            <Breadcrumbs
              contentType={contentType}
              contentId={contentId}
              contentName={contentName}
              additionalBreadcrumbs={additionalBreadcrumbs}
            />
          )}
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default PageTitle
