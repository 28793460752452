export interface FacebookApiErrorResponse {
  error?: {
    message: string
    type: string
    code: number
    fbtrace_id: string
  }
}

/**
 * Facebook SDK /me response when fields=id,name,picture
 */
export interface ValidFacebookApiMeResponse {
  id: string
  name: string
  picture: {
    data: {
      height: number
      is_silhouette: boolean
      url: string
      width: number
    }
  }
}

export type FacebookApiMeResponse = FacebookApiErrorResponse | ValidFacebookApiMeResponse

export const isValidFacebookApiMeResponse = (
  response: FacebookApiMeResponse | undefined
): response is ValidFacebookApiMeResponse =>
  (response as ValidFacebookApiMeResponse)?.id !== undefined

/**
 * Prettified response for Facebook SDK's '/me' response
 */
export interface FacebookUserAccount {
  id: string
  name: string
  /** Picture url */
  picture: string
}

/**
 * Facebook SDK me/accounts?fields=id,name,picture,access_token.as(accessToken) response
 */
export interface ValidFacebookApiAccountsResponse {
  data: {
    id: string
    name: string
    picture: {
      data: {
        height: number
        is_silhouette: boolean
        url: string
        width: number
      }
    }
    accessToken: string
  }[]
  paging: {
    cursors: {
      before: string
      after: string
    }
  }
}

export type FacebookApiAccountsResponse =
  | FacebookApiErrorResponse
  | ValidFacebookApiAccountsResponse

export const isValidFacebookApiAccountsResponse = (
  response: FacebookApiAccountsResponse | undefined
): response is ValidFacebookApiAccountsResponse =>
  (response as ValidFacebookApiAccountsResponse)?.data !== undefined

export interface FacebookUserPage {
  id: string
  accessToken: string
  name: string
  /** Picture url */
  picture: string
}

/**
 * Prettified response for Facebook SDK's '/me/accounts' response
 */
export type FacebookUserPages = FacebookUserPage[]
