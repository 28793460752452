import { AppBar, Toolbar, Typography } from '@mui/material'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import ErrorLogsTable from './ErrorLogsTable'

const ErrorLogs = () => {
  const [t] = useTranslation()
  return (
    <Fragment>
      <AppBar position='static' color='default'>
        <Toolbar>
          <Typography variant='h6'>{t('errorLogs.title')}</Typography>
        </Toolbar>
      </AppBar>
      <ErrorLogsTable />
    </Fragment>
  )
}

export default ErrorLogs
