import { Grid, Typography } from '@mui/material'
import { GeneralProductUI } from '@seesignage/seesignage-utils'
import React from 'react'
import Truncate from 'react-truncate'

const generatePriceText = (price?: number | null, unit?: string | null) => {
  if (price) {
    const euros = price / 100
    return unit ? `${euros} € / ${unit}` : `${euros} €`
  }
  return ''
}

interface ProductDetailsProps {
  product: GeneralProductUI
}

const ProductDetails: React.FC<ProductDetailsProps> = ({ product }) => {
  const { productMedia, name, description, price, unit } = product
  const url = productMedia?.thumbnailUrl || undefined
  return (
    <Grid container justifyContent='flex-start' alignItems='center'>
      <Grid item xs>
        <img src={url || undefined} height={100} width='auto' alt='' />
      </Grid>
      <Grid item xs>
        <Typography variant='subtitle1'>{name}</Typography>
        <Typography display='block' variant='caption'>
          <Truncate width={235} lines={1}>
            {description || ''}
          </Truncate>
        </Typography>
        <Typography display='block' variant='caption'>
          {generatePriceText(price, unit)}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default ProductDetails
