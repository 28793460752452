import React from 'react'
import { useTranslation } from 'react-i18next'
import ExpandIcon from 'mdi-react/ChevronDownIcon'
import FileImageIcon from 'mdi-react/FileImageIcon'
import { MediaType } from '@seesignage/seesignage-utils'
import Typography from '@mui/material/Typography'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { validImageTypes, validVideoTypes } from '../../utils/media'

const useStyles = makeStyles(() => ({
  fileIcon: {
    marginRight: 6
  }
}))
interface MediaFormatProps {
  mediaType?: MediaType
}

const MediaFormats = ({ mediaType }: MediaFormatProps) => {
  const [t] = useTranslation()
  const classes = useStyles()
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandIcon />}>
        <FileImageIcon className={classes.fileIcon} />
        <Typography color='primary'>
          {!mediaType && t('media.supportedFormatsTitle')}
          {mediaType === MediaType.image && t('media.supportedImageFormatsTitle')}
          {mediaType === MediaType.video && t('media.supportedVideoFormatsTitle')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          {!mediaType &&
            t('media.supportedFormatsDesc', {
              supportedMediaTypes: [...validImageTypes, ...validVideoTypes].join(', ')
            })}
          {mediaType === MediaType.image &&
            t('media.supportedImageFormatsDesc', {
              supportedMediaTypes: validImageTypes.join(', ')
            })}
          {mediaType === MediaType.video &&
            t('media.supportedVideoFormatsDesc', {
              supportedMediaTypes: validVideoTypes.join(', ')
            })}
        </Typography>
      </AccordionDetails>
    </Accordion>
  )
}

export default MediaFormats
