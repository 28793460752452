import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import { History } from 'history'
import users from './users'
import playlists from './playlists'
import dialogs from './dialogs'
import environments from './environments'
import screens from './screens'
import media from './media'
import confirmations from './confirmations'
import lists from './lists'
import products from './products'
import templates from './templates'
import customers from './customers'
import channels from './channels'
import infopages from './infopages'
import mocks from './mocks'
import contents from './contents'
import notifications from './notifications'
import tours from './tours'
import campaigns from './campaigns'
import iot from './iot'
import analytics from './analytics'

export default (history: History<any>) =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    campaigns,
    confirmations,
    channels,
    customers,
    dialogs,
    environments,
    infopages,
    lists,
    media,
    mocks,
    playlists,
    products,
    screens,
    templates,
    users,
    contents,
    notifications,
    iot,
    analytics,
    tours
  })
