import React from 'react'
import { connect } from 'react-redux'
import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { Dispatch } from 'redux'
import { Paper, IconButton } from '@mui/material'
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon'
import { signup as signupUser } from '../../../actions/users'
import { selectIsSignupDone } from '../../../selectors/users'
import { bindSubmitActionToPromise } from '../../../utils/forms'
import { StateInterface } from '../../../types/states'
import Background from '../../../components/Background'
import colors from '../../../styles/common/colors'
import { navigate } from '../../../actions/routes'
import SignupForm from './SignupForm'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: '500px',
    position: 'relative',
    top: '20%',
    overflow: 'auto',
    [theme.breakpoints.down('lg')]: {
      width: '90%',
      top: 5,
      height: '90%'
    },
    margin: 'auto',
    padding: theme.spacing(2),
    textAlign: 'center'
  },
  errorMessage: {
    color: 'red',
    marginTop: '10px'
  },
  backButton: {
    position: 'absolute',
    top: 4,
    left: 4
  }
}))

interface SignupProps {
  navigate: (path: string) => void
  signupUser: (params: any) => void
  isSignupDone?: boolean
}

const Signup = ({ navigate, signupUser, isSignupDone }: SignupProps) => {
  const classes = useStyles()
  const [t] = useTranslation()
  return (
    <Background>
      <Paper className={classes.container}>
        <IconButton className={classes.backButton} onClick={() => navigate('/login')} size='large'>
          <ArrowLeftIcon color={colors.seesignageColor} />
        </IconButton>
        <Typography variant='h6' gutterBottom>
          {t('signup.signup')}
        </Typography>
        {isSignupDone ? (
          <Typography variant='body2' gutterBottom>
            {t('signup.success')}
          </Typography>
        ) : (
          <SignupForm
            signupUser={signupUser}
            initialValues={{ dialingCode: { label: 'Finland (Suomi) - 358', value: '+358' } }}
          />
        )}
      </Paper>
    </Background>
  )
}

const mapStateToProps = (state: StateInterface) => ({
  isSignupDone: selectIsSignupDone(state)
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  navigate: (path: string) => dispatch(navigate(path)),
  signupUser: bindSubmitActionToPromise(dispatch, signupUser)
})

export default connect(mapStateToProps, mapDispatchToProps)(Signup)
