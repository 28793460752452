import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { getProofOfPlayFormInitialValues } from '../../../../utils/proofOfPlay'
import { selectSelectedEnvironment } from '../../../../selectors/environments'
import ProofOfPlayForm from './Forms/ProofOfPlayForm'
import ProofOfPlayTable from './ProofOfPlayTable/ProofOfPlayTable'

const ProofOfPlay = () => {
  const selectedEnvironment = useSelector(selectSelectedEnvironment)
  return (
    <Fragment>
      {selectedEnvironment && (
        <ProofOfPlayForm
          initialValues={getProofOfPlayFormInitialValues(selectedEnvironment.environmentId)}
          currentEnvironment={selectedEnvironment}
        />
      )}
      <ProofOfPlayTable />
    </Fragment>
  )
}

export default ProofOfPlay
