import { Button, DialogActions, InputAdornment } from '@mui/material'
import React from 'react'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { bindSubmitActionToPromise } from '../../../utils/forms'
import { validNewFileName } from '../../../validation'
import { ReduxTextField } from '../../../components/FormInput/ReduxWrappers'
import ErrorMessage from '../../../components/Errors/ErrorMessage'
import FormContainer from '../../../components/FormContainer/FormContainer'
import { closeDialog } from '../../../actions/dialogs'
import { RenameMediaFormData } from '../../../types/media'
import { renameMedia } from '../../../actions/media'

interface OwnProps {
  dialogId?: string
  /** We do not want to use form onSubmit when form is under another form in React DOM. Otherwise, it would submit also other forms. */
  useFormOnSubmit: boolean
  onClose?: () => void
}

type RenameMediaFormProps = OwnProps

const RenameMediaForm: React.FC<RenameMediaFormProps &
  InjectedFormProps<RenameMediaFormData, RenameMediaFormProps>> = ({
  handleSubmit,
  submitting,
  error,
  dialogId,
  initialValues,
  onClose,
  useFormOnSubmit
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const submitAction = bindSubmitActionToPromise(dispatch, renameMedia)
  const suffix = initialValues ? initialValues.fileType : ''
  const handleCloseClick = () => {
    if (dialogId) {
      dispatch(closeDialog(dialogId))
    }
    if (typeof onClose === 'function') {
      onClose()
    }
  }
  return (
    <FormContainer onSubmit={useFormOnSubmit ? handleSubmit(submitAction) : undefined}>
      <Field
        fullWidth
        name='name'
        component={ReduxTextField}
        required
        validate={[validNewFileName]}
        InputProps={{
          inputProps: { min: 0, max: 10000, step: 0.01 },
          endAdornment: <InputAdornment position='end'>.{suffix}</InputAdornment>
        }}
      />
      <ErrorMessage message={error} />
      <DialogActions>
        <Button onClick={handleCloseClick} color='primary'>
          {t('general.cancel')}
        </Button>
        <Button
          data-cy='submitRenameFolderButton'
          disabled={submitting}
          color='primary'
          type={useFormOnSubmit ? 'submit' : 'button'}
          onClick={!useFormOnSubmit ? handleSubmit(submitAction) : undefined}>
          {t('general.ok')}
        </Button>
      </DialogActions>
    </FormContainer>
  )
}

export default reduxForm<RenameMediaFormData, RenameMediaFormProps>({
  form: 'RenameMediaForm',
  onSubmitSuccess: (result, dispatch, props) => {
    // close Popover create folder when submit on success.
    if (typeof props.onClose === 'function') {
      props.onClose()
    }
  }
})(RenameMediaForm)
