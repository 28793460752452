import React from 'react'
import { Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Monitor from 'mdi-react/MonitorIcon'
import { TFunction } from 'i18next'
import { inlineBlockStyle } from '../../styles/common/styles'

const useStyles = makeStyles({
  inlineBlockStyle,
  monitorIcon: {
    margin: 2
  },
  monitorIcon90: {
    margin: 2,
    transform: 'rotate(90deg)'
  },
  monitorIcon270: {
    margin: 2,
    transform: 'rotate(270deg)'
  }
})

interface MonitorIconProps {
  t: TFunction
  rotation: number
}

const MonitorIcon = ({ rotation, t }: MonitorIconProps) => {
  const { inlineBlockStyle, monitorIcon, monitorIcon90, monitorIcon270 } = useStyles()
  return (
    <Tooltip disableInteractive title={t(`screens.rotation.${rotation}`) as string}>
      <div className={inlineBlockStyle}>
        {rotation === 0 && <Monitor className={monitorIcon} />}
        {rotation === 90 && <Monitor className={monitorIcon90} />}
        {rotation === 270 && <Monitor className={monitorIcon270} />}
      </div>
    </Tooltip>
  )
}

export default MonitorIcon
