import React from 'react'
import { InjectedFormProps, Field, reduxForm } from 'redux-form'
import { useTranslation } from 'react-i18next'
import {
  FormControlLabel,
  Radio,
  DialogContent,
  Button,
  DialogActions,
  LinearProgress,
  Typography,
  FormHelperText
} from '@mui/material'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { ReduxRadioGroup } from '../../../components/FormInput/ReduxWrappers'
import { bindSubmitActionToPromise } from '../../../utils/forms'
import { generateCampaignXlsx } from '../../../actions/campaigns'
import { closeDialog } from '../../../actions/dialogs'
import { CloseDialog } from '../../../types/actions'
import ErrorMessage from '../../../components/Errors/ErrorMessage'

interface OwnProps {
  isCampaignSub: boolean
  dialogId: string
  name: string
}

interface FormData {
  campaignId: string
  type: 'own' | 'subCampaigns'
}

interface DispatchProps {
  generateCampaignXlsx: (formData: any) => void
  closeDialog: CloseDialog
}

type ComponentProps = OwnProps & DispatchProps

const GenerateCampaignExcelForm: React.FC<ComponentProps &
  InjectedFormProps<FormData, ComponentProps>> = ({
  isCampaignSub,
  dialogId,
  name,
  generateCampaignXlsx,
  handleSubmit,
  closeDialog,
  submitting,
  error
}) => {
  const [t] = useTranslation()
  return (
    <form onSubmit={handleSubmit(generateCampaignXlsx)}>
      <DialogContent>
        <Typography variant='body1'>{t('campaigns.excel.generateDescription')}</Typography>
        <i>{name}</i>
        <br />
        {!isCampaignSub && (
          <Field name='type' component={ReduxRadioGroup}>
            <FormControlLabel
              value='own'
              label={t('campaigns.excel.own')}
              control={<Radio color='primary' />}
            />
            <FormHelperText style={{ marginLeft: 32, marginTop: -10 }}>
              {t('campaigns.excel.ownHelper')}
            </FormHelperText>
            <FormControlLabel
              value='subCampaigns'
              label={t('campaigns.excel.subCampaigns')}
              control={<Radio color='primary' />}
            />
            <FormHelperText style={{ marginLeft: 32, marginTop: -10 }}>
              {t('campaigns.excel.subCampaignsHelper')}
            </FormHelperText>
          </Field>
        )}
      </DialogContent>
      {error && <ErrorMessage message={error} />}
      {submitting && <LinearProgress />}
      <DialogActions>
        <Button disabled={submitting} onClick={() => closeDialog(dialogId)} color='primary'>
          {t('general.cancel')}
        </Button>
        <Button disabled={submitting} type='submit' color='primary'>
          {t('general.download')}
        </Button>
      </DialogActions>
    </form>
  )
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  closeDialog: (dialogId: string) => dispatch(closeDialog(dialogId)),
  generateCampaignXlsx: bindSubmitActionToPromise(dispatch, generateCampaignXlsx)
})

export default connect(
  null,
  mapDispatchToProps
)(
  reduxForm<FormData, ComponentProps>({
    form: 'GenerateCampaignExcelForm'
  })(GenerateCampaignExcelForm)
)
