import React, { useCallback, useEffect, useState } from 'react'
import {
  Avatar,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  FacebookPage,
  InstagramAccount,
  SocialMediaItem,
  SocialMediaItemsConfig,
  SocialMediaItemsType,
  SocialMediaPlatform,
  SocialMediaSelectionType,
  UserSocialMediaAccountType,
  isFacebookPage,
  isInstagramAccount
} from '@seesignage/seesignage-utils'
import { useTranslation } from 'react-i18next'
import ContentsApi from '../../../../../services/api/contents'
import { MediaData, SelectedMedia } from '../CreateSocialMediaWidgetWizard'
import MediaCard, { MediaCardSkeleton } from './MediaCard'

const useStyles = makeStyles(theme => ({
  headerAvatar: {
    width: 65,
    height: 65,
    marginRight: theme.spacing(2)
  }
}))

interface OwnProps {
  account: UserSocialMediaAccountType
  platform: SocialMediaPlatform
  socialMedia: InstagramAccount | FacebookPage
  mediaConfig: SocialMediaItemsConfig
  mediaData: MediaData
  selectedMedia: SelectedMedia
  setMediaData: React.Dispatch<React.SetStateAction<MediaData>>
  setSelectedMedia: React.Dispatch<React.SetStateAction<SelectedMedia>>
  setMediaConfig: React.Dispatch<React.SetStateAction<SocialMediaItemsConfig>>
}

type SelectContentProps = OwnProps

const SelectContent = ({
  account,
  platform,
  socialMedia,
  setSelectedMedia,
  selectedMedia,
  mediaData,
  setMediaData,
  mediaConfig,
  setMediaConfig
}: SelectContentProps) => {
  const classes = useStyles()
  const [t] = useTranslation()

  const [loading, setLoading] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)

  const mediaTab = mediaConfig.type
  const selectLatest = mediaConfig.selectionType === SocialMediaSelectionType.latest

  const isInstagramPlaform = platform === SocialMediaPlatform.instagram

  const fetchMediaData = useCallback(
    async (
      platform: SocialMediaPlatform,
      currTab: SocialMediaItemsType,
      nextUrl?: string
    ): Promise<MediaData> => {
      const defaultData = { items: [] }
      try {
        if (platform === SocialMediaPlatform.facebook && isFacebookPage(socialMedia)) {
          const { accessToken = '', id } = socialMedia
          const { data, paging } = await ContentsApi.getSocialMediaFacebookItems(
            accessToken,
            id,
            nextUrl
          )
          return {
            items: data,
            nextUrl: paging?.next
          }
        }
        if (platform === SocialMediaPlatform.instagram && isInstagramAccount(socialMedia)) {
          const { data, paging } = await ContentsApi.getSocialMediaInstagramItems(
            account.accessToken ?? '',
            socialMedia.id,
            currTab,
            nextUrl
          )
          return {
            items: data,
            nextUrl: paging?.next
          }
        }
        return defaultData
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        return defaultData
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleLoadMoreMediaData = async () => {
    setLoadingMore(true)
    const { items, nextUrl } = await fetchMediaData(platform, mediaTab, mediaData.nextUrl)
    setMediaData(value => ({
      ...value,
      items: [...value.items, ...items],
      nextUrl
    }))
    setLoadingMore(false)
  }

  const handleToggleMediaSection = async (
    event: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
    value: SocialMediaItemsType
  ) => {
    if (!value) {
      return
    }
    setSelectedMedia({})
    setMediaConfig(prevValue => ({
      ...prevValue,
      type: value
    }))
  }

  const handleToggleSelectionMode = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedMedia({})
    setMediaConfig(value => ({
      ...value,
      selectionType: evt.target.checked
        ? SocialMediaSelectionType.latest
        : SocialMediaSelectionType.custom
    }))
  }

  const handleSetLatestCount = async ({ target: { value } }) => {
    const newCount = +value
    if (newCount > 0 && newCount <= 20) {
      setMediaConfig(prevValue => ({ ...prevValue, latestCount: newCount }))
    }
  }

  const handleSelectCustomMedia = (item: SocialMediaItem, isSelected: boolean) => {
    if (isSelected) {
      setSelectedMedia(prevSelectedMedia => ({
        ...prevSelectedMedia,
        [item.id]: item
      }))
    } else {
      setSelectedMedia(prevSelectedMedia => {
        const newSelectedMedia = { ...prevSelectedMedia }
        delete newSelectedMedia[item.id]
        return newSelectedMedia
      })
    }
  }

  useEffect(() => {
    const handleLoadMediaData = async () => {
      setLoading(true)
      const newMediaData = await fetchMediaData(platform, mediaTab)
      setMediaData(newMediaData)
      if (mediaTab === SocialMediaItemsType.posts) {
        setMediaConfig(prevValue => ({
          ...prevValue,
          selectionType: SocialMediaSelectionType.custom,
          latestCount: 7
        }))
        setSelectedMedia({})
      } else {
        setMediaConfig(prevValue => ({
          ...prevValue,
          selectionType: SocialMediaSelectionType.latest,
          latestCount: 20
        }))
      }
      setLoading(false)
    }
    handleLoadMediaData()
  }, [mediaTab, fetchMediaData, platform, setMediaConfig, setMediaData, setSelectedMedia])

  useEffect(() => {
    const handleSelectLatestMedia = () => {
      const { latestCount } = mediaConfig
      const { items } = mediaData
      // Fetch more resources when latest count exceeds the fetched number of items
      if (latestCount && latestCount > items.length && mediaData.nextUrl) {
        handleLoadMoreMediaData()
        return
      }
      const selection = {}
      for (let index = 0; latestCount && index < latestCount && items[index]; index++) {
        const item = items[index]
        selection[item.id] = item
      }
      setSelectedMedia(selection)
    }
    if (mediaConfig.selectionType === SocialMediaSelectionType.latest) {
      handleSelectLatestMedia()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaData, mediaConfig.latestCount, mediaConfig.selectionType])

  return (
    <Grid container>
      <Grid item xs />
      <Grid container item xs={12} md={10} rowSpacing={2}>
        <Grid item xs={12} container justifyContent='space-between' alignItems='center'>
          <Grid item>
            <ListItemText
              primary={
                <Typography variant='h5' gutterBottom textTransform='capitalize'>
                  {platform}
                </Typography>
              }
            />
          </Grid>
          <Grid item>
            <ListItem>
              <ListItemAvatar>
                <Avatar src={socialMedia.picture} className={classes.headerAvatar} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant='h6'>
                    {isInstagramAccount(socialMedia)
                      ? `@${socialMedia.username}`
                      : socialMedia.name}
                  </Typography>
                }
                secondary={
                  <Typography color='text.secondary' textOverflow='ellipsis' noWrap>
                    {socialMedia.id}
                  </Typography>
                }
              />
            </ListItem>
          </Grid>
        </Grid>

        <Grid item xs={12} display='flex' justifyContent='center'>
          <ToggleButtonGroup
            size='medium'
            value={mediaTab}
            exclusive
            onChange={handleToggleMediaSection}
            disabled={!isInstagramPlaform}
            aria-label='Platform'>
            <ToggleButton value={SocialMediaItemsType.posts}>
              {t('contents.widgets.socialMedia.posts')}
            </ToggleButton>
            {isInstagramPlaform && (
              <ToggleButton value={SocialMediaItemsType.stories}>
                {t('contents.widgets.socialMedia.stories')}
              </ToggleButton>
            )}
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={12}>
          <Typography color='text.secondary' textAlign='center'>
            {t('contents.widgets.socialMedia.selectPostsToShow')}
          </Typography>
        </Grid>
        <Grid item xs={12} container justifyContent='center'>
          <Grid item xs={3}>
            <Divider>
              <Typography color='text.secondary'>{t('contents.widgets.socialMedia.or')}</Typography>
            </Divider>
          </Grid>
        </Grid>
        <Grid item xs={12} container justifyContent='center'>
          <Grid item>
            <FormControlLabel
              label={t(`contents.widgets.socialMedia.selectLatest.${mediaTab}`)}
              control={
                <Checkbox
                  checked={selectLatest}
                  onChange={handleToggleSelectionMode}
                  disabled={mediaTab === SocialMediaItemsType.stories}
                />
              }
            />
          </Grid>
          {mediaTab === SocialMediaItemsType.posts && (
            <Grid item xs={3}>
              <TextField
                size='small'
                type='number'
                label={t('contents.widgets.socialMedia.numberOfPosts')}
                inputProps={{ type: 'number', min: 1, max: 20 }}
                disabled={mediaConfig.selectionType === SocialMediaSelectionType.custom}
                value={mediaConfig.latestCount}
                error={(mediaConfig.latestCount && mediaConfig.latestCount > 20) || false}
                helperText='max: 20'
                onChange={handleSetLatestCount}
                fullWidth
              />
            </Grid>
          )}
        </Grid>
        <Grid item container xs={12} spacing={2} justifyContent='center'>
          {loading ? (
            [1, 2, 3, 4, 5, 6].map(item => (
              <Grid item key={item}>
                <MediaCardSkeleton platform={platform} />
              </Grid>
            ))
          ) : mediaData.items.length === 0 ? (
            <Grid item xs={12}>
              <Typography variant='h6' color='text.secondary' textAlign='center'>
                {t(`contents.widgets.socialMedia.noItemsFound.${mediaTab}`)}
              </Typography>
            </Grid>
          ) : (
            mediaData.items.map(item => (
              <Grid item key={item.id}>
                <MediaCard
                  platform={platform}
                  item={item}
                  disableSelect={selectLatest}
                  onSelectItem={handleSelectCustomMedia}
                  isSelected={!!selectedMedia[item.id]}
                />
              </Grid>
            ))
          )}
        </Grid>
        {mediaData.nextUrl && (
          <Grid item display='flex' justifyContent='center' xs={12}>
            <Button
              variant='outlined'
              startIcon={loadingMore && <CircularProgress size={20} />}
              onClick={handleLoadMoreMediaData}>
              {loadingMore ? 'Loading...' : 'Load more...'}
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid item xs />
    </Grid>
  )
}

export default SelectContent
