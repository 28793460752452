import {
  AutocompleteOption,
  ScreenType,
  Configs,
  ScreenResponse,
  PdaConfigs,
  ChannelItemPriority,
  ScreenChannel,
  ScreenNotes,
  ScreenContent,
  ScreenOptimization,
  ScreenAnalytics
} from '@seesignage/seesignage-utils'

export interface ScreensIndexedById {
  [screenId: string]: ScreenResponse
}

export interface ScreensViewMode {
  isListMode: boolean
}

export interface CsvScreen {
  name: string
  code?: string
  rotation: number
  identifier?: string | null
  productNumber: string
}

export interface ScreenChannelOption extends ScreenChannel {
  name?: string
  isParent: boolean
}

export interface CreateScreenFormData {
  rotation: 0 | 90 | 270
  playlistId?: AutocompleteOption
  playlistPriority?: ChannelItemPriority.low | ChannelItemPriority.medium
  listId?: AutocompleteOption
  channelIds?: ScreenChannelOption[] | null
  /** S3 key */
  defaultMedia?: string
  name: string
  productNumber: string
  location?: AutocompleteOption
  minItems?: AutocompleteOption
  type: ScreenType
  tags?: AutocompleteOption[]
  permissionTags?: AutocompleteOption[]
  identifier?: string
  locationId?: string
  billable?: boolean
  configs?: Configs
  pdaConfigs?: PdaConfigs
  isTest?: boolean
  showBarcode?: boolean
  syncPlay?: boolean
  content?: ScreenContent
  notes?: ScreenNotes
  optimization?: ScreenOptimization
  analytics?: ScreenAnalytics
}

export interface BatchUpdateScreenSelectedProperties {
  /** If value is true, property key will be updated for all screens */
  [property: string]: boolean
}

export interface BatchUpdateScreensFormData
  extends Omit<CreateScreenFormData, 'name' | 'identifier' | 'rotation'> {
  properties: BatchUpdateScreenSelectedProperties
  rotation?: 0 | 90 | 270
}
export interface UpdateScreenFromDashboardFormData {
  screenId: string
  environmentId: string
  configs?: Configs
}

export interface MoveScreenFormData {
  screenId: string
  destinationEnvironment: AutocompleteOption
}

export enum SearchFieldType {
  name = 'name',
  tags = 'tags'
}

/** Screen information for dashboard's datagrid */
export interface ScreenDataGrid extends ScreenResponse {
  environment: { name: string; displayName: string }
  /** highest priority of screen warnings */
  highestPriority: number
  daysSinceLastContact: number
}

export enum FilterByStatus {
  online = 'online',
  offline = 'offline',
  standby = 'standby',
  notLinked = 'notLinked'
}

export enum ScreensQueryParamType {
  searchTerm = 'searchTerm',
  tags = 'tags',
  status = 'status'
}

export interface UpdateScreensQueryParams {
  type: ScreensQueryParamType
  value: string
}

export enum TimeRange {
  week = 'week',
  oneDay = 'oneDay',
  threeDays = 'threeDays',
  all = 'all'
}
