import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import setDefaultOptions from 'date-fns/setDefaultOptions'
import { fi, enGB } from 'date-fns/locale'
import {
  fiCommonTranslations,
  svCommonTranslations,
  enCommonTranslations,
  Language
} from '@seesignage/seesignage-utils'
import moment from 'moment'
import { getLanguageFromLocalStorage } from '../utils/language'
import enTranslations from './languages/en.json'
import fiTranslations from './languages/fi.json'
import 'moment/locale/fi'
import 'moment/locale/sv'

// get language from local storage
const lng = getLanguageFromLocalStorage()

moment.updateLocale(lng, {
  week: { dow: 1, doy: 1 }
})

setDefaultOptions({ locale: lng === Language.en ? enGB : fi })

const resources = {
  en: {
    translation: {
      ...enTranslations,
      ...enCommonTranslations
    }
  },
  fi: {
    translation: {
      ...fiTranslations,
      ...fiCommonTranslations
    }
  },
  sv: {
    translation: {
      ...svCommonTranslations
    }
  }
}
i18n.use(initReactI18next).init({
  resources,
  lng,
  fallbackLng: 'sv',
  interpolation: {
    escapeValue: false
  },
  react: {
    useSuspense: true
  }
})

export default i18n
