const rssFeedDefaultVisibleProps = {
  channelTitle: true,
  channelDescription: false,
  itemTitle: true,
  itemDescription: false,
  itemImage: false,
  itemPubDate: false
}

const rssFeedDefaultAvailableProps = {
  channelTitle: false,
  channelDescription: false,
  itemTitle: false,
  itemDescription: false,
  itemImage: false,
  itemPubDate: false
}

export { rssFeedDefaultVisibleProps, rssFeedDefaultAvailableProps }
