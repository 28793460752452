import React from 'react'
import { Field, change, formValueSelector } from 'redux-form'
import {
  List,
  ListSubheader,
  ListItem,
  InputAdornment,
  Collapse,
  Typography,
  Divider,
  FormControlLabel,
  Checkbox
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import BorderLeftVariantIcon from 'mdi-react/BorderLeftVariantIcon'
import ArrowSplitVerticalIcon from 'mdi-react/ArrowSplitVerticalIcon'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ContentColorPicker from '../../FormInput/ColorPicker/ContentColorPicker'
import ReduxTextFieldWithDragChange from '../../FormInput/ReduxWrappers/ReduxTextFieldWithDragChange'
import CustomPopover from '../../Popover/Popover'

const useStyles = makeStyles(() => ({
  fieldContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '15px 0px 5px 0px'
  }
}))

interface BorderSettingsProps {
  formName: string
}

const BorderSettings = ({ formName }: BorderSettingsProps) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const dispatch = useDispatch()

  const handleEnableBorder = (e: any) => {
    const value = e.target.checked ? 10 : 0
    dispatch(change(formName, 'strokeWidth', value))
  }
  const borderWidth = useSelector(state => formValueSelector(formName)(state, 'strokeWidth'))
  return (
    <div>
      <CustomPopover
        buttonTooltip={t('contents.properties.border')}
        ButtonIcon={BorderLeftVariantIcon}>
        <List subheader={<ListSubheader>{t('contents.properties.border')}</ListSubheader>} dense>
          <ListItem style={{ display: 'flex' }}>
            <FormControlLabel
              label={t('contents.actions.enableBorder')}
              control={
                <Checkbox checked={borderWidth > 0} onChange={handleEnableBorder} color='primary' />
              }
            />
          </ListItem>
          <ListItem>
            <Collapse in={borderWidth > 0}>
              <div>
                <Divider />
                <div>
                  <div className={classes.fieldContainer}>
                    <Field
                      name='stroke'
                      component={ContentColorPicker}
                      tooltip={t('contents.properties.borderColor')}
                    />
                    <Typography>{t('contents.properties.borderColor')}</Typography>
                  </div>
                  <br />
                  <Field
                    name='strokeWidth'
                    component={ReduxTextFieldWithDragChange}
                    DragIcon={ArrowSplitVerticalIcon}
                    isToolbar
                    fixedSmallWidth
                    label={t('contents.properties.borderWidth')}
                    parse={(value: any) => {
                      const parsed = parseInt(value)
                      if (isNaN(parsed)) {
                        return 1
                      } else {
                        return parsed
                      }
                    }}
                    InputProps={{
                      inputProps: { min: 1, max: 200 },
                      endAdornment: <InputAdornment position='end'>px</InputAdornment>
                    }}
                  />
                </div>
              </div>
            </Collapse>
          </ListItem>
        </List>
      </CustomPopover>
    </div>
  )
}

export default BorderSettings
