import { handleActions } from 'redux-actions'
import { notifyUpdate } from '../actions/notifications'
import { NotificationsState } from '../types/states'

const initialState: NotificationsState = {
  updateAvailable: false
}
const versionReducer = handleActions(
  {
    [notifyUpdate]: state => ({
      ...state,
      updateAvailable: true
    })
  },
  initialState
)

export default versionReducer
