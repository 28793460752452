import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  DialogActions,
  Grid,
  Typography
} from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import PlusIcon from 'mdi-react/PlusIcon'
import ShoppingIcon from 'mdi-react/ShoppingIcon'
import React, { Fragment, ComponentType } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { MdiReactIconProps } from 'mdi-react'
import { selectIsUserDeviceMobile } from '../../../../selectors/users'
import { PageType } from '../../../../types/playlists'
import { StateInterface } from '../../../../types/states'

const styles = () => ({
  card: {
    maxWidth: 220,
    maxHeight: 220,
    margin: '0px 5px 15px'
  },
  cardMobile: {
    maxWidth: 150,
    maxHeight: 150,
    margin: '0px 5px 15px'
  },
  selectArea: {
    cursor: 'pointer' as any,
    userSelect: 'none' as any,
    '-moz-user-select': 'none' as any,
    '-ms-user-select': 'none' as any,
    '-webkit-user-select': 'none' as any,
    height: 225
  },
  iconContainer: {
    left: '40%',
    top: '30%',
    position: 'absolute' as any
  }
})

const renderCard = (
  classes: Record<string, string>,
  name: string,
  page: PageType,
  selectPage: (page: PageType) => void,
  Icon: ComponentType<MdiReactIconProps>,
  isMobile: boolean
) => (
  <Card className={isMobile ? classes.cardMobile : classes.card}>
    <CardActionArea>
      <CardContent
        onClick={() => selectPage(page)}
        className={classes.selectArea}
        style={{ height: isMobile ? 150 : 225 }}>
        <Grid container direction='column' alignItems='center'>
          <Grid item xs={12}>
            <Typography variant={isMobile ? 'body2' : 'h6'}>{name}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Icon size='60px' color='#1e88e5' />
          </Grid>
        </Grid>
      </CardContent>
    </CardActionArea>
  </Card>
)

interface OwnProps {
  selectPage: (page: PageType) => void
}

interface StateProps {
  isMobile: boolean
}
type AddProductAsItemProps = OwnProps & StateProps & WithStyles<typeof styles> & WithTranslation

const AddProductAsItem: React.FC<AddProductAsItemProps> = ({
  classes,
  selectPage,
  isMobile,
  t
}) => (
  <Fragment>
    <Grid container>
      <Grid item xs={6}>
        {renderCard(
          classes,
          t('playlists.editPlaylist.selectExistingProduct'),
          PageType.existingProduct,
          selectPage,
          ShoppingIcon,
          isMobile
        )}
      </Grid>
      <Grid item xs={6}>
        {renderCard(
          classes,
          t('playlists.editPlaylist.createNewProduct'),
          PageType.createProduct,
          selectPage,
          PlusIcon,
          isMobile
        )}
      </Grid>
    </Grid>
    <DialogActions>
      <Button onClick={() => selectPage(PageType.selectType)} color='primary'>
        {t('general.back')}
      </Button>
    </DialogActions>
  </Fragment>
)
const mapStateToProps = (state: StateInterface): StateProps => ({
  isMobile: selectIsUserDeviceMobile(state)
})

export default connect(mapStateToProps)(withStyles(styles)(withTranslation()(AddProductAsItem)))
