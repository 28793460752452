import React, { ComponentType } from 'react'
import {
  LinearProgress,
  Grid,
  DialogActions,
  Button,
  Paper,
  IconButton,
  Tooltip,
  Checkbox
} from '@mui/material'
import {
  Field,
  FieldArray,
  reduxForm,
  InjectedFormProps,
  WrappedFieldInputProps,
  WrappedFieldArrayProps,
  initialize
} from 'redux-form'
import { useTranslation } from 'react-i18next'
import DeleteIcon from 'mdi-react/DeleteIcon'
import TemperatureCentigradeIcon from 'mdi-react/TemperatureCentigradeIcon'
import SunnyIcon from 'mdi-react/WhiteBalanceSunnyIcon'
import RainIcon from 'mdi-react/WeatherPouringIcon'
import CloudyIcon from 'mdi-react/WeatherCloudyIcon'
import SnowyIcon from 'mdi-react/WeatherSnowyHeavyIcon'
import { TFunction } from 'i18next'
import { MdiReactIconProps } from 'mdi-react'
import { useDispatch } from 'react-redux'
import i18n from '../../../../translations/i18n'
import { required } from '../../../../validation'
import { ReduxTextField } from '../../../../components/FormInput/ReduxWrappers'
import { CloseDialog } from '../../../../types/actions'
import {
  AddPlaylistItemWeatherConditionFormData,
  PlaylistItemConditionType
} from '../../../../types/playlists'
import ErrorMessage from '../../../../components/Errors/ErrorMessage'

const getConditions = (t: TFunction) => [
  {
    label: t('playlists.editPlaylist.addItemCondition.sunny'),
    name: 'sun',
    Icon: SunnyIcon
  },
  {
    label: t('playlists.editPlaylist.addItemCondition.rainy'),
    name: 'rain',
    Icon: RainIcon
  },
  {
    label: t('playlists.editPlaylist.addItemCondition.cloudy'),
    name: 'cloud',
    Icon: CloudyIcon
  },
  {
    label: t('playlists.editPlaylist.addItemCondition.snowing'),
    name: 'snow',
    Icon: SnowyIcon
  }
]

interface ConditionCheckBoxProps {
  input: WrappedFieldInputProps
  Icon: ComponentType<MdiReactIconProps>
  t: TFunction
  tooltipTitle: string
}

const ConditionCheckBox = ({
  input: { onChange, value },
  Icon,
  tooltipTitle
}: ConditionCheckBoxProps) => (
  <Tooltip disableInteractive title={tooltipTitle}>
    <Checkbox
      checked={value ? value : false}
      icon={<Icon size={40} />}
      checkedIcon={
        <Icon
          size={40}
          color={'#1e88e5'}
          style={{ borderRadius: '50%', border: '1px solid #1e88e5' }}
        />
      }
      onChange={onChange}
    />
  </Tooltip>
)

interface RenderTemperatureRangesProps extends WrappedFieldArrayProps {
  t: TFunction
}

const renderTemperatureRanges = ({ fields, t }: RenderTemperatureRangesProps) => {
  const tempLow = 0
  const tempHigh = 20
  return (
    <Grid container>
      <Grid item xs={12}>
        <Button
          onClick={() =>
            fields.push({
              tempLow,
              tempHigh
            })
          }
          fullWidth
          variant='contained'
          style={{
            width: '50%'
          }}>
          <TemperatureCentigradeIcon style={{ marginRight: 8 }} />
          {t('playlists.editPlaylist.addItemCondition.addTemperatureRange')}
        </Button>
      </Grid>
      {fields.map((member, index: number) => (
        <Grid
          key={`range-${index}`}
          item
          xs={12}
          style={{
            margin: '10px 0px 10px 0'
          }}>
          <Paper
            style={{
              padding: 10
            }}>
            <Field
              name={`${member}.tempLow`}
              label={t('playlists.editPlaylist.addItemCondition.tempLow')}
              component={ReduxTextField}
              style={{ margin: 5 }}
              type='number'
              required
              validate={required}
            />
            <Field
              name={`${member}.tempHigh`}
              label={t('playlists.editPlaylist.addItemCondition.tempHigh')}
              component={ReduxTextField}
              style={{ margin: 5 }}
              type='number'
              required
              validate={required}
            />
            <IconButton
              onClick={() => fields.remove(index)}
              aria-label={t('general.delete')}
              style={{ float: 'right' }}
              size='large'>
              <DeleteIcon />
            </IconButton>
          </Paper>
        </Grid>
      ))}
    </Grid>
  )
}

interface OwnProps {
  submitAction: (formData: any) => void
  closeDialog: CloseDialog
  dialogId: string
  submitButtonLabel: string
}

type AddItemWeatherConditionFormProps = OwnProps

const AddItemWeatherConditionForm: React.FC<AddItemWeatherConditionFormProps &
  InjectedFormProps<{}, OwnProps>> = ({
  handleSubmit,
  submitting,
  submitAction,
  closeDialog,
  dialogId,
  submitButtonLabel,
  error
}) => {
  const dispatch = useDispatch()
  const [t] = useTranslation()
  return (
    <form onSubmit={handleSubmit(submitAction)}>
      <Grid container>
        <Grid item xs={12}>
          <Paper
            style={{
              padding: 10
            }}>
            <Tooltip disableInteractive title={t('playlists.editPlaylist.addItemCondition.remove')}>
              <IconButton
                onClick={() =>
                  // dispatch(change('AddItemWeatherConditionForm', 'weather.condition', []))
                  dispatch(
                    initialize('AddItemWeatherConditionForm', {
                      type: PlaylistItemConditionType.weather,
                      weather: undefined
                    })
                  )
                }
                aria-label={t('playlists.editPlaylist.addItemCondition.remove')}
                style={{ float: 'right' }}
                size='large'>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <p>{t('playlists.editPlaylist.addItemCondition.dependingOn')}</p>
            {getConditions(t).map(({ label, name, Icon }) => (
              <Field
                key={label}
                tooltipTitle={label}
                Icon={Icon}
                name={`weather.condition.${name}`}
                component={ConditionCheckBox}
              />
            ))}
            <FieldArray
              name='weather.temperatures'
              component={renderTemperatureRanges}
              props={{ t }}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {submitting && <LinearProgress />}
          <ErrorMessage message={error} />
        </Grid>
      </Grid>
      <DialogActions>
        <Button onClick={() => closeDialog(dialogId)} color='primary'>
          {t('general.cancel')}
        </Button>
        <Button disabled={submitting} color='primary' type='submit'>
          {submitButtonLabel}
        </Button>
      </DialogActions>
    </form>
  )
}

const validate = (values: AddPlaylistItemWeatherConditionFormData) => {
  const errors = {
    weather: {
      temperatures: [] as any
    }
  }
  errors.weather.temperatures = []
  if (values.weather?.temperatures) {
    for (const { tempLow, tempHigh } of values.weather.temperatures) {
      if (Number(tempLow) > Number(tempHigh)) {
        errors.weather.temperatures.push({
          tempLow: i18n.t('error.playlist.temperatures'),
          tempHigh: i18n.t('error.playlist.temperatures')
        })
      }
    }
  }
  return errors
}

export default reduxForm<{}, AddItemWeatherConditionFormProps>({
  form: 'AddItemWeatherConditionForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate
})(AddItemWeatherConditionForm)
