import {
  Button,
  DialogActions,
  FormControlLabel,
  Switch,
  TextField,
  Tooltip,
  IconButton,
  Grid,
  LinearProgress,
  Typography,
  MenuItem
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import MonitorIcon from 'mdi-react/MonitorIcon'
import { stringify } from 'query-string'
import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Autocomplete from '@mui/material/Autocomplete'
import dateFormat from 'dateformat'
import { ScreenResponse, PlaylistItemPreviewPlayer, ScreenType } from '@seesignage/seesignage-utils'
import { MobileDateTimePicker } from '@mui/x-date-pickers'
import { closeDialog } from '../../actions/dialogs'
import {
  selectIsUserDeviceMobile,
  selectPreviewSession,
  selectUserSub
} from '../../selectors/users'
import { StateInterface } from '../../types/states'
import { selectScreenById, selectScreensAsArray } from '../../selectors/screens'
import ScreensApi from '../../services/api/screens'
import { createPreviewSession } from '../../actions/users'
import { getScreenPreviewDates, saveScreenPreviewDate } from '../../services/localStorage/screens'
import {
  setPreviewPlaylistOrientation,
  getPreviewPlaylistOrientation
} from '../../services/localStorage/playlists'
import { convertDatePickerValue } from '../../utils/forms'
import PreviewPlayerItems from './PreviewPlayerItems'

const playerUrl = process.env.REACT_APP_PLAYER_URL

const getResolution = (orientation: string) =>
  orientation === 'landscape'
    ? {
        resolutionWidth: 1920,
        resolutionHeight: 1080
      }
    : {
        resolutionWidth: 1080,
        resolutionHeight: 1920
      }

const useStyles = makeStyles(() => ({
  monitorLanscape: {
    margin: 2
  },
  monitorPortrait: {
    margin: 2,
    transform: 'rotate(90deg)'
  },
  gridRoot: {
    paddingTop: 5
  },
  itemsTitle: {
    paddingBottom: 4
  }
}))

const getPreviewContainerStyle = (
  orientation: string,
  resolutionWidth: number,
  resolutionHeight: number,
  windowHeight: number,
  isFullSize: boolean,
  isMobile: boolean
) => {
  const margin = '10px auto'
  if (isFullSize) {
    return {
      width: resolutionWidth,
      height: resolutionHeight,
      margin
    }
  } else if (orientation === 'landscape') {
    const scale = isMobile ? (windowHeight / 1920) * 0.85 : (windowHeight / 1920) * 0.6
    return {
      transform: `scale(${scale})`,
      transformOrigin: 'top left',
      width: scale * resolutionWidth,
      height: scale * resolutionHeight,
      margin
    }
  } else if (orientation === 'portrait') {
    const scale = isMobile ? (windowHeight / 1920) * 0.85 : (windowHeight / 1920) * 0.4
    return {
      transform: `scale(${scale})`,
      transformOrigin: 'top left',
      width: scale * resolutionWidth,
      height: scale * resolutionHeight,
      margin
    }
  }
  return {}
}

const getScreenOrientationFromRotation = ({ rotation }: ScreenResponse) =>
  rotation === 90 || rotation === 270 ? 'portrait' : 'landscape'

interface OwnProps {
  enableSelectScreen?: boolean
}

type PreviewPlayerProps = OwnProps

const PreviewPlayer: React.FC<PreviewPlayerProps> = ({ enableSelectScreen }) => {
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const classes = useStyles()

  const session = useSelector(selectPreviewSession)
  const userId = useSelector(selectUserSub)
  const isMobile = useSelector(selectIsUserDeviceMobile)
  const screen = useSelector((state: StateInterface) =>
    session?.screenId ? selectScreenById(session.screenId)(state) : undefined
  )
  const screens = useSelector((state: StateInterface) =>
    enableSelectScreen ? selectScreensAsArray(state) : undefined
  )

  const [isFullSize, toggleFullSize] = useState(false)
  const [orientation, setOrientation] = useState(
    screen
      ? getScreenOrientationFromRotation(screen)
      : getPreviewPlaylistOrientation() || 'landscape'
  )
  const [height, setHeight] = useState(window.innerHeight)
  const [isPreviewItemsLoading, setPreviewItemLoading] = useState(false)
  const [date, setDate] = useState<Date | null>(null)
  const [items, setItems] = useState<PlaylistItemPreviewPlayer[] | undefined>()
  const [currentStoredDate, setCurrentStoredDate] = useState<string | undefined>('')

  const { resolutionWidth, resolutionHeight } = getResolution(orientation)
  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      // clean up after this effect when the component unmounts
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (
      session?.sessionId &&
      (screen === undefined || (screen && screen.type === ScreenType.media)) // note: price screen should not load preview player items
    ) {
      const getItemsWithThumbnails = async () => {
        setItems(undefined)
        setPreviewItemLoading(true)
        const items = await ScreensApi.getPreviewPlayerItems(session.sessionId, date?.toISOString())
        setItems(items)
        setPreviewItemLoading(false)
      }

      getItemsWithThumbnails()
    }
  }, [session, date, screen])

  useEffect(() => {
    if (screen) {
      setOrientation(getScreenOrientationFromRotation(screen))
    }
  }, [screen])

  const storedDates = getScreenPreviewDates()
  return (
    <Fragment>
      <Grid container spacing={1} className={classes.gridRoot}>
        {enableSelectScreen && screen && screens && screens.length > 0 && (
          <Fragment>
            <Grid item xs={6}>
              <Autocomplete
                disableClearable
                value={{ label: screen.name, value: screen.screenId }}
                options={screens.map(({ name, screenId }) => ({ label: name, value: screenId }))}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                getOptionLabel={option => option.label}
                renderInput={params => (
                  <TextField {...params} label={t('screens.select')} variant='outlined' />
                )}
                onChange={(e, value) => {
                  if (value) {
                    dispatch(createPreviewSession({ screenId: value.value }))
                  }
                }}
              />
            </Grid>
          </Fragment>
        )}
        {(screen === undefined || (screen && screen.type === ScreenType.media)) && (
          <Fragment>
            <Grid item xs={3}>
              <MobileDateTimePicker
                ampm={false}
                format='dd/MM/yyyy HH:mm'
                label={t('screens.preview.date')}
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    fullWidth: true
                  },
                  actionBar: {
                    actions: ['clear', 'cancel', 'accept']
                  }
                }}
                value={convertDatePickerValue(date)}
                // change value only when the last view is completed
                onAccept={e => {
                  if (e) {
                    saveScreenPreviewDate(e.toISOString())
                  }
                  setDate(e)
                }}
                disablePast
              />
            </Grid>
            {storedDates.length > 0 && (
              <Grid item xs={3}>
                <TextField
                  label={t('screens.preview.storedPreviewDate')}
                  variant='outlined'
                  select
                  fullWidth
                  value={currentStoredDate}
                  onChange={e => {
                    setCurrentStoredDate(e.target.value as string)
                    setDate(new Date(e.target.value))
                  }}>
                  {storedDates.map((date, i) => (
                    <MenuItem key={`${i}-${date}`} value={date}>
                      {dateFormat(new Date(date), 'd.m.yyyy HH:MM')}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
          </Fragment>
        )}

        <Grid item xs={12}>
          {isPreviewItemsLoading && <LinearProgress />}
          {items && (
            <Fragment>
              {items.length > 0 ? (
                <Fragment>
                  <Typography variant='body2' className={classes.itemsTitle}>
                    {t(
                      `screens.preview.${
                        screen?.content?.interleave ? 'itemsInterleaved' : 'items'
                      }`,
                      { count: items.length }
                    )}
                  </Typography>
                  <PreviewPlayerItems
                    items={items}
                    t={t}
                    currentTimeMs={date ? date.valueOf() : Date.now()}
                  />
                </Fragment>
              ) : (
                <Typography variant='caption' color='secondary'>
                  {t(`screens.preview.noItems`)}
                </Typography>
              )}
            </Fragment>
          )}
        </Grid>
        <Grid item xs={12}>
          {session?.playlistId && (
            <Tooltip disableInteractive title={t('templates.changeOrientation')}>
              <IconButton
                onClick={() => {
                  const currentOrientation = orientation === 'landscape' ? 'portrait' : 'landscape'
                  setOrientation(currentOrientation)
                  setPreviewPlaylistOrientation(currentOrientation)
                }}
                size='large'>
                {orientation === 'portrait' ? (
                  <MonitorIcon />
                ) : (
                  <MonitorIcon className={classes.monitorPortrait} />
                )}
              </IconButton>
            </Tooltip>
          )}
          <FormControlLabel
            control={
              <Switch
                checked={isFullSize}
                onChange={() => {
                  toggleFullSize(!isFullSize)
                }}
                color='primary'
              />
            }
            label={t('templates.preview.fullSize')}
          />
        </Grid>
        <Grid item xs={12}>
          {session && session.sessionId && (session.playlistId || session.screenId) && (
            <div
              style={getPreviewContainerStyle(
                orientation,
                resolutionWidth,
                resolutionHeight,
                height,
                isFullSize,
                isMobile
              )}>
              <iframe
                id='previewPlayer'
                allow='autoplay'
                scrolling='no'
                title='Preview'
                src={`${playerUrl}/preview?${stringify(
                  {
                    userId,
                    sessionId: session.sessionId,
                    orientation,
                    width: resolutionWidth,
                    height: resolutionHeight,
                    date
                  },
                  { arrayFormat: 'comma' }
                )}`}
                style={{
                  // default screen resolution
                  width: resolutionWidth,
                  height: resolutionHeight
                }}
              />
            </div>
          )}
        </Grid>
      </Grid>
      <DialogActions>
        <Button
          onClick={() => {
            dispatch(closeDialog())
          }}
          color='primary'>
          {t('general.close')}
        </Button>
      </DialogActions>
    </Fragment>
  )
}

export default PreviewPlayer
