import React from 'react'
import { ListItemIcon, ListItemText, ListItem, ListItemButton } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { navigate } from '../../actions/routes'
import ErrorLogsIconBadge from './ErrorLogsIconBadge'

const isPathSelected = (currentPath: string, menuPath: string) => {
  if (`${currentPath}` === menuPath) {
    return true
  }
  const contentPath = currentPath.split('/')[3]
  // contents are under infopages also
  if (menuPath === '/infopages' && (contentPath === 'contents' || contentPath === 'infopages')) {
    return true
  }
  if (contentPath && `/${contentPath}` === menuPath) {
    return true
  }
  return false
}

interface OwnProps {
  path: string
  /** i18nt translation key */
  labelKey: string
  Icon: JSX.Element
  currentPath: string
  isMenuOpen: boolean
}

const NavigationMenuItem: React.FC<OwnProps> = ({
  path,
  labelKey,
  Icon,
  currentPath,
  isMenuOpen
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  return (
    <ListItem
      id={`menu-item-${path.replace('/', '')}`}
      data-cy={`menu-item-${path.replace('/', '')}`}
      onClick={() => dispatch(navigate(path))}
      disablePadding
      sx={{ display: 'block', minHeight: 'auto' }}
      key={path}
      selected={isPathSelected(currentPath, path)}>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: isMenuOpen ? 'initial' : 'center',
          px: 2.5
        }}>
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: isMenuOpen ? 3 : 'auto',
            justifyContent: 'center'
          }}>
          {path === '/errorLogs' ? <ErrorLogsIconBadge Icon={Icon} /> : Icon}
        </ListItemIcon>
        <ListItemText primary={t(labelKey)} sx={{ opacity: isMenuOpen ? 1 : 0 }} />
      </ListItemButton>
    </ListItem>
  )
}

export default NavigationMenuItem
