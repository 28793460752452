import React from 'react'
import { Typography, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { useDispatch } from 'react-redux'
import ToggleButton from '@mui/material/ToggleButton'
import { useTranslation } from 'react-i18next'
import { createChildTemplate, selectCurrentChildIndex } from '../../../actions/templates'
import colors from '../../../styles/common/colors'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    textAlign: 'center',
    margin: 8,
    padding: 8
  },
  toggleButtonSelected: {
    '&.Mui-selected': {
      backgroundColor: colors.seesignageColor
    }
  }
}))

interface ProductCountSelectorProps {
  unsavedChanges: boolean
  childTemplateIds: string[]
  selectedChildIndex: number
}

const ProductCountSelector: React.FC<ProductCountSelectorProps> = ({
  unsavedChanges,
  childTemplateIds,
  selectedChildIndex
}) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const dispatch = useDispatch()
  return (
    <div className={classes.root}>
      <Typography display='block' variant='caption'>
        {t('templates.editor.productCount')}
      </Typography>
      <ToggleButtonGroup
        exclusive
        value={selectedChildIndex}
        onChange={(e, val) => {
          if (val === 'new') {
            dispatch(createChildTemplate())
          } else if (val !== null) {
            dispatch(selectCurrentChildIndex(val))
          }
        }}>
        {childTemplateIds.map((id, i) => (
          <ToggleButton
            disabled={unsavedChanges}
            classes={{ selected: classes.toggleButtonSelected }}
            key={i}
            value={i}>
            {i + 1}
          </ToggleButton>
        ))}
        <Tooltip
          disableInteractive
          title={
            t('templates.editor.createChildTemplate', {
              productCount: childTemplateIds.length + 1
            }) as string
          }>
          <ToggleButton disabled={unsavedChanges} value='new'>
            +
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
    </div>
  )
}

export default ProductCountSelector
