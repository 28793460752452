import React from 'react'
import { reduxForm, InjectedFormProps } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { ContentStopScheduleFormData } from '../../../../../types/contents'
import CommonContentFields from './CommonContentFields'

const FORM_NAME = 'ContentMediaCarouselToolbarForm'
type FormProps = ContentStopScheduleFormData

const ContentFalconyAnnouncementsToolbarForm: React.FC<InjectedFormProps<FormProps>> = () => {
  const [t] = useTranslation()
  return <CommonContentFields formName={FORM_NAME} enableSize t={t} />
}

export default reduxForm<FormProps>({
  form: FORM_NAME,
  enableReinitialize: true
})(ContentFalconyAnnouncementsToolbarForm)
