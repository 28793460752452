import { createAction } from './utils'

const reducer = 'DIALOGS'

const openDialog = createAction(reducer, 'OPEN')
const closeDialog = createAction(reducer, 'CLOSE')

const setDialogSize = createAction(reducer, 'SET_DIALOG_SIZE')
const restoreDialogMaxWidth = createAction(reducer, 'RESTORE_MAX_WIDTH')

export { openDialog, closeDialog, setDialogSize, restoreDialogMaxWidth }
