import { Tooltip, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import TimerIcon from 'mdi-react/TimerIcon'
import WeatherLightningRainyIcon from 'mdi-react/WeatherLightningRainyIcon'
import React from 'react'
import { PlaylistItemUI, isPlaylistItemWithIntervalAbility } from '@seesignage/seesignage-utils'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { millisToMinutesAndSeconds } from '../../../../../utils/time'
import { selectIsUserDeviceMobile } from '../../../../../selectors/users'
import { StateInterface } from '../../../../../types/states'
import ScheduleIndicator from './ScheduleIndicator'
import ItemTags from './ItemTags'

const iconColor = 'rgba(0,0,0,0.5)'

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative' as any
  },
  scheduleContainer: {
    position: 'absolute' as 'absolute',
    left: 10,
    bottom: 5
  },
  weatherContainer: {
    position: 'absolute' as 'absolute',
    right: 4,
    bottom: 50
  },
  intervalContainer: {
    position: 'absolute' as 'absolute',
    right: 4,
    bottom: 15
  },
  customIntervalContainer: {
    position: 'absolute' as 'absolute',
    right: 8,
    bottom: 5
  },
  conditionIcon: {
    padding: 4
  },
  timer: {
    position: 'relative' as 'relative',
    color: iconColor,
    top: '-5px'
  }
}))
interface OwnProps {
  item: PlaylistItemUI
}
interface StateProps {
  isMobile: boolean
}

type ItemIconsProps = OwnProps & StateProps & WithTranslation

const ItemIcons: React.FC<ItemIconsProps> = ({ item, t, isMobile }) => {
  const classes = useStyles()
  const { conditions } = item
  return (
    <div className={classes.root}>
      <div className={classes.scheduleContainer}>
        <ScheduleIndicator item={item} t={t} isMobile={isMobile} />
      </div>
      {item.conditions?.weather && (
        <div className={classes.weatherContainer}>
          <Tooltip disableInteractive title={t('playlists.card.itemHasWeather')}>
            <div className={classes.conditionIcon}>
              <WeatherLightningRainyIcon color={iconColor} />
            </div>
          </Tooltip>
        </div>
      )}
      {isPlaylistItemWithIntervalAbility(item) && item.interval && (
        <div className={classes.customIntervalContainer}>
          <Tooltip
            disableInteractive
            title={
              t('playlists.card.itemHasCustomInterval', { interval: item.interval }) as string
            }>
            <div>
              <TimerIcon color={iconColor} />
              <Typography display='block' variant='caption'>
                {millisToMinutesAndSeconds(item.interval * 1000)}
              </Typography>
            </div>
          </Tooltip>
        </div>
      )}
      {conditions?.tags && <ItemTags tags={conditions.tags} t={t} />}
    </div>
  )
}
const mapStateToProps = (state: StateInterface): StateProps => ({
  isMobile: selectIsUserDeviceMobile(state)
})

export default connect(mapStateToProps)(withTranslation()(ItemIcons))
