import React, { useState, ComponentType } from 'react'
import classNames from 'classnames'
import { Accordion, AccordionSummary, Typography, AccordionDetails, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import ExpandIcon from 'mdi-react/ChevronDownIcon'
import { MdiReactIconProps } from 'mdi-react'

const useStyles = makeStyles({
  actions: {
    float: 'right' as 'right'
  },
  scrollableContent: {
    overflow: 'scroll' as 'scroll'
  },
  flexContent: {
    display: 'flex'
  },
  denseExpandedAccordionSummary: {
    margin: '4px 0px 0px 0px !important'
  },
  boldTitle: {
    fontWeight: 'bold' as 'bold'
  },
  bordered: {
    border: `1px solid rgb(172 180 183)`
  }
})

interface SimpleExtensionPanelProps {
  title?: string
  TitleComponent?: JSX.Element
  TitleIcon?: ComponentType<MdiReactIconProps>
  children: React.ReactNode
  isOpen?: boolean
  Actions?: any
  actionsProps?: any
  isScrollableContent?: boolean
  isFlexContent?: boolean
  renderOnlyExpandedChildren?: boolean
  dense?: boolean
  boldTitle?: boolean
  bordered?: boolean
  accordionSummaryRootClass?: string
  accordionDetailsRootClass?: string
}

const SimpleExtensionPanel: React.FC<SimpleExtensionPanelProps> = ({
  title,
  TitleComponent,
  TitleIcon,
  children,
  isOpen,
  Actions,
  actionsProps,
  isScrollableContent,
  isFlexContent,
  renderOnlyExpandedChildren,
  boldTitle,
  bordered,
  accordionSummaryRootClass,
  accordionDetailsRootClass
}) => {
  const classes = useStyles()
  const [expanded, togglePanel] = useState(isOpen || false)
  return (
    <Accordion
      className={bordered ? classes.bordered : undefined}
      expanded={expanded}
      onChange={() => {
        togglePanel(!expanded)
      }}>
      <AccordionSummary
        expandIcon={<ExpandIcon />}
        classes={{
          expanded: classes.denseExpandedAccordionSummary,
          root: accordionSummaryRootClass
        }}>
        <Grid container>
          <Grid item xs={11}>
            {title && (
              <Typography
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap'
                }}
                className={classNames({
                  [classes.boldTitle]: boldTitle
                })}>
                {TitleIcon && <TitleIcon style={{ padding: 8 }} />}
                {title}
              </Typography>
            )}
            {TitleComponent && TitleComponent}
          </Grid>
          {Actions && (
            <Grid item xs={1}>
              <div
                className={classes.actions}
                // use stopPropagation to prevent exentsion panel from opening when clicking actions
                onClick={event => event.stopPropagation()}
                onFocus={event => event.stopPropagation()}>
                {Actions && <Actions {...actionsProps} />}
              </div>
            </Grid>
          )}
        </Grid>
      </AccordionSummary>
      {// when renderOnlyExpandedChildren true and expanded, render children.
      // Otherwise not to prevent unnecessary stuff in the dom that is not visible
      renderOnlyExpandedChildren && expanded && (
        <AccordionDetails
          classes={{ root: accordionDetailsRootClass }}
          className={classNames({
            [classes.scrollableContent]: isScrollableContent,
            [classes.flexContent]: isFlexContent
          })}>
          {children}
        </AccordionDetails>
      )}
      {renderOnlyExpandedChildren === undefined && (
        <AccordionDetails
          classes={{ root: accordionDetailsRootClass }}
          className={classNames({
            [classes.scrollableContent]: isScrollableContent
          })}>
          {children}
        </AccordionDetails>
      )}
    </Accordion>
  )
}

export default SimpleExtensionPanel
