import React, { Fragment } from 'react'
import { TFunction } from 'i18next'
import { Typography, Paper } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import classNames from 'classnames'
import dateFormat from 'dateformat'
import { Template, LunchList } from '@seesignage/seesignage-utils'
import { getLunchListSpecialDietsOrder } from '../../../../utils/lunchLists'
import { specialDietsStyles } from '../../../../styles/common'
import colors from '../../../../styles/common/colors'

const styles = () => ({
  ...specialDietsStyles(),
  weeksInfoContainer: {
    marginTop: 10,
    padding: 10
  },
  defaultDietInfoStyles: {
    backgroundColor: '#CCCFD1',
    color: 'white',
    width: 20,
    display: 'inline-block',
    textAlign: 'center' as 'center',
    height: 20,
    padding: 2,
    margin: '2px 4px'
  },
  parentText: {
    color: colors.parentLunchListColor
  },
  subText: {
    color: colors.lunchListDefautlColor
  }
})

const orderedDiets = getLunchListSpecialDietsOrder()

export interface WeeksInfoProps {
  t: TFunction
  list: LunchList
  template: Template
  isSubList: boolean
}

type OwnProps = WeeksInfoProps & WithStyles<typeof styles>

const WeeksInfo = ({
  t,
  list: { startDate, name: ListName },
  template: { name, maxItems },
  classes,
  isSubList
}: OwnProps) => (
  <Paper className={classes.weeksInfoContainer}>
    <Typography variant='h5'>{ListName}</Typography>

    <Typography variant='body1'>
      {!!maxItems && (
        <Fragment>
          {t('lists.lunch.instructions.maxProductsInDay', {
            startDate: dateFormat(startDate, 'd.m.yyyy'),
            templateName: name,
            maxProducts: maxItems
          })}
        </Fragment>
      )}
      {isSubList && (
        <Fragment>
          <span className={classes.subText}>{t('lists.lunch.instructions.blue')}</span>
          {t('lists.lunch.instructions.subListInfo1')}
          <span className={classes.parentText}>{t('lists.lunch.instructions.green')}</span>
          {t('lists.lunch.instructions.subListInfo2')}
        </Fragment>
      )}
    </Typography>

    <Typography variant='h6'>{t('lists.lunch.specialDiets')}</Typography>
    {orderedDiets.map(diet => (
      <div key={`diet-info-${diet}`}>
        <span className={classNames(classes.defaultDietInfoStyles, classes[diet])}>
          {t(`lists.lunch.dietAbbreviations.${diet}`)}
        </span>
        <Typography display='inline'>{t(`lists.lunch.diets.${diet}`)}</Typography>
      </div>
    ))}
  </Paper>
)

export default withStyles(styles)(WeeksInfo)
