import { Button, ButtonGroup } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import CursorDefaultIcon from 'mdi-react/CursorDefaultIcon'
import PanIcon from 'mdi-react/PanIcon'
import ZoomInOutlineIcon from 'mdi-react/ZoomInOutlineIcon'
import ZoomOutOutlineIcon from 'mdi-react/ZoomOutOutlineIcon'
import FitToPageOutlineIcon from 'mdi-react/FitToPageOutlineIcon'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setEditorCursorMode, zoomCanvas } from '../../../../actions/contents'
import { selectEditorOptions } from '../../../../selectors/contents'
import { EditorCursorMode, ZoomType } from '../../../../types/contents'
import { MAX_ZOOM_RATIO, MIN_ZOOM_RATIO } from '../../../../utils/fabric/canvasZoom'
import TooltipSpan from '../../../../components/Tooltip/TooltipSpan'

const useStyles = makeStyles({
  footerToolbar: {
    display: 'inline-block',
    position: 'absolute',
    bottom: 50,
    left: 50,
    zIndex: 10
  },
  nav: {
    marginRight: '20px'
  }
})

const FooterToolbar = () => {
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const { zoom = 1, cursorMode } = useSelector(selectEditorOptions)
  const classes = useStyles()
  const zoomPercentage = Math.floor(zoom * 100)
  return (
    <div className={classes.footerToolbar}>
      <ButtonGroup
        variant='contained'
        aria-label='outlined button group'
        color='inherit'
        className={classes.nav}>
        <TooltipSpan title={t('contents.actions.selectionMode')}>
          <Button
            onClick={() => dispatch(setEditorCursorMode(EditorCursorMode.move))}
            color={cursorMode !== EditorCursorMode.grab ? 'primary' : 'inherit'}>
            <CursorDefaultIcon />
          </Button>
        </TooltipSpan>
        <TooltipSpan title={t('contents.actions.dragMode')}>
          <Button
            onClick={() => dispatch(setEditorCursorMode(EditorCursorMode.grab))}
            color={cursorMode === EditorCursorMode.grab ? 'primary' : 'inherit'}>
            <PanIcon />
          </Button>
        </TooltipSpan>
      </ButtonGroup>
      <ButtonGroup variant='contained' aria-label='outlined button group' color='inherit'>
        <TooltipSpan title={t('contents.actions.zoomOut')}>
          <Button
            onClick={() => dispatch(zoomCanvas(ZoomType.out))}
            disabled={zoom <= MIN_ZOOM_RATIO}>
            <ZoomOutOutlineIcon />
          </Button>
        </TooltipSpan>
        <TooltipSpan title={t('contents.actions.resetZoom')}>
          <Button onClick={() => dispatch(zoomCanvas(ZoomType.reset))}>{zoomPercentage}%</Button>
        </TooltipSpan>
        <TooltipSpan title={t('contents.actions.scaleToFit')}>
          <Button onClick={() => dispatch(zoomCanvas(ZoomType.fit))}>
            <FitToPageOutlineIcon />
          </Button>
        </TooltipSpan>
        <TooltipSpan title={t('contents.actions.zoomIn')}>
          <Button
            onClick={() => dispatch(zoomCanvas(ZoomType.in))}
            disabled={zoom >= MAX_ZOOM_RATIO}>
            <ZoomInOutlineIcon />
          </Button>
        </TooltipSpan>
      </ButtonGroup>
    </div>
  )
}

export default FooterToolbar
