import React from 'react'
import { IconButton, MenuItem, Menu, ListItemIcon, Typography } from '@mui/material'
import { withTranslation, WithTranslation } from 'react-i18next'
import FilterVariantIcon from 'mdi-react/FilterVariantIcon'
import MonitorIcon from 'mdi-react/MonitorIcon'
import PlaylistPlayIcon from 'mdi-react/PlaylistPlayIcon'
import { PlaylistFilterTypes, PlaylistFilter } from '../../../types/playlists'
import { savePlaylistFilter } from '../../../services/localStorage/filters'

interface PlaylistFilterMenuProps extends WithTranslation {
  setFilterType: React.Dispatch<React.SetStateAction<PlaylistFilterTypes>>
  filterType: PlaylistFilter
}

const PlaylistFilterMenu: React.FC<PlaylistFilterMenuProps> = ({
  setFilterType,
  filterType,
  t
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <div>
      <div style={{ display: 'flex' }}>
        <IconButton aria-haspopup='true' onClick={handleClick} size='large'>
          {filterType === PlaylistFilterTypes.linkedToScreens ? (
            <MonitorIcon />
          ) : (
            <FilterVariantIcon />
          )}
        </IconButton>
        <Typography style={{ paddingTop: '16px', marginLeft: '-10px' }} variant='caption'>
          {t('playlists.search.changeFilterType')}
        </Typography>
      </div>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={menuOpen}
        onClose={handleClose}
        id='playlist-filter-menu'>
        <MenuItem
          key='all'
          onClick={() => {
            setFilterType(PlaylistFilterTypes.all)
            savePlaylistFilter(PlaylistFilterTypes.all)
            handleClose()
          }}
          selected={filterType === PlaylistFilterTypes.all}>
          <ListItemIcon>
            <PlaylistPlayIcon fontSize='small' />
          </ListItemIcon>
          <Typography variant='inherit'>{t('playlists.search.all')}</Typography>
        </MenuItem>
        <MenuItem
          key='linkedToScreens'
          onClick={() => {
            setFilterType(PlaylistFilterTypes.linkedToScreens)
            savePlaylistFilter(PlaylistFilterTypes.linkedToScreens)
            handleClose()
          }}
          selected={filterType === PlaylistFilterTypes.linkedToScreens}>
          <ListItemIcon>
            <MonitorIcon fontSize='small' />
          </ListItemIcon>
          <Typography variant='inherit'>{t('playlists.search.linkedToScreen')}</Typography>
        </MenuItem>
      </Menu>
    </div>
  )
}
export default withTranslation()(PlaylistFilterMenu)
