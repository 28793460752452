import React, { Fragment } from 'react'
import { reduxForm, InjectedFormProps } from 'redux-form'
import { useTranslation } from 'react-i18next'
import ImageIcon from 'mdi-react/ImageIcon'
import { Button } from '@mui/material'
import { MediaType } from '@seesignage/seesignage-utils'
import { ContentImageFormData, ContentEditorDialog } from '../../../../../types/contents'
import { OpenContentEditorDialog } from '../../../../../types/dialogs'
import ContentToolbarItem from '../ContentToolbarItem/ContentToolbarItem'
import CommonContentFields from './CommonContentFields'

type FormProps = ContentImageFormData
interface OwnProps {
  openDialog: OpenContentEditorDialog
}

const ContentImageForm: React.FC<OwnProps & InjectedFormProps<FormProps, OwnProps>> = ({
  openDialog
}) => {
  const [t] = useTranslation()
  return (
    <Fragment>
      <CommonContentFields
        formName={'ContentImageForm'}
        enableRotation
        enableSize
        enableScale
        t={t}
      />
      <ContentToolbarItem>
        <Button
          variant='contained'
          color='primary'
          startIcon={<ImageIcon />}
          onClick={() =>
            openDialog({
              dialogId: ContentEditorDialog.AddMediaContentFormDialog,
              mediaType: MediaType.image
            })
          }>
          {t('contents.forms.updateImage')}
        </Button>
      </ContentToolbarItem>
    </Fragment>
  )
}
export default reduxForm<FormProps, OwnProps>({
  form: 'ContentImageForm'
})(ContentImageForm)
