import { createAsyncActions, createAction } from './utils'

const reducer = 'CAMPAIGNS'

const resetCampaigns = createAction(reducer, 'RESET')

const deselectCampaign = createAction(reducer, 'DESELECT')

const setCurrentCampaignItemFieldName = createAction(reducer, 'SET_CURRENT_ITEM_FIELD_NAME')
const clearCurrentCampaignItemFieldName = createAction(reducer, 'CLEAR_CURRENT_ITEM_FIELD_NAME')

const {
  action: getCampaign,
  actionSuccess: getCampaignSuccess,
  actionFail: getCampaignFail
} = createAsyncActions(reducer, 'GET')

const {
  action: listCampaigns,
  actionSuccess: listCampaignsSuccess,
  actionFail: listCampaignsFail
} = createAsyncActions(reducer, 'LIST')

const {
  action: createCampaign,
  actionSuccess: createCampaignSuccess,
  actionFail: createCampaignFail
} = createAsyncActions(reducer, 'CREATE')

const {
  action: publishCampaign,
  actionSuccess: publishCampaignSuccess,
  actionFail: publishCampaignFail
} = createAsyncActions(reducer, 'PUBLISH')

const {
  action: acceptCampaign,
  actionSuccess: acceptCampaignSuccess,
  actionFail: acceptCampaignFail
} = createAsyncActions(reducer, 'ACCEPT')

const {
  action: updateCampaign,
  actionSuccess: updateCampaignSuccess,
  actionFail: updateCampaignFail
} = createAsyncActions(reducer, 'UPDATE')

const {
  action: updateCampaignTemplate,
  actionSuccess: updateCampaignTemplateSuccess,
  actionFail: updateCampaignTemplateFail
} = createAsyncActions(reducer, 'UPDATE_TEMPLATE')

const {
  action: prefillCampaignProduct,
  actionSuccess: prefillCampaignProductSuccess,
  actionFail: prefillCampaignProductFail
} = createAsyncActions(reducer, 'PREFILL_PRODUCT')

const {
  action: deleteCampaign,
  actionSuccess: deleteCampaignSuccess,
  actionFail: deleteCampaignFail
} = createAsyncActions(reducer, 'DELETE')

const {
  action: copyCampaign,
  actionSuccess: copyCampaignSuccess,
  actionFail: copyCampaignFail
} = createAsyncActions(reducer, 'COPY')

const {
  action: generateCampaignXlsx,
  actionSuccess: generateCampaignXlsxSuccess,
  actionFail: generateCampaignXlsxFail
} = createAsyncActions(reducer, 'GENERATE_EXCEL')

export {
  resetCampaigns,
  getCampaign,
  getCampaignFail,
  getCampaignSuccess,
  listCampaigns,
  listCampaignsFail,
  listCampaignsSuccess,
  createCampaign,
  createCampaignFail,
  createCampaignSuccess,
  publishCampaign,
  publishCampaignFail,
  publishCampaignSuccess,
  acceptCampaign,
  acceptCampaignFail,
  acceptCampaignSuccess,
  updateCampaign,
  updateCampaignFail,
  updateCampaignSuccess,
  updateCampaignTemplate,
  updateCampaignTemplateSuccess,
  updateCampaignTemplateFail,
  prefillCampaignProduct,
  prefillCampaignProductFail,
  prefillCampaignProductSuccess,
  deselectCampaign,
  deleteCampaign,
  deleteCampaignSuccess,
  deleteCampaignFail,
  copyCampaign,
  copyCampaignFail,
  copyCampaignSuccess,
  generateCampaignXlsx,
  generateCampaignXlsxFail,
  generateCampaignXlsxSuccess,
  setCurrentCampaignItemFieldName,
  clearCurrentCampaignItemFieldName
}
