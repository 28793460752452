import React, { useState, useEffect, useCallback } from 'react'
import { TextField, InputAdornment } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { WrappedFieldProps } from 'redux-form'

const useStyles = makeStyles({
  fixedSmallWidth: {
    width: 125
  },
  fixedSemiSmallWidth: {
    width: 145
  },
  warningField: {
    '& label': {
      color: 'orange'
    },
    '& .MuiInput-underline': {
      borderBottomColor: 'orange'
    }
  },
  adornment: {
    margin: '0px 1px 0px 1px'
  },
  dragControl: {
    marginRight: 3,
    color: 'gray',
    display: 'flex'
  },
  dragControlEnabled: {
    cursor: 'ew-resize',
    userSelect: 'none' as 'none'
  }
})

interface ReduxTextFieldProps extends WrappedFieldProps {
  label: string
  fullWidth?: boolean
  helperText?: string
  inputProps?: any
  isToolbar?: boolean
  height?: number | string
  DragIcon?: any
  fixedSmallWidth?: boolean
  fixedSemiSmallWidth?: boolean
  InputProps?: any
  type?: any
  disabled?: boolean
}

interface DragControlProps {
  Icon: any
  value: number
  setValue: (newValue: number) => void
  classes: any
  disabled?: boolean
}

const DragControl = ({ Icon, value, setValue, classes, disabled }: DragControlProps) => {
  const [snapshot, setSnapshot] = useState(value)

  const [startVal, setStartVal] = useState(0)

  const onStart = useCallback(
    (event: any) => {
      setStartVal(event.clientX)
      setSnapshot(value)
      // Set these so cursor stays on ew-size when mouse is down
      document.body.style.setProperty('cursor', 'ew-resize')

      const rootElement = document.getElementById('root') as HTMLElement
      rootElement?.style.setProperty('pointer-events', 'none')
    },
    [value]
  )
  useEffect(() => {
    const onUpdate = (event: any) => {
      if (startVal) {
        setValue(snapshot + event.clientX - startVal)
      }
    }

    const onEnd = () => {
      setStartVal(0)
      // reset cursor
      document.body.style.setProperty('cursor', 'default')
      const rootElement = document.getElementById('root') as HTMLElement
      rootElement?.style.setProperty('pointer-events', 'auto')
    }

    document.addEventListener('mousemove', onUpdate)
    document.addEventListener('mouseup', onEnd)
    return () => {
      document.removeEventListener('mousemove', onUpdate)
      document.removeEventListener('mouseup', onEnd)
    }
  }, [startVal, setValue, snapshot])

  return (
    <span
      onMouseDown={!disabled ? onStart : undefined}
      className={classNames({
        [classes.dragControl]: true,
        [classes.dragControlEnabled]: !disabled
      })}>
      <Icon />
    </span>
  )
}

const ReduxTextFieldWithDragChange = ({
  label,
  input,
  meta,
  inputProps,
  height,
  isToolbar,
  fixedSmallWidth,
  fixedSemiSmallWidth,
  InputProps,
  DragIcon,
  ...custom
}: ReduxTextFieldProps) => {
  const classes = useStyles()
  const { touched, invalid, error, warning } = meta
  let customOverrideProps = {}
  if (isToolbar) {
    customOverrideProps = {
      variant: 'outlined',
      size: 'small'
    }
  }

  const { value, onChange } = input
  const min: number | undefined = InputProps?.inputProps?.min
  const max: number | undefined = InputProps?.inputProps?.max

  const handleDragChange = (newValue: number) => {
    if (typeof min === 'number' && newValue <= min) {
      onChange(min)
    } else if (typeof max === 'number' && newValue >= max) {
      onChange(max)
    } else {
      onChange(newValue)
    }
  }

  return (
    <TextField
      variant='standard'
      className={classNames({
        [classes.fixedSmallWidth]: fixedSmallWidth,
        [classes.fixedSemiSmallWidth]: fixedSemiSmallWidth,
        [classes.warningField]: warning
      })}
      label={label}
      placeholder={label}
      error={touched && invalid}
      // overWrite helperText if validation fails
      inputProps={inputProps}
      {...custom}
      {...customOverrideProps}
      // overwrite custom helperText if validation fails
      helperText={touched && typeof error === 'string' ? error : custom.helperText || warning}
      {...input}
      InputProps={{
        sx: { height },
        startAdornment: (
          <InputAdornment position='start'>
            <DragControl
              value={value}
              setValue={handleDragChange}
              Icon={DragIcon}
              classes={classes}
              disabled={custom.disabled}
            />
          </InputAdornment>
        ),
        ...InputProps
      }}
    />
  )
}

export default ReduxTextFieldWithDragChange
