import { select, all, call, put, takeLatest } from 'redux-saga/effects'
import { SubmissionError, change, initialize, updateSyncErrors } from 'redux-form'
import { saveAs } from 'file-saver'
import {
  PharmacySearchCriteria,
  PharmacyMasterProduct,
  CampaignItem,
  CampaignProduct,
  CampaignProductUI,
  CampaignSub,
  Campaign,
  CampaignUI,
  CampaignSubUI,
  UpdateCampaign,
  isParentCampaign
} from '@seesignage/seesignage-utils'
import { toast } from 'react-toastify'
import JSZip from 'jszip'
import { selectEnvironmentIdFromPathname, selectQueryParamFromSearch } from '../selectors/routing'
import Api from '../services/api/campaigns'
import {
  listCampaignsSuccess,
  listCampaignsFail,
  createCampaignSuccess,
  createCampaignFail,
  listCampaigns,
  createCampaign,
  prefillCampaignProductSuccess,
  prefillCampaignProductFail,
  prefillCampaignProduct,
  updateCampaignSuccess,
  updateCampaignFail,
  updateCampaign,
  getCampaignSuccess,
  getCampaignFail,
  getCampaign,
  publishCampaignFail,
  publishCampaignSuccess,
  acceptCampaignSuccess,
  acceptCampaignFail,
  publishCampaign,
  acceptCampaign,
  deleteCampaignSuccess,
  deleteCampaignFail,
  deleteCampaign,
  copyCampaignSuccess,
  copyCampaignFail,
  copyCampaign,
  generateCampaignXlsx,
  generateCampaignXlsxFail,
  generateCampaignXlsxSuccess,
  setCurrentCampaignItemFieldName,
  updateCampaignTemplate,
  updateCampaignTemplateSuccess,
  updateCampaignTemplateFail
} from '../actions/campaigns'
import i18n from '../translations/i18n'
import {
  handleCreateCampaignFormParams,
  handleUpdateCampaignFormParams,
  handleGenerateCampaignExcelFormParams
} from '../types/formData'
import { sendGaEvent } from '../config/ga'
import { closeDialog, openDialog } from '../actions/dialogs'
import ProductsApi from '../services/api/products'
import { PrefillCampaignProductParams, isCampaignBatchProductsUI } from '../types/campaigns'
import { getCampaignInitialValues, getBatchPricePropertiesFromFormValue } from '../utils/campaigns'
import { selectSelectedCampaign, selectCampaignById } from '../selectors/campaigns'
import { generateAndDownloadZip } from '../utils/zip'
import { downloadFile } from '../utils/files'

/**
 * List all environment campaigns. If includeParentCampaigns is included in request payload,
 * request also parent campaigns from API if environment has a parent environment.
 * @param param0
 */
export function* handleListCampaigns() {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    if (environmentId) {
      const campaigns: Campaign[] = yield call(Api.getCampaigns, environmentId)
      yield put(listCampaignsSuccess(campaigns))
    } else {
      yield put(listCampaignsFail(i18n.t('error.playlist.selectEnvFirst')))
    }
  } catch (error) {
    yield put(listCampaignsFail(error.message))
  }
}

export function* handleCreateCampaign({
  payload: { formData, resolve, reject }
}: handleCreateCampaignFormParams) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const { name, startDate, endDate, template, customerId, tags } = formData

    if (environmentId) {
      const campaign: Campaign = yield call(Api.createCampaign, environmentId, {
        name,
        startDate,
        endDate,
        customerId,
        tags: tags.map(({ value }) => value),
        ...template
      })
      yield put(createCampaignSuccess(campaign))
      sendGaEvent('createCampaignSuccess', {
        category: 'campaigns'
      })
      yield put(closeDialog())
    }
    resolve()
  } catch (error) {
    yield put(createCampaignFail(error.message))
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.campaign.somethingWrongCreate')
      })
    )
  }
}

interface HandlePrefillCampaignProductParams {
  payload: PrefillCampaignProductParams
}

export function* handlePrefillCampaignProduct({
  payload: { form, item, productId }
}: HandlePrefillCampaignProductParams) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const products: PharmacyMasterProduct[] = yield call(
      ProductsApi.searchDrugs,
      environmentId,
      productId.length < 10 ? PharmacySearchCriteria.vnr : PharmacySearchCriteria.ean,
      productId
    )
    if (products.length > 0) {
      const { name, description, vnr, ean } = products[0]
      yield put(change(form, `${item}.name`, name))
      yield put(change(form, `${item}.description`, description))
      yield put(change(form, `${item}.vnr`, vnr))
      yield put(change(form, `${item}.productId`, ean))
      yield put(prefillCampaignProductSuccess())
    } else {
      yield put(change(form, `${item}.name`, ''))
      yield put(change(form, `${item}.description`, ''))
      yield put(change(form, `${item}.vnr`, ''))
      yield put(updateSyncErrors(form, {}, i18n.t('error.product.notFound', { productId })))

      yield put(prefillCampaignProductFail())
      yield put(setCurrentCampaignItemFieldName(item))
      yield put(openDialog('ConfirmCreateNewMasterProductDialog'))
    }
  } catch (error) {
    yield put(prefillCampaignProductFail(error.message))
  }
}

export function* handleUpdateCampaign({
  payload: { formData, resolve, reject }
}: handleUpdateCampaignFormParams) {
  try {
    // Use selected campaign from store because we can't use campaignId from query param in edit channel view
    const selectedCampaign: CampaignUI | CampaignSubUI | undefined = yield select(
      selectSelectedCampaign
    )
    if (selectedCampaign) {
      const { environmentId, campaignId } = selectedCampaign
      const {
        items,
        name,
        startDate,
        endDate,
        tags,
        template: { templateEnvironmentId, templateId }
      } = formData
      const campaignItems = items.reduce((newItems: CampaignItem[], item) => {
        if (isCampaignBatchProductsUI(item)) {
          const { batchPriceType, ...batch } = item
          const batchProducts = item.products.reduce(
            (newProducts: CampaignProduct[], product: CampaignProductUI) => {
            const { vnr, description, ...rest } = product // eslint-disable-line
              // note: description does not exist for batch product
              newProducts.push(rest)
              return newProducts
            },
            []
          )
          newItems.push({
            ...batch,
            ...getBatchPricePropertiesFromFormValue(batchPriceType),
            products: batchProducts
          })
        } else {
          const { vnr, ...rest} = item // eslint-disable-line
          newItems.push(rest)
        }
        return newItems
      }, [])

      const campaign: UpdateCampaign = {
        name,
        startDate,
        endDate,
        items: campaignItems,
        templateId,
        templateEnvironmentId,
        tags: tags.map(({ value }) => value)
      }
      const updatedCampaign: Campaign | CampaignSub = yield call(
        Api.updateCampaign,
        environmentId,
        campaignId,
        campaign
      )
      resolve()
      toast.success(i18n.t('campaigns.toasts.updateSuccess'))
      yield put(updateCampaignSuccess(updatedCampaign))
      // make sure that EditCampaignForm has pristine true after new values are saved so isDirty works correctly
      yield put(
        initialize(
          'EditCampaignForm',
          getCampaignInitialValues(
            {
              ...updatedCampaign,
              items //use item from form values because those have product data
            },
            true
          ),
          false
        )
      )
    } else {
      throw new Error('campaign not selected')
    }
  } catch (error) {
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.campaign.somethingWrongUpdate')
      })
    )
    yield put(updateCampaignFail(error.message))
  }
}

/**
 * Change parent environment campaign template and its sub environments related campaigns templates.
 * Note: this function is only for 'published' parent campaigns.
 */
export function* handleUpdateCampaignTemplate({
  payload: { formData, resolve, reject }
}: handleUpdateCampaignFormParams) {
  try {
    // Use selected campaign from store because we can't use campaignId from query param in edit channel view
    const selectedCampaign: CampaignUI | CampaignSubUI | undefined = yield select(
      selectSelectedCampaign
    )

    if (isParentCampaign(selectedCampaign) && selectedCampaign.published && formData.template) {
      const { environmentId, campaignId } = selectedCampaign
      const { template } = formData

      const updatedCampaign: Campaign | CampaignSub = yield call(
        Api.updateCampaignTemplate,
        environmentId,
        campaignId,
        template
      )
      resolve()
      toast.success(i18n.t('campaigns.toasts.updateSuccess'))
      yield put(updateCampaignTemplateSuccess(updatedCampaign))
      // set form isDirty false
      yield put(initialize('EditCampaignForm', formData, false))
    } else {
      throw new Error('Only published parent campaign template can be changed.')
    }
  } catch (error) {
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.campaign.somethingWrongUpdate')
      })
    )
    yield put(updateCampaignTemplateFail(error.message))
  }
}

interface GetCampaignParams {
  payload: string
}

function* handleGetCampaign({ payload }: GetCampaignParams) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const campaign: Campaign = yield call(Api.getCampaign, environmentId, payload)
    yield put(getCampaignSuccess(campaign))
  } catch (error) {
    yield put(getCampaignFail(error.message))
  }
}

function* handlePublishCampaign() {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const campaignId: string = yield select(selectQueryParamFromSearch('campaignId'))
    if (environmentId && campaignId) {
      yield call(Api.publishCampaign, environmentId, campaignId)
      yield put(publishCampaignSuccess({ environmentId, campaignId }))
      toast.success(i18n.t('campaigns.toasts.publishedSuccess'))
      yield put(closeDialog())
    } else {
      throw new Error('environmentId or campaignId missing')
    }
  } catch (error) {
    yield put(publishCampaignFail(error.message))
  }
}

function* handleAcceptCampaign() {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const campaignId: string = yield select(selectQueryParamFromSearch('campaignId'))
    if (environmentId && campaignId) {
      yield call(Api.acceptCampaign, environmentId, campaignId)
      yield put(acceptCampaignSuccess({ environmentId, campaignId }))
      toast.success(i18n.t('campaigns.toasts.acceptedSuccess'))
      yield put(closeDialog())
    } else {
      throw new Error('environmentId or campaignId missing')
    }
  } catch (error) {
    yield put(acceptCampaignFail(error.message))
  }
}

function* handleDeleteCampaign({ payload: campaignId }: { payload: string }) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    yield call(Api.deleteCampaign, environmentId, campaignId)
    yield put(deleteCampaignSuccess(campaignId))
  } catch (error) {
    yield put(deleteCampaignFail(error.message))
  }
}

function* handleCopyCampaign({ payload: campaignId }: { payload: string }) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const newCampaign: Campaign = yield call(Api.copyCampaign, environmentId, campaignId)
    yield put(copyCampaignSuccess(newCampaign))
  } catch (error) {
    yield put(copyCampaignFail(error.message))
  }
}

function* handleGenerateCampaignXlsx({
  payload: { formData, resolve, reject }
}: handleGenerateCampaignExcelFormParams) {
  try {
    const { campaignId, type } = formData
    const getSubCampaigns = type === 'subCampaigns'
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const { name } = yield select(selectCampaignById(campaignId))
    const excels: { url: string; filename: string }[] = yield call(
      Api.getCampaignExcel,
      environmentId,
      campaignId,
      getSubCampaigns
    )

    const files: { blob: Blob; filename: string }[] = yield all(
      excels.map(({ url, filename }) => call(downloadFile, url, filename))
    )
    if (files.length > 1) {
      const zip = new JSZip()
      files.forEach(({ blob, filename }) => {
        zip.file(filename, blob)
      })
      yield call(generateAndDownloadZip, zip, `${name}.zip`)
    } else {
      const { blob, filename } = files[0]
      saveAs(blob, filename)
    }

    yield put(closeDialog())
    yield put(generateCampaignXlsxSuccess())
    resolve()
  } catch (error) {
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.campaign.excelGeneration')
      })
    )
    yield put(generateCampaignXlsxFail(error.message))
  }
}

function* watchCampaignsActions() {
  yield all([
    takeLatest(listCampaigns, handleListCampaigns),
    takeLatest(createCampaign, handleCreateCampaign),
    takeLatest(prefillCampaignProduct, handlePrefillCampaignProduct),
    takeLatest(updateCampaign, handleUpdateCampaign),
    takeLatest(updateCampaignTemplate, handleUpdateCampaignTemplate),
    takeLatest(getCampaign, handleGetCampaign),
    takeLatest(publishCampaign, handlePublishCampaign),
    takeLatest(acceptCampaign, handleAcceptCampaign),
    takeLatest(deleteCampaign, handleDeleteCampaign),
    takeLatest(copyCampaign, handleCopyCampaign),
    takeLatest(generateCampaignXlsx, handleGenerateCampaignXlsx)
  ])
}

export default [watchCampaignsActions]
