import { MenuItem, Theme, ListItemIcon } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import React, { Fragment } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { Field, formValueSelector, reduxForm, InjectedFormProps } from 'redux-form'
import CartIcon from 'mdi-react/CartIcon'
import ShoppingIcon from 'mdi-react/ShoppingIcon'
import FishIcon from 'mdi-react/FishIcon'
import FoodSteakIcon from 'mdi-react/FoodSteakIcon'
import SilverwareForkKnifeIcon from 'mdi-react/SilverwareForkKnifeIcon'
import dateFormat from 'dateformat'
import { ListType, TemplateType, Customer, AutocompleteOption } from '@seesignage/seesignage-utils'
import { selectCustomer } from '../../../actions/customers'
import { selectCustomersAsArray } from '../../../selectors/customers'
import { selectTemplatesByType, selectTemplatesAsArray } from '../../../selectors/templates'
import { required, isPriceValid } from '../../../validation'
import {
  ReduxTextField,
  ReduxSelectWithAutocomplete,
  ReduxDatePicker
} from '../../../components/FormInput/ReduxWrappers'
import { CloseDialog } from '../../../types/actions'
import { StateInterface } from '../../../types/states'
import { compareStrings } from '../../../utils/sorting'
import { CreateListFormData, UpdateListFormData } from '../../../types/lists'
import { selectEnvironmentIdFromPathname } from '../../../selectors/routing'
import SelectTemplate from '../../../components/FormInput/SelectTemplate'
import { StateTemplate } from '../../../types/templates'
import ErrorMessage from '../../../components/Errors/ErrorMessage'

const styles = (theme: Theme) => ({
  formContainer: {
    marginBottom: theme.spacing()
  },
  formControl: {
    width: 552,
    marginRight: 10,
    paddingBottom: 5
  },
  listItemText: {
    display: 'inline-block'
  }
})

export interface TemplateTypesObject {
  [templateType: string]: TemplateType
}

const selectAvailableListTypes = (templates: StateTemplate[]) => {
  const templateTypesObj = templates.reduce((typesObj, template) => {
    typesObj[template.type] = template.type
    return typesObj
  }, {} as TemplateTypesObject)
  const availableListTypes: ListType[] = []
  if (templateTypesObj[ListType.retail]) {
    availableListTypes.push(ListType.retail)
  }
  if (templateTypesObj[ListType.fish]) {
    availableListTypes.push(ListType.fish)
  }
  if (templateTypesObj[ListType.meat]) {
    availableListTypes.push(ListType.meat)
  }
  if (templateTypesObj[ListType.lunch]) {
    availableListTypes.push(ListType.lunch)
  }
  return availableListTypes
}

const selectAvailableCustomersAsOptions = (customers: Customer[], listType?: ListType) => {
  if (listType && customers.length > 0) {
    return customers // note: lunch does not exist in CustomerTableType
      .filter(({ productForm }) => productForm !== 'general')
      .map(({ customerId, name }) => ({ value: customerId, label: name }))
  }
  return []
}

const ListTypeIcon = ({ type }: { type: ListType }) => (
  <ListItemIcon>
    {type === ListType.fish && <FishIcon />}
    {type === ListType.meat && <FoodSteakIcon />}
    {type === ListType.retail && <ShoppingIcon />}
    {type === ListType.lunch && <SilverwareForkKnifeIcon />}
  </ListItemIcon>
)

interface OwnProps {
  submitAction: (formData: any) => void
  closeDialog: CloseDialog
  dialogId: string
  submitButtonLabel: string
  /** disable specific form choices when updating form */
  isUpdateForm?: boolean
}

interface StateProps {
  templates: StateTemplate[]
  customersAsOptions: AutocompleteOption[]
  selectedType?: ListType
  availableListTypes: ListType[]
  startDate: Date
  environmentId?: string
}

interface DispatchProps {
  selectCustomer: (customerId: string) => void
}

type ComponentProps = OwnProps & StateProps & DispatchProps

type CreateListFormProps = ComponentProps & WithStyles<typeof styles> & WithTranslation

const CreateListForm: React.FC<CreateListFormProps &
  InjectedFormProps<CreateListFormData | UpdateListFormData, ComponentProps>> = ({
  classes,
  handleSubmit,
  submitting,
  submitAction,
  closeDialog,
  dialogId,
  submitButtonLabel,
  templates,
  customersAsOptions,
  selectCustomer,
  selectedType,
  availableListTypes,
  isUpdateForm,
  startDate,
  error,
  t
}) => {
  // NOTE: only some specific list types require customer
  const requireCustomer =
    selectedType === ListType.fish ||
    selectedType === ListType.meat ||
    selectedType === ListType.retail

  return (
    <form onSubmit={handleSubmit(submitAction)}>
      <Grid container spacing={2} className={classes.formContainer}>
        {availableListTypes.length > 0 ? (
          <Fragment>
            <Grid item xs={12}>
              <Field
                fullWidth
                name='type'
                label={t('lists.type')}
                component={ReduxTextField}
                select
                disabled={isUpdateForm}
                required
                validate={[required]}>
                {availableListTypes.map(listType => (
                  <MenuItem key={listType} value={listType}>
                    <ListTypeIcon type={listType} />
                    <Typography variant='inherit'>{t(`lists.types.${listType}`)}</Typography>
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            {selectedType && (
              <Fragment>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    name='name'
                    label={t('lists.name')}
                    component={ReduxTextField}
                    required
                    validate={[required]}
                  />
                </Grid>
                {requireCustomer && (
                  <Grid item xs={12}>
                    <Field
                      name='customerId'
                      placeholder={t('product.selectCustomer')}
                      options={customersAsOptions}
                      disabled={isUpdateForm}
                      onChange={(customer: any) => {
                        customer && selectCustomer(customer.value)
                      }}
                      label={t('product.customer')}
                      OptionIcon={CartIcon}
                      component={ReduxSelectWithAutocomplete}
                      required
                      validate={[required]}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    name='template'
                    initialTemplates={templates}
                    component={SelectTemplate}
                    required
                    validate={[required]}
                  />
                </Grid>
                {selectedType === ListType.lunch && (
                  <Fragment>
                    <Grid item xs={12}>
                      <Field
                        name='startDate'
                        component={ReduxDatePicker}
                        label={t('lists.createListForm.startDate')}
                        // allow only mondays
                        shouldDisableDate={
                          (date: Date) => date !== null && date.getDay() !== 1
                          // note: Moment Sunday is 0 and Saturday 6
                        }
                        required
                        validate={[required]}
                        fullWidth
                        startOfTheDay
                      />
                      <Typography display='block' variant='caption'>
                        {t('lists.createListForm.startDateInfo')}
                      </Typography>
                      {startDate && (
                        <Typography display='block' variant='caption'>
                          {t('lists.createListForm.startDateInfoSelect', {
                            startDate: dateFormat(startDate, 'd.m.yyyy')
                          })}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        name='price'
                        label={t('product.price')}
                        type='number'
                        format={(value: number | undefined) => (value ? value / 100 : undefined)}
                        parse={(value: number | undefined) => (value ? value * 100 : undefined)}
                        component={ReduxTextField}
                        validate={[isPriceValid]}
                        helperText={t('lists.createListForm.priceInfo')}
                      />
                    </Grid>
                  </Fragment>
                )}
              </Fragment>
            )}
          </Fragment>
        ) : (
          <Typography>{t(`lists.types.templateMissing`)}</Typography>
        )}
        {submitting && <LinearProgress />}
        <ErrorMessage message={error} />
      </Grid>
      <DialogActions>
        <Button onClick={() => closeDialog(dialogId)} color='primary'>
          {t('general.cancel')}
        </Button>
        {availableListTypes.length > 0 && (
          <Button disabled={submitting} color='primary' type='submit'>
            {submitButtonLabel}
          </Button>
        )}
      </DialogActions>
    </form>
  )
}

const mapStateToProps = (state: StateInterface): StateProps => {
  const listType: ListType | undefined = formValueSelector('CreateListForm')(state, 'type')
  const startDate: Date = formValueSelector('CreateListForm')(state, 'startDate')
  return {
    templates: listType ? selectTemplatesByType(listType)(state).sort(compareStrings('name')) : [],
    customersAsOptions: selectAvailableCustomersAsOptions(
      selectCustomersAsArray(state),
      listType
    ).sort(compareStrings('label')),
    selectedType: listType,
    availableListTypes: selectAvailableListTypes(selectTemplatesAsArray(state)),
    startDate,
    environmentId: selectEnvironmentIdFromPathname(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  selectCustomer: (customerId: string) => dispatch(selectCustomer(customerId))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<CreateListFormData | UpdateListFormData, ComponentProps>({
    form: 'CreateListForm'
  })(withStyles(styles)(withTranslation()(CreateListForm)))
)
