import { Step, StepLabel, Stepper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { change } from 'redux-form'
import { AutocompleteOption } from '@seesignage/seesignage-utils'
import { TFunction } from 'i18next'
import { selectCustomersWithGeneralProductForm } from '../../../../selectors/customers'
import { selectTemplatesByType } from '../../../../selectors/templates'
import { selectIsUserDeviceMobile } from '../../../../selectors/users'
import { StateInterface } from '../../../../types/states'
import { PageType } from '../../../../types/playlists'
import { compareStrings } from '../../../../utils/sorting'
import { listTemplates } from '../../../../actions/templates'
import { StateTemplate } from '../../../../types/templates'
import AddProductDetails from './AddProductDetails'
import AddProductImage from './AddProductImage'
import SelectCustomerAndTemplate from './SelectCustomerAndTemplate'

const useStyles = makeStyles(() => ({
  stepper: {
    marginBottom: 10
  }
}))

interface OwnProps {
  onSubmit: (formData: any) => void
  submitButtonLabel: string
  initialValues?: any
  /** Back function exists only when Wizard is mounted from AddPlaylistItemWizard */
  backFunction?: (page: PageType) => void
  playlistItem?: boolean
  customerDisabled?: boolean
  /** Don't fetch templates again if mock product */
  isMockProduct?: boolean
}

interface StateProps {
  isMobile: boolean
  customers?: AutocompleteOption[]
  templates?: StateTemplate[]
}

interface DispatchProps {
  // destroyForm: () => void
  listTemplates: () => void
  change: (field: string, value: any) => void
}

type CreateProductWizardProps = OwnProps & StateProps & DispatchProps

const getSteps = (t: TFunction, playlistItem: boolean | undefined) =>
  playlistItem
    ? [t('product.selectTemplate'), t('product.productImage'), t('product.details')]
    : [t('product.productImage'), t('product.details')]

const CreateProductWizard: React.FC<CreateProductWizardProps> = ({
  isMobile,
  onSubmit,
  initialValues,
  submitButtonLabel,
  backFunction,
  playlistItem,
  customers,
  templates,
  customerDisabled,
  change,
  listTemplates,
  isMockProduct
}) => {
  const [t] = useTranslation()
  const classes = useStyles()
  const [page, setPage] = useState(playlistItem ? 0 : 1)
  const handleMountActions = () => {
    if (!isMockProduct) {
      listTemplates()
    }
    if (customers && customers.length === 1 && playlistItem) {
      change('customerId', customers[0])
    }
    if (templates && templates.length === 1 && playlistItem) {
      change('template', {
        templateId: templates[0].templateId,
        templateEnvironmentId: templates[0].environmentId
      })
    }
  }
  useEffect(handleMountActions, [])

  const nextPage = () => {
    setPage(page + 1)
  }

  const previousPage = () => {
    setPage(page - 1)
  }
  return (
    <div>
      <Stepper
        activeStep={playlistItem ? page : page - 1}
        alternativeLabel
        className={classes.stepper}>
        {getSteps(t, playlistItem).map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {playlistItem && page === 0 && (
        <SelectCustomerAndTemplate
          isMobile={isMobile}
          backFunction={backFunction}
          customers={customers}
          templates={templates}
          submitButtonLabel={t('general.next')}
          customerDisabled={customerDisabled}
          initialValues={initialValues}
          nextPage={nextPage}
          onSubmit={onSubmit}
          t={t}
        />
      )}
      {page === 1 && (
        <AddProductImage
          onSubmit={nextPage}
          goBack={playlistItem ? previousPage : undefined}
          initialValues={initialValues}
        />
      )}
      {page === 2 && (
        <AddProductDetails
          isMobile={isMobile}
          previousPage={previousPage}
          onSubmit={onSubmit}
          initialValues={initialValues}
          submitButtonLabel={submitButtonLabel}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state: StateInterface): StateProps => ({
  isMobile: selectIsUserDeviceMobile(state),
  customers: selectCustomersWithGeneralProductForm(state).map(({ name, customerId }) => ({
    label: name,
    value: customerId
  })),
  templates: selectTemplatesByType('retail')(state).sort(compareStrings('name'))
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  listTemplates: () => dispatch(listTemplates()),
  change: (field: string, value: any) => dispatch(change('AddProductWizardForm', field, value))
})

export default connect<StateProps, DispatchProps, OwnProps, StateInterface>(
  mapStateToProps,
  mapDispatchToProps
)(CreateProductWizard)
