import {
  Infopage,
  Template,
  InfopageStore,
  InfopageExtensive,
  ContentMetadataByKey
} from '@seesignage/seesignage-utils'
import { handleActions } from 'redux-actions'
import {
  copyInfopageSuccess,
  createInfopageSuccess,
  deleteInfopageSuccess,
  deselectAllInfopages,
  deselectInfopage,
  getInfopageSuccess,
  listInfopages,
  listInfopagesFail,
  listInfopagesSuccess,
  resetInfopages,
  selectInfopage,
  selectUnusedInfopages,
  updateInfopageKeysSuccess,
  updateInfopageSettingsSuccess,
  updateInfopageSuccess,
  selectInfopageTemplate,
  clearSelectedInfopageTemplate,
  initInfopageMetadata,
  setInfopagesInitialLoad,
  setSelectedInfopageType
} from '../actions/infopages'
import { Action } from '../types/actions'
import { IndexById, InfopagesState } from '../types/states'
import {
  SelectedInfopage,
  InfopagesLastEvaluatedKey,
  SelectedInfopageType
} from '../types/infopages'

const initialState: InfopagesState = {
  infopages: {},
  selectedInfopages: [],
  infopagesArray: [],
  infopageModified: false,
  isLoading: false,
  selectedTemplate: undefined,
  infopageKeys: undefined,
  isInitialLoad: true
}

const infopagesReducer = handleActions<InfopagesState, any>(
  {
    [resetInfopages]: () => {
      initialState.infopages = {} // For some reason infopages is not empty
      return initialState
    },
    [listInfopages]: (
      state,
      {
        payload: { resetSearch }
      }: Action<{ includePlaylistData?: boolean; searchTerm?: string; resetSearch?: boolean }>
    ) => {
      return {
        ...state,
        ...(resetSearch ? { infopagesArray: [], lastEvaluatedKey: undefined } : {}),
        isLoading: true
      }
    },
    [listInfopagesFail]: state => ({
      ...state,
      isLoading: false
    }),
    [listInfopagesSuccess]: (
      state,
      {
        payload: { infopages: infopagesArray, lastEvaluatedKey }
      }: Action<{
        infopages: Infopage[]
        lastEvaluatedKey: InfopagesLastEvaluatedKey
      }>
    ) => {
      const infopages = infopagesArray.reduce((infopages: IndexById<Infopage>, infopage) => {
        infopages[infopage.infopageId] = {
          ...infopage
        }
        return infopages
      }, state.infopages)
      return {
        ...state,
        infopages,
        infopagesArray: [...state.infopagesArray, ...infopagesArray],
        lastEvaluatedKey,
        isLoading: false
      }
    },
    [createInfopageSuccess]: (state, { payload: infopage }: Action<InfopageStore>) => ({
      ...state,
      infopages: {
        ...state.infopages,
        [infopage.infopageId]: infopage
      }
    }),
    [deleteInfopageSuccess]: (state, { payload: infopageId }: Action<string>) => {
      const infopages = { ...state.infopages }
      delete infopages[infopageId]
      return {
        ...state,
        infopages,
        infopagesArray: state.infopagesArray.filter(i => i.infopageId !== infopageId)
      }
    },
    [updateInfopageKeysSuccess]: (state, { payload: keys }: Action<ContentMetadataByKey>) => {
      return {
        ...state,
        infopageKeys: keys
      }
    },
    [getInfopageSuccess]: (state, { payload: infopage }: Action<InfopageExtensive>) => ({
      ...state,
      infopages: {
        ...state.infopages,
        [infopage.infopageId]: infopage
      },
      infopageKeys: infopage.contentMetadata
    }),
    [updateInfopageSuccess]: (state, { payload: infopage }: Action<InfopageExtensive>) => ({
      ...state,
      infopages: {
        ...state.infopages,
        [infopage.infopageId]: infopage
      },
      infopageModified: false
    }),
    [updateInfopageSettingsSuccess]: (
      state,
      {
        payload: { infopageId, name, templateId, template }
      }: Action<{ infopageId: string; name: string; templateId: string; template: Template }>
    ) => ({
      ...state,
      infopages: {
        ...state.infopages,
        [infopageId]: {
          ...state.infopages[infopageId],
          name,
          templateId,
          template,
          templateEnvironmentId: template.environmentId
        }
      },
      infopageModified: true
    }),
    [selectUnusedInfopages]: (state, { payload }: Action<SelectedInfopage[]>) => ({
      ...state,
      selectedInfopages: payload
    }),
    [selectInfopage]: (state, { payload }: Action<SelectedInfopage>) => ({
      ...state,
      selectedInfopages: [...state.selectedInfopages, payload]
    }),
    [deselectInfopage]: (state, { payload }: Action<string>) => {
      const infopages = state.selectedInfopages
      const removed = infopages.filter(({ id }) => id !== payload)
      return {
        ...state,
        selectedInfopages: removed
      }
    },
    [deselectAllInfopages]: state => ({
      ...state,
      selectedInfopages: []
    }),
    [copyInfopageSuccess]: (
      state,
      {
        payload: { newInfopage: infopage, sourceInfopageId }
      }: Action<{ newInfopage: Infopage; sourceInfopageId: string }>
    ) => {
      const sourceIndex = state.infopagesArray.findIndex(i => i.infopageId === sourceInfopageId)
      // add new copied copied infopage to array after source infopage id
      state.infopagesArray.splice(sourceIndex + 1, 0, infopage)
      return {
        ...state,
        infopages: {
          ...state.infopages,
          [infopage.infopageId]: infopage
        }
      }
    },
    [selectInfopageTemplate]: (state, { payload: selectedTemplate }: Action<Template>) => ({
      ...state,
      selectedTemplate
    }),
    [clearSelectedInfopageTemplate]: state => ({
      ...state,
      selectedTemplate: undefined
    }),
    [initInfopageMetadata]: (state, { payload: infopageKeys }: Action<ContentMetadataByKey>) => ({
      ...state,
      infopageKeys
    }),
    [setSelectedInfopageType]: (
      state,
      { payload: selectedInfopageType }: Action<SelectedInfopageType>
    ) => ({
      ...state,
      selectedInfopageType
    }),
    [setInfopagesInitialLoad]: state => ({
      ...state,
      isInitialLoad: false
    })
  },
  initialState
)

export default infopagesReducer
