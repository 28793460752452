import { AppBar, IconButton, Theme, Toolbar, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ChannelItemPriority } from '@seesignage/seesignage-utils'
import PlaylistIcon from 'mdi-react/PlaylistPlusIcon'
import moment from 'moment'
import React from 'react'
import InfoIcon from 'mdi-react/InfoCircleIcon'
import { useTranslation } from 'react-i18next'
import SyncIcon from 'mdi-react/RayStartVertexEndIcon'
import { CreateChannelItem, ForceRunTour } from '../../../../types/actions'
import { Tour } from '../../../../types/tours'
import Dialog from '../../../Dialog'
import CreateChannelItemForm from '../../Forms/CreateChannelItemForm'
import colors from '../../../../styles/common/colors'

const styles = (theme: Theme) => ({
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    color: theme.palette.text.secondary,
    marginRight: 4,
    display: 'flex'
  },
  appBar: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14)',
    top: 56,
    [theme.breakpoints.up('sm')]: {
      top: 64
    }
  },
  syncPlayIcon: {
    margin: 8,
    paddingTop: 4
  }
})

const useStyles = makeStyles(styles)

interface EditChannelToolbarProps {
  createItem: CreateChannelItem
  forceRunTour: ForceRunTour
  syncPlay?: boolean
  /** if user has write access to publishing channel */
  hasWriteAccess: boolean
}

const EditChannelToolbar: React.FC<EditChannelToolbarProps> = ({
  createItem,
  forceRunTour,
  syncPlay,
  hasWriteAccess
}) => {
  const classes = useStyles()
  const [t] = useTranslation()
  return (
    <AppBar className={classes.appBar} position='sticky' color='default'>
      <Toolbar>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          {syncPlay && (
            <Tooltip disableInteractive title={t('channels.card.syncPlay')}>
              <span className={classes.syncPlayIcon}>
                <SyncIcon color={colors.seesignageColor} />
              </span>
            </Tooltip>
          )}
          <Tooltip disableInteractive title={t('tours.runTour')}>
            <IconButton onClick={() => forceRunTour(Tour.createChannelItem)} size='large'>
              <InfoIcon />
            </IconButton>
          </Tooltip>
          <Dialog
            dialogId='createChannelItemToolbar'
            title={t('channels.actions.createChannelItem')}
            tooltipTitle={
              hasWriteAccess
                ? t('channels.actions.createChannelItem')
                : t('channels.actions.createChannelItemNoPermission')
            }
            ButtonIcon={PlaylistIcon}
            buttonId='create-channel-item-button'
            buttonDisabled={!hasWriteAccess}
            Content={
              <CreateChannelItemForm
                name='CreateChannelItemForm'
                submitButtonLabel={t('general.save')}
                dialogId='createChannelItemToolbar'
                onSubmit={createItem}
                initialValues={{
                  channelItem: {
                    start: moment()
                      .startOf('day')
                      .toDate(),
                    end: moment()
                      .endOf('day')
                      .toDate(),
                    priority: ChannelItemPriority.low
                  },
                  playlist: {
                    defaultInterval: 7,
                    syncPlay
                  }
                }}
              />
            }
          />
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default EditChannelToolbar
