import { Button, DialogActions, Grid, Theme, Typography } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import MagnifyIcon from 'mdi-react/MagnifyIcon'
import React, { useEffect } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { Field, formValueSelector, reduxForm, change, InjectedFormProps } from 'redux-form'
import { AutocompleteOption, Customer, ProductForm } from '@seesignage/seesignage-utils'
import { getProducts } from '../../../../actions/products'
import { selectCustomersWithProductForm, selectCustomerById } from '../../../../selectors/customers'
import { selectProductsAsOptions, selectIsProductsSearching } from '../../../../selectors/products'
import { selectTemplatesByType } from '../../../../selectors/templates'
import { selectIsUserDeviceMobile } from '../../../../selectors/users'
import { required } from '../../../../validation'
import ProductDetails from '../../../Products/ProductDetails'
import { SearchProductByTermParams } from '../../../../types/products'
import { StateInterface } from '../../../../types/states'
import { ReduxSelectWithAutocomplete } from '../../../../components/FormInput/ReduxWrappers'
import { PageType } from '../../../../types/playlists'
import { compareStrings } from '../../../../utils/sorting'
import SelectTemplate from '../../../../components/FormInput/SelectTemplate'
import SearchDrugForm from '../../../Products/Pharmacy/SearchDrugForm'
import { StateTemplate } from '../../../../types/templates'

const styles = (theme: Theme) => ({
  formContainer: {
    marginBottom: theme.spacing()
  },
  selectProductContainer: {
    marginTop: theme.spacing(2)
  },
  dialogActionsMobile: {
    justifyContent: 'center'
  }
})

interface OwnProps {
  submitAction: (formData: any) => void
  selectPage: (page: PageType) => void
}

interface StateProps {
  suggestions: AutocompleteOption[]
  customers: Customer[]
  selectedCustomer?: Customer
  templates: StateTemplate[]
  selectedProduct?: string
  customerId: AutocompleteOption
  template: string
  isMobile: boolean
  searchingProducts: boolean
}

interface DispatchProps {
  getProducts: (params: SearchProductByTermParams) => void
  changeValue: (field: string, value: any) => void
}

type ComponentProps = OwnProps & StateProps & DispatchProps

type AddProductAsItemFormProps = WithStyles<typeof styles> & WithTranslation & ComponentProps

const AddProductAsItemForm: React.FC<AddProductAsItemFormProps &
  InjectedFormProps<{}, ComponentProps>> = ({
  classes,
  handleSubmit,
  submitAction,
  selectPage,
  suggestions,
  customers,
  templates,
  getProducts,
  selectedProduct,
  selectedCustomer,
  template,
  searchingProducts,
  isMobile,
  changeValue,
  submitting,
  error,
  t
}) => {
  const hasGeneralCustomers = customers.find(
    ({ productForm }) => productForm === ProductForm.general
  )
    ? true
    : false
  const handleMountActions = () => {
    // auto select customer if only one available
    if (customers.length === 1) {
      changeValue('customerId', { value: customers[0].customerId, label: customers[0].name })
    }
    // auto select template if only one available
    if (templates.length === 1) {
      changeValue('template', {
        templateId: templates[0].templateId,
        templateEnvironmentId: templates[0].environmentId
      })
    }
  }
  useEffect(handleMountActions, [])
  return (
    <form onSubmit={handleSubmit(submitAction)}>
      <Grid container className={classes.formContainer} spacing={2}>
        <Grid item xs={12}>
          <Field
            name='customerId'
            placeholder={t('product.selectCustomer')}
            label={t('product.customer')}
            options={customers.map(({ customerId, name }) => ({ label: name, value: customerId }))}
            component={ReduxSelectWithAutocomplete}
            required
            validate={[required]}
            disabled={customers.length === 1}
          />
        </Grid>
        {selectedCustomer && (
          <Grid item xs={12}>
            <Field
              name='template'
              initialTemplates={templates}
              component={SelectTemplate}
              required
              validate={[required]}
            />
          </Grid>
        )}
        {template && (
          <Grid item xs={12}>
            {selectedCustomer &&
              (selectedCustomer.productForm === ProductForm.pharmacy ? (
                <SearchDrugForm
                  changeValue={changeValue}
                  customerId={selectedCustomer?.customerId}
                />
              ) : (
                <Field
                  name='product'
                  label={t('general.product')}
                  placeholder={t('general.search')}
                  options={suggestions}
                  component={ReduxSelectWithAutocomplete}
                  loading={searchingProducts}
                  onTextFieldInputChangeFunction={(input: string) => {
                    getProducts({ input, loadUrls: true })
                  }}
                  required
                  validate={[required]}
                  popupIcon={<MagnifyIcon />}
                />
              ))}
          </Grid>
        )}
        {selectedProduct && <ProductDetails product={JSON.parse(selectedProduct)} />}
        {error && (
          <Typography variant='caption' color='error'>
            {error}
          </Typography>
        )}
      </Grid>
      <DialogActions className={isMobile ? classes.dialogActionsMobile : undefined}>
        <Button
          onClick={() =>
            selectPage(hasGeneralCustomers ? PageType.productType : PageType.selectType)
          }
          color='primary'>
          {t('general.back')}
        </Button>
        <Button disabled={submitting || !template} color='primary' type='submit'>
          {t('general.add')}
        </Button>
      </DialogActions>
    </form>
  )
}

const mapStateToProps = (state: StateInterface): StateProps => {
  const customerId = formValueSelector('AddProductAsItemForm')(state, 'customerId')
  const template = formValueSelector('AddProductAsItemForm')(state, 'template')
  const selectedProduct = formValueSelector('AddProductAsItemForm')(state, 'product')
  return {
    customerId,
    template,
    selectedProduct: selectedProduct ? selectedProduct.value : undefined,
    customers: selectCustomersWithProductForm(state).sort(compareStrings('name')),
    selectedCustomer: selectCustomerById(customerId?.value)(state),
    templates: selectTemplatesByType('retail')(state).sort(compareStrings('name')),
    suggestions: selectProductsAsOptions(state),
    searchingProducts: selectIsProductsSearching(state),
    isMobile: selectIsUserDeviceMobile(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  getProducts: (params: SearchProductByTermParams) => dispatch(getProducts(params)),
  changeValue: (field: string, value: any) => dispatch(change('AddProductAsItemForm', field, value))
})

export default connect<StateProps, DispatchProps, OwnProps, StateInterface>(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<{}, OwnProps & StateProps & DispatchProps>({
    form: 'AddProductAsItemForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
  })(withTranslation()(withStyles(styles)(AddProductAsItemForm)))
)
