import React from 'react'
import { Template } from '@seesignage/seesignage-utils'
import { InjectedFormProps, Field, reduxForm, formValueSelector } from 'redux-form'
import { DialogActions, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import SelectTemplate from '../../../../components/FormInput/SelectTemplate'
import { CloseDialog } from '../../../../types/actions'
import { StateInterface } from '../../../../types/states'
import { selectTemplatesByType } from '../../../../selectors/templates'
import { bindSubmitActionToPromise } from '../../../../utils/forms'
import { updateProductItemsTemplate } from '../../../../actions/playlists'
import { closeDialog } from '../../../../actions/dialogs'
import ErrorMessage from '../../../../components/Errors/ErrorMessage'
import { TemplateAsFormValue } from '../../../../types/templates'

interface OwnProps {
  dialogId: string
}

interface StateProps {
  selectedTemplate?: TemplateAsFormValue
  templates: Template[]
}

interface DispatchProps {
  updateItemsTemplate: (formData: any) => void
  closeDialog: CloseDialog
}

type SelectItemTemplateFormProps = OwnProps & StateProps & DispatchProps

const SelectItemTemplateForm: React.FC<SelectItemTemplateFormProps &
  InjectedFormProps<{}, SelectItemTemplateFormProps>> = ({
  dialogId,
  selectedTemplate,
  templates,
  closeDialog,
  updateItemsTemplate,
  handleSubmit,
  error
}) => {
  const [t] = useTranslation()
  return (
    <form onSubmit={handleSubmit(updateItemsTemplate)}>
      <Field name='template' component={SelectTemplate} initialTemplates={templates} defaultOpen />
      <DialogActions>
        <Button onClick={() => closeDialog(dialogId)} color='primary'>
          {t('general.cancel')}
        </Button>
        {error && <ErrorMessage message={error} />}
        <Button color='primary' disabled={!selectedTemplate} type='submit'>
          {t('general.update')}
        </Button>
      </DialogActions>
    </form>
  )
}

const mapStateToProps = (state: StateInterface): StateProps => ({
  selectedTemplate: formValueSelector('SelectItemTemplateForm')(state, 'template'),
  templates: selectTemplatesByType('retail')(state) as Template[]
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  updateItemsTemplate: bindSubmitActionToPromise(dispatch, updateProductItemsTemplate),
  closeDialog: (dialogId: string) => dispatch(closeDialog(dialogId))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<{}, SelectItemTemplateFormProps>({
    form: 'SelectItemTemplateForm'
  })(SelectItemTemplateForm)
)
