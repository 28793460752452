import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { ScreenResponse } from '@seesignage/seesignage-utils'
import { IconButton, Tooltip } from '@mui/material'
import MonitorIcon from 'mdi-react/MonitorIcon'
import { useTranslation } from 'react-i18next'

interface SelectedScreenActionsProps {
  screen: ScreenResponse
}

const SelectedScreenActionsDashboard: React.FC<SelectedScreenActionsProps> = ({
  screen: { code }
}) => {
  const [t] = useTranslation()
  // TODO: in the future use screenId and view (list/grid) as QPs in screens view.
  return (
    <Fragment>
      <Tooltip disableInteractive title={t('dashboard.screens.actions.navigateToScreen')}>
        <Link style={{ textDecoration: 'none', color: 'inherit' }} to={`/s/${code}`}>
          <IconButton aria-label='edit playlist' size='large'>
            <MonitorIcon />
          </IconButton>
        </Link>
      </Tooltip>
    </Fragment>
  )
}

export default SelectedScreenActionsDashboard
