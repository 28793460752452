import React, { useState, Fragment } from 'react'
import {
  AppBar,
  Toolbar,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  TablePagination,
  Typography,
  Accordion,
  AccordionSummary,
  Grid
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { isMobile } from 'react-device-detect'
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form'
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon'
import { useTranslation } from 'react-i18next'
import ChevronDownIcon from 'mdi-react/ChevronDownIcon'
import PaletteAdvancedIcon from 'mdi-react/PaletteAdvancedIcon'
import SearchField from '../../SearchField'
import { toolbarStyles } from '../../../styles/common'
import templateCardImage from '../../../containers/Templates/templateCard.jpg'
import { TemplateAsFormValue, StateTemplate } from '../../../types/templates'
import SimpleExtensionPanel from '../../ExtensionPanels/SimpleExtensionPanel'
import SupportCards from '../../../containers/Support/SupportCards'

const useStyles = makeStyles(theme => ({
  ...toolbarStyles(theme),
  gridContainer: {
    paddingTop: 8
  },
  gridList: {
    width: '100%',
    maxHeight: 500,
    overflow: 'auto'
  },
  selectedTemplateContainer: {
    padding: 4,
    textAlign: 'center'
  },
  accodrionIcon: {
    marginRight: 8
  },
  errorText: {
    marginLeft: 5
  },
  imageListItem: {
    cursor: 'pointer',
    // overwrite material-ui's list item's image elment
    '& .MuiImageListItem-img': {
      display: 'block',
      margin: 'auto',
      height: '100%',
      width: '100%',
      objectFit: 'contain'
    }
  }
}))

interface SelectTemplateProps {
  initialTemplates: StateTemplate[]
  /** If wrapped in Field */
  input?: WrappedFieldInputProps
  /** If wrapped in Field */
  meta?: WrappedFieldMetaProps
  selectedTemplate?: TemplateAsFormValue | null
  selectTemplate: (value: TemplateAsFormValue | null) => void
  /** Don't show thumbnail of selected Template*/
  showOnlySelectedName?: boolean
  /** Accordion is open by default */
  defaultOpen?: boolean
  disabled?: boolean
}

/**
 * Media grid like Selecting component for Templates
 * Can be used as Redux Field component or just regular selection.
 * @param param0
 */
const SelectTemplate: React.FC<SelectTemplateProps> = ({
  initialTemplates,
  input,
  meta,
  disabled,
  selectedTemplate,
  selectTemplate,
  showOnlySelectedName,
  defaultOpen
}) => {
  const [t] = useTranslation()
  const classes = useStyles()
  const [rowsPerPage, setRowsPerPage] = useState(15)
  const [page, setPage] = React.useState(0)
  const [searchTerm, setSearchTerm] = React.useState('')
  const [expanded, setExpanded] = useState(defaultOpen || false)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const style = disabled
    ? {
        color: 'rgba(0, 0, 0, 0.38)',
        cursor: 'default'
      }
    : undefined

  const handleChange = (value: TemplateAsFormValue | null) => {
    if (disabled) {
      return
    }
    if (input) {
      input.onChange(value)
    } else if (selectTemplate) {
      selectTemplate(value)
    }
  }
  const value = input?.value || selectedTemplate
  const currentTemplateId = value ? value.templateId : undefined
  const currentTemplate = currentTemplateId
    ? initialTemplates.find(({ templateId }) => templateId === currentTemplateId)
    : undefined
  const templates = initialTemplates.filter(template =>
    template.name
      .toLocaleLowerCase()
      .normalize()
      .includes(searchTerm.toLocaleLowerCase().normalize())
  )
  return currentTemplate ? (
    showOnlySelectedName ? (
      <Accordion style={style} expanded={false} onChange={() => handleChange(null)}>
        <AccordionSummary expandIcon={<CloseCircleOutlineIcon />}>
          <PaletteAdvancedIcon className={classes.accodrionIcon} />
          <Typography>{currentTemplate.name}</Typography>
        </AccordionSummary>
      </Accordion>
    ) : (
      <div style={style} className={classes.selectedTemplateContainer}>
        <Typography variant='caption'>{t('templates.template')}</Typography>
        <ImageList className={classes.gridList}>
          <ImageListItem className={classes.imageListItem}>
            <img src={currentTemplate.thumbnailUrl || templateCardImage} alt='' />
            <ImageListItemBar
              title={currentTemplate.name}
              position='top'
              actionIcon={
                <IconButton
                  onClick={() => {
                    handleChange(null)
                    setExpanded(true)
                  }}
                  size='large'>
                  <CloseCircleOutlineIcon color='white' />
                </IconButton>
              }
            />
          </ImageListItem>
        </ImageList>
      </div>
    )
  ) : (
    <Fragment>
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary expandIcon={<ChevronDownIcon />}>
          <Typography display='block' variant='body1'>
            {t('templates.select')}
          </Typography>
          {meta?.error && (
            <Typography
              display='block'
              variant='caption'
              color='error'
              className={classes.errorText}>
              {`*${meta.error}`}
            </Typography>
          )}
        </AccordionSummary>
        <AppBar className={classes.appBar} position='sticky' color='default'>
          <Toolbar>
            <SearchField
              placeholder={t('general.search')}
              onChange={e => setSearchTerm(e.target.value)}
            />
            <Typography variant='body1'></Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.gridContainer}>
          <ImageList className={classes.gridList} rowHeight={isMobile ? 125 : 175} cols={2}>
            {templates
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(({ name, thumbnailUrl, templateId, environmentId }) => (
                <ImageListItem
                  key={templateId}
                  className={classes.imageListItem}
                  onClick={() =>
                    handleChange({ templateId, templateEnvironmentId: environmentId })
                  }>
                  <img src={thumbnailUrl || templateCardImage} alt='' />
                  <ImageListItemBar title={name} position='top' />
                </ImageListItem>
              ))}
          </ImageList>
        </div>
        <TablePagination
          rowsPerPageOptions={[15, 25, 35]}
          component='div'
          count={templates.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage={t('templates.templatesPerPage')}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Accordion>
      <SimpleExtensionPanel title={t('templates.orderNewTemplateTitle')} isOpen={false}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant='body1'>{t('templates.orderNewTemplateDescription')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <SupportCards t={t} />
          </Grid>
        </Grid>
      </SimpleExtensionPanel>
    </Fragment>
  )
}

export default SelectTemplate
