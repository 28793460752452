import { all, takeLatest, put, select, call } from 'redux-saga/effects'
import { Customer, ListType } from '@seesignage/seesignage-utils'
import { change, formValueSelector, SubmissionError } from 'redux-form'
import {
  getCustomers,
  getCustomersSuccess,
  getCustomersFail,
  selectCustomer,
  getCustomersAndProducts,
  listAllCustomers,
  listAllCustomersSuccess,
  listAllCustomersFail,
  deleteCustomerFail,
  deleteCustomerSuccess,
  deleteCustomer,
  createCustomer,
  createCustomerFail,
  createCustomerSuccess,
  updateCustomer,
  updateCustomerFail,
  updateCustomerSuccess,
  removeIntegrationFromForm,
  deselectCustomerRow
} from '../actions/customers'
import { selectEnvironmentIdFromPathname } from '../selectors/routing'
import Api from '../services/api/customers'
import { listProducts } from '../actions/products'
import { closeDialog, openDialog } from '../actions/dialogs'
import { selectCustomerProductsType } from '../selectors/products'
import {
  selectSelectedCustomerId,
  selectCustomerByIdFromAllCustomers
} from '../selectors/customers'
import { GetCustomers } from '../types/actions'
import {
  handleCreateCustomerFormParams,
  handleUpdateCustomerFormParams,
  handleDeleteCustomerFormParams
} from '../types/formData'
import i18n from '../translations/i18n'
import {
  convertCreateCustomerFormValuesForBackend,
  convertUpdateCustomerFormValuesForBackend
} from '../utils/customers'

function* getCustomerProducts(environmentId: string, customer: Customer) {
  const tables = Object.keys(customer.tables || {})
  if (tables.length === 1) {
    const type = tables[0]
    const { customerId } = customer
    yield put(listProducts({ environmentId, customerId, type }))
  }
}

function* handleGetCustomers({ payload: payloadEnvironmentId }: GetCustomers) {
  try {
    const environmentIdPath: string | undefined = yield select(selectEnvironmentIdFromPathname)
    const environmentId: string | undefined = payloadEnvironmentId || environmentIdPath
    const customers: Customer[] = environmentId ? yield call(Api.getCustomers, environmentId) : []
    yield put(getCustomersSuccess(customers))
  } catch (error) {
    yield put(getCustomersFail(error.message))
  }
}

/** Only System admin can list all customers. */
function* handleListAllCustomers() {
  try {
    const customers: Customer[] = yield call(Api.listAllCustomers)
    yield put(listAllCustomersSuccess(customers))
  } catch (error) {
    yield put(listAllCustomersFail(error.message))
  }
}

function* handleGetCustomersAndProducts() {
  try {
    const environmentId: string | undefined = yield select(selectEnvironmentIdFromPathname)
    const selectedCustomer: string | undefined = yield select(selectSelectedCustomerId)
    const selectedProductType: ListType | undefined = yield select(selectCustomerProductsType)
    // customer and type has been previously selected or set
    if (selectedCustomer && selectedProductType) {
      yield put(
        listProducts({
          environmentId,
          customerId: selectedCustomer,
          type: selectedProductType
        })
      )
    } else if (environmentId) {
      const customers: Customer[] = yield call(Api.getCustomers, environmentId)
      // environment has only one customer
      if (customers.length === 1) {
        yield put(selectCustomer(customers[0].customerId))
        const tableTypes = Object.keys(customers[0].tables || {})
        // and only one type = can load the products without user selection
        if (tableTypes.length === 1) {
          yield call(getCustomerProducts, environmentId, customers[0])
          // force selection of product type
        } else {
          yield put(openDialog('SelectCustomerDialog'))
        }
        // force selection of customer and type
      } else {
        yield put(openDialog('SelectCustomerDialog'))
      }
      yield put(getCustomersSuccess(customers))
    }
  } catch (error) {
    yield put(getCustomersFail(error.message))
  }
}

function* handleDeleteCustomer({
  payload: { formData, resolve, reject }
}: handleDeleteCustomerFormParams) {
  try {
    const { environmentId, customerId, confirmationName } = formData
    const customer: Customer | undefined = yield select(
      selectCustomerByIdFromAllCustomers(customerId)
    )
    if (customer && customer.name === confirmationName) {
      yield call(Api.deleteCustomer, environmentId, customerId)
      yield put(deleteCustomerSuccess(customerId))
      yield put(deselectCustomerRow())
      yield put(closeDialog())
      resolve()
    } else {
      throw new SubmissionError({
        _error: i18n.t('error.customer.deleteConfirmFail')
      })
    }
  } catch (error) {
    if (error instanceof SubmissionError) {
      yield call(reject, error)
    } else {
      yield call(
        reject,
        new SubmissionError({
          _error: i18n.t('error.customer.somethingWrongDelete')
        })
      )
    }
    yield put(deleteCustomerFail(error.message))
  }
}

function* handleCreateCustomer({
  payload: { formData, resolve, reject }
}: handleCreateCustomerFormParams) {
  try {
    const { environmentId } = formData
    const customer: Customer = yield call(
      Api.createCustomer,
      environmentId.value,
      convertCreateCustomerFormValuesForBackend(formData)
    )
    yield put(createCustomerSuccess(customer))
    yield put(closeDialog())
    resolve()
  } catch (error) {
    yield put(createCustomerFail(error.message))
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.customer.somethingWrongCreate')
      })
    )
  }
}

function* handleUpdateCustomer({
  payload: { formData, resolve, reject }
}: handleUpdateCustomerFormParams) {
  try {
    const { environmentId, customerId } = formData
    const customer: Customer = yield call(
      Api.updateCustomer,
      environmentId.value,
      customerId,
      convertUpdateCustomerFormValuesForBackend(formData)
    )
    yield put(updateCustomerSuccess(customer))
    yield put(closeDialog())
    resolve()
  } catch (error) {
    yield put(updateCustomerFail(error.message))
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.customer.somethingWrongUpdate')
      })
    )
  }
}

function* handleRemoveIntegrationFromForm({ payload: integration }: { payload: string }) {
  const integrationProps: { [key: string]: any } = yield select(
    formValueSelector('CreateCustomerForm'),
    'integration'
  )
  if (integrationProps[integration]) {
    delete integrationProps[integration]
    yield put(change('CreateCustomerForm', 'integration', integrationProps))
  }
}

function* watchTemplatesActions() {
  yield all([
    takeLatest(getCustomers, handleGetCustomers),
    takeLatest(listAllCustomers, handleListAllCustomers),
    takeLatest(getCustomersAndProducts, handleGetCustomersAndProducts),
    takeLatest(deleteCustomer, handleDeleteCustomer),
    takeLatest(createCustomer, handleCreateCustomer),
    takeLatest(updateCustomer, handleUpdateCustomer),
    takeLatest(removeIntegrationFromForm, handleRemoveIntegrationFromForm)
  ])
}

export default [watchTemplatesActions]
