import {
  ListPlaylistItemUI,
  EnvironmentFeatures,
  FeaturePermission,
  FeaturePermissionLevel,
  TemplateType,
  ListPlaylistItemPreviewPlayer
} from '@seesignage/seesignage-utils'
import { TFunction } from 'i18next'
import { path } from 'ramda'
import { UserInterface } from '../types/users'
import { validateUserAccess } from './permissions'

const generateListContainsText = (item: ListPlaylistItemUI, t: TFunction) => {
  const type: string | undefined = path(['list', 'type'], item)
  const itemsCount: number | undefined = path(['list', 'items', 'length'], item)
  const weeksCount: number | undefined = path(['list', 'weeks', 'length'], item)
  return type === 'retail' || type === 'fish' || type === 'meat'
    ? itemsCount
      ? t('lists.listContains', {
          count: itemsCount
        })
      : t('lists.listEmpty')
    : weeksCount
    ? t('lists.lunch.listContains', {
        count: weeksCount
      })
    : t('lists.listEmpty')
}

const generatePreviewPlayerListContainsText = (
  { products, lunchListItems }: ListPlaylistItemPreviewPlayer,
  t: TFunction
) => {
  const itemsCount = products?.length || lunchListItems?.length
  if (itemsCount) {
    return itemsCount > 0
      ? t('lists.listContains', {
          count: itemsCount
        })
      : t('lists.listEmpty')
  }
  return ''
}

const isListTemplateType = (templateType: TemplateType) => {
  const listTemplateTypes = [
    TemplateType.retail,
    TemplateType.lunch,
    TemplateType.fish,
    TemplateType.meat
  ]
  return listTemplateTypes.includes(templateType)
}

const isListsFeatureEnabled = (
  user?: UserInterface,
  environmentFeatures?: EnvironmentFeatures | null,
  environmentTemplateTypes?: TemplateType[]
) => {
  // Render item only when list templates are found because without list templates user can't do anything in lists view.
  if (user && environmentTemplateTypes && environmentTemplateTypes.length > 0) {
    const templateTypeFound = environmentTemplateTypes.some(isListTemplateType)
    const userHasAccessToLists =
      templateTypeFound &&
      validateUserAccess(user, FeaturePermission.lists, FeaturePermissionLevel.read)

    if (typeof environmentFeatures?.lists === 'undefined') {
      // Note: Old environments that use lists do not have environmentFeatures.lists variable.
      // So if it does not exist, do not check if lists environment feature is true.
      return userHasAccessToLists
    }

    return userHasAccessToLists && environmentFeatures.lists
  }
  return false
}

export { generateListContainsText, generatePreviewPlayerListContainsText, isListsFeatureEnabled }
