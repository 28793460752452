import React, { ComponentType } from 'react'
import { TFunction } from 'i18next'
import { Grid, Card, CardActionArea, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { MdiReactIconProps } from 'mdi-react'
import EmailIcon from 'mdi-react/EmailIcon'
import PhoneIcon from 'mdi-react/PhoneIcon'
import ChatIcon from 'mdi-react/ChatIcon'
import colors from '../../styles/common/colors'
import { maximizeTawkWidget } from '../../config/tawk'

interface SupportCardProps {
  classes: Record<string, string>
  text: string
  Icon: ComponentType<MdiReactIconProps>
  onClick: () => void
}

const SupportCard: React.FC<SupportCardProps> = ({ classes, text, Icon, onClick }) => (
  <Card className={classes.card}>
    <CardActionArea className={classes.cardActionArea} onClick={onClick}>
      <Icon size={32} color={colors.seesignageColor} />
      <Typography variant='caption'>{text}</Typography>
    </CardActionArea>
  </Card>
)

const useStyles = makeStyles(() => ({
  root: {
    padding: 10,
    textAlign: 'center' as any
  },
  card: {
    padding: 8,
    height: 100,
    width: 170
  },
  cardActionArea: {
    display: 'flex' as any,
    flexDirection: 'column' as any,
    height: '100%'
  }
}))

const SupportCards: React.FC<{
  t: TFunction
  chatVisible?: boolean
}> = ({ t, chatVisible }) => {
  const classes = useStyles()
  return (
    <Grid
      className={classes.root}
      container
      spacing={2}
      alignContent='center'
      alignItems='center'
      justifyContent='center'>
      <Grid item>
        <SupportCard
          classes={classes}
          Icon={EmailIcon}
          text={t('support.email')}
          onClick={() => {
            window.location.href = `mailto:${t('support.email')}`
          }}
        />
      </Grid>
      <Grid item>
        <SupportCard
          classes={classes}
          Icon={PhoneIcon}
          text={t('support.phone')}
          onClick={() => {
            window.location.href = `tel:${t('support.phone')}`
          }}
        />
      </Grid>
      {chatVisible && (
        <Grid item>
          <SupportCard
            classes={classes}
            Icon={ChatIcon}
            text={'Chat'}
            onClick={() => {
              maximizeTawkWidget()
            }}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default SupportCards
