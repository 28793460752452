import { Grid } from '@mui/material'
import React from 'react'
import { Field } from 'redux-form'
import FieldsExpansionPanel from '../../../../../components/ExtensionPanels/FieldsExpansionPanel'
import MenuField from '../../../../../components/MenuField'
import cssProperties from '../cssProperties'
import { generateFieldPrefix, gridItemProps, generateKeyFieldPrefix } from '../utils'
import { ReduxTextField } from '../../../../../components/FormInput/ReduxWrappers'
import ColorPicker from '../../../../../components/FormInput/ColorPicker'

interface TextFieldsProps {
  level: number
  childIndexes: number[]
}

const TextFields: React.FC<TextFieldsProps> = ({ level, childIndexes }) => (
  <FieldsExpansionPanel label='text'>
    <Grid container spacing={2} alignItems='center'>
      <Grid {...gridItemProps}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'fontFamily')}
          label='Font family'
          component={ReduxTextField}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <MenuField
          label='Text align'
          name={generateFieldPrefix(level, childIndexes, 'textAlign')}
          items={cssProperties.textAlign}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'fontSize')}
          label='Font size'
          component={ReduxTextField}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <MenuField
          label='Font weight'
          name={generateFieldPrefix(level, childIndexes, 'fontWeight')}
          items={cssProperties.fontWeight}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <MenuField
          label='Font style'
          name={generateFieldPrefix(level, childIndexes, 'fontStyle')}
          items={cssProperties.fontStyle}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'fontStretch')}
          label='Font stretch'
          component={ReduxTextField}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'lineHeight')}
          label='Line height'
          component={ReduxTextField}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <MenuField
          label='Text transform'
          name={generateFieldPrefix(level, childIndexes, 'textTransform')}
          items={cssProperties.textTransform}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'textDecoration')}
          label='Text decoration'
          component={ReduxTextField}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'textIndent')}
          label='Text indent'
          component={ReduxTextField}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'textShadow')}
          label='Text shadow'
          component={ReduxTextField}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'WebkitTextStroke')}
          label='WebkitTextStroke'
          component={ReduxTextField}
        />
      </Grid>
      <Grid item {...gridItemProps}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'letterSpacing')}
          label='Letter spacing'
          component={ReduxTextField}
        />
      </Grid>
      <Grid item {...gridItemProps}>
        <MenuField
          name={generateFieldPrefix(level, childIndexes, 'whiteSpace')}
          label='White space'
          items={['', 'normal', 'nowrap', 'pre', 'pre-line', 'pre-wrap', 'initial', 'inherit']}
        />
      </Grid>
      <Grid {...gridItemProps}>
        Color
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'color')}
          label='Color'
          component={ColorPicker}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <Field
          fullWidth
          name={generateKeyFieldPrefix(level, childIndexes, 'textOptions.truncate.length')}
          label='Truncate text length limit'
          component={ReduxTextField}
          type='number'
          helperText='Text will be truncated if text length >= limit.'
          parse={(value: any) => (!value ? null : Number(value))}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <Field
          fullWidth
          name={generateKeyFieldPrefix(level, childIndexes, 'textOptions.truncate.text')}
          label='Truncate text marker'
          component={ReduxTextField}
          helperText='If text is truncated marked will be added end of the text.'
        />
      </Grid>
      <Grid {...gridItemProps}>
        <Field
          fullWidth
          name={generateKeyFieldPrefix(level, childIndexes, 'textFit.min')}
          label='TextFit min'
          component={ReduxTextField}
          type='number'
          helperText='Minimum font size in pixel. Default is 1.'
          parse={(value: any) => (!value ? null : Number(value))}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <Field
          fullWidth
          name={generateKeyFieldPrefix(level, childIndexes, 'textFit.max')}
          label='TextFit max'
          component={ReduxTextField}
          helperText='Maximum font size in pixel. Default is 100.'
          type='number'
          parse={(value: any) => (!value ? null : Number(value))}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <MenuField
          name={generateKeyFieldPrefix(level, childIndexes, 'textFit.mode')}
          label='TextFit mode'
          items={['', 'single', 'multi']}
        />
      </Grid>
    </Grid>
  </FieldsExpansionPanel>
)

export default TextFields
