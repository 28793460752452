import React, { useState } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import CloudRefreshIcon from 'mdi-react/CloudRefreshIcon'
import IntegrationsService from '../../../services/api/integrations'

interface UpdateIntegrationScreensButtonProps {
  environmentId: string
  customerId: string
}

const UpdateIntegrationScreensButton: React.FC<UpdateIntegrationScreensButtonProps> = ({
  environmentId,
  customerId
}) => {
  const [isLoading, setLoadingState] = useState(false)
  const updateScreenData = async () => {
    setLoadingState(true)
    await IntegrationsService.updateIntegrationScreenData(environmentId, customerId)
    setLoadingState(false)
  }

  return (
    <Tooltip disableInteractive title={'Update screen data'}>
      <span>
        <IconButton disabled={isLoading} onClick={() => updateScreenData()} size='large'>
          <CloudRefreshIcon />
        </IconButton>
      </span>
    </Tooltip>
  )
}

export default UpdateIntegrationScreensButton
