import { API } from 'aws-amplify'
import {
  Environment,
  SubEnvironments,
  EnvironmentUser,
  ReleaseNote,
  WordpressLanguage
} from '@seesignage/seesignage-utils'
import {
  CreateEnvironmentFormData,
  AddUsersToEnvironmentFormData,
  EditEnvironmentPermissionsRequestBody,
  EditEnvironmentFormData,
  GetEnvironment
} from '../../types/environments'

const getEnvironments = async (): Promise<Environment[]> => {
  const environments = await API.get('environments', '/environments', {})
  return environments
}

const getEnvironment = async (id: string): Promise<GetEnvironment> => {
  const environment = await API.get('environments', `/environments/${id}`, {})
  return environment
}

const deleteEnvironment = async (id: string) => {
  await API.del('environments', `/environments/${id}`, {})
}

const createEnvironment = async (environment: CreateEnvironmentFormData) => {
  const newEnvironment = await API.post('environments', '/environments', { body: environment })
  return newEnvironment
}

const addUsers = async (environmentId: string, users: AddUsersToEnvironmentFormData) => {
  await API.put('environments', `/environments/${environmentId}/users`, { body: users })
}

const removeUser = async (environmentId: string, userId: string) => {
  const updatedUsers: EnvironmentUser[] = await API.del(
    'environments',
    `/environments/${environmentId}/users/${userId}`,
    {}
  )
  return updatedUsers
}

const initIotForUserEnvironment = async (environmentId?: string) => {
  await API.get('environments', `/environments/${environmentId}/initIot`, {})
}

const updateEnvironmentPermissions = async (
  permissions: EditEnvironmentPermissionsRequestBody,
  id: string
) => {
  const updatedEnvironment = await API.put(
    'environments',
    `/environments/${id}/users/permissions`,
    {
      body: permissions
    }
  )
  return updatedEnvironment
}

const updateEnvironmentSettings = async (environmentId: string, body: EditEnvironmentFormData) => {
  const updatedSettings = await API.put(
    'environments',
    `/environments/${environmentId}/updateSettings`,
    {
      body
    }
  )
  return updatedSettings
}

const updateAdditionalEnvironmentSettings = async (environmentId: string, body: any) => {
  const updatedSettings = await API.put(
    'environments',
    `/environments/${environmentId}/updateAdditionalSettings`,
    {
      body
    }
  )
  return updatedSettings
}

const getReleaseNotes = async (lang: WordpressLanguage) => {
  const releaseNotes: ReleaseNote[] = await API.get('environments', '/releaseNotes', {
    queryStringParameters: {
      lang
    }
  })
  return releaseNotes
}

/**
 * Update hyper environment
 * @param environmentId Hyper environment
 * @param subEnvironments sub environments
 */
const updateEnvironmentDependency = async (
  environmentId: string,
  subEnvironments: SubEnvironments | null
) => {
  const updatedEnvironments = await API.put(
    'environments',
    `/environments/${environmentId}/updateDependency`,
    {
      body: {
        subEnvironments
      }
    }
  )
  return updatedEnvironments
}

export default {
  getEnvironments,
  getEnvironment,
  deleteEnvironment,
  createEnvironment,
  removeUser,
  addUsers,
  initIotForUserEnvironment,
  updateEnvironmentPermissions,
  updateEnvironmentSettings,
  updateAdditionalEnvironmentSettings,
  getReleaseNotes,
  updateEnvironmentDependency
}
