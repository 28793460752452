import { Tooltip, IconButton, Popover } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { Fragment, useState } from 'react'
import FolderPlusIcon from 'mdi-react/FolderPlusIcon'
import { useTranslation } from 'react-i18next'
import CreateFolderForm from '../../../containers/MediaBrowser/Forms/CreateFolderForm'

const useStyles = makeStyles(theme => ({
  popoverContent: {
    width: 220,
    padding: theme.spacing(1),
    position: 'relative'
  }
}))

interface CreateFolderFormPopoverProps {
  parentFolderId?: string
}

const CreateFolderFormPopover: React.FC<CreateFolderFormPopoverProps> = ({ parentFolderId }) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const [createFolderPopoverAnchorEl, setCreateFolderPopoverAnchorEl] = useState<Element | null>(
    null
  )
  const closeCreateFolderPopover = () => {
    setCreateFolderPopoverAnchorEl(null)
  }
  return (
    <Fragment>
      <Tooltip disableInteractive title={t('media.createFolder')}>
        <IconButton
          id='create-folder-button'
          onClick={event => {
            setCreateFolderPopoverAnchorEl(event.currentTarget)
          }}
          size='large'>
          <FolderPlusIcon />
        </IconButton>
      </Tooltip>
      <Popover
        open={Boolean(createFolderPopoverAnchorEl)}
        anchorEl={createFolderPopoverAnchorEl}
        onClose={closeCreateFolderPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        <div className={classes.popoverContent}>
          <CreateFolderForm
            submitButtonLabel={t('general.create')}
            initialValues={{ parentFolderId }}
            onClose={closeCreateFolderPopover}
            useFormOnSubmit={false}
          />
        </div>
      </Popover>
    </Fragment>
  )
}
export default CreateFolderFormPopover
