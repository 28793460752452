import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'
import SupportIcon from 'mdi-react/QuestionMarkCircleIcon'
import { Dispatch } from 'redux'
import ChangeLanguageForm from '../../../components/ChangeLanguageForm/ChangeLanguageForm'
import { login as loginUser } from '../../../actions/users'
import {
  selectIsUserDeviceMobile,
  selectUserIsLoadingSession,
  selectUserIsAuthenticated
} from '../../../selectors/users'
import { bindSubmitActionToPromise } from '../../../utils/forms'
import menuSignage from '../../../images/seesignage.png'
import { StateInterface } from '../../../types/states'
import Version from '../../../components/Version'
import Dialog from '../../Dialog'
import Support from '../../Support'
import Background from '../../../components/Background'
import LoginForm from './LoginForm'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '25%',
    position: 'relative' as any,
    top: '20%',
    [theme.breakpoints.down('lg')]: {
      width: '90%',
      top: '0',
      height: '100%'
    },
    [theme.breakpoints.down('md')]: {
      height: '100vh'
    },
    margin: 'auto',
    padding: theme.spacing(2),
    textAlign: 'center' as any
  },
  versionContainer: {
    padding: theme.spacing(),
    textAlign: 'center' as 'center',
    [theme.breakpoints.up('md')]: {
      position: 'absolute' as 'absolute',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bottom: 0,
      color: '#fff'
    }
  },
  signup: {
    marginTop: '10px'
  },
  errorMessage: {
    color: 'red' as any,
    marginTop: '10px'
  },
  logo: {
    marginBottom: 20
  }
}))

interface StateProps {
  isAuthenticated: boolean
  isLoadingSession: boolean
  isMobile: boolean
}

interface DispatchProps {
  loginUser: (formData: any) => void
}

type LoginProps = StateProps & DispatchProps

const Login: React.FC<LoginProps> = ({
  loginUser,
  isAuthenticated,
  isLoadingSession,
  isMobile
}) => {
  const classes = useStyles()
  const [t] = useTranslation()
  return (
    <Background>
      {!isAuthenticated && (
        <Paper className={classes.container}>
          <Fragment>
            <img className={classes.logo} src={menuSignage} alt='SeeSignage' height='40' />
            <LoginForm isLoadingSession={isLoadingSession} loginUser={loginUser} />
            <Link style={{ textDecoration: 'none', color: 'inherit' }} to='/signup'>
              <Typography className={classes.signup} variant='body2' gutterBottom>
                {t('login.noAccountYet')}
              </Typography>
            </Link>
            <Link style={{ textDecoration: 'none', color: 'inherit' }} to='/forgotpassword/reset'>
              <Typography className={classes.signup} variant='body2' gutterBottom>
                {t('login.forgotPassword')}
              </Typography>
            </Link>
            <ChangeLanguageForm />
          </Fragment>
          <Dialog
            dialogId='SupportDialog'
            title={t('navigation.support')}
            buttonLabel={t('navigation.support')}
            buttonVariant='outlined'
            ButtonIcon={SupportIcon}
            Content={<Support />}
          />
          {isMobile && <Version className={classes.versionContainer} />}
        </Paper>
      )}

      {!isMobile && <Version className={classes.versionContainer} />}
    </Background>
  )
}

const mapStateToProps = (state: StateInterface): StateProps => ({
  isAuthenticated: selectUserIsAuthenticated(state),
  isLoadingSession: selectUserIsLoadingSession(state),
  isMobile: selectIsUserDeviceMobile(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  loginUser: bindSubmitActionToPromise(dispatch, loginUser)
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
