import React from 'react'
import { reduxForm, InjectedFormProps, Field } from 'redux-form'
import { Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { TFunction } from 'i18next'
import { ContentWeatherWidgetFormData } from '../../../../../../types/contents'

import { ReduxTextField } from '../../../../../../components/FormInput/ReduxWrappers'
import ContentColorPicker from '../../../../../../components/FormInput/ColorPicker/ContentColorPicker'
import SimpleExtensionPanel from '../../../../../../components/ExtensionPanels/SimpleExtensionPanel'

const useStyles = makeStyles(() => ({
  accordionSummaryRootClass: {
    backgroundColor: '#F5F5F5',
    borderBottom: '1px solid rgba(0, 0, 0, 0.09)'
  },
  label: {
    fontWeight: 500,
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 0.8)'
  },
  settingsSectionContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  colorFieldContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px'
  }
}))

interface OwnProps {
  t: TFunction
}

type FormProps = ContentWeatherWidgetFormData
type ComponentProps = OwnProps
const QRCodeWidgetForm: React.FC<ComponentProps & InjectedFormProps<FormProps, ComponentProps>> = ({
  t
}) => {
  const classes = useStyles()
  return (
    <Grid container>
      <Grid item xs={12}>
        <SimpleExtensionPanel
          title={t('contents.widgets.qrCode.value')}
          isOpen={true}
          accordionSummaryRootClass={classes.accordionSummaryRootClass}>
          <div className={classes.settingsSectionContainer}>
            <Typography className={classes.label}>
              {t('contents.widgets.qrCode.enterValue')}
            </Typography>
            <Field
              name='cQRCodeProps.value'
              placeholder={t('contents.widgets.qrCode.value')}
              component={ReduxTextField}
            />
          </div>
        </SimpleExtensionPanel>
      </Grid>

      <Grid item xs={12}>
        <SimpleExtensionPanel
          title={t('contents.widgets.qrCode.colors')}
          isOpen={false}
          accordionSummaryRootClass={classes.accordionSummaryRootClass}>
          <div className={classes.settingsSectionContainer}>
            <div className={classes.colorFieldContainer}>
              <Field
                name='cQRCodeProps.codeColor'
                tooltip={t('contents.widgets.qrCode.codeColor')}
                component={ContentColorPicker}
              />
              <Typography>{t('contents.widgets.qrCode.codeColor')}</Typography>
            </div>

            <div className={classes.colorFieldContainer}>
              <Field
                name='cQRCodeProps.backgroundColor'
                tooltip={t('contents.widgets.qrCode.backgroundColor')}
                component={ContentColorPicker}
              />
              <Typography>{t('contents.widgets.qrCode.backgroundColor')}</Typography>
            </div>
          </div>
        </SimpleExtensionPanel>
      </Grid>
    </Grid>
  )
}

export default reduxForm<FormProps, ComponentProps>({
  form: 'QRCodeWidgetForm',
  enableReinitialize: true
})(QRCodeWidgetForm)
