import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Snackbar } from '@mui/material'
import { selectIsIotError } from '../../selectors/iot'
import Alert from '../../components/Alert'

const IotContainer = () => {
  const [t] = useTranslation()
  const isIotError = useSelector(selectIsIotError)
  // Note: use Snackbar content inside element. Otherwise, TypeError: Cannot read properties of null (reading 'scrollTop').
  return (
    <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'top' }} open={isIotError}>
      <div>
        <Alert severity='warning'>{t('error.iot.connectionNotWorking')}</Alert>
      </div>
    </Snackbar>
  )
}

export default IotContainer
