import React from 'react'
import { Slider, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import SimpleExtensionPanel from '../../../../../../components/ExtensionPanels/SimpleExtensionPanel'

const formatBorder = (border: string | number) => {
  if (typeof border === 'string') {
    const result = Number(border.replace('%', ''))
    return result
  } else {
    return border
  }
}

const useStyles = makeStyles(() => ({
  accordionSummaryRootClass: {
    backgroundColor: '#F5F5F5',
    borderBottom: '1px solid rgba(0, 0, 0, 0.09)'
  },
  sliderContainer: {
    width: '50%'
  }
}))

interface ImageSettingsProps {
  changeValue: (field: string, value: any) => void
  imageBorderRadius: string | number
}

const ImageSettings = ({ changeValue, imageBorderRadius }: ImageSettingsProps) => {
  const [t] = useTranslation()
  const classes = useStyles()

  return (
    <SimpleExtensionPanel
      title={t('contents.widgets.rssFeed.articleImage')}
      isOpen={false}
      accordionSummaryRootClass={classes.accordionSummaryRootClass}>
      <div className={classes.sliderContainer}>
        <Typography>{t('contents.actions.roundEdges')}</Typography>
        <Slider
          id='round-edges-slider'
          value={formatBorder(imageBorderRadius)}
          valueLabelDisplay='auto'
          valueLabelFormat={(value: number) => `${value * 2}%`}
          max={50}
          onChange={(e, n) => {
            const newBorder = Number(n)
            changeValue('cRssFeedProps.styles.itemImageStyles.borderRadius', newBorder)
          }}
        />
      </div>
    </SimpleExtensionPanel>
  )
}

export default ImageSettings
