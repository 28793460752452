import { Button, DialogActions as MuiDialogActions } from '@mui/material'
import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { CloseDialog } from '../../types/actions'

interface DialogActionsProps extends WithTranslation {
  submitButtonLabel: string
  submitting: boolean | undefined
  closeDialog?: CloseDialog
  dialogId?: string
}

const DialogActions: React.FC<DialogActionsProps> = ({
  submitButtonLabel,
  submitting,
  closeDialog,
  dialogId,
  t
}) => (
  <MuiDialogActions>
    {closeDialog && dialogId && (
      <Button onClick={() => closeDialog(dialogId)} color='primary'>
        {t('general.cancel')}
      </Button>
    )}
    <Button disabled={submitting} color='primary' type='submit'>
      {submitButtonLabel}
    </Button>
  </MuiDialogActions>
)

export default withTranslation()(DialogActions)
