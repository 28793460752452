import React from 'react'
import { WrappedFieldProps } from 'redux-form'
import { Tooltip, IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import EyeIcon from 'mdi-react/EyeIcon'
import EyeOffIcon from 'mdi-react/EyeOffIcon'

const HideItemField: React.FC<WrappedFieldProps> = ({ input: { onChange, value } }) => {
  const [t] = useTranslation()
  return (
    <div style={{ height: 60 }}>
      {value === true ? (
        <Tooltip disableInteractive title={t('general.show')}>
          <IconButton onClick={() => onChange(false)} size='large'>
            <EyeOffIcon color='red' />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip disableInteractive title={t('campaigns.editCampaign.hideItem')}>
          <IconButton onClick={() => onChange(true)} size='large'>
            <EyeIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  )
}

export default HideItemField
