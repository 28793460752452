import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { EthernetNetwork as EthernetNetworkInterface } from '@seesignage/seesignage-utils'

interface EthernetNetworkProps extends WithTranslation {
  ethernetNetwork?: EthernetNetworkInterface
}

const EthernetNetwork: React.FC<EthernetNetworkProps> = ({ ethernetNetwork, t }) => {
  if (!ethernetNetwork) {
    return (
      <Paper>
        <Typography variant='body1'>{t('network.notAvailable')}</Typography>
      </Paper>
    )
  }
  const {
    cable,
    dns,
    gateway,
    ipAddress,
    ipMode,
    ipv6Address,
    macAddress,
    status,
    subnetMask
  } = ethernetNetwork
  return (
    <Paper>
      <Typography variant='h6' style={{ padding: 12 }}>
        Ethernet {t('network.information')}
      </Typography>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>Ethernet</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{t('ethernet.cable')}</TableCell>
            <TableCell>{cable}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>DNS</TableCell>
            <TableCell>{dns}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t('ethernet.gateway')}</TableCell>
            <TableCell>{gateway}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>IP mode</TableCell>
            <TableCell>{ipMode}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>IPv6 {t('network.address')}</TableCell>
            <TableCell>{ipv6Address}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>IP {t('network.address')}</TableCell>
            <TableCell>{ipAddress}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>MAC {t('network.address')}</TableCell>
            <TableCell>{macAddress}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t('network.subnet')}</TableCell>
            <TableCell>{subnetMask}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell style={status === 'CONNECTED' ? { color: 'green' } : { color: 'red' }}>
              {status}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  )
}

export default withTranslation()(EthernetNetwork)
