import React from 'react'
import { AppBar, Toolbar } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import PlayIcon from 'mdi-react/PlayIcon'
import { useTranslation } from 'react-i18next'
import { toolbarStyles } from '../../../../styles/common'
import Dialog from '../../../Dialog'
import SearchMiscProductForm from './SearchMiscProduct'
import PreviewMiscList from './PreviewMiscList'

const useStyles = makeStyles(theme => ({
  ...toolbarStyles(theme)
}))

const MiscListToolbar = () => {
  const classes = useStyles()
  const [t] = useTranslation()
  return (
    <AppBar className={classes.appBar} position='sticky' color='default'>
      <Toolbar>
        <SearchMiscProductForm />
        <div className={classes.spacer} />
        <div className={classes.actions}>
          <Dialog
            dialogId='PreviewCampaignDialog'
            title={t('lists.miscList.preview')}
            tooltipTitle={t('lists.miscList.preview')}
            // buttonDisabled={dirty}
            ButtonIcon={PlayIcon}
            Content={<PreviewMiscList />}
            maxWidth='xl'
          />
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default MiscListToolbar
