import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { DialogActions, Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { InfopageStore, isInfopageExtensive } from '@seesignage/seesignage-utils'
import ContentEditor from '../../../../Contents/ContentEditor'
import { formatFormContent } from '../../../../../utils/infopages'
import { SelectedInfopage, SelectedInfopageType } from '../../../../../types/infopages'
import { PageType } from '../../../../../types/playlists'
import { selectInfopageCreatedToPlaylist } from '../../../../../selectors/playlists'
import { selectInfopageById } from '../../../../../selectors/infopages'
import { StateInterface } from '../../../../../types/states'
import { saveCanvasAsPlaylistItem } from '../../../../../actions/contents'
import UpdateInfopageItem from './UpdateInfopageItem'

const useStyles = makeStyles(() => ({
  editorContainer: {
    background: '#fafafa',
    overflow: 'scroll'
  },
  dialogActions: {
    position: 'absolute',
    bottom: '0px',
    background: 'white',
    zIndex: 1000,
    right: '24px',
    left: '24px',
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)'
  }
}))

interface OwnProps {
  selectPage: (page: PageType) => void
}

interface StateProps {
  createdInfopage?: SelectedInfopage
  infopage?: InfopageStore
}

type CreateInfopageItemProps = OwnProps & StateProps

/**
 * Wrapper component to render either ContentEditor of InfopageEditor depending on created type
 * in previous page (NewInfopage)
 */
const CreateInfopageItem: React.FC<CreateInfopageItemProps> = ({ createdInfopage, infopage }) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  if (isInfopageExtensive(infopage)) {
    const { content, contentMetadata } = infopage
    formatFormContent(content, contentMetadata)
    return (
      <UpdateInfopageItem
        submitButtonLabel={t('general.save')}
        infopage={{
          ...infopage,
          content
        }}
      />
    )
  } else if (createdInfopage && createdInfopage.type === SelectedInfopageType.content) {
    return (
      <div id='playlist-infopage-editor-container' className={classes.editorContainer}>
        <ContentEditor contentId={createdInfopage.id} isUpdatePlaylistItem />
        <DialogActions className={classes.dialogActions}>
          <Button
            color='primary'
            onClick={() => dispatch(saveCanvasAsPlaylistItem(createdInfopage.id))}>
            {t('general.save')}
          </Button>
        </DialogActions>
      </div>
    )
  }
  return <Fragment />
}

const mapStateToProps = (state: StateInterface): StateProps => {
  const createdInfopage = selectInfopageCreatedToPlaylist(state)
  return {
    createdInfopage,
    infopage:
      createdInfopage?.type === SelectedInfopageType.infopage
        ? selectInfopageById(createdInfopage.id)(state)
        : undefined
  }
}
export default connect(mapStateToProps)(CreateInfopageItem)
