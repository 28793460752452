import { API } from 'aws-amplify'
import {
  Template,
  CreateTemplate,
  UpdateTemplate,
  MasterTemplateUI,
  ChildTemplate,
  TemplateOrientation
} from '@seesignage/seesignage-utils'
import { UpdateTemplateContentParams } from '../../types/templates'

const listTemplates = async (environmentId: string) => {
  const templates = await API.get('templates', `/environments/${environmentId}/templates`, {})
  return templates
}

interface GetTemplateQueryParams {
  orientation?: TemplateOrientation
  templateEnvironmentId?: string
  metadata?: boolean
}

const getTemplate = async (
  environmentId: string,
  templateId: string,
  qp?: GetTemplateQueryParams
) => {
  const options: any = qp !== undefined ? { queryStringParameters: {} } : {}
  if (qp?.orientation) {
    options.queryStringParameters.orientation = qp.orientation
  }
  if (qp?.templateEnvironmentId) {
    options.queryStringParameters.templateEnvironmentId = qp.templateEnvironmentId
  }
  if (qp?.metadata) {
    options.queryStringParameters.metadata = qp.metadata
  }
  const template = await API.get(
    'templates',
    `/environments/${environmentId}/templates/${templateId}`,
    options
  )
  return template
}

const createTemplate = async (environmentId: string, createTemplateProps: CreateTemplate) => {
  const newTemplate: Template = await API.post(
    'templates',
    `/environments/${environmentId}/templates`,
    {
      body: createTemplateProps
    }
  )
  return newTemplate
}

const updateTemplate = async (
  environmentId: string,
  templateId: string,
  updateTemplateProps: UpdateTemplate
) => {
  const updatedAttributes = await API.put(
    'templates',
    `/environments/${environmentId}/templates/${templateId}`,
    {
      body: updateTemplateProps
    }
  )
  return updatedAttributes
}

const updateTemplateComponents = async (
  environmentId: string,
  templateId: string,
  content: UpdateTemplateContentParams
) => {
  const updatedTemplate: Template = await API.put(
    'templates',
    `/environments/${environmentId}/templates/${templateId}/components`,
    { body: content }
  )
  return updatedTemplate
}

const copyToEnvironments = async (
  environmentId: string,
  templateId: string,
  environments: string[]
) => {
  await API.put('templates', `/environments/${environmentId}/templates/${templateId}/copy`, {
    body: environments
  })
}

const removeTemplate = async (environmentId: string, templateId: string) => {
  await API.del('templates', `/environments/${environmentId}/templates/${templateId}`, {})
}

/**
 * Make a copy of given template
 * @param environmentId
 * @param templateId
 * @param childIndex new index of child template
 */
const duplicateTemplate = async (
  environmentId: string,
  templateId: string,
  childIndex?: number
) => {
  const copy = await API.put(
    'templates',
    `/environments/${environmentId}/templates/${templateId}/duplicate`,
    {
      body: {
        childIndex // note: this might be deprecated and not used anymore
      }
    }
  )
  return copy
}

const createNewChildTemplate = async (
  environmentId: string,
  masterTemplateId: string,
  orientation: TemplateOrientation
) => {
  const updatedTemplate: {
    masterTemplate: MasterTemplateUI
    newChildTemplate: ChildTemplate
  } = await API.put(
    'templates',
    `/environments/${environmentId}/templates/${masterTemplateId}/${orientation}/createChild`,
    {}
  )
  return updatedTemplate
}

export default {
  listTemplates,
  createTemplate,
  updateTemplate,
  getTemplate,
  updateTemplateComponents,
  copyToEnvironments,
  removeTemplate,
  duplicateTemplate,
  createNewChildTemplate
}
