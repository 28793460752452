import { change } from 'redux-form'
import { getStore } from '../../configureStore'
import { contentColors } from '../../styles/common/colors'

const {
  widgets: {
    rssFeed: { semiDarkColor, darkColor, lightColor, white }
  }
} = contentColors

const store = getStore()

const dispatchThemeChanges = (headerColor: string, backgroundColor: string, textColor: string) => {
  store.dispatch(change('RssFeedForm', 'cRssFeedProps.styles.channelTitleStyles.color', textColor))
  store.dispatch(change('RssFeedForm', 'cRssFeedProps.styles.itemTitleStyles.color', textColor))
  store.dispatch(
    change('RssFeedForm', 'cRssFeedProps.styles.itemDescriptionStyles.color', textColor)
  )
  store.dispatch(change('RssFeedForm', 'cRssFeedProps.styles.itemPubDateStyles.color', textColor))
  store.dispatch(
    change(
      'RssFeedForm',
      'cRssFeedProps.styles.channelHeaderContainerStyles.background',
      headerColor
    )
  )
  store.dispatch(
    change('RssFeedForm', 'cRssFeedProps.styles.mainContainerStyles.background', backgroundColor)
  )
}

const themeChange = (newTheme: any) => {
  switch (newTheme) {
    case 'dark':
      dispatchThemeChanges(semiDarkColor, darkColor, lightColor)
      break
    case 'light':
      dispatchThemeChanges(white, lightColor, semiDarkColor)
      break
    case 'default':
      dispatchThemeChanges(lightColor, white, semiDarkColor)
      break
    case 'custom':
      break
    default:
      break
  }
  return newTheme
}

const changeThemeToCustom = (value: any, prev: any, all: any, prevAll: any) => {
  if (prevAll?.cRssFeedProps?.theme !== 'custom') {
    store.dispatch(change('RssFeedForm', 'cRssFeedProps.theme', 'custom'))
  }
  return value
}

const resetRssFeedVisibleProps = () => {
  store.dispatch(
    change('RssFeedForm', 'cRssFeedProps.visibleProperties', {
      channelTitle: true,
      channelDescription: false,
      itemTitle: true,
      itemDescription: false,
      itemImage: false,
      itemPubDate: false
    })
  )
}
export { changeThemeToCustom, themeChange, resetRssFeedVisibleProps }
