import React, { Fragment, useEffect, useState } from 'react'
import {
  LinearProgress,
  Button,
  DialogActions,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  CircularProgress,
  Grid
} from '@mui/material'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import FolderIcon from 'mdi-react/FolderIcon'
import {
  Customer,
  EMMiPreviewFolder,
  isIntegrationIoTDataTranscoding,
  isIntegrationIoTDataFailed,
  isIntegrationIoTDataReady
} from '@seesignage/seesignage-utils'
import { useSelector, useDispatch } from 'react-redux'
import IntegrationsService from '../../../../services/api/integrations'
import {
  ImportFolderWizardPage,
  ImportFolderResponse,
  isEmmiFolderReport,
  GetEMMiFolderByIdResponse
} from '../../../../types/integrations'
import { selectIntegrationsIotData } from '../../../../selectors/iot'
import { resetIntegrationIotData } from '../../../../actions/iot'
import ImportReport from './ImportReport'

const renderLoader = (text: string) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <div>
      <CircularProgress />
    </div>
    <div style={{ marginLeft: 10 }}>
      <Typography>{text}</Typography>{' '}
    </div>
  </div>
)

interface PreviewFolderProps {
  selectedFolder: EMMiPreviewFolder
  emmiCustomer: Customer
  setPage: React.Dispatch<React.SetStateAction<ImportFolderWizardPage>>
}

const PreviewFolder: React.FC<PreviewFolderProps> = ({ emmiCustomer, selectedFolder, setPage }) => {
  const [importReport, setImportReport] = useState<GetEMMiFolderByIdResponse | undefined>(undefined)
  const [isLoading, setLoadingState] = useState(false)
  const [isButtonsDisabled, setButtonsDisabled] = useState(false)
  const [isImportFolderLoading, setImportFolderLoading] = useState(false)
  const [importFolderResponse, setImportFolderResponse] = useState<
    ImportFolderResponse | undefined
  >()

  const iotData = useSelector(selectIntegrationsIotData)

  const [t] = useTranslation()
  const dispatch = useDispatch()

  const { name, folderId, startDate, endDate } = selectedFolder

  const importFolder = async () => {
    setButtonsDisabled(true) // do not allow user to press buttons after import has been started
    setImportFolderLoading(true)
    if (folderId) {
      dispatch(resetIntegrationIotData())
      const response = await IntegrationsService.importEMMiFolder(
        emmiCustomer.environmentId,
        emmiCustomer.customerId,
        folderId
      )
      setImportFolderResponse(response)
    }
    setImportFolderLoading(false)
  }

  useEffect(() => {
    const getFolder = async () => {
      setLoadingState(true)
      if (folderId) {
        const importReport = await IntegrationsService.getEMMiFolderById(
          emmiCustomer.environmentId,
          emmiCustomer.customerId,
          folderId
        )

        setImportReport(importReport)
      }
      setLoadingState(false)
    }
    getFolder()
  }, [emmiCustomer, folderId])

  return (
    <Fragment>
      {isLoading && <LinearProgress />}
      <Grid container>
        <Grid item xs={5}>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <FolderIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={name}
              secondaryTypographyProps={
                new Date(endDate).getTime() < Date.now() ? { color: 'secondary' } : undefined
              }
              secondary={t('integrations.emmi.folderValid', {
                startDate: moment(startDate).format('DD/MM/YYYY'),
                endDate: moment(endDate).format('DD/MM/YYYY')
              })}
            />
          </ListItem>
        </Grid>
        <Grid item xs={7}>
          {isImportFolderLoading &&
            renderLoader(t('integrations.emmi.importingFolder', { folderName: name }))}
          {importFolderResponse?.success &&
            iotData === undefined &&
            renderLoader(t('integrations.emmi.importTaskStarted'))}
          {isIntegrationIoTDataTranscoding(iotData) &&
            renderLoader(
              t('integrations.emmi.integrationStatus.transcoding', {
                total: iotData.jobsTotal,
                transcoded: iotData.jobsTotal - iotData.jobsRemaining
              })
            )}

          {importFolderResponse && (
            <div>
              {importFolderResponse.success ? (
                <div>
                  {isIntegrationIoTDataFailed(iotData) && (
                    <Typography color='error'>
                      {t('integrations.emmi.integrationStatus.failed', {
                        filenames: iotData.filenamesFailed
                          ? iotData.filenamesFailed.join(', ')
                          : '-'
                      })}
                    </Typography>
                  )}
                  {isIntegrationIoTDataReady(iotData) && (
                    <Typography>
                      {t('integrations.emmi.integrationStatus.ready', {
                        total: iotData.jobsTotal,
                        transcoded: iotData.jobsTotal - iotData.jobsRemaining
                      })}
                    </Typography>
                  )}
                </div>
              ) : (
                <div>
                  <Typography color='error'>
                    {t('integrations.emmi.importFolderFailed')}
                    {t(`integrations.emmi.importFolderErrorCode.${importFolderResponse.errorCode}`)}
                  </Typography>
                  <Typography color='error' variant='caption'>
                    ({importFolderResponse.message})
                  </Typography>
                </div>
              )}
            </div>
          )}
        </Grid>
      </Grid>
      {isEmmiFolderReport(importReport) ? (
        <div style={{ width: '100%', height: 550, overflowY: 'scroll' }}>
          <ImportReport {...importReport} />
        </div>
      ) : (
        <Fragment>
          {importReport && (
            <div>
              <Typography color='error'>
                {t('integrations.emmi.importFolderFailed')}
                {t(`integrations.emmi.importFolderErrorCode.${importReport.errorCode}`)}
              </Typography>
              <Typography color='error' variant='caption'>
                ({importReport.message})
              </Typography>
            </div>
          )}
        </Fragment>
      )}
      <DialogActions>
        <Button
          onClick={() => {
            setPage(ImportFolderWizardPage.selectFolder)
          }}
          disabled={isButtonsDisabled}
          color='primary'>
          {t('general.back')}
        </Button>
        <Button
          disabled={isButtonsDisabled}
          color='primary'
          type='submit'
          variant='contained'
          onClick={() => importFolder()}>
          {t('integrations.emmi.import')}
        </Button>
      </DialogActions>
    </Fragment>
  )
}

export default PreviewFolder
