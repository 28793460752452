import React, { Fragment, useEffect, useState } from 'react'
import {
  ListType,
  MiscProduct,
  Template,
  TemplateOrientation,
  TemplateType
} from '@seesignage/seesignage-utils'
import makeStyles from '@mui/styles/makeStyles'
import { PreviewFrame } from '@seesignage/seesignage-player-utils/lib'
import { useDispatch, useSelector } from 'react-redux'
import { LinearProgress, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { selectCurrentMiscList } from '../../../../selectors/lists'
import { selectTemplateById, selectTemplateIsLoading } from '../../../../selectors/templates'
import { getTemplate } from '../../../../actions/templates'

const FISH_LIST_ITEMS_LIMIT = 16
const MEAT_LIST_ITEMS_LIMIT = 26

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  landscapeContainer: {
    transform: 'scale(0.6)',
    transformOrigin: 'top left'
  }
}))

const splitProductsToTables = (arraySize: number, items: MiscProduct[]) =>
  items.reduce((resultArray: MiscProduct[][], item, index) => {
    const chunkIndex = Math.floor(index / arraySize)
    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []
    }
    resultArray[chunkIndex].push(item)
    return resultArray
  }, [])

const PreviewMiscList = () => {
  const classes = useStyles()
  const [previewPageIndex, setPreviewPageIndex] = useState(0)
  const list = useSelector(selectCurrentMiscList)
  const istemplateLoading = useSelector(selectTemplateIsLoading)
  const template = useSelector(selectTemplateById(list?.templateId)) as Template | undefined
  const dispatch = useDispatch()

  useEffect(() => {
    if (list?.templateId && list?.environmentId) {
      dispatch(getTemplate({ templateId: list?.templateId, environmentId: list?.environmentId }))
    }
  }, [list, dispatch])

  const items = list?.items.map(item => item.product) || []
  const itemsPerPage = splitProductsToTables(
    list?.type === ListType.fish ? FISH_LIST_ITEMS_LIMIT : MEAT_LIST_ITEMS_LIMIT,
    items
  )
  const visibleItems = itemsPerPage[previewPageIndex] || []
  return (
    <div className={classes.root}>
      {list && template?.schema && !istemplateLoading ? (
        <Fragment>
          <div style={{ height: 700 }}>
            <div className={classes.landscapeContainer}>
              <PreviewFrame
                id='miscListPreview'
                content={{
                  orientation: TemplateOrientation.landscape,
                  items: visibleItems,
                  template: {
                    schema: template.schema,
                    environmentId: list.environmentId,
                    keys: template.keys,
                    name: 'Misc list preview',
                    templateId: list.templateId,
                    type: TemplateType.fish, // or meat
                    createdAt: 1,
                    components: template.components
                  }
                }}
                width={1920}
                height={1080}
              />
            </div>
          </div>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <ToggleButtonGroup
              exclusive
              value={previewPageIndex}
              onChange={(e, val) => {
                if (val !== null) {
                  setPreviewPageIndex(val)
                }
              }}>
              {itemsPerPage?.map((id, i) => (
                <ToggleButton key={i} value={i}>
                  {i + 1}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </div>
        </Fragment>
      ) : (
        <LinearProgress />
      )}
    </div>
  )
}

export default PreviewMiscList
