import { AppBar, IconButton, Toolbar, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import ToggleButton from '@mui/material/ToggleButton'
import SelectAllIcon from 'mdi-react/CheckBoxMultipleOutlineIcon'
import PreviewIcon from 'mdi-react/MoviePlayIcon'
import InfoIcon from 'mdi-react/InfoCircleIcon'
import PercentIcon from 'mdi-react/PercentIcon'
import React, { Fragment } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { PlaylistItemUI, PlaylistUI } from '@seesignage/seesignage-utils'
import { selectAllPlaylistItems } from '../../../../actions/playlists'
import { createPreviewSession } from '../../../../actions/users'
import { selectViewFromPathname } from '../../../../selectors/routing'
import Dialog from '../../../Dialog'
import PreviewPlayer from '../../../PreviewPlayer'
import { ForceRunTour, CreatePreviewSessionParams } from '../../../../types/actions'
import { StateInterface } from '../../../../types/states'
import { Tour } from '../../../../types/tours'
import { forceRunTour } from '../../../../actions/tours'
import SelectedItemActions from './SelectedItemActions'

const useStyles = makeStyles(theme => ({
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    color: theme.palette.text.secondary,
    marginRight: 4,
    display: 'flex'
  },
  appBar: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14)',
    top: 0
  },
  button: {
    margin: 8
  },
  togglePercentageButton: {
    border: 'none'
  }
}))
interface OwnProps {
  selectedPlaylistItems?: PlaylistItemUI[]
  playlist?: PlaylistUI
  showPercentage: boolean
  setShowPercentage: React.Dispatch<React.SetStateAction<boolean>>
}

interface StateProps {
  isEditChannel: boolean
}

interface DispatchProps {
  createPreviewSession: (createPreviewSessionParams: CreatePreviewSessionParams) => void
  selectAllItems: (playlistId: string) => void
  forceRunTour: ForceRunTour
}

type EditPlaylistToolbarProps = OwnProps & StateProps & DispatchProps & WithTranslation

const EditPlaylistToolbar: React.FC<EditPlaylistToolbarProps> = ({
  selectedPlaylistItems,
  playlist,
  isEditChannel,
  createPreviewSession,
  selectAllItems,
  forceRunTour,
  showPercentage,
  setShowPercentage,
  t
}) => {
  const classes = useStyles()
  return !playlist ? (
    <Fragment />
  ) : (
    <AppBar className={classes.appBar} position='sticky' color='default'>
      <Toolbar>
        <Fragment>
          <Tooltip
            disableInteractive
            title={
              showPercentage
                ? (t('playlists.durations.hidePercentage') as string)
                : (t('playlists.durations.showPercentage') as string)
            }>
            <ToggleButton
              classes={{ root: classes.togglePercentageButton }}
              value='check'
              selected={showPercentage}
              onChange={() => setShowPercentage(!showPercentage)}>
              <PercentIcon />
            </ToggleButton>
          </Tooltip>
          <div className={classes.spacer} />
          <div className={classes.actions}>
            {selectedPlaylistItems && selectedPlaylistItems.length > 0 ? (
              <SelectedItemActions
                defaultInterval={playlist.defaultInterval}
                selectedPlaylistItems={selectedPlaylistItems}
              />
            ) : (
              <Fragment>
                <Tooltip disableInteractive title={t('general.selectAll')}>
                  <IconButton onClick={() => selectAllItems(playlist.playlistId)} size='large'>
                    <SelectAllIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip disableInteractive title={t('playlists.editPlaylist.previewPlaylist')}>
                  <IconButton
                    onClick={() => createPreviewSession({ playlistId: playlist.playlistId })}
                    size='large'>
                    <PreviewIcon />
                  </IconButton>
                </Tooltip>
              </Fragment>
            )}
            <Tooltip disableInteractive title={t('tours.runTour')}>
              <IconButton onClick={() => forceRunTour(Tour.createPlaylistItem)} size='large'>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Fragment>
      </Toolbar>
      {isEditChannel && (
        <Dialog
          dialogId='previewPlaylistDialog'
          title={t('playlists.editPlaylist.previewPlaylist')}
          tooltipTitle={t('playlists.editPlaylist.previewPlaylist')}
          maxWidth='xl'
          noOpenDialogButton
          Content={<PreviewPlayer />}
        />
      )}
    </AppBar>
  )
}

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    isEditChannel: selectViewFromPathname(state) === 'channels'
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  createPreviewSession: (createPreviewSessionParams: CreatePreviewSessionParams) =>
    dispatch(createPreviewSession(createPreviewSessionParams)),
  selectAllItems: (playlistId: string) => dispatch(selectAllPlaylistItems(playlistId)),
  forceRunTour: (tour: Tour) => dispatch(forceRunTour(tour))
})

export default connect<StateProps, DispatchProps, OwnProps, StateInterface>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(EditPlaylistToolbar))
