import React from 'react'
import { Grid, Paper, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { FieldArray, Field } from 'redux-form'
import { TFunction } from 'i18next'
import { ReduxSwitch } from '../../../../../../../components/FormInput/ReduxWrappers'
import MainTableFields from './MainTableFields'
import StylesFields from './StylesFields'

const useStyles = makeStyles(theme => ({
  stylesContainer: {
    padding: theme.spacing(2)
  },
  disableEditStyles: {
    padding: theme.spacing(1)
  }
}))
interface OwnProps {
  t: TFunction
  isSystemAdmin: boolean
  isFieldsDisabled: boolean
}

type ComponentProps = OwnProps

/**
 * Modifies table styles inside table schema
 */
const TableStylesFields: React.FC<ComponentProps> = ({ t, isSystemAdmin, isFieldsDisabled }) => {
  const classes = useStyles()
  return (
    <Grid container>
      {isSystemAdmin && (
        <Grid item xs={12}>
          <div className={classes.disableEditStyles}>
            <Field
              name='cTableProps.schema.disableEditStyles'
              component={ReduxSwitch}
              label={t('contents.widgets.table.disableEditStyles')}
            />
            <Typography display='block' variant='caption'>
              {t('contents.widgets.table.systemAdminAccessHelper')}
            </Typography>
          </div>
        </Grid>
      )}
      <Paper variant='outlined' className={classes.stylesContainer}>
        <StylesFields t={t} isFieldsDisabled={isFieldsDisabled} />
      </Paper>
      <Grid item xs={12}>
        <FieldArray
          name='cTableProps.schema.fields'
          component={MainTableFields}
          props={{ isFieldsDisabled }}
        />
      </Grid>
    </Grid>
  )
}

export default TableStylesFields
