import React from 'react'
import { Field } from 'redux-form'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Styles } from '@seesignage/seesignage-utils'
import { ReduxTextField } from '../../../components/FormInput/ReduxWrappers'
import TextEditorField from './TextEditorField'

interface CustomTextFieldProps extends WithTranslation {
  id: string
  level: number
  styles?: Styles
  childrenList: any
  wysiwygAllowed?: boolean
  editorBackgroundColor?: string
  formName: string
}

const CustomTextField: React.FC<CustomTextFieldProps> = ({
  id,
  styles,
  wysiwygAllowed,
  editorBackgroundColor,
  t
}) => {
  return !wysiwygAllowed ? (
    <Field
      fullWidth
      name={`content.${id}`}
      label={t('infopages.enterText')}
      component={ReduxTextField}
      variant='outlined'
      size='small'
      multiline
    />
  ) : (
    <Field
      fullWidth
      name={`content.${id}.html`}
      label={t('infopages.enterText')}
      styles={styles}
      editorBackgroundColor={editorBackgroundColor}
      component={TextEditorField}
    />
  )
}

export default withTranslation()(CustomTextField)
