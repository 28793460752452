import React, { Fragment } from 'react'
import { WrappedFieldInputProps } from 'redux-form'
import FormatAlignLeftIcon from 'mdi-react/FormatAlignLeftIcon'
import FormatAlignCenterIcon from 'mdi-react/FormatAlignCenterIcon'
import FormatAlignRightIcon from 'mdi-react/FormatAlignRightIcon'
import { Checkbox } from '@mui/material'
import { TFunction } from 'i18next'
import TooltipSpan from '../../../Tooltip/TooltipSpan'

const toolbarIconSize = 25
const textAligns = [
  {
    Icon: FormatAlignLeftIcon,
    align: 'left'
  },
  {
    Icon: FormatAlignCenterIcon,
    align: 'center'
  },
  {
    Icon: FormatAlignRightIcon,
    align: 'right'
  }
]

interface TextAlignCheckBoxProps {
  input: WrappedFieldInputProps
  disabled?: boolean
  t: TFunction
}

// Possible values: "left", "center", "right", "justify", "justify-left", "justify-center" or "justify-right".
const TextAlignCheckBox = ({ input: { onChange, value }, disabled, t }: TextAlignCheckBoxProps) => (
  <Fragment>
    {textAligns.map(({ Icon, align }) => (
      <TooltipSpan title={t(`contents.properties.alignText.${align}`)} key={align}>
        <Checkbox
          disabled={disabled}
          checked={value === align ? true : false}
          icon={<Icon size={toolbarIconSize} />}
          checkedIcon={
            <Icon
              size={toolbarIconSize}
              color='#1e88e5'
              style={{ borderRadius: '50%', border: '1px solid #1e88e5' }}
            />
          }
          onChange={() => onChange(align !== value ? align : undefined)}
        />
      </TooltipSpan>
    ))}
  </Fragment>
)

export default TextAlignCheckBox
