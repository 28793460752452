import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'
import { WrappedFieldProps } from 'redux-form'

interface ReduxDropDownSelectionProps extends WrappedFieldProps {
  labelId: string
  label: string
  options: {
    label: string
    value: any
  }[]
}

const ReduxDropDownSelection = ({
  labelId,
  label,
  options = [],
  input: { onChange, value },
  ...custom
}: ReduxDropDownSelectionProps) => {
  return (
    <FormControl fullWidth {...custom}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        labelId={labelId}
        id='redux-drop-down-selection'
        value={JSON.stringify(value)}
        label={label}
        onChange={e => onChange(JSON.parse(e.target.value))}>
        {options.map((item, i) => (
          <MenuItem key={`${i}`} value={JSON.stringify(item.value)}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default ReduxDropDownSelection
