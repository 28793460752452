import { all, takeLatest, put, call, select } from 'redux-saga/effects'
import { SubmissionError } from 'redux-form'
import { toast } from 'react-toastify'
import { closeDialog } from '../actions/dialogs'
import { submitFeedback, submitFeedbackSuccess, submitFeedbackFail } from '../actions/feedback'
import { selectUser } from '../selectors/users'
import Api from '../services/api/feedback'
import i18n from '../translations/i18n'
import { HandleSubmitFeedbackFormParams } from '../types/formData'
import { UserInterface } from '../types/users'

export function* handleSubmitFeedback({
  payload: { formData, resolve, reject }
}: HandleSubmitFeedbackFormParams) {
  try {
    const { attributes }: UserInterface = yield select(selectUser)
    yield call(Api.sendFeedback, attributes, formData)
    toast.info(i18n.t('feedback.sent'))
    yield put(closeDialog())
    yield put(submitFeedbackSuccess())
    resolve()
  } catch (error) {
    yield put(submitFeedbackFail(error.message))
    yield call(
      reject,
      new SubmissionError({
        _error: error.message
      })
    )
  }
}

function* watchFeedbackActions() {
  yield all([takeLatest(submitFeedback, handleSubmitFeedback)])
}

export default [watchFeedbackActions]
