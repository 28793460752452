import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Tooltip,
  CardHeader,
  CardMedia,
  Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import ContentCopyIcon from 'mdi-react/ContentCopyIcon'
import DeleteIcon from 'mdi-react/DeleteIcon'
import PencilIcon from 'mdi-react/PencilIcon'
import ShareIcon from 'mdi-react/ShareIcon'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Template,
  FeaturePermission,
  FeaturePermissionLevel,
  TemplateType,
  MasterTemplateUI
} from '@seesignage/seesignage-utils'
import BookInformationVariantIcon from 'mdi-react/BookInformationVariantIcon'
import RssBoxIcon from 'mdi-react/RssBoxIcon'
import FishIcon from 'mdi-react/FishIcon'
import SilverwareForkKnifeIcon from 'mdi-react/SilverwareForkKnifeIcon'
import FoodSteakIcon from 'mdi-react/FoodSteakIcon'
import BrightnessPercentIcon from 'mdi-react/BrightnessPercentIcon'
import ShoppingIcon from 'mdi-react/ShoppingIcon'
import TimerIcon from 'mdi-react/TimerIcon'
import Dialog from '../Dialog'
import { CloseDialog, DeleteTemplate, DuplicateTemplate, UpdateTemplate } from '../../types/actions'
import { UserInterface } from '../../types/users'
import { validateUserAccess } from '../../utils/permissions'
import { millisToMinutesAndSeconds } from '../../utils/time'
import CopyToEnvironmentsForm from './Forms/CopyToEnvironmentsForm'
import CreateTemplateForm from './Forms/CreateTemplateForm'
import templateCardImage from './templateCard.jpg'

const useStyles = makeStyles(() => ({
  media: {
    height: 110,
    width: '100%',
    objectFit: 'contain',
    backgroundColor: 'white'
  },
  card: {
    width: 270,
    height: 225,
    display: 'inline-block',
    verticalAlign: 'middle',
    margin: '0px 5px 15px',
    position: 'relative' as any
  },
  selectArea: {
    cursor: 'pointer',
    userSelect: 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    '-webkit-user-select': 'none',
    height: 75
  } as any,
  actions: {
    position: 'absolute' as any,
    bottom: 0
  },
  durationIcon: {
    position: 'absolute',
    right: 4,
    top: 4
  }
}))

const TemplateTypeIcon = {
  [TemplateType.info]: <BookInformationVariantIcon color='#1e88e5' />,
  [TemplateType.feed]: <RssBoxIcon color='#1e88e5' />,
  [TemplateType.fish]: <FishIcon color='#1e88e5' />,
  [TemplateType.lunch]: <SilverwareForkKnifeIcon color='#1e88e5' />,
  [TemplateType.meat]: <FoodSteakIcon color='#1e88e5' />,
  [TemplateType.offer]: <BrightnessPercentIcon color='#1e88e5' />,
  [TemplateType.retail]: <ShoppingIcon color='#1e88e5' />
}

interface TemplateCardProps {
  template: Template | MasterTemplateUI
  navigate: (path: string) => any
  closeDialog: CloseDialog
  deleteTemplate: DeleteTemplate
  duplicateTemplate: DuplicateTemplate
  updateTemplate: UpdateTemplate
  user: UserInterface
}

const TemplateCard: React.FC<TemplateCardProps> = ({
  template,
  navigate,
  closeDialog,
  deleteTemplate,
  duplicateTemplate,
  updateTemplate,
  user
}) => {
  const [t] = useTranslation()
  const classes = useStyles()
  const { templateId, environmentId, name, type, maxItems, thumbnailUrl } = template

  const { duration } = template as Template
  const { childTemplateIds } = template as MasterTemplateUI
  return (
    <Card className={classes.card}>
      {duration && (
        <div className={classes.durationIcon}>
          <TimerIcon />
          <Typography display='block' variant='caption'>
            {millisToMinutesAndSeconds(duration * 1000)}
          </Typography>
        </div>
      )}
      <Tooltip disableInteractive placement='bottom' title={t('templates.open')}>
        <CardActionArea
          onClick={() => navigate(`/environments/${environmentId}/templates/${templateId}`)}>
          <CardHeader
            avatar={TemplateTypeIcon[type]}
            title={name}
            subheader={maxItems && `${t('templates.maxItems')} ${maxItems}`}
          />
          <CardMedia
            component='img'
            className={classes.media}
            image={thumbnailUrl || templateCardImage}
            title={name}
          />
        </CardActionArea>
      </Tooltip>
      {validateUserAccess(user, FeaturePermission.templates, FeaturePermissionLevel.write) && (
        <CardActions className={classes.actions}>
          <Tooltip disableInteractive title={t('templates.duplicate')}>
            <IconButton onClick={() => duplicateTemplate(templateId)} size='large'>
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
          <Dialog
            dialogId={`CopyTemplateToEnvironments.${templateId}`}
            title={t('templates.copyToEnvironments', { name })}
            ButtonIcon={ShareIcon}
            tooltipTitle={t('templates.copyToEnvironments', { name })}
            Content={
              <CopyToEnvironmentsForm
                dialogId={`CopyTemplateToEnvironments.${templateId}`}
                initialValues={{
                  templateId
                }}
              />
            }
          />
          <Dialog
            dialogId={`UpdateTemplate.${templateId}`}
            title={t('templates.update', { name })}
            ButtonIcon={PencilIcon}
            tooltipTitle={t('templates.update', { name })}
            Content={
              <CreateTemplateForm
                dialogId={`CreateTemplate.${templateId}`}
                submitButtonLabel={t('general.update')}
                onSubmit={updateTemplate}
                closeDialog={closeDialog}
                isUpdate
                initialValues={{
                  templateId,
                  name,
                  type,
                  maxItems,
                  duration,
                  isMasterTemplate: childTemplateIds ? true : false
                }}
              />
            }
          />
          <Dialog
            dialogId={`removeTemplateDialog.${templateId}`}
            title={t('templates.delete', { name })}
            ButtonIcon={DeleteIcon}
            tooltipTitle={t('templates.delete', { name })}
            Content={
              <Fragment>
                <DialogContent>
                  <DialogContentText>{t('templates.deleteConfirm', { name })}</DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => closeDialog(`removeTemplateDialog.${templateId}`)}
                    color='primary'>
                    {t('general.cancel')}
                  </Button>
                  <Button onClick={() => deleteTemplate(templateId)} color='primary' autoFocus>
                    {t('general.delete')}
                  </Button>
                </DialogActions>
              </Fragment>
            }
          />
        </CardActions>
      )}
    </Card>
  )
}

export default TemplateCard
