import { Grid, LinearProgress, Snackbar } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Prompt } from 'react-router'
import { isDirty, isSubmitting } from 'redux-form'
import {
  FeaturePermission,
  FeaturePermissionLevel,
  isMasterTemplateUI
} from '@seesignage/seesignage-utils'
import nanoid from 'nanoid'
import { updateTemplateContent } from '../../../actions/templates'
import PageTitle from '../../../components/PageTitle'
import { selectContentIdFromPathname } from '../../../selectors/routing'
import {
  selectSelectedTemplateOrientation,
  selectTemplateById,
  selectTemplateIsLoading,
  selectSchemaHasChanges,
  selectSelectedTemplateChildIndex
} from '../../../selectors/templates'
import { bindSubmitActionToPromise } from '../../../utils/forms'
import TemplateForm from '../Forms/TemplateForm'
import { selectUser } from '../../../selectors/users'
import { validateUserAccess } from '../../../utils/permissions'
import { BreadcrumbContentType } from '../../../types/breadcrumbs'
import { validateProductDataComponentCount } from '../../../utils/templates'
import Alert from '../../../components/Alert'
import TemplatePreview from './TemplatePreview'
import EditTemplateToolbar, { EditTemplateToolbarOwnProps } from './EditTemplateToolbar'
import ProductCountSelector from './ProductCountSelector'

const useStyles = makeStyles(() => ({
  previewGridItem: {
    height: 550,
    position: 'sticky' as any,
    top: 100
  },
  productCountSelector: {
    position: 'relative',
    left: 20,
    margin: 8
  },
  dialogButton: {
    display: 'flex'
  }
}))

const EditTemplate = () => {
  const classes = useStyles()
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  const isLoading = useSelector(selectTemplateIsLoading)
  const formEnabled = !useSelector(isSubmitting('TemplateForm'))
  const isTemplateFormDirty = useSelector(isDirty('TemplateForm'))
  const isSchemaChanges = useSelector(selectSchemaHasChanges)
  const isFormDirty = isTemplateFormDirty || isSchemaChanges
  const selectedOrientation = useSelector(selectSelectedTemplateOrientation)
  const selectedChildIndex = useSelector(selectSelectedTemplateChildIndex)
  const contentId = useSelector(selectContentIdFromPathname)
  const masterOrChildTemplate = useSelector(selectTemplateById(contentId))
  // current template that user is editing
  const currentTemplate = isMasterTemplateUI(masterOrChildTemplate)
    ? masterOrChildTemplate.childTemplates[selectedChildIndex]
    : masterOrChildTemplate

  const childTemplateIds = isMasterTemplateUI(masterOrChildTemplate)
    ? masterOrChildTemplate.childTemplateIds
    : undefined

  const hasEnoughProducts =
    isMasterTemplateUI(masterOrChildTemplate) && currentTemplate
      ? validateProductDataComponentCount(selectedChildIndex + 1, currentTemplate.schema.components)
      : undefined

  const userHasPermission = user
    ? validateUserAccess(user, FeaturePermission.templates, FeaturePermissionLevel.write)
    : false

  if (!masterOrChildTemplate || !currentTemplate?.components || isLoading) {
    return <LinearProgress />
  }

  const {
    type,
    schema,
    components: { [selectedOrientation]: components },
    templateId
  } = currentTemplate

  const templateFormProps = {
    onSubmit: bindSubmitActionToPromise(dispatch, updateTemplateContent),
    initialValues: {
      ...components
    },
    selectedOrientation
  }
  const toolbarProps: EditTemplateToolbarOwnProps = {
    selectedOrientation,
    formEnabled,
    type,
    userHasPermission,
    isDirty: isFormDirty,
    saveDisabled: hasEnoughProducts === false,
    showDeleteChildTemplate: childTemplateIds ? true : false,
    productCount: selectedChildIndex + 1
  }

  return (
    <Fragment>
      {userHasPermission && <Prompt when={isFormDirty} message={t('lists.unsavedChangesPrompt')} />}
      <PageTitle
        contentType={BreadcrumbContentType.templates}
        contentId={templateId}
        contentName={masterOrChildTemplate.name}
      />
      <EditTemplateToolbar {...toolbarProps} />
      <Grid container spacing={8}>
        <Grid item xs={12} sm={12} md={12} lg={5}>
          {schema && <TemplateForm {...templateFormProps} />}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={7} className={classes.previewGridItem}>
          {childTemplateIds && (
            <ProductCountSelector
              unsavedChanges={isFormDirty}
              selectedChildIndex={selectedChildIndex}
              childTemplateIds={childTemplateIds}
            />
          )}
          {schema && (
            <TemplatePreview
              key={nanoid()}
              template={currentTemplate}
              orientation={selectedOrientation}
              formName='TemplateForm'
              selectedChildIndex={selectedChildIndex}
              showProductCount={childTemplateIds === undefined}
            />
          )}
        </Grid>
      </Grid>
      {hasEnoughProducts === false && (
        <Snackbar open={true}>
          <div>
            <Alert severity='error'>
              {t('templates.editor.productCountAlert', { productCount: selectedChildIndex + 1 })}
            </Alert>
          </div>
        </Snackbar>
      )}
    </Fragment>
  )
}

export default EditTemplate
