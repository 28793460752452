import React, { useState, useEffect } from 'react'
import {
  reduxForm,
  Field,
  change,
  formValueSelector,
  FormSection,
  InjectedFormProps
} from 'redux-form'
import { Typography, Grid, CircularProgress, InputAdornment } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { TFunction } from 'i18next'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { ReduxTextField } from '../../../../../../components/FormInput/ReduxWrappers'
import SimpleExtensionPanel from '../../../../../../components/ExtensionPanels/SimpleExtensionPanel'
import { StateInterface } from '../../../../../../types/states'
import { ContentRssFeedFormData } from '../../../../../../types/contents'
import API from '../../../../../../services/api/screens'
import { rssFeedDefaultAvailableProps } from '../../../../../../config/rssFeedDefault'
import { resetRssFeedVisibleProps } from '../../../../../../utils/fabric/customDispatches'
import { parseStringToIntNumber } from '../../../../../../utils/forms'
import TextSettings from './TextSettings'
import ImageSettings from './ImageSettings'
import GeneralSettings from './GeneralSettings'
import VisiblePropsSelector from './VisiblePropsSelector'

const useStyles = makeStyles(() => ({
  accordionSummaryRootClass: {
    backgroundColor: '#F5F5F5',
    borderBottom: '1px solid rgba(0, 0, 0, 0.09)'
  },
  subTitle: {
    fontSize: '17px',
    fontWeight: 500
  },
  progressIcon: {
    marginLeft: '10px',
    size: '15px'
  },
  accordionRoot: {
    display: 'flex',
    flexDirection: 'column'
  },
  accordionSection: {
    margin: '8px 0px 8px 0px'
  },
  errorText: {
    color: 'red',
    fontSize: '13px'
  }
}))

interface AvailableProps {
  channelTitle: boolean
  channelDescription: boolean
  itemTitle: boolean
  itemDescription: boolean
  itemImage: boolean
  itemPubDate: boolean
}

interface StateProps {
  url: string
  itemCount: number
  channelTitleVisible: boolean
  channelDescriptionVisible: boolean
  itemTitleVisible: boolean
  itemDescriptionVisible: boolean
  itemImageVisible: boolean
  itemPubDateVisible: boolean
  imageBorderRadius: string | number
}

interface DispatchProps {
  changeValue: (field: string, value: any) => void
}

interface OwnProps {
  t: TFunction
}

type FormProps = ContentRssFeedFormData

type ComponentProps = StateProps & DispatchProps & OwnProps

const RssFeedForm: React.FC<ComponentProps & InjectedFormProps<FormProps, ComponentProps>> = ({
  url,
  itemCount,
  channelTitleVisible,
  channelDescriptionVisible,
  itemTitleVisible,
  itemDescriptionVisible,
  itemImageVisible,
  itemPubDateVisible,
  changeValue,
  imageBorderRadius,
  t
}) => {
  const classes = useStyles()
  const [loadingRss, setLoadingRss] = useState(false)
  const [errorLoading, setErrorLoading] = useState(false)
  const [availableProperties, setAvailableProperties] = useState<AvailableProps>(
    rssFeedDefaultAvailableProps
  )

  useEffect(() => {
    const updateURL = async () => {
      setErrorLoading(false)
      if (url) {
        setLoadingRss(true)
        try {
          const { availableProps } = await API.getRssFeed(url, itemCount)
          setAvailableProperties(availableProps)
          setLoadingRss(false)
        } catch (error) {
          setErrorLoading(true)
        }
        setLoadingRss(false)
      }
    }
    updateURL()
  }, [url, itemCount])

  return (
    <Grid container>
      <Grid item xs={12}>
        <SimpleExtensionPanel
          title={t('contents.objects.type.CustomRssFeed')}
          isOpen={false}
          accordionSummaryRootClass={classes.accordionSummaryRootClass}>
          <div className={classes.accordionRoot}>
            <div className={classes.accordionSection}>
              <Typography className={classes.subTitle}>
                {t('contents.widgets.rssFeed.source')}
              </Typography>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Field
                  style={{ width: '300px' }}
                  name='cRssFeedProps.url'
                  placeholder={t('contents.widgets.rssFeed.source')}
                  onChange={resetRssFeedVisibleProps}
                  component={ReduxTextField}
                />
                {loadingRss && <CircularProgress className={classes.progressIcon} size={25} />}
              </div>
            </div>
            {errorLoading && (
              <Typography className={classes.errorText}>
                {t('contents.widgets.rssFeed.errorLoading')}
              </Typography>
            )}
            {url && !loadingRss && !errorLoading && (
              <>
                <div className={classes.accordionSection}>
                  <FormSection name='cRssFeedProps.visibleProperties'>
                    <Typography className={classes.subTitle}>
                      {t('contents.actions.showProperties')}
                    </Typography>
                    <VisiblePropsSelector availableProps={availableProperties} />
                  </FormSection>
                </div>
                <div className={classes.accordionSection}>
                  <Typography className={classes.subTitle}>
                    {t('contents.widgets.rssFeed.articlesToShow')}
                  </Typography>
                  <Field
                    name='cRssFeedProps.itemCount'
                    type='number'
                    component={ReduxTextField}
                    placeholder={t('contents.widgets.rssFeed.articlesToShow')}
                    parse={parseStringToIntNumber}
                    normalize={(value: number | null, prevValue: number) => {
                      if (!value) {
                        return prevValue
                      }
                      if (value <= 0) {
                        return 1
                      }
                      return value
                    }}
                    InputProps={{
                      inputProps: { min: 1 }
                    }}
                    style={{ width: '80px' }}
                  />
                </div>
                <div className={classes.accordionSection}>
                  <Typography className={classes.subTitle}>
                    {t('contents.widgets.rssFeed.interval')}
                  </Typography>
                  <Field
                    name='cRssFeedProps.interval'
                    type='number'
                    component={ReduxTextField}
                    format={(value: number) => value / 1000}
                    parse={parseStringToIntNumber}
                    normalize={(value: number | null, prevValue: number) => {
                      if (!value) {
                        return prevValue
                      }
                      if (value <= 0) {
                        return 1000
                      }
                      return value * 1000
                    }}
                    InputProps={{
                      inputProps: { min: 1 },
                      endAdornment: <InputAdornment position='start'>s</InputAdornment>
                    }}
                    style={{ width: '80px' }}
                  />
                </div>
              </>
            )}
          </div>
        </SimpleExtensionPanel>
      </Grid>
      <Grid item xs={12}>
        <SimpleExtensionPanel
          title={t('contents.widgets.rssFeed.styleSettings')}
          isOpen={false}
          accordionSummaryRootClass={classes.accordionSummaryRootClass}>
          {url && !errorLoading && !loadingRss ? (
            <div>
              <GeneralSettings
                title={t('contents.widgets.rssFeed.generalSettings')}
                itemImageVisible={itemImageVisible}
              />
              {channelTitleVisible && (
                <TextSettings
                  title={t('contents.widgets.rssFeed.channelTitle')}
                  namePrefix='cRssFeedProps.styles.channelTitleStyles'
                />
              )}

              {channelDescriptionVisible && (
                <TextSettings
                  title={t('contents.widgets.rssFeed.channelDesc')}
                  namePrefix='cRssFeedProps.styles.channelDescriptionStyles'
                />
              )}

              {itemTitleVisible && (
                <TextSettings
                  title={t('contents.widgets.rssFeed.articleTitle')}
                  namePrefix='cRssFeedProps.styles.itemTitleStyles'
                />
              )}

              {itemDescriptionVisible && (
                <TextSettings
                  title={t('contents.widgets.rssFeed.articleDesc')}
                  namePrefix='cRssFeedProps.styles.itemDescriptionStyles'
                />
              )}

              {itemImageVisible && (
                <ImageSettings imageBorderRadius={imageBorderRadius} changeValue={changeValue} />
              )}

              {itemPubDateVisible && (
                <TextSettings
                  title={t('contents.widgets.rssFeed.articlePubDate')}
                  namePrefix='cRssFeedProps.styles.itemPubDateStyles'
                />
              )}
            </div>
          ) : (
            <Typography>{t('contents.widgets.rssFeed.enterValidURL')}</Typography>
          )}
        </SimpleExtensionPanel>
      </Grid>
    </Grid>
  )
}

const selector = formValueSelector('RssFeedForm')

const mapStateToProps = (state: StateInterface): StateProps => ({
  url: selector(state, 'cRssFeedProps.url') || '',
  itemCount: selector(state, 'cRssFeedProps.itemCount') || 20,
  channelTitleVisible: selector(state, 'cRssFeedProps.visibleProperties.channelTitle'),
  channelDescriptionVisible: selector(state, 'cRssFeedProps.visibleProperties.channelDescription'),
  itemTitleVisible: selector(state, 'cRssFeedProps.visibleProperties.itemTitle'),
  itemDescriptionVisible: selector(state, 'cRssFeedProps.visibleProperties.itemDescription'),
  itemImageVisible: selector(state, 'cRssFeedProps.visibleProperties.itemImage'),
  itemPubDateVisible: selector(state, 'cRssFeedProps.visibleProperties.itemPubDate'),
  imageBorderRadius: selector(state, 'cRssFeedProps.styles.itemImageStyles.borderRadius')
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  changeValue: (field: string, value: any) => dispatch(change('RssFeedForm', field, value))
})

export default connect<StateProps, DispatchProps, {}, StateInterface>(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<{}, ComponentProps>({
    form: 'RssFeedForm',
    enableReinitialize: true
  })(RssFeedForm)
)
