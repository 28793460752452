import { Grid, Typography, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { path } from 'ramda'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import {
  ProductPlaylistItemUI,
  isPharmacyProduct,
  GeneralProductUI,
  RetailProduct,
  PricerProduct,
  PharmacyProduct
} from '@seesignage/seesignage-utils'
import { TFunction } from 'i18next'
import ShoppingIcon from 'mdi-react/ShoppingIcon'
import { parseProductData, getPharmacyProductTitle } from '../../../../../utils/products'
import { PlaylistItemCardRootProps } from '../../../../../types/playlists'
import Media from '../../../../../components/Media'
import { StateInterface } from '../../../../../types/states'
import { selectTemplateById } from '../../../../../selectors/templates'
import useIsUserDeviceMobile from '../../../../../hooks/deviceDetect'
import { StateTemplate } from '../../../../../types/templates'
import ItemIcons from './ItemIcons'
import SelectedIndicator from './SelectedIndicator'

const useStyles = makeStyles(() => ({
  item: {
    height: 145,
    overflow: 'hidden'
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain' as any
  },
  imageArea: {
    height: '100%'
  },
  descriptionArea: {
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)'
  },
  mediaText: {
    padding: '4px',
    width: '100%'
  },
  descriptionText: {
    width: '100%'
  },
  selectIndicatorContainer: {
    position: 'relative' as any
  }
}))

interface TitleTextProps {
  title?: string
  isMobile: boolean
}
const TitleText = ({ title, isMobile }: TitleTextProps) => (
  <Tooltip disableInteractive title={title || ''}>
    <div style={{ width: '95%', padding: 4 }}>
      <Typography noWrap variant={isMobile ? 'caption' : 'subtitle1'} gutterBottom>
        {title || '-'}
      </Typography>
    </div>
  </Tooltip>
)

interface GeneratePriceTextParams {
  price?: number | null
  unit?: string | null
  discountBatchSize?: number | null
  t: TFunction
}

const generatePriceText = ({ price, unit, discountBatchSize, t }: GeneratePriceTextParams) => {
  if (price) {
    const euros = price / 100
    const translatedUnit = unit ? t(`product.unit.${unit}`, { defaultValue: unit }) : undefined
    return translatedUnit
      ? `${euros} € / ${discountBatchSize || ''}${translatedUnit}`
      : `${euros} €`
  }
  return ''
}

const generateItemContent = (
  isMobile: boolean,
  t: TFunction,
  productId: string,
  product?: GeneralProductUI | RetailProduct | PricerProduct | PharmacyProduct | null
) => {
  if (!product) {
    return (
      <Fragment>
        <TitleText title={t('playlists.editPlaylist.errors.productNotFound')} isMobile={isMobile} />
        <Typography variant='caption' color='error'>
          {t('playlists.editPlaylist.errors.productNotFoundDescription', { productId })}
        </Typography>
      </Fragment>
    )
  }
  if (isPharmacyProduct(product)) {
    const { price: productPrice, discountPrice } = product
    const price = discountPrice || productPrice
    return (
      <Fragment>
        <TitleText title={getPharmacyProductTitle(product)} isMobile={isMobile} />
        <Typography variant='caption'>
          {price ? (price / 100).toLocaleString('fi', { style: 'currency', currency: 'EUR' }) : ''}
        </Typography>
      </Fragment>
    )
  } else {
    const { name, price, unit, discountBatchSize } = parseProductData(product)
    return (
      <Fragment>
        <TitleText title={name} isMobile={isMobile} />
        <Typography variant='caption'>
          {generatePriceText({
            price,
            unit,
            discountBatchSize,
            t
          })}
        </Typography>
      </Fragment>
    )
  }
}

interface OwnProps extends PlaylistItemCardRootProps {
  item: ProductPlaylistItemUI
}

interface StateProps {
  template?: StateTemplate
}

type ProductAsItemProps = OwnProps & StateProps

const ProductAsItem: React.FC<ProductAsItemProps> = ({
  item,
  isItemSelected,
  template,
  itemPercentage,
  showPercentage
}) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const isMobile = useIsUserDeviceMobile()
  const { url, image } = parseProductData(item.product)
  return (
    <Grid container className={classes.item}>
      <Grid item xs={4} className={classes.imageArea}>
        <Media
          imageClass={classes.image}
          videoClass={classes.image}
          type={'image'}
          url={url || image?.Pieni?.Url}
        />
      </Grid>
      <Grid item xs={8} className={classes.descriptionArea}>
        <Grid container style={{ height: '100%' }}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={1}>
                <ShoppingIcon />
              </Grid>
              <Grid item xs={10}>
                {generateItemContent(isMobile, t, item.productId, item.product)}
                <div className={classes.descriptionText}>
                  <Typography noWrap variant={'caption'}>
                    {path(['name'], template)}
                  </Typography>
                </div>
              </Grid>
              <SelectedIndicator
                isItemSelected={isItemSelected}
                showPercentage={showPercentage}
                itemPercentage={itemPercentage}
              />
            </Grid>
          </Grid>
        </Grid>
        <ItemIcons item={item} />
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state: StateInterface, ownProps: OwnProps): StateProps => ({
  template: selectTemplateById(ownProps.item.templateId)(state)
})

export default connect(mapStateToProps)(ProductAsItem)
