import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
  CardHeader,
  Tooltip,
  LinearProgress
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import FolderIcon from 'mdi-react/FolderIcon'
import PencilIcon from 'mdi-react/PencilIcon'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { Media, MediaFolder } from '@seesignage/seesignage-utils'
import DeleteIcon from 'mdi-react/DeleteIcon'
import IntegrationIcon from 'mdi-react/AllInclusiveIcon'
import Truncate from 'react-truncate'
import FolderMoveIcon from 'mdi-react/FolderMoveIcon'
import { useDropzone } from 'react-dropzone'
import { closeDialog } from '../../../actions/dialogs'
import { navigate } from '../../../actions/routes'
import Menu from '../../../components/Menu'
import { selectKeysByFolder, selectMediaDeleteSubmitting } from '../../../selectors/media'
import { selectEnvironmentIdFromPathname } from '../../../selectors/routing'
import Dialog from '../../Dialog'
import { StateInterface } from '../../../types/states'
import { CloseDialog } from '../../../types/actions'
import { selectIsUserDeviceMobile } from '../../../selectors/users'
import { OpenDialogButtonType } from '../../../types/dialogs'
import { deleteFolder } from '../../../actions/media'
import CreateFolderForm from '../Forms/CreateFolderForm'
import MoveToFolderForm from '../Forms/MoveToFolderForm'
import colors from '../../../styles/common/colors'

const useStyles = makeStyles(() => ({
  card: {
    width: 250,
    height: 120,
    position: 'relative'
  },
  cardMobile: {
    width: 170,
    height: 120,
    position: 'relative'
  },
  menuContainer: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  integrationIcon: { display: 'block', position: 'absolute', right: 4, bottom: 0 }
}))

interface OwnProps {
  folder: MediaFolder
  setDraggingOverFolderId: React.Dispatch<React.SetStateAction<string | undefined>>
}

interface StateProps {
  files: Media[]
  mediaIsDeleting?: boolean
  environmentId?: string
  isMobile: boolean
}

interface DispatchProps {
  navigate: (path: string) => void
  deleteFolder: (id: string) => void
  closeDialog: CloseDialog
}

type MediaFolderCardProps = OwnProps & StateProps & DispatchProps

const dragStyle = {
  background: 'rgba(0, 174, 239, .05)',
  outline: 'thick solid rgba(0, 174, 239, .7)',
  transition: '200ms'
}

const MediaFolderCard: React.FC<MediaFolderCardProps> = ({
  folder,
  files,
  environmentId,
  navigate,
  closeDialog,
  deleteFolder,
  isMobile,
  mediaIsDeleting,
  setDraggingOverFolderId
}) => {
  const classes = useStyles()
  const [t] = useTranslation()

  const { getRootProps, isDragActive } = useDropzone({
    onDragEnter: () => {
      setDraggingOverFolderId(folder.folderId)
    },
    onDragLeave: () => {
      setDraggingOverFolderId(undefined)
    },
    noClick: true,
    noKeyboard: true
  })

  return (
    <div
      data-cy='folder-dropzone-wrapper'
      {...getRootProps({ className: 'folder-drop-zone' })}
      style={isDragActive ? dragStyle : {}}>
      <Card
        data-cy={`folder-${folder.name}`}
        className={isMobile ? classes.cardMobile : classes.card}>
        <CardActionArea
          data-cy={`open-folder-${folder.name}`}
          onClick={() =>
            navigate(`/environments/${environmentId}/media/folders/${folder.folderId}`)
          }>
          <CardHeader
            avatar={<FolderIcon />}
            title={
              <Tooltip disableInteractive title={folder.name}>
                <>
                  <Truncate lines={2} width={isMobile ? 65 : 150}>
                    {folder.name}
                  </Truncate>
                </>
              </Tooltip>
            }
          />
          <CardContent>
            <Typography variant='caption'>
              {t('media.fileCount', { count: files.length })}
            </Typography>
            {folder.integration && (
              <Tooltip disableInteractive title={t('media.folders.integrationFolder')}>
                <span className={classes.integrationIcon}>
                  <IntegrationIcon color={colors.seesignageColor} />
                </span>
              </Tooltip>
            )}
          </CardContent>
        </CardActionArea>
        <div data-cy={`folder-options-${folder.name}`} className={classes.menuContainer}>
          <Menu
            tooltipTitle={t('media.folderOptions')}
            items={[
              <div
                data-cy={`rename-folder-${folder.name}`}
                key={`renameFolder.${folder.name}`}
                // fix problem with form input field letter typing and menu item
                onKeyDown={e => e.stopPropagation()}>
                <Dialog
                  dialogId='UpdateFolderDialog'
                  title={t('media.edit')}
                  buttonLabel={t('media.edit')}
                  openDialogButtonType={OpenDialogButtonType.menuItem}
                  ButtonIcon={PencilIcon}
                  Content={
                    <CreateFolderForm
                      dialogId='UpdateFolderDialog'
                      isUpdate
                      submitButtonLabel={t('general.update')}
                      initialValues={{
                        name: folder.name,
                        folderId: folder.folderId
                      }}
                      useFormOnSubmit
                    />
                  }
                />
              </div>,
              <div
                data-cy={`move-folder-${folder.name}`}
                key={`moveFolder.${folder.name}`}
                // fix problem with form input field letter typing and menu item
                onKeyDown={e => e.stopPropagation()}>
                <Dialog
                  dialogId={`moveFolder.${folder.folderId}`}
                  title={t('media.folders.move')}
                  buttonLabel={t('general.move')}
                  openDialogButtonType={OpenDialogButtonType.menuItem}
                  ButtonIcon={FolderMoveIcon}
                  Content={
                    <MoveToFolderForm
                      closeDialog={closeDialog}
                      dialogId={`moveFolder.${folder.folderId}`}
                      submitButtonLabel={t('general.move')}
                      isFolder
                      folderId={folder.folderId}
                      initialValues={{ folderId: folder.folderId }}
                    />
                  }
                />
              </div>,
              <div data-cy={`delete-folder-${folder.name}`} key={`deleteFolder.${folder.name}`}>
                <Dialog
                  dialogId={`deleteFolder.${folder.folderId}`}
                  title={t('media.folders.delete')}
                  buttonLabel={t('media.folders.delete')}
                  openDialogButtonType={OpenDialogButtonType.menuItem}
                  ButtonIcon={DeleteIcon}
                  Content={
                    <Fragment>
                      <DialogContent>
                        <DialogContentText>
                          {t('media.folders.deleteContentText')}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          disabled={mediaIsDeleting}
                          onClick={() => closeDialog(`deleteFolder.${folder.folderId}`)}
                          color='primary'>
                          {t('general.cancel')}
                        </Button>
                        <Button
                          disabled={mediaIsDeleting}
                          onClick={() => deleteFolder(folder.folderId)}
                          color='primary'
                          autoFocus>
                          {t('general.delete')}
                        </Button>
                      </DialogActions>
                      {mediaIsDeleting && <LinearProgress />}
                    </Fragment>
                  }
                />
              </div>
            ]}
          />
        </div>
      </Card>
    </div>
  )
}
const mapStateToProps = (state: StateInterface, props: OwnProps): StateProps => ({
  environmentId: selectEnvironmentIdFromPathname(state),
  files: selectKeysByFolder(props.folder.folderId)(state),
  isMobile: selectIsUserDeviceMobile(state),
  mediaIsDeleting: selectMediaDeleteSubmitting(state)
})
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  navigate: (path: string) => dispatch(navigate(path)),
  closeDialog: (dialogId: string) => dispatch(closeDialog(dialogId)),
  deleteFolder: (id: string) => dispatch(deleteFolder(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(MediaFolderCard)
