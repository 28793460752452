import React from 'react'
import { ResponsiveContainer, ScatterChart, XAxis, YAxis, Scatter, Cell, Tooltip } from 'recharts'
import dateFormat from 'dateformat'
import { Status } from '@seesignage/seesignage-utils'
import { generateStatusesToDataPoints, getTimeRangeStartTime } from '../../utils/screens'
import colors from '../../styles/common/colors'
import { TimeRange } from '../../types/screens'
import StatusTooltip from './StatusTooltip'

const getColor = (dataPoint: any) => {
  if (dataPoint.isEthernetConnected || dataPoint.signalStrength >= 0.8) {
    return colors.signalColors.excellent
  }
  if (dataPoint.signalStrength > 0.6) {
    return colors.signalColors.good
  }
  if (dataPoint.signalStrength > 0.3) {
    return colors.signalColors.satisfying
  }
  if (dataPoint.signalStrength > 0) {
    return colors.signalColors.bad
  }
  if (dataPoint.isWifiOn) {
    return colors.signalColors.excellent
  }
  return colors.signalColors.no
}

interface StatusesScatterChartProps {
  statuses: Status[]
  timeRange?: TimeRange
}

const getTicks = (startTime: number) => {
  if (startTime === -1) {
    return undefined
  }
  const currentTime = Date.now()
  const difference = currentTime - startTime

  return [
    startTime,
    startTime + difference / 4,
    startTime + 2 * (difference / 4),
    startTime + 3 * (difference / 4),
    currentTime
  ]
}

const StatusesScatterChart: React.FC<StatusesScatterChartProps> = ({ statuses, timeRange }) => {
  const dataPoints = generateStatusesToDataPoints(statuses)
  const startTime = getTimeRangeStartTime(timeRange)
  const ticks = startTime !== -1 ? getTicks(startTime) : undefined
  return (
    <ResponsiveContainer width='100%' height={50}>
      <ScatterChart>
        <XAxis
          dataKey='date'
          domain={startTime !== -1 ? [startTime, Date.now()] : ['auto', 'auto']}
          name='Date'
          tickFormatter={(tick: any) => dateFormat(tick, 'HH:MM dd-mm')}
          type='number'
          interval='preserveStartEnd'
          tickMargin={10}
          ticks={ticks}
          tick={{ fontFamily: 'Roboto, Helvetica' }}
          padding={{ left: 50, right: 50 }}
        />
        <YAxis
          hide
          dataKey='value'
          name='Value'
          tickFormatter={(tick: string) => `screen ${tick}`}
        />
        <Tooltip wrapperStyle={{ zIndex: 1000 }} content={<StatusTooltip />} />
        <Scatter data={dataPoints} name='Values' fill='#45A2DB'>
          {dataPoints.map((dataPoint: any, index: number) => (
            <Cell key={`cell-${index}`} fill={getColor(dataPoint)} />
          ))}
        </Scatter>
      </ScatterChart>
    </ResponsiveContainer>
  )
}

export default StatusesScatterChart
