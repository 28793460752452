import { handleActions } from 'redux-actions'
import { Media, ContentUIWithResources, ContentUI, Content } from '@seesignage/seesignage-utils'
import { ContentsState, IndexById } from '../types/states'
import { Action } from '../types/actions'
import {
  contentFormChange,
  createContentSuccess,
  createObjectSuccess,
  deleteContentSuccess,
  deselectObject,
  getContent,
  getContentFail,
  getContentSuccess,
  initContentEditorSuccess,
  listContents,
  listContentsFail,
  listContentsSuccess,
  saveCanvas,
  saveCanvasSuccess,
  saveCanvasFail,
  selectObject,
  updateContentSuccess,
  zoomCanvasSuccess,
  addMediaContentSuccess,
  updateMediaSourceSuccess,
  initContentEditor,
  initContentEditorFail,
  setBackgroundImageSuccess,
  selectedObjectActionSuccess,
  canvasActionSuccess,
  objectActionByIdSuccess,
  updateEditorCanvas,
  setContentModified,
  closeEditorCanvas,
  duplicateContentSuccess,
  selectUnusedContents,
  openContentObjectsView,
  resetContents,
  customContentChangeAction,
  contentDrawerFormChangeSuccess,
  setEditorCursorModeSuccess,
  setDrawingObjectId,
  reorderObjectsSuccess,
  createObject,
  createObjectFail,
  updateEditorOption,
  setContentId
} from '../actions/contents'
import {
  clearCanvas,
  createFabricMediaObject,
  getCanvasAsJSON,
  updateSelectedObject,
  updateSelectedWidgetProps
} from '../utils/fabric/canvas'
import { setWorkareaBackgroundImage } from '../utils/fabric/canvasWorkarea'
import { EditorOptions, EditorCursorMode } from '../types/contents'
import { SelectedInfopage } from '../types/infopages'

const initialState: ContentsState = {
  editor: {
    options: {
      zoom: 0.5,
      cursorMode: EditorCursorMode.move
    },
    selectedObjects: undefined,
    contentId: undefined,
    initDone: false
  },
  contents: {},
  isLoading: false,
  isContentModified: false
}

const contents = handleActions<ContentsState, any>(
  {
    [resetContents]: () => initialState,
    [createContentSuccess]: (state, { payload: content }: Action<ContentUI>) => ({
      ...state,
      contents: {
        ...state.contents,
        [content.contentId]: content
      }
    }),
    [updateContentSuccess]: (state, { payload: content }: Action<Content>) => ({
      ...state,
      contents: {
        ...state.contents,
        [content.contentId]: {
          ...content,
          // Update Content with UI only properties, Content => ContentUI
          inUse: (state.contents[content.contentId] as ContentUI).inUse,
          thumbnailUrl: (state.contents[content.contentId] as ContentUI).thumbnailUrl,
          responseType: (state.contents[content.contentId] as ContentUI).responseType
        }
      }
    }),
    [setContentId]: (state, { payload: contentId }) => ({
      ...state,
      editor: {
        ...state.editor,
        contentId
      }
    }),
    [saveCanvas]: state => ({
      ...state,
      isLoading: true
    }),
    [saveCanvasSuccess]: (state, { payload: content }: Action<Content>) => ({
      ...state,
      contents: {
        ...state.contents,
        [content.contentId]: {
          ...content,
          // Update Content with UI only properties, Content => ContentUI
          inUse: (state.contents[content.contentId] as ContentUI).inUse,
          thumbnailUrl: (state.contents[content.contentId] as ContentUI).thumbnailUrl,
          responseType: (state.contents[content.contentId] as ContentUI).responseType
        }
      },
      isLoading: false,
      isContentModified: false
    }),
    [saveCanvasFail]: state => ({
      ...state,
      isLoading: false
    }),
    [getContent]: state => ({
      ...state,
      isLoading: true
    }),
    [getContentSuccess]: (state, { payload: content }: Action<ContentUIWithResources>) => ({
      ...state,
      contents: {
        ...state.contents,
        [content.contentId]: content
      },
      isLoading: false
    }),
    [getContentFail]: state => ({
      ...state,
      isLoading: false
    }),
    [listContents]: state => ({
      ...state,
      isLoading: true
    }),
    [listContentsFail]: state => ({
      ...state,
      isLoading: false
    }),
    [deleteContentSuccess]: (state, { payload: contentId }: Action<string>) => {
      const contents = { ...state.contents }
      delete contents[contentId]
      return {
        ...state,
        contents
      }
    },
    [listContentsSuccess]: (state, { payload: contentsArray }: Action<ContentUI[]>) => {
      const contents = contentsArray.reduce((contentsAcc: IndexById<ContentUI>, content) => {
        contentsAcc[content.contentId] = {
          ...content
        }
        return contentsAcc
      }, {})
      return {
        ...state,
        contents,
        isLoading: false
      }
    },
    [initContentEditor]: state => {
      return {
        ...state,
        editor: {
          ...state.editor,
          initDone: false
        }
      }
    },
    [initContentEditorFail]: state => {
      return {
        ...state,
        editor: {
          ...state.editor,
          initDone: false
        }
      }
    },
    [closeEditorCanvas]: state => {
      clearCanvas()
      return {
        ...state,
        editor: {
          options: {
            ...state.editor.options,
            isObjectsViewOpen: false,
            cursorMode: EditorCursorMode.move
          },
          selectedObjects: undefined,
          contentId: undefined,
          initDone: false
        },
        contents: {},
        initDone: false
      }
    },
    [initContentEditorSuccess]: (state, { payload: options }: Action<EditorOptions>) => {
      return {
        ...state,
        editor: {
          ...state.editor,
          options: {
            ...state.editor.options,
            ...options
          },
          initDone: true,
          canvas: getCanvasAsJSON()
        },
        isContentModified: false
      }
    },
    [updateEditorOption]: (state, { payload: options }: Action<EditorOptions>) => {
      return {
        ...state,
        editor: {
          ...state.editor,
          options: {
            ...state.editor.options,
            ...options
          },
          canvas: getCanvasAsJSON()
        },
        isContentModified: true
      }
    },
    [openContentObjectsView]: state => {
      return {
        ...state,
        editor: {
          ...state.editor,
          // load current canvas objects in order to show layers
          canvas: getCanvasAsJSON()
        }
      }
    },
    [selectObject]: (state, { payload: selectedObjects }: Action<fabric.Object[]>) => {
      return {
        ...state,
        editor: {
          ...state.editor,
          selectedObjects
        }
      }
    },
    [deselectObject]: state => {
      return {
        ...state,
        editor: {
          ...state.editor,
          selectedObjects: undefined
        }
      }
    },
    [createObject]: state => ({
      ...state,
      isLoading: true
    }),
    [createObjectFail]: state => ({
      ...state,
      isLoading: false
    }),
    [createObjectSuccess]: state => {
      return {
        ...state,
        editor: {
          ...state.editor,
          canvas: getCanvasAsJSON()
        },
        isContentModified: true,
        isLoading: false
      }
    },
    [canvasActionSuccess]: state => {
      return {
        ...state,
        editor: {
          ...state.editor,
          canvas: getCanvasAsJSON()
        },
        isContentModified: true
      }
    },
    [selectedObjectActionSuccess]: state => {
      return {
        ...state,
        editor: {
          ...state.editor,
          canvas: getCanvasAsJSON()
        },
        isContentModified: true
      }
    },
    [objectActionByIdSuccess]: state => {
      return {
        ...state,
        editor: {
          ...state.editor,
          canvas: getCanvasAsJSON()
        },
        isContentModified: true
      }
    },
    [customContentChangeAction]: state => {
      return {
        ...state,
        isContentModified: true
      }
    },
    [updateEditorCanvas]: state => {
      return {
        ...state,
        editor: {
          ...state.editor,
          canvas: getCanvasAsJSON()
        }
      }
    },
    [setContentModified]: state => {
      window.temporayDrawingCanvas.clear()
      return {
        ...state,
        isContentModified: true,
        editor: {
          ...state.editor,
          canvas: getCanvasAsJSON()
        }
      }
    },
    [addMediaContentSuccess]: (state, { payload: media }: Action<Media>) => {
      createFabricMediaObject(media)
      return {
        ...state,
        editor: {
          ...state.editor,
          canvas: getCanvasAsJSON()
        },
        isContentModified: true
      }
    },
    [updateMediaSourceSuccess]: state => {
      return {
        ...state,
        editor: {
          ...state.editor,
          canvas: getCanvasAsJSON()
        },
        isContentModified: true
      }
    },
    [setBackgroundImageSuccess]: (state, { payload: media }: Action<Media>) => {
      const resolution = state.editor.options.resolution
      if (resolution && media.url) {
        setWorkareaBackgroundImage(media.key, media.url)
      }
      return {
        ...state,
        isContentModified: true
      }
    },
    [contentFormChange]: (
      state,
      { payload: changeValue }: Action<{ field: string; value: any }>
    ) => {
      updateSelectedObject(changeValue)
      return {
        ...state,
        isContentModified: true
      }
    },
    [contentDrawerFormChangeSuccess]: (
      state,
      {
        payload: { allValues, form, field, value }
      }: Action<{ field: string; value: any; allValues: any; form: string }>
    ) => {
      updateSelectedWidgetProps(allValues, form, field, value)
      return {
        ...state,
        isContentModified: true
      }
    },
    [zoomCanvasSuccess]: (state, { payload: { zoom } }: Action<{ zoom: number }>) => {
      return {
        ...state,
        editor: {
          ...state.editor,
          options: {
            ...state.editor.options,
            zoom
          }
        }
      }
    },
    [duplicateContentSuccess]: (state, { payload: duplicate }: Action<ContentUI>) => ({
      ...state,
      contents: {
        ...state.contents,
        [duplicate.contentId]: duplicate
      }
    }),
    [selectUnusedContents]: (state, { payload: selectedContents }: Action<SelectedInfopage[]>) => ({
      ...state,
      selectedContents
    }),
    [setEditorCursorModeSuccess]: (state, { payload: cursorMode }: Action<EditorCursorMode>) => ({
      ...state,
      editor: {
        ...state.editor,
        options: {
          ...state.editor.options,
          cursorMode
        },
        canvas: getCanvasAsJSON()
      }
    }),
    [setDrawingObjectId]: (state, { payload: drawingObjectId }: Action<string>) => ({
      ...state,
      editor: {
        ...state.editor,
        options: {
          ...state.editor.options,
          drawingObjectId
        }
      }
    }),
    [reorderObjectsSuccess]: state => {
      return {
        ...state,
        editor: {
          ...state.editor,
          canvas: getCanvasAsJSON()
        },
        isContentModified: true
      }
    }
  },
  initialState
)

export default contents
