import { Typography } from '@mui/material'
import React from 'react'
import { version } from '../../../package.json'

interface VersionProps {
  className: string
}

const Version: React.FC<VersionProps> = ({ className }) => (
  <div className={className}>
    <Typography variant='body1' display='block'>
      © {new Date().getFullYear()} SeeSignage
    </Typography>
    <Typography variant='caption'>v.{version}</Typography>
  </div>
)

export default Version
