import { Grid, Typography } from '@mui/material'
import React, { Fragment } from 'react'
import { Field, FieldArray, WrappedFieldArrayProps } from 'redux-form'
import { gridItemProps } from '../Fields/utils'
import { ReduxTextField } from '../../../../components/FormInput/ReduxWrappers'
import { MockProductType } from '../../../../types/mocks'
import FieldsExpansionPanel from '../../../../components/ExtensionPanels/FieldsExpansionPanel'

/** Same properties seems to exist in all Pricer Plaza products */
const stringProperties = [
  'itemName',
  'price',
  'itemGroup',
  'department',
  'presentation',
  'lastUpdated',
  'validFrom',
  // note: player will add imagePath (path to file in the device) and it does not exist in database
  'imagePath'
]

const nestedStringPropertiesVerkkokauppa = [
  'brandId',
  'brandName',
  'bulletPointsFi0',
  'bulletPointsFi1',
  'bulletPointsFi2',
  'bulletPointsFi3',
  'bulletPointsFi4',
  'descriptionShortFi',
  'hrefFi',
  // 'image300', note: 'imagePath' is used for mock
  // 'imageOrig',
  'pid',
  'priceCurrent',
  'priceCurrentFormatted',
  'priceCurrentTaxless',
  'priceDeposit',
  'priceDiscountAmount',
  'priceDiscountBeginAt',
  'priceDiscountEndAt',
  'priceDiscountId',
  'priceDiscountName',
  'priceDiscountPercentage',
  'priceDiscountType',
  'priceOriginal',
  'priceOriginalFormatted',
  'priceOriginalTaxless',
  'priceTaxRate',
  'priceUnitSymbol',
  'priceUnitTaxless',
  'priceUnitValue',
  'productId',
  'ratingAverageOverallRating',
  'ratingOther1',
  'ratingOther2',
  'ratingOther3',
  'ratingOther4',
  'ratingOther5',
  'ratingRecommendedCount',
  'ratingReviewCount'
]

const nestedStringPropertiesHankkija = [
  'assortmentGroup',
  'day30ComparisonPrice',
  'deliveryWarehouse',
  'discountEndDate',
  'discountStartDate',
  'itemNameWeb',
  'longDescriptionWeb',
  'planningGroup',
  'pricingType',
  'pricingUnit',
  'printingDate',
  'printingDate',
  'printingDateOrderNumber',
  'productCode',
  // 'productImageUrl', note: 'imagePath' is used for mock
  'productPageUrl',
  'productStatus',
  'salesGroup',
  'shelfAddress',
  'unitPrice',
  'unitPriceUnit',
  'vatId',
  'warrantyMonths'
]

const renderItems = ({
  fields,
  meta: { error, submitFailed },
  mockProductType
}: WrappedFieldArrayProps & PricerFormFieldsProps) => {
  const nestedStringProperties =
    mockProductType === MockProductType.pricerVerkkokauppa
      ? nestedStringPropertiesVerkkokauppa
      : nestedStringPropertiesHankkija
  return (
    <Fragment>
      {submitFailed && error && <span>{error}</span>}
      {fields.map((member: string, index: number) => (
        <FieldsExpansionPanel customLabel={`Product ${index + 1}`} key={`${index}-${member}`}>
          <Grid container spacing={2} key={`${index}-${member}`}>
            <Grid item xs={12}>
              <Typography variant='subtitle1'>Product {index + 1}</Typography>
            </Grid>
            {stringProperties.map((name, i) => (
              <Grid key={`${i}-${name}`} {...gridItemProps}>
                <Field
                  fullWidth
                  name={`${member}.${name}`}
                  label={name}
                  component={ReduxTextField}
                />
              </Grid>
            ))}
            {nestedStringProperties.map((name, i) => (
              <Grid key={`${i}-${name}`} {...gridItemProps}>
                <Field
                  fullWidth
                  name={`${member}.properties.${name}`}
                  label={`properties.${name}`}
                  component={ReduxTextField}
                />
              </Grid>
            ))}
          </Grid>
        </FieldsExpansionPanel>
      ))}
    </Fragment>
  )
}

interface PricerFormFieldsProps {
  mockProductType: MockProductType.pricerVerkkokauppa | MockProductType.pricerHankkija
}

const PricerFormFields = ({ mockProductType }: PricerFormFieldsProps) => (
  <Grid container spacing={2} alignItems='center'>
    <FieldArray name={mockProductType} component={renderItems} props={{ mockProductType }} />
  </Grid>
)

export default PricerFormFields
