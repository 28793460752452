import { takeLatest, all, call, put, select, take } from 'redux-saga/effects'
import { formValueSelector, SubmissionError, reset, change } from 'redux-form'
import {
  Media,
  selectAvailableTemplateOrientations,
  Template,
  InfopageExtensive,
  PlaylistItemType,
  InfopagePlaylistItemUI,
  Infopage
} from '@seesignage/seesignage-utils'
import { toast } from 'react-toastify'
import { push } from 'connected-react-router'
import nanoid from 'nanoid'
import {
  updateInfopageKeysSuccess,
  updateInfopageKeysFail,
  updateInfopageKeys,
  updateInfopageFail,
  updateInfopageSuccess,
  updateInfopage,
  listInfopagesSuccess,
  listInfopagesFail,
  listInfopages,
  getInfopageSuccess,
  getInfopageFail,
  getInfopage,
  updateInfopageSettingsSuccess,
  updateInfopageSettingsFail,
  updateInfopageSettings,
  createInfopageSuccess,
  createInfopageFail,
  createInfopage,
  deleteInfopageSuccess,
  deleteInfopageFail,
  deleteInfopage,
  copyInfopageSuccess,
  copyInfopageFail,
  copyInfopage,
  deselectInfopage,
  clearSelectedInfopageTemplate,
  createInfopageToPlaylistFail,
  createInfopageToPlaylistSuccess,
  createInfopageToPlaylist
} from '../actions/infopages'
import {
  selectContentIdFromPathname,
  selectEnvironmentIdFromPathname,
  selectViewFromPathname
} from '../selectors/routing'
import {
  handleCreateInfopageFormParams,
  handleUpdateInfopageFormParams,
  handleUpdateInfopageSettingsFormParams
} from '../types/formData'
import i18n from '../translations/i18n'
import { selectInfopageKeys, selectInfopagesLastEvaluatedKey } from '../selectors/infopages'
import InfopagesApi from '../services/api/infopages'
import { selectMediaByKey } from '../selectors/media'
import { closeDialog } from '../actions/dialogs'
import { selectTemplateById } from '../selectors/templates'
import { getTemplate, getTemplateFail, getTemplateSuccess } from '../actions/templates'
import {
  updateInfopageItem,
  createEmptyInfopageToPlaylist,
  addPlaylistItemSuccess
} from '../actions/playlists'
import { formatFormContent, parseFormContent } from '../utils/infopages'
import MediaApi from '../services/api/media'
import {
  SelectedInfopage,
  SelectedInfopageType,
  InfopagesLastEvaluatedKey
} from '../types/infopages'
import { deleteContent } from '../actions/contents'
import { selectUserSub } from '../selectors/users'

const getInfopageElement = (): Element | undefined => {
  const iframe = document.getElementById('infopagePreview') as any
  const innerDoc = iframe?.contentDocument
    ? iframe.contentDocument
    : iframe?.contentWindow?.document
  return innerDoc ? innerDoc.getElementById('rootContainer') : undefined
}

/** Get infopage and template */
function* handleGetInfopage({ payload }: { payload?: string }) {
  try {
    const environmentId: string | undefined = yield select(selectEnvironmentIdFromPathname)
    const infopageId: string | undefined = yield payload || select(selectContentIdFromPathname)

    if (environmentId && infopageId) {
      const infopage: InfopageExtensive = yield call(
        InfopagesApi.getInfopage,
        environmentId,
        infopageId
      )
      const { contentMetadata, content } = infopage

      formatFormContent(content, contentMetadata)
      infopage.content = content
      yield put(getInfopageSuccess(infopage))
    } else {
      throw new Error('Could not read environmentId or infopageId from pathname.')
    }
  } catch (error) {
    yield put(getInfopageFail(error.message))
  }
}

function* handleListInfopages({
  payload: { includePlaylistData, searchTerm }
}: {
  payload: { includePlaylistData?: boolean; searchTerm?: string; resetSearch?: boolean }
}) {
  try {
    const environmentId: string | undefined = yield select(selectEnvironmentIdFromPathname)
    if (environmentId) {
      const lastEvaluatedKey: InfopagesLastEvaluatedKey | undefined = yield select(
        selectInfopagesLastEvaluatedKey
      )

      const infopagesResponse: {
        infopages: Infopage[]
        lastEvaluatedKey: InfopagesLastEvaluatedKey | undefined
      } = yield call(
        InfopagesApi.listInfopages,
        environmentId,
        includePlaylistData,
        searchTerm,
        lastEvaluatedKey?.infopageId,
        lastEvaluatedKey?.name
      )

      yield put(
        listInfopagesSuccess({
          infopages: infopagesResponse.infopages,
          lastEvaluatedKey: infopagesResponse.lastEvaluatedKey
        })
      )
    } else {
      throw new Error('Could not read environmentId from pathname.')
    }
  } catch (error) {
    yield put(listInfopagesFail(error.message))
  }
}

/**
 * Saga handler for creating new empty infopage from Infopages view.
 */
function* handleCreateInfopage({
  payload: { formData, resolve, reject }
}: handleCreateInfopageFormParams) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const { template, name, content: formContent, selectedOrientation } = formData
    const { templateId, templateEnvironmentId } = template
    const infopage = {
      name,
      content: formContent || {},
      selectedOrientation,
      templateEnvironmentId,
      templateId
    }
    const newInfopage: InfopageExtensive = yield call(
      InfopagesApi.createInfopage,
      environmentId,
      infopage
    )
    const { content, contentMetadata } = newInfopage
    formatFormContent(content, contentMetadata)
    newInfopage.content = content
    yield put(createInfopageSuccess(newInfopage))
    yield put(clearSelectedInfopageTemplate())

    const currentView: string = yield select(selectViewFromPathname)

    if (currentView === 'infopages') {
      // navigate to infopage editor
      yield put(
        push(`/environments/${newInfopage.environmentId}/infopages/${newInfopage.infopageId}`)
      )
      yield put(closeDialog())
    } else {
      yield put(
        createEmptyInfopageToPlaylist({
          type: SelectedInfopageType.infopage,
          id: newInfopage.infopageId
        })
      )
    }
    resolve()
  } catch (error) {
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.infopage.createFail')
      })
    )
    yield put(createInfopageFail(error.message))
  }
}

/**
 * Update Infopage, that was created in AddPlaylistItemWizard and generate a playlist item.
 */
function* handleCreateInfopageToPlaylist({
  payload: { formData, resolve, reject }
}: handleUpdateInfopageFormParams) {
  try {
    const environmentId: string | undefined = yield select(selectEnvironmentIdFromPathname)
    const infopageElement = getInfopageElement()
    if (environmentId && infopageElement) {
      const { name, content, template: formTemplate, infopageId, selectedOrientation } = formData
      const { templateEnvironmentId, templateId } = formTemplate
      const updatedInfopage: InfopageExtensive = yield call(
        InfopagesApi.updateInfopage,
        environmentId,
        infopageId,
        {
          name,
          content: parseFormContent(content),
          templateEnvironmentId,
          templateId
        },
        selectedOrientation,
        infopageElement.outerHTML
      )
      const { template } = updatedInfopage
      formatFormContent(updatedInfopage.content, updatedInfopage.contentMetadata)
      const playlistItem: InfopagePlaylistItemUI = {
        type: PlaylistItemType.infopage,
        itemId: nanoid(),
        infopage: updatedInfopage,
        infopageId,
        userSub: yield select(selectUserSub),
        interval: template.duration || undefined
      }
      yield put(addPlaylistItemSuccess(playlistItem))
      yield put(clearSelectedInfopageTemplate())
      yield put(createInfopageToPlaylistSuccess())
      yield put(reset('CreateInfopageForm'))
      yield put(closeDialog())
      resolve()
    }
  } catch (error) {
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.infopage.createFail')
      })
    )
    yield put(createInfopageToPlaylistFail(error.message))
  }
}

/** Middleware function to delete infopage or handle possible error */
function* deleteInfopageFn(environmentId: string, infopageId: string) {
  try {
    yield call(InfopagesApi.deleteInfopage, environmentId, infopageId)
    yield put(deselectInfopage(infopageId))
    yield put(deleteInfopageSuccess(infopageId))
  } catch (error) {
    yield put(deleteInfopageFail(error.message))
    const playlists: string[] | undefined = error?.response?.data?.playlists
    const errorMessage = playlists
      ? i18n.t('error.infopage.deleteFailUsedBy', { playlists, count: playlists.length })
      : undefined
    if (errorMessage) {
      toast.error(errorMessage, {
        autoClose: 10000
      })
    }
  }
}

interface HandleDeleteInfopageParams {
  payload: SelectedInfopage[]
}

function* handleDeleteInfopage({ payload: infopages }: HandleDeleteInfopageParams) {
  try {
    const environmentId: string | undefined = yield select(selectEnvironmentIdFromPathname)
    if (environmentId) {
      yield all(
        infopages.map(({ id, type }) => {
          if (type === SelectedInfopageType.content) {
            return put(deleteContent({ environmentId, contentId: id }))
          } else {
            return call(deleteInfopageFn, environmentId, id)
          }
        })
      )
    }
  } catch (error) {
    yield put(deleteInfopageFail(error.message))
    const playlists: string[] | undefined = error?.response?.data?.playlists
    const errorMessage = playlists
      ? i18n.t('error.infopage.deleteFailUsedBy', { playlists, count: playlists.length })
      : undefined
    if (errorMessage) {
      toast.error(errorMessage, {
        autoClose: 10000
      })
    }
    yield put(deleteInfopageFail(error.message))
  }
}

function* handleUpdateInfopageKeys({
  payload: { key, form }
}: {
  payload: { key: string; form: string }
}) {
  try {
    let media: Media
    if (key.startsWith('common/')) {
      const orientation: string = yield select(formValueSelector(form), 'selectedOrientation')
      const commonFileMetadata: Media = yield call(MediaApi.getCommonFileMetadata, key, orientation)
      media = commonFileMetadata
    } else {
      media = yield select(selectMediaByKey(key))
    }
    const existingKeys: { [key: string]: any } = yield select(selectInfopageKeys)
    const updatedContentMetadata = {
      ...existingKeys,
      [key]: {
        ...media,
        fullPath: media.url
      }
    }
    yield put(updateInfopageKeysSuccess(updatedContentMetadata))
  } catch (error) {
    yield put(updateInfopageKeysFail(error.message))
  }
}

function* handleUpdateInfopage({
  payload: { formData, resolve, reject }
}: handleUpdateInfopageFormParams) {
  try {
    const environmentId: string | undefined = yield select(selectEnvironmentIdFromPathname)
    const infopageElement = getInfopageElement()
    if (environmentId && infopageElement) {
      const {
        isEditPlaylist,
        name,
        content,
        template: formTemplate,
        infopageId,
        selectedOrientation
      } = formData
      const { templateEnvironmentId, templateId } = formTemplate

      const updatedInfopage: InfopageExtensive = yield call(
        InfopagesApi.updateInfopage,
        environmentId,
        infopageId,
        {
          name,
          content: parseFormContent(content),
          templateEnvironmentId,
          templateId
        },
        selectedOrientation,
        infopageElement.outerHTML
      )
      formatFormContent(updatedInfopage.content, updatedInfopage.contentMetadata)
      if (isEditPlaylist) {
        yield put(updateInfopageItem(updatedInfopage))
      }
      yield put(clearSelectedInfopageTemplate())
      yield put(updateInfopageSuccess(updatedInfopage))
      yield put(reset('CreateInfopageForm'))
      yield put(closeDialog())
      toast.success(i18n.t('infopages.updateSuccess'))
      resolve()
    } else {
      throw new Error('No environment selected')
    }
  } catch (error) {
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.infopage.somethingWrongUpdate')
      })
    )
    yield put(updateInfopageFail(error.message))
    toast.error(i18n.t('error.infopage.somethingWrongUpdate'))
  }
}

function* handleUpdateInfopageSettings({
  payload: { formData, reject }
}: handleUpdateInfopageSettingsFormParams) {
  try {
    const { template: formTemplate, name } = formData
    const infopageId: string = yield select(selectContentIdFromPathname)
    const { templateId, templateEnvironmentId } = formTemplate
    yield put(getTemplate({ templateId, environmentId: templateEnvironmentId }))
    /** wait for template to load */
    yield take([getTemplateSuccess, getTemplateFail])
    const template: Template = yield select(selectTemplateById(templateId))
    const newOrientations = selectAvailableTemplateOrientations(template)
    yield put(change('CreateInfopageForm', 'selectedOrientation', newOrientations[0]))
    yield put(updateInfopageSettingsSuccess({ infopageId, templateId, template, name }))
    yield put(closeDialog())
  } catch (error) {
    yield put(updateInfopageSettingsFail(error.message))
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.infopage.updateSettingsFail')
      })
    )
  }
}

function* handleCopyInfopage({ payload: infopageId }: { payload: string }) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const newInfopage: Infopage = yield call(InfopagesApi.copyInfopage, environmentId, infopageId)
    yield put(copyInfopageSuccess({ newInfopage, sourceInfopageId: infopageId }))
  } catch (error) {
    toast.error('error.infopage.copyFail')
    yield put(copyInfopageFail(error.message))
  }
}

function* watchInfopagessActions() {
  yield all([
    takeLatest(createInfopage, handleCreateInfopage),
    takeLatest(deleteInfopage, handleDeleteInfopage),
    takeLatest(listInfopages, handleListInfopages),
    takeLatest(getInfopage, handleGetInfopage),
    takeLatest(createInfopageToPlaylist, handleCreateInfopageToPlaylist),
    takeLatest(updateInfopageKeys, handleUpdateInfopageKeys),
    takeLatest(updateInfopage, handleUpdateInfopage),
    takeLatest(updateInfopageSettings, handleUpdateInfopageSettings),
    takeLatest(copyInfopage, handleCopyInfopage)
  ])
}

export default [watchInfopagessActions]
