import React, { useState } from 'react'
import { AppBar, Tabs, Tab, Theme, Typography, TextField, InputAdornment } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import { TFunction } from 'i18next'
import {
  LunchProductById,
  LunchGroupById,
  LunchProduct,
  LunchListWeek
} from '@seesignage/seesignage-utils/lib/types/lunchLists'
import MagnifyIcon from 'mdi-react/MagnifyIcon'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon'
import {
  CloseDialog,
  CreateOrUpdateLunchListProduct,
  SetSelectedLunchItem
} from '../../../../types/actions'
import Dialog from '../../../Dialog'
import CreateLunchProductForm from '../../Forms/CreateLunchProductForm'
import {
  getItemShadow,
  getOpacity,
  getBackground
} from '../../../../components/Draggable/DraggableItems'
import { SelectedLunchItem, SelectedLunchItemType } from '../../../../types/lists'
import { getProductsThatDoesNotExistInWeeks } from '../../../../utils/lunchLists'
import { compareStrings } from '../../../../utils/sorting'
import Product, { OwnProductProps } from './Product'

const styles = () => ({
  productsContainer: {
    height: 600
  },
  addProductButton: {
    margin: '8px 0px 8px'
  },
  noProductsText: {
    color: 'rgba(0, 0, 0, 0.26)',
    textAlign: 'center' as 'center',
    marginTop: 20
  }
})

interface StyledTabProps {
  label: string
}

const SmallTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8'
  },
  indicator: {
    backgroundColor: '#1890ff'
  }
})(Tabs)

const SmallTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(1),
      fontSize: '0.8rem',
      padding: '6px 6px',
      '&:hover': {
        color: '#40a9ff',
        opacity: 1
      },
      '&$selected': {
        color: '#1890ff',
        fontWeight: theme.typography.fontWeightMedium
      },
      '&:focus': {
        color: '#40a9ff'
      }
    },
    selected: {}
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />)

export const getItemStyle = (
  isDragging: boolean,
  draggableStyle: any,
  isSelected?: boolean,
  isHidden?: boolean
) => ({
  userSelect: 'none',
  margin: '0 0 12px 0',
  boxShadow: getItemShadow(isDragging, isSelected),
  ...draggableStyle,
  opacity: getOpacity(isHidden),
  background: getBackground(isHidden)
})

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? 'rgb(145, 225, 255)' : '#FFFFFF',
  padding: '15px 0px',
  width: '100%',
  height: '100%',
  overflowX: 'scroll' as 'scroll'
})

export interface ProductsProps {
  t: TFunction
  products: LunchProductById
  groups: LunchGroupById
  selectedItem?: SelectedLunchItem
  createOrUpdateLunchListProduct: CreateOrUpdateLunchListProduct
  closeDialog: CloseDialog
  setSelectedItem: SetSelectedLunchItem
  setCurrentDraggableProductId: any
  weeks?: LunchListWeek[]
  isSubList: boolean
}

type OwnProps = ProductsProps & WithStyles<typeof styles>

const Products = ({
  classes,
  t,
  products,
  groups,
  createOrUpdateLunchListProduct,
  closeDialog,
  setSelectedItem,
  selectedItem,
  setCurrentDraggableProductId,
  weeks,
  isSubList
}: OwnProps) => {
  const [currentTab, selectTab] = React.useState(0)
  const [searchTerm, search] = useState('')
  const allProducts = Object.values(products)
  const productsThatDoesNotExistInWeeks = getProductsThatDoesNotExistInWeeks(products, weeks)
  const allProductsArray = allProducts.sort(compareStrings('name'))
  const productsThatDoesNotExistInWeeksArray = productsThatDoesNotExistInWeeks.sort(
    compareStrings('name')
  )
  const tabProducts = currentTab === 0 ? allProductsArray : productsThatDoesNotExistInWeeksArray
  const productsArray =
    tabProducts.length > 0
      ? tabProducts.filter(({ name }) =>
          name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
        )
      : tabProducts
  return (
    <div className={classes.productsContainer}>
      <Dialog
        dialogId='addLunchProductDialog'
        title={t('lists.lunch.edit.createProduct')}
        buttonLabel={t('lists.lunch.edit.createProduct')}
        color='primary'
        ButtonIcon={PlusCircleOutlineIcon}
        buttonClassName={classes.addProductButton}
        buttonFullWidth
        Content={
          <CreateLunchProductForm
            dialogId='addLunchProductDialog'
            submitAction={createOrUpdateLunchListProduct}
            closeDialog={closeDialog}
            groups={groups}
          />
        }
      />
      <TextField
        variant='standard'
        fullWidth
        onChange={e => search(e.target.value)}
        label={t('lists.lunch.search')}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <MagnifyIcon />
            </InputAdornment>
          )
        }}
      />
      <AppBar position='static' color='default'>
        <SmallTabs
          textColor='primary'
          value={currentTab}
          onChange={(event, newValue) => {
            selectTab(newValue)
          }}
          aria-label='lunch tabs'>
          <SmallTab label={t('lists.lunch.tab.all', { count: allProductsArray.length })} />
          <SmallTab
            label={t('lists.lunch.tab.unused', {
              count: productsThatDoesNotExistInWeeksArray.length
            })}
          />
        </SmallTabs>
      </AppBar>
      {productsArray.length > 0 ? (
        <Droppable droppableId='droppableProductsList' type='droppableItem'>
          {({ innerRef, placeholder }, { isDraggingOver }) => (
            <div ref={innerRef} style={getListStyle(isDraggingOver)}>
              {productsArray.map((product, index: number) => {
                const { productId } = product
                const isProductSelected =
                  selectedItem?.type === SelectedLunchItemType.product &&
                  (selectedItem.item as LunchProduct).productId === productId
                // (selectedItem as LunchProduct | undefined)?.productId === productId
                const group = product.groupId ? groups[product.groupId] : undefined
                const productProps: OwnProductProps = {
                  isSubList,
                  product,
                  group,
                  t
                }
                return (
                  <Draggable key={productId} draggableId={productId} index={index}>
                    {({ innerRef, draggableProps, dragHandleProps }, { isDragging }) => (
                      <div
                        ref={innerRef}
                        {...draggableProps}
                        {...dragHandleProps}
                        style={getItemStyle(isDragging, draggableProps.style, isProductSelected)}
                        onMouseDown={() => {
                          setCurrentDraggableProductId(productId)
                        }}
                        onClick={() => {
                          setSelectedItem(
                            isProductSelected
                              ? undefined
                              : { item: product, type: SelectedLunchItemType.product }
                          )
                        }}>
                        <Product isRootProductView {...productProps} />
                      </div>
                    )}
                  </Draggable>
                )
              })}
              {placeholder}
            </div>
          )}
        </Droppable>
      ) : (
        <Typography className={classes.noProductsText}>{t('lists.lunch.noProducts')}</Typography>
      )}
    </div>
  )
}

export default withStyles(styles)(Products)
