import React, { Fragment } from 'react'
import { ComponentType } from '@seesignage/seesignage-utils'
import { useTranslation } from 'react-i18next'
import BackgroundFields from './CommonFields/BackgroundFields'
import BorderFields from './CommonFields/BorderFields'
import EffectsFields from './CommonFields/EffectsFields'
import PositionFields from './CommonFields/PositionFields'
import SizeFields from './CommonFields/SizeFields'
import SpacingFields from './CommonFields/SpacingFields'
import TextFields from './CommonFields/TextFields'

interface DivProps {
  id: string
  level: number
  childIndexes: number[]
  type: ComponentType
  formName: string
}

const Div: React.FC<DivProps> = ({ id, level, childIndexes, type, formName }) => {
  const [t] = useTranslation()
  const fieldProps = {
    level,
    childIndexes,
    t,
    type,
    formName
  }
  return (
    <Fragment>
      <PositionFields {...fieldProps} />
      <SpacingFields {...fieldProps} />
      <TextFields {...fieldProps} />
      <BorderFields {...fieldProps} />
      <BackgroundFields {...fieldProps} />
      <SizeFields {...fieldProps} />
      <EffectsFields id={id} {...fieldProps} />
    </Fragment>
  )
}

export default Div
