import React, { Fragment } from 'react'
import EyeIcon from 'mdi-react/EyeIcon'
import EyeOffIcon from 'mdi-react/EyeOffIcon'
import { IconButton, Tooltip } from '@mui/material'
import { TFunction } from 'i18next'

interface HideItemProps {
  t: TFunction
  isHidden?: boolean
  hidePlaylistItem: () => void
}

const HideItem = ({ t, isHidden, hidePlaylistItem }: HideItemProps) => (
  <Fragment>
    {isHidden === true ? (
      <Tooltip disableInteractive title={t('playlists.editPlaylist.showItem')}>
        <div>
          <IconButton
            onClick={() => hidePlaylistItem()}
            aria-label={t('general.close')}
            size='large'>
            <EyeIcon />
          </IconButton>
        </div>
      </Tooltip>
    ) : (
      <Tooltip disableInteractive title={t('playlists.editPlaylist.hideItem')}>
        <div>
          <IconButton onClick={() => hidePlaylistItem()} aria-label='hide' size='large'>
            <EyeOffIcon />
          </IconButton>
        </div>
      </Tooltip>
    )}
  </Fragment>
)

export default HideItem
