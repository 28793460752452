import React, { Fragment } from 'react'
import { Provider, useSelector } from 'react-redux'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { ToastContainer } from 'react-toastify'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import fi from 'date-fns/locale/fi'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Language } from '@seesignage/seesignage-utils'
import Router from './router'
import { getStore, getHistory } from './configureStore'
import { getThemeWithLanguage } from './theme'
import FileUploadProgress from './components/Progress/File'
import UpdateNotification from './components/Update'
import Tours from './containers/Tours'
import IotContainer from './containers/IoT/IotContainer'
import { selectLanguage } from './selectors/users'
import CookieConsent from './containers/Authentication/CookieConsent'

const MuiThemeContainer = () => {
  const language = useSelector(selectLanguage)
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={getThemeWithLanguage(language)}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={language === Language.fi ? fi : undefined}>
          <Router history={getHistory()} />
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

const App = () => (
  <Provider store={getStore()}>
    <Fragment>
      <ToastContainer />
      <MuiThemeContainer />
      <FileUploadProgress />
      <UpdateNotification />
      <Tours />
      <IotContainer />
      <CookieConsent />
    </Fragment>
  </Provider>
)

export default App
