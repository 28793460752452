import { createAction as createReduxAction } from 'redux-actions'

const createAction = (reducerName: string, actionName: string): any =>
  createReduxAction(`${reducerName}/${actionName}`)

const createActionSuccess = (reducerName: string, actionName: string): any =>
  createReduxAction(`${reducerName}/${actionName}_SUCCESS`)

const createActionFail = (reducerName: string, actionName: string): any =>
  createReduxAction(`${reducerName}/${actionName}_FAIL`)

const createAsyncActions = (reducerName: string, actionName: string) => ({
  action: createAction(reducerName, actionName),
  actionSuccess: createActionSuccess(reducerName, actionName),
  actionFail: createActionFail(reducerName, actionName)
})

export { createAsyncActions, createAction, createActionSuccess, createActionFail }
