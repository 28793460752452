import {
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Typography
} from '@mui/material'
import {
  ContentMetadataByKey,
  InfopageContent,
  selectAvailableTemplateOrientations,
  Template,
  TemplateOrientation
} from '@seesignage/seesignage-utils'
import { TFunction } from 'i18next'
import ArrowDownThickIcon from 'mdi-react/ArrowDownThickIcon'
import MonitorIcon from 'mdi-react/MonitorIcon'
import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import {
  Field,
  Form,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
  WrappedFieldInputProps
} from 'redux-form'
import { StateInterface } from '../../../types/states'
import { TemplateAsFormValue } from '../../../types/templates'
import InfopagePreview from '../Preview'
import { selectEnvironmentIdFromPathname } from '../../../selectors/routing'
import { selectSelectedInfopageTemplate } from '../../../selectors/infopages'
import CreateInfopageContentForm from './CreateInfopageContentForm'

/**
 * Return only orientation options that template has styles for
 * @param template
 */
const renderOrientationOptions = (template: Template) => {
  const availableOrientations = selectAvailableTemplateOrientations(template)
  const menuItems = availableOrientations.map(orientation => (
    <MenuItem key={orientation} value={orientation}>
      <MonitorIcon
        style={{ margin: 2, transform: orientation === 'portrait' ? 'rotate(90deg)' : undefined }}
      />
      {orientation}
    </MenuItem>
  ))
  return menuItems
}

interface SelectOrientationFieldProps {
  input: WrappedFieldInputProps
  template: Template
  t: TFunction
}

const SelectOrientationField = ({ input, t, template }: SelectOrientationFieldProps) => (
  <FormControl variant='standard' style={{ width: 170, margin: 5 }}>
    <InputLabel htmlFor='orientation'>{t('templates.forms.components.orientation')}</InputLabel>
    <Select
      variant='standard'
      value={input.value || selectAvailableTemplateOrientations(template)[0]}
      disabled={selectAvailableTemplateOrientations(template).length === 1}
      onChange={e => input.onChange(e.target.value)}
      inputProps={{
        name: 'orientation',
        id: 'orientation'
      }}>
      {renderOrientationOptions(template)}
    </Select>
  </FormControl>
)

interface FormProps {
  name: string
  infopageId?: string
  isEditPlaylist?: boolean
  template?: TemplateAsFormValue
  content: InfopageContent
  selectedOrientation?: TemplateOrientation
}

interface OwnProps {
  infopageKeys: ContentMetadataByKey
  submitAction: (formData: any) => void
  /** Template from infopage object */
  infopageTemplate?: Template
}

interface StateProps {
  environmentId?: string
  /** Selected Template from redux state (in edit infopage from Playlist) */
  selectedTemplate?: Template
  /** Content from form value */
  infopageContent?: InfopageContent
  formSelectedOrientation?: TemplateOrientation
}

type ComponentProps = OwnProps & StateProps

type CreateInfopageFormProps = ComponentProps & WithTranslation

const CreateInfopageForm: React.FC<CreateInfopageFormProps &
  InjectedFormProps<FormProps, ComponentProps>> = ({
  form,
  infopageKeys,
  handleSubmit,
  submitAction,
  infopageContent,
  formSelectedOrientation,
  infopageTemplate,
  selectedTemplate,
  initialValues,
  t
}) => {
  const template = initialValues.isEditPlaylist ? selectedTemplate : infopageTemplate

  if (!infopageContent || !template?.components) {
    return <LinearProgress />
  }

  const selectedOrientation =
    formSelectedOrientation || selectAvailableTemplateOrientations(template)[0]
  return (
    <Form onSubmit={handleSubmit(submitAction)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Field
            name='selectedOrientation'
            component={SelectOrientationField}
            template={template}
            t={t}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <div style={{ textAlign: 'center' }}>
            <Typography style={{ textAlign: 'center' }} variant='body1' color='primary'>
              {t('infopages.selectContent')}
            </Typography>
            <ArrowDownThickIcon color='#1e88e5' />
          </div>
          <CreateInfopageContentForm
            formName={form}
            renderField={{}}
            currentStyles={template.components[selectedOrientation]}
            currentComponentSchema={template.schema.components}
            childrenList={[]}
            level={0}
          />
        </Grid>
        <Grid item sm={12} lg={6}>
          <InfopagePreview
            infopageContent={infopageContent}
            template={template}
            orientation={selectedOrientation}
            contentMetadata={infopageKeys}
          />
        </Grid>
      </Grid>
    </Form>
  )
}

const mapStateToProps = (state: StateInterface): StateProps => ({
  environmentId: selectEnvironmentIdFromPathname(state),
  infopageContent: formValueSelector('CreateInfopageForm')(state, 'content'),
  selectedTemplate: selectSelectedInfopageTemplate(state),
  formSelectedOrientation: formValueSelector('CreateInfopageForm')(state, 'selectedOrientation')
})

export default connect(mapStateToProps)(
  reduxForm<FormProps, ComponentProps>({
    form: 'CreateInfopageForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    updateUnregisteredFields: true
  })(withTranslation()(CreateInfopageForm))
)
