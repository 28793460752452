import { handleActions } from 'redux-actions'
import { Environment, TemplateType, EnvironmentUI } from '@seesignage/seesignage-utils'
import {
  createEnvironmentFail,
  createEnvironmentSuccess,
  listEnvironmentsSuccess,
  getEnvironmentSuccess,
  getEnvironmentFail,
  getEnvironment,
  listEnvironments,
  listEnvironmentsFail,
  deselectEnvironment,
  deleteEnvironmentSuccess,
  editEnvironmentSuccess,
  updateSubEnvironmentsSuccess,
  removeAsSubEnvironmentSuccess
} from '../actions/environments'
import { EnvironmentsState, EnvironmentsById } from '../types/states'
import { Action } from '../types/actions'
import { saveSelectedEnvironmentIdLocalStorage } from '../services/localStorage/environments'

const initialState: EnvironmentsState = {
  environments: {}, // by environment id
  selectedEnvironment: undefined,
  environmentIsLoading: false,
  listEnvironmentsIsLoading: false,
  environmentTemplateTypes: []
}

const environments = handleActions<EnvironmentsState, any>(
  {
    [deselectEnvironment]: state => ({
      ...state,
      selectedEnvironment: undefined
    }),
    [createEnvironmentFail]: state => ({
      ...state
    }),
    [createEnvironmentSuccess]: (state, { payload: environment }: Action<any>) => ({
      ...state,
      environments: {
        ...state.environments,
        [environment.environmentId]: environment
      }
    }),
    [listEnvironmentsSuccess]: (state, { payload: environmentsArray }: Action<Environment[]>) => {
      const environments = environmentsArray.reduce(
        (environments: EnvironmentsById, environment: Environment) => {
          environments[environment.environmentId] = {
            ...environment
          }
          return environments
        },
        {}
      )
      return {
        ...state,
        environments,
        listEnvironmentsIsLoading: false
      }
    },
    [listEnvironments]: state => ({
      ...state,
      listEnvironmentsIsLoading: true
    }),
    [listEnvironmentsFail]: state => ({
      ...state,
      environments: {},
      listEnvironmentsIsLoading: false
    }),
    [getEnvironmentSuccess]: (
      state,
      {
        payload: { environment, templateTypes }
      }: Action<{ environment: EnvironmentUI; templateTypes: TemplateType[] }>
    ) => {
      return {
        ...state,
        environmentIsLoading: false,
        environmentTemplateTypes: templateTypes,
        selectedEnvironment: environment,
        environments: {
          ...state.environments,
          [environment.environmentId]: environment
        }
      }
    },
    [getEnvironmentFail]: state => ({
      ...state,
      environmentIsLoading: false
    }),
    [getEnvironment]: (
      state,
      { payload: { environmentId } }: Action<{ environmentId: string }>
    ) => {
      saveSelectedEnvironmentIdLocalStorage(environmentId)
      return {
        ...state,
        environmentIsLoading: true
      }
    },
    [deleteEnvironmentSuccess]: (state, { payload: environmentId }: Action<string>) => {
      const environments = { ...state.environments }
      delete environments[environmentId]
      return {
        ...state,
        environments
      }
    },
    [editEnvironmentSuccess]: (
      state,
      { payload: { environmentId, updatedAttributes } }: Action<any>
    ) => {
      const environments = state.environments
      environments[environmentId] = {
        ...environments[environmentId],
        ...updatedAttributes
      }
      return {
        ...state,
        environments
      }
    },
    [updateSubEnvironmentsSuccess]: (
      state,
      { payload: environmentsArray }: Action<Environment[]>
    ) => {
      const environmentsState = state.environments
      for (const environment of environmentsArray) {
        environmentsState[environment.environmentId] = environment
      }
      return {
        ...state,
        environments: environmentsState
      }
    },
    [removeAsSubEnvironmentSuccess]: (
      state,
      { payload: environmentsArray }: Action<Environment[]>
    ) => {
      const environmentsState = state.environments
      for (const environment of environmentsArray) {
        environmentsState[environment.environmentId] = environment
      }
      return {
        ...state,
        environments: environmentsState
      }
    }
  },
  initialState
)

export default environments
