import React, { Fragment } from 'react'
import {
  TextField,
  IconButton,
  Popover,
  Badge,
  Button,
  Typography,
  Tooltip,
  DialogActions
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import Autocomplete from '@mui/material/Autocomplete'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import TuneIcon from 'mdi-react/TuneIcon'
import MonitorIcon from 'mdi-react/MonitorIcon'
import MonitorOffIcon from 'mdi-react/MonitorOffIcon'
import { useHistory, useLocation } from 'react-router'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@mui/material/ToggleButton'
import { FilterByStatus, ScreensQueryParamType } from '../../../types/screens'
import { selectScreensTagsArray } from '../../../selectors/screens'
import SearchField from '../../../components/SearchField'
import { selectIsUserDeviceMobile } from '../../../selectors/users'
import colors from '../../../styles/common/colors'

const statusIcon = {
  [FilterByStatus.online]: <MonitorIcon color={colors.screenStatuses.onlineBg} />,
  [FilterByStatus.offline]: <MonitorOffIcon color={colors.screenStatuses.offlineBg} />,
  [FilterByStatus.notLinked]: <MonitorIcon color={colors.screenStatuses.notLinkedBg} />,
  [FilterByStatus.standby]: <MonitorIcon color={colors.screenStatuses.standbyBg} />
}

const useStyles = makeStyles(theme => ({
  autocompleteField: {
    padding: theme.spacing(1)
  },
  toggleButtonGroupContainer: {
    width: '100%',
    textAlign: 'center'
  },
  clearButton: {
    float: 'right'
  },
  popover: {
    width: '100%',
    padding: theme.spacing(1)
  }
}))

const StyledToggleButtonGroup = withStyles(theme => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius
    }
  }
}))(ToggleButtonGroup)

const ScreensSearchField = () => {
  const [t] = useTranslation()
  const availableTags = useSelector(selectScreensTagsArray)
  const isMobile = useSelector(selectIsUserDeviceMobile)

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const history = useHistory()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const queryParamTags = queryParams.get('tags')
  const queryParamStatus = queryParams.get('status')
  const queryParamSearchTerm = queryParams.get('searchTerm')
  const filterCount =
    queryParamStatus && queryParamTags ? 2 : queryParamStatus || queryParamTags ? 1 : 0
  const selectedTags = queryParamTags ? queryParamTags.split(',') : []

  const updateQueryParam = (type: ScreensQueryParamType, value?: string) => {
    if (value && value !== 'all') {
      queryParams.set(type, value)
    } else {
      queryParams.delete(type)
    }
    history.replace({
      search: queryParams.toString()
    })
  }

  /**
   * Delete tags and status from queryparams. Retain name search.
   */
  const deleteQueryParams = () => {
    queryParams.delete('tags')
    queryParams.delete('status')
    history.replace({
      search: queryParams.toString()
    })
  }

  const classes = useStyles()
  return (
    <Fragment>
      <SearchField
        value={queryParamSearchTerm || ''}
        onChange={e => updateQueryParam(ScreensQueryParamType.searchTerm, e.target.value)}
        placeholder={t('general.search')}
      />
      <Tooltip disableInteractive title={t('screens.moreSearchOptions')}>
        <IconButton onClick={handleClick} size='large'>
          <Badge badgeContent={filterCount} color='primary'>
            <TuneIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        className={classes.popover}
        id={'popover'}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        <Autocomplete
          size='small'
          classes={{ root: classes.autocompleteField }}
          value={selectedTags}
          getOptionLabel={option => option}
          onChange={(event, value) => updateQueryParam(ScreensQueryParamType.tags, value.join(','))}
          options={availableTags}
          multiple
          popupIcon={undefined}
          renderInput={params => (
            <TextField
              {...params}
              placeholder={t('screens.filterByTags')}
              variant='outlined'
              size='small'
            />
          )}
          filterSelectedOptions
        />
        <div className={classes.toggleButtonGroupContainer}>
          <StyledToggleButtonGroup
            exclusive
            value={queryParamStatus}
            onChange={(e, val) => {
              updateQueryParam(ScreensQueryParamType.status, val)
            }}>
            {Object.values(FilterByStatus).map(status => (
              <ToggleButton key={status} value={status}>
                {statusIcon[status]}
                {!isMobile && (
                  <Typography style={{ marginLeft: 4, fontSize: '0.6rem', color: 'black' }}>
                    {t(`screens.status.${status}`)}
                  </Typography>
                )}
              </ToggleButton>
            ))}
          </StyledToggleButtonGroup>
        </div>
        <DialogActions>
          <Button
            disabled={!queryParamStatus && !queryParamTags}
            size='small'
            onClick={deleteQueryParams}>
            {t('general.reset')}
          </Button>
          <Button size='small' color='primary' onClick={handleClose}>
            {t('general.ok')}
          </Button>
        </DialogActions>
      </Popover>
    </Fragment>
  )
}

export default ScreensSearchField
