import React, { useEffect, useState } from 'react'
import { Badge, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { getErrorLogsCountForLastDays } from '../../services/api/errorLogs'

const ErrorLogsIconBadge: React.FC<{ Icon: JSX.Element }> = ({ Icon }) => {
  const [t] = useTranslation()
  const [errorLogsCount, setErrorLogsCount] = useState<number>(0)
  const [isFailed, setFailed] = useState<boolean>(false)
  useEffect(() => {
    const getErrorLogsCount = async () => {
      try {
        const count = await getErrorLogsCountForLastDays()
        setErrorLogsCount(count)
      } catch (error) {
        setErrorLogsCount(0)
        setFailed(true)
      }
    }
    getErrorLogsCount()
  }, [])
  return (
    <Tooltip
      disableInteractive
      placement='right-end'
      title={
        isFailed
          ? (t('errorLogs.getErrorLogsCountFailed') as string)
          : (t('errorLogs.navigationTooltip', { count: errorLogsCount }) as string)
      }>
      <Badge badgeContent={isFailed ? '!' : errorLogsCount} color='primary'>
        {Icon}
      </Badge>
    </Tooltip>
  )
}

export default ErrorLogsIconBadge
