import { API } from 'aws-amplify'
import { Infopage, TemplateOrientation, InfopageExtensive } from '@seesignage/seesignage-utils'
import { CreateInfopage } from '../../types/infopages'

const createInfopage = async (
  environmentId: string,
  infopage: CreateInfopage,
  html?: string
): Promise<Infopage> => {
  const newInfopage = await API.post('infopages', `/environments/${environmentId}/infopages`, {
    body: {
      ...infopage,
      html
    }
  })
  return newInfopage
}

/**
 * Get limited infopages response without template and contentMetadata.
 * Support pagination.
 * @param environmentId
 * @param includePlaylistData
 * @param searchTerm
 * @param lastInfopageId
 * @param lastInfopageName
 */
const listInfopages = async (
  environmentId: string,
  includePlaylistData: boolean | undefined,
  searchTerm: string | undefined,
  lastInfopageId: string | undefined,
  lastInfopageName: string | undefined
) => {
  const options = includePlaylistData
    ? {
        queryStringParameters: {
          includePlaylistData: true,
          ...(lastInfopageId ? { lastInfopageId } : {}),
          ...(lastInfopageName ? { lastInfopageName } : {}),
          ...(searchTerm ? { searchTerm } : {})
        }
      }
    : {}
  const infopagesResponse: { infopages: Infopage[]; lastEvaluatedKey: any } = await API.get(
    'infopages',
    `/environments/${environmentId}/infopages`,
    options
  )
  return infopagesResponse
}

const updateInfopage = async (
  environmentId: string,
  infopageId: string,
  infopage: CreateInfopage,
  orientation?: TemplateOrientation,
  html?: string
) => {
  const updatedInfopage = await API.put(
    'infopages',
    `/environments/${environmentId}/infopages/${infopageId}`,
    {
      body: {
        ...infopage,
        html,
        orientation
      }
    }
  )
  return updatedInfopage
}

/**
 * Get single infopage with template and contentMetadata
 * @param environmentId
 * @param infopageId
 */
const getInfopage = async (environmentId: string, infopageId: string) => {
  const infopage: InfopageExtensive = await API.get(
    'infopages',
    `/environments/${environmentId}/infopages/${infopageId}`,
    {}
  )
  return infopage
}

const deleteInfopage = async (environmentId: string, infopageId: string) => {
  await API.del('infopages', `/environments/${environmentId}/infopages/${infopageId}`, {})
}

const copyInfopage = async (environmentId: string, infopageId: string) => {
  const copy = await API.put(
    'infopages',
    `/environments/${environmentId}/infopages/${infopageId}/copy`,
    {}
  )
  return copy
}

export default {
  listInfopages,
  getInfopage,
  updateInfopage,
  createInfopage,
  deleteInfopage,
  copyInfopage
}
