import { Button, IconButton } from '@mui/material'
import DeleteIcon from 'mdi-react/DeleteIcon'
import makeStyles from '@mui/styles/makeStyles'
import React, { Fragment } from 'react'
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon'
// @ts-ignore
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useDispatch } from 'react-redux'
import { WrappedFieldArrayProps } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { getItemStyle } from '../../../../components/Draggable/DraggableItems'
import { getList } from '../../../../actions/lists'

const useStyles = makeStyles(() => ({
  listActions: {
    minHeight: 50,
    padding: 8
  },
  addItemAction: {
    display: 'inline-block'
  },
  saveActions: {
    display: 'inline-block',
    float: 'right' as 'right'
  },
  button: {
    marginRight: 4
  },
  buttonIcon: { marginRight: 6 }
}))

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? 'rgb(145, 225, 255)' : '#fafafa',
  padding: 8
})

const onDragEnd = (reoderItems: any) => (result: any) => {
  if (!result.destination || result.source.index === result.destination.index) {
    return
  }
  reoderItems(result.source.index, result.destination.index)
}

interface MiscListInputFieldsProps extends WrappedFieldArrayProps {
  isCancelDisabled: boolean
  isSubmitDisabled: boolean
  renderInputField: (index: number, t: any) => React.JSX.Element[]
}

const MiscListInputFields = ({
  fields,
  isCancelDisabled,
  isSubmitDisabled,
  renderInputField
}: MiscListInputFieldsProps) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const dispatch = useDispatch()
  return (
    <div>
      <div className={classes.listActions}>
        <div className={classes.addItemAction}>
          <Button onClick={() => fields.push({})} variant='contained'>
            <PlusCircleOutlineIcon className={classes.buttonIcon} />
            {t('lists.miscList.addRow')}
          </Button>
        </div>
        <div className={classes.saveActions}>
          <Button
            className={classes.button}
            variant='contained'
            onClick={() => dispatch(getList())}
            disabled={isCancelDisabled}>
            {t('general.cancel')}
          </Button>
          <Button
            type='submit'
            className={classes.button}
            disabled={isSubmitDisabled}
            variant='contained'
            color='primary'>
            {t('general.save')}
          </Button>
        </div>
      </div>
      <DragDropContext onDragEnd={onDragEnd(fields.move)}>
        <Droppable droppableId='droppable'>
          {({ innerRef, placeholder }, { isDraggingOver }) => (
            <div ref={innerRef} style={getListStyle(isDraggingOver)}>
              {fields.map((name: string, index: number) => (
                <Draggable key={name} draggableId={name} index={index}>
                  {({ innerRef, draggableProps, dragHandleProps }, { isDragging }) => (
                    <div
                      ref={innerRef}
                      {...draggableProps}
                      {...dragHandleProps}
                      style={{
                        ...getItemStyle(isDragging, draggableProps.style),
                        minHeight: 60
                      }}>
                      <Fragment>
                        <IconButton
                          onClick={() => fields.remove(index)}
                          aria-label={t('general.delete')}
                          style={{ float: 'right' }}
                          size='large'>
                          <DeleteIcon color='red' />
                        </IconButton>
                        {renderInputField(index, t)}
                      </Fragment>
                    </div>
                  )}
                </Draggable>
              ))}
              {placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

export default MiscListInputFields
