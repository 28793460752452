import { fabric } from 'fabric'

export const MAX_ZOOM_RATIO = 2 // otherwise problems with transfor
export const MIN_ZOOM_RATIO = 0.05 // otherwise problems with transfor
const zoomStep = 0.05

const setZoomToPoint = (zoomRatio?: number, viewPort?: number[], point?: fabric.Point) => {
  const { canvas, temporayDrawingCanvas } = window
  if (canvas) {
    if (viewPort) {
      canvas.setViewportTransform(viewPort)
      temporayDrawingCanvas.setViewportTransform(viewPort)
    }
    if (!zoomRatio) {
      zoomRatio = 1
    }

    if (zoomRatio > MAX_ZOOM_RATIO) {
      zoomRatio = MAX_ZOOM_RATIO
    }

    if (zoomRatio <= MIN_ZOOM_RATIO) {
      zoomRatio = MIN_ZOOM_RATIO
    }

    if (!point) {
      const { left, top } = canvas.getCenter()
      point = new fabric.Point(left, top)
    }

    canvas.zoomToPoint(point, zoomRatio)
    temporayDrawingCanvas.zoomToPoint(point, zoomRatio)
    canvas.requestRenderAll()
    return zoomRatio
  }
}

const zoomCanvasOneToOne = () => {
  const { canvas } = window
  if (canvas) {
    return setZoomToPoint(1, [1, 0, 0, 1, 0, 0])
  }
}

const zoomCanvasToInitial = () => {
  return setZoomToPoint(0.5, [1, 0, 0, 1, 0, 0])
}

const zoomCanvas = (isZoomIn: boolean) => {
  const { canvas } = window
  if (canvas) {
    const zoomRatio = canvas.getZoom() + (isZoomIn ? zoomStep : -zoomStep)
    return setZoomToPoint(zoomRatio)
  }
}

const zoomCanvasToFitWorkarea = () => {
  const { canvas, workarea } = window
  if (canvas && workarea) {
    const canvasHeight = canvas.getHeight()
    const canvasWidth = canvas.getWidth()
    const workareaWidth = workarea.getScaledWidth()
    const workareaHeight = workarea.getScaledHeight()

    // Calculate scaling factors
    const scaleX = canvasWidth / workareaWidth
    const scaleY = canvasHeight / workareaHeight

    // Determine the minimum scale factor to maintain aspect ratio
    const scale = Math.min(scaleX, scaleY)
    return setZoomToPoint(scale, [1, 0, 0, 1, 0, 0])
  }
}

const moveViewArea = (e: fabric.IEvent<MouseEvent>) => {
  const { canvas, temporayDrawingCanvas } = window
  const newPoint = new fabric.Point(e.e.movementX, e.e.movementY)
  canvas.relativePan(newPoint)
  temporayDrawingCanvas.relativePan(newPoint)
  canvas.requestRenderAll()
}

export {
  zoomCanvasToInitial,
  zoomCanvas,
  zoomCanvasToFitWorkarea,
  moveViewArea,
  setZoomToPoint,
  zoomCanvasOneToOne
}
