import React, { FunctionComponent } from 'react'
import { Tooltip } from '@mui/material'

interface TooltipSpanProps {
  title: string
  children: React.ReactNode
  open?: boolean
}

const TooltipSpan: FunctionComponent<TooltipSpanProps> = ({ title, children, open }) => (
  <Tooltip disableInteractive title={title} open={open}>
    <span>{children}</span>
  </Tooltip>
)
export default TooltipSpan
