import { Components, StylesChild, TemplateOrientation } from '@seesignage/seesignage-utils'

const generateComponentStyles = (
  orientation: TemplateOrientation,
  styles?: StylesChild
): Components =>
  orientation === TemplateOrientation.landscape
    ? { landscape: styles || {}, portrait: {} }
    : { landscape: {}, portrait: styles || {} }

const getResolution = (orientation: TemplateOrientation) =>
  orientation === TemplateOrientation.landscape
    ? {
        width: 1920,
        height: 1080
      }
    : {
        width: 1080,
        height: 1920
      }

export { generateComponentStyles, getResolution }
