import { Grid, Typography, Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import ItemIcon from 'mdi-react/ImagePlusIcon'
import UrlIcon from 'mdi-react/LinkPlusIcon'
import ShoppingIcon from 'mdi-react/ShoppingIcon'
import TableColumnPlusAfterIcon from 'mdi-react/TableColumnPlusAfterIcon'
import InfopageIcon from 'mdi-react/BookInformationVariantIcon'
import RevolverIcon from 'mdi-react/FerrisWheelIcon'
import React, { Fragment, ComponentType } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { PlaylistUI } from '@seesignage/seesignage-utils'
import { MdiReactIconProps } from 'mdi-react'
import {
  selectCustomersWithGeneralProductForm,
  selectPharmacyCustomers
} from '../../../../selectors/customers'
import { selectIsUserDeviceMobile, selectUser } from '../../../../selectors/users'
import { PageType } from '../../../../types/playlists'
import {
  selectCurrentEnvironmentFeatures,
  selectEnvironmentTemplateTypes
} from '../../../../selectors/environments'
import { isListsFeatureEnabled } from '../../../../utils/lists'

const useSelectItemTypeStyles = makeStyles({
  gridContainer: {
    height: '100%',
    marginTop: '8px'
  }
})

const useItemCardStyles = makeStyles({
  card: {
    height: 120,
    margin: 'auto',
    cursor: 'pointer',
    userSelect: 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    '-webkit-user-select': 'none',
    '&:hover': {
      opacity: 0.8
    },
    width: '100%'
  },
  cardBody: { padding: 8, textAlign: 'center' },
  cardDescription: {
    color: '#1e88e5',
    display: 'block'
  },
  cardGridContainer: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cardIcon: {
    color: '#1e88e5',
    height: '60px',
    width: '60px'
  },
  cardIconContainer: {
    height: '100%'
  },
  cardTitleText: {
    textAlign: 'center'
  }
})

interface ItemCardProps {
  name: string
  description: string
  page: PageType
  selectPage: (page: PageType) => void
  Icon: ComponentType<MdiReactIconProps>
  isMobile: boolean
}

const ItemCard = ({ name, description, page, selectPage, Icon }: ItemCardProps) => {
  const classes = useItemCardStyles()
  return (
    <Paper className={classes.card} elevation={2} onClick={() => selectPage(page)}>
      <Grid className={classes.cardGridContainer} container>
        <Grid container item xs={4} justifyContent='center' alignItems='center'>
          <div className={classes.cardIconContainer}>
            <Icon className={classes.cardIcon} />
          </div>
        </Grid>
        <Grid item xs={8}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant='h6' className={classes.cardTitleText}>
                {name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.cardBody}>
                <Typography variant='caption' className={classes.cardDescription}>
                  {description}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

interface OwnProps {
  selectPage: (page: PageType) => void
  playlist: PlaylistUI
}

type SelectItemTypeProps = OwnProps

const SelectItemType: React.FC<SelectItemTypeProps> = ({ selectPage, playlist }) => {
  const user = useSelector(selectUser)
  const environmentFeatures = useSelector(selectCurrentEnvironmentFeatures)
  const environmentTemplateTypes = useSelector(selectEnvironmentTemplateTypes)
  const generalRetailCustomers = useSelector(selectCustomersWithGeneralProductForm)
  const pharmacyCustomers = useSelector(selectPharmacyCustomers)
  const showLists = isListsFeatureEnabled(user, environmentFeatures, environmentTemplateTypes)
  const isMobile = useSelector(selectIsUserDeviceMobile)
  const classes = useSelectItemTypeStyles()
  const { t } = useTranslation()
  if (!user) {
    return <Fragment />
  }
  const commonItemProps = {
    selectPage,
    isMobile
  }
  return (
    <Grid spacing={1} container justifyContent='center' className={classes.gridContainer}>
      <Grid item xs={12}>
        <ItemCard
          name={t('playlists.editPlaylist.media')}
          page={PageType.media}
          Icon={ItemIcon}
          description={t('playlists.editPlaylist.mediaDescription')}
          {...commonItemProps}
        />
      </Grid>
      <Grid item xs={12}>
        <ItemCard
          name={t('playlists.editPlaylist.infopage')}
          description={t('playlists.editPlaylist.infopageDescription')}
          page={PageType.existingInfopage}
          Icon={InfopageIcon}
          {...commonItemProps}
        />
      </Grid>
      {// do not allow revolver item for sync playlist
      !playlist?.syncPlay && (
        <Grid item xs={12}>
          <ItemCard
            name={t('playlists.revolver.revolver')}
            description={t('playlists.revolver.revolverDescription')}
            page={PageType.revolver}
            Icon={RevolverIcon}
            {...commonItemProps}
          />
        </Grid>
      )}
      {showLists && (
        <Grid item xs={12}>
          <ItemCard
            name={t('playlists.editPlaylist.list')}
            description={t('playlists.editPlaylist.listDescription')}
            page={PageType.list}
            Icon={TableColumnPlusAfterIcon}
            {...commonItemProps}
          />
        </Grid>
      )}
      {(generalRetailCustomers.length > 0 || pharmacyCustomers.length > 0) && (
        <Grid item xs={12}>
          <ItemCard
            name={t('general.product')}
            description={t('playlists.editPlaylist.addProductDescription')}
            page={
              generalRetailCustomers.length > 0 ? PageType.productType : PageType.existingProduct
            }
            Icon={ShoppingIcon}
            {...commonItemProps}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <ItemCard
          name={t('playlists.editPlaylist.urlItem')}
          page={PageType.url}
          description={t('playlists.editPlaylist.urlItemDescription')}
          Icon={UrlIcon}
          {...commonItemProps}
        />
      </Grid>
    </Grid>
  )
}

export default SelectItemType
