import { Button, DialogActions, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import { MediaType } from '@seesignage/seesignage-utils'
import { selectIsUserDeviceMobile } from '../../../../selectors/users'
import SelectMultipleMediaField from '../../../../components/FormInput/SelectMedia/SelectMultipleMediaField'
import ErrorMessage from '../../../../components/Errors/ErrorMessage'

const useStyles = makeStyles(() => ({
  dialogActionsMobile: {
    justifyContent: 'center'
  }
}))

interface AddProductImageOwnProps {
  goBack?: () => void
  onSubmit: (arg: any) => void
}

type FormDataProps = {}

const AddProductImage: React.FC<AddProductImageOwnProps &
  InjectedFormProps<FormDataProps, AddProductImageOwnProps>> = ({
  error,
  handleSubmit,
  goBack,
  form
}) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const isMobile = useSelector(selectIsUserDeviceMobile)
  return (
    <Grid container spacing={0} alignItems='center' justifyContent='flex-start'>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <Field
            isSingleFileInput
            name='key'
            component={SelectMultipleMediaField}
            mediaType={MediaType.image}
            formName={form}
          />
          <Grid item xs={12} sm={12}>
            <ErrorMessage message={error} />
          </Grid>
          <DialogActions className={isMobile ? classes.dialogActionsMobile : undefined}>
            {goBack && (
              <Button onClick={() => goBack()} color='primary'>
                {t('general.back')}
              </Button>
            )}
            <Button color='primary' type='submit'>
              {t('general.next')}
            </Button>
          </DialogActions>
        </form>
      </Grid>
    </Grid>
  )
}

export default reduxForm<FormDataProps, AddProductImageOwnProps>({
  form: 'AddProductWizardForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(AddProductImage)
