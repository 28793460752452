import { createAsyncActions, createAction } from './utils'

const reducer = 'IOT'

const {
  action: initializeIot,
  actionSuccess: initializeIotSuccess,
  actionFail: initializeIotFail
} = createAsyncActions(reducer, 'INITIALIZE_IOT')

const {
  action: onIoTMessage,
  actionSuccess: onIoTMessageSuccess,
  actionFail: onIoTMessageFail
} = createAsyncActions(reducer, 'ON_IOT_MESSAGE')

const setIotConnectionState = createAction(reducer, 'SET_IOT_CONNECTION_STATE')

const setIntegrationIotData = createAction(reducer, 'SET_INTEGRATION_IOT_DATA')
const resetIntegrationIotData = createAction(reducer, 'RESET_INTEGRATION_IOT_DATA')

export {
  initializeIot,
  initializeIotSuccess,
  initializeIotFail,
  onIoTMessage,
  onIoTMessageSuccess,
  onIoTMessageFail,
  setIntegrationIotData,
  resetIntegrationIotData,
  setIotConnectionState
}
