import { Typography } from '@mui/material'
import React, { Fragment } from 'react'
import { ErrorLog } from '@seesignage/seesignage-utils'

const getLogStreamLink = (logGroupName: string, logStreamName: string) =>
  `https://eu-central-1.console.aws.amazon.com/cloudwatch/home?region=eu-central-1#logsV2:log-groups/log-group/${encodeURIComponent(
    logGroupName
  )}/log-events/${encodeURIComponent(logStreamName)}`

const SelectedError: React.FC<{ errorLog: ErrorLog }> = ({
  errorLog: {
    api,
    id,
    logGroupName,
    logStreamName,
    date,
    err,
    func,
    time,
    status,
    sub,
    pathParameters,
    queryStringParameters,
    invokeEvent
  }
}) => (
  <div>
    <Typography variant='subtitle2'>Id</Typography>
    <Typography variant='body1'>{id}</Typography>
    <Typography variant='subtitle2'>Date</Typography>
    <Typography variant='body1'>{date}</Typography>
    <Typography variant='subtitle2'>Time</Typography>
    <Typography variant='body1'>{time}</Typography>
    {logGroupName && logStreamName && (
      <Fragment>
        <Typography variant='subtitle2'>logGroupName</Typography>
        <Typography variant='body1'>{logGroupName}</Typography>
        <Typography variant='subtitle2'>logStreamName</Typography>
        <Typography variant='body1'>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href={getLogStreamLink(logGroupName, logStreamName)}>
            {logStreamName}
          </a>
        </Typography>
      </Fragment>
    )}
    {api && (
      <Fragment>
        <Typography variant='subtitle2'>Service (legacy prop)</Typography>
        <Typography variant='body1'>{api}</Typography>
      </Fragment>
    )}
    {func && (
      <Fragment>
        <Typography variant='subtitle2'>Function (legacy prop)</Typography>
        <Typography variant='body1'>{func}</Typography>
      </Fragment>
    )}
    <Typography variant='subtitle2'>Status</Typography>
    <Typography variant='body1'>{status ? status : '-'}</Typography>
    <Typography variant='subtitle2'>Sub (userId)</Typography>
    <Typography variant='body1'>{sub ? sub : '-'}</Typography>
    {pathParameters ? (
      <Fragment>
        <Typography variant='subtitle2'>Path parameters</Typography>
        <Typography variant='body1'>{JSON.stringify(pathParameters)}</Typography>
      </Fragment>
    ) : (
      <Fragment />
    )}
    {queryStringParameters ? (
      <Fragment>
        <Typography variant='subtitle2'>Query String Parameters</Typography>
        <Typography variant='body1'>{JSON.stringify(queryStringParameters)}</Typography>
      </Fragment>
    ) : (
      <Fragment />
    )}
    {invokeEvent ? (
      <Fragment>
        <Typography variant='subtitle2'>Invoke event</Typography>
        <Typography variant='body1'>{JSON.stringify(invokeEvent)}</Typography>
      </Fragment>
    ) : (
      <Fragment />
    )}
    <Typography variant='subtitle2'>Error</Typography>
    <Typography variant='body1'>{err}</Typography>
  </div>
)

export default SelectedError
