import React, { useState, Fragment } from 'react'
import {
  Avatar,
  Button,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  ListItemIcon,
  ListItemButton
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import DeleteIcon from 'mdi-react/DeleteIcon'
import AddIcon from 'mdi-react/AddIcon'
import EditIcon from 'mdi-react/EditIcon'
import DoneIcon from 'mdi-react/DoneIcon'
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon'
import ArrowRightIcon from 'mdi-react/ArrowRightIcon'
import Skeleton from '@mui/material/Skeleton'
import { SocialMediaPlatform } from '@seesignage/seesignage-utils'
import { useTranslation } from 'react-i18next'
import colors from '../../../../../styles/common/colors'
import AlertDialog from '../../../../../components/AlertDialog'
import SocialMediaGuidePanel from '../../../../../components/ExtensionPanels/SocialMediaGuidePanel'

const useStyles = makeStyles(() => ({
  list: {
    maxHeight: '60vh',
    overflowY: 'hidden'
  },
  card: {
    width: '240px',
    height: '70px',
    marginTop: '5px',
    borderLeft: `8px solid white`
  },
  selectedCard: {
    borderLeft: `8px solid ${colors.seesignageColor}`
  },
  cardAction: {
    height: '100%',
    width: '100%',
    display: 'flex',
    padding: '0px 10px 0px 10px',
    justifyContent: 'space-between'
  },
  cardText: {
    fontSize: '18px',
    fontWeight: 'bold'
  },
  accountsControls: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  accountSelectorContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  instagramAccountsContainer: {
    width: '250px',
    minHeight: '250px',
    background: 'rgba(0, 0, 0, 0.05)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  facebookAccountsContainer: {
    width: '250px',
    height: '250px',
    overflowX: 'hidden',
    overflowY: 'scroll',
    background: 'rgba(0, 0, 0, 0.05)'
  },
  accountsAndPagesWrapper: {
    width: '200%',
    minHeight: '100%',
    display: 'flex',
    transitionDuration: '200ms'
  },
  accountsAndPagesContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center'
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  editButton: {
    alignSelf: 'end'
  },
  title: {
    fontWeight: 500,
    marginBottom: 10
  },
  requirementsSteps: {
    margin: 0
  }
}))

interface ListItem {
  id: string
  url?: string | undefined
  primaryText: string
  secondaryText?: string | undefined
}

export interface AccountsListProps {
  loading: boolean
  editMode: boolean
  editable?: boolean
  platform: SocialMediaPlatform
  onEditButtonClick: () => void
  onAddButtonClick: () => void
  onBackButtonClick?: () => void
  onClickItem: (id: string) => void
  items: ListItem[]
}

const AccountsList = ({
  loading,
  editMode,
  platform,
  onAddButtonClick,
  onEditButtonClick,
  onBackButtonClick,
  editable,
  onClickItem,
  items
}: AccountsListProps) => {
  const [t] = useTranslation()
  const classes = useStyles()
  const [deleteAccountDialogVisiblity, setDeleteAccountDialogVisiblity] = useState(false)
  const [confirmAddAccountDialogVisibility, setConfirmAddAccountDialogVisibility] = useState(false)
  const [clickedAccount, setClickedAccount] = useState<ListItem | null>(null)
  const handleClickItem = (item: ListItem) => {
    if (editMode) {
      setClickedAccount(item)
      setDeleteAccountDialogVisiblity(true)
    } else {
      onClickItem(item.id)
    }
  }

  const handleCloseDialog = () => {
    setDeleteAccountDialogVisiblity(false)
    setClickedAccount(null)
  }

  return (
    <div className={classes.content}>
      <AlertDialog
        type='delete'
        onConfirm={() => {
          onClickItem(clickedAccount?.id ?? '')
          handleCloseDialog()
        }}
        onCancel={handleCloseDialog}
        open={deleteAccountDialogVisiblity}
        title={`${t('contents.widgets.socialMedia.deleteAccountDialogTitle')}: ${
          clickedAccount?.primaryText
        }`}
        contentText={t('contents.widgets.socialMedia.deleteAccountDialogContent')}
        confirmText={t('general.delete')}
        cancelText={t('general.cancel')}
      />
      <AlertDialog
        type='basic'
        onConfirm={() => {
          setConfirmAddAccountDialogVisibility(false)
          onAddButtonClick()
        }}
        onCancel={() => setConfirmAddAccountDialogVisibility(false)}
        open={confirmAddAccountDialogVisibility}
        title={t('contents.widgets.socialMedia.confirmAddAccountDialog.title')}
        Content={
          <Fragment>
            <Typography variant='body1' gutterBottom>
              {t('contents.widgets.socialMedia.confirmAddAccountDialog.content1')}
            </Typography>
            <Typography variant='body1' gutterBottom>
              {t('contents.widgets.socialMedia.confirmAddAccountDialog.content2')}{' '}
              <a href={t('info.privacyPolicyUrl')} target='_blank' rel='noopener noreferrer'>
                {t('contents.widgets.socialMedia.confirmAddAccountDialog.privacyPolicyLinkText')}
              </a>
            </Typography>
            <Typography variant='body1' gutterBottom>
              {t('contents.widgets.socialMedia.confirmAddAccountDialog.content3')}
            </Typography>
          </Fragment>
        }
        confirmText={t('general.add')}
        cancelText={t('general.cancel')}
      />
      <Grid container justifyContent='center'>
        <Grid container item xs={12} md={10}>
          <Grid item xs={12}>
            <Typography variant='h5' gutterBottom textTransform='capitalize'>
              {platform}
            </Typography>
          </Grid>
          <Grid item xs={12} justifyContent='space-between' container>
            {editable ? (
              <>
                <Button
                  startIcon={editMode ? <DoneIcon /> : <EditIcon />}
                  color='primary'
                  disabled={!editMode && items.length === 0}
                  onClick={onEditButtonClick}>
                  {editMode
                    ? t('contents.widgets.socialMedia.editDone')
                    : t('contents.widgets.socialMedia.edit')}
                </Button>
                <Button
                  startIcon={<AddIcon />}
                  color='primary'
                  disabled={editMode}
                  onClick={() => setConfirmAddAccountDialogVisibility(true)}>
                  {t('contents.widgets.socialMedia.addAccount')}
                </Button>
              </>
            ) : (
              <Button startIcon={<ArrowLeftIcon />} color='primary' onClick={onBackButtonClick}>
                {t('contents.widgets.socialMedia.account')}
              </Button>
            )}
          </Grid>
          <Grid item xs={12}>
            <List dense classes={{ root: classes.list }}>
              {loading ? (
                [1, 2, 3].map(id => (
                  <Fragment key={id}>
                    <ListItem>
                      <ListItemButton>
                        <ListItemIcon>
                          <Skeleton animation='wave' variant='circular' width={40} height={40} />
                        </ListItemIcon>
                        <ListItemText
                          primary={<Skeleton animation='wave' height={20} />}
                          secondary={<Skeleton animation='wave' height={10} width='80%' />}
                        />
                      </ListItemButton>
                    </ListItem>
                    <Divider />
                  </Fragment>
                ))
              ) : items.length === 0 ? (
                <Typography variant='h6' align='center' color='textSecondary'>
                  {t('contents.widgets.socialMedia.noItemsFound.account')}
                </Typography>
              ) : (
                <>
                  <Divider />
                  {items.map(item => (
                    <Fragment key={item.id}>
                      <ListItem disablePadding>
                        <ListItemButton onClick={() => handleClickItem(item)}>
                          <ListItemAvatar>
                            {item.url ? (
                              <Avatar src={item.url} />
                            ) : (
                              <Skeleton
                                animation='wave'
                                variant='circular'
                                width={40}
                                height={40}
                              />
                            )}
                          </ListItemAvatar>
                          <ListItemText
                            primary={<Typography variant='h6'>{item.primaryText}</Typography>}
                            secondary={item.secondaryText}
                          />
                          <ListItemSecondaryAction>
                            <IconButton edge='end'>
                              {editMode ? <DeleteIcon color='red' /> : <ArrowRightIcon />}
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItemButton>
                      </ListItem>
                      <Divider light />
                    </Fragment>
                  ))}
                </>
              )}
            </List>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent='center'>
        <Grid item xs={12} md={10}>
          <SocialMediaGuidePanel t={t} platform={platform} />
        </Grid>
      </Grid>
    </div>
  )
}

export default AccountsList
