import React, { Fragment } from 'react'
import classNames from 'classnames'
import {
  ImageListItem,
  ImageListItemBar,
  Typography,
  IconButton,
  Tooltip,
  Chip
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon'
import { MediaType } from '@seesignage/seesignage-utils'
import { useTranslation } from 'react-i18next'
import { FileWithPreview } from '../../../types/media'
import { getMediaTypeFromInputFile } from '../../../utils/media'

const useStyles = makeStyles(() => ({
  pdf: {
    fontWeight: 600,
    color: 'red'
  },
  closeButton: {
    color: '#ffffff'
  },
  imageListItem: {
    margin: 2,
    userSelect: 'none',
    '& .MuiImageListItem-img': {
      display: 'block',
      margin: 'auto',
      height: '100%',
      width: '100%',
      objectFit: 'contain'
    }
  },
  imageListItemError: {
    boxShadow: 'inset 0px 0px 0px 2px #F03021'
  },
  media: {
    height: 147,
    width: 261.33,
    objectFit: 'contain'
  },
  imageListItemBarTop: {
    background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)' as any
  },
  imageListItemBarBottom: {
    background: 'none'
  },
  fileTypeChip: {
    color: '#fafafa',
    backgroundColor: '#1e88e5'
  },
  errorChip: {
    color: '#fafafa',
    backgroundColor: '#F03021',
    marginRight: 2
  },
  imageListItemBarChips: {
    margin: '0px 5px 0px 5px'
  }
}))

interface FileThumbnailProps {
  file: FileWithPreview
  errors?: string[]
  removeItem: () => void
}

const FileThumbnail: React.FC<FileThumbnailProps> = ({ file, removeItem, errors }) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const fileType = getMediaTypeFromInputFile(file)
  const { name, preview } = file
  const hasErrors = errors && errors?.length > 0

  return (
    <ImageListItem
      className={classNames(classes.imageListItem, {
        [classes.imageListItemError]: hasErrors
      })}>
      {fileType === 'pdf' && (
        <embed src={preview} type='application/pdf' className={classes.media} />
      )}
      {fileType === MediaType.image && <img src={preview} alt={name} />}
      {fileType === MediaType.video && (
        <video src={preview} preload='none' className={classes.media} />
      )}
      {fileType === undefined && <div className={classes.media}></div>}

      <ImageListItemBar
        title={
          <Tooltip disableInteractive title={name}>
            <Typography noWrap>{name}</Typography>
          </Tooltip>
        }
        position='top'
        actionPosition='right'
        className={classes.imageListItemBarTop}
        actionIcon={
          <div>
            <IconButton
              className={classes.closeButton}
              onClick={() => {
                removeItem()
              }}
              size='large'>
              <CloseCircleOutlineIcon size={20} />
            </IconButton>
          </div>
        }
      />
      <ImageListItemBar
        classes={{
          titleWrap: classes.imageListItemBarChips
        }}
        title={
          <Fragment>
            {errors && errors?.length > 0 ? (
              <Fragment>
                {errors.map((error, index) => (
                  <Chip
                    key={`error-${index}`}
                    label={t(`validation.mediaUpload.${error}`)}
                    className={classes.errorChip}
                  />
                ))}
              </Fragment>
            ) : (
              <Chip
                label={t(`media.mediaUpload.fileType.${fileType ? fileType : 'invalid'}`)}
                className={classes.fileTypeChip}
              />
            )}
          </Fragment>
        }
        position='bottom'
        className={classes.imageListItemBarBottom}
      />
    </ImageListItem>
  )
}

export default FileThumbnail
