import React, { useEffect } from 'react'
import { InjectedFormProps, Field, reduxForm } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { DialogActions, Button, Typography } from '@mui/material'
import { MoveScreenFormData } from '../../../types/screens'
import { selectEnvironmentsAsOptions } from '../../../selectors/environments'
import { listEnvironments } from '../../../actions/environments'
import { bindSubmitActionToPromise } from '../../../utils/forms'
import { moveScreen } from '../../../actions/screens'
import { ReduxSelectWithAutocomplete } from '../../../components/FormInput/ReduxWrappers'
import { closeDialog } from '../../../actions/dialogs'
import ErrorMessage from '../../../components/Errors/ErrorMessage'

interface MoveScreenFormProps {
  dialogId: string
}

const MoveScreenForm: React.FC<MoveScreenFormProps &
  InjectedFormProps<MoveScreenFormData, MoveScreenFormProps>> = ({
  dialogId,
  handleSubmit,
  error,
  submitting
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const environments = useSelector(selectEnvironmentsAsOptions())

  useEffect(() => {
    dispatch(listEnvironments())
  }, []) // eslint-disable-line
  return (
    <form onSubmit={handleSubmit(bindSubmitActionToPromise(dispatch, moveScreen))}>
      <Typography>{t('screens.moveDialogText')}</Typography>
      <Field
        name='destinationEnvironment'
        options={environments}
        component={ReduxSelectWithAutocomplete}
        label={t('environment.select')}
      />
      {error && <ErrorMessage message={error} />}
      <DialogActions>
        <Button
          disabled={submitting}
          color='primary'
          onClick={() => dispatch(closeDialog(dialogId))}>
          {t('general.cancel')}
        </Button>
        <Button disabled={submitting} type='submit' color='primary'>
          {t('general.move')}
        </Button>
      </DialogActions>
    </form>
  )
}

export default reduxForm<MoveScreenFormData, MoveScreenFormProps>({
  form: 'MoveScreenForm'
})(MoveScreenForm)
