import React from 'react'
import { AppBar, Toolbar, FormControlLabel, Switch } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { MobileDatePicker } from '@mui/x-date-pickers'
import { toolbarStyles } from '../../styles/common'

const useStyles = makeStyles(theme => ({
  ...toolbarStyles(theme),
  switch: {
    width: '220px',
    marginLeft: 10
  }
}))

const ErrorLogsToolbar: React.FC<{
  setSelectedDate: React.Dispatch<React.SetStateAction<Date | null>>
  selectedDate: Date | null
  setShowLastWeek: React.Dispatch<React.SetStateAction<boolean>>
  showLastWeek: boolean
}> = ({ setSelectedDate, selectedDate, setShowLastWeek, showLastWeek }) => {
  const classes = useStyles()
  return (
    <AppBar className={classes.appBar} position='sticky' color='default'>
      <Toolbar>
        <MobileDatePicker
          format='dd/MM/yyyy'
          label='Date'
          value={selectedDate}
          onChange={(date: Date | null) => setSelectedDate(date)}
          slotProps={{
            textField: {
              size: 'small'
            },
            toolbar: {
              hidden: true
            }
          }}
        />
        <FormControlLabel
          className={classes.switch}
          control={
            <Switch
              checked={showLastWeek}
              onChange={e => setShowLastWeek(e.target.checked)}
              color='secondary'
              name='showLastWeek'
            />
          }
          label='Last 7 days'
        />
        <div className={classes.spacer} />
        <div className={classes.actions}></div>
      </Toolbar>
    </AppBar>
  )
}

export default ErrorLogsToolbar
