import { handleActions } from 'redux-actions'
import { openDialog, closeDialog, restoreDialogMaxWidth, setDialogSize } from '../actions/dialogs'
import { DialogsState } from '../types/states'
import { ToggleDialogVisibility, SetDialogSize } from '../types/actions'

const initialState: DialogsState = {
  visibility: {
    id: undefined,
    isVisible: false
  },
  size: {}
}

const dialogs = handleActions<DialogsState, any>(
  {
    [openDialog]: (state, { payload: id }: ToggleDialogVisibility) => ({
      ...state,
      visibility: {
        id,
        isVisible: true
      }
    }),
    [closeDialog]: (state, { payload: id }: ToggleDialogVisibility) => ({
      ...state,
      visibility: {
        id,
        isVisible: false
      },
      size: {
        maxWidth: undefined,
        fullScreen: false
      }
    }),
    [setDialogSize]: (state, { payload: size }: SetDialogSize) => ({
      ...state,
      size
    }),
    [restoreDialogMaxWidth]: state => ({
      ...state,
      size: {
        maxWidth: undefined,
        fullScreen: false
      }
    })
  },
  initialState
)

export default dialogs
