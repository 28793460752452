import React from 'react'
import { reduxForm, Field, InjectedFormProps } from 'redux-form'
import { InputAdornment, Grid, Typography, Divider } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { TFunction } from 'i18next'
import { FabricWidgetObjectFit } from '@seesignage/seesignage-utils'
import {
  ContentMediaCarouselSettingsFormData,
  MediaCarouselWidgetDrawerSettings
} from '../../../../../../types/contents'
import {
  ReduxColorPicker,
  ReduxDropDownSelection,
  ReduxTextField
} from '../../../../../../components/FormInput/ReduxWrappers'
import SimpleExtensionPanel from '../../../../../../components/ExtensionPanels/SimpleExtensionPanel'
import VolumeControl from '../../../../../../components/FormInput/ReduxWrappers/Contents/VolumeControl'
import { greaterThanZero } from '../../../../../../validation'

const useStyles = makeStyles(() => ({
  divider: { marginTop: '10px' },
  settingsTitle: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '12px',
    marginBottom: '4px'
  },
  titleMarginLeft: {
    marginLeft: 10
  },
  accordionSummaryRootClass: {
    backgroundColor: '#F5F5F5',
    borderBottom: '1px solid rgba(0, 0, 0, 0.09)'
  },
  gridItem: {
    display: 'flex',
    justifyContent: 'center'
  },
  shortTextfield: {
    maxWidth: '85px'
  },
  littleTopMargin: {
    marginTop: '9px'
  }
}))

interface OwnProps {
  t: TFunction
  isPlaylistType: boolean
}

type FormProps = ContentMediaCarouselSettingsFormData
type ComponentProps = OwnProps

const ContentMediaCarouselSettingsForm: React.FC<ComponentProps &
  InjectedFormProps<FormProps, ComponentProps>> = ({ t, isPlaylistType }) => {
  const classes = useStyles()
  return (
    <SimpleExtensionPanel
      title={t('contents.widgets.mediaCarousel.generalSettings')}
      isOpen={true}
      accordionSummaryRootClass={classes.accordionSummaryRootClass}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className={classes.settingsTitle}>
            {t('contents.widgets.mediaCarousel.objectFit')}
          </Typography>
          <Field
            name={MediaCarouselWidgetDrawerSettings.widgetObjectFit}
            component={ReduxDropDownSelection}
            labelId='media-carousel-widget-objectFit'
            size='small'
            label=''
            options={Object.keys(FabricWidgetObjectFit).map(item => ({
              label: t(`contents.widgets.mediaCarousel.${item}`),
              value: item
            }))}
          />
          <Divider className={classes.divider} />
        </Grid>
        {!isPlaylistType && (
          <Grid item xs={12}>
            <Typography className={classes.settingsTitle}>
              {t('contents.widgets.mediaCarousel.interval')}
            </Typography>
            <Field
              name={MediaCarouselWidgetDrawerSettings.slideShowInterval}
              component={ReduxTextField}
              variant='outlined'
              size='small'
              type='number'
              fullWidth
              InputProps={{
                inputProps: { min: 1, max: 30, step: 1 },
                endAdornment: <InputAdornment position='end'>s</InputAdornment>
              }}
              validate={[greaterThanZero]}
              required
              format={(value: any) => (value ? value / 1000 : '')}
              parse={(value: any) => (value ? value * 1000 : '')}
            />
            <Divider className={classes.divider} />
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography className={classes.settingsTitle}>
            {t('contents.properties.backgroundColor')}
          </Typography>
          <Field
            name={MediaCarouselWidgetDrawerSettings.widgetBackground}
            size='small'
            component={ReduxColorPicker}
          />
          <Divider className={classes.divider} />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.settingsTitle}>
            {t('contents.widgets.mediaCarousel.videoAudio')}
          </Typography>
          <Field
            name={MediaCarouselWidgetDrawerSettings.videosMuted}
            component={VolumeControl}
            format={(value: boolean) => !value}
            parse={(value: boolean) => !value}
            type='checkbox'
          />
          <Divider className={classes.divider} />
        </Grid>
      </Grid>
    </SimpleExtensionPanel>
  )
}

export default reduxForm<FormProps, ComponentProps>({
  form: 'ContentMediaCarouselSettingsForm',
  enableReinitialize: true
})(ContentMediaCarouselSettingsForm)
