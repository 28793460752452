import { handleActions } from 'redux-actions'
import { Customer } from '@seesignage/seesignage-utils'
import {
  createCustomerSuccess,
  deleteCustomerSuccess,
  getCustomersSuccess,
  listAllCustomers,
  listAllCustomersFail,
  listAllCustomersSuccess,
  resetCustomers,
  selectCustomer,
  updateCustomerSuccess,
  selectCustomerRow,
  deselectCustomerRow
} from '../actions/customers'
import { CustomersById, CustomersState } from '../types/states'
import {
  SelectCustomer,
  GetCustomersSuccess,
  ListAllCustomersSuccess,
  DeleteCustomerSuccess,
  CreateCustomersSuccess,
  UpdateCustomersSuccess,
  Action
} from '../types/actions'

const indexCustomers = (customersArray: Customer[]) =>
  customersArray.reduce((customers, customer) => {
    customers[customer.customerId] = {
      ...customer
    }
    return customers
  }, {} as CustomersById)

const initialState: CustomersState = {
  customers: {},
  allCustomers: {},
  selectedCustomerId: undefined,
  selectedCustomerRow: undefined,
  isListAllLoading: false
}

const customers = handleActions<CustomersState, any>(
  {
    [resetCustomers]: () => initialState,
    [selectCustomer]: (state, { payload: selectedCustomerId }: SelectCustomer) => ({
      ...state,
      selectedCustomerId
    }),
    [getCustomersSuccess]: (state, { payload: customersArray }: GetCustomersSuccess) => ({
      ...state,
      customers: indexCustomers(customersArray)
    }),
    [listAllCustomers]: state => ({
      ...state,
      isListAllLoading: true
    }),
    [listAllCustomersFail]: state => ({
      ...state,
      isListAllLoading: false
    }),
    [listAllCustomersSuccess]: (state, { payload: customersArray }: ListAllCustomersSuccess) => ({
      ...state,
      allCustomers: indexCustomers(customersArray),
      isListAllLoading: false
    }),
    [deleteCustomerSuccess]: (state, { payload: customerId }: DeleteCustomerSuccess) => {
      const allCustomers = { ...state.allCustomers }
      delete allCustomers[customerId]
      return {
        ...state,
        selectedCustomerId: undefined,
        allCustomers
      }
    },
    [createCustomerSuccess]: (state, { payload: customer }: CreateCustomersSuccess) => ({
      ...state,
      allCustomers: {
        ...state.allCustomers,
        [customer.customerId]: customer
      }
    }),
    [updateCustomerSuccess]: (state, { payload: customer }: UpdateCustomersSuccess) => ({
      ...state,
      allCustomers: {
        ...state.allCustomers,
        [customer.customerId]: {
          ...state.allCustomers[customer.customerId],
          ...customer
        }
      }
    }),
    [selectCustomerRow]: (state, { payload: customerId }: Action<string>) => ({
      ...state,
      selectedCustomerRow: customerId
    }),
    [deselectCustomerRow]: state => ({
      ...state,
      selectedCustomerRow: undefined
    })
  },
  initialState
)

export default customers
