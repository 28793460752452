/* eslint-disable @typescript-eslint/camelcase */

import nanoid from 'nanoid'
import { isCookieConsentGiven } from '../services/localStorage/cookies'
import { UserAttributes } from '../types/auth'

const TAWK_SITE_ID = process.env.REACT_APP_TAWK_SITE_ID

declare let Tawk_API: any

/**
 * Configure Tawk chat only when it is not already configured and cookie consent is given.
 * Set tawk visitor info if given.
 */
const configureTawk = async (userAttributes?: UserAttributes) => {
  if (!document.getElementById('tawk-script') && isCookieConsentGiven()) {
    const s1 = document.createElement('script')
    s1.id = 'tawk-script'
    const s0 = document.getElementsByTagName('script')[0]
    s1.async = true
    s1.src = `https://embed.tawk.to/${TAWK_SITE_ID}/default`
    s1.charset = 'UTF-8'
    s1.setAttribute('crossorigin', '*')
    if (s0 && s0.parentNode) {
      s0.parentNode.insertBefore(s1, s0)
    }

    if (userAttributes?.name && userAttributes?.email) {
      // timeout before setting visitor as it takes a while until tawk is ready.
      setTimeout(() => setTawkVisitor(userAttributes.name, userAttributes.email), 1500)
    }
  }
}

const setTawkVisitor = (name: string, email: string) => {
  try {
    Tawk_API.setAttributes({
      name,
      email,
      hash: nanoid()
    })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('setTawkVisitor failed', error)
  }
}

const maximizeTawkWidget = () => {
  try {
    Tawk_API.maximize()
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('maximizeTawkWidget failed', error)
  }
}

const hideTawkWidget = () => {
  try {
    Tawk_API.hideWidget()
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('hideTawkWidget failed', error)
  }
}

const showTawkWidget = () => {
  try {
    Tawk_API.showWidget()
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('showTawkWidget failed', error)
  }
}

export { configureTawk, setTawkVisitor, maximizeTawkWidget, hideTawkWidget, showTawkWidget }
