export default {
  textAlign: ['', 'left', 'right', 'center', 'justify'],
  display: [
    '',
    'inline',
    'block',
    'contents',
    'flex',
    'grid',
    'inline-block',
    'inline-flex',
    'inline-grid',
    'inline-table',
    'list-item',
    'run-in',
    'table',
    'table-caption',
    'table-column-group',
    'table-header-group',
    'table-footer-group',
    'table-row-group',
    'table-cell',
    'table-column',
    'table-row',
    'none',
    'initial',
    'inherit'
  ],
  position: ['', 'static', 'relative', 'fixed', 'absolute', 'sticky'],
  fontWeight: [
    'initial',
    'inherit',
    100,
    150,
    200,
    250,
    300,
    350,
    400,
    450,
    500,
    550,
    600,
    650,
    700,
    750,
    800,
    850,
    900
  ],
  textTransform: ['', 'capitalize', 'uppercase', 'lowercase'],
  fontStyle: ['', 'normal', 'italic', 'oblique', 'initial', 'inherit'],
  objectFit: ['none', 'fill', 'contain', 'cover', 'scale-down'],
  boxSizing: ['content-box', 'border-box', 'initial', 'inherit'],
  /** Described in seesignage-player-utils */
  animations: [
    'none',
    'imageZoomInAndOut',
    'imageZoomInAndOutBig',
    'imageZoomInAndOutAndRotate',
    'fadeIn',
    'swing',
    'bounce'
  ],
  animationTimingFunction: [
    'none',
    'linear',
    'ease',
    'ease-in',
    'ease-out',
    'ease-in-out',
    'step-start',
    'step-end',
    'initial',
    'inherit'
  ],
  animationDirection: [
    'none',
    'normal',
    'reverse',
    'alternate',
    'alternate-reverse',
    'initial',
    'inherit'
  ],
  animationFillMode: ['none', 'forwards', 'backwards', 'both', 'initial', 'inherit'],
  mixBlendMode: [
    'none',
    'normal',
    'multiply',
    'screen',
    'overlay',
    'darken',
    'lighten',
    'color-dodge-dodge',
    'color-burn',
    'difference',
    'exclusion',
    'hue',
    'saturation',
    'color',
    'luminosity'
  ]
}
