import { Typography, LinearProgress, Card, CardMedia, CardContent, Link } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Language, ReleaseNote, WordpressLanguage } from '@seesignage/seesignage-utils'
import { useSelector } from 'react-redux'
import EnvironmentsApi from '../../services/api/environments'
import { selectLanguage } from '../../selectors/users'

const useStyles = makeStyles(() => ({
  title: {
    padding: 5
  },
  releaseNoteTitle: {
    fontSize: '1.1rem'
  },
  cardRoot: {
    display: 'flex',
    marginBottom: 10,
    '&:hover': {
      boxShadow: '0px 0px 0px 2px #04ADEF'
    }
  },
  cardDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  cardMedia: {
    width: 150
  },
  cardContent: {
    flex: '1 0 auto',
    textAlign: 'left'
  }
}))

const ReleaseNotes = () => {
  const [t] = useTranslation()
  const classes = useStyles()
  const [isLoading, setLoading] = useState<boolean>(true)
  const [releaseNotes, setReleaseNotes] = useState<ReleaseNote[]>([])
  const language = useSelector(selectLanguage)

  useEffect(() => {
    let isApiSubscribed = true
    const getReleaseNotes = async () => {
      const lang = language === Language.fi ? WordpressLanguage.fi : WordpressLanguage.en
      const notes = await EnvironmentsApi.getReleaseNotes(lang)
      if (isApiSubscribed) {
        setReleaseNotes(notes)
        setLoading(false)
      }
    }

    getReleaseNotes()
    return () => {
      // cancel the subscription
      isApiSubscribed = false
    }
  }, [language])

  return (
    <div>
      <Typography component='h2' variant='h5' gutterBottom className={classes.title}>
        {t('home.whatsNew')}
      </Typography>
      {isLoading && <LinearProgress />}
      {releaseNotes.map(({ id, date, link, title, thumbnailUrl }) => (
        <Link key={id} target='_blank' href={link} underline='none'>
          <Card className={classes.cardRoot}>
            {thumbnailUrl && (
              <CardMedia className={classes.cardMedia} image={thumbnailUrl} title={title} />
            )}
            <div className={classes.cardDetails}>
              <CardContent className={classes.cardContent}>
                <Typography component='h3' variant='h6' className={classes.releaseNoteTitle}>
                  {title}
                </Typography>
                <Typography variant='subtitle1' color='textSecondary'>
                  {`${moment(date, 'YYYYMMDD').format('DD.MM.YYYY')}`}
                </Typography>
              </CardContent>
            </div>
          </Card>
        </Link>
      ))}
      <Link
        target='_blank'
        href={`https://seesignage.com/${language === Language.en ? 'en/' : ''}release-notes/`}>
        {t('home.navigateToReleaseNotes')}
      </Link>
    </div>
  )
}

export default ReleaseNotes
