import { API } from 'aws-amplify'
import { Channel, ChannelItem } from '@seesignage/seesignage-utils'
import { CreateChannelInterface } from '../../types/channels'

const getChannels = async (environmentId: string, includeParentChannels?: boolean) => {
  const options = includeParentChannels
    ? {
        queryStringParameters: {
          includeParentChannels: true
        }
      }
    : {}
  const channels: Channel[] = await API.get(
    'channels',
    `/environments/${environmentId}/channels`,
    options
  )
  return channels
}

const getChannel = async (environmentId: string, channelId: string) => {
  const channel: Channel = await API.get(
    'channels',
    `/environments/${environmentId}/channels/${channelId}`,
    {}
  )
  return channel
}

const createChannel = async (environmentId: string, channel: CreateChannelInterface) => {
  const newChannel: Channel = await API.post(
    'channels',
    `/environments/${environmentId}/channels`,
    {
      body: channel
    }
  )
  return newChannel
}

const updateChannel = async (
  environmentId: string,
  channelId: string,
  channel: CreateChannelInterface
) => {
  const updatedChannel: Channel = await API.put(
    'channels',
    `/environments/${environmentId}/channels/${channelId}`,
    { body: channel }
  )
  return updatedChannel
}

const updateChannelItems = async (
  environmentId: string,
  channelId: string,
  items: ChannelItem[]
) => {
  const updatedAttributes = await API.put(
    'channels',
    `/environments/${environmentId}/channels/${channelId}/items`,
    { body: items }
  )
  return updatedAttributes
}

const deleteChannel = async (environmentId: string, channelId: string) => {
  await API.del('channels', `/environments/${environmentId}/channels/${channelId}`, {})
}

/**
 * List channels by channel item property
 * @param environmentId
 * @param playlistId
 * @param scope
 */
const listChannelsBy = async (
  environmentId: string,
  playlistId?: string,
  scope?: 'all' | 'name'
) => {
  try {
    const options =
      playlistId && scope
        ? {
            queryStringParameters: {
              playlistId,
              scope
            }
          }
        : {}
    const channels: Channel[] | string[] = await API.get(
      'channels',
      `/environments/${environmentId}/channels/listBy`,
      options
    )
    return channels
  } catch (error) {
    return []
  }
}

export default {
  getChannels,
  getChannel,
  createChannel,
  updateChannel,
  updateChannelItems,
  deleteChannel,
  listChannelsBy
}
