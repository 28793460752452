import React, { useEffect } from 'react'
import { Field, FormName, change, formValueSelector } from 'redux-form'
import { Grid, MenuItem, TextField } from '@mui/material'
import { TFunction } from 'i18next'
import { BorderStyle } from '@seesignage/seesignage-utils'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxTextField } from '../../../../../../components/FormInput/ReduxWrappers'
import ContentColorPicker from '../../../../../../components/FormInput/ColorPicker/ContentColorPicker'
import SimpleExtensionPanel from '../../../../../../components/ExtensionPanels/SimpleExtensionPanel'

const sampleBorderWidths = [...Array(20).keys()]
const customBorders = ['Left', 'Right', 'Top', 'Bottom']

interface BorderfieldsProps {
  namePrefix: string
  isFieldsDisabled: boolean
  t: TFunction
}

interface BorderfieldsWithFormNameProps extends BorderfieldsProps {
  formName: string
}

const BorderfieldsWithFormName: React.FC<BorderfieldsWithFormNameProps> = ({
  namePrefix,
  t,
  isFieldsDisabled,
  formName
}) => {
  const dispatch = useDispatch()
  const style = useSelector(state => formValueSelector(formName)(state, namePrefix)) as any

  const {
    borderLeftStyle,
    borderRightStyle,
    borderBottomStyle,
    borderTopStyle,
    ...styleWithoutBorder
  } = style
  const handleGlobalBorderStyle = (newValue: string) => {
    if (newValue === 'unset') {
      dispatch(change(formName, namePrefix, styleWithoutBorder))
    } else {
      dispatch(
        change(formName, namePrefix, {
          ...styleWithoutBorder,
          borderLeftStyle: newValue,
          borderRightStyle: newValue,
          borderBottomStyle: newValue,
          borderTopStyle: newValue
        })
      )
    }
  }

  useEffect(() => {
    if (style.borderStyle) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { borderStyle, ...styleWithoutMainBorderStyle } = style
      dispatch(change(formName, namePrefix, styleWithoutMainBorderStyle))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          label={t('contents.actions.selectIframeBorderStyle')}
          variant='outlined'
          fullWidth
          size='small'
          disabled={isFieldsDisabled}
          onChange={e => handleGlobalBorderStyle(e.target.value)}
          value={
            borderLeftStyle &&
            borderLeftStyle === borderRightStyle &&
            borderTopStyle === borderBottomStyle &&
            borderLeftStyle === borderTopStyle
              ? borderLeftStyle
              : 'unset'
          }
          select>
          <MenuItem value={'unset'}>unset</MenuItem>
          {Object.values(BorderStyle).map(val => (
            <MenuItem value={val} key={`borderStyle-${val}`}>
              {val}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <Field
          name={`${namePrefix}.borderWidth`}
          label={t('contents.actions.selectIframeBorderWidth')}
          component={ReduxTextField}
          select
          fullWidth
          isToolbar
          disabled={isFieldsDisabled}>
          {sampleBorderWidths.map(val => (
            <MenuItem value={`${val}px`} key={`borderStyle-${val}`}>
              {val}
            </MenuItem>
          ))}
        </Field>
      </Grid>
      <Grid item xs={6}>
        <Field
          name={`${namePrefix}.borderColor`}
          label={t('contents.actions.selectIframeBorderColor')}
          component={ContentColorPicker}
          tooltip={t('contents.actions.selectIframeBorderColor')}
          disabled={isFieldsDisabled}
        />
      </Grid>
      {customBorders.map(border => (
        <Grid key={`${namePrefix}.-${border}`} item xs={12}>
          <SimpleExtensionPanel
            title={t(`contents.properties.border${border}`)}
            isOpen={false}
            renderOnlyExpandedChildren>
            <Grid container>
              <Grid item xs={2}>
                <Field
                  name={`${namePrefix}.border${border}Color`}
                  component={ContentColorPicker}
                  tooltip={t('contents.properties.borderColor')}
                  disabled={isFieldsDisabled}
                />
              </Grid>
              <Grid item xs={5}>
                <Field
                  name={`${namePrefix}.border${border}Style`}
                  component={ReduxTextField}
                  select
                  isToolbar
                  fullWidth
                  disabled={isFieldsDisabled}
                  size='small'>
                  {Object.values(BorderStyle).map(val => (
                    <MenuItem value={val} key={`borderStyle-${val}`}>
                      {val}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={5}>
                <Field
                  name={`${namePrefix}.border${border}Width`}
                  label={t('contents.properties.borderWidth')}
                  disabled={isFieldsDisabled}
                  component={ReduxTextField}
                  select
                  size='small'
                  fullWidth
                  isToolbar>
                  {sampleBorderWidths.map(val => (
                    <MenuItem value={`${val}px`} key={`borderStyle-${val}`}>
                      {val}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
            </Grid>
          </SimpleExtensionPanel>
        </Grid>
      ))}
    </Grid>
  )
}

const Borderfields = (props: BorderfieldsProps) => (
  <FormName>{({ form }) => <BorderfieldsWithFormName formName={form} {...props} />}</FormName>
)
export default Borderfields
