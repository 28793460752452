import React from 'react'
import { TFunction } from 'i18next'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import { Button, DialogActions, DialogContent, Grid } from '@mui/material'
import EmailOpenIcon from 'mdi-react/EmailOpenIcon'
import { useDispatch } from 'react-redux'
import { ReduxCheckbox } from '../../components/FormInput/ReduxWrappers'
import { subscribeUserToNewsLetter as subscribeUserToNewsLetterAction } from '../../actions/users'
import { bindSubmitActionToPromise } from '../../utils/forms'
import { SubscriptionsFormData } from '../../types/users'

const SUBSCRIPTIONS_FORM_NAME = 'SUBSCRIPTIONS_FORM'

interface SubscriptionsFormProps {
  t: TFunction
  closeDialog: () => void
}

// {t('subscription.dialog.content')}
const SubscriptionsForm = ({
  t,
  closeDialog,
  handleSubmit
}: SubscriptionsFormProps & InjectedFormProps<SubscriptionsFormData, SubscriptionsFormProps>) => {
  const dispatch = useDispatch()
  const subscribeUserToNewsLetter = (formData: SubscriptionsFormData) =>
    bindSubmitActionToPromise(
      dispatch,
      subscribeUserToNewsLetterAction
    )(formData).finally(closeDialog)

  return (
    <form onSubmit={handleSubmit(subscribeUserToNewsLetter)}>
      <DialogContent>
        <Grid container>
          <Grid item xs={10}>
            <Field
              name='subscriptions.newsLetter'
              component={ReduxCheckbox}
              label={t('subscriptions.dialog.message')}
            />
          </Grid>
          <Grid container item xs justifyContent='center' alignItems='center'>
            <EmailOpenIcon />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color='inherit'>
          {t('subscriptions.dialog.cancelButton')}
        </Button>
        <Button type='submit' color='primary'>
          {t('general.save')}
        </Button>
      </DialogActions>
    </form>
  )
}

export default reduxForm<SubscriptionsFormData, SubscriptionsFormProps>({
  form: SUBSCRIPTIONS_FORM_NAME
})(SubscriptionsForm)
