import React, { Fragment, useState } from 'react'
import {
  ImageVariants,
  EnvironmentUI,
  isParentEnvironmentUI,
  isSubEnvironmentUI
} from '@seesignage/seesignage-utils'
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemIcon,
  Collapse,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  ListItemButton
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import QualityHighIcon from 'mdi-react/QualityHighIcon'
import UhdIcon from 'mdi-react/Video4kBoxIcon'
import FileVideoIcon from 'mdi-react/FileVideoIcon'
import DatabaseIcon from 'mdi-react/DatabaseIcon'
import DomainIcon from 'mdi-react/DomainIcon'
import AccountBoxIcon from 'mdi-react/AccountBoxIcon'
import FamilyTreeIcon from 'mdi-react/FamilyTreeIcon'
import ChevronUpIcon from 'mdi-react/ChevronUpIcon'
import ChevronDownIcon from 'mdi-react/ChevronDownIcon'
import LinkPlusIcon from 'mdi-react/LinkPlusIcon'
import ImageIcon from 'mdi-react/ImageIcon'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import LinkVariantRemoveIcon from 'mdi-react/LinkVariantRemoveIcon'
import CloudIcon from 'mdi-react/CloudIcon'
import MonitorIcon from 'mdi-react/MonitorIcon'
import MessageBulletedIcon from 'mdi-react/MessageBulletedIcon'
import AddSubEnvironmentsForm from '../Forms/AddSubEnvironmentsForm'
import { OpenDialog, CloseDialog } from '../../../types/actions'
import { selectEnvironmentById } from '../../../selectors/environments'
import { removeAsSubEnvironment as removeAsSubEnvironmentAction } from '../../../actions/environments'
import Dialog from '../../Dialog'
import { selectCustomersAsArray } from '../../../selectors/customers'
import { selectBillableScreensCount } from '../../../selectors/screens'
import { navigateToEnvironment as navigateToEnvironmentAction } from '../../../actions/routes'

const getImageVariantsText = (imageVariants: ImageVariants) => {
  const variants: string[] = []
  for (const [key, val] of Object.entries(imageVariants)) {
    if (val) {
      variants.push(key)
    }
  }
  return variants.join(', ')
}

interface EnvironmentDetailsProps {
  /** is current user a system admin */
  isSystemAdmin: boolean
  environment: EnvironmentUI
  openDialog: OpenDialog
  closeDialog: CloseDialog
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  videoIconsContainer: {
    display: 'flex'
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}))

const EnvironmentDetails: React.FC<EnvironmentDetailsProps> = ({
  isSystemAdmin,
  environment,
  openDialog,
  closeDialog
}) => {
  const classes = useStyles()
  const [t] = useTranslation()

  const dispatch = useDispatch()
  const removeAsSubEnvironment = (environmentId: string) =>
    dispatch(removeAsSubEnvironmentAction(environmentId))
  const navigateToEnvironment = ({ environmentId }: { environmentId: string }) =>
    dispatch(navigateToEnvironmentAction({ environmentId }))
  const customers = useSelector(selectCustomersAsArray)
  const billableScreens = useSelector(selectBillableScreensCount)
  const parentEnvironment = useSelector(
    selectEnvironmentById(environment.parentEnvironment || undefined)
  )
  const parentEnvironmentName = parentEnvironment?.name

  const [subEnvironmentsOpen, setSubEnvironmentsOpen] = useState(
    isParentEnvironmentUI(environment) && Object.keys(environment.subEnvironments).length > 5
      ? false
      : true // Tree open if under 5 sub environments
  )
  const [customersOpen, setCustomersOpen] = useState(false)
  const [notesOpen, setNotesOpen] = useState(false)

  const { features, name, displayName } = environment
  return (
    <div>
      <List dense={true}>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <DomainIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={t('general.name')} secondary={name} />
        </ListItem>
        {displayName && (
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <AccountBoxIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={t('environment.displayName')}
              secondary={environment.displayName}
            />
          </ListItem>
        )}
        {(features?.hqEnabled || features?.uhdEnabled) && (
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <FileVideoIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={t('environment.videoUploads')}
              secondary={
                <span className={classes.videoIconsContainer}>
                  {features?.hqEnabled && <QualityHighIcon color='red' />}
                  {features?.uhdEnabled && <UhdIcon color='blue' />}
                </span>
              }
            />
          </ListItem>
        )}
        {features?.imageVariants && (
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <ImageIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={t('environment.imageVariants')}
              secondary={getImageVariantsText(features.imageVariants)}
            />
          </ListItem>
        )}
        {typeof features?.storageSize === 'number' && (
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <DatabaseIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={t('environment.settingsForm.storageSize')}
              secondary={`${features.storageSize} GB`}
            />
          </ListItem>
        )}
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <MonitorIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={t('navigation.screens')}
            secondary={t('screens.billableCount', { count: billableScreens })}
          />
        </ListItem>
        {isSystemAdmin && (
          <Fragment>
            {environment.notes && (
              <Fragment>
                <ListItem disableGutters>
                  <ListItemButton onClick={() => setNotesOpen(!notesOpen)}>
                    <ListItemAvatar>
                      <Avatar>
                        <MessageBulletedIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={t('environment.notesSection')} />
                    {notesOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                  </ListItemButton>
                </ListItem>
                <Collapse in={notesOpen} timeout='auto' unmountOnExit>
                  <List component='div' disablePadding>
                    <ListItem className={classes.nested}>
                      <TextField
                        variant='outlined'
                        defaultValue={environment.notes}
                        InputProps={{
                          readOnly: true
                        }}
                        multiline
                        minRows={2}
                        maxRows={4}
                        fullWidth
                        label={t('environment.systemAdminNotes')}
                      />
                    </ListItem>
                  </List>
                </Collapse>
              </Fragment>
            )}
            {customers.length > 0 && (
              <Fragment>
                <ListItem disableGutters>
                  <ListItemButton onClick={() => setCustomersOpen(!customersOpen)}>
                    <ListItemAvatar>
                      <Avatar>
                        <CloudIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={t('navigation.integrations')} />
                    {customersOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                  </ListItemButton>
                </ListItem>
                <Collapse in={customersOpen} timeout='auto' unmountOnExit>
                  <List component='div' disablePadding>
                    {customers.map(({ name, customerId, productForm }) => (
                      <ListItem key={customerId} className={classes.nested}>
                        <ListItemIcon>
                          <CloudIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={name}
                          secondary={
                            productForm ? `${t('customers.productForm')} ${productForm}` : undefined
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </Fragment>
            )}
            {isSubEnvironmentUI(environment) && (
              <ListItem
                disableGutters
                secondaryAction={
                  <Dialog
                    dialogId='RemoveAsSubEnvironmentDialog'
                    title={t('environment.unlinkSub')}
                    tooltipTitle={t('environment.unlinkSub')}
                    ButtonIcon={LinkVariantRemoveIcon}
                    Content={
                      <Fragment>
                        <DialogContent>
                          <DialogContentText>
                            {t('environment.unlinkConfirmation')}
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={() => closeDialog('RemoveAsSubEnvironmentDialog')}
                            color='primary'>
                            {t('general.disagree')}
                          </Button>
                          <Button
                            onClick={() => removeAsSubEnvironment(environment.environmentId)}
                            color='primary'
                            autoFocus>
                            {t('general.agree')}
                          </Button>
                        </DialogActions>
                      </Fragment>
                    }
                  />
                }>
                <ListItemButton
                  onClick={() =>
                    navigateToEnvironment({ environmentId: environment.parentEnvironment })
                  }>
                  <ListItemIcon>
                    <DomainIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={t('environment.parentEnvironment')}
                    secondary={parentEnvironmentName}
                  />
                </ListItemButton>
              </ListItem>
            )}
            {!isSubEnvironmentUI(environment) && (
              <Fragment>
                <Dialog
                  dialogId='LinkSubEnvironmentsDialog'
                  title={t('environment.linkSub')}
                  tooltipTitle={t('environment.linkSub')}
                  noOpenDialogButton
                  Content={
                    <AddSubEnvironmentsForm
                      closeDialog={() => closeDialog('LinkSubEnvironmentsDialog')}
                      initialValues={{
                        environmentId: environment.environmentId,
                        selectedSubEnvironments: isParentEnvironmentUI(environment)
                          ? Object.values(environment.subEnvironmentsValues).map(
                              ({ name, displayName, environmentId }) => ({
                                name,
                                displayName,
                                environmentId
                              })
                            )
                          : undefined
                      }}
                    />
                  }
                />
                <ListItem disableGutters>
                  <ListItemButton onClick={() => setSubEnvironmentsOpen(!subEnvironmentsOpen)}>
                    <ListItemAvatar>
                      <Avatar>
                        <FamilyTreeIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={t('environment.subEnvironments_variant')} />
                    {subEnvironmentsOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                  </ListItemButton>
                </ListItem>
                <Collapse in={subEnvironmentsOpen} timeout='auto' unmountOnExit>
                  <List component='div' disablePadding>
                    <ListItem disableGutters disablePadding className={classes.nested}>
                      <ListItemButton onClick={() => openDialog('LinkSubEnvironmentsDialog')}>
                        <ListItemIcon>
                          <LinkPlusIcon />
                        </ListItemIcon>
                        <ListItemText
                          color='green'
                          primary={t('environment.linkSub')}
                          secondary={t('environment.linkNewSub')}
                        />
                      </ListItemButton>
                    </ListItem>
                    {isParentEnvironmentUI(environment) &&
                      Object.values(environment.subEnvironmentsValues).map(
                        ({ name, environmentId, displayName }) => (
                          <ListItem
                            disableGutters
                            key={environmentId}
                            disablePadding
                            className={classes.nested}>
                            <ListItemButton
                              onClick={() => navigateToEnvironment({ environmentId })}>
                              <ListItemIcon>
                                <DomainIcon />
                              </ListItemIcon>
                              <ListItemText primary={name} secondary={displayName} />
                            </ListItemButton>
                          </ListItem>
                        )
                      )}
                  </List>
                </Collapse>
              </Fragment>
            )}
          </Fragment>
        )}
      </List>
    </div>
  )
}

export default EnvironmentDetails
