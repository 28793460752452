import React from 'react'
import { Theme, InputBaseProps, InputBase } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import MagnifyIcon from 'mdi-react/MagnifyIcon'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      border: `1px solid rgba(0, 0, 0, 0.23)`,
      marginLeft: theme.spacing(1),
      width: 'auto'
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      right: 4,
      color: 'grey'
    },
    inputRoot: {
      color: 'inherit',
      width: '100%'
    },
    inputInput: {
      padding: theme.spacing(1),
      // vertical padding + font size from searchIcon
      paddingRight: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      '&:focus': {
        width: '20ch',
        [theme.breakpoints.down('md')]: {
          width: '15ch'
        }
      }
    }
  })
)

const SearchField: React.FC<InputBaseProps> = props => {
  const classes = useStyles()
  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <MagnifyIcon />
      </div>
      <InputBase
        {...props}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput
        }}
        inputProps={{ 'aria-label': 'search' }}
      />
    </div>
  )
}

export default SearchField
