import React from 'react'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import LinearProgress from '@mui/material/LinearProgress'
import { useTranslation } from 'react-i18next'
import FieldContainer from '../../../components/FieldContainer'
import { required, password, passwordsMatch } from '../../../validation'
import ErrorMessage from '../../../components/Errors/ErrorMessage'
import { ReduxTextField } from '../../../components/FormInput/ReduxWrappers'
import { ForgotPasswordSubmitFormData } from '../../../types/users'

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginTop: theme.spacing(2)
  }
}))

type FormProps = ForgotPasswordSubmitFormData

interface OwnProps {
  forgotPassword: (formData: any) => void
}

type ForgotPasswordSubmitFormProps = OwnProps

const ForgotPasswordSubmitForm = ({
  handleSubmit,
  submitting,
  forgotPassword,
  error
}: ForgotPasswordSubmitFormProps & InjectedFormProps<FormProps, OwnProps>) => {
  const classes = useStyles()
  const [t] = useTranslation()
  return (
    <form onSubmit={handleSubmit(forgotPassword)}>
      <FieldContainer>
        <Field
          fullWidth
          variant='outlined'
          name='password'
          autoComplete='new-password'
          label={t('forgotPw.newPw')}
          helperText={t('changePw.helperText')}
          type='password'
          required
          validate={[required, password]}
          component={ReduxTextField}
        />
      </FieldContainer>
      <FieldContainer>
        <Field
          fullWidth
          variant='outlined'
          autoComplete='new-password'
          name='confirmPassword'
          label={t('forgotPw.confirmNewPw')}
          type='password'
          required
          validate={[required, password, passwordsMatch]}
          component={ReduxTextField}
        />
      </FieldContainer>
      {error && <ErrorMessage message={error} />}

      <Button
        fullWidth
        type='submit'
        color='primary'
        variant='contained'
        className={classes.button}
        disabled={submitting}>
        {t('general.submit')}
      </Button>
      {submitting && <LinearProgress />}
    </form>
  )
}

export default reduxForm<FormProps, OwnProps>({
  form: 'ForgotPasswordSubmitForm'
})(ForgotPasswordSubmitForm)
