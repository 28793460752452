import { Grid, MenuItem } from '@mui/material'
import React, { Fragment } from 'react'
import { Field, FieldArray, WrappedFieldArrayProps } from 'redux-form'
import { gridItemProps } from '../Fields/utils'
import { ReduxTextField } from '../../../../components/FormInput/ReduxWrappers'
import { MockProductType } from '../../../../types/mocks'
import { required } from '../../../../validation'
import FieldsExpansionPanel from '../../../../components/ExtensionPanels/FieldsExpansionPanel'

const productType = MockProductType.fish
const stringProperties = ['name', 'origin', 'latinName', 'catchDate', 'catchArea', 'catchType']
const catchTypes = ['hook', 'drag', 'trap', 'seine', 'blockade', 'trawl', 'net', 'fyke']

const renderItems = ({ fields, meta: { error, submitFailed } }: WrappedFieldArrayProps) => (
  <Fragment>
    {submitFailed && error && <span>{error}</span>}
    {fields.map((member: string, index: number) => (
      <FieldsExpansionPanel customLabel={`Product ${index + 1}`} key={`${index}-${member}`}>
        <Grid container spacing={2}>
          {stringProperties.map((name, i) => (
            <Grid key={`${i}-${name}`} {...gridItemProps}>
              <Field fullWidth name={`${member}.${name}`} label={name} component={ReduxTextField} />
            </Grid>
          ))}
          <Grid {...gridItemProps}>
            <Field
              label='catchType'
              name={`${member}.catchType`}
              component={ReduxTextField}
              required
              validate={[required]}
              fullWidth
              select>
              {catchTypes.map(catchType => (
                <MenuItem key={catchType} value={catchType}>
                  {catchType}
                </MenuItem>
              ))}
            </Field>
          </Grid>
        </Grid>
      </FieldsExpansionPanel>
    ))}
  </Fragment>
)

const FishFormFields = () => (
  <Grid container spacing={2} alignItems='center'>
    <FieldArray name={productType} component={renderItems} props={{}} />
  </Grid>
)

export default FishFormFields
