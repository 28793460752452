import React, { Fragment } from 'react'
import { MdiReactIconProps } from 'mdi-react'
import Chip, { ChipProps } from '@mui/material/Chip'
import { Tooltip } from '@mui/material'

const trucateLabel = (chars: string, truncateLimit: number) =>
  chars.length > truncateLimit ? `${chars.substring(0, truncateLimit)}...` : chars

interface TruncatedChipProps {
  tag: string
  Icon?: React.ComponentType<MdiReactIconProps>
  color?: ChipProps['color']
  /** Max amount of characters before truncate */
  truncateLimit: number
}

const TruncatedChip = ({ tag, Icon, color, truncateLimit }: TruncatedChipProps) => (
  <Chip
    style={{
      height: '18px',
      margin: 2,
      padding: '1px 0px 2px 0px'
    }}
    color={color}
    icon={Icon ? <Icon size='0.85rem' /> : undefined}
    key={tag}
    label={
      <Fragment>
        {tag.length > truncateLimit ? (
          <Tooltip disableInteractive title={tag}>
            <span>{trucateLabel(tag, truncateLimit)}</span>
          </Tooltip>
        ) : (
          `${tag}`
        )}
      </Fragment>
    }
  />
)

export default TruncatedChip
