import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  DialogTitle as MuiDialogTitle,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  DialogProps,
  ButtonProps,
  DialogActions
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'
import CloseIcon from 'mdi-react/CloseIcon'
import React, { Fragment, ComponentType, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { MdiReactIconProps } from 'mdi-react'
import { TFunction } from 'i18next'
import { closeDialog, openDialog } from '../../actions/dialogs'
import { selectDialogVisibility } from '../../selectors/dialogs'
import { selectIsUserDeviceMobile } from '../../selectors/users'
import { OpenDialog, CloseDialog } from '../../types/actions'
import { StateInterface } from '../../types/states'
import { DialogVisibility, OpenDialogButtonType } from '../../types/dialogs'
import { AddButton } from '../../components/Buttons'

const ImageButtonStyles = {
  maxHeight: 100,
  cursor: 'pointer',
  boxShadow: '1px 2px 3px rgba(0,0,0,.5)'
}

const EmptyImageButtonStyles = {
  borderStyle: 'dotted',
  cursor: 'pointer',
  height: 100,
  width: 100
}

interface OpenDialogImageButtonProps {
  dialogId: string
  imageSrc?: string
  openDialog: OpenDialog
}

const OpenDialogImageButton = ({ dialogId, imageSrc, openDialog }: OpenDialogImageButtonProps) => (
  <Fragment>
    {imageSrc ? (
      <img style={ImageButtonStyles} src={imageSrc} alt='' onClick={() => openDialog(dialogId)} />
    ) : (
      <div style={EmptyImageButtonStyles} onClick={() => openDialog(dialogId)} />
    )}
  </Fragment>
)

const OpenDialogButton = ({
  classes,
  buttonLabel,
  buttonClassName,
  color,
  dialogId,
  buttonFullWidth,
  ButtonIcon,
  ButtonIconProps,
  buttonDisabled,
  openDialog,
  tooltipTitle,
  buttonVariant,
  t,
  buttonId
}: CustomButtonProps) => (
  <Fragment>
    {buttonLabel ? (
      <Fragment>
        {tooltipTitle ? (
          <Tooltip disableInteractive title={t(tooltipTitle) as string}>
            <span>
              <Button
                id={buttonId}
                data-cy={buttonId}
                className={buttonClassName || undefined}
                disabled={buttonDisabled}
                variant={buttonVariant || 'contained'}
                color={color || 'grey'}
                fullWidth={buttonFullWidth || false}
                onClick={() => openDialog(dialogId)}>
                {ButtonIcon && <ButtonIcon className={classes.buttonIcon} />}
                {buttonLabel}
              </Button>
            </span>
          </Tooltip>
        ) : (
          <Button
            id={buttonId}
            data-cy={buttonId}
            className={buttonClassName || undefined}
            disabled={buttonDisabled}
            variant={buttonVariant || 'contained'}
            color={color}
            fullWidth={buttonFullWidth || false}
            onClick={() => openDialog(dialogId)}
            startIcon={ButtonIcon && <ButtonIcon className={classes.buttonIcon} />}>
            {buttonLabel}
          </Button>
        )}
      </Fragment>
    ) : (
      <Fragment>
        {tooltipTitle ? (
          <Tooltip disableInteractive title={t(tooltipTitle) as string}>
            <div>
              <IconButton
                id={buttonId}
                disabled={buttonDisabled}
                onClick={() => openDialog(dialogId)}
                aria-label='dialogButtonIcon'
                size='large'>
                {ButtonIcon && <ButtonIcon {...ButtonIconProps} />}
              </IconButton>
            </div>
          </Tooltip>
        ) : (
          <IconButton
            data-cy={buttonId}
            id={buttonId}
            disabled={buttonDisabled}
            onClick={() => openDialog(dialogId)}
            aria-label='dialogButtonIcon'
            size='large'>
            {ButtonIcon && <ButtonIcon {...ButtonIconProps} />}
          </IconButton>
        )}
      </Fragment>
    )}
  </Fragment>
)

interface OpenDialogMenuItemButtonProps {
  openDialog: OpenDialog
  dialogId: string
  buttonLabel?: string
  ButtonIcon: React.ComponentType<MdiReactIconProps>
  buttonClassName?: string
}

const OpenDialogMenuItemButton = ({
  buttonLabel,
  buttonClassName,
  dialogId,
  ButtonIcon,
  openDialog
}: OpenDialogMenuItemButtonProps) => (
  <MenuItem onClick={() => openDialog(dialogId)} className={buttonClassName}>
    <ListItemIcon>
      <ButtonIcon />
    </ListItemIcon>
    <ListItemText primary={buttonLabel} />
  </MenuItem>
)

const renderFullScreenAppBar = (dialogId: string, closeDialog: CloseDialog, title: string) => (
  <AppBar style={{ position: 'relative' }}>
    <Toolbar>
      <IconButton color='inherit' onClick={() => closeDialog(dialogId)} size='large'>
        <CloseIcon color='#fafafa' />
      </IconButton>
      <Typography color='inherit' variant='h6'>
        {title}
      </Typography>
    </Toolbar>
  </AppBar>
)

interface CustomButtonProps {
  classes: Record<string, string>
  buttonLabel?: string
  buttonClassName?: string
  color?: ButtonProps['color']
  dialogId: string
  buttonFullWidth?: boolean
  ButtonIcon?: ComponentType<MdiReactIconProps>
  ButtonIconProps?: any
  buttonDisabled?: boolean
  buttonVariant?: 'text' | 'outlined' | 'contained'
  openDialog: OpenDialog
  tooltipTitle?: string
  t: TFunction
  buttonId?: string
}

interface RenderButtonProps {
  openDialogButtonType?: OpenDialogButtonType
  imageButtonProps: any
  menuItemButtonProps: any
  buttonProps: CustomButtonProps
}

const renderButton = ({
  openDialogButtonType,
  imageButtonProps,
  menuItemButtonProps,
  buttonProps
}: RenderButtonProps) => {
  switch (openDialogButtonType) {
    case OpenDialogButtonType.image:
      return <OpenDialogImageButton {...imageButtonProps} />
    case OpenDialogButtonType.menuItem:
      return <OpenDialogMenuItemButton {...menuItemButtonProps} />
    case OpenDialogButtonType.add:
      const { dialogId, openDialog, tooltipTitle, buttonId } = buttonProps
      return (
        <AddButton
          onClick={() => openDialog(dialogId)}
          tooltipTitle={tooltipTitle || ''}
          id={buttonId}
        />
      )
    default:
      return <OpenDialogButton {...buttonProps} />
  }
}

export interface DialogTitleProps {
  id: string
  classes: Record<string, string>
  children: React.ReactNode
  onClose: () => void
}

const DialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, classes, ...other } = props
  return (
    <MuiDialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
          size='large'>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
}

const StyledDialogActions = styled(DialogActions)({
  padding: 0
})

const styles = {
  buttonIcon: { marginRight: 6 },
  closeButton: {
    position: 'absolute' as any,
    right: 5,
    top: 5,
    color: 'grey' as any
  }
}

const useStyles = makeStyles(styles)

interface OwnProps {
  dialogId: string
  title: string
  buttonLabel?: string
  color?: ButtonProps['color']
  Actions?: ReactNode
  Content: any
  ButtonIcon?: ComponentType<MdiReactIconProps>
  ButtonIconProps?: any
  /** is dialog and button disabled */
  isDisabled?: boolean
  /** tooltip for open dialog button when it is disabled */
  isDisabledTooltip?: string
  buttonVariant?: 'text' | 'outlined' | 'contained'
  buttonDisabled?: boolean
  maxWidth?: DialogProps['maxWidth']
  buttonClassName?: string
  tooltipTitle?: string
  buttonFullWidth?: boolean
  /** Type of OpenDialog button */
  openDialogButtonType?: OpenDialogButtonType
  imageSrc?: string
  noOpenDialogButton?: boolean
  key?: string
  buttonId?: string
  fullScreen?: boolean
  disableEnforceFocus?: boolean
  /** Control dialog visibility from component state */
  openFromParentState?: boolean
  closeFromParentState?: () => void
  /** custom on close callback function */
  onClose?: () => void
}

interface StateProps {
  dialogVisibility: DialogVisibility
  isMobile: boolean
}

interface DispatchProps {
  openDialog: OpenDialog
  closeDialog: CloseDialog
}

type DialogContainerProps = OwnProps & StateProps & DispatchProps

const DialogContainer: React.FC<DialogContainerProps> = ({
  openDialog,
  closeDialog,
  dialogVisibility,
  dialogId,
  title,
  buttonLabel,
  Actions,
  Content,
  ButtonIcon,
  ButtonIconProps,
  buttonVariant,
  buttonDisabled,
  color,
  buttonClassName,
  buttonFullWidth,
  openDialogButtonType,
  tooltipTitle,
  maxWidth,
  imageSrc,
  isMobile,
  noOpenDialogButton,
  buttonId,
  fullScreen,
  disableEnforceFocus,
  openFromParentState,
  onClose
}) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const isDialogVisible =
    openFromParentState || (dialogVisibility.id === dialogId && dialogVisibility.isVisible)

  const imageButtonProps = {
    dialogId,
    openDialog,
    imageSrc
  }
  const menuItemButtonProps = {
    buttonLabel,
    buttonClassName,
    dialogId,
    ButtonIcon,
    buttonDisabled,
    openDialog
  }
  const buttonProps: CustomButtonProps = {
    classes,
    buttonLabel,
    buttonClassName,
    color,
    dialogId,
    buttonFullWidth,
    ButtonIcon,
    ButtonIconProps,
    buttonDisabled,
    buttonVariant,
    openDialog,
    tooltipTitle,
    t,
    buttonId
  }

  const onCloseDialogHandler = () => {
    if (typeof onClose === 'function') {
      onClose()
    }
    closeDialog(dialogId)
  }

  return (
    <div>
      {noOpenDialogButton ? (
        <Fragment />
      ) : (
        renderButton({
          openDialogButtonType,
          imageButtonProps,
          menuItemButtonProps,
          buttonProps
        })
      )}
      {isDialogVisible && (
        <Dialog
          disableEnforceFocus={disableEnforceFocus}
          fullWidth
          fullScreen={fullScreen || isMobile}
          maxWidth={maxWidth}
          open={isDialogVisible}
          onClose={onCloseDialogHandler}
          aria-labelledby='dialog-title'>
          {isMobile ? (
            renderFullScreenAppBar(dialogId, closeDialog, title)
          ) : (
            <DialogTitle id='dialog-title' onClose={onCloseDialogHandler} classes={classes}>
              {t(title)}
            </DialogTitle>
          )}
          <DialogContent>{Content}</DialogContent>
          {Actions && <StyledDialogActions>{Actions}</StyledDialogActions>}
        </Dialog>
      )}
    </div>
  )
}

const mapStateToProps = (state: StateInterface): StateProps => ({
  dialogVisibility: selectDialogVisibility(state),
  isMobile: selectIsUserDeviceMobile(state)
})

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps): DispatchProps => ({
  closeDialog: (dialogId: string) => {
    const { openFromParentState, closeFromParentState } = ownProps
    if (openFromParentState && closeFromParentState) {
      closeFromParentState()
    } else {
      dispatch(closeDialog(dialogId))
    }
  },
  openDialog: (dialogId: string) => dispatch(openDialog(dialogId))
})

export default connect<StateProps, DispatchProps, OwnProps, StateInterface>(
  mapStateToProps,
  mapDispatchToProps
)(DialogContainer)
