import {
  AutocompleteOption,
  PlaylistUI,
  PlaylistItemWeatherCondition,
  PlaylistItemTagConditions,
  RevolverItemUI,
  PlaylistItemType,
  PharmacyCustomerProduct,
  RangeConditionDays
} from '@seesignage/seesignage-utils'
import { TemplateAsFormValue } from './templates'
import { SelectedInfopageType } from './infopages'

export interface PlaylistsById {
  [playlistId: string]: PlaylistUI
}

/** Types of pages in AddPlaylistItemWizard */
export enum PageType {
  selectType = 'selectType',
  media = 'media',
  existingInfopage = 'existingInfopage',
  /** Enter basic details for new infopage */
  newInfopage = 'newInfopage',
  /** After creating new empty infopage or content to be edited */
  createInfopageItem = 'createInfopageItem',
  revolver = 'revolver',
  productType = 'productType',
  existingProduct = 'existingProduct',
  createProduct = 'createProduct',
  url = 'url',
  list = 'list',
  selectContent = 'selectContent'
}

export interface CreatePlaylistFormData {
  name: string
  defaultInterval: number
  permissionTags: AutocompleteOption[]
  syncPlay?: boolean
}

export interface UpdatePlaylistFormData extends CreatePlaylistFormData {
  playlistId: string
}

export interface AddProductFormData {
  type: PlaylistItemType.product
  customerId: AutocompleteOption
  product: AutocompleteOption | PharmacyCustomerProduct
  template: TemplateAsFormValue
}

export interface AddListFormData {
  type: PlaylistItemType.list
  list: AutocompleteOption
}

export interface AddUrlFormData {
  type: PlaylistItemType.url
  url: string
  interval: number
}

export interface AddExistingMediaAsItemFormData {
  type: PageType.media
  existingFiles?: string[]
  interval: number
}

export interface InfopageInputValue {
  id: string
  type: SelectedInfopageType
}

export interface AddInfopageAsitemFormData {
  type: PlaylistItemType.infopage
  infopage: InfopageInputValue
}

export interface AddContentAsitemFormData {
  type: PlaylistItemType.content
  contentId: string
}

export type AddPlaylistItemsFormData =
  | AddProductFormData
  | AddListFormData
  | AddUrlFormData
  | AddExistingMediaAsItemFormData
  | AddInfopageAsitemFormData
  | CreateAddRevolverItemFormData
  | AddContentAsitemFormData

interface ScheduleConditionsFormData {
  startTime: Date
  endTime: Date
  days?: RangeConditionDays
}
interface ScheduleRangeFormData {
  start: Date
  end: Date
  conditions: ScheduleConditionsFormData[]
}

export type SchedulePlaylistItemFormData = {
  ranges: ScheduleRangeFormData[]
}
export interface IntervalPlaylistItemFormData {
  interval: number | ''
}

export interface UpdateProductItemTemplateFormData {
  template: TemplateAsFormValue
}

export type AddPlaylistItemConditionFormData =
  | AddPlaylistItemWeatherConditionFormData
  | AddPlaylistItemTagsConditionFormData

export enum PlaylistItemConditionType {
  weather = 'weather',
  tags = 'tags'
}

export type AddPlaylistItemWeatherConditionFormData = {
  weather?: PlaylistItemWeatherCondition
  /** condition type so we know which condition to update */
  type: PlaylistItemConditionType.weather
}

export type AddPlaylistItemTagsConditionFormData = {
  tags: PlaylistItemTagConditions[]
  /** condition type so we know which condition to update */
  type: PlaylistItemConditionType.tags
}

export const isWeatherConditionFormData = (
  item: any
): item is AddPlaylistItemWeatherConditionFormData =>
  item?.type === PlaylistItemConditionType.weather

export const isTagsConditionFormData = (item: any): item is AddPlaylistItemTagsConditionFormData =>
  item?.type === PlaylistItemConditionType.tags

export interface CreateAddRevolverItemFormData {
  type: PlaylistItemType.revolver
  name: string
  revolverItems: RevolverItemUI[]
}

export interface UpdateAddRevolverItemFormData {
  itemId: string
  name: string
  revolverItems: RevolverItemUI[]
}

export enum PlaylistFilterTypes {
  all = 'all',
  linkedToScreens = 'linkedToScreens'
}

export type PlaylistFilter = PlaylistFilterTypes.all | PlaylistFilterTypes.linkedToScreens

export interface PlaylistItemCardRootProps {
  isItemSelected: boolean
  itemPercentage: string
  showPercentage: boolean
}

export interface PlaylistItemDuration {
  duration: number
  percentageText: string
  isCustomInterval: boolean
  range?: {
    min: number
    max: number
  }
}

export interface PlaylistWithUnsavedChanges {
  name: string
  playlistId: string
}

export interface AllPlaylists {
  playlists: PlaylistsById
  parentPlaylists: PlaylistsById
}
