import {
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  FormControlLabel,
  Radio,
  Switch
} from '@mui/material'
import React, { Fragment, useState } from 'react'
import { TFunction } from 'i18next'
import { Field, reduxForm, InjectedFormProps, formValueSelector } from 'redux-form'
import {
  ComponentType,
  GeneralRetailProductProperty,
  PricerProductProperty,
  TemplateType,
  PharmacyProductProperty,
  AdditionalPlayerItemProperty
} from '@seesignage/seesignage-utils'
import { connect } from 'react-redux'
import ExpandIcon from 'mdi-react/ChevronDownIcon'
import HelpCircleOutlineIcon from 'mdi-react/HelpCircleOutlineIcon'
import DialogActions from '../../../components/DialogActions'
import ErrorMessage from '../../../components/Errors/ErrorMessage'
import { CloseDialog, CreateChannel } from '../../../types/actions'
import {
  ReduxTextField,
  ReduxSwitch,
  ReduxCheckbox,
  ReduxRadioGroup
} from '../../../components/FormInput/ReduxWrappers'
import { StateInterface } from '../../../types/states'
import { validateTemplateCustomValue, validateTemplateCustomValueExist } from '../../../validation'
import ColorPicker from '../../../components/FormInput/ColorPicker'
import { EditSchemaComponentFormParams } from '../../../types/templates'
import EditSchemaComponentConditions from './Fields/EditSchemaComponent/EditSchemaComponentConditions'
import EditSchemaComponentFormatOptions from './Fields/EditSchemaComponentFormatOptions'

interface AllowFieldsProps {
  type: ComponentType
  t: TFunction
  allowTextValue?: boolean
  allowWysiwygValue?: boolean
}

const AllowFields = ({ type, t, allowTextValue, allowWysiwygValue }: AllowFieldsProps) => {
  switch (type) {
    case ComponentType.div:
      return (
        <Grid item xs={12} sm={12}>
          <Field
            name='allow.background'
            label={t('templates.editSchemaComponentForm.AllowBackground')}
            component={ReduxSwitch}
          />
        </Grid>
      )
    case ComponentType.image:
      return (
        <Grid item xs={12} sm={12}>
          <Field
            name='allow.image'
            label={t('templates.editSchemaComponentForm.allowImage')}
            component={ReduxSwitch}
          />
        </Grid>
      )
    case ComponentType.video:
      return (
        <Grid item xs={12} sm={12}>
          <Field
            name='allow.video'
            label={t('templates.editSchemaComponentForm.allowVideo')}
            component={ReduxSwitch}
          />
        </Grid>
      )
    case ComponentType.text:
      return (
        <Fragment>
          <Grid item xs={12} sm={12}>
            <Field
              name='allow.text'
              label={t('templates.editSchemaComponentForm.allowText')}
              component={ReduxSwitch}
            />
          </Grid>
          {allowTextValue && (
            <Grid item xs={12} sm={12}>
              <Field
                name='allow.wysiwyg'
                label={t('templates.editSchemaComponentForm.allowWysiwyg')}
                component={ReduxCheckbox}
              />
            </Grid>
          )}
          {allowWysiwygValue && (
            <Grid item xs={12} sm={12}>
              <Typography variant='caption'>
                {t('templates.editSchemaComponentForm.editorBackgroundColor')}
              </Typography>
              <Field name='editorBackgroundColor' component={ColorPicker} />
            </Grid>
          )}
        </Fragment>
      )
    default:
      return <Fragment />
  }
}

interface OwnProps {
  submitButtonLabel: string
  onSubmit: CreateChannel
  closeDialog: CloseDialog
  dialogId: string
  type: ComponentType
  templateType?: TemplateType
  componentType: ComponentType
  parentComponentType?: ComponentType
  t: TFunction
}

interface StateProps {
  allowTextValue?: boolean
  allowWysiwygValue?: boolean
}

const EditSchemaComponentForm: React.FC<OwnProps &
  StateProps &
  InjectedFormProps<EditSchemaComponentFormParams, OwnProps>> = ({
  handleSubmit,
  onSubmit,
  submitting,
  closeDialog,
  dialogId,
  error,
  type,
  allowTextValue,
  allowWysiwygValue,
  templateType,
  componentType,
  parentComponentType,
  t
}) => {
  const [validateCustomValueProps, setValidateCustomValueProps] = useState(true)
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Field fullWidth name='id' label={t('id')} component={ReduxTextField} />
        </Grid>
        <Grid item sm={12}>
          <Field fullWidth name='name' label={t('general.name')} component={ReduxTextField} />
        </Grid>
        {(type === ComponentType.text || type === ComponentType.qrCode) &&
          templateType === TemplateType.retail && (
            <Fragment>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={validateCustomValueProps}
                      onChange={() => setValidateCustomValueProps(!validateCustomValueProps)}
                    />
                  }
                  label='Validate custom value properties'
                />
              </Grid>
              <Grid item sm={12}>
                <Field
                  fullWidth
                  name='customValue'
                  label={'Custom value'}
                  component={ReduxTextField}
                  validate={[
                    validateCustomValueProps
                      ? validateTemplateCustomValue
                      : validateTemplateCustomValueExist
                  ]}
                />
                <Typography variant='caption'>{`e.g '%price% / %unit%'`}</Typography>
              </Grid>
              {type === ComponentType.text && (
                <Fragment>
                  <Grid item xs={12}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandIcon />}>
                        <HelpCircleOutlineIcon />
                        <Typography variant='body1'>Product properties</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div>
                          <Typography variant='h6'>Normal retail products</Typography>
                          <Typography variant='caption'>
                            {`[${Object.values(GeneralRetailProductProperty).join(', ')}]`}
                          </Typography>
                          <Typography variant='h6'>Pricer plaza products (Verkkokauppa)</Typography>
                          <Typography variant='caption'>
                            {`[${Object.values(PricerProductProperty).join(', ')}]`}
                          </Typography>
                          <Typography variant='h6'>Pharmacy products</Typography>
                          <Typography variant='caption'>
                            {`[${Object.values(PharmacyProductProperty).join(', ')}]`}
                          </Typography>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  <Grid item xs={12}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandIcon />}>
                        <HelpCircleOutlineIcon />
                        <Typography variant='body1'>Additional properties</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div>
                          <Typography variant='h6'>
                            Properties e.g from List or CampaignBatch root
                          </Typography>
                          <Typography variant='caption'>
                            {`[${Object.values(AdditionalPlayerItemProperty).join(', ')}]`}
                          </Typography>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  <Grid item xs={12}>
                    <EditSchemaComponentFormatOptions />
                  </Grid>
                </Fragment>
              )}
            </Fragment>
          )}
        {templateType === TemplateType.retail && componentType !== ComponentType.productData && (
          <Grid item sm={12}>
            <EditSchemaComponentConditions parentComponentType={parentComponentType} />
          </Grid>
        )}
        {templateType === TemplateType.info && (
          <AllowFields
            t={t}
            type={type}
            allowWysiwygValue={allowWysiwygValue}
            allowTextValue={allowTextValue}
          />
        )}
        {type === ComponentType.lunchItemSpecialDiet && (
          <Grid item xs={12} sm={12}>
            <Typography variant='body1'>Special Diet type</Typography>
            <Field
              name='specialDietType'
              component={ReduxRadioGroup}
              style={{ display: 'flex', flexDirection: 'row' }}>
              <FormControlLabel value={ComponentType.text} label='text' control={<Radio />} />
              <FormControlLabel value={ComponentType.image} label='image' control={<Radio />} />
            </Field>
          </Grid>
        )}
        {type === ComponentType.lunchGroupPrice && (
          <Grid item xs={12}>
            <Typography variant='body1'>Section index</Typography>
            <Field name='sectionIndex' component={ReduxTextField} type='number' />
          </Grid>
        )}
      </Grid>
      <ErrorMessage message={error} />
      <DialogActions
        submitButtonLabel={t('general.save')}
        submitting={submitting}
        closeDialog={closeDialog}
        dialogId={dialogId}
      />
    </form>
  )
}

const mapStateToProps = (state: StateInterface): StateProps => ({
  allowTextValue: formValueSelector('EditSchemaComponentForm')(state, 'allow.text'),
  allowWysiwygValue: formValueSelector('EditSchemaComponentForm')(state, 'allow.wysiwyg')
})

export default connect<StateProps, {}, OwnProps, StateInterface>(mapStateToProps)(
  reduxForm<EditSchemaComponentFormParams, OwnProps & StateProps>({
    form: 'EditSchemaComponentForm'
  })(EditSchemaComponentForm)
)
