import React from 'react'
import { Tooltip } from '@mui/material'
import EyeOffIcon from 'mdi-react/EyeOffIcon'
import { TFunction } from 'i18next'
import MonitorIcon from '../../../components/Screens/MonitorIcon'

interface ConnectionProps {
  t: TFunction
  classes: Record<string, string>
  rotation: number
  isTest?: boolean
}

const SettingsIcons = ({ classes, rotation, isTest, t }: ConnectionProps) => (
  <div className={classes.inlineBlockStyle}>
    <MonitorIcon t={t} rotation={rotation} />
    {isTest && (
      <Tooltip disableInteractive title={t('screens.isTest')}>
        <span>
          <EyeOffIcon className={classes.inlineBlockStyle} />
        </span>
      </Tooltip>
    )}
  </div>
)

export default SettingsIcons
