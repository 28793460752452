import { API } from 'aws-amplify'
import {
  CreatePlayerSession,
  User,
  UserSocialMedia,
  UpdateUserRequestParams,
  SocialMediaPlatform,
  UserSocialMediaAccountType
} from '@seesignage/seesignage-utils'

const getConfirmations = async (environmentId: string) => {
  const confirmations = await API.get('users', `/environments/${environmentId}/confirmations`, {})
  return confirmations
}

const deleteConfirmation = async (environmentId: string, confirmationId: string) => {
  await API.del('users', `/environments/${environmentId}/confirmations/${confirmationId}`, {})
}

const deleteUser = async () => {
  await API.del('users', '/users', {})
}

const init = async () => {
  await API.get('users', '/init', {})
}

const createPreviewSession = async (session: CreatePlayerSession) => {
  const { sessionId } = await API.post('users', '/player', {
    body: session
  })
  return sessionId
}

const getUser = async () => {
  const user: User = await API.get('users', '/user', {})
  return user
}

const updateUser = async <T extends UpdateUserRequestParams>(data: T) => {
  const res: T = await API.put('users', `/user`, {
    body: data
  })
  return res
}

const getSubscribedUsers = async () => {
  const res = await API.get('users', `/subscribed-to-news`, {})
  return res
}
const addSocialMediaAccount = async (
  newSocialMediaAccount: UserSocialMediaAccountType
): Promise<UserSocialMedia> => {
  const { platform } = newSocialMediaAccount
  switch (platform) {
    case SocialMediaPlatform.facebook:
    case SocialMediaPlatform.instagram:
      const { socialMedia } = await API.post('users', '/social-media', {
        body: {
          newSocialMediaAccount
        }
      })

      return socialMedia

    default:
      return { facebook: {}, instagram: {} }
  }
}

const deleteSocialMediaAccount = async (socialMediaAccount: UserSocialMediaAccountType) => {
  const { platform, id } = socialMediaAccount
  switch (platform) {
    case SocialMediaPlatform.facebook:
    case SocialMediaPlatform.instagram:
      const response = await API.del('users', '/social-media', {
        body: {
          platform,
          id
        }
      })
      return response

    default:
      return
  }
}

export default {
  init,
  deleteUser,
  getConfirmations,
  deleteConfirmation,
  createPreviewSession,
  getUser,
  getSubscribedUsers,
  updateUser,
  addSocialMediaAccount,
  deleteSocialMediaAccount
}
