import { Grid } from '@mui/material'
import React from 'react'
import { Field } from 'redux-form'
import FieldsExpansionPanel from '../../../../../components/ExtensionPanels/FieldsExpansionPanel'
import { generateFieldPrefix } from '../utils'
import { ReduxTextField } from '../../../../../components/FormInput/ReduxWrappers'

interface BorderFieldsProps {
  level: number
  childIndexes: number[]
}

const BorderFields: React.FC<BorderFieldsProps> = ({ level, childIndexes }) => (
  <FieldsExpansionPanel label='border'>
    <Grid container spacing={2} alignItems='center'>
      <Grid item xs={6}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'border')}
          label='Border'
          component={ReduxTextField}
        />
      </Grid>
      <br />
      <Grid item xs={3}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'borderTop')}
          label='Border top'
          component={ReduxTextField}
        />
      </Grid>
      <Grid item xs={3}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'borderRight')}
          label='Border right'
          component={ReduxTextField}
        />
      </Grid>
      <Grid item xs={3}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'borderBottom')}
          label='Border bottom'
          component={ReduxTextField}
        />
      </Grid>
      <Grid item xs={3}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'borderLeft')}
          label='Border left'
          component={ReduxTextField}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'borderRadius')}
          label='Border radius'
          component={ReduxTextField}
        />
      </Grid>
    </Grid>
  </FieldsExpansionPanel>
)

export default BorderFields
