import { MenuItem } from '@mui/material'
import React from 'react'
import { Field } from 'redux-form'
import { ReduxTextField } from '../FormInput/ReduxWrappers'

interface MenuFieldProps {
  name: string
  label: string
  items: (string | number)[]
  parse?: (val: any) => void
}

const MenuField: React.FC<MenuFieldProps> = ({ items, name, label, parse }) => (
  <Field parse={parse} fullWidth name={name} label={label} select component={ReduxTextField}>
    {items.map(value => (
      <MenuItem key={value} value={value}>
        {value === '' ? 'none' : value}
      </MenuItem>
    ))}
  </Field>
)

export default MenuField
