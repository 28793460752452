import React, { Fragment } from 'react'
import { TableCell, TableRow } from '@mui/material'

interface EmptyRowsProps {
  emptyRows: number
  colSpan: number
}

const EmptyRows: React.FC<EmptyRowsProps> = ({ emptyRows, colSpan }) => (
  <Fragment>
    {emptyRows > 0 && (
      <TableRow style={{ height: 49 * emptyRows }}>
        <TableCell colSpan={colSpan} />
      </TableRow>
    )}
  </Fragment>
)

export default EmptyRows
