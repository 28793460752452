import React, { Fragment } from 'react'
import PencilIcon from 'mdi-react/PencilIcon'
import FolderMoveIcon from 'mdi-react/FolderMoveIcon'
import ShareIcon from 'mdi-react/ShareIcon'
import DeleteIcon from 'mdi-react/DeleteIcon'
import DownloadIcon from 'mdi-react/DownloadIcon'
import {
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  LinearProgress,
  IconButton,
  Tooltip
} from '@mui/material'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Media } from '@seesignage/seesignage-utils'
import { connect } from 'react-redux'
import PushToPlaylistsForm from '../Forms/PushToPlaylistsForm'
import MoveToFolderForm from '../Forms/MoveToFolderForm'
import RenameMediaForm from '../Forms/RenameMediaForm'
import Dialog from '../../Dialog'
import { CloseDialog } from '../../../types/actions'
import { StateInterface } from '../../../types/states'
import { selectMediaDeleteSubmitting } from '../../../selectors/media'
import { getMediaNameAndExtension } from '../../../utils/media'

interface OwnProps {
  selectedKeys: string[]
  allSelectedMedia: Media[]
  downloadMediaFiles: (media: Media[]) => void
  closeDialog: CloseDialog
  deleteMedia: () => void
  mediaName?: string
}

interface StateProps {
  deleteMediaSubmitting?: boolean
}

type MediaAdminToolsProps = OwnProps & StateProps & WithTranslation

const MediaAdminTools: React.FC<MediaAdminToolsProps> = ({
  selectedKeys,
  allSelectedMedia,
  downloadMediaFiles,
  closeDialog,
  deleteMedia,
  t,
  mediaName,
  deleteMediaSubmitting
}) => {
  if (!selectedKeys.length || !mediaName) {
    return <Fragment />
  }

  const { name, extension } = getMediaNameAndExtension(mediaName)

  return (
    <Fragment>
      {selectedKeys.length === 1 && (
        <Dialog
          dialogId='RenameMediaDialog'
          title={t('media.rename')}
          tooltipTitle={t('media.rename')}
          ButtonIcon={PencilIcon}
          Content={
            <RenameMediaForm
              dialogId='RenameMediaDialog'
              initialValues={{
                name,
                fileType: extension,
                key: selectedKeys[0]
              }}
              useFormOnSubmit={true}
            />
          }
        />
      )}
      <Dialog
        dialogId='moveToFolderDialog'
        title={t('media.moveToFolder')}
        tooltipTitle={t('media.moveToFolder')}
        ButtonIcon={FolderMoveIcon}
        Content={
          <MoveToFolderForm
            dialogId='moveToFolderDialog'
            closeDialog={closeDialog}
            submitButtonLabel={t('general.move')}
          />
        }
      />
      <Dialog
        dialogId='PushMediaToPlaylistsDialog'
        title={`${t('media.pushToPlaylists')} (${t('media.fileCount', {
          count: selectedKeys.length
        })})`}
        tooltipTitle={t('media.pushToPlaylists')}
        maxWidth='md'
        ButtonIcon={ShareIcon}
        Content={
          <PushToPlaylistsForm
            dialogId='PushMediaToPlaylistsDialog'
            closeDialog={closeDialog}
            submitButtonLabel={t('general.ok')}
          />
        }
      />

      <Dialog
        dialogId='DeleteMediaDialog'
        title={t('media.deleteMedia', { count: selectedKeys.length, mediaName })}
        tooltipTitle='media.deleteMedia'
        ButtonIcon={DeleteIcon}
        Content={
          <Fragment>
            <DialogContent>
              {deleteMediaSubmitting && <LinearProgress />}
              <DialogContentText>
                {t('media.deleteMediaText', { count: selectedKeys.length, mediaName })}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => closeDialog('DeleteMediaDialog')} color='primary'>
                {t('general.disagree')}
              </Button>
              <Button
                onClick={() => deleteMedia()}
                color='primary'
                autoFocus
                disabled={deleteMediaSubmitting}>
                {t('general.agree')}
              </Button>
            </DialogActions>
          </Fragment>
        }
      />
      <Tooltip disableInteractive title={t('media.downloadSelected')}>
        <IconButton onClick={() => downloadMediaFiles(allSelectedMedia)} size='large'>
          <DownloadIcon />
        </IconButton>
      </Tooltip>
    </Fragment>
  )
}

const mapStateToProps = (state: StateInterface): StateProps => ({
  deleteMediaSubmitting: selectMediaDeleteSubmitting(state)
})

export default connect<StateProps, {}, OwnProps, StateInterface>(mapStateToProps)(
  withTranslation()(MediaAdminTools)
)
