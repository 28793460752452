import { handleActions } from 'redux-actions'
import {
  RetailListUI,
  MiscListUI,
  IoTLunchListContent,
  MiscListItemUI,
  RetailListItemUI,
  RetailProduct
} from '@seesignage/seesignage-utils'
import { LunchList } from '@seesignage/seesignage-utils/lib/types/lunchLists'
import {
  getListsSuccess,
  createListSuccess,
  updateListSuccess,
  deleteListSuccess,
  selectListItem,
  deselectListItem,
  reorderItemsStore,
  deleteListItemSuccess,
  addListItemSuccess,
  getListSuccess,
  getListFail,
  getList,
  getLists,
  getListsFail,
  searchLists,
  clearSearchLists,
  saveRetailListSuccess,
  updateProductFromList,
  deselectAllListItems,
  createOrUpdateLunchListGroupSuccess,
  createOrUpdateLunchListProductSuccess,
  deleteLunchListItemSuccess,
  addLunchListWeekSuccess,
  deleteLunchListWeekSuccess,
  setSelectedLunchListItem,
  deselectSelectedLunchListItem,
  updateLunchListItemsSuccess,
  reorderLunchListItemSuccess,
  deleteLunchListGroupOrProductSuccess,
  copyListSuccess,
  publishLunchListSuccess,
  navigateToLunchListSuccess,
  addLunchListProductChangesSuccess,
  getLunchListContentSuccess,
  resetLists,
  saveMiscListSuccess,
  saveMiscListFail,
  saveMiscList
} from '../actions/lists'
import { ListsState } from '../types/states'
import {
  GetListSuccess,
  GetListsSuccess,
  AddListItemSuccess,
  DeleteListItemSuccess,
  DeleteLunchListItemSuccess,
  Action,
  AddLunchListWeekSuccessParams,
  GetLunchListContentSuccess
} from '../types/actions'
import { SelectedLunchItemType } from '../types/lists'

const initialState: ListsState = {
  lists: {}, // indexed by listId
  getListsIsLoading: false,
  listIsLoading: false,
  selectedListItemIds: [],
  searchTerm: undefined,
  lunchListModified: false,
  lunchListWeekChanges: {},
  listModified: false,
  selectedLunchItem: undefined
}

const lists = handleActions<ListsState, any>(
  {
    [resetLists]: () => initialState,
    [getLists]: state => ({
      ...state,
      getListsIsLoading: true
    }),
    [getListsFail]: state => ({
      ...state,
      getListsIsLoading: false
    }),
    [getListsSuccess]: (state, { payload: listsArray }: GetListsSuccess) => {
      const lists = listsArray.reduce((lists: any, list: any) => {
        lists[list.listId] = {
          ...list
        }
        return lists
      }, {} as any)
      return {
        ...state,
        getListsIsLoading: false,
        lists
      }
    },
    [createListSuccess]: (state, { payload: list }: any) => ({
      ...state,
      lists: {
        ...state.lists,
        [list.listId]: list
      }
    }),
    [updateListSuccess]: (state, { payload: list }: any) => ({
      ...state,
      lists: {
        ...state.lists,
        [list.listId]: {
          ...state.lists[list.listId],
          ...list
        }
      }
    }),
    [deleteListSuccess]: (state, { payload: listId }: any) => {
      const lists = { ...state.lists }
      delete lists[listId]
      return {
        ...state,
        lists
      }
    },
    [reorderItemsStore]: (state, { payload: { listId, items } }: any) => ({
      ...state,
      lists: {
        ...state.lists,
        [listId]: {
          ...state.lists[listId],
          items
        }
      },
      listModified: true
    }),
    [selectListItem]: (state, { payload: itemId }: any) => {
      const selectedItems = [...state.selectedListItemIds, itemId]
      return {
        ...state,
        selectedListItemIds: selectedItems
      }
    },
    [deselectListItem]: (state, { payload: itemId }: any) => {
      const ids = [...state.selectedListItemIds]
      const deductedIds = ids.filter(id => id !== itemId)
      return {
        ...state,
        selectedListItemIds: deductedIds
      }
    },
    [deselectAllListItems]: state => ({
      ...state,
      selectedListItemIds: []
    }),
    [addListItemSuccess]: (state, { payload: { listId, item } }: AddListItemSuccess) => ({
      ...state,
      lists: {
        ...state.lists,
        [listId]: {
          ...state.lists[listId],
          items: [...(state.lists[listId] as RetailListUI | MiscListUI).items, item]
        }
      },
      listModified: true
    }),
    [deleteListItemSuccess]: (state, { payload: { listId, itemId } }: DeleteListItemSuccess) => {
      const items = (state.lists[listId] as RetailListUI | MiscListUI).items
      const i = items.findIndex((item: RetailListItemUI | MiscListItemUI) => item.itemId === itemId)
      items.splice(i, 1)
      return {
        ...state,
        lists: {
          ...state.lists,
          [listId]: {
            ...state.lists[listId],
            items
          }
        },
        listModified: true
      }
    },
    [getList]: state => ({
      ...state,
      listIsLoading: true,
      listModified: false,
      lunchListModified: false,
      selectedLunchItem: undefined,
      lunchListWeekChanges: {}
    }),
    [getListFail]: state => ({
      ...state,
      listIsLoading: false
    }),
    [getListSuccess]: (state, { payload: list }: GetListSuccess) => {
      return {
        ...state,
        listIsLoading: false,
        lists: {
          ...state.lists,
          [list.listId]: list
        }
      }
    },
    [saveMiscListSuccess]: (state, { payload: list }: Action<MiscListUI>) => {
      return {
        ...state,
        listIsLoading: false,
        listModified: false,
        lists: {
          ...state.lists,
          [list.listId]: list
        }
      }
    },
    [saveMiscList]: state => ({
      ...state,
      listIsLoading: true
    }),
    [saveMiscListFail]: state => ({
      ...state,
      listIsLoading: false
    }),
    [getLunchListContentSuccess]: (
      state,
      { payload: { list, content } }: GetLunchListContentSuccess
    ) => {
      if (content === IoTLunchListContent.groups) {
        return {
          ...state,
          listIsLoading: false,
          lists: {
            ...state.lists,
            [list.listId]: {
              ...state.lists[list.listId],
              groups: list.groups
            }
          }
        }
      } else if (content === IoTLunchListContent.products) {
        return {
          ...state,
          listIsLoading: false,
          lists: {
            ...state.lists,
            [list.listId]: {
              ...state.lists[list.listId],
              products: list.products
            }
          }
        }
      }
      return {
        ...state
      }
    },
    [searchLists]: (state, { payload: searchTerm }: any) => ({
      ...state,
      searchTerm
    }),
    [clearSearchLists]: state => ({
      ...state,
      searchTerm: undefined
    }),
    [createOrUpdateLunchListGroupSuccess]: (state, { payload: { listId, group } }: any) => {
      return {
        ...state,
        lists: {
          ...state.lists,
          [listId]: {
            ...state.lists[listId],
            groups: {
              ...(state.lists[listId] as LunchList).groups,
              [group.groupId]: group
            }
          }
        },
        selectedLunchItem: undefined
      }
    },
    [deleteLunchListItemSuccess]: (state, action: any) => {
      // delete lunch list item inside weeks
      const {
        payload: { item, listId, weekChanges }
      } = action as DeleteLunchListItemSuccess
      const { type, weekIndex, day, lunchItemIndex } = item
      if (
        type === SelectedLunchItemType.lunchItem &&
        weekIndex !== undefined &&
        lunchItemIndex !== undefined &&
        day
      ) {
        const lunchListWeeks = (state.lists[listId] as LunchList).weeks
        const weeks = lunchListWeeks ? [...lunchListWeeks] : []
        weeks[weekIndex].days[day]?.lunchItems.splice(lunchItemIndex, 1)
        return {
          ...state,
          lunchListModified: true, // requires lunch items update to save changes
          selectedLunchItem: undefined,
          lunchListWeekChanges: weekChanges,
          lists: {
            ...state.lists,
            [listId]: {
              ...(state.lists[listId] as LunchList),
              weeks
            }
          }
        }
      }
      return {
        ...state
      }
    },
    [deleteLunchListGroupOrProductSuccess]: (state, { payload: list }: any) => ({
      ...state,
      selectedLunchItem: undefined,
      lists: {
        ...state.lists,
        [list.listId]: list
      }
    }),
    [deleteLunchListWeekSuccess]: (state, action: any) => {
      const {
        payload: { weekIndex, listId, weekChanges }
      } = action // TODO types
      const lunchListWeeks = (state.lists[listId] as LunchList).weeks
      const weeks = lunchListWeeks ? [...lunchListWeeks] : []
      weeks.splice(weekIndex, 1)
      return {
        ...state,
        lists: {
          ...state.lists,
          [listId]: {
            ...(state.lists[listId] as LunchList),
            weeks
          }
        },
        lunchListModified: true,
        selectedLunchItem: undefined,
        lunchListWeekChanges: weekChanges
      }
    },
    [createOrUpdateLunchListProductSuccess]: (state, { payload: { listId, product } }: any) => {
      return {
        ...state,
        lists: {
          ...state.lists,
          [listId]: {
            ...state.lists[listId],
            products: {
              ...(state.lists[listId] as LunchList).products,
              [product.productId]: product
            }
          }
        },
        selectedLunchItem: undefined
      }
    },
    [updateLunchListItemsSuccess]: (state, { payload: list }: any) => ({
      ...state,
      lists: {
        ...state.lists,
        [list.listId]: list
      },
      lunchListModified: false,
      lunchListWeekChanges: {}
    }),
    [navigateToLunchListSuccess]: (state, { payload: newInitializedSubList }: any) => {
      if (newInitializedSubList) {
        return {
          ...state,
          lists: {
            ...state.lists,
            [newInitializedSubList.listId]: newInitializedSubList
          },
          lunchListModified: false
        }
      }
      return {
        ...state
      }
    },
    [publishLunchListSuccess]: (state, { payload: list }: any) => ({
      ...state,
      lists: {
        ...state.lists,
        [list.listId]: list
      },
      lunchListModified: false
    }),
    [saveRetailListSuccess]: state => ({
      ...state,
      listModified: false
    }),
    [updateProductFromList]: (
      state,
      {
        payload: { listId, productId, updatedProduct }
      }: Action<{ listId: string; productId: string; updatedProduct: RetailProduct }>
    ) => {
      const items = [...(state.lists[listId] as RetailListUI).items]
      const itemIndex = items.findIndex(item => item.productId === productId)
      items[itemIndex] = { ...items[itemIndex], product: { ...updatedProduct } }
      const updatedList: RetailListUI = {
        ...(state.lists[listId] as RetailListUI),
        items
      }
      return {
        ...state,
        lists: {
          ...state.lists,
          [listId]: updatedList
        }
      }
    },
    [addLunchListWeekSuccess]: (state, { payload }: any) => {
      const { listId, week, weekChanges } = payload as AddLunchListWeekSuccessParams
      const lunchListWeeks = (state.lists[listId] as LunchList).weeks
      return {
        ...state,
        lists: {
          ...state.lists,
          [listId]: {
            ...state.lists[listId],
            // add new week to end of the weeks array
            weeks: lunchListWeeks ? [...lunchListWeeks, week] : [week]
          }
        },
        lunchListWeekChanges: weekChanges,
        lunchListModified: true
      }
    },
    [reorderLunchListItemSuccess]: (state, { payload: { listId, weeks, weekChanges } }: any) => ({
      ...state,
      lists: {
        ...state.lists,
        [listId]: {
          ...state.lists[listId],
          weeks
        }
      },
      lunchListModified: true,
      lunchListWeekChanges: weekChanges
    }),
    [addLunchListProductChangesSuccess]: (state, { payload: { listId, weeks } }: any) => ({
      ...state,
      lists: {
        ...state.lists,
        [listId]: {
          ...state.lists[listId],
          weeks
        }
      },
      lunchListModified: true,
      selectedLunchItem: undefined
    }),
    [setSelectedLunchListItem]: (state, { payload: selectedLunchItem }: any) => ({
      ...state,
      selectedLunchItem
    }),
    [deselectSelectedLunchListItem]: state => ({
      ...state,
      selectedLunchItem: undefined
    }),
    [copyListSuccess]: (state, { payload: list }: Action<any>) => ({
      ...state,
      lists: {
        ...state.lists,
        [list.listId]: list
      }
    })
  },
  initialState
)

export default lists
