import { path } from 'ramda'
import { ScreenResponse, ChannelItemPriority } from '@seesignage/seesignage-utils'
import { CalendarEvent } from '../types/channels'
import { SortByValue, SortDirection } from '../types/sortings'

const isValidKeys = (key: string, a: any, b: any) =>
  Object.prototype.hasOwnProperty.call(a, key) || Object.prototype.hasOwnProperty.call(b, key)

const compareStrings = (orderBy: string, order?: SortDirection) => (a: any, b: any) => {
  if (!isValidKeys(orderBy, a, b)) {
    return 0
  }
  // sorting strings with non-ASCII characters
  const aString: string | undefined = a[orderBy]
  const bString: string | undefined = b[orderBy]

  if (aString && bString) {
    const c = a[orderBy].toUpperCase().localeCompare(b[orderBy].toUpperCase())
    // ascending order by default
    return order === SortDirection.desc ? c * -1 : c
  }
  // reference string occurs before compare string
  return -1
}

/**
 * Compare strings of nested objects
 * orderBy must be in format such as 'a.b'
 */
const compareStringsNested = (orderBy: string, order?: SortDirection) => (a: any, b: any) => {
  const keyPath = orderBy.split('.')
  const aString: string | undefined = path(keyPath, a)
  const bString: string | undefined = path(keyPath, b)

  if (typeof aString !== 'string' || typeof bString !== 'string') {
    return 0
  }

  if (aString && bString) {
    const c = aString.toUpperCase().localeCompare(bString.toUpperCase())
    // ascending order by default
    return order === SortDirection.desc ? c * -1 : c
  }
  // reference string occurs before compare string
  return -1
}

const compareBySortType = (key: string, order: SortDirection) => (a: any, b: any) => {
  if (key === SortByValue.lastOnline) {
    // last status of the array is the latest status
    const aUpdatedAt = (a as ScreenResponse).statuses[(a as ScreenResponse).statuses.length - 1]
      ?.updatedAt
    const bUpdatedAt = (b as ScreenResponse).statuses[(b as ScreenResponse).statuses.length - 1]
      ?.updatedAt
    if (aUpdatedAt && bUpdatedAt) {
      return order === SortDirection.desc ? aUpdatedAt - bUpdatedAt : bUpdatedAt - aUpdatedAt
    } else if (aUpdatedAt) {
      return -1
    } else if (bUpdatedAt) {
      return 1
    }
  } else if (!isValidKeys(key, a, b)) {
    return 0
  }
  if (typeof a[key] === 'string') {
    // sorting strings with non-ASCII characters
    const c = a[key].toUpperCase().localeCompare(b[key].toUpperCase())
    // ascending order by default
    return order === SortDirection.desc ? c * -1 : c
  }
  return order === SortDirection.desc ? a[key] - b[key] : b[key] - a[key]
}

const compareArrayOfStrings = (order?: SortDirection) => (a: any, b: any) => {
  const c = a.toUpperCase().localeCompare(b.toUpperCase())
  return order === SortDirection.desc ? c * -1 : c
}

const compareScreensByAvailableCapacity = (order?: SortDirection) => (
  screenA: ScreenResponse,
  screenB: ScreenResponse
) => {
  const aC: number | undefined = path(['storage', 'availableCapacity'], screenA)
  const bC: number | undefined = path(['storage', 'availableCapacity'], screenB)
  const diff = aC && bC ? aC - bC : -1
  // ascending order by default
  return order === SortDirection.desc ? diff * -1 : diff
}

const compareCreatedAtDates = (a: any, b: any) => b.createdAt - a.createdAt

const compareStartDatesAsc = (a: any, b: any) => a.startDate - b.startDate

const compareSchedules = (a: any, b: any) => {
  const aC: number | undefined = path(['startDate'], a)
  const bC: number | undefined = path(['startDate'], b)
  return aC && bC ? aC - bC : -1
}

/**
 * Sort calendar events by priority before passing as prop to Calendar
 * @param direction
 */
const sortByPriority = (direction?: SortDirection) => (a: CalendarEvent, b: CalendarEvent) => {
  return direction === SortDirection.asc
    ? (a.priority || ChannelItemPriority.low) - (b.priority || ChannelItemPriority.low)
    : (b.priority || ChannelItemPriority.low) - (a.priority || ChannelItemPriority.low)
}

const sortTable = (
  property: string,
  order: SortDirection,
  orderBy: string,
  setOrder: React.Dispatch<React.SetStateAction<SortDirection>>,
  setOrderBy: React.Dispatch<React.SetStateAction<string>>
) => {
  setOrder(
    orderBy === property && order === SortDirection.desc ? SortDirection.asc : SortDirection.desc
  )
  setOrderBy(property)
}

export {
  compareStrings,
  compareStringsNested,
  compareBySortType,
  compareScreensByAvailableCapacity,
  compareCreatedAtDates,
  compareArrayOfStrings,
  compareSchedules,
  compareStartDatesAsc,
  sortByPriority,
  sortTable
}
