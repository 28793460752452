import React, { Fragment } from 'react'
import { reduxForm, InjectedFormProps, Field } from 'redux-form'
import { TFunction } from 'i18next'
import ContentColorPicker from '../../../../../components/FormInput/ColorPicker/ContentColorPicker'
import { CanvasBackgroundFormData } from '../../../../../types/contents'

type FormProps = CanvasBackgroundFormData

interface ContentCircleFormProps {
  size?: number | string
  t: TFunction
}

const CanvasBackgroundForm = ({
  size,
  t
}: ContentCircleFormProps & InjectedFormProps<FormProps, ContentCircleFormProps>) => (
  <Fragment>
    <Field
      name='backgroundColor'
      component={ContentColorPicker}
      tooltip={t('contents.actions.selectBackgroundColor')}
      size={size}
    />
  </Fragment>
)
export default reduxForm<FormProps, ContentCircleFormProps>({
  form: 'CanvasBackgroundForm'
})(CanvasBackgroundForm)
