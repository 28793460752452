import { createAction, createAsyncActions } from './utils'

const reducer = 'INFOPAGES'

const resetInfopages = createAction(reducer, 'RESET')

const {
  action: listInfopages,
  actionFail: listInfopagesFail,
  actionSuccess: listInfopagesSuccess
} = createAsyncActions(reducer, 'LIST')

const {
  action: getInfopage,
  actionFail: getInfopageFail,
  actionSuccess: getInfopageSuccess
} = createAsyncActions(reducer, 'GET')

const {
  action: createInfopage,
  actionSuccess: createInfopageSuccess,
  actionFail: createInfopageFail
} = createAsyncActions(reducer, 'CREATE')

const {
  action: deleteInfopage,
  actionSuccess: deleteInfopageSuccess,
  actionFail: deleteInfopageFail
} = createAsyncActions(reducer, 'DELETE')

const {
  action: updateInfopage,
  actionSuccess: updateInfopageSuccess,
  actionFail: updateInfopageFail
} = createAsyncActions(reducer, 'UPDATE')

const {
  action: createInfopageToPlaylist,
  actionSuccess: createInfopageToPlaylistSuccess,
  actionFail: createInfopageToPlaylistFail
} = createAsyncActions(reducer, 'CREATE_TO_PLAYLIST')

const {
  action: updateInfopageKeys,
  actionSuccess: updateInfopageKeysSuccess,
  actionFail: updateInfopageKeysFail
} = createAsyncActions(reducer, 'UPDATE_KEYS')

const {
  action: updateInfopageSettings,
  actionSuccess: updateInfopageSettingsSuccess,
  actionFail: updateInfopageSettingsFail
} = createAsyncActions(reducer, 'UPDATE_SETTINGS')

const {
  action: copyInfopage,
  actionSuccess: copyInfopageSuccess,
  actionFail: copyInfopageFail
} = createAsyncActions(reducer, 'COPY')

const selectInfopageTemplate = createAction(reducer, 'SELECT_TEMPLATE')
const clearSelectedInfopageTemplate = createAction(reducer, 'CLEAR_TEMPLATE')

const selectUnusedInfopages = createAction(reducer, 'SELECT_UNUSED')
const selectInfopage = createAction(reducer, 'SELECT')
const deselectInfopage = createAction(reducer, 'DESELECT')
const deselectAllInfopages = createAction(reducer, 'DESELECT_ALL')

/** Initialize infopage content metadata to state */
const initInfopageMetadata = createAction(reducer, 'INIT_METADATA')
const setInfopagesInitialLoad = createAction(reducer, 'INITIAL_LOAD')
const setSelectedInfopageType = createAction(reducer, 'SET_SELECTED_INFOPAGE_TYPE')

export {
  resetInfopages,
  listInfopages,
  listInfopagesSuccess,
  listInfopagesFail,
  getInfopage,
  getInfopageFail,
  getInfopageSuccess,
  createInfopage,
  createInfopageFail,
  createInfopageSuccess,
  deleteInfopage,
  deleteInfopageFail,
  deleteInfopageSuccess,
  updateInfopage,
  updateInfopageFail,
  updateInfopageSuccess,
  createInfopageToPlaylist,
  createInfopageToPlaylistFail,
  createInfopageToPlaylistSuccess,
  updateInfopageKeys,
  updateInfopageKeysFail,
  updateInfopageKeysSuccess,
  updateInfopageSettings,
  updateInfopageSettingsFail,
  updateInfopageSettingsSuccess,
  copyInfopage,
  copyInfopageFail,
  copyInfopageSuccess,
  selectUnusedInfopages,
  selectInfopage,
  deselectInfopage,
  deselectAllInfopages,
  selectInfopageTemplate,
  clearSelectedInfopageTemplate,
  initInfopageMetadata,
  setInfopagesInitialLoad,
  setSelectedInfopageType
}
