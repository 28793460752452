import React from 'react'
import { InjectedFormProps, Field, reduxForm } from 'redux-form'
import { PharmacyProduct } from '@seesignage/seesignage-utils'
import { connect } from 'react-redux'
import { DialogActions, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Dispatch } from 'redux'
import SelectTemplate from '../../../components/FormInput/SelectTemplate'
import { StateInterface } from '../../../types/states'
import { selectTemplatesByType } from '../../../selectors/templates'
import { compareStrings } from '../../../utils/sorting'
import { required } from '../../../validation'
import { getMasterPharmacyProduct } from '../../../actions/products'
import { StateTemplate } from '../../../types/templates'
import CreateCustomerDrugForm from './CreateCustomerDrugForm'

interface OwnProps {
  submitAction: (formData: any) => void
  product: PharmacyProduct
}

interface StateProps {
  templates: StateTemplate[]
}

interface DispatchProps {
  fetchMasterProduct: (ean: string, property: 'description' | 'price') => void
}

type EditCustomerDrugFormProps = OwnProps & StateProps & DispatchProps

const EditCustomerDrugForm: React.FC<EditCustomerDrugFormProps &
  InjectedFormProps<{}, EditCustomerDrugFormProps>> = ({
  submitAction,
  product,
  handleSubmit,
  fetchMasterProduct,
  submitting,
  templates
}) => {
  const [t] = useTranslation()
  return (
    <form onSubmit={handleSubmit(submitAction)}>
      <Field
        initialTemplates={templates}
        name='template'
        component={SelectTemplate}
        required
        validate={[required]}
      />

      <CreateCustomerDrugForm selectedDrug={product} fetchMasterProduct={fetchMasterProduct} />
      <DialogActions>
        <Button disabled={submitting} color='primary' type='submit'>
          {t('general.save')}
        </Button>
      </DialogActions>
    </form>
  )
}

const mapStateToProps = (state: StateInterface): StateProps => ({
  templates: selectTemplatesByType('retail')(state).sort(compareStrings('name'))
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  fetchMasterProduct: (ean: string, property: 'description' | 'price') =>
    dispatch(getMasterPharmacyProduct({ form: 'EditCustomerDrugForm', ean, property }))
})

export default connect<StateProps, DispatchProps, OwnProps, StateInterface>(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<{}, EditCustomerDrugFormProps>({
    form: 'EditCustomerDrugForm',
    enableReinitialize: true
  })(EditCustomerDrugForm)
)
