import React from 'react'
import { reduxForm, InjectedFormProps } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { ContentIframeFormData } from '../../../../../types/contents'
import CommonContentFields from './CommonContentFields'

type FormProps = ContentIframeFormData

const ContentIframeForm: React.FC<InjectedFormProps<FormProps>> = () => {
  const [t] = useTranslation()
  return <CommonContentFields formName={'ContentIframeForm'} enableSize t={t} />
}
export default reduxForm<FormProps>({
  form: 'ContentIframeForm'
})(ContentIframeForm)
