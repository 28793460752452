import React, { Fragment, useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import MonitorIcon from 'mdi-react/MonitorIcon'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import { ValidateScreenCodesResponse } from '@seesignage/seesignage-utils'
import { WrappedFieldProps } from 'redux-form'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Button, CircularProgress, LinearProgress } from '@mui/material'
import { useDropzone } from 'react-dropzone'
import useDebounce from '../../../hooks/debounce'
import ScreensApi from '../../../services/api/screens'
import { selectSelectedEnvironmentId } from '../../../selectors/environments'
import {
  getImportScreenCodesCsvErrorTranslation,
  readScreenCodesFromCsvAndValidate
} from '../../../utils/csv'
import { dropzoneAcceptCsvFiles } from '../../../utils/dropzone'
import ErrorMessage from '../../Errors/ErrorMessage'
import { ScreensFormValueAutocompleteOption } from '../../../types/channels'

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2)
  },
  importCsvButton: {
    marginBottom: 8
  },
  textField: {
    marginTop: 8
  },
  errorContainer: {
    padding: '8px 0px 8px',
    maxHeight: '150px',
    overflow: 'auto'
  }
}))

interface ReduxReduxSelectScreensProps extends WrappedFieldProps {
  label: string
  disabled?: boolean
  helperText?: string
}

const ReduxSelectScreens = ({
  input,
  label,
  disabled,
  helperText,
  meta
}: ReduxReduxSelectScreensProps) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const environmentId = useSelector(selectSelectedEnvironmentId)
  const [loading, setLoading] = useState(false)
  const [isImportLoading, setImportLoading] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<ScreensFormValueAutocompleteOption[]>([])
  const searchTerm = useDebounce(inputValue, 200)
  const [importResponse, setImportResponse] = useState<ValidateScreenCodesResponse | undefined>()
  const [csvError, setCsvError] = useState<string | undefined>(undefined)

  const fieldValue: ScreensFormValueAutocompleteOption[] = input.value || []
  const { touched, invalid, error } = meta

  const { open } = useDropzone({
    onDrop: async acceptedFiles => {
      if (acceptedFiles.length > 0 && environmentId) {
        setImportLoading(true)
        setCsvError(undefined)
        setImportResponse(undefined)
        try {
          const screenCodesResponse = await readScreenCodesFromCsvAndValidate(
            acceptedFiles[0],
            environmentId
          )
          setImportResponse(screenCodesResponse)
          const currentScreenCodes = fieldValue.map(v => v.value)
          const uniqueValidScreens = screenCodesResponse.validScreens.filter(
            s => !currentScreenCodes.includes(s.code)
          )

          input.onChange([
            ...fieldValue,
            ...uniqueValidScreens.map(s => {
              const option: ScreensFormValueAutocompleteOption = {
                label: s.code,
                value: s.code,
                data: s
              }
              return option
            })
          ])
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error)
          setCsvError(error.message)
          setImportResponse(undefined)
        }
      }
      setImportLoading(false)
    },
    noClick: true,
    noKeyboard: true,
    multiple: false,
    accept: dropzoneAcceptCsvFiles
  })

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  useEffect(() => {
    const validateCodes = async () => {
      setLoading(true)
      setImportResponse(undefined)
      setCsvError(undefined)
      if (environmentId) {
        const response = await ScreensApi.validateScreenCodes(environmentId, [searchTerm])
        if (response.validScreens.length > 0) {
          const screen = response.validScreens[0]
          setOptions([
            {
              label: screen.code,
              value: screen.code,
              data: screen
            }
          ])
        }
      }
      setLoading(false)
    }
    if (searchTerm.length === 7) {
      validateCodes()
    }
  }, [searchTerm, environmentId])

  return (
    <Fragment>
      <Button
        color='primary'
        variant='contained'
        onClick={open}
        className={classes.importCsvButton}
        onFocus={input.onFocus}>
        {t('channels.forms.channelItemImportScreens')}
      </Button>
      {isImportLoading && <LinearProgress />}
      {importResponse && importResponse.invalidScreenCodes.length > 0 && (
        <div className={classes.errorContainer}>
          <ErrorMessage
            message={t('channels.forms.screensImportErrors.invalidScreenCodeApiResponse', {
              invalidScreens: importResponse?.invalidScreenCodes.join(', ')
            })}
          />
        </div>
      )}
      {csvError && (
        <div className={classes.errorContainer}>
          <ErrorMessage message={getImportScreenCodesCsvErrorTranslation(t, csvError)} />
        </div>
      )}
      <Autocomplete
        disabled={disabled}
        value={fieldValue}
        getOptionLabel={option => (typeof option === 'string' ? option : option.label)}
        filterOptions={x => x}
        onChange={(event, values: any) => {
          input.onChange(values)
        }}
        options={options}
        loading={loading}
        loadingText={`${t('general.loading')}...`}
        multiple
        autoComplete
        includeInputInList
        freeSolo
        filterSelectedOptions
        renderInput={params => (
          <TextField
            {...params}
            variant='outlined'
            onChange={handleTextFieldChange}
            onFocus={input.onFocus}
            onBlur={() => input.onBlur(undefined)} // make sure onBlur doesn't change field value
            label={label}
            error={touched && invalid}
            helperText={(touched && error) || helperText}
            fullWidth
            className={classes.textField}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color='inherit' size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        )}
        renderOption={(props, option: ScreensFormValueAutocompleteOption) => {
          return (
            <li {...props}>
              <Grid container alignItems='center'>
                <Grid item>
                  <MonitorIcon className={classes.icon} />
                </Grid>
                <Grid item xs>
                  <Typography variant='body2'>
                    {t('channels.forms.addScreenCode', {
                      value: option.label,
                      screenName: option.data.name
                    })}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          )
        }}
      />
    </Fragment>
  )
}

export default ReduxSelectScreens
