import * as Moment from 'moment'
import { extendMoment } from 'moment-range'
import {
  Channel,
  parseRecurringRule,
  RecurringRule,
  filterOldRecurrencies,
  RecurringFrequency,
  ChannelItemPriority,
  PlaylistUI,
  ChannelItem,
  LogicalOperator,
  ChannelPlaylist,
  Campaign,
  isChannelPlaylist,
  isChannelCampaign,
  ChannelCampaign,
  CampaignSub,
  isLegacyChannelPlaylist,
  ChannelPlaylistLegacy,
  ChannelCampaignLegacy,
  getScheduleDateTimeFromDate,
  getScheduleTimeFromString,
  isLegacyChannelCampaign,
  getDateFromScheduleDateTime,
  ScheduleDateTime,
  getDateFromScheduleTime,
  AutocompleteOption,
  ChannelItemScreenCondition
} from '@seesignage/seesignage-utils'
import { TFunction } from 'i18next'
import { toast } from 'react-toastify'
import { View } from 'react-big-calendar'
import {
  CalendarEvent,
  CalendarSlot,
  ChannelCardItem,
  ChannelItemType,
  UpdateChannelItemFormData
} from '../types/channels'
import { PlaylistsById } from '../types/playlists'
import { OpenDialog } from '../types/actions'
import { IndexById } from '../types/states'
import colors from '../styles/common/colors'
import { compareStartDatesAsc } from './sorting'
import { convertAutocompleteFormFieldValue, convertTagsForFormField } from './conversion'

export const MAX_OVERLAPPING_PLAYLISTS_LIMIT = 40

const moment = extendMoment(Moment)

const getPlaylistProperties = (playlist?: PlaylistUI) => {
  if (playlist) {
    const { defaultInterval, items, name, userHasAccess, permissionTags } = playlist
    return {
      defaultInterval,
      items,
      name,
      userHasAccess,
      permissionTags
    }
  }
  return {}
}

/**
 * Get start, end, startTime and endTime from ChannelPlaylist. Convert to schedules if item is legacy
 * @param item
 */
const getScheduleRangesFromChannelPlaylist = (item: ChannelPlaylist | ChannelPlaylistLegacy) => {
  if (isLegacyChannelPlaylist(item)) {
    const { endDate, startDate, startTime, endTime } = item
    return {
      start: getScheduleDateTimeFromDate(new Date(startDate)),
      end: getScheduleDateTimeFromDate(new Date(endDate)),
      startTime: startTime ? getScheduleTimeFromString(startTime) : undefined,
      endTime: endTime ? getScheduleTimeFromString(endTime) : undefined
    }
  }
  const { start, end, startTime, endTime } = item
  return {
    start,
    end,
    startTime,
    endTime
  }
}

/**
 * Get start and end from ChannelCampaign. Convert to schedules if item is legacy
 * @param item
 */
const getScheduleRangesFromChannelCampaign = (item: ChannelCampaign | ChannelCampaignLegacy) => {
  if (isLegacyChannelCampaign(item)) {
    const { endDate, startDate } = item
    return {
      start: getScheduleDateTimeFromDate(new Date(startDate)),
      end: getScheduleDateTimeFromDate(new Date(endDate))
    }
  }
  const { start, end } = item
  return {
    start,
    end
  }
}

const convertChannelToEvents = (
  channel: Channel,
  playlistsById: PlaylistsById,
  campaignsById: IndexById<Campaign | CampaignSub>
): CalendarEvent[] =>
  channel.items.reduce((events: CalendarEvent[], item) => {
    if (isChannelPlaylist(item) || isLegacyChannelPlaylist(item)) {
      const { playlistId, recurringRule, conditions, itemId, priority } = item
      const { start, end, startTime, endTime } = getScheduleRangesFromChannelPlaylist(item)
      const playlist = playlistsById[playlistId]
      const { items, name, userHasAccess } = getPlaylistProperties(playlist)
      if (recurringRule) {
        const recurringEvents = parseRecurringRule(start, end, recurringRule)
        for (const event of recurringEvents) {
          const { startDate: recurringStart, endDate: recurringEnd } = event
          events.push({
            itemId,
            playlistId,
            environmentId: channel.environmentId,
            title: name || '',
            playlistItemsCount: items ? items.length : undefined,
            start: getDateFromScheduleDateTime(recurringStart),
            end: getDateFromScheduleDateTime(recurringEnd),
            startTime: startTime ? getDateFromScheduleTime(startTime) : undefined,
            endTime: endTime ? getDateFromScheduleTime(endTime) : undefined,
            recurringRule,
            priority,
            userHasAccess,
            tags: conditions?.tags
          })
        }
      } else {
        events.push({
          itemId,
          playlistId,
          environmentId: channel.environmentId,
          title: name || '',
          playlistItemsCount: items ? items.length : undefined,
          start: getDateFromScheduleDateTime(start),
          end: getDateFromScheduleDateTime(end),
          startTime: startTime ? getDateFromScheduleTime(startTime) : undefined,
          endTime: endTime ? getDateFromScheduleTime(endTime) : undefined,
          priority,
          userHasAccess,
          tags: conditions?.tags
        })
      }
    } else if (isChannelCampaign(item) || isLegacyChannelCampaign(item)) {
      const { campaignId, itemId, priority } = item
      const { start, end } = getScheduleRangesFromChannelCampaign(item)
      const campaign = campaignsById[campaignId]
      events.push({
        itemId,
        environmentId: channel.environmentId,
        title: campaign?.name,
        start: getDateFromScheduleDateTime(start),
        end: getDateFromScheduleDateTime(end),
        campaignId,
        priority,
        userHasAccess: true
      })
    }

    return events
  }, [])

interface CheckIfSlotOverlapsTooManyCalendarEventsParams {
  events: CalendarEvent[]
  start: string | Date
  end: string | Date
  skipItemId?: string
  recurringRule?: RecurringRule | null
}

/**
 * Verify selected slot doesn't overlap with over X number of calendar events.
 * If recurring rule is provided, check also that no recurring events overlap
 */
const checkIfSlotOverlapsTooManyCalendarEvents = ({
  events,
  start,
  end,
  skipItemId,
  recurringRule
}: CheckIfSlotOverlapsTooManyCalendarEventsParams) => {
  const matches = events.filter(({ start: startDate, end: endDate, itemId }) => {
    if (skipItemId === itemId) {
      return false
    }
    if (recurringRule) {
      const dateRanges = parseRecurringRule(
        new Date(start).toISOString(),
        new Date(end).toISOString(),
        recurringRule
      )

      const recurringMatch = dateRanges.find(
        ({ startDate: recurringStart, endDate: recurringEnd }) => {
          const existingRange = moment.range(new Date(startDate), new Date(endDate))
          const newRange = moment.range(
            getDateFromScheduleDateTime(recurringStart),
            getDateFromScheduleDateTime(recurringEnd)
          )
          return newRange.overlaps(existingRange)
        }
      )
      return recurringMatch
    } else {
      const existingRange = moment.range(new Date(startDate), new Date(endDate))
      const newRange = moment.range(new Date(start), new Date(end))
      return newRange.overlaps(existingRange)
    }
  })

  const indexedEventsMatches = matches.reduce((indexedEvents: IndexById<CalendarEvent>, event) => {
    indexedEvents[event.itemId] = {
      ...event
    }
    return indexedEvents
  }, {})

  return Object.keys(indexedEventsMatches).length >= MAX_OVERLAPPING_PLAYLISTS_LIMIT
}

const generateChannelCardInformation = (
  channel: Channel,
  playlistsById: PlaylistsById,
  campaignsById: IndexById<Campaign | CampaignSub>
): {
  currentItems: ChannelCardItem[]
  futureItems: ChannelCardItem[]
} => {
  const currentDate = new Date()
  const sortedItems = channel.items.sort(compareStartDatesAsc)
  const { currentItems, futureItems } = sortedItems.reduce(
    (acc, item) => {
      const { start, end } =
        isChannelPlaylist(item) || isLegacyChannelPlaylist(item)
          ? getScheduleRangesFromChannelPlaylist(item)
          : getScheduleRangesFromChannelCampaign(item)
      const { playlistId, recurringRule } = item as ChannelPlaylist
      const { campaignId } = item as ChannelCampaign
      const type =
        isChannelPlaylist(item) || isLegacyChannelPlaylist(item)
          ? ChannelItemType.playlist
          : ChannelItemType.campaign
      const id = playlistId || campaignId
      const { startDate: itemStartDate, endDate: itemEndDate } = getChannelItemDateRanges(
        start,
        end,
        recurringRule
      )
      const s = getDateFromScheduleDateTime(itemStartDate)
      const e = getDateFromScheduleDateTime(itemEndDate)
      const completeItem =
        isChannelPlaylist(item) || isLegacyChannelPlaylist(item)
          ? playlistsById[playlistId]
          : campaignsById[campaignId]
      if (currentDate >= s && currentDate <= e && completeItem) {
        acc.currentItems.push({
          id,
          name: completeItem.name,
          startDate: s,
          endDate: e,
          type
        })
      } else if (s >= currentDate && completeItem) {
        acc.futureItems.push({
          id,
          name: completeItem.name,
          startDate: getDateFromScheduleDateTime(start),
          endDate: getDateFromScheduleDateTime(end),
          type
        })
      }

      return acc
    },
    {
      currentItems: [] as ChannelCardItem[],
      futureItems: [] as ChannelCardItem[]
    }
  )
  return {
    futureItems,
    currentItems
  }
}
/**
 * Get date ranges for current channel item event. If recurring rule exists, return current
 * or event in the nearest future.
 * @param itemStartDate
 * @param itemEndDate
 * @param recurringRule
 */
const getChannelItemDateRanges = (
  itemStartDate: ScheduleDateTime,
  itemEndDate: ScheduleDateTime,
  recurringRule?: RecurringRule
) => {
  if (recurringRule) {
    const recurringDateRanges = parseRecurringRule(itemStartDate, itemEndDate, recurringRule)
    const filteredOld = filterOldRecurrencies(recurringDateRanges)
    return filteredOld.length > 0
      ? { startDate: filteredOld[0].startDate, endDate: filteredOld[0].endDate }
      : { startDate: itemStartDate, endDate: itemEndDate }
  }
  return { startDate: itemStartDate, endDate: itemEndDate }
}

/**
 * Get initial values for recurring rule
 * @param selectedCalendarEvent
 */
const getRecurringRuleFormInitialValue = (selectedCalendarEvent?: CalendarEvent) => {
  if (selectedCalendarEvent) {
    const { recurringRule } = selectedCalendarEvent
    return {
      interval: recurringRule?.interval || 1,
      freq: recurringRule?.freq || RecurringFrequency.WEEKLY,
      noEndDate: recurringRule?.endDate ? false : true,
      endDate: recurringRule?.endDate
    }
  }
  return undefined
}

/**
 * onSelectSlot event for react-big-calendar
 */
const onSelectSlot = (
  event: CalendarSlot,
  events: CalendarEvent[],
  view: View,
  openDialog: OpenDialog,
  t: TFunction
) => {
  if (view === 'month') {
    event.start = new Date(event.start)
    event.end = moment(event.end)
      .endOf('day')
      .toDate()
  }
  if (checkIfSlotOverlapsTooManyCalendarEvents({ events, start: event.start, end: event.end })) {
    toast.warn(
      t('channels.calendar.collapsesPlaylist', {
        maxOverlappingPlaylistsLimit: MAX_OVERLAPPING_PLAYLISTS_LIMIT
      }),
      { autoClose: 15000 }
    )
  } else {
    openDialog({ event, id: 'createChannelItem' })
  }
}

const eventStyleByPriority = {
  [ChannelItemPriority.low]: { backgroundColor: colors.priorities.low },
  [ChannelItemPriority.medium]: { backgroundColor: colors.priorities.medium },
  [ChannelItemPriority.high]: { backgroundColor: colors.priorities.high },
  [ChannelItemPriority.emergency]: { backgroundColor: colors.priorities.emergency }
}

const eventChipStyleByPriority = {
  [ChannelItemPriority.low]: { color: colors.priorities.low, backgroundColor: 'white' },
  [ChannelItemPriority.medium]: { color: colors.priorities.medium, backgroundColor: 'white' },
  [ChannelItemPriority.high]: { color: colors.priorities.high, backgroundColor: 'white' },
  [ChannelItemPriority.emergency]: { color: colors.priorities.emergency, backgroundColor: 'white' }
}

const playlistPriorityStyleGetter = (priority: ChannelItemPriority) =>
  eventStyleByPriority[priority || ChannelItemPriority.low]

/**
 * eventPropGetter event for react-big-calendar
 * Specify custom event styles here
 */
const eventPropGetter = ({ priority }: CalendarEvent) => {
  return {
    // by default use 'low' priority style
    style: eventStyleByPriority[priority || ChannelItemPriority.low]
  }
}

const mappedPrioritiesValues = Object.values(ChannelItemPriority)
const prioritiesValues = mappedPrioritiesValues.slice(mappedPrioritiesValues.length / 2)

const getPrioritiesValues = () => prioritiesValues

const priorities = [
  {
    label: ChannelItemPriority.low,
    color: colors.priorities.low
  },
  {
    label: ChannelItemPriority.medium,
    color: colors.priorities.medium
  },
  {
    label: ChannelItemPriority.high,
    color: colors.priorities.high
  },
  {
    label: ChannelItemPriority.emergency,
    color: colors.priorities.emergency
  }
]

const getPriorityOptions = () => priorities

const getPlaylistInitialValues = (selectedPlaylist: PlaylistUI) => {
  const { name, defaultInterval, permissionTags } = selectedPlaylist
  return {
    name,
    defaultInterval,
    permissionTags: permissionTags
      ? permissionTags.map(tag => ({ value: tag, label: tag }))
      : undefined
  }
}

const convertChannelItemConditionsScreensForFormField = (screens?: ChannelItemScreenCondition[]) =>
  screens
    ? screens.map(s => ({
        value: s.code,
        label: s.code,
        data: s
      }))
    : undefined

const getUpdateChannelItemInitialValues = (
  selectedChannelItem: ChannelItem,
  selectedCalendarEvent: CalendarEvent
) => {
  const { priority } = selectedChannelItem
  const channelItem = convertChannelItem(selectedChannelItem)
  const { start, end } = getScheduleRangesFromChannelPlaylist(channelItem as ChannelPlaylist)
  const { startTime, endTime, conditions, recurringRule } = channelItem as ChannelPlaylist

  const formData: UpdateChannelItemFormData = {
    start: getDateFromScheduleDateTime(start),
    end: getDateFromScheduleDateTime(end),
    startTime: startTime ? getDateFromScheduleTime(startTime) : undefined,
    endTime: endTime ? getDateFromScheduleTime(endTime) : undefined,
    priority,
    conditions: conditions
      ? {
          operator: conditions.operator,
          tags: convertTagsForFormField(conditions.tags),
          screens: convertChannelItemConditionsScreensForFormField(conditions.screens)
        }
      : {
          operator: LogicalOperator.or
        },
    recurringEvent: recurringRule ? true : false,
    recurringRule: getRecurringRuleFormInitialValue(selectedCalendarEvent),
    playlistId: convertAutocompleteFormFieldValue(
      selectedCalendarEvent?.playlistId
    ) as AutocompleteOption
  }
  return formData
}

/**
 * Get initial values for CreateChannelItemForm when updating ChannelItem
 */
const getUpdateChannelItemFormInitialValues = (
  selectedPlaylist?: PlaylistUI,
  selectedChannelItem?: ChannelItem,
  selectedCalendarEvent?: CalendarEvent
) => ({
  playlist: selectedPlaylist ? getPlaylistInitialValues(selectedPlaylist) : undefined,
  channelItem:
    selectedChannelItem && selectedCalendarEvent
      ? getUpdateChannelItemInitialValues(selectedChannelItem, selectedCalendarEvent)
      : undefined
})

/**
 * If channelItem is deprecated legacy item, convert to new
 * @param item
 */
const convertChannelItem = (item: ChannelItem) => {
  if (isLegacyChannelCampaign(item)) {
    const { startDate, endDate, ...newItem } = item
    return {
      start: getScheduleDateTimeFromDate(new Date(startDate)),
      end: getScheduleDateTimeFromDate(new Date(endDate)),
      ...newItem
    }
  }
  if (isLegacyChannelPlaylist(item)) {
    const { startDate, endDate, startTime, endTime, ...newItem } = item
    return {
      start: getScheduleDateTimeFromDate(new Date(startDate)),
      end: getScheduleDateTimeFromDate(new Date(endDate)),
      startTime: startTime ? getScheduleTimeFromString(startTime) : undefined,
      endTime: endTime ? getScheduleTimeFromString(endTime) : undefined,
      ...newItem
    }
  }
  return item
}

/**
 * Convert all channel items to new ChannelPlaylist|ChannelCampaign
 * @param items
 */
const convertChannelItems = (items: ChannelItem[]): (ChannelPlaylist | ChannelCampaign)[] =>
  items.map(item => convertChannelItem(item))

export {
  convertChannelToEvents,
  checkIfSlotOverlapsTooManyCalendarEvents,
  generateChannelCardInformation,
  getRecurringRuleFormInitialValue,
  onSelectSlot,
  playlistPriorityStyleGetter,
  eventPropGetter,
  getPrioritiesValues,
  getPriorityOptions,
  eventChipStyleByPriority,
  getUpdateChannelItemFormInitialValues,
  convertChannelItem,
  convertChannelItems
}
