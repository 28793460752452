import React, { useState } from 'react'
import { ObjectType } from '@seesignage/seesignage-utils'
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip
} from '@mui/material'
import { ClassNameMap, makeStyles } from '@mui/styles'
import ExpandLessIcon from 'mdi-react/ExpandLessIcon'
import ExpandMoreIcon from 'mdi-react/ExpandMoreIcon'
import ChevronRightIcon from 'mdi-react/ChevronRightIcon'
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon'
import FormatTextVariantIcon from 'mdi-react/FormatTextVariantIcon'
import IframeIcon from 'mdi-react/IframeIcon'
import ImageIcon from 'mdi-react/ImageIcon'
import CircleIcon from 'mdi-react/CircleIcon'
import RectangleIcon from 'mdi-react/RectangleIcon'
import TriangleIcon from 'mdi-react/TriangleIcon'
import StopScheduleIcon from 'mdi-react/BusIcon'
import QrcodeScanIcon from 'mdi-react/QrcodeScanIcon'
import TableIcon from 'mdi-react/TableIcon'
import AnnouncementIcon from 'mdi-react/AnnouncementIcon'
import WeatherCloudyIcon from 'mdi-react/WeatherCloudyIcon'
import ClockDigitalIcon from 'mdi-react/ClockDigitalIcon'
import InstagramIcon from 'mdi-react/InstagramIcon'
import RssBoxIcon from 'mdi-react/RssBoxIcon'
import ViewCarouselOutlineIcon from 'mdi-react/ViewCarouselOutlineIcon'
import PolygonIcon from 'mdi-react/VectorPolygonIcon'
import LineIcon from 'mdi-react/VectorLineIcon'
import PencilIcon from 'mdi-react/PencilIcon'
import { MdiReactIconComponentType } from 'mdi-react'
import { TFunction } from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ContentEditorDialog, EditorCursorMode } from '../../../../types/contents'
import {
  selectEditorCursorMode,
  selectNumberOfLimitedWidgets
} from '../../../../selectors/contents'
import { isUserSystemAdmin } from '../../../../selectors/users'
import { selectProFeaturesEnabled } from '../../../../selectors/environments'
import { createObject, setEditorCursorMode } from '../../../../actions/contents'
import { isDrawingMode } from '../../../../utils/fabric/drawingUtils'
import { OpenContentEditorDialogProps } from '../../../../types/dialogs'
import { isMediaWidgetDisabled, isProFeature } from '../../../../utils/fabric/canvasObjectUtils'

const OPEN_PANEL_WIDTH = 280
const CLOSE_PANEL_WIDTH = 60

const useStyles = makeStyles({
  leftPanel: {
    height: '100%',
    minWidth: CLOSE_PANEL_WIDTH,
    position: 'relative',
    overflow: 'hidden auto',
    '-webkit-box-shadow': '1px 0 5px rgba(0, 0, 0, 0.3)',
    '-moz-box-shadow': '1px 0 5px rgba(0, 0, 0, 0.3)',
    boxShadow: '1px 0 5px rgba(0, 0, 0, 0.3)',
    transition: 'width .5s ease',
    zIndex: 10,
    background: '#fff'
  },
  subHeader: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontFamily: '"Roboto","Helvetica","Arial","sans-serif"',
    fontWeight: 500,
    fontSize: '0.875rem',
    padding: '8px 16px',
    height: 48,
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  panelHeader: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: 500,
    fontSize: '0.875rem',
    padding: '8px 16px',
    height: 48,
    minWidth: CLOSE_PANEL_WIDTH,
    justifyContent: 'end',
    alignItems: 'center'
  },
  subHeaderText: {
    padding: 8
  },
  listItem: {
    overflow: 'hidden',
    textWrap: 'nowrap'
  }
})

interface MenuItem {
  Icon: MdiReactIconComponentType
  label: any
  type: ObjectType
  dialogId?: ContentEditorDialog
}

interface MenuSection {
  headerTitle: any
  headerTitleDense: any
  items: MenuItem[]
}

const getLeftToolbarOptions = (t: TFunction): MenuSection[] => [
  {
    headerTitle: 'Basic',
    headerTitleDense: 'B',
    items: [
      {
        Icon: FormatTextVariantIcon,
        label: t('contents.menu.text'),
        type: ObjectType.CustomTextbox
      },
      {
        Icon: ImageIcon,
        label: t('contents.menu.media'),
        type: ObjectType.CustomImage,
        dialogId: ContentEditorDialog.AddMediaContentFormDialog
      }
    ]
  },
  {
    headerTitle: 'Draw',
    headerTitleDense: 'D',
    items: [
      {
        Icon: PencilIcon,
        label: t('contents.menu.freeDrawing'),
        type: ObjectType.CustomPath
      },
      {
        Icon: LineIcon,
        label: t('contents.menu.line'),
        type: ObjectType.CustomLine
      },
      {
        Icon: PolygonIcon,
        label: t('contents.menu.polygon'),
        type: ObjectType.CustomPolygon
      }
    ]
  },
  {
    headerTitle: t('contents.menu.shapes'),
    headerTitleDense: t('contents.menu.shapesDense'),
    items: [
      {
        Icon: TriangleIcon,
        label: t('contents.menu.triangle'),
        type: ObjectType.CustomTriangle
      },
      {
        Icon: RectangleIcon,
        label: t('contents.menu.rectangle'),
        type: ObjectType.CustomRect
      },
      {
        Icon: CircleIcon,
        label: t('contents.menu.circle'),
        type: ObjectType.CustomCircle
      }
    ]
  },
  {
    headerTitle: t('contents.menu.elements'),
    headerTitleDense: 'W',
    items: [
      {
        Icon: IframeIcon,
        label: t('contents.menu.iframe'),
        type: ObjectType.CustomIframe
      },
      {
        Icon: StopScheduleIcon,
        label: t('contents.menu.stopSchedule'),
        type: ObjectType.CustomStopSchedule
      },
      {
        Icon: TableIcon,
        label: t('contents.menu.table'),
        type: ObjectType.CustomTable
      },
      {
        Icon: ClockDigitalIcon,
        label: t('contents.menu.date'),
        type: ObjectType.CustomDate
      },
      {
        Icon: WeatherCloudyIcon,
        label: t('contents.menu.weather'),
        type: ObjectType.CustomWeather
      },
      {
        Icon: QrcodeScanIcon,
        label: t('contents.menu.qrCode'),
        type: ObjectType.CustomQRCode
      },
      {
        Icon: RssBoxIcon,
        label: t('contents.menu.rssFeed'),
        type: ObjectType.CustomRssFeed
      },
      {
        Icon: ViewCarouselOutlineIcon,
        label: t('contents.menu.mediaCarousel'),
        type: ObjectType.CustomMediaCarousel,
        dialogId: ContentEditorDialog.MediaCarouselDialog
      },
      {
        Icon: InstagramIcon,
        label: t('contents.menu.socialMedia'),
        type: ObjectType.CustomSocialMedia,
        dialogId: ContentEditorDialog.AddSocialMediaDialog
      },
      {
        Icon: AnnouncementIcon,
        label: t('contents.menu.falconyAnnouncements'),
        type: ObjectType.CustomFalconyAnnouncements
      }
    ]
  }
]

const listItemIsSelected = (type: ObjectType, editorCursorMode?: EditorCursorMode) =>
  (editorCursorMode === EditorCursorMode.freeDrawing && type === ObjectType.CustomPath) ||
  (editorCursorMode === EditorCursorMode.drawingLine && type === ObjectType.CustomLine) ||
  (editorCursorMode === EditorCursorMode.drawingPolygon && type === ObjectType.CustomPolygon)

interface SectionProps {
  numberOfLimitedMediaWidgets: number
  section: MenuSection
  openPanel: boolean
  classes: ClassNameMap<string>
  editorCursorMode?: EditorCursorMode
  onClick: (item: MenuItem) => void
  t: TFunction
}

const Section = ({
  classes,
  numberOfLimitedMediaWidgets,
  section,
  openPanel,
  onClick,
  editorCursorMode,
  t
}: SectionProps) => {
  const [open, setOpen] = useState(true)
  const { headerTitle, headerTitleDense, items } = section

  return (
    <List disablePadding>
      <ListItem disablePadding>
        <ListItemButton
          className={classes.subHeader}
          disabled={!openPanel}
          sx={{
            '&.Mui-disabled ': {
              opacity: 1
            }
          }}
          onClick={() => setOpen(!open)}>
          <div className={classes.subHeaderText}>{openPanel ? headerTitle : headerTitleDense}</div>
          {openPanel && <div>{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>}
        </ListItemButton>
      </ListItem>
      <Collapse in={open || !openPanel} timeout='auto' unmountOnExit>
        <List disablePadding>
          {items.map(item => {
            const { type, label } = item
            const { isDisabled, tooltip } = isMediaWidgetDisabled(
              type,
              numberOfLimitedMediaWidgets,
              t
            )
            return (
              <Tooltip disableInteractive title={tooltip} key={type}>
                <span>
                  <ListItem disablePadding>
                    <ListItemButton
                      selected={listItemIsSelected(type, editorCursorMode)}
                      disabled={editorCursorMode === EditorCursorMode.grab || isDisabled}
                      className={classes.listItem}
                      onClick={() => onClick(item)}>
                      <ListItemIcon>
                        <item.Icon />
                      </ListItemIcon>
                      <ListItemText primary={label} />
                    </ListItemButton>
                  </ListItem>
                </span>
              </Tooltip>
            )
          })}
        </List>
      </Collapse>
    </List>
  )
}

interface CanvasObjectMenuPanelProps {
  t: TFunction
  openDialog: (props: OpenContentEditorDialogProps) => void
}

const CanvasObjectMenuPanel = ({ openDialog, t }: CanvasObjectMenuPanelProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const editorCursorMode = useSelector(selectEditorCursorMode)
  const numberOfLimitedMediaWidgets = useSelector(selectNumberOfLimitedWidgets) // note: this limit is because max 4 video per content is supported
  const isSystemAdmin = useSelector(isUserSystemAdmin)
  const isProFeaturesEnabledByEnvironment = useSelector(selectProFeaturesEnabled)
  const proFeaturesEnabled = isSystemAdmin || isProFeaturesEnabledByEnvironment // always enable pro features for system admin

  const [openPanel, setOpenPanel] = useState(true)

  const handleElementClick = (menuItem: MenuItem) => {
    const { type, dialogId } = menuItem
    switch (type) {
      case ObjectType.CustomTextbox:
      case ObjectType.CustomImage: {
        if (isDrawingMode(editorCursorMode)) {
          dispatch(setEditorCursorMode(EditorCursorMode.move))
        }
        if (dialogId) {
          openDialog({ dialogId })
        } else {
          dispatch(createObject({ type }))
        }
        break
      }

      case ObjectType.CustomPath:
      case ObjectType.CustomLine:
      case ObjectType.CustomPolygon:
      case ObjectType.CustomTriangle:
      case ObjectType.CustomRect:
      case ObjectType.CustomCircle:
      case ObjectType.CustomIframe:
      case ObjectType.CustomStopSchedule:
      case ObjectType.CustomTable:
      case ObjectType.CustomDate:
      case ObjectType.CustomWeather:
      case ObjectType.CustomQRCode:
      case ObjectType.CustomRssFeed:
      case ObjectType.CustomMediaCarousel:
      case ObjectType.CustomFalconyAnnouncements:
      case ObjectType.CustomSocialMedia: {
        const isProFeat = isProFeature(type)
        // NOTE: add check if type === custom widget.
        // Now, shapes are also disabled if !widgetsEnabled
        if (isProFeat && !proFeaturesEnabled) {
          openDialog({ dialogId: ContentEditorDialog.LimitedFeatures })
          return
        }

        if (isDrawingMode(editorCursorMode) && type !== ObjectType.CustomPath) {
          dispatch(setEditorCursorMode(EditorCursorMode.move))
        }

        if (type === ObjectType.CustomPath) {
          dispatch(setEditorCursorMode(EditorCursorMode.freeDrawing))
        } else if (type === ObjectType.CustomLine) {
          dispatch(setEditorCursorMode(EditorCursorMode.drawingLine))
        } else if (type === ObjectType.CustomPolygon) {
          dispatch(setEditorCursorMode(EditorCursorMode.drawingPolygon))
        } else if (dialogId) {
          openDialog({ dialogId })
        } else {
          dispatch(createObject({ type }))
        }
        break
      }

      default:
        break
    }
  }

  return (
    <div
      className={classes.leftPanel}
      style={{
        width: openPanel ? OPEN_PANEL_WIDTH : CLOSE_PANEL_WIDTH
      }}>
      <List aria-labelledby='nested-list-subheader' disablePadding>
        <ListItem disablePadding divider>
          <ListItemButton className={classes.panelHeader} onClick={() => setOpenPanel(!openPanel)}>
            {openPanel ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </ListItemButton>
        </ListItem>
        {getLeftToolbarOptions(t).map(option => (
          <Section
            classes={classes}
            editorCursorMode={editorCursorMode}
            numberOfLimitedMediaWidgets={numberOfLimitedMediaWidgets}
            openPanel={openPanel}
            section={option}
            key={option.headerTitle}
            onClick={handleElementClick}
            t={t}
          />
        ))}
      </List>
    </div>
  )
}

export default CanvasObjectMenuPanel
