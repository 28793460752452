import { Typography } from '@mui/material'
import { WithStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import withStyles from '@mui/styles/withStyles'
import { path } from 'ramda'
import React, { Fragment } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { ListPlaylistItemUI } from '@seesignage/seesignage-utils'
import ItemTitle from '../../../../Lists/EditList/RetailList/ItemTitle'
import { PlaylistItemCardRootProps } from '../../../../../types/playlists'
import { generateListContainsText } from '../../../../../utils/lists'
import ItemIcons from './ItemIcons'
import SelectedIndicator from './SelectedIndicator'

const styles = () => ({
  item: {
    height: 140
  },
  itemTitleText: {
    padding: 4,
    overflow: 'hidden' as any,
    height: 25
  },
  media: {
    maxWidth: '100%',
    height: '100%'
  },
  mediaBox: {
    backgroundColor: 'white',
    maxWidth: '100%',
    maxHeight: '100%'
  },
  itemDescriptionArea: {
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)'
  },
  fileIcon: {
    float: 'left' as any,
    padding: '0px 4px 0px 4px'
  },
  conditionIcons: {
    float: 'right' as any
  }
})

interface ListAsItemProps
  extends PlaylistItemCardRootProps,
    WithStyles<typeof styles>,
    WithTranslation {
  item: ListPlaylistItemUI
}

const ListAsItem: React.FC<ListAsItemProps> = ({
  classes,
  item,
  isItemSelected,
  t,
  showPercentage,
  itemPercentage
}) => {
  const name: string | undefined = path(['list', 'name'], item)
  const type: string | undefined = path(['list', 'type'], item)
  return (
    <Grid container className={classes.item}>
      <Fragment>
        <Grid item xs={5} className={classes.itemDescriptionArea}>
          <ItemTitle containerClass={classes.itemTitleText} name={name} />
          <Typography display='block' variant='caption'>
            {type && t(`lists.types.${type}`)}
          </Typography>
          <Typography display='block' variant='caption'>
            {generateListContainsText(item, t)}
          </Typography>
        </Grid>
        <Grid item xs={7} style={{ height: '100%' }}>
          <Grid container style={{ height: '100%' }}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={11} />
                <SelectedIndicator
                  isItemSelected={isItemSelected}
                  showPercentage={showPercentage}
                  itemPercentage={itemPercentage}
                />
              </Grid>
            </Grid>
          </Grid>
          <ItemIcons item={item} />
        </Grid>
      </Fragment>
    </Grid>
  )
}

export default withStyles(styles)(withTranslation()(ListAsItem))
