import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useState, Fragment } from 'react'
import ExpandIcon from 'mdi-react/ChevronDownIcon'
import classNames from 'classnames'
import { SchemaComponents } from '@seesignage/seesignage-utils'
import i18n from '../../translations/i18n'
import { isTemplateComponentStylesAllowed } from '../../utils/templates'
import Icon from './Icon'

const DEBUG = false

const useStyles = makeStyles({
  expansionPanel: {
    width: '100%'
  },
  extensionPanelDragging: {
    backgroundColor: '#00AEEF'
  },
  expansionPanelContent: {
    display: 'block',
    margin: '4px 0'
  },
  expansionPanelDetails: {
    display: 'block'
  },
  title: {
    float: 'left' as 'left',
    fontSize: '0.875rem',
    fontFamily: 'Roboto',
    letterSpacing: '0.01071em',
    margin: 14
  },
  icon: {
    float: 'left' as 'left',
    marginTop: 10
  },
  actions: {
    float: 'right' as 'right'
  }
})

interface GetTitleTextProps {
  label?: string
  customLabel?: string
  schemaComponents?: SchemaComponents
}

const getTitleText = ({ label, schemaComponents, customLabel }: GetTitleTextProps) => {
  if (customLabel) {
    return customLabel
  } else if (label) {
    return i18n.t(`templates.forms.labels.${label}`)
  } else if (schemaComponents) {
    const { id, type, name } = schemaComponents
    return `${
      i18n.exists(`templates.forms.schema.${id}`) ? i18n.t(`templates.forms.schema.${id}`) : type
    } ${name ? `(${name})` : ''}`
  }
  return 'unknown'
}

interface FieldsExpansionPanelProps {
  schemaComponents?: SchemaComponents
  label?: string
  customLabel?: string
  children: any
  Actions?: any
  actionsProps?: any
  level?: number
  fieldsName?: any
  isDragging?: boolean
}

const FieldsExpansionPanel: React.FC<FieldsExpansionPanelProps> = ({
  schemaComponents,
  label,
  children,
  Actions,
  actionsProps,
  level,
  fieldsName,
  isDragging,
  customLabel
}) => {
  const classes = useStyles()
  const [isOpen, togglePanel] = useState(false)
  const allowStyles = isTemplateComponentStylesAllowed(schemaComponents?.type)
  return (
    <Accordion
      className={classNames(classes.expansionPanel, isDragging && classes.extensionPanelDragging)}
      expanded={isOpen}
      onChange={() => {
        togglePanel(!isOpen)
      }}>
      <AccordionSummary
        expandIcon={allowStyles ? <ExpandIcon /> : undefined}
        classes={{ content: classes.expansionPanelContent }}>
        <div>
          <div className={classes.icon}>
            {schemaComponents && <Icon type={schemaComponents.type} />}
          </div>
          <div className={classes.title}>
            {getTitleText({ label, schemaComponents, customLabel })}
          </div>
          {DEBUG && (
            <Fragment>
              {schemaComponents && schemaComponents.type
                ? ` (${schemaComponents.type}) (level: ${level})`
                : ''}
              {fieldsName ? ` (${fieldsName})` : ''}
            </Fragment>
          )}
        </div>
        <div
          className={classes.actions}
          // use stopPropagation to prevent exentsion panel from opening when clicking actions
          onClick={event => event.stopPropagation()}
          onFocus={event => event.stopPropagation()}>
          {Actions && <Actions {...actionsProps} />}
        </div>
      </AccordionSummary>
      {allowStyles && (
        <AccordionDetails className={classes.expansionPanelDetails}>
          {isOpen ? children : ''}
        </AccordionDetails>
      )}
    </Accordion>
  )
}

export default FieldsExpansionPanel
