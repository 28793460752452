import { Grid } from '@mui/material'
import React from 'react'
import { ScreenResponse } from '@seesignage/seesignage-utils'
import EthernetNetwork from './NetworkInformation/EthernetNetwork'
import WifiNetwork from './NetworkInformation/WifiNetwork'
import TvInformation from './TvInformation'
import FilesInformation from './TvInformation/FilesInformation'
import ConnectionInformation from './ConnectionInformation'

interface SelectedRowContentProps {
  screen: ScreenResponse
}

const SelectedRowContent: React.FC<SelectedRowContentProps> = ({ screen }) => {
  const lastItem = screen.statuses ? screen.statuses.slice(-1)[0] : undefined
  return (
    <Grid container>
      <Grid item xs={12}>
        <TvInformation screen={screen} updatedAt={lastItem?.updatedAt} />
      </Grid>
      <Grid item xs={12}>
        <ConnectionInformation screen={screen} />
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <WifiNetwork wifiNetwork={lastItem?.wifiNetwork} />
        </Grid>
        <Grid item xs={4}>
          <EthernetNetwork ethernetNetwork={lastItem?.ethernetNetwork} />
        </Grid>
        <Grid item xs={4}>
          <FilesInformation screen={screen} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SelectedRowContent
