/* eslint-disable @typescript-eslint/camelcase */
import { Amplify } from 'aws-amplify'
import { AWSIoTProvider } from '@aws-amplify/pubsub'
import config from './aws'

export const configureAmplify = async () => {
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      identityPoolId: config.cognito.IDENTITY_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID
    },
    Storage: {
      region: config.s3.mediaUploads.REGION,
      bucket: config.s3.mediaUploads.BUCKET,
      identityPoolId: config.cognito.IDENTITY_POOL_ID
    },
    API: {
      endpoints: [
        {
          name: 'environments',
          endpoint: config.apiGateway.environments.URL,
          region: config.apiGateway.environments.REGION
        },
        {
          name: 'screens',
          endpoint: config.apiGateway.screens.URL,
          region: config.apiGateway.screens.REGION
        },
        {
          name: 'playlists',
          endpoint: config.apiGateway.playlists.URL,
          region: config.apiGateway.playlists.REGION
        },
        {
          name: 'users',
          endpoint: config.apiGateway.users.URL,
          region: config.apiGateway.users.REGION
        },
        {
          name: 'media',
          endpoint: config.apiGateway.media.URL,
          region: config.apiGateway.media.REGION
        },
        {
          name: 'feedback',
          endpoint: config.apiGateway.feedback.URL,
          region: config.apiGateway.feedback.REGION
        },
        {
          name: 'lists',
          endpoint: config.apiGateway.lists.URL,
          region: config.apiGateway.lists.REGION
        },
        {
          name: 'products',
          endpoint: config.apiGateway.products.URL,
          region: config.apiGateway.products.REGION
        },
        {
          name: 'templates',
          endpoint: config.apiGateway.templates.URL,
          region: config.apiGateway.templates.REGION
        },
        {
          name: 'customers',
          endpoint: config.apiGateway.customers.URL,
          region: config.apiGateway.customers.REGION
        },
        {
          name: 'channels',
          endpoint: config.apiGateway.channels.URL,
          region: config.apiGateway.channels.REGION
        },
        {
          name: 'contents',
          endpoint: config.apiGateway.contents.URL,
          region: config.apiGateway.contents.REGION
        },
        {
          name: 'infopages',
          endpoint: config.apiGateway.infopages.URL,
          region: config.apiGateway.infopages.REGION
        },
        {
          name: 'campaigns',
          endpoint: config.apiGateway.campaigns.URL,
          region: config.apiGateway.campaigns.REGION
        },
        {
          name: 'integrations',
          endpoint: config.apiGateway.integrations.URL,
          region: config.apiGateway.integrations.REGION
        },
        {
          name: 'logs',
          endpoint: config.apiGateway.logs.URL,
          region: config.apiGateway.logs.REGION
        }
      ]
    }
  })

  Amplify.addPluggable(
    new AWSIoTProvider({
      aws_pubsub_region: config.iot.aws_pubsub_region,
      aws_pubsub_endpoint: config.iot.aws_pubsub_endpoint
    })
  )
}
