import { all, takeLatest, put, select, call } from 'redux-saga/effects'
import { Template, TemplateType } from '@seesignage/seesignage-utils'
import { SubmissionError } from 'redux-form'
import {
  setInitialMockDataFail,
  setInitialMockData,
  setInitialMockDataSuccess,
  setMockProductType,
  addTemplateMockData,
  addTemplateMockDataSuccess,
  addTemplateMockDataFail
} from '../actions/mocks'
import { closeDialog } from '../actions/dialogs'
import { selectContentIdFromPathname } from '../selectors/routing'
import { selectTemplateById } from '../selectors/templates'
import { MockProductType, MockItems } from '../types/mocks'
import { HandleAddTemplateMockData } from '../types/formData'

function* handleAddTemplateMockData({
  payload: {
    formData: {
      retail,
      generalRetail,
      pricerHankkija,
      pricerVerkkokauppa,
      pharmacy,
      lunch,
      fish,
      meat,
      currentMockProductType,
      additionalProps
    },
    resolve,
    reject
  }
}: HandleAddTemplateMockData) {
  try {
    const mockItems: MockItems = {
      retail,
      generalRetail,
      pricerHankkija,
      pricerVerkkokauppa,
      pharmacy,
      lunch,
      fish,
      meat
    }
    yield put(
      addTemplateMockDataSuccess({
        mockItems,
        mockAdditionalProps: additionalProps,
        currentMockProductType
      })
    )
    yield put(closeDialog())
    resolve()
  } catch (error) {
    yield put(addTemplateMockDataFail())
    yield call(
      reject,
      new SubmissionError({
        _error: error.message
      })
    )
  }
}

/**
 * initialize store with default MockProductType
 * */
function* handleSetInitialMockData() {
  const templateId: string = yield select(selectContentIdFromPathname)
  const { type }: Template = yield select(selectTemplateById(templateId))
  try {
    switch (type) {
      case TemplateType.lunch:
        yield put(setMockProductType(MockProductType.lunch))
        break
      case TemplateType.retail:
        yield put(setMockProductType(MockProductType.retail))
        break
      case TemplateType.fish:
        yield put(setMockProductType(MockProductType.fish))
        break
      case TemplateType.meat:
        yield put(setMockProductType(MockProductType.meat))
        break
      default:
        break
    }
    yield put(setInitialMockDataSuccess())
  } catch (error) {
    yield put(setInitialMockDataFail(error.message))
  }
}

function* watchMocksActions() {
  yield all([
    takeLatest(addTemplateMockData, handleAddTemplateMockData),
    takeLatest(setInitialMockData, handleSetInitialMockData)
  ])
}

export default [watchMocksActions]
