import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import FormatTextVariantIcon from 'mdi-react/FormatTextVariantIcon'
import IframeIcon from 'mdi-react/IframeIcon'
import StopScheduleIcon from 'mdi-react/BusIcon'
import TableIcon from 'mdi-react/TableIcon'
import ImageIcon from 'mdi-react/ImageIcon'
import VideoIcon from 'mdi-react/VideoIcon'
import CircleIcon from 'mdi-react/CircleIcon'
import RectangleIcon from 'mdi-react/RectangleIcon'
import TriangleIcon from 'mdi-react/TriangleIcon'
import PolygonIcon from 'mdi-react/VectorPolygonIcon'
import LineIcon from 'mdi-react/VectorLineIcon'
import DeleteIcon from 'mdi-react/DeleteIcon'
import WeatherCloudyIcon from 'mdi-react/WeatherCloudyIcon'
import ClockDigitalIcon from 'mdi-react/ClockDigitalIcon'
import ViewCarouselOutlineIcon from 'mdi-react/ViewCarouselOutlineIcon'
import RssBoxIcon from 'mdi-react/RssBoxIcon'
import QrcodeScanIcon from 'mdi-react/QrcodeScanIcon'
import DrawIcon from 'mdi-react/DrawIcon'
import { useTranslation } from 'react-i18next'
import FacebookIcon from 'mdi-react/FacebookIcon'
import InstagramIcon from 'mdi-react/InstagramIcon'
import AnnouncementIcon from 'mdi-react/AnnouncementIcon'
import { TFunction } from 'i18next'
import {
  isFabricCircleObject,
  isFabricDateObject,
  isFabricFalconyAnnouncementsObject,
  isFabricIframeObject,
  isFabricImageObject,
  isFabricLineObject,
  isFabricMediaCarouselObject,
  isFabricOnlyObject,
  isFabricPathObject,
  isFabricPolygonObject,
  isFabricQRCodeObject,
  isFabricRectObject,
  isFabricRssFeedObject,
  isFabricSocialMediaObject,
  isFabricStopScheduleObject,
  isFabricTableObject,
  isFabricTextboxObject,
  isFabricTriangleObject,
  isFabricVideoObject,
  isFabricWeatherObject,
  isFabricWidgetObject
} from '@seesignage/seesignage-player-utils'
import { MdiReactIconComponentType } from 'mdi-react'
import AlertIcon from 'mdi-react/AlertIcon'
import { SocialMediaPlatform } from '@seesignage/seesignage-utils'
import { selectEditorObjects } from '../../../../../selectors/contents'
import { setActiveObjectById, deselectActiveObject } from '../../../../../utils/fabric/canvas'
import {
  ObjectActionById,
  ObjectActionByIdProps,
  ReorderObjectsParams
} from '../../../../../types/actions'
import { SelectedObjectActionType } from '../../../../../types/contents'
import {
  objectActionById as objectActionByIdAction,
  reorderObjects as reorderObjectsAction
} from '../../../../../actions/contents'

const useStyles = makeStyles(() => ({
  listItem: {
    userSelect: 'none' as 'none',
    cursor: 'pointer',
    '& .Mui-selected': {
      backgroundColor: 'rgb(145, 225, 255, 0.5) !important'
    }
  }
}))

interface ObjectDetail {
  ObjectIcon: MdiReactIconComponentType
  label?: string
}

const getObjectDetails = (obj: fabric.Object): ObjectDetail => {
  if (isFabricTextboxObject(obj)) {
    return {
      ObjectIcon: FormatTextVariantIcon,
      label: obj.text
    }
  } else if (isFabricImageObject(obj)) {
    return {
      ObjectIcon: ImageIcon,
      label: obj.customOptions.name
    }
  } else if (isFabricRectObject(obj)) {
    return {
      ObjectIcon: RectangleIcon
    }
  } else if (isFabricCircleObject(obj)) {
    return {
      ObjectIcon: CircleIcon
    }
  } else if (isFabricLineObject(obj)) {
    return {
      ObjectIcon: LineIcon
    }
  } else if (isFabricPolygonObject(obj)) {
    return {
      ObjectIcon: PolygonIcon
    }
  } else if (isFabricPathObject(obj)) {
    return {
      ObjectIcon: DrawIcon
    }
  } else if (isFabricTriangleObject(obj)) {
    return {
      ObjectIcon: TriangleIcon
    }
  } else if (isFabricIframeObject(obj)) {
    return {
      ObjectIcon: IframeIcon,
      label: obj.customOptions.widgetProps.src
    }
  } else if (isFabricStopScheduleObject(obj)) {
    return {
      ObjectIcon: StopScheduleIcon
    }
  } else if (isFabricSocialMediaObject(obj)) {
    if (obj.customOptions.widgetProps.mediaProfile.platform === SocialMediaPlatform.facebook) {
      return {
        ObjectIcon: FacebookIcon,
        label: obj.customOptions.widgetProps.mediaProfile.socialMedia?.name
      }
    }
    return {
      ObjectIcon: InstagramIcon,
      label: obj.customOptions.widgetProps.mediaProfile.socialMedia?.name
    }
  } else if (isFabricRssFeedObject(obj)) {
    return {
      ObjectIcon: RssBoxIcon,
      label: obj.customOptions.widgetProps.url
    }
  } else if (isFabricTableObject(obj)) {
    return {
      ObjectIcon: TableIcon,
      label: obj.customOptions.widgetProps.rows?.[0]?.[0]?.[0].value.trim()
    }
  } else if (isFabricWeatherObject(obj)) {
    return {
      ObjectIcon: WeatherCloudyIcon,
      label: obj.customOptions.widgetProps.location?.label
    }
  } else if (isFabricDateObject(obj)) {
    return {
      ObjectIcon: ClockDigitalIcon
    }
  } else if (isFabricMediaCarouselObject(obj)) {
    return {
      ObjectIcon: ViewCarouselOutlineIcon
    }
  } else if (isFabricQRCodeObject(obj)) {
    return {
      ObjectIcon: QrcodeScanIcon,
      label: obj.customOptions.widgetProps.value
    }
  } else if (isFabricVideoObject(obj)) {
    return {
      ObjectIcon: VideoIcon,
      label: obj.customOptions.name
    }
  } else if (isFabricFalconyAnnouncementsObject(obj)) {
    return {
      ObjectIcon: AnnouncementIcon,
      label: obj.customOptions.widgetProps.url
    }
  } else {
    return {
      ObjectIcon: AlertIcon
    }
  }
}

interface CustomListItemProps {
  obj: any
  selectedObjectCId: string
  objectActionById: ObjectActionById
  t: TFunction
}

const CustomListItem: React.FC<CustomListItemProps> = ({
  obj,
  selectedObjectCId,
  objectActionById,
  t
}) => {
  const classes = useStyles()
  const cId = obj.customOptions.id
  const { label, ObjectIcon } = getObjectDetails(obj)
  return (
    <ListItem
      disablePadding
      className={classes.listItem}
      secondaryAction={
        <IconButton
          aria-label='delete'
          onClick={() => objectActionById({ action: SelectedObjectActionType.delete, cId })}
          size='large'>
          <DeleteIcon />
        </IconButton>
      }>
      <ListItemButton
        selected={selectedObjectCId === cId}
        onClick={() => {
          if (selectedObjectCId === cId) {
            deselectActiveObject()
          } else {
            setActiveObjectById(cId)
          }
        }}>
        <ListItemIcon>
          <ObjectIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography>
              {label ? `${label.slice(0, 15)}...` : t(`contents.objects.type.${obj.type}`)}
            </Typography>
          }
        />
      </ListItemButton>
    </ListItem>
  )
}

interface LayersProps {
  selectedObject?: fabric.Object
}

const Layers = ({ selectedObject }: LayersProps) => {
  const [t] = useTranslation()
  const selectedObjectCId = (selectedObject as any)?.customOptions.id
  const dispatch = useDispatch()
  const objectActionById = (props: ObjectActionByIdProps) => dispatch(objectActionByIdAction(props))
  const reorderObjects = (params: ReorderObjectsParams) => dispatch(reorderObjectsAction(params))
  const objects = [...useSelector(selectEditorObjects)].reverse()
  // note: in fabricjs objects are stored to array in order from bottom to top.
  // Last item of the array is on top. Visualize layers in oppositore order
  const fabricOnlyObjects = (objects as fabric.Object[]).filter(isFabricOnlyObject)
  const fabricWidgets = (objects as fabric.Object[]).filter(isFabricWidgetObject)
  const onDragEnd = (result: DropResult) => {
    if (result.destination && result.destination.index !== result.source.index) {
      reorderObjects({
        objectId: result.draggableId,
        startIndex: result.source.index,
        endIndex: result.destination.index
      })
    }
  }

  return (
    <>
      {fabricWidgets.length > 0 && (
        <List
          subheader={<ListSubheader disableSticky>{t('contents.objects.widgets')}</ListSubheader>}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='droppableWidgets'>
              {({ innerRef, placeholder }) => (
                <div ref={innerRef}>
                  {fabricWidgets.map((obj, index) => {
                    const id = (obj as any).customOptions.id || `object-${index}`
                    return (
                      <Draggable key={id} draggableId={id} index={index}>
                        {({ innerRef, draggableProps, dragHandleProps }) => {
                          return (
                            <div ref={innerRef} {...draggableProps} {...dragHandleProps}>
                              <CustomListItem
                                key={`widget-layer-${index}`}
                                obj={obj}
                                selectedObjectCId={selectedObjectCId}
                                objectActionById={objectActionById}
                                t={t}
                              />
                            </div>
                          )
                        }}
                      </Draggable>
                    )
                  })}
                  {placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </List>
      )}
      {fabricOnlyObjects.length > 0 && (
        <List
          dense
          subheader={<ListSubheader disableSticky>{t('contents.objects.objects')}</ListSubheader>}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='droppableWidgets'>
              {({ innerRef, placeholder }) => (
                <div ref={innerRef}>
                  {fabricOnlyObjects.map((obj, index) => {
                    const id = (obj as any).customOptions.id || `object-${index}`
                    return (
                      <Draggable key={id} draggableId={id} index={index}>
                        {({ innerRef, draggableProps, dragHandleProps }) => {
                          return (
                            <div ref={innerRef} {...draggableProps} {...dragHandleProps}>
                              <CustomListItem
                                key={`widget-layer-${index}`}
                                obj={obj}
                                selectedObjectCId={selectedObjectCId}
                                objectActionById={objectActionById}
                                t={t}
                              />
                            </div>
                          )
                        }}
                      </Draggable>
                    )
                  })}
                  {placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </List>
      )}
    </>
  )
}

export default Layers
