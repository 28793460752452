import React, { useState, Fragment } from 'react'
import { IconButton, Tooltip, Button, Divider, Popover } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { SketchPicker, ColorResult, RGBColor } from 'react-color'
import EyedropperIcon from 'mdi-react/EyedropperIcon'
import { WrappedFieldInputProps } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { convertRgbObjectToString, convertColorStringToObject } from '../../../utils/conversion'
import {
  selectCurrentEnvironmentColors,
  selectParentEnviromentColors
} from '../../../selectors/environments'
import PresetColorSelector from './PresetColorSelector'

const presetColors = [
  '#D0021B',
  '#F5A623',
  '#F8E71C',
  '#8B572A',
  '#7ED321',
  '#417505',
  '#BD10E0',
  '#9013FE',
  '#4A90E2',
  '#50E3C2',
  '#B8E986',
  '#000000',
  '#4A4A4A',
  '#9B9B9B',
  '#FAFAFA',
  '#FFFFFF'
]

const useStyles = makeStyles<undefined, { size: number | string }>(() => ({
  button: {
    width: ({ size }) => size,
    height: ({ size }) => size,
    padding: 5,
    '&:hover': {
      boxShadow: '0 0 0 2px rgb(30, 136, 229)'
    }
  },
  clearButton: {
    margin: '20px 0px 10px 0px',
    width: '100%'
  },
  colorPickerLabel: {
    paddingLeft: 5,
    paddingRight: 2,
    fontSize: '16px'
  },
  divider: {
    marginTop: '8px'
  },
  colorPickerContainer: {
    padding: '15px'
  }
}))

const sketchPickerStyles = {
  default: {
    picker: {
      padding: 0,
      boxShadow: 'none',
      width: '255px'
    }
  }
}

const handleOnChange = (
  setColor: React.Dispatch<React.SetStateAction<string | RGBColor | undefined>>,
  onChange: (value: any) => void,
  color: ColorResult
) => {
  const { rgb } = color
  setColor(rgb)
  const value = rgb.a === 1 ? color.hex : convertRgbObjectToString(rgb)
  onChange(value)
}

interface OwnProps {
  input: WrappedFieldInputProps | { value: string | undefined; onChange: any }
  size?: string | number
  tooltip: string
  label?: string
  disabled?: boolean
}

type ContentColorPickerProps = OwnProps

const ContentColorPicker: React.FC<ContentColorPickerProps> = ({
  input: { value, onChange },
  size = 35,
  tooltip,
  label,
  disabled
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [selectedColor, setColor] = useState<string | RGBColor | undefined>(
    convertColorStringToObject(value)
  )
  const [t] = useTranslation()
  const classes = useStyles({ size })
  const environmentColors = useSelector(selectCurrentEnvironmentColors)
  const parentEnvironmentColors = useSelector(selectParentEnviromentColors)

  const clearColor = () => {
    onChange('')
    setColor('')
  }

  return (
    <Fragment>
      <Tooltip disableInteractive title={tooltip}>
        <span>
          {label && <span className={classes.colorPickerLabel}>{label}</span>}
          <IconButton
            disabled={disabled}
            className={classes.button}
            onClick={(e: any) => {
              setAnchorEl(e.currentTarget)
            }}
            style={{
              backgroundColor: value
            }}
            size='large'>
            <EyedropperIcon color={disabled ? 'rgba(0, 0, 0, 0.38)' : 'rgba(0, 0, 0, 0.87)'} />
          </IconButton>
        </span>
      </Tooltip>
      <Popover
        id={'color-picker-popper'}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        <div className={classes.colorPickerContainer}>
          <SketchPicker
            styles={sketchPickerStyles}
            presetColors={[]}
            onChange={color => {
              handleOnChange(setColor, onChange, color)
            }}
            color={value}
          />
          {environmentColors && environmentColors.length > 0 && (
            <>
              <Divider className={classes.divider} />
              <PresetColorSelector
                title={t('environment.colors')}
                onChange={color => {
                  setColor(color)
                  onChange(color)
                }}
                colors={environmentColors || []}
                value={value}
              />
            </>
          )}
          {parentEnvironmentColors && parentEnvironmentColors.length > 0 && (
            <>
              <Divider className={classes.divider} />
              <PresetColorSelector
                title={t('environment.parentColors')}
                onChange={color => {
                  setColor(color)
                  onChange(color)
                }}
                colors={parentEnvironmentColors}
                value={value}
              />
            </>
          )}

          <Divider className={classes.divider} />
          <PresetColorSelector
            title={t('environment.defaultColors')}
            onChange={color => {
              setColor(color)
              onChange(color)
            }}
            colors={presetColors}
            value={value}
          />

          {selectedColor && (
            <Button
              fullWidth
              className={classes.clearButton}
              variant='outlined'
              onClick={clearColor}>
              {t('general.clear')}
            </Button>
          )}
        </div>
      </Popover>
    </Fragment>
  )
}

export default ContentColorPicker
