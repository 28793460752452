import { createSelector } from 'reselect'
import { StateInterface } from '../types/states'

const selectUsersDomain = (state: StateInterface) => state.users
const selectUser = createSelector(selectUsersDomain, users => users.user)
const selectUserIsLoadingSession = createSelector(
  selectUsersDomain,
  users => users.isLoadingSession
)
const selectUserRole = createSelector(selectUser, user => user?.role)
const selectUserSub = createSelector(selectUser, user => user?.sub)
const selectUserSubscriptions = createSelector(selectUser, user => user?.subscriptions)
const selectUserAttributes = createSelector(selectUsersDomain, users => users?.user?.attributes)
const selectUserEmail = createSelector(selectUserAttributes, attributes => attributes?.email)
const selectUserSocialMedia = createSelector(selectUser, user => user?.socialMedia)
const selectUserEmailVerified = createSelector(
  selectUserAttributes,
  attributes => attributes && attributes.email_verified
)

const selectUserSocialMediaAccounts = createSelector(
  selectUserAttributes,
  attributes => attributes?.socialMediaAccounts
)

const selectUserIsAuthenticated = createSelector(selectUsersDomain, userDomain =>
  userDomain?.isAuthenticated ? userDomain.isAuthenticated : false
)
const selectIsSignupDone = createSelector(selectUsersDomain, userDomain => userDomain?.isSignupDone)
const selectDevice = createSelector(selectUsersDomain, users => users.device)
const selectIsUserDeviceMobile = createSelector(selectDevice, device => device.isMobile)

const selectPreviewSession = createSelector(selectUsersDomain, users => users.previewSession)

const selectUserName = createSelector(selectUserAttributes, attributes =>
  attributes ? attributes.name : ''
)

const selectUserLastLogin = createSelector(selectUser, user => (user ? user.lastLogin : ''))
const selectFailedUnauthenticatedPath = createSelector(
  selectUsersDomain,
  userDomain => userDomain.failedUnauthenticatedPath
)

const isUserSystemAdmin = createSelector(selectUser, user => user?.role === 'admin')

/** is user a system admin or environment admin */
const isUserAdmin = createSelector(
  selectUser,
  user => user?.role === 'admin' || user?.environmentRole === 'admin'
)

const isDownloadSubscribersToNewsLetterListLoading = createSelector(
  selectUsersDomain,
  userState => userState.downloadSubscribersToNewsLetterListLoading || false
)
const selectLanguage = createSelector(selectUsersDomain, usersDomain => usersDomain.language)

export {
  selectUsersDomain,
  selectUser,
  selectUserIsLoadingSession,
  selectUserSub,
  selectUserRole,
  selectUserAttributes,
  selectUserEmail,
  selectUserEmailVerified,
  selectUserIsAuthenticated,
  selectIsSignupDone,
  selectIsUserDeviceMobile,
  selectPreviewSession,
  selectUserName,
  selectUserLastLogin,
  isDownloadSubscribersToNewsLetterListLoading,
  isUserSystemAdmin,
  isUserAdmin,
  selectFailedUnauthenticatedPath,
  selectUserSocialMediaAccounts,
  selectUserSubscriptions,
  selectLanguage,
  selectUserSocialMedia
}
