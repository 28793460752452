import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactJoyride, { CallBackProps, STATUS } from 'react-joyride'
import { useDispatch, useSelector } from 'react-redux'
import { clearTour, setTourStatus } from '../../actions/tours'
import { selectCurrentTour } from '../../selectors/tours'
import colors from '../../styles/common/colors'
import { getTourProperties, getLocaleTranslations } from '../../utils/tours'

const Tours = () => {
  const currentTour = useSelector(selectCurrentTour)
  const [t] = useTranslation()
  const dispatch = useDispatch()

  const joyrideCallback = (data: CallBackProps) => {
    // const { action, index, type, status } = data
    if (currentTour) {
      dispatch(setTourStatus({ tour: currentTour, status: data.status }))
    }
    if (data.status === STATUS.READY) {
      dispatch(clearTour())
    }
  }

  const run = currentTour !== undefined // TODO get correct steps and translation
  const tourProperties = getTourProperties(currentTour, t)
  return (
    <ReactJoyride
      run={run}
      callback={joyrideCallback}
      locale={getLocaleTranslations(t)}
      // without custom zIndex, dialog does not show over drawer
      styles={{ options: { zIndex: 9999, primaryColor: colors.seesignageColor } }}
      {...tourProperties}
    />
  )
}

export default Tours
