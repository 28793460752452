import { Button } from '@mui/material'
import { EMMiFile } from '@seesignage/seesignage-utils'
import React from 'react'

const getOptions = (addEMMiMedia: (files: EMMiFile[]) => void, url: string) => ({
  // Required. Called when a user selects an item in the EMMiPicker.
  success: function(files: EMMiFile[]) {
    addEMMiMedia(files)
  },

  // Optional. Called when the user closes the dialog without selecting a file
  // and does not include any parameters.
  // cancel: function() {},

  // Required. EMMi url, can be like:
  // root (https://customer.emmi.fi)
  // public folder link (https://customer.emmi.fi/l/6r5HFJvFhJDN)
  url,

  // Optional.
  // downloadurl and filename will be returned only if conversionid is provided.
  conversionid: 1,

  // Optional. A value of false (default) limits selection to a single file, while
  // true enables multiple file selection.
  multiselect: true // or true

  // Optional.
  // token: 'VfL_2RbPgWcSJ8qWQdxt'
  //
})

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return
  }

  const script = document.createElement('script')
  script.setAttribute('async', '')
  script.setAttribute('id', id)
  script.src = src
  position.appendChild(script)
}

interface EMMiFilePickerProps {
  addEMMiMedia: (files: EMMiFile[]) => void
  url: string
}

const EMMIFilePicker: React.FC<EMMiFilePickerProps> = ({ addEMMiMedia, url }) => {
  const loaded = React.useRef(false)
  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#emmi')) {
      loadScript('https://static.emmi.fi/EMMiPicker.js', document.querySelector('head'), 'emmi')
    }

    loaded.current = true
  }
  return (
    <div>
      <Button
        style={{ marginLeft: 8 }}
        variant='contained'
        onClick={() => (window as any).EMMiPicker.choose(getOptions(addEMMiMedia, url))}>
        EMMi
      </Button>
    </div>
  )
}

export default EMMIFilePicker
