import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import Grid from '@mui/material/Grid'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { reduxForm, InjectedFormProps } from 'redux-form'
import OnOffTimerField from '../../../Screens/Forms/Fields/OnOffTimerField'
import { validateOnOffTimers } from '../../../../validation'
import { UpdateScreenFromDashboard } from '../../../../types/actions'
import ErrorMessage from '../../../../components/Errors/ErrorMessage'

const useStyles = makeStyles(theme => ({
  formContainer: {
    marginBottom: theme.spacing()
  }
}))

interface OwnProps {
  submitAction: UpdateScreenFromDashboard
}

type ComponentProps = OwnProps

type CreateScreenFormProps = ComponentProps

const ScreenFormDashboard: React.FC<CreateScreenFormProps &
  InjectedFormProps<{}, ComponentProps>> = ({ handleSubmit, submitting, submitAction, error }) => {
  const [t] = useTranslation()
  const classes = useStyles()
  return (
    <form onSubmit={handleSubmit(submitAction)}>
      <Grid container spacing={2} className={classes.formContainer}>
        <Grid item xs={12}>
          <OnOffTimerField t={t} classes={classes} />
        </Grid>
        <Grid item xs={12}>
          <ErrorMessage message={error} />
        </Grid>
        <Grid item xs={12}>
          <DialogActions>
            <Button disabled={submitting} color='primary' type='submit'>
              {t('general.save')}
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </form>
  )
}

const validate = (values: any) => {
  const errors: any = {}
  return validateOnOffTimers(errors, values)
}

export default reduxForm<{}, ComponentProps>({
  form: 'ScreenFormDashboard',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  validate
})(ScreenFormDashboard)
