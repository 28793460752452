import React from 'react'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import { InputAdornment } from '@mui/material'
import CheckboxBlankCircleIcon from 'mdi-react/CheckboxBlankCircleIcon'
import { TFunction } from 'i18next'
import ReduxTextFieldWithDragChange from '../../../../../components/FormInput/ReduxWrappers/ReduxTextFieldWithDragChange'
import ContentColorPicker from '../../../../../components/FormInput/ColorPicker/ContentColorPicker'
import ContentToolbarItem from '../ContentToolbarItem/ContentToolbarItem'

interface FreeDrawingSettingsProps {
  t: TFunction
}

const FreeDrawingSettings = ({
  t
}: FreeDrawingSettingsProps & InjectedFormProps<{}, FreeDrawingSettingsProps>) => {
  return (
    <>
      <ContentToolbarItem>
        <Field
          name='color'
          component={ContentColorPicker}
          tooltip={t('contents.properties.brushColor')}
        />
      </ContentToolbarItem>
      <ContentToolbarItem tooltip={t('contents.properties.brushWidth')}>
        <Field
          name='width'
          isToolbar
          fixedSmallWidth
          component={ReduxTextFieldWithDragChange}
          DragIcon={CheckboxBlankCircleIcon}
          InputProps={{
            inputProps: { min: 1, max: 300 },
            endAdornment: <InputAdornment position='end'>px</InputAdornment>
          }}
          label={t('contents.properties.brushWidth')}
        />
      </ContentToolbarItem>
    </>
  )
}

export default reduxForm<{}, FreeDrawingSettingsProps>({
  form: 'FreeDrawingSettingsForm'
})(FreeDrawingSettings)
