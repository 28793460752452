import { DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import DeleteIcon from 'mdi-react/DeleteIcon'
import React, { Fragment } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { CloseDialog } from '../../../../types/actions'
import Dialog from '../../../Dialog'

const styles = () => ({
  actions: {
    display: 'flex'
  }
})

interface OwnProps {
  deleteLunchListWeek: (weekIndex: number) => void
  closeDialog: CloseDialog
  weekIndex: number
}

type WeekActionsProps = OwnProps & WithStyles<typeof styles> & WithTranslation

const WeekActions: React.FC<WeekActionsProps> = ({
  t,
  deleteLunchListWeek,
  closeDialog,
  classes,
  weekIndex
}) => (
  <div className={classes.actions}>
    <Dialog
      dialogId={`DeleteWeekDialog.${weekIndex}`}
      title={t('lists.lunch.edit.deleteWeek')}
      ButtonIcon={DeleteIcon}
      tooltipTitle={t('lists.lunch.edit.deleteWeek')}
      Content={
        <Fragment>
          <DialogContent>
            <DialogContentText>{t('lists.lunch.edit.deleteWeekConfirm')}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => closeDialog('DeleteWeekDialog')} color='primary'>
              {t('general.disagree')}
            </Button>
            <Button onClick={() => deleteLunchListWeek(weekIndex)} color='primary' autoFocus>
              {t('general.agree')}
            </Button>
          </DialogActions>
        </Fragment>
      }
    />
  </div>
)

export default withStyles(styles)(withTranslation()(WeekActions))
