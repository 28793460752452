import { handleActions } from 'redux-actions'
import {
  PlayerSession,
  Permissions,
  EnvironmentUserRole,
  Subscriptions,
  Language,
  UserSocialMedia,
  UserSocialMediaAccountType
} from '@seesignage/seesignage-utils'
import {
  initializeSession,
  initializeSessionSuccess,
  initializeSessionFail,
  loginFail,
  logoutSuccess,
  signupSuccess,
  signupFail,
  updateUserInfoSuccess,
  forgotPasswordSubmitFail,
  updatePermissions,
  setDeviceType,
  createPreviewSessionSuccess,
  addSocialMediaAccountSuccess,
  deleteSocialMediaAccountSuccess,
  subscribeUserToNewsLetterSuccess,
  downloadSubscribersToNewsLetterList,
  downloadSubscribersToNewsLetterListFail,
  downloadSubscribersToNewsLetterListSuccess,
  changeLanguageSuccess
} from '../actions/users'
import { UsersState } from '../types/states'
import { Action } from '../types/actions'
import { UserInterface } from '../types/users'
import { getLanguageFromLocalStorage } from '../utils/language'

const initialState: UsersState = {
  isLoadingSession: false,
  user: undefined,
  device: {
    isMobile: false
  },
  previewSession: undefined,
  language: getLanguageFromLocalStorage()
}

const users = handleActions<UsersState, any>(
  {
    [initializeSession]: state => ({
      ...state,
      isLoadingSession: true
    }),
    [initializeSessionFail]: (state, { payload: failedUnauthenticatedPath }: Action<string>) => ({
      ...state,
      isLoadingSession: false,
      user: undefined,
      failedUnauthenticatedPath
    }),
    [initializeSessionSuccess]: (state, { payload: user }: Action<UserInterface>) => ({
      ...state,
      failedUnauthenticatedPath: undefined,
      isLoadingSession: false,
      isAuthenticated: true,
      user
    }),
    [logoutSuccess]: state => ({
      ...state,
      user: undefined,
      failedUnauthenticatedPath: undefined,
      isAuthenticated: false
    }),
    [signupSuccess]: state => ({
      ...state,
      isSignupDone: true
    }),
    [loginFail]: state => ({
      ...state,
      isAuthenticated: false,
      user: undefined
    }),
    [signupFail]: state => ({
      ...state,
      isAuthenticated: false,
      user: undefined
    }),
    [updateUserInfoSuccess]: (state, { payload: user }: Action<UserInterface>) => ({
      ...state,
      user
    }),
    [subscribeUserToNewsLetterSuccess]: (
      state,
      { payload: subscriptions }: Action<Subscriptions>
    ) => ({
      ...state,
      user: {
        ...(state.user as UserInterface),
        subscriptions
      }
    }),
    [downloadSubscribersToNewsLetterList]: state => ({
      ...state,
      downloadSubscribersToNewsLetterListLoading: true
    }),
    [downloadSubscribersToNewsLetterListFail]: state => ({
      ...state,
      downloadSubscribersToNewsLetterListLoading: false
    }),
    [downloadSubscribersToNewsLetterListSuccess]: state => ({
      ...state,
      downloadSubscribersToNewsLetterListLoading: false
    }),
    [forgotPasswordSubmitFail]: state => ({
      ...state,
      isAuthenticated: false
    }),
    [updatePermissions]: (
      state,
      {
        payload: { permissions, role }
      }: Action<{
        permissions?: Permissions
        role?: EnvironmentUserRole
      }>
    ) => ({
      ...state,
      user: {
        ...(state.user as UserInterface),
        permissions,
        environmentRole: role
      }
    }),
    [setDeviceType]: (state, { payload: isMobile }: Action<boolean>) => ({
      ...state,
      device: {
        isMobile
      }
    }),
    [createPreviewSessionSuccess]: (state, { payload: previewSession }: Action<PlayerSession>) => ({
      ...state,
      previewSession
    }),
    [addSocialMediaAccountSuccess]: (
      state,
      { payload: updatedSocialMediaData }: Action<UserSocialMedia>
    ) => ({
      ...state,
      user: {
        ...(state.user as UserInterface),
        socialMedia: updatedSocialMediaData
      }
    }),
    [deleteSocialMediaAccountSuccess]: (
      state,
      { payload: { platform, id } }: Action<UserSocialMediaAccountType>
    ) => {
      const socialMediaPlatform = { ...state.user?.socialMedia?.[platform] }
      delete socialMediaPlatform?.[id]
      return {
        ...state,
        user: {
          ...(state.user as UserInterface),
          socialMedia: { ...state.user?.socialMedia, [platform]: socialMediaPlatform }
        }
      }
    },
    [changeLanguageSuccess]: (state, { payload: language }: Action<Language>) => ({
      ...state,
      language
    })
  },
  initialState
)

export default users
