import React from 'react'
import { WrappedFieldInputProps, WrappedFieldProps, WrappedFieldMetaProps } from 'redux-form'
import { FileWithPreview } from '../../../types/media'
import FileThumbnail from '../FilesInput/FileThumbnail'

interface InputProps extends WrappedFieldInputProps {
  value: FileWithPreview
}

interface MetaProps extends WrappedFieldMetaProps {
  /** errors from validateAddMediaForm function */
  error?: string[]
}

interface FileThumbnailFieldProps extends WrappedFieldProps {
  input: InputProps
  meta: MetaProps
  removeItem: () => void
}

const FileThumbnailField: React.FC<FileThumbnailFieldProps> = ({
  input: { value: file },
  removeItem,
  meta: { error: errors }
}) => <FileThumbnail file={file} removeItem={removeItem} errors={errors} />

export default FileThumbnailField
