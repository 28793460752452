import { SubmissionError } from 'redux-form'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux-actions'
import { toast } from 'react-toastify'
import {
  LunchGroup,
  LunchProduct,
  LunchList,
  UpdateLunchListItemsBody,
  CreateLunchProductBody,
  LunchListWeekDay,
  LunchItem,
  CreateLunchGroupBody,
  ProductChangeForDay,
  WeekChanges,
  LunchListWeek
} from '@seesignage/seesignage-utils'
import nanoid from 'nanoid'
import { clone, isEmpty } from 'ramda'
import { closeDialog } from '../actions/dialogs'
import {
  deleteLunchListItem,
  deleteLunchListItemFail,
  deleteLunchListItemSuccess,
  deleteLunchListGroupOrProductSuccess,
  createOrUpdateLunchListGroup,
  createOrUpdateLunchListProduct,
  createOrUpdateLunchListGroupSuccess,
  createOrUpdateLunchListGroupFail,
  createOrUpdateLunchListProductSuccess,
  createOrUpdateLunchListProductFail,
  addLunchListWeek,
  addLunchListWeekFail,
  addLunchListWeekSuccess,
  deleteLunchListWeek,
  deleteLunchListWeekSuccess,
  deleteLunchListWeekFail,
  updateLunchListItems,
  updateLunchListItemsFail,
  updateLunchListItemsSuccess,
  reorderLunchListItem,
  reorderLunchListItemSuccess,
  reorderLunchListItemFail,
  publishLunchList,
  publishLunchListFail,
  publishLunchListSuccess,
  navigateToLunchList,
  navigateToLunchListSuccess,
  navigateToLunchListFail,
  addLunchListProductChanges,
  addLunchListProductChangesSuccess,
  addLunchListProductChangesFail,
  getLunchListContent,
  getLunchListContentFail,
  getLunchListContentSuccess
} from '../actions/lists'

import { selectContentIdFromPathname, selectEnvironmentIdFromPathname } from '../selectors/routing'

import Api from '../services/api/lists'

import i18n from '../translations/i18n'
import {
  handleCreateLunchGroupParams,
  handleCreateLunchProductParams,
  handleAddLunchListProductChangesParams,
  handleAddLunchListWeekParams
} from '../types/formData'
import { SelectedLunchItemData, SelectedLunchItemType } from '../types/lists'
import { selectListById, selectCurrentList, selectLunchListWeekChanges } from '../selectors/lists'
import {
  ReorderLunchItemParams,
  NavigateToLunchListParams,
  GetLunchListContentParams
} from '../types/actions'
import { reorder } from '../utils/arrays'
import { generateEmptyLunchListWeekDays } from '../utils/lunchLists'
import { navigate } from '../actions/routes'

/**
 * Delete lunch group or product.
 * NOTE: deletes group or product from DB only if root level item.
 * Weekday items are only removed from state.
 */
export function* handleDeleteLunchListItem({
  payload: selectedItem
}: Action<SelectedLunchItemData>) {
  try {
    const { environmentId, listId, weeks }: LunchList = yield select(selectCurrentList)
    if (weeks) {
      const { weekIndex, day } = selectedItem
      if (selectedItem.type === SelectedLunchItemType.group) {
        // deleting lunch item from groups. Backend also removes it from weeks.
        const list: LunchList = yield call(
          Api.deleteLunchListGroup,
          environmentId,
          listId,
          (selectedItem.item as LunchGroup).groupId
        )
        toast.info(i18n.t('lists.lunch.notifications.groupDeleted'))
        yield put(deleteLunchListGroupOrProductSuccess(list))
      } else if (selectedItem.type === SelectedLunchItemType.product) {
        // deleting lunch item from products. Backend also removes it from weeks.
        const list: LunchList = yield call(
          Api.deleteLunchListProduct,
          environmentId,
          listId,
          (selectedItem.item as LunchProduct).productId
        )
        yield put(deleteLunchListGroupOrProductSuccess(list))
        toast.info(i18n.t('lists.lunch.notifications.productDeleted'))
      } else if (weekIndex !== undefined && day !== undefined) {
        // removing lunch item from weeks. Keep track of changes
        const { weekId } = weeks[weekIndex]
        const currentWeekChanges: WeekChanges = yield select(selectLunchListWeekChanges)
        let newWeekChanges = { ...currentWeekChanges }
        const destinationWeekUpdatedDays = newWeekChanges[weekId]?.updated || []

        newWeekChanges = {
          ...newWeekChanges,
          [weekId]: {
            ...newWeekChanges[weekId],
            // prevent duplicates
            updated: [...new Set([...destinationWeekUpdatedDays, day])]
          }
        }
        // deleting lunch item inside weeks
        yield put(
          deleteLunchListItemSuccess({ item: selectedItem, listId, weekChanges: newWeekChanges })
        )
      }
    }
    yield put(closeDialog())
  } catch (error) {
    yield put(deleteLunchListItemFail(error.message))
  }
}

export function* handleDeleteLunchListWeek({ payload: weekIndex }: Action<number>) {
  try {
    const { listId, weeks }: LunchList = yield select(selectCurrentList)
    if (weeks) {
      const week = weeks[weekIndex]
      const currentWeekChanges: WeekChanges = yield select(selectLunchListWeekChanges)
      const newWeekChanges: WeekChanges = {
        ...currentWeekChanges,
        [week.weekId]: {
          deleted: true
        }
      }

      yield put(deleteLunchListWeekSuccess({ weekIndex, listId, weekChanges: newWeekChanges }))
      yield put(closeDialog())
    }
  } catch (error) {
    yield put(deleteLunchListWeekFail(error.message))
  }
}

export function* handleCreateOrUpdateLunchListGroup({
  payload: { formData, resolve, reject }
}: handleCreateLunchGroupParams) {
  try {
    const environmentId: string | undefined = yield select(selectEnvironmentIdFromPathname)
    const listId: string | undefined = yield select(selectContentIdFromPathname)
    if (environmentId && listId) {
      const { name, price, groupId } = formData
      const groupBody: CreateLunchGroupBody = {
        name,
        price
      }
      const group: LunchGroup = groupId
        ? yield call(Api.updateLunchListGroup, environmentId, listId, groupId, groupBody)
        : yield call(Api.createLunchListGroup, environmentId, listId, groupBody)

      if (groupId) {
        toast.info(i18n.t('lists.lunch.notifications.groupUpdated'))
      } else {
        toast.info(i18n.t('lists.lunch.notifications.groupCreated'))
      }

      yield put(closeDialog())
      yield put(createOrUpdateLunchListGroupSuccess({ listId, group }))
      resolve()
    }
  } catch (error) {
    yield put(createOrUpdateLunchListGroupFail(error.message))
    if (error instanceof SubmissionError) {
      yield call(reject, error)
    }
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.list.lunch.createOrUpdateLunchGroup')
      })
    )
  }
}

export function* handleCreateOrUpdateLunchListProduct({
  payload: { formData, resolve, reject }
}: handleCreateLunchProductParams) {
  try {
    const environmentId: string | undefined = yield select(selectEnvironmentIdFromPathname)
    const listId: string | undefined = yield select(selectContentIdFromPathname)
    if (environmentId && listId) {
      const { name, specialDiet, groupId, productId } = formData
      const lunchListBody: CreateLunchProductBody = {
        name,
        specialDiet
      }
      // handle groupId value from autocomple option
      if (groupId) {
        lunchListBody.groupId = groupId.value
      }
      const product: LunchProduct = productId
        ? yield call(Api.updateLunchListProduct, environmentId, listId, productId, lunchListBody)
        : yield call(Api.createLunchListProduct, environmentId, listId, lunchListBody)
      if (productId) {
        toast.info(i18n.t('lists.lunch.notifications.productUpdated'))
      } else {
        toast.info(i18n.t('lists.lunch.notifications.productCreated'))
      }

      yield put(closeDialog())
      yield put(createOrUpdateLunchListProductSuccess({ listId, product }))
      resolve()
    }
  } catch (error) {
    yield put(createOrUpdateLunchListProductFail(error.message))
    if (error instanceof SubmissionError) {
      yield call(reject, error)
    }
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.list.lunch.createOrUpdateLunchProduct')
      })
    )
  }
}

export function* handleUpdateLunchListItems() {
  try {
    const environmentId: string | undefined = yield select(selectEnvironmentIdFromPathname)
    const listId: string | undefined = yield select(selectContentIdFromPathname)
    const list: LunchList | undefined = yield select(selectListById(listId))
    if (environmentId && listId && list) {
      const { published, weeks } = list
      const weekChanges: WeekChanges = yield select(selectLunchListWeekChanges)
      const updateLunchListItemsBody: UpdateLunchListItemsBody = {
        published,
        weeks: weeks || [],
        weekChanges: weekChanges
      }
      const updatedList: LunchList = yield call(
        Api.updateLunchListItems,
        environmentId,
        listId,
        updateLunchListItemsBody
      )
      yield put(updateLunchListItemsSuccess(updatedList))
      toast.info(i18n.t('lists.lunch.notifications.lunchItemsUpdated'))
    }
  } catch (error) {
    yield put(updateLunchListItemsFail(error.message))
    toast.error(i18n.t('error.list.lunch.updateLunchItems'))
  }
}

export function* handleAddLunchListWeek({
  payload: {
    formData: { weekdays },
    resolve,
    reject
  }
}: handleAddLunchListWeekParams) {
  try {
    const listId: string | undefined = yield select(selectContentIdFromPathname)
    if (listId) {
      const currentWeekChanges: WeekChanges = yield select(selectLunchListWeekChanges)
      const week: LunchListWeek = {
        days: generateEmptyLunchListWeekDays(weekdays),
        weekId: nanoid()
      }

      const newWeekChanges: WeekChanges = {
        ...currentWeekChanges,
        [week.weekId]: {
          added: true
        }
      }

      yield put(addLunchListWeekSuccess({ listId, week, weekChanges: newWeekChanges }))
      yield put(closeDialog())
    }
    resolve()
  } catch (error) {
    yield put(addLunchListWeekFail(error.message))
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.list.lunch.addLunchListWeek')
      })
    )
  }
}

function* handleReorderLunchListItem({
  payload: { sourceId, sourceIndex, destinationId, destinationIndex, productId }
}: Action<ReorderLunchItemParams>) {
  try {
    const list: LunchList | undefined = yield select(selectCurrentList)
    if (list) {
      const currentWeekChanges: WeekChanges = yield select(selectLunchListWeekChanges)
      let newWeekChanges = { ...currentWeekChanges }
      const { listId } = list

      // TODO improve types

      // source
      const splittedSourceId = sourceId.split('.')
      // sourceWeekIndex undefined if item comes from products list
      const sourceWeekIndex = splittedSourceId[2] ? Number(splittedSourceId[2]) : undefined
      const sourceWeekDay = splittedSourceId[4] as LunchListWeekDay

      // destination
      const splittedDestinationId = destinationId.split('.')
      const destionationWeekIndex = Number(splittedDestinationId[2])
      const destionationWeekDay = splittedDestinationId[4] as LunchListWeekDay

      const weeks = list.weeks ? [...list.weeks] : []

      const sourceWeekId = sourceWeekIndex !== undefined ? weeks[sourceWeekIndex].weekId : undefined
      const destinationWeekId = weeks[destionationWeekIndex].weekId

      // track day changes
      const sourceWeekUpdatedDays = sourceWeekId ? newWeekChanges[sourceWeekId]?.updated || [] : []
      const destinationWeekUpdatedDays = newWeekChanges[destinationWeekId]?.updated || []

      // CASE 1: product dragged from products list. No need to remove source.
      if (sourceId === 'droppableProductsList' && productId) {
        const newLunchItem: LunchItem = {
          itemId: nanoid(),
          productId
        }

        weeks[destionationWeekIndex].days[destionationWeekDay]?.lunchItems.splice(
          destinationIndex,
          0,
          newLunchItem
        )

        // ONLY destination week changes
        newWeekChanges = {
          ...newWeekChanges,
          [destinationWeekId]: {
            ...newWeekChanges[destinationWeekId],
            // prevent duplicates
            updated: [...new Set([...destinationWeekUpdatedDays, destionationWeekDay])]
          }
        }
      } else {
        // CASE 2: product dragged inside weeks.
        // moving to same list. Same week and day.
        if (
          sourceWeekIndex === destionationWeekIndex &&
          sourceWeekDay === destionationWeekDay &&
          sourceIndex !== destinationIndex
        ) {
          const itemsToReorder = weeks[destionationWeekIndex].days[destionationWeekDay]?.lunchItems
          if (itemsToReorder) {
            const lunchDay = weeks[destionationWeekIndex].days[destionationWeekDay]
            if (lunchDay) {
              lunchDay.lunchItems = reorder(itemsToReorder, sourceIndex, destinationIndex)

              // UPDATE CHANGES: ONLY destination week changes
              newWeekChanges = {
                ...newWeekChanges,
                [destinationWeekId]: {
                  ...newWeekChanges[destinationWeekId],
                  // prevent duplicates
                  updated: [...new Set([...destinationWeekUpdatedDays, destionationWeekDay])]
                }
              }
            }
          }
        } else if (sourceWeekIndex !== undefined) {
          // moving to different list.
          const sourceLunchItem =
            weeks[sourceWeekIndex].days[sourceWeekDay]?.lunchItems[sourceIndex]

          if (sourceLunchItem) {
            const targetLunchItem = clone(sourceLunchItem)
            // remove from original
            weeks[sourceWeekIndex].days[sourceWeekDay]?.lunchItems.splice(sourceIndex, 1)
            // insert into next
            weeks[destionationWeekIndex].days[destionationWeekDay]?.lunchItems.splice(
              destinationIndex,
              0,
              targetLunchItem
            )

            // UPDATE CHANGES: MOVING INSIDE SAME WEEK'S LISTS
            if (sourceWeekId === destinationWeekId) {
              newWeekChanges = {
                ...newWeekChanges,
                [destinationWeekId]: {
                  ...newWeekChanges[destinationWeekId],
                  // prevent duplicates
                  updated: [
                    // source and destination day changes
                    ...new Set([...destinationWeekUpdatedDays, sourceWeekDay, destionationWeekDay])
                  ]
                }
              }
            } else {
              // UPDATE CHANGES: MOVING FROM OTHER WEEK
              if (sourceWeekId) {
                newWeekChanges = {
                  ...newWeekChanges,
                  [sourceWeekId]: {
                    ...newWeekChanges[sourceWeekId],
                    // prevent duplicates
                    updated: [
                      // source day changes
                      ...new Set([...sourceWeekUpdatedDays, sourceWeekDay])
                    ]
                  },
                  [destinationWeekId]: {
                    ...newWeekChanges[destinationWeekId],
                    // prevent duplicates
                    updated: [
                      // destination day changes
                      ...new Set([...destinationWeekUpdatedDays, destionationWeekDay])
                    ]
                  }
                }
              }
            }
          }
        }
      }
      yield put(reorderLunchListItemSuccess({ listId, weeks, weekChanges: newWeekChanges }))
    }
  } catch (error) {
    yield put(reorderLunchListItemFail(error.message))
  }
}

/**
 * Toggle lunch list published status
 */
function* handlePublishLunchList() {
  try {
    const { environmentId, listId, weeks, published }: LunchList = yield select(selectCurrentList)
    const updateLunchListItemsBody: UpdateLunchListItemsBody = {
      published: !published,
      weeks: weeks || [],
      weekChanges: {}
    }
    const updatedList: LunchList = yield call(
      Api.updateLunchListItems,
      environmentId,
      listId,
      updateLunchListItemsBody
    )
    yield put(publishLunchListSuccess(updatedList))
    yield put(closeDialog())
  } catch (error) {
    yield put(publishLunchListFail(error.message))
  }
}

function* handleNavigateToLunchList({
  payload: { environmentId, listId }
}: Action<NavigateToLunchListParams>) {
  try {
    const pathEnvironmentId: string = yield select(selectEnvironmentIdFromPathname)
    // when current environment and list environment is diffrent, create sub list
    if (environmentId !== pathEnvironmentId) {
      const list: LunchList | undefined = yield select(selectListById(listId))
      if (list) {
        const { environmentId: parentEnvironmentId } = list
        const newList: LunchList = yield call(
          Api.initializeSubLunchList,
          pathEnvironmentId,
          listId,
          parentEnvironmentId
        )
        yield put(navigateToLunchListSuccess(newList))
        yield put(navigate(`/environments/${pathEnvironmentId}/lunchLists/${newList.listId}`))
      }
    } else {
      yield put(navigateToLunchListSuccess())
      yield put(navigate(`/environments/${pathEnvironmentId}/lunchLists/${listId}`))
    }
  } catch (error) {
    yield put(navigateToLunchListFail(error.message))
  }
}

function* handleAddLunchListProductChanges({
  payload: { formData, resolve, reject }
}: handleAddLunchListProductChangesParams) {
  try {
    const { listId, weeks }: LunchList = yield select(selectCurrentList)
    const { weekIndex, day, existingProductId, temporaryProductId, isHidden } = formData
    const weeksToUpdate = weeks ? weeks : []

    const productChangeForDay: ProductChangeForDay = {}

    const productId = temporaryProductId?.value
    if (productId && productId !== 'none') {
      productChangeForDay.productId = productId
    }

    // cannot be hidden and sametime other product
    if (isHidden) {
      delete productChangeForDay.productId
      productChangeForDay.isHidden = true
    }

    const currentWeekday = weeksToUpdate[weekIndex].days[day]
    if (currentWeekday) {
      if (
        isEmpty(productChangeForDay) &&
        currentWeekday.changes &&
        currentWeekday.changes[existingProductId]
      ) {
        // remove existing changes if no changes are given and change exists
        delete currentWeekday.changes[existingProductId]
        // if changes becomes empty object remove it
        if (isEmpty(currentWeekday.changes)) {
          delete currentWeekday.changes
        }
      } else {
        currentWeekday.changes = {
          ...weeksToUpdate[weekIndex].days[day]?.changes,
          [existingProductId]: productChangeForDay
        }
      }
    }

    yield put(
      addLunchListProductChangesSuccess({
        listId,
        weeks: weeksToUpdate
      })
    )
    resolve()
    yield put(closeDialog())
  } catch (error) {
    yield put(addLunchListProductChangesFail(error.message))
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.list.lunch.addLunchListProductChanges')
      })
    )
  }
}

export function* handleGetLunchListContent({
  payload: { environmentId, listId, content }
}: Action<GetLunchListContentParams>) {
  try {
    const list: LunchList = yield call(Api.getList, environmentId, listId)
    toast.info(i18n.t(`lists.lunch.notifications.contentUpdated.${content}`))
    yield put(getLunchListContentSuccess({ list, content }))
  } catch (error) {
    yield put(getLunchListContentFail(error.message))
  }
}

function* watchLunchListsActions() {
  yield all([
    takeLatest(createOrUpdateLunchListGroup, handleCreateOrUpdateLunchListGroup),
    takeLatest(createOrUpdateLunchListProduct, handleCreateOrUpdateLunchListProduct),
    takeLatest(updateLunchListItems, handleUpdateLunchListItems),
    takeLatest(addLunchListWeek, handleAddLunchListWeek),
    takeLatest(deleteLunchListItem, handleDeleteLunchListItem),
    takeLatest(deleteLunchListWeek, handleDeleteLunchListWeek),
    takeLatest(reorderLunchListItem, handleReorderLunchListItem),
    takeLatest(publishLunchList, handlePublishLunchList),
    takeLatest(navigateToLunchList, handleNavigateToLunchList),
    takeLatest(addLunchListProductChanges, handleAddLunchListProductChanges),
    takeLatest(getLunchListContent, handleGetLunchListContent)
  ])
}

export default [watchLunchListsActions]
