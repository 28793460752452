import React from 'react'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
// @ts-ignore
import countryTelData from 'country-telephone-data'
import { useTranslation } from 'react-i18next'
import { required, email, password, passwordsMatch, phoneNumber } from '../../../validation'
import {
  ReduxTextField,
  ReduxSelectWithAutocomplete
} from '../../../components/FormInput/ReduxWrappers'
import { SignupFormData } from '../../../types/users'
import { parseEmailField } from '../../../utils/forms'
import ErrorMessage from '../../../components/Errors/ErrorMessage'

interface CountryTelData {
  name: string
  dialCode: string
}

const getDialingCodes = () =>
  countryTelData.allCountries.map(({ name, dialCode }: CountryTelData) => ({
    value: `+${dialCode}`,
    label: `${name} - ${dialCode}`
  }))

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginTop: theme.spacing(2)
  },
  autocomplete: {
    marginTop: theme.spacing(2)
  }
}))

type FormProps = SignupFormData

interface OwnProps {
  signupUser: (formData: any) => void
}

type SignupFormProps = OwnProps

const SignupForm = ({
  handleSubmit,
  submitting,
  signupUser,
  error
}: SignupFormProps & InjectedFormProps<FormProps, OwnProps>) => {
  const classes = useStyles()
  const [t] = useTranslation()
  return (
    <form onSubmit={handleSubmit(signupUser)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Field
            fullWidth
            name='name'
            label={t('signup.fullName')}
            component={ReduxTextField}
            required
            validate={[required]}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.autocomplete}>
            <Field
              name='dialingCode'
              placeholder={t('signup.dialingCode')}
              label={t('signup.dialingCode')}
              options={getDialingCodes()}
              component={ReduxSelectWithAutocomplete}
              required
              validate={[required]}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            fullWidth
            name='phoneNumber'
            label={t('signup.phoneNum')}
            helperText={t('signup.phoneHelper')}
            component={ReduxTextField}
            required
            validate={[required, phoneNumber]}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            fullWidth
            name='email'
            label={t('signup.email')}
            helperText={t('signup.emailHelper')}
            component={ReduxTextField}
            required
            validate={[required, email]}
            parse={parseEmailField}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            fullWidth
            name='password'
            autoComplete='new-password'
            label={t('signup.password')}
            type='password'
            helperText={t('changePw.helperText')}
            required
            validate={[required, password]}
            component={ReduxTextField}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            fullWidth
            name='confirmPassword'
            autoComplete='new-password'
            label={t('signup.confirmPw')}
            type='password'
            required
            validate={[required, password, passwordsMatch]}
            component={ReduxTextField}
          />
        </Grid>
      </Grid>
      <ErrorMessage message={error} />
      <Button
        fullWidth
        type='submit'
        variant='contained'
        color='primary'
        className={classes.button}
        disabled={submitting}>
        {t('general.submit')}
      </Button>
      {submitting && <LinearProgress />}
    </form>
  )
}

export default reduxForm<FormProps, OwnProps>({
  form: 'SignupForm'
})(SignupForm)
