import React, { Fragment } from 'react'
import { FieldArrayFieldsProps, WrappedFieldArrayProps } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { Button, Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { CampaignItem, CampaignItemType } from '@seesignage/seesignage-utils'
import nanoid from 'nanoid'
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd'
import DragIcon from 'mdi-react/DragIcon'
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon'
import { InitialCampaignItem, CampaignBatchPriceType } from '../../../../types/campaigns'
import { getItemStyle } from '../../../../components/Draggable/DraggableItems'
import ErrorMessage from '../../../../components/Errors/ErrorMessage'
import colors from '../../../../styles/common/colors'
import { CampaignItemField } from '.'

const useStyles = makeStyles(theme => ({
  buttonsContainer: {
    position: 'sticky',
    top: -3,
    width: '100%',
    textAlign: 'center',
    zIndex: 99,
    backgroundColor: '#fafafa',
    display: 'flex',
    height: 64,
    justifyContent: 'center'
  },
  button: {
    margin: theme.spacing(2)
  },
  item: {
    backgroundColor: '#fafafa',
    margin: '8px 0px 8px 0px',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    minWidth: 1040
  },
  itemsContainer: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    overflow: 'auto'
  },
  itemContainer: {
    borderLeft: `20px solid ${colors.seesignageColor}`,
    width: '95%',
    display: 'flex',
    margin: theme.spacing(1),
    padding: theme.spacing(1)
  },
  batchProductsContainer: {
    borderLeft: '20px solid orange',
    width: '100%',
    margin: theme.spacing(1),
    padding: theme.spacing(1)
  },
  dragArea: {
    cursor: 'move'
  },
  error: {
    padding: 6,
    textAlign: 'center'
  }
}))

interface CampaignItemsFieldProps extends WrappedFieldArrayProps {
  isCampaignSub: boolean
  /** Are buttons and fields disabled. True if `isCampaignSub = true` or `published = true` */
  isDisabled: boolean
  fields: FieldArrayFieldsProps<CampaignItem | InitialCampaignItem>
  setPreviewItemIndex: React.Dispatch<React.SetStateAction<number>>
  isPreviewDisabled?: boolean
}

/**
 * redux-form FieldArray component for `CampaignItem[]`
 */
const CampaignItemsField: React.FC<CampaignItemsFieldProps> = ({
  fields,
  meta: { error, submitFailed },
  isCampaignSub,
  isDisabled,
  setPreviewItemIndex,
  isPreviewDisabled
}) => {
  const [t] = useTranslation()
  const classes = useStyles()
  const onDragEnd = (reoderItems: (from: number, to: number) => void) => (result: DropResult) => {
    if (!result.destination || result.source.index === result.destination.index) {
      return
    }
    reoderItems(result.source.index, result.destination.index)
  }
  return (
    <Fragment>
      {submitFailed && error && (
        <div className={classes.error}>
          <ErrorMessage message={error} />
        </div>
      )}
      {!isCampaignSub && !isDisabled && (
        <div className={classes.buttonsContainer}>
          <Button
            id='add-campaign-product'
            className={classes.button}
            variant='contained'
            startIcon={<PlusCircleOutlineIcon />}
            onClick={() =>
              fields.push({
                itemId: nanoid(),
                type: CampaignItemType.product,
                showPercentage: false
              })
            }>
            {t('campaigns.editCampaign.addProduct')}
          </Button>
          <Button
            id='add-campaign-batch-product'
            className={classes.button}
            variant='contained'
            startIcon={<PlusCircleOutlineIcon />}
            onClick={() =>
              fields.push({
                itemId: nanoid(),
                type: CampaignItemType.batchProducts,
                batchPriceType: CampaignBatchPriceType.none,
                products: []
              })
            }>
            {t('campaigns.editCampaign.addBatch')}
          </Button>
        </div>
      )}
      {fields.length > 0 && (
        <div className={classes.itemsContainer} id='campaign-items'>
          <DragDropContext onDragEnd={onDragEnd(fields.move)}>
            <Droppable droppableId='campaignItems'>
              {({ innerRef, placeholder }) => (
                <div ref={innerRef}>
                  {fields.map((item, index) => {
                    const itemProps = {
                      classes,
                      item,
                      isCampaignSub,
                      isDisabled,
                      index,
                      removeItem: () => fields.remove(index),
                      setPreviewItemIndex,
                      isPreviewDisabled
                    }
                    return (
                      <Draggable key={item} draggableId={item} index={index}>
                        {({ innerRef, draggableProps, dragHandleProps }, { isDragging }) => (
                          <Paper
                            className={classes.item}
                            style={getItemStyle(isDragging, draggableProps.style)}
                            ref={innerRef}
                            {...draggableProps}
                            {...dragHandleProps}>
                            <div className={classes.dragArea} {...dragHandleProps}>
                              <DragIcon />
                            </div>
                            <CampaignItemField key={`${item}.${index}`} {...itemProps} />
                          </Paper>
                        )}
                      </Draggable>
                    )
                  })}
                  {placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
    </Fragment>
  )
}

export default CampaignItemsField
