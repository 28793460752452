export default {
  lunchListDefautlColor: '#1e88e5',
  notifications: {
    errorColor: '#E74C3C',
    environmentAdminColor: '#1e88e5',
    systemAdminColor: '#FFAB00'
  },
  parentLunchListColor: '#00BB99',
  parentLunchListBrokenLinkColor: '#fb8c00',
  priorities: {
    low: '#00AEEF',
    medium: '#F9CE00',
    high: '#F03020',
    emergency: '#C000F0'
  },
  seesignageColor: '#1e88e5',
  screenStatuses: {
    onlineBg: '#1F88E5',
    onlineText: '#ffffff',
    offlineBg: '#f50057',
    offlineText: '#ffffff',
    notLinkedBg: '#ffab00',
    notLinkedText: '#000000',
    standbyBg: '#265093',
    standbyText: '#ffffff'
  },
  signalColors: {
    excellent: '#4a45d8',
    good: '#45A2DB',
    satisfying: '#F6BA0B',
    bad: 'red',
    no: 'black'
  }
}

export const contentColors = {
  widgets: {
    rssFeed: {
      lightColor: '#fafafa',
      darkColor: '#050505',
      semiDarkColor: '#1c1c1c',
      white: '#ffffff'
    }
  }
}
