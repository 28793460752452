import { createSelector } from 'reselect'
import { StateInterface } from '../types/states'

const selectCampaignsDomain = (state: StateInterface) => state.campaigns

const selectCampaigns = createSelector(selectCampaignsDomain, campaigns => campaigns.campaigns)

const selectCampaignsAsArray = createSelector(selectCampaigns, campaigns =>
  Object.values(campaigns)
)

const selectCampaignById = (campaignId?: string | null) =>
  createSelector(selectCampaigns, campaigns => (campaignId ? campaigns[campaignId] : undefined))

const selectSelectedCampaign = createSelector(
  selectCampaignsDomain,
  domain => domain.selectedCampaign
)

const selectIsCampaignDeleting = createSelector(selectCampaignsDomain, domain => domain.isDeleting)
const selectIsLoadingField = createSelector(selectCampaignsDomain, domain => domain.isLoadingField)

const selectCurrentItemFieldName = createSelector(
  selectCampaignsDomain,
  domain => domain.currentItemFieldName
)

export {
  selectCampaigns,
  selectCampaignsAsArray,
  selectCampaignById,
  selectSelectedCampaign,
  selectIsCampaignDeleting,
  selectIsLoadingField,
  selectCurrentItemFieldName
}
