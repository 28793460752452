import React, { Fragment } from 'react'
import { Field, change, formValueSelector } from 'redux-form'
import AlphaXIcon from 'mdi-react/AlphaXIcon'
import AlphaYIcon from 'mdi-react/AlphaYIcon'
import { Dispatch } from 'redux'
import RotateRightIcon from 'mdi-react/RotateRightIcon'
import AlphaWIcon from 'mdi-react/AlphaWIcon'
import AlphaHIcon from 'mdi-react/AlphaHIcon'
import ArrowExpandHorizontalIcon from 'mdi-react/ArrowExpandHorizontalIcon'
import ArrowExpandVerticalIcon from 'mdi-react/ArrowExpandVerticalIcon'
import ArrowHorizontalLockIcon from 'mdi-react/ArrowHorizontalLockIcon'
import ArrowVerticalLockIcon from 'mdi-react/ArrowVerticalLockIcon'
import AxisXYArrowLockIcon from 'mdi-react/AxisXYArrowLockIcon'
import { InputAdornment, Tooltip, IconButton, Popover, Divider } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { TFunction } from 'i18next'
import { connect, useSelector } from 'react-redux'
import MenuDownIcon from 'mdi-react/MenuDownIcon'
import {
  parseNumberFieldToFloatOrZero,
  formatNumberFieldAndRoundBy1Decimal,
  formatNumberFieldAndRoundBy2Decimals,
  parseNumberFieldToIntOrZero,
  formatNumberFieldToInt
} from '../../../../../../utils/forms'
import ReduxIconCheckbox from '../../../../../../components/FormInput/ReduxWrappers/ReduxIconCheckbox'
import { selectSelectedObjects } from '../../../../../../selectors/contents'
import { StateInterface } from '../../../../../../types/states'
import colors from '../../../../../../styles/common/colors'
import ReduxTextFieldWithDragChange from '../../../../../../components/FormInput/ReduxWrappers/ReduxTextFieldWithDragChange'
import ContentToolbarItem from '../../ContentToolbarItem/ContentToolbarItem'
import {
  formatObjectPositionRelativeToWorkarea,
  parseObjectTopRelativeToWorkarea
} from '../../../../../../utils/fabric/canvasWorkarea'

const useStyles = makeStyles(() => ({
  lockContainer: {
    display: 'flex',
    border: '1px solid rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    marginLeft: '3px',
    height: '38px'
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  adornment: {
    margin: '0px 1px 0px 1px'
  },
  popoverIcon: {
    padding: 0,
    margin: 0
  }
}))
interface OwnProps {
  formName: string
  t: TFunction
  enableRotation?: boolean
  enableSize?: boolean
  enableScale?: boolean
  fieldsDisabled?: boolean
  selectedObject?: any
}

interface StateProps {
  selectedObjects?: fabric.Object[]
}

interface DispatchProps {
  changeValue: (field: string, value: any) => void
}

type CommonContentFieldsProps = OwnProps & StateProps & DispatchProps

const CommonContentFields = ({
  enableRotation,
  enableSize,
  enableScale,
  t,
  fieldsDisabled,
  changeValue,
  formName
}: CommonContentFieldsProps) => {
  const classes = useStyles()
  const [lockAnchor, setLockAnchor] = React.useState<HTMLButtonElement | null>(null)

  const lockMovementX = useSelector(state => formValueSelector(formName)(state, 'lockMovementX'))
  const lockMovementY = useSelector(state => formValueSelector(formName)(state, 'lockMovementY'))
  const someAxisLocked = lockMovementX || lockMovementY

  const handleMovementLock = () => {
    if (someAxisLocked) {
      changeValue('lockMovementX', false)
      changeValue('lockMovementY', false)
      return
    } else {
      changeValue('lockMovementX', true)
      changeValue('lockMovementY', true)
    }
  }

  return (
    <Fragment>
      <ContentToolbarItem
        tooltip={
          someAxisLocked
            ? (t('contents.actions.unlockObject') as string)
            : (t('contents.actions.lockObject') as string)
        }>
        <IconButton
          size='small'
          onClick={handleMovementLock}
          sx={{
            color: someAxisLocked ? colors.seesignageColor : 'rgba(0, 0, 0, 0.54)'
          }}>
          <AxisXYArrowLockIcon />
        </IconButton>
      </ContentToolbarItem>
      <ContentToolbarItem>
        <Divider orientation='vertical' flexItem />
      </ContentToolbarItem>
      <ContentToolbarItem tooltip={t('contents.actions.chooseAxisLock')}>
        <IconButton
          size='small'
          className={classes.icon}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => setLockAnchor(e.currentTarget)}>
          <MenuDownIcon />
        </IconButton>
      </ContentToolbarItem>
      <Popover
        id={lockAnchor ? 'lock-popover' : undefined}
        open={Boolean(lockAnchor)}
        anchorEl={lockAnchor}
        onClose={() => setLockAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Tooltip disableInteractive title={t('contents.actions.lockXAxis')}>
            <IconButton className={classes.popoverIcon} size='small'>
              <Field
                name='lockMovementX'
                Icon={ArrowHorizontalLockIcon}
                component={ReduxIconCheckbox}
              />
            </IconButton>
          </Tooltip>

          <Tooltip disableInteractive title={t('contents.actions.lockYAxis')}>
            <IconButton className={classes.popoverIcon} size='small'>
              <Field
                name='lockMovementY'
                Icon={ArrowVerticalLockIcon}
                component={ReduxIconCheckbox}
              />
            </IconButton>
          </Tooltip>
        </div>
      </Popover>
      <ContentToolbarItem
        tooltip={lockMovementX ? t('contents.actions.xAxisLocked') : t('contents.properties.left')}>
        <Field
          isToolbar
          fixedSemiSmallWidth
          name='left'
          component={ReduxTextFieldWithDragChange}
          DragIcon={AlphaXIcon}
          type='number'
          InputProps={{
            inputProps: { step: 1 },
            endAdornment: (
              <InputAdornment position='start' className={classes.adornment}>
                px
              </InputAdornment>
            )
          }}
          parse={value =>
            parseObjectTopRelativeToWorkarea('left')(parseNumberFieldToIntOrZero(value))
          }
          format={value =>
            formatNumberFieldToInt(formatObjectPositionRelativeToWorkarea('left')(value))
          }
          disabled={lockMovementX || fieldsDisabled}
        />
      </ContentToolbarItem>
      <ContentToolbarItem
        tooltip={lockMovementY ? t('contents.actions.yAxisLocked') : t('contents.properties.top')}>
        <Field
          isToolbar
          fixedSemiSmallWidth
          name='top'
          component={ReduxTextFieldWithDragChange}
          DragIcon={AlphaYIcon}
          type='number'
          InputProps={{
            inputProps: { step: 1 },
            endAdornment: (
              <InputAdornment position='start' className={classes.adornment}>
                px
              </InputAdornment>
            )
          }}
          parse={value =>
            parseObjectTopRelativeToWorkarea('top')(parseNumberFieldToIntOrZero(value))
          }
          format={value =>
            formatNumberFieldToInt(formatObjectPositionRelativeToWorkarea('top')(value))
          }
          disabled={lockMovementY || fieldsDisabled}
        />
      </ContentToolbarItem>
      {enableSize && (
        <Fragment>
          <ContentToolbarItem tooltip={t('contents.properties.width')}>
            <Field
              isToolbar
              fixedSemiSmallWidth
              name='width'
              component={ReduxTextFieldWithDragChange}
              DragIcon={AlphaWIcon}
              type='number'
              InputProps={{
                inputProps: { step: 0.1 },
                endAdornment: (
                  <InputAdornment position='start' className={classes.adornment}>
                    px
                  </InputAdornment>
                )
              }}
              parse={parseNumberFieldToFloatOrZero}
              format={formatNumberFieldAndRoundBy1Decimal}
              disabled={someAxisLocked || fieldsDisabled}
            />
          </ContentToolbarItem>
          <ContentToolbarItem tooltip={t('contents.properties.height')}>
            <Field
              isToolbar
              fixedSemiSmallWidth
              name='height'
              component={ReduxTextFieldWithDragChange}
              DragIcon={AlphaHIcon}
              type='number'
              InputProps={{
                inputProps: { step: 0.1 },
                endAdornment: (
                  <InputAdornment position='start' className={classes.adornment}>
                    px
                  </InputAdornment>
                )
              }}
              parse={parseNumberFieldToFloatOrZero}
              format={formatNumberFieldAndRoundBy1Decimal}
              disabled={someAxisLocked || fieldsDisabled}
            />
          </ContentToolbarItem>
        </Fragment>
      )}
      {enableRotation && (
        <ContentToolbarItem tooltip={t('contents.properties.angle')}>
          <Field
            isToolbar
            fixedSmallWidth
            name='angle'
            component={ReduxTextFieldWithDragChange}
            DragIcon={RotateRightIcon}
            InputProps={{
              inputProps: { min: -360, max: 360, step: 0.1 },
              endAdornment: (
                <InputAdornment position='start' className={classes.adornment}>
                  °
                </InputAdornment>
              )
            }}
            type='number'
            parse={parseNumberFieldToFloatOrZero}
            format={formatNumberFieldAndRoundBy1Decimal}
            disabled={someAxisLocked || fieldsDisabled}
          />
        </ContentToolbarItem>
      )}
      {enableScale && (
        <>
          <ContentToolbarItem tooltip={t('contents.properties.scaleX')}>
            <Field
              isToolbar
              fixedSmallWidth
              name='scaleX'
              component={ReduxTextFieldWithDragChange}
              DragIcon={ArrowExpandHorizontalIcon}
              type='number'
              InputProps={{
                inputProps: { step: 0.01 }
              }}
              parse={parseNumberFieldToFloatOrZero}
              format={formatNumberFieldAndRoundBy2Decimals}
              disabled={someAxisLocked || fieldsDisabled}
            />
          </ContentToolbarItem>
          <ContentToolbarItem tooltip={t('contents.properties.scaleY')}>
            <Field
              isToolbar
              fixedSmallWidth
              name='scaleY'
              component={ReduxTextFieldWithDragChange}
              DragIcon={ArrowExpandVerticalIcon}
              type='number'
              InputProps={{
                inputProps: { step: 0.01 }
              }}
              parse={parseNumberFieldToFloatOrZero}
              format={formatNumberFieldAndRoundBy2Decimals}
              disabled={someAxisLocked || fieldsDisabled}
            />
          </ContentToolbarItem>
        </>
      )}
    </Fragment>
  )
}

const mapStateToProps = (state: StateInterface): StateProps => {
  const selectedObjects = selectSelectedObjects(state)
  return {
    selectedObjects
  }
}

const mapDispatchToProps = (dispatch: Dispatch, { formName }: OwnProps): DispatchProps => ({
  changeValue: (field: string, value: any) => dispatch(change(formName, field, value))
})
export default connect(mapStateToProps, mapDispatchToProps)(CommonContentFields)
