import { Tooltip, IconButton, Typography, AppBar, Toolbar, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { Fragment, useEffect } from 'react'
import CloseIcon from 'mdi-react/CloseIcon'
import { ScreenResponse } from '@seesignage/seesignage-utils'
import { useTranslation } from 'react-i18next'
import SimpleExtensionPanel from '../../../components/ExtensionPanels/SimpleExtensionPanel'
import TvInformation from '../../Screens/ScreensTable/TvInformation'
import WifiNetwork from '../../Screens/ScreensTable/NetworkInformation/WifiNetwork'
import EthernetNetwork from '../../Screens/ScreensTable/NetworkInformation/EthernetNetwork'
import StatusesScatterChart from '../../Connections/StatusesScatterChart'
import { GetScreenFromDashboard, UpdateScreenFromDashboard } from '../../../types/actions'
import ScreenFormDashboard from './Forms/ScreenFormDashboard'
import SelectedScreenActionsDashboard from './SelectedScreenActionsDashboard'

const useStyles = makeStyles(theme => ({
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    color: theme.palette.text.secondary,
    marginRight: 4,
    display: 'flex'
  },
  appBar: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14)',
    top: 0
  },
  container: {
    maxWidth: '500px',
    textAlign: 'center' as 'center',
    padding: theme.spacing(),
    margin: 'auto'
  },
  editScreenDrawer: {
    padding: '8px 0px 8px 0px',
    width: 700,
    [theme.breakpoints.down('lg')]: {
      width: '90vw'
    },
    [theme.breakpoints.down('md')]: {
      width: '100vw'
    },
    height: '90%'
  },
  editScreen: {
    height: '100%',
    overflow: 'auto'
  },
  drawerTitle: {
    position: 'relative',
    width: '100%',
    textAlign: 'center',
    height: 50
  },
  closeDrawerButton: {
    position: 'absolute',
    left: 0,
    top: 0
  }
}))

interface ScreenDrawerContentProps {
  handleCloseDrawer: () => void
  screen: ScreenResponse
  updateScreenFromDashboard: UpdateScreenFromDashboard
  getScreenFromDashboard: GetScreenFromDashboard
}

const ScreenDrawerContent = ({
  screen,
  handleCloseDrawer,
  updateScreenFromDashboard,
  getScreenFromDashboard
}: ScreenDrawerContentProps) => {
  const { screenId, environmentId, name, configs, statuses } = screen
  const handleMountActions = () => {
    getScreenFromDashboard({ screenId, environmentId })
  }
  useEffect(handleMountActions, [])
  const [t] = useTranslation()
  const classes = useStyles()
  const lastStatusItem = statuses.slice(-1)[0]
  return (
    <div className={classes.editScreenDrawer}>
      <div className={classes.drawerTitle}>
        <Tooltip disableInteractive title={t('general.close')}>
          <IconButton
            onClick={handleCloseDrawer}
            className={classes.closeDrawerButton}
            size='large'>
            <CloseIcon />
          </IconButton>
        </Tooltip>
        <Typography variant='h6'>{name}</Typography>
      </div>
      <div>
        <AppBar className={classes.appBar} position='sticky' color='default'>
          <Toolbar>
            <Fragment>
              <div className={classes.spacer} />
              <div className={classes.actions}>
                {screen && (
                  <Fragment>
                    <SelectedScreenActionsDashboard screen={screen} />
                  </Fragment>
                )}
              </div>
            </Fragment>
          </Toolbar>
        </AppBar>
      </div>
      <div className={classes.editScreen}>
        <Grid container>
          <Grid item xs={12}>
            <SimpleExtensionPanel title={t('screens.createScreenForm.timers')}>
              <ScreenFormDashboard
                submitAction={updateScreenFromDashboard}
                initialValues={{ configs, screenId, environmentId }}
              />
            </SimpleExtensionPanel>
          </Grid>
          <Grid item xs={12}>
            <TvInformation screen={screen} updatedAt={lastStatusItem?.updatedAt} />
          </Grid>
          <Grid item xs={12}>
            {statuses.length > 1 && <StatusesScatterChart statuses={statuses} />}
          </Grid>
          <Grid item xs={6}>
            <WifiNetwork wifiNetwork={lastStatusItem?.wifiNetwork} />
          </Grid>
          <Grid item xs={6}>
            <EthernetNetwork ethernetNetwork={lastStatusItem?.ethernetNetwork} />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
export default ScreenDrawerContent
