import { Button, DialogActions, Grid, LinearProgress } from '@mui/material'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import { AutocompleteOption } from '@seesignage/seesignage-utils'
import { closeDialog } from '../../../actions/dialogs'
import { copyTemplateToEnvironments } from '../../../actions/templates'
import { selectEnvironmentsAsOptions } from '../../../selectors/environments'
import { selectEnvironmentIdFromPathname } from '../../../selectors/routing'
import { bindSubmitActionToPromise } from '../../../utils/forms'
import { required } from '../../../validation'
import { StateInterface } from '../../../types/states'
import { ReduxAutocompleteMulti } from '../../../components/FormInput/ReduxWrappers'
import { CloseDialog } from '../../../types/actions'
import ErrorMessage from '../../../components/Errors/ErrorMessage'

interface OwnProps {
  dialogId: string
}

interface StateProps {
  environments?: AutocompleteOption[]
}
interface DispatchProps {
  copyToEnvironments: any
  closeDialog: CloseDialog
}

type ComponentProps = OwnProps & StateProps & DispatchProps

type CopyToEnvironmentsFormProps = ComponentProps & WithTranslation

const CopyToEnvironmentsForm = ({
  dialogId,
  closeDialog,
  handleSubmit,
  copyToEnvironments,
  environments,
  submitting,
  error,
  t
}: CopyToEnvironmentsFormProps & InjectedFormProps<{}, ComponentProps>) => (
  <form onSubmit={handleSubmit(copyToEnvironments)}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Field
          name='environments'
          label={t('environment.select_other')}
          component={ReduxAutocompleteMulti}
          options={environments}
          required
          validate={[required]}
          multiple
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        {submitting && <LinearProgress />}
        <ErrorMessage message={error} />
      </Grid>
    </Grid>
    <DialogActions>
      <Button onClick={() => closeDialog(dialogId)} color='primary'>
        {t('general.cancel')}
      </Button>
      <Button disabled={submitting} color='primary' type='submit'>
        {t('general.copy')}
      </Button>
    </DialogActions>
  </form>
)

const mapStateToProps = (state: StateInterface): StateProps => ({
  environments: selectEnvironmentsAsOptions(selectEnvironmentIdFromPathname(state))(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  copyToEnvironments: bindSubmitActionToPromise(dispatch, copyTemplateToEnvironments),
  closeDialog: (dialogId: string) => dispatch(closeDialog(dialogId))
})

export default connect<StateProps, DispatchProps, OwnProps, StateInterface>(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<{}, ComponentProps>({
    form: 'CopyTemplateToEnvironmentsForm'
  })(withTranslation()(CopyToEnvironmentsForm))
)
