import React, { useState } from 'react'
import { EnvironmentUI } from '@seesignage/seesignage-utils'
import { Tabs, Tab, Fab, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import AccountIcon from 'mdi-react/AccountIcon'
import AccountQuestionIcon from 'mdi-react/AccountQuestionIcon'
import AccountPlusIcon from 'mdi-react/AccountPlusIcon'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { bindSubmitActionToPromise } from '../../../../utils/forms'
import { addEnvironmentUsers as addEnvironmentUsersAction } from '../../../../actions/environments'
import {
  openDialog as openDialogAction,
  closeDialog as closeDialogAction
} from '../../../../actions/dialogs'
import { UserInterface } from '../../../../types/users'
import AddEnvironmentUsersForm from '../../Forms/AddEnvironmentUsersForm'
import { isAdminRole, checkAdminAccess } from '../../../../utils/permissions'
import Dialog from '../../../Dialog'
import { StateInterface } from '../../../../types/states'
import { selectConfirmationsByEnvironmentId } from '../../../../selectors/confirmations'
import { selectEnvironmentIdFromPathname } from '../../../../selectors/routing'
import ConfirmationsTable from './ConfirmationsTable'
import UsersTable from './UsersTable'

enum EnvironmentUsersTab {
  users = 'users',
  invited = 'invited'
}

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative'
  },
  fab: {
    position: 'absolute',
    top: 8,
    right: 8
  }
}))
interface EnvironmentUsersProps {
  currentUser: UserInterface
  environment: EnvironmentUI
}

const EnvironmentUsers: React.FC<EnvironmentUsersProps> = ({ currentUser, environment }) => {
  const classes = useStyles()
  const [t] = useTranslation()

  const dispatch = useDispatch()
  const openDialog = (dialogId: string) => dispatch(openDialogAction(dialogId))
  const closeDialog = (dialogId: string) => dispatch(closeDialogAction(dialogId))
  const addUsers = bindSubmitActionToPromise(dispatch, addEnvironmentUsersAction)

  const confirmationsCount = useSelector((state: StateInterface) =>
    selectConfirmationsByEnvironmentId(selectEnvironmentIdFromPathname(state))
  ).length

  const [currentTab, setTab] = useState<EnvironmentUsersTab>(EnvironmentUsersTab.users)
  return (
    <div className={classes.root}>
      <Dialog
        dialogId='AddEnvironmentUsersDialog'
        title={t('environment.addUsers')}
        tooltipTitle={t('environment.addUsers')}
        noOpenDialogButton
        Content={
          <AddEnvironmentUsersForm
            closeDialog={() => closeDialog('AddEnvironmentUsersDialog')}
            addUsers={addUsers}
          />
        }
      />
      <Tabs
        value={currentTab}
        indicatorColor='primary'
        textColor='primary'
        onChange={(e, newValue) => setTab(newValue)}>
        <Tab
          icon={<AccountIcon />}
          label={t('environment.users')}
          value={EnvironmentUsersTab.users}
        />
        <Tab
          icon={<AccountQuestionIcon />}
          label={`${t('environment.invitedUsers')} ${
            confirmationsCount ? `(${confirmationsCount})` : ''
          }`}
          value={EnvironmentUsersTab.invited}
        />
      </Tabs>
      {currentTab === EnvironmentUsersTab.users && (
        <UsersTable currentUser={currentUser} environment={environment} />
      )}
      {currentTab === EnvironmentUsersTab.invited && (
        <ConfirmationsTable
          isAdminUser={
            isAdminRole(currentUser.role) || checkAdminAccess(environment, currentUser.sub)
          }
        />
      )}
      <Tooltip disableInteractive title={t('environment.addUsers')}>
        <Fab
          onClick={() => openDialog('AddEnvironmentUsersDialog')}
          className={classes.fab}
          color='primary'
          aria-label='add'>
          <AccountPlusIcon />
        </Fab>
      </Tooltip>
    </div>
  )
}

export default EnvironmentUsers
