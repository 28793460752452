import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import { Content, MediaType } from '@seesignage/seesignage-utils'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Divider, LinearProgress } from '@mui/material'
import { CANVAS_CONTAINER_ID, CANVAS_ID } from '@seesignage/seesignage-player-utils'
import { useDropzone } from 'react-dropzone'
import Dialog from '../../Dialog'
import { ContentEditorDialog } from '../../../types/contents'
import { OpenContentEditorDialogProps } from '../../../types/dialogs'
import CreateMediaCarouselWizard from '../Forms/CreateMediaCarouselWizard/CreateMediaCarouselWizard'
import { generateMediaCarouselWizardInitialValues } from '../../../utils/fabric/canvasFormValues'
import { bindSubmitActionToPromise } from '../../../utils/forms'
import { selectContentIdFromPathname } from '../../../selectors/routing'
import {
  selectContentById,
  selectContentsEditor,
  selectIsContentModified,
  selectIsContentsLoading,
  selectSelectedObjects
} from '../../../selectors/contents'
import {
  getContent as getContentAction,
  saveCanvas as saveCanvasAction,
  setContentId as setContentIdAction,
  closeEditorCanvas as closeEditorCanvasAction,
  updateMediaSource,
  addMediaContent,
  setBackgroundImage
} from '../../../actions/contents'
import { isUserSystemAdmin } from '../../../selectors/users'
import CreateSocialMediaWidgetWizard from '../Forms/CreateSocialMediaWidgetWizard/CreateSocialMediaWidgetWizard'
import { BreadcrumbContentType } from '../../../types/breadcrumbs'
import ContentEditorPageTitle from '../../../components/PageTitle/ContentEditorPageTitle'
import { selectDialogVisibility } from '../../../selectors/dialogs'
import { setDropzoneFiles } from '../../../actions/media'
import { getFilesWithPreview } from '../../../utils/files'
import { SetDropzoneFilesActionParams } from '../../../types/media'
import DragDropSnackbar from '../../../components/Media/DragDropSnackbar'
import CanvasObjectMenu from './CanvasObjectMenu'
import ContentDrawer from './ContentDrawer'
import ContentToolbar from './ContentToolbar'
import AddMediaContentForm, {
  addMediaContentFormName
} from './ContentToolbar/ContentToolbarForms/AddMediaContentForm'
import UnlockFeaturesDialog from './ContentToolbar/CustomContentToolbar/UnlockFeatures'
import FooterToolbar from './ContentCanvasArea/FooterToolbar'
import './contentEditorStyles.css'

const useStyles = makeStyles({
  layout: {
    overflow: 'hidden auto',
    minHeight: 'calc(100vh - 129px)',
    height: 'calc(100vh - 129px)',
    display: 'flex',
    flex: 'auto',
    flexDirection: 'column'
  },
  editorArea: {
    display: 'flex',
    flex: 1,
    overflow: 'hidden'
  },
  canvasPanel: {
    position: 'relative',
    backgroundColor: '#f3f3f3',
    flex: 1,
    overflow: 'hidden',
    zIndex: 5
  },
  dragActive: {
    border: '3px dashed rgba(0, 174, 239, 1)',
    cursor: 'pointer',
    borderRadius: 4
  },
  canvasContainer: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0
  },
  temporayDrawingCanvas: {
    position: 'absolute',
    top: 0,
    left: 0,
    pointerEvents: 'none'
  }
})

interface ContentEditorProps {
  /** 'true' when updating content from playlist. */
  isUpdatePlaylistItem?: boolean
  contentId?: string
}

const ContentEditor = ({ isUpdatePlaylistItem, contentId: contentIdProp }: ContentEditorProps) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const saveCanvas = () => dispatch(saveCanvasAction())

  const contentIdFromPathname = useSelector(selectContentIdFromPathname)
  const contentId = (isUpdatePlaylistItem && contentIdProp
    ? contentIdProp
    : contentIdFromPathname) as string
  const selectedObjects = useSelector(selectSelectedObjects)
  const dialogVisibility = useSelector(selectDialogVisibility)
  const content = useSelector(selectContentById(contentId)) as Content
  const isLoading = useSelector(selectIsContentsLoading)
  const editor = useSelector(selectContentsEditor)
  const isModified = useSelector(selectIsContentModified)
  const isSystemAdmin = useSelector(isUserSystemAdmin)
  const selectedObject =
    selectedObjects && selectedObjects.length === 1 ? selectedObjects[0] : undefined

  const [isUpdateMedia, setIsUpdateMedia] = useState(false)
  const [isUpdateDialogMode, setIsUpdateDialogMode] = useState(false)
  const [updateMediaType, setUpdateMediaType] = useState<MediaType | undefined>(undefined)
  const [dialogs, setDialogs] = useState({
    [ContentEditorDialog.AddMediaContentFormDialog]: false,
    [ContentEditorDialog.SetBackgroundImageFormDialog]: false,
    [ContentEditorDialog.MediaCarouselDialog]: false,
    [ContentEditorDialog.AddSocialMediaDialog]: false,
    [ContentEditorDialog.LimitedFeatures]: false
  })

  const openDialog = ({ dialogId, mediaType, isUpdateMode }: OpenContentEditorDialogProps) => {
    if (mediaType) {
      setIsUpdateMedia(true)
      setUpdateMediaType(mediaType)
    }

    if (isUpdateMode) {
      setIsUpdateDialogMode(true)
    }

    setDialogs({
      ...dialogs,
      [dialogId]: true
    })
  }

  const closeDialog = (dialogId: ContentEditorDialog) => {
    if (updateMediaType) {
      setIsUpdateMedia(false)
      setUpdateMediaType(undefined)
    }

    if (isUpdateDialogMode) {
      setIsUpdateDialogMode(false)
    }

    setDialogs({
      ...dialogs,
      [dialogId]: false
    })
  }

  const { getRootProps, isDragActive } = useDropzone({
    onDrop: (files: File[]) => {
      const setDropzoneFilesActionParams: SetDropzoneFilesActionParams = {
        files: getFilesWithPreview(files),
        formName: addMediaContentFormName, // autoselect media
        formFieldName: 'key'
      }
      dispatch(setDropzoneFiles(setDropzoneFilesActionParams))
      openDialog({ dialogId: ContentEditorDialog.AddMediaContentFormDialog })
    },
    noClick: true,
    noKeyboard: true,
    disabled: dialogVisibility.isVisible // disable dropzone when dialog is open.
    // NOTE: do not use accept here because we want to allow all files because redux-form
    // handles the file type validation.
  })

  useEffect(() => {
    if (contentId) {
      dispatch(setContentIdAction(contentId))
      dispatch(getContentAction(contentId))
    }
    return () => {
      dispatch(closeEditorCanvasAction()) // note: closeEditorCanvas when component unmounts
    }
  }, [dispatch, contentId])

  if (!content) {
    return <LinearProgress />
  }

  return (
    <>
      {content && isLoading && <LinearProgress />}
      {!isUpdatePlaylistItem && (
        <ContentEditorPageTitle
          contentType={BreadcrumbContentType.infopages}
          contentId={contentId}
          contentName={content.name}
          saveCanvas={saveCanvas}
          isModified={isModified}
          isLoading={isLoading}
        />
      )}
      <div className={classes.layout}>
        <ContentToolbar
          editorLoaded={editor?.initDone}
          openDialog={openDialog}
          t={t}
          isSystemAdmin={isSystemAdmin}
        />
        <div className={classes.editorArea}>
          <CanvasObjectMenu openDialog={openDialog} t={t} />
          <div className={`${classes.canvasPanel} ${isDragActive ? classes.dragActive : ''}`}>
            <div
              id={CANVAS_CONTAINER_ID}
              {...getRootProps({
                className: `dropzone ${classes.canvasContainer}
            }`
              })}>
              <canvas id={CANVAS_ID} />
              <canvas id='temporayDrawingCanvas' className={classes.temporayDrawingCanvas} />
            </div>
            <FooterToolbar />
          </div>
          <ContentDrawer editorLoaded={editor?.initDone} isSystemAdmin={isSystemAdmin} />
        </div>

        <Divider />
        <DragDropSnackbar open={isDragActive} label={t('media.uploadToInfopage')} />
        <Dialog
          noOpenDialogButton
          dialogId={ContentEditorDialog.AddMediaContentFormDialog}
          title={
            isUpdateMedia
              ? updateMediaType === MediaType.image
                ? t('contents.forms.updateImage')
                : t('contents.forms.updateVideo')
              : t('contents.forms.addMedia')
          }
          openFromParentState={dialogs[ContentEditorDialog.AddMediaContentFormDialog]}
          closeFromParentState={() => closeDialog(ContentEditorDialog.AddMediaContentFormDialog)}
          maxWidth='xl'
          Content={
            <AddMediaContentForm
              dialogId={ContentEditorDialog.AddMediaContentFormDialog}
              mediaType={updateMediaType}
              closeDialog={closeDialog}
              onSubmit={
                isUpdateMedia
                  ? bindSubmitActionToPromise(dispatch, updateMediaSource)
                  : bindSubmitActionToPromise(dispatch, addMediaContent)
              }
            />
          }
        />
        <Dialog
          noOpenDialogButton
          dialogId={ContentEditorDialog.SetBackgroundImageFormDialog}
          title={
            isUpdateMedia
              ? updateMediaType === MediaType.image
                ? t('contents.forms.updateImage')
                : t('contents.forms.updateVideo')
              : t('contents.forms.addMedia')
          }
          openFromParentState={dialogs[ContentEditorDialog.SetBackgroundImageFormDialog]}
          closeFromParentState={() => closeDialog(ContentEditorDialog.SetBackgroundImageFormDialog)}
          maxWidth='xl'
          Content={
            <AddMediaContentForm
              dialogId={ContentEditorDialog.SetBackgroundImageFormDialog}
              mediaType={MediaType.image}
              closeDialog={closeDialog}
              onSubmit={bindSubmitActionToPromise(dispatch, setBackgroundImage)}
            />
          }
        />
        <Dialog
          dialogId={ContentEditorDialog.MediaCarouselDialog}
          openFromParentState={dialogs[ContentEditorDialog.MediaCarouselDialog]}
          closeFromParentState={() => closeDialog(ContentEditorDialog.MediaCarouselDialog)}
          title={
            isUpdateDialogMode
              ? t('contents.forms.updateMediaCarousel')
              : t('contents.forms.createMediaCarousel')
          }
          noOpenDialogButton
          maxWidth='xl'
          Content={
            <CreateMediaCarouselWizard
              currentContentId={contentId}
              isUpdateMediaCarousel={isUpdateDialogMode}
              initialValues={generateMediaCarouselWizardInitialValues(
                selectedObject,
                isUpdateDialogMode
              )}
              closeDialog={() => closeDialog(ContentEditorDialog.MediaCarouselDialog)}
            />
          }
        />
        <Dialog
          dialogId={ContentEditorDialog.AddSocialMediaDialog}
          openFromParentState={dialogs[ContentEditorDialog.AddSocialMediaDialog]}
          closeFromParentState={() => closeDialog(ContentEditorDialog.AddSocialMediaDialog)}
          title={t(
            isUpdateDialogMode
              ? 'contents.widgets.socialMedia.updateSocialMediaWidget'
              : 'contents.widgets.socialMedia.createSocialMediaWidget'
          )}
          noOpenDialogButton
          maxWidth={'lg'}
          buttonId='add-social-media-widget'
          Content={
            <CreateSocialMediaWidgetWizard
              isUpdateSocialMedia={isUpdateDialogMode}
              closeDialog={() => closeDialog(ContentEditorDialog.AddSocialMediaDialog)}
            />
          }
        />
        <UnlockFeaturesDialog isOpen={dialogs.LimitedFeatures} closeDialog={closeDialog} t={t} />
      </div>
    </>
  )
}

export default ContentEditor
