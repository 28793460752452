import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, Grid } from '@mui/material'
import SupportCards from './SupportCards'

/**
 * Component for Support details. Can be used in page or dialog
 */
const Support: React.FC = () => {
  const [t] = useTranslation()
  return (
    <Grid container>
      <SupportCards t={t} chatVisible />
      <Grid item xs={12}>
        <Typography display='block' variant='h5' gutterBottom align='center'>
          {t('info.privacyPolicyTitle')}
        </Typography>
        <Typography display='block' variant='body1' gutterBottom align='center'>
          {t('info.privacyPolicyDesc')}
        </Typography>
        <Typography display='block' variant='body1' gutterBottom align='center'>
          <a href={t('info.privacyPolicyUrl')} rel='noopener noreferrer' target='_blank'>
            {t('info.privacyPolicyUrl')}
          </a>
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Support
