import { Tooltip, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PreviewFrame } from '@seesignage/seesignage-player-utils'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  StylesChild,
  ContentMetadataByKey,
  InfopageContent,
  Template,
  TemplateOrientation
} from '@seesignage/seesignage-utils'
import nanoid from 'nanoid'
import ReloadIcon from 'mdi-react/ReloadIcon'
import { useTranslation } from 'react-i18next'
import { StateInterface } from '../../../types/states'
import { selectIsUserDeviceMobile } from '../../../selectors/users'
import { getResolution } from '../../../utils/preview'

const useStyles = makeStyles(theme => ({
  landscapeContainer: {
    padding: theme.spacing(),
    height: 360,
    width: 582,
    position: 'sticky' as any,
    top: 200
  },
  portraitContainer: {
    height: 590,
    width: 330,
    position: 'sticky' as any,
    top: 200
  },
  screenContainer: {
    transform: 'scale(0.3)',
    transformOrigin: 'top left'
  },
  screenContainerMobile: {
    transform: 'scale(0.2)',
    transformOrigin: 'top left'
  },
  reloadContainer: {
    position: 'relative',
    textAlign: 'center',
    width: '91%'
  },
  reloadButton: {
    position: 'absolute',
    top: -45
  }
}))

interface OwnProps {
  orientation: TemplateOrientation
  template: Template
  contentMetadata: ContentMetadataByKey
  infopageContent: InfopageContent
}

interface StateProps {
  styles?: StylesChild
  isMobile: boolean
}

type InfopagePreviewProps = OwnProps & StateProps

const InfopagePreview: React.FC<InfopagePreviewProps> = ({
  orientation,
  contentMetadata,
  infopageContent,
  template,
  isMobile
}) => {
  const classes = useStyles()
  const [previewId, setPreviewId] = useState(nanoid())
  const [t] = useTranslation()
  const contentProps: any = {
    infopageContent,
    contentMetadata,
    orientation,
    template
  }
  const replay = () => {
    setPreviewId(nanoid())
  }
  const { width, height } = getResolution(orientation)
  return (
    <div
      key={previewId}
      className={
        orientation === TemplateOrientation.landscape
          ? classes.landscapeContainer
          : classes.portraitContainer
      }>
      <div className={classes.reloadContainer}>
        <Tooltip disableInteractive title={t('infopages.replay')}>
          <IconButton onClick={() => replay()} className={classes.reloadButton} size='large'>
            <ReloadIcon />
          </IconButton>
        </Tooltip>
      </div>
      <div className={isMobile ? classes.screenContainerMobile : classes.screenContainer}>
        <PreviewFrame id='infopagePreview' content={contentProps} width={width} height={height} />
      </div>
    </div>
  )
}

const mapStateToProps = (state: StateInterface): StateProps => ({
  isMobile: selectIsUserDeviceMobile(state)
})

export default connect(mapStateToProps)(InfopagePreview)
