import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import CalendarClockIcon from 'mdi-react/CalendarClockIcon'
import CalendarRangeIcon from 'mdi-react/CalendarRangeIcon'
import DeleteIcon from 'mdi-react/DeleteIcon'
import React, { Fragment } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import {
  Field,
  FieldArray,
  reduxForm,
  InjectedFormProps,
  WrappedFieldInputProps,
  WrappedFieldArrayProps,
  change
} from 'redux-form'
import { FormGroup, Checkbox, Tooltip } from '@mui/material'
import { TFunction } from 'i18next'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import i18n from '../../../translations/i18n'
import { ReduxDateTimePicker, ReduxTimePicker } from '../../../components/FormInput/ReduxWrappers'
import { CloseDialog } from '../../../types/actions'
import { SchedulePlaylistItemFormData } from '../../../types/playlists'
import ErrorMessage from '../../../components/Errors/ErrorMessage'
import { required } from '../../../validation'

const getDays = (t: TFunction) => [
  {
    checkBoxLabel: `${t('general.time.letterDays.mon')}`,
    tooltipTitle: `${t('general.time.days.mon')}`,
    name: 'Monday'
  },
  {
    checkBoxLabel: `${t('general.time.letterDays.tue')}`,
    tooltipTitle: `${t('general.time.days.tue')}`,
    name: 'Tuesday'
  },
  {
    checkBoxLabel: `${t('general.time.letterDays.wed')}`,
    tooltipTitle: `${t('general.time.days.wed')}`,
    name: 'Wednesday'
  },
  {
    checkBoxLabel: `${t('general.time.letterDays.thu')}`,
    tooltipTitle: `${t('general.time.days.thu')}`,
    name: 'Thursday'
  },
  {
    checkBoxLabel: `${t('general.time.letterDays.fri')}`,
    tooltipTitle: `${t('general.time.days.fri')}`,
    name: 'Friday'
  },
  {
    checkBoxLabel: `${t('general.time.letterDays.sat')}`,
    tooltipTitle: `${t('general.time.days.sat')}`,
    name: 'Saturday'
  },
  {
    checkBoxLabel: `${t('general.time.letterDays.sun')}`,
    tooltipTitle: `${t('general.time.days.sun')}`,
    name: 'Sunday'
  }
]

interface CheckBoxIconProps {
  day: string
  checked: boolean
}

const CheckBoxIcon = ({ day, checked }: CheckBoxIconProps) => {
  const circleStyles = {
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    padding: '4px',
    fontSize: '15px',
    lineHeight: '20px',
    background: checked ? '#1e88e5' : 'white',
    color: checked ? 'white' : 'black',
    textAlign: 'center' as any,
    verticalAlign: 'middle'
  }
  return (
    <div style={circleStyles}>
      <Typography style={{ fontWeight: 1000 }} variant='caption'>
        {day}
      </Typography>
    </div>
  )
}

interface DayCheckBoxProps {
  input: WrappedFieldInputProps
  t: TFunction
  checkBoxLabel: string
  tooltipTitle: string
}

const DayCheckBox = ({
  input: { onChange, value },
  checkBoxLabel,
  tooltipTitle
}: DayCheckBoxProps) => (
  <Tooltip disableInteractive title={tooltipTitle}>
    <Checkbox
      checked={value ? value : false}
      icon={<CheckBoxIcon day={checkBoxLabel} checked={false} />}
      checkedIcon={<CheckBoxIcon day={checkBoxLabel} checked={true} />}
      onChange={onChange}
    />
  </Tooltip>
)

const CustomErrorField = ({ meta: { error } }: WrappedFieldArrayProps) => (
  <Fragment>
    <ErrorMessage message={error} />
  </Fragment>
)

interface ConditionsFieldProps extends WrappedFieldArrayProps {
  t: TFunction
  clearDays: (field: string) => void
}

const ConditionsField = ({ fields, clearDays, t }: ConditionsFieldProps) => {
  const startTime = new Date()
  const endTime = new Date()
  startTime.setHours(0, 0, 0, 0)
  endTime.setHours(23, 59, 59, 0)
  return (
    <Grid item xs={12} sm={12}>
      <Button
        fullWidth
        onClick={() =>
          fields.push({
            startTime,
            endTime,
            days: {
              Monday: true,
              Tuesday: true,
              Wednesday: true,
              Thursday: true,
              Friday: true,
              Saturday: true,
              Sunday: true
            }
          })
        }
        variant='contained'
        style={{ margin: '10px 0 10px 0' }}>
        <CalendarClockIcon /> {t('date.addConditions')}
      </Button>
      {fields.map((condition, index) => (
        <Fragment key={`days-${condition}-${index}`}>
          <Grid
            item
            sm={12}
            style={{
              padding: 10
            }}>
            <Paper
              style={{
                padding: '10px 10px 10px 15px'
              }}>
              <IconButton
                onClick={() => fields.remove(index)}
                aria-label='Delete'
                style={{ float: 'right' }}
                size='large'>
                <DeleteIcon />
              </IconButton>
              <Grid item xs={12}>
                <FormGroup row>
                  {getDays(t).map(({ checkBoxLabel, tooltipTitle, name }) => (
                    <Field
                      key={name}
                      name={`${condition}.days.${name}`}
                      checkBoxLabel={checkBoxLabel}
                      tooltipTitle={tooltipTitle}
                      component={DayCheckBox}
                    />
                  ))}
                </FormGroup>
              </Grid>
              <Grid item xs={8}>
                <Button
                  variant='contained'
                  style={{ textAlign: 'center', width: '100%', marginBottom: '16px' }}
                  onClick={() => clearDays(`${condition}.days`)}>
                  {t('date.clearDays')}
                </Button>
              </Grid>
              <Field
                label='errorMessage'
                name={`${condition}.errorMessage`}
                component={CustomErrorField}
              />
              <Grid container>
                <Grid item xs={6}>
                  <Field
                    name={`${condition}.startTime`}
                    label={t('date.startTime')}
                    component={ReduxTimePicker}
                    style={{ margin: 5 }}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name={`${condition}.endTime`}
                    label={t('date.endTime')}
                    component={ReduxTimePicker}
                    style={{ margin: 5 }}
                    required
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Fragment>
      ))}
    </Grid>
  )
}

interface RangeFieldsProps extends WrappedFieldArrayProps {
  t: TFunction
  clearDays: (field: string) => void
}

const RangeFields = ({ fields, clearDays, t }: RangeFieldsProps) => {
  const start = new Date()
  const end = new Date()
  start.setHours(0, 0, 0, 0)
  end.setHours(23, 59, 59, 0)
  return (
    <Grid container spacing={2}>
      {fields.map((member, index) => (
        <Grid
          key={`range-${index}`}
          item
          xs={12}
          style={{
            margin: '10px 0px 10px 0'
          }}>
          <Paper
            style={{
              padding: 10
            }}>
            <Grid container spacing={2} justifyContent='flex-start' alignItems='flex-start'>
              <Grid item xs={5}>
                <Field
                  name={`${member}.start`}
                  label={t('date.startDateTime')}
                  component={ReduxDateTimePicker}
                  validate={[required]}
                  required
                />
              </Grid>
              <Grid item xs={5}>
                <Field
                  name={`${member}.end`}
                  label={t('date.endDateTime')}
                  component={ReduxDateTimePicker}
                  setNoEndDate
                  validate={[required]}
                  required
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  onClick={() => fields.remove(index)}
                  aria-label={t('general.delete')}
                  style={{ float: 'right' }}
                  size='large'>
                  <DeleteIcon />
                </IconButton>
              </Grid>
              <FieldArray
                name={`${member}.conditions`}
                component={ConditionsField}
                props={{ t, clearDays }}
              />
            </Grid>
          </Paper>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Button
          onClick={() =>
            fields.push({
              start,
              end,
              conditions: []
            })
          }
          fullWidth
          color='primary'
          variant='contained'
          style={{ margin: '10px 0 10px 0' }}>
          <CalendarRangeIcon /> {t('date.addRange')}
        </Button>
      </Grid>
    </Grid>
  )
}

interface OwnProps {
  submitAction: (formData: any) => void
  closeDialog: CloseDialog
  dialogId: string
  submitButtonLabel: string
  isMobile: boolean
}

interface DispatchProps {
  /** Deselect all days from field */
  clearDays: (field: string) => void
}

type ScheduleProps = OwnProps & DispatchProps & WithTranslation

const SchedulePlaylistItemForm: React.FC<ScheduleProps &
  InjectedFormProps<SchedulePlaylistItemFormData, OwnProps & DispatchProps>> = ({
  handleSubmit,
  submitting,
  submitAction,
  closeDialog,
  clearDays,
  dialogId,
  submitButtonLabel,
  error,
  isMobile,
  t
}) => (
  <form onSubmit={handleSubmit(submitAction)}>
    <Grid container>
      <FieldArray name='ranges' component={RangeFields} props={{ t, clearDays }} />
      <Grid item xs={12}>
        {submitting && <LinearProgress />}
        <ErrorMessage message={error} />
      </Grid>
    </Grid>
    <DialogActions style={isMobile ? { justifyContent: 'center' } : undefined}>
      <Button onClick={() => closeDialog(dialogId)} color='primary'>
        {t('general.cancel')}
      </Button>
      <Button disabled={submitting} color='primary' type='submit'>
        {submitButtonLabel}
      </Button>
    </DialogActions>
  </form>
)

const validate = (values: SchedulePlaylistItemFormData) => {
  const errors: any = {}
  if (values.ranges) {
    const ranges = values.ranges.map(({ start, end, conditions }) => {
      const errorsInRange: any = {}
      if (start > end) {
        errorsInRange.start = i18n.t('error.playlist.scheduleStartDate')
        errorsInRange.end = i18n.t('error.playlist.scheduleEndDate')
      }
      if (conditions) {
        const conditionsErrors = conditions.map(({ startTime, endTime, days }) => {
          const errorsInCondition: any = {}
          if (startTime > endTime) {
            errorsInCondition.startTime = i18n.t('error.playlist.scheduleStartTime')
            errorsInCondition.endTime = i18n.t('error.playlist.scheduleEndTime')
          }
          if (days) {
            const checkedDaysCount = Object.values(days).filter(x => x).length
            if (checkedDaysCount === 0) {
              errorsInCondition.errorMessage = i18n.t('error.playlist.scheduleNoDays')
            }
          }
          return errorsInCondition
        })
        errorsInRange.conditions = conditionsErrors
      }
      return errorsInRange
    })
    errors.ranges = ranges
  }
  return errors
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  clearDays: (field: string) =>
    dispatch(
      change('SchedulePlaylistItemForm', field, {
        Monday: false,
        Tuesday: false,
        Wednesday: false,
        Thursday: false,
        Friday: false,
        Saturday: false,
        Sunday: false
      })
    )
})

export default connect(
  null,
  mapDispatchToProps
)(
  reduxForm<SchedulePlaylistItemFormData, OwnProps & DispatchProps>({
    form: 'SchedulePlaylistItemForm',
    validate
  })(withTranslation()(SchedulePlaylistItemForm))
)
