import { API } from 'aws-amplify'
import { ErrorLog } from '@seesignage/seesignage-utils'

const getErrorLogsForDate = async (date: string) => {
  const errorLogs: ErrorLog[] = await API.get('logs', `/errors`, {
    queryStringParameters: {
      date
    }
  })
  return errorLogs
}

const getErrorLogsForLastDays = async () => {
  const errorLogs: ErrorLog[] = await API.get('logs', `/errors`, {
    queryStringParameters: {
      errorLogsForLastDays: 7
    }
  })
  return errorLogs
}

const getErrorLogsCountForLastDays = async () => {
  const { count }: { count: number } = await API.get('logs', `/errors`, {
    queryStringParameters: {
      errorLogsCountForLastDays: 7
    }
  })
  return count
}

export { getErrorLogsForDate, getErrorLogsForLastDays, getErrorLogsCountForLastDays }
