import {
  FishProduct,
  MeatProduct,
  ListType,
  AutocompleteOption,
  SpecialDiets,
  LunchListWeekDay,
  LunchGroup,
  LunchProduct,
  LunchItem,
  LunchListDay
} from '@seesignage/seesignage-utils'
import { TemplateAsFormValue } from './templates'

export interface AddMenuProductFormData {
  readonly sectionId: string
  name: string
  specialDiet?: any
  price?: number
  /**
   * existing product from search
   */
  existingProduct: any
}

/** Note: type and customerId cannot be channged later */
export interface CreateListRequestBody {
  name: string
  type: ListType
  /** lunch list does not have customerId */
  customerId?: string
  templateId: string
  /** if list uses parent environment template */
  templateEnvironmentId?: string
  /** lunch list startdate */
  startDate?: string
  /** lunch list price */
  price?: number
}

export interface CreateListFormData {
  environmentId: string
  name: string
  type: ListType
  /** does not exist in lunch list */
  customerId?: AutocompleteOption
  template: TemplateAsFormValue
  /** lunch list startdate */
  startDate?: string
  price?: number
}

export interface UpdateListFormData extends CreateListFormData {
  listId: string
}

export interface MiscItem {
  itemId?: string
  productId: string
}

export interface FishItem extends MiscItem {
  product: FishProduct
}

export interface MeatItem extends MiscItem {
  product: MeatProduct
}

export interface MiscListFormData {
  items: FishItem[] | MeatItem[]
}

export interface CreateLunchListFormData {
  name: string
  groupId?: AutocompleteOption
  specialDiet?: SpecialDiets
  /** exists when updating existing product */
  productId?: string
}

export interface AddLunchProductChangesFormData {
  /** product id that is going to be changed */
  existingProductId: string
  /** product that has been change to  */
  temporaryProductId?: AutocompleteOption
  isHidden?: boolean
  /** only initial value */
  weekIndex: number
  /** only initial value */
  day: LunchListWeekDay
}

export interface CreateLunchGroupFormData {
  /** exists when updating existing group */
  groupId?: string
  name: string
  price?: number
}

export enum SelectedLunchItemType {
  group = 'group',
  product = 'product',
  lunchItem = 'lunchItem'
}

export interface SelectedLunchItemData {
  item: LunchGroup | LunchProduct | LunchItem
  /**
   * group: group is selected from groups list
   * product: product is selected
   * lunchItem: lunch item is selected from weeks
   */
  type:
    | SelectedLunchItemType.group
    | SelectedLunchItemType.product
    | SelectedLunchItemType.lunchItem
  /** lunchItem week index */
  weekIndex?: number
  /** lunchItem index in lunchItems */
  lunchItemIndex?: number
  /** lunchItem day */
  day?: LunchListWeekDay
}

export type SelectedLunchItem = SelectedLunchItemData | undefined

export interface LunchListWeekFormDays {
  mon?: boolean
  tue?: boolean
  wed?: boolean
  thu?: boolean
  fri?: boolean
  sat?: boolean
  sun?: boolean
}

export interface LunchListWeekFormData {
  weekdays: LunchListWeekFormDays
}

export interface LunchListDayUI extends LunchListDay {
  day: LunchListWeekDay
}

/** Specific type guards for lunch lists */
export const isLunchGroup = (item: any): item is LunchGroup => item.groupId && item.name
export const isLunchItem = (item: any): item is LunchItem => item.itemId && item.productId
export const isLunchProduct = (item: any): item is LunchProduct => item.name && item.productId
