import { isFabricWorkareaObject } from '@seesignage/seesignage-player-utils'
import { fabric } from 'fabric'

function canvasGuidelines(canvas: fabric.Canvas) {
  let verticalLines: { x: number; y1: number; y2: number }[] = [],
    horizontalLines: { y: number; x1: number; x2: number }[] = [],
    viewportTransform = canvas.viewportTransform || [1, 0, 0, 1, 0, 0],
    zoom = canvas.getZoom()

  const aligningLineOffset = 5,
    aligningLineMargin = 4,
    aligningLineWidth = 1,
    ctx = canvas.getSelectionContext(),
    aligningLineColor = 'rgb(0, 174, 239)'

  const drawingLine = (x1: number, y1: number, x2: number, y2: number) => {
    ctx.save()
    ctx.lineWidth = aligningLineWidth
    ctx.strokeStyle = aligningLineColor
    ctx.beginPath()
    ctx.moveTo(x1 * zoom + viewportTransform[4], y1 * zoom + viewportTransform[5])
    ctx.lineTo(x2 * zoom + viewportTransform[4], y2 * zoom + viewportTransform[5])
    ctx.stroke()
    ctx.restore()
  }

  const drawVerticalLine = (coords: { x: number; y1: number; y2: number }) => {
    drawingLine(
      coords.x + 0.5,
      coords.y1 > coords.y2 ? coords.y2 : coords.y1,
      coords.x + 0.5,
      coords.y2 > coords.y1 ? coords.y2 : coords.y1
    )
  }

  const drawHorizontalLine = (coords: { y: number; x1: number; x2: number }) => {
    drawingLine(
      coords.x1 > coords.x2 ? coords.x2 : coords.x1,
      coords.y + 0.5,
      coords.x2 > coords.x1 ? coords.x2 : coords.x1,
      coords.y + 0.5
    )
  }

  const isInRange = (v1: number, v2: number) => {
    v1 = Math.round(v1)
    v2 = Math.round(v2)
    for (let i = v1 - aligningLineMargin, len = v1 + aligningLineMargin; i <= len; i++) {
      if (i === v2) {
        return true
      }
    }
    return false
  }

  const movingGuidelines = (e: fabric.IEvent<MouseEvent>) => {
    const { target } = e as { target: fabric.Object }
    if (!target) {
      return
    }
    const canvasObjects = canvas.getObjects()
    const activeObjectCenter = target.getCenterPoint()
    const activeObjectLeft = activeObjectCenter.x
    const activeObjectTop = activeObjectCenter.y
    const activeObjectBoundingRect = target.getBoundingRect()
    const activeObjectHeight = activeObjectBoundingRect.height / viewportTransform[3]
    const activeObjectWidth = activeObjectBoundingRect.width / viewportTransform[0]
    let horizontalInTheRange = false
    let verticalInTheRange = false
    const { _currentTransform: transform } = canvas as any
    if (!transform) {
      return
    }

    // It should be trivial to DRY this up by encapsulating (repeating) creation of x1, x2, y1, and y2 into functions,
    // but we're not doing it here for perf. reasons -- as this a function that's invoked on every mouse move

    for (let i = canvasObjects.length; i--; ) {
      if (canvasObjects[i] === target || !canvasObjects[i].evented) {
        continue
      }

      const objectCenter = canvasObjects[i].getCenterPoint()
      const objectLeft = objectCenter.x
      const objectTop = objectCenter.y
      const objectBoundingRect = canvasObjects[i].getBoundingRect()
      const objectHeight = objectBoundingRect.height / viewportTransform[3]
      const objectWidth = objectBoundingRect.width / viewportTransform[0]

      // snap by the horizontal center line
      if (isInRange(objectLeft, activeObjectLeft)) {
        verticalInTheRange = true
        if (isFabricWorkareaObject(canvasObjects[i])) {
          const y1 = -5000
          const y2 = 5000
          verticalLines.push({
            x: objectLeft,
            y1,
            y2
          })
        } else {
          verticalLines.push({
            x: objectLeft,
            y1:
              objectTop < activeObjectTop
                ? objectTop - objectHeight / 2 - aligningLineOffset
                : objectTop + objectHeight / 2 + aligningLineOffset,
            y2:
              activeObjectTop > objectTop
                ? activeObjectTop + activeObjectHeight / 2 + aligningLineOffset
                : activeObjectTop - activeObjectHeight / 2 - aligningLineOffset
          })
        }
        target.setPositionByOrigin(
          new fabric.Point(objectLeft, activeObjectTop),
          'center',
          'center'
        )
      }

      // snap by the left edge
      if (isInRange(objectLeft - objectWidth / 2, activeObjectLeft - activeObjectWidth / 2)) {
        verticalInTheRange = true
        if (isFabricWorkareaObject(canvasObjects[i])) {
          const y1 = -5000
          const y2 = 5000
          const x = objectLeft - objectWidth / 2
          verticalLines.push({
            x,
            y1,
            y2
          })
        } else {
          verticalLines.push({
            x: objectLeft - objectWidth / 2,
            y1:
              objectTop < activeObjectTop
                ? objectTop - objectHeight / 2 - aligningLineOffset
                : objectTop + objectHeight / 2 + aligningLineOffset,
            y2:
              activeObjectTop > objectTop
                ? activeObjectTop + activeObjectHeight / 2 + aligningLineOffset
                : activeObjectTop - activeObjectHeight / 2 - aligningLineOffset
          })
        }
        target.setPositionByOrigin(
          new fabric.Point(objectLeft - objectWidth / 2 + activeObjectWidth / 2, activeObjectTop),
          'center',
          'center'
        )
      }

      // snap by the right edge
      if (isInRange(objectLeft + objectWidth / 2, activeObjectLeft + activeObjectWidth / 2)) {
        verticalInTheRange = true
        if (isFabricWorkareaObject(canvasObjects[i])) {
          const y1 = -5000
          const y2 = 5000
          const x = objectLeft + objectWidth / 2
          verticalLines.push({
            x,
            y1,
            y2
          })
        } else {
          verticalLines.push({
            x: objectLeft + objectWidth / 2,
            y1:
              objectTop < activeObjectTop
                ? objectTop - objectHeight / 2 - aligningLineOffset
                : objectTop + objectHeight / 2 + aligningLineOffset,
            y2:
              activeObjectTop > objectTop
                ? activeObjectTop + activeObjectHeight / 2 + aligningLineOffset
                : activeObjectTop - activeObjectHeight / 2 - aligningLineOffset
          })
        }
        target.setPositionByOrigin(
          new fabric.Point(objectLeft + objectWidth / 2 - activeObjectWidth / 2, activeObjectTop),
          'center',
          'center'
        )
      }

      // snap by the vertical center line
      if (isInRange(objectTop, activeObjectTop)) {
        horizontalInTheRange = true
        if (isFabricWorkareaObject(canvasObjects[i])) {
          const x1 = -5000
          const x2 = 5000
          horizontalLines.push({
            y: objectTop,
            x1,
            x2
          })
        } else {
          horizontalLines.push({
            y: objectTop,
            x1:
              objectLeft < activeObjectLeft
                ? objectLeft - objectWidth / 2 - aligningLineOffset
                : objectLeft + objectWidth / 2 + aligningLineOffset,
            x2:
              activeObjectLeft > objectLeft
                ? activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset
                : activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset
          })
        }
        target.setPositionByOrigin(
          new fabric.Point(activeObjectLeft, objectTop),
          'center',
          'center'
        )
      }

      // snap by the top edge
      if (isInRange(objectTop - objectHeight / 2, activeObjectTop - activeObjectHeight / 2)) {
        horizontalInTheRange = true
        if (isFabricWorkareaObject(canvasObjects[i])) {
          const x1 = -5000
          const x2 = 5000
          const y = objectTop - objectHeight / 2
          horizontalLines.push({
            y,
            x1,
            x2
          })
        } else {
          horizontalLines.push({
            y: objectTop - objectHeight / 2,
            x1:
              objectLeft < activeObjectLeft
                ? objectLeft - objectWidth / 2 - aligningLineOffset
                : objectLeft + objectWidth / 2 + aligningLineOffset,
            x2:
              activeObjectLeft > objectLeft
                ? activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset
                : activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset
          })
        }
        target.setPositionByOrigin(
          new fabric.Point(activeObjectLeft, objectTop - objectHeight / 2 + activeObjectHeight / 2),
          'center',
          'center'
        )
      }

      // snap by the bottom edge
      if (isInRange(objectTop + objectHeight / 2, activeObjectTop + activeObjectHeight / 2)) {
        horizontalInTheRange = true
        if (isFabricWorkareaObject(canvasObjects[i])) {
          const x1 = -5000
          const x2 = 5000
          const y = objectTop + objectHeight / 2
          horizontalLines.push({
            y,
            x1,
            x2
          })
        } else {
          horizontalLines.push({
            y: objectTop + objectHeight / 2,
            x1:
              objectLeft < activeObjectLeft
                ? objectLeft - objectWidth / 2 - aligningLineOffset
                : objectLeft + objectWidth / 2 + aligningLineOffset,
            x2:
              activeObjectLeft > objectLeft
                ? activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset
                : activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset
          })
        }
        target.setPositionByOrigin(
          new fabric.Point(activeObjectLeft, objectTop + objectHeight / 2 - activeObjectHeight / 2),
          'center',
          'center'
        )
      }
    }

    if (!horizontalInTheRange) {
      horizontalLines = []
    }

    if (!verticalInTheRange) {
      verticalLines = []
    }
  }

  const clearGuidlines = () => {
    verticalLines = []
    horizontalLines = []
  }

  const startGuideline = (currentViewportTransform: number[], currentZoom: number) => {
    viewportTransform = currentViewportTransform
    zoom = currentZoom
  }

  const clearPreviousLines = () => {
    canvas.clearContext(ctx)
  }

  const drawAvailableLines = () => {
    for (let i = verticalLines.length; i--; ) {
      drawVerticalLine(verticalLines[i])
    }
    for (let i = horizontalLines.length; i--; ) {
      drawHorizontalLine(horizontalLines[i])
    }
    clearGuidlines()
  }

  return {
    beforeRender: clearPreviousLines,
    afterRender: drawAvailableLines,
    mouseUp: clearGuidlines,
    mouseMove: movingGuidelines,
    mouseDown: startGuideline
  }
}

export default canvasGuidelines
