import { Grid } from '@mui/material'
import React from 'react'
import { Field } from 'redux-form'
import FieldsExpansionPanel from '../../../../../components/ExtensionPanels/FieldsExpansionPanel'
import MenuField from '../../../../../components/MenuField'
import cssProperties from '../cssProperties'
import { generateFieldPrefix, gridItemProps } from '../utils'
import { ReduxTextField } from '../../../../../components/FormInput/ReduxWrappers'

interface PositionFieldsProps {
  level: number
  childIndexes: number[]
}

const PositionFields: React.FC<PositionFieldsProps> = ({ level, childIndexes }) => (
  <FieldsExpansionPanel label='position'>
    <Grid container spacing={2} alignItems='center'>
      <Grid {...gridItemProps}>
        <MenuField
          label='Display'
          name={generateFieldPrefix(level, childIndexes, 'display')}
          items={cssProperties.display}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <MenuField
          label='Position'
          name={generateFieldPrefix(level, childIndexes, 'position')}
          items={cssProperties.position}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'left')}
          label='Left'
          component={ReduxTextField}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'right')}
          label='Right'
          component={ReduxTextField}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'top')}
          label='Top'
          component={ReduxTextField}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'bottom')}
          label='Bottom'
          component={ReduxTextField}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <MenuField
          label='Box Sizing'
          name={generateFieldPrefix(level, childIndexes, 'boxSizing')}
          items={cssProperties.boxSizing}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'verticalAlign')}
          label='Vertical Align'
          component={ReduxTextField}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'listStyleType')}
          label='List style type'
          component={ReduxTextField}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'transform')}
          label='Transform'
          component={ReduxTextField}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'WebkitTransform')}
          label='WebkitTransform'
          component={ReduxTextField}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'transformOrigin')}
          label='Transform origin'
          component={ReduxTextField}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'float')}
          label='Float'
          component={ReduxTextField}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'zIndex')}
          label='Z-index'
          component={ReduxTextField}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'overflow')}
          label='Overflow'
          component={ReduxTextField}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'gridTemplateColumns')}
          label='Grid template columns'
          component={ReduxTextField}
        />
      </Grid>
    </Grid>
  </FieldsExpansionPanel>
)

export default PositionFields
