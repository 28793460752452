import React, { Fragment } from 'react'
import { MediaQuality } from '@seesignage/seesignage-utils'
import QualityHighIcon from 'mdi-react/QualityHighIcon'
import UhdIcon from 'mdi-react/Video4kBoxIcon'

interface VideoQualityIndicatorProps {
  quality?: MediaQuality
}

const VideoQualityIndicator = ({ quality }: VideoQualityIndicatorProps) =>
  quality ? (
    quality === MediaQuality.hq ? (
      <QualityHighIcon color='red' />
    ) : (
      <UhdIcon color='blue' />
    )
  ) : (
    <Fragment />
  )

export default VideoQualityIndicator
