import React from 'react'
import { FieldArrayFieldsProps, WrappedFieldProps, Field } from 'redux-form'
import { useTranslation } from 'react-i18next'
import {
  Paper,
  List,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  ListItem,
  TextField,
  Grid,
  Tooltip,
  Typography,
  Button
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import CalendarClockIcon from 'mdi-react/CalendarClockIcon'
import DeleteIcon from 'mdi-react/DeleteIcon'
import Autocomplete from '@mui/material/Autocomplete'
import LinkIcon from 'mdi-react/LinkIcon'
import { TFunction } from 'i18next'
import { DropResult, DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { ScreenChannelOption } from '../../../../types/screens'
import colors from '../../../../styles/common/colors'
import { validateNonEmptyObject } from '../../../../validation'

const useChannelFieldStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2)
  },
  parentIcon: {
    color: colors.parentLunchListColor,
    marginRight: theme.spacing(2)
  },
  parentIconContainer: {
    color: colors.parentLunchListColor,
    position: 'relative',
    top: 8
  }
}))

interface ChannelFieldProps extends WrappedFieldProps {
  remove: () => void
  channelOptions: ScreenChannelOption[]
  index: number
  t: TFunction
}

const ChannelField = ({
  input: { value: formValue, onChange, onBlur, onFocus },
  meta: { touched, invalid, error },
  remove,
  channelOptions,
  t
}: ChannelFieldProps) => {
  const value = channelOptions.find(({ channelId }) => channelId === formValue.channelId)
  const classes = useChannelFieldStyles()
  return value?.channelId ? (
    <ListItem>
      <ListItemAvatar>
        <Avatar>
          <CalendarClockIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={value.name} />
      <ListItemSecondaryAction>
        {value.isParent && (
          <Tooltip disableInteractive title={t('general.isParentItem')}>
            <span className={classes.parentIconContainer}>
              <LinkIcon />
            </span>
          </Tooltip>
        )}
        <Tooltip disableInteractive title={t('general.delete')}>
          <IconButton onClick={() => remove()} edge='end' aria-label='delete' size='large'>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  ) : (
    <ListItem>
      <Autocomplete
        fullWidth
        value={value}
        getOptionLabel={option => option.name || ''}
        onChange={(event, value) => onChange(value)}
        autoComplete
        includeInputInList
        noOptionsText={t('general.noOptions')}
        isOptionEqualToValue={(option: ScreenChannelOption, value) =>
          option.channelId === value.channelId
        }
        options={channelOptions}
        renderInput={params => (
          <TextField
            {...params}
            variant={'outlined'}
            onFocus={onFocus}
            onBlur={() => onBlur(undefined)} // make sure onBlur doesn't change field value
            error={touched && invalid}
            helperText={touched && error}
            label={t('screens.selectChannel')}
            fullWidth
          />
        )}
        renderOption={(props, option) => (
          <li {...props}>
            {option.isParent ? (
              <Grid container alignItems='center'>
                <Tooltip disableInteractive title={t('general.isParentItem')}>
                  <Grid item>
                    <LinkIcon className={classes.parentIcon} />
                  </Grid>
                </Tooltip>
                <Grid item xs>
                  <Typography variant='body2'>{option.name}</Typography>
                </Grid>
              </Grid>
            ) : (
              <Grid container alignItems='center'>
                <Grid item xs>
                  <Typography variant='body2'>{option.name}</Typography>
                </Grid>
              </Grid>
            )}
          </li>
        )}
      />
      <ListItemSecondaryAction>
        <Tooltip disableInteractive title={t('general.delete')}>
          <IconButton onClick={() => remove()} edge='end' aria-label='delete' size='large'>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  )
}
interface SelectChannelsFieldProps {
  fields: FieldArrayFieldsProps<ScreenChannelOption | {}>
  channelOptions: ScreenChannelOption[]
  disabled?: boolean
}

const SelectChannelsField: React.FC<SelectChannelsFieldProps> = ({
  fields,
  channelOptions,
  disabled
}) => {
  const [t] = useTranslation()
  const onDragEnd = (reoderItems: (from: number, to: number) => void) => (result: DropResult) => {
    if (!result.destination || result.source.index === result.destination.index) {
      return
    }
    reoderItems(result.source.index, result.destination.index)
  }
  return (
    <Paper style={{ width: '100%' }}>
      <Typography style={{ marginLeft: 4 }} variant='caption'>
        {t('screens.content.channel')}
      </Typography>

      <List dense={true}>
        <DragDropContext onDragEnd={onDragEnd(fields.move)}>
          <Droppable droppableId='droppable'>
            {({ innerRef, placeholder }) => (
              <div ref={innerRef}>
                {fields.map((member, index) => (
                  <Draggable key={member} draggableId={member} index={index}>
                    {({ innerRef, draggableProps, dragHandleProps }) => (
                      <div ref={innerRef} {...draggableProps} {...dragHandleProps}>
                        <Field
                          key={index}
                          name={member}
                          t={t}
                          remove={() => fields.remove(index)}
                          component={ChannelField}
                          required
                          validate={validateNonEmptyObject}
                          channelOptions={channelOptions}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {fields.length < 5 && ( // Limit channels to 5
          <ListItem>
            <Button
              disabled={disabled}
              variant='contained'
              fullWidth
              color='primary'
              onClick={() => fields.push({})}>
              {t('general.addNew')}
            </Button>
          </ListItem>
        )}
      </List>
    </Paper>
  )
}

export default SelectChannelsField
