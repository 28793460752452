import { InfopageContent, Media, TemplateOrientation } from '@seesignage/seesignage-utils'
import { TemplateAsFormValue } from './templates'

export interface InfopageFormContent {
  [id: string]: string | Media
}

export interface CreateInfopageFormData {
  name: string
  isEditPlaylist?: boolean
  template: TemplateAsFormValue
  content: InfopageFormContent
  selectedOrientation?: TemplateOrientation
}

export interface UpdateInfopageFormData extends CreateInfopageFormData {
  infopageId: string
}

export interface CreateInfopage {
  name: string
  content: InfopageContent
  templateId: string
  templateEnvironmentId: string
}

export interface InfopageSettingsFormData {
  template: TemplateAsFormValue
  name: string
  content?: InfopageContent
}

export enum SelectedInfopageType {
  infopage = 'infopage',
  content = 'content'
}
/**
 * Object type of selected Contents and Infopages up for deletion.
 */
export interface SelectedInfopage {
  id: string
  type: SelectedInfopageType
}

export interface InfopagesLastEvaluatedKey {
  environmentId: string
  infopageId: string
  name: string
}
