import { createAction, createAsyncActions } from './utils'

const reducer = 'PRODUCTS'

const resetProducts = createAction(reducer, 'RESET')

const {
  action: getProducts,
  actionSuccess: getProductsSuccess,
  actionFail: getProductsFail
} = createAsyncActions(reducer, 'GET')

const {
  action: createOrUpdateGeneralProduct,
  actionSuccess: createOrUpdateGeneralProductSuccess,
  actionFail: createOrUpdateGeneralProductFail
} = createAsyncActions(reducer, 'CREATE_OR_UPDATE_GENERAL_PRODUCT')

const {
  action: createOrUpdateRetailProduct,
  actionSuccess: createOrUpdateRetailProductSuccess,
  actionFail: createOrUpdateRetailProductFail
} = createAsyncActions(reducer, 'CREATE_OR_UPDATE_RETAIL_PRODUCT')

const {
  action: createProductToList,
  actionSuccess: createProductToListSuccess,
  actionFail: createProductToListFail
} = createAsyncActions(reducer, 'CREATE_TO_LIST')

const clearSuggestions = createAction(reducer, 'CLEAR_SUGGESTIONS')

const {
  action: prefillRetailProductForm,
  actionSuccess: prefillRetailProductFormSuccess,
  actionFail: prefillRetailProductFormFail
} = createAsyncActions(reducer, 'GENERATE_PRODUCT_URL')

const checkProductExists = createAction(reducer, 'CHECK_PRODUCT_EXISTS')
const checkMasterProductExists = createAction(reducer, 'CHECK_MASTER_PRODUCT_EXISTS')

const {
  action: listProducts,
  actionSuccess: listProductsSuccess,
  actionFail: listProductsFail
} = createAsyncActions(reducer, 'LIST')

const {
  action: listProductsFromForm,
  actionSuccess: listProductsFromFormSuccess,
  actionFail: listProductsFromFormFail
} = createAsyncActions(reducer, 'LIST_FROM_FORM')

const {
  action: deleteProduct,
  actionSuccess: deleteProductSuccess,
  actionFail: deleteProductFail
} = createAsyncActions(reducer, 'DELETE')

const {
  action: updatePlaylistProduct,
  actionSuccess: updatePlaylistProductSuccess,
  actionFail: updatePlaylistProductFail
} = createAsyncActions(reducer, 'UPDATE_PLAYLIST_PRODUCT')

const {
  action: updateProductDetails,
  actionFail: updateProductDetailsFail,
  actionSuccess: updateProductDetailsSuccess
} = createAsyncActions(reducer, 'UPDATE_DETAILS')

const {
  action: updatePharmacyProduct,
  actionFail: updatePharmacyProductFail,
  actionSuccess: updatePharmacyProductSuccess
} = createAsyncActions(reducer, 'UPDATE_PHARMACY_PRODUCT')

const {
  action: getMasterPharmacyProduct,
  actionFail: getMasterPharmacyProductFail,
  actionSuccess: getMasterPharmacyProductSuccess
} = createAsyncActions(reducer, 'GET_MASTER_PHARMACY_PRODUCT')

const {
  action: createMasterPharmacyProduct,
  actionFail: createMasterPharmacyProductFail,
  actionSuccess: createMasterPharmacyProductSuccess
} = createAsyncActions(reducer, 'CREATE_MASTER_PHARMACY_PRODUCT')

const clearCustomerProducts = createAction(reducer, 'CLEAR_CUSTOMER_PRODUCTS')

export {
  resetProducts,
  getProducts,
  getProductsSuccess,
  getProductsFail,
  createOrUpdateRetailProduct,
  createOrUpdateRetailProductSuccess,
  createOrUpdateRetailProductFail,
  createOrUpdateGeneralProduct,
  createOrUpdateGeneralProductSuccess,
  createOrUpdateGeneralProductFail,
  createProductToList,
  createProductToListFail,
  createProductToListSuccess,
  clearSuggestions,
  prefillRetailProductForm,
  prefillRetailProductFormSuccess,
  prefillRetailProductFormFail,
  checkProductExists,
  checkMasterProductExists,
  listProducts,
  listProductsSuccess,
  listProductsFail,
  listProductsFromForm,
  listProductsFromFormSuccess,
  listProductsFromFormFail,
  deleteProduct,
  deleteProductSuccess,
  deleteProductFail,
  updatePlaylistProduct,
  updatePlaylistProductSuccess,
  updatePlaylistProductFail,
  clearCustomerProducts,
  updateProductDetails,
  updateProductDetailsFail,
  updateProductDetailsSuccess,
  updatePharmacyProduct,
  updatePharmacyProductFail,
  updatePharmacyProductSuccess,
  getMasterPharmacyProduct,
  getMasterPharmacyProductFail,
  getMasterPharmacyProductSuccess,
  createMasterPharmacyProduct,
  createMasterPharmacyProductFail,
  createMasterPharmacyProductSuccess
}
