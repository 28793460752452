import {
  AutocompleteOption,
  Media,
  MediaQuality,
  CreateMediaFolder,
  MediaType
} from '@seesignage/seesignage-utils'
import { IndexById } from './states'
import { AutocompleteOptionWithCustomValue } from './forms'

export interface DropzoneFieldProps {
  /** Drag and drop files */
  files?: FileWithPreview[]
  /** Folder where to upload file */
  parentFolderId?: string
}

export interface DropzoneIndexedByFormFieldName {
  [formFieldName: string]: DropzoneFieldProps
}

/**
 * There can be multiple forms visible at the same time with dropzone.
 * So dropzonez needs to be indexed by form name and form field name.
 * Then we can show dropzone files in correct dropzone input.
 */
export interface DropzoneIndexedByFormName {
  [formName: string]: DropzoneIndexedByFormFieldName
}

export interface SetDropzoneFilesActionParams extends DropzoneFieldProps {
  formName: string
  formFieldName: string
}

export interface KeysById {
  [key: string]: Media
}
export interface FileProgress {
  /** key of file (S3 key or EMMi assetid) */
  key: string
  /** upload progress (0-100) */
  progress: number
  /** name of file */
  name: string
  /** if video file has been uploaded and is now being transcoded */
  isTranscoding?: boolean
  /** error message */
  error?: string
}

export interface FilesUploadProgress {
  [key: string]: FileProgress
}

export interface FileWithPreview extends File {
  preview: string
  path?: string
}

export const isFileWithPreview = (file: any): file is FileWithPreview => file?.preview !== undefined

export interface MediaPagination {
  itemsPerPage: number
  page: number
}

// FormData

type ParentFolderIdFormFieldValue = AutocompleteOptionWithCustomValue<string | null>

export interface AddMediaFormData {
  files: FileWithPreview[]
  parentFolderId?: ParentFolderIdFormFieldValue
  quality?: MediaQuality | 'fullhd'
  /** single file input support adding media by key */
  isSingleFileInput?: boolean
  /** Use media as field value in single file input. Otherwise, use key as field value. */
  useMediaAsFieldValue?: boolean
  /** Name of the form where we want to add the media after upload */
  formNameToAddMedia: string
  /** Name of the form field where we want to add the media after upload */
  formFieldNameToAddMedia: string
  /** if mediaType is given allow only media from type. Validated in validateAddMediaForm function. */
  allowOnlyMediaType?: MediaType
}
export interface MoveMediaToFolderFormData {
  folder: AutocompleteOption
}

export interface MoveFolderFormData {
  folder: AutocompleteOption
  folderId?: string
}

export interface RenameMediaFormData {
  name: string
  fileType: string
  key: string
}

export interface RemameFolderFormData {
  oldName: string
  newName: string
}

/** Response from media-uploads api if uploaded file already exists */
export interface ExistingFileResponse {
  existingKey: string
}

export interface S3StorageObject {
  eTag: string
  key: string
  lastModified: Date
  size: number
}

export interface UploadFileToS3Response {
  currentFilename: string
  key: string
}

export interface GetUrlForMediaResponse {
  key: string
  url: string
  thumbnailUrl?: string
}

export interface KeyWithUrl {
  key: string
  url: string
  thumbnailUrl?: string
}

export type MediaUrlsByKey = IndexById<KeyWithUrl>

export type CreateFolderFormData = CreateMediaFolder

export interface UpdateFolderFormData extends CreateFolderFormData {
  folderId: string
}

export type CreateMediaFolderFormData = CreateFolderFormData | UpdateFolderFormData

export const isMedia = (media: any): media is Media => !!media?.key
