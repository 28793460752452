import React from 'react'
import { reduxForm, InjectedFormProps, Field, formValueSelector, change } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import classNames from 'classnames'
import VectorPointIcon from 'mdi-react/VectorPointIcon'
import ContentColorPicker from '../../../../../components/FormInput/ColorPicker/ContentColorPicker'
import BorderSettings from '../../../../../components/FormInput/Drawing/BorderSettings'
import { StateInterface } from '../../../../../types/states'
import colors from '../../../../../styles/common/colors'
import ContentToolbarItem from '../ContentToolbarItem/ContentToolbarItem'
import CommonContentFields from './CommonContentFields'

const useStyles = makeStyles(() => ({
  iconSelected: {
    color: colors.seesignageColor
  }
}))

interface StateProps {
  pointEditMode: boolean
}

interface DispatchProps {
  setPointEditMode: (mode: boolean) => void
}

type ContentPolygonFormProps = StateProps & DispatchProps

const ContentPolygonForm: React.FC<ContentPolygonFormProps &
  InjectedFormProps<{}, ContentPolygonFormProps>> = ({ pointEditMode, setPointEditMode }) => {
  const [t] = useTranslation()
  const classes = useStyles()
  const handleEditPoints = () => {
    setPointEditMode(!pointEditMode)
  }

  return (
    <>
      <CommonContentFields formName='ContentPolygonForm' enableRotation enableScale t={t} />
      <ContentToolbarItem
        tooltip={
          (pointEditMode
            ? t('contents.actions.hidePoints')
            : t('contents.actions.editPoints')) as string
        }>
        <IconButton
          onClick={handleEditPoints}
          className={classNames({
            [classes.iconSelected]: pointEditMode
          })}
          size='small'>
          <VectorPointIcon />
        </IconButton>
      </ContentToolbarItem>
      <ContentToolbarItem>
        <Field name='fill' component={ContentColorPicker} tooltip={t('Select fill color')} />
      </ContentToolbarItem>
      <ContentToolbarItem>
        <BorderSettings formName='ContentPolygonForm' />
      </ContentToolbarItem>
    </>
  )
}

const mapStateToProps = (state: StateInterface) => ({
  pointEditMode: formValueSelector('ContentPolygonForm')(state, 'pointEditMode')
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  setPointEditMode: (mode: boolean) => dispatch(change('ContentPolygonForm', 'pointEditMode', mode))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<{}, ContentPolygonFormProps>({
    form: 'ContentPolygonForm',
    enableReinitialize: true
  })(ContentPolygonForm)
)
