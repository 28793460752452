import {
  Grid,
  Paper,
  Typography,
  TextField,
  MenuItem,
  InputAdornment,
  AppBar,
  Toolbar,
  TablePagination
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { ScreenResponse } from '@seesignage/seesignage-utils'
import CalendarRangeIcon from 'mdi-react/CalendarRangeIcon'
import { selectScreensAsArray } from '../../selectors/screens'
import { selectUserIsAuthenticated } from '../../selectors/users'
import { compareStrings } from '../../utils/sorting'
import { StateInterface } from '../../types/states'
import { BreadcrumbContentType } from '../../types/breadcrumbs'
import PageTitle from '../../components/PageTitle'
import colors from '../../styles/common/colors'
import { getStatusesForTimeRange } from '../../utils/screens'
import { TimeRange } from '../../types/screens'
import { toolbarStyles } from '../../styles/common'
import SearchField from '../../components/SearchField'
import StatusesScatterChart from './StatusesScatterChart'

const useStyles = makeStyles(theme => ({
  ...toolbarStyles(theme),
  screensArea: {
    padding: 15,
    marginBottom: 80
  },
  screen: {
    padding: 20,
    marginTop: 10,
    marginBottom: 10
  },
  signalDescriptions: {
    display: 'flex',
    alignItems: 'center'
  },
  colorText: {
    display: 'inline-block',
    verticalAlign: 'middle'
  },
  dot: {
    height: 20,
    width: 20,
    borderRadius: '50%',
    display: 'inline-block',
    verticalAlign: 'middle',
    margin: 6
  },
  timeRange: {
    display: 'flex',
    alignItems: 'center',
    width: '150px',
    marginTop: '10px',
    marginBottom: '20px',
    marginRight: '20px'
  },
  excellentSignal: {
    backgroundColor: colors.signalColors.excellent
  },
  goodSignal: {
    backgroundColor: colors.signalColors.good
  },
  satisfyingSignal: {
    backgroundColor: colors.signalColors.satisfying
  },
  badSignal: {
    backgroundColor: colors.signalColors.bad
  },
  noSignal: {
    backgroundColor: colors.signalColors.no
  },
  timeRangeField: {
    width: '100%'
  },
  inputAdornment: {
    marginRight: '10px'
  },
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 0
  },
  bottomRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end'
  }
}))

interface ConnectionsProps {
  isAuthenticated: boolean
  screens: ScreenResponse[]
}

const Connections: React.FC<ConnectionsProps> = ({ isAuthenticated, screens }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [timeRange, setTimeRange] = useState(TimeRange.week)
  const [page, setPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(25)
  const classes = useStyles()
  const [t] = useTranslation()

  const handleChangeTimeRange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTimeRange(e.target.value as TimeRange)
  }
  const handleChangeSearchTerm = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPage(0)
    setSearchTerm(e.target.value)
  }

  const filteredScreens = screens.filter(({ name }) =>
    name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
  )

  const visibleScreens = filteredScreens.slice(
    page * itemsPerPage,
    page * itemsPerPage + itemsPerPage
  )

  return (
    <Fragment>
      <PageTitle
        contentType={BreadcrumbContentType.screens}
        contentName={t('navigation.connections')}
        contentId='connections'
      />
      <AppBar position='sticky' className={classes.appBar} color='default'>
        <Toolbar>
          <div className={classes.timeRange}>
            <TextField
              variant='standard'
              className={classes.timeRangeField}
              value={timeRange}
              onChange={handleChangeTimeRange}
              select
              label={t('connection.filter.timeRange')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start' className={classes.inputAdornment}>
                    <CalendarRangeIcon />
                  </InputAdornment>
                )
              }}>
              <MenuItem value={TimeRange.all}>{t('connection.filter.allTime')}</MenuItem>
              <MenuItem value={TimeRange.week}>{t('connection.filter.week')}</MenuItem>
              <MenuItem value={TimeRange.threeDays}>{t('connection.filter.threeDays')}</MenuItem>
              <MenuItem value={TimeRange.oneDay}>{t('connection.filter.oneDay')}</MenuItem>
            </TextField>
          </div>
          <SearchField
            value={searchTerm}
            onChange={handleChangeSearchTerm}
            placeholder={t('general.search')}
          />
        </Toolbar>
      </AppBar>

      {isAuthenticated ? (
        <>
          <Grid container className={classes.screensArea}>
            <Grid item xs={12} className={classes.topRow}>
              <div className={classes.signalDescriptions}>
                <span className={`${classes.dot} ${classes.excellentSignal}`} />
                <div className={classes.colorText}>{t('connection.signal.excellent')}</div>
                <span className={`${classes.dot} ${classes.goodSignal}`} />
                <div className={classes.colorText}>{t('connection.signal.good')}</div>
                <span className={`${classes.dot} ${classes.satisfyingSignal}`} />
                <div className={classes.colorText}>{t('connection.signal.satisfying')}</div>
                <span className={`${classes.dot} ${classes.badSignal}`} />
                <div className={classes.colorText}>{t('connection.signal.bad')}</div>
                <span className={`${classes.dot} ${classes.noSignal}`} />
                <div className={classes.colorText}>{t('connection.signal.no')}</div>
              </div>
              <TablePagination
                component='div'
                count={filteredScreens.length}
                rowsPerPage={itemsPerPage}
                page={page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page'
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page'
                }}
                onPageChange={(e, page) => setPage(page)}
                onRowsPerPageChange={(e: any) => setItemsPerPage(e.target.value)}
                labelRowsPerPage={t('general.show')}
              />
            </Grid>
            {visibleScreens.length > 0 ? (
              <Fragment>
                {visibleScreens.map(({ name, statuses }, i: number) => {
                  const statusesForTimeRange = getStatusesForTimeRange(statuses, timeRange)

                  return (
                    <Grid item xs={12} key={`${name}-${i}`}>
                      <Paper className={classes.screen} elevation={1}>
                        <Typography variant='body2' gutterBottom>
                          {name}
                        </Typography>
                        {statusesForTimeRange.length > 0 ? (
                          <StatusesScatterChart
                            statuses={statusesForTimeRange}
                            timeRange={timeRange}
                          />
                        ) : (
                          <Typography variant='body1' gutterBottom>
                            {t('connection.noAvailableData')}
                          </Typography>
                        )}
                      </Paper>
                    </Grid>
                  )
                })}
                <div className={classes.bottomRow}>
                  <TablePagination
                    component='div'
                    count={filteredScreens.length}
                    rowsPerPage={itemsPerPage}
                    page={page}
                    backIconButtonProps={{
                      'aria-label': 'Previous Page'
                    }}
                    nextIconButtonProps={{
                      'aria-label': 'Next Page'
                    }}
                    onPageChange={(e, page) => setPage(page)}
                    onRowsPerPageChange={(e: any) => setItemsPerPage(e.target.value)}
                    labelRowsPerPage={t('general.show')}
                  />
                </div>
              </Fragment>
            ) : (
              <Typography variant='body1' gutterBottom>
                {t('connection.no')} <Link to='/screens'>{t('connection.screens')}</Link>{' '}
                {t('connection.available')}
              </Typography>
            )}
          </Grid>
        </>
      ) : (
        <div />
      )}
    </Fragment>
  )
}

const mapStateToProps = (state: StateInterface) => ({
  isAuthenticated: selectUserIsAuthenticated(state),
  screens: selectScreensAsArray(state).sort(compareStrings('name'))
})

export default connect(mapStateToProps)(Connections)
