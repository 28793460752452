import React, { Fragment } from 'react'
import { AppBar, Tab, Tabs } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { toolbarStyles } from '../../styles/common'
import PageTitle from '../../components/PageTitle'
import { BreadcrumbContentType } from '../../types/breadcrumbs'
import ProofOfPlay from './Reports/ProofOfPlay'

const useStyles = makeStyles(toolbarStyles)

enum TabSection {
  reports = 0
}

interface TabPanelProps {
  children?: React.ReactNode
  index: TabSection
  value: TabSection
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props
  return (
    value === index && (
      <div
        role='tabpanel'
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}>
        {children}
      </div>
    )
  )
}

const a11yProps = (index: TabSection) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`
})

const Analytics = () => {
  const [t] = useTranslation()
  const classes = useStyles()
  const [value, setValue] = React.useState<TabSection>(TabSection.reports)
  const handleChange = (_event: React.SyntheticEvent, newValue: TabSection) => {
    setValue(newValue)
  }
  return (
    <Fragment>
      <PageTitle contentType={BreadcrumbContentType.analytics} />
      <AppBar className={classes.appBar} position='static' color='default'>
        <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
          <Tab
            label={t('analytics.tabs.reports')}
            {...a11yProps(TabSection.reports)}
            sx={{ paddingY: '20px' }}
          />
        </Tabs>
      </AppBar>
      <CustomTabPanel value={value} index={TabSection.reports}>
        <ProofOfPlay />
      </CustomTabPanel>
    </Fragment>
  )
}

export default Analytics
