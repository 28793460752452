import React from 'react'
import { WarningPriority } from '@seesignage/seesignage-utils'
import classNames from 'classnames'
import { Typography } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import colors from '../../../styles/common/colors'

const styles = () => ({
  priority: {
    borderRadius: '8px',
    textAlign: 'center' as 'center',
    padding: '2px 6px',
    color: '#ffffff'
  },
  priorityLow: {
    backgroundColor: colors.priorities.low
  },
  priorityMedium: {
    backgroundColor: colors.priorities.medium
  },
  priorityHigh: {
    backgroundColor: colors.priorities.high
  },
  priorityEmergency: {
    backgroundColor: colors.priorities.emergency
  }
})

interface WarningPriorityCellProps extends WithStyles<typeof styles> {
  priority: WarningPriority
}

const WarningPriorityCell: React.FC<WarningPriorityCellProps> = ({ classes, priority }) => (
  <Typography
    variant='body2'
    gutterBottom
    className={classNames({
      [classes.priority]: true,
      [classes.priorityLow]: priority === WarningPriority.low,
      [classes.priorityMedium]: priority === WarningPriority.medium,
      [classes.priorityHigh]: priority === WarningPriority.high,
      [classes.priorityEmergency]: priority === WarningPriority.emergency
    })}>
    {priority}
  </Typography>
)

export default withStyles(styles)(WarningPriorityCell)
