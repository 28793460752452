import React from 'react'
import { FieldArray } from 'redux-form'
import ExpandIcon from 'mdi-react/ChevronDownIcon'
import { Grid, Accordion, AccordionSummary, AccordionDetails, Paper } from '@mui/material'
import {
  ComponentType,
  SchemaComponents,
  StylesChild,
  MediaType
} from '@seesignage/seesignage-utils'
import Icon from '../../../components/ExtensionPanels/Icon'
import CustomMedia from './CustomMedia'
import CustomTextField from './CustomTextField'

const formComponents = {
  div: CustomMedia,
  text: CustomTextField,
  image: CustomMedia,
  video: CustomMedia
}

const getPanelSummary = (fieldType: ComponentType, label: string) => (
  <div style={{ display: 'flex' }}>
    <div style={{ float: 'left' }}>
      <Icon type={fieldType} />
    </div>
    <div style={{ padding: '4px 0px 0px 5px' }}>{`${label}`}</div>
  </div>
)
/** Object to determine if component will be rendered in FieldArray
 * - False if field already rendered.
 * - This can happen if two schema components have been given the same key
 */
interface RenderField {
  [id: string]: boolean
}

interface CreateInfopageContentFormProps {
  formName: string
  /** template form schema */
  currentComponentSchema: SchemaComponents
  /** tempate styles needed for wysiwyg default values*/
  currentStyles?: StylesChild
  renderField: RenderField
  level: number
  childrenList: any
}

const CreateInfopageContentForm: React.FC<CreateInfopageContentFormProps> = ({
  currentComponentSchema,
  currentStyles,
  renderField,
  level,
  childrenList,
  formName
}) => {
  const currentStylesChildren: any = currentStyles?.children
  const fieldType = currentComponentSchema.type
  const textAllowed = fieldType === ComponentType.text && currentComponentSchema.allow?.text
  const wysiwygAllowed = fieldType === ComponentType.text && currentComponentSchema.allow?.wysiwyg
  const imageAllowed = fieldType === ComponentType.image && currentComponentSchema.allow?.image
  const isVideoAllowed = fieldType === ComponentType.video && currentComponentSchema.allow?.video
  const backgroundAllowed =
    fieldType === ComponentType.div && currentComponentSchema.allow?.background
  const label = currentComponentSchema.name || fieldType
  const schemaId = currentComponentSchema.id

  const isComponentAllowed = textAllowed || imageAllowed || backgroundAllowed || isVideoAllowed
  if (isComponentAllowed) {
    renderField[schemaId] = renderField[schemaId] ? false : true
  }

  const FormComponent = isComponentAllowed ? formComponents[fieldType] : undefined

  const componentChildren = currentComponentSchema.children
  const mediaType =
    imageAllowed || backgroundAllowed
      ? MediaType.image
      : isVideoAllowed
      ? MediaType.video
      : undefined

  const currentFormProps: any = {
    id: schemaId,
    styles: currentStyles?.styles,
    level,
    childrenList,
    wysiwygAllowed,
    editorBackgroundColor: currentComponentSchema.editorBackgroundColor,
    mediaType,
    formName
  }

  return (
    <Grid item xs={12}>
      {FormComponent &&
        renderField[schemaId] &&
        (!textAllowed || (textAllowed && wysiwygAllowed) ? (
          <Accordion style={{ margin: 8, width: '100%' }}>
            <AccordionSummary expandIcon={<ExpandIcon />}>
              {getPanelSummary(fieldType, label)}
            </AccordionSummary>
            <AccordionDetails style={{ display: 'block' }}>
              <FormComponent {...currentFormProps} />
            </AccordionDetails>
          </Accordion>
        ) : (
          <Paper style={{ padding: '10px 24px', margin: 8 }}>
            <Grid container>
              <Grid item xs={12} sm={3}>
                <div style={{ paddingTop: 15 }}>{getPanelSummary(fieldType, label)}</div>
              </Grid>
              <Grid item xs={12} sm={9}>
                <FormComponent {...currentFormProps} />
              </Grid>
            </Grid>
          </Paper>
        ))}
      {// if children, continue recursion.
      componentChildren &&
        Object.values(componentChildren).map((schema, childrenIndex) => {
          const listOfChildren = [...childrenList, childrenIndex]
          const formProps = {
            component: CreateInfopageContentForm,
            props: {
              currentStyles: currentStylesChildren
                ? currentStylesChildren[childrenIndex]
                : undefined,
              level: level + 1,
              childrenList: listOfChildren,
              currentComponentSchema: schema,
              renderField,
              formName
            },
            name: 'children'
          }
          return <FieldArray key={`${schema.id}-${childrenIndex}`} {...formProps} />
        })}
    </Grid>
  )
}

export default CreateInfopageContentForm
