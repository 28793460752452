import { Grid, Tooltip, Typography, Chip } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import RssBoxIcon from 'mdi-react/RssBoxIcon'
import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Truncate from 'react-truncate'
import { isFeedPlaylistItem, FeedPlaylistItemUI } from '@seesignage/seesignage-utils'
import { PlaylistItemCardRootProps } from '../../../../../types/playlists'
import { StateInterface } from '../../../../../types/states'
import { selectTemplateById } from '../../../../../selectors/templates'
import colors from '../../../../../styles/common/colors'
import { StateTemplate } from '../../../../../types/templates'
import SelectedIndicator from './SelectedIndicator'
import ItemIcons from './ItemIcons'

const styles = () => ({
  item: {
    height: 130,
    overflow: 'hidden'
  },
  itemTitleText: {
    padding: '4px',
    width: '100%',
    overflow: 'hidden'
  },
  mediaBox: {
    backgroundColor: 'white',
    display: 'flex',
    height: '100%',
    width: '100%'
  },
  media: {
    width: '100%',
    height: '100%',
    objectFit: 'contain' as any
  },
  itemDescriptionArea: {
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)'
  },
  icon: {
    margin: 'auto'
  },
  selectIndicatorContainer: {
    position: 'relative' as any
  },
  feedIcon: {
    float: 'left' as any,
    padding: '0px 4px 0px 4px'
  },
  feedConditions: {
    padding: 8,
    display: 'inline-block',
    alignSelf: 'flex-end'
  },
  feedPropertyChip: {
    margin: 2
  }
})

interface OwnProps extends PlaylistItemCardRootProps {
  item: FeedPlaylistItemUI
  isMobile: boolean
}

interface StateProps {
  template?: StateTemplate
}

type UrlItemProps = OwnProps & StateProps & WithTranslation & WithStyles<typeof styles>

const UrlItem: React.FC<UrlItemProps> = ({
  classes,
  item,
  isItemSelected,
  isMobile,
  template,
  itemPercentage,
  showPercentage,
  t
}) => {
  const { thumbnailUrl } = item
  return (
    <Grid container className={classes.item}>
      <Grid item xs={4} className={classes.mediaBox}>
        {item.feedConditions?.rules ? (
          <Grid container>
            <Grid item xs={12} style={{ backgroundImage: `url(${thumbnailUrl})`, display: 'flex' }}>
              <div className={classes.feedConditions}>
                {item.feedConditions.rules.map(({ property }, i) => (
                  <Tooltip
                    disableInteractive
                    key={`${i}-${property}`}
                    title='feed no longer supported. Remove feed items.'>
                    <Chip size='small' label={property} className={classes.feedPropertyChip} />
                  </Tooltip>
                ))}
              </div>
            </Grid>
          </Grid>
        ) : (
          <RssBoxIcon className={classes.icon} size='70px' color={colors.seesignageColor} />
        )}
      </Grid>
      <Grid item xs={8} className={classes.itemDescriptionArea}>
        <Grid container style={{ height: '100%' }}>
          <Grid item xs={12}>
            <Grid container className={classes.itemTitleText}>
              <Grid item xs={11}>
                <Tooltip disableInteractive title={item.url}>
                  <Typography
                    style={{ marginLeft: 4 }}
                    align='left'
                    variant='subtitle1'
                    gutterBottom>
                    {isFeedPlaylistItem(item) && (
                      <div className={classes.feedIcon}>
                        <RssBoxIcon color={colors.seesignageColor} />
                      </div>
                    )}
                    <Truncate lines={1} width={isMobile ? 160 : 300}>
                      {item.url}
                    </Truncate>
                  </Typography>
                </Tooltip>
                {template && (
                  <Typography align='left' variant='subtitle1'>
                    {`${t('lists.templateName', { name: template.name })}`}
                  </Typography>
                )}
              </Grid>
              <SelectedIndicator
                isItemSelected={isItemSelected}
                showPercentage={showPercentage}
                itemPercentage={itemPercentage}
              />
            </Grid>
          </Grid>
        </Grid>
        <ItemIcons item={item} />
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state: StateInterface, ownProps: OwnProps): StateProps => {
  const templateId = isFeedPlaylistItem(ownProps.item) ? ownProps.item.templateId : undefined
  return {
    template: selectTemplateById(templateId)(state)
  }
}
export default connect(mapStateToProps)(withStyles(styles)(withTranslation()(UrlItem)))
