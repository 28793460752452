import React, { Fragment } from 'react'
import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import CheckCircleIcon from 'mdi-react/CheckCircleIcon'
import { Media, MediaType } from '@seesignage/seesignage-utils'
import useWidth from '../../utils/hooks/useWidth'
import useIsWidthUp from '../../utils/hooks/useIsWidthUp'
import VideoQualityIndicator from './VideoQualityIndicator'
import MediaSkeleton from './MediaSkeleton'

const useStyles = makeStyles(() => ({
  imageList: { width: '100%' },
  imageListItem: {
    // overwrite material-ui's list item's image elment
    '& .MuiImageListItem-img': {
      display: 'block',
      margin: 'auto',
      height: '100%',
      width: '100%',
      objectFit: 'contain'
    }
  },
  video: {
    height: '100%',
    width: '100%',
    display: 'block',
    margin: 'auto'
  },
  title: {
    fontSize: '0.8rem'
  },
  tileBar: {
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
  }
}))

interface OwnProps {
  mediaFiles: Media[]
  selectedMediaKeys: string[]
  deselectMedia: (key: string) => void
  selectMedia: (media: Media) => void
  pushMediaToList?: (item: Media) => void
}

type MediaGridListProps = OwnProps

const MediaGridList: React.FC<MediaGridListProps> = ({
  mediaFiles,
  selectedMediaKeys,
  deselectMedia,
  selectMedia,
  pushMediaToList
}) => {
  const width = useWidth()
  const classes = useStyles()

  const isWidthUpXL = useIsWidthUp('xl', width)
  const isWidthUpLG = useIsWidthUp('lg', width)
  const isWidthUpMD = useIsWidthUp('md', width)
  const getGridListCols = () => {
    if (isWidthUpXL) {
      return 6
    }

    if (isWidthUpLG) {
      return 5
    }

    if (isWidthUpMD) {
      return 4
    }

    return 2
  }

  return (
    <ImageList cols={getGridListCols()} className={classes.imageList} gap={8} rowHeight={188}>
      {mediaFiles.map(media => {
        const { key, type, url, thumbnailUrl, name } = media
        const thumbUrl = thumbnailUrl || url
        return (
          <ImageListItem
            key={key}
            className={classes.imageListItem}
            onClick={() => {
              if (typeof pushMediaToList === 'function') {
                pushMediaToList(media)
              } else if (selectedMediaKeys.includes(key)) {
                deselectMedia(key)
              } else {
                selectMedia(media)
              }
            }}>
            {thumbUrl ? (
              type === MediaType.image ? (
                <img src={thumbUrl} alt={name} />
              ) : (
                <video
                  className={classes.video}
                  preload='none'
                  poster={thumbUrl}
                  controls
                  src={url}
                />
              )
            ) : (
              <MediaSkeleton />
            )}
            <div>
              <ImageListItemBar
                title={
                  <Tooltip disableInteractive title={name}>
                    <Typography noWrap>{name}</Typography>
                  </Tooltip>
                }
                position='top'
                classes={{ title: classes.title }}
                className={classes.tileBar}
                actionIcon={
                  <IconButton size='large'>
                    <VideoQualityIndicator quality={(media as Media)?.quality} />
                    {!(typeof pushMediaToList === 'function') && (
                      <Fragment>
                        {selectedMediaKeys.includes(key) ? (
                          <CheckCircleIcon color='lightGreen' />
                        ) : (
                          <div style={{ opacity: 0.8 }}>
                            <CheckCircleIcon color='white' />
                          </div>
                        )}
                      </Fragment>
                    )}
                  </IconButton>
                }
                actionPosition='right'
              />
            </div>
          </ImageListItem>
        )
      })}
    </ImageList>
  )
}
export default MediaGridList
