import { Button, CircularProgress, Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import MagnifyIcon from 'mdi-react/MagnifyIcon'
import FoodAppleIcon from 'mdi-react/FoodAppleIcon'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Field, InjectedFormProps, formValueSelector, reduxForm, reset } from 'redux-form'
import { AutocompleteOption } from '@seesignage/seesignage-utils'
import { addListItem } from '../../../actions/lists'
import { getProducts } from '../../../actions/products'
import { selectIsProductsSearching, selectProductsAsOptions } from '../../../selectors/products'
import { selectIsUserDeviceMobile } from '../../../selectors/users'
import { ReduxSelectWithAutocomplete } from '../../../components/FormInput/ReduxWrappers'
import { bindSubmitActionToPromise } from '../../../utils/forms'

const SearchProductFormName = 'SearchProductForm'

const useStyles = makeStyles(theme => ({
  formContainer: {
    marginBottom: theme.spacing(),
    width: 400
  },
  formContainerMobile: {
    marginBottom: theme.spacing(),
    width: 200
  }
}))

const SearchProductForm: React.FC<InjectedFormProps<{}, {}>> = ({ handleSubmit, error }) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const isMobile = useSelector(selectIsUserDeviceMobile)
  const productsAsOptions = useSelector(selectProductsAsOptions)
  const productsSearching = useSelector(selectIsProductsSearching)
  const selectedProduct: AutocompleteOption = useSelector(state =>
    formValueSelector(SearchProductFormName)(state, 'product')
  )
  return (
    <form onSubmit={handleSubmit(bindSubmitActionToPromise(dispatch, addListItem))}>
      <Grid
        container
        spacing={0}
        className={isMobile ? classes.formContainerMobile : classes.formContainer}>
        <Grid item xs={10} sm={10}>
          <Field
            name='product'
            placeholder={t('lists.searchProduct')}
            options={productsAsOptions}
            onTextFieldInputChangeFunction={(input: string) => dispatch(getProducts({ input }))}
            component={ReduxSelectWithAutocomplete}
            required
            OptionIcon={FoodAppleIcon}
            popupIcon={<MagnifyIcon />}
            size='small'
          />
        </Grid>
        {productsSearching && (
          <Grid item xs={1}>
            <CircularProgress />
          </Grid>
        )}
        <Grid item xs={1} sm={1}>
          <Button color='primary' type='submit' disabled={selectedProduct ? false : true}>
            {t('general.add')}
          </Button>
        </Grid>
        {error && (
          <Typography variant='caption' color='error'>
            {error}
          </Typography>
        )}
      </Grid>
    </form>
  )
}

export default reduxForm({
  form: SearchProductFormName,
  onSubmitSuccess: (_result, dispatch) => {
    dispatch(reset(SearchProductFormName))
  }
})(SearchProductForm)
