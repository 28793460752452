import { Grid, IconButton, MenuItem, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { Field, reduxForm, InjectedFormProps, formValueSelector } from 'redux-form'
import {
  CustomerImages,
  CustomerLanguage,
  ProductForm,
  AutocompleteOption,
  CustomerIntegrationApiSource
} from '@seesignage/seesignage-utils'
import DeleteIcon from 'mdi-react/DeleteIcon'
import { required } from '../../../validation'
import {
  ReduxSelectWithAutocomplete,
  ReduxSwitch,
  ReduxTextField
} from '../../../components/FormInput/ReduxWrappers'
import { CloseDialog } from '../../../types/actions'
import DialogActions from '../../../components/DialogActions'
import SimpleExtensionPanel from '../../../components/ExtensionPanels/SimpleExtensionPanel'
import { StateInterface } from '../../../types/states'
import { selectAllEnvironmentsAsOptions } from '../../../selectors/environments'
import { CustomerFormValuesUI } from '../../../types/customers'
import { removeIntegrationFromForm } from '../../../actions/customers'

interface OwnProps {
  submitAction: (formData: any) => void
  closeDialog: CloseDialog
  dialogId: string
  isUpdate?: boolean
}

interface DispatchProps {
  removeIntegrationFromForm: (integration: string) => void
}

interface StateProps {
  environmentsAsOptions: AutocompleteOption[]
  images?: CustomerImages
}

type FormProps = CustomerFormValuesUI

type ComponentProps = OwnProps & StateProps & DispatchProps

type CreateCustomerFormProps = ComponentProps & WithTranslation

const CreateCustomerForm: React.FC<CreateCustomerFormProps &
  InjectedFormProps<FormProps, ComponentProps>> = ({
  error,
  handleSubmit,
  submitting,
  submitAction,
  closeDialog,
  dialogId,
  isUpdate,
  environmentsAsOptions,
  images,
  t,
  removeIntegrationFromForm
}) => (
  <form onSubmit={handleSubmit(submitAction)}>
    <Field
      fullWidth
      name='name'
      label={t('customers.form.name')}
      component={ReduxTextField}
      required
      validate={[required]}
    />
    <Field
      id='environmentId'
      name='environmentId'
      placeholder={t('customers.form.environment')}
      label={t('customers.form.environment')}
      options={[...environmentsAsOptions]}
      component={ReduxSelectWithAutocomplete}
      required
      validate={[required]}
      disabled={isUpdate}
    />
    <Field
      label={t('customers.form.productForm')}
      name='productForm'
      component={ReduxTextField}
      fullWidth
      select>
      <MenuItem value=''>None</MenuItem>
      {Object.values(ProductForm).map(productForm => (
        <MenuItem key={productForm} value={productForm}>
          {productForm}
        </MenuItem>
      ))}
    </Field>
    <Field
      label={t('customers.form.language')}
      name='language'
      component={ReduxTextField}
      fullWidth
      select>
      <MenuItem value=''>None</MenuItem>
      {Object.values(CustomerLanguage).map(language => (
        <MenuItem key={language} value={language}>
          {language}
        </MenuItem>
      ))}
    </Field>
    <Field fullWidth name='apiKey' label={t('customers.form.apiKey')} component={ReduxTextField} />
    <SimpleExtensionPanel title={t('customers.form.selectTntegration')} isOpen={true}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SimpleExtensionPanel title={t('customers.form.retail')} isOpen={false}>
            <Grid container spacing={2}>
              <Field
                fullWidth
                name='integration.retail.storeId'
                label={t('customers.form.integration.storeId')}
                component={ReduxTextField}
                helperText={t('customers.form.integration.storeIdInfo')}
              />
              <Grid item xs={5}>
                <Field
                  id='integration'
                  label={t('customers.form.integration.dataSource')}
                  name='integration.retail.dataSource'
                  component={ReduxTextField}
                  fullWidth
                  select>
                  <MenuItem value=''>None</MenuItem>
                  <MenuItem value='kRuoka'>kRuoka API</MenuItem>
                  <MenuItem value='sok'>S-kaupat API</MenuItem>
                </Field>
              </Grid>
              <Grid item xs={5}>
                <Field
                  name='integration.retail.prefill'
                  component={ReduxSwitch}
                  label={t('customers.form.integration.prefill')}
                />
              </Grid>
              <Grid item sm={2}>
                <Tooltip disableInteractive title={t('customers.form.integration.remove')}>
                  <IconButton
                    onClick={() => removeIntegrationFromForm('retail')}
                    aria-label={t('customers.form.integration.remove')}
                    style={{ float: 'right' }}
                    size='large'>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </SimpleExtensionPanel>
        </Grid>
        <Grid item xs={12}>
          <SimpleExtensionPanel title='EMMi' isOpen={false}>
            <Grid container spacing={2}>
              <Grid item sm={10}>
                <Field
                  fullWidth
                  name='integration.emmi.url'
                  label={t('customers.form.integration.emmiUrl')}
                  component={ReduxTextField}
                />
              </Grid>
              <Grid item sm={2}>
                <Tooltip disableInteractive title={t('customers.form.integration.remove')}>
                  <IconButton
                    onClick={() => removeIntegrationFromForm('emmi')}
                    aria-label={t('customers.form.integration.remove')}
                    style={{ float: 'right' }}
                    size='large'>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item sm={5}>
                <Field
                  fullWidth
                  name='integration.emmi.username'
                  label={t('customers.form.integration.username')}
                  component={ReduxTextField}
                />
              </Grid>
              <Grid item sm={5}>
                <Field
                  fullWidth
                  name='integration.emmi.password'
                  label={t('customers.form.integration.password')}
                  component={ReduxTextField}
                  helperText={t('customers.form.integration.passwordInfo')}
                />
              </Grid>
            </Grid>
          </SimpleExtensionPanel>
        </Grid>
        <Grid item xs={12}>
          <SimpleExtensionPanel title='Pricer Plaza' isOpen={false}>
            <Grid container spacing={2}>
              <Grid item sm={5}>
                <Field
                  fullWidth
                  name='integration.pricer.username'
                  label={t('customers.form.integration.username')}
                  component={ReduxTextField}
                />
              </Grid>
              <Grid item sm={5}>
                <Field
                  fullWidth
                  name='integration.pricer.password'
                  label={t('customers.form.integration.password')}
                  component={ReduxTextField}
                  helperText={t('customers.form.integration.passwordInfo')}
                />
              </Grid>
              <Grid item sm={2}>
                <Tooltip disableInteractive title={t('customers.form.integration.remove')}>
                  <IconButton
                    onClick={() => removeIntegrationFromForm('pricer')}
                    aria-label={t('customers.form.integration.remove')}
                    style={{ float: 'right' }}
                    size='large'>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  name='integration.pricer.cmApiUrl'
                  label={t('customers.form.integration.cmApiUrl')}
                  component={ReduxTextField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  name='integration.pricer.storeApiUrl'
                  label={t('customers.form.integration.storeApiUrl')}
                  component={ReduxTextField}
                />
              </Grid>
            </Grid>
          </SimpleExtensionPanel>
        </Grid>
        <Grid item xs={12}>
          <SimpleExtensionPanel title='API' isOpen={false}>
            <Grid container spacing={2}>
              <Grid item sm={10}>
                <Field
                  fullWidth
                  name='integration.api.apiKey'
                  label='apikey'
                  component={ReduxTextField}
                  helperText={t('customers.form.integration.passwordInfo')}
                />
              </Grid>
              <Grid item sm={2}>
                <Tooltip disableInteractive title={t('customers.form.integration.remove')}>
                  <IconButton
                    onClick={() => removeIntegrationFromForm('api')}
                    aria-label={t('customers.form.integration.remove')}
                    style={{ float: 'right' }}
                    size='large'>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item sm={12}>
                <Field
                  fullWidth
                  name='integration.api.url'
                  label='url'
                  component={ReduxTextField}
                />
              </Grid>
              <Grid item sm={5}>
                <Field
                  fullWidth
                  name='integration.api.source'
                  label={t('customers.form.integration.apiSource')}
                  select
                  component={ReduxTextField}>
                  {Object.values(CustomerIntegrationApiSource).map(source => (
                    <MenuItem key={source} value={source}>
                      {source}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
            </Grid>
          </SimpleExtensionPanel>
        </Grid>
      </Grid>
    </SimpleExtensionPanel>
    <SimpleExtensionPanel title={t('customers.form.selectTables')} isOpen={true}>
      <Grid container spacing={2}>
        <Field fullWidth name='tables.retail' label='retail' component={ReduxTextField} />
        <Field fullWidth name='tables.pharmacy' label='pharmacy' component={ReduxTextField} />
        <Field fullWidth name='tables.fish' label='fish' component={ReduxTextField} />
        <Field fullWidth name='tables.meat' label='meat' component={ReduxTextField} />
      </Grid>
    </SimpleExtensionPanel>
    <SimpleExtensionPanel title={t('customers.form.selectImages')} isOpen={true}>
      {!images?.foodie && (
        <Grid item xs={6}>
          <Field name='images.kRuoka' component={ReduxSwitch} label='kRuoka' />
        </Grid>
      )}
      {!images?.kRuoka && (
        <Grid item xs={6}>
          <Field name='images.foodie' component={ReduxSwitch} label='foodie' />
        </Grid>
      )}
    </SimpleExtensionPanel>
    <Typography variant='body1' color='error'>
      {error || ''}
    </Typography>
    <DialogActions
      dialogId={dialogId}
      submitting={submitting}
      closeDialog={closeDialog}
      submitButtonLabel={t(`general.${isUpdate ? 'update' : 'add'}`)}
    />
  </form>
)

const selector = formValueSelector('CreateCustomerForm')

const mapStateToProps = (state: StateInterface): StateProps => ({
  environmentsAsOptions: selectAllEnvironmentsAsOptions(state),
  images: selector(state, 'images')
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  removeIntegrationFromForm: (integration: string) =>
    dispatch(removeIntegrationFromForm(integration))
})

export default connect<StateProps, DispatchProps, OwnProps, StateInterface>(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<FormProps, ComponentProps>({
    form: 'CreateCustomerForm'
  })(withTranslation()(CreateCustomerForm))
)
