export interface LocalStorageTours {
  [tour: string]: string
}

export enum Tour {
  subCampaignView = 'subCampaignView',
  createCampaign = 'createCampaign',
  editCampaign = 'editCampaign',
  editSubCampaign = 'editSubCampaign',
  createChannel = 'createChannel',
  createChannelItem = 'createChannelItem',
  createPlaylistItem = 'createPlaylistItem',
  addMediaPlaylistItemWizard = 'addMediaPlaylistItemWizard',
  addRevolverPlaylistItemWizard = 'addRevolverPlaylistItemWizard',
  navigation = 'navigation'
}
