import React from 'react'
import { useTranslation } from 'react-i18next'
import { InjectedFormProps, Field, reduxForm } from 'redux-form'
import { Grid, DialogActions, Button, Typography, MenuItem } from '@mui/material'
import {
  PharmacyPackageUnit,
  formatDecimalsToCurrency,
  parseCurrencyStringToNumber
} from '@seesignage/seesignage-utils'
import { useDispatch } from 'react-redux'
import { CreatePharmacyMasterProductFormData } from '../../../types/products'
import { ReduxTextField } from '../../../components/FormInput/ReduxWrappers'
import { bindSubmitActionToPromise, parseNumberField } from '../../../utils/forms'
import { createMasterPharmacyProduct } from '../../../actions/products'
import { closeDialog } from '../../../actions/dialogs'
import { required } from '../../../validation'
import ErrorMessage from '../../../components/Errors/ErrorMessage'
import { asyncValidateCreateMasterProduct } from '../../../validation/asyncValidate'

const packageUnits = Object.values(PharmacyPackageUnit)

interface OwnProps {
  dialogId: string
}

const CreateMasterDrugForm: React.FC<OwnProps &
  InjectedFormProps<CreatePharmacyMasterProductFormData, OwnProps>> = ({
  dialogId,
  handleSubmit,
  error,
  invalid,
  submitting
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const createProduct = bindSubmitActionToPromise(dispatch, createMasterPharmacyProduct)
  const textFieldProps = {
    fullWidth: true,
    variant: 'outlined' as 'outlined'
  }
  return (
    <form onSubmit={handleSubmit(createProduct)}>
      <Grid justifyContent='center' container spacing={1}>
        <Grid item xs={12}>
          <Typography variant='caption'>{t('products.pharmacy.createNewHelper')}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Field
            name='product.ean'
            required
            validate={[required]}
            {...textFieldProps}
            component={ReduxTextField}
            label={t('products.pharmacy.ean')}
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            name='product.vnr'
            {...textFieldProps}
            component={ReduxTextField}
            label={t('products.pharmacy.vnr')}
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            name='product.sku'
            {...textFieldProps}
            component={ReduxTextField}
            label={t('products.pharmacy.sku')}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name='product.name'
            required
            validate={[required]}
            {...textFieldProps}
            component={ReduxTextField}
            label={t('products.pharmacy.name')}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name='product.marketer'
            {...textFieldProps}
            component={ReduxTextField}
            required
            validate={[required]}
            label={t('products.pharmacy.marketer')}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name='product.packageSize'
            parse={parseNumberField}
            {...textFieldProps}
            type='number'
            component={ReduxTextField}
            label={t('products.pharmacy.packageSize')}
            helperText={t('general.optional')}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name='product.packageUnit'
            {...textFieldProps}
            component={ReduxTextField}
            label={t('products.pharmacy.packageUnit')}
            helperText={t('general.optional')}
            select>
            {packageUnits.map(unit => (
              <MenuItem key={unit} value={unit}>
                {unit}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name='product.packageMultiplier'
            parse={parseNumberField}
            {...textFieldProps}
            type='number'
            component={ReduxTextField}
            label={t('products.pharmacy.packageMultiplier')}
            helperText={t('general.optional')}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            {...textFieldProps}
            name='product.price'
            format={formatDecimalsToCurrency}
            parse={parseCurrencyStringToNumber}
            type='number'
            component={ReduxTextField}
            label={t('products.pharmacy.price')}
            helperText={t('general.optional')}
            InputProps={{
              endAdornment: '€'
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name='product.description'
            {...textFieldProps}
            disabled={false}
            multiline
            maxRows={4}
            component={ReduxTextField}
            label={t('products.pharmacy.description')}
            helperText={t('general.optional')}
          />
        </Grid>
      </Grid>
      {error && <ErrorMessage message={error} />}
      <DialogActions>
        <Button color='primary' onClick={() => dispatch(closeDialog(dialogId))}>
          {t('general.cancel')}
        </Button>
        <Button disabled={submitting || invalid} color='primary' type='submit'>
          {t('general.create')}
        </Button>
      </DialogActions>
    </form>
  )
}

export default reduxForm<CreatePharmacyMasterProductFormData, OwnProps>({
  form: 'CreateMasterDrugForm',
  asyncValidate: asyncValidateCreateMasterProduct,
  asyncBlurFields: ['product.ean', 'product.vnr']
})(CreateMasterDrugForm)
