import React from 'react'
import { useTranslation } from 'react-i18next'
import { Field } from 'redux-form'
import { RssFeedTheme } from '@seesignage/seesignage-utils'
import { MenuItem, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import SimpleExtensionPanel from '../../../../../../components/ExtensionPanels/SimpleExtensionPanel'
import { themeChange, changeThemeToCustom } from '../../../../../../utils/fabric/customDispatches'
import ContentColorPicker from '../../../../../../components/FormInput/ColorPicker/ContentColorPicker'
import { ReduxTextField } from '../../../../../../components/FormInput/ReduxWrappers'
import TextImageAlignCheckBox from '../../../../../../components/FormInput/ReduxWrappers/Contents/TextImageAlignCheckBox'

const useStyles = makeStyles(() => ({
  fieldContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px'
  },
  accordionSummaryRootClass: {
    backgroundColor: '#F5F5F5',
    borderBottom: '1px solid rgba(0, 0, 0, 0.09)'
  }
}))

interface GeneralSettingsProps {
  title: string
  itemImageVisible: boolean
}

const GeneralSettings = ({ title, itemImageVisible }: GeneralSettingsProps) => {
  const [t] = useTranslation()
  const classes = useStyles()
  return (
    <SimpleExtensionPanel
      title={title}
      isOpen={false}
      accordionSummaryRootClass={classes.accordionSummaryRootClass}>
      <div>
        <Typography>{t('contents.properties.theme')}</Typography>
        <Field name='cRssFeedProps.theme' component={ReduxTextField} select normalize={themeChange}>
          {Object.values(RssFeedTheme).map((theme: any) => {
            return (
              <MenuItem key={theme} value={theme}>
                {t(`contents.widgets.rssFeed.theme.${theme}`)}
              </MenuItem>
            )
          })}
        </Field>

        <br />
        <br />
        <div className={classes.fieldContainer}>
          <Field
            name={`cRssFeedProps.styles.channelHeaderContainerStyles.background`}
            component={ContentColorPicker}
            tooltip={t('contents.actions.selectTextColor')}
            normalize={changeThemeToCustom}
          />
          <Typography>{t('contents.properties.headerBackgroundColor')}</Typography>
        </div>
        <div className={classes.fieldContainer}>
          <Field
            name={`cRssFeedProps.styles.mainContainerStyles.background`}
            component={ContentColorPicker}
            tooltip={t('contents.actions.selectTextColor')}
            normalize={changeThemeToCustom}
          />
          <Typography>{t('contents.properties.backgroundColor')}</Typography>
        </div>
        {itemImageVisible && (
          <div className={classes.fieldContainer}>
            <Field
              name='cRssFeedProps.styles.textContainerStyles.order'
              component={TextImageAlignCheckBox}
            />
            <Typography>{t('contents.actions.alignContent')}</Typography>
          </div>
        )}
      </div>
    </SimpleExtensionPanel>
  )
}

export default GeneralSettings
