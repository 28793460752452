import React, { ReactNode } from 'react'

interface FormContainerProps {
  onSubmit?: () => void
  children: ReactNode
}

/**
 * Use 'form' as container if onSubmit exists. Otherwise use div. Useful when rendering redux-form inside of a another form.
 */
const FormContainer: React.FC<FormContainerProps> = ({ children, onSubmit }) => {
  if (typeof onSubmit === 'function') {
    return <form onSubmit={onSubmit}>{children}</form>
  }
  return <div>{children}</div>
}

export default FormContainer
