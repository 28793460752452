import React from 'react'
import { ScreenWarning } from '@seesignage/seesignage-utils'
import { Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { inlineBlockStyle } from '../../../styles/common/styles'
import { getScreentWarningIcon } from '../../../utils/screens'

const useStyles = makeStyles(() => ({
  inlineBlockStyle
}))

interface OwnProps {
  warnings?: ScreenWarning[]
}

type WarningsCellProps = OwnProps

const WarningsCell = ({ warnings }: WarningsCellProps) => {
  const [t] = useTranslation()
  const classes = useStyles()
  return (
    <div>
      {warnings
        ? warnings.map(({ warningType }) => {
            const WarningIcon = getScreentWarningIcon(warningType)
            return (
              <Tooltip
                disableInteractive
                key={warningType}
                title={t(`screens.warnings.warningType.${warningType}`) as string}>
                <span>
                  <WarningIcon className={classes.inlineBlockStyle} />
                </span>
              </Tooltip>
            )
          })
        : '-'}
    </div>
  )
}

export default WarningsCell
