import {
  AppBar,
  Toolbar,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Tooltip,
  IconButton
} from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import React, { Fragment } from 'react'
import { TFunction } from 'i18next'
import DeleteIcon from 'mdi-react/DeleteIcon'
import CloseIcon from 'mdi-react/CloseIcon'
import { Resolutions, ResolutionLandscape } from '@seesignage/seesignage-utils'
import { toolbarStyles } from '../../../styles/common'
import Dialog from '../../Dialog'
import { SelectedInfopage, SelectedInfopageType } from '../../../types/infopages'
import { OpenDialogButtonType } from '../../../types/dialogs'
import CreateInfopageOrContentForm from '../Forms/CreateInfopageOrContentForm'
import InfopagesSearchField, { InfopagesSearchFieldProps } from './InfopagesSearchField'

export interface InfopagesToolbarOwnProps {
  t: TFunction
  deleteInfopage: (infopages: SelectedInfopage[]) => void
  deselectAll: () => void
  selectedInfopages: SelectedInfopage[]
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>
  infopageType?: SelectedInfopageType
  searchTerm: string | null
  supportInfopages: boolean
}

type ContentsToolbarProps = InfopagesToolbarOwnProps & WithStyles<typeof toolbarStyles>

const InfopagesToolbar = ({
  classes,
  t,
  deleteInfopage,
  deselectAll,
  selectedInfopages,
  setSearchTerm,
  infopageType,
  searchTerm,
  supportInfopages
}: ContentsToolbarProps) => {
  const infopagesSearchFieldProps: InfopagesSearchFieldProps = {
    setSearchTerm,
    infopageType,
    searchTerm,
    supportInfopages
  }
  return (
    <AppBar className={classes.appBar} position='sticky' color='default'>
      <Toolbar>
        <InfopagesSearchField {...infopagesSearchFieldProps} />
        <div className={classes.spacer} />
        <div className={classes.actions}>
          {selectedInfopages.length > 0 ? (
            <Fragment>
              <Dialog
                dialogId='DeleteContentsDialog'
                title={t('infopages.deleteTitle', { count: selectedInfopages.length })}
                tooltipTitle={t('infopages.delete')}
                ButtonIcon={DeleteIcon}
                Content={
                  <Fragment>
                    <DialogContent>
                      <DialogContentText>
                        {t('infopages.deleteConfirm', { count: selectedInfopages.length })}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => deleteInfopage(selectedInfopages)}
                        color='primary'
                        autoFocus>
                        {t('general.delete')}
                      </Button>
                    </DialogActions>
                  </Fragment>
                }
              />
              <Tooltip disableInteractive title={t('general.deselect')}>
                <IconButton
                  onClick={() => deselectAll()}
                  aria-label={t('general.close')}
                  size='large'>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Fragment>
          ) : (
            <Dialog
              dialogId='CreateInfopageDialog'
              title={t('infopages.create')}
              tooltipTitle={t('infopages.create')}
              openDialogButtonType={OpenDialogButtonType.add}
              Content={
                <CreateInfopageOrContentForm
                  dialogId='CreateInfopageDialog'
                  submitButtonLabel={t('general.create')}
                  infopageType={infopageType}
                  initialValues={{
                    selectedResolution: Resolutions.landscape,
                    width: ResolutionLandscape.width,
                    height: ResolutionLandscape.height
                  }}
                />
              }
            />
          )}
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default withStyles(toolbarStyles)(InfopagesToolbar)
