import {
  PlayerPharmacyProduct,
  PlayerPricerProduct,
  PlayerRetailProduct,
  PlayerGeneralRetailProduct,
  AdditionalPlayerItemProps
} from '@seesignage/seesignage-utils'

export enum MockProductType {
  retail = 'retail',
  generalRetail = 'generalRetail',
  pricerVerkkokauppa = 'pricerVerkkokauppa',
  pricerHankkija = 'pricerHankkija',
  pharmacy = 'pharmacy',
  lunch = 'lunch',
  fish = 'fish',
  meat = 'meat'
}

export interface MockItems {
  retail: PlayerRetailProduct[]
  generalRetail: PlayerGeneralRetailProduct[]
  pricerVerkkokauppa: PlayerPricerProduct[]
  pricerHankkija: PlayerPricerProduct[]
  pharmacy: PlayerPharmacyProduct[]
  lunch: any
  fish: any
  meat: any
}

// PlayerRetailProduct | PlayerGeneralRetailProduct | PlayerPricerProduct | MiscProduct | PlayerPharmacyProduct | PlayerOffer
export interface TemplateMockFormData extends MockItems {
  currentMockProductType: MockProductType
  additionalProps?: AdditionalPlayerItemProps
}
