import React from 'react'
import { Typography, Grid, Card, CardActionArea } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import MediaIcon from 'mdi-react/FolderMultipleImageIcon'
import PlaylistPlayIcon from 'mdi-react/PlaylistPlayIcon'
import { useTranslation } from 'react-i18next'
import { MediaCarouselWizardView } from '../../../../types/contents'
import SimpleExtensionPanel from '../../../../components/ExtensionPanels/SimpleExtensionPanel'

const useStyles = makeStyles(() => ({
  container: {
    textAlign: 'center',
    paddingTop: 15
  },
  card: {
    width: '300px',
    height: '100px',
    margin: '20px'
  },
  cardAction: {
    height: '100%',
    width: '100%',
    display: 'flex',
    transition: '0.2s'
  },
  text: {
    marginLeft: '10px'
  }
}))

interface SelectMediaCarouselTypeProps {
  setView: React.Dispatch<React.SetStateAction<MediaCarouselWizardView>>
}

const SelectMediaCarouselType: React.FC<SelectMediaCarouselTypeProps> = ({ setView }) => {
  const [t] = useTranslation()
  const classes = useStyles()
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Typography variant='h5' gutterBottom>
          {t('contents.widgets.mediaCarousel.selectTypeTitle')}
        </Typography>
        <Typography variant='body1'>
          {t('contents.widgets.mediaCarousel.selectTypeDescription')}
        </Typography>
      </Grid>
      <Grid container direction='row' justifyContent='center' alignItems='center'>
        <Grid item>
          <Card className={classes.card} onClick={() => setView(MediaCarouselWizardView.media)}>
            <CardActionArea className={classes.cardAction}>
              <MediaIcon size='40px' />
              <Typography className={classes.text} variant='h6'>
                {t('contents.widgets.mediaCarousel.mediaCarousel')}
              </Typography>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item>
          <Card className={classes.card} onClick={() => setView(MediaCarouselWizardView.playlist)}>
            <CardActionArea className={classes.cardAction}>
              <PlaylistPlayIcon size='40px' />
              <Typography className={classes.text} variant='h6'>
                {t('contents.widgets.mediaCarousel.playlistCarousel')}
              </Typography>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <SimpleExtensionPanel
          title={t('contents.widgets.mediaCarousel.deviceLimitationsTitle')}
          isOpen={false}>
          <Typography>
            {t('contents.widgets.mediaCarousel.deviceLimitationsDescription')}
          </Typography>
        </SimpleExtensionPanel>
      </Grid>
    </Grid>
  )
}

export default SelectMediaCarouselType
