import { API } from 'aws-amplify'
import {
  MiscProduct,
  ProductImageProvider,
  ListType,
  PrefillProductData,
  Product,
  RetailProduct,
  PharmacyCustomerProduct,
  PharmacyProduct,
  PharmacyMasterProduct,
  PharmacySearchCriteria,
  CreatePharmacyMasterProductProps,
  PricerProduct
} from '@seesignage/seesignage-utils'
import { CreateGeneralProduct, CreateRetailProduct } from '../../types/products'

const getSuggestions = async (
  environmentId: string,
  customerId: string,
  type: ListType,
  searchTerm: string
) => {
  const encoded = encodeURI(searchTerm)
  const suggestions = await API.get(
    'products',
    `/environments/${environmentId}/customers/${customerId}/productsSearch/${type}/${encoded}`,
    {}
  )
  return suggestions
}

const getProductById = async (
  environmentId: string,
  customerId: string,
  type: ListType,
  productId: string | undefined
): Promise<Product> => {
  const product = await API.get(
    'products',
    `/environments/${environmentId}/customers/${customerId}/products/${type}/${productId}`,
    {}
  )
  return product
}

const getProductPrefillById = async (
  environmentId: string,
  customerId: string,
  productId: string
) => {
  const productPrefill: PrefillProductData = await API.get(
    'products',
    `/environments/${environmentId}/customers/${customerId}/productsPrefill/${productId}`,
    {}
  )
  return productPrefill
}

const createProduct = async (
  environmentId: string,
  customerId: string,
  type: ListType,
  newProduct: CreateGeneralProduct | CreateRetailProduct
) => {
  const product = await API.post(
    'products',
    `/environments/${environmentId}/customers/${customerId}/products/${type}`,
    { body: newProduct }
  )
  return product
}

const updateProduct = async (
  environmentId: string,
  customerId: string,
  type: ListType,
  productId: string,
  product: Product
) => {
  const updatedAttributes = await API.put(
    'products',
    `/environments/${environmentId}/customers/${customerId}/products/${type}/${productId}`,
    { body: product }
  )
  return updatedAttributes
}

const listProducts = async (environmentId: string, customerId: string, type: ListType) => {
  const products = await API.get(
    'products',
    `/environments/${environmentId}/customers/${customerId}/products/${type}/list`,
    {}
  )
  return products
}

const deleteProduct = async (
  environmentId: string,
  customerId: string,
  type: string,
  productId: string
) => {
  await API.del(
    'products',
    `/environments/${environmentId}/customers/${customerId}/products/${type}/${productId}`,
    {}
  )
}

const createOrUpdateMisc = async (
  environmentId: string,
  customerId: string,
  type: string,
  product: MiscProduct
) => {
  const createdOrUpdated = await API.put(
    'products',
    `/environments/${environmentId}/customers/${customerId}/products/misc/${type}/createOrUpdate`,
    {
      body: product
    }
  )
  return createdOrUpdated
}

const getPricerProductById = async (
  environmentId: string,
  customerId: string,
  productId: string
) => {
  const { product } = await API.get(
    'products',
    `/environments/${environmentId}/customers/${customerId}/products/pricer/${productId}`,
    {}
  )
  return product as PricerProduct
}

const createPricerProduct = async (
  environmentId: string,
  customerId: string,
  product: RetailProduct
) => {
  const newProduct = await API.post(
    'products',
    `/environments/${environmentId}/customers/${customerId}/products/pricer/`,
    { body: product }
  )
  return newProduct
}

const generateValidImageUrl = async (provider: ProductImageProvider, ean: string) => {
  const url = await API.get('products', `/generateUrl/${provider}/${ean}`, {})
  return url
}

const updateSokProductDetails = async (
  environmentId: string,
  customerId: string,
  { updateImage, updatePrice }: { updateImage?: boolean; updatePrice?: boolean }
) => {
  await API.put(
    'products',
    `/environments/${environmentId}/customers/${customerId}/updateSokProductDetails`,
    { body: { updateImage, updatePrice } }
  )
}

const searchDrugs = async (
  environmentId: string,
  searchType: PharmacySearchCriteria,
  searchTerm: string
) => {
  try {
    const drugs: PharmacyMasterProduct[] = await API.get(
      'products',
      `/environments/${environmentId}/pharmacy`,
      {
        queryStringParameters: {
          searchType,
          searchTerm
        }
      }
    )
    return drugs
  } catch (error) {
    return []
  }
}

const getCustomerDrug = async (
  environmentId: string,
  customerId: string,
  ean: string
): Promise<PharmacyProduct | undefined> => {
  try {
    const drug = await API.get(
      'products',
      `/environments/${environmentId}/pharmacy/${customerId}/${ean}`,
      {}
    )
    return drug
  } catch (error) {
    return undefined
  }
}

const updateCustomerDrug = async (
  environmentId: string,
  customerId: string,
  product: PharmacyCustomerProduct
) => {
  const newDrug = await API.put(
    'products',
    `/environments/${environmentId}/pharmacy/${customerId}/${product.ean}`,
    { body: product }
  )
  return newDrug
}

const createMasterDrug = async (
  environmentId: string,
  customerId: string,
  product: CreatePharmacyMasterProductProps
) => {
  const newDrug = await API.post(
    'products',
    `/environments/${environmentId}/pharmacy/${customerId}/masterProduct`,
    { body: product }
  )
  return newDrug
}

export default {
  getSuggestions,
  getProductById,
  getProductPrefillById,
  createProduct,
  updateProduct,
  listProducts,
  deleteProduct,
  createOrUpdateMisc,
  getPricerProductById,
  createPricerProduct,
  generateValidImageUrl,
  updateSokProductDetails,
  searchDrugs,
  getCustomerDrug,
  updateCustomerDrug,
  createMasterDrug
}
