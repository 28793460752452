import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  AppBar,
  Toolbar,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import DomainIcon from 'mdi-react/DomainIcon'
import { useTranslation } from 'react-i18next'
import DeleteIcon from 'mdi-react/DeleteIcon'
import { EnvironmentUI } from '@seesignage/seesignage-utils'
import { toolbarStyles } from '../../../styles/common'
import { selectUser } from '../../../selectors/users'
import { isAdminRole } from '../../../utils/permissions'
import CreateEnvironmentForm from '../Forms/CreateEnvironmentForm'
import { bindSubmitActionToPromise } from '../../../utils/forms'
import {
  createEnvironment as createEnvironmentAction,
  deleteEnvironment as deleteEnvironmentAction
} from '../../../actions/environments'
import { closeDialog as closeDialogAction } from '../../../actions/dialogs'
import Dialog from '../../Dialog'

const useStyles = makeStyles(theme => ({
  ...toolbarStyles(theme),
  autocomplete: {
    width: 400
  },
  deleteButton: {
    marginLeft: 8,
    color: 'red'
  }
}))

interface EnvironmentsToolbarProps {
  environment: EnvironmentUI
}

const EnvironmentsToolbar: React.FC<EnvironmentsToolbarProps> = ({ environment }) => {
  const { environmentId, name } = environment
  const classes = useStyles()
  const [t] = useTranslation()

  const dispatch = useDispatch()
  const createEnvironment = bindSubmitActionToPromise(dispatch, createEnvironmentAction)
  const deleteEnvironment = (environmentId: string) =>
    dispatch(deleteEnvironmentAction(environmentId))
  const closeDialog = (dialogId?: string) => dispatch(closeDialogAction(dialogId))

  const user = useSelector(selectUser)

  const isAdmin = isAdminRole(user?.role)
  return user ? (
    <AppBar className={classes.appBar} position='sticky' color='default'>
      <Toolbar>
        <div className={classes.spacer} />

        {isAdmin && (
          <div className={classes.actions}>
            <Dialog
              dialogId='CreateEnvironmentDialog'
              title={t('environment.createNew')}
              buttonLabel={t('general.create')}
              ButtonIcon={DomainIcon}
              Content={
                <CreateEnvironmentForm
                  submitAction={createEnvironment}
                  submitButtonLabel={t('general.create')}
                  closeDialog={() => closeDialog('CreateEnvironmentDialog')}
                  isUpdate={false}
                />
              }
            />
            <Dialog
              dialogId='RemoveEnvironmentDialog'
              title={`${t('environment.remove')} (${name})`}
              tooltipTitle={t('environment.remove')}
              ButtonIcon={DeleteIcon}
              buttonClassName={classes.deleteButton}
              buttonLabel={t('general.delete')}
              Content={
                <Fragment>
                  <DialogContent>
                    <DialogContentText>
                      {t('environment.confirmationText', { name })}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => closeDialog('RemoveEnvironmentDialog')} color='primary'>
                      {t('general.disagree')}
                    </Button>
                    <Button
                      onClick={() => deleteEnvironment(environmentId)}
                      color='primary'
                      autoFocus>
                      {t('general.agree')}
                    </Button>
                  </DialogActions>
                </Fragment>
              }
            />
          </div>
        )}
      </Toolbar>
    </AppBar>
  ) : (
    <Fragment />
  )
}

export default EnvironmentsToolbar
