import React from 'react'
import { TFunction } from 'i18next'
import { Grid } from '@mui/material'
import { Field } from 'redux-form'
import ContentColorPicker from '../../../../../../../components/FormInput/ColorPicker/ContentColorPicker'
import { ReduxTextField } from '../../../../../../../components/FormInput/ReduxWrappers'
import { parseStringToIntNumber } from '../../../../../../../utils/forms'
import SimpleExtensionPanel from '../../../../../../../components/ExtensionPanels/SimpleExtensionPanel'
import TextFields from '../../CommonFields/TextFields'
import Borderfields from '../../CommonFields/BorderFields'

const SizeFields = ({
  namePrefix,
  t,
  isFieldsDisabled
}: {
  namePrefix: string
  t: TFunction
  isFieldsDisabled: boolean
}) => (
  <Grid container>
    <Grid item xs={6}>
      <Field
        fullWidth
        name={`${namePrefix}.width`}
        label={t('contents.properties.width')}
        component={ReduxTextField}
        type='number'
        parse={parseStringToIntNumber}
        isToolbar
        disabled={isFieldsDisabled}
      />
    </Grid>
    <Grid item xs={6}>
      <Field
        fullWidth
        name={`${namePrefix}.height`}
        label={t('contents.properties.height')}
        component={ReduxTextField}
        type='number'
        size='small'
        parse={parseStringToIntNumber}
        isToolbar
        disabled={isFieldsDisabled}
      />
    </Grid>
  </Grid>
)
const SpacingFields = ({
  namePrefix,
  t,
  isFieldsDisabled
}: {
  namePrefix: string
  t: TFunction
  isFieldsDisabled: boolean
}) => (
  <Grid container>
    <Grid item xs={6}>
      <Field
        fullWidth
        name={`${namePrefix}.paddingTop`}
        label={t('contents.properties.spacingTop')}
        component={ReduxTextField}
        type='number'
        parse={parseStringToIntNumber}
        isToolbar
        disabled={isFieldsDisabled}
      />
    </Grid>
    <Grid item xs={6}>
      <Field
        fullWidth
        name={`${namePrefix}.paddingBottom`}
        label={t('contents.properties.spacingBottom')}
        component={ReduxTextField}
        type='number'
        size='small'
        parse={parseStringToIntNumber}
        isToolbar
        disabled={isFieldsDisabled}
      />
    </Grid>
    <Grid item xs={6}>
      <Field
        fullWidth
        name={`${namePrefix}.paddingLeft`}
        label={t('contents.properties.spacingLeft')}
        component={ReduxTextField}
        type='number'
        size='small'
        parse={parseStringToIntNumber}
        isToolbar
        disabled={isFieldsDisabled}
      />
    </Grid>
    <Grid item xs={6}>
      <Field
        fullWidth
        name={`${namePrefix}.paddingRight`}
        label={t('contents.properties.spacingRight')}
        component={ReduxTextField}
        type='number'
        size='small'
        parse={parseStringToIntNumber}
        isToolbar
        disabled={isFieldsDisabled}
      />
    </Grid>
  </Grid>
)

const BackgroundFields = ({
  namePrefix,
  t,
  isFieldsDisabled
}: {
  namePrefix: string
  t: TFunction
  isFieldsDisabled: boolean
}) => (
  <Grid container>
    <Grid item xs={2}>
      <Field
        name={`${namePrefix}.backgroundColor`}
        component={ContentColorPicker}
        tooltip={t('contents.properties.backgroundColor')}
        disabled={isFieldsDisabled}
      />
    </Grid>
  </Grid>
)

const StylesFields = ({
  item,
  t,
  isFieldsDisabled
}: {
  /** Name of field, if it does not exist do not use "item" prefix for styles */
  item?: string
  t: TFunction
  isFieldsDisabled: boolean
}) => {
  const namePrefix = item ? `${item}.styles` : 'cTableProps.schema.styles'
  return (
    <Grid container>
      <Grid item xs={12}>
        <SimpleExtensionPanel title={t('contents.properties.text')} isOpen={false}>
          <TextFields namePrefix={namePrefix} t={t} isFieldsDisabled={isFieldsDisabled} />
        </SimpleExtensionPanel>
      </Grid>
      <Grid item xs={12}>
        <SimpleExtensionPanel title={t('contents.properties.size')} isOpen={false}>
          <SizeFields namePrefix={namePrefix} t={t} isFieldsDisabled={isFieldsDisabled} />
        </SimpleExtensionPanel>
      </Grid>
      <Grid item xs={12}>
        <SimpleExtensionPanel title={t('contents.properties.padding')} isOpen={false}>
          <SpacingFields namePrefix={namePrefix} t={t} isFieldsDisabled={isFieldsDisabled} />
        </SimpleExtensionPanel>
      </Grid>
      <Grid item xs={12}>
        <SimpleExtensionPanel title={t('contents.properties.border')} isOpen={false}>
          <Borderfields namePrefix={namePrefix} t={t} isFieldsDisabled={isFieldsDisabled} />
        </SimpleExtensionPanel>
      </Grid>
      <Grid item xs={12}>
        <SimpleExtensionPanel title={t('contents.properties.background')} isOpen={false}>
          <BackgroundFields namePrefix={namePrefix} t={t} isFieldsDisabled={isFieldsDisabled} />
        </SimpleExtensionPanel>
      </Grid>
    </Grid>
  )
}

export default StylesFields
