import React from 'react'
import { reduxForm, Field, InjectedFormProps } from 'redux-form'
import { Grid, Typography, Divider } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { TFunction } from 'i18next'
import { FalconyAnnouncementsProps } from '@seesignage/seesignage-utils'
import {
  ReduxDropDownSelection,
  ReduxTextField
} from '../../../../../../components/FormInput/ReduxWrappers'
import SimpleExtensionPanel from '../../../../../../components/ExtensionPanels/SimpleExtensionPanel'
import { required, validFalconyAnnouncementsDomain, validUrl } from '../../../../../../validation'
import ContentColorPicker from '../../../../../../components/FormInput/ColorPicker/ContentColorPicker'

const useStyles = makeStyles(() => ({
  accordionSummaryRootClass: {
    backgroundColor: '#F5F5F5',
    borderBottom: '1px solid rgba(0, 0, 0, 0.09)'
  },
  settingsTitle: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '12px',
    marginBottom: '4px'
  },
  divider: {
    marginTop: '10px'
  }
}))

const FORM_NAME = 'ContentFalconyAnnouncementsSettingsForm'

interface OwnProps {
  t: TFunction
}

type FormProps = FalconyAnnouncementsProps
type ComponentProps = OwnProps

const latestItemsCountOptions = Array.from({ length: 20 }, (_, i) => ({
  label: 1 + i,
  value: 1 + i
}))

const oldestItemsCountsOption = (t: TFunction) =>
  [0, 1, 3, 6, 12].map(value => {
    if (value === 0) {
      return {
        label: t('contents.widgets.falconyAnnouncements.unset'),
        value: ''
      }
    }
    if (value === 1) {
      return {
        label: `${value} ${t('contents.widgets.falconyAnnouncements.month')}`,
        value
      }
    }
    return {
      label: `${value} ${t('contents.widgets.falconyAnnouncements.months')}`,
      value
    }
  })

const visibleItemsCountOptions = Array.from({ length: 10 }, (_, i) => ({
  label: 1 + i,
  value: 1 + i
}))

const slideShowIntervalOptions = (t: TFunction) =>
  [5, 10, 15, 20, 30, 45, 60].map(value => ({
    label: `${value} ${t('contents.widgets.falconyAnnouncements.seconds')}`,
    value
  }))

const ContentFalconyAnnouncementsSettingsForm: React.FC<ComponentProps &
  InjectedFormProps<FormProps, ComponentProps>> = ({ t }) => {
  const classes = useStyles()
  return (
    <SimpleExtensionPanel
      title={t('contents.widgets.falconyAnnouncements.generalSettings')}
      isOpen={true}
      accordionSummaryRootClass={classes.accordionSummaryRootClass}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className={classes.settingsTitle}>
            {t('contents.widgets.falconyAnnouncements.source')}
          </Typography>
          <Field
            name='url'
            component={ReduxTextField}
            variant='outlined'
            size='small'
            type='url'
            placeholder='https://pelsu.fi'
            fullWidth
            validate={[required, validUrl, validFalconyAnnouncementsDomain]}
            required
          />
          <Divider className={classes.divider} />
        </Grid>

        <Grid item xs={12}>
          <Typography className={classes.settingsTitle}>
            {t('contents.widgets.falconyAnnouncements.latestItemsCount')}
          </Typography>
          <Field
            name='latestItemCount'
            component={ReduxDropDownSelection}
            size='small'
            label=''
            options={latestItemsCountOptions}
          />
          <Divider className={classes.divider} />
        </Grid>

        <Grid item xs={12}>
          <Typography className={classes.settingsTitle}>
            {t('contents.widgets.falconyAnnouncements.oldestItemsCount')}
          </Typography>
          <Field
            name='oldestItemByMonth'
            component={ReduxDropDownSelection}
            size='small'
            label=''
            options={oldestItemsCountsOption(t)}
          />
          <Divider className={classes.divider} />
        </Grid>

        <Grid item xs={12}>
          <Typography className={classes.settingsTitle}>
            {t('contents.widgets.falconyAnnouncements.visibleItemsCount')}
          </Typography>
          <Field
            name='visibleItemCount'
            component={ReduxDropDownSelection}
            size='small'
            label=''
            options={visibleItemsCountOptions}
          />
          <Divider className={classes.divider} />
        </Grid>

        <Grid item xs={12}>
          <Typography className={classes.settingsTitle}>
            {t('contents.widgets.falconyAnnouncements.slideShowInterval')}
          </Typography>
          <Field
            name='slideShowInterval'
            component={ReduxDropDownSelection}
            size='small'
            label=''
            options={slideShowIntervalOptions(t)}
          />
          <Divider className={classes.divider} />
        </Grid>

        <Grid item xs={12}>
          <Typography className={classes.settingsTitle}>
            {t('contents.widgets.falconyAnnouncements.backgroundColor')}
          </Typography>
          <Field name='cardStyles.backgroundColor' component={ContentColorPicker} />
          <Divider className={classes.divider} />
        </Grid>
      </Grid>
    </SimpleExtensionPanel>
  )
}

export default reduxForm<FormProps, ComponentProps>({
  form: FORM_NAME,
  enableReinitialize: true
})(ContentFalconyAnnouncementsSettingsForm)
