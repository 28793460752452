import { handleActions } from 'redux-actions'
import { AdditionalPlayerItemProps } from '@seesignage/seesignage-utils'
import { addTemplateMockDataSuccess, setMockProductType } from '../actions/mocks'
import { Action } from '../types/actions'
import { MocksState } from '../types/states'
import { MockProductType, MockItems } from '../types/mocks'
import {
  getInitialMockItems,
  getInitialMockAdditonalProps
} from '../containers/Templates/EditTemplate/TemplatePreview/Mocks/products'

const initialState: MocksState = {
  mockItems: getInitialMockItems(),
  mockAdditionalProps: getInitialMockAdditonalProps(),
  mockProductType: MockProductType.retail
}

const mocks = handleActions<MocksState, any>(
  {
    [addTemplateMockDataSuccess]: (
      state,
      {
        payload: { mockItems, mockAdditionalProps, currentMockProductType }
      }: Action<{
        mockItems: MockItems
        mockAdditionalProps?: AdditionalPlayerItemProps
        currentMockProductType: MockProductType
      }>
    ) => ({
      ...state,
      mockItems,
      mockAdditionalProps: mockAdditionalProps,
      mockProductType: currentMockProductType
    }),
    [setMockProductType]: (state, { payload: mockProductType }: Action<MockProductType>) => ({
      ...state,
      mockProductType
    })
  },
  initialState
)

export default mocks
