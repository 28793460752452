import React, { useState, Fragment } from 'react'
import { Grid, Fab, Tooltip, IconButton, Dialog, DialogContent } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { SketchPicker, ColorResult, RGBColor } from 'react-color'
import EyedropperIcon from 'mdi-react/EyedropperIcon'
import { useTranslation } from 'react-i18next'
import CloseIcon from 'mdi-react/CloseIcon'
import { WrappedFieldInputProps } from 'redux-form'
import { convertRgbObjectToString, convertColorStringToObject } from '../../../utils/conversion'

const useStyles = makeStyles({
  button: {
    width: '42px',
    height: '20px',
    marginRight: 5,
    '&:hover': {
      opacity: '0.2 !important'
    }
  },
  clearButton: {
    marginLeft: 20
  }
})

const handleOnChange = (
  setColor: React.Dispatch<React.SetStateAction<string | RGBColor | undefined>>,
  onChange: (value: any) => void,
  color: ColorResult
) => {
  const { rgb } = color
  setColor(rgb)
  const value = rgb.a === 1 ? color.hex : convertRgbObjectToString(rgb)
  onChange(value)
}

interface OwnProps {
  input: WrappedFieldInputProps | { value: string; onChange: any }
  label?: string
}

type CustomColorPickerProps = OwnProps

const CustomColorPicker: React.FC<CustomColorPickerProps> = ({ input: { value, onChange } }) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const [pickerVisible, setPickerVisibleState] = useState(false)
  const [selectedColor, setColor] = useState<string | RGBColor | undefined>(
    convertColorStringToObject(value)
  )
  const clearColor = () => {
    onChange('')
    setColor('')
  }
  return (
    <Fragment>
      <Grid container>
        <Grid item xs={6} sm={2}>
          <Fab
            className={classes.button}
            onClick={() => setPickerVisibleState(true)}
            style={{
              backgroundColor: value
            }}>
            <EyedropperIcon color='white' />
          </Fab>
          <Dialog open={pickerVisible} onClose={() => setPickerVisibleState(false)}>
            <DialogContent>
              <SketchPicker
                onChange={color => handleOnChange(setColor, onChange, color)}
                color={selectedColor || value}
              />
            </DialogContent>
          </Dialog>
        </Grid>
        {selectedColor && (
          <Grid item xs={6} sm={1}>
            <Tooltip disableInteractive title={t('templates.forms.components.clearColor')}>
              <IconButton className={classes.clearButton} onClick={clearColor} size='large'>
                <CloseIcon size={16} />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </Fragment>
  )
}

export default CustomColorPicker
