import { Tooltip, Typography } from '@mui/material'
import React from 'react'

interface ItemTitleProps {
  containerClass: any
  name?: string
}
const ItemTitle: React.FC<ItemTitleProps> = ({ containerClass, name }) => (
  <Tooltip disableInteractive title={name || ''}>
    <div className={containerClass}>
      <Typography noWrap variant='subtitle1' gutterBottom>
        {name || '-'}
      </Typography>
    </div>
  </Tooltip>
)

export default ItemTitle
