import React from 'react'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import LinearProgress from '@mui/material/LinearProgress'
import { useTranslation } from 'react-i18next'
import { Grid, Button } from '@mui/material'
import { required, email } from '../../../validation'
import { ReduxTextField } from '../../../components/FormInput/ReduxWrappers'
import { parseEmailField } from '../../../utils/forms'
import ErrorMessage from '../../../components/Errors/ErrorMessage'

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginTop: theme.spacing(2)
  }
}))

interface FormProps {
  email: string
  password: string
}

interface OwnProps {
  loginUser: (formData: any) => void
  isLoadingSession?: boolean
}

type LoginFormProps = OwnProps

const LoginForm: React.FC<LoginFormProps & InjectedFormProps<FormProps, OwnProps>> = ({
  handleSubmit,
  submitting,
  loginUser,
  isLoadingSession,
  error
}) => {
  const classes = useStyles()
  const [t] = useTranslation()
  return (
    <form onSubmit={handleSubmit(loginUser)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Field
            id='login-field-email'
            fullWidth
            disabled={isLoadingSession}
            variant='outlined'
            name='email'
            label={t('login.email')}
            component={ReduxTextField}
            required
            validate={[required, email]}
            parse={parseEmailField}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            id='login-field-password'
            fullWidth
            disabled={isLoadingSession}
            variant='outlined'
            name='password'
            label={t('login.password')}
            type='password'
            required
            validate={[required]}
            component={ReduxTextField}
          />
        </Grid>
      </Grid>
      <ErrorMessage message={error} />
      <Button
        id='login-button'
        fullWidth
        variant='contained'
        color='primary'
        className={classes.button}
        disabled={submitting || isLoadingSession}
        type='submit'>
        {t('login.login')}
      </Button>

      {(submitting || isLoadingSession) && <LinearProgress />}
    </form>
  )
}

export default reduxForm<FormProps, OwnProps>({
  form: 'LoginForm'
})(LoginForm)
