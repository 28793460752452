import { Typography, TypographyVariant } from '@mui/material'
import React from 'react'

interface ToastMessageProps {
  variant: TypographyVariant
  message: string
}

/**
 * Custom Toast message with Typography variant
 */
const ToastMessage = ({ variant, message }: ToastMessageProps) => (
  <div>
    <Typography variant={variant}>{message}</Typography>
  </div>
)

export default ToastMessage
