import React, { Fragment } from 'react'
import { WrappedFieldArrayProps, Field, formValueSelector } from 'redux-form'
import { CampaignProduct, CampaignItemType } from '@seesignage/seesignage-utils'
import { TFunction } from 'i18next'
import { Grid, IconButton, Button } from '@mui/material'
import DeleteIcon from 'mdi-react/DeleteIcon'
import nanoid from 'nanoid'
import { useSelector } from 'react-redux'
import { ReduxTextField } from '../../../../components/FormInput/ReduxWrappers'
import { getBackground } from '../../../../components/Draggable/DraggableItems'
import { required, validateCampaignProductName } from '../../../../validation'
import { CampaignBatchPriceType, BatchPriceFieldsProps } from '../../../../types/campaigns'
import { getHiddenBatchPriceFields } from '../../../../utils/campaigns'
import CampaignSubItemPriceFields from './CampaignItemPriceFields'
import ProductIdField from './ProductIdField'
import ShowPercentageField from './ShowPercentageField'
import { HideItemField } from '.'

interface BatchProductField {
  /** Name of field */
  batchProduct: string
  hiddenPriceFields?: BatchPriceFieldsProps
  isDisabled: boolean
  t: TFunction
  isCampaignSub: boolean
  remove: () => void
}

const BatchProductField: React.FC<BatchProductField> = ({
  isCampaignSub,
  hiddenPriceFields,
  isDisabled,
  t,
  batchProduct,
  remove
}) => {
  const isHidden = useSelector(state =>
    formValueSelector('EditCampaignForm')(state, `${batchProduct}.isHidden`)
  )
  const vnrCode = useSelector(state =>
    formValueSelector('EditCampaignForm')(state, `${batchProduct}.vnr`)
  )

  return (
    <div style={{ width: '100%', display: 'flex' }}>
      <Grid
        style={{ background: getBackground(isHidden), width: '95%', marginRight: 8 }}
        container
        spacing={1}>
        <Grid item xs={3}>
          <Grid container spacing={1}>
            <Grid item xs={vnrCode ? 6 : 12}>
              <Field
                fullWidth
                disabled={isCampaignSub || isDisabled}
                name={`${batchProduct}.productId`}
                label={t('campaigns.editCampaign.productId')}
                component={ProductIdField}
                item={batchProduct}
                required
                validate={[required]}
              />
            </Grid>
            {vnrCode && (
              <Grid item xs={6}>
                <Field
                  fullWidth
                  name={`${batchProduct}.vnr`}
                  label={t('vnr')}
                  disabled
                  component={ReduxTextField}
                  size='small'
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={isCampaignSub ? 4 : 7}>
          <Field
            disabled={isDisabled}
            fullWidth
            name={`${batchProduct}.name`}
            label={t('general.name')}
            component={ReduxTextField}
            required
            validate={[validateCampaignProductName]}
            size='small'
          />
        </Grid>
        {isCampaignSub && (
          <CampaignSubItemPriceFields
            priceRequired={!hiddenPriceFields?.price}
            hiddenPriceFields={hiddenPriceFields}
            item={batchProduct}
            t={t}
          />
        )}
        {!isDisabled && (
          <Grid item xs={1}>
            <IconButton onClick={() => remove()} size='large'>
              <DeleteIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
      {isCampaignSub && <Field name={`${batchProduct}.isHidden`} component={HideItemField} />}
      {isCampaignSub && !hiddenPriceFields?.discountPercentage && (
        <Field name={`${batchProduct}.showPercentage`} component={ShowPercentageField} />
      )}
    </div>
  )
}

interface BatchProductsFieldProps extends WrappedFieldArrayProps<CampaignProduct> {
  t: TFunction
  isCampaignSub: boolean
  /** Are buttons and fields disabled. True if `isCampaignSub = true` or `accepted = true` or `published = true` */
  isDisabled: boolean
  batchPriceType: CampaignBatchPriceType
}

const BatchProductsField: React.FC<BatchProductsFieldProps> = ({
  fields,
  isCampaignSub,
  isDisabled,
  batchPriceType,
  t
}) => {
  const hiddenPriceFields = getHiddenBatchPriceFields(batchPriceType, 'batchProduct')
  return (
    <Fragment>
      {!isDisabled && (
        <Button
          style={{ margin: 8 }}
          variant='contained'
          onClick={() =>
            fields.push({
              itemId: nanoid(),
              type: CampaignItemType.product,
              productId: '',
              showPercentage:
                batchPriceType === CampaignBatchPriceType.batchPercentage ? true : false
            })
          }>
          {t('campaigns.editCampaign.addBatchProduct')}
        </Button>
      )}
      {fields.map((batchProduct, index) => {
        const props = {
          isCampaignSub,
          isDisabled,
          hiddenPriceFields,
          remove: () => fields.remove(index),
          batchProduct,
          t
        }
        return <BatchProductField key={`${batchProduct}.${index}`} {...props} />
      })}
    </Fragment>
  )
}

export default BatchProductsField
