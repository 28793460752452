import { Box, Fab, Popover } from '@mui/material'
import React from 'react'
import { SketchPicker } from 'react-color'
import { WrappedFieldProps } from 'redux-form'
import EyedropperIcon from 'mdi-react/EyedropperIcon'
import { convertRgbObjectToString } from '../../../utils/conversion'

type ReduxColorPickerProps = WrappedFieldProps
const id = 'social-medialcolor-picker'

const ReduxColorPicker = ({ input: { onChange, value } }: ReduxColorPickerProps) => {
  const [open, setOpen] = React.useState(false)
  const pickerRef = React.useRef<HTMLButtonElement | null>(null)

  return (
    <Box margin={1}>
      <Fab
        size='small'
        aria-describedby={id}
        ref={pickerRef}
        onClick={() => setOpen(value => !value)}
        style={{
          backgroundColor: value
        }}>
        <EyedropperIcon color='white' />
      </Fab>
      <Popover
        id={id}
        open={open}
        anchorEl={pickerRef.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}>
        <SketchPicker
          onChange={data => {
            onChange(convertRgbObjectToString(data.rgb))
          }}
          color={value}
        />
      </Popover>
    </Box>
  )
}

export default ReduxColorPicker
