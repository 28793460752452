import { change, formValueSelector, getFormValues, initialize } from 'redux-form'
import { all, put, select, takeEvery, call } from 'redux-saga/effects'
import { MediaType, CampaignItemUI, CampaignItemType } from '@seesignage/seesignage-utils'
import { assocPath } from 'ramda'
import { clearSuggestions } from '../actions/products'
import { updateTemplateKey } from '../actions/templates'
import { ReduxFormChangeAction, ReduxFormArrayPushAction } from '../types/forms'
import { updateInfopageKeys } from '../actions/infopages'
import { getPlaylist } from '../actions/playlists'
import { selectIsEditChannelItemOpen } from '../selectors/channels'
import { CampaignBatchPriceType, UpdateCampaignFormData } from '../types/campaigns'
import { splitReduxFormFieldPath } from '../utils/forms'
import {
  contentFormChange,
  contentDrawerFormChange,
  contentToolFormChange
} from '../actions/contents'

const PriceFields: { [unit: string]: boolean } = {
  price: true,
  discountPrice: true,
  discountPercentage: true
}

/**
 * uses updateSelectedObject function to update object values
 */
const ContentEditorForms: any = {
  /** toolbar forms that: */
  ContentTextBoxForm: true,
  ContentImageForm: true,
  ContentVideoForm: true,
  ContentIframeForm: true,
  ContentStopScheduleForm: true,
  ContentRssFeedForm: true,
  ContentTableForm: true,
  ContentDateForm: true,
  ContentWeatherForm: true,
  ContentCircleForm: true,
  ContentRectangleForm: true,
  ContentTriangleForm: true,
  ContentLineForm: true,
  ContentPolygonForm: true,
  ContentPathForm: true,
  CanvasBackgroundForm: true,
  /** drawer forms: (TODO: in the future refactor these to ContentEditorDrawerForms) */
  CommonBoxSettingsForm: true,
  StopScheduleForm: true,
  RssFeedForm: true,
  ContentMediaCarouselToolbarForm: true,
  ContentSocialMediaToolbarForm: true,
  ContentQRCodeForm: true,
  ContentFalconyAnnouncementsToolbarForm: true
}

/** uses contentDrawerFormChange function to update object values */
const ContentEditorDrawerForms: any = {
  ContentTableSchemaForm: true,
  DateWidgetForm: true,
  WeatherWidgetForm: true,
  IframeWidgetForm: true,
  QRCodeWidgetForm: true,
  ContentSocialMediaSettingsForm: true,
  ContentMediaCarouselSettingsForm: true,
  ContentFalconyAnnouncementsSettingsForm: true
}

/**
 * Forms for content editor settings eg. pencil
 */
const ContentEditorToolForms: any = {
  FreeDrawingSettingsForm: true,
  LineDrawingSettingsForm: true,
  PolygonDrawingSettingsForm: true
}

function* handleReduxFormChange({ meta: { form, field }, payload }: ReduxFormChangeAction) {
  if (
    form === 'TemplateForm' &&
    (field.split('.').pop() === 'backgroundImage' || field.split('.').pop() === 'key')
  ) {
    if (payload) {
      yield put(updateTemplateKey(payload))
    }
    /**
     * Clear product if customer changes
     */
  } else if (form === 'AddProductAsItemForm' && field === 'customerId') {
    yield put(clearSuggestions())
    yield put(change(form, 'product', undefined))
  } else if (
    form === 'CreateInfopageForm' &&
    (payload?.type === MediaType.image || payload?.type === MediaType.video)
  ) {
    if (payload) {
      yield put(updateInfopageKeys({ key: payload.key, form }))
    }
  } else if (
    (form === 'CreateChannelItemForm' || form === 'UpdateChannelItemForm') &&
    field === 'channelItem.playlistId'
  ) {
    const isEditChannelItemOpen: boolean = yield select(selectIsEditChannelItemOpen)
    if (payload && isEditChannelItemOpen) {
      yield put(getPlaylist(payload.value))
    }
    // Content form changes
  } else if (ContentEditorForms[form]) {
    yield put(contentFormChange({ field, value: payload }))
  } else if (ContentEditorDrawerForms[form]) {
    const allValues: any[] = yield select(getFormValues(form))
    yield put(contentDrawerFormChange({ field, value: payload, allValues, form }))
  } else if (form === 'CreateListForm' && field === 'type') {
    /** Clear template and customer if type changes */
    yield put(change(form, 'templateId', null))
    yield put(change(form, 'customerId', null))
  } else if (form === 'CreateScreenForm' && field === 'syncPlay') {
    /**
     * Clear screen contents: playlists and channels if syncPlay field changes.
     * We want to make sure that it is possible to select sync playlist or channel only if syncPlay is used in screen
     */
    yield put(change(form, 'playlistId', null))
    yield put(change(form, 'playlistPriority', null))
    yield put(change(form, 'channelIds', null))
  } else if (form === 'EditCampaignForm' && field.startsWith('items')) {
    yield call(handleEditCampaignFormChange, form, field, payload)
  } else if (ContentEditorToolForms[form]) {
    yield put(contentToolFormChange({ field, value: payload, form }))
  }
}

/**
 * If campaign item field changes, update necessary child or sibling fields depending on new value.
 * - e.g if Batch price field is updated and batch products use batch price, update all batch products
 * - e.g if Product price field changes, update discountPercentage or discountPrice
 * @param form
 * @param field
 * @param value
 */
function* handleEditCampaignFormChange(form: string, field: string, value: any) {
  const itemRoot = field
    .split('.')
    .slice(0, -1)
    .join('') // CampaignItem or Batchproduct field path
  const property = field.split('.').pop() || '' // e.g price, discountPrice, discountPercentage

  const item: CampaignItemUI = yield select(formValueSelector(form), itemRoot)
  const batchPriceType: CampaignBatchPriceType | undefined = yield select(
    formValueSelector(form),
    `${itemRoot}.batchPriceType`
  )
  let campaign: UpdateCampaignFormData = yield select(getFormValues(form))
  const showPercentage: boolean = yield select(
    formValueSelector(form),
    `${itemRoot}.showPercentage`
  )
  if (PriceFields[property]) {
    const price: number = yield select(formValueSelector(form), `${itemRoot}.price`)
    const pathArr = splitReduxFormFieldPath(itemRoot)
    if (property === 'price') {
      const discountPercentage: number = yield select(
        formValueSelector(form),
        `${itemRoot}.discountPercentage`
      )
      const discountPrice: number = yield select(
        formValueSelector(form),
        `${itemRoot}.discountPrice`
      )
      if (discountPercentage && showPercentage) {
        // update discountPrice
        const newDiscountPrice = Math.round(value * (1 - discountPercentage / 100))
        campaign = assocPath([...pathArr, 'discountPrice'], newDiscountPrice || null, campaign)
      } else if (discountPrice) {
        const newDiscountPercentage =
          Math.round((((value - discountPrice) / price) * 100 + Number.EPSILON) * 100) / 100
        campaign = assocPath(
          [...pathArr, 'discountPercentage'],
          newDiscountPercentage || '',
          campaign
        )
      }
    } else if (property === 'discountPrice') {
      const discountPercentage =
        Math.round((((price - value) / price) * 100 + Number.EPSILON) * 100) / 100
      campaign = assocPath([...pathArr, 'discountPercentage'], discountPercentage || '', campaign)
    } else if (property === 'discountPercentage') {
      const discountPrice = Math.round(price * (1 - value / 100))
      campaign = assocPath([...pathArr, 'discountPrice'], discountPrice || null, campaign)
    }
    yield put(initialize(form, { ...campaign, changed: true }, false))
  }
  if (
    item.type === CampaignItemType.batchProducts &&
    (batchPriceType === CampaignBatchPriceType.batchPrice ||
      batchPriceType === CampaignBatchPriceType.batchPercentage) &&
    (PriceFields[property] || property === 'showPercentage')
  ) {
    // Batch field modified and will be copied to all batch products
    if (item.products) {
      for (let i = 0; i < item.products.length; i++) {
        const batchProductField = `${itemRoot}.products[${i}]`
        yield put(change(form, `${batchProductField}.${property}`, value))
      }
    }
  }
  // if selected batchPercentage as batch price type, set showPercentage to true
  if (value === 'batchPercentage') {
    yield put(change(form, `${itemRoot}.showPercentage`, true))
  } else if (value === 'batchPrice' && showPercentage) {
    yield put(change(form, `${itemRoot}.showPercentage`, false))
  } else if (value === 'packagePrice' && showPercentage) {
    yield put(change(form, `${itemRoot}.showPercentage`, false))
  }
}

function* handleReduxFormArrayPush({ meta: { form, field }, payload }: ReduxFormArrayPushAction) {
  // updates cotents table widget schema changes
  if (ContentEditorDrawerForms[form]) {
    const allValues: any[] = yield select(getFormValues(form))
    yield put(contentDrawerFormChange({ field, value: payload, allValues }))
  }
}

function* handleReduxFormArrayRemove({ meta: { form, field }, payload }: ReduxFormArrayPushAction) {
  // updates cotents table widget schema changes
  if (ContentEditorDrawerForms[form]) {
    const allValues: any[] = yield select(getFormValues(form))
    yield put(contentDrawerFormChange({ field, value: payload, allValues }))
  }
}

function* watchRoutesActions() {
  yield all([
    takeEvery('@@redux-form/CHANGE', handleReduxFormChange),
    takeEvery('@@redux-form/ARRAY_PUSH', handleReduxFormArrayPush),
    takeEvery('@@redux-form/ARRAY_REMOVE', handleReduxFormArrayRemove)
  ])
}

export default [watchRoutesActions]
