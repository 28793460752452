import { Grid } from '@mui/material'
import React from 'react'
import { Field } from 'redux-form'
import FieldsExpansionPanel from '../../../../../components/ExtensionPanels/FieldsExpansionPanel'
import { generateFieldPrefix, gridItemProps } from '../utils'
import { ReduxTextField } from '../../../../../components/FormInput/ReduxWrappers'

interface SpacingFieldsProps {
  level: number
  childIndexes: number[]
}

const SpacingFields: React.FC<SpacingFieldsProps> = ({ level, childIndexes }) => (
  <FieldsExpansionPanel label='spacing'>
    <Grid container spacing={2} alignItems='center'>
      <Grid {...gridItemProps}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'padding')}
          label='Padding'
          component={ReduxTextField}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'margin')}
          label='Margin'
          component={ReduxTextField}
        />
      </Grid>
    </Grid>
  </FieldsExpansionPanel>
)

export default SpacingFields
