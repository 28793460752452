import React, { useRef, useState } from 'react'
import {
  PlaylistItemPreviewPlayer,
  isPlaylistItemVisibleByConditions
} from '@seesignage/seesignage-utils'
import { IconButton, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import ChevronRightIcon from 'mdi-react/ChevronRightIcon'
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon'
import { TFunction } from 'i18next'
import ItemThumbnail from './ItemThumbnail'

const useStyles = makeStyles(() => ({
  thumbnailsContainer: {
    backgroundColor: '#2D2D2D'
  },
  thumbnails: {
    overflowX: 'scroll',
    padding: '8px 10px 8px 00px',
    display: 'inline-block',
    whiteSpace: 'nowrap' // required so that items are scrollable in same line
  }
}))

interface PreviewPlayerItemsProps {
  items: PlaylistItemPreviewPlayer[]
  t: TFunction
  currentTimeMs: number
}

const PreviewPlayerItems = ({ items, t, currentTimeMs }: PreviewPlayerItemsProps) => {
  const classes = useStyles()
  const scrl = useRef<HTMLDivElement>(null)
  const [scrollX, setscrollX] = useState(0)
  const [scrolEnd, setscrolEnd] = useState(false)

  const slide = (shift: number) => {
    if (scrl.current) {
      scrl.current.scrollLeft += shift
      if (
        Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <= scrl.current.offsetWidth
      ) {
        setscrolEnd(true)
      } else {
        setscrolEnd(false)
      }
    }
  }

  const scrollCheck = () => {
    if (scrl.current) {
      setscrollX(scrl.current.scrollLeft)
      if (
        Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <= scrl.current.offsetWidth
      ) {
        setscrolEnd(true)
      } else {
        setscrolEnd(false)
      }
    }
  }

  const screenHasChannels = items.some(item => item.playlistId)
  return (
    <Grid container className={classes.thumbnailsContainer} alignItems='center'>
      <Grid item xs={1}>
        {scrollX !== 0 && (
          <IconButton onClick={() => slide(-300)} style={{ float: 'left' }} size='large'>
            <ChevronLeftIcon color='white' />
          </IconButton>
        )}
      </Grid>
      <Grid item xs={10} className={classes.thumbnails} ref={scrl} onScroll={scrollCheck}>
        {items.map((item, index) => {
          const isVisible = isPlaylistItemVisibleByConditions({
            item,
            allItems: items,
            screenHasChannels,
            currentTimeMs
          })
          return (
            <ItemThumbnail
              key={item.itemId}
              item={item}
              index={index}
              t={t}
              isVisible={isVisible}
            />
          )
        })}
      </Grid>
      <Grid item xs={1}>
        {!scrolEnd && (
          <IconButton onClick={() => slide(300)} style={{ float: 'right' }} size='large'>
            <ChevronRightIcon color='white' />
          </IconButton>
        )}
      </Grid>
    </Grid>
  )
}

export default PreviewPlayerItems
