import React from 'react'
import { FormControlLabel, Checkbox } from '@mui/material'
import colors from '../../../styles/common/colors'

const ReduxCheckbox = ({ input, label, ...custom }: any) => (
  <div>
    <FormControlLabel
      control={
        <Checkbox
          style={{ color: colors.seesignageColor }}
          checked={input.value ? true : false}
          onChange={input.onChange}
          {...custom}
        />
      }
      label={label}
    />
  </div>
)

export default ReduxCheckbox
