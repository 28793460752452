import React, { Fragment, useState } from 'react'
import { Field, FieldArray, formValueSelector, change, unregisterField } from 'redux-form'
import {
  ComponentRuleOperator,
  RetailProductProperty,
  LogicalOperator,
  ComponentType
} from '@seesignage/seesignage-utils'
import { Paper, FormControlLabel, Radio, Button, Switch, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import makeStyles from '@mui/styles/makeStyles'
import { ReduxRadioGroup } from '../../../../../components/FormInput/ReduxWrappers'
import { StateInterface } from '../../../../../types/states'
import ConditionFields from './ConditionFields'

const useStyles = makeStyles(() => ({
  paper: {
    padding: 10
  }
}))

interface OwnProps {
  parentComponentType?: ComponentType
}

interface StateProps {
  getOperatorValue: (fieldName: string) => ComponentRuleOperator | undefined
  getPropertyValue: (fieldName: string) => RetailProductProperty | undefined
  conditionValue: LogicalOperator | undefined
}

interface DispatchProps {
  removeConditions: () => void
}

const EditSchemaComponentConditions: React.FC<StateProps & DispatchProps & OwnProps> = ({
  getOperatorValue,
  getPropertyValue,
  conditionValue,
  removeConditions,
  parentComponentType
}) => {
  const [t] = useTranslation()
  const classes = useStyles()
  const [isCustomProps, setCustomProps] = useState(true)
  const [isConditionFieldVisible, setConditionFieldVisibility] = useState(
    conditionValue ? true : false
  )
  const handleRemoveConditions = () => {
    removeConditions()
    setConditionFieldVisibility(false)
  }
  return (
    <Fragment>
      {isConditionFieldVisible ? (
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant='subtitle1'>Conditions for rendering</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch checked={isCustomProps} onChange={() => setCustomProps(!isCustomProps)} />
                }
                label='Support custom properties'
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name='conditions.condition'
                component={ReduxRadioGroup}
                style={{ display: 'flex', flexDirection: 'row' }}>
                <FormControlLabel value={LogicalOperator.and} label='AND' control={<Radio />} />
                <FormControlLabel value={LogicalOperator.or} label='OR' control={<Radio />} />
              </Field>
            </Grid>
            <FieldArray
              name='conditions.rules'
              component={ConditionFields}
              props={{
                conditionValue,
                getOperatorValue,
                getPropertyValue,
                removeConditions: handleRemoveConditions,
                t,
                isCustomProps
              }}
            />
          </Grid>
        </Paper>
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Button
              onClick={() => setConditionFieldVisibility(true)}
              variant='contained'
              color='primary'>
              Add condition
            </Button>
          </Grid>
          {parentComponentType !== ComponentType.productData && (
            <Grid item xs={12}>
              <Typography variant='subtitle1' gutterBottom>
                Note: conditions for this element only works for batch products.
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
    </Fragment>
  )
}

const mapStateToProps = (state: StateInterface): StateProps => {
  const selector = formValueSelector('EditSchemaComponentForm')
  return {
    getOperatorValue: (fieldName: string) => selector(state, fieldName),
    getPropertyValue: (fieldName: string) => selector(state, fieldName),
    conditionValue: selector(state, 'conditions.condition')
  }
}
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  removeConditions: () => {
    dispatch(change('EditSchemaComponentForm', 'conditions', null))
    dispatch(unregisterField('EditSchemaComponentForm', 'conditions'))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(EditSchemaComponentConditions)
