import { path } from 'ramda'
import { Dispatch } from 'redux'
import {
  ScheduleTime,
  ScheduleDateTime,
  getDateFromScheduleDateTime,
  getDateFromScheduleTime,
  Schedule,
  Resolutions,
  ContentResolution
} from '@seesignage/seesignage-utils'
import moment from 'moment'
import { ScreenChannelOption } from '../types/screens'
import { SchedulePlaylistItemFormData } from '../types/playlists'
import { CreateInfopageContentFormData } from '../types/contents'
import { getContentResolutionValue } from './fabric/resolution'

/**
 * redux-form integration with redux-saga
 * @param dispatch
 * @param action
 */
const bindSubmitActionToPromise = (dispatch: Dispatch, action: (arg: {}) => any) => (
  formData: any
) => new Promise((resolve, reject) => dispatch(action({ formData, resolve, reject })))

const convertAutoselectValue = (formValue: any) => {
  const value: string | undefined = path(['value'], formValue)
  return value === 'none' || value === undefined ? null : value
}

const convertAutoselectValueNumber = (formValue: any) => {
  const value: number | undefined = path(['value'], formValue)
  return value === undefined ? null : value
}

/**
 * Convert CreateScreenValueForm's `channels` value into proper Screen property values.
 * - Remove Option props
 * @param value
 */
const convertScreenChannelsValue = (value?: ScreenChannelOption[] | null) => {
  if (value && value.length > 0) {
    return value.map(({ channelId, environmentId }) => ({
      channelId,
      environmentId
    }))
  }
  return null
}

/**
 * Redux form field with type 'number' is still a string in the store.
 * Use this function to parse field value into a number.
 * @param value
 */
const parseNumberField = (value: any) => {
  const parsedValue = parseInt(value, 10)
  return isNaN(parsedValue) ? null : parsedValue
}

/**
 * Parse string to int number or return zero if undefined/null because
 * `value` prop on `input` should not be null
 */
const parseNumberFieldToIntOrZero = (value: string) => {
  const intNumber = parseInt(value, 10) // returns NaN if empty string
  return isNaN(intNumber) ? 0 : intNumber
}

const parseNumberFieldToIntOrTen = (value: string) => {
  const intNumber = parseInt(value, 10) // returns NaN if empty string
  return isNaN(intNumber) ? 10 : intNumber
}

const formatQRCodeScale = (value: number | string) => {
  const newVal = parseFloat(`${value}`)
  if (isNaN(newVal)) {
    return ''
  } else {
    const rounded = Math.round((newVal + Number.EPSILON) * 100) / 100
    return rounded
  }
}

/**
 * Format Redux store's number value to integer
 */
const formatNumberFieldToInt = (value: number) => {
  const intNumber = parseInt(`${value}`, 10)
  return isNaN(intNumber) ? null : intNumber
}

/**
 * Parse float number and return zero if undefined because
 * `value` prop on `input` should not be null
 */
const parseNumberFieldToFloatOrZero = (value: string) => {
  const floatNumber = parseFloat(value) // returns NaN if empty string
  return isNaN(floatNumber) ? 0 : floatNumber
}

const parseEmailField = (value?: string) => (value ? value.toLocaleLowerCase() : '')

/**
 * format float number by 1 decimal
 */
const formatNumberFieldAndRoundBy1Decimal = (value: number) => {
  const floatNumber = parseFloat(`${value}`)
  return isNaN(floatNumber) ? null : Math.round((floatNumber + Number.EPSILON) * 10) / 10
}

const formatNumberFieldAndRoundByInteger = (value: number) => {
  const floatNumber = parseFloat(`${value}`)
  return isNaN(floatNumber) ? null : Math.round(floatNumber)
}

/**
 * format float number by 2 decimals
 */
const formatNumberFieldAndRoundBy2Decimals = (value: number) => {
  const floatNumber = parseFloat(`${value}`)
  return isNaN(floatNumber) ? null : Math.round((floatNumber + Number.EPSILON) * 100) / 100
}

/**
 * Split redux form field name into path array
 * @example
 * const pathString = 'items[0].products[1].price'
 * const pathArray = splitReduxFormFieldPath(pathString)
 * // => ['items', 0, 'products', 1, 'price']
 * @param path
 */
const splitReduxFormFieldPath = (path: string) => {
  const replaced = path.replace(/[[\]']+/g, '.')
  return replaced
    .split('.')
    .filter(t => t.length > 0)
    .map(str => {
      return isNaN(parseInt(str)) ? str : parseInt(str)
    })
}
const parseStringToIntNumber = (value?: string) =>
  value !== '' && value !== undefined && value !== null ? parseInt(value) : null

const convertDateToScheduleDateTime = (date: Date): ScheduleDateTime => {
  const momentDate = moment(date)
  const day = momentDate.date()
  const month = momentDate.month() + 1
  const year = momentDate.year()
  const hours = momentDate.hour()
  const minutes = momentDate.minute()
  return {
    day,
    month,
    year,
    hours,
    minutes
  }
}

const convertDateToScheduleTime = (date: Date): ScheduleTime => {
  const momentDate = moment(date)
  const hours = momentDate.hour()
  const minutes = momentDate.minute()
  return {
    hours,
    minutes
  }
}

const convertScheduleDateTimeToDate = (scheduleDateTime: ScheduleDateTime) =>
  getDateFromScheduleDateTime(scheduleDateTime)

const convertScheduleTimeToDate = (scheduleTime: ScheduleTime) =>
  getDateFromScheduleTime(scheduleTime)

const convertPlaylistItemScheduleFormValues = (
  formData: SchedulePlaylistItemFormData
): Schedule | undefined => {
  if (formData && formData.ranges.length > 0) {
    return {
      ranges: formData.ranges.map(({ start, end, conditions }) => ({
        start: convertDateToScheduleDateTime(start),
        end: convertDateToScheduleDateTime(end),
        conditions: conditions.map(({ startTime, endTime, days }) => ({
          startTime: convertDateToScheduleTime(startTime),
          endTime: convertDateToScheduleTime(endTime),
          days
        }))
      }))
    }
  }
}

/**
 * Get boolean type properties value. If value is undefined, return false so that it will be updated to db.
 * - In BatchUpdateScreensParams field might be selected, but value undefined. In this case we want to pass `false` value
 * @param value
 */
const getBooleanPropertyValue = (value?: boolean) => (value === undefined ? false : value)

/**
 * Date picker's value must be in Date format.
 * If value is ISO date string covert to JS Date.
 */
const convertDatePickerValue = (value: any) => {
  if (typeof value === 'string' && value.length > 0) {
    // when value is ISO date string.
    // note: when value is not set it can be empty "" string in date picker component.
    return new Date(value)
  } else if (value) {
    return value
  }
  return null
}

const getContentResolutionFromCreateInfopageContentFormData = ({
  width,
  height,
  selectedResolution
}: CreateInfopageContentFormData) => {
  if (
    selectedResolution === Resolutions.custom &&
    typeof width === 'number' &&
    typeof height === 'number'
  ) {
    const contentResolution: ContentResolution = { width, height }
    return contentResolution
  }
  const resolutionEnum = getContentResolutionValue(selectedResolution)
  const contentResolution: ContentResolution = {
    width: resolutionEnum.width,
    height: resolutionEnum.height
  }
  return contentResolution
}

export {
  bindSubmitActionToPromise,
  convertAutoselectValue,
  convertAutoselectValueNumber,
  convertScreenChannelsValue,
  parseNumberField,
  parseNumberFieldToIntOrZero,
  parseNumberFieldToIntOrTen,
  parseNumberFieldToFloatOrZero,
  parseEmailField,
  formatNumberFieldToInt,
  formatNumberFieldAndRoundBy1Decimal,
  formatNumberFieldAndRoundBy2Decimals,
  formatNumberFieldAndRoundByInteger,
  splitReduxFormFieldPath,
  parseStringToIntNumber,
  convertDateToScheduleDateTime,
  convertDateToScheduleTime,
  convertScheduleDateTimeToDate,
  convertScheduleTimeToDate,
  convertPlaylistItemScheduleFormValues,
  getBooleanPropertyValue,
  formatQRCodeScale,
  convertDatePickerValue,
  getContentResolutionFromCreateInfopageContentFormData
}
