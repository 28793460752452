import React, { Fragment } from 'react'
import { InjectedFormProps, WrappedFieldInputProps, Field, reduxForm } from 'redux-form'
import {
  Grid,
  Typography,
  IconButton,
  Divider,
  Button,
  DialogActions,
  List,
  ListItemButton,
  ListItem,
  ListItemText
} from '@mui/material'
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon'
import Truncate from 'react-truncate'
import { TFunction } from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { selectEnvironmentsForLinking } from '../../../selectors/environments'
import { bindSubmitActionToPromise } from '../../../utils/forms'
import { updateSubEnvironments as updateSubEnvironmentsAction } from '../../../actions/environments'
import { EnvironmentListItemView } from '../../../types/environments'

interface SelectEnvironmentsFieldProps {
  input: WrappedFieldInputProps
  selectedEnvironmentId?: string
  options: EnvironmentListItemView[]
  label: string
  t: TFunction
}

const SelectEnvironmentsField: React.FC<SelectEnvironmentsFieldProps> = ({
  input: { value: fieldValue, onChange },
  options,
  selectedEnvironmentId,
  t
}) => {
  const visibleOptions = options.filter(({ environmentId }) => {
    if (environmentId === selectedEnvironmentId) {
      return false
    }
    if (fieldValue) {
      return !fieldValue.find(
        ({ environmentId: selectedValue }: EnvironmentListItemView) =>
          environmentId === selectedValue
      )
    }
    return true
  })
  return (
    <Grid container>
      <Grid item xs>
        <Typography variant='body1' textAlign='center'>
          {t('environment.environments')}
        </Typography>
        <Divider />
        <List sx={{ overflow: 'auto', height: '480px', direction: 'rtl' }}>
          {visibleOptions.map(({ environmentId, name, displayName }) => (
            <Fragment key={environmentId}>
              <ListItem disablePadding sx={{ direction: 'ltr' }}>
                <ListItemButton
                  onClick={() => onChange([...fieldValue, { environmentId, name, displayName }])}>
                  <ListItemText
                    primary={<Truncate lines={1}>{name}</Truncate>}
                    secondary={<Truncate lines={1}>{displayName}</Truncate>}
                  />
                </ListItemButton>
              </ListItem>
              <Divider />
            </Fragment>
          ))}
        </List>
      </Grid>
      <Divider orientation='vertical' flexItem />
      <Grid item xs>
        <Typography variant='body1' textAlign='center'>
          {t('environment.selectedEnvironments')}
        </Typography>
        <Divider />
        <List sx={{ overflow: 'auto', height: '480px' }}>
          {fieldValue &&
            fieldValue.map(({ environmentId, displayName, name }: EnvironmentListItemView) => (
              <Fragment key={environmentId}>
                <ListItem
                  secondaryAction={
                    <IconButton
                      color='error'
                      onClick={() =>
                        onChange(
                          fieldValue.filter(
                            ({ environmentId: removedEnvironmentId }: EnvironmentListItemView) =>
                              removedEnvironmentId !== environmentId
                          )
                        )
                      }
                      size='large'>
                      <CloseCircleOutlineIcon color='red' />
                    </IconButton>
                  }>
                  <ListItemText
                    primary={<Truncate lines={1}>{name}</Truncate>}
                    secondary={<Truncate lines={1}>{displayName}</Truncate>}
                  />
                </ListItem>
                <Divider />
              </Fragment>
            ))}
        </List>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  )
}

interface FormData {
  environmentId: string
  selectedSubEnvironments?: EnvironmentListItemView[]
}
interface AddSubEnvironmentsFormProps {
  closeDialog: () => void
}

const AddSubEnvironmentsForm: React.FC<AddSubEnvironmentsFormProps &
  InjectedFormProps<FormData, AddSubEnvironmentsFormProps>> = ({
  handleSubmit,
  closeDialog,
  initialValues
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const updateSubEnvironments = bindSubmitActionToPromise(dispatch, updateSubEnvironmentsAction)
  const environments = useSelector(selectEnvironmentsForLinking)
  return (
    <form onSubmit={handleSubmit(updateSubEnvironments)}>
      <Field
        name='selectedSubEnvironments'
        selectedEnvironmentId={initialValues.environmentId}
        component={SelectEnvironmentsField}
        options={environments.map(({ environmentId, name, displayName }) => ({
          environmentId,
          name,
          displayName
        }))}
        t={t}
      />
      <DialogActions>
        <Button color='primary' onClick={closeDialog}>
          {t('general.cancel')}
        </Button>
        <Button type='submit' color='primary'>
          {t('general.save')}
        </Button>
      </DialogActions>
    </form>
  )
}

export default reduxForm<FormData, AddSubEnvironmentsFormProps>({
  form: 'AddSubEnvironmentsForm'
})(AddSubEnvironmentsForm)
