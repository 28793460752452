import React from 'react'
import styled, { keyframes } from 'styled-components'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import ArrowIcon from 'mdi-react/ArrowUpIcon'

const useStyles = makeStyles({
  root: {
    float: 'right' as 'right',
    width: '265px'
  },
  label: {
    textAlign: 'right' as 'right',
    padding: '10px 35px'
  }
})

const fade = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const FadeIn = styled.div`
  -webkit-animation-duration: 2s;
  -webkit-animation-name: fadeIn;
  animation-duration: 2s;
  animation-name: fadeIn;
  animation: ${fade} 4s;
`
interface OwnProps {
  label: string
}

type CreateNewSignProps = OwnProps

const CreateNewSign = ({ label }: CreateNewSignProps) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <FadeIn>
        <Typography className={classes.label} variant='subtitle1' gutterBottom>
          {label} <ArrowIcon />
        </Typography>
      </FadeIn>
    </div>
  )
}
export default CreateNewSign
