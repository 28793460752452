import { Tooltip } from '@mui/material'
import React, { Fragment } from 'react'

interface TooltipContainerProps {
  useTooltip?: boolean
  children?: any
  title?: string
}

const TooltipContainer = ({ useTooltip, title, children }: TooltipContainerProps) => {
  if (useTooltip && title) {
    return (
      <Tooltip disableInteractive title={title}>
        {children}
      </Tooltip>
    )
  }
  return <Fragment>{children}</Fragment>
}

export default TooltipContainer
