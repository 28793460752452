import { lensPath, append, over, path, dissocPath, assocPath, dropLast, insert } from 'ramda'
import {
  SchemaComponents,
  ComponentType,
  StylesChild,
  Template,
  ChildTemplate,
  TemplateOrientation
} from '@seesignage/seesignage-utils'
import nanoid from 'nanoid'
import { EditSchemaComponentFormParams } from '../types/templates'

const generateNewIds = (mutateObject: any, mockId?: string) => {
  if (mutateObject.id) {
    mutateObject.id = mockId || nanoid()
  }
  if (mutateObject.children) {
    for (const o of mutateObject.children) {
      generateNewIds(o, mockId)
    }
  }
}

const getSchemaChildWithNewIds = (child: any, mockId?: string) => {
  const newChild = { ...child }
  generateNewIds(newChild, mockId)
  return newChild
}

const getPathToChildrenArray = (childIndexes: number[]) => {
  if (childIndexes.length === 0) {
    return ['children']
  } else {
    const pathToChildrenArray: (string | number)[] = ['children']
    for (const index of childIndexes) {
      pathToChildrenArray.push(...[index, 'children'])
    }
    return pathToChildrenArray
  }
}

const getPathToParentChildrenArray = (childIndexes: number[]) => {
  if (childIndexes.length === 1) {
    return ['children']
  } else {
    const parentChildIndexes = dropLast(1, childIndexes)
    const pathToChildrenArray: (string | number)[] = ['children']
    for (const index of parentChildIndexes) {
      pathToChildrenArray.push(...[index, 'children'])
    }
    return pathToChildrenArray
  }
}

const getPathToChild = (childIndexes: number[]) => {
  const pathToChildrenArray: (string | number)[] = []
  for (const index of childIndexes) {
    pathToChildrenArray.push(...['children', index])
  }
  return pathToChildrenArray
}

const getSchemaChild = (components: SchemaComponents, childIndexes: number[]) => {
  const pathToChild = getPathToChild(childIndexes)
  return path(pathToChild, components) as any
}

const getStylesChild = (components: StylesChild, childIndexes: number[]) => {
  const pathToChild = getPathToChild(childIndexes)
  return path(pathToChild, components) as any
}

/**
 * Remove child from schema components and return new components
 */
const removeSchemaChild = (components: SchemaComponents, childIndexes: number[]) => {
  const pathToChildren = getPathToChild(childIndexes)
  return dissocPath(pathToChildren, components) as SchemaComponents
}

/**
 * Remove styles child from orientation's styles and return new styles
 */
const removeStylesChild = (orientationStyles: StylesChild, childIndexes: number[]) => {
  const pathToChildren = getPathToChild(childIndexes)
  return dissocPath(pathToChildren, orientationStyles) as StylesChild
}

/**
 * Remove element from template and return updated template
 * TODO: support also other orientations changes
 * @param template
 */
const removeChildElement = (
  template: Template | ChildTemplate,
  childIndexes: number[],
  orientation: TemplateOrientation
) => {
  const schemaComponents = removeSchemaChild({ ...template.schema.components }, childIndexes)
  const updatedTemplate: Template | ChildTemplate = {
    ...template,
    schema: {
      ...template.schema,
      components: schemaComponents
    },
    components: {
      ...template.components,
      [orientation]: removeStylesChild(template.components[orientation], childIndexes)
    }
  }

  return updatedTemplate
}

const generateLunchItemSpecialDiet = (
  specialDietType: ComponentType.image | ComponentType.text
) => ({
  id: 'lunchItemSpecialDiet',
  type: ComponentType.lunchItemSpecialDiet,
  specialDietType: ComponentType.text,
  children: [
    {
      id: 'specialDietLactose',
      name: 'lactoseFree',
      type: specialDietType,
      conditions: {
        condition: 'and',
        rules: [
          {
            operator: 'equals',
            property: 'lactoseFree',
            value: true
          }
        ]
      }
    },
    {
      id: 'specialDietLowLactose',
      type: specialDietType,
      name: 'low lactose',
      conditions: {
        condition: 'and',
        rules: [
          {
            operator: 'equals',
            property: 'lowLactose',
            value: true
          }
        ]
      }
    },
    {
      id: 'specialDietGlutenFree',
      type: specialDietType,
      name: 'glutenFree',
      conditions: {
        condition: 'and',
        rules: [
          {
            operator: 'equals',
            property: 'glutenFree',
            value: true
          }
        ]
      }
    },
    {
      id: 'specialDietVegetarian',
      type: specialDietType,
      name: 'vegetarian',
      conditions: {
        condition: 'and',
        rules: [
          {
            operator: 'equals',
            property: 'vegetarian',
            value: true
          }
        ]
      }
    },
    {
      id: 'specialDietVegan',
      type: specialDietType,
      name: 'vegan',
      conditions: {
        condition: 'and',
        rules: [
          {
            operator: 'equals',
            property: 'vegan',
            value: true
          }
        ]
      }
    },
    {
      id: 'specialDietDairyFree',
      name: 'dairyFree',
      type: specialDietType,
      conditions: {
        condition: 'and',
        rules: [
          {
            operator: 'equals',
            property: 'dairyFree',
            value: true
          }
        ]
      }
    },
    {
      id: 'specialDietEggFree',
      name: 'eggFree',
      type: specialDietType,
      conditions: {
        condition: 'and',
        rules: [
          {
            operator: 'equals',
            property: 'eggFree',
            value: true
          }
        ]
      }
    }
  ]
})

const generateLunchItems = () => ({
  children: [
    {
      id: 'lunchItemContainer',
      type: ComponentType.lunchItemContainer,
      children: [
        {
          id: 'lunchGroupName',
          type: ComponentType.text
        },
        {
          id: 'lunchItemName',
          type: ComponentType.text
        },
        { ...generateLunchItemSpecialDiet(ComponentType.text) }
      ]
    }
  ],
  id: 'lunchItemsContainer',
  type: ComponentType.lunchItemsContainer
})

const generateLunchGroups = () => ({
  children: [
    {
      id: 'lunchGroup',
      type: ComponentType.lunchGroup,
      children: [
        {
          id: 'lunchGroupTitle',
          type: ComponentType.text
        },
        {
          id: 'lunchItemsContainer',
          type: ComponentType.lunchItemsContainer,
          children: [
            {
              id: 'lunchItemContainer',
              type: ComponentType.lunchItemContainer,
              children: [
                {
                  id: 'lunchItemName',
                  type: ComponentType.text
                },
                {
                  id: 'lunchItemPrice',
                  type: ComponentType.text
                },
                { ...generateLunchItemSpecialDiet(ComponentType.text) }
              ]
            }
          ]
        }
      ]
    }
  ],
  id: 'lunchGroups',
  type: ComponentType.lunchGroups
})

const generateListPrice = () => ({
  id: 'listPrice',
  type: ComponentType.listPrice,
  children: [
    {
      id: 'priceIntegers',
      name: 'price integers',
      type: ComponentType.text
    },
    {
      id: 'priceDecimals',
      name: 'price decimals',
      type: ComponentType.text
    }
  ]
})

const generateFishItems = (): SchemaComponents => ({
  id: ComponentType.fishItems,
  type: ComponentType.fishItems
})

const generateMeatItems = () => ({
  id: ComponentType.meatItems,
  type: ComponentType.meatItems
})

const generateNewChild = (type: ComponentType, mockId?: string, productIndex?: number) => {
  const child = {
    id: mockId || nanoid()
  }
  switch (type) {
    case ComponentType.div:
      return {
        ...child,
        children: [],
        type
      }
    case ComponentType.productData:
      return {
        ...child,
        children: [],
        type,
        productIndex
      }
    case ComponentType.qrCode:
    case ComponentType.text:
    case ComponentType.image:
    case ComponentType.video:
    case ComponentType.date:
      return {
        ...child,
        type
      }
    case ComponentType.fishItems:
      return generateFishItems()
    case ComponentType.meatItems:
      return generateMeatItems()
    case ComponentType.lunchItemsContainer:
      return generateLunchItems()
    case ComponentType.listPrice:
      return generateListPrice()
    case ComponentType.lunchGroups:
      return generateLunchGroups()
    default:
      break
  }
}

interface AddNewChildToSchemaComponentsParams {
  components: SchemaComponents
  childIndexes: number[]
  type: ComponentType
  /** List product index if type is productData and child template */
  productIndex?: number
  mockId?: string
}

const addNewChildToSchemaComponents = ({
  components,
  childIndexes,
  type,
  mockId
}: AddNewChildToSchemaComponentsParams) => {
  const pathToChildrenArrayLens = lensPath(getPathToChildrenArray(childIndexes))
  return over(pathToChildrenArrayLens, append(generateNewChild(type, mockId)), components)
}

const updateSchemaComponent = (
  components: SchemaComponents,
  formData: EditSchemaComponentFormParams
) => {
  const { specialDietType } = formData
  const pathToChild = getPathToChild(formData.childIndexes)
  let child = path(pathToChild, components) as any
  if (specialDietType) {
    child = generateLunchItemSpecialDiet(specialDietType)
  }
  const newChild = {
    ...child,
    ...formData
  }
  delete newChild.childIndexes
  return assocPath(pathToChild, newChild, components)
}

/**
 * Duplicate child and add to end of the array
 */
const duplicateSchemaChild = (components: SchemaComponents, childIndexes: number[]) => {
  const child = getSchemaChild(components, childIndexes)
  const newChild = getSchemaChildWithNewIds(child)
  const pathToParentChildrenArray = getPathToParentChildrenArray(childIndexes)
  const pathToChildrenArrayLens = lensPath(pathToParentChildrenArray)
  return over(pathToChildrenArrayLens, append(newChild), components)
}

const moveArrayElement = (array: any[], sourceIndex: number, destinationIndex: number) => {
  const newArray = [...array]
  const element = newArray[sourceIndex]
  newArray.splice(sourceIndex, 1)
  newArray.splice(destinationIndex, 0, element)
  return newArray
}

export interface ReorderSchemaChildParams {
  components: SchemaComponents
  sourceChildParentIndexes: number[]
  destinationChildParentIndexes: number[]
  /** source children index */
  sourceIndex: number
  /** destination children index */
  destinationIndex: number
}

const reorderSchemaChild = ({
  components,
  sourceChildParentIndexes,
  destinationChildParentIndexes,
  sourceIndex,
  destinationIndex
}: ReorderSchemaChildParams) => {
  const pathToDestinationChildrenArray = getPathToChildrenArray(destinationChildParentIndexes)
  // case 1: swap array elements if these are in same array
  if (JSON.stringify(sourceChildParentIndexes) === JSON.stringify(destinationChildParentIndexes)) {
    const destinationChildren: any = path(pathToDestinationChildrenArray, components)
    const reorderedChildren = moveArrayElement(destinationChildren, sourceIndex, destinationIndex)
    return assocPath(pathToDestinationChildrenArray, reorderedChildren, components)
  }
  // case 2: move element from one array to other
  const sourceChildIndexes = [...sourceChildParentIndexes, sourceIndex]
  const sourceChild = getSchemaChild(components, sourceChildIndexes)
  // From higher level to lower level
  if (destinationChildParentIndexes.length < sourceChildParentIndexes.length) {
    const schemaComponentsWithoutSourceChild = removeSchemaChild(components, sourceChildIndexes)
    const destinationChildren: any = path(
      pathToDestinationChildrenArray,
      schemaComponentsWithoutSourceChild
    )
    const newChildren = insert(destinationIndex, sourceChild, destinationChildren)
    return assocPath(
      pathToDestinationChildrenArray,
      newChildren,
      schemaComponentsWithoutSourceChild
    )
  }
  // From lower level to higher level
  const destinationChildren: any = path(pathToDestinationChildrenArray, components)
  const newChildren = insert(destinationIndex, sourceChild, destinationChildren)
  const reorderedComponents = assocPath(pathToDestinationChildrenArray, newChildren, components)
  const schemaComponentsWithoutSourceChild = removeSchemaChild(
    reorderedComponents,
    sourceChildIndexes
  )
  return schemaComponentsWithoutSourceChild
}

export interface ReorderComponentsStylesChildParams {
  template: Template | ChildTemplate
  orientation: TemplateOrientation
  sourceChildParentIndexes: number[]
  destinationChildParentIndexes: number[]
  /** source children index */
  sourceIndex: number
  /** destination children index */
  destinationIndex: number
}

/**
 * Reorder given orientations 'components' styles.
 * We need this function to keep the other orientation's styles updated when we reorder elements in current orientation.
 */
const reorderStylesChild = ({
  template,
  orientation,
  sourceChildParentIndexes,
  destinationChildParentIndexes,
  sourceIndex,
  destinationIndex
}: ReorderComponentsStylesChildParams) => {
  // root styles child
  const stylesChild = template.components[orientation]
  const pathToDestinationChildrenArray = getPathToChildrenArray(destinationChildParentIndexes)

  // case 1: swap array elements if these are in same array
  if (JSON.stringify(sourceChildParentIndexes) === JSON.stringify(destinationChildParentIndexes)) {
    const destinationChildren: any = path(pathToDestinationChildrenArray, stylesChild)
    const reorderedChildren = moveArrayElement(destinationChildren, sourceIndex, destinationIndex)
    return assocPath(pathToDestinationChildrenArray, reorderedChildren, stylesChild)
  }
  // case 2: move element from one array to other
  const sourceChildIndexes = [...sourceChildParentIndexes, sourceIndex]
  const sourceChild = getStylesChild(stylesChild, sourceChildIndexes)
  // From higher level to lower level
  if (destinationChildParentIndexes.length < sourceChildParentIndexes.length) {
    const stylesChildWithoutSourceChild = removeStylesChild(stylesChild, sourceChildIndexes)
    const destinationChildren: any = path(
      pathToDestinationChildrenArray,
      stylesChildWithoutSourceChild
    )
    const newChildren = insert(destinationIndex, sourceChild, destinationChildren)
    return assocPath(pathToDestinationChildrenArray, newChildren, stylesChildWithoutSourceChild)
  }
  // From lower level to higher level
  const destinationChildren: any = path(pathToDestinationChildrenArray, stylesChild)
  const newChildren = insert(destinationIndex, sourceChild, destinationChildren)
  const reorderedStylesChild = assocPath(pathToDestinationChildrenArray, newChildren, stylesChild)
  const stylesChildWithoutSourceChild = removeStylesChild(reorderedStylesChild, sourceChildIndexes)
  return stylesChildWithoutSourceChild
}

const getProductsContainerComponents = (schema: SchemaComponents) => {
  const productsContainers: SchemaComponents[] = []
  const findProductsContainers = (schema: SchemaComponents) => {
    if (schema.type === ComponentType.products) {
      productsContainers.push(schema)
    }
    if (!schema.children?.length) {
      return
    }
    for (const child of schema.children) {
      findProductsContainers(child)
    }
  }
  findProductsContainers(schema)
  return productsContainers
}

/**
 * Get amount of `ProductData` components in Schema
 * @param schema
 */
const getProductDataComponentCount = (schema: SchemaComponents) => {
  let count = 0
  const getComponentCount = (schema: SchemaComponents) => {
    if (schema.type === ComponentType.productData) {
      count++
    }
    if (!schema.children?.length) {
      return
    }
    for (const child of schema.children) {
      getComponentCount(child)
    }
  }
  getComponentCount(schema)
  return count
}

/**
 * Validate template schema has correct amount of `ProductData` components in a `products` container
 * @param amount
 * @param schema
 */
const validateProductDataComponentCount = (amount: number, schema: SchemaComponents) => {
  const productsContainers = getProductsContainerComponents(schema)
  for (const products of productsContainers) {
    const productDatas = getProductDataComponentCount(products)
    if (productDatas === amount) {
      continue
    } else {
      return false
    }
  }
  return true
}

const isTemplateComponentStylesAllowed = (componentType?: ComponentType) => {
  if (componentType === ComponentType.fishItems || componentType === ComponentType.meatItems) {
    return false
  }
  return true
}

export {
  generateNewIds,
  getSchemaChildWithNewIds,
  getPathToChildrenArray,
  getPathToChild,
  getSchemaChild,
  removeSchemaChild,
  removeStylesChild,
  removeChildElement,
  updateSchemaComponent,
  addNewChildToSchemaComponents,
  reorderSchemaChild,
  reorderStylesChild,
  duplicateSchemaChild,
  getProductsContainerComponents,
  getProductDataComponentCount,
  validateProductDataComponentCount,
  isTemplateComponentStylesAllowed
}
