import React from 'react'
import {
  Dialog,
  Typography,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button
} from '@mui/material'
import { TFunction } from 'i18next'
import { ContentEditorDialog } from '../../../../../types/contents'
import SupportCards from '../../../../Support/SupportCards'

const UnlockFeaturesDialog: React.FC<{
  isOpen: boolean
  closeDialog: (dialogId: ContentEditorDialog) => void
  t: TFunction
}> = ({ isOpen, closeDialog, t }) => {
  return (
    <Dialog open={isOpen} onClose={() => closeDialog(ContentEditorDialog.LimitedFeatures)}>
      <DialogTitle>{t('contents.unlockFeatures.dialogTitle')}</DialogTitle>
      <DialogContent>
        <Typography>{t('contents.unlockFeatures.dialogContent')}</Typography>
        <SupportCards t={t} chatVisible />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeDialog(ContentEditorDialog.LimitedFeatures)} color='primary'>
          {t('general.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UnlockFeaturesDialog
