import React from 'react'
import { InjectedFormProps, Field, reduxForm } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { Typography, DialogActions, Button } from '@mui/material'
import { DeleteCustomerFormData } from '../../../types/customers'
import { ReduxTextField } from '../../../components/FormInput/ReduxWrappers'
import { required } from '../../../validation'
import { CloseDialog } from '../../../types/actions'
import ErrorMessage from '../../../components/Errors/ErrorMessage'

interface OwnProps {
  dialogId: string
  name: string
  deleteCustomer: (formData: any) => void
  closeDialog: CloseDialog
}

const DeleteCustomerForm: React.FC<OwnProps &
  InjectedFormProps<DeleteCustomerFormData, OwnProps>> = ({
  dialogId,
  name,
  handleSubmit,
  submitting,
  deleteCustomer,
  closeDialog,
  pristine,
  error
}) => {
  const [t] = useTranslation()
  return (
    <form onSubmit={handleSubmit(deleteCustomer)}>
      <Typography>{t('customers.deleteWarning')}</Typography>
      <i>{t('customers.deleteConfirm', { name })}</i>
      <Field
        style={{ marginTop: 8 }}
        fullWidth
        name='confirmationName'
        component={ReduxTextField}
        variant='outlined'
        required
        validate={[required]}
      />
      {error && <ErrorMessage message={error} />}
      <DialogActions>
        <Button onClick={() => closeDialog(dialogId)} color='primary'>
          {t('general.cancel')}
        </Button>
        <Button disabled={submitting || pristine} color='secondary' type='submit'>
          {t('general.delete')}
        </Button>
      </DialogActions>
    </form>
  )
}

export default reduxForm<DeleteCustomerFormData, OwnProps>({
  form: 'DeleteCustomerForm'
})(DeleteCustomerForm)
