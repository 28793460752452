import React from 'react'
import { TFunction } from 'i18next'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  Campaign,
  CampaignSub,
  isSubCampaign,
  isParentCampaign
} from '@seesignage/seesignage-utils'
import moment from 'moment'
import colors from '../../styles/common/colors'

const useStyles = makeStyles(() => ({
  status: {
    padding: 8,
    color: '#ffffff',
    textAlign: 'center' as 'center',
    fontWeight: 500,
    fontFamily: 'Roboto'
  },
  ready: {
    backgroundColor: colors.screenStatuses.onlineBg,
    color: colors.screenStatuses.onlineText
  },
  needChanges: {
    backgroundColor: colors.screenStatuses.notLinkedBg,
    color: colors.screenStatuses.notLinkedText
  },
  ended: {
    backgroundColor: colors.screenStatuses.standbyBg,
    color: colors.screenStatuses.standbyText
  }
}))

const renderCampaignStatusText = (
  campaign: Campaign | CampaignSub,
  isEnded: boolean,
  t: TFunction
) => {
  if (isEnded) {
    return t('campaigns.ended')
  }
  if (isParentCampaign(campaign)) {
    if (campaign.published) {
      return t('campaigns.published', {
        publishedAt: moment(campaign.publishedAt).format('DD.MM.YYYY')
      })
    } else {
      return t('campaigns.notPublished')
    }
  }
  if (isSubCampaign(campaign)) {
    if (campaign.accepted) {
      return t('campaigns.accepted', {
        acceptedAt: moment(campaign.acceptedAt).format('DD.MM.YYYY')
      })
    } else {
      return t('campaigns.notAccepted')
    }
  }
  return ''
}

interface LastContactDateProps {
  t: TFunction
  campaign: Campaign | CampaignSub
}

const CampaignCardStatus: React.FC<LastContactDateProps> = ({ campaign, t }) => {
  const classes = useStyles()
  const isEnded = new Date(campaign.endDate) < new Date()
  return (
    <div
      className={classNames({
        [classes.status]: true,
        [classes.ended]: isEnded,
        [classes.ready]:
          !isEnded &&
          ((isSubCampaign(campaign) && campaign.accepted) ||
            (isParentCampaign(campaign) && campaign.published)),
        [classes.needChanges]:
          !isEnded &&
          ((isSubCampaign(campaign) && !!campaign.accepted === false) ||
            (isParentCampaign(campaign) && !!campaign.published === false))
      })}>
      {renderCampaignStatusText(campaign, isEnded, t)}
    </div>
  )
}

export default CampaignCardStatus
