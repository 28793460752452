import {
  PlaylistItemUI,
  isVideoPlaylistItem,
  isRevolverPlaylistItem,
  RevolverPlaylistItemUI,
  MediaType,
  PlaylistDuration,
  Schedule,
  ScheduleLegacy,
  isLegacySchedule,
  WeatherConditionTypes,
  Media,
  RevolverItemUI,
  LogicalOperator
} from '@seesignage/seesignage-utils'
import nanoid from 'nanoid'
import i18n from '../translations/i18n'
import {
  PlaylistItemDuration,
  SchedulePlaylistItemFormData,
  AddPlaylistItemWeatherConditionFormData,
  PlaylistItemConditionType,
  AddPlaylistItemTagsConditionFormData
} from '../types/playlists'
import { millisToMinutesAndSeconds } from './time'
import { convertScheduleDateTimeToDate, convertScheduleTimeToDate } from './forms'

/**
 * Get minimum/maximum duration of revolver items and calculate average duration of all items
 * @param item
 * @param defaultInterval
 */
const getRevolverDurations = (item: RevolverPlaylistItemUI, defaultInterval: number) => {
  const { revolverItems } = item
  const { durations, sum } = revolverItems.reduce(
    ({ durations, sum }: { durations: number[]; sum: number }, item) => {
      if (item.type === MediaType.video && !item.duration) {
        throw new Error('Could not get video duration')
      }
      const duration = item.type === MediaType.video ? item.duration || 0 : defaultInterval * 1000
      durations.push(duration)
      sum += duration
      return {
        durations,
        sum
      }
    },
    { durations: [], sum: 0 }
  )
  const avg = sum / durations.length || 0
  return {
    max: Math.max(...durations),
    min: Math.min(...durations),
    avg
  }
}

/**
 * Calculate total duration of Playlist. If playlist contains videos, that have been transcoded
 * before duration support, will return undefined.
 *   - Skips hidden items
 * @param playlistItems
 * @param defaultInterval
 */
const calculateTotalDuration = (playlistItems: PlaylistItemUI[], defaultInterval: number) => {
  try {
    return playlistItems.reduce(
      (playlistDuration: PlaylistDuration, item) => {
        if (item.isHidden) {
          return playlistDuration // skip hidden items from count
        }
        if (isVideoPlaylistItem(item)) {
          if (!item.duration) {
            throw new Error('Unable to calculate duration')
          }
          playlistDuration.min += item.duration
          playlistDuration.max += item.duration
          playlistDuration.average += item.duration
        } else if (isRevolverPlaylistItem(item)) {
          const { max, min, avg } = getRevolverDurations(item, defaultInterval)
          playlistDuration.min += min
          playlistDuration.max += max
          playlistDuration.average += avg
        } else if (item.interval) {
          playlistDuration.min += item.interval * 1000
          playlistDuration.max += item.interval * 1000
          playlistDuration.average += item.interval * 1000
        } else {
          playlistDuration.max += defaultInterval * 1000
          playlistDuration.min += defaultInterval * 1000
          playlistDuration.average += defaultInterval * 1000
        }
        return playlistDuration
      },
      { min: 0, max: 0, average: 0 }
    )
  } catch (error) {
    return undefined
  }
}

/**
 * Calculate the percentage ratio of an item compared to total playlist duration.
 * @returns string representation of the ratio.
 * @param totalDuration
 * @param item
 * @param defaultInterval
 */
const calculateItemPercentage = (
  item: PlaylistItemUI,
  defaultInterval: number,
  totalDuration?: PlaylistDuration
): PlaylistItemDuration => {
  let itemComparisonValue = 0
  let isCustomInterval = false
  let range: any
  if (isRevolverPlaylistItem(item)) {
    try {
      const { avg, min, max } = getRevolverDurations(item, defaultInterval)
      itemComparisonValue = avg
      range = {
        min,
        max
      }
    } catch (error) {
      // empty catch
    }
  } else if (isVideoPlaylistItem(item)) {
    itemComparisonValue = item.duration || 0
  } else if (item.interval) {
    itemComparisonValue = item.interval * 1000
    isCustomInterval = true
  } else {
    itemComparisonValue = defaultInterval * 1000
  }
  return {
    duration: itemComparisonValue,
    isCustomInterval,
    range,
    percentageText: totalDuration
      ? getItemPercentageText(
          Math.round((itemComparisonValue / totalDuration.average) * 100),
          totalDuration.max !== totalDuration.average
        )
      : 'N/A%'
  }
}

/**
 * String representation of item percentage
 */
const getItemPercentageText = (percentage: number, isEstimate: boolean) =>
  isEstimate ? `~${percentage}%` : `${percentage}%`

/**
 * Get duration text for Playlist
 * @param playlistDuration
 */
const getPlaylistDurationText = (playlistDuration: PlaylistDuration) => {
  const { max, min } = playlistDuration
  return i18n.t('playlists.durations.totalDuration', {
    totalDuration:
      max !== min
        ? `${millisToMinutesAndSeconds(min)} - ${millisToMinutesAndSeconds(max)}`
        : millisToMinutesAndSeconds(max)
  })
}

/**
 * Get minutes and seconds from item interval
 * @returns minutes and seconds, if interval is undefined, both are 0
 * @param interval
 * @param defaultValue return default value if interval is undefined
 */
const getMinutesAndSecondsFromInterval = (interval?: number, defaultValue?: number) => {
  if (interval) {
    const minutes = interval > 60 ? Math.floor(interval / 60) : 0
    return {
      minutes: minutes,
      seconds: interval > 60 ? interval - minutes * 60 : interval
    }
  }
  return {
    minutes: 0,
    seconds: defaultValue
  }
}

const getPlaylistItemDefaultScheduleValues = (): SchedulePlaylistItemFormData => {
  const start = new Date()
  const end = new Date()
  start.setHours(0, 0, 0, 0)
  end.setHours(23, 59, 59, 0)
  return {
    ranges: [
      {
        start,
        end,
        conditions: [
          {
            startTime: start,
            endTime: end,
            days: {
              Monday: true,
              Tuesday: true,
              Wednesday: true,
              Thursday: true,
              Friday: true,
              Saturday: true,
              Sunday: true
            }
          }
        ]
      }
    ]
  }
}

const getPlaylistItemScheduleInitialValues = (
  schedule?: Schedule | ScheduleLegacy
): SchedulePlaylistItemFormData => {
  if (!schedule) {
    return getPlaylistItemDefaultScheduleValues()
  }
  if (!isLegacySchedule(schedule)) {
    return {
      ranges: schedule.ranges.map(({ start, end, conditions }) => ({
        start: convertScheduleDateTimeToDate(start),
        end: convertScheduleDateTimeToDate(end),
        conditions: conditions.map(({ startTime, endTime, days }) => ({
          startTime: convertScheduleTimeToDate(startTime),
          endTime: convertScheduleTimeToDate(endTime),
          days
        }))
      }))
    }
  } else {
    return {
      ranges: schedule.ranges.map(({ startDate, endDate, conditions }) => ({
        start: new Date(startDate),
        end: new Date(endDate),
        conditions: conditions
          ? conditions.map(({ startTime, endTime, days }) => ({
              startTime: new Date(startTime),
              endTime: new Date(endTime),
              days
            }))
          : []
      }))
    }
  }
}

const getAddItemTagsConditionFormInitialValues = (playlistItem?: PlaylistItemUI) => {
  const initialValues: AddPlaylistItemTagsConditionFormData = {
    type: PlaylistItemConditionType.tags,
    tags:
      playlistItem?.conditions?.tags && playlistItem.conditions.tags.length > 0
        ? playlistItem?.conditions?.tags?.map(({ operator, values, not }) => ({
            operator,
            values,
            not: not ? true : false
            // make sure that not is false if undefined
            // because older playlist item tag conditions do not have it
          }))
        : [{ operator: LogicalOperator.or, values: [], not: false }]
  }
  return initialValues
}

const defaultWeatherConditions: WeatherConditionTypes = {
  sun: true,
  cloud: true,
  rain: true,
  snow: true
}

const getAddItemWeatherConditionFormInitialValues = (playlistItem?: PlaylistItemUI) => {
  const initialValues: AddPlaylistItemWeatherConditionFormData = {
    type: PlaylistItemConditionType.weather,
    weather: {
      condition: playlistItem?.conditions?.weather?.condition || defaultWeatherConditions,
      temperatures: playlistItem?.conditions?.weather?.temperatures
    }
  }
  return initialValues
}

const createRevolverItemFromMedia = ({ key, type, name, url, thumbnailUrl, duration }: Media) => {
  const newRevolverItem: RevolverItemUI = {
    itemId: nanoid(),
    key,
    type,
    name,
    url: url as string,
    thumbnailUrl,
    duration
  }
  return newRevolverItem
}

export {
  getRevolverDurations,
  calculateTotalDuration,
  calculateItemPercentage,
  getItemPercentageText,
  getPlaylistDurationText,
  getMinutesAndSecondsFromInterval,
  getPlaylistItemDefaultScheduleValues,
  getPlaylistItemScheduleInitialValues,
  getAddItemTagsConditionFormInitialValues,
  getAddItemWeatherConditionFormInitialValues,
  createRevolverItemFromMedia
}
