import React, { useState } from 'react'
import classNames from 'classnames'
import Truncate from 'react-truncate'
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
  CardMedia,
  IconButton,
  Tooltip
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import dateFormat from 'dateformat'
import { useTranslation } from 'react-i18next'
import { ContentUI, InfopageStore, isInfopage } from '@seesignage/seesignage-utils'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import CheckCircleIcon from 'mdi-react/CheckCircleIcon'
import CloseCircleIcon from 'mdi-react/CloseCircleIcon'
import PaletteAdvancedIcon from 'mdi-react/PaletteAdvancedIcon'
import { TFunction } from 'i18next'
import { Navigate, CloseDialog, DeleteContent, DuplicateContent } from '../../../types/actions'
import { UserInterface } from '../../../types/users'
import LightTooltip from '../../../components/Tooltip/LightTooltip'
import { isContent } from '../../../types/contents'
import { duplicateContent } from '../../../actions/contents'
import { closeDialog } from '../../../actions/dialogs'
import { navigate } from '../../../actions/routes'
import {
  copyInfopage,
  selectInfopage,
  deselectInfopage,
  deleteInfopage
} from '../../../actions/infopages'
import { StateInterface } from '../../../types/states'
import { selectTemplateById } from '../../../selectors/templates'
import { SelectedInfopageType } from '../../../types/infopages'
import { selectIsUserDeviceMobile } from '../../../selectors/users'
import useLongPress from '../../../hooks/longPress'
import ContentCardActions, { InfopageCardActionsOwnProps } from './InfopageCardActions'
import defaultContent from './defaultContent.jpg'

const useStyles = makeStyles(theme => ({
  card: {
    width: 250,
    height: 325,
    display: 'inline-block',
    verticalAlign: 'middle',
    margin: '0px 5px 15px',
    position: 'relative'
  },
  '@keyframes recentChanges': {
    '0%': {
      opacity: 0.1
    },
    '100%': {
      opacity: 1
    }
  },
  cardWithRecentChanges: {
    boxShadow: `${theme.palette.success.light} 0px 4px 8px 1px, ${theme.palette.success.light} 0px 2px 2px 1px, ${theme.palette.success.light} 0px 3px 1px -2px`,
    animation: `$recentChanges 1000ms ${theme.transitions.easing.easeInOut}`
  },
  cardContent: {
    display: 'inline-block',
    cursor: 'pointer',
    userSelect: 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    '-webkit-user-select': 'none',
    height: 105,
    overflow: 'auto',
    padding: '4px 16px'
  },
  selected: {
    boxShadow: '1px 3px 5px 2px rgba(0, 174, 239, .7)'
  },
  actions: {
    float: 'right'
  },
  link: {
    textDecoration: 'none'
  },
  titleText: {
    width: 195,
    padding: '4px',
    float: 'left',
    textAlign: 'left',
    fontSize: '1.10rem'
  },
  cardMedia: {
    height: 150,
    width: '100%',
    top: 0,
    objectFit: 'contain'
  },
  selectButton: {
    float: 'right',
    position: 'absolute',
    backgroundColor: 'rgba(255,255,255,0.9)',
    borderRadius: '50%',
    padding: 0,
    top: 6,
    zIndex: 99,
    right: 5
  },
  templateContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  }
}))

interface ActiveIndicatorProps {
  t: TFunction
}

const ActiveIndicator = ({ t }: ActiveIndicatorProps) => (
  <div style={{ position: 'absolute', bottom: 43, marginLeft: 10 }}>
    <Typography variant='caption' color='primary'>
      {t('infopages.active')}
    </Typography>
  </div>
)

/**
 * Check if recent changes
 * modified in last 1 minute
 */
const hasRecentChanges = (createdAt: number, updatedAt?: number) => {
  const limit = Date.now() - 1000 * 30 * 1
  return createdAt > limit || (updatedAt && updatedAt > limit)
}

export interface InfopageCardOwnProps {
  infopage: ContentUI | InfopageStore
  type: SelectedInfopageType
  user: UserInterface
  isSelected: boolean
  /** Are any infopages selected */
  infopagesSelected: boolean
}

interface StateProps {
  templateName?: string
  isMobile: boolean
}

interface DispatchProps {
  navigate: Navigate
  remove: DeleteContent
  duplicate: DuplicateContent
  closeDialog: CloseDialog
  selectInfopage: (id: string, type: SelectedInfopageType) => void
  deselectInfopage: (id: string) => void
}

type InfopageCardProps = InfopageCardOwnProps & DispatchProps & StateProps

/**
 * Card to display both Contents and Infopages
 * @param param0
 */
const InfopageCard: React.FC<InfopageCardProps> = ({
  infopage,
  type,
  isSelected,
  infopagesSelected,
  templateName,
  user,
  isMobile,
  navigate,
  remove,
  duplicate,
  closeDialog,
  selectInfopage,
  deselectInfopage
}) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const [checkboxStyles, setCheckboxStyles] = useState({ display: 'none ' })
  const { environmentId, name, createdAt, updatedAt, inUse } = infopage
  const id = isContent(infopage) ? infopage.contentId : infopage.infopageId
  const cardActionsProps: InfopageCardActionsOwnProps = {
    infopage,
    deleteInfopage: remove,
    duplicateContent: duplicate,
    closeDialog,
    isSystemAdmin: user.role === 'admin',
    type,
    t
  }
  const touchEvents = useLongPress(() => selectInfopage(id, type))

  const selectionEvents = isMobile
    ? touchEvents
    : {
        onMouseEnter: () => setCheckboxStyles({ display: 'block' }),
        onMouseLeave: () => setCheckboxStyles({ display: 'none' })
      }
  return (
    <Card
      {...selectionEvents}
      className={classNames({
        [classes.card]: true,
        [classes.selected]: isSelected,
        [classes.cardWithRecentChanges]: hasRecentChanges(createdAt, updatedAt)
      })}>
      <Tooltip disableInteractive title={t(isSelected ? 'general.deselect' : 'general.select')}>
        <div style={infopagesSelected ? { display: 'block' } : checkboxStyles}>
          <IconButton
            style={{
              color: !isSelected ? '#AAA' : 'rgba(0, 174, 239, .7)'
            }}
            className={classes.selectButton}
            onClick={() => (isSelected ? deselectInfopage(id) : selectInfopage(id, type))}
            size='large'>
            {isSelected ? <CloseCircleIcon /> : <CheckCircleIcon />}
          </IconButton>
        </div>
      </Tooltip>
      <LightTooltip title={t('general.edit')}>
        <CardActionArea onClick={() => navigate(`/environments/${environmentId}/${type}s/${id}`)}>
          <CardMedia
            component='img'
            className={classes.cardMedia}
            image={infopage.thumbnailUrl || defaultContent}
          />
          <CardContent className={classes.cardContent}>
            <div className={classes.titleText}>
              <Truncate width={225} lines={2}>
                {name}
              </Truncate>
            </div>
            {(updatedAt || createdAt) && (
              <Typography variant='caption' display='block'>
                {t('contents.updated', { date: dateFormat(updatedAt || createdAt, 'dd.mm.yyyy') })}
              </Typography>
            )}
            {isContent(infopage) ? (
              <Typography variant='caption' display='block'>
                {t('contents.resolutionInfo', {
                  width: infopage.resolution.width,
                  height: infopage.resolution.height
                })}
              </Typography>
            ) : (
              <div className={classes.templateContainer}>
                <PaletteAdvancedIcon size={16} style={{ marginRight: 4 }} />
                <span>{templateName}</span>
              </div>
            )}
          </CardContent>
        </CardActionArea>
      </LightTooltip>
      {inUse && <ActiveIndicator t={t} />}
      <ContentCardActions {...cardActionsProps} />
    </Card>
  )
}

const mapStateToProps = (state: StateInterface, ownProps: InfopageCardOwnProps): StateProps => ({
  templateName: isInfopage(ownProps.infopage)
    ? selectTemplateById(ownProps.infopage.templateId)(state)?.name
    : undefined,
  isMobile: selectIsUserDeviceMobile(state)
})

const mapDispatchToProps = (dispatch: Dispatch, ownProps: InfopageCardOwnProps): DispatchProps => {
  const { type } = ownProps
  const duplicateAction = type === SelectedInfopageType.content ? duplicateContent : copyInfopage
  return {
    closeDialog: (dialogId: string) => dispatch(closeDialog(dialogId)),
    navigate: (path: string) => dispatch(navigate(path)),
    remove: (type: SelectedInfopageType, id: string) => dispatch(deleteInfopage([{ type, id }])),
    duplicate: (id: string) => dispatch(duplicateAction(id)),
    selectInfopage: (id: string, type: SelectedInfopageType) =>
      dispatch(selectInfopage({ id, type })),
    deselectInfopage: (id: string) => dispatch(deselectInfopage(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InfopageCard)
