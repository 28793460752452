import React from 'react'
import { Snackbar, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import FolderPlusIcon from 'mdi-react/FolderPlusIcon'
import CloudUploadIcon from 'mdi-react/CloudUploadIcon'

const useStyles = makeStyles(() => ({
  snackbarContainer: {
    minWidth: '300px',
    padding: '10px',
    boxShadow: '2px 2px 10px 2px rgba(0, 0, 0, .3)',
    background: 'rgba(0, 174, 239, 1)',
    borderRadius: '10px',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  folderNameWrapper: {
    display: 'flex'
  },
  folderIcon: {
    marginRight: '10px'
  }
}))

interface DragDropSnackbarProps {
  open: boolean
  label: string
  mediaTargetFolderName?: string
}

const DragDropSnackbar: React.FC<DragDropSnackbarProps> = ({
  open,
  label,
  mediaTargetFolderName
}) => {
  const classes = useStyles()
  return (
    <Snackbar open={open}>
      <div className={classes.snackbarContainer}>
        <CloudUploadIcon size='35px' />
        <Typography>{label}</Typography>
        {mediaTargetFolderName && (
          <div className={classes.folderNameWrapper}>
            <FolderPlusIcon className={classes.folderIcon} />
            <Typography>{mediaTargetFolderName}</Typography>
          </div>
        )}
      </div>
    </Snackbar>
  )
}

export default DragDropSnackbar
