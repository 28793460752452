import React, { Fragment } from 'react'
import moment from 'moment'
import {
  Campaign,
  CampaignSub,
  isParentCampaign,
  isSubCampaign
} from '@seesignage/seesignage-utils'
import dateFormat from 'dateformat'
import {
  Card,
  Tooltip,
  CardActionArea,
  CardContent,
  Typography,
  CardActions,
  DialogContent,
  DialogActions,
  Button,
  CardHeader,
  CardMedia,
  IconButton
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import DeleteIcon from 'mdi-react/DeleteIcon'
import TagIcon from 'mdi-react/TagIcon'
import PoundBoxIcon from 'mdi-react/PoundBoxIcon'
import ContentCopyIcon from 'mdi-react/ContentCopyIcon'
import MicrosoftExcelIcon from 'mdi-react/MicrosoftExcelIcon'
import { Navigate, CloseDialog } from '../../types/actions'
import { StateInterface } from '../../types/states'
import { selectTemplateById } from '../../selectors/templates'
import { navigate } from '../../actions/routes'
import Dialog from '../Dialog'
import { closeDialog } from '../../actions/dialogs'
import { deleteCampaign, copyCampaign } from '../../actions/campaigns'
import { selectIsCampaignDeleting } from '../../selectors/campaigns'
import Tags from '../../components/Chips/Tags'
import CampaignCardStatus from './CampaignCardStatus'
import GenerateCampaignExcelForm from './Forms/GenerateCampaignExcelForm'

const formatValidString = (startDate: string, endDate: string) => {
  const start = moment(startDate).format('DD.MM.YYYY')
  const end = moment(endDate).format('DD.MM.YYYY')
  return `${start} - ${end}`
}

const useStyles = makeStyles(() => ({
  card: {
    width: 300,
    height: 325,
    display: 'inline-block',
    verticalAlign: 'middle',
    margin: '0px 5px 15px',
    position: 'relative'
  },
  selectArea: {
    cursor: 'pointer',
    userSelect: 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    '-webkit-user-select': 'none',
    height: 105
  },
  actions: {
    float: 'right'
  },
  subTitleText: {
    width: '100%',
    fontSize: '1rem',
    padding: '4px 0 4px'
  },
  errorTitle: {
    width: '100%',
    fontSize: '0.9rem',
    padding: '4px 0 4px',
    margin: '4px 0 4px',
    color: 'red'
  },
  bodyText: {
    width: '100%',
    height: '80%',
    padding: '4px',
    overflowY: 'auto'
  },
  paper: {
    padding: '4px 6px 4px',
    margin: '4px 0 4px'
  },
  validFrom: {
    fontWeight: 500
  },
  title: {
    width: 220
  }
}))

interface OwnProps {
  campaign: Campaign | CampaignSub
}

interface StateProps {
  templateName?: string
  isDeleting: boolean
}

interface DispatchProps {
  navigate: Navigate
  deleteCampaign: (campaignId: string) => void
  copyCampaign: () => void
  closeDialog: CloseDialog
}

type CampaignCardProps = OwnProps & StateProps & DispatchProps

const CampaignCard: React.FC<CampaignCardProps> = ({
  campaign,
  navigate,
  deleteCampaign,
  copyCampaign,
  closeDialog,
  isDeleting
}) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const {
    environmentId,
    name,
    startDate,
    endDate,
    campaignId,
    tags,
    updatedAt,
    createdAt
  } = campaign
  return (
    <Card className={classes.card}>
      <Tooltip disableInteractive placement='bottom' title={t('campaigns.open')}>
        <CardActionArea
          onClick={() =>
            navigate(`/environments/${environmentId}/campaigns?campaignId=${campaignId}`)
          }>
          <CardHeader
            avatar={<TagIcon />}
            title={
              <Typography className={classes.title} noWrap>
                {name}
              </Typography>
            }
            subheader={
              <Fragment>
                <Typography variant='caption' className={classes.validFrom}>
                  {t('campaigns.validFrom', { valid: formatValidString(startDate, endDate) })}
                </Typography>
                <Typography variant='caption' display='block'>
                  {t(`campaigns.${updatedAt ? 'updatedAt' : 'createdAt'}`, {
                    date: dateFormat(updatedAt ? updatedAt : createdAt, 'dd.mm.yyyy')
                  })}
                </Typography>
              </Fragment>
            }
          />
          <CardMedia>
            <CampaignCardStatus campaign={campaign} t={t} />
          </CardMedia>
          <CardContent className={classes.selectArea}>
            <Tags icon={PoundBoxIcon} tags={tags} color='primary' />
          </CardContent>
        </CardActionArea>
      </Tooltip>
      <CardActions className={classes.actions}>
        <Dialog
          dialogId={`GenerateCampaignCsvDialog.${campaignId}`}
          title={t('campaigns.excel.generate', { name })}
          ButtonIcon={MicrosoftExcelIcon}
          tooltipTitle={t('campaigns.excel.generate')}
          Content={
            <GenerateCampaignExcelForm
              name={name}
              dialogId={`GenerateCampaignCsvDialog.${campaignId}`}
              isCampaignSub={isSubCampaign(campaign)}
              initialValues={{ campaignId, type: 'own' }}
            />
          }
        />
        {isParentCampaign(campaign) && (
          <Fragment>
            <Tooltip disableInteractive title={t('campaigns.actions.copy')}>
              <IconButton onClick={() => copyCampaign()} size='large'>
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
            <Dialog
              dialogId={`DeleteCampaignDialog.${campaignId}`}
              title={`${t('campaigns.actions.delete', { name })}`}
              ButtonIcon={DeleteIcon}
              tooltipTitle={t('campaigns.actions.delete', { name })}
              Content={
                <Fragment>
                  <DialogContent>
                    <Typography display='block' variant='body1'>
                      {t('campaigns.actions.deleteConfirm')}
                    </Typography>
                    <i style={{ color: 'tomato ' }}>{t('campaigns.actions.deleteNotice')}</i>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      disabled={isDeleting}
                      onClick={() => closeDialog(`DeleteCampaignDialog.${campaignId}`)}
                      color='primary'>
                      {t('general.cancel')}
                    </Button>
                    <Button
                      disabled={isDeleting}
                      onClick={() => deleteCampaign(campaignId)}
                      color='primary'
                      autoFocus>
                      {t('general.delete')}
                    </Button>
                  </DialogActions>
                </Fragment>
              }
            />
          </Fragment>
        )}
      </CardActions>
    </Card>
  )
}

const mapStateToProps = (state: StateInterface, ownProps: OwnProps): StateProps => ({
  templateName: selectTemplateById(ownProps.campaign?.templateId)(state)?.name,
  isDeleting: selectIsCampaignDeleting(state)
})

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps): DispatchProps => ({
  navigate: (path: string) => dispatch(navigate(path)),
  closeDialog: (dialogId: string) => dispatch(closeDialog(dialogId)),
  deleteCampaign: (campaignId: string) => dispatch(deleteCampaign(campaignId)),
  copyCampaign: () => dispatch(copyCampaign(ownProps.campaign.campaignId))
})

export default connect(mapStateToProps, mapDispatchToProps)(CampaignCard)
