import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { LinearProgress } from '@mui/material'
import PageTitle from '../../components/PageTitle'
import { StateInterface } from '../../types/states'
import { selectIsListAllLoading } from '../../selectors/customers'
import { BreadcrumbContentType } from '../../types/breadcrumbs'
import CustomersTable from './CustomersTable'

interface StateProps {
  isLoading: boolean
}

const Customers: React.FC<StateProps> = ({ isLoading }) => (
  <Fragment>
    <PageTitle contentType={BreadcrumbContentType.integrations} />
    {isLoading && <LinearProgress />}
    <CustomersTable />
  </Fragment>
)

const mapStateToProps = (state: StateInterface): StateProps => ({
  isLoading: selectIsListAllLoading(state)
})

export default connect(mapStateToProps)(Customers)
