import React from 'react'
import { Typography, Select, Tooltip, IconButton } from '@mui/material'
import { TFunction } from 'i18next'
import ArrowDownIcon from 'mdi-react/ArrowDownIcon'
import ArrowUpIcon from 'mdi-react/ArrowUpIcon'
import { SortByValue, SortDirection } from '../../types/sortings'

interface SorterProps {
  sortBy: SortByValue
  setSortBy: React.Dispatch<React.SetStateAction<SortByValue>>
  sortDirection: SortDirection
  setSortDirection: React.Dispatch<React.SetStateAction<SortDirection>>
  t: TFunction
  children: React.ReactNode
}

/**
 * Component to handle sorting of items.
 *  - Requires useState parameters for modifying order of parent component items.
 *  - Provide MenuItem options as children
 */
const Sorter: React.FC<SorterProps> = ({
  children,
  sortBy,
  setSortBy,
  sortDirection,
  setSortDirection,
  t
}: SorterProps) => (
  <div style={{ float: 'right' }}>
    <Typography style={{ padding: 10 }} variant='caption'>
      {t('sorting.sortBy.sortBy')}
    </Typography>
    <Select
      variant='standard'
      style={{ width: 120 }}
      IconComponent={undefined}
      value={sortBy}
      onChange={e => setSortBy(e.target.value as SortByValue)}>
      {children}
    </Select>
    <Tooltip disableInteractive title={t('sorting.sortBy.changeDirection')}>
      {sortDirection === SortDirection.desc ? (
        <IconButton onClick={() => setSortDirection(SortDirection.asc)} size='large'>
          <ArrowDownIcon />
        </IconButton>
      ) : (
        <IconButton onClick={() => setSortDirection(SortDirection.desc)} size='large'>
          <ArrowUpIcon />
        </IconButton>
      )}
    </Tooltip>
  </div>
)

export default Sorter
