import { createSelector } from 'reselect'
import { StateInterface } from '../types/states'
import { SelectedInfopage, SelectedInfopageType } from '../types/infopages'

const selectInfopagesDomain = (state: StateInterface) => state.infopages

const selectInfopages = createSelector(selectInfopagesDomain, domain => domain.infopages)

const selectInfopagesAsArray = createSelector(selectInfopagesDomain, domain =>
  Object.values(domain.infopages)
)

const selectInfopagesArray = createSelector(selectInfopagesDomain, domain =>
  Object.values(domain.infopagesArray)
)

const selectInfopagesLastEvaluatedKey = createSelector(
  selectInfopagesDomain,
  domain => domain.lastEvaluatedKey
)

const selectHasMoreInfopages = createSelector(selectInfopagesLastEvaluatedKey, key => !!key)

const selectInfopagesAsOptions = createSelector(selectInfopagesAsArray, infopages =>
  infopages.map(({ name, infopageId }) => ({ label: name, value: infopageId }))
)

/**
 * Select array of infopages not currently in any playlist
 */
const selectInfopagesNotInUse = createSelector(selectInfopagesAsArray, infopages =>
  infopages.reduce((infopageIds: SelectedInfopage[], infopage) => {
    const { inUse, infopageId } = infopage
    if (!inUse) {
      infopageIds.push({ id: infopageId, type: SelectedInfopageType.infopage })
    }
    return infopageIds
  }, [])
)

const selectInfopageById = (infopageId?: string) =>
  createSelector(selectInfopages, infopages => (infopageId ? infopages[infopageId] : undefined))

const selectSelectedInfopages = createSelector(
  selectInfopagesDomain,
  infopages => infopages.selectedInfopages
)

const selectIsInfopageSettingsModified = createSelector(
  selectInfopagesDomain,
  domain => domain.infopageModified
)

const selectIsInfopagesLoading = createSelector(selectInfopagesDomain, domain => domain.isLoading)

const selectSelectedInfopageTemplate = createSelector(
  selectInfopagesDomain,
  domain => domain.selectedTemplate
)

const selectInfopageKeys = createSelector(selectInfopagesDomain, domain => domain.infopageKeys)

const selectIsInfopagesInitialLoad = createSelector(
  selectInfopagesDomain,
  domain => domain.isInitialLoad
)

const selectSelectedInfopageType = createSelector(
  selectInfopagesDomain,
  domain => domain.selectedInfopageType
)

export {
  selectInfopagesArray,
  selectInfopagesLastEvaluatedKey,
  selectHasMoreInfopages,
  selectInfopagesAsArray,
  selectInfopagesAsOptions,
  selectInfopageById,
  selectInfopagesNotInUse,
  selectSelectedInfopages,
  selectIsInfopageSettingsModified,
  selectIsInfopagesLoading,
  selectSelectedInfopageTemplate,
  selectInfopageKeys,
  selectIsInfopagesInitialLoad,
  selectSelectedInfopageType
}
