const offset1 = '0px 4px 8px 0px'
const offset2 = '0px 2px 2px 0px'
const offset3 = '0px 3px 1px -2px'

export const getItemShadow = (isDragging: boolean, isSelected?: boolean) => {
  if (isDragging) {
    return `${offset1} rgba(80, 195, 237, 3),
    ${offset2} rgba(80, 195, 237, 3), ${offset3} rgba(80, 195, 237, 3)`
  } else if (isSelected) {
    return `${offset1} rgba(0, 174, 239, 3),
    ${offset2} rgba(0, 174, 239, 3), ${offset3} rgba(0, 174, 239, 3)`
  } else {
    return `${offset1} rgba(0, 0, 0, 0.2),
    ${offset2} rgba(0, 0, 0, 0.14), ${offset3} rgba(0, 0, 0, 0.12)`
  }
}

export const getBackground = (isHidden?: boolean) => {
  if (isHidden) {
    return `repeating-linear-gradient(
      45deg,
      transparent,
      transparent 10px,
      #ccc 10px,
      #ccc 20px
    )`
  }
  return '#FAFAFA'
}

export const getOpacity = (isHidden?: boolean) => {
  if (isHidden) {
    return 0.3
  }
  return 1
}

export const getItemStyle = (
  isDragging: boolean,
  draggableStyle: any,
  isSelected?: boolean,
  isHidden?: boolean
) => ({
  userSelect: 'none',
  textAlign: 'center',
  margin: '0 0 12px 0',
  minWidth: 200,
  minHeight: 40,
  boxShadow: getItemShadow(isDragging, isSelected),
  ...draggableStyle,
  opacity: getOpacity(isHidden),
  background: getBackground(isHidden)
})
