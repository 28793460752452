import {
  AppBar,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  Divider
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { TFunction } from 'i18next'
import AccessPointIcon from 'mdi-react/AccessPointIcon'
import CloseIcon from 'mdi-react/CloseIcon'
import DownloadIcon from 'mdi-react/DownloadIcon'
import PlusIcon from 'mdi-react/PlusIcon'
import ViewGridIcon from 'mdi-react/ViewGridIcon'
import ViewListIcon from 'mdi-react/ViewListIcon'
import MoviePlayIcon from 'mdi-react/MoviePlayIcon'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import {
  ScreenResponse,
  Environment,
  Customer,
  EnvironmentUI,
  EnvironmentScreenFeatures,
  ScreenType
} from '@seesignage/seesignage-utils'
import SettingsIcon from 'mdi-react/SettingsIcon'
import { closeDialog } from '../../../actions/dialogs'
import { navigate } from '../../../actions/routes'
import {
  changeScreensViewMode,
  createScreen,
  deleteScreen,
  downloadScreensCsv,
  sendScreenCommand,
  updateScreen,
  deselectAllScreens
} from '../../../actions/screens'
import Menu from '../../../components/Menu'
import { selectEnvironmentById } from '../../../selectors/environments'
import { selectEnvironmentIdFromPathname } from '../../../selectors/routing'
import { selectIsUserDeviceMobile, isUserAdmin, isUserSystemAdmin } from '../../../selectors/users'
import {
  CloseDialog,
  Navigate,
  SendScreenCommand,
  SendScreenCommandParams,
  CreatePreviewSessionParams
} from '../../../types/actions'
import { bindSubmitActionToPromise } from '../../../utils/forms'
import Dialog from '../../Dialog'
import CreateScreenForm from '../Forms/CreateScreenForm'
import { toolbarStyles } from '../../../styles/common'
import { StateInterface } from '../../../types/states'
import { createPreviewSession } from '../../../actions/users'
import { OpenDialogButtonType } from '../../../types/dialogs'
import { selectCustomerWithApiIntegration } from '../../../selectors/customers'
import PreviewPlayer from '../../PreviewPlayer'
import {
  selectScreensAsArray,
  selectSelectedScreenIds,
  selectScreenById
} from '../../../selectors/screens'
import BatchUpdateScreensForm from '../Forms/BatchUpdateScreensForm'
import SelectedScreenActions from './SelectedScreenActions'
import ScreensSearchField from './ScreensSearchField'
import UpdateIntegrationScreensButton from './UpdateIntegrationScreensButton'

const useStyles = makeStyles(theme => ({
  ...toolbarStyles(theme),
  root: {
    paddingRight: theme.spacing()
  },
  highlight: {
    backgroundColor: theme.palette.primary.light
  },
  divider: {
    margin: '0px 8px 0px 0px'
  },
  selectedCount: { position: 'absolute', left: 8 }
}))

interface RenderToolbarTextProps {
  hasAdminAccess: boolean
  name?: string
  isMobile: boolean
  t: TFunction
}

const renderToolbarText = ({ hasAdminAccess, name, isMobile, t }: RenderToolbarTextProps) => (
  <Typography color='inherit' variant={isMobile ? 'caption' : 'subtitle1'} noWrap>
    {hasAdminAccess && !isMobile
      ? `${t('screens.manage')} ${name ? `(${name})` : ''}`
      : `${t('screens.screen')} ${name ? `(${name})` : ''}`}
  </Typography>
)

const renderDownloadCsvButton = (
  downloadScreensCsv: () => void,
  isMobile: boolean,
  t: TFunction
) => (
  <Tooltip disableInteractive title={t('screens.downloadCsv')} key='csv'>
    {isMobile ? (
      <MenuItem onClick={() => downloadScreensCsv()}>
        <ListItemIcon>
          <DownloadIcon />
        </ListItemIcon>
        <ListItemText primary={t('screens.downloadCsv')} />
      </MenuItem>
    ) : (
      <IconButton onClick={() => downloadScreensCsv()} size='large'>
        <DownloadIcon />
      </IconButton>
    )}
  </Tooltip>
)

const renderConnectionsButton = (navigate: Navigate, isMobile: boolean, t: TFunction) => (
  <Tooltip disableInteractive title={t('navigation.connections')} key='connections'>
    {isMobile ? (
      <MenuItem onClick={() => navigate('/connections')}>
        <ListItemIcon>
          <AccessPointIcon />
        </ListItemIcon>
        <ListItemText primary={t('navigation.connections')} />
      </MenuItem>
    ) : (
      <IconButton onClick={() => navigate('/connections')} size='large'>
        <AccessPointIcon />
      </IconButton>
    )}
  </Tooltip>
)

const renderCreateScreenButton = (
  createScreen: (formData: any) => void,
  isMobile: boolean,
  closeDialog: CloseDialog,
  t: TFunction,
  screenFeatures: EnvironmentScreenFeatures | undefined
) => {
  const createScreenFormInitialValues: any = {
    rotation: 0,
    type: ScreenType.media,
    configs: {
      volume: 0,
      security: {
        safetyLock: true
      }
    }
  }
  if (screenFeatures?.reduceServerLoadIot) {
    createScreenFormInitialValues.optimization = { reduceServerLoadIot: true }
  }
  return (
    <div id='create-screen-button' key='create screen'>
      <Dialog
        dialogId='CreateScreenDialog'
        title={t('screens.createNewScreen')}
        tooltipTitle={t('screens.createNewScreen')}
        ButtonIcon={PlusIcon}
        buttonLabel={isMobile ? t('screens.createNewScreen') : undefined}
        openDialogButtonType={isMobile ? OpenDialogButtonType.menuItem : OpenDialogButtonType.add}
        maxWidth='md'
        Content={
          <CreateScreenForm
            dialogId='CreateScreenDialog'
            submitAction={createScreen}
            closeDialog={closeDialog}
            submitButtonLabel={t('general.create')}
            initialValues={createScreenFormInitialValues}
          />
        }
      />
    </div>
  )
}
interface OwnProps {
  isListMode: boolean
}

interface StateProps {
  apiIntegrationCustomer?: Customer | undefined
  currentEnvironment?: Environment | EnvironmentUI
  hasAdminAccess: boolean
  isSystemAdmin: boolean
  isMobile: boolean
  selectedScreenIds: string[]
  selectedScreen?: ScreenResponse
}
interface DispatchProps {
  closeDialog: CloseDialog
  createScreen: (formData: any) => void
  updateScreen: (formData: any) => void
  deleteScreen: (screenId: string) => void
  deselectAllScreens: () => void
  createPreviewSession: (createPreviewSessionParams: CreatePreviewSessionParams) => void
  sendScreenCommand: SendScreenCommand
  changeScreensViewMode: (isListMode: boolean) => void
  navigate: Navigate
  downloadScreensCsv: () => void
}

type ScreensToolbarProps = OwnProps & StateProps & DispatchProps

const ScreensToolbar: React.FC<ScreensToolbarProps> = ({
  apiIntegrationCustomer,
  selectedScreenIds,
  selectedScreen,
  deselectAllScreens,
  closeDialog,
  createScreen,
  updateScreen,
  deleteScreen,
  sendScreenCommand,
  currentEnvironment,
  hasAdminAccess,
  isSystemAdmin,
  changeScreensViewMode,
  isListMode,
  isMobile,
  navigate,
  downloadScreensCsv,
  createPreviewSession
}) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const showActions = selectedScreenIds.length > 0
  const actionsProps = {
    closeDialog,
    deleteScreen,
    updateScreen,
    sendScreenCommand,
    createPreviewSession,
    isMobile,
    hasAdminAccess,
    isSystemAdmin,
    t
  }
  const firstScreenId = useSelector(selectScreensAsArray)[0]?.screenId
  const adminActions = [
    renderConnectionsButton(navigate, isMobile, t),
    renderDownloadCsvButton(downloadScreensCsv, isMobile, t),
    ...(isMobile || !isSystemAdmin
      ? []
      : [<Divider key='divider' orientation='vertical' flexItem className={classes.divider} />]),
    ...(isSystemAdmin
      ? [
          renderCreateScreenButton(
            createScreen,
            isMobile,
            closeDialog,
            t,
            currentEnvironment?.features?.screens
          )
        ]
      : [])
  ]
  return (
    <AppBar className={classes.appBar} position='sticky' color='default'>
      <Toolbar
        className={classNames(classes.root, {
          [classes.highlight]: showActions && selectedScreenIds.length === 1
        })}>
        {currentEnvironment && (
          <Fragment>
            {showActions ? (
              <div className={classes.title}>
                {selectedScreenIds.length === 1 ? (
                  renderToolbarText({
                    hasAdminAccess,
                    name: selectedScreen?.name,
                    isMobile,
                    t
                  })
                ) : (
                  <Typography color='primary' className={classes.selectedCount} variant='caption'>
                    {t('screens.selected', {
                      count: selectedScreenIds.length
                    })}
                  </Typography>
                )}
              </div>
            ) : (
              <ScreensSearchField />
            )}
            <div className={classes.spacer} />
            <div className={classes.actions}>
              <Dialog
                dialogId='previewScreenDialog'
                title={t('screens.preview.title')}
                tooltipTitle={t('screens.preview.title')}
                maxWidth='xl'
                noOpenDialogButton
                Content={<PreviewPlayer enableSelectScreen={true} />}
              />
              {showActions ? (
                <Fragment>
                  {selectedScreen && (
                    <SelectedScreenActions screen={selectedScreen} {...actionsProps} />
                  )}
                  {selectedScreenIds.length > 1 && hasAdminAccess && (
                    <Dialog
                      dialogId='BatchUpdateScreensDialog'
                      title={`${t('screens.batchUpdate')}`}
                      ButtonIcon={SettingsIcon}
                      buttonLabel={isMobile ? t('screens.update') : undefined}
                      tooltipTitle={t('screens.batchUpdate')}
                      Content={
                        <BatchUpdateScreensForm
                          closeDialog={closeDialog}
                          dialogId='BatchUpdateScreensDialog'
                          initialValues={{ selectedScreenIds }}
                        />
                      }
                    />
                  )}
                  <Tooltip
                    disableInteractive
                    title={t('screens.deselect', { count: selectedScreenIds.length }) as string}>
                    <IconButton
                      onClick={() => deselectAllScreens()}
                      aria-label='Close'
                      size='large'>
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </Fragment>
              ) : (
                <Fragment>
                  <Tooltip disableInteractive title={t('screens.preview.title')}>
                    <IconButton
                      onClick={() => createPreviewSession({ screenId: firstScreenId })}
                      size='large'>
                      <MoviePlayIcon />
                    </IconButton>
                  </Tooltip>
                  {hasAdminAccess && (
                    <Fragment>
                      {apiIntegrationCustomer && (
                        <UpdateIntegrationScreensButton
                          environmentId={apiIntegrationCustomer.environmentId}
                          customerId={apiIntegrationCustomer.customerId}
                        />
                      )}
                      {isListMode ? (
                        <Tooltip disableInteractive title={t('screens.viewMode.grid')}>
                          <IconButton
                            onClick={() => changeScreensViewMode(false)}
                            aria-label={t('screens.viewMode.grid')}
                            size='large'>
                            <ViewGridIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip disableInteractive title={t('screens.viewMode.list')}>
                          <IconButton
                            onClick={() => changeScreensViewMode(true)}
                            aria-label={t('screens.viewMode.list')}
                            size='large'>
                            <ViewListIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {isMobile ? (
                        <Menu tooltipTitle={t('screens.options')} items={adminActions} />
                      ) : (
                        <Fragment>{adminActions}</Fragment>
                      )}
                    </Fragment>
                  )}
                </Fragment>
              )}
            </div>
          </Fragment>
        )}
      </Toolbar>
    </AppBar>
  )
}

const mapStateToProps = (state: StateInterface): StateProps => {
  const selectedScreenIds = selectSelectedScreenIds(state)
  return {
    currentEnvironment: selectEnvironmentById(selectEnvironmentIdFromPathname(state))(state),
    apiIntegrationCustomer: selectCustomerWithApiIntegration(state),
    isMobile: selectIsUserDeviceMobile(state),
    hasAdminAccess: isUserAdmin(state),
    isSystemAdmin: isUserSystemAdmin(state),
    selectedScreenIds,
    selectedScreen:
      selectedScreenIds.length === 1 ? selectScreenById(selectedScreenIds[0])(state) : undefined
  }
}
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  closeDialog: (dialogId: string) => dispatch(closeDialog(dialogId)),
  createScreen: bindSubmitActionToPromise(dispatch, createScreen),
  updateScreen: bindSubmitActionToPromise(dispatch, updateScreen),
  deleteScreen: (screenId: string) => dispatch(deleteScreen(screenId)),
  deselectAllScreens: () => dispatch(deselectAllScreens()),
  sendScreenCommand: (command: SendScreenCommandParams) => dispatch(sendScreenCommand(command)),
  changeScreensViewMode: (isListMode: boolean) => dispatch(changeScreensViewMode(isListMode)),
  navigate: (path: string) => dispatch(navigate(path)),
  downloadScreensCsv: () => dispatch(downloadScreensCsv()),
  createPreviewSession: (createPreviewSessionParams: CreatePreviewSessionParams) =>
    dispatch(createPreviewSession(createPreviewSessionParams))
})

export default connect(mapStateToProps, mapDispatchToProps)(ScreensToolbar)
