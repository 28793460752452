import { GridProps } from '@mui/material/Grid'

/**
 * Generate 'styles' field prefix
 * @param level
 * @param childIndexes
 * @param fieldName
 */
const generateFieldPrefix = (level: number, childIndexes: number[], fieldName: string) => {
  if (level > 0) {
    let prefix = ''
    for (const index of childIndexes) {
      prefix += `children[${index}].`
    }
    return `${prefix}styles.${fieldName}`
  } else {
    return `styles.${fieldName}`
  }
}

/**
 * Generate field prefix to root of current object.
 *  E.g with fieldName = 'key', generates {key: 'value'}
 * @param level
 * @param childIndexes
 * @param fieldName
 */
const generateKeyFieldPrefix = (level: number, childIndexes: number[], fieldName: string) => {
  if (level > 0) {
    let prefix = ''
    for (const index of childIndexes) {
      prefix += `children[${index}].`
    }
    return `${prefix}${fieldName}`
  } else {
    return `${fieldName}`
  }
}

const generateEffectsFieldPrefix = (level: number, childIndexes: number[], fieldName: string) => {
  if (level > 0) {
    let prefix = ''
    for (const index of childIndexes) {
      prefix += `children[${index}].`
    }
    return `${prefix}${fieldName}`
  } else {
    return `${fieldName}`
  }
}

const generateFieldArrayPrefix = (childIndexes: number[]) => {
  if (childIndexes.length > 0) {
    const childs = childIndexes.map(i => `children[${i}]`)
    return `${childs.join('.')}.children`
  }
  return 'children'
}
const gridItemProps: GridProps = {
  item: true,
  xs: 12,
  lg: 4
}

export {
  generateFieldPrefix,
  generateKeyFieldPrefix,
  generateEffectsFieldPrefix,
  gridItemProps,
  generateFieldArrayPrefix
}
