import React from 'react'
import { makeStyles } from '@mui/styles'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { useTranslation } from 'react-i18next'
import { Typography, Button } from '@mui/material'
import { SaveCanvas } from '../../types/actions'
import Breadcrumbs from '../../containers/Navigation/Breadcrumbs'
import { BreadcrumbContentType } from '../../types/breadcrumbs'
import MoreMenu from '../../containers/Contents/ContentEditor/ContentToolbar/CustomContentToolbar/MoreMenu'
import { toolbarStyles } from '../../styles/common'

const useStyles = makeStyles(theme => ({
  ...toolbarStyles(theme),
  contentBar: {
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    borderTop: 'none',
    top: 0
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}))

interface ContentEditorPageTitleProps {
  title?: string
  contentType?: BreadcrumbContentType
  contentId?: string
  contentName?: string
  additionalBreadcrumbs?: JSX.Element[]
  saveCanvas: SaveCanvas
  isModified: boolean
  isLoading: boolean
}

const ContentEditorPageTitle: React.FC<ContentEditorPageTitleProps> = ({
  contentType,
  title,
  contentId,
  contentName,
  additionalBreadcrumbs,
  saveCanvas,
  isModified,
  isLoading
}) => {
  const [t] = useTranslation()
  const classes = useStyles()
  return (
    <AppBar position='sticky' color='default' className={`${classes.appBar} ${classes.contentBar}`}>
      <Toolbar className={classes.toolBar}>
        {title ? (
          <Typography variant='h6'>{title}</Typography>
        ) : (
          <Breadcrumbs
            contentType={contentType}
            contentId={contentId}
            contentName={contentName}
            additionalBreadcrumbs={additionalBreadcrumbs}
          />
        )}
        <div>
          <MoreMenu t={t} />
          <Button
            variant='contained'
            color='primary'
            onClick={() => saveCanvas()}
            disabled={!isModified || isLoading}>
            {t('general.save')}
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  )
}
export default ContentEditorPageTitle
