import { Grid, Tooltip, Typography } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import UrlIcon from 'mdi-react/LinkIcon'
import React from 'react'
import Truncate from 'react-truncate'
import { UrlPlaylistItem } from '@seesignage/seesignage-utils'
import { PlaylistItemCardRootProps } from '../../../../../types/playlists'
import colors from '../../../../../styles/common/colors'
import SelectedIndicator from './SelectedIndicator'
import ItemIcons from './ItemIcons'

const styles = () => ({
  item: {
    height: 130,
    overflow: 'hidden'
  },
  itemTitleText: {
    padding: '4px',
    width: '100%',
    overflow: 'hidden'
  },
  mediaBox: {
    backgroundColor: 'white',
    display: 'flex',
    height: '100%',
    width: '100%'
  },
  itemDescriptionArea: {
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)'
  },
  icon: {
    margin: 'auto'
  }
})

interface OwnProps extends PlaylistItemCardRootProps {
  item: UrlPlaylistItem
  isMobile: boolean
}

type UrlAsItemProps = OwnProps & WithStyles<typeof styles>

const UrlAsItem: React.FC<UrlAsItemProps> = ({
  classes,
  item,
  isItemSelected,
  isMobile,
  itemPercentage,
  showPercentage
}) => (
  <Grid container className={classes.item}>
    <Grid item xs={4} className={classes.mediaBox}>
      <UrlIcon className={classes.icon} size={'70px'} color={colors.seesignageColor} />
    </Grid>
    <Grid item xs={8} className={classes.itemDescriptionArea}>
      <Grid container style={{ height: '100%' }}>
        <Grid item xs={12}>
          <Grid container className={classes.itemTitleText}>
            <Grid item xs={11}>
              <Tooltip disableInteractive title={item.url}>
                <Typography style={{ marginLeft: 4 }} align='left' variant='subtitle1' gutterBottom>
                  <Truncate lines={1} width={isMobile ? 180 : 300}>
                    {item.url}
                  </Truncate>
                </Typography>
              </Tooltip>
            </Grid>
            <SelectedIndicator
              isItemSelected={isItemSelected}
              showPercentage={showPercentage}
              itemPercentage={itemPercentage}
            />
          </Grid>
        </Grid>
      </Grid>
      <ItemIcons item={item} />
    </Grid>
  </Grid>
)

export default withStyles(styles)(UrlAsItem)
