import React, { Fragment } from 'react'
import { reduxForm, InjectedFormProps, Field } from 'redux-form'
import RadiusIcon from 'mdi-react/RadiusIcon'
import { useTranslation } from 'react-i18next'
import { parseNumberField } from '../../../../../utils/forms'
import ContentColorPicker from '../../../../../components/FormInput/ColorPicker/ContentColorPicker'
import { ContentCircleFormData } from '../../../../../types/contents'
import ContentToolbarItem from '../ContentToolbarItem/ContentToolbarItem'
import ReduxTextFieldWithDragChange from '../../../../../components/FormInput/ReduxWrappers/ReduxTextFieldWithDragChange'
import CommonContentFields from './CommonContentFields'

type FormProps = ContentCircleFormData

const ContentCircleForm: React.FC<InjectedFormProps<FormProps>> = () => {
  const [t] = useTranslation()
  return (
    <Fragment>
      <CommonContentFields formName={'ContentCircleForm'} enableRotation enableScale t={t} />
      <ContentToolbarItem>
        <Field
          name='fill'
          component={ContentColorPicker}
          tooltip={t('contents.actions.selectBackgroundColor')}
        />
      </ContentToolbarItem>
      <ContentToolbarItem>
        <Field
          name='radius'
          component={ReduxTextFieldWithDragChange}
          isToolbar
          fixedSmallWidth
          type='number'
          DragIcon={RadiusIcon}
          InputProps={{
            inputProps: { step: 1, min: 1 }
          }}
          parse={parseNumberField}
        />
      </ContentToolbarItem>
    </Fragment>
  )
}

export default reduxForm<FormProps>({
  form: 'ContentCircleForm'
})(ContentCircleForm)
