import { handleActions } from 'redux-actions'
import { isMasterTemplateUI, TemplateOrientation } from '@seesignage/seesignage-utils'
import {
  listTemplatesSuccess,
  listTemplatesFail,
  listTemplates,
  getTemplate,
  getTemplateFail,
  getTemplateSuccess,
  selectTemplateOrientation,
  updateTemplateSuccess,
  updateTemplateKeySuccess,
  deleteTemplateSuccess,
  duplicateTemplateSuccess,
  createTemplateSuccess,
  addTemplateChildSuccess,
  removeTemplateChildSuccess,
  reorderTemplateChildSuccess,
  duplicateTemplateChildSuccess,
  editSchemaComponentSuccess,
  updateTemplateContentSuccess,
  resetTemplates,
  selectCurrentChildIndex,
  createChildTemplateSuccess,
  deleteChildTemplateSuccess,
  deleteChildTemplate
} from '../actions/templates'
import { TemplatesState, IndexById } from '../types/states'
import {
  Action,
  CreateTemplateSuccess,
  UpdateTemplateKeySuccess,
  CreateChildTemplateSuccess,
  DeleteChildTemplateSuccess
} from '../types/actions'
import { StateTemplate } from '../types/templates'

const initialState: TemplatesState = {
  templates: {}, // indexed by templateId
  templatesIsLoading: false,
  templateIsLoading: false,
  schemaHasChanges: false,
  selectedTemplateOrientation: TemplateOrientation.landscape, // default orientation
  selectedChildIndex: 0,
  childTemplateIsDeleting: false
}

const templates = handleActions<TemplatesState, any>(
  {
    [resetTemplates]: () => initialState,
    [createTemplateSuccess]: (state, { payload: template }: CreateTemplateSuccess) => ({
      ...state,
      templates: {
        ...state.templates,
        [template.templateId]: template
      }
    }),
    [listTemplates]: state => ({
      ...state,
      templatesIsLoading: true
    }),
    [listTemplatesFail]: state => ({
      ...state,
      templatesIsLoading: false
    }),
    [listTemplatesSuccess]: (state, { payload: templatesArray }: Action<StateTemplate[]>) => {
      const templates = templatesArray.reduce<IndexById<StateTemplate>>((templates, template) => {
        templates[template.templateId] = {
          ...template
        }
        return templates
      }, {})
      return {
        ...state,
        templatesIsLoading: false,
        templates
      }
    },
    [getTemplate]: state => ({
      ...state,
      templateIsLoading: true
    }),
    [getTemplateFail]: state => ({
      ...state,
      templateIsLoading: false
    }),
    [getTemplateSuccess]: (state, { payload: template }: Action<StateTemplate>) => ({
      ...state,
      templateIsLoading: false,
      templates: {
        ...state.templates,
        [template.templateId]: template
      },
      schemaHasChanges: false
    }),
    [selectTemplateOrientation]: (
      state,
      { payload: orientation }: Action<TemplateOrientation>
    ) => ({
      ...state,
      selectedTemplateOrientation: orientation
    }),
    [updateTemplateSuccess]: (state, { payload: template }: Action<StateTemplate>) => ({
      ...state,
      templates: {
        ...state.templates,
        [template.templateId]: {
          ...state.templates[template.templateId],
          ...template
        }
      },
      schemaHasChanges: false
    }),
    [addTemplateChildSuccess]: (state, { payload: template }: Action<StateTemplate>) => ({
      ...state,
      templates: {
        ...state.templates,
        [template.templateId]: {
          ...state.templates[template.templateId],
          ...template
        }
      },
      schemaHasChanges: true
    }),
    [removeTemplateChildSuccess]: (state, { payload: template }: Action<StateTemplate>) => ({
      ...state,
      templates: {
        ...state.templates,
        [template.templateId]: {
          ...state.templates[template.templateId],
          ...template
        }
      },
      schemaHasChanges: true
    }),
    [editSchemaComponentSuccess]: (state, { payload: template }: Action<StateTemplate>) => ({
      ...state,
      templates: {
        ...state.templates,
        [template.templateId]: {
          ...state.templates[template.templateId],
          ...template
        }
      },
      schemaHasChanges: true
    }),
    [duplicateTemplateChildSuccess]: (state, { payload: template }: Action<StateTemplate>) => ({
      ...state,
      templates: {
        ...state.templates,
        [template.templateId]: {
          ...state.templates[template.templateId],
          ...template
        }
      },
      schemaHasChanges: true
    }),
    [reorderTemplateChildSuccess]: (state, { payload: template }: Action<StateTemplate>) => ({
      ...state,
      templates: {
        ...state.templates,
        [template.templateId]: {
          ...state.templates[template.templateId],
          ...template
        }
      },
      schemaHasChanges: true
    }),
    [updateTemplateKeySuccess]: (
      state,
      { payload: { templateId, masterTemplateId, key, url } }: UpdateTemplateKeySuccess
    ) => {
      const id = masterTemplateId || templateId
      const template = { ...state.templates[id] }
      if (isMasterTemplateUI(template)) {
        template.childTemplates[state.selectedChildIndex] = {
          ...template.childTemplates[state.selectedChildIndex],
          keys: {
            ...template.childTemplates[state.selectedChildIndex].keys,
            [key]: url
          }
        }
      } else {
        template.keys = {
          ...template.keys,
          [key]: url
        }
      }
      return {
        ...state,
        templates: {
          ...state.templates,
          [templateId]: template
        }
      }
    },
    [deleteTemplateSuccess]: (state, { payload: templateId }: Action<string>) => {
      const templates = { ...state.templates }
      delete templates[templateId]
      return {
        ...state,
        templates
      }
    },
    [duplicateTemplateSuccess]: (state, { payload: copy }: Action<StateTemplate>) => ({
      ...state,
      templates: {
        ...state.templates,
        [copy.templateId]: copy
      }
    }),
    [updateTemplateContentSuccess]: (
      state,
      { payload: updatedTemplate }: Action<StateTemplate>
    ) => ({
      ...state,
      templates: {
        ...state.templates,
        [updatedTemplate.templateId]: updatedTemplate
      },
      schemaHasChanges: false
    }),
    [selectCurrentChildIndex]: (state, { payload: index }: Action<number>) => ({
      ...state,
      selectedChildIndex: index
    }),
    [createChildTemplateSuccess]: (
      state,
      { payload: { masterTemplate, childTemplates } }: CreateChildTemplateSuccess
    ) => ({
      ...state,
      templates: {
        ...state.templates,
        [masterTemplate.templateId]: {
          ...state.templates[masterTemplate.templateId],
          ...masterTemplate,
          childTemplates
        }
      },
      selectedChildIndex: childTemplates.length - 1
    }),
    [deleteChildTemplate]: state => ({
      ...state,
      childTemplateIsDeleting: true
    }),
    [deleteChildTemplateSuccess]: (
      state,
      { payload: { masterTemplate, childTemplates } }: DeleteChildTemplateSuccess
    ) => ({
      ...state,
      templates: {
        ...state.templates,
        [masterTemplate.templateId]: {
          ...state.templates[masterTemplate.templateId],
          childTemplates
        }
      },
      selectedChildIndex: 0,
      childTemplateIsDeleting: false
    })
  },
  initialState
)

export default templates
