import React, { Fragment } from 'react'
import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import CloudUploadIcon from 'mdi-react/CloudUploadIcon'

const useStyles = makeStyles(() => ({
  dragDropContainer: {
    position: 'absolute',
    top: 0,
    zIndex: 2000,
    background: 'rgba(0, 174, 239, 0.8)',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  dragDropInfo: {
    background: 'rgba(0, 174, 239, 1)',
    padding: '10px',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: 170
  }
}))

interface DragDropInfoProps {
  open: boolean
  label: string
}

const DragDropInfo: React.FC<DragDropInfoProps> = ({ open, label }) => {
  const classes = useStyles()
  return (
    <Fragment>
      {open && (
        <div className={classes.dragDropContainer}>
          <div className={classes.dragDropInfo}>
            <CloudUploadIcon size='35px' />
            <Typography>{label}</Typography>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default DragDropInfo
