import { TFunction } from 'i18next'
import { isMobile } from 'react-device-detect'
import { Locale, Step } from 'react-joyride'
import { Tour } from '../types/tours'

interface TourProperties {
  steps: Step[]
  continuous?: boolean
  showProgress?: boolean
  showSkipButton?: boolean
  disableScrolling?: boolean
}

const getCreateCampaignTourProperties = (t: TFunction): TourProperties => ({
  steps: [
    {
      target: '#root',
      title: t('tours.createCampaign.steps.0.title'),
      content: t('tours.createCampaign.steps.0.content'),
      disableBeacon: true,
      placement: 'center' as 'center'
    },
    {
      target: '#create-campaign-button',
      content: t('tours.createCampaign.steps.1.content'),
      disableBeacon: true
    },
    {
      target: '#root',
      content: t('tours.createCampaign.steps.2.content'),
      disableBeacon: true,
      placement: 'center' as 'center'
    }
  ],
  continuous: true,
  showProgress: true,
  showSkipButton: true,
  disableScrolling: !isMobile // disable scrolling in desktop
})

const getSubCampaignViewTourProperties = (t: TFunction): TourProperties => ({
  steps: [
    {
      target: '#root',
      title: t('tours.subCampaignView.steps.0.title'),
      content: t('tours.subCampaignView.steps.0.content'),
      disableBeacon: true,
      placement: 'center' as 'center'
    },
    {
      target: '#root',
      content: t('tours.subCampaignView.steps.1.content'),
      disableBeacon: true,
      placement: 'center' as 'center'
    }
  ],
  continuous: true,
  showProgress: true,
  showSkipButton: true,
  disableScrolling: !isMobile // disable scrolling in desktop
})

const getEditCampaignTourProperties = (t: TFunction): TourProperties => ({
  steps: [
    {
      target: '#edit-campaign-drawer',
      title: t('tours.editCampaign.steps.0.title'),
      content: t('tours.editCampaign.steps.0.content'),
      disableBeacon: true,
      placement: 'center' as 'center'
    },
    {
      target: '#add-campaign-product',
      content: t('tours.editCampaign.steps.1.content'),
      disableBeacon: true
    },
    {
      target: '#add-campaign-batch-product',
      content: t('tours.editCampaign.steps.2.content'),
      disableBeacon: true
    },
    {
      target: '#save-campaign',
      content: t('tours.editCampaign.steps.3.content'),
      disableBeacon: true
    },
    {
      target: '#publish-campaign',
      content: t('tours.editCampaign.steps.4.content'),
      disableBeacon: true
    }
  ],
  continuous: true,
  showProgress: true,
  showSkipButton: true,
  disableScrolling: !isMobile // disable scrolling in desktop
})

const getEditSubCampaignTourProperties = (t: TFunction): TourProperties => ({
  steps: [
    {
      target: '#edit-campaign-drawer',
      title: t('tours.editSubCampaign.steps.0.title'),
      content: t('tours.editSubCampaign.steps.0.content'),
      disableBeacon: true,
      placement: 'center' as 'center'
    },
    {
      target: '#campaign-items',
      content: t('tours.editSubCampaign.steps.1.content'),
      disableBeacon: true
    },
    {
      target: '#save-campaign',
      content: t('tours.editSubCampaign.steps.2.content'),
      disableBeacon: true
    },
    {
      target: '#publish-campaign',
      content: t('tours.editSubCampaign.steps.3.content'),
      disableBeacon: true
    }
  ],
  continuous: true,
  showProgress: true,
  showSkipButton: true,
  disableScrolling: !isMobile // disable scrolling in desktop
})

const getCreateChannelTourProperties = (t: TFunction): TourProperties => ({
  steps: [
    {
      target: '#root',
      title: t('tours.createChannel.steps.0.title'),
      content: t('tours.createChannel.steps.0.content'),
      disableBeacon: true,
      placement: 'center' as 'center'
    },
    {
      target: '#create-channel-button',
      content: t('tours.createChannel.steps.1.content'),
      disableBeacon: true
    },
    {
      target: '#root',
      content: t('tours.createChannel.steps.2.content'),
      disableBeacon: true,
      placement: 'center' as 'center'
    }
  ],
  continuous: true,
  showProgress: true,
  showSkipButton: true,
  disableScrolling: !isMobile // disable scrolling in desktop
})

const getCreateChannelItemTourProperties = (t: TFunction): TourProperties => ({
  steps: [
    {
      target: '#channel-calendar',
      title: t('tours.createChannelItem.steps.0.title'),
      content: t('tours.createChannelItem.steps.0.content'),
      disableBeacon: true,
      placement: 'center' as 'center'
    },
    {
      target: '#create-channel-item-button',
      content: t('tours.createChannelItem.steps.1.content'),
      disableBeacon: true
    },
    {
      target: '#channel-calendar',
      content: t('tours.createChannelItem.steps.2.content'),
      disableBeacon: true,
      placement: 'center' as 'center'
    }
  ],
  continuous: true,
  showProgress: true,
  showSkipButton: true,
  disableScrolling: !isMobile // disable scrolling in desktop
})

const getCreatePlaylistItemTourProperties = (t: TFunction): TourProperties => ({
  steps: [
    {
      target: '#add-playlist-item',
      content: t('tours.createPlaylistItem.steps.0.content'),
      disableBeacon: true
    },
    {
      target: '#playlis-items-area',
      content: t('tours.createPlaylistItem.steps.1.content'),
      disableBeacon: true
    },
    {
      target: '#save-playlist-items-button',
      content: t('tours.createPlaylistItem.steps.2.content'),
      disableBeacon: true
    },
    {
      target: '#cancel-playlist-items-button',
      content: t('tours.createPlaylistItem.steps.3.content'),
      disableBeacon: true
    }
  ],
  continuous: true,
  showProgress: true,
  showSkipButton: true,
  disableScrolling: !isMobile // disable scrolling in desktop
})

const getAddMediaPlaylistItemWizardTourProperties = (t: TFunction): TourProperties => ({
  steps: [
    {
      target: '#upload-media-button',
      content: t('tours.addMediaPlaylistItemWizard.steps.0.content'),
      disableBeacon: true
    },
    {
      target: '#dropzone-area',
      content: t('tours.addMediaPlaylistItemWizard.steps.1.content'),
      disableBeacon: true
    },
    {
      target: '#dropzone-area',
      content: t('tours.addMediaPlaylistItemWizard.steps.2.content'),
      disableBeacon: true
    },
    {
      target: '#select-all-media-button',
      content: t('tours.addMediaPlaylistItemWizard.steps.3.content'),
      disableBeacon: true
    },
    {
      target: '#create-folder-button',
      content: t('tours.addMediaPlaylistItemWizard.steps.4.content'),
      disableBeacon: true
    },
    {
      target: '#media-submit-button',
      content: t('tours.addMediaPlaylistItemWizard.steps.5.content'),
      disableBeacon: true
    }
  ],
  continuous: true,
  showProgress: true,
  showSkipButton: true,
  disableScrolling: !isMobile // disable scrolling in desktop
})

const getAddRevolverPlaylistItemWizardTourProperties = (t: TFunction): TourProperties => ({
  steps: [
    {
      target: '#upload-media-button',
      content: t('tours.addRevolverPlaylistItemWizard.steps.0.content'),
      disableBeacon: true
    },
    {
      target: '#dropzone-area',
      content: t('tours.addRevolverPlaylistItemWizard.steps.1.content'),
      disableBeacon: true
    },
    {
      target: '#dropzone-area',
      content: t('tours.addRevolverPlaylistItemWizard.steps.2.content'),
      disableBeacon: true
    },
    {
      target: '#revolver-items',
      content: t('tours.addRevolverPlaylistItemWizard.steps.3.content'),
      disableBeacon: true
    },
    {
      target: '#create-folder-button',
      content: t('tours.addRevolverPlaylistItemWizard.steps.4.content'),
      disableBeacon: true
    },
    {
      target: '#revolver-submit-button',
      content: t('tours.addRevolverPlaylistItemWizard.steps.5.content'),
      disableBeacon: true
    }
  ],
  continuous: true,
  showProgress: true,
  showSkipButton: true,
  disableScrolling: !isMobile // disable scrolling in desktop
})

const getNavigationTourProperties = (t: TFunction): TourProperties => ({
  steps: [
    {
      target: '#root',
      title: t('tours.navigation.steps.0.title'),
      content: t('tours.navigation.steps.0.content'),
      placement: 'center' as 'center',
      disableBeacon: true
    },
    {
      target: '#menu-item-channels',
      title: t('tours.navigation.steps.channels.title'),
      content: t('tours.navigation.steps.channels.content'),
      placement: 'right-start' as 'right-start'
    },
    {
      target: '#menu-item-screens',
      title: t('tours.navigation.steps.screens.title'),
      content: t('tours.navigation.steps.screens.content'),
      placement: 'right-start' as 'right-start'
    },
    {
      target: '#menu-item-playlists',
      title: t('tours.navigation.steps.playlists.title'),
      content: t('tours.navigation.steps.playlists.content'),
      disableBeacon: true,
      placement: 'right-start' as 'right-start'
    },
    {
      target: '#menu-item-media',
      title: t('tours.navigation.steps.media.title'),
      content: t('tours.navigation.steps.media.content'),
      placement: 'right-start' as 'right-start'
    }
  ],
  continuous: true,
  showProgress: true,
  showSkipButton: true
})

const getTourProperties = (tour: Tour | undefined, t: TFunction): TourProperties => {
  switch (tour) {
    case Tour.subCampaignView:
      return getSubCampaignViewTourProperties(t)
    case Tour.createCampaign:
      return getCreateCampaignTourProperties(t)
    case Tour.editCampaign:
      return getEditCampaignTourProperties(t)
    case Tour.editSubCampaign:
      return getEditSubCampaignTourProperties(t)
    case Tour.createChannel:
      return getCreateChannelTourProperties(t)
    case Tour.createChannelItem:
      return getCreateChannelItemTourProperties(t)
    case Tour.createPlaylistItem:
      return getCreatePlaylistItemTourProperties(t)
    case Tour.addMediaPlaylistItemWizard:
      return getAddMediaPlaylistItemWizardTourProperties(t)
    case Tour.addRevolverPlaylistItemWizard:
      return getAddRevolverPlaylistItemWizardTourProperties(t)
    case Tour.navigation:
      return getNavigationTourProperties(t)
  }
  return {
    steps: []
  }
}

const getLocaleTranslations = (t: TFunction): Locale => ({
  back: t('tours.joyride.back'),
  close: t('tours.joyride.close'),
  last: t('tours.joyride.last'),
  next: t('tours.joyride.next'),
  skip: t('tours.joyride.skip')
})

export { getTourProperties, getLocaleTranslations }
