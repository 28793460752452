import { createSelector } from 'reselect'
import { path } from 'ramda'
import {
  isRetailList,
  isMiscList,
  List,
  TemplateType,
  Template,
  ListType
} from '@seesignage/seesignage-utils'
import { StateInterface } from '../types/states'
import { selectContentIdFromPathname } from './routing'

const selectListsDomain = (state: StateInterface) => state.lists

const selectLists = createSelector(selectListsDomain, domain => domain.lists)

const selectListIsLoading = createSelector(selectListsDomain, domain => domain.listIsLoading)

const selectGetListsIsLoading = createSelector(
  selectListsDomain,
  domain => domain.getListsIsLoading
)

const selectListById = (listId?: string | null) =>
  createSelector(selectLists, lists => (listId ? lists[listId] : undefined))

const selectListsAsArray = createSelector(selectLists, listsById => Object.values(listsById))

const selectSelectedListItemIds = createSelector(
  selectListsDomain,
  domain => domain.selectedListItemIds
)

const selectListCustomerById = (listId?: string) =>
  createSelector(selectLists, lists => {
    const list = listId ? lists[listId] : undefined
    if (isRetailList(list) || isMiscList(list)) {
      return list ? list.customerId : undefined
    }
    return undefined
  })

const selectListTemplateByListId = (listId?: string) =>
  createSelector(selectLists, lists => (listId ? lists[listId]?.templateId : undefined))

const selectListTypeByListId = (listId?: string) =>
  createSelector(selectLists, lists => {
    const list = listId ? lists[listId] : undefined
    return list ? list.type : undefined
  })

const selectListSize = (listId: string) =>
  createSelector(selectLists, lists => path(['items', 'length'], lists[listId]))

const selectListSearchTerm = createSelector(selectListsDomain, domain => domain.searchTerm)

const selectListsBySearchTerm = (searchTerm?: string) =>
  createSelector(selectListsAsArray, lists =>
    lists && searchTerm
      ? lists.filter(list => list.name.toLowerCase().includes(searchTerm.toLowerCase()))
      : []
  )

const selectIsListModified = createSelector(selectListsDomain, domain => domain.listModified)

const selectIsLunchListModified = createSelector(
  selectListsDomain,
  domain => domain.lunchListModified
)

const selectSelectedLunchItem = createSelector(
  selectListsDomain,
  domain => domain.selectedLunchItem
)

const selectLunchListWeekChanges = createSelector(
  selectListsDomain,
  domain => domain.lunchListWeekChanges
)

const selectCurrentList = createSelector(
  selectLists,
  selectContentIdFromPathname,
  (lists, listId) => {
    return listId && lists[listId] ? lists[listId] : undefined
  }
)

const selectCurrentMiscList = createSelector(
  selectLists,
  selectContentIdFromPathname,
  (lists, listId) => {
    const currentList = listId ? lists[listId] : undefined
    return isMiscList(currentList) ? currentList : undefined
  }
)

const selectListsAsOptions = (lists: List[]) =>
  lists.map(({ listId, name }) => ({ value: listId, label: name }))

/**
 * Select lists from current environment
 * @param environmentId
 */
const selectOwnListsAsArray = (environmentId?: string) =>
  createSelector(selectListsAsArray, lists =>
    environmentId
      ? lists.filter(({ environmentId: listEnvironmentId }) => environmentId === listEnvironmentId)
      : []
  )

export interface TemplateTypesObject {
  [templateType: string]: TemplateType
}

const selectAvailableListTypes = (templates: Template[]) => {
  const templateTypesObj = templates.reduce((typesObj, template) => {
    typesObj[template.type] = template.type
    return typesObj
  }, {} as TemplateTypesObject)
  const availableListTypes: ListType[] = []
  if (templateTypesObj[ListType.retail]) {
    availableListTypes.push(ListType.retail)
  }
  if (templateTypesObj[ListType.fish]) {
    availableListTypes.push(ListType.fish)
  }
  if (templateTypesObj[ListType.meat]) {
    availableListTypes.push(ListType.meat)
  }
  if (templateTypesObj[ListType.lunch]) {
    availableListTypes.push(ListType.lunch)
  }
  return availableListTypes
}

export {
  selectListsDomain,
  selectListById,
  selectListsAsArray,
  selectSelectedListItemIds,
  selectListIsLoading,
  selectGetListsIsLoading,
  selectListCustomerById,
  selectListTypeByListId,
  selectListSize,
  selectListSearchTerm,
  selectListsBySearchTerm,
  selectIsListModified,
  selectIsLunchListModified,
  selectSelectedLunchItem,
  selectLunchListWeekChanges,
  selectListTemplateByListId,
  selectCurrentList,
  selectCurrentMiscList,
  selectListsAsOptions,
  selectOwnListsAsArray,
  selectAvailableListTypes
}
