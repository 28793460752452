import React from 'react'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import { InputAdornment } from '@mui/material'
import MinusThickIcon from 'mdi-react/MinusThickIcon'
import { TFunction } from 'i18next'
import ReduxTextFieldWithDragChange from '../../../../../components/FormInput/ReduxWrappers/ReduxTextFieldWithDragChange'
import ContentColorPicker from '../../../../../components/FormInput/ColorPicker/ContentColorPicker'
import ContentToolbarItem from '../ContentToolbarItem/ContentToolbarItem'

interface LineDrawingSettingsProps {
  t: TFunction
}

const LineDrawingSettings = ({
  t
}: LineDrawingSettingsProps & InjectedFormProps<{}, LineDrawingSettingsProps>) => {
  return (
    <>
      <ContentToolbarItem>
        <Field
          name='color'
          component={ContentColorPicker}
          tooltip={t('contents.properties.lineColor')}
        />
      </ContentToolbarItem>
      <ContentToolbarItem tooltip={t('contents.properties.lineWidth')}>
        <Field
          name='width'
          isToolbar
          fixedSmallWidth
          component={ReduxTextFieldWithDragChange}
          DragIcon={MinusThickIcon}
          InputProps={{
            inputProps: { min: 1, max: 300 },
            endAdornment: <InputAdornment position='end'>px</InputAdornment>
          }}
          label={t('contents.properties.lineWidth')}
        />
      </ContentToolbarItem>
    </>
  )
}

export default reduxForm<{}, LineDrawingSettingsProps>({
  form: 'LineDrawingSettingsForm'
})(LineDrawingSettings)
