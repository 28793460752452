import { createAction, createAsyncActions } from './utils'

const reducer = 'SCREENS'

const resetScreens = createAction(reducer, 'RESET')

const selectScreen = createAction(reducer, 'SELECT')
const deselectScreen = createAction(reducer, 'DESELECT')
const selectAllScreens = createAction(reducer, 'SELECT_ALL')
const deselectAllScreens = createAction(reducer, 'DESELECT_ALL')

const selectScreenRow = createAction(reducer, 'SELECT_ROW')

const selectScreenDashboard = createAction(reducer, 'SELECT_SCREEN_DASHBOARD')
const deselectScreenDashboard = createAction(reducer, 'DESELECT_SCREEN_DASHBOARD')

const changeScreensViewMode = createAction(reducer, 'CHANGE_SCREENS_VIEW_MODE')

const setScreensInitialLoad = createAction(reducer, 'INITIAL_LOAD')

const {
  action: createScreen,
  actionSuccess: createScreenSuccess,
  actionFail: createScreenFail
} = createAsyncActions(reducer, 'CREATE')

const {
  action: updateScreen,
  actionSuccess: updateScreenSuccess,
  actionFail: updateScreenFail
} = createAsyncActions(reducer, 'UPDATE')

const {
  action: updateScreenFromDashboard,
  actionSuccess: updateScreenFromDashboardSuccess,
  actionFail: updateScreenFromDashboardFail
} = createAsyncActions(reducer, 'UPDATE_SCREEN_FROM_DASHBOARD')

const {
  action: getScreenFromDashboard,
  actionSuccess: getScreenFromDashboardSuccess,
  actionFail: getScreenFromDashboardFail
} = createAsyncActions(reducer, 'GET_SCREEN_FROM_DASHBOARD')

const {
  action: getScreen,
  actionSuccess: getScreenSuccess,
  actionFail: getScreenFail
} = createAsyncActions(reducer, 'GET')

const {
  action: listScreens,
  actionSuccess: listScreensSuccess,
  actionFail: listScreensFail
} = createAsyncActions(reducer, 'LIST')

const {
  action: listScreensWithWarnings,
  actionSuccess: listScreensWithWarningsSuccess,
  actionFail: listScreensWithWarningsFail
} = createAsyncActions(reducer, 'LIST_SCREENS_WITH_WARNINGS')

const {
  action: deleteScreen,
  actionSuccess: deleteScreenSuccess,
  actionFail: deleteScreenFail
} = createAsyncActions(reducer, 'DELETE')

const {
  action: sendScreenCommand,
  actionSuccess: sendScreenCommandSuccess,
  actionFail: sendScreenCommandFail
} = createAsyncActions(reducer, 'SEND_SCREEN_COMMAND')

const {
  action: downloadScreensCsv,
  actionSuccess: downloadScreensCsvSuccess,
  actionFail: downloadScreensCsvFail
} = createAsyncActions(reducer, 'DOWNLOAD_CSV')

const {
  action: downloadAdminScreensCsv,
  actionSuccess: downloadAdminScreensCsvSuccess,
  actionFail: downloadAdminScreensCsvFail
} = createAsyncActions(reducer, 'DOWNLOAD_ADMIN_CSV')

const {
  action: moveScreen,
  actionSuccess: moveScreenSuccess,
  actionFail: moveScreenFail
} = createAsyncActions(reducer, 'MOVE')

const {
  action: batchUpdateScreens,
  actionFail: batchUpdateScreensFail,
  actionSuccess: batchUpdateScreensSuccess
} = createAsyncActions(reducer, 'BATCH_UPDATE')

export {
  resetScreens,
  selectScreen,
  deselectScreen,
  selectAllScreens,
  deselectAllScreens,
  selectScreenRow,
  setScreensInitialLoad,
  createScreen,
  createScreenSuccess,
  createScreenFail,
  updateScreen,
  updateScreenSuccess,
  updateScreenFail,
  getScreen,
  getScreenSuccess,
  getScreenFail,
  listScreens,
  listScreensSuccess,
  listScreensFail,
  listScreensWithWarnings,
  listScreensWithWarningsSuccess,
  listScreensWithWarningsFail,
  deleteScreen,
  deleteScreenSuccess,
  deleteScreenFail,
  sendScreenCommand,
  sendScreenCommandSuccess,
  sendScreenCommandFail,
  changeScreensViewMode,
  downloadScreensCsv,
  downloadScreensCsvFail,
  downloadScreensCsvSuccess,
  downloadAdminScreensCsv,
  downloadAdminScreensCsvFail,
  downloadAdminScreensCsvSuccess,
  selectScreenDashboard,
  deselectScreenDashboard,
  updateScreenFromDashboard,
  updateScreenFromDashboardSuccess,
  updateScreenFromDashboardFail,
  getScreenFromDashboard,
  getScreenFromDashboardSuccess,
  getScreenFromDashboardFail,
  moveScreen,
  moveScreenFail,
  moveScreenSuccess,
  batchUpdateScreens,
  batchUpdateScreensFail,
  batchUpdateScreensSuccess
}
