import { createAsyncActions, createAction } from './utils'

const reducer = 'LISTS'

const resetLists = createAction(reducer, 'RESET')

const {
  action: getLists,
  actionFail: getListsFail,
  actionSuccess: getListsSuccess
} = createAsyncActions(reducer, 'GET_LISTS')

const {
  action: getList,
  actionFail: getListFail,
  actionSuccess: getListSuccess
} = createAsyncActions(reducer, 'GET_LIST')

const {
  action: createList,
  actionFail: createListFail,
  actionSuccess: createListSuccess
} = createAsyncActions(reducer, 'CREATE')

const {
  action: deleteList,
  actionFail: deleteListFail,
  actionSuccess: deleteListSuccess
} = createAsyncActions(reducer, 'DELETE')

const {
  action: updateList,
  actionFail: updateListFail,
  actionSuccess: updateListSuccess
} = createAsyncActions(reducer, 'UPDATE')

const {
  action: addListItem,
  actionFail: addListItemFail,
  actionSuccess: addListItemSuccess
} = createAsyncActions(reducer, 'ADD_LIST_ITEM')

const {
  action: deleteListItem,
  actionFail: deleteListItemFail,
  actionSuccess: deleteListItemSuccess
} = createAsyncActions(reducer, 'DELETE_LIST_ITEM')

const {
  action: reorderItems,
  actionFail: reorderItemsFail,
  actionSuccess: reorderItemsSuccess
} = createAsyncActions(reducer, 'REORDER_ITEMS')

const reorderItemsStore = createAction(reducer, 'REORDER_ITEMS_STORE')

const {
  action: saveMiscList,
  actionFail: saveMiscListFail,
  actionSuccess: saveMiscListSuccess
} = createAsyncActions(reducer, 'SAVE_MISC_LIST')

const {
  action: saveRetailList,
  actionFail: saveRetailListFail,
  actionSuccess: saveRetailListSuccess
} = createAsyncActions(reducer, 'SAVE_RETAIL_LIST')

const selectListItem = createAction(reducer, 'SELECT_ITEM')
const deselectListItem = createAction(reducer, 'DESELECT_ITEM')

const searchLists = createAction(reducer, 'SEARCH_LISTS')
const clearSearchLists = createAction(reducer, 'CLEAR_SEARCH_LISTS')

const addMiscListItem = createAction(reducer, 'ADD_MISC_LIST_ITEM')

const updateProductFromList = createAction(reducer, 'UPDATE_PRODUCT_FROM_LIST')

const deselectAllListItems = createAction(reducer, 'DESELECT_ALL_ITEMS')

const setSelectedLunchListItem = createAction(reducer, 'SET_SELECTED_LUNCH_LIST_ITEM')
const deselectSelectedLunchListItem = createAction(reducer, 'DESELECT_SELECTED_LUNCH_LIST_ITEM')

const {
  action: createOrUpdateLunchListGroup,
  actionFail: createOrUpdateLunchListGroupFail,
  actionSuccess: createOrUpdateLunchListGroupSuccess
} = createAsyncActions(reducer, 'CREATE_OR_UPDATE_LUNCH_LIST_GROUP')

const {
  action: createOrUpdateLunchListProduct,
  actionFail: createOrUpdateLunchListProductFail,
  actionSuccess: createOrUpdateLunchListProductSuccess
} = createAsyncActions(reducer, 'CREATE_OR_UPDATE_LUNCH_LIST_PRODUCT')

const {
  action: updateLunchListItems,
  actionFail: updateLunchListItemsFail,
  actionSuccess: updateLunchListItemsSuccess
} = createAsyncActions(reducer, 'UPDATE_LUNCH_LIST_ITEMS')

const {
  action: deleteLunchListItem,
  actionFail: deleteLunchListItemFail,
  actionSuccess: deleteLunchListItemSuccess
} = createAsyncActions(reducer, 'DELETE_LUNCH_LIST_ITEM')
const deleteLunchListGroupOrProductSuccess = createAction(
  reducer,
  'DELETE_LUNCH_LIST_GROUP_OR_PRODUCT_ITEM'
)

const {
  action: reorderLunchListItem,
  actionFail: reorderLunchListItemFail,
  actionSuccess: reorderLunchListItemSuccess
} = createAsyncActions(reducer, 'REORDER_LUNCH_LIST_ITEM')

const {
  action: addLunchListWeek,
  actionFail: addLunchListWeekFail,
  actionSuccess: addLunchListWeekSuccess
} = createAsyncActions(reducer, 'ADD_LUNCH_LIST_WEEK')

const {
  action: deleteLunchListWeek,
  actionFail: deleteLunchListWeekFail,
  actionSuccess: deleteLunchListWeekSuccess
} = createAsyncActions(reducer, 'DELETE_LUNCH_LIST_WEEK')

const {
  action: publishLunchList,
  actionFail: publishLunchListFail,
  actionSuccess: publishLunchListSuccess
} = createAsyncActions(reducer, 'PUBLISH_LUNCH_LIST')

const {
  action: copyList,
  actionFail: copyListFail,
  actionSuccess: copyListSuccess
} = createAsyncActions(reducer, 'COPY_LIST')

const {
  action: navigateToLunchList,
  actionFail: navigateToLunchListFail,
  actionSuccess: navigateToLunchListSuccess
} = createAsyncActions(reducer, 'NAVIGATE_TO_LUNCH_LIST')

const {
  action: addLunchListProductChanges,
  actionFail: addLunchListProductChangesFail,
  actionSuccess: addLunchListProductChangesSuccess
} = createAsyncActions(reducer, 'ADD_LUNCH_LIST_PRODUCT_CHANGES')

const {
  action: getLunchListContent,
  actionFail: getLunchListContentFail,
  actionSuccess: getLunchListContentSuccess
} = createAsyncActions(reducer, 'GET_LUNCH_LIST_CONTENT')

export {
  resetLists,
  getLists,
  getListsSuccess,
  getListsFail,
  getList,
  getListSuccess,
  getListFail,
  createList,
  createListSuccess,
  createListFail,
  deleteList,
  deleteListSuccess,
  deleteListFail,
  updateList,
  updateListSuccess,
  updateListFail,
  addListItem,
  addListItemSuccess,
  addListItemFail,
  deleteListItem,
  deleteListItemSuccess,
  deleteListItemFail,
  reorderItems,
  reorderItemsStore,
  reorderItemsSuccess,
  reorderItemsFail,
  selectListItem,
  deselectListItem,
  searchLists,
  clearSearchLists,
  saveMiscList,
  saveMiscListSuccess,
  saveMiscListFail,
  addMiscListItem,
  saveRetailList,
  saveRetailListFail,
  saveRetailListSuccess,
  updateProductFromList,
  deselectAllListItems,
  setSelectedLunchListItem,
  deselectSelectedLunchListItem,
  createOrUpdateLunchListGroup,
  createOrUpdateLunchListGroupSuccess,
  createOrUpdateLunchListGroupFail,
  createOrUpdateLunchListProduct,
  createOrUpdateLunchListProductSuccess,
  createOrUpdateLunchListProductFail,
  deleteLunchListItem,
  deleteLunchListItemSuccess,
  deleteLunchListItemFail,
  deleteLunchListGroupOrProductSuccess,
  reorderLunchListItem,
  reorderLunchListItemSuccess,
  reorderLunchListItemFail,
  addLunchListWeek,
  addLunchListWeekFail,
  addLunchListWeekSuccess,
  deleteLunchListWeek,
  deleteLunchListWeekFail,
  deleteLunchListWeekSuccess,
  updateLunchListItems,
  updateLunchListItemsFail,
  updateLunchListItemsSuccess,
  publishLunchList,
  publishLunchListSuccess,
  publishLunchListFail,
  copyList,
  copyListFail,
  copyListSuccess,
  navigateToLunchList,
  navigateToLunchListFail,
  navigateToLunchListSuccess,
  addLunchListProductChanges,
  addLunchListProductChangesSuccess,
  addLunchListProductChangesFail,
  getLunchListContent,
  getLunchListContentSuccess,
  getLunchListContentFail
}
