import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import { Media } from '@seesignage/seesignage-utils'
import { TFunction } from 'i18next'
import React from 'react'
import moment from 'moment'
import { millisToMinutesAndSeconds } from '../../../utils/time'
import VideoQualityIndicator from '../../../components/Media/VideoQualityIndicator'
import { convertBytesToString } from '../../../utils/conversion'

interface MediaDetailsProps {
  selectedMedia: Media
  t: TFunction
}

const MediaDetails: React.FC<MediaDetailsProps> = ({ selectedMedia, t }) => {
  const { createdAt, name, type, size, quality, duration } = selectedMedia
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
            {t('media.name')}
          </TableCell>
          <TableCell>{name}</TableCell>
        </TableRow>
        {createdAt && (
          <TableRow>
            <TableCell>{t('media.uploaded')}</TableCell>
            <TableCell>{moment(new Date(createdAt)).format('DD/MM/YYYY HH:MM')}</TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell>{t('media.type')}</TableCell>
          <TableCell>{type}</TableCell>
        </TableRow>
        {size && (
          <TableRow>
            <TableCell>{t('media.size')}</TableCell>
            <TableCell>{convertBytesToString(size)}</TableCell>
          </TableRow>
        )}
        {duration && (
          <TableRow>
            <TableCell>{t('media.duration')}</TableCell>
            <TableCell>{millisToMinutesAndSeconds(duration)}</TableCell>
          </TableRow>
        )}
        {quality && (
          <TableRow>
            <TableCell>{t('media.quality')}</TableCell>
            <TableCell>
              <VideoQualityIndicator quality={quality} />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}

export default MediaDetails
