import React from 'react'
import { SpecialDiets } from '@seesignage/seesignage-utils/lib/types/lunchLists'
import { TFunction } from 'i18next'
import classNames from 'classnames'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import { specialDietsStyles } from '../../../../styles/common'
import { getLunchListSpecialDietsOrder } from '../../../../utils/lunchLists'

const styles = () => ({
  ...specialDietsStyles(),
  specialDietsBar: {
    width: '100%'
  },
  defaultDietStyles: {
    backgroundColor: '#CCCFD1',
    color: 'white',
    width: `${100 / 7}%`,
    display: 'inline-block',
    textAlign: 'center' as 'center',
    height: 15,
    paddingTop: 2,
    fontSize: '12px'
  }
})

const orderedDiets = getLunchListSpecialDietsOrder()

interface SpecialDietsBarProps extends WithStyles<typeof styles> {
  specialDiet?: SpecialDiets
  t: TFunction
}

const SpecialDietsBar = ({ classes, specialDiet, t }: SpecialDietsBarProps) => (
  <div className={classes.specialDietsBar}>
    {orderedDiets.map(diet => (
      <div
        key={diet}
        className={classNames(classes.defaultDietStyles, {
          [classes[diet]]: specialDiet && specialDiet[diet]
        })}>
        {t(`lists.lunch.dietAbbreviations.${diet}`)}
      </div>
    ))}
  </div>
)

export default withStyles(styles)(SpecialDietsBar)
