import { createAction, createAsyncActions } from './utils'

const reducer = 'CUSTOMERS'

const selectCustomer = createAction(reducer, 'SELECT')
/** Reset customers state to initial */
const resetCustomers = createAction(reducer, 'RESET')

const {
  action: getCustomers,
  actionSuccess: getCustomersSuccess,
  actionFail: getCustomersFail
} = createAsyncActions(reducer, 'GET')

const {
  action: listAllCustomers,
  actionSuccess: listAllCustomersSuccess,
  actionFail: listAllCustomersFail
} = createAsyncActions(reducer, 'LIST_ALL')

const {
  action: getCustomersAndProducts,
  actionSuccess: getCustomersAndProductsSuccess,
  actionFail: getCustomersAndProductsFail
} = createAsyncActions(reducer, 'GET_AND_PRODUCTS')

const {
  action: updateCustomer,
  actionSuccess: updateCustomerSuccess,
  actionFail: updateCustomerFail
} = createAsyncActions(reducer, 'UPDATE')

const {
  action: createCustomer,
  actionSuccess: createCustomerSuccess,
  actionFail: createCustomerFail
} = createAsyncActions(reducer, 'CREATE')

const {
  action: deleteCustomer,
  actionSuccess: deleteCustomerSuccess,
  actionFail: deleteCustomerFail
} = createAsyncActions(reducer, 'DELETE')

const removeIntegrationFromForm = createAction(reducer, 'EMPTY_INTEGRATION_VALUES')

const selectCustomerRow = createAction(reducer, 'SELECT_ROW')
const deselectCustomerRow = createAction(reducer, 'DESELECT_ROW')

export {
  selectCustomer,
  resetCustomers,
  getCustomers,
  getCustomersSuccess,
  getCustomersFail,
  listAllCustomers,
  listAllCustomersSuccess,
  listAllCustomersFail,
  getCustomersAndProducts,
  getCustomersAndProductsSuccess,
  getCustomersAndProductsFail,
  updateCustomer,
  updateCustomerSuccess,
  updateCustomerFail,
  createCustomer,
  createCustomerSuccess,
  createCustomerFail,
  deleteCustomer,
  deleteCustomerSuccess,
  deleteCustomerFail,
  removeIntegrationFromForm,
  selectCustomerRow,
  deselectCustomerRow
}
