/* eslint-disable @typescript-eslint/camelcase */
import { toast } from 'react-toastify'
import {
  FacebookApiAccountsResponse,
  FacebookApiMeResponse,
  FacebookUserAccount,
  FacebookUserPages,
  isValidFacebookApiAccountsResponse,
  isValidFacebookApiMeResponse
} from '../../../types/facebook'

const FACEBOOK_API_VERSION = 'v20.0'
const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID
const FACEBOOK_SDK_SRC = 'https://connect.facebook.net/en_US/sdk.js'
const FACEBOOK_ELEMENT_ID = 'facebook-jssdk'

const loadFacebookSDKScript = () => {
  // Check if facebook sdk script is loaded
  if (document.getElementById(FACEBOOK_ELEMENT_ID)) {
    return
  }
  const scriptElement = document.createElement('script')
  scriptElement.id = FACEBOOK_ELEMENT_ID
  scriptElement.async = true
  scriptElement.src = FACEBOOK_SDK_SRC
  const firstScriptElement = document.getElementsByTagName('script')[0]
  firstScriptElement.parentNode?.insertBefore(scriptElement, firstScriptElement)
}

const setFacebookInitFunction = () => {
  window.fbAsyncInit = function() {
    window.FB.init({
      appId: FACEBOOK_APP_ID,
      autoLogAppEvents: true,
      xfbml: true,
      version: FACEBOOK_API_VERSION
    })
    window.FB.AppEvents.logPageView()
  }
}

const initFacebookSDK = () => {
  // wait for facebook sdk to initialize before starting the react app
  setFacebookInitFunction()
  // load facebook sdk script
  loadFacebookSDKScript()
}

const addFacebookAccount = (scope: string) =>
  new Promise<fb.StatusResponse>(resolve => {
    const fbLoginOptions: fb.LoginOptions = {
      scope,
      return_scopes: true,
      enable_profile_selector: true
    }
    window.FB.login((response: fb.StatusResponse) => {
      resolve(response)
    }, fbLoginOptions)
  })

/**
 * Ensures that all requested and required scopes have been granted by Facebook
 */
const validateFbGrantedScope = (requestedScope: string[], grantedScope: string[]) => {
  for (const scope of requestedScope) {
    if (!grantedScope.includes(scope)) {
      return false
    }
  }
  return true
}

const getFacebookAccount = (accessToken: string, id: string, name?: string) =>
  new Promise<FacebookUserAccount>((resolve, reject) => {
    window.FB.api(
      '/me',
      'get',
      { access_token: accessToken, fields: 'id,name,picture' },
      (response: FacebookApiMeResponse) => {
        if (isValidFacebookApiMeResponse(response)) {
          const user: FacebookUserAccount = {
            id: response.id,
            name: response.name,
            picture: response?.picture?.data?.url
          }
          resolve(user)
        } else {
          // eslint-disable-next-line no-console
          console.error(response)
          const errorMessage = `FB API Error: ${response?.error?.message} (type: ${response?.error?.type}, code: ${response?.error?.code}) - id: ${id} - name: ${name}`
          toast.error(`Unable to access user data: ${name ?? id}`)
          reject(new Error(errorMessage))
        }
      }
    )
  })

const clearFacebookLoginData = () =>
  new Promise<fb.StatusResponse>(resolve => window.FB.logout(resolve))

const getFacebookPages = (accessToken: string) =>
  new Promise<FacebookUserPages>((resolve, reject) => {
    window.FB.api(
      '/me/accounts',
      'get',
      { access_token: accessToken, fields: 'id,name,picture,access_token.as(accessToken)' },
      (response: FacebookApiAccountsResponse) => {
        if (isValidFacebookApiAccountsResponse(response)) {
          const pages: FacebookUserPages =
            response.data?.map(value => ({
              ...value,
              picture: value?.picture?.data?.url
            })) ?? []
          resolve(pages)
        } else {
          // eslint-disable-next-line no-console
          console.error(response)
          const errorMessage = `FB API Error: ${response?.error?.message} (type: ${response?.error?.type}, code: ${response?.error?.code})`
          toast.error('Unable to access user pages')
          reject(new Error(errorMessage))
        }
      }
    )
  })

export {
  initFacebookSDK,
  addFacebookAccount,
  validateFbGrantedScope,
  getFacebookAccount,
  clearFacebookLoginData,
  getFacebookPages
}
