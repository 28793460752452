import { createAction, createAsyncActions } from './utils'

const reducer = 'TEMPLATES'

const resetTemplates = createAction(reducer, 'RESET')

const selectTemplateOrientation = createAction(reducer, 'SELECT_TEMPLATE_ORIENTATION')

const selectCurrentChildIndex = createAction(reducer, 'SELECT_CURRENT_CHILD_INDEX')

const {
  action: getTemplate,
  actionSuccess: getTemplateSuccess,
  actionFail: getTemplateFail
} = createAsyncActions(reducer, 'GET')

const {
  action: listTemplates,
  actionSuccess: listTemplatesSuccess,
  actionFail: listTemplatesFail
} = createAsyncActions(reducer, 'LIST')

const {
  action: createTemplate,
  actionSuccess: createTemplateSuccess,
  actionFail: createTemplateFail
} = createAsyncActions(reducer, 'CREATE')

const {
  action: updateTemplate,
  actionSuccess: updateTemplateSuccess,
  actionFail: updateTemplateFail
} = createAsyncActions(reducer, 'UPDATE')

const {
  action: updateTemplateContent,
  actionSuccess: updateTemplateContentSuccess,
  actionFail: updateTemplateContentFail
} = createAsyncActions(reducer, 'UPDATE_CONTENT')

const {
  action: updateTemplateKey,
  actionSuccess: updateTemplateKeySuccess,
  actionFail: updateTemplateKeyFail
} = createAsyncActions(reducer, 'UPDATE_TEMPLATE_KEY')

const {
  action: copyTemplateToEnvironments,
  actionSuccess: copyTemplateToEnvironmentsSuccess,
  actionFail: copyTemplateToEnvironmentsFail
} = createAsyncActions(reducer, 'COPY_TO_ENVIRONMENTS')

const {
  action: duplicateTemplate,
  actionSuccess: duplicateTemplateSuccess,
  actionFail: duplicateTemplateFail
} = createAsyncActions(reducer, 'DUPLICATE')

const {
  action: deleteTemplate,
  actionSuccess: deleteTemplateSuccess,
  actionFail: deleteTemplateFail
} = createAsyncActions(reducer, 'DELETE')

const {
  action: addTemplateChild,
  actionSuccess: addTemplateChildSuccess,
  actionFail: addTemplateChildFail
} = createAsyncActions(reducer, 'ADD_CHILD')

const {
  action: removeTemplateChild,
  actionSuccess: removeTemplateChildSuccess,
  actionFail: removeTemplateChildFail
} = createAsyncActions(reducer, 'REMOVE_CHILD')

const {
  action: duplicateTemplateChild,
  actionSuccess: duplicateTemplateChildSuccess,
  actionFail: duplicateTemplateChildFail
} = createAsyncActions(reducer, 'DUPLICATE_CHILD')

const {
  action: reorderTemplateChild,
  actionSuccess: reorderTemplateChildSuccess,
  actionFail: reorderTemplateChildFail
} = createAsyncActions(reducer, 'REORDER_CHILD')

const {
  action: editSchemaComponent,
  actionSuccess: editSchemaComponentSuccess,
  actionFail: editSchemaComponentFail
} = createAsyncActions(reducer, 'EDIT_SCHEMA_COMPONENT')

const {
  action: createChildTemplate,
  actionSuccess: createChildTemplateSuccess,
  actionFail: createChildTemplateFail
} = createAsyncActions(reducer, 'CREATE_CHILD_TEMPLATE')

const {
  action: deleteChildTemplate,
  actionSuccess: deleteChildTemplateSuccess,
  actionFail: deleteChildTemplateFail
} = createAsyncActions(reducer, 'DELETE_CHILD_TEMPLATE')

export {
  resetTemplates,
  getTemplate,
  getTemplateSuccess,
  getTemplateFail,
  listTemplates,
  listTemplatesSuccess,
  listTemplatesFail,
  createTemplate,
  createTemplateSuccess,
  createTemplateFail,
  updateTemplate,
  updateTemplateSuccess,
  updateTemplateFail,
  updateTemplateContent,
  updateTemplateContentSuccess,
  updateTemplateContentFail,
  selectTemplateOrientation,
  updateTemplateKey,
  updateTemplateKeySuccess,
  updateTemplateKeyFail,
  copyTemplateToEnvironments,
  copyTemplateToEnvironmentsFail,
  copyTemplateToEnvironmentsSuccess,
  duplicateTemplate,
  duplicateTemplateFail,
  duplicateTemplateSuccess,
  deleteTemplate,
  deleteTemplateFail,
  deleteTemplateSuccess,
  addTemplateChild,
  addTemplateChildSuccess,
  addTemplateChildFail,
  removeTemplateChild,
  removeTemplateChildSuccess,
  removeTemplateChildFail,
  duplicateTemplateChild,
  duplicateTemplateChildSuccess,
  duplicateTemplateChildFail,
  reorderTemplateChild,
  reorderTemplateChildSuccess,
  reorderTemplateChildFail,
  editSchemaComponent,
  editSchemaComponentSuccess,
  editSchemaComponentFail,
  createChildTemplate,
  createChildTemplateFail,
  createChildTemplateSuccess,
  selectCurrentChildIndex,
  deleteChildTemplate,
  deleteChildTemplateFail,
  deleteChildTemplateSuccess
}
