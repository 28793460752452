import React, { Fragment } from 'react'
import { FieldArrayFieldsProps, WrappedFieldArrayProps } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { Button, Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import nanoid from 'nanoid'
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd'
import DragIcon from 'mdi-react/DragIcon'
import { MainTableField, TableFieldType } from '@seesignage/seesignage-utils'
import ErrorMessage from '../../../../../../../components/Errors/ErrorMessage'
import { getItemStyle } from '../../../../../../../components/Draggable/DraggableItems'
import SubFields from './SubFields'

const useStyles = makeStyles(theme => ({
  buttonsContainer: {
    width: '100%',
    textAlign: 'center',
    zIndex: 99,
    backgroundColor: 'white',
    display: 'flex',
    position: 'sticky',
    height: 64,
    justifyContent: 'center',
    boxShadow: '0px 3px 10px -4px rgba(0,0,0,0.6)'
  },
  button: {
    margin: theme.spacing(1)
  },
  item: {
    backgroundColor: '#fafafa',
    margin: '8px 0px 8px 0px',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1)
  },
  itemsContainer: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    overflow: 'auto'
  },
  dragArea: {
    cursor: 'move'
  },
  error: {
    padding: 6,
    textAlign: 'center'
  }
}))

interface TableFieldsProps extends WrappedFieldArrayProps {
  fields: FieldArrayFieldsProps<MainTableField>
  isFieldsDisabled: boolean
}

const TableFieldsDragAndDrop: React.FC<TableFieldsProps> = ({
  fields,
  meta: { error, submitFailed },
  isFieldsDisabled
}) => {
  const [t] = useTranslation()
  const classes = useStyles()
  const onDragEnd = (reoderItems: (from: number, to: number) => void) => (result: DropResult) => {
    if (!result.destination || result.source.index === result.destination.index) {
      return
    }
    reoderItems(result.source.index, result.destination.index)
  }
  return (
    <Fragment>
      {!isFieldsDisabled && (
        <Button
          className={classes.button}
          variant='contained'
          onClick={() =>
            fields.push({
              id: nanoid(),
              subFields: [
                // by default create single string field
                {
                  id: nanoid(),
                  name: '',
                  type: TableFieldType.string,
                  styles: {}
                }
              ],
              styles: {}
            })
          }>
          {t('contents.widgets.table.addField')}
        </Button>
      )}
      {submitFailed && error && (
        <div className={classes.error}>
          <ErrorMessage message={error} />
        </div>
      )}
      {fields.length > 0 && (
        <Paper elevation={0} className={classes.itemsContainer} id='campaign-items'>
          <DragDropContext onDragEnd={onDragEnd(fields.move)}>
            <Droppable droppableId='campaignItems' isDropDisabled={isFieldsDisabled}>
              {({ innerRef, placeholder }) => (
                <div ref={innerRef}>
                  {fields.map((item, index) => {
                    const itemProps = {
                      item,
                      index,
                      removeItem: () => fields.remove(index),
                      isFieldsDisabled
                    }
                    return (
                      <Draggable
                        key={item}
                        draggableId={item}
                        index={index}
                        isDragDisabled={isFieldsDisabled}>
                        {({ innerRef, draggableProps, dragHandleProps }, { isDragging }) => (
                          <Paper
                            className={classes.item}
                            style={getItemStyle(isDragging, draggableProps.style)}
                            ref={innerRef}
                            {...draggableProps}
                            {...dragHandleProps}>
                            <div className={classes.dragArea} {...dragHandleProps}>
                              <DragIcon />
                            </div>
                            <SubFields key={`${item}.${index}`} {...itemProps} />
                          </Paper>
                        )}
                      </Draggable>
                    )
                  })}
                  {placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Paper>
      )}
    </Fragment>
  )
}

export default TableFieldsDragAndDrop
