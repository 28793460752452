import {
  AutocompleteOption,
  RetailProduct,
  ProductTags,
  ListType,
  GeneralProductUI,
  Product,
  PharmacyCustomerProduct,
  CreatePharmacyMasterProductProps
} from '@seesignage/seesignage-utils'
import { TemplateAsFormValue } from './templates'

export interface ProductsIndexedById {
  [productId: string]: Product // RetailProduct | GeneralProductUI
}

export type CreateGeneralProduct = Omit<
  GeneralProductUI,
  'productId' | 'customerId' | 'createdAt' | 'updatedAt'
>

export interface CreateGeneralProductFormData extends CreateGeneralProduct {
  /** when uploading new product image. do not give as initial value to form */
  productImage?: File
  /** templateId when adding product to playlist */
  template?: TemplateAsFormValue
  /** customerId when adding product to playlist */
  customerId?: AutocompleteOption
}

export interface UpdateGeneralProductFormData extends CreateGeneralProductFormData {
  productId: string
  /** when updating playlist item */
  itemId?: string
}

export const isUpdateGeneralProduct = (formData: any): formData is UpdateGeneralProductFormData =>
  formData.productId

export type CreateRetailProduct = Omit<RetailProduct, 'createdAt' | 'updatedAt'>

export type ProductFormTags = {
  [key in ProductTags]?: boolean
}

export interface RetailProductFormData extends CreateRetailProduct {
  productTags?: ProductFormTags
  /** listId when adding product to list */
  listId?: string
  /** so saga knows to use update function */
  isUpdate?: boolean
}

export interface ListProductsFromFormParams {
  type: ListType
  customerId: AutocompleteOption
}

export interface SearchProductByTermParams {
  /**
   * User input string
   */
  input: string
  /**
   * If needed to load product urls
   */
  loadUrls?: boolean
}

export interface DeleteProductParams {
  productId: string
  type: ListType
}

export interface UpdatePharmacyProductFormParams {
  template: TemplateAsFormValue
  itemId: string
  product: PharmacyCustomerProduct
}

export interface CreatePharmacyMasterProductFormData {
  customerId: string
  product: CreatePharmacyMasterProductProps
}
