import React from 'react'
import {
  PharmacyMasterProduct,
  formatDecimalsToCurrency,
  parseCurrencyStringToNumber
} from '@seesignage/seesignage-utils'
import { Field } from 'redux-form'
import { Grid, TextField, IconButton, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import RefreshIcon from 'mdi-react/RefreshIcon'
import { ReduxTextField } from '../../../components/FormInput/ReduxWrappers'
import { required } from '../../../validation'

interface OwnProps {
  selectedDrug: PharmacyMasterProduct
  fetchMasterProduct?: (ean: string, property: 'description' | 'price') => void
}

type CreateCustomerDrugFormProps = OwnProps

const CreateCustomerDrugForm: React.FC<CreateCustomerDrugFormProps> = ({
  selectedDrug,
  fetchMasterProduct
}) => {
  const [t] = useTranslation()
  const textFieldProps = {
    fullWidth: true,
    variant: 'outlined' as 'outlined',
    disabled: true
  }
  return (
    <Grid justifyContent='center' container spacing={2}>
      <Grid item xs={12}>
        <img
          src={selectedDrug.image?.Pieni?.Url}
          alt=''
          style={{ height: 200, width: 200, objectFit: 'contain' }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          {...textFieldProps}
          value={selectedDrug.name}
          label={t('products.pharmacy.name')}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          name='product.ean'
          {...textFieldProps}
          component={ReduxTextField}
          label={t('products.pharmacy.ean')}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          {...textFieldProps}
          value={selectedDrug.vnr}
          label={t('products.pharmacy.vnr')}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          {...textFieldProps}
          value={selectedDrug.packageSize || ''}
          label={t('products.pharmacy.packageSize')}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          {...textFieldProps}
          value={selectedDrug.packageUnit || ''}
          label={t('products.pharmacy.packageUnit')}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          {...textFieldProps}
          value={selectedDrug.packageMultiplier || ''}
          label={t('products.pharmacy.packageMultiplier')}
        />
      </Grid>
      <Grid item xs={6}>
        <div style={{ display: 'flex' }}>
          <Field
            {...textFieldProps}
            name='product.price'
            format={formatDecimalsToCurrency}
            parse={parseCurrencyStringToNumber}
            disabled={false}
            type='number'
            component={ReduxTextField}
            required
            validate={[required]}
            label={t('products.pharmacy.price')}
            InputProps={{
              endAdornment: '€'
            }}
          />
          {fetchMasterProduct && (
            <Tooltip disableInteractive title={t('products.pharmacy.getMasterPrice')}>
              <IconButton
                onClick={() => fetchMasterProduct(selectedDrug.ean, 'price')}
                size='large'>
                <RefreshIcon color='primary' />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </Grid>
      <Grid item xs={6}>
        <Field
          {...textFieldProps}
          disabled={false}
          name='product.discountPrice'
          format={formatDecimalsToCurrency}
          parse={parseCurrencyStringToNumber}
          type='number'
          component={ReduxTextField}
          label={t('products.pharmacy.discountPrice')}
          InputProps={{
            endAdornment: '€'
          }}
          helperText={t('general.optional')}
        />
      </Grid>
      <Grid item xs={12}>
        <div style={{ display: 'flex' }}>
          <Field
            name='product.description'
            {...textFieldProps}
            disabled={false}
            multiline
            maxRows={4}
            component={ReduxTextField}
            label={t('products.pharmacy.description')}
            helperText={t('general.optional')}
          />
          {fetchMasterProduct && (
            <Tooltip disableInteractive title={t('products.pharmacy.getMasterDescription')}>
              <IconButton
                onClick={() => fetchMasterProduct(selectedDrug.ean, 'description')}
                size='large'>
                <RefreshIcon color='primary' />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </Grid>
    </Grid>
  )
}
export default CreateCustomerDrugForm
