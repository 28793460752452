import React from 'react'
import { reduxForm, InjectedFormProps, Field } from 'redux-form'
import { Grid, InputAdornment } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { TFunction } from 'i18next'

import WebIcon from 'mdi-react/WebIcon'
import { ContentIframeFormData } from '../../../../../../types/contents'
import {
  ReduxTextField,
  ReduxDurationField
} from '../../../../../../components/FormInput/ReduxWrappers'
import SimpleExtensionPanel from '../../../../../../components/ExtensionPanels/SimpleExtensionPanel'
import Borderfields from '../CommonFields/BorderFields'
import PositionFields from '../CommonFields/PositionFields'
import { parseNumberField } from '../../../../../../utils/forms'

const useStyles = makeStyles(theme => ({
  adornment: {
    margin: '0px 1px 0px 1px'
  },
  accordionSummaryRootClass: {
    backgroundColor: '#F5F5F5',
    borderBottom: '1px solid rgba(0, 0, 0, 0.09)'
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  srcContainer: {
    padding: theme.spacing(1)
  }
}))

interface OwnProps {
  t: TFunction
}

type FormProps = ContentIframeFormData
type ComponentProps = OwnProps

const IframeForm: React.FC<ComponentProps & InjectedFormProps<FormProps, ComponentProps>> = ({
  t
}) => {
  const classes = useStyles()
  return (
    <Grid container>
      <Grid item xs={12}>
        <SimpleExtensionPanel
          title={t('contents.widgets.iframe.url')}
          isOpen={true}
          accordionSummaryRootClass={classes.accordionSummaryRootClass}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                name='cIframeProps.src'
                label={t('contents.widgets.iframe.src')}
                component={ReduxTextField}
                type='text'
                fullWidth
                InputProps={{
                  inputProps: { step: 1 },
                  startAdornment: (
                    <InputAdornment position='start'>
                      <WebIcon className={classes.icon} />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                fullWidth
                name='cIframeProps.refreshInterval'
                label={t('contents.widgets.iframe.refreshInterval')}
                component={ReduxDurationField}
                parse={parseNumberField}
                type='number'
                defaultValue={0}
              />
            </Grid>
          </Grid>
        </SimpleExtensionPanel>
      </Grid>
      <Grid item xs={12}>
        <SimpleExtensionPanel
          title={t('contents.properties.border')}
          isOpen={false}
          accordionSummaryRootClass={classes.accordionSummaryRootClass}>
          <Borderfields t={t} namePrefix='cIframeProps.styles' isFieldsDisabled={false} />
        </SimpleExtensionPanel>
      </Grid>
      <Grid item xs={12}>
        <SimpleExtensionPanel
          title={t('contents.properties.position')}
          isOpen={false}
          accordionSummaryRootClass={classes.accordionSummaryRootClass}>
          <PositionFields t={t} namePrefix='cIframeProps.styles' isFieldsDisabled={false} />
        </SimpleExtensionPanel>
      </Grid>
    </Grid>
  )
}

export default reduxForm<FormProps, ComponentProps>({
  form: 'IframeWidgetForm',
  enableReinitialize: true
})(IframeForm)
