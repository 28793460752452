/* eslint-disable @typescript-eslint/camelcase */
import { SubmissionError, reset } from 'redux-form'
import { toast } from 'react-toastify'
import { path } from 'ramda'
import { all, take, takeLatest, put, call, select } from 'redux-saga/effects'
import { isMobile } from 'react-device-detect'
import { push } from 'connected-react-router'
import moment from 'moment'
import setDefaultOptions from 'date-fns/setDefaultOptions'
import { fi, enGB } from 'date-fns/locale'
import {
  User,
  Environment,
  UserSocialMediaAccountType,
  SocialMediaPlatform,
  GetSubsribedUsersToNewsResponse,
  Language,
  UserSocialMedia
} from '@seesignage/seesignage-utils'
import { closeDialog, openDialog } from '../actions/dialogs'
import {
  initializeSession,
  initializeSessionSuccess,
  initializeSessionFail,
  login,
  loginSuccess,
  loginFail,
  logout,
  logoutSuccess,
  logoutFail,
  signup,
  signupSuccess,
  signupFail,
  updateUserInfo,
  updateUserInfoFail,
  updateUserInfoSuccess,
  changePassword,
  changePasswordFail,
  changePasswordSuccess,
  forgotPassword,
  forgotPasswordSuccess,
  forgotPasswordFail,
  forgotPasswordSubmit,
  forgotPasswordSubmitFail,
  forgotPasswordSubmitSuccess,
  deleteUser,
  deleteUserSuccess,
  deleteUserFail,
  setDeviceType,
  createPreviewSession,
  createPreviewSessionSuccess,
  createPreviewSessionFail,
  addSocialMediaAccount,
  addSocialMediaAccountSuccess,
  addSocialMediaAccountFail,
  deleteSocialMediaAccount,
  deleteSocialMediaAccountSuccess,
  deleteSocialMediaAccountFail,
  subscribeUserToNewsLetter,
  subscribeUserToNewsLetterSuccess,
  subscribeUserToNewsLetterFail,
  downloadSubscribersToNewsLetterList,
  downloadSubscribersToNewsLetterListSuccess,
  downloadSubscribersToNewsLetterListFail,
  changeLanguage,
  changeLanguageFail,
  changeLanguageSuccess
} from '../actions/users'
import {
  getSession,
  getSubFromSession,
  getRoleFromSession,
  authLogin,
  authLogout,
  authSignup,
  authUpdateUserInfo,
  authChangePassword,
  authForgotPassword,
  authForgotPasswordSubmit,
  getCurrentUserInfo
} from '../services/authentication'
import userAPI from '../services/api/users'
import i18n from '../translations/i18n'
import { selectLocationPathname, selectEnvironmentIdFromPathname } from '../selectors/routing'
import { setTawkVisitor, hideTawkWidget } from '../config/tawk'
import { sendGaEvent } from '../config/ga'
import { CreatePreviewSession } from '../types/actions'
import { SignupUserAttributes, UserSession, UserInfo } from '../types/auth'
import {
  HandleChangePassword,
  HandleForgotPassword,
  HandleForgotPasswordSubmit,
  HandleLogin,
  HandleSignup,
  HandleUpdateUserInfo,
  HandleUpdateUserSubscription
} from '../types/formData'
import { selectEnvironmentsAsArray, selectSelectedEnvironment } from '../selectors/environments'
import { initializeIot } from '../actions/iot'
import { selectFailedUnauthenticatedPath, selectUser } from '../selectors/users'
import { UserInterface } from '../types/users'
import { isSystemAdminUser } from '../utils/permissions'
import {
  getEnvironment,
  listEnvironments,
  listEnvironmentsSuccess,
  listEnvironmentsFail
} from '../actions/environments'
import { deselectSelectedEnvironmentIdLocalStorage } from '../services/localStorage/environments'
import { compareStrings } from '../utils/sorting'
import { getCustomers } from '../actions/customers'
import {
  getFacebookAccount,
  addFacebookAccount,
  clearFacebookLoginData,
  validateFbGrantedScope
} from '../services/api/SocialMedia/facebook'
import { downloadCsvFile, generateSubscribersToNewsLetterListCsv } from '../utils/csv'
import { saveLanguageToLocalStorage } from '../utils/language'
import { FacebookUserAccount } from '../types/facebook'

let cognitoUser: any // CognitoUser Object

// paths that does not require authentication
const unAuthPaths = [
  '/forgotpassword/reset',
  '/forgotpassword/submit',
  '/forgotpassword/linkSent',
  '/signup',
  '/login'
]

const isPublicPath = (currentPath: string) => unAuthPaths.some(path => currentPath.startsWith(path))

export function* handleInitializeSession() {
  try {
    yield put(setDeviceType(isMobile))
    const session: UserSession | undefined = yield call(getSession)
    const sub = session ? getSubFromSession(session) : undefined
    if (session && sub) {
      const role = getRoleFromSession(session)
      const { attributes }: UserInfo = yield call(getCurrentUserInfo)
      const {
        lastLogin,
        socialMedia = { facebook: {}, instagram: {} },
        subscriptions
      }: User = yield call(userAPI.getUser)
      const user: UserInterface = {
        attributes,
        lastLogin,
        role,
        sub,
        subscriptions,
        socialMedia
      }

      if (isSystemAdminUser(user)) {
        // do not show tawk chat for system user
        hideTawkWidget()
      } else {
        // set current visitor information to Tawk chat
        setTawkVisitor(attributes.name, attributes.email)
      }

      // select failedUnauthenticatedPath before it is cleared after initializeSessionSuccess
      const failedUnauthenticatedPath: string | undefined = yield select(
        selectFailedUnauthenticatedPath
      )

      yield put(initializeSessionSuccess(user))

      yield put(listEnvironments())
      // wait until environments are ready
      yield take([listEnvironmentsSuccess, listEnvironmentsFail])

      const environmentIdFromPathName = yield select(selectEnvironmentIdFromPathname)

      const failedUnauthenticatedPathArray = failedUnauthenticatedPath?.split('/') || []
      const environmentIdFromFailedUnauthenticatedPath =
        failedUnauthenticatedPathArray[1] === 'environments'
          ? failedUnauthenticatedPathArray[2]
          : undefined

      const selectedEnvironmentId: string | undefined =
        environmentIdFromPathName || environmentIdFromFailedUnauthenticatedPath

      if (selectedEnvironmentId) {
        // environmentId found from path or local storage
        yield put(getEnvironment({ environmentId: selectedEnvironmentId }))
        yield put(getCustomers(selectedEnvironmentId))
        yield put(initializeIot({ environmentId: selectedEnvironmentId, userId: user.sub }))
      } else {
        // use first environmentId
        const environments: Environment[] = yield select(selectEnvironmentsAsArray)
        const sortedEnvironments = environments.sort(compareStrings('name'))
        const firstEnvironment = sortedEnvironments[0]
        if (firstEnvironment) {
          yield put(getEnvironment({ environmentId: firstEnvironment.environmentId }))
          yield put(getCustomers(firstEnvironment.environmentId))
          yield put(
            initializeIot({ environmentId: firstEnvironment.environmentId, userId: user.sub })
          )
        }
      }

      sendGaEvent('initializeSessionSuccess', {
        category: 'users'
      })

      if (failedUnauthenticatedPath) {
        // redirect to path that user tried to navigate before valid user session.
        yield put(push(failedUnauthenticatedPath))
      } else {
        const pathname: string = yield select(selectLocationPathname)
        if (pathname === '/login') {
          // by default, navigate to home after successfull session
          yield put(push('/home'))
        }
      }
    } else {
      throw new Error('Initialize session failed')
    }
  } catch (error) {
    const failedUnauthenticatedPath: string = yield select(selectLocationPathname)
    const isPublic = isPublicPath(failedUnauthenticatedPath)
    if (isPublic) {
      yield put(initializeSessionFail())
    } else {
      // when path is not public, require login and store failed unauthenticated path
      yield put(push('/login'))
      yield put(initializeSessionFail(failedUnauthenticatedPath))
    }
  }
}

export function* handleLogin({
  payload: {
    formData: { email, password },
    resolve,
    reject
  }
}: HandleLogin) {
  try {
    cognitoUser = yield call(authLogin, email, password)
    if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
      yield put(loginFail('NEW_PASSWORD_REQUIRED'))
      yield put(push('/password/renew'))
    } else {
      yield call(userAPI.init)
      yield call(handleInitializeSession)
      yield put(loginSuccess())
    }
    resolve()
  } catch (error) {
    yield put(loginFail(error.message))
    const i18Key =
      error?.code === 'NetworkError'
        ? 'error.network.noInternet'
        : 'error.user.incorrectCredentials'
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t(i18Key)
      })
    )
  }
}

export function* handleLogout() {
  try {
    yield call(authLogout)
    yield put(logoutSuccess())
    deselectSelectedEnvironmentIdLocalStorage()
    yield put(push('/login'))
    window.location.reload() // force clean state
  } catch (error) {
    yield put(logoutFail())
  }
}

export function* handleSignup({
  payload: {
    formData: {
      name,
      email,
      dialingCode: { value: dialingCode },
      phoneNumber,
      password
    },
    resolve,
    reject
  }
}: HandleSignup) {
  try {
    const singupInfo: SignupUserAttributes = {
      name,
      email,
      phone_number: `${dialingCode}${phoneNumber}`,
      password
    }
    yield call(authSignup, singupInfo)
    yield put(signupSuccess())
    sendGaEvent('signupSuccess', {
      category: 'users'
    })
    // user is automatically confirmed. No need for extra confirmation because valid user exists in confirmation documents
    yield put(push(`/login`))
    resolve()
  } catch (error) {
    yield put(signupFail(error.message))
    let _error
    if (error.code === 'UsernameExistsException') {
      _error = i18n.t('signup.usernameExistsException')
    } else if (error.code === 'UserLambdaValidationException') {
      _error = i18n.t('signup.userNotConfirmed')
    } else {
      _error = i18n.t('error.user.somethingWrongCreate')
    }
    yield call(
      reject,
      new SubmissionError({
        _error
      })
    )
  }
}

export function* handleUpdateUserInfo({
  payload: { formData, resolve, reject }
}: HandleUpdateUserInfo) {
  try {
    yield call(authUpdateUserInfo, formData)
    const existingUserData: UserInterface = yield select(selectUser)
    const updatedUserData: UserInterface = {
      ...existingUserData,
      attributes: {
        ...existingUserData.attributes,
        ...formData
      }
    }
    yield put(updateUserInfoSuccess(updatedUserData))
    resolve()
  } catch (error) {
    yield put(updateUserInfoFail(error.message))
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.user.couldNotUpdate')
      })
    )
  }
}

export function* handleUpdateUserSubscriptions({
  payload: { formData, resolve, reject }
}: HandleUpdateUserSubscription) {
  try {
    const { subscriptions = { newsLetter: false } } = formData
    const res = yield call(userAPI.updateUser, { subscriptions })
    yield put(subscribeUserToNewsLetterSuccess(res.subscriptions))
    resolve()
  } catch (error) {
    yield put(subscribeUserToNewsLetterFail())
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.user.couldNotUpdate')
      })
    )
  }
}

export function* handleDownloadSubscribersToNewsLetterList() {
  try {
    const { name: environmentName } = yield select(selectSelectedEnvironment)
    const subscribersList: GetSubsribedUsersToNewsResponse = yield call(userAPI.getSubscribedUsers)
    const csv = generateSubscribersToNewsLetterListCsv(subscribersList)
    downloadCsvFile(csv, `subscribers_list_for_${environmentName}.csv`)
    yield put(downloadSubscribersToNewsLetterListSuccess())
  } catch (error) {
    toast.error(i18n.t('errors.user.downloadCsv'))
    yield put(downloadSubscribersToNewsLetterListFail(error.message))
  }
}

export function* handleChangePassword({
  payload: {
    formData: { currentPassword, password },
    resolve,
    reject
  }
}: HandleChangePassword) {
  try {
    yield call(authChangePassword, currentPassword, password)
    toast.success(i18n.t('changePw.changeSuccess'))
    yield put(reset('ChangePasswordForm'))
    yield put(changePasswordSuccess())
    resolve()
  } catch (error) {
    yield put(changePasswordFail(error.code))
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.user.somethingWrongPwChange')
      })
    )
  }
}

export function* handleForgotPassword({
  payload: {
    formData: { email },
    resolve,
    reject
  }
}: HandleForgotPassword) {
  try {
    yield call(authForgotPassword, email)
    yield put(forgotPasswordSuccess())
    yield put(push(`/forgotpassword/linkSent`))
    resolve()
  } catch (error) {
    yield put(forgotPasswordFail(error.code))
    const errorMsg =
      error.code === 'UserNotFoundException'
        ? i18n.t('error.userNotKnown')
        : i18n.t('error.somethingWrong')
    yield call(
      reject,
      new SubmissionError({
        _error: errorMsg
      })
    )
  }
}

export function* handleForgotPasswordSubmit({
  payload: {
    formData: { email, code, password },
    resolve,
    reject
  }
}: HandleForgotPasswordSubmit) {
  try {
    yield call(authForgotPasswordSubmit, email, code, password)
    yield put(forgotPasswordSubmitSuccess())
    yield put(push('/login'))
    resolve()
  } catch (error) {
    yield put(forgotPasswordSubmitFail(error.code))
    const errorMsg =
      error.code === 'CodeMismatchException' || error.code === 'ExpiredCodeException'
        ? i18n.t('error.verificationCode')
        : i18n.t('error.somethingWrong')
    yield call(
      reject,
      new SubmissionError({
        _error: errorMsg
      })
    )
  }
}

export function* handleDeleteUser() {
  try {
    yield call(userAPI.deleteUser)
    yield put(logout())
    yield put(deleteUserSuccess())
    yield put(closeDialog())
  } catch (error) {
    const errorMessage = path(['response', 'data', 'message'], error)
    if (errorMessage) {
      toast.error(`${i18n.t('error.user.couldNotRemove')} ${errorMessage}`)
    } else {
      toast.error(i18n.t('error.user.couldNotRemove'))
    }
    yield put(deleteUserFail(error.message))
  }
}

export function* handleCreatePreviewSession({
  payload: { playlistId, screenId }
}: CreatePreviewSession) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const sessionId = yield call(userAPI.createPreviewSession, {
      environmentId,
      playlistId,
      screenId
    })
    yield put(createPreviewSessionSuccess({ sessionId, environmentId, playlistId, screenId }))
    yield put(openDialog(`preview${playlistId ? 'Playlist' : 'Screen'}Dialog`))
  } catch (error) {
    toast.error(i18n.t('error.playlist.preview'))
    yield put(createPreviewSessionFail(error.message))
  }
}

interface HandleAddSocialMediaAccountParams {
  payload: {
    platform: SocialMediaPlatform
    stopLoading: () => void
  }
}

export function* handleAddSocialMediaAccount({
  payload: { platform, stopLoading }
}: HandleAddSocialMediaAccountParams) {
  try {
    const fbScope = [
      'public_profile',
      'pages_read_engagement',
      'pages_read_user_content',
      'pages_show_list',
      'business_management'
    ]
    const instaScope = ['instagram_basic', 'instagram_manage_insights']
    const requiredScopes = [
      ...fbScope,
      ...(platform === SocialMediaPlatform.instagram ? instaScope : [])
    ]
    const loginResponse: fb.StatusResponse = yield call(
      addFacebookAccount,
      requiredScopes.join(',')
    )
    if (loginResponse.status === 'connected') {
      const { accessToken, userID, grantedScopes } = loginResponse.authResponse
      if (!accessToken) {
        throw new Error('accessToken missing from login response')
      }

      const accessRights = grantedScopes ? grantedScopes.split(',') : []
      if (!validateFbGrantedScope(requiredScopes, accessRights)) {
        throw new Error(i18n.t('contents.widgets.socialMedia.errorMissingPermission'))
      }
      const { name, picture }: FacebookUserAccount = yield call(
        getFacebookAccount,
        accessToken,
        userID
      )
      const newSocialMediaData: UserSocialMediaAccountType = {
        accessToken,
        id: userID,
        picture,
        name,
        createdAt: Date.now(),
        tokenCreatedAt: Date.now(),
        tokenExpiresIn: Date.now() + 60 * 60,
        accessRights,
        platform
      }
      const socialMediaData: UserSocialMedia = yield call(
        userAPI.addSocialMediaAccount,
        newSocialMediaData
      )
      yield call(clearFacebookLoginData)
      yield put(addSocialMediaAccountSuccess(socialMediaData))
    } else {
      throw new Error(i18n.t('contents.widgets.socialMedia.errorAuthFailed'))
    }
  } catch (error) {
    stopLoading()
    toast.error(
      `${i18n.t('contents.widgets.socialMedia.errorConnectingAccount')}: ${error.message || ''}`
    )
    yield put(addSocialMediaAccountFail())
  }
}

interface HandleDeleteSocialMediaAccountParams {
  payload: {
    account: UserSocialMediaAccountType
    stopLoading: () => void
  }
}

export function* handleDeleteSocialMediaAccount({
  payload: { account, stopLoading }
}: HandleDeleteSocialMediaAccountParams) {
  try {
    yield call(userAPI.deleteSocialMediaAccount, account)
    yield put(deleteSocialMediaAccountSuccess(account))
  } catch (error) {
    yield put(deleteSocialMediaAccountFail())
  } finally {
    stopLoading()
  }
}

interface HandleChangeLanguageParams {
  payload: Language
}

export function* handleChangeLanguage({ payload: language }: HandleChangeLanguageParams) {
  try {
    saveLanguageToLocalStorage(language)
    i18n.changeLanguage(language)
    setDefaultOptions({ locale: language === Language.en ? enGB : fi })
    moment.updateLocale(language, {
      week: { dow: 1, doy: 1 }
    })
    yield put(changeLanguageSuccess(language))
  } catch (error) {
    yield put(changeLanguageFail(error.message))
  }
}

function* watchUsersActions() {
  yield all([
    takeLatest(initializeSession, handleInitializeSession),
    takeLatest(login, handleLogin),
    takeLatest(logout, handleLogout),
    takeLatest(signup, handleSignup),
    takeLatest(updateUserInfo, handleUpdateUserInfo),
    takeLatest(subscribeUserToNewsLetter, handleUpdateUserSubscriptions),
    takeLatest(downloadSubscribersToNewsLetterList, handleDownloadSubscribersToNewsLetterList),
    takeLatest(changePassword, handleChangePassword),
    takeLatest(forgotPassword, handleForgotPassword),
    takeLatest(forgotPasswordSubmit, handleForgotPasswordSubmit),
    takeLatest(deleteUser, handleDeleteUser),
    takeLatest(createPreviewSession, handleCreatePreviewSession),
    takeLatest(addSocialMediaAccount, handleAddSocialMediaAccount),
    takeLatest(deleteSocialMediaAccount, handleDeleteSocialMediaAccount),
    takeLatest(changeLanguage, handleChangeLanguage)
  ])
}

export default [watchUsersActions]
