import React from 'react'
import { InjectedFormProps, reduxForm, Field } from 'redux-form'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { List, ListSubheader, ListItem, DialogActions, Button } from '@mui/material'
import { AdditionalEnvironmentSettingsFormData } from '../../../types/environments'
import { bindSubmitActionToPromise } from '../../../utils/forms'
import { updateAdditionalEnvironmentSettings } from '../../../actions/environments'
import {
  ReduxTextField,
  ReduxCreatableAutocomplete
} from '../../../components/FormInput/ReduxWrappers'
import { required } from '../../../validation'

interface AdditionalEnvironmentSettingsFormProps {
  showParentTags: boolean
  closeDialog: () => void
}

const AdditionalEnvironmentSettingsForm: React.FC<AdditionalEnvironmentSettingsFormProps &
  InjectedFormProps<
    AdditionalEnvironmentSettingsFormData,
    AdditionalEnvironmentSettingsFormProps
  >> = ({ handleSubmit, closeDialog, submitting, showParentTags }) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const updateAdditionalSettings = bindSubmitActionToPromise(
    dispatch,
    updateAdditionalEnvironmentSettings
  )
  return (
    <form onSubmit={handleSubmit(updateAdditionalSettings)}>
      <List subheader={<ListSubheader disableSticky>{t('environment.details')}</ListSubheader>}>
        <ListItem>
          <Field
            fullWidth
            name='name'
            disabled
            label={t('environment.name')}
            component={ReduxTextField}
            required
            validate={[required]}
          />
        </ListItem>
        <ListItem>
          <Field
            fullWidth
            name='displayName'
            label={t('environment.displayName')}
            component={ReduxTextField}
          />
        </ListItem>
        {showParentTags && (
          <ListItem>
            <Field
              name='parentTags'
              label={t('environment.additionalSettingsForm.parentTags')}
              helperText={t('environment.additionalSettingsForm.parentTagsHelper')}
              fullWidth
              component={ReduxCreatableAutocomplete}
              multiple
              disabled
              options={[]}
            />
          </ListItem>
        )}
        <ListItem>
          <Field
            name='tags'
            label={t('screens.tags')}
            helperText={t('environment.additionalSettingsForm.tagsHelper')}
            fullWidth
            component={ReduxCreatableAutocomplete}
            multiple
            options={[]}
          />
        </ListItem>
      </List>
      <DialogActions>
        <Button onClick={closeDialog} color='primary'>
          {t('general.cancel')}
        </Button>
        <Button disabled={submitting} color='primary' type='submit'>
          {t('general.update')}
        </Button>
      </DialogActions>
    </form>
  )
}

export default reduxForm<
  AdditionalEnvironmentSettingsFormData,
  AdditionalEnvironmentSettingsFormProps
>({
  form: 'AdditionalEnvironmentSettingsForm'
})(AdditionalEnvironmentSettingsForm)
