import { createSelector } from 'reselect'
import { path } from 'ramda'
import {
  Environment,
  AutocompleteOption,
  EnvironmentUI,
  isSubEnvironmentUI
} from '@seesignage/seesignage-utils'
import { StateInterface } from '../types/states'
import { EnvironmentType } from '../types/environments'
import { compareStrings } from '../utils/sorting'
import { getEnvironmentDisplayName } from '../utils/environments'

const selectEnvironmentsDomain = (state: StateInterface) => state.environments

const selectEnvironments = createSelector(
  selectEnvironmentsDomain,
  environments => environments.environments
)

const selectSelectedEnvironment = createSelector(
  selectEnvironmentsDomain,
  environments => environments.selectedEnvironment
)

const selectSelectedEnvironmentId = createSelector(
  selectSelectedEnvironment,
  environment => environment?.environmentId
)

const selectEnvironmentById = (environmentId?: string) =>
  createSelector(selectEnvironments, environment =>
    environmentId ? environment[environmentId] : undefined
  )

const selectEnvironmentsAsArray = createSelector(selectEnvironments, environments =>
  Object.values(environments)
)

const selectListEnvironmentsIsLoading = createSelector(
  selectEnvironmentsDomain,
  environments => environments.listEnvironmentsIsLoading
)

const selectEnvironmentIsLoading = createSelector(
  selectEnvironmentsDomain,
  environment => environment.environmentIsLoading
)

const selectEnvironmentsAsOptions = (currentEnvironmentId?: string) =>
  createSelector(selectEnvironmentsAsArray, environments =>
    environments.reduce((optionsArray: AutocompleteOption[], { environmentId, name }) => {
      if (currentEnvironmentId !== environmentId) {
        optionsArray.push({
          value: environmentId,
          label: name
        })
      }
      return optionsArray
    }, [])
  )

const selectAllEnvironmentsAsOptions = createSelector(selectEnvironmentsAsArray, environments =>
  environments.map(({ environmentId, name }) => ({
    value: environmentId,
    label: name
  }))
)

const selectEnvironmentHqVideosEnabled = (environmentId?: string) =>
  createSelector(
    selectEnvironmentById(environmentId),
    environment => environment && environment.features && environment.features.hqEnabled
  )

const selectEnvironmentUhdVideosEnabled = (environmentId?: string) =>
  createSelector(
    selectEnvironmentById(environmentId),
    environment => environment && environment.features && environment.features.uhdEnabled
  )

const selectEnvironmentCampaignsEnabled = (environmentId?: string) =>
  createSelector(selectEnvironmentById(environmentId), environment =>
    environment && environment.features && environment.features.campaigns ? true : false
  )

/**
 * Select environments eligable for linking to be sub environments. This includes all environments
 * that are neither existing sub or parent environments.
 */
const selectEnvironmentsForLinking = createSelector(selectEnvironmentsAsArray, environments =>
  environments.filter(
    ({ parentEnvironment, subEnvironments }) => !parentEnvironment && !subEnvironments
  )
)

const selectHyperEnvironmentIdBySubId = (environmentId?: string) =>
  createSelector(selectEnvironmentById(environmentId), environment =>
    environment ? environment.parentEnvironment : undefined
  )

const getEnvironmentType = (environment: Environment | EnvironmentUI) => {
  const { subEnvironments, parentEnvironment } = environment
  if (subEnvironments) {
    return EnvironmentType.hyper
  } else if (parentEnvironment) {
    return EnvironmentType.sub
  }
  return EnvironmentType.normal
}

const selectCurrentEnvironmentHasSubEnvironments = createSelector(
  selectSelectedEnvironment,
  environment => !!environment?.subEnvironments
)

/**
 * Select environments for environments table. Filter out environments with parentEnvironment only
 * if the parentEnvironment is found in given array. This is so that there will be no duplicates
 * visible on table.
 * @param environments
 */
const selectEnvironmentsForTable = (environments: Environment[]) =>
  environments.filter(({ parentEnvironment }) => {
    if (parentEnvironment) {
      const parentEnvironmentFoundInEnvironments = environments.some(
        ({ environmentId }) => environmentId === parentEnvironment
      )
      return parentEnvironmentFoundInEnvironments ? false : true
    }
    return true
  })

const selectEnvironmentStorageQuota = (environmentId?: string) =>
  createSelector(
    selectEnvironmentById(environmentId),
    environment => environment?.features?.storageSize
  )

const selectEnvironmentName = (environmentId?: string) =>
  createSelector(selectEnvironmentById(environmentId), environment => environment?.name)

const selectEnvironmentDisplayName = (environmentId?: string) =>
  createSelector(selectEnvironmentById(environmentId), environment =>
    environment ? getEnvironmentDisplayName(environment) : ''
  )

const selectEnvironmentTemplateTypes = createSelector(
  selectEnvironmentsDomain,
  domain => domain.environmentTemplateTypes
)

const selectEnvironmentScreenFeatures = (environmentId?: string) =>
  createSelector(
    selectEnvironmentById(environmentId),
    environment => environment?.features?.screens
  )

const selectCurrentEnvironmentFeatures = createSelector(
  selectSelectedEnvironment,
  environment => environment?.features
)

const selectCurrentEnvironmentScreenFeatures = createSelector(
  selectSelectedEnvironment,
  environment => environment?.features?.screens
)

const selectCurrentEnvironmentType = createSelector(selectSelectedEnvironment, environment =>
  environment ? getEnvironmentType(environment) : EnvironmentType.normal
)

const selectCurrentEnvironmentTags = createSelector(selectSelectedEnvironment, environment =>
  isSubEnvironmentUI(environment)
    ? [
        ...new Set([
          ...(environment.tags ? environment.tags : []),
          ...(environment.parentTags ? environment.parentTags : [])
        ])
      ]
    : environment?.tags
)

const selectCurrentEnvironmentTagsAsOptions = createSelector(selectCurrentEnvironmentTags, tags =>
  tags ? tags.map(tag => ({ value: tag, label: tag })).sort(compareStrings('value')) : []
)

const selectCurrentEnvironmentPermissionTagsAsOptions = createSelector(
  selectSelectedEnvironment,
  environment => {
    if (environment) {
      const { users } = environment
      const tags = users
        .reduce((tagsArray: string[][], { permissions }) => {
          const tags: string[] | undefined = path(['tags'], permissions)
          if (tags) {
            tagsArray.push(tags)
          }
          return tagsArray
        }, [])
        .flat(1)
      const tagsNoDuplicates = [...new Set(tags)]
      return tagsNoDuplicates.map(tag => ({ value: tag, label: tag })).sort(compareStrings('value'))
    }
    return []
  }
)

const selectCurrentEnvironmentColors = createSelector(
  selectSelectedEnvironment,
  enviroment => enviroment?.colors
)

const selectParentEnviromentColors = createSelector(
  selectSelectedEnvironment,
  environment => (environment as any)?.parentProperties?.colors as string[] | undefined
)

const selectProFeaturesEnabled = createSelector(
  selectCurrentEnvironmentFeatures,
  features => features?.infopages?.proFeatures
)

const selectEnvironmentFeaturesChannelsImportScreenCodes = createSelector(
  selectCurrentEnvironmentFeatures,
  features => features?.channels?.importScreenCodes
)

export {
  selectEnvironmentsDomain,
  selectEnvironments,
  selectSelectedEnvironment,
  selectSelectedEnvironmentId,
  selectEnvironmentById,
  selectEnvironmentsAsArray,
  selectListEnvironmentsIsLoading,
  selectEnvironmentIsLoading,
  selectEnvironmentsAsOptions,
  selectAllEnvironmentsAsOptions,
  selectEnvironmentHqVideosEnabled,
  selectEnvironmentUhdVideosEnabled,
  selectEnvironmentCampaignsEnabled,
  selectEnvironmentsForLinking,
  selectHyperEnvironmentIdBySubId,
  getEnvironmentType,
  selectCurrentEnvironmentHasSubEnvironments,
  selectEnvironmentsForTable,
  selectEnvironmentStorageQuota,
  selectEnvironmentName,
  selectEnvironmentDisplayName,
  selectEnvironmentTemplateTypes,
  selectEnvironmentScreenFeatures,
  selectCurrentEnvironmentScreenFeatures,
  selectCurrentEnvironmentFeatures,
  selectCurrentEnvironmentTagsAsOptions,
  selectCurrentEnvironmentPermissionTagsAsOptions,
  selectCurrentEnvironmentType,
  selectCurrentEnvironmentColors,
  selectParentEnviromentColors,
  selectCurrentEnvironmentTags,
  selectProFeaturesEnabled,
  selectEnvironmentFeaturesChannelsImportScreenCodes
}
