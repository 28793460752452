import { Grid, Button, IconButton, Paper, MenuItem, Typography } from '@mui/material'
import React from 'react'
import { FieldArray, WrappedFieldArrayProps, Field } from 'redux-form'
import { TFunction } from 'i18next'
import AlarmIcon from 'mdi-react/AlarmIcon'
import DeleteIcon from 'mdi-react/DeleteIcon'
import { ReduxTextField, ReduxTimePicker } from '../../../../components/FormInput/ReduxWrappers'
import { required } from '../../../../validation'
import ErrorMessage from '../../../../components/Errors/ErrorMessage'

interface RenderTimerFieldsProps extends WrappedFieldArrayProps<any> {
  t: TFunction
  classes: Record<string, string>
  disabled?: boolean
}

const renderTimerFields = ({
  fields,
  t,
  classes,
  disabled,
  meta: { error }
}: RenderTimerFieldsProps) => (
  <Grid container spacing={2}>
    {fields.length > 0 && (
      <Grid item xs={12}>
        <Typography variant='h6' component='h6' className={classes.title}>
          {t(
            `screens.createScreenForm.${
              fields.name === 'configs.onOffTimer.onTimers' ? 'on' : 'off'
            }`
          )}
        </Typography>
      </Grid>
    )}
    {fields.map((member, index) => (
      <Grid key={`range-${index}-${fields.name}`} item xs={12}>
        <Paper variant='outlined' style={{ margin: '2px 8px', padding: '4px 8px 10px 8px' }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Field
                id='day'
                label={t('screens.createScreenForm.timerDay')}
                name={`${member}.day`}
                component={ReduxTextField}
                required
                validate={required}
                disabled={disabled}
                fullWidth
                select>
                <MenuItem value={7}>{t('screens.createScreenForm.timerEveryday')}</MenuItem>
                <MenuItem value={8}>{t('screens.createScreenForm.timerMonFri')}</MenuItem>
                <MenuItem value={9}>{t('screens.createScreenForm.timerSatSun')}</MenuItem>
                <MenuItem value={0}>{t('general.time.days.mon')}</MenuItem>
                <MenuItem value={1}>{t('general.time.days.tue')}</MenuItem>
                <MenuItem value={2}>{t('general.time.days.wed')}</MenuItem>
                <MenuItem value={3}>{t('general.time.days.thu')}</MenuItem>
                <MenuItem value={4}>{t('general.time.days.fri')}</MenuItem>
                <MenuItem value={5}>{t('general.time.days.sat')}</MenuItem>
                <MenuItem value={6}>{t('general.time.days.sun')}</MenuItem>
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                name={`${member}.time`}
                label={t('screens.createScreenForm.time')}
                component={ReduxTimePicker}
                disabled={disabled}
                required
                validate={required}
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton
                disabled={disabled}
                onClick={() => fields.remove(index)}
                aria-label={t('general.delete')}
                size='large'>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    ))}
    <ErrorMessage message={error} />
    {fields.length < 7 && (
      <Grid item xs={12}>
        <Button
          disabled={disabled}
          onClick={() => fields.push({})}
          fullWidth
          color={fields.name === 'configs.onOffTimer.onTimers' ? 'primary' : 'secondary'}
          variant='contained'
          style={{ margin: '10px 0 10px 0' }}>
          <AlarmIcon style={{ marginRight: 4 }} />
          {t(
            `screens.createScreenForm.${
              fields.name === 'configs.onOffTimer.onTimers' ? 'addOnTimer' : 'addOffTimer'
            }`
          )}
        </Button>
      </Grid>
    )}
  </Grid>
)

interface OnOffTimerFieldProps {
  t: TFunction
  disabled?: boolean
  classes: Record<string, string>
}

const OnOffTimerField: React.FC<OnOffTimerFieldProps> = ({ t, classes, disabled }) => (
  <Grid container>
    <Grid item xs={12}>
      <FieldArray
        name='configs.onOffTimer.onTimers'
        component={renderTimerFields}
        props={{
          disabled,
          t,
          classes
        }}
      />
    </Grid>
    <Grid item xs={12}>
      <FieldArray
        name='configs.onOffTimer.offTimers'
        component={renderTimerFields}
        props={{
          disabled,
          t,
          classes
        }}
      />
    </Grid>
  </Grid>
)

export default OnOffTimerField
