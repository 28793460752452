import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Step, StepLabel, Stepper, StepIcon, Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import WebIcon from 'mdi-react/WebIcon'
import AccountIcon from 'mdi-react/AccountIcon'
import ImageTextIcon from 'mdi-react/ImageTextIcon'
import {
  ObjectType,
  SocialMediaItem,
  FacebookPage,
  InstagramAccount,
  SocialMediaItemsType,
  SocialMediaItemsConfig,
  SocialMediaSelectionType,
  SocialMediaPlatform,
  SocialMediaProfile,
  UserSocialMedia,
  UserSocialMediaAccountType
} from '@seesignage/seesignage-utils'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { SocialMediaWizardPage, UpdateSocialMediaProps } from '../../../../types/contents'
import {
  selectIsUserDeviceMobile,
  selectUserSocialMedia,
  selectUserSub
} from '../../../../selectors/users'
import { ColorConnector } from '../../../../components/Stepper'
import {
  createObject as createObjectAction,
  updateSelectedWidget as updateSelectedWidgetAction
} from '../../../../actions/contents'
import { initFacebookSDK } from '../../../../services/api/SocialMedia/facebook'
import SelectPlatform from './Items/SelectPlatform'
import SelectAccount from './Items/SelectAccount'
import SelectContent from './Items/SelectContent'

const useStyles = makeStyles(() => ({
  form: {
    position: 'relative',
    minHeight: '500px',
    paddingBottom: '70px',
    display: 'flex',
    flexDirection: 'column'
  },
  formFooter: {
    position: 'absolute',
    bottom: '0px',
    height: '70px',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  actionBtn: {
    margin: '0 10px'
  }
}))

const getWizardSteps = (t: TFunction) => [
  {
    icon: <WebIcon />,
    text: t('contents.widgets.socialMedia.platform')
  },
  {
    icon: <AccountIcon />,
    text: t('contents.widgets.socialMedia.account')
  },
  {
    icon: <ImageTextIcon />,
    text: t('contents.widgets.socialMedia.content')
  }
]

const getActiveStepIndex = (socialMediaWizardPage: SocialMediaWizardPage) => {
  switch (socialMediaWizardPage) {
    case SocialMediaWizardPage.selectPlatform:
      return 0
    case SocialMediaWizardPage.selectAccount:
      return 1
    case SocialMediaWizardPage.selectContent:
      return 2
    default:
      return 0
  }
}

export interface SelectedMedia {
  [mediaId: string]: SocialMediaItem
}

export type MediaData = {
  items: SocialMediaItem[]
  nextUrl?: string
}
interface CreateSocialMediaWidgetWizardProps {
  closeDialog: () => void
  isUpdateSocialMedia?: boolean
}

const CreateSocialMediaWidgetWizard: React.FC<CreateSocialMediaWidgetWizardProps> = ({
  closeDialog,
  isUpdateSocialMedia
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [t] = useTranslation()
  const isMobile = useSelector(selectIsUserDeviceMobile)
  const connectedSocialMedia = useSelector(selectUserSocialMedia) as UserSocialMedia
  const sub = useSelector(selectUserSub)
  const [currentStepView, setCurrentStepView] = useState<SocialMediaWizardPage>(
    SocialMediaWizardPage.selectPlatform
  )
  const [platform, setPlatform] = useState<SocialMediaPlatform | undefined>()
  const [account, setAccount] = useState<UserSocialMediaAccountType | undefined>()
  const [socialMedia, setSocialMedia] = useState<InstagramAccount | FacebookPage | undefined>()
  const [mediaConfig, setMediaConfig] = useState<SocialMediaItemsConfig>({
    type: SocialMediaItemsType.posts,
    selectionType: SocialMediaSelectionType.custom,
    latestCount: 7
  })
  const [selectedMedia, setSelectedMedia] = useState<SelectedMedia>({})
  const [mediaData, setMediaData] = useState<MediaData>({ items: [] })

  const mediaItems = Object.values(selectedMedia)

  const createSocialMediaObject = (props: UpdateSocialMediaProps) =>
    dispatch(createObjectAction({ type: ObjectType.CustomSocialMedia, props }))
  const updateSocialMediaObject = (props: UpdateSocialMediaProps) =>
    dispatch(updateSelectedWidgetAction({ type: ObjectType.CustomSocialMedia, props }))

  const handleGoToPreviousStep = () => {
    switch (currentStepView) {
      case SocialMediaWizardPage.selectAccount:
        setAccount(undefined)
        setSocialMedia(undefined)
        setPlatform(undefined)
        setCurrentStepView(SocialMediaWizardPage.selectPlatform)
        break
      case SocialMediaWizardPage.selectContent:
        setSelectedMedia({})
        setMediaConfig({
          type: SocialMediaItemsType.posts,
          selectionType: SocialMediaSelectionType.custom,
          latestCount: 7
        })
        setCurrentStepView(SocialMediaWizardPage.selectAccount)
        break
      default:
        setCurrentStepView(SocialMediaWizardPage.selectPlatform)
        break
    }
  }

  const renderCurrentStepView = () => {
    switch (currentStepView) {
      case SocialMediaWizardPage.selectPlatform: {
        const selectPlatformStepProps = {
          setPlatform,
          onGoToNextStep: () => setCurrentStepView(SocialMediaWizardPage.selectAccount)
        }
        return <SelectPlatform {...selectPlatformStepProps} />
      }

      case SocialMediaWizardPage.selectAccount: {
        if (platform) {
          const selectAccountStepProps = {
            t,
            setSelectedAccount: (account: UserSocialMediaAccountType) => setAccount(account),
            setSelectedSocialMedia: (socialMedia: InstagramAccount | FacebookPage) =>
              setSocialMedia(socialMedia),
            platform: platform,
            connectedAccounts: connectedSocialMedia[platform] || {},
            onGoToNextStep: () => setCurrentStepView(SocialMediaWizardPage.selectContent)
          }
          return <SelectAccount {...selectAccountStepProps} />
        } else {
          handleGoToPreviousStep()
          return renderCurrentStepView()
        }
      }

      case SocialMediaWizardPage.selectContent: {
        if (platform && account && socialMedia) {
          const selectContentStepProps = {
            account,
            platform,
            socialMedia,
            selectedMedia,
            mediaData,
            mediaConfig,
            setMediaData,
            setSelectedMedia,
            setMediaConfig
          }
          return <SelectContent {...selectContentStepProps} />
        } else {
          handleGoToPreviousStep()
          return renderCurrentStepView()
        }
      }

      default:
        return null
    }
  }

  const handleCreateSocialMediaWidget = () => {
    if (!sub || !platform || !account || !socialMedia) {
      return
    }
    const filteredSocialMedia = JSON.parse(JSON.stringify(socialMedia))

    if (platform === SocialMediaPlatform.facebook) {
      delete filteredSocialMedia?.accessToken
      delete filteredSocialMedia?.accessRights
    }

    const mediaProfile: SocialMediaProfile = {
      sub,
      platform,
      account: {
        id: account.id,
        name: account.name,
        picture: account.picture,
        platform: account.platform
      },
      socialMedia: filteredSocialMedia
    }
    if (isUpdateSocialMedia) {
      updateSocialMediaObject({
        mediaConfig,
        mediaProfile,
        mediaItems
      })
    } else {
      createSocialMediaObject({
        mediaConfig,
        mediaProfile,
        mediaItems
      })
    }
    closeDialog()
  }

  const handleGoToNextStep = () => {
    switch (currentStepView) {
      case SocialMediaWizardPage.selectPlatform:
        setCurrentStepView(SocialMediaWizardPage.selectAccount)
        break
      case SocialMediaWizardPage.selectAccount:
        setCurrentStepView(SocialMediaWizardPage.selectContent)
        break
      case SocialMediaWizardPage.selectContent:
        handleCreateSocialMediaWidget()
        break
      default:
        setCurrentStepView(currentStepView)
    }
  }

  useEffect(() => {
    initFacebookSDK()
  }, [])

  return (
    <form className={classes.form}>
      <Stepper
        alternativeLabel
        connector={<ColorConnector />}
        activeStep={getActiveStepIndex(currentStepView)}>
        {getWizardSteps(t).map(({ text, icon }) => (
          <Step key={text}>
            <StepLabel StepIconComponent={props => <StepIcon {...props} icon={icon} />}>
              {!isMobile && text}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className={classes.content}>{renderCurrentStepView()}</div>
      <div className={classes.formFooter}>
        <Button
          disabled={currentStepView === SocialMediaWizardPage.selectPlatform}
          className={classes.actionBtn}
          onClick={handleGoToPreviousStep}>
          {t('contents.widgets.socialMedia.back')}
        </Button>
        {[SocialMediaWizardPage.selectContent, SocialMediaWizardPage.previewWidget].includes(
          currentStepView
        ) && (
          <Button
            disabled={mediaItems.length === 0 && mediaConfig.type === SocialMediaItemsType.posts}
            className={classes.actionBtn}
            onClick={handleGoToNextStep}>
            {t('contents.widgets.socialMedia.next')}
          </Button>
        )}
      </div>
    </form>
  )
}

export default CreateSocialMediaWidgetWizard
