/* eslint-disable @typescript-eslint/camelcase */
import { CognitoUserSession } from 'amazon-cognito-identity-js'
import { Auth } from 'aws-amplify'
import { path } from 'ramda'
import { UserRole } from '@seesignage/seesignage-utils'
import { UserInfo, SignupUserAttributes, UpdateUserAttributes, UserSession } from '../types/auth'

const getSession = async () => {
  try {
    const session: UserSession = await Auth.currentSession()
    return session
  } catch (error) {
    return undefined
  }
}

const getSubFromSession = (session: UserSession) => session.accessToken?.payload.sub

const getRoleFromSession = (session: CognitoUserSession) => {
  const groups: string[] = path(['accessToken', 'payload', 'cognito:groups'], session) || []
  return groups.includes('admin') ? UserRole.admin : UserRole.user
}

const getCurrentUserInfo = async () => {
  const userInfo: UserInfo = await Auth.currentUserInfo()
  return userInfo
}

const getCurrentCredentials = async () => {
  const credentials = await Auth.currentCredentials()
  return credentials
}

const authLogin = async (email: string, password: string) => {
  const cognitoUser = await Auth.signIn(email, password)
  return cognitoUser
}

const authLogout = async () => {
  await Auth.signOut()
}

const authSignup = async ({ email, password, name, phone_number }: SignupUserAttributes) => {
  const signupSchema = {
    username: email,
    email,
    password,
    attributes: {
      name,
      phone_number
    }
  }
  const user = await Auth.signUp(signupSchema)
  return user
}

const authUpdateUserInfo = async ({ name, phone_number }: UpdateUserAttributes) => {
  const userAttributes = {
    name,
    phone_number
  }
  const user = await Auth.currentAuthenticatedUser()
  const updatedUser = await Auth.updateUserAttributes(user, userAttributes)
  return updatedUser
}

const authRenewPassword = async (
  cognitoUser: any,
  password: string,
  requiredAttributes: {
    [key: string]: string
  }
) => {
  await Auth.completeNewPassword(cognitoUser, password, requiredAttributes)
}

const authChangePassword = async (currentPassword: string, password: string) => {
  const user = await Auth.currentAuthenticatedUser()
  await Auth.changePassword(user, currentPassword, password)
}

const authForgotPassword = async (email: string) => {
  await Auth.forgotPassword(email)
}

const authForgotPasswordSubmit = async (email: string, code: string, password: string) => {
  await Auth.forgotPasswordSubmit(email, code, password)
}

const authConfirmSignUp = async (email: string, code: string) => {
  await Auth.confirmSignUp(email, code)
}

const authResendSignUpVerificationCode = async (email: string) => {
  await Auth.resendSignUp(email)
}

const authVerifyEmail = async () => {
  await Auth.verifyCurrentUserAttribute('email')
}

const authVerifyEmailSubmit = async (code: string) => {
  await Auth.verifyCurrentUserAttributeSubmit('email', code)
}

export {
  getSession,
  getSubFromSession,
  getRoleFromSession,
  getCurrentUserInfo,
  getCurrentCredentials,
  authLogin,
  authLogout,
  authSignup,
  authUpdateUserInfo,
  authRenewPassword,
  authChangePassword,
  authForgotPassword,
  authForgotPasswordSubmit,
  authVerifyEmail,
  authVerifyEmailSubmit,
  authConfirmSignUp,
  authResendSignUpVerificationCode
}
