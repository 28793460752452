declare let document: any

export const browserCheck = () => {
  const isIE = /*@cc_on!@*/ false || Object.prototype.hasOwnProperty.call(document, 'documentMode')
  const isEdge = !isIE && Object.prototype.hasOwnProperty.call(window, 'StyleMedia')
  if (isIE || isEdge) {
    alert(`Internet Explorer is an outdated browser and we cannot ensure full support. 
Please consider using a modern browser (Chrome, Firefox) to ensure all features work properly.`)
  }
}
