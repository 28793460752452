import React, { useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material'
import ExpandIcon from 'mdi-react/ChevronDownIcon'
import CloseIcon from 'mdi-react/ChevronUpIcon'
import HelpCircleOutlineIcon from 'mdi-react/HelpCircleOutlineIcon'
import { SocialMediaPlatform } from '@seesignage/seesignage-utils'
import { TFunction } from 'i18next'

const ExpandableListItem = ({
  mainListContent,
  subListContent
}: {
  mainListContent: React.ReactNode
  subListContent: React.ReactNode
}) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <ListItemButton onClick={() => setOpen(!open)}>
        {mainListContent}
        {open ? <CloseIcon /> : <ExpandIcon />}
      </ListItemButton>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='ol' disablePadding sx={{ pl: 4 }}>
          {subListContent}
        </List>
      </Collapse>
    </>
  )
}

interface SocialMediaGuideProps {
  platform: SocialMediaPlatform
  t: TFunction
}

const SocialMediaGuide = ({ platform, t }: SocialMediaGuideProps) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandIcon />}>
        <Grid container>
          <Grid item xs={1}>
            <HelpCircleOutlineIcon />
          </Grid>
          <Grid item xs={11}>
            <Typography style={{ width: '100%' }} color='primary'>
              {t('contents.widgets.socialMedia.guide.extenstionsTitle')}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <List sx={{ width: '100%' }} component='ol' aria-labelledby='nested-list-subheader'>
          <ListItem>
            <ListItemIcon></ListItemIcon>
            <ListItemText
              primary={
                <Typography style={{ width: '100%' }} fontWeight='bold'>
                  {t('contents.widgets.socialMedia.guide.facebookAPIUsed')}
                </Typography>
              }
            />
          </ListItem>
          <ExpandableListItem
            mainListContent={
              <>
                <ListItemIcon>1.</ListItemIcon>
                <ListItemText primary={t('contents.widgets.socialMedia.guide.haveFacebook')} />
              </>
            }
            subListContent={
              <ListItem>
                <ListItemIcon>a.</ListItemIcon>
                <ListItemText
                  primary={
                    <a
                      href='https://www.facebook.com/help/188157731232424'
                      target='_blank'
                      rel='noopener noreferrer'>
                      {t('contents.widgets.socialMedia.guide.howToCreateFacebook')}
                    </a>
                  }
                />
              </ListItem>
            }
          />
          <ExpandableListItem
            mainListContent={
              <>
                <ListItemIcon>2.</ListItemIcon>
                <ListItemText primary={t('contents.widgets.socialMedia.guide.haveFacebookPage')} />
              </>
            }
            subListContent={
              <>
                <ListItem>
                  <ListItemIcon>a.</ListItemIcon>
                  <ListItemText
                    primary={
                      <a
                        href='https://www.facebook.com/business/help/1199464373557428?id=418112142508425'
                        target='_blank'
                        rel='noopener noreferrer'>
                        {t('contents.widgets.socialMedia.guide.howToCreatePageOnFb')}
                      </a>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>b.</ListItemIcon>
                  <ListItemText
                    primary={
                      <a
                        href='https://www.facebook.com/help/104002523024878/'
                        target='_blank'
                        rel='noopener noreferrer'>
                        {t('contents.widgets.socialMedia.guide.howToCreateFbPage')}
                      </a>
                    }
                  />
                </ListItem>
              </>
            }
          />
          <ExpandableListItem
            mainListContent={
              <>
                <ListItemIcon>3.</ListItemIcon>
                <ListItemText
                  primary={t('contents.widgets.socialMedia.guide.haveAdminAccessToFbPage')}
                />
              </>
            }
            subListContent={
              <>
                <ListItem>
                  <ListItemIcon>a.</ListItemIcon>
                  <ListItemText
                    primary={
                      <a
                        href='https://www.facebook.com/business/help/152071822895768?id=418112142508425'
                        target='_blank'
                        rel='noopener noreferrer'>
                        {t('contents.widgets.socialMedia.guide.howToGiveFbAccess')}
                      </a>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>b.</ListItemIcon>
                  <ListItemText
                    primary={
                      <a
                        href='https://www.facebook.com/help/187316341316631'
                        target='_blank'
                        rel='noopener noreferrer'>
                        {t('contents.widgets.socialMedia.guide.manageFbPageAccess')}
                      </a>
                    }
                  />
                </ListItem>
              </>
            }
          />
          {platform === SocialMediaPlatform.instagram && (
            <ExpandableListItem
              mainListContent={
                <>
                  <ListItemIcon>4.</ListItemIcon>
                  <ListItemText
                    primary={
                      <>
                        {t('contents.widgets.socialMedia.guide.mustHave')}
                        <a
                          href='https://help.instagram.com/138925576505882/'
                          target='_blank'
                          rel='noopener noreferrer'>
                          {t('contents.widgets.socialMedia.guide.profInstagramAccount')}
                        </a>
                        {t('contents.widgets.socialMedia.guide.linkFbPageAndInstagram')}
                      </>
                    }
                  />
                </>
              }
              subListContent={
                <>
                  <ListItem>
                    <ListItemIcon>a.</ListItemIcon>
                    <ListItemText
                      primary={
                        <a
                          href='https://help.instagram.com/502981923235522'
                          target='_blank'
                          rel='noopener noreferrer'>
                          {t('contents.widgets.socialMedia.guide.howToSetupBizInstgram')}
                        </a>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>b.</ListItemIcon>
                    <ListItemText
                      primary={
                        <a
                          href='https://help.instagram.com/570895513091465'
                          target='_blank'
                          rel='noopener noreferrer'>
                          {t('contents.widgets.socialMedia.guide.howToChangeFbPageInstagram')}
                        </a>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>c.</ListItemIcon>
                    <ListItemText
                      primary={
                        <a
                          href='https://www.facebook.com/help/1148909221857370'
                          target='_blank'
                          rel='noopener noreferrer'>
                          {t(
                            'contents.widgets.socialMedia.guide.howToAddRemoveInstagramFromFbPage'
                          )}
                        </a>
                      }
                    />
                  </ListItem>
                </>
              }
            />
          )}
        </List>
      </AccordionDetails>
    </Accordion>
  )
}

export default SocialMediaGuide
