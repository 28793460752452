import { createAsyncActions, createAction } from './utils'

const reducer = 'CONTENTS'

const resetContents = createAction(reducer, 'RESET')

const {
  action: initContentEditor,
  actionSuccess: initContentEditorSuccess,
  actionFail: initContentEditorFail
} = createAsyncActions(reducer, 'INIT_CONTENT_EDITOR')

const {
  action: createObject,
  actionSuccess: createObjectSuccess,
  actionFail: createObjectFail
} = createAsyncActions(reducer, 'CREATE_OBJECT')

const selectObject = createAction(reducer, 'SELECT')
const setContentId = createAction(reducer, 'SET_CONTENT_ID')
const deselectObject = createAction(reducer, 'DESELECT')

const {
  action: zoomCanvas,
  actionSuccess: zoomCanvasSuccess,
  actionFail: zoomCanvasFail
} = createAsyncActions(reducer, 'ZOOM_CANVAS')

const updateEditorOption = createAction(reducer, 'UPDATE_EDITOR_OPTION')

const contentFormChange = createAction(reducer, 'FORM_CHANGE')

const setContentModified = createAction(reducer, 'SET_CONTENT_MODIFIED')
const openContentObjectsView = createAction(reducer, 'OPEN_CONTENT_OBJECTS_VIEW')

const customContentChangeAction = createAction(reducer, 'CUSTOM_CONTENT_CHANGE_ACTION')

const {
  action: contentDrawerFormChange,
  actionSuccess: contentDrawerFormChangeSuccess,
  actionFail: contentDrawerFormChangeFail
} = createAsyncActions(reducer, 'DRAWER_FORM_CHANGE')

const {
  action: addMediaContent,
  actionSuccess: addMediaContentSuccess,
  actionFail: addMediaContentFail
} = createAsyncActions(reducer, 'ADD_MEDIA_CONTENT')

const {
  action: updateMediaSource,
  actionSuccess: updateMediaSourceSuccess,
  actionFail: updateMediaSourceFail
} = createAsyncActions(reducer, 'UPDATE_MEDIA_SOURCE')

const {
  action: setBackgroundImage,
  actionSuccess: setBackgroundImageSuccess,
  actionFail: setBackgroundImageFail
} = createAsyncActions(reducer, 'SET_BACKGROUND_IMAGE')

const {
  action: selectedObjectAction,
  actionSuccess: selectedObjectActionSuccess,
  actionFail: selectedObjectActionFail
} = createAsyncActions(reducer, 'SELECTED_OBJECT_ACTION')

const {
  action: objectActionById,
  actionSuccess: objectActionByIdSuccess,
  actionFail: objectActionByIdFail
} = createAsyncActions(reducer, 'OBJECT_ACTION_BY_ID_ACTION')

const {
  action: canvasAction,
  actionSuccess: canvasActionSuccess,
  actionFail: canvasActionFail
} = createAsyncActions(reducer, 'CANVAS_ACTION')

const {
  action: createContent,
  actionSuccess: createContentSuccess,
  actionFail: createContentFail
} = createAsyncActions(reducer, 'CREATE')

const {
  action: updateContent,
  actionSuccess: updateContentSuccess,
  actionFail: updateContentFail
} = createAsyncActions(reducer, 'UPDATE')

const {
  action: saveCanvas,
  actionSuccess: saveCanvasSuccess,
  actionFail: saveCanvasFail
} = createAsyncActions(reducer, 'SAVE_CANVAS')

const {
  action: downloadCanvas,
  actionSuccess: downloadCanvasSuccess,
  actionFail: downloadCanvasFail
} = createAsyncActions(reducer, 'DOWNLOAD_CANVAS')

const {
  action: deleteContent,
  actionSuccess: deleteContentSuccess,
  actionFail: deleteContentFail
} = createAsyncActions(reducer, 'DELETE')

const {
  action: listContents,
  actionSuccess: listContentsSuccess,
  actionFail: listContentsFail
} = createAsyncActions(reducer, 'LIST')

const {
  action: getContent,
  actionSuccess: getContentSuccess,
  actionFail: getContentFail
} = createAsyncActions(reducer, 'GET')

const {
  action: duplicateContent,
  actionSuccess: duplicateContentSuccess,
  actionFail: duplicateContentFail
} = createAsyncActions(reducer, 'DUPLICATE')

const {
  action: copyContentToEnvironments,
  actionSuccess: copyContentToEnvironmentsSuccess,
  actionFail: copyContentToEnvironmentsFail
} = createAsyncActions(reducer, 'COPY_TO_ENVIRONMENTS')

const {
  action: saveCanvasAsPlaylistItem,
  actionSuccess: saveCanvasAsPlaylistItemSuccess,
  actionFail: saveCanvasAsPlaylistItemFail
} = createAsyncActions(reducer, 'SAVE_CANVAS_AS_PLAYLIST_ITEM')

const {
  action: updateSelectedWidget,
  actionSuccess: updateSelectedWidgetSuccess,
  actionFail: updateSelectedWidgetFail
} = createAsyncActions(reducer, 'UPDATE_SELECTED_WIDGET')

const {
  action: reorderObjects,
  actionSuccess: reorderObjectsSuccess,
  actionFail: reorderObjectsFail
} = createAsyncActions(reducer, 'REORDER_OBJECTS')

const selectUnusedContents = createAction(reducer, 'SELECT_UNUSED')

const updateEditorCanvas = createAction(reducer, 'UPDATE_EDITOR_CANVAS')
const closeEditorCanvas = createAction(reducer, 'CLOSE_EDITOR_CANVAS')

const {
  action: setEditorCursorMode,
  actionSuccess: setEditorCursorModeSuccess,
  actionFail: setEditorCursorModeFail
} = createAsyncActions(reducer, 'SET_EDITOR_CURSOR_MODE')

const setDrawingObjectId = createAction(reducer, 'SET_DRAWING_OBJECT_ID')

const {
  action: contentToolFormChange,
  actionSuccess: contentToolFormChangeSuccess,
  actionFail: contentToolFormChangeFail
} = createAsyncActions(reducer, 'TOOL_FORM_CHANGE')

export {
  resetContents,
  selectObject,
  deselectObject,
  zoomCanvas,
  zoomCanvasSuccess,
  zoomCanvasFail,
  initContentEditor,
  initContentEditorSuccess,
  initContentEditorFail,
  createObject,
  createObjectSuccess,
  createObjectFail,
  contentFormChange,
  contentDrawerFormChange,
  contentDrawerFormChangeSuccess,
  contentDrawerFormChangeFail,
  setBackgroundImage,
  setBackgroundImageSuccess,
  setBackgroundImageFail,
  addMediaContent,
  addMediaContentSuccess,
  addMediaContentFail,
  updateMediaSource,
  updateMediaSourceSuccess,
  updateMediaSourceFail,
  createContent,
  createContentSuccess,
  createContentFail,
  saveCanvas,
  saveCanvasSuccess,
  saveCanvasFail,
  downloadCanvas,
  downloadCanvasSuccess,
  downloadCanvasFail,
  updateContent,
  updateContentSuccess,
  updateContentFail,
  deleteContent,
  deleteContentSuccess,
  deleteContentFail,
  listContents,
  listContentsSuccess,
  listContentsFail,
  getContent,
  getContentSuccess,
  getContentFail,
  canvasAction,
  canvasActionSuccess,
  canvasActionFail,
  selectedObjectAction,
  selectedObjectActionSuccess,
  selectedObjectActionFail,
  objectActionById,
  objectActionByIdSuccess,
  objectActionByIdFail,
  updateEditorCanvas,
  closeEditorCanvas,
  duplicateContent,
  duplicateContentSuccess,
  duplicateContentFail,
  copyContentToEnvironments,
  copyContentToEnvironmentsSuccess,
  copyContentToEnvironmentsFail,
  setContentModified,
  selectUnusedContents,
  saveCanvasAsPlaylistItem,
  saveCanvasAsPlaylistItemFail,
  saveCanvasAsPlaylistItemSuccess,
  openContentObjectsView,
  customContentChangeAction,
  updateSelectedWidget,
  updateSelectedWidgetSuccess,
  updateSelectedWidgetFail,
  setEditorCursorMode,
  setEditorCursorModeSuccess,
  setEditorCursorModeFail,
  setDrawingObjectId,
  contentToolFormChange,
  contentToolFormChangeSuccess,
  contentToolFormChangeFail,
  reorderObjects,
  reorderObjectsSuccess,
  reorderObjectsFail,
  setContentId,
  updateEditorOption
}
