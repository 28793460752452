import { Grid, Tooltip, Typography } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { withTranslation, WithTranslation } from 'react-i18next'
import Truncate from 'react-truncate'
import RevolverIcon from 'mdi-react/FerrisWheelIcon'
import { MediaType, RevolverPlaylistItemUI } from '@seesignage/seesignage-utils'
import { PlaylistItemCardRootProps } from '../../../../../types/playlists'
import ItemIcons from './ItemIcons'
import SelectedIndicator from './SelectedIndicator'

const styles = () => ({
  item: {
    height: 140,
    overflow: 'hidden'
  },
  itemTitleText: {
    padding: '4px 0px 0px 4px',
    width: '100%',
    overflow: 'hidden'
  },
  itemDescriptionArea: {
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
    height: '100%',
    position: 'relative' as any
  },
  carouselContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center' as any,
    flexDirection: 'column' as any
  },
  carouselArrow: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
})

const imageStyle: React.CSSProperties = {
  display: 'block',
  maxHeight: 140,
  margin: 'auto',
  width: '100%',
  objectFit: 'scale-down'
}

const videoStyle: React.CSSProperties = {
  width: '100%',
  maxHeight: 140,
  objectFit: 'scale-down'
}

interface OwnProps extends PlaylistItemCardRootProps {
  item: RevolverPlaylistItemUI
  selectPlaylistItem: (itemId: string) => void
  deselectPlaylistItem: (itemId: string) => void
  isMobile: boolean
}

type RevolverAsItemProps = OwnProps & WithTranslation & WithStyles<typeof styles>

const RevolverAsItem: React.FC<RevolverAsItemProps> = ({
  classes,
  item,
  isItemSelected,
  isMobile,
  selectPlaylistItem,
  deselectPlaylistItem,
  itemPercentage,
  showPercentage,
  t
}) => {
  return (
    <Grid container alignContent='center' className={classes.item}>
      <Grid item xs={4} className={classes.carouselContainer}>
        <Carousel
          statusFormatter={(current, total) => `${current} / ${total}`}
          showThumbs={false}
          showIndicators={false}
          infiniteLoop>
          {item.revolverItems.map(media =>
            media.type === MediaType.video ? (
              <video
                key={item.itemId}
                poster={media.thumbnailUrl}
                preload='none'
                style={videoStyle}
                controls
                src={media.url}
              />
            ) : (
              <img
                key={item.itemId}
                style={imageStyle}
                src={media.thumbnailUrl || media.url}
                alt=''
              />
            )
          )}
        </Carousel>
      </Grid>
      <Grid
        className={classes.itemDescriptionArea}
        item
        xs={8}
        onClick={() =>
          isItemSelected ? deselectPlaylistItem(item.itemId) : selectPlaylistItem(item.itemId)
        }>
        <Grid container style={{ height: '100%' }}>
          <Grid item xs={12}>
            <Grid container className={classes.itemTitleText}>
              <Grid item xs={2} sm={1}>
                <RevolverIcon />
              </Grid>
              <Grid item xs={9} sm={10}>
                <Tooltip disableInteractive title={item.name}>
                  <Typography align='left' variant='subtitle1' gutterBottom>
                    <Truncate lines={1} width={isMobile ? 180 : 300}>
                      {item.name}
                    </Truncate>
                  </Typography>
                </Tooltip>
              </Grid>
              <SelectedIndicator
                isItemSelected={isItemSelected}
                showPercentage={showPercentage}
                itemPercentage={itemPercentage}
              />
              <Grid item xs={11}>
                <Typography variant='caption'>
                  {t('playlists.revolver.contains', { count: item.revolverItems.length })}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ItemIcons item={item} />
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(withTranslation()(RevolverAsItem))
