import { TableCell, TableRow } from '@mui/material'
import { GeneralProductUI, RetailProduct } from '@seesignage/seesignage-utils'
import dateFormat from 'dateformat'
import React, { Fragment } from 'react'

interface RetailProductRowProps {
  product: RetailProduct | GeneralProductUI
  ActionsField: any
  t: any
}

const RetailProductRow: React.FC<RetailProductRowProps> = ({ product, ActionsField }) => {
  const { productId, name, price, description, createdAt, updatedAt } = product
  const dateToBeUsed = updatedAt || createdAt
  const updatedString = dateToBeUsed ? dateFormat(new Date(dateToBeUsed), 'dd.mm.yyyy') : ''
  return (
    <Fragment key={productId}>
      <TableRow hover tabIndex={-1} key={productId}>
        <TableCell>{name}</TableCell>
        <TableCell>{price ? price / 100 : ''}</TableCell>
        <TableCell>{description}</TableCell>
        <TableCell>{updatedString}</TableCell>
        <TableCell>{ActionsField}</TableCell>
      </TableRow>
    </Fragment>
  )
}

export default RetailProductRow
