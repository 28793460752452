import React, { ComponentType } from 'react'
import { Checkbox } from '@mui/material'
import { WrappedFieldInputProps } from 'redux-form'
import { MdiReactIconProps } from 'mdi-react'
import colors from '../../../styles/common/colors'

interface ConditionCheckBoxProps {
  input: WrappedFieldInputProps
  Icon: ComponentType<MdiReactIconProps>
  iconSize?: number
}

const ReduxIconCheckbox = ({
  input: { onChange, value },
  Icon,
  iconSize
}: ConditionCheckBoxProps) => (
  <Checkbox
    checked={value ? value : false}
    icon={<Icon size={iconSize} />}
    checkedIcon={<Icon size={iconSize} color={colors.seesignageColor} />}
    onChange={onChange}
  />
)

export default ReduxIconCheckbox
