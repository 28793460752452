import { LinearProgress } from '@mui/material'
import React, { Fragment, useState } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { ListType, RetailProduct, GeneralProductUI, Customer } from '@seesignage/seesignage-utils'
import { selectSelectedCustomer } from '../../selectors/customers'
import {
  selectCustomerProductsAsArray,
  selectCustomerProductsType,
  selectProductsAreLoading
} from '../../selectors/products'

import { StateInterface } from '../../types/states'
import { BreadcrumbContentType } from '../../types/breadcrumbs'
import PageTitle from '../../components/PageTitle'
import ProductsTable from './ProductsTable'
import ProductsTableToolbar, { ProductsTableToolbarOwnProps } from './ProductsTableToolbar'

interface StateProps {
  products: GeneralProductUI[] | RetailProduct[]
  selectedCustomer?: Customer
  selectedProductType?: ListType
  productsLoading: boolean
}

type ProductsProps = StateProps & WithTranslation

const Products: React.FC<ProductsProps> = ({
  selectedCustomer,
  selectedProductType,
  products,
  productsLoading,
  t
}) => {
  const [searchTerm, search] = useState<string>('')
  const toolbarProps: ProductsTableToolbarOwnProps = {
    search,
    selectedProductType,
    selectedCustomer,
    t
  }
  return (
    <Fragment>
      <PageTitle contentType={BreadcrumbContentType.products} />
      <ProductsTableToolbar {...toolbarProps} />
      {productsLoading && <LinearProgress />}
      {products.length > 0 && selectedProductType && selectedCustomer?.productForm && (
        <ProductsTable
          searchTerm={searchTerm}
          products={products}
          productsType={selectedProductType}
          productForm={selectedCustomer?.productForm}
        />
      )}
    </Fragment>
  )
}
const mapStateToProps = (state: StateInterface): StateProps => ({
  selectedCustomer: selectSelectedCustomer(state),
  selectedProductType: selectCustomerProductsType(state),
  products: selectCustomerProductsAsArray(state) as RetailProduct[] | GeneralProductUI[],
  productsLoading: selectProductsAreLoading(state)
})

export default connect<StateProps, {}, {}, StateInterface>(mapStateToProps)(
  withTranslation()(Products)
)
