import React from 'react'
import { reduxForm, InjectedFormProps, Field } from 'redux-form'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { TFunction } from 'i18next'
import { ContentWeatherWidgetFormData } from '../../../../../../types/contents'
import SimpleExtensionPanel from '../../../../../../components/ExtensionPanels/SimpleExtensionPanel'
import Borderfields from '../CommonFields/BorderFields'
import TextFields from '../CommonFields/TextFields'
import { ReduxGoogleAutocomplete } from '../../../../../../components/FormInput/ReduxWrappers'

const useStyles = makeStyles(() => ({
  accordionSummaryRootClass: {
    backgroundColor: '#F5F5F5',
    borderBottom: '1px solid rgba(0, 0, 0, 0.09)'
  }
}))

interface OwnProps {
  t: TFunction
}

type FormProps = ContentWeatherWidgetFormData
type ComponentProps = OwnProps

const WeatherWidgetForm: React.FC<ComponentProps &
  InjectedFormProps<FormProps, ComponentProps>> = ({ t }) => {
  const classes = useStyles()
  return (
    <Grid container>
      <Grid item xs={12}>
        <SimpleExtensionPanel
          title={t('contents.widgets.weather.weather')}
          isOpen={true}
          accordionSummaryRootClass={classes.accordionSummaryRootClass}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                fullWidth
                name='cWeatherProps.location'
                label={t('location.location')}
                existingLocations={[]}
                component={ReduxGoogleAutocomplete}
              />
            </Grid>
          </Grid>
        </SimpleExtensionPanel>
      </Grid>
      <Grid item xs={12}>
        <SimpleExtensionPanel
          title={t('contents.properties.text')}
          isOpen={false}
          accordionSummaryRootClass={classes.accordionSummaryRootClass}>
          <TextFields t={t} namePrefix='cWeatherProps.styles' isFieldsDisabled={false} />
        </SimpleExtensionPanel>
      </Grid>
      <Grid item xs={12}>
        <SimpleExtensionPanel
          title={t('contents.properties.border')}
          isOpen={false}
          accordionSummaryRootClass={classes.accordionSummaryRootClass}>
          <Borderfields t={t} namePrefix='cWeatherProps.styles' isFieldsDisabled={false} />
        </SimpleExtensionPanel>
      </Grid>
    </Grid>
  )
}

export default reduxForm<FormProps, ComponentProps>({
  form: 'WeatherWidgetForm'
})(WeatherWidgetForm)
