import React from 'react'
import { Field } from 'redux-form'
import { Grid } from '@mui/material'
import { MediaType } from '@seesignage/seesignage-utils'
import SelectMultipleMediaField from '../../../components/FormInput/SelectMedia/SelectMultipleMediaField'

interface CustomMediaProps {
  id: string
  mediaType: MediaType
  formName: string
}

export type CustomMediaComponentType = React.FC<CustomMediaProps>

/**
 * Form field wrapper component for selecting custom image or video.
 * @param param0
 */
const CustomMedia: CustomMediaComponentType = ({ id, mediaType, formName }) => (
  <Grid container spacing={2} alignItems='center'>
    <Grid item xs={12} style={{ overflow: 'hidden' }}>
      <Field
        mediaType={mediaType}
        useMediaAsFieldValue
        isSingleFileInput
        formName={formName}
        name={`content.${id}`}
        component={SelectMultipleMediaField}
      />
    </Grid>
  </Grid>
)

export default CustomMedia
