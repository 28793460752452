import React, { Fragment, useEffect, useState } from 'react'
import dateFormat from 'dateformat'
import { useTranslation } from 'react-i18next'
import { saveAs } from 'file-saver'
import {
  DialogActions,
  Button,
  LinearProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Toolbar
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Log, S3ScreensLogsMetadata } from '@seesignage/seesignage-utils'
import DownloadIcon from 'mdi-react/DownloadIcon'
import { CloseDialog } from '../../../../types/actions'
import ScreensService from '../../../../services/api/screens'
import { toolbarStyles } from '../../../../styles/common'

const useStyles = makeStyles(theme => ({
  ...toolbarStyles(theme),
  titleContainer: {
    display: 'flex',
    width: '100%',
    minHeight: '5%'
  },
  logViever: {
    marginTop: 5,
    backgroundColor: '#222',
    color: '#d6d6d6',
    fontFamily: 'Monaco, monospace',
    padding: '5px 10px 5px 20px',
    textAlign: 'left',
    overflow: 'scroll',
    zIndex: 9999,
    maxHeight: '80%'
  },
  logLineError: {
    margin: '5px 0 0 0',
    color: '#ff9b93'
  },
  logLineInfo: {
    margin: '5px 0 0 0',
    whiteSpace: 'nowrap'
  },
  selectLogSession: {
    minWidth: 200
  }
}))

/**
 * Download logs as .log file
 * @param logSessions
 */
const downloadLogs = (logSessions: Log[][]) => {
  let fileContent = ''
  logSessions.forEach(logSession => {
    fileContent = `${fileContent}--------------------------------------- RESTART --------------------------------------\n`
    logSession.forEach(({ message, type, date }) => {
      fileContent = `${fileContent}${dateFormat(
        date,
        'yyyy-mm-dd HH:MM:ss'
      )}: [${type}] ${message}\n`
    })
  })
  saveAs('data:text;charset=utf-8,' + encodeURI(fileContent), 'screen_logs.log')
}

interface LogsProps {
  dialogId: string
  screenId: string
  environmentId: string
  closeDialog: CloseDialog
}

const Logs: React.FC<LogsProps> = ({ screenId, environmentId, dialogId, closeDialog }) => {
  const [t] = useTranslation()
  const classes = useStyles()
  const [logSessions, setLogs] = useState<Log[][]>([])
  const [metadata, setMetadata] = useState<S3ScreensLogsMetadata[]>([])
  const [loading, setLoadingState] = useState(false)
  const [selectedDate, setSelectedDate] = useState<string | undefined>(undefined)

  useEffect(() => {
    const getLogsMetadata = async () => {
      setLoadingState(true)
      const logsMetadata = await ScreensService.getScreenLogsMetadata(environmentId, screenId)
      if (logsMetadata.length) {
        setMetadata(logsMetadata)
        setSelectedDate(logsMetadata[0].date)
      }
      setLoadingState(false)
    }
    getLogsMetadata()
  }, [environmentId, screenId])

  useEffect(() => {
    const getLogs = async () => {
      if (selectedDate) {
        setLoadingState(true)
        const logsResponse = await ScreensService.getScreenLogs(
          environmentId,
          screenId,
          selectedDate
        )
        setLogs(logsResponse)
        setLoadingState(false)
      }
    }
    getLogs()
  }, [selectedDate, environmentId, screenId])
  const logsExist = logSessions.length > 0
  return (
    <Fragment>
      {selectedDate && (
        <Toolbar variant='dense' disableGutters>
          <FormControl variant='standard' className={classes.selectLogSession}>
            <InputLabel htmlFor='selectLogSession'>{t('calendar.date')}</InputLabel>
            <Select
              size='small'
              variant='standard'
              value={selectedDate}
              onChange={e => setSelectedDate(e.target.value as string)}
              inputProps={{
                name: 'selectedDate',
                id: 'selectedDate'
              }}>
              {metadata.map(({ date, logsessions, rows }, i) => (
                <MenuItem key={`menu-item-${i}`} value={date}>
                  {`${date} (rows: ${rows}) (sessions: ${logsessions})`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div className={classes.spacer} />
          <div className={classes.actions}>
            {logsExist && (
              <Button
                size='small'
                variant='contained'
                color='primary'
                onClick={() => downloadLogs(logSessions)}
                startIcon={<DownloadIcon />}>
                {t('general.download')}
              </Button>
            )}
          </div>
        </Toolbar>
      )}
      {loading && <LinearProgress />}
      <div id='log-viewer' className={classes.logViever}>
        {logsExist ? (
          logSessions.map((logSession, index) => (
            <Fragment key={`log-session-${index}`}>
              <p
                style={{
                  color: 'lightGreen'
                }}>{`--------------------------------------- LOG SESSION START ---------------------------------------`}</p>
              {logSession.map(({ date, message, type }, i) => (
                <Fragment key={`log-line-${i}`}>
                  <p className={type === 'info' ? classes.logLineInfo : classes.logLineError}>
                    {dateFormat(date, 'yyyy-mm-dd HH:MM:ss')}: {message}
                  </p>
                </Fragment>
              ))}
            </Fragment>
          ))
        ) : (
          <Fragment>
            <p className={classes.logLineInfo}>{t('logs.notAvailable')}</p>
          </Fragment>
        )}
      </div>
      <DialogActions>
        <Button onClick={() => closeDialog(dialogId)} color='primary'>
          {t('general.close')}
        </Button>
      </DialogActions>
    </Fragment>
  )
}

export default Logs
