import { AppBar, Button, DialogActions, LinearProgress, Theme, Toolbar } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import SettingsIcon from 'mdi-react/SettingsIcon'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { reset } from 'redux-form'
import { getInfopage, updateInfopageSettings } from '../../../actions/infopages'
import { bindSubmitActionToPromise } from '../../../utils/forms'
import Dialog from '../../Dialog'
import InfopageSettings from '../Forms/InfopageSettings'
import { TemplateAsFormValue } from '../../../types/templates'

const styles = (theme: Theme) => ({
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    color: theme.palette.text.secondary,
    marginRight: 4,
    display: 'flex'
  },
  appBar: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14)',
    top: 56,
    [theme.breakpoints.up('sm')]: {
      top: 64
    }
  },
  saveButton: {
    margin: 6,
    width: '100%'
  }
})

interface OwnProps {
  submitForm: () => void
  initialValues: {
    name: string
    template: TemplateAsFormValue
  }
  isFormPristine: boolean
  submitting: boolean
  isSettingsModified: boolean
}

interface DispatchProps {
  getInfopage: () => void
  resetForm: () => void
  updateSettings: (formData: any) => void
}

type EditInfopageToolbar = OwnProps & DispatchProps & WithStyles<typeof styles> & WithTranslation

const EditInfopageToolbar: React.FC<EditInfopageToolbar> = ({
  classes,
  submitForm,
  initialValues,
  isFormPristine,
  getInfopage,
  resetForm,
  updateSettings,
  submitting,
  isSettingsModified,
  t
}) => (
  <AppBar className={classes.appBar} position='sticky' color='default'>
    <Toolbar>
      <div className={classes.spacer} />
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button
          disabled={(!isSettingsModified && isFormPristine) || submitting}
          variant='contained'
          onClick={() => {
            resetForm()
            getInfopage()
          }}>
          {t('general.cancel')}
        </Button>
        <Button
          disabled={(!isSettingsModified && isFormPristine) || submitting}
          variant='contained'
          color='primary'
          onClick={submitForm}>
          {t('templates.forms.save')}
        </Button>
      </DialogActions>
      <Dialog
        buttonDisabled={submitting}
        dialogId='InfopageSettingsDialog'
        title={t('infopages.settings')}
        tooltipTitle={t('infopages.settings')}
        ButtonIcon={SettingsIcon}
        Content={
          <InfopageSettings
            initialValues={initialValues}
            submitAction={updateSettings}
            submitButtonLabel={t('general.update')}
            t={t}
          />
        }
      />
    </Toolbar>
    {submitting && <LinearProgress />}
  </AppBar>
)

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  getInfopage: () => dispatch(getInfopage()),
  resetForm: () => dispatch(reset('CreateInfopageForm')),
  updateSettings: bindSubmitActionToPromise(dispatch, updateInfopageSettings)
})

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(EditInfopageToolbar)))
