import { Grid } from '@mui/material'
import React, { Fragment } from 'react'
import { Field } from 'redux-form'
import { ComponentType } from '@seesignage/seesignage-utils'
import { useTranslation } from 'react-i18next'
import { ReduxTextField } from '../../../../components/FormInput/ReduxWrappers'
import BorderFields from './CommonFields/BorderFields'
import PositionFields from './CommonFields/PositionFields'
import SizeFields from './CommonFields/SizeFields'
import SpacingFields from './CommonFields/SpacingFields'
import CommonTextFields from './CommonFields/TextFields'
import { generateKeyFieldPrefix, gridItemProps } from './utils'
import EffectsFields from './CommonFields/EffectsFields'
import BackgroundFields from './CommonFields/BackgroundFields'

interface TextFieldsProps {
  id: string
  level: number
  childIndexes: number[]
  formName: string
}

const TextFields: React.FC<TextFieldsProps> = ({ level, childIndexes, id, formName }) => {
  const [t] = useTranslation()
  const fieldProps = {
    level,
    childIndexes,
    t,
    formName
  }
  return (
    <Fragment>
      <CommonTextFields {...fieldProps} />
      <PositionFields {...fieldProps} />
      <SpacingFields {...fieldProps} />
      <BorderFields {...fieldProps} />
      <SizeFields {...fieldProps} />
      <BackgroundFields {...fieldProps} />
      <EffectsFields id={id} type={ComponentType.text} {...fieldProps} />
      <Grid container spacing={2} alignItems='center'>
        <Grid {...gridItemProps}>
          <Field
            fullWidth
            name={generateKeyFieldPrefix(level, childIndexes, 'label')}
            label='Label'
            component={ReduxTextField}
          />
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default TextFields
