import { fabric } from 'fabric'
import { isFabricWorkareaObject } from '@seesignage/seesignage-player-utils'
import { clearCanvasBackgroundFormValues } from './canvasFormValues'

// Defines the number of fraction digits to use when serializing object values.
// Note: otherwise scaleX and scaleY may not be accurate enough
fabric.Object.NUM_FRACTION_DIGITS = 5

const setWorkareaBackgroundColor = (color: string) => {
  const workarea = window.workarea
  if (isFabricWorkareaObject(workarea)) {
    workarea.set('backgroundColor', color)
  }
}

const setWorkareaBackgroundImage = (key: string, url: string) => {
  const workarea = window.workarea
  if (isFabricWorkareaObject(workarea)) {
    const width = workarea.getScaledWidth()
    const height = workarea.getScaledHeight()
    // use fromURL because it helps us to get image dimensions
    fabric.Image.fromURL(
      url,
      fabricImage => {
        const scaleX = width / (fabricImage.width || width)
        const scaleY = height / (fabricImage.height || height)
        fabricImage.set({
          originX: 'left',
          originY: 'top',
          scaleX,
          scaleY
        })
        workarea.set({
          ...fabricImage,
          crossOrigin: 'anonymous'
        })
        workarea.setCustomOptions({ key, src: url })
        window.canvas.renderAll()
      },
      {
        crossOrigin: 'anonymous'
      }
    )
  }
}

const clearWorkareaBackground = () => {
  const workarea = window.workarea
  if (isFabricWorkareaObject(workarea)) {
    workarea.setElement(new Image(workarea.getScaledWidth(), workarea.getScaledHeight()))
    workarea.set({ scaleX: 1, scaleY: 1 })
    workarea.setCustomOptions({})
    window.canvas.renderAll()
  }
  clearCanvasBackgroundFormValues()
}

const getWorkareaCoordinates = () => {
  if (window.workarea) {
    const { top, left } = window.workarea
    return { top, left }
  }
  return { top: 0, left: 0 }
}

/**
 * format object position with relative to workarea position
 * `value` prop on `input` should not be null
 */
const formatObjectPositionRelativeToWorkarea = (pos: 'top' | 'left') => (value = 0) => {
  const workareaCoords = getWorkareaCoordinates()
  const positionValue = workareaCoords?.[pos] || 0
  return value - positionValue
}

/**
 * Parse object positon from relative to absolute to canvas
 * `value` prop on `input` should not be null
 */
const parseObjectTopRelativeToWorkarea = (pos: 'top' | 'left') => (value = 0) => {
  const workareaCoords = getWorkareaCoordinates()
  const positionValue = workareaCoords?.[pos] || 0
  return value + positionValue
}

export {
  setWorkareaBackgroundColor,
  setWorkareaBackgroundImage,
  clearWorkareaBackground,
  getWorkareaCoordinates,
  formatObjectPositionRelativeToWorkarea,
  parseObjectTopRelativeToWorkarea
}
