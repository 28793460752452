import {
  CampaignProduct,
  CampaignItemType,
  CampaignBatchProductsUI,
  isCampaignBatchProducts,
  CampaignProductUI,
  isCampaignProduct,
  CampaignSubUI,
  isSubCampaign
} from '@seesignage/seesignage-utils'
import { CreatableOptionType } from '../components/FormInput/ReduxWrappers/ReduxCreateableAutocomplete'
import { TemplateAsFormValue } from './templates'

export interface CreateCampaignFormData {
  name: string
  startDate: string
  endDate: string
  template: TemplateAsFormValue
  tags: CreatableOptionType[]
  customerId: string
}

export interface CampaignBatchProductsFormValue extends CampaignBatchProductsUI {
  batchPriceType: CampaignBatchPriceType
}
export interface UpdateCampaignFormData extends CreateCampaignFormData {
  items: (CampaignBatchProductsFormValue | CampaignProductFormValue)[]
  /** additional property to determine if form has changed programmatically in InitializeForm */
  changed?: boolean
}

export interface CampaignProductFormValue extends CampaignProduct {
  name: string
  vnr: string
}

export interface GenerateCampaignExcelFormData {
  campaignId: string
  type: 'own' | 'subCampaigns'
}

export interface PrefillCampaignProductParams {
  form: string
  fieldName: string
  /** path to item root in Form object */
  item: string
  productId: string
}

export enum CampaignBatchPriceType {
  none = 'none',
  batchPrice = 'batchPrice',
  batchPercentage = 'batchPercentage',
  packagePrice = 'packagePrice'
}

export interface BatchPriceFieldsProps {
  price?: {
    hidden?: boolean
    disabled?: boolean
  }
  discountPrice?: {
    hidden?: boolean
    disabled?: boolean
  }
  discountPercentage?: {
    hidden?: boolean
    disabled?: boolean
  }
}

/**
 * Value pushed to `redux-form fieldArray`
 */
export interface InitialCampaignItem {
  itemId: string
  type: CampaignItemType
  showPercentage?: boolean
  batchPriceType?: CampaignBatchPriceType
}

export const isCampaignBatchProductsUI = (item: any): item is CampaignBatchProductsUI =>
  isCampaignBatchProducts(item) ? true : false

export const isCampaignProductUI = (item: any): item is CampaignProductUI =>
  isCampaignProduct(item) ? true : false

export const isCampaignSubUI = (campaign: any): campaign is CampaignSubUI =>
  isSubCampaign(campaign) ? true : false
