import { handleActions } from 'redux-actions'
import { ScreenResponse, Screen } from '@seesignage/seesignage-utils'
import {
  createScreenSuccess,
  listScreensSuccess,
  selectScreen,
  deselectScreen,
  deleteScreenSuccess,
  updateScreenSuccess,
  listScreens,
  listScreensFail,
  getScreenSuccess,
  changeScreensViewMode,
  downloadAdminScreensCsv,
  downloadAdminScreensCsvFail,
  downloadAdminScreensCsvSuccess,
  resetScreens,
  listScreensWithWarningsSuccess,
  listScreensWithWarnings,
  listScreensWithWarningsFail,
  selectScreenDashboard,
  deselectScreenDashboard,
  updateScreenFromDashboardSuccess,
  getScreenFromDashboardSuccess,
  moveScreenSuccess,
  batchUpdateScreensSuccess,
  selectAllScreens,
  deselectAllScreens,
  selectScreenRow,
  setScreensInitialLoad
} from '../actions/screens'
import { ScreensState } from '../types/states'
import { Action } from '../types/actions'
import { ScreensIndexedById } from '../types/screens'

const initialState: ScreensState = {
  screens: {}, // indexed by screenId
  screensWithWarnings: {}, // indexed by screenId
  viewMode: {
    isListMode: false
  },
  selectedScreenIds: [],
  listScreensIsLoading: false,
  listScreensDashboardIsLoading: false,
  adminScreensCsvLoading: false,
  isInitialLoad: true
}

const screens = handleActions<ScreensState, any>(
  {
    [resetScreens]: () => initialState,
    [selectScreen]: (state, { payload: selectedScreenId }: Action<string>) => ({
      ...state,
      selectedScreenIds: [...state.selectedScreenIds, selectedScreenId]
    }),
    [deselectScreen]: (state, { payload: screenId }: Action<string>) => ({
      ...state,
      selectedScreenIds: state.selectedScreenIds.filter(id => id !== screenId)
    }),
    [selectAllScreens]: (state, { payload: screenIds }: Action<string[]>) => ({
      ...state,
      selectedScreenIds: screenIds
    }),
    [deselectAllScreens]: state => ({
      ...state,
      selectedScreenIds: []
    }),
    [selectScreenRow]: (state, { payload: screenId }: Action<string>) => ({
      ...state,
      selectedScreenIds: [screenId]
    }),
    [selectScreenDashboard]: (state, { payload: selectedScreenIdDashboard }: Action<string>) => ({
      ...state,
      selectedScreenIdDashboard
    }),
    [deselectScreenDashboard]: state => ({
      ...state,
      selectedScreenIdDashboard: undefined
    }),
    [createScreenSuccess]: (state, { payload: screen }: Action<ScreenResponse>) => ({
      ...state,
      screens: {
        ...state.screens,
        [screen.screenId]: screen
      }
    }),
    [updateScreenSuccess]: (state, { payload: screen }: Action<ScreenResponse>) => ({
      ...state,
      screens: {
        ...state.screens,
        [screen.screenId]: {
          ...state.screens[screen.screenId],
          ...screen
        }
      }
    }),
    [updateScreenFromDashboardSuccess]: (state, { payload: screen }: Action<ScreenResponse>) => ({
      ...state,
      screensWithWarnings: {
        ...state.screensWithWarnings,
        [screen.screenId]: {
          ...state.screensWithWarnings[screen.screenId],
          ...screen
        }
      }
    }),
    [listScreens]: state => ({
      ...state,
      listScreensIsLoading: true
    }),
    [listScreensFail]: state => ({
      ...state,
      listScreensIsLoading: false
    }),
    [listScreensSuccess]: (state, { payload: screensArray }: Action<ScreenResponse[]>) => {
      const screens = screensArray.reduce((screens: ScreensIndexedById, screen) => {
        screens[screen.screenId] = {
          ...screen
        }
        return screens
      }, {})
      return {
        ...state,
        listScreensIsLoading: false,
        screens
      }
    },
    [listScreensWithWarnings]: state => ({
      ...state,
      listScreensDashboardIsLoading: true
    }),
    [listScreensWithWarningsFail]: state => ({
      ...state,
      listScreensDashboardIsLoading: false
    }),
    [listScreensWithWarningsSuccess]: (
      state,
      { payload: screensArray }: Action<ScreenResponse[]>
    ) => {
      const screensWithWarnings = screensArray.reduce((screens: ScreensIndexedById, screen) => {
        screens[screen.screenId] = {
          ...screen
        }
        return screens
      }, {})
      return {
        ...state,
        listScreensDashboardIsLoading: false,
        screensWithWarnings
      }
    },
    [getScreenSuccess]: (state, { payload: screen }: Action<ScreenResponse>) => ({
      ...state,
      screens: {
        ...state.screens,
        [screen.screenId]: screen
      }
    }),
    [getScreenFromDashboardSuccess]: (state, { payload: screen }: Action<ScreenResponse>) => ({
      ...state,
      screensWithWarnings: {
        ...state.screensWithWarnings,
        [screen.screenId]: screen
      }
    }),
    [deleteScreenSuccess]: (state, { payload: screenId }: Action<string>) => {
      const screens = { ...state.screens }
      delete screens[screenId]
      return {
        ...state,
        screens,
        selectedScreenIds: []
      }
    },
    [changeScreensViewMode]: (state, { payload: isListMode }: any) => ({
      ...state,
      viewMode: {
        ...state.viewMode,
        isListMode
      }
    }),
    [downloadAdminScreensCsv]: state => ({
      ...state,
      adminScreensCsvLoading: true
    }),
    [downloadAdminScreensCsvFail]: state => ({
      ...state,
      adminScreensCsvLoading: false
    }),
    [downloadAdminScreensCsvSuccess]: state => ({
      ...state,
      adminScreensCsvLoading: false
    }),
    [moveScreenSuccess]: (state, { payload: screenId }: Action<string>) => {
      const screens = { ...state.screens }
      delete screens[screenId]
      return {
        ...state,
        screens,
        selectedScreenIds: []
      }
    },
    [batchUpdateScreensSuccess]: (state, { payload: screens }: Action<Screen[]>) => {
      const stateScreens = { ...state.screens }
      screens.forEach(screen => {
        stateScreens[screen.screenId] = {
          ...stateScreens[screen.screenId],
          ...screen
        }
      })
      return {
        ...state,
        screens: stateScreens,
        selectedScreenIds: []
      }
    },
    [setScreensInitialLoad]: state => ({
      ...state,
      isInitialLoad: false
    })
  },
  initialState
)

export default screens
