import { createAsyncActions, createAction } from './utils'

const reducer = 'ANALYTICS'

const resetAnalytics = createAction(reducer, 'RESET')

const {
  action: queryProofOfPlay,
  actionSuccess: queryProofOfPlaySuccess,
  actionFail: queryProofOfPlayFail
} = createAsyncActions(reducer, 'QUERY_PROOF_OF_PLAY')

const {
  action: getProofOfPlayOptions,
  actionSuccess: getProofOfPlayOptionsSuccess,
  actionFail: getProofOfPlayOptionsFail
} = createAsyncActions(reducer, 'GET_PROOF_OF_PLAY_OPTIONS')

export {
  resetAnalytics,
  queryProofOfPlay,
  queryProofOfPlaySuccess,
  queryProofOfPlayFail,
  getProofOfPlayOptions,
  getProofOfPlayOptionsSuccess,
  getProofOfPlayOptionsFail
}
