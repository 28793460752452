import React from 'react'
import { FormControl, RadioGroup, FormLabel } from '@mui/material'

const ReduxRadioGroup = ({ input, label, ...rest }: any) => (
  <FormControl variant='standard'>
    <FormLabel component='legend'>{label}</FormLabel>
    <RadioGroup {...input} {...rest}></RadioGroup>
  </FormControl>
)

export default ReduxRadioGroup
