export enum PreviewPlayerPostMessageType {
  playIndex = 'playIndex'
}

interface PreviewPlayerPlayIndexData {
  type: PreviewPlayerPostMessageType
  index: number
}

const playPreviewPlayerIndex = (index: number) => {
  const previewPlayer = document.getElementById('previewPlayer') as any
  if (previewPlayer) {
    previewPlayer.contentWindow.postMessage(
      { type: PreviewPlayerPostMessageType.playIndex, index } as PreviewPlayerPlayIndexData,
      '*'
    )
  }
}

export { playPreviewPlayerIndex }
