import React from 'react'
import { Paper, Grid } from '@mui/material'
import { Field } from 'redux-form'
import { formatDecimalsToCurrency, parseCurrencyStringToNumber } from '@seesignage/seesignage-utils'
import { useTranslation } from 'react-i18next'
import { gridItemProps } from '../Fields/utils'
import { ReduxTextField, ReduxSwitch } from '../../../../components/FormInput/ReduxWrappers'

const AdditionalPropsFields = () => {
  const [t] = useTranslation()
  return (
    <Paper style={{ padding: 8 }}>
      <h6>Additional properties (e.g batch or list properties)</h6>
      <Grid container>
        <Grid item xs={12}>
          <Field
            fullWidth
            name='additionalProps.name'
            component={ReduxTextField}
            label={t('general.name')}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            fullWidth
            name='additionalProps.description'
            component={ReduxTextField}
            label={t('products.pharmacy.description')}
          />
        </Grid>
        <Grid {...gridItemProps}>
          <Field
            name='additionalProps.price'
            format={formatDecimalsToCurrency}
            parse={parseCurrencyStringToNumber}
            type='number'
            component={ReduxTextField}
            label={t('products.pharmacy.price')}
            InputProps={{
              endAdornment: '€'
            }}
          />
        </Grid>
        <Grid {...gridItemProps}>
          <Field
            name='additionalProps.discountPrice'
            format={formatDecimalsToCurrency}
            parse={parseCurrencyStringToNumber}
            type='number'
            component={ReduxTextField}
            label={t('products.pharmacy.discountPrice')}
            InputProps={{
              endAdornment: '€'
            }}
            helperText={t('general.optional')}
          />
        </Grid>
        <Grid {...gridItemProps}>
          <Field
            name='additionalProps.discountPercentage'
            type='number'
            component={ReduxTextField}
            label={t('campaigns.editCampaign.discountPercentage')}
          />
        </Grid>
        <Grid {...gridItemProps}>
          <Field
            name='additionalProps.useBatchPrice'
            label={t('campaigns.editCampaign.batchPrice.batchPrice')}
            component={ReduxSwitch}
          />
        </Grid>
        <Grid {...gridItemProps}>
          <Field
            name='additionalProps.useBatchPercentage'
            label={t('campaigns.editCampaign.batchPrice.batchPercentage')}
            component={ReduxSwitch}
          />
        </Grid>
        <Grid {...gridItemProps}>
          <Field
            name='additionalProps.usePackagePrice'
            label={t('campaigns.editCampaign.batchPrice.packagePrice')}
            component={ReduxSwitch}
          />
        </Grid>
        <Grid {...gridItemProps}>
          <Field
            name='additionalProps.showPercentage'
            label={t('campaigns.editCampaign.usePercentage')}
            component={ReduxSwitch}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default AdditionalPropsFields
