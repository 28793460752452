import { createSelector } from 'reselect'
import dateFormat from 'dateformat'
import { Media, AutocompleteOption, MediaType } from '@seesignage/seesignage-utils'
import { compareStrings } from '../utils/sorting'
import { StateInterface } from '../types/states'
import { KeysById } from '../types/media'
import { getFoldersPathArray } from '../utils/media'

const selectMediaDomain = (state: StateInterface) => state.media

const selectListFilesIsLoading = createSelector(
  selectMediaDomain,
  domain => domain.listFilesIsLoading
)

const selectKeys = createSelector(selectMediaDomain, domain => domain.keys)

const selectImages = createSelector(selectMediaDomain, domain =>
  Object.values(domain.keys).reduce((imagesByKey, key) => {
    const keyParts = key.key.split('/')
    if (keyParts[2] === 'images') {
      imagesByKey[key.key] = key
    }
    return imagesByKey
  }, {} as KeysById)
)

const selectVideos = createSelector(selectMediaDomain, domain =>
  Object.values(domain.keys).reduce((videosByKey, key) => {
    const keyParts = key.key.split('/')
    if (keyParts[2] === 'videos') {
      videosByKey[key.key] = key
    }
    return videosByKey
  }, {} as KeysById)
)

const selectKeysAsArray = createSelector(selectKeys, keysById =>
  Object.values(keysById).sort(compareStrings('name'))
)

const selectMediaAsArray = createSelector(selectKeys, keysById => Object.values(keysById))

const selectMediaByTypeAsArray = (mediaType?: MediaType) =>
  createSelector(selectMediaAsArray, allMedia =>
    mediaType ? allMedia.filter(({ type }) => type === mediaType) : allMedia
  )

const selectImagesAsArray = createSelector(selectImages, imagesById => Object.values(imagesById))

const selectVideosAsArray = createSelector(selectVideos, videosById => Object.values(videosById))

const selectKeysAsOptions = createSelector(selectKeysAsArray, keys =>
  keys.map(({ key, name, size, lastModified }) => ({
    value: key,
    label: `${name} (${size ? Math.round(size / 1024) : 0} KB 
      - ${dateFormat(lastModified, 'yyyy-mm-dd HH:MM')})`
  }))
)

const selectMediaByKey = (key: string) => createSelector(selectKeys, keys => keys[key])

const selectMediaNameByKey = (key?: string) =>
  createSelector(selectKeys, keys => (key ? keys[key]?.name : undefined))

const selectSelectedMediaKeys = createSelector(selectMediaDomain, media => media.selectedMediaKeys)

const selectSelectedMedias = createSelector(selectSelectedMediaKeys, selectKeys, (keys, media) =>
  keys && media ? keys.map(key => media[key]) : []
)

const selectPagination = createSelector(selectMediaDomain, domain => domain.pagination)

const selectCurrentPage = createSelector(selectPagination, pagination => pagination.page)

const selectItemsPerPage = createSelector(selectPagination, pagination => pagination.itemsPerPage)

const selectMediaFolders = createSelector(selectMediaDomain, domain => domain.folders)

const selectMediaFolderById = (folderId?: string | null) =>
  createSelector(selectMediaFolders, folders => (folderId ? folders[folderId] : undefined))

const selectKeysByFolder = (folderId?: string) =>
  createSelector(selectKeysAsArray, keys =>
    keys.filter(({ parentFolderId }) => (folderId ? parentFolderId === folderId : !parentFolderId))
  )

const selectMediaByKeys = (keys: string[]) =>
  createSelector(selectKeysAsArray, keysArray => keysArray.filter(({ key }) => keys.includes(key)))

const selectSelectedFolderId = createSelector(selectMediaDomain, domain => domain.selectedFolderId)

const selectSelectedFolder = createSelector(
  selectSelectedFolderId,
  selectMediaFolders,
  (selectedFolderId, folders) => (selectedFolderId ? folders[selectedFolderId] : undefined)
)

const selectSearchTerm = createSelector(selectMediaDomain, domain => domain.searchTerm)

const selectAllMediaFoldersArray = createSelector(selectMediaFolders, folders =>
  Object.values(folders)
)

/**
 * Select all folders as AutocompleteOption
 * - Children of current folder are filtered out
 * - Folder itself is filtered out
 * @param currentFolderId
 */
const selectAllFoldersAsOptions = (currentFolderId?: string) =>
  createSelector(selectMediaFolders, folders => {
    const foldersArr = Object.values(folders)
    return foldersArr.reduce((options: AutocompleteOption[], folder) => {
      const folderPathArr = getFoldersPathArray(folders, folder.folderId)
      const isChildOfCurrent = currentFolderId
        ? folderPathArr.some(({ id }) => id === currentFolderId)
        : false
      if (!isChildOfCurrent) {
        options.push({
          label: folderPathArr.map(({ name }) => name).join(' > '),
          value: folder.folderId
        })
      }
      return options
    }, [])
  })

/**
 * Select media folders under the current selected folder
 */
const selectMediaFoldersArray = (selectedFolderId?: string) =>
  createSelector(selectMediaFolders, folders => {
    const foldersArr = Object.values(folders)
    return selectedFolderId
      ? foldersArr.filter(({ parentFolderId }) => parentFolderId === selectedFolderId)
      : foldersArr.filter(({ parentFolderId }) => !parentFolderId)
  })

const selectKeysBySearchTerm = (searchTerm?: string) =>
  createSelector(selectKeysAsArray, keys =>
    keys && searchTerm
      ? keys.filter(key =>
          key.name
            .toLocaleLowerCase()
            .normalize()
            .includes(searchTerm.toLocaleLowerCase().normalize())
        )
      : []
  )

const selectImagesBySearchTerm = (searchTerm?: string) =>
  createSelector(selectImagesAsArray, keys =>
    keys && searchTerm
      ? keys.filter(
          ({ name }) =>
            name &&
            name
              .toLowerCase()
              .normalize()
              .includes(searchTerm.toLowerCase().normalize())
        )
      : []
  )

/**
 * Boolean value for is given key still transcoding
 * @param key
 */
const selectVideoIsTranscoding = (key: string) =>
  createSelector(selectMediaUploadProgress, media =>
    media[key] && media[key].isTranscoding ? true : false
  )

/**
 * Filter given media array by selected folder and/or searchTerm
 * @param {*} selectedFolder
 * @param {*} searchTerm
 * @param {*} media
 */
interface FilterMediaByTermsParams {
  selectedFolderId?: string | null
  searchTerm?: string
  keys: Media[]
}
/**
 * Filter given media array by selected folder and/or searchTerm
 * @param FilterMediaByTermsParams
 */
const filterMediaByTerms = ({ selectedFolderId, searchTerm, keys }: FilterMediaByTermsParams) => {
  let result
  if (selectedFolderId) {
    const folderMedia = keys.filter(({ parentFolderId }) => parentFolderId === selectedFolderId)
    result = searchTerm
      ? folderMedia.filter(({ name }) =>
          name
            .toLocaleLowerCase()
            .normalize()
            .includes(searchTerm.toLocaleLowerCase().normalize())
        )
      : folderMedia

    return result
  }
  result = searchTerm
    ? keys.filter(({ name }) =>
        name
          .toLocaleLowerCase()
          .normalize()
          .includes(searchTerm.toLocaleLowerCase().normalize())
      )
    : keys.filter(({ parentFolderId }) => !parentFolderId)

  return result
}

const selectMediaUploadProgress = createSelector(selectMediaDomain, domain => domain.uploadProgress)

const selectMediaDeleteSubmitting = createSelector(
  selectMediaDomain,
  domain => domain.mediaIsDeleting
)

const selectStorageUsed = createSelector(selectMediaDomain, domain => domain.storageUsed)

/**
 * Returns true if some videos are transcoding
 */
const selectVideosAreTranscoding = createSelector(selectMediaUploadProgress, media =>
  Object.values(media).some(({ isTranscoding }) => isTranscoding)
)

const selectDropzone = createSelector(selectMediaDomain, domain => domain.dropzone)
const selectDropzoneFieldProps = (formName: string, fieldName: string) =>
  createSelector(selectDropzone, dropzone => dropzone[formName]?.[fieldName] || {})

export {
  selectMediaDomain,
  selectKeys,
  selectKeysAsArray,
  selectMediaByTypeAsArray,
  selectListFilesIsLoading,
  selectKeysAsOptions,
  selectMediaByKey,
  selectSelectedMediaKeys,
  selectSelectedMedias,
  selectMediaAsArray,
  selectImagesAsArray,
  selectVideosAsArray,
  selectCurrentPage,
  selectItemsPerPage,
  selectAllMediaFoldersArray,
  selectAllFoldersAsOptions,
  selectMediaFolders,
  selectMediaFoldersArray,
  selectMediaFolderById,
  selectKeysByFolder,
  selectMediaByKeys,
  selectSelectedFolderId,
  selectSelectedFolder,
  selectSearchTerm,
  selectKeysBySearchTerm,
  selectImagesBySearchTerm,
  selectVideoIsTranscoding,
  filterMediaByTerms,
  selectMediaUploadProgress,
  selectMediaDeleteSubmitting,
  selectStorageUsed,
  selectVideosAreTranscoding,
  selectMediaNameByKey,
  selectDropzone,
  selectDropzoneFieldProps
}
