import { Button, DialogActions, Grid, LinearProgress } from '@mui/material'
import React, { useEffect } from 'react'
import { Field, reduxForm, InjectedFormProps, FieldArray, formValueSelector } from 'redux-form'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { RevolverItemUI } from '@seesignage/seesignage-utils'
import { required, maxItems } from '../../../../validation'
import { ReduxTextField } from '../../../../components/FormInput/ReduxWrappers'
import {
  PageType,
  CreateAddRevolverItemFormData,
  UpdateAddRevolverItemFormData
} from '../../../../types/playlists'
import ErrorMessage from '../../../../components/Errors/ErrorMessage'
import { listFiles, clearSearchMedia, deselectFolder } from '../../../../actions/media'
import { selectIsUserDeviceMobile } from '../../../../selectors/users'
import RevolverItemsField from './RevolverItemsField'

interface OwnProps {
  onSubmit: (formData: any) => void
  selectPage?: (page: PageType) => void
  submitButtonLabel: string
}

type ComponentProps = OwnProps

type AddRevolverItemFormProps = ComponentProps

type FormData = CreateAddRevolverItemFormData | UpdateAddRevolverItemFormData

const AddRevolverItemForm: React.FC<AddRevolverItemFormProps &
  InjectedFormProps<FormData, ComponentProps>> = ({
  handleSubmit,
  submitting,
  onSubmit,
  error,
  selectPage,
  submitButtonLabel
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const handleMountActions = () => {
    dispatch(listFiles())
    dispatch(clearSearchMedia())
    dispatch(deselectFolder())
    return () => {
      // unmount component
      dispatch(clearSearchMedia())
      dispatch(deselectFolder())
    }
  }
  useEffect(handleMountActions, [])

  const revolverItems: RevolverItemUI[] = useSelector(state =>
    formValueSelector('AddRevolverItemForm')(state, 'revolverItems')
  )
  const isMobile = useSelector(selectIsUserDeviceMobile)

  const isSubmitDisabled = submitting || revolverItems.length === 0

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Field
            fullWidth
            name='name'
            label={t('general.name')}
            component={ReduxTextField}
            required
            validate={[required]}
          />
        </Grid>
        <Grid item xs={12}>
          <FieldArray
            name='revolverItems'
            component={RevolverItemsField}
            validate={[maxItems(100)]}
            props={{}}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          {submitting && <LinearProgress />}
          <ErrorMessage message={error} />
        </Grid>
      </Grid>
      <DialogActions style={isMobile ? { justifyContent: 'center' } : undefined}>
        {selectPage && (
          <Button onClick={() => selectPage(PageType.selectType)} color='primary'>
            {t('general.back')}
          </Button>
        )}
        <Button
          disabled={isSubmitDisabled}
          color='primary'
          type='submit'
          id='revolver-submit-button'>
          {submitButtonLabel}
        </Button>
      </DialogActions>
    </form>
  )
}

export default reduxForm<FormData, ComponentProps>({
  form: 'AddRevolverItemForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(AddRevolverItemForm)
