import { Typography } from '@mui/material'
import React, { Fragment } from 'react'

interface ErrorMessageProps {
  message?: string
}

const ErrorMessage = ({ message }: ErrorMessageProps) => (
  <Fragment>
    {message && (
      <Typography style={{ color: 'red' }} variant='body1'>
        {message}
      </Typography>
    )}
  </Fragment>
)

export default ErrorMessage
