import {
  ContentMetadataByKey,
  InfopageContent,
  isMediaInfopageContent
} from '@seesignage/seesignage-utils'
import { InfopageFormContent } from '../types/infopages'

/**
 * Parse form value content to match document type of InfopageContent
 */
const parseFormContent = (content: InfopageFormContent): InfopageContent => {
  const parsedContent: InfopageContent = {}
  for (const [id, value] of Object.entries(content)) {
    if (value && isMediaInfopageContent(value)) {
      const { key, type } = value
      parsedContent[id] = {
        key,
        type
      }
    } else if (value) {
      parsedContent[id] = value
    }
  }
  return parsedContent
}

/**
 * Format InfopageContent to work with form field
 * @param content
 * @param contentMetadata
 */
const formatFormContent = (content: InfopageContent, contentMetadata?: ContentMetadataByKey) => {
  for (const [id, value] of Object.entries(content)) {
    if (isMediaInfopageContent(value) && contentMetadata) {
      const { key } = value
      const metadata = contentMetadata[key]
      if (metadata) {
        content[id] = {
          ...value,
          ...metadata
        }
      }
    }
  }
}

export { parseFormContent, formatFormContent }
