import { createSelector } from 'reselect'
import { StateInterface } from '../types/states'

const selectRoutingDomain = (state: StateInterface) => state.router

const selectLocation = createSelector(selectRoutingDomain, router => router.location)
const selectLocationPathname = createSelector(
  selectLocation,
  location => location.pathname as string
)
const selectLocationSearch = createSelector(selectLocation, location => location.search)
const selectLocationQueryParams = createSelector(
  selectLocation,
  location => (location as any)?.query
)

const selectEnvironmentIdFromPathname = createSelector(selectLocationPathname, pathname => {
  const pathArray = pathname.split('/')
  // make sure that path has environment
  const environmentId = pathArray[1] === 'environments' ? pathArray[2] : undefined
  return environmentId
})

const selectContentIdFromPathname = createSelector(selectLocationPathname, pathname => {
  const pathArray = pathname.split('/')
  return pathArray[4] as string | undefined
})

const selectViewFromPathname = createSelector(selectLocationPathname, pathname => {
  const pathArray = pathname.split('/')
  return pathArray[3] as string | undefined
})

const selectScreenCodeFromPathname = createSelector(selectLocationPathname, pathname => {
  const pathArray = pathname.split('/')
  return pathArray[1] === 's' ? pathArray[2] : undefined
})

const selectFolderIdFromPathname = createSelector(selectLocationPathname, pathname => {
  const pathArray = pathname.split('/')
  return pathArray[4] === 'folders' ? pathArray[5] : undefined
})

/**
 * Select query param value from current path.
 * @example
 * 'https://app.seesignage.com/environments/abcd123/playlists?playlistId=dcba321'
 * selectQueryParamFromSearch('playlistId')
 * // => dcba321
 * @param query query name to get value from
 */
const selectQueryParamFromSearch = (query: string) =>
  createSelector(selectLocationSearch, search => {
    const params = new URLSearchParams(search)
    return params.get(query)
  })

export {
  selectLocationPathname,
  selectEnvironmentIdFromPathname,
  selectContentIdFromPathname,
  selectViewFromPathname,
  selectScreenCodeFromPathname,
  selectQueryParamFromSearch,
  selectFolderIdFromPathname,
  selectLocationQueryParams
}
