import { fabric } from 'fabric'
import {
  CANVAS_CONTAINER_ID,
  isFabricVideoObject,
  isFabricTextboxObject,
  isFabricRectObject,
  isFabricCircleObject,
  isFabricTriangleObject,
  isFabricPolygonObject
} from '@seesignage/seesignage-player-utils/lib'
import { TFunction } from 'i18next'
import { ObjectType, SocialMediaWidgetOrientation } from '@seesignage/seesignage-utils'

const playActiveVideoObject = () => {
  const obj = window.canvas.getActiveObject()
  if (isFabricVideoObject(obj)) {
    obj.setPlay(true)
  }
}

const pauseActiveVideoObject = () => {
  const obj = window.canvas.getActiveObject()
  if (isFabricVideoObject(obj)) {
    obj.setPlay(false)
  }
}

const reorderCustomWidgets = (obj: fabric.Object, startIndex: number, endIndex: number) => {
  const canvasContainer = document.getElementById(CANVAS_CONTAINER_ID)
  const { widgetContainer } = obj as any

  if (canvasContainer && canvasContainer.children && widgetContainer) {
    const movingBackwards = endIndex > startIndex

    const referenceNode = movingBackwards
      ? canvasContainer.children[canvasContainer.children.length - endIndex - 1]
      : canvasContainer.children[canvasContainer.children.length - endIndex]

    canvasContainer.insertBefore(widgetContainer, referenceNode)
  }
}

/**
 * check if object supports shadow
 */
const isShadowAbleObject = (selectedObject?: fabric.Object) =>
  isFabricTextboxObject(selectedObject) ||
  isFabricRectObject(selectedObject) ||
  isFabricCircleObject(selectedObject) ||
  isFabricTriangleObject(selectedObject) ||
  isFabricPolygonObject(selectedObject)

/**
 * this limit is because all devices doesn't support playling multiple videos as the same time
 */
const isMediaWidgetDisabled = (
  type: ObjectType,
  numberOfLimitedMediaWidgets: number,
  t: TFunction
) => {
  if (type === ObjectType.CustomMediaCarousel && numberOfLimitedMediaWidgets >= 5) {
    // media carousel widget can have 4 videos playing at the same time
    return { isDisabled: true, tooltip: t('error.content.tooManyLimitedObjectsMediaCarousel') }
  } else if (
    (type === ObjectType.socialMedia || type === ObjectType.CustomVideo) &&
    numberOfLimitedMediaWidgets >= 3
  ) {
    // socialMedia and video widdget can have 3 videos playing at the same time
    return { isDisabled: true, tooltip: t('error.content.tooManyLimitedObjects') }
  }
  return { isDisabled: false, tooltip: undefined }
}

const isProFeature = (type: ObjectType) =>
  [
    ObjectType.CustomDate,
    ObjectType.CustomIframe,
    ObjectType.CustomQRCode,
    ObjectType.CustomRssFeed,
    ObjectType.CustomVideo,
    ObjectType.socialMedia,
    ObjectType.CustomTable,
    ObjectType.CustomSocialMedia,
    ObjectType.CustomMediaCarousel,
    ObjectType.CustomWeather,
    ObjectType.CustomPolygon
  ].includes(type)

const getSocialMediaDimensions = (orientation: SocialMediaWidgetOrientation) => {
  switch (orientation) {
    case SocialMediaWidgetOrientation.horizontal:
      return [600, 400]
    case SocialMediaWidgetOrientation.vertical:
    default:
      return [400, 600]
  }
}

export {
  getSocialMediaDimensions,
  isShadowAbleObject,
  isProFeature,
  isMediaWidgetDisabled,
  reorderCustomWidgets,
  pauseActiveVideoObject,
  playActiveVideoObject
}
