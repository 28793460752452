import { Box, Button, ButtonProps } from '@mui/material'
import { GridToolbarContainer } from '@mui/x-data-grid'
import { CountPlaysByAttribute, TimeInterval } from '@seesignage/seesignage-utils'
import DownloadIcon from 'mdi-react/DownloadIcon'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generateProofOfPlayCsv } from '../../../../../utils/csv'
import { selectProofOfPlayItems, selectProofOfPlayQuery } from '../../../../../selectors/analytics'

const buttonBaseProps: ButtonProps = {
  color: 'primary',
  size: 'small',
  startIcon: <DownloadIcon />
}

const DownloadByPlaylistIdButton = ({ interval }: { interval: TimeInterval }) => {
  const [t] = useTranslation()
  const items = useSelector(selectProofOfPlayItems)
  return (
    <Fragment>
      {items && (
        <Button
          {...buttonBaseProps}
          onClick={() =>
            generateProofOfPlayCsv(items as any, CountPlaysByAttribute.playlist_id, interval)
          }>
          {t('analytics.proofOfPlay.downloadCsv')}
        </Button>
      )}
    </Fragment>
  )
}

const ProofOfPlayTableToolbar = () => {
  const query = useSelector(selectProofOfPlayQuery)
  return (
    <GridToolbarContainer>
      <Box sx={{ flexGrow: 1 }} />
      {query?.countPlaysByAttribute === CountPlaysByAttribute.playlist_id && (
        <DownloadByPlaylistIdButton interval={query.interval} />
      )}
    </GridToolbarContainer>
  )
}

export default ProofOfPlayTableToolbar
