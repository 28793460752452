import {
  Button,
  CardActionArea,
  CardActions,
  DialogActions,
  DialogContent,
  DialogContentText,
  Card,
  CardContent,
  Tooltip,
  Typography,
  IconButton,
  CardHeader
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import DeleteIcon from 'mdi-react/DeleteIcon'
import SettingsIcon from 'mdi-react/SettingsIcon'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import ContentCopyIcon from 'mdi-react/ContentCopyIcon'
import { useSelector } from 'react-redux'
import { TFunction } from 'i18next'
import {
  ListType,
  ListUI,
  isRetailList,
  isMiscList,
  isLunchList
} from '@seesignage/seesignage-utils'
import FishIcon from 'mdi-react/FishIcon'
import FoodSteakIcon from 'mdi-react/FoodSteakIcon'
import ShoppingIcon from 'mdi-react/ShoppingIcon'
import SilverwareForkKnifeIcon from 'mdi-react/SilverwareForkKnifeIcon'
import { selectCustomerById } from '../../selectors/customers'
import Dialog from '../Dialog'
import { Navigate, CloseDialog, NavigateToLunchList } from '../../types/actions'
import { selectListCustomerById } from '../../selectors/lists'
import { StateInterface } from '../../types/states'
import { StateTemplate } from '../../types/templates'
import CreateListForm from './Forms/CreateListForm'

const styles = {
  card: {
    width: 250,
    height: 225,
    display: 'inline-block',
    verticalAlign: 'middle',
    margin: '0px 5px 15px'
  },
  mainArea: {
    height: 165,
    overflow: 'auto',
    scrollBehavior: 'smooth' as 'smooth'
  },
  selectArea: {
    padding: '8px 16px',
    cursor: 'pointer',
    userSelect: 'none' as 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    '-webkit-user-select': 'none',
    height: 75
  },
  actions: {
    justifyContent: 'end'
  },
  titleText: {
    width: '100%',
    padding: '4px',
    float: 'left' as 'left',
    textAlign: 'left' as 'left',
    fontSize: '1.25rem'
  }
}

const useStyles = makeStyles(styles)

const CardAvatar = {
  fish: <FishIcon />,
  meat: <FoodSteakIcon />,
  retail: <ShoppingIcon />,
  lunch: <SilverwareForkKnifeIcon />
}

interface HandleClickParams {
  navigate: Navigate
  navigateToLunchList: NavigateToLunchList
  type: string
  environmentId: string
  listId: string
}

const handleClick = ({
  navigate,
  navigateToLunchList,
  type,
  environmentId,
  listId
}: HandleClickParams) => {
  if (type === ListType.retail) {
    navigate(`/environments/${environmentId}/lists/${listId}`)
  } else if (type === ListType.lunch) {
    navigateToLunchList({
      environmentId,
      listId
    })
  } else if (type === ListType.fish || type === ListType.meat) {
    navigate(`/environments/${environmentId}/miscLists/${listId}`)
  }
}

interface ListContainsTextParams {
  list: ListUI
  t: TFunction
}

const listContainsText = ({ list, t }: ListContainsTextParams) => {
  if (isRetailList(list) || isMiscList(list)) {
    const count = list.items.length
    return count ? t('lists.listContains', { count }) : t('lists.listEmpty')
  }
}

interface ListCardProps {
  list: ListUI
  navigate: Navigate
  navigateToLunchList: NavigateToLunchList
  updateList: (formData: any) => void
  deleteList: (listId: string) => void
  copyList: (listId: string) => void
  closeDialog: CloseDialog
  template: StateTemplate
  environmentIdFromPath: string
}

const ListCard: React.FC<ListCardProps> = ({
  environmentIdFromPath,
  list,
  navigate,
  navigateToLunchList,
  updateList,
  deleteList,
  closeDialog,
  copyList,
  template
}) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const { listId, environmentId: listEnvironmentId, name, type } = list
  const customer = useSelector((state: StateInterface) =>
    selectCustomerById(selectListCustomerById(listId)(state))(state)
  )
  return (
    <Card className={classes.card}>
      <Tooltip disableInteractive placement='bottom' title={t('lists.open')}>
        <CardActionArea
          className={classes.mainArea}
          onClick={() =>
            handleClick({
              navigate,
              navigateToLunchList,
              type,
              environmentId: listEnvironmentId,
              listId
            })
          }>
          <CardHeader title={name} avatar={CardAvatar[type]} />
          <CardContent className={classes.selectArea}>
            <Typography variant='caption' display='block'>
              {customer && customer.name}
            </Typography>
            {template && (
              <Typography variant='caption' display='block'>
                {t('lists.templateName', { name: template?.name })}
              </Typography>
            )}
            <Typography variant='caption' display='block'>
              {listContainsText({ list, t })}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Tooltip>
      {// only list owner environment can edit list
      listEnvironmentId === environmentIdFromPath && (
        <CardActions className={classes.actions}>
          <Tooltip disableInteractive title={t('lists.copy')}>
            <IconButton onClick={() => copyList(list.listId)} size='large'>
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
          <Dialog
            dialogId={`UpdateListDialog.${listId}`}
            title={`${t('lists.edit')} (${name})`}
            ButtonIcon={SettingsIcon}
            tooltipTitle={t('lists.edit')}
            Content={
              <CreateListForm
                dialogId={`UpdateListDialog.${listId}`}
                submitButtonLabel={t('general.update')}
                submitAction={updateList}
                closeDialog={closeDialog}
                isUpdateForm
                initialValues={{
                  name,
                  type,
                  customerId: customer
                    ? { value: customer.customerId, label: customer.name }
                    : undefined,
                  template: template
                    ? {
                        templateId: template.templateId,
                        templateEnvironmentId: template.environmentId
                      }
                    : undefined,
                  listId,
                  startDate: isLunchList(list) ? list.startDate : undefined,
                  price: isLunchList(list) ? list.price : undefined
                }}
              />
            }
          />
          <Dialog
            dialogId={`removeListDialog.${listId}`}
            title={`${t('lists.removeList')} (${name})`}
            ButtonIcon={DeleteIcon}
            tooltipTitle={t('lists.removeList')}
            Content={
              <Fragment>
                <DialogContent>
                  <DialogContentText>{t('lists.deletionConfirmation')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => closeDialog(`removeListDialog.${listId}`)} color='primary'>
                    {t('general.cancel')}
                  </Button>
                  <Button onClick={() => deleteList(listId)} color='primary' autoFocus>
                    {t('general.delete')}
                  </Button>
                </DialogActions>
              </Fragment>
            }
          />
        </CardActions>
      )}
    </Card>
  )
}

export default ListCard
