import React from 'react'
import { Field } from 'redux-form'
import { Grid } from '@mui/material'
import { TFunction } from 'i18next'
import { ReduxTextField } from '../../../../../../components/FormInput/ReduxWrappers'
import { parseStringToIntNumber } from '../../../../../../utils/forms'

interface PositionfieldsProps {
  namePrefix: string
  isFieldsDisabled: boolean
  t: TFunction
}
/**
 * CSS clip property. Note: these values must be converted converted to "clip: rect(<top>, <right>, <bottom>, <left>)" format.
 */
const Poistionfields: React.FC<PositionfieldsProps> = ({ t, namePrefix, isFieldsDisabled }) => (
  <Grid container>
    <Grid item xs={6}>
      <Field
        fullWidth
        name={`${namePrefix}.top`}
        label={t('contents.properties.spacingTop')}
        component={ReduxTextField}
        type='number'
        parse={parseStringToIntNumber}
        isToolbar
        disabled={isFieldsDisabled}
      />
    </Grid>
    <Grid item xs={6}>
      <Field
        fullWidth
        name={`${namePrefix}.bottom`}
        label={t('contents.properties.spacingBottom')}
        component={ReduxTextField}
        type='number'
        size='small'
        parse={parseStringToIntNumber}
        isToolbar
        disabled={isFieldsDisabled}
      />
    </Grid>
    <Grid item xs={6}>
      <Field
        fullWidth
        name={`${namePrefix}.left`}
        label={t('contents.properties.spacingLeft')}
        component={ReduxTextField}
        type='number'
        size='small'
        parse={parseStringToIntNumber}
        isToolbar
        disabled={isFieldsDisabled}
      />
    </Grid>
    <Grid item xs={6}>
      <Field
        fullWidth
        name={`${namePrefix}.right`}
        label={t('contents.properties.spacingRight')}
        component={ReduxTextField}
        type='number'
        size='small'
        parse={parseStringToIntNumber}
        isToolbar
        disabled={isFieldsDisabled}
      />
    </Grid>
  </Grid>
)

export default Poistionfields
