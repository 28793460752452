import { Chip, Paper, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getPriorityOptions } from '../../../utils/channels'

const priorities = getPriorityOptions()

const styles = {
  legendContainer: {
    padding: 6,
    maxWidth: 300,
    margin: 'auto',
    textAlign: 'center' as 'center'
  },
  priorityChip: {
    borderRadius: '5px',
    color: '#fff',
    marginRight: 4
  }
}

const useStyles = makeStyles(styles)

const CalendarLegend = () => {
  const classes = useStyles()
  const [t] = useTranslation()
  return (
    <Paper className={classes.legendContainer}>
      <Typography variant='subtitle1'>{t('channels.calendar.legendTitle')}</Typography>
      {priorities.map(({ label, color }) => (
        <Chip
          className={classes.priorityChip}
          key={label}
          size='small'
          label={t(`channels.forms.priorities.${label}`)}
          style={{ backgroundColor: color }}
        />
      ))}
    </Paper>
  )
}

export default CalendarLegend
