import React from 'react'
import { TFunction } from 'i18next'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import classNames from 'classnames'
import { ScreenStatus } from '@seesignage/seesignage-utils'
import colors from '../../../styles/common/colors'

const styles = () => ({
  status: {
    padding: 8,
    color: '#ffffff'
  },
  onlineStatus: {
    backgroundColor: colors.screenStatuses.onlineBg,
    color: colors.screenStatuses.onlineText
  },
  offlineStatus: {
    backgroundColor: colors.screenStatuses.offlineBg,
    color: colors.screenStatuses.offlineText
  },
  notLinkedStatus: {
    backgroundColor: colors.screenStatuses.notLinkedBg,
    color: colors.screenStatuses.notLinkedText
  },
  standbyStatus: {
    backgroundColor: colors.screenStatuses.standbyBg,
    color: colors.screenStatuses.standbyText
  }
})
interface LastContactDateProps extends WithStyles<typeof styles> {
  t: TFunction
  status: ScreenStatus
}

const ScreenCardStatus: React.FC<LastContactDateProps> = ({ status, t, classes }) => {
  return (
    <div
      className={classNames({
        [classes.status]: true,
        [classes.onlineStatus]: status === ScreenStatus.online,
        [classes.offlineStatus]: status === ScreenStatus.offline,
        [classes.notLinkedStatus]: status === ScreenStatus.notLinked,
        [classes.standbyStatus]: status === ScreenStatus.standby
      })}>
      {t(`screens.status.${status}`)}
    </div>
  )
}

export default withStyles(styles)(ScreenCardStatus)
