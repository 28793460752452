import {
  Grid,
  Theme,
  Typography,
  FormGroup,
  FormLabel,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, reduxForm, InjectedFormProps, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import {
  AutocompleteOption,
  EnvironmentScreenFeatures,
  DocumentPermissionLevel
} from '@seesignage/seesignage-utils'
import DialogActions from '../../../components/DialogActions'
import ErrorMessage from '../../../components/Errors/ErrorMessage'
import { CloseDialog, CreateChannel, UpdateChannel } from '../../../types/actions'
import { required, defaultInterval } from '../../../validation'
import {
  ReduxTextField,
  ReduxCreatableAutocomplete,
  ReduxSwitch,
  ReduxCheckbox
} from '../../../components/FormInput/ReduxWrappers'
import { StateInterface } from '../../../types/states'
import {
  selectCurrentEnvironmentTagsAsOptions,
  selectCurrentEnvironmentScreenFeatures,
  selectCurrentEnvironmentPermissionTagsAsOptions
} from '../../../selectors/environments'
import { isUserSystemAdmin, isUserAdmin } from '../../../selectors/users'
import { CreateChannelFormData, UpdateChannelFormData } from '../../../types/channels'
import { formatNumberFieldAndRoundBy1Decimal } from '../../../utils/forms'

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    marginBottom: theme.spacing()
  },
  selectProductContainer: {
    marginTop: theme.spacing(2)
  },
  permissionsFields: {
    marginLeft: theme.spacing(2)
  }
}))

interface OwnProps {
  onSubmit: CreateChannel | UpdateChannel
  closeDialog: CloseDialog
  submitButtonLabel: string
  dialogId: string
  isUpdate?: boolean
}

interface StateProps {
  tagsAsOptions: AutocompleteOption[]
  permissionTagsAsOptions: AutocompleteOption[]
  environmentScreenFeatures?: EnvironmentScreenFeatures
  isSystemAdmin: boolean
  /** system admin or environment admin */
  isAdmin: boolean
  usePermissions?: boolean
}

type CreateChannelFormProps = OwnProps & StateProps

const CreateChannelForm: React.FC<CreateChannelFormProps &
  InjectedFormProps<CreateChannelFormData | UpdateChannelFormData, CreateChannelFormProps>> = ({
  handleSubmit,
  onSubmit,
  submitting,
  closeDialog,
  dialogId,
  error,
  isUpdate,
  tagsAsOptions,
  permissionTagsAsOptions,
  environmentScreenFeatures,
  isSystemAdmin,
  isAdmin,
  usePermissions
}) => {
  const classes = useStyles()
  const [t] = useTranslation()
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} className={classes.formContainer}>
        <Grid item xs={12}>
          <Field
            fullWidth
            name='name'
            label={t('general.name')}
            component={ReduxTextField}
            required
            validate={[required]}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            fullWidth
            variant='outlined'
            name='defaultPlaylistInterval'
            label={t('playlists.forms.defaultInterval')}
            parse={formatNumberFieldAndRoundBy1Decimal}
            helperText={t('channels.forms.playlistDefaultIntervalHelper')}
            InputProps={{
              endAdornment: <InputAdornment position='end'>s</InputAdornment>
            }}
            type='number'
            component={ReduxTextField}
            validate={[defaultInterval]}
          />
        </Grid>
        <Grid item xs={12}>
          <div>
            <Field
              id='tags'
              name='tags'
              placeholder={t('channels.forms.tags')}
              component={ReduxCreatableAutocomplete}
              label={t('channels.forms.tags')}
              options={tagsAsOptions}
              multiple
            />
            <Typography variant='caption'>{t('channels.forms.tagsHelper')}</Typography>
          </div>
        </Grid>
        {isAdmin && (
          <Grid item xs={12}>
            <Field
              name='permissionTags'
              options={permissionTagsAsOptions}
              component={ReduxCreatableAutocomplete}
              label={t('screens.permissionTags')}
              multiple
            />
            <Typography variant='caption'>{t('channels.forms.permissionTagHelper')}</Typography>
          </Grid>
        )}
        {isSystemAdmin && (
          <Fragment>
            <Grid item xs={12}>
              <Field
                name='permissions.usePermissions'
                component={ReduxSwitch}
                label={t('channels.forms.usePermissions')}
              />
            </Grid>
            {usePermissions && (
              <Grid item xs={12}>
                <FormControl variant='standard' component='fieldset'>
                  <FormLabel component='legend'>
                    {t('channels.forms.documentPermissionsUser')}
                  </FormLabel>
                  <FormGroup className={classes.permissionsFields}>
                    {Object.values(DocumentPermissionLevel).map(level => (
                      <FormControlLabel
                        key={`lists.${level}`}
                        label={level}
                        control={
                          <Field
                            key={`permissions.user.${level}`}
                            name={`permissions.user.${level}`}
                            component={ReduxCheckbox}
                            color='primary'
                          />
                        }
                      />
                    ))}
                  </FormGroup>
                  <FormHelperText>{t('channels.forms.documentPermissionsHelper')}</FormHelperText>
                </FormControl>
              </Grid>
            )}
          </Fragment>
        )}
        {environmentScreenFeatures?.syncPlay && (
          <Grid item xs={12}>
            <Field
              name='syncPlay'
              component={ReduxSwitch}
              label={t('channels.forms.syncPlay')}
              disabled={isUpdate}
            />
            <Typography display='block' variant='caption'>
              {t('channels.forms.syncPlayHelper')}
            </Typography>
          </Grid>
        )}
      </Grid>
      <ErrorMessage message={error} />
      <DialogActions
        submitButtonLabel={t('general.save')}
        submitting={submitting}
        closeDialog={closeDialog}
        dialogId={dialogId}
      />
    </form>
  )
}

const selector = formValueSelector('CreateChannelForm')

const mapStateToProps = (state: StateInterface): StateProps => ({
  tagsAsOptions: selectCurrentEnvironmentTagsAsOptions(state),
  environmentScreenFeatures: selectCurrentEnvironmentScreenFeatures(state),
  permissionTagsAsOptions: selectCurrentEnvironmentPermissionTagsAsOptions(state),
  isAdmin: isUserAdmin(state),
  isSystemAdmin: isUserSystemAdmin(state),
  usePermissions: selector(state, 'permissions.usePermissions')
})

export default connect<StateProps, null, OwnProps, StateInterface>(mapStateToProps)(
  reduxForm<CreateChannelFormData | UpdateChannelFormData, CreateChannelFormProps>({
    form: 'CreateChannelForm'
  })(CreateChannelForm)
)
