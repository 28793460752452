import React, { Fragment } from 'react'

interface MediaProps {
  imageClass: string
  videoClass: string
  type: 'image' | 'video'
  url?: string | null
  thumbnailUrl?: string
}

const Media: React.FC<MediaProps> = ({ imageClass, videoClass, type, url, thumbnailUrl }) => (
  <Fragment>
    {type === 'image' && url && <img className={imageClass} alt='' src={thumbnailUrl || url} />}
    {type === 'video' && url && (
      <video
        className={videoClass}
        preload={thumbnailUrl ? 'none' : 'auto'}
        controls
        poster={thumbnailUrl}
        src={url}
      />
    )}
  </Fragment>
)

export default Media
