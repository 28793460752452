import React, { Fragment } from 'react'
import { Field, WrappedFieldArrayProps } from 'redux-form'
import {
  ComponentRuleOperator,
  ProductProperty,
  RetailProductProperty,
  AdditionalPlayerItemProperty,
  LogicalOperator
} from '@seesignage/seesignage-utils'
import { IconButton, Grid, MenuItem, Tooltip } from '@mui/material'
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon'
import DeleteIcon from 'mdi-react/DeleteIcon'
import { TFunction } from 'i18next'
import CloseIcon from 'mdi-react/CloseIcon'
import { required } from '../../../../../validation'
import { compareArrayOfStrings } from '../../../../../utils/sorting'
import { SortDirection } from '../../../../../types/sortings'
import { ReduxTextField } from '../../../../../components/FormInput/ReduxWrappers'

const productProperties = Object.keys(ProductProperty)
const additionalProperties = Object.keys(AdditionalPlayerItemProperty)
const allProperties = [...new Set([...productProperties, ...additionalProperties])].sort(
  compareArrayOfStrings(SortDirection.asc)
)
const operators = Object.keys(ComponentRuleOperator)

const willRenderValueField = (operator: ComponentRuleOperator) => {
  switch (operator) {
    case ComponentRuleOperator.exists:
    case ComponentRuleOperator.notExists:
    case ComponentRuleOperator.true:
    case ComponentRuleOperator.false:
      return false
    default:
      return true
  }
}

/**
 * Get additional props for ReduxTextField based on property type.
 * If property value is supposed to be a number, return number props, otherwise undefined
 * @param property
 */
const getTextFieldValueProps = (property?: RetailProductProperty) => {
  switch (property) {
    case RetailProductProperty.deposit:
    case RetailProductProperty.discountBatchSize:
    case RetailProductProperty.discountPrice:
    case RetailProductProperty.packageSize:
    case RetailProductProperty.price:
    case RetailProductProperty.scaleNumber:
    case RetailProductProperty.unitPrice:
      return {
        type: 'number',
        parse: Number,
        InputProps: {
          inputProps: { min: 0, max: 10000, step: 0.01 }
        }
      }
    default:
      return undefined
  }
}

interface RenderConditionFieldsProps extends WrappedFieldArrayProps {
  getOperatorValue: (fieldName: string) => ComponentRuleOperator | undefined
  getPropertyValue: (fieldName: string) => RetailProductProperty | undefined
  conditionValue: LogicalOperator | undefined
  removeConditions: () => void
  t: TFunction
  isCustomProps: boolean
}

/**
 * ConditionFields component for FieldArray
 */
const ConditionFields = ({
  fields,
  conditionValue,
  getOperatorValue,
  getPropertyValue,
  removeConditions,
  t,
  isCustomProps
}: RenderConditionFieldsProps) => (
  <Fragment>
    <Grid container item>
      <Grid item>
        <Tooltip disableInteractive title={t('templates.editSchemaComponentForm.addRule')}>
          <span>
            <IconButton disabled={!conditionValue} onClick={() => fields.push({})} size='large'>
              <PlusCircleOutlineIcon />
            </IconButton>
          </span>
        </Tooltip>
        {conditionValue && (
          <Tooltip
            disableInteractive
            title={t('templates.editSchemaComponentForm.deleteConditions')}>
            <IconButton onClick={() => removeConditions()} size='large'>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        )}
      </Grid>
    </Grid>
    {conditionValue &&
      fields.map((field, index) => {
        const propertyValue = getPropertyValue(`${field}.property`)
        const operatorValue = getOperatorValue(`${field}.operator`)
        const textFieldProps = getTextFieldValueProps(propertyValue)
        return (
          <Grid key={`conditions-${field}-${index}`} container spacing={1}>
            <Grid item xs={4}>
              {isCustomProps ? (
                <Field
                  fullWidth
                  name={`${field}.property`}
                  label='property'
                  required
                  validate={[required]}
                  component={ReduxTextField}
                />
              ) : (
                <Field
                  name={`${field}.property`}
                  label='property'
                  component={ReduxTextField}
                  select
                  required
                  validate={[required]}
                  fullWidth>
                  {allProperties.map(property => (
                    <MenuItem key={property} value={property}>
                      {property}
                    </MenuItem>
                  ))}
                </Field>
              )}
            </Grid>
            <Grid item xs={3}>
              <Field
                name={`${field}.operator`}
                label='operator'
                component={ReduxTextField}
                required
                validate={[required]}
                select
                fullWidth>
                {operators.map(operator => (
                  <MenuItem key={operator} value={operator}>
                    {t(`templates.editSchemaComponentForm.operators.${operator}`)}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            {operatorValue && willRenderValueField(operatorValue) && (
              <Grid item xs={3}>
                <Field
                  name={`${field}.value`}
                  label='value'
                  component={ReduxTextField}
                  required
                  validate={[required]}
                  fullWidth
                  {...textFieldProps}
                />
              </Grid>
            )}
            <Grid item xs={2}>
              <IconButton
                onClick={() => fields.remove(index)}
                aria-label={'delete'}
                style={{ float: 'right' }}
                size='large'>
                <DeleteIcon color='default' />
              </IconButton>
            </Grid>
          </Grid>
        )
      })}
  </Fragment>
)

export default ConditionFields
