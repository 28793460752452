import React, { Fragment } from 'react'
import { WrappedFieldArrayProps } from 'redux-form'
import { TFunction } from 'i18next'
import { TableField } from '@seesignage/seesignage-utils'
import TableFieldStyles from './TableFieldStyles'

interface TableFieldsProps extends WrappedFieldArrayProps<TableField> {
  t: TFunction
  isFieldsDisabled: boolean
}

const TableFields: React.FC<TableFieldsProps> = ({ fields, t, isFieldsDisabled }) => {
  return (
    <Fragment>
      {fields.map((item, index) => {
        const props = {
          rowNumber: index + 1,
          remove: () => fields.remove(index),
          item,
          t,
          isFieldsDisabled
        }
        return <TableFieldStyles key={`${item}.${index}`} {...props} />
      })}
    </Fragment>
  )
}

export default TableFields
