import { formValueSelector } from 'redux-form'
import { FabricLine, FabricPath, FabricPolygon } from '@seesignage/seesignage-player-utils/lib'
import nanoid from 'nanoid'
import { fabric } from 'fabric'
import {
  CommonFabricObjectCustomOptions,
  FabricPolygonCustomOptions
} from '@seesignage/seesignage-utils'
import { EditorCursorMode } from '../../types/contents'
import { setContentModified, updateEditorOption } from '../../actions/contents'
import { getStore } from '../../configureStore'
import PenCursorIcon from '../../images/PenCursorIcon.png'
import PencilCursorIcon from '../../images/PencilCursorIcon.png'
import { addFabricObject } from './canvas'
// import { FabricPolygon } from './CustomObjects/PolygonObject'

const freeDrawingUtils = () => {
  const end = (e: any) => {
    const { path } = e
    if (path) {
      // add custom id from each drawing path
      const newPath = new FabricPath(
        (path as fabric.Path).path,
        { id: nanoid(), version: '1.0' },
        { ...path }
      )
      // remove created path
      window.canvas.remove(path)
      // Add custom path
      addFabricObject(newPath)
    }
  }

  return {
    end
  }
}

const drawLineUtils = (canvas: fabric.Canvas) => {
  let activeLine: fabric.Line | null = null
  const store = getStore()
  const tempCanvas = window.temporayDrawingCanvas

  const draw = (e: any) => {
    if (activeLine) {
      const pointer = canvas.getPointer(e.e)
      activeLine.set({ x2: pointer.x, y2: pointer.y })
      tempCanvas.renderAll()
    }
  }

  const start = (e: fabric.IEvent<MouseEvent>) => {
    const { absolutePointer } = e
    if (!absolutePointer) {
      return
    }

    const stroke =
      formValueSelector('LineDrawingSettingsForm')(store.getState(), 'color') || '#000000'
    const strokeWidth =
      formValueSelector('LineDrawingSettingsForm')(store.getState(), 'width') || 10
    const { x, y } = absolutePointer
    activeLine = new fabric.Line([x, y, x, y], {
      strokeWidth,
      stroke
    })
    if (activeLine) {
      tempCanvas.add(activeLine).requestRenderAll()
    }
  }

  const end = () => {
    if (!activeLine) {
      return
    }
    const { x1 = 0, y1 = 0, x2 = 0, y2 = 0, stroke = '#000000', strokeWidth = 10 } = activeLine
    // if line is very short, likely it was misclick on canvas
    if (!distanceWithinThreshhold(new fabric.Point(x1, y1), new fabric.Point(x2, y2), 5)) {
      const newLine = new FabricLine(
        [x1, y1, x2, y2],
        { id: nanoid(), version: '1.0' } as CommonFabricObjectCustomOptions,
        { stroke, strokeWidth }
      )
      addFabricObject(newLine)
    }
    tempCanvas.clear().requestRenderAll()
    activeLine = null
  }

  return {
    start,
    draw,
    end
  }
}

const distanceWithinThreshhold = (p1: fabric.Point, p2: fabric.Point, threshold: number) => {
  const a = p1.x - p2.x
  const b = p1.y - p2.y

  const c = Math.sqrt(a * a + b * b)
  return c < threshold
}

const drawPolygonUtils = (canvas: fabric.Canvas) => {
  let activeShape: fabric.Polygon | null = null,
    activeLine: fabric.Line | null = null,
    pointArray: fabric.Point[] = [],
    stroke = '#000000',
    strokeWidth = 5,
    startCircle: fabric.Circle | null = null,
    fill = 'rgba(155, 155, 155, 1)'

  const tempCanvas = window.temporayDrawingCanvas

  const end = () => {
    if (activeShape) {
      const { stroke = '#000000', strokeWidth = 5, fill = 'rgba(155, 155, 155, 1)' } = activeShape
      const newPolygon = new FabricPolygon(
        pointArray,
        {
          id: nanoid(),
          version: '1.0',
          pointEditMode: false
        } as FabricPolygonCustomOptions,
        {
          stroke,
          strokeWidth,
          fill,
          uniformStroke: true
        } as fabric.IPolylineOptions
      )

      addFabricObject(newPolygon)
      canvas.renderAll()
    }
    tempCanvas.clear()
    activeLine = null
    activeShape = null
    pointArray = []
    stroke = '#000000'
    strokeWidth = 5
    startCircle = null
    fill = 'rgba(155, 155, 155, 1)'
  }

  const canEnd = (point: fabric.Point) =>
    pointArray.length > 2 && distanceWithinThreshhold(pointArray[0], point, 10)

  const addPoint = (opt: fabric.IEvent<MouseEvent>) => {
    const { absolutePointer } = opt

    if (!absolutePointer) {
      return
    }

    if (canEnd(absolutePointer)) {
      end()
      return
    }

    const { x, y } = absolutePointer
    const circle = new fabric.Circle({
      radius: 10,
      fill: '#ffffff',
      stroke: 'black',
      strokeWidth: 2,
      left: x,
      top: y,
      selectable: false,
      hasBorders: false,
      hasControls: false,
      originX: 'center',
      originY: 'center',
      hoverCursor: 'pointer'
    })
    const points = [x, y, x, y]
    const line = new fabric.Line(points, {
      strokeWidth,
      fill,
      stroke,
      originX: 'center',
      originY: 'center',
      selectable: false,
      hasBorders: false,
      hasControls: false,
      evented: false
    })

    pointArray.push(absolutePointer)
    // const shapePoints = [...(activeShape?.points || []), absolutePointer]
    const polygon = new fabric.Polygon(pointArray, {
      strokeWidth,
      fill,
      stroke,
      opacity: 0.1,
      selectable: false,
      hasBorders: false,
      hasControls: false,
      evented: false
    })
    if (!activeShape) {
      // Add first point
      circle.set({
        fill: 'red'
      })
      startCircle = circle
    } else {
      tempCanvas.remove(activeShape)
    }
    activeShape = polygon
    tempCanvas.add(polygon)
    activeLine = line
    tempCanvas.add(line)
    tempCanvas.add(circle)
    tempCanvas.renderAll()
  }

  const draw = (event: fabric.IEvent<MouseEvent>) => {
    const { absolutePointer } = event

    if (!activeLine || !activeShape || !absolutePointer) {
      return
    }

    if (canEnd(absolutePointer)) {
      startCircle?.setRadius(15)
    } else {
      startCircle?.setRadius(10)
    }

    activeLine.set({ x2: absolutePointer.x, y2: absolutePointer.y })
    if (pointArray.length > 0) {
      const points = [...pointArray]
      points[points.length] = absolutePointer
      activeShape.set({
        points
      })
    }
    tempCanvas.requestRenderAll()
  }

  return {
    addPoint,
    draw
  }
}

const isAtStartingPoint = (point: any, polygon: any) => {
  // how close you want to define the starting point
  const threshold = 8

  const startingPoint = polygon.points[0]
  const { x: startingX, y: startingY } = startingPoint
  return Math.hypot(startingY - point.y, startingX - point.x) < threshold
}

const setEditorCursorModeUtil = (cursorMode: EditorCursorMode) => {
  // Reset state
  window.canvas.defaultCursor = 'default'
  window.canvas.hoverCursor = 'move'
  window.canvas.selection = true
  window.canvas.isDrawingMode = false
  const [workarea, ...canvasObjects] = window.canvas.getObjects()
  workarea.hoverCursor = 'default'
  canvasObjects.forEach(obj => {
    obj.selectable = true
    obj.evented = true
  })

  const store = getStore()

  if (cursorMode === EditorCursorMode.freeDrawing) {
    // set defaults for free drawing
    window.canvas.freeDrawingBrush.width = 30
    window.canvas.freeDrawingBrush.color = '#000000'
    window.canvas.isDrawingMode = true
    window.canvas.selection = false
    window.canvas.freeDrawingCursor = `url(${PencilCursorIcon}) -10 30, auto`
    const [workarea, ...canvasObjects] = window.canvas.getObjects()
    workarea.hoverCursor = `url(${PenCursorIcon}) -10 30, auto`
    canvasObjects.forEach(obj => {
      obj.selectable = false
      obj.evented = false
    })
  } else if (
    cursorMode === EditorCursorMode.drawingPolygon ||
    cursorMode === EditorCursorMode.drawingLine
  ) {
    window.canvas.hoverCursor = `url(${PenCursorIcon}) -10 30, auto`
    window.canvas.defaultCursor = `url(${PenCursorIcon}) -10 30, auto`
    window.canvas.selection = false
    const [workarea, ...canvasObjects] = window.canvas.getObjects()
    workarea.hoverCursor = `url(${PenCursorIcon}) -10 30, auto`
    canvasObjects.forEach(obj => {
      obj.selectable = false
      obj.evented = false
    })
  } else if (cursorMode === EditorCursorMode.grab) {
    window.canvas.defaultCursor = 'grab'
    window.canvas.selection = false
    const [workarea, ...canvasObjects] = window.canvas.getObjects()
    workarea.hoverCursor = 'grab'
    canvasObjects.forEach(obj => {
      obj.selectable = false
      obj.evented = false
    })
    store.dispatch(updateEditorOption({ dragActive: false }))
  } else {
    store.dispatch(setContentModified())
  }

  window.canvas.requestRenderAll()
}

const isDrawingMode = (editorCursorMode?: EditorCursorMode) =>
  editorCursorMode &&
  [
    EditorCursorMode.drawingPolygon,
    EditorCursorMode.drawingLine,
    EditorCursorMode.freeDrawing
  ].includes(editorCursorMode)

export {
  drawLineUtils,
  drawPolygonUtils,
  freeDrawingUtils,
  isAtStartingPoint,
  setEditorCursorModeUtil,
  isDrawingMode
}
