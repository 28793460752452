import moment, { Moment } from 'moment'

const oneDay = 1000 * 60 * 60 * 24 // milliseconds in one day

const getCurrentDay = (timeMs: number): string => {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const dIndex = new Date(timeMs).getDay()
  return days[dIndex]
}

const getCurrentMsByTime = (currentTimeMs: number, dateTimeString: string): number => {
  const currentDateTime = new Date(currentTimeMs)
  const d = new Date(dateTimeString)
  const hours = d.getHours()
  const minutes = d.getMinutes()
  currentDateTime.setHours(hours)
  currentDateTime.setMinutes(minutes)
  return currentDateTime.getTime()
}

const getCurrentDateString = (format: string) => {
  if (format === 'MM-DD-YYYY') {
    const now = new Date()
    const years = now.getFullYear()
    const months = now.getMonth() + 1
    const days = now.getDate()
    return `${months}-${days}-${years}`
  }
  return new Date().toISOString()
}

/**
 * Returns an array of string dates between start and end dates in provided format.
 * @param startDate
 * @param endDate
 * @param format e.g MM-DD-YYYY
 */
const getDatesBetweenRange = (startDate: Moment, endDate: Moment, format: string) => {
  const arr: string[] = []
  let currentDate = startDate
  while (currentDate <= endDate) {
    arr.push(moment(currentDate).format(format))
    currentDate = moment(currentDate).add(1, 'days')
  }
  return arr
}

/** Format milliseconds into minutes and seconds. HH:MM */
const millisToMinutesAndSeconds = (millis: number) => {
  const minutes = Math.floor(millis / 60000)
  const seconds = parseInt(((millis % 60000) / 1000).toFixed(0))
  return minutes + ':' + (seconds < 10 ? '0' : '') + seconds
}

const getDateStringWithGivenTime = (isoDateString?: string, isoDateStringWithTime?: string) => {
  if (isoDateString && isoDateStringWithTime) {
    const dateTemp = new Date(isoDateString)
    const dateTimeTemp = new Date(isoDateStringWithTime)
    dateTemp.setHours(dateTimeTemp.getHours(), dateTimeTemp.getMinutes(), 0, 0)
    return dateTemp.toISOString()
  }
}

const getDaysBetweenDates = (date1: Date, date2: Date) =>
  Math.ceil(Math.abs(date2.getTime() - date1.getTime()) / oneDay)

const getStartOfTheDayDate = (startDate = new Date()) => {
  startDate.setHours(0, 0, 0, 0)
  return startDate
}

const getEndOfTheDayDate = (endDate = new Date()) => {
  endDate.setHours(23, 59, 59, 0)
  return endDate
}

export {
  getCurrentDay,
  getCurrentMsByTime,
  getCurrentDateString,
  getDatesBetweenRange,
  millisToMinutesAndSeconds,
  getDateStringWithGivenTime,
  getDaysBetweenDates,
  getStartOfTheDayDate,
  getEndOfTheDayDate
}
