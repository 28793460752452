import {
  AppBar,
  Theme,
  Toolbar,
  Tooltip,
  IconButton,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button
} from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import React, { Fragment } from 'react'
import DeleteIcon from 'mdi-react/DeleteIcon'
import CloseIcon from 'mdi-react/CloseIcon'
import PencilIcon from 'mdi-react/PencilIcon'
import { TFunction } from 'i18next'
import {
  LunchGroupById,
  LunchGroup,
  LunchProduct,
  LunchList,
  LunchItem,
  LunchListWeek,
  LunchProductById
} from '@seesignage/seesignage-utils'
import { SelectedLunchItem, SelectedLunchItemType } from '../../../../types/lists'
import {
  CloseDialog,
  DeleteLunchListItem,
  CreateOrUpdateLunchListGroup,
  CreateOrUpdateLunchListProduct,
  SetSelectedLunchItem,
  PublishLunchList,
  AddLunchListProductChanges
} from '../../../../types/actions'
import Dialog from '../../../Dialog'
import CreateLunchGroupForm from '../../Forms/CreateLunchGroupForm'
import CreateLunchProductForm from '../../Forms/CreateLunchProductForm'
import Spacer from '../../../../components/Spacer/Spacer'
import AddLunchProductChangesForm from '../../Forms/AddLunchProductChangesForm'
import {
  selectAddLunchProductChangesFormInitialValues,
  selectUpdateLunchListProductFormInitialValues
} from '../../../../utils/lunchLists'
import { toolbarStyles } from '../../../../styles/common'

const styles = (theme: Theme) => ({
  ...toolbarStyles(theme),
  publishButton: {
    width: 150,
    marginLeft: 8
  },
  previewButton: {
    marginLeft: 4
  }
})

export interface LunchListToolbarProps {
  createOrUpdateLunchListGroup: CreateOrUpdateLunchListGroup
  createOrUpdateLunchListProduct: CreateOrUpdateLunchListProduct
  addLunchListProductChanges: AddLunchListProductChanges
  deleteLunchListItem: DeleteLunchListItem
  publishLunchList: PublishLunchList
  closeDialog: CloseDialog
  setSelectedItem: SetSelectedLunchItem
  selectedItem?: SelectedLunchItem
  groups: LunchGroupById
  t: TFunction
  isSubList: boolean
  hasSubEnvironments: boolean
  list: LunchList
  weeks?: LunchListWeek[]
  products: LunchProductById
  togglePreview: () => void
}

type OwnProps = LunchListToolbarProps & WithStyles<typeof styles>

const LunchListToolbar = ({
  classes,
  t,
  deleteLunchListItem,
  publishLunchList,
  createOrUpdateLunchListGroup,
  createOrUpdateLunchListProduct,
  addLunchListProductChanges,
  closeDialog,
  setSelectedItem,
  selectedItem,
  groups,
  hasSubEnvironments,
  weeks,
  list: { published, name },
  isSubList,
  products,
  togglePreview
}: OwnProps) => {
  const showPublish = hasSubEnvironments
  let showDeleteLunchItemButton = false
  let showUpdateLunchItemButton = false
  let showLunchProductButtons = false
  let showLunchGroupButtons = false

  if (selectedItem) {
    if (selectedItem.type === SelectedLunchItemType.lunchItem) {
      showDeleteLunchItemButton = !(selectedItem.item as LunchItem).isParentItem
      // check if lunch item's product is parent product, if so disable editing product
      showUpdateLunchItemButton = !products[(selectedItem.item as LunchItem).productId]
        .isParentProduct
    } else if (selectedItem.type === SelectedLunchItemType.product) {
      showLunchProductButtons = !(selectedItem.item as LunchProduct).isParentProduct
    } else if (selectedItem.type === SelectedLunchItemType.group) {
      showLunchGroupButtons = !(selectedItem.item as LunchGroup).isParentGroup
    }
  }

  return (
    <AppBar className={classes.appBar} position='sticky' color='default'>
      <Toolbar>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          {selectedItem && (
            <Fragment>
              {showDeleteLunchItemButton && (
                // deleting lunch item from week does not need confirm dialog
                <Tooltip disableInteractive title={t('lists.lunch.edit.deleteItem')}>
                  <IconButton onClick={() => deleteLunchListItem(selectedItem)} size='large'>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
              {(showLunchProductButtons || showLunchGroupButtons) && (
                // confirm dialog for deleting group or product
                <Dialog
                  dialogId='DeleteLunchItemDialog'
                  title={t('lists.lunch.edit.deleteItem')}
                  ButtonIcon={DeleteIcon}
                  tooltipTitle={t('lists.lunch.edit.deleteItem')}
                  Content={
                    <Fragment>
                      <DialogContent>
                        <DialogContentText>
                          {t(
                            `lists.lunch.edit.${
                              selectedItem.type === 'group'
                                ? 'deleteGroupInfo'
                                : 'deleteProductInfo'
                            }`,
                            {
                              name: (selectedItem.item as any).name
                            }
                          )}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => closeDialog('DeleteLunchItemDialog')}
                          color='primary'>
                          {t('general.disagree')}
                        </Button>
                        <Button
                          onClick={() => deleteLunchListItem(selectedItem)}
                          color='primary'
                          autoFocus>
                          {t('general.agree')}
                        </Button>
                      </DialogActions>
                    </Fragment>
                  }
                />
              )}
              {showLunchGroupButtons && (
                <Dialog
                  dialogId='updateLunchListGroupDialog'
                  title={`${t('lists.lunch.edit.updateGroup')} (${
                    (selectedItem.item as LunchGroup).name
                  })`}
                  ButtonIcon={PencilIcon}
                  tooltipTitle={t('lists.lunch.edit.updateGroup')}
                  Content={
                    <CreateLunchGroupForm
                      dialogId='updateLunchListGroupDialog'
                      submitAction={createOrUpdateLunchListGroup}
                      closeDialog={closeDialog}
                      isUpdate
                      initialValues={{
                        name: (selectedItem.item as LunchGroup).name,
                        price: (selectedItem.item as LunchGroup).price,
                        // add groupId so handleCreateOrUpdateLunchListGroup knows to update
                        groupId: (selectedItem.item as LunchGroup).groupId
                      }}
                    />
                  }
                />
              )}

              {showLunchProductButtons && (
                <Dialog
                  dialogId='updateLunchListProductDialog'
                  title={`${t('lists.lunch.edit.updateProduct')} (${
                    (selectedItem.item as LunchProduct).name
                  })`}
                  ButtonIcon={PencilIcon}
                  tooltipTitle={t('lists.lunch.edit.updateProduct')}
                  Content={
                    <CreateLunchProductForm
                      dialogId='updateLunchListProductDialog'
                      submitAction={createOrUpdateLunchListProduct}
                      closeDialog={closeDialog}
                      groups={groups}
                      isUpdate
                      initialValues={selectUpdateLunchListProductFormInitialValues(
                        selectedItem.item as LunchProduct
                      )}
                    />
                  }
                />
              )}

              {showUpdateLunchItemButton && (
                <Dialog
                  dialogId='updateLunchListProductDialog'
                  title={`${t('lists.lunch.edit.updateProduct')} (${
                    products[(selectedItem.item as LunchItem).productId].name
                  })`}
                  ButtonIcon={PencilIcon}
                  tooltipTitle={t('lists.lunch.edit.updateProduct')}
                  Content={
                    <CreateLunchProductForm
                      dialogId='updateLunchListProductDialog'
                      submitAction={createOrUpdateLunchListProduct}
                      closeDialog={closeDialog}
                      groups={groups}
                      isUpdate
                      initialValues={selectUpdateLunchListProductFormInitialValues(
                        products[(selectedItem.item as LunchItem).productId]
                      )}
                    />
                  }
                />
              )}
              {// sub list can add changes to parent's lunch item
              isSubList &&
                selectedItem.type === SelectedLunchItemType.lunchItem &&
                (selectedItem.item as LunchItem).isParentItem &&
                weeks && (
                  <Dialog
                    dialogId='addLunchProductChangesFormDialog'
                    title={t('lists.lunch.edit.addLunchProductChanges')}
                    ButtonIcon={PencilIcon}
                    tooltipTitle={t('lists.lunch.edit.addLunchProductChanges')}
                    Content={
                      <AddLunchProductChangesForm
                        dialogId='addLunchProductChangesFormDialog'
                        submitAction={addLunchListProductChanges}
                        closeDialog={closeDialog}
                        products={products}
                        initialValues={selectAddLunchProductChangesFormInitialValues(
                          selectedItem,
                          weeks
                        )}
                      />
                    }
                  />
                )}
              <Tooltip disableInteractive title={t('general.deselect')}>
                <IconButton onClick={() => setSelectedItem(undefined)} size='large'>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              {!isSubList && <Spacer />}
            </Fragment>
          )}
        </div>

        {showPublish && (
          <Dialog
            dialogId='publishLunchListDialog'
            title={t(`lists.lunch.edit.${published ? 'unpublish' : 'publish'}`)}
            buttonLabel={t(`lists.lunch.edit.${published ? 'unpublish' : 'publish'}`)}
            color={published ? 'secondary' : 'primary'}
            buttonClassName={classes.publishButton}
            Content={
              <Fragment>
                <DialogContent>
                  <DialogContentText>
                    {t(`lists.lunch.edit.${published ? 'unpublish' : 'publish'}Info`, { name })}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => closeDialog('publishLunchListDialog')} color='primary'>
                    {t('general.disagree')}
                  </Button>
                  <Button onClick={() => publishLunchList()} color='primary' autoFocus>
                    {t('general.agree')}
                  </Button>
                </DialogActions>
              </Fragment>
            }
          />
        )}
        <Button
          className={classes.previewButton}
          variant='contained'
          onClick={togglePreview}
          color='primary'>
          {t('lists.lunch.preview.title')}
        </Button>
      </Toolbar>
    </AppBar>
  )
}

export default withStyles(styles)(LunchListToolbar)
