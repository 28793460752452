import {
  AutocompleteOption,
  ContentResolution,
  CreateContent,
  Content,
  StopProps,
  RssFeedProps,
  IframeProps,
  TableProps,
  WeatherProps,
  DateProps,
  FabricWidgetObjectFit,
  ObjectType,
  QRCodeProps,
  MediaPlaylistItemPlayer,
  StoredCanvasForUI,
  VideoProps,
  SocialMediaProps,
  CarouselItemType,
  CarouselItemPlayer,
  Resolutions,
  ContentPlaylistItemPlayer,
  SocialMediaItemsConfig,
  SocialMediaProfile,
  SocialMediaItem
} from '@seesignage/seesignage-utils'
import { fabric } from 'fabric'

export interface EditorOptions {
  zoom?: number
  resolution?: ContentResolution
  cursorMode?: EditorCursorMode
  dragActive?: boolean
}

export interface ContentsEditor {
  /**
   * Canvas used to represent layers views.
   *
   * */
  canvas?: StoredCanvasForUI
  options: EditorOptions
  contentId?: string
  selectedObjects?: fabric.Object[]
  initDone: boolean
}

export enum ZoomType {
  in = 'ZOOM_IN',
  out = 'ZOOM_OUT',
  reset = 'ZOOM_RESET',
  fit = 'ZOOM_FIT'
}

export enum EditorCursorMode {
  grab = 'grab',
  move = 'move',
  freeDrawing = 'freeDrawing',
  drawingLine = 'drawingLine',
  drawingPolygon = 'drawingPolygon'
}

export enum CanvasActionType {
  clear = 'clear',
  clearBackground = 'clearBackground'
}

export enum SelectedObjectActionType {
  delete = 'delete',
  clone = 'clone',
  forward = 'forward',
  backward = 'backward',
  horizontalCenter = 'horizontalCenter',
  verticalCenter = 'verticalCenter',
  // play video
  play = 'play',
  // pause video
  pause = 'pause',
  // stop video
  stop = 'stop'
}

/** Content FORMS */

export interface ContentTextBoxFormData {
  fontSize: { value?: number; label: string }
  fontFamily?: string
  textAlign?: string
  underline?: boolean
  linethrough?: boolean
  fontWeight?: string | number
  fontStyle?: string
  fill?: string | any
  left?: number
  top?: number
  lineHeight?: number
  charSpacing?: number
  angle?: number
  scaleX?: number
  scaleY?: number
  lockMovementX?: boolean
  lockMovementY?: boolean
}

export type CreateInfopageContentFormData = Omit<CreateContent, 'resolution'> & {
  /** use this to add resolution in sagas to request */
  selectedResolution: Resolutions
  width?: number
  height?: number
}

export interface UpdateContentFormData extends CreateInfopageContentFormData {
  contentId: string
}

export interface CanvasBackgroundFormData {
  backgroundColor?: string
}

interface CommonMovementFields {
  lockMovementX?: boolean
  lockMovementY?: boolean
}

export interface CommonContentFields extends CommonMovementFields {
  left?: number
  top?: number
  angle?: number
  scaleX?: number
  scaleY?: number
}

export interface CommonPolygonFields extends CommonContentFields {
  width?: number
  height?: number
}

interface CommonShapeFields extends CommonPolygonFields {
  fill?: string | any
}

export interface ContentCircleFormData extends CommonContentFields {
  fill?: string | any
  radius?: number
}
export type ContentTriangleFormData = CommonShapeFields
export interface ContentPathFormData extends CommonPolygonFields {
  stroke?: string
}

export interface ContentLineFormData extends ContentPathFormData {
  stroke?: string
  strokeWidth?: number
}

export type ContentRectangleFormData = CommonShapeFields

export interface ContentVideoFormData extends CommonContentFields {
  cVideoProps: VideoProps
}

export type ContentImageFormData = CommonPolygonFields

export interface ContentIframeFormData extends CommonPolygonFields {
  cIframeProps?: IframeProps
}

export interface StopPropsFormData extends Omit<StopProps, 'numberOfDepartures'> {
  numberOfDepartures?: { label: string; value: number }
}

export interface ContentStopScheduleFormData extends CommonPolygonFields {
  cStopProps?: StopPropsFormData
}

export interface ContentRssFeedFormData extends CommonPolygonFields {
  cRssFeedProps?: RssFeedProps
}

export interface ContentTableFormData extends CommonPolygonFields {
  cTableProps?: TableProps
}

export interface WeatherPropsFormData extends Omit<WeatherProps, 'location'> {
  location?: AutocompleteOption
}

export interface ContentWeatherWidgetFormData extends CommonPolygonFields {
  cWeatherProps?: WeatherPropsFormData
}

export interface ContentSocialMediaWidgetToolbarFormData extends CommonPolygonFields {
  contentResolution: Resolutions
}

export interface ContentSocialMediaWidgetWizardFormData extends CommonPolygonFields {
  cSocialMediaProps?: {
    mediaConfig?: SocialMediaItemsConfig
    mediaProfile?: SocialMediaProfile
    mediaItems?: SocialMediaItem[]
  }
}
export type ContentSocialMediaWidgetSettingsFormData = Omit<
  SocialMediaProps,
  'mediaConfig' | 'mediaProfile' | 'mediaItems'
>

export interface ContentQRCodeWidgetFormData extends CommonPolygonFields {
  QRCodeScale?: number
  cQRCodeProps?: QRCodeProps
}
export interface ContentWeatherWidgetFormDataFinal extends CommonPolygonFields {
  cWeatherProps?: WeatherProps
}

export interface ContentDateWidgetFormData extends CommonPolygonFields {
  cDateProps?: DateProps
}

export interface ContentMediaCarouselToolbarFormData extends CommonPolygonFields {
  contentResolution: Resolutions
}

export interface ContentMediaCarouselSettingsFormData {
  background?: string
  videosMuted?: boolean
  interval?: number
  objectFit?: FabricWidgetObjectFit
}
export interface MediaCarouselMediaWizardFormData {
  carouselItems: MediaCarouselRevolver[]
}

export interface MediaCarouselMediaFormDataTransFormed
  extends Omit<MediaCarouselMediaWizardFormData, 'carouselItems'> {
  carouselItems: CarouselItemPlayer[]
}

export enum MediaCarouselWidgetDrawerSettings {
  widgetBackground = 'background',
  slideShowInterval = 'interval',
  videosMuted = 'videosMuted',
  widgetObjectFit = 'objectFit'
}

export interface MediaCarouselPlaylistWizardFormData {
  playlistId: AutocompleteOption
  /**
   * Manually added in MediaCarouselForm when selected playlist changes.
   * environmentId needed because playlist might be inherited from parent environment.
   *  */
  environmentId: string
  /**
   * Manually added in MediaCarouselForm when selected playlist changes.
   *  */
  defaultInterval: number
  carouselItems: (MediaPlaylistItemPlayer | ContentPlaylistItemPlayer)[]
}

export type MediaCarouselWizardFormData =
  | MediaCarouselMediaWizardFormData
  | MediaCarouselPlaylistWizardFormData
  | MediaCarouselMediaFormDataTransFormed

interface CommonMediaCarouselRevolverItem {
  id: string
  url: string
  name: string
}

export interface MediaCarouselRevolverMediaItem extends CommonMediaCarouselRevolverItem {
  size?: number
  type: CarouselItemType.video | CarouselItemType.image
}

export interface MediaCarouselRevolverInfopageItem extends CommonMediaCarouselRevolverItem {
  type: CarouselItemType.content
  environmentId: string
}

export type MediaCarouselRevolver =
  | MediaCarouselRevolverInfopageItem
  | MediaCarouselRevolverMediaItem

export interface CopyContentToEnvironmentsFormData {
  contentId: string
  environments: AutocompleteOption[]
}

export interface AddMediaContentFormData {
  /** selected media key */
  key: string
}

export enum ContentEditorDialog {
  AddMediaContentFormDialog = 'AddMediaContentFormDialog',
  SetBackgroundImageFormDialog = 'SetBackgroundImageFormDialog',
  MediaCarouselDialog = 'MediaCarouselDialog',
  AddSocialMediaDialog = 'AddSocialMediaDialog',
  LimitedFeatures = 'LimitedFeatures'
}

export enum DrawerViews {
  layers = 'layers',
  settings = 'settings'
}

/** Typeguard to identify Content type of object */
export const isContent = (item: any): item is Content => item?.contentId

export enum SocialMediaWizardPage {
  selectPlatform = 'selectPlatform',
  selectAccount = 'selectAccount',
  selectContent = 'selectContent',
  previewWidget = 'previewWidget'
}

export interface CreateObjectActionPayload {
  type: ObjectType
  props?: SocialMediaProps | MediaCarouselWizardFormData
}

export enum MediaCarouselWizardView {
  selectType = 'selectType',
  playlist = 'playlist',
  media = 'media'
}

export type UpdateSocialMediaProps = Omit<
  SocialMediaProps,
  | 'background'
  | 'showCaption'
  | 'showHeader'
  | 'slideShowInterval'
  | 'videosMuted'
  | 'showContentOnlyIfPostExists'
  | 'objectFit'
>

export enum SocialMediaWidgetSettings {
  widgetBackground = 'background',
  widgetObjectFit = 'objectFit',
  showCaption = 'showCaption',
  showHeader = 'showHeader',
  slideShowInterval = 'slideShowInterval',
  videosMuted = 'videosMuted',
  widgetAspectRatio = 'aspectRatio',
  showContentOnlyIfPostExists = 'showContentOnlyIfPostExists'
}

export const isMediaCarouselPlaylistFormData = (
  item: any
): item is MediaCarouselPlaylistWizardFormData => typeof item?.playlistId?.value !== 'undefined'

export const isMediaCarouselMediaFormData = (item: any): item is MediaCarouselMediaWizardFormData =>
  typeof item?.playlistId?.value === 'undefined'

export const isMediaCarouselMediaFormDataTransformed = (
  item: any
): item is MediaCarouselMediaFormDataTransFormed => typeof item?.playlistId?.value === 'undefined'

export const isMediaCarouselRevolverItem = (item: any): item is MediaCarouselRevolver => item?.id
export const isMediaCarouselRevolverMediaItem = (
  item: any
): item is MediaCarouselRevolverMediaItem =>
  item?.type === CarouselItemType.video || item?.type === CarouselItemType.image

export const isMediaCarouselRevolverInfopageItem = (
  item: any
): item is MediaCarouselRevolverInfopageItem => item?.type === CarouselItemType.content
