import { Grid, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TFunction } from 'i18next'
import ImageIcon from 'mdi-react/ImageIcon'
import ImageOffIcon from 'mdi-react/ImageOffOutlineIcon'
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon'
import { selectEditorCursorMode, selectSelectedObjects } from '../../../../selectors/contents'
import { selectedObjectAction, canvasAction } from '../../../../actions/contents'
import { OpenContentEditorDialog } from '../../../../types/dialogs'
import { CanvasActionType, ContentEditorDialog, EditorCursorMode } from '../../../../types/contents'
import { generateBackgroundInitialValues } from '../../../../utils/fabric/canvasFormValues'
import CanvasBackgroundForm from './ContentToolbarForms/CanvasBackgroundForm'
import DrawingSettings from './CustomContentToolbar/DrawingSettings'
import ContentToolbarItem from './ContentToolbarItem/ContentToolbarItem'
import SelectedObjectActions from './CustomContentToolbar/SelectedObjectActions'

const useStyles = makeStyles({
  toolBarArea: {
    minHeight: '110px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    background: 'rgba(0, 0, 0, 0.04)',
    padding: '3px 6px'
  }
})

interface ContentToolBarProps {
  openDialog: OpenContentEditorDialog
  t: TFunction
  isSystemAdmin: boolean
  editorLoaded: boolean
}

const ContentToolBar = ({ openDialog, isSystemAdmin, t, editorLoaded }: ContentToolBarProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const dispatchSelectedObjectAction = (type: string) => dispatch(selectedObjectAction(type))
  const dispatchCanvasAction = (type: string) => dispatch(canvasAction(type))

  const selectedObjects = useSelector(selectSelectedObjects)
  const editorCursorMode = useSelector(selectEditorCursorMode)

  return (
    <Grid container className={classes.toolBarArea}>
      {editorLoaded && editorCursorMode && editorCursorMode !== EditorCursorMode.grab && (
        <>
          {!selectedObjects && editorCursorMode === EditorCursorMode.move && (
            <>
              <ContentToolbarItem>
                <CanvasBackgroundForm t={t} initialValues={generateBackgroundInitialValues()} />
              </ContentToolbarItem>
              <ContentToolbarItem tooltip={t('contents.actions.selectBackgroundImage')}>
                <IconButton
                  onClick={() =>
                    openDialog({ dialogId: ContentEditorDialog.SetBackgroundImageFormDialog })
                  }
                  size='small'>
                  <ImageIcon />
                </IconButton>
              </ContentToolbarItem>
              <ContentToolbarItem tooltip={t('contents.actions.clearBackground')}>
                <IconButton
                  onClick={() => dispatchCanvasAction(CanvasActionType.clearBackground)}
                  size='small'>
                  <ImageOffIcon />
                </IconButton>
              </ContentToolbarItem>
              <ContentToolbarItem tooltip={t('contents.actions.clear')}>
                <IconButton
                  onClick={() => dispatchCanvasAction(CanvasActionType.clear)}
                  size='small'>
                  <TrashCanOutlineIcon />
                </IconButton>
              </ContentToolbarItem>
            </>
          )}
          <DrawingSettings dispatch={dispatch} t={t} editorCursorMode={editorCursorMode} />
          {selectedObjects && editorCursorMode === EditorCursorMode.move && (
            <SelectedObjectActions
              t={t}
              openDialog={openDialog}
              selectedObjects={selectedObjects}
              selectedObjectAction={dispatchSelectedObjectAction}
              isSystemAdmin={isSystemAdmin}
            />
          )}
        </>
      )}
    </Grid>
  )
}

export default ContentToolBar
