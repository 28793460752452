import React from 'react'
import { useTranslation } from 'react-i18next'
import { Field } from 'redux-form'
import { ReduxCheckbox } from '../../../../../../components/FormInput/ReduxWrappers'

interface AvailableProps {
  channelTitle: boolean
  channelDescription: boolean
  itemTitle: boolean
  itemDescription: boolean
  itemImage: boolean
  itemPubDate: boolean
}
interface VisiblePropsSelectorProps {
  availableProps: AvailableProps
}

const VisiblePropsSelector: React.FC<VisiblePropsSelectorProps> = ({ availableProps }) => {
  const [t] = useTranslation()
  return (
    <div>
      <div>
        {availableProps?.channelTitle && (
          <Field
            name='channelTitle'
            component={ReduxCheckbox}
            label={t('contents.widgets.rssFeed.channelTitle')}
          />
        )}
        {availableProps?.channelDescription && (
          <Field
            name='channelDescription'
            label={t('contents.widgets.rssFeed.channelDesc')}
            component={ReduxCheckbox}
          />
        )}
        {availableProps?.itemTitle && (
          <Field
            name='itemTitle'
            component={ReduxCheckbox}
            label={t('contents.widgets.rssFeed.articleTitle')}
          />
        )}
        {availableProps?.itemDescription && (
          <Field
            name='itemDescription'
            component={ReduxCheckbox}
            label={t('contents.widgets.rssFeed.articleDesc')}
          />
        )}
        {availableProps?.itemImage && (
          <Field
            name='itemImage'
            component={ReduxCheckbox}
            label={t('contents.widgets.rssFeed.articleImage')}
          />
        )}
        {availableProps?.itemPubDate && (
          <Field
            name='itemPubDate'
            component={ReduxCheckbox}
            label={t('contents.widgets.rssFeed.articlePubDate')}
          />
        )}
      </div>
    </div>
  )
}

export default VisiblePropsSelector
