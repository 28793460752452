const getMenuOpenState = (): boolean => {
  const value = localStorage.getItem('menuOpen')
  return value === 'false' ? false : true
}

const setMenuOpenState = (open: boolean) => {
  localStorage.setItem('menuOpen', open === true ? 'true' : 'false')
}

export { getMenuOpenState, setMenuOpenState }
