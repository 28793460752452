import { MenuItem } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Prompt } from 'react-router'
import { Field, FieldArray, InjectedFormProps, isDirty, reduxForm } from 'redux-form'
import { ListType } from '@seesignage/seesignage-utils'
import { saveMiscList } from '../../../../actions/lists'
import ErrorMessage from '../../../../components/Errors/ErrorMessage'
import { bindSubmitActionToPromise } from '../../../../utils/forms'
import { required } from '../../../../validation'
import {
  ReduxCreatableAutocomplete,
  ReduxTextField
} from '../../../../components/FormInput/ReduxWrappers'
import { getFishLatinNamesAsOptions } from '../../../../utils/autofill/fish'
import MiscListInputFields from './MiscListInputFields'

const getFishFields = (t: any) => [
  {
    id: 'name',
    label: t('lists.fish.name')
  },
  {
    id: 'latinName',
    label: t('lists.fish.latinName')
  },
  {
    id: 'origin',
    label: t('lists.fish.catchOrigin')
  },
  {
    id: 'catchArea',
    label: t('lists.fish.catchArea')
  },
  {
    id: 'catchDate',
    label: t('lists.fish.catchDate')
  },
  {
    id: 'catchType',
    label: t('lists.fish.catchType')
  },
  {
    id: 'productionMethod',
    label: t('lists.fish.productionMethod')
  }
]

const getProductionTypes = (t: any) => [
  {
    value: '',
    label: t('general.empty')
  },
  {
    value: 'farmed',
    label: t('lists.fish.productionMethods.farmed')
  },
  {
    value: 'catched',
    label: t('lists.fish.productionMethods.catched')
  }
]

const getCatchTypes = (t: any) => [
  {
    value: '',
    label: t('general.empty')
  },
  {
    value: 'hook',
    label: t('lists.fish.catchTypes.hook')
  },
  {
    value: 'drag',
    label: t('lists.fish.catchTypes.drag')
  },
  {
    value: 'trap',
    label: t('lists.fish.catchTypes.trap')
  },
  {
    value: 'seine',
    label: t('lists.fish.catchTypes.seine')
  },
  {
    value: 'blockade',
    label: t('lists.fish.catchTypes.blockade')
  },
  {
    value: 'trawl',
    label: t('lists.fish.catchTypes.trawl')
  },
  {
    value: 'net',
    label: t('lists.fish.catchTypes.net')
  },
  {
    value: 'fyke',
    label: t('lists.fish.catchTypes.fyke')
  }
]

const getMeatFields = (t: any) => [
  {
    id: 'name',
    label: t('lists.meat.name')
  },
  {
    id: 'origin',
    label: t('lists.meat.origin')
  },
  {
    id: 'batchNumber',
    label: t('lists.meat.batchNumber')
  },
  {
    id: 'slaughterId',
    label: t('lists.meat.slaughterId')
  },
  {
    id: 'cutId',
    label: t('lists.meat.cutId')
  }
]

const useStyles = makeStyles(() => ({
  formContainer: {
    paddingBottom: 30
  }
}))

const renderFishInputField = (index: number, t: any) =>
  getFishFields(t).map(({ id, label }) => {
    switch (id) {
      case 'catchType':
        return (
          <Field
            style={{ width: '150px', padding: 2 }}
            key={`items[${index}].product.${id}`}
            name={`items[${index}].product.${id}`}
            label={label}
            select
            component={ReduxTextField}>
            {getCatchTypes(t).map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Field>
        )
      case 'productionMethod':
        return (
          <Field
            style={{ width: '160px', padding: 2 }}
            key={`items[${index}].product.${id}`}
            name={`items[${index}].product.${id}`}
            label={label}
            select
            component={ReduxTextField}>
            {getProductionTypes(t).map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Field>
        )
      case 'latinName':
        return (
          <Field
            style={{ padding: 2, width: '230px', display: 'inline-block' }}
            key={`items[${index}].product.${id}`}
            name={`items[${index}].product.${id}`}
            label={label}
            variant='standard'
            options={getFishLatinNamesAsOptions()}
            component={ReduxCreatableAutocomplete}
            useInputValue
          />
        )
      default:
        const isNameField = id === 'name'
        return (
          <Field
            style={{ padding: 2 }}
            key={`items[${index}].product.${id}`}
            name={`items[${index}].product.${id}`}
            label={label}
            component={ReduxTextField}
            required={isNameField}
            validate={isNameField ? required : undefined}
            isCapitalized={isNameField}
          />
        )
    }
  })

const renderMeatInputField = (index: number, t: any) =>
  getMeatFields(t).map(({ id, label }) => {
    const isNameField = id === 'name'
    return (
      <Field
        style={{ padding: 2 }}
        key={`items[${index}].product.${id}`}
        name={`items[${index}].product.${id}`}
        label={label}
        component={ReduxTextField}
        required={isNameField}
        validate={isNameField ? required : undefined}
        isCapitalized={isNameField}
      />
    )
  })

type MiscListFormFormOwnProps = {
  type: ListType.fish | ListType.meat
}

type FormDataProps = {}

const MiscListForm: React.FC<MiscListFormFormOwnProps &
  InjectedFormProps<FormDataProps, MiscListFormFormOwnProps>> = ({
  handleSubmit,
  submitting,
  error,
  type
}) => {
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const classes = useStyles()
  const isFormDirty = useSelector(isDirty('MiscListForm'))
  const save = bindSubmitActionToPromise(dispatch, saveMiscList)
  return (
    <div className={classes.formContainer}>
      <form onSubmit={handleSubmit(save)}>
        <Prompt when={isFormDirty} message={t('lists.unsavedChangesPrompt')} />
        <ErrorMessage message={error} />
        <FieldArray
          name='items'
          component={MiscListInputFields}
          props={{
            renderInputField: type === ListType.fish ? renderFishInputField : renderMeatInputField,
            isCancelDisabled: !isFormDirty,
            isSubmitDisabled: submitting || !isFormDirty
          }}
        />
      </form>
    </div>
  )
}

export default reduxForm<FormDataProps, MiscListFormFormOwnProps>({
  form: 'MiscListForm',
  enableReinitialize: true
})(MiscListForm)
