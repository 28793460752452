import { createSelector } from 'reselect'
import {
  ContentUI,
  ObjectType,
  ContentUIWithResources,
  isContentUI,
  Content
} from '@seesignage/seesignage-utils'
import { StateInterface } from '../types/states'
import { compareStrings } from '../utils/sorting'

const selectContentsDomain = (state: StateInterface) => state.contents
const selectIsContentsLoading = createSelector(selectContentsDomain, contents => contents.isLoading)
const selectIsContentModified = createSelector(
  selectContentsDomain,
  contents => contents.isContentModified
)
const selectContentsEditor = createSelector(selectContentsDomain, contents => contents.editor)
const selectContentId = createSelector(selectContentsEditor, editor => editor.contentId)
const selectSelectedObjects = createSelector(selectContentsEditor, editor => editor.selectedObjects)
const selectEditorOptions = createSelector(selectContentsEditor, editor => editor.options)

const selectEditorObjects = createSelector(
  selectContentsEditor,
  editor => editor.canvas?.objects || []
)

const selectNumberOfLimitedWidgets = createSelector(
  selectEditorObjects,
  objects =>
    objects.filter(obj => {
      return (
        (obj as any).type === ObjectType.CustomVideo ||
        (obj as any).type === ObjectType.CustomMediaCarousel ||
        (obj as any).type === ObjectType.socialMedia
      )
    }).length
)

const selectContents = createSelector(selectContentsDomain, domain => domain.contents)
const selectContentsAsArray = createSelector(selectContents, contentsById =>
  Object.values(contentsById)
)
const selectContentsUIAsArray = createSelector(selectContentsAsArray, contents =>
  contents.filter(isContentUI)
)

const selectContentsExists = createSelector(selectContentsAsArray, contents => contents.length > 0)

const selectEditorCursorMode = createSelector(selectEditorOptions, options => options.cursorMode)

const selectContentById = (contentId?: string | null) =>
  createSelector(selectContents, contents =>
    contents && contentId ? contents[contentId] : undefined
  )

const sortContentsBySearch = (
  contents: (Content | ContentUI | ContentUIWithResources)[],
  searchTerm: string | null
) =>
  searchTerm?.length
    ? contents
        .filter(({ name }) => name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
        .sort(compareStrings('name'))
    : contents.sort(compareStrings('name'))

export {
  selectIsContentsLoading,
  selectIsContentModified,
  selectSelectedObjects,
  selectEditorOptions,
  selectContents,
  selectContentsAsArray,
  selectContentsUIAsArray,
  selectContentById,
  selectContentsExists,
  selectContentsEditor,
  selectEditorObjects,
  selectContentId,
  sortContentsBySearch,
  selectNumberOfLimitedWidgets,
  selectEditorCursorMode
}
