import React, { Fragment } from 'react'
import { Field } from 'redux-form'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ComponentType, MediaType } from '@seesignage/seesignage-utils'
import SelectMultipleMediaField from '../../../../components/FormInput/SelectMedia/SelectMultipleMediaField'
import { generateKeyFieldPrefix } from './utils'
import SpacingFields from './CommonFields/SpacingFields'
import SizeFields from './CommonFields/SizeFields'
import PositionFields from './CommonFields/PositionFields'
import BorderFields from './CommonFields/BorderFields'
import EffectsFields from './CommonFields/EffectsFields'

interface ImageProps {
  id: string
  level: number
  childIndexes: number[]
  type: ComponentType
  formName: string
}

const ImageFields = ({ level, childIndexes, id, formName }: ImageProps) => {
  const [t] = useTranslation()
  const fieldProps = {
    level,
    childIndexes,
    t
  }
  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12} style={{ overflow: 'hidden', padding: '8px' }}>
          <Field
            isSingleFileInput
            formName={formName}
            name={generateKeyFieldPrefix(level, childIndexes, 'key')}
            component={SelectMultipleMediaField}
            mediaType={MediaType.image}
          />
        </Grid>
      </Grid>
      <PositionFields {...fieldProps} />
      <SpacingFields {...fieldProps} />
      <SizeFields {...fieldProps} />
      <BorderFields {...fieldProps} />
      <EffectsFields id={id} {...fieldProps} type={ComponentType.image} />
    </Fragment>
  )
}

export default ImageFields
