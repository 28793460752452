import { handleActions } from 'redux-actions'
import {
  PlaylistUI,
  isPlaylistItemWithIntervalAbility,
  ProductPlaylistItemUI,
  InfopagePlaylistItemUI,
  InfopageExtensive,
  PlaylistItemUI,
  isContentPlaylistItem,
  isInfopagePlaylistItem,
  isProductPlaylistItem,
  PlaylistDuration,
  isMediaPlaylistItemUI
} from '@seesignage/seesignage-utils'
import {
  selectPlaylistItem,
  deselectPlaylistItem,
  listPlaylistsSuccess,
  createPlaylistSuccess,
  getPlaylistSuccess,
  deletePlaylistSuccess,
  deletePlaylistItemSuccess,
  deselectPlaylist,
  updatePlaylistSuccess,
  hidePlaylistItemSuccess,
  schedulePlaylistItemSuccess,
  addPlaylistItemsSuccess,
  addPlaylistItemConditionSuccess,
  getPlaylist,
  getPlaylistFail,
  copyPlaylistSuccess,
  removePlaylistItemIntervalSuccess,
  intervalPlaylistItemSuccess,
  addPlaylistItemSuccess,
  selectAllPlaylistItems,
  deselectAllPlaylistItems,
  updatePlaylistItemsSuccess,
  updatePlaylistItems,
  updatePlaylistItemsFail,
  updateInfopageItem,
  updateInfopageItemThumbnail,
  updateRevolverItemSuccess,
  reorderItemsSuccess,
  updateTranscodedVideoItem,
  addPlaylistItems,
  addPlaylistItemsFail,
  resetPlaylists,
  listPlaylistsFail,
  listPlaylists,
  updateUrlItemSuccess,
  deletePlaylist,
  deletePlaylistFail,
  createEmptyInfopageToPlaylist,
  updateContentThumbnail,
  updateProductItemsTemplateSuccess,
  duplicatePlaylistItemSuccess,
  closePlaylistItemWizard,
  setPlaylistItemWizardPage
} from '../actions/playlists'
import { compareSchedules } from '../utils/sorting'
import { updatePlaylistProductSuccess } from '../actions/products'
import {
  Action,
  AddPlaylistItemConditionSuccess,
  IntervalPlaylistItemsSuccess,
  UpdatePlaylistProductSuccess,
  UpdateTranscodedVideoItem,
  SchedulePlaylistItemSuccess,
  UpdateProductItemsTemplateSuccess
} from '../types/actions'
import { PlaylistsState } from '../types/states'
import { AllPlaylists, PageType } from '../types/playlists'
import { calculateTotalDuration } from '../utils/playlists'
import { SelectedInfopage } from '../types/infopages'

const initialState: PlaylistsState = {
  playlists: {}, // indexed by id,
  parentPlaylists: {},
  viewMode: {
    isExistingItem: true
  },
  selectedPlaylistItemIds: [],
  playlistIsLoading: false,
  listPlaylistsIsLoading: false,
  selectedPlaylist: undefined,
  isModified: false,
  isPlaylistItemsSubmitting: false,
  isMediaUploading: false,
  playlistIsDeleting: false,
  createdInfopage: undefined,
  playlistItemWizard: {
    page: PageType.selectType
  }
}

const playlists = handleActions<PlaylistsState, any>(
  {
    [resetPlaylists]: () => initialState,
    [getPlaylist]: state => ({
      ...state,
      playlistIsLoading: true
    }),
    [getPlaylistFail]: state => ({
      ...state,
      playlistIsLoading: false
    }),
    [deselectPlaylist]: state => ({
      ...state,
      selectedPlaylist: undefined
    }),
    [selectPlaylistItem]: (state, { payload: itemId }: Action<string>) => {
      const selectedItems = [...state.selectedPlaylistItemIds, itemId]
      return {
        ...state,
        selectedPlaylistItemIds: selectedItems
      }
    },
    [deselectPlaylistItem]: (state, { payload: deselectedItem }: Action<string>) => {
      const ids = [...state.selectedPlaylistItemIds]
      const deductedIds = ids.filter(id => id !== deselectedItem)
      return {
        ...state,
        selectedPlaylistItemIds: deductedIds
      }
    },
    [reorderItemsSuccess]: (state, { payload: playlist }: Action<PlaylistUI>) => ({
      ...state,
      selectedPlaylist: playlist,
      isModified: true
    }),
    [listPlaylists]: state => ({
      ...state,
      listPlaylistsIsLoading: true
    }),
    [listPlaylistsFail]: state => ({
      ...state,
      listPlaylistsIsLoading: false
    }),
    [listPlaylistsSuccess]: (
      state,
      {
        payload: { environmentId, playlistsArray }
      }: Action<{ environmentId: string; playlistsArray: PlaylistUI[] }>
    ) => {
      const { playlists, parentPlaylists } = playlistsArray.reduce(
        (allPlaylists: AllPlaylists, playlist: PlaylistUI) => {
          if (environmentId === playlist.environmentId) {
            allPlaylists.playlists[playlist.playlistId] = {
              ...playlist
            }
          } else {
            allPlaylists.parentPlaylists[playlist.playlistId] = {
              ...playlist
            }
          }
          return allPlaylists
        },
        { playlists: {}, parentPlaylists: {} }
      )
      return {
        ...state,
        playlists,
        parentPlaylists,
        listPlaylistsIsLoading: false
      }
    },
    [getPlaylistSuccess]: (state, { payload: playlist }: Action<PlaylistUI>) => {
      const sortedSchedules = playlist.items.map((item: PlaylistItemUI) => {
        if (item.schedule) {
          const { schedule } = item
          item.schedule.ranges = schedule.ranges.sort(compareSchedules)
        }
        return item
      })
      playlist.items = sortedSchedules
      return {
        ...state,
        playlists: {
          ...state.playlists,
          [playlist.playlistId]: playlist
        },
        selectedPlaylist: playlist,
        playlistIsLoading: false,
        isModified: false
      }
    },
    [createPlaylistSuccess]: (state, { payload: playlist }: Action<PlaylistUI>) => ({
      ...state,
      playlists: {
        ...state.playlists,
        [playlist.playlistId]: playlist
      }
    }),
    [addPlaylistItems]: state => ({
      ...state,
      isMediaUploading: true
    }),
    [addPlaylistItemsFail]: state => ({
      ...state,
      isMediaUploading: false
    }),
    [addPlaylistItemSuccess]: (state, { payload: item }: Action<PlaylistItemUI>) => {
      const playlist = state.selectedPlaylist
      if (playlist) {
        const items = [...playlist.items, item]
        return {
          ...state,
          selectedPlaylist: {
            ...playlist,
            items,
            totalDuration: calculateTotalDuration(items, playlist.defaultInterval)
          },
          isMediaUploading: false,
          isModified: true
        }
      }
      return state
    },
    [addPlaylistItemsSuccess]: (state, { payload: items }: Action<PlaylistItemUI[]>) => {
      const playlist = state.selectedPlaylist

      if (playlist) {
        const playlistItems = [...playlist.items, ...items]
        return {
          ...state,
          selectedPlaylist: {
            ...playlist,
            items: playlistItems,
            totalDuration: calculateTotalDuration(playlistItems, playlist.defaultInterval),
            isPlaylistModified: true
          },
          isMediaUploading: false,
          isModified: true,
          playlistItemWizard: {
            ...state.playlistItemWizard,
            dndFiles: undefined
          }
        }
      }
      return state
    },
    [schedulePlaylistItemSuccess]: (
      state,
      { payload: { itemId, schedule } }: SchedulePlaylistItemSuccess
    ) => {
      if (state.selectedPlaylist) {
        const playlistItems = [...state.selectedPlaylist.items]
        const itemIndex = playlistItems.findIndex(item => item.itemId === itemId)
        playlistItems[itemIndex] = {
          ...playlistItems[itemIndex],
          schedule
        }
        return {
          ...state,
          selectedPlaylist: {
            ...state.selectedPlaylist,
            items: playlistItems
          },
          isModified: true
        }
      }
      return state
    },
    [addPlaylistItemConditionSuccess]: (
      state,
      { payload: { itemId, conditions } }: AddPlaylistItemConditionSuccess
    ) => {
      if (state.selectedPlaylist) {
        const playlistItems = [...state.selectedPlaylist.items]
        const itemIndex = playlistItems.findIndex(item => item.itemId === itemId)

        playlistItems[itemIndex] = {
          ...playlistItems[itemIndex],
          conditions
        }
        return {
          ...state,
          selectedPlaylist: {
            ...state.selectedPlaylist,
            items: playlistItems
          },
          isModified: true
        }
      }
      return state
    },
    [deletePlaylist]: state => ({
      ...state,
      playlistIsDeleting: true
    }),
    [deletePlaylistFail]: state => ({
      ...state,
      playlistIsDeleting: false
    }),
    [deletePlaylistSuccess]: (state, { payload: playlistId }: Action<string>) => {
      const playlists = { ...state.playlists }
      delete playlists[playlistId]
      return {
        ...state,
        playlists,
        selectedPlaylist: undefined,
        playlistIsDeleting: false
      }
    },
    [deletePlaylistItemSuccess]: (state, { payload: itemId }: Action<string>) => {
      if (state.selectedPlaylist) {
        const playlist = { ...state.selectedPlaylist }
        const { items } = playlist
        const i = items.findIndex(item => item.itemId === itemId)
        items.splice(i, 1)
        return {
          ...state,
          selectedPlaylist: {
            ...state.selectedPlaylist,
            items,
            totalDuration: calculateTotalDuration(items, playlist.defaultInterval)
          },
          isModified: true
        }
      }
      return state
    },
    [hidePlaylistItemSuccess]: (state, { payload: itemId }: Action<string>) => {
      if (state.selectedPlaylist) {
        const playlist = { ...state.selectedPlaylist }
        const itemIndex = playlist.items.findIndex(item => item.itemId === itemId)
        const isHidden = playlist.items[itemIndex].isHidden
        playlist.items[itemIndex].isHidden = !isHidden
        return {
          ...state,
          selectedPlaylist: playlist,
          isModified: true
        }
      }

      return state
    },
    [updatePlaylistSuccess]: (state, { payload: playlist }: Action<PlaylistUI>) => ({
      ...state,
      playlists: {
        ...state.playlists,
        [playlist.playlistId]: {
          ...state.playlists[playlist.playlistId],
          ...playlist
        }
      }
    }),
    [copyPlaylistSuccess]: (state, { payload: playlist }: Action<PlaylistUI>) => ({
      ...state,
      playlists: {
        ...state.playlists,
        [playlist.playlistId]: playlist
      }
    }),
    [updatePlaylistItems]: state => ({
      ...state,
      isPlaylistItemsSubmitting: true
    }),
    [updatePlaylistItemsFail]: state => ({
      ...state,
      isPlaylistItemsSubmitting: false
    }),
    [updateProductItemsTemplateSuccess]: (
      state,
      {
        payload: {
          itemId,
          template: { templateId, templateEnvironmentId },
          templateDuration
        }
      }: UpdateProductItemsTemplateSuccess
    ) => {
      if (state.selectedPlaylist) {
        const playlist = { ...state.selectedPlaylist }
        const { items, defaultInterval } = playlist
        const itemIndex = items.findIndex(item => item.itemId === itemId)
        const playlistItem = { ...items[itemIndex] }
        if (isProductPlaylistItem(playlistItem)) {
          playlistItem.templateId = templateId
          playlistItem.templateEnvironmentId = templateEnvironmentId
          playlistItem.interval = templateDuration ? templateDuration : playlistItem.interval
        }
        items[itemIndex] = playlistItem
        return {
          ...state,
          selectedPlaylist: {
            ...state.selectedPlaylist,
            items,
            totalDuration: calculateTotalDuration(items, defaultInterval)
          },
          isModified: true
        }
      }
      return state
    },
    [intervalPlaylistItemSuccess]: (
      state,
      { payload: { itemId, interval } }: IntervalPlaylistItemsSuccess
    ) => {
      if (state.selectedPlaylist) {
        const playlist = { ...state.selectedPlaylist }
        const { items, defaultInterval } = playlist
        const itemIndex = items.findIndex(item => item.itemId === itemId)
        const playlistItem = { ...items[itemIndex] }
        if (isPlaylistItemWithIntervalAbility(playlistItem)) {
          playlistItem.interval = interval
        }
        items[itemIndex] = playlistItem
        return {
          ...state,
          selectedPlaylist: {
            ...state.selectedPlaylist,
            items,
            totalDuration: calculateTotalDuration(items, defaultInterval)
          },
          isModified: true
        }
      }
      return state
    },
    [removePlaylistItemIntervalSuccess]: (state, { payload: itemId }: Action<string>) => {
      if (state.selectedPlaylist) {
        const playlist = { ...state.selectedPlaylist }
        const { items, defaultInterval } = playlist
        const itemIndex = items.findIndex(item => item.itemId === itemId)
        const playlistItem = { ...items[itemIndex] }
        if (isPlaylistItemWithIntervalAbility(playlistItem)) {
          delete playlistItem.interval
        }
        items[itemIndex] = playlistItem
        return {
          ...state,
          selectedPlaylist: {
            ...state.selectedPlaylist,
            items,
            totalDuration: calculateTotalDuration(items, defaultInterval)
          },
          isModified: true
        }
      }
      return state
    },
    [selectAllPlaylistItems]: state => {
      if (state.selectedPlaylist) {
        const items = state.selectedPlaylist.items.map(({ itemId }) => itemId)
        return {
          ...state,
          selectedPlaylistItemIds: items
        }
      }
      return state
    },
    [deselectAllPlaylistItems]: state => ({
      ...state,
      selectedPlaylistItemIds: []
    }),
    [updatePlaylistProductSuccess]: (
      state,
      {
        payload: { templateId, templateEnvironmentId, itemId, updatedProduct }
      }: UpdatePlaylistProductSuccess
    ) => {
      if (state.selectedPlaylist) {
        const playlist = { ...state.selectedPlaylist }
        const itemIndex = playlist.items.findIndex(item => item.itemId === itemId)

        const updatedProductPlaylistItem: ProductPlaylistItemUI = {
          ...(playlist.items[itemIndex] as ProductPlaylistItemUI),
          templateId,
          templateEnvironmentId,
          product: updatedProduct
        }

        playlist.items[itemIndex] = updatedProductPlaylistItem
        return {
          ...state,
          selectedPlaylist: playlist,
          isModified: true
        }
      }
      return state
    },
    [updateInfopageItem]: (state, { payload: infopage }: Action<InfopageExtensive>) => {
      if (state.selectedPlaylist) {
        const playlistItems = [...state.selectedPlaylist.items]
        const itemIndex = playlistItems.findIndex(
          item => isInfopagePlaylistItem(item) && item.infopageId === infopage.infopageId
        )
        const item = { ...playlistItems[itemIndex] } as InfopagePlaylistItemUI
        playlistItems[itemIndex] = {
          ...item,
          infopage
        }
        return {
          ...state,
          selectedPlaylist: {
            ...state.selectedPlaylist,
            items: playlistItems
          }
        }
      }
      return state
    },
    [updateInfopageItemThumbnail]: (
      state,
      {
        payload: { infopageId, thumbnailUrl }
      }: Action<{ infopageId: string; thumbnailUrl: string }>
    ) => {
      if (state.selectedPlaylist) {
        // playlist may have same infopage multiple times to add thumbnailUrl to each of them
        const playlistItems = [...state.selectedPlaylist.items].map(item => {
          if (isInfopagePlaylistItem(item) && item.infopageId === infopageId) {
            if (item.infopage) {
              item.infopage.thumbnailUrl = thumbnailUrl
            }
            return item
          }
          return item
        })

        return {
          ...state,
          selectedPlaylist: {
            ...state.selectedPlaylist,
            items: playlistItems
          }
        }
      }
      return state
    },
    [updateRevolverItemSuccess]: (state, { payload: item }: Action<PlaylistItemUI>) => {
      if (state.selectedPlaylist) {
        const { itemId } = item
        const playlist = { ...state.selectedPlaylist }
        const itemIndex = playlist.items.findIndex(item => item.itemId === itemId)
        playlist.items[itemIndex] = {
          ...playlist.items[itemIndex],
          ...item
        }
        return {
          ...state,
          selectedPlaylist: {
            ...playlist,
            totalDuration: calculateTotalDuration(playlist.items, playlist.defaultInterval)
          },
          isModified: true
        }
      }
      return state
    },
    [updateTranscodedVideoItem]: (
      state,
      { payload: { playlistId, fileWithUrl } }: UpdateTranscodedVideoItem
    ) => {
      const { key } = fileWithUrl
      if (state.selectedPlaylist && state.selectedPlaylist.playlistId === playlistId) {
        const playlist = { ...state.selectedPlaylist }
        if (playlist && playlist.items) {
          const itemIndex = playlist.items.findIndex(
            item => isMediaPlaylistItemUI(item) && item.key === key
          )
          playlist.items[itemIndex] = {
            ...playlist.items[itemIndex],
            ...(fileWithUrl as any)
          }
        }
        return {
          ...state,
          selectedPlaylist: {
            ...playlist,
            totalDuration: calculateTotalDuration(playlist.items, playlist.defaultInterval)
          }
        }
      }
      return state
    },
    [updatePlaylistItemsSuccess]: (state, { payload: updatedPlaylist }: Action<PlaylistUI>) => {
      if (state.selectedPlaylist) {
        return {
          ...state,
          selectedPlaylist: updatedPlaylist,
          playlists: {
            ...state.playlists,
            [updatedPlaylist.playlistId]: {
              ...state.playlists[updatedPlaylist.playlistId],
              ...updatedPlaylist
            }
          },
          isPlaylistItemsSubmitting: false,
          isModified: false
        }
      }
      return state
    },
    [duplicatePlaylistItemSuccess]: (
      state,
      {
        payload: { updatedItemsWithData, totalDuration }
      }: Action<{ updatedItemsWithData: PlaylistItemUI[]; totalDuration?: PlaylistDuration }>
    ) => {
      if (state.selectedPlaylist) {
        const playlist = { ...state.selectedPlaylist, totalDuration }
        playlist.items = updatedItemsWithData
        return {
          ...state,
          selectedPlaylist: playlist,
          playlists: {
            ...state.playlists,
            [playlist.playlistId]: {
              ...state.playlists[playlist.playlistId],
              items: updatedItemsWithData,
              totalDuration
            }
          },
          isPlaylistItemsSubmitting: false,
          isModified: true
        }
      }
      return state
    },
    [updateUrlItemSuccess]: (state, { payload: item }: Action<PlaylistItemUI>) => {
      if (state.selectedPlaylist) {
        const playlist = { ...state.selectedPlaylist }
        const { itemId } = item
        const itemIndex = playlist.items.findIndex(item => item.itemId === itemId)
        playlist.items[itemIndex] = {
          ...playlist.items[itemIndex],
          ...item
        }
        return {
          ...state,
          selectedPlaylist: playlist,
          isModified: true
        }
      }
      return state
    },
    [createEmptyInfopageToPlaylist]: (
      state,
      { payload: createdInfopage }: Action<SelectedInfopage>
    ) => ({
      ...state,
      createdInfopage
    }),
    [updateContentThumbnail]: (
      state,
      { payload: { contentId, thumbnailUrl } }: Action<{ contentId: string; thumbnailUrl: string }>
    ) => {
      if (state.selectedPlaylist) {
        // playlist may have same content multiple times so add thumbnailUrl to each of them
        const playlistItems = [...state.selectedPlaylist.items].map(item => {
          if (isContentPlaylistItem(item) && item.contentId === contentId) {
            item.content.thumbnailUrl = thumbnailUrl
            return item
          }
          return item
        })

        return {
          ...state,
          selectedPlaylist: {
            ...state.selectedPlaylist,
            items: playlistItems
          }
        }
      }
      return state
    },
    [closePlaylistItemWizard]: state => ({
      ...state,
      playlistItemWizard: {
        ...state.playlistItemWizard,
        page: PageType.selectType
      }
    }),
    [setPlaylistItemWizardPage]: (state, { payload: page }: Action<PageType>) => ({
      ...state,
      playlistItemWizard: {
        ...state.playlistItemWizard,
        page
      }
    })
  },
  initialState
)

export default playlists
