import { TableCell, TableRow, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { Fragment } from 'react'
import { ScreenResponse, PlaylistUI, ListUI, Channel } from '@seesignage/seesignage-utils'
import { TFunction } from 'i18next'
import { path } from 'ramda'
import UncheckedIcon from 'mdi-react/CheckboxBlankOutlineIcon'
import CheckedIcon from 'mdi-react/CheckboxMarkedOutlineIcon'
import WarningsCell from '../../Dashboard/ScreensWarnings/WarningsCell'
import Connection from '../../../components/Network/Connection'
import { convertBytesToGigabytes } from '../../../utils/conversion'
import LastContactCell from './LastContactCell'
import SelectedRowContent from './SelectedRowContent'
import SettingsIcons from './SettingsIcons'

const useStyles = makeStyles(() => ({
  row: {
    cursor: 'pointer' as any
  },
  codeUsed: {
    color: 'red'
  },
  codeNotUsed: {
    color: 'green'
  },
  selectedRow: {
    paddingBottom: 14,
    backgroundColor: 'rgb(145, 225, 255, 0.1)'
  },
  monitorIcon: {
    margin: 2
  },
  monitorIcon90: {
    margin: 2,
    transform: 'rotate(90deg)'
  },
  monitorIcon270: {
    margin: 2,
    transform: 'rotate(270deg)'
  },
  firmware: {
    fontSize: '12px'
  },
  code: {
    fontFamily: 'Roboto Slab'
  }
}))

const getScreenContent = (
  { playlistId, channelIds, listId }: ScreenResponse,
  selectPlaylistById: (playlistId?: string | null) => PlaylistUI | undefined,
  selectListById: (listId?: string | null) => ListUI | undefined,
  selectChannelById: (channelId?: string | null) => Channel | undefined
) => {
  const playlist = selectPlaylistById(playlistId)
  const list = selectListById(listId)
  const channel = selectChannelById(channelIds?.[0].channelId)
  if (channel && playlist) {
    return `${channel.name}, ${playlist.name}`
  } else if (channel) {
    return channel.name
  } else if (list) {
    return list.name
  } else if (playlist) {
    return playlist.name
  }
  return '-'
}

const renderAvailableCapacity = (capacity?: number, availableCapacity?: number) => {
  if (capacity && availableCapacity) {
    const isLowCapacity = availableCapacity < 0.2 * capacity
    const style = isLowCapacity ? { color: 'red' } : {}
    const availableGb = convertBytesToGigabytes(availableCapacity)
    return (
      <Typography variant='body2' gutterBottom style={style}>
        {availableGb.toFixed(2)}
      </Typography>
    )
  }
  return '-'
}

interface ScreensTableRowProps {
  screen: ScreenResponse
  isSelected: boolean
  t: TFunction
  handleRowClick: (event: any, screenId: string) => void
  colSpan: number
  selectPlaylistById: (playlistId?: string | null) => PlaylistUI | undefined
  selectListById: (listId?: string | null) => ListUI | undefined
  selectChannelById: (channelId?: string | null) => Channel | undefined
  isSystemAdminAccess: boolean
}

const ScreensTableRow: React.FC<ScreensTableRowProps> = ({
  screen,
  isSelected,
  t,
  handleRowClick,
  colSpan,
  selectPlaylistById,
  selectListById,
  selectChannelById,
  isSystemAdminAccess
}) => {
  const classes = useStyles()
  const {
    screenId,
    name,
    statuses,
    status,
    productNumber,
    identifier,
    rotation,
    code,
    isRegistered,
    storage,
    playerVersion,
    isTest,
    warnings,
    billable,
    build
  } = screen
  const lastStatusItem = statuses.slice(-1)[0]
  const availableCapacity: number | undefined = path(['availableCapacity'], storage)
  const capacity: number | undefined = path(['capacity'], storage)
  return (
    <Fragment key={screenId}>
      <TableRow
        hover
        className={classes.row}
        onClick={event => handleRowClick(event, screenId)}
        tabIndex={-1}
        key={screenId}
        selected={isSelected}>
        <TableCell>{name}</TableCell>
        <TableCell className={classes.code}>{code}</TableCell>
        <TableCell>
          {getScreenContent(screen, selectPlaylistById, selectListById, selectChannelById)}
        </TableCell>
        <TableCell>{productNumber}</TableCell>
        <TableCell>{identifier}</TableCell>
        <TableCell>
          <LastContactCell screenStatus={status} lastStatus={lastStatusItem} />
        </TableCell>
        <TableCell>
          <WarningsCell warnings={warnings} />
        </TableCell>
        <TableCell>{playerVersion || '-'}</TableCell>
        <TableCell className={classes.firmware}>{build?.firmwareVersion || '-'}</TableCell>
        <TableCell>{renderAvailableCapacity(capacity, availableCapacity)}</TableCell>
        <TableCell>
          <SettingsIcons t={t} classes={classes} rotation={rotation} isTest={isTest} />
        </TableCell>
        <TableCell>
          <Connection
            t={t}
            signalStrength={path(['wifiNetwork', 'signalStrength'], lastStatusItem)}
            isEthernetConnected={
              path(['ethernetNetwork', 'status'], lastStatusItem) === 'CONNECTED'
            }
          />
        </TableCell>
        {
          <TableCell>
            {(isSystemAdminAccess && billable) || (!isSystemAdminAccess && isRegistered) ? (
              <CheckedIcon />
            ) : (
              <UncheckedIcon />
            )}
          </TableCell>
        }
      </TableRow>
      {isSelected && (
        <TableRow>
          <TableCell colSpan={colSpan} className={classes.selectedRow}>
            <SelectedRowContent screen={screen} />
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  )
}

export default ScreensTableRow
