import { Typography } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import dateformat from 'dateformat'
import React from 'react'
import classNames from 'classnames'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Status, ScreenStatus } from '@seesignage/seesignage-utils'
import colors from '../../../styles/common/colors'

const styles = () => ({
  contactText: {
    fontWeight: 500
  },
  onlineStatus: {
    color: colors.screenStatuses.onlineBg
  },
  offlineStatus: {
    color: colors.screenStatuses.offlineBg
  },
  standbyStatus: {
    color: colors.screenStatuses.standbyBg
  }
})
interface LastContactDateProps extends WithTranslation, WithStyles<typeof styles> {
  status: ScreenStatus
  lastStatusItem?: Status
}

const LastContactDate: React.FC<LastContactDateProps> = ({
  lastStatusItem,
  t,
  classes,
  status
}) => (
  <Typography
    variant='caption'
    gutterBottom
    className={classNames({
      [classes.contactText]: true,
      [classes.onlineStatus]: status === ScreenStatus.online,
      [classes.offlineStatus]: status === ScreenStatus.offline,
      [classes.standbyStatus]: status === ScreenStatus.standby
    })}>
    {lastStatusItem?.updatedAt &&
      dateformat(new Date(lastStatusItem.updatedAt), 'HH:MM dd.mm.yyyy')}
    {status === ScreenStatus.notLinked && t('screens.notLinked')}
  </Typography>
)

export default withStyles(styles)(withTranslation()(LastContactDate))
