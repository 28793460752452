import React, { useState } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { Editor } from 'react-draft-wysiwyg'
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { getSupportedFontFamilies } from '@seesignage/seesignage-player-utils/lib'
import { Styles } from '@seesignage/seesignage-utils'
import { WrappedFieldInputProps } from 'redux-form'
import { TFunction } from 'i18next'
import htmlToDraft from 'html-to-draftjs'

const fontSize = {
  options: [16, 20, 30, 40, 50, 60, 70, 80, 90, 100, 120, 140, 160, 180, 200, 220, 260, 300]
}

const fontFamily = {
  options: getSupportedFontFamilies()
}
// https://jpuri.github.io/react-draft-wysiwyg/#/docs?_k=jjqinp
const toolbarOptions = {
  options: [
    'inline',
    'fontSize',
    'fontFamily',
    'list',
    'textAlign',
    'colorPicker',
    'emoji',
    'history'
  ],
  fontFamily,
  fontSize
}

const getDefaultValue = (t: TFunction, styles?: Styles) => {
  const text = t('general.typeSomething')
  if (styles) {
    const { fontFamily, fontSize, color } = styles
    return `<p><span style="font-size: ${fontSize || 16};font-family: ${fontFamily ||
      'Roboto'};color: ${color}">${text}</span></p>`
  }
  return `<p><span style="font-size: 16;font-family: Roboto">${text}</span></p>`
}

const getDefaultStyles = (styles?: Styles) => ({
  fontFamily: styles?.fontFamily || 'Roboto',
  fontSize: styles?.fontSize || 16,
  color: styles?.color
})

interface TextEditorFieldProps extends WithTranslation {
  input: WrappedFieldInputProps
  editorBackgroundColor?: string
  styles?: Styles
}

const TextEditorField: React.FC<TextEditorFieldProps> = ({
  input,
  styles,
  editorBackgroundColor,
  t
}) => {
  const defaultValue = getDefaultValue(t, styles)
  const value = input.value
  const contentBlock = htmlToDraft(value || defaultValue)
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState))

  const handleStateChange = (newEditorState: EditorState) => {
    const hasContentChanged = newEditorState.getCurrentContent() !== editorState.getCurrentContent()
    if (hasContentChanged) {
      const rawState = convertToRaw(newEditorState.getCurrentContent())
      input.onChange(draftToHtml(rawState))
    }
    setEditorState(newEditorState)
  }

  return (
    <div style={{ border: '1px solid lightGrey', minHeight: 250 }}>
      <Editor
        editorStyle={{
          ...getDefaultStyles(styles),
          backgroundColor: editorBackgroundColor
        }}
        editorState={editorState}
        stripPastedStyles
        toolbar={toolbarOptions}
        onEditorStateChange={handleStateChange}
      />
    </div>
  )
}

export default withTranslation()(TextEditorField)
