/* eslint-disable @typescript-eslint/camelcase */
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import Grid from '@mui/material/Grid'
import React, { Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { reduxForm, InjectedFormProps, Field, formValueSelector } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { Box, LinearProgress, MenuItem, Paper, Typography } from '@mui/material'
import {
  CountPlaysByAttribute,
  EnvironmentUI,
  TimeInterval,
  isParentEnvironmentUI
} from '@seesignage/seesignage-utils'
import ErrorMessage from '../../../../../components/Errors/ErrorMessage'
import { bindSubmitActionToPromise } from '../../../../../utils/forms'
import { getProofOfPlayOptions, queryProofOfPlay } from '../../../../../actions/analytics'
import {
  ReduxAutocompleteMulti,
  ReduxDatePicker,
  ReduxTextField
} from '../../../../../components/FormInput/ReduxWrappers'
import { required } from '../../../../../validation'
import {
  selectIsGetProofOfPlayOptionsLoading,
  selectIsQueryProofOfPlayLoading,
  selectProofOfPlayOptions
} from '../../../../../selectors/analytics'
import { QueryProofOfPlayFormData } from '../../../../../types/analytics'
import { selectEnvironmentIdFromPathname } from '../../../../../selectors/routing'
import i18n from '../../../../../translations/i18n'
import { getDaysBetweenDates, getEndOfTheDayDate } from '../../../../../utils/time'
import { compareStrings } from '../../../../../utils/sorting'

export const PROOF_OF_PLAY_FORM_NAME = 'ProofOfPlayForm'
const MAX_DAYS_RANGE = 31

const intervalOptions = Object.values(TimeInterval)
const countPlaysByAttributeOptions = Object.values(CountPlaysByAttribute)

type ComponentProps = {
  currentEnvironment: EnvironmentUI
}

type ProofOfPlayFormProps = ComponentProps

const ProofOfPlayForm: React.FC<ProofOfPlayFormProps &
  InjectedFormProps<QueryProofOfPlayFormData, ComponentProps>> = ({
  handleSubmit,
  submitting,
  error,
  currentEnvironment
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()

  const currentEnvironmentId = useSelector(selectEnvironmentIdFromPathname)
  const isQueryLoading = useSelector(selectIsQueryProofOfPlayLoading)
  const isOptionsLoading = useSelector(selectIsGetProofOfPlayOptionsLoading)
  const autocompleteOptions = useSelector(selectProofOfPlayOptions)

  const screenEnvironmentId: string | undefined = useSelector(state =>
    formValueSelector(PROOF_OF_PLAY_FORM_NAME)(state, 'environmentId')
  )
  const startDate: string | undefined = useSelector(state =>
    formValueSelector(PROOF_OF_PLAY_FORM_NAME)(state, 'startDate')
  )
  const endDate: string | undefined = useSelector(state =>
    formValueSelector(PROOF_OF_PLAY_FORM_NAME)(state, 'endDate')
  )

  const submitAction = bindSubmitActionToPromise(dispatch, queryProofOfPlay)

  useEffect(() => {
    const getOptions = async (
      currentEnvId: string,
      screenEnvId: string,
      start: string,
      end: string
    ) => {
      dispatch(
        getProofOfPlayOptions({
          environmentId: currentEnvId,
          screenEnvironmentId: screenEnvId,
          startDate: start,
          endDate: end
        })
      )
    }
    if (startDate && endDate && screenEnvironmentId && currentEnvironmentId) {
      const start = new Date(startDate)
      const end = new Date(endDate)
      if (start < end && getDaysBetweenDates(start, end) <= MAX_DAYS_RANGE) {
        getOptions(
          currentEnvironmentId,
          screenEnvironmentId,
          new Date(start).toISOString(),
          new Date(end).toISOString()
        )
      }
    }
  }, [startDate, endDate, screenEnvironmentId, currentEnvironmentId, dispatch])

  return (
    <Fragment>
      <Paper>
        <form onSubmit={handleSubmit(submitAction)}>
          <Box>
            <Grid container marginTop={1} marginBottom={1}>
              <Grid item xs={12} paddingX={1} paddingY={1}>
                <Typography variant='h6' gutterBottom>
                  {t('analytics.proofOfPlay.formTitle')}
                </Typography>
              </Grid>
              {isParentEnvironmentUI(currentEnvironment) && (
                <Grid item xs={12} paddingX={1} paddingY={1}>
                  <Field
                    label={t('analytics.proofOfPlay.environment')}
                    name='environmentId'
                    component={ReduxTextField}
                    fullWidth
                    variant='outlined'
                    size='small'
                    select>
                    {[
                      currentEnvironment,
                      ...Object.values(currentEnvironment.subEnvironmentsValues)
                    ]
                      .sort(compareStrings('name'))
                      .map(({ name, environmentId: eId }) => (
                        <MenuItem key={eId} value={eId}>
                          {name}
                        </MenuItem>
                      ))}
                  </Field>
                </Grid>
              )}
              <Grid item xs={4} paddingX={1} paddingY={1}>
                <Box display='flex' alignItems='center'>
                  <Field
                    fullWidth
                    name='startDate'
                    component={ReduxDatePicker}
                    label={t('date.startDate')}
                    dateformat='DD/MM/YYYY'
                    required
                    validate={[required]}
                    startOfTheDay
                    variant='outlined'
                    size='small'
                    autoCloseOnSelect
                    minDate={new Date(2024, 1, 1)}
                    maxDate={getEndOfTheDayDate()}
                  />
                  <Typography variant='h6' sx={{ mx: 2 }} color='rgba(0, 0, 0, 0.6)'>
                    -
                  </Typography>
                  <Field
                    fullWidth
                    name='endDate'
                    component={ReduxDatePicker}
                    label={t('date.endDate')}
                    dateformat='DD/MM/YYYY'
                    required
                    validate={[required]}
                    endOfTheDay
                    variant='outlined'
                    size='small'
                    autoCloseOnSelect
                    minDate={new Date(2024, 1, 1)}
                    maxDate={getEndOfTheDayDate()}
                  />
                </Box>
              </Grid>
              <Grid item xs={4} paddingX={1} paddingY={1}>
                <Field
                  label={t('analytics.proofOfPlay.countPlaysByAttribute')}
                  name='countPlaysByAttribute'
                  component={ReduxTextField}
                  fullWidth
                  variant='outlined'
                  size='small'
                  select>
                  {countPlaysByAttributeOptions.map(countPlaysByAttribute => (
                    <MenuItem key={countPlaysByAttribute} value={countPlaysByAttribute}>
                      {t(
                        `analytics.proofOfPlay.countPlaysByAttributeOptions.${countPlaysByAttribute}`
                      )}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={4} paddingX={1} paddingY={1}>
                <Field
                  label={t('analytics.proofOfPlay.interval')}
                  name='interval'
                  component={ReduxTextField}
                  fullWidth
                  variant='outlined'
                  size='small'
                  select>
                  {intervalOptions.map(interval => (
                    <MenuItem key={interval} value={interval}>
                      {t(`analytics.proofOfPlay.intervalOptions.${interval}`)}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={12} paddingX={1}>
                <Typography variant='subtitle1'>
                  {t('analytics.proofOfPlay.contentSourcesTitle')}
                </Typography>
              </Grid>
              <Grid item xs={6} paddingX={1} paddingY={1}>
                <Field
                  name='channelIds'
                  label={t('navigation.channels')}
                  component={ReduxAutocompleteMulti}
                  // show only current environment's options
                  options={autocompleteOptions?.channels || []}
                  multiple
                  variant='outlined'
                  size='small'
                  loading={isOptionsLoading}
                />
              </Grid>
              <Grid item xs={6} paddingX={1} paddingY={1}>
                <Field
                  name='playlistIds'
                  label={t('navigation.playlists')}
                  component={ReduxAutocompleteMulti}
                  // show only current environment's options
                  options={autocompleteOptions?.playlists || []}
                  multiple
                  variant='outlined'
                  size='small'
                  loading={isOptionsLoading}
                />
              </Grid>
              {error && (
                <Grid item xs={12} paddingX={1} paddingY={1}>
                  <ErrorMessage message={error} />
                </Grid>
              )}
              <Grid item xs={12}>
                <DialogActions>
                  <Button disabled={submitting || isQueryLoading} color='primary' type='submit'>
                    {t('general.create')}
                  </Button>
                </DialogActions>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Paper>
      {isQueryLoading && <LinearProgress />}
    </Fragment>
  )
}

const validate = ({ startDate, endDate }: QueryProofOfPlayFormData) => {
  const errors: any = {}
  if (startDate && endDate) {
    const start = new Date(startDate)
    const end = new Date(endDate)
    if (start > end) {
      errors.startDate = i18n.t('error.playlist.scheduleStartDate')
      errors.endDate = i18n.t('error.playlist.scheduleEndDate')
    } else if (getDaysBetweenDates(start, end) > MAX_DAYS_RANGE) {
      const errorMessage = i18n.t('error.analytics.tooLongDateRange', { maxDays: MAX_DAYS_RANGE })
      errors.startDate = errorMessage
      errors.endDate = errorMessage
    }
  }
  return errors
}

export default reduxForm<QueryProofOfPlayFormData, ComponentProps>({
  validate,
  form: PROOF_OF_PLAY_FORM_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: false, // otherwise chaging environment does not reset selected form properties
  updateUnregisteredFields: true
})(ProofOfPlayForm)
