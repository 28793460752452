import React from 'react'
import { Dispatch } from 'redux'
import { Button } from '@mui/material'
import { TFunction } from 'i18next'
import { EditorCursorMode } from '../../../../../types/contents'
import { generateFreeDrawingSettingsInitialValues } from '../../../../../utils/fabric/canvasFormValues'
import ContentToolbarItem from '../ContentToolbarItem/ContentToolbarItem'
import { setEditorCursorMode } from '../../../../../actions/contents'
import FreeDrawingSettingsForm from './FreeDrawingSettingsForm'
import LineDrawingSettingsForm from './LineDrawingSettingsForm'
//import PolygonDrawingSettingsForm from './PolygonDrawingSettingsForm'

interface DrawingSettingsProps {
  dispatch: Dispatch<any>
  editorCursorMode: EditorCursorMode
  t: TFunction
}

const DrawingSettings: React.FC<DrawingSettingsProps> = ({ editorCursorMode, dispatch, t }) => {
  return (
    <>
      {[
        EditorCursorMode.freeDrawing,
        EditorCursorMode.drawingLine,
        EditorCursorMode.drawingPolygon
      ].includes(editorCursorMode) && (
        <ContentToolbarItem>
          <Button
            variant='contained'
            color='primary'
            onClick={() => dispatch(setEditorCursorMode(EditorCursorMode.move))}>
            {t('general.done')}
          </Button>
        </ContentToolbarItem>
      )}
      {editorCursorMode === EditorCursorMode.freeDrawing && (
        <FreeDrawingSettingsForm t={t} initialValues={generateFreeDrawingSettingsInitialValues()} />
      )}
      {EditorCursorMode.drawingLine === editorCursorMode && (
        <LineDrawingSettingsForm t={t} initialValues={{ color: '#000000', width: 10 }} />
      )}
      {/* At the moment you cannot disable border (stroke) when drawing polyline,
      so let user edit polygon after its created */}
      {/* {(editorCursorMode === EditorCursorMode.readyTodrawingPolygon ||
        editorCursorMode === EditorCursorMode.drawingPolygon) && (
        <PolygonDrawingSettingsForm
          initialValues={{ fill: 'rgba(0, 0, 0, 0.2)', stroke: '#000000', strokeWidth: 5 }}
        />
      )} */}
    </>
  )
}

export default DrawingSettings
