import { Language } from '@seesignage/seesignage-utils'

const saveLanguageToLocalStorage = (language: Language) => {
  localStorage.setItem('language', language)
}

const getLanguageFromLocalStorage = () => {
  const language = localStorage.getItem('language') as Language | null
  return language ? language : Language.en
}

const getApiUrlLanguage = () => {
  const language = getLanguageFromLocalStorage()
  return `${language}-${language.toUpperCase()}`
}

export { saveLanguageToLocalStorage, getLanguageFromLocalStorage, getApiUrlLanguage }
