import { all, takeLatest, put, select } from 'redux-saga/effects'

import { destroy } from 'redux-form'
import { closeDialog, openDialog } from '../actions/dialogs'
import { clearSearchMedia } from '../actions/media'
import { hideTawkWidget, showTawkWidget } from '../config/tawk'
import { selectUser } from '../selectors/users'
import { isSystemAdminUser } from '../utils/permissions'

// eslint-disable-next-line require-yield
function* handleOpenDialog() {
  hideTawkWidget()
}

interface HandleCloseDialogParams {
  payload?: string
}

export function* handleCloseDialog({ payload: dialogId }: HandleCloseDialogParams) {
  const user = yield select(selectUser)
  if (!isSystemAdminUser(user)) {
    showTawkWidget()
  }
  if (
    dialogId === 'createRetailProductDialog' ||
    dialogId === 'CreateMockProductDialog' ||
    dialogId === 'UpdateMockProductDialog' ||
    (dialogId && dialogId.startsWith('UpdateProductDialog'))
  ) {
    yield put(clearSearchMedia())
    yield put(destroy('AddProductWizardForm'))
  } else if (dialogId === 'AddPlaylistItemWizard') {
    yield put(destroy('AddProductWizardForm'))
  }
}

function* watchConfirmationsActions() {
  yield all([takeLatest(openDialog, handleOpenDialog), takeLatest(closeDialog, handleCloseDialog)])
}

export default [watchConfirmationsActions]
