import React, { ReactElement } from 'react'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import TooltipSpan from '../../../../../components/Tooltip/TooltipSpan'

const useStyles = makeStyles({
  toolBarItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

interface ContentToolbarItemProps {
  hasSpacing?: boolean
  tooltip?: string
  children: ReactElement
}

const ContentToolbarItem = ({
  children,
  hasSpacing,
  tooltip,
  ...rest
}: ContentToolbarItemProps) => {
  const classes = useStyles()
  return (
    <Grid
      item
      {...rest}
      className={classes.toolBarItem}
      marginY={'3px'}
      marginX={hasSpacing ? undefined : '3px'}>
      {tooltip ? <TooltipSpan title={tooltip}>{children}</TooltipSpan> : children}
    </Grid>
  )
}

export default ContentToolbarItem
