import React, { ComponentType } from 'react'
import { WrappedFieldInputProps } from 'redux-form'
import { Checkbox } from '@mui/material'
import { MdiReactIconProps } from 'mdi-react'

const toolbarIconSize = 25
interface StringCheckBoxProps {
  input: WrappedFieldInputProps
  Icon: ComponentType<MdiReactIconProps>
  checkedValue: string
  uncheckedValue: string
  disabled?: boolean
}

const StringCheckBox = ({
  input: { onChange, value },
  Icon,
  checkedValue,
  uncheckedValue,
  disabled
}: StringCheckBoxProps) => (
  <Checkbox
    disabled={disabled}
    size='small'
    checked={value === checkedValue ? true : false}
    icon={<Icon size={toolbarIconSize} />}
    checkedIcon={
      <Icon
        size={toolbarIconSize}
        color={'#1e88e5'}
        style={{ borderRadius: '50%', border: '1px solid #1e88e5' }}
      />
    }
    onChange={() => onChange(checkedValue !== value ? checkedValue : uncheckedValue)}
  />
)

export default StringCheckBox
