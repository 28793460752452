import { PubSub, Hub } from 'aws-amplify'
import { CONNECTION_STATE_CHANGE, ConnectionState } from '@aws-amplify/pubsub'
import { all, takeLatest, put, call, delay, select, takeEvery } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import {
  IoTLunchListContent,
  Media,
  EMMiIoTMessage,
  EMMiIoTContent,
  IoTData,
  IoTDataType,
  PlaylistUI,
  ContentsMediaType,
  SocialMediaPlatform,
  isPlaylistIoTData,
  isContentIoTData,
  isCampaignIoTData,
  isIntegrationIoTData,
  EMMiIntegrationStatus,
  IoTResponse,
  IoTValue,
  isIoTValueScreen,
  IoTValueScreen,
  ScreenIotMessageCommandSuccess,
  isIoTValueScreenUpdate,
  isIoTValueScreenCommandSuccess,
  ScreensIotMessageUpdate,
  MediaFolder
} from '@seesignage/seesignage-utils'
import { push } from 'connected-react-router'
import EnvironmentsApi from '../services/api/environments'
import {
  onIoTMessage,
  onIoTMessageSuccess,
  onIoTMessageFail,
  initializeIot,
  setIntegrationIotData,
  setIotConnectionState
} from '../actions/iot'
import { getStore } from '../configureStore'
import {
  getPlaylist,
  updateTranscodedVideoItem,
  listPlaylists,
  updateContentThumbnail,
  updateInfopageItemThumbnail
} from '../actions/playlists'
import { getScreen, deleteScreenSuccess } from '../actions/screens'
import {
  listFiles,
  deleteMediaSuccess,
  addMediaSuccess,
  clearUploadProgress,
  setUploadProgress
} from '../actions/media'
import { getList, getLunchListContent } from '../actions/lists'
import {
  selectViewFromPathname,
  selectContentIdFromPathname,
  selectQueryParamFromSearch
} from '../selectors/routing'
import { selectUserSub } from '../selectors/users'
import i18n from '../translations/i18n'
import { listContents, getContent, closeEditorCanvas } from '../actions/contents'
import { getChannel, listChannels } from '../actions/channels'
import { selectSelectedPlaylist } from '../selectors/playlists'
import { selectSelectedInfopageType } from '../selectors/infopages'
import { listCampaigns } from '../actions/campaigns'
import { SelectedInfopageType } from '../types/infopages'

import { closeDialog } from '../actions/dialogs'
import { selectMediaFolderById } from '../selectors/media'

let SUBSCRIPTIONS: any
let isPubSubListenOn = false

const getTopics = (environmentId: string, userId: string) => [
  `environments/${environmentId}/contents`,
  `environments/${environmentId}/media`,
  `environments/${environmentId}/lists`,
  `environments/${environmentId}/screens`,
  `environments/${environmentId}/playlists`,
  `environments/${environmentId}/channels`,
  `environments/${environmentId}/infopages`,
  `environments/${environmentId}/campaigns`,
  `environments/${environmentId}/integrations`,
  `environments/${environmentId}/users`,
  `environments/${environmentId}/users/${userId}`
]

interface HandlePlaylistIoTMessageParams {
  environmentId: string
  topic: string
  message?: string
  iotData?: IoTData
}

function* handleOnPlaylistsIoTMessage({
  environmentId,
  topic,
  message,
  iotData
}: HandlePlaylistIoTMessageParams) {
  try {
    const currentUserId: string | undefined = yield select(selectUserSub)
    const selectedPlaylist: PlaylistUI | undefined = yield select(selectSelectedPlaylist)
    if (
      topic === `environments/${environmentId}/playlists` &&
      isPlaylistIoTData(iotData) &&
      currentUserId !== iotData.userId
    ) {
      const { userFullName, itemName, playlistId } = iotData
      const currentPathPlaylistId: string | undefined = yield select(selectContentIdFromPathname)
      // prioritise selectedPlaylistId, since it might be a channel item
      switch (message) {
        case 'create': {
          if (!selectedPlaylist) {
            // user is viewing playlists
            toast.info(
              i18n.t('playlists.notifications.otherUserCreatedPlaylist', {
                userFullName,
                playlistName: itemName
              })
            )
            yield put(listPlaylists())
          }
          break
        }
        case 'update': {
          if (playlistId && playlistId === selectedPlaylist?.playlistId) {
            toast.info(
              i18n.t('playlists.notifications.otherUserUpdatedSamePlaylist', {
                userFullName,
                playlistName: itemName
              })
            )
            yield put(getPlaylist(playlistId))
          } else {
            // user is viewing playlists
            toast.info(
              i18n.t('playlists.notifications.otherUserUpdatedPlaylist', {
                userFullName,
                playlistName: itemName
              })
            )
            // Only listPlaylists if user is not in EditPlaylist view. Might crash app
            if (!currentPathPlaylistId) {
              yield put(listPlaylists())
            }
          }
          break
        }
        case 'delete': {
          if (playlistId === selectedPlaylist?.playlistId) {
            // if user is viewing playlist in edit playlist but some other user deleted playlist,
            // navigate to playlists view
            const currentView: string = yield select(selectViewFromPathname)
            if (currentView === 'playlists') {
              yield put(push(`/environments/${environmentId}/playlists`))
            }
            toast.info(
              i18n.t('playlists.notifications.otherUserDeletedPlaylist', {
                userFullName,
                playlistName: itemName
              })
            )
          } else {
            // user is viewing playlist
            toast.info(
              i18n.t('playlists.notifications.otherUserDeletedPlaylist', {
                userFullName,
                playlistName: itemName
              })
            )
            // Only listPlaylists if user is not in EditPlaylist view. Might crash app
            if (!currentPathPlaylistId) {
              yield put(listPlaylists())
            }
          }
          break
        }
      }
    }
  } catch (error) {
    yield put(onIoTMessageFail(error.message))
  }
}

interface HandleChannelsIoTMessageParams {
  environmentId: string
  topic: string
  message?: string
  iotData?: IoTData
}

function* handleOnChannelsIoTMessage({
  environmentId,
  topic,
  message,
  iotData
}: HandleChannelsIoTMessageParams) {
  try {
    const currentUserId: string | undefined = yield select(selectUserSub)
    const currentChannelId: string | undefined = yield select(selectContentIdFromPathname)
    if (
      topic === `environments/${environmentId}/channels` &&
      iotData?.type === IoTDataType.channel &&
      currentUserId !== iotData.userId
    ) {
      const { userFullName, itemName, channelId } = iotData
      switch (message) {
        case 'create': {
          if (!currentChannelId) {
            // user is viewing channels
            toast.info(
              i18n.t('channels.notifications.otherUserCreatedChannel', {
                userFullName,
                channelName: itemName
              })
            )
            yield put(listChannels())
          }
          break
        }
        case 'update': {
          if (channelId && channelId === currentChannelId) {
            toast.info(
              i18n.t('channels.notifications.otherUserUpdatedSameChannel', {
                userFullName,
                channelName: itemName
              })
            )
            yield put(getChannel(channelId))
          } else {
            // user is viewing channels
            toast.info(
              i18n.t('channels.notifications.otherUserUpdatedChannel', {
                userFullName,
                channelName: itemName
              })
            )
            yield put(listChannels())
          }
          break
        }
        case 'delete': {
          if (channelId === currentChannelId) {
            // if user is viewing channel in calendar view but some other user deleted channel,
            // navigate to channels view
            yield put(push(`/environments/${environmentId}/channels`))

            toast.info(
              i18n.t('channels.notifications.otherUserDeletedChannel', {
                userFullName,
                channelName: itemName
              })
            )
          } else {
            // user is viewing playlist
            toast.info(
              i18n.t('channels.notifications.otherUserDeletedChannel', {
                userFullName,
                channelName: itemName
              })
            )
            yield put(listChannels())
          }
          break
        }
      }
    }
  } catch (error) {
    yield put(onIoTMessageFail(error.message))
  }
}
interface HandleContentIoTMessageParams {
  environmentId: string
  topic: string
  message?: string
  iotData?: IoTData
}

function* handleOnContentsIoTMessage({
  environmentId,
  topic,
  message,
  iotData
}: HandleContentIoTMessageParams) {
  try {
    const currentUserId: string | undefined = yield select(selectUserSub)
    // show notifications only when action is triggered by some other user
    if (
      topic === `environments/${environmentId}/contents` &&
      isContentIoTData(iotData) &&
      currentUserId !== iotData?.userId
    ) {
      const { userFullName, itemName, contentId } = iotData
      const currentPathContentId: string | undefined = yield select(selectContentIdFromPathname)
      switch (message) {
        case 'create':
          // user is viewing contents
          toast.info(
            i18n.t('contents.notifications.otherUserCreatedContent', {
              userFullName,
              contentName: itemName
            })
          )
          yield put(listContents())
          break
        case 'update':
          if (contentId === currentPathContentId) {
            toast.info(
              i18n.t('contents.notifications.otherUserUpdatedSameContent', {
                userFullName,
                contentName: itemName
              })
            )
            // note: when user is viewing same content we need to reinitialize whole content editor
            yield put(closeEditorCanvas())
            yield put(getContent()) // initializes content editor again
          } else {
            // user is viewing contents
            toast.info(
              i18n.t('contents.notifications.otherUserUpdatedContent', {
                userFullName,
                contentName: itemName
              })
            )
            yield put(listContents())
          }
          break
        case 'delete':
          if (contentId === currentPathContentId) {
            // if user is viewing content in editor but some other user deleted content,
            // navigate to contents view
            yield put(push(`/environments/${environmentId}/contents`))
            toast.info(
              i18n.t('contents.notifications.otherUserDeletedContent', {
                userFullName,
                contentName: itemName
              })
            )
          } else {
            // user is viewing contents
            toast.info(
              i18n.t('contents.notifications.otherUserDeletedContent', {
                userFullName,
                contentName: itemName
              })
            )
            yield put(listContents())
          }
          break
      }
    }

    yield put(onIoTMessageSuccess())
  } catch (error) {
    yield put(onIoTMessageFail(error.message))
  }
}
interface HandleOnCampaignsIoTMessageParams {
  environmentId: string
  topic: string
  message?: string
  iotData?: IoTData
}

function* handleOnCampaignsIoTMessage({
  environmentId,
  topic,
  message,
  iotData
}: HandleOnCampaignsIoTMessageParams) {
  try {
    const currentUserId: string | undefined = yield select(selectUserSub)
    // show notifications only when action is triggered by some other user
    if (
      topic === `environments/${environmentId}/campaigns` &&
      isCampaignIoTData(iotData) &&
      currentUserId !== iotData?.userId
    ) {
      const { userFullName, itemName: name, campaignId } = iotData
      const qpCampaignId: string | undefined = yield select(
        selectQueryParamFromSearch('campaignId')
      )
      switch (message) {
        case 'create':
          if (!campaignId) {
            // user is viewing campaigns
            toast.info(
              i18n.t('campaigns.notifications.otherUserCreatedCampaign', {
                userFullName,
                name
              })
            )
            yield put(listCampaigns())
          }
          break
        case 'update':
          if (campaignId === qpCampaignId) {
            toast.info(
              i18n.t('campaigns.notifications.otherUserUpdatedSameCampaign', {
                userFullName,
                name
              })
            )
            yield put(getContent())
          } else {
            // user is viewing campaigns
            toast.info(
              i18n.t('campaigns.notifications.otherUserUpdatedCampaign', {
                userFullName,
                name
              })
            )
            yield put(listCampaigns())
          }
          break
        case 'delete':
          if (campaignId === qpCampaignId) {
            // if user is viewing campaign but some other user deleted campaign,
            // navigate to campaigns view
            yield put(push(`/environments/${environmentId}/campaigns`))
            toast.info(
              i18n.t('campaigns.notifications.otherUserDeletedCampaign', {
                userFullName,
                name
              })
            )
          } else {
            // user is viewing campaigns
            toast.info(
              i18n.t('campaigns.notifications.otherUserDeletedCampaign', {
                userFullName,
                name
              })
            )
            yield put(listCampaigns())
          }
          break
      }
    }

    yield put(onIoTMessageSuccess())
  } catch (error) {
    yield put(onIoTMessageFail(error.message))
  }
}

interface HandleOnListsIoTMessageParams {
  environmentId: string
  topic: string
  message?: string
  /** exists when updating lunch list items */
  userId?: string
  content?: IoTLunchListContent
}

function* handleOnListsIoTMessage({
  environmentId,
  topic,
  message,
  userId,
  content
}: HandleOnListsIoTMessageParams) {
  try {
    const listId: string = yield select(selectContentIdFromPathname)
    switch (topic) {
      case `environments/${environmentId}/lists/${listId}`:
        if (message === 'update' && listId) {
          const currentView: string = yield select(selectViewFromPathname)
          if (currentView === 'lunchLists') {
            const currentUserId: string = yield select(selectUserSub)
            // update lunch list only when action comes from different than current user
            if (currentUserId !== userId) {
              if (content) {
                yield put(getLunchListContent({ environmentId, listId, content }))
              } else {
                yield put(getList(listId))
                toast.info(i18n.t('lists.lunch.notifications.otherUserUpdatedList'))
              }
              yield put(onIoTMessageSuccess())
            }
          } else {
            yield put(getList(listId))
            yield put(onIoTMessageSuccess())
          }
        }
        break
      default:
        break
    }
  } catch (error) {
    yield put(onIoTMessageFail(error.message))
  }
}

interface HandleOnScreensIoTMessageParams {
  environmentId: string
  value: IoTValueScreen
}

export function* handleOnScreensIoTMessage({
  environmentId,
  value
}: HandleOnScreensIoTMessageParams) {
  try {
    const currentUserId: string | undefined = yield select(selectUserSub)
    if (isIoTValueScreenUpdate(value)) {
      const { topic, screenId, userId, message, userFullName, itemName } = value
      if (topic === `environments/${environmentId}/screens`) {
        // update screen data only if other user
        if (currentUserId !== userId) {
          if (message === ScreensIotMessageUpdate.delete) {
            yield put(deleteScreenSuccess(screenId))
          } else {
            yield put(getScreen(screenId))
          }
          yield put(getScreen(screenId))
          // create, delete and update messages
          toast.success(i18n.t(`iot.screens.message.${message}`, { userFullName, itemName }), {
            autoClose: 5000
          })
        }
      }
    } else if (isIoTValueScreenCommandSuccess(value)) {
      const { topic, message, screenId, itemName } = value
      if (topic === `environments/${environmentId}/screens`) {
        // update screen data only if other user
        if (
          message === ScreenIotMessageCommandSuccess.filesSuccess ||
          message === ScreenIotMessageCommandSuccess.pingSuccess
        ) {
          toast.success(i18n.t(`iot.screens.message.${message}`, { itemName }), { autoClose: 5000 })
          yield put(getScreen(screenId))
        } else if (message === ScreenIotMessageCommandSuccess.statusUpdated) {
          // when screen updated its own status. Screen updates status automatically every 30 minutes.
          yield put(getScreen(screenId))
        }
      }
    }
    yield put(onIoTMessageSuccess())
  } catch (error) {
    yield put(onIoTMessageFail(error.message))
  }
}

/**
 * Handle EMMi file transfer related IoT messages
 * @param message
 * @param emmi
 * @param key
 */
function* handleEMMiIoTMessage(message: EMMiIoTMessage, emmi: EMMiIoTContent, media?: Media) {
  if (emmi.asset) {
    const { asset, progress } = emmi
    switch (message) {
      case EMMiIoTMessage.EMMiProgress:
        if (progress) {
          yield put(
            setUploadProgress({
              key: asset.assetid,
              name: asset.filename,
              progress
            })
          )
        }

        break
      case EMMiIoTMessage.EMMiImageConverted:
        yield put(clearUploadProgress(asset.assetid))
        yield put(addMediaSuccess(media))

        break
      case EMMiIoTMessage.EMMiVideoSentToTranscoder:
        yield put(clearUploadProgress(asset.assetid))
        yield put(
          setUploadProgress({
            key: emmi.key || '',
            name: asset.filename,
            isTranscoding: true
          })
        )
        break
      case EMMiIoTMessage.EMMiFileExists:
        yield put(clearUploadProgress(asset.assetid))
        const folder: MediaFolder | undefined = yield select(
          selectMediaFolderById(media?.parentFolderId)
        )
        toast.warn(
          i18n.t('media.fileAlreadyUploaded', {
            fileName: asset.filename,
            folder: folder?.name || 'root'
          })
        )
        break
      /** ERRORS */
      case EMMiIoTMessage.EMMiDownloadError:
        yield put(
          setUploadProgress({
            key: asset.assetid,
            name: asset.filename,
            error: i18n.t('error.media.downloadFailed'),
            progress: 0
          })
        )
        break
      case EMMiIoTMessage.EMMiConversionError:
        yield put(
          setUploadProgress({
            key: asset.assetid,
            name: asset.filename,
            error: i18n.t('error.media.conversionFailed'),
            progress: 0
          })
        )
        break
      default:
        break
    }
  }
}

interface HandleOnMediaIoTMessageParams {
  environmentId: string
  topic: string
  message?: string
  /** Transcoded video metadata received as payload */
  key?: Media
  /** EMMi upload and conversion content */
  emmi?: EMMiIoTContent
}

export function* handleOnMediaIoTMessage({
  environmentId,
  topic,
  message,
  key,
  emmi
}: HandleOnMediaIoTMessageParams) {
  try {
    switch (topic) {
      case `environments/${environmentId}/media`:
        if (message === 'update') {
          yield put(listFiles())
          yield put(onIoTMessageSuccess())
        } else if (message === 'delete' && key) {
          yield put(deleteMediaSuccess(key))
          yield put(onIoTMessageSuccess())
        } else if (message === 'transcodingReady' && key) {
          const selectedPlaylist: PlaylistUI | undefined = yield select(selectSelectedPlaylist)
          // update media playlist item if selected playlist
          if (selectedPlaylist) {
            yield put(
              updateTranscodedVideoItem({
                playlistId: selectedPlaylist?.playlistId,
                fileWithUrl: key
              })
            )
          }
          yield put(addMediaSuccess(key))
          yield put(clearUploadProgress(key.key))
          yield put(onIoTMessageSuccess())
        } else if (message && message in EMMiIoTMessage && emmi) {
          yield call(handleEMMiIoTMessage, message as EMMiIoTMessage, emmi, key)
        }
        break
      default:
        break
    }
  } catch (error) {
    yield put(onIoTMessageFail(error.message))
  }
}

interface HandleOnIntegrationIoTMessageParams {
  environmentId: string
  topic: string
  message?: string
  iotData?: IoTData
}

export function* handleOnIntegrationIoTMessage({
  environmentId,
  topic,
  iotData
}: HandleOnIntegrationIoTMessageParams) {
  try {
    if (isIntegrationIoTData(iotData) && topic === `environments/${environmentId}/integrations`) {
      yield put(setIntegrationIotData(iotData))
      switch (iotData.status) {
        case EMMiIntegrationStatus.ready:
          yield put(closeDialog())
          toast.success(i18n.t('integrations.emmi.importTaskSuccess'), { autoClose: 15000 })
          break
        default:
          break
      }
    }
  } catch (error) {
    yield put(onIoTMessageFail(error.message))
  }
}

interface OnAuthIoTMessagePayload {
  message?: 'authToken' | 'accessDenied'
  token?: string
  tokenExpires?: string
  platform?: SocialMediaPlatform
}

interface OnIoTMessagePayload {
  environmentId: string
  value: IoTValue
}

interface HandleOnIotMessageParams {
  payload: OnIoTMessagePayload
}

export function* handleOnIoTMessage({
  payload: { environmentId, value }
}: HandleOnIotMessageParams) {
  try {
    const currentView: string | undefined = yield select(selectViewFromPathname)

    if (isIoTValueScreen(value)) {
      // new IoT message implementation. Move to use typeguards.
      if (currentView === 'screens') {
        yield call(handleOnScreensIoTMessage, {
          environmentId,
          value
        })
      }
    } else {
      // old IoT message stucture implementation. Will be deprecated in the future.
      const { topic, message, key, emmi, userId, content, iotData, thumbnail } = value
      // media messages should be handled everywhere, since FileUploadProgress is visible everywhere
      yield call(handleOnMediaIoTMessage, {
        environmentId,
        key,
        emmi,
        message,
        topic
      })
      // integration messages should be handled everywhere
      yield call(handleOnIntegrationIoTMessage, {
        environmentId,
        message,
        topic,
        iotData
      })
      if (currentView === 'playlists' || currentView === 'channels') {
        yield call(handleOnPlaylistsIoTMessage, {
          environmentId,
          topic,
          message,
          iotData
        })
      }
      if (currentView === 'campaigns') {
        yield call(handleOnCampaignsIoTMessage, {
          environmentId,
          topic,
          message,
          iotData
        })
      }
      const selectedInfoPageType: SelectedInfopageType | undefined = yield select(
        selectSelectedInfopageType
      )

      if (
        currentView === 'contents' ||
        (currentView === 'infopages' && selectedInfoPageType === SelectedInfopageType.content)
      ) {
        yield call(handleOnContentsIoTMessage, {
          environmentId,
          topic,
          message,
          iotData
        })
      }
      if (currentView === 'lists' || currentView === 'lunchLists') {
        yield call(handleOnListsIoTMessage, {
          environmentId,
          topic,
          message,
          userId,
          content
        })
      }
      if (currentView === 'channels') {
        yield call(handleOnChannelsIoTMessage, {
          environmentId,
          topic,
          message,
          iotData
        })
      }
      if (thumbnail) {
        const { contentType, contentId, thumbnailUrl } = thumbnail
        if (contentType === ContentsMediaType.contents) {
          yield put(updateContentThumbnail({ contentId, thumbnailUrl }))
        } else if (contentType === ContentsMediaType.infopages) {
          yield put(updateInfopageItemThumbnail({ infopageId: contentId, thumbnailUrl }))
        }
      }
    }
  } catch (error) {
    yield put(onIoTMessageFail(error.message))
  }
}

interface HandleInitializeIotParams {
  payload: {
    environmentId: string
    userId: string
  }
}

function* handleInitializeIot({ payload: { environmentId, userId } }: HandleInitializeIotParams) {
  yield call(EnvironmentsApi.initIotForUserEnvironment, environmentId)
  if (SUBSCRIPTIONS) {
    SUBSCRIPTIONS.unsubscribe()
    yield delay(100)
  }
  const topics = getTopics(environmentId, userId)
  const store = getStore()
  SUBSCRIPTIONS = PubSub.subscribe(topics).subscribe({
    next: pubSubObservable => {
      const { value } = (pubSubObservable as never) as IoTResponse
      const iotMessagePayload: OnIoTMessagePayload = {
        value,
        environmentId
      }
      store.dispatch(onIoTMessage(iotMessagePayload))
    },
    error: (error: Error) => {
      console.error( //eslint-disable-line
        `Subscription to [${topics}] failed`,
        error
      )
    },
    complete: () => console.log(`Subscription to [${topics}] complete`) //eslint-disable-line
  })

  // start listening iot connection state only once.
  if (isPubSubListenOn === false) {
    Hub.listen('pubsub', data => {
      const { payload } = data
      if (payload.event === CONNECTION_STATE_CHANGE) {
        const connectionState = payload.data.connectionState as ConnectionState
        store.dispatch(setIotConnectionState(connectionState))
      }
    })
    isPubSubListenOn = true
  }
}

function* watchIotActions() {
  yield all([
    takeLatest(initializeIot, handleInitializeIot),
    takeEvery(onIoTMessage, handleOnIoTMessage)
  ])
}

export default [watchIotActions]
