import React, { Fragment } from 'react'
import moment from 'moment'
import { CampaignSubUI, CampaignUI, isParentCampaign } from '@seesignage/seesignage-utils'
import {
  isDirty,
  isInvalid,
  submit,
  formValueSelector,
  isSubmitting,
  getFormSyncWarnings
} from 'redux-form'
import { DialogContent, Typography, DialogActions, Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useSelector, connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Dispatch } from 'redux'
import { isCampaignSubUI } from '../../../types/campaigns'
import Dialog from '../../Dialog'
import { CloseDialog } from '../../../types/actions'
import { publishCampaign, acceptCampaign } from '../../../actions/campaigns'
import { closeDialog } from '../../../actions/dialogs'

const useStyles = makeStyles(() => ({
  buttonsContainer: {
    position: 'relative',
    width: '100%',
    height: '50px',
    top: 10,
    marginBottom: 5
  },
  publishButton: {
    position: 'absolute',
    backgroundColor: '#28a745',
    color: 'white',
    left: 8,
    bottom: 8
  },
  saveButton: {
    position: 'absolute',
    right: 8,
    bottom: 8
  }
}))

interface OwnProps {
  campaign: CampaignUI | CampaignSubUI
}

interface DispatchProps {
  publishCampaign: () => void
  submitCampaignForm: () => void
  acceptCampaign: () => void
  closeDialog: CloseDialog
}

type EditCampaignButtonsProps = OwnProps & DispatchProps

const EditCampaignButtons: React.FC<EditCampaignButtonsProps> = ({
  campaign,
  publishCampaign,
  submitCampaignForm,
  acceptCampaign,
  closeDialog
}) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const form = 'EditCampaignForm'
  const isPublished = isParentCampaign(campaign) && campaign?.published
  const isAccepted = isCampaignSubUI(campaign) && campaign?.accepted
  // note: "isDirty" not working with price fields because handleEditCampaignFormChange saga
  // dispatch initialize from action that initialized the whole form to initial state.
  // therefore, we have custom "changed" prop that is true when prices are changed
  const isFormDirty = useSelector(isDirty(form))
  const hasFormChanged = useSelector(state => formValueSelector(form)(state, 'changed'))
  const isFormInvalid = useSelector(isInvalid(form))
  const formSubmitting = useSelector(isSubmitting(form))
  const formWarnings = useSelector(getFormSyncWarnings(form))
  const hasWarnings = Object.keys(formWarnings).length > 0
  return (
    <div className={classes.buttonsContainer}>
      <Dialog
        dialogId={isParentCampaign(campaign) ? 'publishCampaignDialog' : 'acceptCampaignDialog'}
        title={`${t(
          `campaigns.editCampaign.${
            isParentCampaign(campaign) ? 'publishDialogTitle' : 'acceptDialogTitle'
          }`,
          { name: campaign.name }
        )}`}
        buttonLabel={
          isParentCampaign(campaign)
            ? isPublished
              ? t('campaigns.published', {
                  publishedAt: moment(campaign.publishedAt).format('DD.MM.YYYY')
                })
              : t('campaigns.editCampaign.publish')
            : isAccepted
            ? t('campaigns.accepted', {
                acceptedAt: moment(campaign.acceptedAt).format('DD.MM.YYYY')
              })
            : t('campaigns.editCampaign.accept')
        }
        buttonDisabled={
          isPublished || isAccepted || isFormDirty || isFormInvalid || hasWarnings || hasFormChanged
        }
        buttonVariant='contained'
        buttonId='publish-campaign'
        buttonClassName={classes.publishButton}
        Content={
          <Fragment>
            <DialogContent>
              <Typography display='block' variant='body1'>
                {t(
                  `campaigns.editCampaign.${
                    isParentCampaign(campaign) ? 'publishDialogInfo' : 'acceptDialogInfo'
                  }`
                )}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => closeDialog('PublishCampaignDialog')} color='primary'>
                {t('general.cancel')}
              </Button>
              <Button
                disabled={formSubmitting}
                onClick={() => (isParentCampaign(campaign) ? publishCampaign() : acceptCampaign())}
                color='primary'
                autoFocus>
                {isParentCampaign(campaign)
                  ? t('campaigns.editCampaign.publish')
                  : t('campaigns.editCampaign.accept')}
              </Button>
            </DialogActions>
          </Fragment>
        }
      />
      <Button
        onClick={() => submitCampaignForm()}
        id='save-campaign'
        disabled={formSubmitting || (!isFormDirty && !hasFormChanged)}
        className={classes.saveButton}
        color='primary'
        variant='contained'>
        {t('general.save')}
      </Button>
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  publishCampaign: () => dispatch(publishCampaign()),
  acceptCampaign: () => dispatch(acceptCampaign()),
  closeDialog: (dialogId: string) => dispatch(closeDialog(dialogId)),
  submitCampaignForm: () => dispatch(submit('EditCampaignForm'))
})

export default connect(null, mapDispatchToProps)(EditCampaignButtons)
