import { LocalStorageTours, Tour } from '../../types/tours'

const TOURS_PARAMETER = 'tours'

const getTours = () => {
  const toursString = localStorage.getItem(TOURS_PARAMETER)
  const tours: LocalStorageTours = toursString ? JSON.parse(toursString) : {}
  return tours
}

const storeTourStatus = (tour: Tour, status: string) => {
  const tours = getTours()
  tours[tour] = status
  localStorage.setItem(TOURS_PARAMETER, JSON.stringify(tours))
}

const shouldTourRun = (tour: Tour): boolean => {
  const tours = getTours()
  // do not run tour again if it has some status stored.
  return typeof tours[tour] === 'string' ? false : true
}

export { storeTourStatus, shouldTourRun }
