import makeStyles from '@mui/styles/makeStyles'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Field, formValueSelector, reduxForm, InjectedFormProps } from 'redux-form'
import {
  List,
  Channel,
  AutocompleteOption,
  PlaylistUI,
  EnvironmentScreenFeatures,
  ScreenType
} from '@seesignage/seesignage-utils'
import { selectAllChannelsAsArray } from '../../../selectors/channels'
import { selectOwnListsAsArray } from '../../../selectors/lists'
import { selectAllPlaylistsAsArray } from '../../../selectors/playlists'
import { selectScreensDefaultLocations } from '../../../selectors/screens'
import { selectIsUserDeviceMobile, isUserSystemAdmin, isUserAdmin } from '../../../selectors/users'
import { compareStrings } from '../../../utils/sorting'
import { required, volume, validateOnOffTimers } from '../../../validation'
import { StateInterface } from '../../../types/states'
import { CloseDialog } from '../../../types/actions'
import {
  selectEnvironmentScreenFeatures,
  selectCurrentEnvironmentTagsAsOptions,
  selectCurrentEnvironmentPermissionTagsAsOptions
} from '../../../selectors/environments'
import { selectEnvironmentIdFromPathname } from '../../../selectors/routing'
import {
  ReduxTextField,
  ReduxSwitch,
  ReduxGoogleAutocomplete,
  ReduxCreatableAutocomplete
} from '../../../components/FormInput/ReduxWrappers'
import SimpleExtensionPanel from '../../../components/ExtensionPanels/SimpleExtensionPanel'
import ErrorMessage from '../../../components/Errors/ErrorMessage'
import SelectMultipleMediaField from '../../../components/FormInput/SelectMedia/SelectMultipleMediaField'
import ContentSelectionField, { ContentSelectionFieldProps } from './Fields/ContentSelectionField'
import OnOffTimerField from './Fields/OnOffTimerField'
import ScreenTypeField from './Fields/ScreenTypeField'

const useStyles = makeStyles(theme => ({
  formContainer: {
    marginBottom: theme.spacing()
  },
  dialogActionsMobile: {
    justifyContent: 'center'
  },
  title: {
    width: '100%',
    padding: '8px 0px 0px 8px'
  },
  paper: {
    width: '98%',
    padding: '8px 8px 12px 8px',
    marginBottom: 8
  }
}))

interface OwnProps {
  submitAction: (formData: any) => void
  closeDialog: CloseDialog
  dialogId: string
  submitButtonLabel: string
}

interface StateProps {
  environmentId?: string
  type: ScreenType
  playlists: PlaylistUI[]
  lists: List[]
  channels: Channel[]
  existingLocations: AutocompleteOption[]
  existingTags: AutocompleteOption[]
  permissionTagsAsOptions: AutocompleteOption[]
  isMobile: boolean
  isSystemAdmin: boolean
  isAdmin: boolean
  environmentScreenFeatures?: EnvironmentScreenFeatures
}

type ComponentProps = OwnProps & StateProps

type CreateScreenFormProps = ComponentProps

const CreateScreenForm: React.FC<CreateScreenFormProps & InjectedFormProps<{}, ComponentProps>> = ({
  handleSubmit,
  submitting,
  submitAction,
  closeDialog,
  dialogId,
  submitButtonLabel,
  playlists,
  lists,
  channels,
  existingLocations,
  existingTags,
  permissionTagsAsOptions,
  error,
  type,
  environmentId,
  isMobile,
  isSystemAdmin,
  isAdmin,
  environmentScreenFeatures,
  form
}) => {
  const [t] = useTranslation()
  const classes = useStyles()
  const contentSelectionProps: ContentSelectionFieldProps = {
    environmentId,
    type,
    playlists,
    lists,
    channels,
    t,
    isSystemAdmin,
    isAdmin
  }
  return (
    <form onSubmit={handleSubmit(submitAction)}>
      <SimpleExtensionPanel title={t('screens.createScreenForm.screenInformation')}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Field
              id='name'
              fullWidth
              name='name'
              label={t('screens.name')}
              component={ReduxTextField}
              required
              validate={[required]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              fullWidth
              id='productNumber'
              name='productNumber'
              label={t('screens.createScreenForm.productNo')}
              component={ReduxTextField}
              required
              validate={[required]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              id='identifier'
              label={t('screens.identifier')}
              name='identifier'
              component={ReduxTextField}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              fullWidth
              name='location'
              label={t('location.location')}
              existingLocations={existingLocations}
              component={ReduxGoogleAutocomplete}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              fullWidth
              name='locationId'
              label={t('screens.locationId')}
              helperText={t('screens.locationIdHelper')}
              component={ReduxTextField}
            />
          </Grid>
        </Grid>
      </SimpleExtensionPanel>
      <SimpleExtensionPanel title={t('screens.createScreenForm.configurations')}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6}>
            <Field
              id='rotation'
              label={t('screens.screenOrientation')}
              name='rotation'
              component={ReduxTextField}
              fullWidth
              select>
              <MenuItem value={0}>{t('screens.rotation.0')}</MenuItem>
              <MenuItem value={90}>{t('screens.rotation.90')}</MenuItem>
              <MenuItem value={270}>{t('screens.rotation.270')}</MenuItem>
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              id='volume'
              label={t('screens.createScreenForm.volume')}
              name='configs.volume'
              component={ReduxTextField}
              fullWidth
              type='number'
              inputProps={{ min: 0, max: 100, step: 1 }}
              parse={(value: any) => (!value ? null : Number(value))}
              validate={[volume]}
            />
          </Grid>
          {environmentScreenFeatures?.proofOfPlay && (
            <Grid item xs={6}>
              <Field
                name='analytics.proofOfPlay'
                component={ReduxSwitch}
                label={t('screens.proofOfPlay')}
              />
              <Typography display='block' variant='caption'>
                {t('screens.proofOfPlayHelper')}
              </Typography>
            </Grid>
          )}
          <Grid item xs={6}>
            <Field
              name='configs.security.safetyLock'
              component={ReduxSwitch}
              label={t('screens.createScreenForm.safetyLock')}
            />
            <Typography display='block' variant='caption'>
              {t('screens.createScreenForm.safetyLockHelper')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Field
              name='configs.ecoSensor.isOn'
              component={ReduxSwitch}
              label={t('screens.createScreenForm.ecoSensor')}
            />
            <Typography display='block' variant='caption'>
              {t('screens.createScreenForm.ecoSensorHelper')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Field name='showBarcode' component={ReduxSwitch} label={t('screens.showBarcode')} />
            <Typography display='block' variant='caption'>
              {t('screens.showBarcodeHelper')}
            </Typography>
          </Grid>
          {isSystemAdmin && (
            <Fragment>
              <Grid item xs={6}>
                <Field name='billable' component={ReduxSwitch} label={t('screens.billable')} />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name='isTest'
                  component={ReduxSwitch}
                  label={t('screens.createScreenForm.isTest')}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name='optimization.reduceServerLoadIot'
                  component={ReduxSwitch}
                  label={t('screens.createScreenForm.reduceServerLoadIot')}
                />
                <Typography display='block' variant='caption'>
                  {t('screens.createScreenForm.reduceServerLoadIotHelper')}
                </Typography>
              </Grid>
            </Fragment>
          )}
          {environmentScreenFeatures?.syncPlay && (
            <Grid item xs={6}>
              <Field
                name='syncPlay'
                component={ReduxSwitch}
                label={t('screens.createScreenForm.syncPlay')}
              />
              <Typography display='block' variant='caption'>
                {t('screens.createScreenForm.syncPlayHelper')}
              </Typography>
            </Grid>
          )}
        </Grid>
      </SimpleExtensionPanel>
      <SimpleExtensionPanel title={t('screens.defaultMedia')}>
        <div>
          <Typography variant='caption'>
            {t('screens.createScreenForm.defaultMediaHelper')}
          </Typography>
          <Field
            isSingleFileInput
            formName={form}
            name='defaultMedia'
            component={SelectMultipleMediaField}
          />
        </div>
      </SimpleExtensionPanel>
      <SimpleExtensionPanel title={t('screens.createScreenForm.content')} isOpen={true}>
        <Grid container spacing={2}>
          {environmentScreenFeatures?.types && (
            <Grid item xs={12} sm={12}>
              <Field
                id='type'
                label={t('screens.type')}
                environmentScreenTypes={environmentScreenFeatures.types}
                name='type'
                component={ScreenTypeField}
                required
                validate={[required]}
                isMobile={isMobile}
                t={t}
              />
            </Grid>
          )}
          {type && <ContentSelectionField {...contentSelectionProps} />}
        </Grid>
      </SimpleExtensionPanel>
      <SimpleExtensionPanel title={t('screens.createScreenForm.timers')}>
        <OnOffTimerField t={t} classes={classes} />
      </SimpleExtensionPanel>
      <SimpleExtensionPanel title={t('screens.createScreenForm.tags')}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <div>
              <Field
                name='tags'
                id='tags'
                placeholder={t('screens.addTags')}
                component={ReduxCreatableAutocomplete}
                label={t('screens.tags')}
                options={existingTags}
                multiple
              />
              <Typography variant='caption'>{t('screens.tagsHelper')}</Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={12}>
            <div>
              <Field
                id='permissionTags'
                name='permissionTags'
                placeholder={t('screens.addTags')}
                component={ReduxCreatableAutocomplete}
                label={t('screens.permissionTags')}
                options={permissionTagsAsOptions}
                multiple
              />
              <Typography variant='caption'>{t('screens.permissionTagHelper')}</Typography>
            </div>
          </Grid>
        </Grid>
      </SimpleExtensionPanel>
      {isSystemAdmin && (
        <SimpleExtensionPanel title={t('screens.createScreenForm.pdaConfigurations')}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Field
                name='pdaConfigs.allowOnlyEditLists'
                component={ReduxSwitch}
                label={t('screens.createScreenForm.allowOnlyEditLists')}
              />
              <Typography display='block' variant='caption'>
                {t('screens.createScreenForm.allowOnlyEditListsHelper')}
              </Typography>
            </Grid>
          </Grid>
        </SimpleExtensionPanel>
      )}
      <SimpleExtensionPanel title={t('screens.createScreenForm.notes')}>
        <Grid container spacing={2}>
          {isSystemAdmin && (
            <Grid item xs={12}>
              <Field
                id='notes.systemAdmin'
                label={t('screens.createScreenForm.notesForSysAdmin')}
                name='notes.systemAdmin'
                component={ReduxTextField}
                fullWidth
                multiline
                minRows={2}
                maxRows={4}
                isToolbar
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Field
              id='notes.environmentAdmin'
              label={t('screens.createScreenForm.notesForEnvAdmin')}
              name='notes.environmentAdmin'
              component={ReduxTextField}
              fullWidth
              multiline
              minRows={2}
              maxRows={4}
              isToolbar
            />
          </Grid>
        </Grid>
      </SimpleExtensionPanel>
      <Grid container spacing={2} className={classes.formContainer}>
        <Grid item xs={12} sm={12}>
          <ErrorMessage message={error} />
        </Grid>
      </Grid>
      <DialogActions className={isMobile ? classes.dialogActionsMobile : undefined}>
        <Button onClick={() => closeDialog(dialogId)} color='primary'>
          {t('general.cancel')}
        </Button>
        <Button disabled={submitting} color='primary' type='submit'>
          {submitButtonLabel}
        </Button>
      </DialogActions>
    </form>
  )
}

const validate = (values: any) => {
  const errors: any = {}
  return validateOnOffTimers(errors, values)
}

const selector = formValueSelector('CreateScreenForm')

const mapStateToProps = (state: StateInterface): StateProps => {
  const environmentId = selectEnvironmentIdFromPathname(state)
  const allPlaylists = selectAllPlaylistsAsArray(state)
  const allChannels = selectAllChannelsAsArray(state)
  // if screen has sync play property allow adding only sync playlists or sync publishing channels to the screen
  const isSyncPlay = selector(state, 'syncPlay') ? true : false
  const filteredPlaylists = isSyncPlay
    ? allPlaylists.filter(({ syncPlay }) => syncPlay)
    : allPlaylists
  const filteredChannels = isSyncPlay ? allChannels.filter(({ syncPlay }) => syncPlay) : allChannels
  return {
    environmentId,
    type: selector(state, 'type'),
    playlists: filteredPlaylists.sort(compareStrings('name')),
    channels: filteredChannels.sort(compareStrings('name')),
    lists: selectOwnListsAsArray(environmentId)(state).sort(compareStrings('name')),
    existingLocations: selectScreensDefaultLocations(state),
    existingTags: selectCurrentEnvironmentTagsAsOptions(state),
    permissionTagsAsOptions: selectCurrentEnvironmentPermissionTagsAsOptions(state),
    isMobile: selectIsUserDeviceMobile(state),
    isSystemAdmin: isUserSystemAdmin(state),
    isAdmin: isUserAdmin(state),
    environmentScreenFeatures: selectEnvironmentScreenFeatures(environmentId)(state)
  }
}

export default connect<StateProps, null, OwnProps, StateInterface>(mapStateToProps)(
  reduxForm<{}, ComponentProps>({
    form: 'CreateScreenForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    updateUnregisteredFields: true,
    validate
  })(CreateScreenForm)
)
