import {
  EmmiErrorCode,
  EMMiAssetsByTags,
  EMMiAssetsByCustomSchedule,
  EMMiPreviewAsset
} from '@seesignage/seesignage-utils'

export enum ImportFolderWizardPage {
  selectFolder = 'selectFolder',
  previewFolder = 'previewFolder'
}

export interface ImportFolderResponse {
  success: boolean
  message: string
  errorCode?: EmmiErrorCode
}

export interface EMMiFolderReport {
  assetsByTags: EMMiAssetsByTags
  assetsByScreenTypes: {
    [screenType: string]: string[]
  }
  assetsByCustomSchedule: EMMiAssetsByCustomSchedule
  assets: {
    [assetId: string]: EMMiPreviewAsset
  }
  extraContent: {
    assetId: string
    screenTypes: string[]
  }[]
}

export interface EMMiFolderReportError {
  isError: boolean
  message: string
  errorCode?: EmmiErrorCode
}

export type GetEMMiFolderByIdResponse = EMMiFolderReport | EMMiFolderReportError

export const isEmmiFolderReport = (item: any): item is EMMiFolderReport =>
  item?.assets !== undefined
