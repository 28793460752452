import {
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  Typography,
  Checkbox,
  CardActions,
  Skeleton
} from '@mui/material'
import {
  SocialMediaItem,
  SocialMediaItemType,
  SocialMediaPlatform
} from '@seesignage/seesignage-utils'
import React from 'react'
import ImageIcon from 'mdi-react/ImageIcon'
import VideoIcon from 'mdi-react/VideoIcon'
import BookmarkOutlineIcon from 'mdi-react/BookmarkOutlineIcon'
import HeartOutlineIcon from 'mdi-react/HeartOutlineIcon'
import CommentOutlineIcon from 'mdi-react/CommentOutlineIcon'
import PlayOutlineIcon from 'mdi-react/PlayOutlineIcon'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  header: {
    padding: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(1)
  },
  iconBlock: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 15,
    marginRight: '3px'
  },
  icon: {
    marginRight: '3px'
  }
}))

const MediaCardSkeleton = ({ platform }: { platform: SocialMediaPlatform }) => (
  <Card
    sx={{
      height: platform === SocialMediaPlatform.instagram ? '270px' : '250px',
      width: '300px'
    }}>
    <CardHeader
      avatar={<Skeleton animation='wave' height={10} width={10} style={{ marginBottom: 6 }} />}
      action={<Skeleton animation='wave' height={10} width={10} style={{ marginBottom: 6 }} />}
    />
    <Skeleton variant='rectangular' animation='wave' height={140} />
    <CardContent sx={{ padding: 1 }}>
      <Skeleton animation='wave' height={10} width='100%' />
    </CardContent>
    {platform === SocialMediaPlatform.instagram && (
      <CardActions>
        <Skeleton animation='wave' height={10} width={10} />
        <Skeleton animation='wave' height={10} width={10} />
        <Skeleton animation='wave' height={10} width={10} />
        <Skeleton animation='wave' height={10} width={10} />
      </CardActions>
    )}
  </Card>
)

const numberFormatter = new Intl.NumberFormat('en-US', {
  notation: 'compact',
  maximumSignificantDigits: 3
})

interface MediaCardProps {
  item: SocialMediaItem
  platform: SocialMediaPlatform
  disableSelect: boolean
  isSelected: boolean
  onSelectItem: (item: SocialMediaItem, checked: boolean) => void
}

const MediaCard = ({ disableSelect, item, onSelectItem, isSelected, platform }: MediaCardProps) => {
  const classes = useStyles()
  const isImageFile = item.mediaType === SocialMediaItemType.image
  const { mediaUrl, caption, insights } = item
  return (
    <Card
      sx={{
        height: platform === SocialMediaPlatform.instagram ? '270px' : '250px',
        width: '300px'
      }}>
      <CardHeader
        avatar={isImageFile ? <ImageIcon /> : <VideoIcon />}
        action={
          <Checkbox
            onChange={evt => onSelectItem(item, evt.target.checked)}
            checked={isSelected}
            disabled={disableSelect}
          />
        }
        className={classes.header}
      />
      {isImageFile ? (
        <CardMedia
          component='img'
          src={mediaUrl}
          alt='social media photo/video'
          height={140}
          loading='eager'
        />
      ) : (
        <CardMedia component='video' src={mediaUrl} muted autoPlay loop height={140} playsInline />
      )}
      <CardContent className={classes.content}>
        <Typography variant='body2' color='text.secondary' textOverflow='ellipsis' noWrap>
          {caption}
        </Typography>
      </CardContent>
      {platform === SocialMediaPlatform.instagram && insights && (
        <CardActions>
          {typeof insights.likeCount === 'number' && (
            <span className={classes.iconBlock}>
              <HeartOutlineIcon size={20} className={classes.icon} />
              {numberFormatter.format(insights.likeCount)}
            </span>
          )}
          {typeof insights.commentCount === 'number' && (
            <span className={classes.iconBlock}>
              <CommentOutlineIcon size={20} className={classes.icon} />
              {numberFormatter.format(insights.commentCount)}
            </span>
          )}
          {typeof insights.saveCount === 'number' && (
            <span className={classes.iconBlock}>
              <BookmarkOutlineIcon size={20} className={classes.icon} />
              {numberFormatter.format(insights.saveCount)}
            </span>
          )}
          {typeof insights.viewCount === 'number' && (
            <span className={classes.iconBlock}>
              <PlayOutlineIcon size={20} className={classes.icon} />
              {numberFormatter.format(insights.viewCount)}
            </span>
          )}
        </CardActions>
      )}
    </Card>
  )
}

export { MediaCardSkeleton }

export default MediaCard
