import { API } from 'aws-amplify'
import {
  FileMetadata,
  Media,
  MediaConversion,
  MediaDocument,
  MediaType,
  AddMediaBody,
  EMMiFile,
  MediaFolder,
  CreateMediaFolder
} from '@seesignage/seesignage-utils'
import { isSafari } from 'react-device-detect'
import { MediaUrlsByKey } from '../../types/media'
import { IndexById } from '../../types/states'

/**
 * Get media files from dynamo db. Use mediaType to get only specific media types
 * @param environmentId
 */
const getMediaFiles = async (environmentId: string, mediaType?: MediaType) => {
  const options = mediaType
    ? {
        queryStringParameters: {
          mediaType
        }
      }
    : {}
  const { files } = await API.get('media', `/media/environments/${environmentId}`, options)
  return files as IndexById<Media>
}

/**
 * Delete media file from transcoded media bucket.
 * Note: mediaKey is in following format:
 * {environmentId}/{mediaType}s/{mediaName}
 */
const deleteMediaFile = async (mediaKey: string) => {
  const encodedKey = encodeURI(mediaKey)
  await API.del('media', `/media/${encodedKey}`, {})
}

const addMediaFile = async (
  environmentId: string,
  body: AddMediaBody
): Promise<MediaConversion | MediaDocument> => {
  if (isSafari) {
    // safari filename fix
    body.name = body.name.replace(/[^a-z0-9.]+/gi, '')
  }
  const response = await API.post('media', `/media/environments/${environmentId}`, {
    body
  })
  return response
}

/**
 * Change parentFolderId property of given media keys
 * @param environmentId
 * @param folderId
 * @param keys
 */
const addToFolder = async (environmentId: string, folderId: string | null, keys: string[]) => {
  const folder = await API.put('media', `/media/environments/${environmentId}/folder`, {
    body: {
      folderId,
      keys
    }
  })
  return folder
}

/**
 * Rename media
 * @param environmentId
 * @param key
 * @param name
 */
const renameMediaFile = async (environmentId: string, key: string, name: string) => {
  const { newName } = await API.put('media', `/media/environments/${environmentId}/key/rename`, {
    body: { key, name }
  })
  return newName
}

/**
 * Get common file metadata.
 * example key: common/backgrounds/bricks
 * orientation: "portrait" or "landscape"
 */
const getCommonFileMetadata = async (key: string, orientation: string): Promise<FileMetadata> => {
  const commonFileMetadata: FileMetadata = await API.get('media', `/media/${key}${orientation}`, {})
  return commonFileMetadata
}

const getFileMetadata = async (key: string) => {
  const fileMetadata = await API.get('media', key, {})
  return fileMetadata
}

const addEMMiFile = async (environmentId: string, file: EMMiFile, parentFolderId?: string) => {
  await API.post('media', `/media/environments/${environmentId}/emmi`, {
    body: {
      file,
      parentFolderId
    }
  })
}
const getMediaUrlsByKey = async (
  keys: string[],
  includeThumbnails?: boolean,
  checkExists?: boolean
) => {
  const queryStringParameters = checkExists ? { checkExists } : undefined
  const options = includeThumbnails
    ? {
        body: { keys, includeThumbnails },
        queryStringParameters
      }
    : {
        body: { keys },
        queryStringParameters
      }
  const medialUrlsByKey: MediaUrlsByKey = await API.post('media', `/media/urls`, options)
  return medialUrlsByKey
}

const getMediaUrl = async (key: string, includeThumbnails?: boolean) => {
  const urlsByKey = await getMediaUrlsByKey([key], includeThumbnails)
  return urlsByKey[key]
}

const getFolders = async (environmentId: string) => {
  const folders: MediaFolder[] = await API.get(
    'media',
    `/environments/${environmentId}/folders`,
    {}
  )
  return folders
}

const createFolder = async (environmentId: string, folder: CreateMediaFolder) => {
  const newFolder: MediaFolder = await API.post('media', `/environments/${environmentId}/folders`, {
    body: folder
  })
  return newFolder
}

const updateFolder = async (environmentId: string, folderId: string, folder: CreateMediaFolder) => {
  const updatedFolder: MediaFolder = await API.put(
    'media',
    `/environments/${environmentId}/folders/${folderId}`,
    { body: folder }
  )
  return updatedFolder
}

const deleteFolder = async (environmentId: string, folderId: string) => {
  await API.del('media', `/environments/${environmentId}/folders/${folderId}`, {})
}

export default {
  getMediaFiles,
  deleteMediaFile,
  addMediaFile,
  addToFolder,
  getCommonFileMetadata,
  renameMediaFile,
  getFileMetadata,
  addEMMiFile,
  getMediaUrlsByKey,
  getMediaUrl,
  createFolder,
  updateFolder,
  getFolders,
  deleteFolder
}
