import { AppBar, IconButton, Toolbar, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import CloseIcon from 'mdi-react/CloseIcon'
import DeleteIcon from 'mdi-react/DeleteIcon'
import PlusIcon from 'mdi-react/PlusIcon'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { isRetailProduct, RetailListUI, ProductForm } from '@seesignage/seesignage-utils'
import { useDispatch } from 'react-redux'
import { closeDialog as closeDialogAction } from '../../../../actions/dialogs'
import { deleteListItem, deselectAllListItems } from '../../../../actions/lists'
import { createOrUpdateRetailProduct, prefillRetailProductForm } from '../../../../actions/products'
import { bindSubmitActionToPromise } from '../../../../utils/forms'
import Dialog from '../../../Dialog'
import CreateRetailProductForm from '../../Forms/CreateRetailProductForm'
import SearchProductForm from '../../Forms/SearchProductForm'
import { toolbarStyles } from '../../../../styles/common'
import EditListItem from './EditListItem'

const useStyles = makeStyles(theme => ({
  ...toolbarStyles(theme),
  hrSpacer: {
    borderLeft: '1px solid black'
  }
}))

export interface EditListToolbarProps {
  list: RetailListUI
  selectedListItemIds: string[]
  customerProductForm?: ProductForm
}

const EditListToolbar: React.FC<EditListToolbarProps> = ({
  selectedListItemIds,
  list: { listId, items },
  customerProductForm
}) => {
  const [t] = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const closeDialog = (dialogId: string) => dispatch(closeDialogAction(dialogId))
  const singleSelectedListItem =
    selectedListItemIds.length === 1
      ? items.find(({ itemId }) => selectedListItemIds[0] === itemId)
      : undefined
  return (
    <AppBar className={classes.appBar} position='sticky' color='default'>
      <Toolbar>
        {selectedListItemIds.length === 0 && <SearchProductForm />}
        <div className={classes.spacer} />
        <div className={classes.actions}>
          {selectedListItemIds.length > 0 && (
            <Fragment>
              {customerProductForm &&
                singleSelectedListItem &&
                isRetailProduct(singleSelectedListItem.product) && (
                  <EditListItem listId={listId} product={singleSelectedListItem.product} />
                )}
              <Tooltip disableInteractive title={t('product.removeProduct')}>
                <IconButton
                  onClick={() => dispatch(deleteListItem())}
                  aria-label={t('product.removeProduct')}
                  size='large'>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              <Tooltip disableInteractive title={t('general.deselect')}>
                <IconButton onClick={() => dispatch(deselectAllListItems())} size='large'>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Fragment>
          )}
          {customerProductForm && (
            <Fragment>
              <hr className={classes.hrSpacer} />
              <Dialog
                dialogId='createRetailProductDialog'
                title={t('lists.createProduct')}
                tooltipTitle={t('lists.createProduct')}
                ButtonIcon={PlusIcon}
                Content={
                  <CreateRetailProductForm
                    dialogId='createRetailProductDialog'
                    onSubmit={bindSubmitActionToPromise(dispatch, createOrUpdateRetailProduct)}
                    closeDialog={closeDialog}
                    submitButtonLabel={t('general.add')}
                    prefillRetailProductForm={prefillRetailProductForm}
                    initialValues={{ listId }}
                  />
                }
              />
            </Fragment>
          )}
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default EditListToolbar
