/**
 * Convert input code to ean-13
 * @param code
 */
export const convertToEan = (code: string) => {
  if (code.length === 13) {
    return sanitateEanCode(code)
  }
  if (code.length !== 4) {
    return undefined
  }
  const start = `2000${code}0000`
  const reverse = start.split('').reverse()
  let odd = 0
  let even = 0
  for (let i = 0; i < reverse.length; i++) {
    if (i % 2 === 0) {
      odd += parseInt(reverse[i], 10)
    } else {
      even += parseInt(reverse[i], 10)
    }
  }
  const checksum = (10 - ((3 * odd + even) % 10)) % 10
  return `${start}${checksum}`
}

/**
 * Calculate ean check digit
 * @param ean
 */
const eanCheckDigit = (ean: string) => {
  let sum = 0
  for (let i = 0; i < ean.length; i++) {
    let digit = Number(ean[ean.length - i - 1])
    if (i % 2 === 0) {
      digit *= 3
    }
    sum += digit
  }
  return (10 - (sum % 10)) % 10
}

/**
 * Sanitate pre weighed ean-13 code
 * @param productCode
 */
const sanitateEanCode = (productCode: string) => {
  const productCodeValue = productCode ? ('0000000000000' + productCode).substr(-13) : productCode

  const prefix = Number(productCode.substring(0, 2))
  let eanBase = ''

  switch (prefix) {
    case 20:
    case 21:
    case 22:
    case 23:
    case 24:
    case 25:
      eanBase = '20' + productCodeValue.substring(2, 8) + '0000'
      break
    case 28:
      eanBase = '28' + productCodeValue.substring(2, 7) + '00000'
      break
    case 29:
      eanBase = '29' + productCodeValue.substring(2, 7) + '00000'
      break
    default:
      return productCodeValue
  }
  return eanBase + eanCheckDigit(eanBase)
}
