import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { Dispatch } from 'redux'
import { useLocation } from 'react-router'
import { Paper, IconButton } from '@mui/material'
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon'
import { forgotPassword, forgotPasswordSubmit } from '../../../actions/users'
import { selectUserIsAuthenticated } from '../../../selectors/users'
import { bindSubmitActionToPromise } from '../../../utils/forms'
import { StateInterface } from '../../../types/states'
import Background from '../../../components/Background'
import colors from '../../../styles/common/colors'
import { navigate } from '../../../actions/routes'
import ForgotPasswordForm from './ForgotPasswordForm'
import ForgotPasswordSubmitForm from './ForgotPasswordSubmitForm'
import { ForgotPasswordLinkSent } from '.'

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '30%',
    position: 'relative' as any,
    top: '20%',
    [theme.breakpoints.down('lg')]: {
      width: '90%',
      top: '0',
      height: '100%'
    },
    [theme.breakpoints.down('md')]: {
      height: '100vh'
    },
    margin: '10px auto',
    padding: theme.spacing()
  },
  signup: {
    marginTop: '10px'
  },
  errorMessage: {
    color: 'red',
    marginTop: '10px'
  },
  backButton: {
    position: 'absolute',
    top: 4,
    left: 4
  }
}))

interface StateProps {
  isAuthenticated?: boolean
}

interface DispatchProps {
  navigate: (path: string) => void
  forgotPassword: (formData: any) => void
  forgotPasswordSubmit: (formData: any) => void
}

interface OwnProps {
  match: any
}

type ForgotPasswordProps = OwnProps & StateProps & DispatchProps

const useQuery = () => new URLSearchParams(useLocation().search)

const ForgotPassword = ({
  forgotPassword,
  forgotPasswordSubmit,
  navigate,
  isAuthenticated,
  match: {
    params: { type }
  }
}: ForgotPasswordProps) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const query = useQuery()
  const email = query.get('email')
  const code = query.get('code')
  return (
    <Background>
      <Paper className={classes.form}>
        {isAuthenticated ? (
          <Typography className={classes.signup} variant='body2' gutterBottom>
            {t('forgotPw.alreadyLogged')}
          </Typography>
        ) : (
          <Fragment>
            <IconButton
              className={classes.backButton}
              onClick={() => navigate('/login')}
              size='large'>
              <ArrowLeftIcon color={colors.seesignageColor} />
            </IconButton>
            <Typography className={classes.signup} variant='h6' gutterBottom>
              {t('forgotPw.reset')}
            </Typography>
            {type === 'reset' && <ForgotPasswordForm forgotPassword={forgotPassword} />}
            {type === 'submit' &&
              (email && code ? (
                <ForgotPasswordSubmitForm
                  forgotPassword={forgotPasswordSubmit}
                  initialValues={{
                    email,
                    code
                  }}
                />
              ) : (
                <Typography color='error'>{t('forgotPw.codeMissing')}</Typography>
              ))}
            {type === 'linkSent' && <ForgotPasswordLinkSent />}
          </Fragment>
        )}
      </Paper>
    </Background>
  )
}

const mapStateToProps = (state: StateInterface): StateProps => ({
  isAuthenticated: selectUserIsAuthenticated(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  navigate: (path: string) => dispatch(navigate(path)),
  forgotPassword: bindSubmitActionToPromise(dispatch, forgotPassword),
  forgotPasswordSubmit: bindSubmitActionToPromise(dispatch, forgotPasswordSubmit)
})

export default connect<StateProps, DispatchProps, OwnProps, StateInterface>(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword)
