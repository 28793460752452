import React from 'react'
import { reduxForm, InjectedFormProps, Field } from 'redux-form'
import { InputAdornment } from '@mui/material'
import MinusThickIcon from 'mdi-react/MinusThickIcon'
import { useTranslation } from 'react-i18next'
import { ContentLineFormData } from '../../../../../types/contents'
import ContentColorPicker from '../../../../../components/FormInput/ColorPicker/ContentColorPicker'
import ReduxTextFieldWithDragChange from '../../../../../components/FormInput/ReduxWrappers/ReduxTextFieldWithDragChange'
import ContentToolbarItem from '../ContentToolbarItem/ContentToolbarItem'
import CommonContentFields from './CommonContentFields'

type FormProps = ContentLineFormData

const ContentLineForm: React.FC<InjectedFormProps<FormProps>> = () => {
  const [t] = useTranslation()
  return (
    <>
      <CommonContentFields formName='ContentLineForm' enableRotation t={t} />
      <ContentToolbarItem>
        <Field
          name='stroke'
          component={ContentColorPicker}
          tooltip={t('contents.properties.lineColor')}
        />
      </ContentToolbarItem>
      <ContentToolbarItem>
        <Field
          name='strokeWidth'
          isToolbar
          fixedSmallWidth
          component={ReduxTextFieldWithDragChange}
          DragIcon={MinusThickIcon}
          InputProps={{
            inputProps: { min: 1, max: 300 },
            endAdornment: <InputAdornment position='end'>px</InputAdornment>
          }}
          label={t('contents.properties.lineWidth')}
        />
      </ContentToolbarItem>
    </>
  )
}

export default reduxForm<FormProps>({
  form: 'ContentLineForm',
  enableReinitialize: true
})(ContentLineForm)
