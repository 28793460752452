import { SubmissionError } from 'redux-form'
import {
  GeneralProductUI,
  isPricerProduct,
  PlayerRetailProduct,
  PricerProduct,
  ProductTags,
  RetailProduct,
  isPharmacyProduct,
  PharmacyProduct,
  PharmacyMasterProduct
} from '@seesignage/seesignage-utils'
import {
  RetailProductFormData,
  ProductFormTags,
  UpdateGeneralProductFormData,
  UpdatePharmacyProductFormParams
} from '../types/products'
import i18n from '../translations/i18n'
import { TemplateAsFormValue } from '../types/templates'

const getRetailProductFormInitialValues = (
  {
    productId,
    name,
    description,
    country,
    qualityClass,
    scaleNumber,
    unit,
    discount,
    packageSize,
    packageUnit,
    imageUrl,
    price,
    tags
  }: RetailProduct,
  listId?: string // if edit list view
) => {
  let productTags
  if (tags) {
    productTags = tags.reduce((tagsObject: ProductFormTags, tag) => {
      tagsObject[tag] = true
      return tagsObject
    }, {})
  }
  const initialValues: RetailProductFormData = {
    productId,
    name,
    description,
    country,
    qualityClass,
    scaleNumber,
    unit,
    discount,
    packageSize,
    packageUnit,
    imageUrl,
    price,
    productTags,
    listId,
    isUpdate: true // so saga knows to use update function
  }
  return initialValues
}

const getGeneralProductFormInitialValues = ({
  productId,
  name,
  price,
  description,
  discountValidString,
  comparisonUnit,
  discount,
  discountBatchSize,
  discountPrice,
  packageSize,
  packageUnit,
  deposit,
  unit,
  key
}: GeneralProductUI) => {
  const initialValues: UpdateGeneralProductFormData = {
    productId,
    name,
    description,
    price,
    comparisonUnit,
    discount,
    discountBatchSize,
    discountPrice,
    packageSize,
    packageUnit,
    discountValidString,
    deposit,
    unit,
    key
  }
  return initialValues
}

const getGeneralProductFormInitialValuesForPlaylist = (
  {
    productId,
    name,
    price,
    description,
    discountValidString,
    comparisonUnit,
    discount,
    discountBatchSize,
    discountPrice,
    packageSize,
    packageUnit,
    deposit,
    unit,
    key,
    customerId
  }: GeneralProductUI,
  itemId: string,
  template: TemplateAsFormValue
) => {
  const initialValues: UpdateGeneralProductFormData = {
    customerId: { value: customerId, label: '' },
    template,
    productId,
    name,
    description,
    price,
    comparisonUnit,
    discount,
    discountBatchSize,
    discountPrice,
    packageSize,
    packageUnit,
    discountValidString,
    deposit,
    unit,
    key,
    itemId
  }
  return initialValues
}

const getUpdatePharmacyProductInitialValuesForPlaylist = (
  { ean, customerId, price, description, discountPrice }: PharmacyProduct,
  itemId: string,
  template: TemplateAsFormValue
) => {
  const initialValues: UpdatePharmacyProductFormParams = {
    product: {
      ean,
      customerId,
      price,
      discountPrice,
      description
    },
    itemId,
    template
  }
  return initialValues
}

/**
 * Parse form values of selected tags to ProductTags array.
 * Currently throw Submission Error if over 1 is selected
 * @param tags
 */
const parseRetailProductTagsFromFormData = (tags?: ProductFormTags) => {
  if (!tags) {
    return undefined
  }
  const keys = Object.keys(tags) as Array<keyof typeof ProductTags>
  const parsedTags = keys.filter(key => tags[key])
  if (parsedTags.length > 1) {
    throw new SubmissionError({
      _error: i18n.t('error.product.selectOnlyOneTag')
    })
  }
  return parsedTags as ProductTags[]
}

const convertGeneralMockProductsForPlayer = (products: GeneralProductUI[]) =>
  products.map(product => {
    if (product.productMedia?.url) {
      const playerProduct: PlayerRetailProduct = {
        ...product,
        comparisonUnit: product.comparisonUnit ? product.comparisonUnit : undefined,
        imagePath: product.productMedia.url
      }
      return playerProduct
    }
    return product
  })

const parseProductData = (
  product?: GeneralProductUI | RetailProduct | PricerProduct | PharmacyProduct | null
) => {
  if (!product) {
    return {}
  }
  if (isPricerProduct(product)) {
    const { itemId, itemName, sics, properties } = product
    return {
      productId: itemId,
      name: itemName,
      sics: sics?.join(', '),
      url: properties?.image300 || properties?.imageOrig
    }
  } else if (isPharmacyProduct(product)) {
    const { name, price, packageUnit, packageSize, image } = product
    return {
      name,
      price,
      packageUnit,
      packageSize,
      image
    }
  }
  const { productId, name, price, discountPrice, unit, discountBatchSize } = product
  return {
    productId,
    name,
    tags: (product as any).tags,
    scaleNumber: (product as any).scaleNumber,
    url: (product as any).productMedia?.thumbnailUrl || (product as any).productMedia?.url,
    price: discountPrice || price,
    unit,
    discountBatchSize
  }
}

const getPharmacyProductTitle = (item: PharmacyMasterProduct | PharmacyProduct) => {
  const { name, packageSize, packageUnit } = item
  let label = `${name}`
  if (packageSize) {
    label = `${label} ${packageSize} ${packageUnit}`
  } else if (packageUnit) {
    label = `${label} ${packageUnit}`
  }
  return label
}

/**
 * Get common product properties for Pricer Plaza products.
 */
const getPricerProductProperties = ({ itemName: name, properties }: PricerProduct) => ({
  name,
  imageUrl: properties.image300 || (properties as any).productImageUrl,
  description: properties.descriptionShortFi,
  price: properties.priceCurrent
})

export {
  getRetailProductFormInitialValues,
  getGeneralProductFormInitialValues,
  getGeneralProductFormInitialValuesForPlaylist,
  getUpdatePharmacyProductInitialValuesForPlaylist,
  parseRetailProductTagsFromFormData,
  convertGeneralMockProductsForPlayer,
  parseProductData,
  getPharmacyProductTitle,
  getPricerProductProperties
}
