import { Theme, TableRow } from '@mui/material'

import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
      }
    }
  })
)(TableRow)

export default StyledTableRow
