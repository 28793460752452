import { IconButton, Tooltip } from '@mui/material'
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker'
import InfinityIcon from 'mdi-react/InfinityIcon'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { WrappedFieldProps } from 'redux-form'
import { PickersActionBarAction } from '@mui/x-date-pickers'
import { convertDatePickerValue } from '../../../utils/forms'

interface ReduxDateTimePickerProps extends WrappedFieldProps {
  label: string
  fullWidth?: boolean
  setNoEndDate?: boolean
  required?: boolean
}

const ReduxDateTimePicker: React.FC<ReduxDateTimePickerProps> = ({
  input: { value, onChange, onBlur, onFocus },
  label,
  meta: { invalid, touched, error },
  setNoEndDate,
  required,
  ...custom
}) => {
  const [t] = useTranslation()
  return (
    <MobileDateTimePicker
      ampm={false}
      label={label}
      format='dd/MM/yyyy HH:mm'
      value={convertDatePickerValue(value)}
      onChange={onChange}
      displayWeekNumber
      slotProps={{
        actionBar: {
          actions: [...(required ? [] : ['clear' as PickersActionBarAction]), 'cancel', 'accept']
        },
        textField: {
          error: invalid && touched,
          helperText: invalid && touched ? error : undefined,
          required,
          variant: 'standard',
          InputProps: {
            endAdornment: setNoEndDate ? (
              <Tooltip disableInteractive title={t('date.runIndefinitely')}>
                <IconButton
                  onClick={e => {
                    e.stopPropagation()
                    onChange(new Date(2099, 11, 31, 12, 0))
                  }}
                  size='large'>
                  <InfinityIcon size={16} color='#1e88e5' />
                </IconButton>
              </Tooltip>
            ) : (
              undefined
            )
          },
          onBlur: () => onBlur(value),
          onFocus: () => onFocus(value)
        }
      }}
      {...custom}
    />
  )
}

export default ReduxDateTimePicker
