import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { WithStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import withStyles from '@mui/styles/withStyles'
import dateFormat from 'dateformat'
import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { ScreenResponse } from '@seesignage/seesignage-utils'
import { convertBytesToGigabytes } from '../../../../utils/conversion'

const styles = (theme: Theme) => ({
  container: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(2)
  },
  title: {
    paddingTop: theme.spacing(),
    paddingLeft: 12
  }
})

const renderBytes = (availableCapacity: number, capacity: number) => {
  if (availableCapacity && capacity) {
    const availableGB = convertBytesToGigabytes(availableCapacity)
    const capacityGB = convertBytesToGigabytes(capacity)
    const usedGB = (capacityGB - availableGB).toFixed(2)
    const maxGB = capacityGB.toFixed(2)
    return `${usedGB}/${maxGB}`
  }
  return 'Not available'
}

interface TvInformationProps extends WithStyles<typeof styles>, WithTranslation {
  screen: ScreenResponse
  updatedAt?: number
}

const TvInformation: React.FC<TvInformationProps> = ({
  screen: { serialNumber, audio, storage, build },
  updatedAt,
  classes,
  t
}) => {
  return (
    <div className={classes.container}>
      <Paper>
        <Typography className={classes.title} variant='h6'>
          TV {t('network.information')}
        </Typography>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>{t('tv.serialNo')}</TableCell>
              <TableCell>{t('tv.volume')}</TableCell>
              <TableCell>{t('tv.mute')}</TableCell>
              <TableCell>{t('tv.capacity')} (GB)</TableCell>
              <TableCell>{t('screens.lastContact')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{serialNumber}</TableCell>
              <TableCell>
                {typeof audio?.volume === 'number' ? `${audio.volume}` : 'Not available'}
              </TableCell>
              <TableCell>{audio?.isMute ? 'true' : 'false'}</TableCell>
              <TableCell>
                {typeof storage?.availableCapacity === 'number' &&
                typeof storage?.capacity === 'number'
                  ? renderBytes(storage.availableCapacity, storage.capacity)
                  : '-'}
              </TableCell>
              <TableCell>
                {updatedAt !== undefined
                  ? dateFormat(new Date(updatedAt), 'HH:MM dd.mm.yyyy')
                  : 'Not connected'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>{t('screens.build.model')}</TableCell>
              <TableCell>{t('screens.build.firmwareVersion')}</TableCell>
              <TableCell>{t('screens.build.buildVersion')}</TableCell>
              <TableCell>{t('screens.build.hardwareVersion')}</TableCell>
              <TableCell>{t('screens.build.sdkVersion')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{build?.model || ''}</TableCell>
              <TableCell>{build?.firmwareVersion || ''}</TableCell>
              <TableCell>{build?.buildVersion || ''}</TableCell>
              <TableCell>{build?.hardwareVersion || ''}</TableCell>
              <TableCell>{build?.sdkVersion || ''}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>{t('tv.userAgent')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{build?.userAgent}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </div>
  )
}

export default withStyles(styles)(withTranslation()(TvInformation))
