import { Theme } from '@mui/material/styles'
import withStyles from '@mui/styles/withStyles'
import Tooltip from '@mui/material/Tooltip'

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
}))(Tooltip)

export default LightTooltip
