import { Media } from '@seesignage/seesignage-utils'

/**
 * Appends current time in ms to filename
 */
export const renameFileIfExists = (name: string) => {
  const prefix = name.split('.')[0]
  const suffix = name.split('.')[1]
  const timems = new Date().getTime()
  return `${prefix}${timems}.${suffix}`
}

export const fileNameExists = (name: string, existingKeys: Media[]) =>
  existingKeys.find(({ name: existingName }) => {
    if (name) {
      const filePrefixX = name.split('.')[0]
      const filePrefixY = existingName.split('.')[0]
      if (filePrefixX === filePrefixY) {
        return true
      }
    }
    return false
  })

export const splitFileName = (name: string) => {
  if (name) {
    return name.split('.')
  }
}
