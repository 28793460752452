import { API } from 'aws-amplify'
import {
  Content,
  CreateContent,
  UpdateContent,
  SocialMediaPlatform,
  ContentUIWithResources,
  ContentUI,
  UpdateContentCanvas,
  DigitransitSearchStopsResponse,
  SocialMediaItem,
  SocialMediaItemsType,
  ContentResponseType,
  CarouselItemType,
  CarouselItemPlayer
} from '@seesignage/seesignage-utils'
import { toast } from 'react-toastify'
import {
  MediaCarouselRevolver,
  isMediaCarouselRevolverInfopageItem,
  isMediaCarouselRevolverMediaItem
} from '../../types/contents'

const getContents = async (environmentId: string, includeInUseData?: boolean) => {
  const options = includeInUseData
    ? {
        queryStringParameters: {
          includeInUseData: true
        }
      }
    : {}
  const contents: ContentUI[] = await API.get(
    'contents',
    `/environments/${environmentId}/contents`,
    options
  )
  return contents
}

const getContent = async (
  environmentId: string,
  contentId: string,
  responseType?: ContentResponseType
) => {
  const content: ContentUIWithResources = await API.get(
    'contents',
    `/environments/${environmentId}/contents/${contentId}`,
    {
      queryStringParameters: {
        responseType
      }
    }
  )
  return content
}

const createContent = async (environmentId: string, content: CreateContent) => {
  const newContent: ContentUI = await API.post(
    'contents',
    `/environments/${environmentId}/contents`,
    {
      body: content
    }
  )
  return newContent
}

const updateContent = async (environmentId: string, contentId: string, content: UpdateContent) => {
  const updatedContent: Content = await API.put(
    'contents',
    `/environments/${environmentId}/contents/${contentId}`,
    { body: content }
  )
  return updatedContent
}

const updateCanvas = async (
  environmentId: string,
  contentId: string,
  { canvas }: UpdateContentCanvas
) => {
  const content: Content = await API.put(
    'contents',
    `/environments/${environmentId}/contents/${contentId}/canvas`,
    {
      body: {
        canvas
      }
    }
  )
  return content
}

const deleteContent = async (environmentId: string, contentId: string) => {
  await API.del('contents', `/environments/${environmentId}/contents/${contentId}`, {})
}

const duplicateContent = async (environmentId: string, contentId: string) => {
  const duplicate: ContentUI = await API.put(
    'contents',
    `/environments/${environmentId}/contents/${contentId}/duplicate`,
    {}
  )
  return duplicate
}

const copyContentToEnvironments = async (
  environmentId: string,
  contentId: string,
  environments: string[]
) => {
  await API.put('contents', `/environments/${environmentId}/contents/${contentId}/copy`, {
    body: environments
  })
}

interface FacebookMediaResponse {
  data: SocialMediaItem[]
  paging?: {
    previous?: string
    next?: string
  }
}

const getSocialMediaFacebookItems = async (
  accessToken: string,
  pageId: string,
  nextUrl?: string
): Promise<FacebookMediaResponse> => {
  try {
    const response = await API.get('contents', '/socialmedia/items', {
      queryStringParameters: {
        platform: SocialMediaPlatform.facebook, // OPTIONAL
        accessToken,
        pageId,
        nextUrl
      }
    })
    return response
  } catch (error) {
    toast.error('An error occured while trying to retrieve user data')
    return { data: [] }
  }
}

const getSocialMediaInstagramItems = async (
  accessToken: string,
  instagramId: string,
  socialMediaItemsType: SocialMediaItemsType,
  nextUrl?: string
): Promise<FacebookMediaResponse> => {
  try {
    const response = await API.get('contents', '/socialmedia/items', {
      queryStringParameters: {
        platform: SocialMediaPlatform.instagram, // OPTIONAL
        socialMediaItemsType,
        accessToken,
        instagramId,
        nextUrl
      }
    })
    return response
  } catch (error) {
    toast.error('An error occured while trying to retrieve user data')
    return { data: [] }
  }
}
const getDigitransitStops = async (searchTerm: string) => {
  const { stops }: DigitransitSearchStopsResponse = await API.get(
    'contents',
    '/digitransit/stops',
    {
      queryStringParameters: {
        searchTerm
      }
    }
  )
  return stops
}

const convertMediaCarouselRevolverItemsToCarouselItemPlayer = async (
  mediaCarouselRevolverItems: MediaCarouselRevolver[],
  currentEnvironmentId: string
) => {
  const carouselItems = (await Promise.all(
    mediaCarouselRevolverItems
      .map(async item => {
        if (isMediaCarouselRevolverInfopageItem(item)) {
          const { id, type, environmentId: itemEnvironmentId } = item
          const environmentId = itemEnvironmentId || currentEnvironmentId
          const carouselItem: CarouselItemPlayer = {
            environmentId,
            contentId: id,
            type: type as CarouselItemType.content,
            content: await getContent(environmentId, id, ContentResponseType.player)
          }
          return carouselItem
        } else if (isMediaCarouselRevolverMediaItem(item)) {
          const { id, type, size, url, name } = item
          const carouselItem: CarouselItemPlayer = {
            key: id,
            type,
            fileMetadata: {
              filename: name,
              url: url || '',
              size: typeof size === 'number' ? size : 0
            }
          }
          return carouselItem
        }
      })
      .filter(item => item)
  )) as CarouselItemPlayer[]

  return carouselItems
}

export default {
  getContents,
  getContent,
  createContent,
  updateContent,
  updateCanvas,
  deleteContent,
  duplicateContent,
  copyContentToEnvironments,
  getSocialMediaFacebookItems,
  getSocialMediaInstagramItems,
  getDigitransitStops,
  convertMediaCarouselRevolverItemsToCarouselItemPlayer
}
