import { IconButton, ListItemIcon, Menu, MenuItem, MenuProps } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { Fragment, useState } from 'react'
import DotsVerticalIcon from 'mdi-react/DotsVerticalIcon'
import ImageIcon from 'mdi-react/ImageIcon'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { TFunction } from 'i18next'
import { StateInterface } from '../../../../../types/states'
import { downloadCanvas } from '../../../../../actions/contents'
import { DownloadCanvas } from '../../../../../types/actions'

const styles = {
  listItemIcon: {
    minWidth: 30
  },
  paper: {
    border: '1px solid #d3d4d5'
  }
}

const useStyles = makeStyles(styles)

const StyledMenu = (props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
)

interface OwnProps {
  t: TFunction
}
interface DispatchProps {
  downloadCanvas: DownloadCanvas
}

type MoreMenuProps = DispatchProps & OwnProps

const MoreMenu = ({ downloadCanvas, t }: MoreMenuProps) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClick = (action?: string) => () => {
    switch (action) {
      case 'download':
        downloadCanvas()
        break
      default:
        break
    }
    setAnchorEl(null)
  }
  return (
    <Fragment>
      <IconButton
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
        onClick={handleOpenMenu}
        size='large'>
        <DotsVerticalIcon />
      </IconButton>
      <StyledMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClick()}
        classes={{ paper: classes.paper }}>
        <MenuItem onClick={handleClick('download')}>
          <ListItemIcon className={classes.listItemIcon}>
            <ImageIcon fontSize='small' />
          </ListItemIcon>
          {t('contents.actions.downloadImage')}
        </MenuItem>
      </StyledMenu>
    </Fragment>
  )
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  downloadCanvas: () => dispatch(downloadCanvas())
})

export default connect<{}, DispatchProps, OwnProps, StateInterface>(
  null,
  mapDispatchToProps
)(MoreMenu)
