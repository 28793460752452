import { Grid } from '@mui/material'
import React from 'react'
import { Field } from 'redux-form'
import { TFunction } from 'i18next'
import { MediaType } from '@seesignage/seesignage-utils'
import FieldsExpansionPanel from '../../../../../components/ExtensionPanels/FieldsExpansionPanel'
import { generateFieldPrefix, generateKeyFieldPrefix, gridItemProps } from '../utils'
import { ReduxTextField } from '../../../../../components/FormInput/ReduxWrappers'
import SelectMultipleMediaField from '../../../../../components/FormInput/SelectMedia/SelectMultipleMediaField'

interface BackgroundFieldsProps {
  level: number
  childIndexes: number[]
  t: TFunction
  formName: string
}

const BackgroundFields: React.FC<BackgroundFieldsProps> = ({ level, childIndexes, formName }) => (
  <FieldsExpansionPanel label='background'>
    <Grid container spacing={2} alignItems='center'>
      <Grid item xs={12}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'background')}
          label='Background'
          helperText={`Don't use together with other background styles`}
          component={ReduxTextField}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'backgroundColor')}
          label='Background color'
          component={ReduxTextField}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'backgroundSize')}
          label='Background size'
          component={ReduxTextField}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'backgroundRepeat')}
          label='Background repeat'
          component={ReduxTextField}
        />
      </Grid>
      <Grid {...gridItemProps}>
        <Field
          fullWidth
          name={generateFieldPrefix(level, childIndexes, 'backgroundPosition')}
          label='Background position'
          component={ReduxTextField}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          label='Background image'
          isSingleFileInput
          formName={formName}
          name={generateKeyFieldPrefix(level, childIndexes, 'backgroundImage')}
          component={SelectMultipleMediaField}
          mediaType={MediaType.image}
          supportCommonBackgrounds
        />
      </Grid>
    </Grid>
  </FieldsExpansionPanel>
)

export default BackgroundFields
