import { Grid, Typography } from '@mui/material'
import React, { Fragment } from 'react'
import { Field, FieldArray, WrappedFieldArrayProps } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { gridItemProps } from '../Fields/utils'
import { ReduxTextField, ReduxCheckbox } from '../../../../components/FormInput/ReduxWrappers'
import { MockProductType } from '../../../../types/mocks'
import FieldsExpansionPanel from '../../../../components/ExtensionPanels/FieldsExpansionPanel'
import { required } from '../../../../validation'
import { getDiets } from '../../../../utils/lunchLists'

const productType = MockProductType.lunch

interface RenderItemsProps extends WrappedFieldArrayProps {
  t: TFunction
}

const renderItems = ({ t, fields, meta: { error, submitFailed } }: RenderItemsProps) => (
  <Fragment>
    {submitFailed && error && <span>{error}</span>}
    {fields.map((member: string, index: number) => (
      <FieldsExpansionPanel customLabel={`Product ${index + 1}`} key={`${index}-${member}`}>
        <Grid container spacing={2}>
          <Grid {...gridItemProps}>
            <Field
              fullWidth
              name={`${member}.name`}
              label={t('general.name')}
              component={ReduxTextField}
              required
              validate={[required]}
            />
          </Grid>
          <Grid {...gridItemProps}>
            <Field
              fullWidth
              name={`${member}.groupId`}
              label='groupId'
              component={ReduxTextField}
              required
              validate={[required]}
            />
          </Grid>
          <Grid {...gridItemProps}>
            <Field
              fullWidth
              name={`${member}.group.name`}
              label='Group name'
              component={ReduxTextField}
              required
              validate={[required]}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{t('lists.lunch.specialDiets')}</Typography>
            <Grid container>
              {getDiets(t).map(({ label, name }) => (
                <Grid key={name} item xs={4}>
                  <Field
                    name={`${member}.specialDiet.${name}`}
                    label={label}
                    component={ReduxCheckbox}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </FieldsExpansionPanel>
    ))}
  </Fragment>
)

const LunchFormFields = () => {
  const [t] = useTranslation()
  return (
    <Grid container spacing={2} alignItems='center'>
      <FieldArray name={productType} component={renderItems} props={{ t }} />
    </Grid>
  )
}

export default LunchFormFields
