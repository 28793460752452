import { Button, Snackbar } from '@mui/material'
import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { acceptCookieConsent, isCookieConsentGiven } from '../../../services/localStorage/cookies'
import { configureTawk } from '../../../config/tawk'
import { selectUserAttributes } from '../../../selectors/users'

const CookieConsent = () => {
  const [isConsentOpen, setConsentOpen] = useState(!isCookieConsentGiven())
  const [t] = useTranslation()
  const userAttributes = useSelector(selectUserAttributes)

  const handleAcceptCookies = () => {
    setConsentOpen(false)
    acceptCookieConsent()
    configureTawk(userAttributes)
  }

  return (
    <Fragment>
      <Snackbar
        open={isConsentOpen}
        message={<>{t('info.cookieConsentDesc')}</>}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        action={
          <>
            <Button href={t('info.privacyPolicyUrl')} target='_blank' color='inherit' size='small'>
              {t('info.privacyPolicyTitle')}
            </Button>
            <Button
              variant='contained'
              size='small'
              onClick={handleAcceptCookies}
              sx={{ marginLeft: 1 }}>
              {t('general.ok')}
            </Button>
          </>
        }
      />
    </Fragment>
  )
}

export default CookieConsent
