import { Button, CircularProgress, Grid } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import CloudDownloadIcon from 'mdi-react/CloudDownloadIcon'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { downloadSubscribersToNewsLetterList as downloadSubscribersToNewsLetterListAction } from '../../../actions/users'
import { isDownloadSubscribersToNewsLetterListLoading } from '../../../selectors/users'

const Users = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const downloadSubscribersToNewsLetterList = () =>
    dispatch(downloadSubscribersToNewsLetterListAction())
  const csvLoading = useSelector(isDownloadSubscribersToNewsLetterListLoading)
  return (
    <Grid container rowGap={2} marginTop={2}>
      <Grid item xs={12} paddingX={1}>
        <Button
          onClick={csvLoading ? undefined : downloadSubscribersToNewsLetterList}
          startIcon={
            csvLoading ? (
              <CircularProgress color='inherit' size={20} />
            ) : (
              <CloudDownloadIcon color='#1e88e5' />
            )
          }
          variant='outlined'>
          {csvLoading
            ? `${t('general.loading')}...`
            : t('dashboard.users.downloadSubscribersButtonLabel')}
        </Button>
      </Grid>
    </Grid>
  )
}

export default Users
