import { AppBar, Grid, IconButton, LinearProgress, Theme, Toolbar, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import InfoIcon from 'mdi-react/InfoCircleIcon'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import { Channel, Campaign, CampaignSub } from '@seesignage/seesignage-utils'
import FolderIcon from 'mdi-react/FolderIcon'
import { createChannel, deleteChannel, updateChannel } from '../../actions/channels'
import { closeDialog } from '../../actions/dialogs'
import { navigate } from '../../actions/routes'
import Container from '../../components/Container'
import { selectAreChannelsLoading, selectChannelsByAccessAsArray } from '../../selectors/channels'
import { selectPlaylists } from '../../selectors/playlists'
import { selectIsUserDeviceMobile, selectUser } from '../../selectors/users'
import {
  CloseDialog,
  CreateChannel,
  DeleteChannel,
  Navigate,
  ForceRunTour,
  UpdateChannel
} from '../../types/actions'
import { PlaylistsById } from '../../types/playlists'
import { StateInterface, IndexById } from '../../types/states'
import { bindSubmitActionToPromise } from '../../utils/forms'
import Dialog from '../Dialog'
import { UserInterface } from '../../types/users'
import { compareStrings } from '../../utils/sorting'
import { BreadcrumbContentType } from '../../types/breadcrumbs'
import PageTitle from '../../components/PageTitle'
import { Tour } from '../../types/tours'
import { forceRunTour } from '../../actions/tours'
import { OpenDialogButtonType } from '../../types/dialogs'
import { selectCampaigns } from '../../selectors/campaigns'
import ImportFolder from '../Integrations/EMMi/ImportFolder/ImportFolder'
import { selectEmmiCustomer } from '../../selectors/customers'
import ChannelCard from './ChannelCard'
import CreateChannelForm from './Forms/CreateChannelForm'

const styles = (theme: Theme) => ({
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    color: theme.palette.text.secondary,
    marginRight: 4,
    display: 'flex'
  },
  grid: {
    padding: '10px 0px 10px',
    marginBottom: 100 // better mobile usability
  },
  appBar: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14)',
    top: 56,
    [theme.breakpoints.up('sm')]: {
      top: 64
    }
  }
})

const useStyles = makeStyles(styles)

interface StateProps {
  channels: Channel[]
  isMobile: boolean
  playlistsById: PlaylistsById
  campaignsById: IndexById<Campaign | CampaignSub>
  user?: UserInterface
  isLoading: boolean
}

interface DispatchProps {
  closeDialog: CloseDialog
  create: CreateChannel
  update: UpdateChannel
  navigate: Navigate
  deleteC: DeleteChannel
  forceRunTour: ForceRunTour
}

type ChannelsProps = StateProps & DispatchProps

const Channels = ({
  isMobile,
  closeDialog,
  create,
  update,
  deleteC,
  navigate,
  channels,
  playlistsById,
  campaignsById,
  user,
  isLoading,
  forceRunTour
}: ChannelsProps) => {
  const emmiCustomer = useSelector(selectEmmiCustomer)
  const classes = useStyles()
  const [t] = useTranslation()
  return (
    <Container>
      <PageTitle contentType={BreadcrumbContentType.channels} />
      {user && (
        <Fragment>
          <AppBar
            className={classes.appBar}
            position='sticky'
            color='default'
            id='channels-toolbar'>
            <Toolbar>
              <div className={classes.spacer} />
              <div className={classes.actions}>
                <Tooltip disableInteractive title={t('tours.runTour')}>
                  <IconButton onClick={() => forceRunTour(Tour.createChannel)} size='large'>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
                {emmiCustomer && (
                  <Dialog
                    dialogId='getEMMiFolders'
                    title={t('integrations.emmi.importFolders')}
                    tooltipTitle={t('integrations.emmi.importFolders')}
                    Content={<ImportFolder emmiCustomer={emmiCustomer} />}
                    ButtonIcon={FolderIcon}
                    maxWidth='lg'
                  />
                )}
                <Dialog
                  dialogId='CreateChannelDialog'
                  title={t('channels.actions.createNew')}
                  openDialogButtonType={OpenDialogButtonType.add}
                  buttonId='create-channel-button'
                  tooltipTitle={t('channels.actions.createNew')}
                  Content={
                    <CreateChannelForm
                      dialogId='CreateChannelDialog'
                      onSubmit={create}
                      closeDialog={closeDialog}
                      submitButtonLabel={t('general.create')}
                    />
                  }
                />
              </div>
            </Toolbar>
          </AppBar>
          {isLoading && <LinearProgress />}
          <Grid
            container
            className={classes.grid}
            alignItems={isMobile ? 'center' : 'flex-start'}
            justifyContent={isMobile ? 'center' : 'flex-start'}>
            {channels.length > 0 && (
              <Fragment>
                {channels.map(channel => {
                  const cardProps = {
                    channel,
                    navigate,
                    deleteC,
                    update,
                    closeDialog,
                    playlistsById,
                    campaignsById,
                    user
                  }
                  return <ChannelCard key={channel.channelId} {...cardProps} />
                })}
              </Fragment>
            )}
          </Grid>
        </Fragment>
      )}
    </Container>
  )
}
const mapStateToProps = (state: StateInterface): StateProps => ({
  channels: selectChannelsByAccessAsArray(state).sort(compareStrings('name')),
  isMobile: selectIsUserDeviceMobile(state),
  playlistsById: selectPlaylists(state),
  campaignsById: selectCampaigns(state),
  isLoading: selectAreChannelsLoading(state),
  user: selectUser(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  closeDialog: (dialogId: string) => dispatch(closeDialog(dialogId)),
  navigate: (path: string) => dispatch(navigate(path)),
  create: bindSubmitActionToPromise(dispatch, createChannel),
  update: bindSubmitActionToPromise(dispatch, updateChannel),
  deleteC: (channelId: string) => dispatch(deleteChannel(channelId)),
  forceRunTour: (tour: Tour) => dispatch(forceRunTour(tour))
})

export default connect(mapStateToProps, mapDispatchToProps)(Channels)
