import React from 'react'
import { WrappedFieldProps } from 'redux-form'
import { Tooltip, IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import PercentIcon from 'mdi-react/PercentIcon'
import colors from '../../../../styles/common/colors'

const ShowPercentageField: React.FC<WrappedFieldProps> = ({ input: { onChange, value } }) => {
  const [t] = useTranslation()
  return (
    <div style={{ height: 60 }}>
      {value === true ? (
        <Tooltip disableInteractive title={t('campaigns.editCampaign.useDiscountPrice')}>
          <IconButton onClick={() => onChange(false)} size='large'>
            <PercentIcon color={colors.seesignageColor} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip disableInteractive title={t('campaigns.editCampaign.usePercentage')}>
          <IconButton onClick={() => onChange(true)} size='large'>
            <PercentIcon color='lightGrey' />
          </IconButton>
        </Tooltip>
      )}
    </div>
  )
}

export default ShowPercentageField
