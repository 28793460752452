import { createAsyncActions, createAction } from './utils'

const reducer = 'MOCKS'

const {
  action: setInitialMockData,
  actionSuccess: setInitialMockDataSuccess,
  actionFail: setInitialMockDataFail
} = createAsyncActions(reducer, 'SET_INITIAL_DATA')

const {
  action: addTemplateMockData,
  actionSuccess: addTemplateMockDataSuccess,
  actionFail: addTemplateMockDataFail
} = createAsyncActions(reducer, 'ADD_TEMPLATE_MOCK_DATA')

const setMockProductType = createAction(reducer, 'SET_MOCK_PRODUCT_TYPE')

export {
  setInitialMockData,
  setInitialMockDataFail,
  setInitialMockDataSuccess,
  addTemplateMockData,
  addTemplateMockDataSuccess,
  addTemplateMockDataFail,
  setMockProductType
}
