import React, { useState, Fragment } from 'react'
import { Grid, Tab, Tabs } from '@mui/material'
import { arrayPush } from 'redux-form'
import { CarouselItemType, ContentUI, Media, MediaType } from '@seesignage/seesignage-utils'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  MediaCarouselWizardFormData,
  MediaCarouselRevolverInfopageItem,
  MediaCarouselRevolverMediaItem,
  MediaCarouselWizardView,
  isMediaCarouselPlaylistFormData
} from '../../../../types/contents'
import AddExistingMediaForm from '../../../Playlists/Forms/Media/AddExistingMediaForm'
import SelectMediaCarouselType from './SelectMediaCarouselType'
import SelectExistingInfopagesForm from './SelectExistingInfopagesForm'
import MediaCarouselForm from './MediaCarouselForm'

enum MediaSource {
  mediaBrowser = 'mediaBrowser',
  infopage = 'infopage'
}

function a11yProps(value: MediaSource) {
  return {
    id: `simple-tab-${value}`,
    'aria-controls': `simple-tabpanel-${value}`,
    value
  }
}

interface CreateMediaCarouselWizardProps {
  initialValues?: Partial<MediaCarouselWizardFormData>
  isUpdateMediaCarousel: boolean
  /** contentId of the content that user is currently editing. */
  currentContentId: string
  closeDialog: () => void
}

const CreateMediaCarouselWizard = ({
  closeDialog,
  initialValues,
  currentContentId,
  isUpdateMediaCarousel
}: CreateMediaCarouselWizardProps) => {
  const [view, setView] = useState<MediaCarouselWizardView>(
    isUpdateMediaCarousel
      ? isMediaCarouselPlaylistFormData(initialValues)
        ? MediaCarouselWizardView.playlist
        : MediaCarouselWizardView.media
      : MediaCarouselWizardView.selectType
  )
  const [mediaSource, setMediaSource] = useState<MediaSource>(MediaSource.mediaBrowser)

  const dispatch = useDispatch()
  const [t] = useTranslation()

  const pushMediaToList = (media: Media) => {
    const { key, type, url = '', name, size } = media
    const mediaCarouselItem: MediaCarouselRevolverMediaItem = {
      id: key,
      type: type === MediaType.image ? CarouselItemType.image : CarouselItemType.video,
      url,
      name,
      size
    }
    dispatch(arrayPush('MediaCarouselForm', 'carouselItems', mediaCarouselItem))
  }

  const pushInfoPageToList = (item: ContentUI) => {
    const { contentId, thumbnailUrl = '', name, environmentId } = item
    const mediaCarouselItem: MediaCarouselRevolverInfopageItem = {
      id: contentId,
      environmentId,
      type: CarouselItemType.content,
      url: thumbnailUrl,
      name
    }
    dispatch(arrayPush('MediaCarouselForm', 'carouselItems', mediaCarouselItem))
  }

  return (
    <Grid container spacing={2} pt={2}>
      {view === MediaCarouselWizardView.selectType ? (
        <SelectMediaCarouselType setView={setView} />
      ) : (
        <Fragment>
          {view === MediaCarouselWizardView.media && (
            <>
              <Grid item xs={12} sm={7}>
                <Tabs
                  value={mediaSource}
                  onChange={(ev, value) => setMediaSource(value)}
                  centered
                  sx={{ height: '100%' }}
                  aria-label='basic tabs example'>
                  <Tab
                    label={t('contents.widgets.mediaCarousel.mediaBrowser')}
                    {...a11yProps(MediaSource.mediaBrowser)}
                  />
                  <Tab
                    label={t('contents.widgets.mediaCarousel.infopages')}
                    {...a11yProps(MediaSource.infopage)}
                  />
                </Tabs>
              </Grid>
              <Grid item xs={12} sm={7}>
                {mediaSource === MediaSource.infopage ? (
                  <SelectExistingInfopagesForm
                    t={t}
                    currentContentId={currentContentId}
                    pushInfoPageToList={pushInfoPageToList}
                  />
                ) : (
                  <AddExistingMediaForm pushMediaToList={pushMediaToList} />
                )}
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={view === MediaCarouselWizardView.media ? 5 : 12}>
            <MediaCarouselForm
              closeDialog={closeDialog}
              isUpdateMediaCarousel={isUpdateMediaCarousel}
              initialValues={initialValues}
              view={view}
              setView={setView}
            />
          </Grid>
        </Fragment>
      )}
    </Grid>
  )
}

export default CreateMediaCarouselWizard
