import { LinearProgress, TablePagination } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import React, { Fragment, useState } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import {
  Template,
  FeaturePermission,
  FeaturePermissionLevel,
  MasterTemplateUI
} from '@seesignage/seesignage-utils'
import { navigate } from '../../actions/routes'
import {
  selectEnvironmentTemplates,
  selectTemplatesIsLoading,
  selectTemplatesTypesArray
} from '../../selectors/templates'
import { selectUser } from '../../selectors/users'
import { compareStrings } from '../../utils/sorting'
import { StateInterface } from '../../types/states'
import {
  Navigate,
  CloseDialog,
  DeleteTemplate,
  DuplicateTemplate,
  UpdateTemplate
} from '../../types/actions'
import { closeDialog } from '../../actions/dialogs'
import { bindSubmitActionToPromise } from '../../utils/forms'
import { deleteTemplate, duplicateTemplate, updateTemplate } from '../../actions/templates'
import { validateUserAccess } from '../../utils/permissions'
import { UserInterface } from '../../types/users'
import { selectEnvironmentIdFromPathname } from '../../selectors/routing'
import { BreadcrumbContentType } from '../../types/breadcrumbs'
import PageTitle from '../../components/PageTitle'
import TemplatesToolbar from './TemplatesToolbar'
import TemplateCard from './TemplateCard'

const listStyles = () => ({
  lists: {
    margin: '10px 5px 10px 5px',
    minHeight: 600
  }
})

interface StateProps {
  templates: (Template | MasterTemplateUI)[]
  templatesTypes: string[]
  isLoading: boolean
  user?: UserInterface
}

interface DispatchProps {
  navigate: Navigate
  closeDialog: CloseDialog
  deleteTemplate: DeleteTemplate
  duplicateTemplate: DuplicateTemplate
  updateTemplate: UpdateTemplate
}
type TemplatesProps = StateProps & DispatchProps & WithStyles<typeof listStyles> & WithTranslation

const Templates: React.FC<TemplatesProps> = ({
  classes,
  templates,
  navigate,
  closeDialog,
  deleteTemplate,
  duplicateTemplate,
  updateTemplate,
  isLoading,
  user,
  templatesTypes,
  t
}) => {
  const [searchTerm, searchTemplates] = useState('')
  const [selectedType, selectType] = useState('all')
  const visibleTemplates =
    searchTerm.length === 0 && selectedType === 'all'
      ? templates
      : templates.filter(({ name, type }) =>
          selectedType === 'all'
            ? name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
            : (type === selectedType || (type === 'info' && selectedType === 'infopage')) &&
              name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
        )
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  return user &&
    validateUserAccess(user, FeaturePermission.templates, FeaturePermissionLevel.read) ? (
    <Fragment>
      <PageTitle contentType={BreadcrumbContentType.templates} />
      <TemplatesToolbar
        searchTemplates={searchTemplates}
        selectType={selectType}
        setPage={setPage}
        type={selectedType}
        templatesTypes={['all', ...templatesTypes]}
        user={user}
      />
      <Fragment>
        {isLoading ? (
          <LinearProgress />
        ) : (
          <div className={classes.lists}>
            {visibleTemplates.length > 0 ? (
              <Fragment>
                {visibleTemplates
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(template => {
                    const cardProps = {
                      template,
                      navigate,
                      closeDialog,
                      deleteTemplate,
                      duplicateTemplate,
                      updateTemplate,
                      user,
                      t
                    }
                    return <TemplateCard key={template.templateId} {...cardProps} />
                  })}
              </Fragment>
            ) : (
              <div />
            )}
          </div>
        )}
        <TablePagination
          component='div'
          count={visibleTemplates.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page'
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page'
          }}
          onPageChange={(e, page) => setPage(page)}
          onRowsPerPageChange={(e: any) => setRowsPerPage(e.target.value)}
          labelRowsPerPage={t('templates.templatesPerPage')}
        />
      </Fragment>
    </Fragment>
  ) : (
    <Fragment />
  )
}

const mapStateToProps = (state: StateInterface): StateProps => {
  const environmentId = selectEnvironmentIdFromPathname(state)
  return {
    templates: selectEnvironmentTemplates(environmentId)(state).sort(compareStrings('name')),
    templatesTypes: selectTemplatesTypesArray(state),
    isLoading: selectTemplatesIsLoading(state),
    user: selectUser(state)
  }
}
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  navigate: (path: string) => dispatch(navigate(path)),
  closeDialog: (dialogId: string) => dispatch(closeDialog(dialogId)),
  updateTemplate: bindSubmitActionToPromise(dispatch, updateTemplate),
  deleteTemplate: (templateId: string) => dispatch(deleteTemplate(templateId)),
  duplicateTemplate: (templateId: string) => dispatch(duplicateTemplate(templateId))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(listStyles)(withTranslation()(Templates)))
