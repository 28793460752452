import { connect } from 'react-redux'
import React, { Fragment } from 'react'
import Toolbar from '@mui/material/Toolbar'
import AppBar from '@mui/material/AppBar'
import { LinearProgress, Grid, Theme } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Dispatch } from 'redux'
import { ListUI } from '@seesignage/seesignage-utils'
import { closeDialog } from '../../actions/dialogs'
import {
  selectListsAsArray,
  selectGetListsIsLoading,
  selectListSearchTerm,
  selectListsBySearchTerm
} from '../../selectors/lists'
import { navigate } from '../../actions/routes'
import {
  createList,
  updateList,
  deleteList,
  searchLists,
  copyList,
  navigateToLunchList
} from '../../actions/lists'
import { bindSubmitActionToPromise } from '../../utils/forms'
import Dialog from '../Dialog'
import { compareStrings } from '../../utils/sorting'
import { selectCustomer } from '../../actions/customers'
import {
  selectCurrentEnvironmentFeatures,
  selectEnvironmentTemplateTypes
} from '../../selectors/environments'
import { selectUser, selectIsUserDeviceMobile } from '../../selectors/users'
import { Navigate, CloseDialog, NavigateToLunchList } from '../../types/actions'
import { StateInterface, IndexById } from '../../types/states'
import { selectTemplates } from '../../selectors/templates'
import { selectEnvironmentIdFromPathname } from '../../selectors/routing'
import { BreadcrumbContentType } from '../../types/breadcrumbs'
import PageTitle from '../../components/PageTitle'
import SearchField from '../../components/SearchField'
import { OpenDialogButtonType } from '../../types/dialogs'
import { StateTemplate } from '../../types/templates'
import { isListsFeatureEnabled } from '../../utils/lists'
import ListCard from './ListCard'
import CreateListForm from './Forms/CreateListForm'

const listStyles = (theme: Theme) => ({
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    color: theme.palette.text.secondary,
    marginRight: 4,
    display: 'flex'
  },
  lists: {
    padding: '10px 0px 10px',
    marginBottom: 100 // better mobile usability
  },
  appBar: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14)',
    top: 56,
    [theme.breakpoints.up('sm')]: {
      top: 64
    }
  }
})

interface StateProps {
  allLists: ListUI[]
  isLoading: boolean
  isMobile: boolean
  searchTerm?: string
  listsBySearch: ListUI[]
  templatesById: IndexById<StateTemplate>
  environmentIdFromPath: string
  showLists: boolean
}

interface DispatchProps {
  closeDialog: CloseDialog
  navigate: Navigate
  navigateToLunchList: NavigateToLunchList
  createList: (formData?: any) => void
  updateList: (formData?: any) => void
  deleteList: (listId: string) => void
  copyList: (listId: string) => void
  selectCustomer: (customerId: string) => void
  searchLists: (searchTerm: string) => void
}

type ListsProps = StateProps & DispatchProps & WithStyles<typeof listStyles> & WithTranslation

const Lists: React.FC<ListsProps> = ({
  classes,
  allLists,
  navigate,
  navigateToLunchList,
  closeDialog,
  createList,
  updateList,
  deleteList,
  copyList,
  isLoading,
  isMobile,
  searchLists,
  searchTerm,
  showLists,
  listsBySearch,
  templatesById,
  environmentIdFromPath,
  t
}) => {
  const lists = searchTerm ? listsBySearch : allLists
  return showLists ? (
    <Fragment>
      <PageTitle contentType={BreadcrumbContentType.lists} />
      <Fragment>
        <AppBar className={classes.appBar} position='sticky' color='default'>
          <Toolbar>
            <SearchField
              placeholder={t('general.search')}
              onChange={e => searchLists(e.target.value)}
            />
            <div className={classes.spacer} />
            <div className={classes.actions}>
              <Dialog
                dialogId='CreateListDialog'
                title={t('lists.create')}
                openDialogButtonType={OpenDialogButtonType.add}
                tooltipTitle={t('lists.create')}
                Content={
                  <CreateListForm
                    dialogId='CreateListDialog'
                    submitAction={createList}
                    closeDialog={closeDialog}
                    submitButtonLabel={t('general.create')}
                  />
                }
              />
            </div>
          </Toolbar>
        </AppBar>
        {isLoading ? (
          <LinearProgress />
        ) : (
          <Grid
            container
            className={classes.lists}
            alignItems={isMobile ? 'center' : 'flex-start'}
            justifyContent={isMobile ? 'center' : 'flex-start'}>
            {lists.length > 0 && (
              <Fragment>
                {lists.map(list => {
                  const cardProps = {
                    list,
                    navigate,
                    navigateToLunchList,
                    updateList,
                    deleteList,
                    copyList,
                    closeDialog,
                    template: templatesById[list.templateId],
                    environmentIdFromPath,
                    t
                  }
                  return <ListCard key={list.listId} {...cardProps} />
                })}
              </Fragment>
            )}
          </Grid>
        )}
      </Fragment>
    </Fragment>
  ) : (
    <Fragment />
  )
}

const mapStateToProps = (state: StateInterface): StateProps => {
  const searchTerm = selectListSearchTerm(state)
  const user = selectUser(state)
  const environmentFeatures = selectCurrentEnvironmentFeatures(state)
  const environmentTemplateTypes = selectEnvironmentTemplateTypes(state)
  return {
    allLists: selectListsAsArray(state).sort(compareStrings('name')),
    isLoading: selectGetListsIsLoading(state),
    isMobile: selectIsUserDeviceMobile(state),
    searchTerm,
    listsBySearch: selectListsBySearchTerm(searchTerm)(state).sort(compareStrings('name')),
    templatesById: selectTemplates(state),
    environmentIdFromPath: selectEnvironmentIdFromPathname(state) as string,
    showLists: isListsFeatureEnabled(user, environmentFeatures, environmentTemplateTypes)
  }
}
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  closeDialog: (dialogId: string) => dispatch(closeDialog(dialogId)),
  navigate: (path: string) => dispatch(navigate(path)),
  navigateToLunchList: (params: any) => dispatch(navigateToLunchList(params)),
  createList: bindSubmitActionToPromise(dispatch, createList),
  updateList: bindSubmitActionToPromise(dispatch, updateList),
  deleteList: (listId: string) => dispatch(deleteList(listId)),
  copyList: (listId: string) => dispatch(copyList(listId)),
  selectCustomer: (customerId: string) => dispatch(selectCustomer(customerId)),
  searchLists: (searchTerm: string) => dispatch(searchLists(searchTerm))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(listStyles)(withTranslation()(Lists)))
