import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import MagnifyIcon from 'mdi-react/MagnifyIcon'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { MiscProduct, isFishProduct, isMiscList } from '@seesignage/seesignage-utils'
import useDebounce from '../../../../hooks/debounce'
import { DEBOUNCE_DELAY } from '../../../../config/constants'
import ProductsApi from '../../../../services/api/products'
import { selectCurrentMiscList } from '../../../../selectors/lists'
import { addMiscListItem } from '../../../../actions/lists'
import { compareStrings } from '../../../../utils/sorting'

const useStyles = makeStyles(() => ({
  searchField: {
    width: 230,
    display: 'inline-block'
  },
  button: {
    display: 'inline-block'
  },
  searchIcon: {
    position: 'absolute',
    right: 10
  }
}))

const SearchMiscProduct = () => {
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const list = useSelector(selectCurrentMiscList)
  const [searchResults, setSearchResults] = useState<MiscProduct[]>([])
  const [selectedOption, setSelectedOption] = useState<MiscProduct | null>(null)
  const [searchFieldValue, setSearchFieldValue] = useState('')
  const searchTerm = useDebounce(searchFieldValue, DEBOUNCE_DELAY)

  useEffect(() => {
    const getItems = async () => {
      setLoading(true)
      if (isMiscList(list)) {
        const { environmentId, customerId, type } = list
        try {
          const products: MiscProduct[] = await ProductsApi.getSuggestions(
            environmentId,
            customerId,
            type,
            searchTerm
          )
          setSearchResults(products.sort(compareStrings('name')))
        } catch (error) {
          setSearchResults([])
        }
      }
      setLoading(false)
    }
    if (searchTerm.length >= 2) {
      getItems()
    }
  }, [searchTerm, list])

  // disable adding product if it already exists in list
  const isAddProductDisabled =
    list?.items.some(({ product }) => product?.productId === selectedOption?.productId) ||
    !selectedOption

  return (
    <Fragment>
      <div>
        <Autocomplete
          classes={{ root: classes.searchField }}
          size='small'
          options={searchResults}
          value={selectedOption}
          getOptionLabel={option => option.name}
          onChange={(event, value) => setSelectedOption(value)}
          autoComplete
          includeInputInList
          noOptionsText={<>{t('general.noOptions')}</>}
          isOptionEqualToValue={(option, value) => option.productId === value.productId}
          loading={loading}
          renderInput={params => (
            <TextField
              {...params}
              value={searchFieldValue}
              onChange={e => setSearchFieldValue(e.target.value)}
              placeholder={t('lists.searchProduct')}
              fullWidth
              size='small'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position='end'>
                    {loading ? <CircularProgress color='inherit' size={20} /> : null}
                    <MagnifyIcon className={classes.searchIcon} />
                  </InputAdornment>
                )
              }}
            />
          )}
          renderOption={(props, option) => (
            <li {...props} key={option?.productId}>
              <Grid container alignItems='center'>
                <Grid item xs>
                  <Typography variant='body2'>
                    {isFishProduct(option)
                      ? `${option.name} ${option.latinName ? `(${option.latinName})` : ''}`
                      : option.name}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          )}
        />
      </div>
      <Tooltip disableInteractive title={t('lists.miscList.copyTooltip')}>
        <span>
          <Button
            className={classes.button}
            color='primary'
            disabled={!selectedOption}
            onClick={() => {
              dispatch(addMiscListItem({ product: selectedOption, isCopy: true }))
              setSelectedOption(null)
            }}>
            {t('general.copy')}
          </Button>
        </span>
      </Tooltip>
      <Tooltip
        disableInteractive
        title={t(
          `lists.miscList.${isAddProductDisabled ? 'disableAddProductTooltip' : 'addTooltip'}`
        )}>
        <span>
          <Button
            className={classes.button}
            color='primary'
            disabled={isAddProductDisabled}
            onClick={() => {
              dispatch(addMiscListItem({ product: selectedOption }))
              setSelectedOption(null)
            }}>
            {t('general.add')}
          </Button>
        </span>
      </Tooltip>
    </Fragment>
  )
}

export default SearchMiscProduct
