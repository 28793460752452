import { path } from 'ramda'
import { toast } from 'react-toastify'
import { arrayPush, SubmissionError } from 'redux-form'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import {
  ListType,
  ListUI,
  MiscListUI,
  RetailListUI,
  isRetailList,
  isMiscList,
  isRetailProduct,
  Product,
  isPharmacyProduct,
  List,
  Customer,
  MiscProduct
} from '@seesignage/seesignage-utils'
import nanoid from 'nanoid'
import {
  isPricerProduct,
  PricerProduct
} from '@seesignage/seesignage-utils/lib/types/productsPricer'
import { selectCustomer } from '../actions/customers'
import { closeDialog } from '../actions/dialogs'
import {
  addListItem,
  addListItemFail,
  addListItemSuccess,
  addMiscListItem,
  createList,
  createListFail,
  createListSuccess,
  deleteList,
  deleteListFail,
  deleteListItem,
  deleteListItemFail,
  deleteListItemSuccess,
  deleteListSuccess,
  deselectAllListItems,
  getList,
  getListFail,
  getLists,
  getListsFail,
  getListsSuccess,
  getListSuccess,
  reorderItems,
  reorderItemsFail,
  reorderItemsStore,
  reorderItemsSuccess,
  saveMiscList,
  saveMiscListFail,
  saveMiscListSuccess,
  saveRetailList,
  saveRetailListFail,
  saveRetailListSuccess,
  updateList,
  updateListFail,
  updateListSuccess,
  copyListSuccess,
  copyListFail,
  copyList
} from '../actions/lists'

import { clearSuggestions } from '../actions/products'
import { selectCustomerById } from '../selectors/customers'
import {
  selectListById,
  selectListCustomerById,
  selectListSize,
  selectListTypeByListId,
  selectSelectedListItemIds
} from '../selectors/lists'

import { selectContentIdFromPathname, selectEnvironmentIdFromPathname } from '../selectors/routing'
import { selectTemplateById } from '../selectors/templates'
import Api from '../services/api/lists'
import Products from '../services/api/products'
import i18n from '../translations/i18n'
import {
  handleCreateListParams,
  handleSaveMiscListParams,
  handleUpdateListParams
} from '../types/formData'
import { listDeletionFail } from '../utils/message'
import { CreateListRequestBody } from '../types/lists'
import { reorder } from '../utils/arrays'

export function* handleGetLists() {
  try {
    const environmentId: string | undefined = yield select(selectEnvironmentIdFromPathname)
    if (environmentId) {
      const lists: ListUI[] = yield call(Api.getLists, environmentId)
      yield put(getListsSuccess(lists))
    } else {
      yield put(getListsFail('No environment selected'))
    }
  } catch (error) {
    yield put(getListsFail(error.message))
  }
}

export function* handleGetList() {
  try {
    const environmentId: string | undefined = yield select(selectEnvironmentIdFromPathname)
    const listId: string | undefined = yield select(selectContentIdFromPathname)
    if (environmentId && listId) {
      const list: ListUI = yield call(Api.getList, environmentId, listId)
      if (isRetailList(list) || isMiscList(list)) {
        const { customerId } = list
        yield put(selectCustomer(customerId))
      }

      yield put(getListSuccess(list))
    } else {
      throw new Error('invalid path variables')
    }
  } catch (error) {
    yield put(getListFail(error.message))
  }
}

export function* handleCreateList({
  payload: { formData, resolve, reject }
}: handleCreateListParams) {
  try {
    const environmentId: string | undefined = yield select(selectEnvironmentIdFromPathname)
    const { name, template, type, startDate, price } = formData
    const { templateId, templateEnvironmentId } = template
    if (environmentId) {
      const createListBody: CreateListRequestBody = {
        name,
        templateId,
        templateEnvironmentId,
        type
      }
      if (type === ListType.lunch) {
        createListBody.startDate = startDate

        if (price) {
          createListBody.price = price
        }
      } else {
        // other list types have customerId
        createListBody.customerId = formData?.customerId?.value
      }
      const list: ListUI = yield call(Api.createList, environmentId, createListBody)

      yield put(createListSuccess(list))
      yield put(closeDialog())
      resolve()
    }
  } catch (error) {
    yield put(createListFail(error.message))
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.list.somethingWrongCreate')
      })
    )
  }
}

export function* handleUpdateList({
  payload: { formData, resolve, reject }
}: handleUpdateListParams) {
  try {
    const { name, template, listId, type, startDate, price } = formData
    const environmentId: string | undefined = yield select(selectEnvironmentIdFromPathname)
    const { templateId, templateEnvironmentId } = template
    if (environmentId) {
      const updateListBody: CreateListRequestBody = {
        name,
        templateId,
        templateEnvironmentId,
        type
      }

      updateListBody.templateEnvironmentId = templateEnvironmentId
      if (type === ListType.lunch) {
        updateListBody.startDate = startDate

        if (price) {
          updateListBody.price = price
        }
      } else {
        // other list types have customerId
        updateListBody.customerId = formData?.customerId?.value
      }

      const updatedListAttributes: List = yield call(
        Api.updateList,
        environmentId,
        listId,
        updateListBody
      )

      yield put(
        updateListSuccess({
          ...updatedListAttributes,
          listId
        })
      )
      yield put(closeDialog())
      resolve()
    }
  } catch (error) {
    yield put(updateListFail(error.message))
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.list.somethingWrongUpdate')
      })
    )
  }
}

interface HandleDeleteListParams {
  payload: string
}

export function* handleDeleteList({ payload: listId }: HandleDeleteListParams) {
  try {
    const environmentId: string | undefined = yield select(selectEnvironmentIdFromPathname)
    if (environmentId) {
      yield call(Api.deleteList, environmentId, listId)
      yield put(deleteListSuccess(listId))
      yield put(closeDialog())
    }
  } catch (error) {
    const playlists: string[] | undefined = path(['response', 'data', 'playlists'], error)
    const screens: string[] | undefined = path(['response', 'data', 'screens'], error)
    const errorMessage = listDeletionFail({ playlists, screens })
    if (errorMessage) {
      toast.error(errorMessage, {
        autoClose: 10000
      })
    }
    yield put(deleteListFail(error.message))
  }
}

interface HandleReorderItemsParams {
  payload: {
    startIndex: number
    endIndex: number
  }
}

/**
 * Reorder retail or misc list items
 */
export function* handleReorderItems({
  payload: { startIndex, endIndex }
}: HandleReorderItemsParams) {
  try {
    const listId: string | undefined = yield select(selectContentIdFromPathname)
    const list: ListUI = yield select(selectListById(listId))
    if (listId && (isRetailList(list) || isMiscList(list))) {
      yield put(
        reorderItemsStore({
          listId,
          items: reorder(list.items, startIndex, endIndex)
        })
      )
      yield put(reorderItemsSuccess())
    }
  } catch (error) {
    yield put(reorderItemsFail(error.message))
  }
}

interface HandleAddMiscListItemParams {
  payload: {
    product: MiscProduct
    /** if we want to copy the product as new product */
    isCopy?: boolean
  }
}

export function* handleAddMiscListItem({
  payload: { product, isCopy }
}: HandleAddMiscListItemParams) {
  const { productId } = product
  if (isCopy) {
    delete (product as any).productId
  }
  const item = {
    itemId: nanoid(),
    // note: if productId is not given, when saving list it creates new product to database.
    productId: isCopy ? undefined : productId,
    product
  }

  yield put(arrayPush('MiscListForm', 'items', item))
  yield put(clearSuggestions())
}

export function* handleAddListItem({
  payload: {
    formData: {
      product: { value }
    },
    resolve,
    reject
  }
}: any) {
  try {
    const listId: string = yield select(selectContentIdFromPathname)
    const { templateId } = yield select(selectListById(listId))
    const { maxItems } = yield select(selectTemplateById(templateId))
    const listSize: number = yield select(selectListSize(listId))
    if (maxItems && listSize >= maxItems) {
      throw new SubmissionError({
        _error: i18n.t('error.list.maximumReached', { maxItems })
      })
    }

    const product: Product | PricerProduct = JSON.parse(value)

    if ((product as any)?.preWeighedId) {
      delete (product as any).preWeighedId
    }

    yield put(clearSuggestions())
    yield put(closeDialog())
    yield put(
      addListItemSuccess({
        listId,
        item: {
          product,
          // we store itemId for pricer product because there might be multiple sics
          productId: isPricerProduct(product)
            ? product.itemId
            : isPharmacyProduct(product)
            ? product.ean
            : product.productId,
          itemId: nanoid(),
          key: (product as any)?.key
        }
      })
    )
    resolve()
  } catch (error) {
    if (error instanceof SubmissionError) {
      yield call(reject, new SubmissionError({ _error: path(['errors', '_error'], error) }))
    } else {
      yield call(
        reject,
        new SubmissionError({
          _error: i18n.t('error.list.somethingWrongAddItem')
        })
      )
    }
    yield put(addListItemFail(error.message))
  }
}

export function* handleDeleteListItem() {
  try {
    const itemIds: string[] = yield select(selectSelectedListItemIds)
    const listId: string | undefined = yield select(selectContentIdFromPathname)
    yield all(itemIds.map(itemId => put(deleteListItemSuccess({ listId, itemId }))))
    yield put(deselectAllListItems())
  } catch (error) {
    yield put(deleteListItemFail(error.message))
  }
}

/**
 * Save misc list that consinst of fish or meat items
 */
export function* handleSaveMiscList({
  payload: { formData, resolve, reject }
}: handleSaveMiscListParams) {
  try {
    const environmentId: string | undefined = yield select(selectEnvironmentIdFromPathname)
    const listId: string | undefined = yield select(selectContentIdFromPathname)
    const list: MiscListUI | undefined = yield select(selectListById(listId))
    const customerId: string | undefined = yield select(selectListCustomerById(listId))
    const type: ListType = yield select(selectListTypeByListId(listId))
    if (environmentId && listId && list && customerId && type) {
      const { items } = formData
      // update products
      const miscListUI = yield call(Api.updateMiscListItems, environmentId, listId, items)
      toast.success(i18n.t('lists.changesSaved'))
      yield put(saveMiscListSuccess(miscListUI))
      resolve()
    }
  } catch (error) {
    yield put(saveMiscListFail(error.message))
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.list.somethingWrongAddItem')
      })
    )
  }
}

export function* handleSaveRetailList() {
  try {
    const environmentId: string | undefined = yield select(selectEnvironmentIdFromPathname)
    const listId: string | undefined = yield select(selectContentIdFromPathname)
    const customerId: string | undefined = yield select(selectListCustomerById(listId))
    const customer: Customer = yield select(selectCustomerById(customerId))
    const { items }: RetailListUI = yield select(selectListById(listId))
    if (environmentId && listId && customerId && items) {
      if (!customer.productForm) {
        for (const item of items) {
          const { product } = item
          if (isRetailProduct(product)) {
            delete product.createdAt
            delete product.updatedAt // TODO: updatedAt might not be used atm but could be useful to habe
            delete product.fileMetadata
            yield call(Products.createPricerProduct, environmentId, customerId, product)
          }
        }
      }
      const listItems = items.map(({ productId, itemId }) => ({
        productId,
        itemId: itemId || nanoid()
      }))
      yield call(Api.updateListItems, environmentId, listId, listItems)
      toast.success(i18n.t('lists.changesSaved'))
      yield put(saveRetailListSuccess())
    }
  } catch (error) {
    yield put(saveRetailListFail(error.message))
  }
}

interface HandleCopyListParams {
  payload: string
}

function* handleCopyList({ payload: listId }: HandleCopyListParams) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const list: List = yield call(Api.copyList, environmentId, listId)
    toast.success(i18n.t('lists.copySuccess', { name: `(${list.name})` }))
    yield put(copyListSuccess(list))
  } catch (error) {
    yield put(copyListFail(error.message))
  }
}

function* watchListsActions() {
  yield all([
    takeLatest(getLists, handleGetLists),
    takeLatest(getList, handleGetList),
    takeLatest(createList, handleCreateList),
    takeLatest(updateList, handleUpdateList),
    takeLatest(deleteList, handleDeleteList),
    takeLatest(reorderItems, handleReorderItems),
    takeLatest(deleteListItem, handleDeleteListItem),
    takeLatest(addListItem, handleAddListItem),
    takeLatest(saveMiscList, handleSaveMiscList),
    takeLatest(addMiscListItem, handleAddMiscListItem),
    takeLatest(saveRetailList, handleSaveRetailList),
    takeLatest(copyList, handleCopyList)
  ])
}

export default [watchListsActions]
