import React from 'react'
import { useTranslation } from 'react-i18next'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import {
  Grid,
  Typography,
  DialogActions,
  Button,
  FormControl,
  FormLabel,
  FormControlLabel,
  FormGroup,
  MenuItem
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  FeaturePermission,
  FeaturePermissionLevel,
  EnvironmentUser,
  AutocompleteOption,
  EnvironmentUserRole
} from '@seesignage/seesignage-utils'
import { CloseDialog } from '../../../types/actions'

import {
  ReduxCreatableAutocomplete,
  ReduxCheckbox,
  ReduxTextField
} from '../../../components/FormInput/ReduxWrappers'
import { UserInterface } from '../../../types/users'
import { validateUserAccess, isAdminUser } from '../../../utils/permissions'
import { EditEnvironmentPermissionsFormData } from '../../../types/environments'

const useStyles = makeStyles(theme => ({
  formContainer: {
    padding: theme.spacing()
  }
}))

interface OwnProps {
  onSubmit: (formData: any) => void
  submitButtonLabel: string
  closeDialog: CloseDialog
  dialogId: string
  options?: AutocompleteOption[]
  currentUser: UserInterface
  user: EnvironmentUser
}

type EditEnvironmentPermissionsFormProps = OwnProps

const EditEnvironmentPermissionsForm: React.FC<EditEnvironmentPermissionsFormProps &
  InjectedFormProps<EditEnvironmentPermissionsFormData, OwnProps>> = ({
  handleSubmit,
  onSubmit,
  submitting,
  submitButtonLabel,
  closeDialog,
  dialogId,
  options,
  currentUser,
  user,
  error
}) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const currentUserWriteAccess = validateUserAccess(
    currentUser,
    FeaturePermission.templates,
    FeaturePermissionLevel.write
  )
  const currentUserReadAccess = validateUserAccess(
    currentUser,
    FeaturePermission.templates,
    FeaturePermissionLevel.read
  )
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} className={classes.formContainer}>
        {isAdminUser(currentUser) && (
          <Grid item xs={12}>
            <Field
              fullWidth
              label={t('environment.role')}
              name='role'
              component={ReduxTextField}
              select>
              <MenuItem value={EnvironmentUserRole.admin}>{t('environment.admin')}</MenuItem>
              <MenuItem value={EnvironmentUserRole.user}>{t('environment.user')}</MenuItem>
            </Field>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant='caption'>{t('environment.permissions.editDescription')}</Typography>
        </Grid>

        {!isAdminUser(user) && (
          <Grid item sm={6}>
            <FormControl variant='standard'>
              <FormLabel component='legend'>{t('lists.lists')}</FormLabel>
              <FormGroup>
                {Object.values(FeaturePermissionLevel).map(level => (
                  <FormControlLabel
                    key={`lists.${level}`}
                    label={level}
                    control={
                      <Field
                        key={`permissions.lists.${level}`}
                        name={`permissions.lists.${level}`}
                        component={ReduxCheckbox}
                        color='primary'
                      />
                    }
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>
        )}

        {
          // Only system admins or environment admins with access to templates can change this
        }
        {(currentUserReadAccess || currentUserWriteAccess) && (
          <Grid item xs={6}>
            <FormControl variant='standard'>
              <FormLabel component='legend'>{t('templates.templates')}</FormLabel>
              <FormGroup>
                <FormControlLabel
                  label={'read'}
                  control={
                    <Field
                      name={`permissions.templates.read`}
                      disabled={!currentUserReadAccess}
                      component={ReduxCheckbox}
                      color='primary'
                    />
                  }
                />
                <FormControlLabel
                  label={'write'}
                  control={
                    <Field
                      name={`permissions.templates.write`}
                      disabled={!currentUserWriteAccess}
                      component={ReduxCheckbox}
                      color='primary'
                    />
                  }
                />
              </FormGroup>
            </FormControl>
          </Grid>
        )}
        <Grid item sm={12}>
          <Field
            name='permissions.tags'
            label={t('screens.tags')}
            placeholder={t('screens.tags')}
            component={ReduxCreatableAutocomplete}
            multiple
            options={options}
          />
          <Typography variant='caption'>{t('environment.permissions.tags')}</Typography>
        </Grid>
      </Grid>
      <Typography variant='body1' color='error'>
        {error || ''}
      </Typography>
      <DialogActions>
        <Button onClick={() => closeDialog(dialogId)} color='primary'>
          {t('general.cancel')}
        </Button>
        <Button disabled={submitting} color='primary' type='submit'>
          {submitButtonLabel}
        </Button>
      </DialogActions>
    </form>
  )
}
export default reduxForm<EditEnvironmentPermissionsFormData, OwnProps>({
  form: 'EditEnvironmentPermissionsForm'
})(EditEnvironmentPermissionsForm)
