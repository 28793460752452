import { Grid } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import React, { Fragment } from 'react'
import DrawingBoxIcon from 'mdi-react/DrawingBoxIcon'
import { ContentPlaylistItemUI } from '@seesignage/seesignage-utils'
import Media from '../../../../../components/Media'
import ItemTitle from '../../../../Lists/EditList/RetailList/ItemTitle'
import { PlaylistItemCardRootProps } from '../../../../../types/playlists'
import SelectedIndicator from './SelectedIndicator'
import ItemIcons from './ItemIcons'

const styles = () => ({
  item: {
    height: 140,
    overflow: 'hidden'
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain' as any
  },
  imageArea: {
    height: '100%'
  },
  descriptionArea: {
    height: '100%',
    position: 'relative' as any,
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)'
  },
  mediaText: {
    padding: '4px',
    width: '100%'
  }
})

interface OwnProps extends PlaylistItemCardRootProps {
  item: ContentPlaylistItemUI
}

type ContentAsItemProps = OwnProps & WithStyles<typeof styles>

const ContentAsItem = ({
  classes,
  item,
  isItemSelected,
  itemPercentage,
  showPercentage
}: ContentAsItemProps) => {
  const {
    content: { name, thumbnailUrl }
  } = item
  return (
    <Fragment>
      <Grid container className={classes.item}>
        <Grid item xs={4} className={classes.imageArea}>
          <Media
            imageClass={classes.image}
            videoClass={classes.image}
            type='image'
            url={thumbnailUrl}
          />
        </Grid>
        <Grid item xs={8} className={classes.descriptionArea}>
          <Grid container style={{ height: '100%' }}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={1}>
                  <DrawingBoxIcon />
                </Grid>
                <Grid item xs={10}>
                  <Fragment>
                    <ItemTitle containerClass={classes.mediaText} name={name} />
                  </Fragment>
                </Grid>
                <SelectedIndicator
                  isItemSelected={isItemSelected}
                  showPercentage={showPercentage}
                  itemPercentage={itemPercentage}
                />
              </Grid>
            </Grid>
          </Grid>
          <ItemIcons item={item} />
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default withStyles(styles)(ContentAsItem)
