import React from 'react'
import { useDropzone } from 'react-dropzone'
import { Typography, ImageList } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import UploadIcon from 'mdi-react/UploadIcon'
import { WrappedFieldArrayProps, Field } from 'redux-form'
import { useSelector } from 'react-redux'
import { selectIsUserDeviceMobile } from '../../../selectors/users'
import { getFilesWithPreview } from '../../../utils/files'
import { FileWithPreview } from '../../../types/media'
import FileThumbnailField from './FileThumbnailField'

const useStyles = makeStyles(() => ({
  dropInBox: {
    height: 150,
    cursor: 'pointer',
    border: '5px inset rgb(145, 225, 255)',
    backgroundColor: '#e1e1e1',
    textAlign: 'center' as any,
    padding: '20px 10px 20px 10px',
    overflow: 'auto' as any,
    '&:hover': {
      boxShadow: '0px 0px 0px 1px #00aeef inset'
    } as any
  },
  dragActive: {
    backgroundImage:
      'repeating-linear-gradient(-45deg, #F0F0F0, #F0F0F0 25px, #C8C8C8 25px, #C8C8C8 50px)',
    animation: 'progress 2s linear infinite !important' as any
  },
  uploadIcon: {
    margin: '30px 10px 10px 10px'
  },
  imageListContainer: {
    marginTop: 10
  }
}))

interface FilesInputProps extends WrappedFieldArrayProps {
  label: string
  onChange: (files: FileWithPreview[]) => void
  existingFiles?: FileWithPreview[]
  showDropzone: boolean
}

/**
 * FilesInput component for FieldArray.
 */
const FilesInput = ({ label, fields, onChange, existingFiles, showDropzone }: FilesInputProps) => {
  const classes = useStyles()
  const isMobile = useSelector(selectIsUserDeviceMobile)

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: files => {
      const filesWithPreview = getFilesWithPreview(files)
      onChange([...(existingFiles ? existingFiles : []), ...filesWithPreview])
    },
    multiple: true
    // Note: 'accept' attribute is not needed as form files are validated in reduxForm validateAddMediaForm function.
  })

  return (
    <section className='container'>
      {showDropzone && (
        <div
          {...getRootProps({
            className: classNames(classes.dropInBox, isDragActive && classes.dragActive)
          })}>
          <input {...getInputProps()} />
          <UploadIcon size={30} className={classes.uploadIcon} />
          {label && (
            <Typography variant='subtitle1' gutterBottom>
              {label}
            </Typography>
          )}
        </div>
      )}
      <div className={classes.imageListContainer}>
        <ImageList rowHeight={147} cols={isMobile ? 1 : 2}>
          {fields.map((name, index) => (
            <Field
              name={name}
              type='text'
              component={FileThumbnailField}
              key={`file-${index}`}
              removeItem={() => fields.remove(index)}
            />
          ))}
        </ImageList>
      </div>
    </section>
  )
}

export default FilesInput
