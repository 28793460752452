import React from 'react'
import { Typography, Paper, Grid, Tooltip } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import { LunchProduct, LunchGroup, LunchItem } from '@seesignage/seesignage-utils'
import classNames from 'classnames'
import LinkIcon from 'mdi-react/LinkIcon'
import LinkOffIcon from 'mdi-react/LinkOffIcon'
import { TFunction } from 'i18next'
import colors from '../../../../styles/common/colors'
import TooltipContainer from '../../../../components/TooltipContainer'
import SpecialDietsBar from './SpecialDietsBar'

const styles = () => ({
  linkIcon: {
    float: 'right' as 'right',
    padding: '0px 4px 0px 4px',
    color: colors.parentLunchListColor
  },
  brokenLink: {
    color: colors.parentLunchListBrokenLinkColor
  },
  groupName: {
    padding: '4px 0 0 4px',
    color: colors.lunchListDefautlColor
  },
  groupNameParent: {
    color: colors.parentLunchListColor
  },
  groupNameParentWithChanges: {
    textDecoration: 'line-through'
  },
  productName: {
    padding: '4px 0 0 4px',
    color: colors.lunchListDefautlColor,
    fontWeight: 600
  },
  productNameParent: {
    color: colors.parentLunchListColor
  },
  hiddenProduct: {
    opacity: 0.3
  }
})

export interface OwnProductProps {
  isSubList: boolean
  /** exists when weeks view  */
  lunchItem?: LunchItem
  product: LunchProduct
  changeProduct?: LunchProduct
  changeGroup?: LunchGroup
  isHidden?: boolean
  group?: LunchGroup
  t: TFunction
  isRootProductView?: boolean
  isOverMaxItems?: boolean
}

type ProductProps = OwnProductProps & WithStyles<typeof styles>

const Product = ({
  t,
  classes,
  group,
  product: { name: productName, specialDiet, isParentProduct },
  changeProduct,
  isSubList,
  lunchItem,
  isRootProductView,
  isHidden,
  isOverMaxItems,
  changeGroup
}: ProductProps) => {
  const isFromParent =
    isSubList && ((isRootProductView && isParentProduct) || lunchItem?.isParentItem) ? true : false
  const hasProductChanges = !!changeProduct

  const tooltipContainerProps = {
    useTooltip: isHidden || isOverMaxItems,
    title: t(
      `lists.lunch.lunchItem.${isOverMaxItems ? 'isOverMaxItems' : `${isHidden ? 'isHidden' : ''}`}`
    )
  }

  return (
    <TooltipContainer {...tooltipContainerProps}>
      <Paper
        className={classNames({
          [classes.hiddenProduct]: isHidden || isOverMaxItems
        })}>
        <Grid container>
          <Grid item xs={8}>
            {group && !changeProduct && (
              <Typography
                variant='caption'
                display='block'
                className={classNames(classes.groupName, {
                  [classes.groupNameParent]: group.isParentGroup
                })}>
                {group.name}
              </Typography>
            )}
            <Typography
              className={classNames(classes.productName, {
                [classes.productNameParent]: isParentProduct,
                [classes.groupNameParentWithChanges]: hasProductChanges
              })}
              variant='caption'
              display='block'
              gutterBottom={!changeProduct}>
              {productName}
            </Typography>
            {changeGroup && (
              <Typography className={classes.groupName} variant='caption' display='block'>
                {changeGroup.name}
              </Typography>
            )}
            {changeProduct && (
              <Typography
                className={classes.productName}
                variant='caption'
                display='block'
                gutterBottom>
                {changeProduct.name}
              </Typography>
            )}
          </Grid>

          {// when sub list and lunchItem is from parent show link icon
          !!lunchItem && isFromParent && (
            <Grid item xs={4}>
              <Tooltip
                disableInteractive
                title={
                  t(
                    `lists.lunch.${hasProductChanges ? 'isParentItemWithChanges' : 'isParentItem'}`
                  ) as string
                }>
                <div>
                  {hasProductChanges ? (
                    <LinkOffIcon className={classNames(classes.linkIcon, classes.brokenLink)} />
                  ) : (
                    <LinkIcon className={classes.linkIcon} />
                  )}
                </div>
              </Tooltip>
            </Grid>
          )}
        </Grid>
        <SpecialDietsBar specialDiet={specialDiet} t={t} />
      </Paper>
    </TooltipContainer>
  )
}

export default withStyles(styles)(Product)
