import { IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { Fragment } from 'react'
import { TFunction } from 'i18next'
import DeleteIcon from 'mdi-react/DeleteIcon'
import ContentCopyIcon from 'mdi-react/ContentCopyIcon'
import ArrangeBringForwardIcon from 'mdi-react/ArrangeBringForwardIcon'
import ArrangeSendBackwardIcon from 'mdi-react/ArrangeSendBackwardIcon'
import FormatHorizontalAlignCenterIcon from 'mdi-react/FormatHorizontalAlignCenterIcon'
import FormatVerticalAlignCenterIcon from 'mdi-react/FormatVerticalAlignCenterIcon'
import { ObjectType } from '@seesignage/seesignage-utils'
import {
  isFabricCircleObject,
  isFabricDateObject,
  isFabricFalconyAnnouncementsObject,
  isFabricIframeObject,
  isFabricImageObject,
  isFabricLineObject,
  isFabricMediaCarouselObject,
  isFabricPathObject,
  isFabricPolygonObject,
  isFabricQRCodeObject,
  isFabricRectObject,
  isFabricRssFeedObject,
  isFabricSocialMediaObject,
  isFabricStopScheduleObject,
  isFabricTableObject,
  isFabricTextboxObject,
  isFabricTriangleObject,
  isFabricVideoObject,
  isFabricWeatherObject
} from '@seesignage/seesignage-player-utils'
import {
  generateImageInitialValues,
  generateVideoInitialValues,
  generateTextBoxInitialValues,
  generateCircleInitialValues,
  generateTriangleInitialValues,
  generateRectangleInitialValues,
  generateIframeInitialValues,
  generateStopScheduleInitialValues,
  generateTableInitialValues,
  generateDateWidgetInitialValues,
  generateWeatherWidgetInitialValues,
  generateRssFeedInitialValues,
  generateSocialMediaToolbarInitialValues,
  generateQRCodeInitialValues,
  generateLineDrawingFormInitialValues,
  generatePolygonInitialValues,
  generatePathInitialValues,
  generateMediaCarouselToolbarInitialValues,
  generateFalconyAnnouncementsToolbarInitialValues
} from '../../../../../utils/fabric/canvasFormValues'
import { SelectedObjectActionType } from '../../../../../types/contents'
import { SelectedObjectAction } from '../../../../../types/actions'
import { OpenContentEditorDialog } from '../../../../../types/dialogs'
import ContentTableForm from '../ContentToolbarForms/ContentTableForm'
import ContentTextBoxForm from '../ContentToolbarForms/ContentTextBoxForm'
import ContentImageForm from '../ContentToolbarForms/ContentImageForm'
import ContentVideoForm from '../ContentToolbarForms/ContentVideoForm'
import ContentRectangleForm from '../ContentToolbarForms/ContentRectangleForm'
import ContentTriangleForm from '../ContentToolbarForms/ContentTriangleForm'
import ContentCircleForm from '../ContentToolbarForms/ContentCircleForm'
import ContentIframeForm from '../ContentToolbarForms/ContentIframeForm'
import ContentStopScheduleForm from '../ContentToolbarForms/ContentStopScheduleForm'
import ContentDateForm from '../ContentToolbarForms/ContentDateForm'
import ContentWeatherForm from '../ContentToolbarForms/ContentWeatherForm'
import ContentQRCodeForm from '../ContentToolbarForms/ContentQRCodeForm'
import ContentRssFeedForm from '../ContentToolbarForms/ContentRssFeedForm'
import ContentMediaCarouselToolbarForm from '../ContentToolbarForms/ContentMediaCarouselToolbarForm'
import ContentSocialMediaToolbarForm from '../ContentToolbarForms/ContentSocialMediaToolbarForm'
import ContentLineForm from '../ContentToolbarForms/ContentLineForm'
import ContentPolygonForm from '../ContentToolbarForms/ContentPolygonForm'
import ContentToolbarItem from '../ContentToolbarItem/ContentToolbarItem'
import ContentPathForm from '../ContentToolbarForms/ContentPathForm'
import ContentFalconyAnnouncementsToolbarForm from '../ContentToolbarForms/ContentFalconyAnnouncementsToolbarForm'

const useStyles = makeStyles(() => ({
  icon: {
    color: 'rgba(0, 0, 0, 0.54)'
  }
}))

export interface SelectedObjectActionsOwnProps {
  t: TFunction
  selectedObjects: fabric.Object[]
  selectedObjectAction: SelectedObjectAction
  isSystemAdmin: boolean
  openDialog: OpenContentEditorDialog
}

type SelectedObjectActionsProps = SelectedObjectActionsOwnProps
const SelectedObjectActions = ({
  selectedObjectAction,
  selectedObjects,
  isSystemAdmin,
  t,
  openDialog
}: SelectedObjectActionsProps) => {
  const classes = useStyles()

  const selectedObject = selectedObjects.length === 1 ? selectedObjects[0] : undefined
  const lockMovementX = selectedObject?.lockMovementX
  const lockMovementY = selectedObject?.lockMovementY
  const someAxisLocked = lockMovementX || lockMovementY

  return (
    <Fragment>
      {isFabricTextboxObject(selectedObject) && (
        <ContentTextBoxForm initialValues={generateTextBoxInitialValues(selectedObject)} />
      )}
      {isFabricImageObject(selectedObject) && (
        <ContentImageForm
          openDialog={openDialog}
          initialValues={generateImageInitialValues(selectedObject)}
        />
      )}
      {isFabricIframeObject(selectedObject) && (
        <ContentIframeForm initialValues={generateIframeInitialValues(selectedObject)} />
      )}
      {isFabricDateObject(selectedObject) && (
        <ContentDateForm initialValues={generateDateWidgetInitialValues(selectedObject)} />
      )}
      {isFabricWeatherObject(selectedObject) && (
        <ContentWeatherForm initialValues={generateWeatherWidgetInitialValues(selectedObject)} />
      )}
      {isFabricStopScheduleObject(selectedObject) && (
        <ContentStopScheduleForm
          initialValues={generateStopScheduleInitialValues(selectedObject)}
        />
      )}
      {isFabricRssFeedObject(selectedObject) && (
        <ContentRssFeedForm initialValues={generateRssFeedInitialValues(selectedObject)} />
      )}
      {isFabricMediaCarouselObject(selectedObject) && (
        <ContentMediaCarouselToolbarForm
          openDialog={openDialog}
          initialValues={generateMediaCarouselToolbarInitialValues(selectedObject)}
        />
      )}
      {isFabricTableObject(selectedObject) && (
        <ContentTableForm
          initialValues={generateTableInitialValues(selectedObject)}
          fieldsDisabled={
            !isSystemAdmin && selectedObject.customOptions.widgetProps.schema.disableEditStyles
              ? true
              : false
          }
        />
      )}
      {isFabricVideoObject(selectedObject) && (
        <ContentVideoForm
          openDialog={openDialog}
          initialValues={generateVideoInitialValues(selectedObject)}
          selectedObjectAction={selectedObjectAction}
        />
      )}
      {isFabricRectObject(selectedObject) && (
        <ContentRectangleForm initialValues={generateRectangleInitialValues(selectedObject)} />
      )}
      {isFabricPathObject(selectedObject) && (
        <ContentPathForm initialValues={generatePathInitialValues(selectedObject)} />
      )}
      {isFabricTriangleObject(selectedObject) && (
        <ContentTriangleForm initialValues={generateTriangleInitialValues(selectedObject)} />
      )}
      {isFabricCircleObject(selectedObject) && (
        <ContentCircleForm initialValues={generateCircleInitialValues(selectedObject)} />
      )}
      {isFabricLineObject(selectedObject) && (
        <ContentLineForm initialValues={generateLineDrawingFormInitialValues(selectedObject)} />
      )}
      {isFabricPolygonObject(selectedObject) && (
        <ContentPolygonForm initialValues={generatePolygonInitialValues(selectedObject)} />
      )}
      {isFabricSocialMediaObject(selectedObject) && (
        <ContentSocialMediaToolbarForm
          openDialog={openDialog}
          initialValues={generateSocialMediaToolbarInitialValues(selectedObject)}
        />
      )}
      {isFabricQRCodeObject(selectedObject) && (
        <ContentQRCodeForm
          initialValues={generateQRCodeInitialValues(selectedObject)}
          someAxisLocked={someAxisLocked}
        />
      )}
      {isFabricFalconyAnnouncementsObject(selectedObject) && (
        <ContentFalconyAnnouncementsToolbarForm
          initialValues={generateFalconyAnnouncementsToolbarInitialValues(selectedObject)}
        />
      )}
      {selectedObject &&
        ![
          ObjectType.CustomDate,
          ObjectType.CustomWeather,
          ObjectType.CustomTable,
          ObjectType.CustomStopSchedule,
          ObjectType.CustomVideo,
          ObjectType.CustomIframe,
          ObjectType.CustomMediaCarousel,
          ObjectType.CustomFalconyAnnouncements,
          ObjectType.CustomQRCode,
          ObjectType.CustomSocialMedia,
          'activeSelection'
        ].includes((selectedObject as any)?.type) && (
          <Fragment>
            {![ObjectType.CustomRssFeed].includes((selectedObject as any).type) && (
              <>
                <ContentToolbarItem
                  tooltip={t(
                    lockMovementX
                      ? 'contents.actions.horizontalCenter'
                      : 'contents.actions.xAxisLocked'
                  )}>
                  <IconButton
                    disabled={lockMovementX}
                    onClick={() => selectedObjectAction(SelectedObjectActionType.horizontalCenter)}
                    size='small'>
                    <FormatHorizontalAlignCenterIcon className={classes.icon} />
                  </IconButton>
                </ContentToolbarItem>
                <ContentToolbarItem
                  tooltip={t(
                    lockMovementY
                      ? 'contents.actions.yAxisLocked'
                      : 'contents.actions.verticalCenter'
                  )}>
                  <IconButton
                    disabled={lockMovementY}
                    onClick={() => selectedObjectAction(SelectedObjectActionType.verticalCenter)}
                    size='small'>
                    <FormatVerticalAlignCenterIcon className={classes.icon} />
                  </IconButton>
                </ContentToolbarItem>
                <ContentToolbarItem
                  tooltip={t(
                    someAxisLocked ? 'contents.actions.objectLocked' : 'contents.actions.forward'
                  )}>
                  <IconButton
                    disabled={someAxisLocked}
                    onClick={() => selectedObjectAction(SelectedObjectActionType.forward)}
                    size='small'>
                    <ArrangeBringForwardIcon className={classes.icon} />
                  </IconButton>
                </ContentToolbarItem>
                <ContentToolbarItem
                  tooltip={t(
                    someAxisLocked ? 'contents.actions.objectLocked' : 'contents.actions.backward'
                  )}>
                  <IconButton
                    disabled={someAxisLocked}
                    onClick={() => selectedObjectAction(SelectedObjectActionType.backward)}
                    size='small'>
                    <ArrangeSendBackwardIcon className={classes.icon} />
                  </IconButton>
                </ContentToolbarItem>
              </>
            )}

            <ContentToolbarItem tooltip={t('contents.actions.clone')}>
              <IconButton
                onClick={() => selectedObjectAction(SelectedObjectActionType.clone)}
                size='small'>
                <ContentCopyIcon className={classes.icon} />
              </IconButton>
            </ContentToolbarItem>
          </Fragment>
        )}
      {(selectedObject as any)?.type === ObjectType.CustomTable && (
        <ContentToolbarItem tooltip={t('contents.actions.clone')}>
          <IconButton
            onClick={() => selectedObjectAction(SelectedObjectActionType.clone)}
            size='small'>
            <ContentCopyIcon className={classes.icon} />
          </IconButton>
        </ContentToolbarItem>
      )}
      <ContentToolbarItem
        tooltip={t(someAxisLocked ? 'contents.actions.objectLocked' : 'contents.actions.delete')}>
        <IconButton
          disabled={someAxisLocked}
          onClick={() => selectedObjectAction(SelectedObjectActionType.delete)}
          size='small'>
          <DeleteIcon className={classes.icon} />
        </IconButton>
      </ContentToolbarItem>
    </Fragment>
  )
}

export default SelectedObjectActions
