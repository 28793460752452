import {
  Button,
  DialogActions,
  Grid,
  Typography,
  LinearProgress,
  FormControlLabel,
  Radio,
  InputAdornment
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import FolderOpenIcon from 'mdi-react/FolderOpenIcon'
import { Field, FieldArray, reduxForm, InjectedFormProps, formValueSelector } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { selectIsUserDeviceMobile } from '../../../selectors/users'
import {
  ReduxRadioGroup,
  ReduxSelectWithAutocomplete
} from '../../../components/FormInput/ReduxWrappers'
import { selectAllFoldersAsOptions } from '../../../selectors/media'
import {
  selectEnvironmentUhdVideosEnabled,
  selectEnvironmentHqVideosEnabled
} from '../../../selectors/environments'
import { selectEnvironmentIdFromPathname } from '../../../selectors/routing'
import FilesInput from '../../../components/FormInput/FilesInput/FilesInput'
import { validateAddMediaForm } from '../../../validation/forms'
import { FileWithPreview, AddMediaFormData } from '../../../types/media'
import ErrorMessage from '../../../components/Errors/ErrorMessage'
import { clearDropzone, listFolders } from '../../../actions/media'
import { required } from '../../../validation'
import { compareStrings } from '../../../utils/sorting'
import FormContainer from '../../../components/FormContainer/FormContainer'

const useStyles = makeStyles(() => ({
  dialogActionsMobile: {
    justifyContent: 'center'
  },
  folderContainer: {
    margin: 4,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  gridRoot: {
    paddingTop: 5
  }
}))

interface AddMediaFormOwnProps {
  onSubmit: (arg: any) => void
  backButtonOnClick: () => void
  submitButtonLabel: string
  backButtonLabel: string
  showDropzone: boolean
  /** We do not want to use form onSubmit when form is under another form in React DOM. Otherwise, it would submit also other forms. */
  useFormOnSubmit: boolean
}

type FormDataProps = AddMediaFormData

const AddMediaForm: React.FC<AddMediaFormOwnProps &
  InjectedFormProps<FormDataProps, AddMediaFormOwnProps>> = ({
  onSubmit,
  handleSubmit,
  submitting,
  error,
  change,
  invalid, // form has validation errors
  submitButtonLabel,
  backButtonLabel,
  backButtonOnClick,
  showDropzone,
  useFormOnSubmit,
  form
}) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const folders = useSelector(selectAllFoldersAsOptions()).sort(compareStrings('label'))
  const hqSelected = useSelector(state => formValueSelector(form)(state, 'quality') === 'hq')
  const files: FileWithPreview[] | undefined = useSelector(state =>
    formValueSelector(form)(state, 'files')
  )
  const isMobile = useSelector(selectIsUserDeviceMobile)
  const environmentId = useSelector(selectEnvironmentIdFromPathname)
  const hqVideosEnabled = useSelector(selectEnvironmentHqVideosEnabled(environmentId))
  const uhdVideosEnabled = useSelector(selectEnvironmentUhdVideosEnabled(environmentId))

  useEffect(() => {
    if (folders.length === 0) {
      dispatch(listFolders())
    }
  }, [dispatch, folders.length])

  useEffect(() => {
    return () => {
      // clear dropzone in unmount component
      dispatch(clearDropzone())
    }
  }, [dispatch])

  useEffect(() => {
    // if all new files removed, clear dropzone from store so AddMediaForm won't be visible anymore.
    if (files?.length === 0) {
      dispatch(clearDropzone())
    }
  }, [dispatch, files])

  const allVideoFiles =
    files && files.length > 0 ? files.every(file => file.type.startsWith('video')) : false

  const isSubmitDisabled = submitting || invalid || files?.length === 0 || files === undefined

  return (
    <FormContainer onSubmit={useFormOnSubmit ? handleSubmit(onSubmit) : undefined}>
      <Grid container spacing={2} className={classes.gridRoot}>
        <Grid item xs={12}>
          <Field
            name='parentFolderId'
            label={t('media.saveMediaToFolder')}
            component={ReduxSelectWithAutocomplete}
            OptionIcon={FolderOpenIcon}
            options={[{ value: null, label: 'root' }, ...folders]}
            validate={[required]}
            placeholder={t('general.select')}
            disableClearable
            inputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <FolderOpenIcon />
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FieldArray
            name='files'
            component={FilesInput}
            props={{
              onChange: (allFiles: FileWithPreview[]) => {
                change('files', allFiles)
              },
              existingFiles: files,
              label: t('media.addMediaText'),
              showDropzone
            }}
          />
          {submitting && <LinearProgress />}
        </Grid>
        {allVideoFiles && (hqVideosEnabled || uhdVideosEnabled) && (
          <Grid item xs={12}>
            <Field
              name='quality'
              label={t('media.quality')}
              component={ReduxRadioGroup}
              style={{ display: 'flex', flexDirection: 'row' }}>
              <FormControlLabel value='fullhd' label={'Full HD'} control={<Radio />} />
              {hqVideosEnabled && (
                <FormControlLabel value='hq' label='High Quality' control={<Radio />} />
              )}
              {uhdVideosEnabled && <FormControlLabel value='uhd' label='4K' control={<Radio />} />}
            </Field>
            {hqSelected && (
              <Typography display='block' variant='caption' color='error'>
                {t('media.hqVerification')}
              </Typography>
            )}
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} sm={12}>
        <ErrorMessage message={error} />
      </Grid>
      <DialogActions className={isMobile ? classes.dialogActionsMobile : undefined}>
        <Button onClick={backButtonOnClick} color='primary'>
          {backButtonLabel}
        </Button>
        <Button
          disabled={isSubmitDisabled}
          color='primary'
          type={useFormOnSubmit ? 'submit' : 'button'}
          onClick={!useFormOnSubmit ? handleSubmit(onSubmit) : undefined}
          variant='contained'>
          {submitButtonLabel}
        </Button>
      </DialogActions>
    </FormContainer>
  )
}

export default reduxForm<FormDataProps, AddMediaFormOwnProps>({
  form: 'AddMediaForm', // note: form name is overwritten in SelectMultipleMediaField
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  validate: validateAddMediaForm
})(AddMediaForm)
