import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { LinearProgress, Grid, MenuItem, Tooltip, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ScreenResponse } from '@seesignage/seesignage-utils'
import { useTranslation } from 'react-i18next'
import CheckBoxMultipleOutlineIcon from 'mdi-react/CheckBoxMultipleOutlineIcon'
import CloseBoxMultipleOutlineIcon from 'mdi-react/CloseBoxMultipleOutlineIcon'
import {
  selectListScreensIsLoading,
  selectScreensBySearch,
  selectSelectedScreenIds
} from '../../../selectors/screens'
import { selectIsUserDeviceMobile } from '../../../selectors/users'
import { StateInterface } from '../../../types/states'
import { SortByValue, SortDirection } from '../../../types/sortings'
import Sorter from '../../../components/Sorting'
import { compareBySortType } from '../../../utils/sorting'
import { selectAllScreens, deselectAllScreens } from '../../../actions/screens'
import { FilterByStatus } from '../../../types/screens'
import colors from '../../../styles/common/colors'
import ScreenCard from './ScreenCard'

const styles = {
  screens: {
    margin: '10px 5px 10px 5px',
    alignItems: 'center'
  }
}

const useStyles = makeStyles(styles)

interface OwnProps {
  searchTerm: string
  selectedStatus: FilterByStatus | null
  selectedTags: string[]
}

interface StateProps {
  screens: ScreenResponse[]
  selectedScreenIds: string[]
  isLoading: boolean
  isMobile: boolean
}

type ScreensCardViewProps = OwnProps & StateProps

const ScreensCardView: React.FC<ScreensCardViewProps> = ({
  screens: allScreens,
  selectedScreenIds,
  isLoading,
  isMobile
}) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const [sortBy, setSortBy] = useState<SortByValue>(SortByValue.name)
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.asc)
  const sorterProps = {
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    t
  }
  const dispatch = useDispatch()
  const screens = allScreens.sort(compareBySortType(sortBy, sortDirection))
  const allScreensSelected = selectedScreenIds.length === screens.length
  const handleScreenSelection = () => {
    if (allScreensSelected) {
      dispatch(deselectAllScreens())
    } else {
      dispatch(selectAllScreens(screens.map(({ screenId }) => screenId)))
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      {screens.length > 0 && (
        <div style={{ width: '100%' }}>
          <Tooltip
            disableInteractive
            title={
              allScreensSelected
                ? (t('screens.deselect', { count: selectedScreenIds.length }) as string)
                : (t('general.selectAll') as string)
            }>
            <IconButton onClick={handleScreenSelection} size='large'>
              {allScreensSelected ? (
                <CloseBoxMultipleOutlineIcon color={colors.seesignageColor} />
              ) : (
                <CheckBoxMultipleOutlineIcon />
              )}
            </IconButton>
          </Tooltip>
          <Sorter {...sorterProps}>
            <MenuItem value='name'>{t('sorting.sortBy.name')}</MenuItem>
            <MenuItem value='createdAt'>{t('sorting.sortBy.createdAt')}</MenuItem>
            <MenuItem value='lastOnline'>{t('sorting.sortBy.lastOnline')}</MenuItem>
          </Sorter>
        </div>
      )}
      <Grid
        container
        className={classes.screens}
        alignItems={isMobile ? 'center' : 'flex-start'}
        justifyContent={isMobile ? 'center' : 'flex-start'}>
        {isLoading && <LinearProgress />}
        {screens.map(screen => (
          <ScreenCard key={screen.screenId} screen={screen} selectedScreenIds={selectedScreenIds} />
        ))}
      </Grid>
    </div>
  )
}
const mapStateToProps = (state: StateInterface, ownProps: OwnProps): StateProps => ({
  screens: selectScreensBySearch(
    ownProps.searchTerm,
    ownProps.selectedTags,
    ownProps.selectedStatus
  )(state),
  selectedScreenIds: selectSelectedScreenIds(state),
  isLoading: selectListScreensIsLoading(state),
  isMobile: selectIsUserDeviceMobile(state)
})

export default connect(mapStateToProps)(ScreensCardView)
