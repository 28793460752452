import { path } from 'ramda'
import {
  Environment,
  FeaturePermissionLevel,
  EnvironmentUser,
  FeaturePermission,
  UserRole,
  EnvironmentUI,
  Channel,
  PlaylistUI
} from '@seesignage/seesignage-utils'
import { UserInterface } from '../types/users'

const isAdminRole = (role?: UserRole) => role === UserRole.admin

/** Check that does given user have admin access to given environment */
const checkAdminAccess = (
  selectedEnvironment: Environment | EnvironmentUI | undefined,
  userSub: string
) =>
  selectedEnvironment?.users.some(user => user.role === 'admin' && user.sub === userSub)
    ? true
    : false

/**
 * Check if user is System admin
 */
const isSystemAdminUser = (user?: UserInterface) => user?.role === UserRole.admin

/**
 * Check does given user have system admin access or has environment admin access
 * @param user
 */
const isAdminUser = (user?: EnvironmentUser | UserInterface) =>
  path(['role'], user) === 'admin' || path(['environmentRole'], user) === 'admin'

/**
 * Validate user access to given feature and level
 * System admins have access everywhere. Environment admins always have access to lists
 */
const validateUserAccess = (
  user: UserInterface | EnvironmentUser,
  feature: FeaturePermission,
  level: FeaturePermissionLevel
) => {
  if (isAdminUser(user) && feature === FeaturePermission.lists) {
    return true
  }
  const hasAccess = user.role === 'admin' ? true : user.permissions?.[feature]?.[level]
  return hasAccess ? true : false
}

/**
 * Validate user access to write given document.
 * For example, if true user can edit given document, if false user can't edit.
 */
const validateDocumentWriteAccess = (obj: Channel, user: UserInterface) => {
  if (isSystemAdminUser(user)) {
    // system admin can always write to every document
    return true
  } else if (obj.permissions?.usePermissions) {
    // document permission are in use
    if (obj.permissions.user?.write === false || obj.permissions.user?.write === undefined) {
      // if write permission is undefined or false, user can't write to document
      return false
    }
    return true
  }
  return true
}

/**
 * Validate user access to write given document.
 */
const validatePlaylistWriteAccess = (obj: PlaylistUI, user: UserInterface) => {
  if (isSystemAdminUser(user)) {
    // system admin can always write to every document
    return true
  } else if (obj.integration?.source) {
    // integration playlist's write is disabled if user is not system admin
    return false
  }
  return true
}

/**
 * Check if user has access to navigate Channel or Playlist from screens view.
 * Backend response has userHasAccess boolean in document that describes if user has access to it.
 * NOTE: do not allow user to navigate to document that is from different environment. It would require extra logic.
 */
const validateUserDocumentAccessFromScreensView = (
  document: Channel | PlaylistUI,
  currenEnvironmentId: string
) => currenEnvironmentId === document.environmentId && document.userHasAccess === true

export {
  isAdminRole,
  isSystemAdminUser,
  isAdminUser,
  checkAdminAccess,
  validateUserAccess,
  validateDocumentWriteAccess,
  validatePlaylistWriteAccess,
  validateUserDocumentAccessFromScreensView
}
