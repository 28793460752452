/** Reorder items in same list */
const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

/**
 * Are arrays equal
 * @example
 * const a = [1, 2, 3]
 * const b = [1, 2, 3]
 * areArraysEqual(a,b)
 * // => true
 * @example
 * const a = ['one', 'two', 'three']
 * const b = ['one', 'four']
 * areArraysEqual(a,b)
 * // => false
 * @param a
 * @param b
 */
const areArraysEqual = (arr1: any[], arr2: any[]) =>
  arr2.every(arr2Item => arr1.includes(arr2Item)) && arr1.every(arr1Item => arr2.includes(arr1Item))

export { reorder, areArraysEqual }
