import React, { Fragment, useState } from 'react'
import {
  Paper,
  Typography,
  IconButton,
  SwipeableDrawer,
  Toolbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent
} from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import CloseIcon from 'mdi-react/CloseIcon'

import { TFunction } from 'i18next'
import { PreviewFrame } from '@seesignage/seesignage-player-utils'
import {
  getLunchItemsForDay,
  LunchList,
  LunchListWeekDay,
  Template,
  TemplateType,
  TemplateOrientation
} from '@seesignage/seesignage-utils'

import classNames from 'classnames'
import { path } from 'ramda'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { getResolution } from '../../../../utils/preview'
import { selectTemplateOrientation } from '../../../../actions/templates'
import { selectSelectedTemplateOrientation } from '../../../../selectors/templates'
import { SelectOrientation } from '../../../../types/actions'
import { StateInterface } from '../../../../types/states'

const orderedDays = [
  LunchListWeekDay.mon,
  LunchListWeekDay.tue,
  LunchListWeekDay.wed,
  LunchListWeekDay.thu,
  LunchListWeekDay.fri,
  LunchListWeekDay.sat,
  LunchListWeekDay.sun
]

const styles = () => ({
  container: {
    marginTop: 8,
    height: '100%',
    paddingLeft: 10
  },
  containerLandscape: {
    maxWidth: 610
  },
  containerPortrait: {
    maxWidth: 470
  },
  actionsContainer: {
    paddingBottom: 4
  },
  screenContainer: {
    transformOrigin: 'top left'
  },
  screenContainerLandscape: {
    transform: 'scale(0.3)'
  },
  screenContainerPortrait: {
    transform: 'scale(0.35)'
  },
  formControl: {
    marginRight: 8,
    width: 130
  }
})

const renderDayMenuOptions = (t: TFunction, list: LunchList, weekIndex: number | undefined) =>
  orderedDays
    .filter(day =>
      weekIndex !== undefined && list.weeks?.length ? !!list.weeks[weekIndex].days[day] : false
    )
    .map((day, index) => (
      <MenuItem key={`menu-item-day-${index}`} value={day}>
        {t(`lists.lunch.weekdays.${day}`)}
      </MenuItem>
    ))

const findFirstAvailableDay = (weekIndex: number, list: LunchList) =>
  orderedDays.find(cday => {
    return path(['weeks', weekIndex, 'days', cday], list) !== undefined
  })

interface StateProps {
  selectedOrientation: TemplateOrientation
}

interface DispatchProps {
  selectOrientation: SelectOrientation
}

export interface LunchListPreviewProps {
  t: TFunction
  isPreviewOpen: boolean
  template: Template
  list: LunchList
  togglePreview: () => void
}

type ComponentProps = StateProps & DispatchProps & LunchListPreviewProps
type OwnProps = ComponentProps & WithStyles<typeof styles>

const LunchListPreview = ({
  t,
  isPreviewOpen,
  togglePreview,
  classes,
  template,
  list,
  selectedOrientation,
  selectOrientation
}: OwnProps) => {
  const [weekIndex, setWeekIndex] = useState<number | undefined>(
    list?.weeks?.length && list.weeks.length > 0 ? 0 : undefined // weekIndex 0 by default if exists
  )
  const [day, setDay] = useState<LunchListWeekDay | undefined>(
    findFirstAvailableDay(0, list)
    // use week 0's first available day by default
  )
  const { width, height } = getResolution(selectedOrientation)

  const handleWeekChange = (event: SelectChangeEvent<number>) => {
    const newWeekIndex = parseInt(event.target.value as string)
    setWeekIndex(newWeekIndex)
    setDay(findFirstAvailableDay(newWeekIndex, list))
    // set first available day because some weeks might not have same day
  }

  const handleDayChange = (event: SelectChangeEvent<LunchListWeekDay>) => {
    setDay(event.target.value as LunchListWeekDay)
  }
  return (
    <SwipeableDrawer
      anchor='right'
      open={isPreviewOpen}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onOpen={() => {}}
      onClose={togglePreview}
      disableSwipeToOpen>
      <Paper>
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='close drawer'
            onClick={togglePreview}
            edge='start'
            size='large'>
            <CloseIcon />
          </IconButton>
          <Typography variant='h6' noWrap>
            {t('lists.lunch.preview.title')}
          </Typography>
        </Toolbar>
        <Paper className={classes.actionsContainer}>
          <Toolbar>
            <FormControl variant='standard' className={classes.formControl}>
              <InputLabel htmlFor='orientation'>
                {t('lists.lunch.preview.selectOrientation')}
              </InputLabel>
              <Select
                variant='standard'
                value={selectedOrientation || 'landscape'}
                onChange={(e: any) => selectOrientation(e.target.value)}
                inputProps={{
                  name: 'orientation',
                  id: 'orientation'
                }}>
                <MenuItem value='landscape'>{t('screens.landscape')}</MenuItem>
                <MenuItem value='portrait'>{t('screens.portrait')}</MenuItem>
              </Select>
            </FormControl>
            {list.weeks && (
              <Fragment>
                <FormControl variant='standard' className={classes.formControl}>
                  <InputLabel id='selectWeek'>{t('lists.lunch.preview.selectWeek')}</InputLabel>
                  <Select
                    variant='standard'
                    labelId='selectWeek'
                    id='selectWeek'
                    value={weekIndex}
                    onChange={handleWeekChange}>
                    {list.weeks.map((week, index) => (
                      <MenuItem key={`menu-item-week-${index}`} value={index}>
                        {t('lists.lunch.weekTitle', {
                          weekNumber: index + 1
                        })}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {weekIndex !== undefined && (
                  <FormControl variant='standard' className={classes.formControl}>
                    <InputLabel id='selectday'>{t('lists.lunch.preview.selectDay')}</InputLabel>
                    <Select
                      variant='standard'
                      labelId='selectday'
                      id='selectday'
                      value={day}
                      onChange={handleDayChange}>
                      {renderDayMenuOptions(t, list, weekIndex)}
                    </Select>
                  </FormControl>
                )}
              </Fragment>
            )}
          </Toolbar>
        </Paper>
        <div
          className={classNames(classes.container, {
            [classes.containerLandscape]: selectedOrientation === 'landscape',
            [classes.containerPortrait]: selectedOrientation === 'portrait'
          })}>
          <div
            className={classNames(classes.screenContainer, {
              [classes.screenContainerLandscape]: selectedOrientation === 'landscape',
              [classes.screenContainerPortrait]: selectedOrientation === 'portrait'
            })}>
            <PreviewFrame
              content={{
                listPrice: list.price,
                orientation: selectedOrientation,
                items:
                  weekIndex !== undefined && day !== undefined
                    ? (getLunchItemsForDay(list, weekIndex, day, template.maxItems) as any[])
                    : [],
                template: {
                  schema: template.schema,
                  environmentId: 'fe0e5877-87be-4ffe-9120-f172a3164431',
                  keys: template.keys,
                  name: 'lunch template preview',
                  templateId: '986a2660-7643-4e16-a315-2a4af97808ca',
                  type: TemplateType.lunch,
                  createdAt: 1,
                  components: template.components
                }
              }}
              width={width}
              height={height}
            />
          </div>
        </div>
      </Paper>
    </SwipeableDrawer>
  )
}

const mapStateToProps = (state: StateInterface): StateProps => ({
  selectedOrientation: selectSelectedTemplateOrientation(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  selectOrientation: (orientation: string) => dispatch(selectTemplateOrientation(orientation))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LunchListPreview))
