import { createAsyncActions, createAction } from './utils'

const reducer = 'PLAYLISTS'

const resetPlaylists = createAction(reducer, 'RESET')

const deselectPlaylist = createAction(reducer, 'DESELECT_PLAYLIST')

const selectPlaylistItem = createAction(reducer, 'SELECT_ITEM')
const deselectPlaylistItem = createAction(reducer, 'DESELECT_ITEM')

const selectAllPlaylistItems = createAction(reducer, 'SELECT_ALL_ITEMS')
const deselectAllPlaylistItems = createAction(reducer, 'DESELECT_ALL_ITEMS')

const addPlaylistItemSuccess = createAction(reducer, 'ADD_PLAYLIST_ITEM_SUCCESS')

const checkUrlIsValid = createAction(reducer, 'CHECK_URL_VALID')

const updateInfopageItem = createAction(reducer, 'UPDATE_INFOPAGE_ITEM_SUCCESS')
const updateInfopageItemThumbnail = createAction(reducer, 'UPDATE_INFOPAGE_ITEM_THUMBNAIL')

const updateTranscodedVideoItem = createAction(reducer, 'UPDATE_TRANSCODED_ITEM')

const createEmptyInfopageToPlaylist = createAction(reducer, 'CREATE_EMPTY_INFOPAGE')

const updateContentThumbnail = createAction(reducer, 'UPDATE_CONTENT_THUMBNAIL')

const closePlaylistItemWizard = createAction(reducer, 'CLOSE_PLAYLIST_ITEM_WIZARD')
const setPlaylistItemWizardPage = createAction(reducer, 'SET_PLAYLIST_ITEM_WIZARD_PAGE')

const {
  action: reorderItems,
  actionSuccess: reorderItemsSuccess,
  actionFail: reorderItemsFail
} = createAsyncActions(reducer, 'REORDER_ITEMS')

const {
  action: listPlaylists,
  actionSuccess: listPlaylistsSuccess,
  actionFail: listPlaylistsFail
} = createAsyncActions(reducer, 'LIST')

const {
  action: getPlaylist,
  actionSuccess: getPlaylistSuccess,
  actionFail: getPlaylistFail
} = createAsyncActions(reducer, 'GET_PLAYLIST')

const {
  action: deletePlaylist,
  actionSuccess: deletePlaylistSuccess,
  actionFail: deletePlaylistFail
} = createAsyncActions(reducer, 'DELETE')

const {
  action: updatePlaylist,
  actionSuccess: updatePlaylistSuccess,
  actionFail: updatePlaylistFail
} = createAsyncActions(reducer, 'UPDATE')

const {
  action: createPlaylist,
  actionSuccess: createPlaylistSuccess,
  actionFail: createPlaylistFail
} = createAsyncActions(reducer, 'CREATE')

const {
  action: addPlaylistItems,
  actionSuccess: addPlaylistItemsSuccess,
  actionFail: addPlaylistItemsFail
} = createAsyncActions(reducer, 'ADD_PLAYLIST_ITEMS')

const {
  action: hidePlaylistItem,
  actionSuccess: hidePlaylistItemSuccess,
  actionFail: hidePlaylistItemFail
} = createAsyncActions(reducer, 'HIDE_PLAYLIST_ITEM')

const {
  action: duplicatePlaylistItem,
  actionSuccess: duplicatePlaylistItemSuccess,
  actionFail: duplicatePlaylistItemFail
} = createAsyncActions(reducer, 'DUPLICATE_PLAYLIST_ITEM')

const {
  action: deletePlaylistItem,
  actionSuccess: deletePlaylistItemSuccess,
  actionFail: deletePlaylistItemFail
} = createAsyncActions(reducer, 'DELETE_PLAYLIST_ITEM')

const {
  action: schedulePlaylistItem,
  actionSuccess: schedulePlaylistItemSuccess,
  actionFail: schedulePlaylistItemFail
} = createAsyncActions(reducer, 'SCHEDULE_PLAYLIST_ITEM')

const {
  action: intervalPlaylistItem,
  actionSuccess: intervalPlaylistItemSuccess,
  actionFail: intervalPlaylistItemFail
} = createAsyncActions(reducer, 'INTERVAL_PLAYLIST_ITEM')

const {
  action: uploadFile,
  actionSuccess: uploadFileSuccess,
  actionFail: uploadFileFail
} = createAsyncActions(reducer, 'UPLOAD_FILE')

const {
  action: addPlaylistItemCondition,
  actionSuccess: addPlaylistItemConditionSuccess,
  actionFail: addPlaylistItemConditionFail
} = createAsyncActions(reducer, 'ADD_PLAYLIST_ITEM_CONDITION')

const {
  action: removePlaylistItemInterval,
  actionSuccess: removePlaylistItemIntervalSuccess,
  actionFail: removePlaylistItemIntervalFail
} = createAsyncActions(reducer, 'REMOVE_PLAYLIST_ITEM_INTERVAL')

const {
  action: copyPlaylist,
  actionSuccess: copyPlaylistSuccess,
  actionFail: copyPlaylistFail
} = createAsyncActions(reducer, 'COPY_PLAYLIST')

const {
  action: updatePlaylistItems,
  actionSuccess: updatePlaylistItemsSuccess,
  actionFail: updatePlaylistItemsFail
} = createAsyncActions(reducer, 'UPDATE_ITEMS')

const {
  action: updateRevolverItem,
  actionSuccess: updateRevolverItemSuccess,
  actionFail: updateRevolverItemFail
} = createAsyncActions(reducer, 'UPDATE_REVOLVER_ITEM')

const {
  action: updateUrlItem,
  actionSuccess: updateUrlItemSuccess,
  actionFail: updateUrlItemFail
} = createAsyncActions(reducer, 'UPDATE_URL_ITEM')

const {
  action: updateProductItemsTemplate,
  actionSuccess: updateProductItemsTemplateSuccess,
  actionFail: updateProductItemsTemplateFail
} = createAsyncActions(reducer, 'UPDATE_PRODUCT_ITEMS_TEMPLATE')

export {
  resetPlaylists,
  deselectPlaylist,
  selectPlaylistItem,
  deselectPlaylistItem,
  selectAllPlaylistItems,
  deselectAllPlaylistItems,
  reorderItems,
  reorderItemsSuccess,
  reorderItemsFail,
  listPlaylists,
  listPlaylistsSuccess,
  listPlaylistsFail,
  deletePlaylist,
  deletePlaylistSuccess,
  deletePlaylistFail,
  updatePlaylist,
  updatePlaylistSuccess,
  updatePlaylistFail,
  createPlaylist,
  createPlaylistSuccess,
  createPlaylistFail,
  uploadFile,
  uploadFileSuccess,
  uploadFileFail,
  addPlaylistItems,
  addPlaylistItemsSuccess,
  addPlaylistItemSuccess,
  addPlaylistItemsFail,
  getPlaylist,
  getPlaylistSuccess,
  getPlaylistFail,
  deletePlaylistItem,
  deletePlaylistItemSuccess,
  deletePlaylistItemFail,
  hidePlaylistItem,
  hidePlaylistItemSuccess,
  hidePlaylistItemFail,
  duplicatePlaylistItem,
  duplicatePlaylistItemSuccess,
  duplicatePlaylistItemFail,
  schedulePlaylistItem,
  schedulePlaylistItemSuccess,
  schedulePlaylistItemFail,
  intervalPlaylistItem,
  intervalPlaylistItemSuccess,
  intervalPlaylistItemFail,
  addPlaylistItemCondition,
  addPlaylistItemConditionSuccess,
  addPlaylistItemConditionFail,
  removePlaylistItemInterval,
  removePlaylistItemIntervalSuccess,
  removePlaylistItemIntervalFail,
  checkUrlIsValid,
  copyPlaylist,
  copyPlaylistSuccess,
  copyPlaylistFail,
  updatePlaylistItems,
  updatePlaylistItemsSuccess,
  updatePlaylistItemsFail,
  updateInfopageItem,
  updateInfopageItemThumbnail,
  updateRevolverItem,
  updateRevolverItemFail,
  updateRevolverItemSuccess,
  updateTranscodedVideoItem,
  updateUrlItem,
  updateUrlItemFail,
  updateUrlItemSuccess,
  createEmptyInfopageToPlaylist,
  updateContentThumbnail,
  updateProductItemsTemplate,
  updateProductItemsTemplateFail,
  updateProductItemsTemplateSuccess,
  closePlaylistItemWizard,
  setPlaylistItemWizardPage
}
