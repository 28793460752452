import React from 'react'
import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import FormControl from '@mui/material/FormControl'
import { useTranslation } from 'react-i18next'
import { TextField, MenuItem } from '@mui/material'
import { Language } from '@seesignage/seesignage-utils'
import { useDispatch, useSelector } from 'react-redux'
import { changeLanguage } from '../../actions/users'
import { selectLanguage } from '../../selectors/users'

const useStyles = makeStyles((theme: Theme) => ({
  languageForm: {
    width: 180,
    margin: theme.spacing(2)
  }
}))

const ChangeLanguageForm = () => {
  const classes = useStyles()
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const language = useSelector(selectLanguage)
  return (
    <FormControl variant='standard' className={classes.languageForm}>
      <TextField
        label={t('language.language')}
        variant='outlined'
        onChange={event => dispatch(changeLanguage(event.target.value))}
        value={language}
        inputProps={{
          name: 'lang',
          id: 'lang'
        }}
        select>
        <MenuItem value={Language.en}>English</MenuItem>
        <MenuItem value={Language.fi}>Suomi</MenuItem>
      </TextField>
    </FormControl>
  )
}

export default ChangeLanguageForm
