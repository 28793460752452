import { path, clone } from 'ramda'
import { toast } from 'react-toastify'
import {
  SubmissionError,
  arrayRemove,
  arrayMove,
  arrayInsert,
  formValueSelector,
  arrayPush
} from 'redux-form'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import {
  Template,
  UpdateTemplate,
  LunchList,
  StylesChild,
  Components,
  MasterTemplateUI,
  ChildTemplate,
  isMasterTemplateUI,
  removeNullishValues,
  TemplateOrientation
} from '@seesignage/seesignage-utils'
import { closeDialog } from '../actions/dialogs'
import {
  copyTemplateToEnvironments,
  copyTemplateToEnvironmentsSuccess,
  deleteTemplate,
  deleteTemplateFail,
  deleteTemplateSuccess,
  duplicateTemplate,
  duplicateTemplateFail,
  duplicateTemplateSuccess,
  getTemplate,
  getTemplateFail,
  getTemplateSuccess,
  listTemplates,
  listTemplatesFail,
  listTemplatesSuccess,
  updateTemplate,
  updateTemplateContentSuccess,
  updateTemplateFail,
  updateTemplateKey,
  updateTemplateKeySuccess,
  updateTemplateSuccess,
  createTemplate,
  createTemplateSuccess,
  createTemplateFail,
  updateTemplateContent,
  updateTemplateContentFail,
  addTemplateChild,
  addTemplateChildSuccess,
  addTemplateChildFail,
  removeTemplateChild,
  removeTemplateChildSuccess,
  removeTemplateChildFail,
  reorderTemplateChild,
  reorderTemplateChildSuccess,
  reorderTemplateChildFail,
  duplicateTemplateChild,
  duplicateTemplateChildSuccess,
  duplicateTemplateChildFail,
  editSchemaComponent,
  editSchemaComponentSuccess,
  editSchemaComponentFail,
  selectTemplateOrientation,
  copyTemplateToEnvironmentsFail,
  createChildTemplateSuccess,
  createChildTemplate,
  createChildTemplateFail,
  deleteChildTemplateSuccess,
  deleteChildTemplateFail,
  deleteChildTemplate
} from '../actions/templates'
import {
  selectContentIdFromPathname,
  selectEnvironmentIdFromPathname,
  selectViewFromPathname
} from '../selectors/routing'
import {
  selectSelectedTemplateOrientation,
  selectTemplateById,
  selectSelectedTemplateChildIndex
} from '../selectors/templates'
import MediaApi from '../services/api/media'
import Api from '../services/api/templates'
import i18n from '../translations/i18n'
import {
  handleCopyTemplateToEnvironmentsParams,
  handleUpdateTemplateContentParams,
  handleUpdateTemplateParams,
  handleCreateTemplateParams,
  handleEditSchemaComponentParams
} from '../types/formData'
import { templateDeletionFail } from '../utils/message'
import {
  Action,
  AddTemplateChildParams,
  RemoveTemplateChildParams,
  ReorderTemplateChildParams
} from '../types/actions'
import { generateFieldArrayPrefix } from '../containers/Templates/Forms/Fields/utils'
import {
  addNewChildToSchemaComponents,
  duplicateSchemaChild,
  reorderSchemaChild,
  updateSchemaComponent,
  getPathToChild,
  removeChildElement
} from '../utils/templates'
import { UpdateTemplateContentParams, StateTemplate } from '../types/templates'
import { selectCurrentList } from '../selectors/lists'
import { KeyWithUrl } from '../types/media'
import { selectInfopageTemplate } from '../actions/infopages'

const getTemplateElement = (): Element | undefined => {
  const iframe = document.getElementById('templatePreview') as any
  const innerDoc = iframe?.contentDocument
    ? iframe.contentDocument
    : iframe?.contentWindow?.document
  return innerDoc ? innerDoc.getElementById('rootContainer') : undefined
}
function* handleListTemplates() {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    if (environmentId) {
      const templates = yield call(Api.listTemplates, environmentId)
      yield put(listTemplatesSuccess(templates))
    } else {
      yield put(listTemplatesFail('No environment selected'))
    }
  } catch (error) {
    yield put(listTemplatesFail(error.message))
  }
}

interface HandleGetTemplateParams {
  templateId?: string
  environmentId?: string
  /** Fetched template will be stored in infopages reducer for infopage use.
   * This allows getting template without it getting overriden by ListTemplates
   */
  selectForInfopage?: boolean
}

function* handleGetTemplate({
  payload: { templateId: payloadTemplateId, environmentId: payloadEnvironmentId, selectForInfopage }
}: Action<HandleGetTemplateParams>) {
  try {
    const pathEnvironmentId = yield select(selectEnvironmentIdFromPathname)
    const environmentId = payloadEnvironmentId || pathEnvironmentId
    const orientation: TemplateOrientation = yield select(selectSelectedTemplateOrientation)
    if (environmentId) {
      const pathTemplateId = yield select(selectContentIdFromPathname)
      let templateId = payloadTemplateId || pathTemplateId
      let templateEnvironmentId = environmentId

      // if currently in lunch lists view check if templateEnvironmentId is available and use templateId from lunch list
      const currentView = yield select(selectViewFromPathname)
      if (currentView === 'lunchLists') {
        const lunchList: LunchList = yield select(selectCurrentList)
        templateId = lunchList.templateId
        templateEnvironmentId = lunchList.templateEnvironmentId
      }

      const template = yield call(Api.getTemplate, pathEnvironmentId, templateId, {
        orientation,
        metadata: true, // get media metadata in backend
        templateEnvironmentId
      })
      // Select template for infopage
      if (selectForInfopage) {
        yield put(selectInfopageTemplate(template))
      }
      yield put(getTemplateSuccess(template))
    } else {
      yield put(getTemplateFail('No environment selected'))
    }
  } catch (error) {
    yield put(getTemplateFail(error.message))
  }
}

function* handleUpdateTemplateContent({
  payload: { formData, resolve, reject }
}: handleUpdateTemplateContentParams) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    let templateId: string = yield select(selectContentIdFromPathname)
    const pathTemplate: Template = yield select(selectTemplateById(templateId))
    const currentChildIndex = yield select(selectSelectedTemplateChildIndex)
    const template = isMasterTemplateUI(pathTemplate)
      ? pathTemplate.childTemplates[currentChildIndex]
      : pathTemplate
    if (isMasterTemplateUI(pathTemplate)) {
      templateId = pathTemplate.childTemplateIds[currentChildIndex]
    }
    const orientation: TemplateOrientation = yield select(selectSelectedTemplateOrientation)
    // clone formData if object is corrupted
    const styles = clone(formData)
    const orientationStyles: StylesChild = removeNullishValues(styles)
    const templateElement = getTemplateElement()
    if (templateElement) {
      // Update also other orientation's component styles as they might change in store. For example, when
      // removing schema components, then corresponding styles are removed also from other orientation's styles
      const components: Components =
        orientation === 'landscape'
          ? {
              landscape: orientationStyles,
              portrait: template.components.portrait
            }
          : {
              landscape: template.components.landscape,
              portrait: orientationStyles
            }

      const contentParams: UpdateTemplateContentParams = {
        components,
        schema: template.schema,
        html: templateElement?.outerHTML,
        orientation
      }
      const updatedTemplate: Template | ChildTemplate = yield call(
        Api.updateTemplateComponents,
        environmentId,
        templateId,
        contentParams
      )

      // keep keys because these are updated on backgroudImage field change and already contains urls
      updatedTemplate.keys = template.keys
      if (isMasterTemplateUI(pathTemplate)) {
        pathTemplate.childTemplates[currentChildIndex] = updatedTemplate as ChildTemplate
        yield put(updateTemplateContentSuccess(pathTemplate))
      }

      toast.success(i18n.t('templates.updateSuccess'))

      yield put(updateTemplateContentSuccess(updatedTemplate))
      resolve()
    } else {
      throw new Error('Template element not found')
    }
  } catch (error) {
    toast.error(i18n.t('templates.updateFail'))
    yield put(updateTemplateContentFail(error.message))
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('templates.updateFail')
      })
    )
  }
}

function* handleAddTemplateChild({
  payload: { childIndexes, type }
}: Action<AddTemplateChildParams>) {
  try {
    const templateId: string = yield select(selectContentIdFromPathname)
    const template: Template = yield select(selectTemplateById(templateId))
    if (isMasterTemplateUI(template)) {
      const currentChildIndex: number = yield select(selectSelectedTemplateChildIndex)
      const childTemplate = template.childTemplates[currentChildIndex]
      const childSchemaComponents = addNewChildToSchemaComponents({
        components: { ...childTemplate.schema.components },
        childIndexes,
        productIndex: currentChildIndex,
        type
      })
      template.childTemplates[currentChildIndex].schema.components = childSchemaComponents
    } else {
      const schemaComponents = addNewChildToSchemaComponents({
        components: { ...template.schema.components },
        childIndexes,
        type
      })
      template.schema.components = schemaComponents
    }

    yield put(addTemplateChildSuccess(template))
  } catch (error) {
    yield put(addTemplateChildFail(error.message))
  }
}

function* handleDuplicateTemplateChild({
  payload: { childIndexes, fieldArrayName, currentIndex }
}: Action<RemoveTemplateChildParams>) {
  try {
    const templateId: string = yield select(selectContentIdFromPathname)
    const template: Template = yield select(selectTemplateById(templateId))
    const childParentIndexes = [...childIndexes]
    childParentIndexes.pop()
    if (isMasterTemplateUI(template)) {
      const currentChildIndex = yield select(selectSelectedTemplateChildIndex)
      const childTemplate = template.childTemplates[currentChildIndex]
      const newChildTemplateSchemaComponents = duplicateSchemaChild(
        childTemplate.schema.components,
        childIndexes
      )
      childTemplate.schema.components = newChildTemplateSchemaComponents
      template.childTemplates[currentChildIndex] = childTemplate
    } else {
      const newSchemaComponents = duplicateSchemaChild(template.schema.components, childIndexes)

      template.schema.components = newSchemaComponents
    }
    const childValue = yield select(
      formValueSelector('TemplateForm'),
      `${fieldArrayName}[${currentIndex}]`
    )
    const destinationFieldArrayName = generateFieldArrayPrefix(childParentIndexes)
    yield put(arrayPush('TemplateForm', destinationFieldArrayName, childValue))

    yield put(duplicateTemplateChildSuccess(template))
  } catch (error) {
    yield put(duplicateTemplateChildFail(error.message))
  }
}

/**
 * Remove element from template schema and remove its redux form values
 */
function* handleRemoveTemplateChild({
  payload: { childIndexes, currentIndex, fieldArrayName }
}: Action<RemoveTemplateChildParams>) {
  try {
    const templateId: string = yield select(selectContentIdFromPathname)
    const template: StateTemplate = yield select(selectTemplateById(templateId))
    const currentOrientation: TemplateOrientation = yield select(selectSelectedTemplateOrientation)
    const childIndex = yield select(selectSelectedTemplateChildIndex)
    if (isMasterTemplateUI(template)) {
      const updatedChild = removeChildElement(
        template.childTemplates[childIndex],
        childIndexes,
        currentOrientation
      ) as ChildTemplate
      template.childTemplates[childIndex] = updatedChild
      yield put(removeTemplateChildSuccess(template))
    } else {
      const updatedTemplate = removeChildElement(
        isMasterTemplateUI(template) ? template.childTemplates[childIndex] : template,
        childIndexes,
        currentOrientation
      )
      yield put(removeTemplateChildSuccess(updatedTemplate))
    }
    // remove component child from current form styles
    yield put(arrayRemove('TemplateForm', fieldArrayName, currentIndex))
  } catch (error) {
    yield put(removeTemplateChildFail(error.message))
  }
}

function* handleReorderTemplateChild({
  payload: { sourceId, sourceIndex, destinationId, destinationIndex }
}: Action<ReorderTemplateChildParams>) {
  try {
    const sourceChildParentIndexes =
      sourceId === 'none' ? [] : sourceId.split(',').map(n => Number(n))
    const destinationChildParentIndexes =
      destinationId === 'none' ? [] : destinationId.split(',').map(n => Number(n))

    const templateId: string = yield select(selectContentIdFromPathname)
    const pathTemplate: Template = yield select(selectTemplateById(templateId))
    const currentChildIndex = yield select(selectSelectedTemplateChildIndex)

    const template = isMasterTemplateUI(pathTemplate)
      ? pathTemplate.childTemplates[currentChildIndex]
      : pathTemplate

    const reorderSchemaChildProps = {
      components: template.schema.components,
      sourceChildParentIndexes,
      destinationChildParentIndexes,
      sourceIndex,
      destinationIndex
    }
    const reorderedSchemaComponents = reorderSchemaChild(reorderSchemaChildProps)
    template.schema.components = reorderedSchemaComponents

    // TODO: currently removing other orientations styles does not work. Fix this in the future.
    // reorder also other template orientations components styles in state.
    // const orientation: TemplateOrientation = yield select(selectSelectedTemplateOrientation)
    // const otherOrientation =
    //   orientation === TemplateOrientation.landscape
    //     ? TemplateOrientation.portrait
    //     : TemplateOrientation.landscape
    // const reorderedStylesChild = reorderStylesChild({
    //   template,
    //   orientation: otherOrientation,
    //   sourceChildParentIndexes,
    //   destinationChildParentIndexes,
    //   sourceIndex,
    //   destinationIndex
    // })
    // template.components[otherOrientation] = reorderedStylesChild

    // Keep redux form fields and values (styles) updated
    const sourceFieldArrayName = generateFieldArrayPrefix(sourceChildParentIndexes)
    // reorder items in same field array
    if (sourceId === destinationId) {
      yield put(arrayMove('TemplateForm', sourceFieldArrayName, sourceIndex, destinationIndex))
    } else {
      const sourceChildFieldArrayName = `${sourceFieldArrayName}[${sourceIndex}]`
      const childValue = yield select(formValueSelector('TemplateForm'), sourceChildFieldArrayName)
      // From higher level to lower level
      if (destinationChildParentIndexes.length < sourceChildParentIndexes.length) {
        yield put(arrayRemove('TemplateForm', sourceFieldArrayName, sourceIndex))
        const destinationFieldArrayName = generateFieldArrayPrefix(destinationChildParentIndexes)
        yield put(
          arrayInsert('TemplateForm', destinationFieldArrayName, destinationIndex, childValue)
        )
      } else {
        // From lower level to higher level
        const destinationFieldArrayName = generateFieldArrayPrefix(destinationChildParentIndexes)
        yield put(
          arrayInsert('TemplateForm', destinationFieldArrayName, destinationIndex, childValue)
        )
        yield put(arrayRemove('TemplateForm', sourceFieldArrayName, sourceIndex))
      }
    }

    if (isMasterTemplateUI(pathTemplate)) {
      pathTemplate.childTemplates[currentChildIndex] = template as ChildTemplate
      // update master template and it's child template's schema in store
      yield put(reorderTemplateChildSuccess(pathTemplate))
    } else {
      // update template schema in store
      yield put(reorderTemplateChildSuccess(template))
    }
  } catch (error) {
    yield put(reorderTemplateChildFail(error.message))
  }
}

function* handleCreateTemplate({
  payload: { formData, resolve, reject }
}: handleCreateTemplateParams) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const template: Template = yield call(Api.createTemplate, environmentId, formData)
    yield put(createTemplateSuccess(template))
    yield put(closeDialog())
    resolve()
  } catch (error) {
    toast.error(i18n.t('error.template.createFail'))
    yield put(createTemplateFail(error.message))
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.template.createFail')
      })
    )
  }
}

function* handleUpdateTemplate({
  payload: { formData, resolve, reject }
}: handleUpdateTemplateParams) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const { name, maxItems, templateId, duration } = formData
    const updateTemplateProps: UpdateTemplate = {
      name,
      maxItems,
      duration
    }
    const template: Template = yield call(
      Api.updateTemplate,
      environmentId,
      templateId,
      updateTemplateProps
    )
    yield put(updateTemplateSuccess(template))
    yield put(closeDialog())
    resolve()
  } catch (error) {
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.template.updateFail')
      })
    )
  }
}

function* handleEditSchemaComponent({
  payload: { formData, resolve, reject }
}: handleEditSchemaComponentParams) {
  try {
    const {
      id,
      name,
      childIndexes,
      allow,
      conditions,
      customValue,
      formatOptions,
      specialDietType,
      groupIndex,
      fieldArrayName,
      currentIndex,
      editorBackgroundColor
    } = formData
    const schemaComponent = {
      id,
      name,
      childIndexes,
      allow,
      conditions: conditions && conditions.condition ? conditions : undefined,
      customValue,
      formatOptions,
      specialDietType,
      groupIndex,
      editorBackgroundColor
    }
    if (
      schemaComponent.conditions &&
      (!schemaComponent.conditions.rules || schemaComponent.conditions.rules.length === 0)
    ) {
      throw new SubmissionError({
        _error: 'Conditions must contain atleast one rule!'
      })
    }
    const templateId: string = yield select(selectContentIdFromPathname)
    const pathTemplate: Template = yield select(selectTemplateById(templateId))
    const currentChildIndex = yield select(selectSelectedTemplateChildIndex)
    const template = isMasterTemplateUI(pathTemplate)
      ? pathTemplate.childTemplates[currentChildIndex]
      : pathTemplate
    if (specialDietType) {
      const {
        schema: { components }
      } = template
      const pathToChild = getPathToChild(childIndexes)
      const oldSpecialDiet: any = path(pathToChild, components)
      // Remove styles if specialDietType changed
      if (oldSpecialDiet.specialDietType !== specialDietType && fieldArrayName && currentIndex) {
        yield put(arrayRemove('TemplateForm', fieldArrayName, currentIndex))
      }
    }

    const schemaComponents = updateSchemaComponent(template.schema.components, schemaComponent)
    template.schema.components = schemaComponents
    if (isMasterTemplateUI(pathTemplate)) {
      pathTemplate.childTemplates[currentChildIndex] = template as ChildTemplate
      yield put(editSchemaComponentSuccess(pathTemplate))
    } else {
      yield put(editSchemaComponentSuccess(template))
    }

    yield put(closeDialog())
    resolve()
  } catch (error) {
    if (error instanceof SubmissionError) {
      yield call(reject, error)
    }
    yield put(editSchemaComponentFail(error.message))
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.templates.editFail')
      })
    )
  }
}

function* getKeyUrl(key: string, orientation: TemplateOrientation) {
  if (key.startsWith('common/')) {
    const commonFileMetadata = yield call(MediaApi.getCommonFileMetadata, key, orientation)
    return commonFileMetadata.url
  } else if (key.startsWith('environments/')) {
    const { url }: KeyWithUrl = yield call(MediaApi.getMediaUrl, key)
    return url
  }
  return undefined
}

interface HanldeUpdatetemplateKeyParams {
  payload: string
}

function* handleUpdateTemplateKey({ payload: key }: HanldeUpdatetemplateKeyParams) {
  try {
    const templateId: string = yield select(selectContentIdFromPathname)
    const orientation = yield select(selectSelectedTemplateOrientation)
    const url: string = yield call(getKeyUrl, key, orientation)
    yield put(updateTemplateKeySuccess({ templateId, key, url }))
  } catch (error) {
    yield put(updateTemplateFail(error.message))
  }
}

function* handleCopyToEnvironments({
  payload: { formData, resolve, reject }
}: handleCopyTemplateToEnvironmentsParams) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const { templateId, environments } = formData
    const environmentIds = environments.map(({ value }) => value)
    yield call(Api.copyToEnvironments, environmentId, templateId, environmentIds)
    toast.success(i18n.t('templates.copyToEnvironmentsSuccess'))
    yield put(copyTemplateToEnvironmentsSuccess())
    yield put(closeDialog())
    resolve()
  } catch (error) {
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.template.somethingWrongCopyToEnvironments')
      })
    )
    yield put(copyTemplateToEnvironmentsFail())
  }
}

interface HandleDeleteTemplateParams {
  payload: string
}

function* handleDeleteTemplate({ payload: templateId }: HandleDeleteTemplateParams) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    yield call(Api.removeTemplate, environmentId, templateId)
    yield put(deleteTemplateSuccess(templateId))
    yield put(closeDialog())
  } catch (error) {
    const lists: string[] | undefined = path(['response', 'data', 'lists'], error)
    const playlists: string[] | undefined = path(['response', 'data', 'playlists'], error)
    const infopages: string[] | undefined = path(['response', 'data', 'infopages'], error)

    const errorMessage = templateDeletionFail({ lists, playlists, infopages })
    if (errorMessage) {
      toast.error(errorMessage)
    }
    yield put(deleteTemplateFail())
  }
}

interface HandleDuplicateTemplateParams {
  payload: string
}

function* handleDuplicateTemplate({ payload: templateId }: HandleDuplicateTemplateParams) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const copy = yield call(Api.duplicateTemplate, environmentId, templateId)
    yield put(duplicateTemplateSuccess(copy))
  } catch (error) {
    toast.error(i18n.t('error.templates.duplicateFailed'))
    yield put(duplicateTemplateFail())
  }
}

function* handleSelectTemplateOrientation() {
  yield put(getTemplate({}))
}

function* handleCreateChildTemplate() {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const templateId = yield select(selectContentIdFromPathname)
    const orientation = yield select(selectSelectedTemplateOrientation)
    const masterTemplate: MasterTemplateUI | undefined = yield select(
      selectTemplateById(templateId)
    )
    if (masterTemplate) {
      const {
        masterTemplate: updatedMasterTemplate,
        newChildTemplate
      }: {
        masterTemplate: MasterTemplateUI
        newChildTemplate: ChildTemplate
      } = yield call(Api.createNewChildTemplate, environmentId, templateId, orientation)
      const childTemplates = [...masterTemplate.childTemplates, newChildTemplate]
      yield put(
        createChildTemplateSuccess({ masterTemplate: updatedMasterTemplate, childTemplates })
      )
    }
  } catch (error) {
    yield put(createChildTemplateFail(error.message))
  }
}

function* handleDeleteChildTemplate() {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const templateId = yield select(selectContentIdFromPathname)
    const masterTemplate: MasterTemplateUI | undefined = yield select(
      selectTemplateById(templateId)
    )
    const childIndex = yield select(selectSelectedTemplateChildIndex)

    if (masterTemplate) {
      const { name, childTemplateIds, childTemplates } = masterTemplate
      const childToBeRemoved = childTemplateIds[childIndex]
      yield call(Api.removeTemplate, environmentId, childToBeRemoved)
      childTemplateIds.splice(childIndex, 1)
      childTemplates.splice(childIndex, 1)
      const updatedMasterTemplate: MasterTemplateUI = yield call(
        Api.updateTemplate,
        environmentId,
        templateId,
        { name, childTemplateIds }
      )
      yield put(closeDialog())
      yield put(
        deleteChildTemplateSuccess({ masterTemplate: updatedMasterTemplate, childTemplates })
      )
    }
  } catch (error) {
    yield put(deleteChildTemplateFail(error.message))
  }
}

function* watchTemplatesActions() {
  yield all([
    takeLatest(listTemplates, handleListTemplates),
    takeLatest(getTemplate, handleGetTemplate),
    takeLatest(createTemplate, handleCreateTemplate),
    takeLatest(editSchemaComponent, handleEditSchemaComponent),
    takeLatest(updateTemplateContent, handleUpdateTemplateContent),
    takeLatest(addTemplateChild, handleAddTemplateChild),
    takeLatest(removeTemplateChild, handleRemoveTemplateChild),
    takeLatest(duplicateTemplateChild, handleDuplicateTemplateChild),
    takeLatest(reorderTemplateChild, handleReorderTemplateChild),
    takeLatest(updateTemplateKey, handleUpdateTemplateKey),
    takeLatest(copyTemplateToEnvironments, handleCopyToEnvironments),
    takeLatest(deleteTemplate, handleDeleteTemplate),
    takeLatest(updateTemplate, handleUpdateTemplate),
    takeLatest(duplicateTemplate, handleDuplicateTemplate),
    takeLatest(selectTemplateOrientation, handleSelectTemplateOrientation),
    takeLatest(createChildTemplate, handleCreateChildTemplate),
    takeLatest(deleteChildTemplate, handleDeleteChildTemplate)
  ])
}

export default [watchTemplatesActions]
