import { API } from 'aws-amplify'
import { UserAttributes } from '../../types/auth'
import { Feedback, SendFeedbackRequest } from '../../types/feedback'

const sendFeedback = async (
  { name, email, phone_number: phoneNumber }: UserAttributes,
  feedback: Feedback
) => {
  const body: SendFeedbackRequest = {
    user: {
      name,
      email,
      phoneNumber
    },
    feedback
  }
  const response = await API.post('feedback', '/feedback', { body })
  return response
}

export default {
  sendFeedback
}
