import React, { Fragment, ReactNode } from 'react'
import { Tooltip, IconButton, Popover } from '@mui/material'

interface PopoverProps {
  buttonTooltip: string
  ButtonIcon: any
  children: ReactNode
}

const CustomPopover: React.FC<PopoverProps> = ({ children, buttonTooltip, ButtonIcon }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const handleClickMoreOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  return (
    <Fragment>
      <Tooltip disableInteractive title={buttonTooltip}>
        <IconButton aria-describedby={id} onClick={handleClickMoreOptions} size='large'>
          <ButtonIcon />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null)
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        {children}
      </Popover>
    </Fragment>
  )
}

export default CustomPopover
