import { Grid, InputAdornment, MenuItem } from '@mui/material'
import React, { Fragment } from 'react'
import { FieldArray, Field, WrappedFieldArrayProps } from 'redux-form'
import { useTranslation } from 'react-i18next'
import {
  formatDecimalsToCurrency,
  parseCurrencyStringToNumber,
  parseStringToNumber,
  PackageUnit
} from '@seesignage/seesignage-utils'
import { TFunction } from 'i18next'
import { MockProductType } from '../../../../types/mocks'
import { ReduxTextField, ReduxSwitch } from '../../../../components/FormInput/ReduxWrappers'
import { gridItemProps } from '../Fields/utils'
import { required, isPriceValid } from '../../../../validation'
import { parseNumberField } from '../../../../utils/forms'

const productType = MockProductType.generalRetail
const packageUnits = ['', ...Object.values(PackageUnit)]

interface RenderItemsProps extends WrappedFieldArrayProps {
  t: TFunction
}

const renderItems = ({ t, fields, meta: { error, submitFailed } }: RenderItemsProps) => {
  return (
    <Fragment>
      {submitFailed && error && <span>{error}</span>}
      {fields.map((member: string, index: number) => (
        <Grid container key={`${index}-${member}`} spacing={2}>
          <Grid item xs={12}>
            <Field
              fullWidth
              name={`${member}.name`}
              label={`${t('general.name')}*`}
              component={ReduxTextField}
              required
              validate={[required]}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              multiline
              maxRows='4'
              name={`${member}.description`}
              label={t('product.description')}
              component={ReduxTextField}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={4}>
                <Field
                  fullWidth
                  name={`${member}.price`}
                  label={t('product.sellingPrice')}
                  component={ReduxTextField}
                  validate={[isPriceValid]}
                  type='number'
                  InputProps={{
                    inputProps: { min: 0, max: 100000, step: 0.01 },
                    endAdornment: <InputAdornment position='end'>€</InputAdornment>
                  }}
                  format={formatDecimalsToCurrency}
                  parse={parseCurrencyStringToNumber}
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <Field
                  fullWidth
                  name={`${member}.unit`}
                  label={t('product.sellingUnit')}
                  component={ReduxTextField}
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <Field
                  fullWidth
                  name={`${member}.deposit`}
                  label={t('product.deposit')}
                  helperText={t('product.depositHelper')}
                  component={ReduxTextField}
                  validate={[isPriceValid]}
                  type='number'
                  InputProps={{
                    inputProps: { min: 0, max: 100000, step: 0.01 },
                    endAdornment: <InputAdornment position='end'>€</InputAdornment>
                  }}
                  format={formatDecimalsToCurrency}
                  parse={parseCurrencyStringToNumber}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Field
              fullWidth
              name={`${member}.packageSize`}
              label={t('product.packageSize')}
              component={ReduxTextField}
              type='number'
              InputProps={{
                inputProps: { min: 0, max: 100000, step: 0.001 }
              }}
              parse={parseStringToNumber}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <Field
              fullWidth
              name={`${member}.packageUnit`}
              label={t('product.packageUnit')}
              component={ReduxTextField}
              select>
              {packageUnits.map(value => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Field>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Field
              fullWidth
              name={`${member}.comparisonUnit`}
              label={t('product.comparisonUnit')}
              component={ReduxTextField}
              helperText={t('product.comparisonUnitHelper')}
              select>
              {packageUnits.map(value => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Field>
          </Grid>
          <Grid item xs={12}>
            <Field
              name={`${member}.discount`}
              component={ReduxSwitch}
              label={t('product.discount')}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              name={`${member}.discountPrice`}
              label={t('product.discountPrice')}
              component={ReduxTextField}
              required
              validate={[isPriceValid, required]}
              type='number'
              InputProps={{
                inputProps: { min: 0, max: 100000, step: 0.01 },
                endAdornment: <InputAdornment position='end'>€</InputAdornment>
              }}
              format={formatDecimalsToCurrency}
              parse={parseCurrencyStringToNumber}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              name={`${member}.discountBatchSize`}
              label={t('product.discountBatch')}
              component={ReduxTextField}
              type='number'
              parse={parseNumberField}
              InputProps={{
                inputProps: { min: 0, max: 100000, step: 1 }
              }}
              helperText={t('product.discountBatchHelper')}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name={`${member}.discountValidString`}
              label={t('product.discountValid')}
              component={ReduxTextField}
              helperText={t('product.discountValidHelper')}
            />
          </Grid>
          <Grid {...gridItemProps}>
            <Field
              fullWidth
              name={`${member}.imagePath`}
              label='imagePath'
              component={ReduxTextField}
            />
          </Grid>
        </Grid>
      ))}
    </Fragment>
  )
}

const GeneralRetailFormFields = () => {
  const [t] = useTranslation()
  return (
    <Grid container spacing={2} alignItems='center'>
      <FieldArray name={productType} component={renderItems} props={{ t }} />
    </Grid>
  )
}

export default GeneralRetailFormFields
