import { AppBar, Toolbar, Select, MenuItem } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { FeaturePermission, FeaturePermissionLevel } from '@seesignage/seesignage-utils'
import { toolbarStyles } from '../../../styles/common'
import Dialog from '../../Dialog'
import { CloseDialog, CreateTemplate } from '../../../types/actions'
import { bindSubmitActionToPromise } from '../../../utils/forms'
import { createTemplate } from '../../../actions/templates'
import { closeDialog } from '../../../actions/dialogs'
import CreateTemplateForm from '../Forms/CreateTemplateForm'
import { UserInterface } from '../../../types/users'
import { validateUserAccess } from '../../../utils/permissions'
import SearchField from '../../../components/SearchField'
import { OpenDialogButtonType } from '../../../types/dialogs'

const useStyles = makeStyles(theme => ({
  ...toolbarStyles(theme),
  selectTypeInputField: {
    padding: '9px 32px 9px 9px'
  }
}))

interface TemplatesToolbarProps {
  createTemplate: CreateTemplate
  closeDialog: CloseDialog
  searchTemplates: React.Dispatch<React.SetStateAction<string>>
  selectType: React.Dispatch<React.SetStateAction<string>>
  setPage: React.Dispatch<React.SetStateAction<number>>
  type: string
  templatesTypes: string[]
  user: UserInterface
}

const TemplatesToolbar = ({
  createTemplate,
  closeDialog,
  searchTemplates,
  selectType,
  setPage,
  type,
  templatesTypes,
  user
}: TemplatesToolbarProps) => {
  const classes = useStyles()
  const [t] = useTranslation()
  return (
    <AppBar className={classes.appBar} position='static' color='default'>
      <Toolbar>
        <SearchField
          onChange={e => {
            setPage(0)
            searchTemplates(e.target.value)
          }}
          placeholder={t('general.search')}
        />
        <Select
          style={{ marginLeft: 10 }}
          classes={{ outlined: classes.selectTypeInputField }}
          variant='outlined'
          value={type}
          onChange={e => {
            setPage(0)
            selectType(e.target.value as string)
          }}>
          {templatesTypes.map(type => (
            <MenuItem dense key={type} value={type}>
              {t(`templates.search.${type}`)}
            </MenuItem>
          ))}
        </Select>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          {validateUserAccess(user, FeaturePermission.templates, FeaturePermissionLevel.write) && (
            <Fragment>
              <Dialog
                key='createTemplate'
                tooltipTitle={t('templates.create')}
                openDialogButtonType={OpenDialogButtonType.add}
                dialogId='createTemplateDialog'
                title={`${t('templates.create')}`}
                Content={
                  <CreateTemplateForm
                    dialogId='createTemplateDialog'
                    submitButtonLabel={t('general.create')}
                    onSubmit={createTemplate}
                    closeDialog={closeDialog}
                  />
                }
              />
            </Fragment>
          )}
        </div>
      </Toolbar>
    </AppBar>
  )
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
  createTemplate: bindSubmitActionToPromise(dispatch, createTemplate),
  closeDialog: (dialogId: string) => dispatch(closeDialog(dialogId))
})

export default connect(null, mapDispatchToProps)(TemplatesToolbar)
