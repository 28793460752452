import React from 'react'
import { ScreenResponse } from '@seesignage/seesignage-utils'
import { Paper, Typography } from '@mui/material'
import { WithStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import withStyles from '@mui/styles/withStyles'
import { useTranslation } from 'react-i18next'
import StatusesScatterChart from '../../../Connections/StatusesScatterChart'

const styles = (theme: Theme) => ({
  container: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(2)
  },
  title: {
    paddingTop: theme.spacing(),
    paddingLeft: 12
  },
  screen: {
    marginBottom: 16
  }
})

interface ConnectionInformationProps extends WithStyles<typeof styles> {
  screen: ScreenResponse
}

const ConnectionInformation: React.FC<ConnectionInformationProps> = ({
  screen: { statuses },
  classes
}) => {
  const [t] = useTranslation()
  return (
    <Paper className={classes.screen} elevation={1}>
      <Typography className={classes.title} variant='h6'>
        {t('screens.connection')}
      </Typography>
      {statuses.length > 0 ? (
        <StatusesScatterChart statuses={statuses} />
      ) : (
        <Typography variant='body1' gutterBottom>
          {t('connection.noAvailableData')}
        </Typography>
      )}
    </Paper>
  )
}

export default withStyles(styles)(ConnectionInformation)
