import React from 'react'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import LinearProgress from '@mui/material/LinearProgress'

import { useTranslation } from 'react-i18next'
import { required, email } from '../../../validation'
import ErrorMessage from '../../../components/Errors/ErrorMessage'
import { ReduxTextField } from '../../../components/FormInput/ReduxWrappers'
import { ForgotPasswordFormData } from '../../../types/users'

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginTop: theme.spacing(2)
  }
}))

type FormProps = ForgotPasswordFormData

interface OwnProps {
  forgotPassword: (formData: any) => void
}

type ForgotPasswordFormProps = OwnProps

const ForgotPasswordForm = ({
  handleSubmit,
  submitting,
  forgotPassword,
  error
}: ForgotPasswordFormProps & InjectedFormProps<FormProps, OwnProps>) => {
  const classes = useStyles()
  const [t] = useTranslation()
  return (
    <form onSubmit={handleSubmit(forgotPassword)}>
      <Field
        fullWidth
        variant='outlined'
        name='email'
        label={t('signup.email')}
        component={ReduxTextField}
        required
        validate={[required, email]}
      />
      {error && <ErrorMessage message={error} />}
      <Button
        fullWidth
        type='submit'
        color='primary'
        variant='contained'
        className={classes.button}
        disabled={submitting}>
        {t('general.submit')}
      </Button>
      {submitting && <LinearProgress />}
    </form>
  )
}

export default reduxForm<FormProps, OwnProps>({
  form: 'ForgotPasswordForm'
})(ForgotPasswordForm)
