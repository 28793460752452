import { createSelector } from 'reselect'
import filter from 'lodash.filter'
import { AutocompleteOption, ScreenStatus } from '@seesignage/seesignage-utils'
import { StateInterface } from '../types/states'
import { compareStrings } from '../utils/sorting'

const selectScreensDomain = (state: StateInterface) => state.screens

const selectListScreensIsLoading = createSelector(
  selectScreensDomain,
  domain => domain.listScreensIsLoading
)

const selectListScreensDashboardIsLoading = createSelector(
  selectScreensDomain,
  domain => domain.listScreensDashboardIsLoading
)

const selectScreens = createSelector(selectScreensDomain, screens => screens.screens)
const selectScreensWithWarnings = createSelector(
  selectScreensDomain,
  screens => screens.screensWithWarnings
)
const selectScreensAsArray = createSelector(selectScreens, screens => Object.values(screens))
const selectScreensWithWarningsAsArray = createSelector(selectScreensWithWarnings, screens =>
  Object.values(screens)
)
const selectScreenById = (screenId?: string | null) =>
  createSelector(selectScreens, screens => (screenId ? screens[screenId] : undefined))

const selectScreenByIdDashboard = (screenId?: string) =>
  createSelector(selectScreensWithWarnings, screens => (screenId ? screens[screenId] : undefined))

const selectSelectedScreenIds = createSelector(
  selectScreensDomain,
  screens => screens.selectedScreenIds
)
const selectSelectedScreenIdDashboard = createSelector(
  selectScreensDomain,
  screens => screens.selectedScreenIdDashboard
)

/**
 * Returns selected Screen if only one is selected
 */
const selectSelectedScreen = createSelector(
  selectScreens,
  selectSelectedScreenIds,
  (screens, selectedIds) => (selectedIds.length === 1 ? screens[selectedIds[0]] : undefined)
)

const selectScreensDefaultLocations = createSelector(selectScreensAsArray, screens =>
  screens.reduce((locations: AutocompleteOption[], { location }) => {
    if (location && !locations.some(({ value }) => value === location.id)) {
      locations.push({ value: location.id, label: location.label })
    }
    return locations
  }, [])
)

const selectScreensTagsArray = createSelector(selectScreensAsArray, screens => {
  const arr = screens
    .reduce((tagsArray: string[][], { tags }) => {
      if (tags) {
        tagsArray.push(tags)
      }
      return tagsArray
    }, [])
    .flat(1)
  return [...new Set(arr)]
})

const selectScreensTagsAsOptions = createSelector(selectScreensTagsArray, tags =>
  tags.map(tag => ({
    value: tag,
    label: tag
  }))
)

const selectScreensByTags = (selectedTags?: string[]) =>
  createSelector(selectScreensAsArray, screens =>
    selectedTags && selectedTags.length > 0 ? filter(screens, { tags: selectedTags }) : screens
  )

const selectMediaScreensByTags = (selectedTags?: string[]) =>
  createSelector(selectScreensAsArray, screens =>
    selectedTags && selectedTags.length > 0
      ? filter(screens, { tags: selectedTags, type: 'media' })
      : []
  )

const selectScreensViewMode = createSelector(
  selectScreensDomain,
  domain => domain.viewMode.isListMode
)

const selectIsAdminScreensCsvLoading = createSelector(
  selectScreensDomain,
  screens => screens.adminScreensCsvLoading
)

const screenStatusMatch = (screenStatus: ScreenStatus, selectedStatus: string | null) =>
  !selectedStatus ? true : screenStatus === selectedStatus

const selectScreensBySearch = (searchTerm: string, selectedTags: string[], status: string | null) =>
  createSelector(selectScreensAsArray, screens => {
    screens.sort(compareStrings('name'))
    if (searchTerm.length) {
      return screens.filter(
        ({ name, code, identifier, serialNumber, tags, status: screenStatus }) => {
          const tagsMatch = selectedTags.length
            ? selectedTags.every(tag => tags?.includes(tag))
            : true
          // match search term by name, code, serialNumber or tags
          const searchTermMatch =
            name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
            (identifier &&
              identifier.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) ||
            code.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
            (serialNumber &&
              serialNumber.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) ||
            tags?.find(tag => tag.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))

          return searchTermMatch && tagsMatch && screenStatusMatch(screenStatus, status)
        }
      )
    } else if (selectedTags.length) {
      return screens.filter(({ tags, status: screenStatus }) => {
        const tagsMatch = selectedTags.every(tag => tags?.includes(tag))
        return tagsMatch && screenStatusMatch(screenStatus, status)
      })
    } else if (status?.length) {
      return screens.filter(({ status: screenStatus }) => screenStatusMatch(screenStatus, status))
    }
    return screens
  })

/**
 * Select count of Billable screens found in store
 */
const selectBillableScreensCount = createSelector(
  selectScreensAsArray,
  screens => screens.filter(({ billable }) => billable).length
)

const selectIsScreensInitialLoad = createSelector(
  selectScreensDomain,
  domain => domain.isInitialLoad
)

export {
  selectScreens,
  selectListScreensIsLoading,
  selectScreensAsArray,
  selectScreensWithWarningsAsArray,
  selectScreenById,
  selectSelectedScreen,
  selectSelectedScreenIds,
  selectSelectedScreenIdDashboard,
  selectScreensDefaultLocations,
  selectScreensViewMode,
  selectScreensTagsAsOptions,
  selectScreensByTags,
  selectMediaScreensByTags,
  selectIsAdminScreensCsvLoading,
  selectScreensBySearch,
  selectScreensTagsArray,
  selectBillableScreensCount,
  selectScreenByIdDashboard,
  selectListScreensDashboardIsLoading,
  selectIsScreensInitialLoad
}
