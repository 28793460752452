import { Grid, Tooltip, Typography } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import InfopagesIcon from 'mdi-react/BookInformationVariantIcon'
import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import Truncate from 'react-truncate'
import { InfopagePlaylistItemUI } from '@seesignage/seesignage-utils'
import { PlaylistItemCardRootProps } from '../../../../../types/playlists'
import ItemIcons from './ItemIcons'
import SelectedIndicator from './SelectedIndicator'

const styles = () => ({
  item: {
    height: 140,
    overflow: 'hidden'
  },
  itemTitleText: {
    width: '100%'
  },
  mediaBox: {
    backgroundColor: 'white',
    display: 'flex',
    height: '100%',
    width: '100%'
  },
  media: {
    width: '100%',
    height: '100%',
    objectFit: 'contain' as any
  },
  itemDescriptionArea: {
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)'
  },
  icon: {
    margin: 'auto'
  }
})

interface OwnProps extends PlaylistItemCardRootProps {
  item: InfopagePlaylistItemUI
  isMobile: boolean
}

type InfopageAsItemProps = OwnProps & WithTranslation & WithStyles<typeof styles>

const InfopageAsItem: React.FC<InfopageAsItemProps> = ({
  classes,
  item,
  isItemSelected,
  isMobile,
  showPercentage,
  itemPercentage,
  t
}) => {
  const { infopage, template } = item
  const thumbnailUrl = infopage?.thumbnailUrl
  return (
    <Grid container alignContent='center' className={classes.item}>
      <Grid item xs={4} className={classes.mediaBox}>
        {thumbnailUrl ? (
          <img className={classes.media} src={thumbnailUrl} alt='' />
        ) : (
          <InfopagesIcon className={classes.icon} size={'70px'} color='#1e88e5' />
        )}
      </Grid>
      <Grid item xs={8} className={classes.itemDescriptionArea}>
        <Grid container style={{ height: '100%' }}>
          <Grid item xs={12}>
            <Grid container className={classes.itemTitleText}>
              <Grid item xs={2} sm={1}>
                <InfopagesIcon />
              </Grid>
              <Grid item xs={9} sm={10}>
                <Tooltip disableInteractive title={infopage?.name || template?.name || ''}>
                  <Typography align='left' variant='subtitle1' gutterBottom>
                    <Truncate lines={1} width={isMobile ? 180 : 300}>
                      {infopage?.name || template?.name || ''}
                    </Truncate>
                  </Typography>
                </Tooltip>
              </Grid>
              <SelectedIndicator
                isItemSelected={isItemSelected}
                showPercentage={showPercentage}
                itemPercentage={itemPercentage}
              />
            </Grid>
          </Grid>
          {template && (
            <Typography variant='caption' color='error'>
              {t('infopages.oldInfopage')}
            </Typography>
          )}
        </Grid>
        <ItemIcons item={item} />
      </Grid>
    </Grid>
  )
}
export default withStyles(styles)(withTranslation()(InfopageAsItem))
