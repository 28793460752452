import { RGBColor } from 'react-color'
import { Location } from '@seesignage/seesignage-utils'
import i18n from '../translations/i18n'

const convertBytesToGigabytes = (bytes: number) => bytes * Math.pow(10, -9)

const convertAutocompleteFormFieldValue = (value?: string | number | null) => {
  if (value) {
    return {
      value
    }
  }
  return undefined
}

const convertLocationForFormField = (location?: Location | null) => {
  if (location) {
    const { id, label } = location
    return {
      value: id,
      label
    }
  }
  return undefined
}

const convertTagsForFormField = (tags?: string[] | null) =>
  tags
    ? tags.map(tag => ({
        value: tag,
        label: tag
      }))
    : undefined

const convertRgbObjectToString = (rgb: RGBColor) => {
  const { r, g, b, a } = rgb
  return `rgba(${r},${g},${b},${a})`
}

const convertColorStringToObject = (color?: string): string | RGBColor | undefined => {
  if (!color) {
    return undefined
  }
  if (color.startsWith('#')) {
    return color
  } else {
    const rgbArray = color
      .substring(5, color.length - 1)
      .replace(/ /g, '')
      .split(',')
    return {
      r: Number(rgbArray[0]),
      g: Number(rgbArray[1]),
      b: Number(rgbArray[2]),
      a: Number(rgbArray[3])
    }
  }
}

const convertSpecialDietToString = (diet: any) => {
  const diets = Object.keys(diet).reduce((dietArray, key) => {
    if (diet[key]) {
      dietArray.push(i18n.t(`lists.lunch.dietAbbreviations.${key}`))
    }
    return dietArray
  }, [])

  return diets.join(',')
}

const convertCentsToEuros = (price?: number) => (price ? price / 100 : undefined)

const joinArraysUnique = (arr1: any, arr2: any) => {
  const joined = [...arr1, ...arr2]
  return [...new Set(joined)]
}

/**
 * Convert bytes into either KB or MB.
 * If bytes are less than 1024 KB, return in KB. If bytes are larger than 1024 MB return in MB
 * @param bytes
 */
const convertBytesToString = (bytes: number) => {
  const kb = bytes / 1024
  return kb < 1024 ? `${kb.toFixed(2)} KB` : `${(kb / 1024).toFixed(2)} MB`
}

const hexToRgbA = (hex: string) => {
  let c: any
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)'
  }
  throw new Error('Bad Hex')
}

export {
  convertBytesToGigabytes,
  convertLocationForFormField,
  convertTagsForFormField,
  convertRgbObjectToString,
  convertSpecialDietToString,
  convertCentsToEuros,
  joinArraysUnique,
  convertAutocompleteFormFieldValue,
  convertColorStringToObject,
  convertBytesToString,
  hexToRgbA
}
