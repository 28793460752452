import React from 'react'
import { List, ListItem, ListItemText, Theme, Typography } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import { TFunction } from 'i18next'
import { LunchGroup } from '@seesignage/seesignage-utils/lib/types/lunchLists'
import classNames from 'classnames'
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon'
import {
  CloseDialog,
  CreateOrUpdateLunchListGroup,
  SetSelectedLunchItem
} from '../../../../types/actions'
import Dialog from '../../../Dialog'
import CreateLunchGroupForm from '../../Forms/CreateLunchGroupForm'

import { SelectedLunchItem, SelectedLunchItemType } from '../../../../types/lists'
import { compareStrings } from '../../../../utils/sorting'
import { convertCentsToEuros } from '../../../../utils/conversion'
import colors from '../../../../styles/common/colors'

const styles = (theme: Theme) => ({
  groupsContainer: {
    height: 200
  },
  list: {
    height: 150,
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper
  },
  listItem: {
    cursor: 'pointer',
    padding: '2px 10px 2px'
  },
  listItemText: {
    color: '#1e88e5',
    fontWeight: 600
  },
  selectedListItemText: {
    color: 'white !important'
  },
  parentListItemText: {
    color: colors.parentLunchListColor
  },
  selectedListItem: {
    backgroundColor: '#50c3ed'
  },
  noGroupsText: {
    color: 'rgba(0, 0, 0, 0.26)',
    textAlign: 'center' as 'center',
    marginTop: 20
  }
})

export interface GroupsProps {
  t: TFunction
  groups: LunchGroup[]
  selectedItem?: SelectedLunchItem
  createOrUpdateLunchListGroup: CreateOrUpdateLunchListGroup
  closeDialog: CloseDialog
  setSelectedItem: SetSelectedLunchItem
}

type OwnProps = GroupsProps & WithStyles<typeof styles>

const Groups = ({
  classes,
  t,
  groups,
  createOrUpdateLunchListGroup,
  closeDialog,
  setSelectedItem,
  selectedItem
}: OwnProps) => (
  <div className={classes.groupsContainer}>
    <Dialog
      dialogId='addLunchGroupDialog'
      title={t('lists.lunch.edit.createGroup')}
      buttonLabel={t('lists.lunch.edit.createGroup')}
      color='primary'
      ButtonIcon={PlusCircleOutlineIcon}
      buttonFullWidth
      Content={
        <CreateLunchGroupForm
          dialogId='addLunchGroupDialog'
          submitAction={createOrUpdateLunchListGroup}
          closeDialog={closeDialog}
        />
      }
    />
    {groups.length > 0 ? (
      <List className={classes.list}>
        {groups.sort(compareStrings('name')).map(group => {
          const { name, groupId, price, isParentGroup } = group
          const isGroupSelected =
            selectedItem?.type === SelectedLunchItemType.group &&
            (selectedItem.item as LunchGroup | undefined)?.groupId === groupId
          return (
            <ListItem
              key={groupId}
              className={classNames(classes.listItem, {
                [classes.selectedListItem]: isGroupSelected
              })}
              onClick={() => {
                setSelectedItem(
                  isGroupSelected
                    ? undefined
                    : {
                        item: group,
                        type: SelectedLunchItemType.group
                      }
                )
              }}>
              <ListItemText
                className={classNames(classes.listItemText, {
                  [classes.parentListItemText]: isParentGroup,
                  [classes.selectedListItemText]: isGroupSelected
                })}>
                {name} {price ? `- ${convertCentsToEuros(price)} €` : ''}
              </ListItemText>
            </ListItem>
          )
        })}
      </List>
    ) : (
      <Typography className={classes.noGroupsText}>{t('lists.lunch.noGroups')}</Typography>
    )}
  </div>
)

export default withStyles(styles)(Groups)
