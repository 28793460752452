import { PlaylistFilter, PlaylistFilterTypes } from '../../types/playlists'

const getPlaylistFilter = (): PlaylistFilter => {
  const value = localStorage.getItem('playlistFilter')
  return value === null ? PlaylistFilterTypes.all : (value as PlaylistFilter)
}

const savePlaylistFilter = (filter: PlaylistFilter) => {
  localStorage.setItem('playlistFilter', filter)
}
export { getPlaylistFilter, savePlaylistFilter }
