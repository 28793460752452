import { Button, DialogActions, Grid, LinearProgress } from '@mui/material'
import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import { required, validUrl, validateYoutubeUrl, customInterval } from '../../../../validation'
import { asyncValidateUrl } from '../../../../validation/asyncValidate'
import { ReduxTextField, ReduxDurationField } from '../../../../components/FormInput/ReduxWrappers'
import { PageType } from '../../../../types/playlists'
import { formatUrl, parseUrl } from '../../../../utils/url'
import { parseNumberField } from '../../../../utils/forms'
import ErrorMessage from '../../../../components/Errors/ErrorMessage'

interface OwnProps {
  onSubmit: (formData: any) => void
  selectPage?: (page: PageType) => void
  submitButtonLabel: string
  defaultInterval: number
}
type AddUrlItemFormProps = OwnProps & WithTranslation
const AddUrlItemForm: React.FC<AddUrlItemFormProps & InjectedFormProps<{}, OwnProps>> = ({
  handleSubmit,
  submitting,
  onSubmit,
  error,
  selectPage,
  submitButtonLabel,
  defaultInterval,
  t
}) => (
  <form onSubmit={handleSubmit(onSubmit)}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Field
          fullWidth
          name='url'
          label={t('playlists.editPlaylist.addUrlItemForm.url')}
          component={ReduxTextField}
          format={(url: string) => formatUrl(url)}
          parse={(url: string) => parseUrl(url)}
          required
          validate={[required, validUrl, validateYoutubeUrl]}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          fullWidth
          name='interval'
          label={t('playlists.editPlaylist.addUrlItemForm.interval')}
          component={ReduxDurationField}
          parse={parseNumberField}
          required
          validate={[customInterval, required]}
          defaultValue={defaultInterval}
          type='number'
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        {submitting && <LinearProgress />}
        <ErrorMessage message={error} />
      </Grid>
    </Grid>
    <DialogActions>
      {selectPage && (
        <Button onClick={() => selectPage(PageType.selectType)} color='primary'>
          {t('general.back')}
        </Button>
      )}
      <Button disabled={submitting} color='primary' type='submit'>
        {submitButtonLabel}
      </Button>
    </DialogActions>
  </form>
)

export default reduxForm<{}, OwnProps>({
  form: 'addUrlItemForm',
  asyncValidate: asyncValidateUrl,
  asyncBlurFields: ['url']
})(withTranslation()(AddUrlItemForm))
