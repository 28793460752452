import React from 'react'
import { reduxForm, InjectedFormProps, Field } from 'redux-form'
import { Grid, MenuItem } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { TFunction } from 'i18next'
import { Language } from '@seesignage/seesignage-utils'
import { ContentDateWidgetFormData } from '../../../../../../types/contents'
import SimpleExtensionPanel from '../../../../../../components/ExtensionPanels/SimpleExtensionPanel'
import Borderfields from '../CommonFields/BorderFields'
import TextFields from '../CommonFields/TextFields'
import { ReduxTextField } from '../../../../../../components/FormInput/ReduxWrappers'

const dateFormats = [
  {
    label: '1.1.2021',
    value: 'D.M.YYYY'
  },
  {
    label: '1/2021',
    value: 'M/YYYY'
  },
  {
    label: '12:00 1.1.2021',
    value: 'HH:mm D.M.YYYY'
  },
  {
    label: '12:00:00 1.1.2021',
    value: 'HH:mm:ss D.M.YYYY'
  },
  {
    label: '12:00',
    value: 'HH:mm'
  },
  {
    label: '12:00:00',
    value: 'HH:mm:ss'
  },
  {
    label: 'Sunday, February 14th 2021, 3:25:50 pm',
    value: 'dddd, MMMM Do YYYY, h:mm:ss a'
  },
  {
    label: 'Sunday, February 14th 2021, 12:00',
    value: 'dddd, MMMM Do YYYY, HH:mm'
  },
  {
    label: 'Sunday, February 14th 2021',
    value: 'dddd, MMMM Do YYYY'
  },
  {
    label: 'February',
    value: 'MMMM'
  },
  {
    label: 'Monday',
    value: 'dddd'
  },
  {
    label: '2021',
    value: 'YYYY'
  },
  {
    label: '14th',
    value: 'Do'
  }
]

const languages = Object.values(Language)

const useStyles = makeStyles(() => ({
  accordionSummaryRootClass: {
    backgroundColor: '#F5F5F5',
    borderBottom: '1px solid rgba(0, 0, 0, 0.09)'
  }
}))

interface OwnProps {
  t: TFunction
}

type FormProps = ContentDateWidgetFormData
type ComponentProps = OwnProps

const DateWidgetForm: React.FC<ComponentProps & InjectedFormProps<FormProps, ComponentProps>> = ({
  t
}) => {
  const classes = useStyles()
  return (
    <Grid container>
      <Grid item xs={12}>
        <SimpleExtensionPanel
          title={t('contents.widgets.date.date')}
          isOpen={true}
          accordionSummaryRootClass={classes.accordionSummaryRootClass}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                name='cDateProps.format'
                label={t('contents.widgets.date.dateFormat')}
                component={ReduxTextField}
                select
                isToolbar
                fullWidth>
                {dateFormats.map(({ label, value }) => (
                  <MenuItem value={value} key={`format-${value}`}>
                    {label}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field
                name='cDateProps.language'
                label={t('contents.widgets.date.selectLanguage')}
                component={ReduxTextField}
                select
                isToolbar
                fullWidth>
                {languages.map(lang => (
                  <MenuItem value={lang} key={`lang-${lang}`}>
                    {t(`general.language.${lang}`)}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          </Grid>
        </SimpleExtensionPanel>
      </Grid>
      <Grid item xs={12}>
        <SimpleExtensionPanel
          title={t('contents.properties.text')}
          isOpen={false}
          accordionSummaryRootClass={classes.accordionSummaryRootClass}>
          <TextFields t={t} namePrefix='cDateProps.styles' isFieldsDisabled={false} />
        </SimpleExtensionPanel>
      </Grid>
      <Grid item xs={12}>
        <SimpleExtensionPanel
          title={t('contents.properties.border')}
          isOpen={false}
          accordionSummaryRootClass={classes.accordionSummaryRootClass}>
          <Borderfields t={t} namePrefix='cDateProps.styles' isFieldsDisabled={false} />
        </SimpleExtensionPanel>
      </Grid>
    </Grid>
  )
}

export default reduxForm<FormProps, ComponentProps>({
  form: 'DateWidgetForm',
  enableReinitialize: true
})(DateWidgetForm)
