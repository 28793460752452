import React from 'react'
import classNames from 'classnames'
import { Card, CardActionArea, Typography, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import FacebookIcon from 'mdi-react/FacebookIcon'
import InstagramIcon from 'mdi-react/InstagramIcon'
import { SocialMediaPlatform } from '@seesignage/seesignage-utils'
import { WrappedFieldProps } from 'redux-form'

const useStyles = makeStyles(() => ({
  card: {
    width: '250px',
    height: '100px',
    margin: '20px'
  },
  cardAction: {
    height: '100%',
    width: '100%',
    display: 'flex',
    transition: '0.2s'
  },
  text: {
    marginLeft: '10px'
  }
}))

type ReduxSelectSocialMediaPlatformProps = WrappedFieldProps

const ReduxSelectSocialMediaPlatform = ({
  input: { onChange }
}: ReduxSelectSocialMediaPlatformProps) => {
  const classes = useStyles()
  return (
    <Grid container direction='row' justifyContent='center' alignItems='center'>
      <Grid item>
        <Card
          className={classNames(classes.card)}
          onClick={() => onChange(SocialMediaPlatform.instagram)}>
          <CardActionArea className={classes.cardAction}>
            <InstagramIcon size='40px' />
            <Typography className={classes.text} variant='h6'>
              Instagram
            </Typography>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item>
        <Card
          className={classNames(classes.card)}
          onClick={() => onChange(SocialMediaPlatform.facebook)}>
          <CardActionArea className={classes.cardAction}>
            <FacebookIcon size='40px' />
            <Typography className={classes.text} variant='h6'>
              Facebook
            </Typography>
          </CardActionArea>
        </Card>
      </Grid>
    </Grid>
  )
}

export default ReduxSelectSocialMediaPlatform
