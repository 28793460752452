import React, { Fragment, useState, useEffect } from 'react'
import { FormAction, isSubmitting, submit } from 'redux-form'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { InfopageExtensive, Template, ContentMetadataByKey } from '@seesignage/seesignage-utils'
import { Button, DialogActions, Paper, TextField, LinearProgress, Grid } from '@mui/material'
import { Dispatch } from 'redux'
import CreateInfopageForm from '../../../../Infopages/Forms/CreateInfopageForm'
import { StateInterface } from '../../../../../types/states'
import {
  createInfopageToPlaylist,
  updateInfopage,
  initInfopageMetadata
} from '../../../../../actions/infopages'
import { bindSubmitActionToPromise } from '../../../../../utils/forms'
import { compareStrings } from '../../../../../utils/sorting'
import { listTemplates, getTemplate } from '../../../../../actions/templates'
import SelectTemplate from '../../../../../components/FormInput/SelectTemplate'
import { TemplateAsFormValue } from '../../../../../types/templates'
import { selectTemplatesByType } from '../../../../../selectors/templates'
import { selectInfopageKeys } from '../../../../../selectors/infopages'

interface OwnProps {
  /** Given as prop if being edited from playlist */
  infopage?: InfopageExtensive
  submitButtonLabel: string
  isUpdateItem?: boolean
}

interface StateProps {
  templates: Template[]
  infopageKeys?: ContentMetadataByKey
  submitting: boolean
}

interface DispatchProps {
  createInfopage: (formData: any) => void
  updateInfopage: (formData: any) => void
  getTemplate: (environmentId: string, templateId: string) => void
  submitForm: () => FormAction
  listTemplates: () => void
  initializeMetadata: (metadata: ContentMetadataByKey) => void
}

type UpdateInfopageItemProps = OwnProps & StateProps & DispatchProps & WithTranslation

/**
 * Wrapper component to create infopage content from AddPlaylistItemWizard or edit from EditPlaylistToolbar
 */
const UpdateInfopageItem: React.FC<UpdateInfopageItemProps> = ({
  templates,
  infopageKeys,
  createInfopage,
  updateInfopage,
  infopage,
  submitForm,
  submitButtonLabel,
  submitting,
  listTemplates,
  getTemplate,
  initializeMetadata,
  isUpdateItem,
  t
}) => {
  const [name, setName] = useState<string>(infopage?.name || '')
  const [selectedTemplate, selectTemplate] = useState<TemplateAsFormValue | null>(
    infopage?.templateId
      ? {
          templateId: infopage.templateId,
          templateEnvironmentId: infopage.templateEnvironmentId
        }
      : null
  )
  const handleMountActions = () => {
    if (isUpdateItem && infopage) {
      listTemplates()
    }
    if (infopage) {
      getTemplate(infopage.templateEnvironmentId, infopage.templateId)
      initializeMetadata(infopage.contentMetadata)
    }
  }
  useEffect(handleMountActions, [])

  const handleChangeTemplate = (template: TemplateAsFormValue | null) => {
    selectTemplate(template)
    if (template) {
      const { templateEnvironmentId, templateId } = template
      getTemplate(templateEnvironmentId, templateId)
    }
  }
  return (
    <Fragment>
      <Paper variant='outlined' style={{ width: 500, padding: 10 }}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              variant='standard'
              style={{ margin: 10 }}
              label={t('general.name')}
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </Grid>
          {name && (
            <Grid item xs={selectedTemplate ? 6 : 12} style={{ marginTop: 15 }}>
              <SelectTemplate
                selectedTemplate={selectedTemplate}
                selectTemplate={handleChangeTemplate}
                initialTemplates={templates}
                showOnlySelectedName
              />
            </Grid>
          )}
        </Grid>
      </Paper>
      {name.length > 0 && selectedTemplate && infopageKeys && (
        <CreateInfopageForm
          submitAction={isUpdateItem ? updateInfopage : createInfopage}
          infopageTemplate={infopage?.template}
          initialValues={{
            name,
            isEditPlaylist: true,
            template: selectedTemplate,
            content: infopage?.content || {},
            infopageId: infopage?.infopageId
          }}
          infopageKeys={infopageKeys}
        />
      )}
      <DialogActions>
        {name.length > 0 && selectedTemplate && (
          <Button color='primary' disabled={submitting} onClick={() => submitForm()}>
            {submitButtonLabel}
          </Button>
        )}
      </DialogActions>
      {submitting && <LinearProgress />}
    </Fragment>
  )
}

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    templates: selectTemplatesByType('info')(state).sort(compareStrings('name')) as Template[],
    infopageKeys: selectInfopageKeys(state),
    submitting: isSubmitting('CreateInfopageForm')(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  updateInfopage: bindSubmitActionToPromise(dispatch, updateInfopage),
  createInfopage: bindSubmitActionToPromise(dispatch, createInfopageToPlaylist),
  getTemplate: (environmentId: string, templateId: string) =>
    dispatch(getTemplate({ environmentId, templateId, selectForInfopage: true })),
  submitForm: () => dispatch(submit('CreateInfopageForm')),
  listTemplates: () => dispatch(listTemplates()),
  initializeMetadata: (metadata: ContentMetadataByKey) => dispatch(initInfopageMetadata(metadata))
})
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UpdateInfopageItem))
