import axios from 'axios'
import { toast } from 'react-toastify'

export const getConnectedInstagramAccounts = async (
  accessToken: string,
  accountId: string,
  name?: string
) => {
  try {
    const { data } = await axios.get(
      `https://graph.facebook.com/v16.0/me?fields=accounts{instagram_business_account.as(instagramBusinessAccount){id,username,name,profile_picture_url.as(picture)}}&access_token=${accessToken}`
    )
    const formatedResult = data?.accounts?.data
      .map(fbPage => fbPage.instagramBusinessAccount)
      .filter(account => account)
    return formatedResult ?? []
  } catch (error) {
    toast.error(`Unable to access user data: ${name ?? accountId}`)
    return []
  }
}
