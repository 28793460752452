import { CountPlaysByAttribute, TimeInterval } from '@seesignage/seesignage-utils'
import { QueryProofOfPlayFormData } from '../types/analytics'
import { getEndOfTheDayDate, getStartOfTheDayDate } from './time'

const getProofOfPlayFormInitialValues = (environmentId: string) => {
  const startDate = getStartOfTheDayDate()
  startDate.setDate(1)
  const initialValues: QueryProofOfPlayFormData = {
    startDate: startDate.toISOString(),
    endDate: getEndOfTheDayDate().toISOString(),
    interval: TimeInterval.day,
    countPlaysByAttribute: CountPlaysByAttribute.playlist_id,
    environmentId
  }
  return initialValues
}

export { getProofOfPlayFormInitialValues }
