import React, { useState, useEffect, useMemo, Fragment } from 'react'
import {
  SocialMediaPlatform,
  UserFacebookAccounts,
  UserInstagramAccounts,
  InstagramAccount,
  FacebookPage,
  isInstagramAccount
} from '@seesignage/seesignage-utils'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  getFacebookPages,
  getFacebookAccount
} from '../../../../../services/api/SocialMedia/facebook'
import { addSocialMediaAccount, deleteSocialMediaAccount } from '../../../../../actions/users'
import { getConnectedInstagramAccounts } from '../../../../../services/api/SocialMedia/instagram'
import { FacebookUserAccount, FacebookUserPages } from '../../../../../types/facebook'
import AccountsList, { AccountsListProps } from './AccountsList'

enum SocialMediaItemType {
  account = 'Account',
  instagram = 'Instagram',
  page = 'Page'
}

interface InstagramAccountSelector
  extends Omit<SelectAccountProps<UserInstagramAccounts>, 'setSelectedSocialMedia'> {
  setSelectedSocialMedia: (socialMedia: InstagramAccount) => void
}

const InstagramAccountSelector = ({
  connectedAccounts,
  platform,
  onGoToNextStep,
  setSelectedAccount,
  setSelectedSocialMedia
}: InstagramAccountSelector) => {
  const dispatch = useDispatch()
  const [fbAccount, setFBAccount] = useState<FacebookUserAccount[]>([])
  const [instaAccounts, setInstaAccounts] = useState<InstagramAccount[]>([])
  const [section, setSection] = useState<SocialMediaItemType>(SocialMediaItemType.account)
  const [loading, setLoading] = useState(true)
  const [editMode, setEditMode] = useState(false)

  const handleAddFacebookAccount = () => {
    setLoading(true)
    dispatch(
      addSocialMediaAccount({
        platform,
        stopLoading: () => setLoading(false)
      })
    )
  }

  const handleDeleteAccount = (id: string) => {
    setLoading(true)
    dispatch(
      deleteSocialMediaAccount({
        account: connectedAccounts[id],
        stopLoading: () => setLoading(false)
      })
    )
  }

  const loadInstagramAccounts = (fbAccountId: string) => {
    setSection(SocialMediaItemType.instagram)
    const { accessToken, name } = connectedAccounts[fbAccountId]
    getConnectedInstagramAccounts(accessToken ?? '', fbAccountId, name)
      .then(setInstaAccounts)
      .finally(() => setLoading(false))
  }

  const handleClickItem = async (id: string) => {
    setLoading(true)
    if (editMode) {
      handleDeleteAccount(id)
    } else {
      if (section === SocialMediaItemType.account) {
        setSelectedAccount(connectedAccounts[id])
        loadInstagramAccounts(id)
      } else {
        setSelectedSocialMedia(
          instaAccounts.find(instaAccount => instaAccount.id === id) as InstagramAccount
        )
        onGoToNextStep()
      }
    }
  }

  const listItems = useMemo(() => {
    switch (section) {
      case SocialMediaItemType.account:
        return fbAccount
      case SocialMediaItemType.instagram:
        return instaAccounts
      default:
        return []
    }
  }, [section, fbAccount, instaAccounts])

  useEffect(() => {
    setLoading(true)
    const loadFacebookAcoounts = async () => {
      Promise.all(
        Object.values(connectedAccounts).map(({ id, name, accessToken }) =>
          getFacebookAccount(accessToken as string, id, name)
        )
      )
        .then(setFBAccount)
        .finally(() => setLoading(false))
    }
    loadFacebookAcoounts()
  }, [connectedAccounts])

  const listSocialMediaItemsProps: AccountsListProps = {
    loading,
    editMode,
    editable: section === SocialMediaItemType.account,
    platform,
    onClickItem: handleClickItem,
    onAddButtonClick: handleAddFacebookAccount,
    onEditButtonClick: () => setEditMode(value => !value),
    onBackButtonClick: () => setSection(SocialMediaItemType.account),
    items: listItems.map((item: FacebookUserAccount | InstagramAccount) => ({
      id: item.id,
      primaryText: item.name ?? '',
      secondaryText: `${section} ID: ${isInstagramAccount(item) ? `@${item.username}` : item.id}`,
      url: item.picture
    }))
  }

  return <AccountsList {...listSocialMediaItemsProps} />
}

interface FacebookAccountSelectorProps
  extends Omit<SelectAccountProps<UserFacebookAccounts>, 'setSelectedSocialMedia'> {
  setSelectedSocialMedia: (socialMedia: FacebookPage) => void
}

const FacebookAccountSelector = ({
  connectedAccounts,
  platform,
  onGoToNextStep,
  setSelectedAccount,
  setSelectedSocialMedia
}: FacebookAccountSelectorProps) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const [fbAccounts, setFBAccounts] = useState<FacebookUserAccount[]>([])
  const [fbPages, setFBPages] = useState<FacebookUserPages>([])
  const [section, setSection] = useState<SocialMediaItemType>(SocialMediaItemType.account)
  const [editMode, setEditMode] = useState(false)
  const [loading, setLoading] = useState(true)

  const handleAddFacebookAccount = () => {
    setLoading(true)
    dispatch(
      addSocialMediaAccount({
        platform,
        stopLoading: () => setLoading(false)
      })
    )
  }

  const handleDeleteAccount = (id: string) => {
    setLoading(true)
    dispatch(
      deleteSocialMediaAccount({
        account: connectedAccounts[id],
        stopLoading: () => setLoading(false)
      })
    )
  }

  const loadFacebookAccountPages = (id: string) => {
    setSection(SocialMediaItemType.page)
    getFacebookPages(connectedAccounts[id].accessToken)
      .then(setFBPages)
      .finally(() => setLoading(false))
  }

  const handleClickItem = async (id: string) => {
    setLoading(true)
    if (editMode) {
      handleDeleteAccount(id)
    } else {
      if (section === SocialMediaItemType.account) {
        setSelectedAccount(connectedAccounts[id])
        loadFacebookAccountPages(id)
      } else {
        setSelectedSocialMedia(fbPages.find(fbpage => fbpage.id === id) as FacebookPage)
        onGoToNextStep()
      }
    }
  }

  useEffect(() => {
    setLoading(true)
    const loadFacebookAcoounts = async () => {
      Promise.all(
        Object.values(connectedAccounts).map(({ id, name, accessToken }) =>
          getFacebookAccount(accessToken, id, name)
        )
      )
        .then(setFBAccounts)
        .finally(() => setLoading(false))
    }
    loadFacebookAcoounts()
  }, [connectedAccounts])

  const listItems = useMemo(() => {
    switch (section) {
      case SocialMediaItemType.account:
        return fbAccounts
      case SocialMediaItemType.page:
        return fbPages
      default:
        return []
    }
  }, [section, fbAccounts, fbPages])

  const listSocialMediaItemsProps: AccountsListProps = {
    loading,
    editMode,
    editable: section === SocialMediaItemType.account,
    platform,
    onClickItem: handleClickItem,
    onAddButtonClick: handleAddFacebookAccount,
    onEditButtonClick: () => setEditMode(value => !value),
    onBackButtonClick: () => setSection(SocialMediaItemType.account),
    items: listItems.map(item => ({
      id: item.id,
      primaryText: item.name ?? '',
      secondaryText: `${t('contents.widgets.socialMedia.accountId')}: ${item.id}`,
      url: item.picture
    }))
  }

  return <AccountsList {...listSocialMediaItemsProps} />
}

interface SelectAccountProps<
  T extends UserInstagramAccounts | UserFacebookAccounts =
    | UserInstagramAccounts
    | UserFacebookAccounts
> {
  platform: SocialMediaPlatform
  connectedAccounts: T
  onGoToNextStep: () => void
  setSelectedAccount: (account: T[keyof T]) => void
  setSelectedSocialMedia: (socialMedia: InstagramAccount | FacebookPage) => void
}

const SelectAccount = ({
  platform,
  connectedAccounts,
  onGoToNextStep,
  setSelectedAccount,
  setSelectedSocialMedia
}: SelectAccountProps) => {
  const commonAccountSelectorProps = {
    onGoToNextStep,
    platform,
    setSelectedAccount,
    setSelectedSocialMedia
  }
  return (
    <Fragment>
      {platform === SocialMediaPlatform.facebook && (
        <FacebookAccountSelector
          {...commonAccountSelectorProps}
          connectedAccounts={connectedAccounts as UserFacebookAccounts}
        />
      )}
      {platform === SocialMediaPlatform.instagram && (
        <InstagramAccountSelector
          {...commonAccountSelectorProps}
          connectedAccounts={connectedAccounts as UserInstagramAccounts}
        />
      )}
    </Fragment>
  )
}

export default SelectAccount
