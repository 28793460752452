import { Button, Snackbar } from '@mui/material'
import React from 'react'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { StateInterface } from '../../types/states'
import Alert from '../Alert'

interface StateProps {
  isUpdateAvailable: boolean
}

const UpdateNotification: React.FC<StateProps & WithTranslation> = ({ isUpdateAvailable, t }) => (
  // Note: use Snackbar content inside element. Otherwise, TypeError: Cannot read properties of null (reading 'scrollTop').
  <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'top' }} open={isUpdateAvailable}>
    <div>
      <Alert
        severity='info'
        action={
          <Button
            size='small'
            color='inherit'
            onClick={() => {
              window.location.reload()
            }}>
            {t('general.update')}
          </Button>
        }>
        {t('app.updateAvailable')}
      </Alert>
    </div>
  </Snackbar>
)

const mapStateToProps = (state: StateInterface): StateProps => ({
  isUpdateAvailable: state.notifications.updateAvailable
})

export default connect(mapStateToProps)(withTranslation()(UpdateNotification))
