import React from 'react'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import { Button, DialogContent, Grid, DialogActions } from '@mui/material'
import { TFunction } from 'i18next'
import { required, password, passwordsMatch } from '../../validation'
import FieldContainer from '../../components/FieldContainer'
import { ReduxTextField } from '../../components/FormInput/ReduxWrappers'
import ErrorMessage from '../../components/Errors/ErrorMessage'
import { ChangePasswordFormData } from '../../types/users'

interface ChangePasswordFormProps {
  changePassword: (formData: any) => Promise<unknown>
  closeDialog: () => void
  t: TFunction
}

const ChangePasswordForm: React.FC<ChangePasswordFormProps &
  InjectedFormProps<ChangePasswordFormData, ChangePasswordFormProps>> = ({
  closeDialog,
  handleSubmit,
  changePassword,
  submitting,
  error,
  t
}) => {
  const handleSubmitFormData = (formData: ChangePasswordFormData) =>
    changePassword(formData).finally(closeDialog)
  return (
    <form onSubmit={handleSubmit(handleSubmitFormData)}>
      <DialogContent>
        <Grid container rowGap={2}>
          <Grid item xs={12}>
            <FieldContainer>
              <Field
                fullWidth
                name='currentPassword'
                label={t('changePw.currentPassword')}
                type='password'
                variant='outlined'
                required
                validate={[required]}
                component={ReduxTextField}
              />
            </FieldContainer>
          </Grid>
          <Grid item xs={12}>
            <FieldContainer>
              <Field
                fullWidth
                name='password'
                variant='outlined'
                label={t('changePw.password')}
                type='password'
                helperText={t('changePw.helperText')}
                required
                validate={[required, password]}
                component={ReduxTextField}
              />
            </FieldContainer>
          </Grid>
          <Grid item xs={12}>
            <FieldContainer>
              <Field
                fullWidth
                name='confirmPassword'
                variant='outlined'
                label={t('changePw.confirm')}
                type='password'
                required
                validate={[required, password, passwordsMatch]}
                component={ReduxTextField}
              />
            </FieldContainer>
          </Grid>
          {error && (
            <Grid item xs={12}>
              <ErrorMessage message={error} />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>{t('general.cancel')}</Button>
        <Button type='submit' disabled={submitting}>
          {t('general.save')}
        </Button>
      </DialogActions>
    </form>
  )
}

export default reduxForm<ChangePasswordFormData, ChangePasswordFormProps>({
  form: 'ChangePasswordForm'
})(ChangePasswordForm)
