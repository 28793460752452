import React from 'react'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import { connect } from 'react-redux'
import DialogActions from '@mui/material/DialogActions'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { withTranslation, WithTranslation } from 'react-i18next'
import { AutocompleteOption, EnvironmentScreenFeatures } from '@seesignage/seesignage-utils'
import { InputAdornment } from '@mui/material'
import { required, defaultInterval } from '../../../validation'
import { isAdminUser } from '../../../utils/permissions'
import { CloseDialog } from '../../../types/actions'
import { UserInterface } from '../../../types/users'
import { StateInterface } from '../../../types/states'
import {
  selectCurrentEnvironmentScreenFeatures,
  selectCurrentEnvironmentPermissionTagsAsOptions
} from '../../../selectors/environments'
import {
  ReduxTextField,
  ReduxCreatableAutocomplete,
  ReduxSwitch
} from '../../../components/FormInput/ReduxWrappers'
import ErrorMessage from '../../../components/Errors/ErrorMessage'

interface FormProps {
  name: string
  defaultInterval: number
  playlistId?: string
  permissionTags?: AutocompleteOption[] | null
  syncPlay?: boolean
}

interface OwnProps {
  submitAction: (formData: any) => void
  closeDialog: CloseDialog
  dialogId: string
  disableSyncPlay?: boolean
  submitButtonLabel: string
  user: UserInterface
}

interface StateProps {
  permissionTagsAsOptions: AutocompleteOption[]
  environmentScreenFeatures?: EnvironmentScreenFeatures
}

type CreatePlaylistFormProps = OwnProps & StateProps & WithTranslation

const CreatePlaylistForm: React.FC<CreatePlaylistFormProps &
  InjectedFormProps<FormProps, OwnProps & StateProps>> = ({
  disableSyncPlay,
  handleSubmit,
  submitting,
  submitAction,
  closeDialog,
  dialogId,
  submitButtonLabel,
  error,
  user,
  permissionTagsAsOptions,
  environmentScreenFeatures,
  t
}) => (
  <form onSubmit={handleSubmit(submitAction)}>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Field
          fullWidth
          name='name'
          label={t('playlists.actions.playlistName')}
          component={ReduxTextField}
          required
          validate={[required]}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          fullWidth
          name='defaultInterval'
          label={t('playlists.forms.defaultInterval')}
          helperText={t('playlists.forms.defaultIntervalHelper')}
          InputProps={{
            endAdornment: <InputAdornment position='end'>s</InputAdornment>
          }}
          type='number'
          component={ReduxTextField}
          required
          validate={[required, defaultInterval]}
        />
      </Grid>
      {isAdminUser(user) && (
        <Grid item xs={12} sm={12} style={{ paddingTop: '15px' }}>
          <Field
            name='permissionTags'
            placeholder={t('screens.addTags')}
            component={ReduxCreatableAutocomplete}
            label={t('screens.permissionTags')}
            options={permissionTagsAsOptions}
            multiple
          />
          <Typography variant='caption'>{t('playlists.actions.permissionTagHelper')}</Typography>
        </Grid>
      )}
      {environmentScreenFeatures?.syncPlay && (
        <Grid item xs={12}>
          <Field
            name='syncPlay'
            component={ReduxSwitch}
            label={t('playlists.forms.syncPlaylist')}
            disabled={disableSyncPlay}
          />
          {disableSyncPlay && (
            <Typography display='block' variant='caption' color='error'>
              {t('playlists.forms.syncPlaylistDisabledHelper')}
            </Typography>
          )}
          <Typography display='block' variant='caption'>
            {t('playlists.forms.syncPlaylistHelper')}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} sm={12}>
        {submitting && <LinearProgress />}
        <ErrorMessage message={error} />
      </Grid>
    </Grid>
    <DialogActions>
      <Button onClick={() => closeDialog(dialogId)} color='primary'>
        {t('general.cancel')}
      </Button>
      <Button disabled={submitting} color='primary' type='submit'>
        {submitButtonLabel}
      </Button>
    </DialogActions>
  </form>
)

const mapStateToProps = (state: StateInterface): StateProps => {
  return {
    permissionTagsAsOptions: selectCurrentEnvironmentPermissionTagsAsOptions(state),
    environmentScreenFeatures: selectCurrentEnvironmentScreenFeatures(state)
  }
}

export default connect<StateProps, {}, OwnProps, StateInterface>(mapStateToProps)(
  reduxForm<FormProps, OwnProps & StateProps>({
    form: 'createPlaylistForm'
  })(withTranslation()(CreatePlaylistForm))
)
