import React, { ComponentType } from 'react'
import TextField, { TextFieldVariants } from '@mui/material/TextField'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { MdiReactIconProps } from 'mdi-react'
import { AutocompleteOption } from '@seesignage/seesignage-utils'
import { WrappedFieldProps } from 'redux-form'
import { useTranslation } from 'react-i18next'

export interface CreatableOptionType extends AutocompleteOption {
  title?: string
  inputValue?: string
}

const filter = createFilterOptions<CreatableOptionType>()
interface ReduxCreatableAutocompleteProps extends WrappedFieldProps {
  label: string
  multiple?: boolean
  options: CreatableOptionType[]
  disabled?: boolean
  /**
   * Custom icon rendered next to option when menu opens
   */
  OptionIcon?: ComponentType<MdiReactIconProps>
  popupIcon?: JSX.Element
  /**
   * Custom i18n translation for create label. e.g media.createFolder
   */
  customCreateLabel?: string
  helperText?: string
  variant?: TextFieldVariants
  /**
   * if you want to use AutoCompleteOption's value as field value
   * and not the whole AutoCompleteOption object.
   * Note: This does not support multi selection.
   * */
  useInputValue?: boolean
}
/**
 * Get Autocomplete value depending on if it supports multiple values or just one.
 * Multiple autocomplete requires array as value, cannot be null
 * @param input
 * @param multiple
 */
const getValue = (input: any, multiple?: boolean) =>
  multiple ? input.value || [] : input.value || null

const ReduxCreatableAutocomplete = ({
  input,
  label,
  meta: { touched, invalid, error },
  helperText,
  variant,
  useInputValue,
  ...custom
}: ReduxCreatableAutocompleteProps) => {
  const { customCreateLabel, multiple, ...rest } = custom
  const [t] = useTranslation()
  const fieldValue = useInputValue ? input.value : getValue(input, multiple)
  return (
    <Autocomplete
      value={fieldValue}
      onChange={(event: any, newValue: any) => {
        if (useInputValue) {
          input.onChange(newValue?.value || null) // redux-form does not support undefined as form value so set null if it does not exists
          return
        }
        if (typeof newValue === 'string') {
          // user pressed enter to create new value
          input.onChange({ value: newValue, label: newValue })
          return
          // multi selection
        } else if (Array.isArray(newValue)) {
          const value = newValue.map(val =>
            typeof val === 'string' ? { value: val, label: val } : val
          )
          input.onChange(value)
          return
        }
        input.onChange(newValue)
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params) as CreatableOptionType[]
        if (params.inputValue !== '') {
          filtered.push({
            value: params.inputValue,
            inputValue: params.inputValue,
            title: customCreateLabel
              ? t(customCreateLabel, { value: params.inputValue })
              : t('screens.createTag', { value: params.inputValue }),
            label: params.inputValue
          })
        }

        return filtered
      }}
      getOptionLabel={option => (typeof option === 'string' ? option : option.label)}
      renderOption={(props, option) => <li {...props}>{option.title || option.label}</li>}
      freeSolo
      multiple={multiple}
      filterSelectedOptions
      renderInput={params => (
        <TextField
          {...params}
          onFocus={input.onFocus}
          onBlur={() => input.onBlur(undefined)} // make sure onBlur doesn't change field value
          error={touched && invalid}
          helperText={(touched && error) || helperText}
          label={label}
          variant={variant || 'outlined'}
          fullWidth
        />
      )}
      {...rest}
    />
  )
}

export default ReduxCreatableAutocomplete
