import React, { Fragment } from 'react'
import { TFunction } from 'i18next'
import { EnvironmentScreenTypes } from '@seesignage/seesignage-utils'
import { WrappedFieldProps } from 'redux-form'
import { Typography } from '@mui/material'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@mui/material/ToggleButton'
import CurrencyUsdIcon from 'mdi-react/CurrencyUsdIcon'
import ImageIcon from 'mdi-react/ImageIcon'

interface ScreenTypeFieldProps extends WrappedFieldProps {
  label: string
  isMobile: boolean
  environmentScreenTypes?: EnvironmentScreenTypes
  disabled?: boolean
  t: TFunction
}

const ScreenTypeField = ({
  input: { onChange, value },
  t,
  label,
  isMobile,
  disabled,
  environmentScreenTypes,
  ...rest
}: ScreenTypeFieldProps) => (
  <Fragment>
    <Typography display='block' variant='caption'>
      {label}
    </Typography>
    <ToggleButtonGroup
      exclusive
      value={value}
      orientation={isMobile ? 'vertical' : 'horizontal'}
      onChange={(e, val) => {
        if (val !== null) {
          onChange(val)
        }
      }}
      {...rest}>
      <ToggleButton disabled={disabled} value='media'>
        <ImageIcon />
        {t('screens.media')}
      </ToggleButton>
      {environmentScreenTypes?.price && (
        <ToggleButton disabled={disabled} value='price'>
          <CurrencyUsdIcon />
          {t('screens.price')}
        </ToggleButton>
      )}
    </ToggleButtonGroup>
  </Fragment>
)

export default ScreenTypeField
