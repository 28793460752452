import React, { useState, useEffect } from 'react'
import { WrappedFieldProps } from 'redux-form'
import { Grid, Slider, TextField, Typography, Checkbox } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { selectSelectedObjects } from '../../../../selectors/contents'
import ContentColorPicker from '../../ColorPicker/ContentColorPicker'
import { hexToRgbA } from '../../../../utils/conversion'

const useStyles = makeStyles(() => ({
  accordionSummaryRootClass: {
    backgroundColor: '#F5F5F5',
    borderBottom: '1px solid rgba(0, 0, 0, 0.09)'
  },
  input: {
    maxWidth: '50px'
  },
  enableShadow: {
    paddingTop: '10px'
  },
  enableShadowRow: {
    display: 'flex'
  },
  shadowColor: {
    paddingTop: '5px'
  },
  labelAndFieldRow: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}))

const formatColorForFabric = (value: string) => {
  if (value.startsWith('#')) {
    return hexToRgbA(value)
  } else {
    return value
  }
}

const ShadowControl: React.FC<WrappedFieldProps> = ({ input }) => {
  const { onChange } = input
  const classes = useStyles()
  const [t] = useTranslation()

  const [offsetX, setOffsetX] = useState<number | undefined>(5)
  const [offsetY, setOffsetY] = useState<number | undefined>(5)
  const [blur, setBlur] = useState<number | undefined>(8)
  const [color, setColor] = useState<string | undefined>('rgba(0, 0, 0, 0.5)')
  const [shadowEnabled, setShadowEnabled] = useState(false)
  const selectedObjects: any = useSelector(selectSelectedObjects)
  const selectedObject =
    selectedObjects && selectedObjects.length === 1 ? selectedObjects[0] : undefined

  const resetValues = () => {
    setOffsetX(5)
    setOffsetY(5)
    setBlur(5)
    setColor('rgba(0, 0, 0, 0.5)')
    setShadowEnabled(false)
  }

  useEffect(() => {
    resetValues()
    const selected = selectedObject
    if (selected?.shadow) {
      const shadow = selected.shadow
      if (typeof shadow !== 'string') {
        //if not string type, must be shadow type
        setShadowEnabled(true)
        setOffsetX(shadow.offsetX)
        setOffsetY(shadow.offsetY)
        setBlur(shadow.blur)
        setColor(shadow.color)
      }
    }
  }, [selectedObject])

  const updateForm = (
    offSetX: number | undefined,
    offSetY: number | undefined,
    blur: number | undefined,
    color: string | undefined
  ) => {
    const shadowString = `${color} ${offSetX}px ${offSetY}px ${blur}px`
    onChange(shadowString)
  }

  const handleCheck = async () => {
    if (shadowEnabled) {
      onChange(false)
      resetValues()
    } else {
      const shadowString = `${color} ${offsetX}px ${offsetY}px ${blur}px`
      onChange(shadowString)
      setShadowEnabled(true)
    }
  }

  const handleColorPickerChange = (value: string) => {
    const convertedColor = formatColorForFabric(value)
    setColor(convertedColor)
    updateForm(offsetX, offsetY, blur, convertedColor)
  }

  return (
    <Grid container>
      <Grid item xs={12} className={classes.enableShadowRow}>
        <Checkbox
          value={shadowEnabled}
          onChange={handleCheck}
          checked={shadowEnabled}
          color='primary'
        />
        <Typography className={classes.enableShadow}>
          {t('contents.properties.enableShadow')}
        </Typography>
      </Grid>
      <Grid container style={{ display: shadowEnabled ? '' : 'none' }}>
        <Grid item xs={12}>
          <Grid className={classes.labelAndFieldRow}>
            <Typography>{t('contents.properties.offsetX')}</Typography>
            <TextField
              variant='standard'
              type='number'
              className={classes.input}
              value={offsetX}
              onChange={e => {
                const newOffsetX = Number(e.target.value)
                setOffsetX(newOffsetX)
                updateForm(newOffsetX, offsetY, blur, color)
              }}
            />
          </Grid>
          <Slider
            min={-100}
            max={100}
            value={offsetX}
            onChange={(e, n) => {
              const newOffsetX = Number(n)
              setOffsetX(newOffsetX)
              updateForm(newOffsetX, offsetY, blur, color)
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.labelAndFieldRow}>
            <Typography>{t('contents.properties.offsetY')}</Typography>
            <TextField
              variant='standard'
              type='number'
              className={classes.input}
              value={offsetY}
              onChange={e => {
                const newVal = Number(e.target.value)
                setOffsetY(newVal)
                updateForm(offsetX, offsetY, blur, color)
              }}
            />
          </div>

          <Slider
            min={-100}
            max={100}
            value={offsetY}
            onChange={(e, n) => {
              const newOffsetY = Number(n)
              setOffsetY(newOffsetY)
              updateForm(offsetX, newOffsetY, blur, color)
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.labelAndFieldRow}>
            <Typography>{t('contents.properties.blur')}</Typography>
            <TextField
              variant='standard'
              type='number'
              className={classes.input}
              value={blur}
              onChange={e => {
                const newBlur = Number(e.target.value)
                setBlur(newBlur)
                updateForm(offsetX, offsetY, newBlur, color)
              }}
            />
          </div>
          <Slider
            id='blur-slider'
            max={50}
            value={blur}
            onChange={(e, n) => {
              const newBlur = Number(n)
              setBlur(newBlur)
              updateForm(offsetX, offsetY, newBlur, color)
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <ContentColorPicker
              tooltip='color'
              input={{
                onChange: handleColorPickerChange,
                value: color
              }}
            />
            <Typography className={classes.shadowColor}>
              {t('contents.properties.shadowColor')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ShadowControl
