const getScreenPreviewDates = () => {
  const screenPreviewDatesString = localStorage.getItem('screenPreviewDates')
  if (typeof screenPreviewDatesString === 'string') {
    return JSON.parse(screenPreviewDatesString) as string[]
  }
  return []
}

/**
 * Save last 20 screen preview dates
 */
const saveScreenPreviewDate = (date: string) => {
  const dates = getScreenPreviewDates()
  const newDates = [date, ...dates]
  if (newDates.length > 20) {
    newDates.pop()
  }
  localStorage.setItem('screenPreviewDates', JSON.stringify(newDates))
}

export { getScreenPreviewDates, saveScreenPreviewDate }
