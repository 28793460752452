import React, { Fragment } from 'react'
import { Field } from 'redux-form'
import { ReduxTextField } from '../../../../components/FormInput/ReduxWrappers'

const SocialMedia = () => (
  <Fragment>
    <Field fullWidth name='socialMedia' label='Social media styles' component={ReduxTextField} />
  </Fragment>
)

export default SocialMedia
