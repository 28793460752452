import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Grid, MenuItem, LinearProgress, TablePagination } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Dispatch } from 'redux'
import { InfopageStore, ContentUI } from '@seesignage/seesignage-utils'
import { selectIsUserDeviceMobile, selectUser } from '../../selectors/users'
import { StateInterface } from '../../types/states'
import { UserInterface } from '../../types/users'
import {
  selectIsContentsLoading,
  sortContentsBySearch,
  selectContentsUIAsArray
} from '../../selectors/contents'
import CreateNewSign from '../../components/Signs/CreateNewSign'
import PageTitle from '../../components/PageTitle'
import { BreadcrumbContentType } from '../../types/breadcrumbs'
import { SortByValue, SortDirection } from '../../types/sortings'
import Sorter from '../../components/Sorting'
import {
  selectSelectedInfopages,
  selectInfopagesArray,
  selectHasMoreInfopages,
  selectIsInfopagesLoading,
  selectSelectedInfopageType
} from '../../selectors/infopages'
import { isContent } from '../../types/contents'
import InfopageCard, { InfopageCardOwnProps } from '../Infopages/InfopageCard/InfopageCard'
import {
  selectUnusedInfopages,
  deleteInfopage,
  deselectAllInfopages,
  listInfopages
} from '../../actions/infopages'
import { SelectedInfopage, SelectedInfopageType } from '../../types/infopages'
import useDebounce from '../../hooks/debounce'
import { selectTemplatesByType } from '../../selectors/templates'
import { listContents } from '../../actions/contents'
import { compareBySortType } from '../../utils/sorting'
import { selectEnvironmentIdFromPathname } from '../../selectors/routing'
import { DEBOUNCE_DELAY } from '../../config/constants'
import InfopagesToolbar, { InfopagesToolbarOwnProps } from './InfopagesToolbar/InfopagesToolbar'
import InfopagesInfiniteScroll from './InfopagesInfiniteScroll'

const useStyles = makeStyles(() => ({
  contentsContainer: {
    marginBottom: 100
  },
  contents: {
    padding: '10px 0px 10px'
  }
}))
interface StateProps {
  infopages: InfopageStore[]
  contents: ContentUI[]
  selectedInfopages: SelectedInfopage[]
  isMobile: boolean
  isLoading: boolean
  user?: UserInterface
  infopageType?: SelectedInfopageType
  hasMoreInfopages: boolean
  supportInfopages: boolean
  environmentId: string | undefined
}

interface DispatchProps {
  selectUnusedInfopages: (ids: SelectedInfopage[]) => void
  deleteInfopage: (ids: SelectedInfopage[]) => void
  deselectAll: () => void
  listInfopages: (params: any) => void
  listContents: (params: any) => void
}

type InfopagesProps = StateProps & DispatchProps

/**
 * Combined container component to display Contents and Infopages
 * @param param0
 */
const Infopages: React.FC<InfopagesProps> = ({
  infopageType,
  infopages,
  contents,
  deleteInfopage,
  deselectAll,
  selectedInfopages,
  isMobile,
  isLoading,
  user,
  listInfopages,
  listContents,
  hasMoreInfopages,
  supportInfopages,
  environmentId
}) => {
  const classes = useStyles()
  const [t] = useTranslation()

  const [page, setPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(25)

  const [sortBy, setSortBy] = useState<SortByValue>(SortByValue.name)
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.asc)
  const [searchTerm, setSearchTerm] = useState('')
  const searchTermDebounced = useDebounce(searchTerm, DEBOUNCE_DELAY)

  // initial load for infopages
  useEffect(() => {
    if (infopageType === SelectedInfopageType.infopage) {
      listInfopages({
        includePlaylistData: true,
        searchTerm: searchTermDebounced,
        resetSearch: true // clear infopages array, total infopages count and last evaluated key for new search
      })
    }
  }, [listInfopages, searchTermDebounced, infopageType, environmentId]) // load again if environmentId changes
  // initial load for contents
  useEffect(() => {
    if (infopageType === SelectedInfopageType.content) {
      listContents(true)
    }
  }, [infopageType, listContents, environmentId]) // load again if environmentId changes

  if (!user) {
    return <Fragment />
  }

  const visibleInfopages =
    infopageType === SelectedInfopageType.content
      ? sortContentsBySearch(contents, searchTerm)
          .sort(compareBySortType(sortBy, sortDirection))
          .slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage)
      : infopages

  const toolbarProps: InfopagesToolbarOwnProps = {
    t,
    selectedInfopages,
    deleteInfopage,
    deselectAll,
    setSearchTerm,
    infopageType,
    searchTerm,
    supportInfopages
  }
  const sorterProps = {
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    t
  }
  // use infinite scroll only for infopages
  const hasMoreToLoad = infopageType === SelectedInfopageType.content ? false : hasMoreInfopages
  return (
    <Fragment>
      <PageTitle contentType={BreadcrumbContentType.infopages} />
      <InfopagesToolbar {...toolbarProps} />
      {isLoading && <LinearProgress />}
      {visibleInfopages.length === 0 && !isLoading && searchTerm.length === 0 ? (
        <CreateNewSign label={t('contents.createNew')} />
      ) : (
        <Fragment>
          {infopageType === SelectedInfopageType.content && (
            <Sorter {...sorterProps}>
              <MenuItem value='name'>{t('sorting.sortBy.name')}</MenuItem>
              <MenuItem value='createdAt'>{t('sorting.sortBy.createdAt')}</MenuItem>
              <MenuItem value='updatedAt'>{t('sorting.sortBy.updatedAt')}</MenuItem>
            </Sorter>
          )}
        </Fragment>
      )}
      <div className={classes.contentsContainer}>
        {visibleInfopages.length > 0 && (
          <Fragment>
            <InfopagesInfiniteScroll
              listInfopages={listInfopages}
              searchTerm={searchTerm}
              infopageType={infopageType}
              hasMoreToLoad={hasMoreToLoad}
              itemsCount={visibleInfopages.length}
              scrollableTarget='main-content'
              t={t}
              hasSearchTerm={searchTerm.length > 0}>
              <Grid
                container
                className={classes.contents}
                alignItems={isMobile ? 'center' : 'flex-start'}
                justifyContent={isMobile ? 'center' : 'flex-start'}>
                {(visibleInfopages as (ContentUI | InfopageStore)[]).map(infopage => {
                  const id = isContent(infopage) ? infopage.contentId : infopage.infopageId
                  const infopageCardProps: InfopageCardOwnProps = {
                    infopage,
                    user,
                    isSelected: selectedInfopages.find(({ id: selectedId }) => selectedId === id)
                      ? true
                      : false,
                    infopagesSelected: selectedInfopages.length > 0,
                    type: isContent(infopage)
                      ? SelectedInfopageType.content
                      : SelectedInfopageType.infopage
                  }
                  return <InfopageCard key={id} {...infopageCardProps} />
                })}
              </Grid>
            </InfopagesInfiniteScroll>

            {infopageType === SelectedInfopageType.content && (
              <TablePagination
                component='div'
                count={contents.length}
                rowsPerPage={itemsPerPage}
                page={page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page'
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page'
                }}
                onPageChange={(e, page) => setPage(page)}
                onRowsPerPageChange={(e: any) => setItemsPerPage(e.target.value)}
                labelRowsPerPage={t('general.show')}
              />
            )}
          </Fragment>
        )}
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state: StateInterface): StateProps => {
  const infopageTemplates = selectTemplatesByType('info')(state)
  const supportInfopages = infopageTemplates.length > 0

  const selectedInfopageType = selectSelectedInfopageType(state)
  const initialInfopageType = supportInfopages
    ? SelectedInfopageType.infopage
    : SelectedInfopageType.content

  return {
    environmentId: selectEnvironmentIdFromPathname(state),
    // if templates exist infopageType is 'infopage' by default, otherwise content.
    supportInfopages,
    infopages: selectInfopagesArray(state),
    contents: selectContentsUIAsArray(state),
    isMobile: selectIsUserDeviceMobile(state),
    selectedInfopages: selectSelectedInfopages(state),
    user: selectUser(state),
    isLoading: selectIsContentsLoading(state) || selectIsInfopagesLoading(state),
    hasMoreInfopages: selectHasMoreInfopages(state),
    infopageType: selectedInfopageType ? selectedInfopageType : initialInfopageType
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  selectUnusedInfopages: (infopages: SelectedInfopage[]) =>
    dispatch(selectUnusedInfopages(infopages)),
  deleteInfopage: (contents: SelectedInfopage[]) => dispatch(deleteInfopage(contents)),
  deselectAll: () => dispatch(deselectAllInfopages()),
  listInfopages: (params: any) => dispatch(listInfopages(params)),
  listContents: (params: any) => dispatch(listContents(params))
})

export default connect(mapStateToProps, mapDispatchToProps)(Infopages)
