import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LinearProgress, Typography, Paper, Grid, Box, IconButton, Snackbar } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon'
import Truncate from 'react-truncate'
import { FilesUploadProgress } from '../../../types/media'
import { selectMediaUploadProgress } from '../../../selectors/media'
import { StateInterface } from '../../../types/states'
import { clearUploadProgress } from '../../../actions/media'

const useStyles = makeStyles({
  paper: {
    padding: 8,
    backgroundColor: 'white',
    width: 300,
    overflow: 'auto',
    maxHeight: 300,
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px'
  },
  titleContainer: {
    backgroundColor: 'lightGrey',
    margin: 0,
    padding: 10,
    textAlign: 'center' as any
  }
})

interface UploadProgressProps {
  progress: number
  clearItem: () => void
  error?: string
}

const UploadProgress = ({ progress, clearItem, error }: UploadProgressProps) => (
  <Box display='flex' alignItems='center'>
    <Box width='100%' mr={1}>
      {progress > 0 ? (
        <LinearProgress variant='determinate' value={progress} />
      ) : (
        <LinearProgress />
      )}
    </Box>
    <Box minWidth={35}>
      <Typography variant='body2' color='textSecondary'>{`${Math.round(progress)}%`}</Typography>
    </Box>
    {error && (
      <Box>
        <IconButton onClick={() => clearItem()} size='large'>
          <CloseCircleOutlineIcon />
        </IconButton>
      </Box>
    )}
  </Box>
)

interface StateProps {
  mediaUploading: FilesUploadProgress
}

interface DispatchProps {
  clearItem: (key: string) => void
}

type FileUploadProgressProps = StateProps & DispatchProps

/**
 * File upload progress component with upload percentages, transcoding information or error
 * @param param0
 */
const FileUploadProgress: React.FC<FileUploadProgressProps> = ({ mediaUploading, clearItem }) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const mediaUploadingArr = Object.values(mediaUploading)
  const [open, setOpen] = useState(mediaUploadingArr.length > 0)
  useEffect(() => {
    if (mediaUploadingArr.length > 0) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [mediaUploadingArr.length])
  if (mediaUploadingArr.length === 0) {
    return <Fragment />
  }
  return (
    <Snackbar
      ClickAwayListenerProps={{ mouseEvent: false }}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      open={open}
      autoHideDuration={1000 * 60 * 3}
      onClose={() => setOpen(false)}>
      <Paper className={classes.paper}>
        <Typography className={classes.titleContainer} display='block' variant='body1'>
          {t('media.uploading')}
        </Typography>
        {mediaUploadingArr.map(({ key, name, progress, isTranscoding, error }) => (
          <Grid
            container
            key={key}
            style={error ? { border: '1px red dotted', padding: 4, borderRadius: 5 } : undefined}>
            <Grid item xs={12}>
              <Truncate width={270}>
                <Typography variant='caption' display='block'>
                  {name}
                </Typography>
              </Truncate>
            </Grid>
            {isTranscoding ? (
              <div style={{ width: '100%' }}>
                <Grid item xs={12}>
                  <LinearProgress />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='caption' color='primary'>
                    {t('media.videoTranscoding')}
                  </Typography>
                </Grid>
              </div>
            ) : (
              <Grid item xs={12}>
                <UploadProgress
                  clearItem={() => clearItem(key)}
                  progress={progress}
                  error={error}
                />
                {error && (
                  <Typography variant='caption' color='error'>
                    {error}
                  </Typography>
                )}
              </Grid>
            )}
          </Grid>
        ))}
      </Paper>
    </Snackbar>
  )
}

const mapStateToProps = (state: StateInterface): StateProps => ({
  mediaUploading: selectMediaUploadProgress(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  clearItem: (key: string) => dispatch(clearUploadProgress(key))
})

export default connect(mapStateToProps, mapDispatchToProps)(FileUploadProgress)
