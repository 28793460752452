import { createSelector } from 'reselect'
import { Confirmation } from '@seesignage/seesignage-utils'
import { StateInterface, ConfirmationsByEnvironmentId } from '../types/states'

const selectConfirmationsDomain = (state: StateInterface) => state.confirmations

const selectConfirmations = createSelector(
  selectConfirmationsDomain,
  confirmations => confirmations.confirmationsByEnvironmentId
)

const selectConfirmationsByEnvironmentId = (environmentId?: string) =>
  createSelector(selectConfirmations, confirmations =>
    environmentId && confirmations[environmentId] ? confirmations[environmentId] : []
  )

const indexConfirmationsByEnvironmentId = (confirmations: Confirmation[]) =>
  confirmations.reduce((itemsByEnvironmentId: ConfirmationsByEnvironmentId, confirmation) => {
    itemsByEnvironmentId[confirmation.environmentId] =
      itemsByEnvironmentId[confirmation.environmentId] || []
    itemsByEnvironmentId[confirmation.environmentId].push(confirmation)
    return itemsByEnvironmentId
  }, {})

export {
  selectConfirmationsDomain,
  selectConfirmations,
  selectConfirmationsByEnvironmentId,
  indexConfirmationsByEnvironmentId
}
