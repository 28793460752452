import { Media, MediaQuality, MediaType, MediaFolder } from '@seesignage/seesignage-utils'
import { SubmissionError } from 'redux-form'
import axios from 'axios'
import { FileWithPreview, KeysById } from '../types/media'
import i18n from '../translations/i18n'
import MediaApi from '../services/api/media'
import { IndexById } from '../types/states'
import { isInvalidFilename, isTooLongFilename } from '../validation'

const validImageTypes = ['JPG', 'JPEG', 'PNG', 'SVG', 'TIFF', 'TIF', 'WEBP', 'AVIF']
const validVideoTypes = ['MP4', 'MOV', 'MPEG', 'AVI', 'M4V']
const pdfMimeType = 'application/pdf'
const supportedImageMimeTypes = [
  'image/jpeg',
  'image/png',
  'image/svg',
  'image/tiff',
  'image/webp',
  'image/avif',
  pdfMimeType // pdf will be generated to image
]
const supportedVideoMimeTypes = [
  'video/mpeg',
  'video/mpeg2',
  'video/avi',
  'video/mp4',
  'video/x-m4v',
  'video/quicktime' // MOV
]
const supportedMediaMimeTypes = [...supportedImageMimeTypes, ...supportedVideoMimeTypes]

/** Is invalid image or video type */
const isInvalidMediaType = (file: File) => !supportedMediaMimeTypes.includes(file.type)
const isInvalidImageType = (file: File) => !supportedImageMimeTypes.includes(file.type)
const isInvalidVideoType = (file: File) => !supportedVideoMimeTypes.includes(file.type)

const isPdf = (file: File) => file.type === pdfMimeType
const isValidVideoType = (file: File) => supportedVideoMimeTypes.includes(file.type)
const isValidImageType = (file: File) => supportedImageMimeTypes.includes(file.type)

/**
 * Validate media files and return errors in array.
 */
const validateMediaFile = (file: FileWithPreview, allowOnlyMediaType?: MediaType) => {
  const { name, type, size } = file
  const errorsInFile: string[] = []
  if (allowOnlyMediaType === MediaType.image) {
    if (isInvalidImageType(file)) {
      errorsInFile.push('invalidMediaType')
    }
  } else if (allowOnlyMediaType === MediaType.video) {
    if (isInvalidVideoType(file)) {
      errorsInFile.push('invalidMediaType')
    }
  } else if (isInvalidMediaType(file)) {
    errorsInFile.push('invalidMediaType')
  }

  if (supportedImageMimeTypes.includes(type) && size > 52428800) {
    errorsInFile.push('sizeExceeds')
  } else if (size > 2000000000) {
    errorsInFile.push('sizeExceeds')
  }
  if (isInvalidFilename(name)) {
    errorsInFile.push('invalidCharacters')
  }
  if (isTooLongFilename(name)) {
    errorsInFile.push(`tooLongFilename`)
  }
  return errorsInFile
}

/** Get Quality value from AddMediaForm data. If quality value exists in image file, return undefined.
 * Only uhd and hq are acceptable values for video quality, since fullhd is the default.
 */
const getVideoQualityFromFormData = (
  type: MediaType | 'pdf',
  formDataQuality?: MediaQuality | 'fullhd'
) => (type === 'video' ? (formDataQuality === 'fullhd' ? undefined : formDataQuality) : undefined)

/** Get media url and thumbnail url by Media */
const getUrlForMedia = async (media: Media) => {
  if (media.url) {
    // url already exists */
    return media
  } else {
    const { key } = media
    const { url, thumbnailUrl } = await MediaApi.getMediaUrl(key, true)
    return {
      ...media,
      url,
      thumbnailUrl
    }
  }
}

const getUrlsForMedia = async (mediaFiles: Media[], checkExists?: boolean) => {
  if (mediaFiles.length > 0) {
    const mediaFilesWithoutUrl = mediaFiles.filter(({ url }) => url === undefined)
    const keysWithoutUrl = mediaFilesWithoutUrl.map(({ key }) => key)
    const mediaUrlsByKey = await MediaApi.getMediaUrlsByKey(keysWithoutUrl, true, checkExists)
    const mediaFilesByKeyWithUrls = mediaFilesWithoutUrl.reduce((keysById, media) => {
      const { url, thumbnailUrl } = mediaUrlsByKey[media.key]
      keysById[media.key] = {
        ...media,
        url,
        thumbnailUrl
      }
      return keysById
    }, {} as KeysById)
    return mediaFilesByKeyWithUrls
  }
  return {}
}

const getMediaByKeyAndUrlIfExists = (exisingMediaByKey: KeysById, newMediaByKey: KeysById) =>
  Object.values(newMediaByKey).reduce((keysById, newMedia) => {
    // Use existing media url if it exists
    if (!newMedia.url && exisingMediaByKey[newMedia.key]?.url) {
      const { url, thumbnailUrl } = exisingMediaByKey[newMedia.key]
      keysById[newMedia.key] = {
        ...newMedia,
        url,
        thumbnailUrl
      }
    }

    return keysById
  }, newMediaByKey)

/**
 * Get media type from S3 key.
 * S3 key is in format such as:
 * environments/fe0e5877-87be-4ffe-9120-f172a3164431/images/c0400b44-b419-465c-9cae-bdf0d2847022.jpg
 */
const getMediaTypeFromKey = (key: string) => {
  const splittedKey = key.split('/')
  if (splittedKey[2] === 'videos') {
    return MediaType.video
  } else if (splittedKey[2] === 'images') {
    return MediaType.image
  } else if (key.startsWith('common')) {
    return MediaType.image
  }
  return undefined
}

const isVideoKey = (key: string) => getMediaTypeFromKey(key) === MediaType.video

const convertBytesToGB = (size: number) => size / 1024 / 1024 / 1024

const convertGBToBytes = (size: number) => size * 1024 * 1024 * 1024

/** check if there is enough space to download the file */
const validateFileSizeInStorage = (
  usedStorageInBytes: number,
  fileSizeInBytes: number,
  environmentStorageSizeInGB: number
) => {
  if (usedStorageInBytes + fileSizeInBytes > convertGBToBytes(environmentStorageSizeInGB)) {
    const storageFullErrorMessage = i18n.t('error.media.storageFull')
    throw new SubmissionError({
      _error: storageFullErrorMessage
    })
  }
}

const getMediaTypeFromInputFile = (file: FileWithPreview | File) => {
  if (isPdf(file)) {
    return 'pdf'
  } else if (isValidVideoType(file)) {
    return MediaType.video
  } else if (isValidImageType(file)) {
    return MediaType.image
  }
}

const getFileTypeFromFilename = (filename: string) => {
  let fileExtension = filename.split('.').pop()
  fileExtension = fileExtension ? fileExtension.toUpperCase() : ''
  if (validImageTypes.includes(fileExtension)) {
    return MediaType.image
  }
  if (validVideoTypes.includes(fileExtension)) {
    return MediaType.video
  }
  if (fileExtension === 'PDF') {
    return 'pdf'
  }
}

/** Calculate total size of all provided media files in bytes */
const calculateTotalStorageSize = (mediaFiles: IndexById<Media>) =>
  Object.values(mediaFiles).reduce((total: number, file) => {
    const { size } = file
    total += size ? size : 0
    return total
  }, 0)

/**
 * Get array path of folder hierarchy (top to bottom)
 * @param folders all folders
 * @param folderId starting folder
 * @example
 * const root = { ...folderProps, folderId: 'root-id' }
 * const folder1 = { ...folderProps, folderId: 'folder1-id', parentFolderId: 'root-id' }
 * const folder2 = { ...folderProps, folderId: 'folder2-id', parentFolderId: 'folder1-id' }
 * const folder3 = { ...folderProps, folderId: 'folder3-id', parentFolderId: 'folder2-id' }
 * const folders = {
 * [root-id]: root,
 * [folder1-id]: folder1
 * [folder2-id]: folder2
 * [folder3-id]: folder3
 *  }
 * const idPath = getFoldersPathArray(folders, 'folder3-id')
 * // =>
 * [{ id: 'root-id', name: 'root' }, { id: 'folder1-id', name: 'folder1'}, { id: 'folder2-id', name: 'folder2' }, { id: 'folder3-id', name: 'folder3' }]
 */
const getFoldersPathArray = (folders: IndexById<MediaFolder>, folderId?: string) => {
  if (!folderId || !folders[folderId]) {
    return []
  }
  let currentFolder = folders[folderId]
  const folderPath = [{ name: currentFolder?.name, id: currentFolder?.folderId }]
  while (currentFolder?.parentFolderId) {
    currentFolder = folders[currentFolder.parentFolderId]
    folderPath.unshift({ name: currentFolder.name, id: currentFolder.folderId })
  }
  return folderPath
}

const downloadMedia = async (name: string, url: string) => {
  const response = await axios.get(url, {
    responseType: 'blob',
    headers: {
      'Cache-Control': 'no-cache'
    }
  })
  const u = URL.createObjectURL(response.data)
  const anchorEl = document.createElement('a')
  anchorEl.href = u
  anchorEl.download = `${name}`
  anchorEl.target = '_blank'
  document.body.appendChild(anchorEl) // required for firefox
  anchorEl.click()
  document.body.removeChild(anchorEl)
}

const getMediaNameAndExtension = (filename: string) => {
  const nameArray = filename.split('.')
  const extension = nameArray.pop()
  const name = nameArray.join()
  return {
    name,
    extension
  }
}

export {
  isInvalidMediaType,
  validImageTypes,
  validVideoTypes,
  validateMediaFile,
  isPdf,
  isValidVideoType,
  isValidImageType,
  supportedImageMimeTypes,
  supportedVideoMimeTypes,
  supportedMediaMimeTypes,
  pdfMimeType,
  getVideoQualityFromFormData,
  getUrlForMedia,
  getUrlsForMedia,
  getMediaByKeyAndUrlIfExists,
  getMediaTypeFromKey,
  convertBytesToGB,
  convertGBToBytes,
  validateFileSizeInStorage,
  getMediaTypeFromInputFile,
  getFileTypeFromFilename,
  calculateTotalStorageSize,
  isVideoKey,
  getFoldersPathArray,
  downloadMedia,
  getMediaNameAndExtension
}
