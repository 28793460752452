import { createSelector } from 'reselect'
import { ConnectionState } from '@aws-amplify/pubsub'
import { StateInterface } from '../types/states'

const selectIotDomain = (state: StateInterface) => state.iot

const selectIntegrationsIotData = createSelector(selectIotDomain, iot => iot.iotData)
const selectIotConnectionState = createSelector(selectIotDomain, iot => iot.connectionState)

const selectIsIotError = createSelector(selectIotConnectionState, connectionState => {
  if (
    // The connection is disrupted and the network connection is unavailable.
    connectionState === ConnectionState.ConnectionDisruptedPendingNetwork
  ) {
    return true
  }
  return false
})

export { selectIntegrationsIotData, selectIsIotError }
