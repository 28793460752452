import { Grid } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { MediaPlaylistItemUI, VideoPlaylistItemUI } from '@seesignage/seesignage-utils'
import { PlaylistItemCardRootProps } from '../../../../../types/playlists'
import Media from '../../../../../components/Media'
import { StateInterface } from '../../../../../types/states'
import { selectVideoIsTranscoding } from '../../../../../selectors/media'
import SelectedIndicator from './SelectedIndicator'
import ItemTitle from './ItemTitle'
import ItemIcons from './ItemIcons'

const styles = () => ({
  dialogActions: {
    float: 'right' as any
  },
  titleText: {
    width: '75%',
    padding: '4px',
    float: 'left' as any,
    textAlign: 'left' as any,
    overflow: 'hidden'
  },
  item: {
    height: 140,
    overflow: 'hidden'
  },
  media: {
    width: '100%',
    height: '100%',
    objectFit: 'contain' as any
  },
  itemMediaArea: {
    height: '100%'
  },
  itemDescriptionArea: {
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)'
  },
  itemFileIcon: {
    float: 'left' as any,
    padding: '0px 4px 0px 4px'
  },
  itemMediaText: {
    padding: '4px',
    width: '100%'
  }
})

interface OwnProps extends PlaylistItemCardRootProps {
  item: MediaPlaylistItemUI
}

interface StateProps {
  isItemTranscoding: boolean
}

type MediaAsItemProps = OwnProps & StateProps & WithTranslation & WithStyles<typeof styles>

const MediaAsItem: React.FC<MediaAsItemProps> = ({
  classes,
  t,
  item,
  isItemSelected,
  isItemTranscoding,
  itemPercentage,
  showPercentage
}) => {
  const { name, url, type, thumbnailUrl } = item
  const { quality } = item as VideoPlaylistItemUI
  return (
    <Grid container className={classes.item}>
      <Grid item xs={4} className={classes.itemMediaArea}>
        <Media
          imageClass={classes.media}
          videoClass={classes.media}
          type={type}
          url={url}
          thumbnailUrl={thumbnailUrl}
        />
      </Grid>
      <Grid item xs={8} className={classes.itemDescriptionArea}>
        <Grid container style={{ height: '100%' }}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={11}>
                <ItemTitle
                  containerClass={classes.itemMediaText}
                  fileIconClass={classes.itemFileIcon}
                  mediaTextClass={classes.titleText}
                  name={name}
                  type={type}
                  quality={quality}
                  isItemTranscoding={isItemTranscoding}
                  t={t}
                />
              </Grid>
              <SelectedIndicator
                isItemSelected={isItemSelected}
                showPercentage={showPercentage}
                itemPercentage={itemPercentage}
              />
            </Grid>
          </Grid>
        </Grid>
        <ItemIcons item={item} />
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state: StateInterface, ownProps: OwnProps): StateProps => ({
  isItemTranscoding: selectVideoIsTranscoding(ownProps.item.key)(state)
})

export default connect(mapStateToProps)(withStyles(styles)(withTranslation()(MediaAsItem)))
