import React, { useEffect, Fragment } from 'react'
import {
  reduxForm,
  InjectedFormProps,
  Field,
  FieldArray,
  formValueSelector,
  change
} from 'redux-form'
import { Grid, DialogActions, Button, LinearProgress } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import PlaylistPlayIcon from 'mdi-react/PlaylistPlayIcon'
import { useTranslation } from 'react-i18next'
import { ObjectType, Media, AutocompleteOption } from '@seesignage/seesignage-utils'

import { listPlaylists as listPlaylistsAction } from '../../../../actions/playlists'
import { ReduxSelectWithAutocomplete } from '../../../../components/FormInput/ReduxWrappers'
import {
  selectPlaylistsAsOptions,
  selectAllPlaylistsAsArray,
  selectPlaylistById,
  selectListPlaylistsIsLoading
} from '../../../../selectors/playlists'
import { MediaCarouselWizardFormData, MediaCarouselWizardView } from '../../../../types/contents'
import { compareStrings } from '../../../../utils/sorting'
import { maxItems, required } from '../../../../validation'
import RevolverItemsField from '../../../Playlists/Forms/Revolver/RevolverItemsField'
import { createObject, updateSelectedWidget } from '../../../../actions/contents'
import ErrorMessage from '../../../../components/Errors/ErrorMessage'

import { selectEnvironmentIdFromPathname } from '../../../../selectors/routing'

const formSelector = formValueSelector('MediaCarouselForm')

interface OwnProps {
  isUpdateMediaCarousel: boolean
  view: MediaCarouselWizardView
  closeDialog: () => void
  setView: React.Dispatch<React.SetStateAction<MediaCarouselWizardView>>
}

type FormProps = MediaCarouselWizardFormData

type MediaCarouselProps = OwnProps

const MediaCarouselForm: React.FC<MediaCarouselProps &
  InjectedFormProps<FormProps, MediaCarouselProps>> = ({
  handleSubmit,
  submitting,
  isUpdateMediaCarousel,
  closeDialog,
  view,
  setView
}) => {
  // const classes = useStyles()
  const dispatch = useDispatch()
  const [t] = useTranslation()

  const playlistId: AutocompleteOption | undefined = useSelector(state =>
    formSelector(state, 'playlistId')
  )

  const carouselItems: Media[] | undefined = useSelector(state =>
    formSelector(state, 'carouselItems')
  )

  const playlists = useSelector(selectAllPlaylistsAsArray).sort(compareStrings('name'))
  const selectedPlaylist = useSelector(selectPlaylistById(playlistId?.value))
  const isPlaylistsLoading = useSelector(selectListPlaylistsIsLoading)
  const environmentId = useSelector(selectEnvironmentIdFromPathname)

  useEffect(() => {
    if (view === MediaCarouselWizardView.playlist) {
      dispatch(listPlaylistsAction({ includeParentPlaylists: true }))
    }
  }, [dispatch, view])

  useEffect(() => {
    dispatch(change('MediaCarouselForm', 'environmentId', selectedPlaylist?.environmentId))
    dispatch(change('MediaCarouselForm', 'defaultInterval', selectedPlaylist?.defaultInterval))
  }, [dispatch, selectedPlaylist])

  const onSubmit = (formData: MediaCarouselWizardFormData) => {
    if (isUpdateMediaCarousel) {
      dispatch(updateSelectedWidget({ type: ObjectType.CustomMediaCarousel, props: formData }))
    } else {
      dispatch(createObject({ type: ObjectType.CustomMediaCarousel, props: formData }))
    }
    closeDialog()
  }

  const isSubmitDisabled =
    submitting || view === MediaCarouselWizardView.playlist
      ? !selectedPlaylist
      : carouselItems?.length === 0

  // if selected playlist is not found. show user that playlist has been deleted
  const isPlaylistDeleted = !selectedPlaylist && playlistId?.value && !isPlaylistsLoading

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {view === MediaCarouselWizardView.playlist && (
            <Fragment>
              {isPlaylistDeleted && (
                <ErrorMessage
                  message={t('contents.widgets.mediaCarousel.selectedPlaylistDeleted')}
                />
              )}
              <Field
                name='playlistId'
                placeholder={t('screens.selectPlaylist')}
                label={t('screens.content.playlist')}
                required
                validate={[required]}
                options={selectPlaylistsAsOptions(playlists, environmentId)}
                component={ReduxSelectWithAutocomplete}
                OptionIcon={PlaylistPlayIcon}
              />
              {isPlaylistsLoading && <LinearProgress />}
            </Fragment>
          )}
          {view === MediaCarouselWizardView.media && (
            <FieldArray
              name='carouselItems'
              component={RevolverItemsField}
              validate={[maxItems(100)]}
              props={{}}
            />
          )}
        </Grid>
      </Grid>
      <DialogActions>
        {// After you have created media carousel you you should not be able to go back to change it's type.
        !isUpdateMediaCarousel && (
          <Button
            color='primary'
            onClick={() => {
              setView(MediaCarouselWizardView.selectType)
            }}>
            {t('general.back')}
          </Button>
        )}
        <Button disabled={isSubmitDisabled} color='primary' type='submit' variant='contained'>
          {isUpdateMediaCarousel ? t('general.update') : t('general.create')}
        </Button>
      </DialogActions>
    </form>
  )
}

export default reduxForm<FormProps, MediaCarouselProps>({
  form: 'MediaCarouselForm'
})(MediaCarouselForm)
