import { Accept } from 'react-dropzone'
import { MediaType } from '@seesignage/seesignage-utils'
import { supportedMediaMimeTypes, supportedImageMimeTypes, supportedVideoMimeTypes } from './media'

const getDropzoneAccept = (mimeTypes: string[]) =>
  mimeTypes.reduce((acceptFiles, mimeType) => {
    acceptFiles[mimeType] = []
    return acceptFiles
  }, {} as { [key: string]: string[] })

// see: https://react-dropzone.js.org/#section-accepting-specific-file-types
const dropzoneAcceptMediaFiles = {
  ...getDropzoneAccept(supportedMediaMimeTypes)
} as Accept

const dropzoneAcceptImageFiles = {
  ...getDropzoneAccept(supportedImageMimeTypes)
} as Accept

const dropzoneAcceptVideoFiles = {
  ...getDropzoneAccept(supportedVideoMimeTypes)
} as Accept

const dropzoneAcceptCsvFiles = {
  ...getDropzoneAccept(['text/csv'])
} as Accept

const getAcceptedMediaTypes = (mediaType?: MediaType) => {
  if (mediaType) {
    return mediaType === MediaType.video ? dropzoneAcceptVideoFiles : dropzoneAcceptImageFiles
  }
  return dropzoneAcceptMediaFiles
}

export {
  dropzoneAcceptMediaFiles,
  dropzoneAcceptImageFiles,
  dropzoneAcceptVideoFiles,
  dropzoneAcceptCsvFiles,
  getAcceptedMediaTypes
}
