import { handleActions } from 'redux-actions'
import { clearTour, forceRunTour, runTour, setTourStatus } from '../actions/tours'
import { Action } from '../types/actions'
import { ToursState } from '../types/states'
import { Tour } from '../types/tours'
import { shouldTourRun, storeTourStatus } from '../services/localStorage/tours'

const initialState: ToursState = {
  currentTour: undefined
}

const customers = handleActions<ToursState, any>(
  {
    [forceRunTour]: (state, { payload: tour }: Action<Tour>) => ({
      ...state,
      currentTour: tour
    }),
    [runTour]: (state, { payload: tour }: Action<Tour>) => ({
      ...state,
      currentTour: shouldTourRun(tour) ? tour : undefined
    }),
    [clearTour]: state => ({
      ...state,
      currentTour: undefined
    }),
    [setTourStatus]: (
      state,
      { payload: { tour, status } }: Action<{ tour: Tour; status: string }>
    ) => {
      storeTourStatus(tour, status)
      return {
        ...state
      }
    }
  },
  initialState
)

export default customers
