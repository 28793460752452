import React from 'react'
import { Tooltip } from '@mui/material'
import SignalLowIcon from 'mdi-react/SignalCellular1Icon'
import SignalMedIcon from 'mdi-react/SignalCellular2Icon'
import SignalHiIcon from 'mdi-react/SignalCellular3Icon'
import SignalOff from 'mdi-react/SignalOffIcon'
import { withTranslation, WithTranslation } from 'react-i18next'

interface SignalStrengthProps extends WithTranslation {
  signalStrength?: number
}

const SignalStrength = ({ signalStrength, t }: SignalStrengthProps) => {
  if (signalStrength) {
    if (signalStrength >= 0.7) {
      return (
        <Tooltip disableInteractive title={t('screens.wifiConnected')}>
          <span>
            <SignalHiIcon />
          </span>
        </Tooltip>
      )
    }
    if (signalStrength > 0.3) {
      return (
        <Tooltip disableInteractive title={t('screens.wifiConnected')}>
          <span>
            <SignalMedIcon />
          </span>
        </Tooltip>
      )
    }
    if (signalStrength > 0.0) {
      return (
        <Tooltip disableInteractive title={t('screens.wifiConnected')}>
          <span>
            <SignalLowIcon />
          </span>
        </Tooltip>
      )
    }
  }
  return (
    <Tooltip disableInteractive title={t('screens.wifiOffline')}>
      <span>
        <SignalOff />
      </span>
    </Tooltip>
  )
}

export default withTranslation()(SignalStrength)
