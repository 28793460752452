import { Button, DialogActions, Grid, Typography, Paper, IconButton, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Field,
  reduxForm,
  InjectedFormProps,
  FieldArray,
  WrappedFieldArrayProps,
  formValueSelector,
  change
} from 'redux-form'
import { connect, useDispatch } from 'react-redux'
import DeleteIcon from 'mdi-react/DeleteIcon'
import { LogicalOperator } from '@seesignage/seesignage-utils'
import { TFunction } from 'i18next'
import { CloseDialog } from '../../../../types/actions'
import { StateInterface } from '../../../../types/states'
import { ReduxTagSelector } from '../../../../components/FormInput/ReduxWrappers'
import ReduxLogicalOperatorSelector from '../../../../components/FormInput/ReduxWrappers/ReduxLogicalOperatorSelector'
import { selectCurrentEnvironmentTags } from '../../../../selectors/environments'
import ReduxNotOperatorSelector from '../../../../components/FormInput/ReduxWrappers/ReduxNotOperatorSelector'
import { isNotEmptyList } from '../../../../validation'

const useStyles = makeStyles(() => ({
  tagGroupSplitter: {
    fontSize: '20px',
    fontWeight: 500,
    textAlign: 'center'
  },
  explanation: {
    margin: '8px 0px 12px',
    fontSize: '12px'
  },
  tagGroupExplanation: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.7)',
    marginTop: 8
  },
  tagGroupCard: {
    padding: 10,
    margin: '10px 0px 10px 0px'
  },
  tagGroupTitle: {
    fontWeight: 500
  },
  tagsSelector: {
    marginTop: 15
  },
  logicalOperatorSelector: {
    display: 'inline-block',
    marginRight: 10
  }
}))

const getTagConditionExplanation = ({
  t,
  logicalOperator,
  not
}: {
  t: TFunction
  logicalOperator: LogicalOperator
  not?: boolean
}) => {
  if (logicalOperator === LogicalOperator.or) {
    if (not === true) {
      return t('playlists.editPlaylist.orOperatorNotExplanation')
    }
    return t('playlists.editPlaylist.orOperatorExplanation')
  }
  if (logicalOperator === LogicalOperator.and) {
    if (not === true) {
      return t('playlists.editPlaylist.andOperatorNotExplanation')
    }
    return t('playlists.editPlaylist.andOperatorExplanation')
  }
  return ''
}

interface TagFieldsProps extends WrappedFieldArrayProps {
  t: TFunction
  existingTags: string[]
  getTagGroupLogicalOperator: (index: number) => LogicalOperator
  getTagGroupNot: (index: number) => boolean | undefined
}

const TagGroups = ({
  fields,
  existingTags,
  getTagGroupLogicalOperator,
  getTagGroupNot,
  t
}: TagFieldsProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isFirstTagGroupValuesEmpty = fields.length === 1 && fields.get(0).values.length === 0
  return (
    <Grid container>
      {fields.map((tagGroup, index) => (
        <Grid item xs={12} key={`tagGroup-${index}`}>
          {index > 0 && <Typography className={classes.tagGroupSplitter}>+</Typography>}
          <Paper elevation={3} className={classes.tagGroupCard}>
            <Grid container>
              <Grid item xs={9}>
                <Typography variant='body1' className={classes.tagGroupTitle}>
                  {t('playlists.editPlaylist.tagGroup', { index: index + 1 })}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Tooltip
                  disableInteractive
                  title={t('playlists.editPlaylist.removeTagGroup')}
                  placement='top-start'>
                  <span>
                    <IconButton
                      onClick={() => {
                        if (index === 0) {
                          // we do not want to remove first tag condition. Just empty it's tagGroup tag values.
                          dispatch(change('addItemTagsForm', `${tagGroup}.values`, []))
                        } else {
                          fields.remove(index)
                        }
                      }}
                      aria-label={t('playlists.editPlaylist.removeTagGroup')}
                      style={{ float: 'right' }}
                      disabled={isFirstTagGroupValuesEmpty}
                      size='large'>
                      <DeleteIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.logicalOperatorSelector}>
                  <Field name={`${tagGroup}.not`} select component={ReduxNotOperatorSelector} />
                </div>
                <div className={classes.logicalOperatorSelector}>
                  <Field
                    name={`${tagGroup}.operator`}
                    select
                    component={ReduxLogicalOperatorSelector}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.tagGroupExplanation}>
                  {getTagConditionExplanation({
                    t,
                    logicalOperator: getTagGroupLogicalOperator(index),
                    not: getTagGroupNot(index)
                  })}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.tagsSelector}>
                <Field
                  name={`${tagGroup}.values`}
                  component={ReduxTagSelector}
                  label={t('playlists.editPlaylist.tags')}
                  multiple
                  options={existingTags}
                  required={!isFirstTagGroupValuesEmpty}
                  validate={isFirstTagGroupValuesEmpty ? [] : [isNotEmptyList]}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Button
          variant='contained'
          color='primary'
          fullWidth
          onClick={() =>
            fields.push({
              operator: LogicalOperator.or,
              not: false,
              values: []
            })
          }>
          {t('playlists.editPlaylist.addTagGroup')}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='body2' className={classes.explanation}>
          {t('playlists.editPlaylist.tagGroupExplanation')}
        </Typography>
      </Grid>
    </Grid>
  )
}

interface StateProps {
  getTagGroupLogicalOperator: (index: number) => LogicalOperator
  getTagGroupNot: (index: number) => boolean | undefined
  existingTags: string[]
}

interface OwnProps {
  submitAction: (formData: any) => void
  closeDialog: CloseDialog
  dialogId: string
  submitButtonLabel: string
  existingTags: string[]
}

type AddItemTagsFormProps = StateProps & OwnProps

const AddItemTagsForm: React.FC<AddItemTagsFormProps &
  InjectedFormProps<{}, AddItemTagsFormProps>> = ({
  submitAction,
  handleSubmit,
  submitting,
  closeDialog,
  dialogId,
  submitButtonLabel,
  existingTags,
  getTagGroupLogicalOperator,
  getTagGroupNot
}) => {
  const [t] = useTranslation()
  return (
    <form onSubmit={handleSubmit(submitAction)}>
      <FieldArray
        name='tags'
        component={TagGroups}
        props={{ existingTags, t, getTagGroupLogicalOperator, getTagGroupNot }}
      />
      <DialogActions>
        <Button onClick={() => closeDialog(dialogId)} color='primary'>
          {t('general.cancel')}
        </Button>
        <Button disabled={submitting} color='primary' type='submit'>
          {submitButtonLabel}
        </Button>
      </DialogActions>
    </form>
  )
}

const selector = formValueSelector('addItemTagsForm')

const mapStateToProps = (state: StateInterface): StateProps => ({
  getTagGroupLogicalOperator: (index: number) => selector(state, `tags[${index}].operator`),
  getTagGroupNot: (index: number) => selector(state, `tags[${index}].not`),
  existingTags: selectCurrentEnvironmentTags(state) || []
})

export default connect(mapStateToProps)(
  reduxForm<{}, AddItemTagsFormProps>({
    form: 'addItemTagsForm'
  })(AddItemTagsForm)
)
