import React, { Fragment } from 'react'
import { path } from 'ramda'
import { Status, ScreenNotes, ScreenContent } from '@seesignage/seesignage-utils'
import { TFunction } from 'i18next'
import { Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import AlarmIcon from 'mdi-react/AlarmIcon'
import EyeOffIcon from 'mdi-react/EyeOffIcon'
import MessageBulletedIcon from 'mdi-react/MessageBulletedIcon'
import SyncIcon from 'mdi-react/RayStartVertexEndIcon'
import ChartLineIcon from 'mdi-react/ChartLineIcon'
import ShuffleVariantIcon from 'mdi-react/ShuffleVariantIcon'

import Connection from '../../../components/Network/Connection'
import { inlineBlockStyle } from '../../../styles/common/styles'
import colors from '../../../styles/common/colors'

const useStyles = makeStyles({
  inlineBlockStyle,
  colors
})
interface ScreenCardIconsProps {
  t: TFunction
  status: Status
  hasOnOffTimer: boolean
  isTest?: boolean
  syncPlay?: boolean
  proofOfPlay?: boolean
  notes?: ScreenNotes
  content?: ScreenContent
}

const noteHintLimit = 200
const getNoteHint = (type: string, note: string) =>
  `${type}: ${note.substring(0, noteHintLimit)}${note.length > noteHintLimit ? '...' : ''}`

const ScreenCardIcons = ({
  notes,
  status,
  hasOnOffTimer,
  isTest,
  syncPlay,
  proofOfPlay,
  content,
  t
}: ScreenCardIconsProps) => {
  const classes = useStyles()
  return (
    <Fragment>
      <Connection
        t={t}
        signalStrength={path(['wifiNetwork', 'signalStrength'], status)}
        isEthernetConnected={path(['ethernetNetwork', 'status'], status) === 'CONNECTED'}
      />
      {hasOnOffTimer && (
        <Tooltip disableInteractive title={t('screens.hasOnOffTimer')}>
          <span>
            <AlarmIcon className={classes.inlineBlockStyle} />
          </span>
        </Tooltip>
      )}
      {isTest && (
        <Tooltip disableInteractive title={t('screens.isTest')}>
          <span>
            <EyeOffIcon className={classes.inlineBlockStyle} />
          </span>
        </Tooltip>
      )}
      {syncPlay && (
        <Tooltip disableInteractive title={t('screens.syncPlayEnabled')}>
          <span>
            <SyncIcon />
          </span>
        </Tooltip>
      )}
      {proofOfPlay && (
        <Tooltip disableInteractive title={t('screens.proofOfPlayEnabled')}>
          <span>
            <ChartLineIcon />
          </span>
        </Tooltip>
      )}
      {content?.interleave && (
        <Tooltip disableInteractive title={t('screens.interleaveContentEnabled')}>
          <span>
            <ShuffleVariantIcon />
          </span>
        </Tooltip>
      )}
      {notes?.systemAdmin && (
        <Tooltip
          disableInteractive
          title={getNoteHint(t('screens.createScreenForm.notesForSysAdmin'), notes.systemAdmin)}>
          <span>
            <MessageBulletedIcon
              className={classes.inlineBlockStyle}
              color={colors.notifications.systemAdminColor}
            />
          </span>
        </Tooltip>
      )}
      {notes?.environmentAdmin && (
        <Tooltip
          disableInteractive
          title={getNoteHint(
            t('screens.createScreenForm.notesForEnvAdmin'),
            notes.environmentAdmin
          )}>
          <span>
            <MessageBulletedIcon
              className={classes.inlineBlockStyle}
              color={colors.notifications.environmentAdminColor}
            />
          </span>
        </Tooltip>
      )}
    </Fragment>
  )
}

export default ScreenCardIcons
