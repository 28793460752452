import React, { Fragment, useState, useEffect, useCallback } from 'react'
import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  TablePagination,
  AppBar,
  Toolbar,
  LinearProgress,
  Button
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon'
import { WrappedFieldProps } from 'redux-form'
import { TFunction } from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ContentUI, InfopageStore, Infopage } from '@seesignage/seesignage-utils'
import { isContent } from '../../../types/contents'
import { SelectedInfopageType } from '../../../types/infopages'
import {
  selectInfopagesArray,
  selectSelectedInfopageType,
  selectHasMoreInfopages,
  selectIsInfopagesLoading
} from '../../../selectors/infopages'
import {
  selectContentsAsArray,
  selectIsContentsLoading,
  sortContentsBySearch
} from '../../../selectors/contents'
import { selectTemplatesByType } from '../../../selectors/templates'
import InfopagesInfiniteScroll from '../../../containers/Infopages/InfopagesInfiniteScroll'
import InfopagesSearchField, {
  InfopagesSearchFieldProps
} from '../../../containers/Infopages/InfopagesToolbar/InfopagesSearchField'
import { listInfopages } from '../../../actions/infopages'
import useDebounce from '../../../hooks/debounce'
import ErrorMessage from '../../Errors/ErrorMessage'
import { compareBySortType } from '../../../utils/sorting'
import { SortByValue, SortDirection } from '../../../types/sortings'
import { DEBOUNCE_DELAY } from '../../../config/constants'
import { PageType } from '../../../types/playlists'
import { AddButton } from '../../Buttons'

const useStyles = makeStyles(() => ({
  appBar: {
    marginTop: 8
  },
  gridContainer: {
    paddingTop: 8
  },
  imageList: { width: '100%' },
  imageListItem: {
    cursor: 'pointer',
    // overwrite material-ui's list item's image elment
    '& .MuiImageListItem-img': {
      display: 'block',
      margin: 'auto',
      height: '100%',
      width: '100%',
      objectFit: 'contain'
    }
  }
}))

interface SelectInfopageProps extends WrappedFieldProps {
  label: string
  isMobile: boolean
  t: TFunction
  infopageSelected?: boolean
  selectPage: (page: PageType) => void
}

const SelectInfopage: React.FC<SelectInfopageProps> = ({
  input,
  isMobile,
  t,
  meta,
  infopageSelected,
  selectPage
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [itemsPerPage, setItemsPerPage] = useState(20)
  const [page, setPage] = React.useState(0)
  const [searchTerm, setSearchTerm] = React.useState('')
  const searchTermDebounced = useDebounce(searchTerm, DEBOUNCE_DELAY)

  const isContentsLoading = useSelector(selectIsContentsLoading)
  const isInfopagesLoading = useSelector(selectIsInfopagesLoading)
  const isLoading = isContentsLoading || isInfopagesLoading
  const infopages = useSelector(selectInfopagesArray)
  const contents = useSelector(selectContentsAsArray)
  const infopageTemplates = useSelector(selectTemplatesByType('info'))
  const supportInfopages = infopageTemplates.length > 0
  const selectedInfopageType = useSelector(selectSelectedInfopageType)
  const hasMoreInfopages = useSelector(selectHasMoreInfopages)
  const initialInfopageType = supportInfopages
    ? SelectedInfopageType.infopage
    : SelectedInfopageType.content
  const infopageType = selectedInfopageType ? selectedInfopageType : initialInfopageType

  const getInfopages = useCallback(
    (params: any) => {
      dispatch(listInfopages(params))
    },
    [dispatch]
  )

  // initial load for infopages
  useEffect(() => {
    if (infopageType === SelectedInfopageType.infopage) {
      getInfopages({
        includePlaylistData: true,
        searchTerm: searchTermDebounced,
        resetSearch: true // clear infopages array, total infopages count and last evaluated key for new search
      })
    }
  }, [getInfopages, searchTermDebounced, infopageType])

  const selectedInfopage = input.value // TODO type and fix
  const currentInfopage = selectedInfopage
    ? infopageType === SelectedInfopageType.content
      ? contents.find(item => item.contentId === selectedInfopage.id)
      : infopages.find(item => item.infopageId === selectedInfopage.id)
    : undefined

  const visibleInfopages =
    infopageType === SelectedInfopageType.content
      ? sortContentsBySearch(contents, searchTerm)
          .sort(compareBySortType(SortByValue.name, SortDirection.asc))
          .slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage)
      : infopages

  // use infinite scroll only for infopages
  const hasMoreToLoad = infopageType === SelectedInfopageType.content ? false : hasMoreInfopages

  const infopagesSearchFieldProps: InfopagesSearchFieldProps = {
    setSearchTerm,
    infopageType,
    searchTerm,
    supportInfopages
  }

  const { touched, error } = meta

  return (
    <Fragment>
      {currentInfopage ? (
        <ImageList rowHeight={188} className={classes.imageList}>
          <ImageListItem className={classes.imageListItem}>
            <img src={(currentInfopage as ContentUI | Infopage).thumbnailUrl} alt='' />
            <ImageListItemBar
              title={currentInfopage.name}
              position='top'
              actionIcon={
                <IconButton
                  onClick={() => {
                    input.onChange(undefined)
                  }}
                  size='large'>
                  <CloseCircleOutlineIcon color='white' />
                </IconButton>
              }
            />
          </ImageListItem>
        </ImageList>
      ) : (
        <Fragment>
          <AppBar className={classes.appBar} position='sticky' color='default'>
            <Toolbar>
              <InfopagesSearchField {...infopagesSearchFieldProps} />
              {!infopageSelected && (
                <Fragment>
                  {isMobile ? (
                    <AddButton
                      tooltipTitle={t('general.createNew')}
                      onClick={() => selectPage(PageType.newInfopage)}
                    />
                  ) : (
                    <Button
                      color='primary'
                      variant='contained'
                      onClick={() => selectPage(PageType.newInfopage)}>
                      {t('general.createNew')}
                    </Button>
                  )}
                </Fragment>
              )}
            </Toolbar>
          </AppBar>
          {isLoading && <LinearProgress />}
          <div className={classes.gridContainer}>
            {visibleInfopages.length > 0 && (
              <InfopagesInfiniteScroll
                listInfopages={getInfopages}
                searchTerm={searchTerm}
                infopageType={infopageType}
                hasMoreToLoad={hasMoreToLoad}
                itemsCount={visibleInfopages.length}
                scrollableTarget='infopages-container'
                t={t}
                height={isMobile ? 400 : 500}
                hasSearchTerm={searchTerm.length > 0}>
                <ImageList
                  className={classes.imageList}
                  rowHeight={isMobile ? 100 : 188}
                  cols={isMobile ? 2 : 3}
                  style={
                    infopageType === SelectedInfopageType.content
                      ? { height: isMobile ? 300 : 500 }
                      : undefined
                  }
                  id='infopages-container'>
                  {(visibleInfopages as (ContentUI | InfopageStore)[]).map(item => {
                    const id = isContent(item) ? item.contentId : item.infopageId
                    const type = isContent(item)
                      ? SelectedInfopageType.content
                      : SelectedInfopageType.infopage
                    return (
                      <ImageListItem
                        key={id}
                        className={classes.imageListItem}
                        onClick={() => {
                          input.onBlur(undefined) // set form field as "touched"
                          input.onChange({ id, type })
                        }}>
                        <img src={item.thumbnailUrl} alt='' />
                        <ImageListItemBar title={item.name} position='top' />
                      </ImageListItem>
                    )
                  })}
                </ImageList>
              </InfopagesInfiniteScroll>
            )}
          </div>
          {infopageType === SelectedInfopageType.content && (
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component='div'
              count={contents.length}
              rowsPerPage={itemsPerPage}
              page={page}
              labelRowsPerPage={t('general.show')}
              onPageChange={(e, page) => setPage(page)}
              onRowsPerPageChange={(e: any) => setItemsPerPage(e.target.value)}
            />
          )}
          {touched && typeof error === 'string' && <ErrorMessage message={error} />}
        </Fragment>
      )}
    </Fragment>
  )
}

export default SelectInfopage
