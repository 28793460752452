import { all, takeLatest, put, call, select } from 'redux-saga/effects'
import { Confirmation } from '@seesignage/seesignage-utils'
import {
  listConfirmations,
  listConfirmationsSuccess,
  listConfirmationsFail,
  deleteConfirmation,
  deleteConfirmationSuccess,
  deleteConfirmationFail
} from '../actions/confirmations'

import Api from '../services/api/users'
import { selectEnvironmentIdFromPathname } from '../selectors/routing'

export function* handleListConfirmations({ payload: environmentId }: { payload: string }) {
  try {
    const confirmations: Confirmation[] = yield call(Api.getConfirmations, environmentId)
    yield put(listConfirmationsSuccess(confirmations))
  } catch (error) {
    yield put(listConfirmationsFail(error.message))
  }
}

export function* handleDeleteConfirmation({ payload: confirmationId }: { payload: string }) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    yield call(Api.deleteConfirmation, environmentId, confirmationId)
    yield put(
      deleteConfirmationSuccess({
        environmentId,
        confirmationId
      })
    )
  } catch (error) {
    yield put(deleteConfirmationFail(error.message))
  }
}

function* watchConfirmationsActions() {
  yield all([
    takeLatest(listConfirmations, handleListConfirmations),
    takeLatest(deleteConfirmation, handleDeleteConfirmation)
  ])
}

export default [watchConfirmationsActions]
