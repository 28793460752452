import React from 'react'
import { TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { WrappedFieldProps } from 'redux-form'

const useStyles = makeStyles({
  toolbarTextField: {
    marginLeft: 3
  },
  fixedSmallWidth: {
    width: 125
  },
  warningField: {
    '& label': {
      color: 'orange'
    },
    '& .MuiInput-underline': {
      borderBottomColor: 'orange'
    }
  },
  capitalized: {
    textTransform: 'capitalize'
  }
})

interface ReduxTextFieldProps extends WrappedFieldProps {
  label?: string
  fullWidth?: boolean
  helperText?: string
  inputProps?: any
  hasSpacing?: boolean
  isToolbar?: boolean
  fixedSmallWidth?: boolean
  /** Capitalization for text view only */
  isCapitalized?: boolean
}

const ReduxTextField = ({
  label,
  input,
  meta,
  inputProps,
  isToolbar,
  hasSpacing,
  fixedSmallWidth,
  isCapitalized,
  ...custom
}: ReduxTextFieldProps) => {
  const classes = useStyles()
  const { touched, invalid, error, warning } = meta
  let customOverrideProps = {}
  if (isToolbar) {
    customOverrideProps = {
      variant: 'outlined',
      size: 'small'
    }
  }

  const inputPropsNew = isCapitalized
    ? { ...inputProps, className: classes.capitalized }
    : inputProps

  return (
    <TextField
      variant='standard'
      className={classNames({
        [classes.toolbarTextField]: isToolbar && !hasSpacing,
        [classes.fixedSmallWidth]: fixedSmallWidth,
        [classes.warningField]: warning
      })}
      label={label}
      placeholder={label}
      error={touched && invalid}
      // overWrite helperText if validation fails
      inputProps={inputPropsNew}
      {...custom}
      {...customOverrideProps}
      // overwrite custom helperText if validation fails
      helperText={touched && typeof error === 'string' ? error : custom.helperText || warning}
      {...input}
    />
  )
}

export default ReduxTextField
