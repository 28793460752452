import React, { useState, useEffect, Fragment } from 'react'
import { WrappedFieldProps } from 'redux-form'
import { TextField, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { getMinutesAndSecondsFromInterval } from '../../../utils/playlists'
import ErrorMessage from '../../Errors/ErrorMessage'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex'
  },
  field: {
    margin: 8,
    width: 60
  }
}))

interface ReduxDurationFieldProps extends WrappedFieldProps {
  defaultValue?: number
  label?: string
}

/**
 * A redux for wrapper component to set minutes and seconds for a duration field.
 *  - Input value will be one value (number in seconds)
 *  - two visible fields to easily set minutes and seconds
 */
const ReduxDurationField: React.FC<ReduxDurationFieldProps> = ({
  input: { onChange, value },
  meta: { error },
  defaultValue,
  label
}) => {
  const classes = useStyles()
  const { minutes: mins, seconds: secs } = getMinutesAndSecondsFromInterval(value, defaultValue)
  const [t] = useTranslation()
  const [minutes, setMinutes] = useState(mins)
  const [seconds, setSeconds] = useState(secs)
  useEffect(() => {
    if (typeof minutes === 'number' && typeof seconds === 'number') {
      onChange(minutes ? minutes * 60 + seconds : seconds)
    } else {
      onChange(0)
    }
  }, [minutes, seconds]) // eslint-disable-line
  return (
    <Fragment>
      {label && <Typography variant='caption'>{label}</Typography>}
      <div className={classes.root}>
        <TextField
          variant='standard'
          className={classes.field}
          value={minutes}
          helperText={t('general.time.minutes')}
          inputProps={{ min: 0, style: { textAlign: 'center' } }}
          type='number'
          onChange={e => {
            setMinutes(e.target.value.length > 0 ? parseInt(e.target.value) : 0)
          }}
        />
        <div style={{ paddingTop: 15 }}>:</div>
        <TextField
          variant='standard'
          className={classes.field}
          value={seconds}
          type='number'
          helperText={t('general.time.seconds')}
          inputProps={{ min: 0, max: 60, style: { textAlign: 'center' } }}
          onChange={e => {
            setSeconds(e.target.value.length > 0 ? parseInt(e.target.value) : 0)
          }}
        />
        {error && <ErrorMessage message={error} />}
      </div>
    </Fragment>
  )
}

export default ReduxDurationField
