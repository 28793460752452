import { Typography } from '@mui/material'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import { required } from '../../../validation'
import { ReduxTextField } from '../../../components/FormInput/ReduxWrappers'
import { CloseDialog } from '../../../types/actions'
import DialogActions from '../../../components/DialogActions'

interface OwnProps {
  submitAction: (formData: any) => void
  closeDialog: CloseDialog
  dialogId: string
  isUpdate?: boolean
}

interface FormProps {
  name: string
  price?: number
  /** exists when updating existing group */
  groupId?: string
}

type CreateLunchGroupFormProps = OwnProps & WithTranslation

const CreateLunchGroupForm: React.FC<CreateLunchGroupFormProps &
  InjectedFormProps<FormProps, OwnProps>> = ({
  error,
  handleSubmit,
  submitting,
  submitAction,
  closeDialog,
  dialogId,
  isUpdate,
  t
}) => (
  <form onSubmit={handleSubmit(submitAction)}>
    <Field
      fullWidth
      name='name'
      label={t('general.name')}
      component={ReduxTextField}
      required
      validate={[required]}
      helperText={t('lists.lunch.createGroupForm.nameHelperText')}
    />
    {/*
      Add support for product price in the future when needed
    <Field
      fullWidth
      name='price'
      label={t('product.price')}
      type='number'
      format={(value: number | undefined) => (value ? value / 100 : undefined)}
      parse={(value: number | undefined) => (value ? value * 100 : undefined)}
      component={ReduxTextField}
      validate={[isPriceValid]}
      helperText={t('general.optional')}
    /> */}
    <Typography variant='body1' color='error'>
      {error || ''}
    </Typography>
    <DialogActions
      dialogId={dialogId}
      submitting={submitting}
      closeDialog={closeDialog}
      submitButtonLabel={t(`general.${isUpdate ? 'update' : 'add'}`)}
    />
  </form>
)

export default reduxForm<FormProps, OwnProps>({
  form: 'CreateLunchGroupForm'
})(withTranslation()(CreateLunchGroupForm))
