import { Grid } from '@mui/material'
import React from 'react'
import { Field } from 'redux-form'
import { ComponentType } from '@seesignage/seesignage-utils'
import FieldsExpansionPanel from '../../../../../components/ExtensionPanels/FieldsExpansionPanel'
import { generateEffectsFieldPrefix, generateFieldPrefix, gridItemProps } from '../utils'
import {
  ReduxAutocompleteMulti,
  ReduxTextField
} from '../../../../../components/FormInput/ReduxWrappers'
import MenuField from '../../../../../components/MenuField'
import cssProperties from '../cssProperties'

const effects = [
  {
    value: 'snow',
    label: 'Snow'
  }
]

interface EffectsFieldsProps {
  id: string
  level: number
  childIndexes: number[]
  type: ComponentType
}

const EffectsFields: React.FC<EffectsFieldsProps> = ({ id, level, childIndexes, type }) =>
  id !== 'rootContainer' ? ( // effects won't work on root container
    <FieldsExpansionPanel label='effects'>
      <Grid container spacing={8} alignItems='center'>
        {type === ComponentType.div && (
          <Grid item xs={12}>
            <Field
              fullWidth
              name={generateEffectsFieldPrefix(level, childIndexes, 'effects')}
              label='Effects'
              component={ReduxAutocompleteMulti}
              options={effects}
              multiple
            />
          </Grid>
        )}
        <Grid {...gridItemProps}>
          <MenuField
            parse={(val: string) => (val === 'none' ? null : val)}
            label='Animation'
            name={generateFieldPrefix(level, childIndexes, 'animationName')}
            items={cssProperties.animations}
          />
        </Grid>
        <Grid {...gridItemProps}>
          <Field
            fullWidth
            name={generateFieldPrefix(level, childIndexes, 'animationDuration')}
            label='Duration'
            component={ReduxTextField}
            helperText='Such as 3s or 1000ms'
          />
        </Grid>
        <Grid {...gridItemProps}>
          <Field
            fullWidth
            name={generateFieldPrefix(level, childIndexes, 'animationIterationCount')}
            label='Iteration count'
            component={ReduxTextField}
            helperText='number | infinite'
          />
        </Grid>
        <Grid {...gridItemProps}>
          <MenuField
            parse={(val: string) => (val === 'none' ? null : val)}
            label='Timing function'
            name={generateFieldPrefix(level, childIndexes, 'animationTimingFunction')}
            items={cssProperties.animationTimingFunction}
          />
        </Grid>
        <Grid {...gridItemProps}>
          <Field
            fullWidth
            name={generateFieldPrefix(level, childIndexes, 'animationDelay')}
            label='Delay'
            component={ReduxTextField}
            helperText='Such as 3s or 1000ms'
          />
        </Grid>
        <Grid {...gridItemProps}>
          <MenuField
            parse={(val: string) => (val === 'none' ? null : val)}
            label='Direction'
            name={generateFieldPrefix(level, childIndexes, 'animationDirection')}
            items={cssProperties.animationDirection}
          />
        </Grid>
        <Grid {...gridItemProps}>
          <MenuField
            parse={(val: string) => (val === 'none' ? null : val)}
            label='Fill mode'
            name={generateFieldPrefix(level, childIndexes, 'animationFillMode')}
            items={cssProperties.animationFillMode}
          />
        </Grid>
        <Grid {...gridItemProps}>
          <MenuField
            parse={(val: string) => (val === 'none' ? null : val)}
            label='mix-blend-mode'
            name={generateFieldPrefix(level, childIndexes, 'mixBlendMode')}
            items={cssProperties.mixBlendMode}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            fullWidth
            name={generateFieldPrefix(level, childIndexes, 'animation')}
            label='Custom animation'
            component={ReduxTextField}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            fullWidth
            name={generateFieldPrefix(level, childIndexes, 'boxShadow')}
            label='Box shadow'
            component={ReduxTextField}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            fullWidth
            name={generateFieldPrefix(level, childIndexes, 'filter')}
            label='Filter'
            component={ReduxTextField}
          />
        </Grid>
      </Grid>
    </FieldsExpansionPanel>
  ) : (
    <div />
  )

export default EffectsFields
