import {
  TemplateType,
  FeaturePermission,
  FeaturePermissionLevel,
  EnvironmentFeatures,
  EnvironmentScreenFeatures
} from '@seesignage/seesignage-utils'
import { UserInterface } from '../types/users'
import { validateUserAccess, isSystemAdminUser, isAdminUser } from './permissions'
import { isListsFeatureEnabled } from './lists'

interface ValidateRenderItemParams {
  /** name of feature. e.g lists or products */
  feature: string
  user: UserInterface
  /** environment has customer with editable products */
  hasEditableProducts?: boolean
  /** different types of templates found in environment */
  templateTypes: TemplateType[]
  environmentFeatures?: EnvironmentFeatures | null
  environmentScreenFeatures?: EnvironmentScreenFeatures | undefined
}

/**
 * Validate if Navigation item will be rendered or not
 */
const validateRenderItem = ({
  feature,
  user,
  templateTypes,
  hasEditableProducts,
  environmentFeatures,
  environmentScreenFeatures
}: ValidateRenderItemParams) => {
  switch (feature) {
    case FeaturePermission.lists:
      return isListsFeatureEnabled(user, environmentFeatures, templateTypes)
    case FeaturePermission.templates:
      return validateUserAccess(user, feature, FeaturePermissionLevel.read)
    case 'analytics':
      return environmentScreenFeatures?.proofOfPlay ? true : false
    case 'products':
      return hasEditableProducts ? true : false
    case 'campaigns':
      return environmentFeatures?.campaigns
    case 'integrations':
    case 'dashboard':
    case 'errorLogs':
      return isSystemAdminUser(user)
    case 'edit':
      return isAdminUser(user)
    default:
      return true
  }
}

export { validateRenderItem }
