import { Button, DialogActions, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Field, reduxForm, InjectedFormProps, formValueSelector } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { Media } from '@seesignage/seesignage-utils'
import { PageType, AddExistingMediaAsItemFormData } from '../../../../types/playlists'
import { selectIsUserDeviceMobile } from '../../../../selectors/users'
import SelectMultipleMediaField from '../../../../components/FormInput/SelectMedia/SelectMultipleMediaField'
import ErrorMessage from '../../../../components/Errors/ErrorMessage'
import MediaFormats from '../../../../components/ExtensionPanels/MediaFormats'
import { runTour } from '../../../../actions/tours'
import { Tour } from '../../../../types/tours'
import { selectPlaylistItemWizardPage } from '../../../../selectors/playlists'

const useStyles = makeStyles(() => ({
  dialogActionsMobile: {
    justifyContent: 'center'
  },
  mediaFormatsContainer: {
    marginTop: 5
  }
}))

interface AddExistingMediaFormOwnProps {
  onSubmit?: (arg: any) => void
  selectPage?: (page: PageType) => void
  /**
   * When you want to use this component for adding media files to list
   * such as ad revolver playlist item or content editor carousel widget
   * */
  pushMediaToList?: (item: Media) => void
}

type FormDataProps = AddExistingMediaAsItemFormData

const AddExistingMediaForm: React.FC<AddExistingMediaFormOwnProps &
  InjectedFormProps<FormDataProps, AddExistingMediaFormOwnProps>> = ({
  onSubmit,
  handleSubmit,
  selectPage,
  submitting,
  error,
  pushMediaToList,
  form
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [t] = useTranslation()

  const selectedExistingItems: string[] | undefined = useSelector(state =>
    formValueSelector('AddExistingMediaForm')(state, 'existingFiles')
  )
  const isMobile = useSelector(selectIsUserDeviceMobile)
  const page = useSelector(selectPlaylistItemWizardPage)

  const currentTour =
    page === PageType.media
      ? Tour.addMediaPlaylistItemWizard
      : page === PageType.revolver
      ? Tour.addRevolverPlaylistItemWizard
      : undefined

  useEffect(() => {
    if (currentTour) {
      dispatch(runTour(currentTour))
    }
  }, [currentTour, dispatch])

  const selectedItemsCount = selectedExistingItems ? selectedExistingItems.length : 0
  const isSubmitDisabled = submitting || selectedItemsCount === 0
  const showActionButtons = !(typeof pushMediaToList === 'function')
  return (
    <Grid container spacing={0} alignItems='center' justifyContent='flex-start'>
      <Grid item xs={12}>
        <form
          onSubmit={typeof onSubmit === 'function' ? handleSubmit(onSubmit) : undefined}
          id='add-existing-media'>
          <Field
            name='existingFiles'
            component={SelectMultipleMediaField}
            pushMediaToList={pushMediaToList}
            tour={currentTour}
            formName={form}
          />
          <Grid item xs={12} sm={12}>
            <ErrorMessage message={error} />
          </Grid>
          <Grid item xs={12} sm={12} className={classes.mediaFormatsContainer}>
            <MediaFormats />
          </Grid>
          {showActionButtons && (
            <DialogActions className={isMobile ? classes.dialogActionsMobile : undefined}>
              <Button
                onClick={() => {
                  if (typeof selectPage === 'function') {
                    selectPage(PageType.selectType)
                  }
                }}
                color='primary'>
                {t('general.back')}
              </Button>
              <Button
                disabled={isSubmitDisabled}
                color='primary'
                type='submit'
                id='media-submit-button'>
                {t('general.add')}
              </Button>
            </DialogActions>
          )}
        </form>
      </Grid>
    </Grid>
  )
}

export default reduxForm<FormDataProps, AddExistingMediaFormOwnProps>({
  form: 'AddExistingMediaForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true
})(AddExistingMediaForm)
