import { API } from 'aws-amplify'
import {
  Campaign,
  CreateCampaign,
  CampaignSub,
  BatchProductsPlaylistItemPlayer,
  ProductPlaylistItemPlayer,
  UpdateCampaign
} from '@seesignage/seesignage-utils'

const getCampaigns = async (environmentId: string) => {
  const campaigns: Campaign[] = await API.get(
    'campaigns',
    `/environments/${environmentId}/campaigns`,
    {}
  )
  return campaigns
}

const getCampaign = async (environmentId: string, campaignId: string) => {
  const campaign = await API.get(
    'campaigns',
    `/environments/${environmentId}/campaigns/${campaignId}`,
    {}
  )
  return campaign
}

const getCampaignForPreview = async (
  environmentId: string,
  campaignId: string,
  rotation: number
) => {
  try {
    const campaign: (ProductPlaylistItemPlayer | BatchProductsPlaylistItemPlayer)[] = await API.get(
      'campaigns',
      `/environments/${environmentId}/campaigns/${campaignId}`,
      {
        queryStringParameters: {
          forPreview: true,
          rotation
        }
      }
    )
    return campaign
  } catch (error) {
    return undefined
  }
}

const deleteCampaign = async (environmentId: string, campaignId: string) => {
  await API.del('campaigns', `/environments/${environmentId}/campaigns/${campaignId}`, {})
}

const createCampaign = async (environmentId: string, campaign: CreateCampaign) => {
  const newCampaign = await API.post('campaigns', `/environments/${environmentId}/campaigns`, {
    body: campaign
  })
  return newCampaign
}

const updateCampaign = async (
  environmentId: string,
  campaignId: string,
  campaign: UpdateCampaign
) => {
  const updatedCampaign: Campaign | CampaignSub = await API.put(
    'campaigns',
    `/environments/${environmentId}/campaigns/${campaignId}`,
    { body: { updatedCampaign: campaign } }
  )
  return updatedCampaign
}

const updateCampaignTemplate = async (
  environmentId: string,
  campaignId: string,
  template: { templateEnvironmentId: string; templateId: string }
) => {
  const updatedCampaign: Campaign = await API.put(
    'campaigns',
    `/environments/${environmentId}/campaigns/${campaignId}`,
    { body: { template } }
  )
  return updatedCampaign
}

const publishCampaign = async (environmentId: string, campaignId: string) => {
  const publishedCampaign = await API.put(
    'campaigns',
    `/environments/${environmentId}/campaigns/${campaignId}`,
    { body: { published: true } }
  )
  return publishedCampaign
}

const acceptCampaign = async (environmentId: string, campaignId: string) => {
  const acceptedCampaign = await API.put(
    'campaigns',
    `/environments/${environmentId}/campaigns/${campaignId}`,
    { body: { accepted: true } }
  )
  return acceptedCampaign
}

const copyCampaign = async (environmentId: string, campaignId: string) => {
  const campaignCopy = await API.put(
    'campaigns',
    `/environments/${environmentId}/campaigns/${campaignId}/copy`,
    {}
  )
  return campaignCopy
}

const getCampaignExcel = async (
  environmentId: string,
  campaignId: string,
  getSubCampaigns: boolean
) => {
  const options = getSubCampaigns
    ? {
        queryStringParameters: {
          getSubCampaigns: true
        }
      }
    : {}
  const { urls }: { urls: string[] } = await API.get(
    'campaigns',
    `/environments/${environmentId}/campaigns/${campaignId}/excel`,
    options
  )
  return urls
}

export default {
  getCampaign,
  getCampaignForPreview,
  getCampaigns,
  deleteCampaign,
  createCampaign,
  updateCampaign,
  updateCampaignTemplate,
  publishCampaign,
  acceptCampaign,
  copyCampaign,
  getCampaignExcel
}
