import React, { useState, useEffect, Fragment } from 'react'
import { WrappedFieldProps } from 'redux-form'
import { useDispatch, connect } from 'react-redux'
import { TextField, LinearProgress } from '@mui/material'
import { prefillCampaignProduct } from '../../../../actions/campaigns'
import { selectIsLoadingField } from '../../../../selectors/campaigns'
import useDebounce from '../../../../hooks/debounce'
import { StateInterface } from '../../../../types/states'

interface StateProps {
  isLoading: boolean
}

interface OwnProps extends WrappedFieldProps {
  label: string
  /** Item Field name (path in form object) */
  item: string
  helperText?: string
  disabled?: boolean
}

type ProductIdFieldProps = StateProps & OwnProps

/**
 * `productId` field with onChange handler to fetch and prefill `name` field
 */
const ProductIdField: React.FC<ProductIdFieldProps & StateProps> = ({
  input,
  item,
  label,
  disabled,
  meta: { error, invalid },
  input: { name },
  helperText,
  isLoading
}) => {
  const [fieldValue, setFieldValue] = useState('')
  const dispatch = useDispatch()
  const fieldValueDebounced = useDebounce(fieldValue, 500)
  useEffect(() => {
    if (fieldValueDebounced.length >= 3) {
      dispatch(
        prefillCampaignProduct({
          form: 'EditCampaignForm',
          item,
          productId: fieldValueDebounced,
          fieldName: name
        })
      )
    }
  }, [fieldValueDebounced, item, dispatch, name])
  return (
    <Fragment>
      <TextField
        variant='standard'
        fullWidth
        onChange={e => {
          input.onChange(e.target.value)
          setFieldValue(e.target.value)
        }}
        disabled={disabled}
        value={input.value}
        label={label}
        size='small'
        helperText={typeof error === 'string' ? error : helperText}
        error={error && invalid}
      />
      {isLoading && <LinearProgress />}
    </Fragment>
  )
}

const mapStateToProps = (state: StateInterface, { input: { name } }: OwnProps): StateProps => ({
  isLoading: name === selectIsLoadingField(state)
})

export default connect(mapStateToProps)(ProductIdField)
