import React, { Fragment, useEffect } from 'react'
import { AppBar, IconButton, Toolbar, Badge } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Tooltip from '@mui/material/Tooltip'
import classNames from 'classnames'
import CheckBoxMultipleOutlineIcon from 'mdi-react/CheckBoxMultipleOutlineIcon'
import CloseIcon from 'mdi-react/CloseIcon'
import FolderPlusIcon from 'mdi-react/FolderPlusIcon'
import { path } from 'ramda'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { EMMiFile, Media } from '@seesignage/seesignage-utils'
import { closeDialog } from '../../../actions/dialogs'
import {
  addEMMiMedia,
  addMedia,
  clearSelection,
  deleteMedia,
  searchMedia,
  selectAllKeys,
  downloadMediaFiles
} from '../../../actions/media'
import { selectEnvironmentById } from '../../../selectors/environments'
import {
  selectMediaByKey,
  selectSelectedMediaKeys,
  selectMediaFolderById,
  selectSelectedMedias,
  selectSelectedFolder,
  selectDropzoneFieldProps
} from '../../../selectors/media'
import { selectEnvironmentIdFromPathname } from '../../../selectors/routing'
import { bindSubmitActionToPromise } from '../../../utils/forms'
import Dialog from '../../Dialog'
import AddMediaForm from '../Forms/AddMediaForm'
import EMMIFilePicker from '../EMMI/EMMIFilePicker'
import { selectCustomerEMMiUrl } from '../../../selectors/customers'
import SearchField from '../../../components/SearchField'
import { OpenDialogButtonType } from '../../../types/dialogs'
import CreateFolderForm from '../Forms/CreateFolderForm'
import useDebounce from '../../../hooks/debounce'
import { DEBOUNCE_DELAY } from '../../../config/constants'
import MediaAdminTools from './MediaAdminTools'

const useStyles = makeStyles(theme => ({
  root: {
    paddingRight: theme.spacing()
  },
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    color: theme.palette.primary.light,
    marginRight: 4,
    display: 'flex'
  },
  title: {
    flex: '0 0 auto'
  },
  appBar: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14)',
    top: 56,
    [theme.breakpoints.up('sm')]: {
      top: 64
    }
  }
}))

interface MediaToolbarProps {
  visibleMedia: Media[]
}

const MediaToolbar: React.FC<MediaToolbarProps> = ({ visibleMedia }) => {
  const currentEnvironmentId = useSelector(selectEnvironmentIdFromPathname)
  const currentEnvironment = useSelector(selectEnvironmentById(currentEnvironmentId))
  const selectedMediaKeys = useSelector(selectSelectedMediaKeys)
  const selectedMedia = useSelector(selectMediaByKey(selectedMediaKeys[0]))
  const allSelectedMedia = useSelector(selectSelectedMedias)
  const selectedKeys = useSelector(selectSelectedMediaKeys)
  const emmiUrl = useSelector(selectCustomerEMMiUrl)
  const { files: dropzoneFiles, parentFolderId: parentFolderIdDropzone } = useSelector(
    selectDropzoneFieldProps('AddMediaForm', 'files')
  )
  const selectedFolder = useSelector(selectSelectedFolder)
  const dropzoneFolder = useSelector(selectMediaFolderById(parentFolderIdDropzone))

  const dispatch = useDispatch()
  const classes = useStyles()
  const [t] = useTranslation()

  const [searchTerm, setSearchTerm] = React.useState('')
  const searchTermDebounced = useDebounce(searchTerm, DEBOUNCE_DELAY)

  useEffect(() => {
    dispatch(searchMedia(searchTermDebounced))
  }, [searchTermDebounced, dispatch])

  // use dropzone folder only when it exists
  const currentFolder = dropzoneFolder ? dropzoneFolder : selectedFolder
  const showActions = !!selectedMedia
  const mediaName: string | undefined = path(['name'], selectedMedia)
  return (
    <AppBar className={classes.appBar} position='sticky' color='default'>
      <Toolbar className={classNames(classes.root)}>
        <SearchField
          placeholder={t('general.search')}
          onChange={e => {
            setSearchTerm(e.target.value)
          }}
        />
        {currentEnvironment && (
          <Fragment>
            <div className={classes.spacer} />
            <div className={classes.actions}>
              {showActions ? (
                <Fragment>
                  <MediaAdminTools
                    selectedKeys={selectedKeys}
                    allSelectedMedia={allSelectedMedia}
                    downloadMediaFiles={(mediaFiles: Media[]) =>
                      dispatch(downloadMediaFiles(mediaFiles))
                    }
                    closeDialog={(dialogId: string) => dispatch(closeDialog(dialogId))}
                    deleteMedia={() => dispatch(deleteMedia())}
                    mediaName={mediaName}
                  />
                  <Tooltip disableInteractive title={t('media.deselectMedia')}>
                    <IconButton
                      onClick={() => dispatch(clearSelection())}
                      aria-label={t('general.close')}
                      size='large'>
                      <Badge badgeContent={allSelectedMedia.length} color='primary'>
                        <CloseIcon />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                </Fragment>
              ) : (
                <Fragment>
                  <Tooltip disableInteractive title={t('general.selectAll')}>
                    <IconButton onClick={() => dispatch(selectAllKeys(visibleMedia))} size='large'>
                      <CheckBoxMultipleOutlineIcon />
                    </IconButton>
                  </Tooltip>
                  <Dialog
                    dialogId='CreateFolderDialog'
                    buttonId='create-folder'
                    tooltipTitle={t('media.createFolder')}
                    title={t('media.createFolder')}
                    ButtonIcon={FolderPlusIcon}
                    Content={
                      <CreateFolderForm
                        dialogId='CreateFolderDialog'
                        submitButtonLabel={t('general.create')}
                        initialValues={{ parentFolderId: currentFolder?.folderId }}
                        useFormOnSubmit
                      />
                    }
                  />
                  <Dialog
                    dialogId='addMediaDialog'
                    buttonId='add-media'
                    tooltipTitle={t('media.addMediaLabel')}
                    title={t('media.addMediaLabel')}
                    openDialogButtonType={OpenDialogButtonType.add}
                    Content={
                      <AddMediaForm
                        submitButtonLabel={t('general.add')}
                        backButtonLabel={t('general.cancel')}
                        backButtonOnClick={() => dispatch(closeDialog('addMediaDialog'))}
                        onSubmit={bindSubmitActionToPromise(dispatch, addMedia)}
                        initialValues={{
                          parentFolderId: currentFolder
                            ? { value: currentFolder.folderId, label: currentFolder.name }
                            : { value: null, label: 'Root' },
                          quality: 'fullhd',
                          files:
                            dropzoneFiles && dropzoneFiles.length > 0 ? dropzoneFiles : undefined
                        }}
                        showDropzone
                        useFormOnSubmit
                      />
                    }
                  />
                  {emmiUrl && (
                    <EMMIFilePicker
                      addEMMiMedia={(files: EMMiFile[]) => dispatch(addEMMiMedia(files))}
                      url={emmiUrl}
                    />
                  )}
                </Fragment>
              )}
            </div>
          </Fragment>
        )}
      </Toolbar>
    </AppBar>
  )
}

export default MediaToolbar
