import { createAction } from 'redux-actions'
import { createTypes, async } from 'redux-action-types'

const at = createTypes('FEEDBACK/',
  async('SUBMIT')
)

const submitFeedback = createAction(at.SUBMIT)
const submitFeedbackSuccess = createAction(at.SUBMIT_SUCCESS)
const submitFeedbackFail = createAction(at.SUBMIT_FAIL)

export {
  submitFeedback,
  submitFeedbackSuccess,
  submitFeedbackFail
}
