import React, { useState } from 'react'
import { FieldArrayFieldsProps } from 'redux-form'
import { IconButton, Tooltip, Button, Popover } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { SketchPicker, ColorResult } from 'react-color'
import { useTranslation } from 'react-i18next'
import CloseIcon from 'mdi-react/CloseIcon'
import { convertRgbObjectToString } from '../../../utils/conversion'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  addColorButton: {
    height: '40px',
    maxWidth: '40px',
    minWidth: '40px',
    fontSize: '20px'
  },
  deleteColorButton: {
    width: '18px',
    height: '18px',
    padding: '3px',
    position: 'absolute',
    right: '-8px',
    top: '-8px',
    zIndex: 2,
    fontSize: '15px',
    color: 'white',
    background: 'rgba(0, 0, 0, 0.3)',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.2)'
    }
  },
  deleteColorTooltip: {
    position: 'absolute',
    zIndex: 10000
  },
  colorIconWrapper: {
    margin: '0px 15px 15px 0px',
    position: 'relative'
  },
  colorIcon: {
    height: '40px',
    maxWidth: '40px',
    minWidth: '40px',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2)'
  },
  popover: {
    transform: 'translate(0, -10px)'
  },
  popoverContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '10px'
  }
}))

interface OwnProps {
  fields: FieldArrayFieldsProps<string>
  editable: boolean
}

const ReduxSelectEnvironmentColors: React.FC<OwnProps> = ({ fields, editable }) => {
  const [colorPickerVisible, setColorPickerVisible] = useState(false)
  const [selectedColor, setSelectedColor] = useState('FFFFFF')
  const [isExistingColor, setIsExistingColor] = useState(false)
  const [existingColorIndex, setExistingColorIndex] = useState<number>(-1)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const classes = useStyles()
  const [t] = useTranslation()

  const handleColorPickerChange = (color: ColorResult) => {
    const { rgb } = color
    const value = rgb.a === 1 ? color.hex : convertRgbObjectToString(rgb)
    if (isExistingColor) {
      fields.splice(existingColorIndex, 1, value)
    }
    setSelectedColor(value)
  }

  const handleStartAddingColor = (e: React.MouseEvent) => {
    setIsExistingColor(false)
    setAnchorEl(e.target as HTMLElement)
    setColorPickerVisible(true)
  }

  const handleFinishAddingColor = () => {
    fields.push(selectedColor)
    setSelectedColor('FFFFFF')
    setColorPickerVisible(false)
  }

  const handleSelectColorToEdit = (color: string, index: number) => (e: React.MouseEvent) => {
    setSelectedColor(color)
    setIsExistingColor(true)
    setExistingColorIndex(index)
    setAnchorEl(e.target as HTMLElement)
    setColorPickerVisible(true)
  }

  const allColors = fields?.getAll() || []
  return (
    <div className={classes.container}>
      {allColors.map((color, index) => (
        <div key={index} className={classes.colorIconWrapper}>
          {editable && (
            <Tooltip
              disableInteractive
              title={t('environment.deleteColor')}
              className={classes.deleteColorTooltip}>
              <IconButton
                onClick={() => fields.remove(index)}
                className={classes.deleteColorButton}
                size='large'>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip disableInteractive title={color}>
            <span>
              <Button
                style={{ background: color }}
                className={classes.colorIcon}
                disabled={!editable}
                onClick={handleSelectColorToEdit(color, index)}
              />
            </span>
          </Tooltip>
        </div>
      ))}
      {editable && (
        <Tooltip disableInteractive title={t('environment.addColor')}>
          <Button
            color='primary'
            variant='outlined'
            className={classes.addColorButton}
            onClick={handleStartAddingColor}>
            +
          </Button>
        </Tooltip>
      )}

      <Popover
        anchorEl={anchorEl}
        open={colorPickerVisible}
        onClose={() => setColorPickerVisible(false)}
        className={classes.popover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}>
        <div className={classes.popoverContent}>
          <SketchPicker
            presetColors={[]}
            disableAlpha
            styles={{
              default: {
                picker: {
                  boxShadow: 'none',
                  width: '250px'
                }
              }
            }}
            onChange={handleColorPickerChange}
            color={selectedColor}
          />
          {!isExistingColor && (
            <Button color='primary' variant='outlined' onClick={handleFinishAddingColor}>
              {t('environment.addColor')}
            </Button>
          )}
        </div>
      </Popover>
    </div>
  )
}

export default ReduxSelectEnvironmentColors
