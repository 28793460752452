import {
  isFabricIframeObject,
  isFabricVideoObject,
  CANVAS_CONTAINER_ID,
  isFabricTableObject,
  isFabricDateObject,
  isFabricWeatherObject,
  isFabricMediaCarouselObject,
  isFabricWidgetObject,
  isFabricStopScheduleObject,
  isFabricQRCodeObject,
  isFabricRssFeedObject,
  FabricWorkareaObject,
  isFabricOnlyObject,
  isFabricImageObject,
  isFabricPolygonObject,
  isFabricWorkareaObject,
  isFabricSocialMediaObject,
  isFabricFalconyAnnouncementsObject
} from '@seesignage/seesignage-player-utils/lib'
import {
  Media,
  ObjectType,
  RssFeedProps,
  MediaType,
  RssFeedTheme,
  QRCodeProps,
  StopProps,
  CanvasSaveMode,
  VideoProps,
  MediaCarouselPlaylistPropsPlayer,
  MediaCarouselPropsPlayer,
  StoredCanvasForUI,
  FalconyAnnouncementsProps
} from '@seesignage/seesignage-utils'
import ResizeObserver from 'resize-observer-polyfill'
import { fabric } from 'fabric'
import nanoid from 'nanoid'
import {
  initContentEditorSuccess,
  customContentChangeAction,
  zoomCanvasSuccess
} from '../../actions/contents'
import { getStore } from '../../configureStore'
import {
  ContentTableFormData,
  ContentDateWidgetFormData,
  ContentWeatherWidgetFormDataFinal,
  ContentIframeFormData,
  MediaCarouselWizardFormData,
  isMediaCarouselPlaylistFormData,
  EditorOptions,
  SocialMediaWidgetSettings,
  UpdateSocialMediaProps,
  isMediaCarouselMediaFormDataTransformed,
  MediaCarouselWidgetDrawerSettings
} from '../../types/contents'
import { ReorderObjectsParams } from '../../types/actions'
import { rssFeedDefaultVisibleProps } from '../../config/rssFeedDefault'
import { initCanvasEventHandlers } from './canvasEvents'
import { setWorkareaBackgroundColor } from './canvasWorkarea'
import { reorderCustomWidgets } from './canvasObjectUtils'
import { addNewImageObjectToCanvas, addNewVideoObjectToCanvas } from './canvasCreateObject'
import { zoomCanvasToInitial } from './canvasZoom'
import canvasGuidelines from './canvasGuidelines'

declare global {
  interface Window {
    canvas: fabric.Canvas
    workarea: FabricWorkareaObject
    // helperCanvas will be used to display temporary stuff like mouse cursor and polygon while drawing it
    temporayDrawingCanvas: fabric.StaticCanvas
    dispatchCustomContentChangeAction?: (payload?: any) => void
  }
}

const dispatchCustomContentChangeAction = (payload?: any) => {
  const store = getStore()
  store.dispatch(customContentChangeAction(payload))
}

let resizeObserver: ResizeObserver | null = null

/**
 * Callback function to execute after Canvas initialization success
 */
const initSuccessCallback = (options: EditorOptions) => {
  const store = getStore()
  store.dispatch(initContentEditorSuccess(options))
  // Objects should remain in current stack position when selected.
  // note: means that when object is selected we do not want to display it in top.
  window.canvas.preserveObjectStacking = true
  window.dispatchCustomContentChangeAction = dispatchCustomContentChangeAction
  // Disable transferring styles on text copy paste since it causes bugs on styles
  ;(fabric as any).disableStyleCopyPaste = true
  // Set helper canvas for content editor
  // This will be used to display mouse cursors and other temporary stuff
  const temporayDrawingCanvas = new fabric.StaticCanvas('temporayDrawingCanvas', {
    ...options.resolution
  })
  window.temporayDrawingCanvas = temporayDrawingCanvas
  initCanvasEventHandlers(window.canvas)

  let isFirstRun = true
  createCanvasResizeObserver(() => {
    if (isFirstRun) {
      canvasGuidelines(window.canvas)
      const zoom = zoomCanvasToInitial()
      if (zoom) {
        store.dispatch(zoomCanvasSuccess({ zoom }))
      }
      window.canvas.renderAll()
      isFirstRun = false
    }
  })
}

const createCanvasResizeObserver = (callbackFn: (width: number, height: number) => void) => {
  const canvasContainer = document.getElementById(CANVAS_CONTAINER_ID)
  resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
    const { width = 0, height = 0 } = (entries[0] && entries[0].contentRect) || {}
    resizeCanvas(width, height)
    callbackFn(width, height)
  })
  if (canvasContainer) {
    resizeObserver.observe(canvasContainer)
  }
}

const destroyCanvasResizeObserver = () => {
  if (resizeObserver) {
    resizeObserver.disconnect()
    resizeObserver = null
  }
}

/**
 *  Add fabric object to the canvas and select added object
 * */
const addFabricObject = (obj: any) => {
  window.canvas.add(obj)
  if (
    ![ObjectType.CustomPath, ObjectType.CustomLine, ObjectType.CustomPolygon].includes(obj?.type)
  ) {
    window.canvas.centerObject(obj)
  }
  if (isFabricOnlyObject(obj)) {
    bringBelowFabricWidgetObjects(obj)
  }
  // TODO: Prevent object event, select when on cursor grab mode
  window.canvas.setActiveObject(obj)
}

const createFabricMediaObject = (media: Media) => {
  const { type, url } = media
  if (type === MediaType.image && url) {
    addNewImageObjectToCanvas(media)
  } else if (type === MediaType.video && url) {
    addNewVideoObjectToCanvas(media)
  }
}

const updateSelectedObject = ({ field, value }: { field: string; value: any }) => {
  const valueToSet = { [field]: value } as any
  const activeObject = window.canvas.getActiveObject()
  if (activeObject) {
    switch (field) {
      case 'fontSize':
        // autocomplete field
        if (value) {
          activeObject.set({
            fontSize: value?.value
          } as any)
          window.canvas.requestRenderAll()
        }
        break
      case 'lockMovementX':
      case 'lockMovementY': {
        activeObject.set({
          hoverCursor: value ? 'pointer' : 'move',
          [field]: value
        })
        activeObject.set({
          hasControls: !activeObject.lockMovementX && !activeObject.lockMovementY
        })

        window.canvas.requestRenderAll()
        break
      }
      case 'width':
      case 'height':
      case 'radius':
      case 'left':
      case 'top':
      case 'angle':
      case 'fill':
      case 'scaleX':
      case 'scaleY':
      case 'fontFamily':
      case 'textAlign':
      case 'fontWeight':
      case 'shadow':
      case 'fontStyle':
      case 'underline':
      case 'linethrough':
      case 'lineHeight':
      case 'charSpacing':
      case 'stroke':
      case 'strokeWidth':
        if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
          activeObject.set(valueToSet)
          if (field === 'top' || field === 'left') {
            activeObject.setCoords()
            // https://github.com/fabricjs/fabric.js/wiki/When-to-call-setCoords
          }
          // rerender widget to fit dimension
          if (
            (isFabricFalconyAnnouncementsObject(activeObject) ||
              isFabricMediaCarouselObject(activeObject)) &&
            ['width', 'height'].includes(field)
          ) {
            activeObject.renderWidget()
          }
          window.canvas.requestRenderAll()
        }
        break
      // video element props
      case 'cVideoProps.autoplay':
      case 'cVideoProps.loop':
      case 'cVideoProps.muted':
        const attributeName = field.split('.').pop()
        if (
          attributeName &&
          (value || typeof value === 'boolean') &&
          isFabricVideoObject(activeObject)
        ) {
          const cVideoProps = {
            ...activeObject.customOptions.widgetProps,
            [attributeName]: value
          } as VideoProps
          activeObject.setWidgetProps(cVideoProps)
        }
        break
      case 'cStopProps.stops':
      case 'cStopProps.showVia':
      case 'cStopProps.title':
      case 'cStopProps.titleColor':
      case 'cStopProps.titleFontSize':
      case 'cStopProps.backgroundColor':
      case 'cStopProps.tableHeaderTextColor':
      case 'cStopProps.tableHeaderBackgroundColor':
      case 'cStopProps.tableHeaderFontSize':
      case 'cStopProps.bodyTextColor':
      case 'cStopProps.bodyFontSize':
      case 'cStopProps.showTime':
      case 'cStopProps.fontFamily':
      case 'cStopProps.showStopNameColumn':
      case 'cStopProps.useStopNameAsTitle': {
        const attributeName = field.split('.').pop()
        if (attributeName && isFabricStopScheduleObject(activeObject)) {
          const cStopProps: StopProps = {
            ...activeObject.customOptions.widgetProps,
            [attributeName]: value
          }
          activeObject.setWidgetProps(cStopProps)
        }
        break
      }
      case 'cStopProps.numberOfDepartures':
        // autocomplete field
        if (value && isFabricStopScheduleObject(activeObject)) {
          const cStopProps: StopProps = {
            ...activeObject.customOptions.widgetProps,
            numberOfDepartures: value?.value
          }
          activeObject.setWidgetProps(cStopProps)
        }
        break
      case 'QRCodeScale': {
        activeObject.set({
          scaleX: value,
          scaleY: value
        })
        activeObject.setCoords()
        window.canvas.requestRenderAll()
        break
      }
      case 'cQRCodeProps.value':
      case 'cQRCodeProps.backgroundColor':
      case 'cQRCodeProps.codeColor':
      case 'cQRCodeProps.size': {
        const attributeName = field.split('.').pop()
        if (attributeName && isFabricQRCodeObject(activeObject)) {
          const cQRCodeProps: QRCodeProps = {
            ...activeObject.customOptions.widgetProps,
            [attributeName]: value
          }
          activeObject.setWidgetProps(cQRCodeProps)
        }
        break
      }
      case 'cRssFeedProps.styles.itemImageStyles.borderRadius':
        if (isFabricRssFeedObject(activeObject)) {
          const {
            customOptions: { widgetProps }
          } = activeObject
          const cRssFeedProps: RssFeedProps = {
            ...widgetProps,
            styles: {
              ...widgetProps.styles,
              itemImageStyles: {
                ...widgetProps?.styles?.itemImageStyles,
                borderRadius: value + '%'
              }
            }
          }
          activeObject.setWidgetProps(cRssFeedProps)
        }
        break
      case 'cRssFeedProps.visibleProperties': {
        if (isFabricRssFeedObject(activeObject)) {
          const cRssFeedProps: RssFeedProps = {
            ...activeObject.customOptions.widgetProps,
            visibleProperties: value
          }
          activeObject.setWidgetProps(cRssFeedProps)
        }
        break
      }

      case 'cRssFeedProps.visibleProperties.channelTitle':
      case 'cRssFeedProps.visibleProperties.channelDescription':
      case 'cRssFeedProps.visibleProperties.itemTitle':
      case 'cRssFeedProps.visibleProperties.itemDescription':
      case 'cRssFeedProps.visibleProperties.itemImage':
      case 'cRssFeedProps.visibleProperties.itemPubDate': {
        const attributeName: any = field.split('.').pop()
        if (isFabricRssFeedObject(activeObject)) {
          const {
            customOptions: { widgetProps }
          } = activeObject
          const cRssFeedProps: RssFeedProps = {
            ...widgetProps,
            visibleProperties: {
              ...widgetProps.visibleProperties,
              [attributeName]: value
            }
          }
          activeObject.setWidgetProps(cRssFeedProps)
        }
        break
      }
      case 'cRssFeedProps.url':
        if (value === '') {
          return
        }
        if (typeof value === 'string' && isFabricRssFeedObject(activeObject)) {
          const cRssFeedProps: RssFeedProps = {
            ...activeObject.customOptions.widgetProps,
            url: value,
            visibleProperties: rssFeedDefaultVisibleProps
          }
          activeObject.setWidgetProps(cRssFeedProps)
        }
        break
      case 'cRssFeedProps.theme':
        if (typeof value === 'string' && isFabricRssFeedObject(activeObject)) {
          const {
            customOptions: { widgetProps }
          } = activeObject
          const cRssFeedProps: RssFeedProps = {
            ...widgetProps,
            theme: value as RssFeedTheme
          }
          activeObject.setWidgetProps(cRssFeedProps)
        }
        break
      case 'cRssFeedProps.itemCount':
      case 'cRssFeedProps.interval':
        if (value && isFabricRssFeedObject(activeObject)) {
          const attribute = field.split('.')[1]
          const cRssFeedProps: RssFeedProps = {
            ...activeObject.customOptions.widgetProps,
            [attribute]: value
          }
          activeObject.setWidgetProps(cRssFeedProps)
        }
        break
      // for PolygonWithCustomControls
      case 'pointEditMode':
        if (isFabricPolygonObject(activeObject)) {
          activeObject.setCustomOptions({ pointEditMode: value })
          window.canvas.requestRenderAll()
        }
        break
      default:
        if (field.startsWith('cRssFeedProps.styles') && isFabricRssFeedObject(activeObject)) {
          const element = field.split('.')[2]
          const attribute = field.split('.')[3]
          const {
            customOptions: { widgetProps }
          } = activeObject
          const cRssFeedProps: any = {
            ...widgetProps,
            styles: {
              ...widgetProps.styles,
              [element]: {
                ...(widgetProps.styles as any)[element],
                [attribute]: value
              }
            }
          }
          activeObject.setWidgetProps(cRssFeedProps)
        }

        break
    }
  } else {
    // updating workarea properties when no active object
    switch (field) {
      case 'backgroundColor':
        // set backgroundColor to workarea
        if (typeof value === 'string') {
          setWorkareaBackgroundColor(value)
          window.canvas.requestRenderAll()
        }
        break
      default:
        break
    }
  }
}

const updateMediaSource = (media: Media) => {
  const { url, key, name } = media
  const activeObject = window.canvas.getActiveObject()

  if (isFabricImageObject(activeObject)) {
    activeObject.setSrc(
      url || '',
      () => {
        activeObject.setCustomOptions({ key, src: url, name })
        window.canvas.renderAll()
      },
      { crossOrigin: 'anonymous' }
    )
    return
  }

  if (isFabricVideoObject(activeObject)) {
    activeObject.setCustomOptions({ key, src: url, name })
    activeObject.setWidgetProps({ ...activeObject.customOptions.widgetProps, src: url || '' })
    return
  }
}

const updateSelectedWidgetProps = (
  allFormValues:
    | ContentIframeFormData
    | ContentTableFormData
    | ContentDateWidgetFormData
    | ContentWeatherWidgetFormDataFinal
    | FalconyAnnouncementsProps
    | MediaCarouselWizardFormData
    | UpdateSocialMediaProps,
  form?: string,
  field?: string,
  value?: any
) => {
  const activeObject = window.canvas.getActiveObject()
  if (isFabricTableObject(activeObject)) {
    const { cTableProps } = allFormValues as ContentTableFormData
    if (cTableProps) {
      // update schema and styles props
      activeObject.setTableSchemaProps(cTableProps.schema)
    }
  } else if (isFabricDateObject(activeObject)) {
    const { cDateProps } = allFormValues as ContentDateWidgetFormData
    if (cDateProps) {
      activeObject.setWidgetProps(cDateProps)
    }
  } else if (isFabricWeatherObject(activeObject)) {
    const { cWeatherProps } = allFormValues as ContentWeatherWidgetFormDataFinal
    if (cWeatherProps) {
      activeObject.setWidgetProps(cWeatherProps)
    }
  } else if (isFabricIframeObject(activeObject)) {
    const { cIframeProps } = allFormValues as ContentIframeFormData
    if (cIframeProps) {
      activeObject.setWidgetProps(cIframeProps)
    }
  } else if (isFabricFalconyAnnouncementsObject(activeObject)) {
    const { widgetProps } = activeObject.customOptions
    if (form === 'ContentFalconyAnnouncementsSettingsForm') {
      switch (field) {
        case 'url': {
          const regex = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.pelsu.fi\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/
          if (typeof value === 'string' && regex.test(value)) {
            activeObject.setWidgetProps({
              ...widgetProps,
              [field]: value
            })
          }
          break
        }
        case 'refreshInterval': {
          if (typeof value === 'number' && value >= 30) {
            activeObject.setWidgetProps({
              ...widgetProps,
              [field]: value
            })
          }
          break
        }
        case 'slideShowInterval':
        case 'latestItemCount':
        case 'visibleItemCount': {
          if (typeof value === 'number' && value > 0) {
            activeObject.setWidgetProps({
              ...widgetProps,
              [field]: value
            })
          }
          break
        }
        case 'oldestItemByMonth': {
          if (typeof value === 'number' && value > 0) {
            activeObject.setWidgetProps({
              ...widgetProps,
              [field]: value
            })
          } else if (!value) {
            activeObject.setWidgetProps({
              ...widgetProps,
              [field]: undefined
            })
          }
          break
        }
        case 'cardStyles.backgroundColor': {
          activeObject.setWidgetProps({
            ...widgetProps,
            cardStyles: { backgroundColor: value }
          })
          break
        }
        default:
          break
      }
    }
  } else if (isFabricSocialMediaObject(activeObject)) {
    const props = allFormValues as UpdateSocialMediaProps
    const { widgetProps } = activeObject.customOptions
    activeObject.setWidgetProps({ ...widgetProps, ...props })
    if (form === 'ContentSocialMediaSettingsForm') {
      switch (field) {
        case SocialMediaWidgetSettings.slideShowInterval: {
          if (typeof value === 'number' && value > 0) {
            activeObject.setWidgetProps({
              ...widgetProps,
              [field]: value
            })
          }
          break
        }
        case SocialMediaWidgetSettings.widgetObjectFit:
        case SocialMediaWidgetSettings.showHeader:
        case SocialMediaWidgetSettings.showCaption:
        case SocialMediaWidgetSettings.showContentOnlyIfPostExists:
        case SocialMediaWidgetSettings.widgetBackground:
        case SocialMediaWidgetSettings.videosMuted: {
          activeObject.setWidgetProps({
            ...widgetProps,
            [field]: value
          })
          break
        }
        default:
          break
      }
      window.canvas.requestRenderAll()
      return
    }
  } else if (isFabricMediaCarouselObject(activeObject)) {
    const { widgetProps } = activeObject.customOptions
    if (form === 'ContentMediaCarouselSettingsForm') {
      switch (field) {
        case MediaCarouselWidgetDrawerSettings.slideShowInterval:
        case MediaCarouselWidgetDrawerSettings.widgetBackground:
        case MediaCarouselWidgetDrawerSettings.widgetObjectFit:
        case MediaCarouselWidgetDrawerSettings.videosMuted: {
          activeObject.setWidgetProps({
            ...widgetProps,
            [field]: value
          })
          break
        }
        default:
          break
      }
      window.canvas.requestRenderAll()
      return
    }

    if (isMediaCarouselPlaylistFormData(allFormValues)) {
      const {
        playlistId: { value },
        environmentId,
        carouselItems,
        defaultInterval
      } = allFormValues
      const mediaCarouselPlaylistPropsPlayer: MediaCarouselPlaylistPropsPlayer = {
        ...widgetProps,
        playlistId: value,
        environmentId,
        defaultInterval,
        carouselItems
      }
      activeObject.setWidgetProps(mediaCarouselPlaylistPropsPlayer)
    }
    if (isMediaCarouselMediaFormDataTransformed(allFormValues)) {
      const { carouselItems } = allFormValues
      const mediaCarouselPropsPlayer: MediaCarouselPropsPlayer = {
        ...(widgetProps as MediaCarouselPropsPlayer),
        carouselItems
      }
      activeObject.setWidgetProps(mediaCarouselPropsPlayer)
    }
  }
  window.canvas.requestRenderAll()
}

/**
 * Get canvas as JSON for storing canvas data to memory or database
 * and include additional properties
 */
const getCanvasAsJSON = (saveTo: CanvasSaveMode = CanvasSaveMode.toMemory) => {
  const canvasAsJson = window.canvas.toJSON([
    saveTo,
    'lockMovementX',
    'lockMovementY',
    'hasControls',
    'hoverCursor',
    '_controlsVisibility',
    'lineStyle',
    'snapping',
    'snappingThreshold'
  ]) as StoredCanvasForUI
  return canvasAsJson
}

const deleteActiveObjects = () => {
  const activeObjects = window.canvas.getActiveObjects()
  for (const activeObject of activeObjects) {
    // remove custom element from dom
    if (isFabricWidgetObject(activeObject)) {
      activeObject.removeWidget()
    }
    window.canvas.remove(activeObject)
  }
  window.canvas.discardActiveObject().renderAll()
}

const deleteObjectById = (id: string) => {
  const objects = window.canvas.getObjects()
  const currentObj = objects.find(obj => (obj as any).customOptions.id === id) as any
  if (currentObj) {
    if (isFabricWidgetObject(currentObj)) {
      currentObj.removeWidget()
    }
    window.canvas.remove(currentObj)
    window.canvas.renderAll()
  }
}

const cloneActiveObjects = () => {
  const activeObjects = window.canvas.getActiveObjects()
  for (const activeObject of activeObjects) {
    activeObject.clone(
      (clone: fabric.Object) => {
        const left = activeObject?.left && activeObject?.left > 0 ? activeObject?.left : 50
        const top = activeObject?.top && activeObject?.top > 0 ? activeObject?.top : 50
        const props = {
          left: left + 25,
          top: top + 25,
          customOptions: { ...clone.get('customOptions' as any), id: nanoid() }
        }

        addFabricObject(clone.set(props))
      },
      ['customOptions']
    )
  }
  window.canvas.discardActiveObject().renderAll()
}

/**
 * Send active objects to forward
 * NOTE: does not support custom elements that are over the canvas
 */
const sendActiveObjectsForward = () => {
  const activeObjects = window.canvas.getActiveObjects()
  for (const activeObject of activeObjects) {
    activeObject.bringForward()
  }
  window.canvas.renderAll()
}

/**
 * Send active objects to backwards
 * NOTE: "workarea" type of object is always the first object in objects array.
 * It means that it is behind all other objects. Therefore, we make sure to not send
 * object behind first object
 * NOTE: does not support custom elements that are over the canvas
 */
const sendActiveObjectsBacwards = () => {
  const activeObjects = window.canvas.getActiveObjects()
  for (const activeObject of activeObjects) {
    const zIndex = window.canvas.getObjects().indexOf(activeObject)
    if (zIndex !== 1) {
      activeObject.sendBackwards()
    }
  }
  window.canvas.renderAll()
}

/**
 * Resize the canvas dimensions to fill the available space
 * @param nextWidth New width
 * @param nextHeight New height
 * @returns
 */
const resizeCanvas = (nextWidth: number, nextHeight: number) => {
  if (!window.canvas) {
    return
  }
  window.canvas.setWidth(nextWidth).setHeight(nextHeight)
  window.temporayDrawingCanvas.setWidth(nextWidth).setHeight(nextHeight)
  window.canvas.setBackgroundColor('#f3f3f3', () => window.canvas.renderAll())

  const objects = window.canvas.getObjects()
  const [workarea, ...canvasObjects] = objects

  if (!isFabricWorkareaObject(workarea)) {
    return
  }

  const prevWorkAreaLeft = workarea.left || 0
  const prevWorkareaTop = workarea.top || 0

  window.canvas.centerObject(workarea)

  workarea.setCoords()
  const newWorkAreaLeft = workarea.left || 0
  const newWorkareaTop = workarea.top || 0

  const diffLeft = newWorkAreaLeft - prevWorkAreaLeft
  const diffTop = newWorkareaTop - prevWorkareaTop
  canvasObjects.forEach(obj => {
    const left = (obj.left || 0) + diffLeft
    const top = (obj.top || 0) + diffTop
    obj.set({
      left,
      top
    })
    obj.setCoords()
  })
  window.canvas.requestRenderAll()
}

const clearCanvas = () => {
  // make sure that canvas exists
  if (window.canvas) {
    destroyCanvasResizeObserver()
    const objects = window.canvas.getObjects()
    // remove custom HTML elements that are over canvas
    for (const obj of objects) {
      if (isFabricWidgetObject(obj)) {
        obj.removeWidget()
      }
    }
    // Clears a canvas element and dispose objects. NOTE: for some reason canvas.clear() breaks canvas
    window.canvas.dispose()
    window.temporayDrawingCanvas.dispose()
    ;(window as any).canvas = undefined
    ;(window as any).workarea = undefined
  }
}

const setActiveObjectHorizontalAlign = () => {
  const activeObjects = window.canvas.getActiveObjects()
  const object = activeObjects[0]
  object.centerH()
  window.canvas.renderAll()
}

const setActiveObjectVerticalAlign = () => {
  const activeObjects = window.canvas.getActiveObjects()
  const object = activeObjects[0]
  object.centerV()
  window.canvas.renderAll()
}

const setActiveObjectById = (id: string) => {
  const obj = window.canvas.getObjects()
  const activeObject = obj.find((o: any) => o.customOptions.id === id)
  if (activeObject) {
    window.canvas.setActiveObject(activeObject)
    window.canvas.renderAll()
  }
}

const deselectActiveObject = () => {
  window.canvas.discardActiveObject()
  window.canvas.renderAll()
}

const countCustomObjectsByType = (customType: ObjectType) => {
  const objs = window.canvas.getObjects()
  return objs.filter(({ type }: any) => type === customType).length
}

const countLimitedObjects = () => {
  const objs = window.canvas.getObjects()
  return objs.filter(({ type }: any) =>
    [ObjectType.CustomVideo, ObjectType.CustomMediaCarousel, ObjectType.socialMedia].includes(type)
  ).length
}

const getActiveObjectGroup = () => {
  const activeObject = window.canvas.getActiveObject()
  if (activeObject && activeObject.isType('activeSelection')) {
    return activeObject as fabric.Group
  }
}

/**
 * Everytime we add a normal object to canvas,
 * bring it below custom objects/widgets.
 * This is because even if the html element is always above the canvas,
 * you cannot select the actual fabrcic object of a custom object
 * if it's behind a normal object.
 */
const bringBelowFabricWidgetObjects = (fabricOnlyObject: any) => {
  const fabricOnlyObjects = window.canvas.getObjects().filter(isFabricOnlyObject)
  fabricOnlyObject.moveTo(Math.max(fabricOnlyObjects.length, 1))
}

const reorderObjectsUtil = ({ objectId, startIndex, endIndex }: ReorderObjectsParams) => {
  const objects = window.canvas.getObjects()
  const object = objects.find(obj => (obj as any).customOptions.id === objectId)
  if (object) {
    const difference = startIndex - endIndex
    const currentObjectIndex = objects.indexOf(object)
    object.moveTo(currentObjectIndex + difference)
    if (isFabricWidgetObject(object)) {
      reorderCustomWidgets(object, startIndex, endIndex)
    }
  }
}

export {
  initSuccessCallback,
  createFabricMediaObject,
  addFabricObject,
  updateSelectedObject,
  updateMediaSource,
  updateSelectedWidgetProps,
  getCanvasAsJSON,
  deleteActiveObjects,
  deleteObjectById,
  cloneActiveObjects,
  sendActiveObjectsForward,
  sendActiveObjectsBacwards,
  setActiveObjectHorizontalAlign,
  setActiveObjectVerticalAlign,
  getActiveObjectGroup,
  clearCanvas,
  setActiveObjectById,
  deselectActiveObject,
  countCustomObjectsByType,
  countLimitedObjects,
  reorderObjectsUtil
}
