import { useSelector } from 'react-redux'
import { StateInterface } from '../types/states'
import { selectIsUserDeviceMobile } from '../selectors/users'

const useIsUserDeviceMobile = () => {
  const isMobile = useSelector((state: StateInterface) => selectIsUserDeviceMobile(state))
  return isMobile
}

export default useIsUserDeviceMobile
