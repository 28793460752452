import React, { Fragment, useEffect, useState } from 'react'
import {
  DataGrid,
  GridColDef,
  GridSortModel,
  GridSortDirection,
  GridRowId,
  GridCellParams
} from '@mui/x-data-grid'
import dateFormat from 'dateformat'
import { ErrorLog } from '@seesignage/seesignage-utils'
import { LinearProgress } from '@mui/material'
import { useDispatch } from 'react-redux'
import Dialog from '../Dialog'
import { openDialog } from '../../actions/dialogs'
import { getErrorLogsForLastDays, getErrorLogsForDate } from '../../services/api/errorLogs'
import ErrorLogsToolbar from './ErrorLogsToolbar'
import SelectedError from './SelectedError'

const columns: GridColDef[] = [
  {
    field: 'time',
    headerName: 'Date',
    width: 170,
    renderCell: function renderCell(params: GridCellParams) {
      return <span>{dateFormat(params.value as string, 'dd.mm.yyyy HH:MM:ss')}</span>
    }
  },
  {
    field: 'logGroupName',
    headerName: 'CloudWatch logGroupName',
    width: 350
  },
  {
    field: 'status',
    headerName: 'Code',
    width: 50
  },
  {
    field: 'err',
    headerName: 'Error',
    width: 800
  },
  {
    field: 'func',
    headerName: 'Function',
    width: 300
  }
]

interface PaginationModel {
  page: number
  pageSize: number
}

const ErrorLogsTable: React.FC = () => {
  const [errorLogs, setErrorLogs] = useState<ErrorLog[]>([])
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date())
  const [showLastWeek, setShowLastWeek] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [selectedErrorLog, setSelectedErrorLog] = useState<ErrorLog>()
  const dispatch = useDispatch()
  useEffect(() => {
    const getErrorLogs = async (date?: string) => {
      try {
        setLoading(true)
        const logs = date ? await getErrorLogsForDate(date) : await getErrorLogsForLastDays()
        setErrorLogs(logs)
        setLoading(false)
      } catch (error) {
        setErrorLogs([])
        setLoading(false)
      }
    }
    if (showLastWeek) {
      getErrorLogs()
    } else if (selectedDate) {
      // single selected date
      getErrorLogs(selectedDate.toISOString())
    }
  }, [selectedDate, showLastWeek])

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'time',
      sort: 'desc' as GridSortDirection
    }
  ])
  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([])
  const [paginationModel, setPaginationModel] = useState<PaginationModel>({
    page: 0,
    pageSize: 100
  })

  return (
    <Fragment>
      <ErrorLogsToolbar
        setSelectedDate={setSelectedDate}
        selectedDate={selectedDate}
        showLastWeek={showLastWeek}
        setShowLastWeek={setShowLastWeek}
      />
      {isLoading && <LinearProgress />}
      <DataGrid
        rowSelectionModel={selectionModel}
        onRowSelectionModelChange={newSelectionModel => {
          setSelectionModel(newSelectionModel)
        }}
        onRowClick={({ row }) => {
          setSelectedErrorLog(row as ErrorLog)
          dispatch(openDialog('SelectedErrorDialog'))
        }}
        sortModel={sortModel}
        onSortModelChange={model => setSortModel(model)}
        rows={errorLogs}
        columns={columns}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[25, 50, 100]}
        getRowId={row => row.id}
      />
      {selectedErrorLog && (
        <Dialog
          dialogId='SelectedErrorDialog'
          title='Error'
          Content={<SelectedError errorLog={selectedErrorLog} />}
        />
      )}
    </Fragment>
  )
}

export default ErrorLogsTable
