import InfiniteScroll from 'react-infinite-scroll-component'
import React, { Fragment } from 'react'
import { CircularProgress, Typography } from '@mui/material'
import { TFunction } from 'i18next'
import { SelectedInfopageType } from '../../types/infopages'

interface InfopagesInfiniteScrollProps {
  listInfopages?: (params: any) => void
  infopageType?: SelectedInfopageType | null
  itemsCount: number
  children?: any
  searchTerm: string
  hasMoreToLoad: boolean
  t: TFunction
  scrollableTarget: string
  height?: number
  hasSearchTerm: boolean
}

const InfopagesInfiniteScroll = ({
  infopageType,
  children,
  listInfopages,
  searchTerm,
  itemsCount,
  hasMoreToLoad,
  scrollableTarget,
  height,
  t,
  hasSearchTerm
}: InfopagesInfiniteScrollProps) => {
  // use infine scroll only for infopages
  if (infopageType === SelectedInfopageType.infopage) {
    return (
      <InfiniteScroll
        dataLength={itemsCount}
        next={() => {
          if (infopageType === SelectedInfopageType.infopage && listInfopages) {
            listInfopages({ includePlaylistData: true, searchTerm })
          }
        }}
        height={height}
        hasMore={hasMoreToLoad}
        scrollableTarget={scrollableTarget}
        loader={
          <div
            style={{
              textAlign: 'center',
              padding: 20
            }}>
            <CircularProgress />
          </div>
        }
        endMessage={
          <Typography style={{ textAlign: 'center' }}>
            {t(`infopages.${hasSearchTerm ? 'allInfopagesResultsLoaded' : 'allInfopagesLoaded'}`, {
              count: itemsCount
            })}
          </Typography>
        }>
        {children}
      </InfiniteScroll>
    )
  }
  return <Fragment>{children}</Fragment>
}

export default InfopagesInfiniteScroll
