import React, { useState, useEffect, Fragment } from 'react'
import { PharmacyMasterProduct, PharmacySearchCriteria } from '@seesignage/seesignage-utils'
import Autocomplete from '@mui/material/Autocomplete'
import {
  TextField,
  CircularProgress,
  Grid,
  Paper,
  IconButton,
  MenuItem,
  Tooltip,
  ListItemIcon,
  ListItem,
  ListItemText
} from '@mui/material'
import { connect } from 'react-redux'
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import ProductsService from '../../../services/api/products'
import useDebounce from '../../../hooks/debounce'
import { StateInterface } from '../../../types/states'
import { selectEnvironmentIdFromPathname } from '../../../selectors/routing'
import { getPharmacyProductTitle } from '../../../utils/products'
import { DEBOUNCE_DELAY } from '../../../config/constants'
import CreateCustomerDrugForm from './CreateCustomerDrugForm'

const useStyles = makeStyles(() => ({
  searchListProductName: {
    '& > :first-letter': {
      textTransform: 'capitalize'
    }
  },
  closeIconButton: { position: 'absolute', top: 5, right: 5 }
}))
interface OwnProps {
  customerId: string
  changeValue: (field: string, value: any) => void
}

interface StateProps {
  environmentId?: string
}

type SearchDrugFormProps = OwnProps & StateProps

const SearchDrugForm: React.FC<SearchDrugFormProps> = ({
  customerId,
  environmentId,
  changeValue
}) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [t] = useTranslation()
  const [searchResults, setSearchResults] = useState<PharmacyMasterProduct[]>([])
  const [searchFieldValue, setSearchFieldValue] = useState('')
  const [searchType, setSearchType] = useState<PharmacySearchCriteria>(PharmacySearchCriteria.name)
  const [selectedDrug, setDrug] = useState<PharmacyMasterProduct | null>(null)
  const searchTerm = useDebounce(searchFieldValue, DEBOUNCE_DELAY)

  useEffect(() => {
    const getProducts = async () => {
      setLoading(true)
      const products = await ProductsService.searchDrugs(
        environmentId || '',
        searchType,
        searchTerm
      )
      setSearchResults(products)
      if (products.length === 1) {
        setDrug(products[0])
      }
      setLoading(false)
    }
    if (searchTerm.length >= 3) {
      getProducts()
    }
  }, [searchTerm, searchType]) // eslint-disable-line
  useEffect(() => {
    const getCustomerDrug = async (environmentId: string, customerId: string, ean: string) => {
      const customerDrug = await ProductsService.getCustomerDrug(environmentId, customerId, ean)
      changeValue('product.customerId', customerId)
      if (customerDrug?.price || selectedDrug?.price) {
        changeValue('product.price', customerDrug?.price || selectedDrug?.price)
      }

      changeValue('product.ean', selectedDrug?.ean)
      if (customerDrug?.description || selectedDrug?.description) {
        changeValue('product.description', customerDrug?.description || selectedDrug?.description)
      }
    }
    if (selectedDrug && environmentId) {
      getCustomerDrug(environmentId, customerId, selectedDrug.ean)
    }
  }, [selectedDrug]) // eslint-disable-line
  if (!environmentId) {
    return null
  }
  return (
    <Fragment>
      {selectedDrug ? (
        <Paper style={{ padding: 8, position: 'relative' }}>
          <Tooltip disableInteractive title={t('general.deselect')}>
            <IconButton
              className={classes.closeIconButton}
              onClick={() => {
                changeValue('product', null)
                setDrug(null)
              }}
              size='large'>
              <CloseCircleOutlineIcon />
            </IconButton>
          </Tooltip>
          <CreateCustomerDrugForm selectedDrug={selectedDrug} />
        </Paper>
      ) : (
        <Grid style={{ width: '100%' }} container spacing={0}>
          <Grid item xs={8}>
            <Autocomplete
              id='searchDrugsField'
              fullWidth
              noOptionsText={t('general.noOptions')}
              loadingText={`${t('general.loading')}...`}
              onChange={(e, val) => setDrug(val)}
              isOptionEqualToValue={(option: PharmacyMasterProduct, value: PharmacyMasterProduct) =>
                option.ean === value.ean
              }
              getOptionLabel={getPharmacyProductTitle}
              options={searchResults}
              loading={loading}
              renderOption={(props, option) => {
                return (
                  <ListItem {...props} key={props.id}>
                    <ListItemIcon>
                      <img
                        style={{ height: 60, width: 50, objectFit: 'contain' }}
                        src={option.image?.Pieni?.Url}
                        alt=''
                        loading='lazy'
                      />
                    </ListItemIcon>
                    <ListItemText
                      className={classes.searchListProductName}
                      primary={getPharmacyProductTitle(option)}
                      secondary={`EAN: ${option.ean} ${option.vnr ? `VNR: ${option.vnr}` : ''}`}
                    />
                  </ListItem>
                )
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  value={searchFieldValue}
                  onChange={e => setSearchFieldValue(e.target.value)}
                  label={t('lists.searchProduct')}
                  variant='outlined'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color='inherit' size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              variant='outlined'
              value={searchType}
              onChange={e => setSearchType(e.target.value as any)}
              select
              label={t('general.criteria')}>
              <MenuItem value={PharmacySearchCriteria.name}>{t('products.pharmacy.name')}</MenuItem>
              <MenuItem value={PharmacySearchCriteria.vnr}>vnr</MenuItem>
              <MenuItem value={PharmacySearchCriteria.ean}>ean</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      )}
    </Fragment>
  )
}

const mapStateToProps = (state: StateInterface): StateProps => ({
  environmentId: selectEnvironmentIdFromPathname(state)
})

export default connect(mapStateToProps)(SearchDrugForm)
