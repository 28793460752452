import React, { Fragment } from 'react'
import { reduxForm, InjectedFormProps, Field } from 'redux-form'
import { useTranslation } from 'react-i18next'
import ArrowExpandAllIcon from 'mdi-react/ArrowExpandAllIcon'
import { InputAdornment } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ContentQRCodeWidgetFormData } from '../../../../../types/contents'
import { ReduxTextField } from '../../../../../components/FormInput/ReduxWrappers'
import { formatQRCodeScale } from '../../../../../utils/forms'
import ContentToolbarItem from '../ContentToolbarItem/ContentToolbarItem'
import CommonContentFields from './CommonContentFields'

const useStyles = makeStyles(() => ({
  adornment: {
    margin: '0px 15px 0px -5px'
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54)'
  }
}))

interface OwnProps {
  someAxisLocked?: boolean
}

type FormProps = ContentQRCodeWidgetFormData

const ContentQRCodeForm: React.FC<OwnProps & InjectedFormProps<FormProps, OwnProps>> = ({
  someAxisLocked
}) => {
  const [t] = useTranslation()
  const classes = useStyles()

  return (
    <Fragment>
      <CommonContentFields
        formName={'ContentQRCodeForm'}
        enableSize={false}
        enableRotation={false}
        enableScale={false}
        t={t}
      />
      <ContentToolbarItem
        tooltip={t(someAxisLocked ? 'contents.actions.objectLocked' : 'contents.properties.scale')}>
        <Field
          isToolbar
          fixedSmallWidth
          name='QRCodeScale'
          hasSpacing
          component={ReduxTextField}
          format={formatQRCodeScale}
          type='number'
          InputProps={{
            inputProps: { step: 0.1 },
            startAdornment: (
              <InputAdornment position='start' className={classes.adornment}>
                <ArrowExpandAllIcon className={classes.icon} />
              </InputAdornment>
            )
          }}
          disabled={someAxisLocked}
        />
      </ContentToolbarItem>
    </Fragment>
  )
}

export default reduxForm<FormProps, OwnProps>({
  form: 'ContentQRCodeForm',
  enableReinitialize: true
})(ContentQRCodeForm)
