import React from 'react'
import { FieldArray, Field } from 'redux-form'
import { Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { TFunction } from 'i18next'
import { ReduxSwitch } from '../../../../../../../components/FormInput/ReduxWrappers'
import MainTableFieldsDragAndDrop from './MainTableFieldsDragAndDrop'

const useStyles = makeStyles(theme => ({
  disableEditSchema: {
    padding: theme.spacing(1)
  }
}))

interface OwnProps {
  t: TFunction
  isSystemAdmin: boolean
  isFieldsDisabled: boolean
}

type ComponentProps = OwnProps

const TableSchemaFields: React.FC<ComponentProps> = ({ t, isSystemAdmin, isFieldsDisabled }) => {
  const classes = useStyles()
  return (
    <Grid container>
      {isSystemAdmin && (
        <Grid item xs={12}>
          <div className={classes.disableEditSchema}>
            <Field
              name='cTableProps.schema.disableEditSchema'
              component={ReduxSwitch}
              label={t('contents.widgets.table.disableEditSchema')}
            />
            <Typography display='block' variant='caption'>
              {t('contents.widgets.table.systemAdminAccessHelper')}
            </Typography>
          </div>
        </Grid>
      )}
      <Grid item xs={12}>
        <FieldArray
          name='cTableProps.schema.fields'
          component={MainTableFieldsDragAndDrop}
          props={{ isFieldsDisabled }}
        />
      </Grid>
    </Grid>
  )
}

export default TableSchemaFields
