import {
  PlayerPharmacyProduct,
  PlayerPricerProduct,
  PlayerRetailProduct,
  PackageUnit,
  PlayerGeneralRetailProduct,
  AdditionalPlayerItemProps
} from '@seesignage/seesignage-utils'
import { MockItems } from '../../../../../types/mocks'
import { fishProductsMock, meatProductsMock } from '../Mocks/misc'
import { lunchItemsMock } from './lunch'

const MOCK_IMAGE_URL =
  'https://seesignage-fonts.s3.eu-central-1.amazonaws.com/images/mock_product_image.png'

const mockRetailProduct: PlayerRetailProduct = {
  qrCodeUrl: 'www.pricer.fi',
  unit: 'kg',
  scaleNumber: '00',
  createdAt: 1552394608268,
  freeText: null,
  strength: null,
  group: null,
  country: 'Maa',
  supplier: null,
  name: 'tuotteen nimi',
  imageUrl: '',
  // note: player will add imagePath (path to file in the device) and it does not exist in database
  imagePath: MOCK_IMAGE_URL,
  packageSize: null,
  discountUnitPrice: null,
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  price: 1,
  productId: '2000505900001'
}

const retailProductsMock: PlayerRetailProduct[] = Array(8).fill(mockRetailProduct)
const pricerVerkkokauppaProductsMock: PlayerPricerProduct[] = Array(8).fill({
  productId: '671536',
  itemId: '671536',
  itemName: 'MSI GeForce RTX 3060 Ti GAMING X TRIO -näytönohjain PCI-e-väylään',
  price: '649.90',
  itemGroup: 'NVIDIA GeForce RTX 3060',
  department: 'Default',
  presentation: 'NORMAL',
  lastUpdated: '2021-01-27T09:05:31+02:00[Europe/Helsinki]',
  validFrom: null,
  sics: ['4719072763145'],
  properties: {
    SUPPLIER: null,
    brandId: '55',
    brandName: 'MSI',
    bulletPointsFi0: 'NVIDIA RTX 3060 TI',
    bulletPointsFi1: '8 Gt GDDR6',
    bulletPointsFi2: 'Huippuluokan suorituskykyä pelaamiseen',
    bulletPointsFi3: '1 x HDMI, 3 x Display Port',
    bulletPointsFi4: 'Ylikellotettu X-malli',
    descriptionShortFi:
      'GeForce RTX 30 -sarjan grafiikkasuorittimet antavat äärimmäisen suorituskyvyn niin pelaajien kuin luovan työn tekijöidenkin käyttöön. Tehon salaisuus on Ampere – NVIDIAN toisen sukupolven RTX-arkkitehtuuri.',
    hrefFi: '/fi/product/39983/qfxfb/MSI-GeForce-RTX-3060-Ti-GAMING-X-TRIO-naytonohjain-PCI-e-vay',
    image300: 'https://cdn.verk.net/300/images/12/2_671536-1651x1556.jpg',
    imageOrig: 'https://cdn.verk.net/images/12/2_671536-1651x1556.jpg',
    pid: null,
    priceCurrent: '649.9',
    priceCurrentFormatted: '649,90',
    priceCurrentTaxless: '524.11',
    priceDeposit: null,
    priceDiscountAmount: null,
    priceDiscountBeginAt: '2020-09-17 16:00:00',
    priceDiscountEndAt: '2021-12-31 23:59:00',
    priceDiscountId: '157759',
    priceDiscountName: 'NVIDIA RTX 3060-sarjan saatavuustiedote',
    priceDiscountPercentage: null,
    priceDiscountType: null,
    priceOriginal: '649.9',
    priceOriginalFormatted: '649,90',
    priceOriginalTaxless: '524.11',
    priceTaxRate: '24',
    priceUnitSymbol: null,
    priceUnitTaxless: null,
    priceUnitValue: null,
    productId: '39983',
    ratingAverageOverallRating: '4',
    ratingOther1: '5',
    ratingOther2: '3.6667',
    ratingOther3: null,
    ratingOther4: null,
    ratingOther5: null,
    ratingRecommendedCount: '1',
    ratingReviewCount: '3'
  },
  links: [],
  // note: player will add imagePath (path to file in the device) and it does not exist in database
  imagePath: MOCK_IMAGE_URL
})

const pricerHankkijaProductsMock: PlayerPricerProduct[] = Array(16).fill({
  department: 'Default',
  itemGroup: null,
  itemId: '00720503',
  itemName: 'RACING BE-VIMIN LIUOS  1X1L',
  lastUpdated: '2024-02-27T13:46:01+02:00[Europe/Helsinki]',
  links: [],
  presentation: null,
  price: '15,99',
  properties: {
    assortmentGroup: 'A1',
    day30ComparisonPrice: '19,99',
    deliveryWarehouse: '770',
    discountEndDate: '1240229',
    discountStartDate: '1240220',
    itemNameWeb: null,
    longDescriptionWeb: null,
    planningGroup: '57',
    pricingType: 'KA',
    pricingUnit: 'KPL',
    printingDate: '1240220',
    printingDateOrderNumber: '1',
    productCode: 'VIF82761',
    productImageUrl: null,
    productPageUrl: 'https://www.hankkija.fi/',
    productStatus: '',
    salesGroup: '718',
    shelfAddress: '',
    unitPrice: '0,00',
    unitPriceUnit: '',
    vatId: '5',
    warrantyMonths: ''
  },
  sics: ['6417679827618'],
  validFrom: null,
  // note: player will add imagePath (path to file in the device) and it does not exist in database
  imagePath: MOCK_IMAGE_URL
})

const pharmacyProductMock: PlayerPharmacyProduct = {
  price: 1,
  name: 'Tuotteen nimi',
  packageMultiplier: 1,
  ean: '591230',
  sku: '123',
  image: {
    Iso: {
      Url: 'http://laakekuva.apteekkariliitto.fi/tuotekuva_iso/Pakkauskuva18590.jpg'
    },
    Pieni: {
      Url: 'http://laakekuva.apteekkariliitto.fi/tuotekuva_pieni/Esikatselu18590.jpg'
    }
  },
  packageUnit: 'yksikkö',
  marketer: 'Markkinoija',
  marketerId: '123',
  packageSize: 10,
  vnr: '591230',
  description:
    'Kuvaus. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
  customerId: 'YR3MW-gwcHpBRdzIB-f22',
  url: 'http://laakekuva.apteekkariliitto.fi/tuotekuva_iso/Pakkauskuva18590.jpg',
  fileMetadata: {
    filename: '591230.png',
    size: 104480,
    url: 'http://laakekuva.apteekkariliitto.fi/tuotekuva_iso/Pakkauskuva18590.jpg'
  },
  imagePath: MOCK_IMAGE_URL,
  updatedAt: 123,
  createdAt: 123,
  pimUpdatedAt: 123
}

const pharmacyProductsMock: PlayerPharmacyProduct[] = Array(8).fill(pharmacyProductMock)

const generalProductsMock: PlayerGeneralRetailProduct[] = [
  {
    name: 'Tuotteen nimi',
    description:
      'kuvaus. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    price: 80,
    discount: true,
    discountPrice: 50,
    discountBatchSize: 1,
    discountValidString: 'Discount valid 2.5-2.6.2020',
    deposit: null,
    unit: 'kpl',
    packageSize: 1,
    packageUnit: PackageUnit.kpl,
    key: 'environments/fe0e5877-87be-4ffe-9120-f172a3164431/images/Ifz3sE5ckwlXTERRdh_6q.png',
    productId: 'a8o9nyaEpCUutmctYSG27',
    customerId: '7e51ec01-90d9-4fd6-bd5e-a9772e2cbcd3',
    updatedAt: 1621615144134,
    imagePath: MOCK_IMAGE_URL
  }
]

const getInitialMockItems = (): MockItems => ({
  retail: retailProductsMock,
  generalRetail: generalProductsMock,
  pricerVerkkokauppa: pricerVerkkokauppaProductsMock,
  pricerHankkija: pricerHankkijaProductsMock,
  pharmacy: pharmacyProductsMock,
  fish: fishProductsMock,
  meat: meatProductsMock,
  lunch: lunchItemsMock
})

const getInitialMockAdditonalProps = (): AdditionalPlayerItemProps => ({
  name: 'Nipputarjouksen nimi',
  description: 'Nipputarjouksen kuvaus',
  price: 1,
  discountPrice: 1,
  discountPercentage: 10
})

export { getInitialMockItems, getInitialMockAdditonalProps }
