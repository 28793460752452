import { StepConnector } from '@mui/material'

import withStyles from '@mui/styles/withStyles'

const ColorConnector = withStyles({
  alternativeLabel: {
    top: 16
  },
  active: {
    '& $line': {
      backgroundImage: 'linear-gradient(135deg, #00EFEC, #00AEEF 30%, #0067F4)'
    }
  },
  completed: {
    '& $line': {
      backgroundImage: 'linear-gradient(135deg, #00EFEC, #00AEEF 30%, #0067F4)'
    }
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1
  }
})(StepConnector)

export default ColorConnector
