import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Select, MenuItem, ListItemIcon, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useDispatch } from 'react-redux'
import BookOutlineIcon from 'mdi-react/BookOutlineIcon'
import PaletteAdvancedIcon from 'mdi-react/PaletteAdvancedIcon'
import SearchField from '../../../components/SearchField'
import { SelectedInfopageType } from '../../../types/infopages'
import { setSelectedInfopageType } from '../../../actions/infopages'

const useStyles = makeStyles(() => ({
  selectTypeInputField: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: ''
  },
  selectedInfopageType: {
    marginLeft: 10,
    marginRight: 10
  },
  infopageTypeIcon: {
    marginRight: 5,
    minWidth: 0
  }
}))

export interface InfopagesSearchFieldProps {
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>
  infopageType?: SelectedInfopageType
  searchTerm: string | null
  supportInfopages: boolean
}

const InfopagesSearchField: React.FC<InfopagesSearchFieldProps> = ({
  setSearchTerm,
  infopageType,
  searchTerm,
  supportInfopages
}) => {
  const [t] = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  return (
    <Fragment>
      <SearchField
        value={searchTerm || ''}
        onChange={e => {
          setSearchTerm(e.target.value)
        }}
        placeholder={t('general.search')}
      />
      {supportInfopages && (
        <Select
          size='small'
          className={classes.selectedInfopageType}
          classes={{ outlined: classes.selectTypeInputField }}
          variant='outlined'
          value={infopageType}
          onChange={e => dispatch(setSelectedInfopageType(e.target.value as SelectedInfopageType))}>
          <MenuItem dense value={SelectedInfopageType.content}>
            <ListItemIcon className={classes.infopageTypeIcon}>
              <BookOutlineIcon fontSize='small' />
            </ListItemIcon>
            <Typography variant='inherit'>{t('infopages.infopage')}</Typography>
          </MenuItem>
          <MenuItem dense value={SelectedInfopageType.infopage}>
            <ListItemIcon className={classes.infopageTypeIcon}>
              <PaletteAdvancedIcon fontSize='small' />
            </ListItemIcon>
            <Typography variant='inherit'>{t('templates.template')}</Typography>
          </MenuItem>
        </Select>
      )}
    </Fragment>
  )
}

export default InfopagesSearchField
