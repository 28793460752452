import { setZoomToPoint, zoomCanvasOneToOne } from './canvasZoom'

const getWorkareaSnapshot = () => {
  const { workarea, canvas } = window
  if (workarea && canvas) {
    const zoom = canvas.getZoom()
    const center = canvas.getVpCenter()
    const viewportTransform = canvas.viewportTransform

    const { left, top } = workarea
    const width = workarea.getScaledWidth()
    const height = workarea.getScaledHeight()
    // set zoom to 1:1 ratio
    zoomCanvasOneToOne()
    const dataUrl = window.canvas.toDataURL({
      format: 'png',
      quality: 1,
      enableRetinaScaling: true,
      top,
      left,
      width,
      height
    })
    // return canvas view to previous state
    setZoomToPoint(zoom, viewportTransform, center)
    return dataUrl
  }
}

const dataUrlToBlob = (dataURI: string) => {
  const binary = atob(dataURI.split(',')[1])
  const array: number[] = []
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i))
  }
  return new Blob([new Uint8Array(array)], { type: 'image/jpg' })
}

/** Get current canvas as blob that can be send to S3 */
const getCurrentCanvasBlob = () => {
  // png as default
  const dataUrl = getWorkareaSnapshot()
  if (dataUrl) {
    return dataUrlToBlob(dataUrl)
  }
}

const downloadCanvasAsImage = (imageName: string) => {
  const dataUrl = getWorkareaSnapshot()
  if (dataUrl) {
    const anchorEl = document.createElement('a')
    anchorEl.href = dataUrl
    anchorEl.download = `${imageName}.png`
    document.body.appendChild(anchorEl) // required for firefox
    anchorEl.click()
  }
}

export { downloadCanvasAsImage, getCurrentCanvasBlob }
