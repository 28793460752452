import { Grid } from '@mui/material'
import React, { Fragment } from 'react'
import { FieldArray, Field, WrappedFieldArrayProps } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { MockProductType } from '../../../../types/mocks'
import { renderRetailProductFields } from '../../../Lists/Forms/CreateRetailProductForm'
import { ReduxTextField } from '../../../../components/FormInput/ReduxWrappers'
import FieldsExpansionPanel from '../../../../components/ExtensionPanels/FieldsExpansionPanel'
import { gridItemProps } from '../Fields/utils'

const productType = MockProductType.retail

interface RenderItemsProps extends WrappedFieldArrayProps {
  t: TFunction
}

const renderItems: React.FC<any> = ({
  t,
  fields,
  meta: { error, submitFailed }
}: RenderItemsProps) => {
  return (
    <Fragment>
      {submitFailed && error && <span>{error}</span>}
      {fields.map((member: string, index: number) => (
        <FieldsExpansionPanel customLabel={`Product ${index + 1}`} key={`${index}-${member}`}>
          <Grid container spacing={2}>
            {renderRetailProductFields(t, member)}
            <Grid {...gridItemProps}>
              <Field
                fullWidth
                name={`${member}.imagePath`}
                label='imagePath'
                component={ReduxTextField}
              />
            </Grid>
          </Grid>
        </FieldsExpansionPanel>
      ))}
    </Fragment>
  )
}

const RetailFormFields = () => {
  const [t] = useTranslation()
  return (
    <Grid container spacing={2} alignItems='center'>
      <FieldArray name={productType} component={renderItems} props={{ t }} />
    </Grid>
  )
}

export default RetailFormFields
