import { Button, DialogActions, LinearProgress, Typography } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import React, { Fragment } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Prompt } from 'react-router'
import { Dispatch } from 'redux'
import { RetailListUI, ProductForm } from '@seesignage/seesignage-utils'
import {
  deselectListItem,
  getList,
  reorderItems,
  saveRetailList,
  selectListItem
} from '../../../../actions/lists'
import { getItemStyle } from '../../../../components/Draggable/DraggableItems'
import PageTitle from '../../../../components/PageTitle'
import {
  selectIsListModified,
  selectListById,
  selectListIsLoading,
  selectSelectedListItemIds
} from '../../../../selectors/lists'
import { selectContentIdFromPathname } from '../../../../selectors/routing'
import { StateInterface } from '../../../../types/states'
import { selectCustomerProductForm } from '../../../../selectors/customers'
import { BreadcrumbContentType } from '../../../../types/breadcrumbs'
import EditListToolbar from './EditListToolbar'
import ListItem from './ListItem'

const styles = () => ({
  items: {
    minHeight: 600,
    marginBottom: 50
  },
  emptyList: {
    marginTop: 8,
    textAlign: 'center' as any
  },
  list: {
    maxWidth: 600,
    margin: 'auto',
    padding: 12
  }
})

const handleOnDragEnd = (result: any, reorderItems: any) => {
  if (result.destination && result.destination.index !== result.source.index) {
    reorderItems({
      startIndex: result.source.index,
      endIndex: result.destination.index
    })
  }
}

interface StateProps {
  list?: RetailListUI
  customerProductForm?: ProductForm
  selectedListItemIds: string[]
  isLoading: boolean
  listModified: boolean
}

interface DispatchProps {
  selectListItem: (id: string) => void
  deselectListItem: (id: string) => void
  reorderItems: (indexes: any) => void
  saveList: () => void
  cancelChanges: () => void
}

type EditListProps = StateProps & DispatchProps & WithStyles<typeof styles> & WithTranslation

/**
 * View for editing retail list. NOT general.
 */
const EditList: React.FC<EditListProps> = ({
  classes,
  selectedListItemIds,
  selectListItem,
  deselectListItem,
  list,
  isLoading,
  reorderItems,
  listModified,
  saveList,
  cancelChanges,
  customerProductForm,
  t
}) => {
  if (!list) {
    return <LinearProgress />
  }
  const onDragEnd = (result: any) => {
    handleOnDragEnd(result, reorderItems)
  }
  const dragDropProps = {
    onDragEnd
  }
  const { name, items, listId } = list
  return (
    <Fragment>
      <Prompt when={listModified} message={t('lists.unsavedChangesPrompt')} />
      <PageTitle contentType={BreadcrumbContentType.lists} contentId={listId} contentName={name} />
      <EditListToolbar
        list={list}
        selectedListItemIds={selectedListItemIds}
        customerProductForm={customerProductForm}
      />
      {isLoading ? (
        <LinearProgress />
      ) : (
        <div className={classes.items}>
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button variant='contained' onClick={cancelChanges} disabled={!listModified}>
              {t('general.cancel')}
            </Button>
            <Button disabled={!listModified} variant='contained' color='primary' onClick={saveList}>
              {t('templates.forms.save')}
            </Button>
          </DialogActions>
          {items.length ? (
            <DragDropContext {...dragDropProps}>
              <Droppable droppableId='droppableList'>
                {({ innerRef, placeholder }) => (
                  <div ref={innerRef} className={classes.list}>
                    {items.map((item, index) => {
                      const { itemId } = item
                      return (
                        <Draggable key={itemId} draggableId={itemId} index={index}>
                          {({ innerRef, draggableProps, dragHandleProps }, { isDragging }) => {
                            const isItemSelected = selectedListItemIds.includes(itemId)
                            return (
                              <div
                                onClick={() =>
                                  isItemSelected ? deselectListItem(itemId) : selectListItem(itemId)
                                }
                                ref={innerRef}
                                {...draggableProps}
                                {...dragHandleProps}
                                style={getItemStyle(
                                  isDragging,
                                  draggableProps.style,
                                  isItemSelected
                                )}>
                                <ListItem item={item} />
                              </div>
                            )
                          }}
                        </Draggable>
                      )
                    })}
                    {placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <Typography className={classes.emptyList} variant='subtitle1' gutterBottom>
              {t('lists.listEmpty')}
            </Typography>
          )}
        </div>
      )}
    </Fragment>
  )
}
const mapStateToProps = (state: StateInterface): StateProps => {
  const list = selectListById(selectContentIdFromPathname(state))(state) as RetailListUI | undefined
  const selectedListItemIds = selectSelectedListItemIds(state)
  return {
    list,
    customerProductForm: selectCustomerProductForm(list?.customerId)(state),
    selectedListItemIds,
    isLoading: selectListIsLoading(state),
    listModified: selectIsListModified(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  selectListItem: (itemId: string) => dispatch(selectListItem(itemId)),
  deselectListItem: (itemId: string) => dispatch(deselectListItem(itemId)),
  reorderItems: (index: any) => dispatch(reorderItems(index)),
  saveList: () => dispatch(saveRetailList()),
  cancelChanges: () => dispatch(getList())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(EditList)))
