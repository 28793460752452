import { Typography } from '@mui/material'
import Rating from '@mui/material/Rating'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import LinearProgress from '@mui/material/LinearProgress'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Field, reduxForm, InjectedFormProps, WrappedFieldProps } from 'redux-form'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { required } from '../../validation'
import { ReduxTextField } from '../../components/FormInput/ReduxWrappers'
import { bindSubmitActionToPromise } from '../../utils/forms'
import { submitFeedback } from '../../actions/feedback'
import { closeDialog } from '../../actions/dialogs'
import { CloseDialog } from '../../types/actions'
import ErrorMessage from '../../components/Errors/ErrorMessage'

const RatingField = ({ input: { onChange, value }, ...rest }: WrappedFieldProps) => (
  <Rating
    {...rest}
    name='rating'
    value={value}
    onChange={(event, newValue) => {
      onChange(newValue)
    }}
  />
)

interface FeedbackFormData {
  feedback: string
  rating: number
}

interface OwnProps {
  dialogId: string
}
interface DispatchProps {
  closeDialog: CloseDialog
  submitFeedback: (formData: FeedbackFormData) => void
}

type FeedbackFormProps = OwnProps & DispatchProps

const FeedbackForm: React.FC<FeedbackFormProps &
  InjectedFormProps<FeedbackFormData, FeedbackFormProps>> = ({
  handleSubmit,
  submitFeedback,
  closeDialog,
  submitting,
  dialogId,
  error
}) => {
  const [t] = useTranslation()
  return (
    <form onSubmit={handleSubmit(submitFeedback)}>
      <Field
        fullWidth
        name='feedback'
        component={ReduxTextField}
        multiline
        rows='8'
        variant='outlined'
        required
        validate={[required]}
      />

      <Typography variant='body1'>{t('feedback.rating')}</Typography>
      <Field name='rating' component={RatingField} />

      {submitting && <LinearProgress />}
      <ErrorMessage message={error} />
      <Typography display='block' variant='caption'>
        {t('feedback.info')}
      </Typography>
      <DialogActions>
        <Button onClick={() => closeDialog(dialogId)} color='primary'>
          {t('general.cancel')}
        </Button>
        <Button disabled={submitting} color='primary' type='submit'>
          {t('general.send')}
        </Button>
      </DialogActions>
    </form>
  )
}
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  closeDialog: (dialogId: string) => dispatch(closeDialog(dialogId)),
  submitFeedback: bindSubmitActionToPromise(dispatch, submitFeedback)
})

export default connect(
  null,
  mapDispatchToProps
)(
  reduxForm<FeedbackFormData, FeedbackFormProps>({
    form: 'FeedbackForm'
  })(FeedbackForm)
)
