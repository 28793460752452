import { useState, useEffect, useCallback } from 'react'

/**
 * Hook to capture long press events (should be used only with mobile devices)
 * @param callback callback function to be called after ms timeout
 * @param ms timeout milliseconds, defaults to 300
 */
const useLongPress = (callback: () => void, ms = 300) => {
  const [startLongPress, setStartLongPress] = useState(false)

  useEffect(() => {
    let timerId: any
    if (startLongPress) {
      timerId = window.setTimeout(callback, ms)
    }

    return () => {
      window.clearTimeout(timerId)
    }
  }, [callback, ms, startLongPress])

  const start = useCallback(() => {
    setStartLongPress(true)
  }, [])
  const stop = useCallback(() => {
    setStartLongPress(false)
  }, [])

  return {
    onTouchStart: start,
    onTouchEnd: stop,
    onContextMenu: (e: React.MouseEvent<any, MouseEvent>) => e.preventDefault()
  }
}

export default useLongPress
