import React from 'react'
import classNames from 'classnames'
import { Tooltip, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import appColors from '../../../styles/common/colors'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    gap: '5px',
    flexWrap: 'wrap',
    width: '255px'
  },
  color: {
    width: '24px',
    height: '24px',
    borderRadius: '3px',
    cursor: 'pointer',
    boxShadow: '1px 1px 5px 1px rgba(0, 0, 0, 0.1)',
    transitionDuration: '200ms',
    outline: '2px solid rgba(0, 0, 0, 0)'
  },
  selectedColor: {
    outline: `2px solid ${appColors.seesignageColor}`
  },
  title: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '8px 0px 8px 0px'
  }
}))

interface PresetColorSelectorProps {
  title: string
  colors: string[]
  onChange: (color: string) => void
  value: string
}

const PresetColorSelector: React.FC<PresetColorSelectorProps> = ({
  title,
  colors,
  onChange,
  value
}) => {
  const classes = useStyles()
  return (
    <div>
      <Typography className={classes.title}>{title}</Typography>
      <div className={classes.container}>
        {colors.map((color, index) => (
          <Tooltip disableInteractive title={color} key={`${color}-${index}`}>
            <div
              style={{
                background: color
              }}
              className={classNames({
                [classes.color]: true,
                [classes.selectedColor]: value === color
              })}
              onClick={() => onChange(color)}
            />
          </Tooltip>
        ))}
      </div>
    </div>
  )
}

export default PresetColorSelector
