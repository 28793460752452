import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Paper,
  Table,
  TableBody,
  TablePagination
} from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import DeleteIcon from 'mdi-react/DeleteIcon'
import PencilIcon from 'mdi-react/PencilIcon'
import React, { Fragment, useState } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import {
  GeneralProductUI,
  ListType,
  ProductForm,
  RetailProduct
} from '@seesignage/seesignage-utils'
import { TFunction } from 'i18next'
import { closeDialog } from '../../actions/dialogs'
import {
  createOrUpdateGeneralProduct,
  createOrUpdateRetailProduct,
  deleteProduct
} from '../../actions/products'
import { EnhancedTableHead } from '../../components/Table'
import { bindSubmitActionToPromise } from '../../utils/forms'
import { compareStrings, sortTable } from '../../utils/sorting'
import Dialog from '../Dialog'
import CreateProductWizard from '../Lists/Forms/CreateProductWizard/CreateProductWizard'
import CreateRetailProductForm from '../Lists/Forms/CreateRetailProductForm'
import {
  CloseDialog,
  CreateOrUpdateGeneralProduct,
  CreateOrUpdateRetailProduct
} from '../../types/actions'
import { DeleteProductParams } from '../../types/products'
import {
  getGeneralProductFormInitialValues,
  getRetailProductFormInitialValues
} from '../../utils/products'
import { SortDirection } from '../../types/sortings'
import RetailProductRow from './RetailProductRow'

const getColumnData = () => [
  { id: 'name', disablePadding: false, label: 'general.name', sortable: true },
  { id: 'price', disablePadding: false, label: 'product.price' },
  { id: 'description', disablePadding: false, label: 'product.description' },
  { id: 'createdAt', disablePadding: false, label: 'general.updatedAt' },
  { id: 'actions', disablePadding: true, label: '' }
]

const styles = () => ({
  root: {
    margin: '10px 10px 70px 10px'
  },
  table: {
    minWidth: 350,
    height: '100%'
  },
  tableWrapper: {
    overflowX: 'auto' as any,
    overflowY: 'hidden' as any,
    height: '100%'
  }
})

interface RenderActionsProps {
  product: GeneralProductUI | RetailProduct
  createOrUpdateGeneralProduct: CreateOrUpdateGeneralProduct
  createOrUpdateRetailProduct: CreateOrUpdateRetailProduct
  closeDialog: CloseDialog
  deleteProduct: (params: DeleteProductParams) => void
  type: ListType
  t: TFunction
  productForm: string
}

const renderActions = ({
  product,
  createOrUpdateGeneralProduct,
  createOrUpdateRetailProduct,
  closeDialog,
  deleteProduct,
  type,
  t,
  productForm
}: RenderActionsProps) => {
  const { productId, name } = product
  return (
    <Grid container spacing={1} justifyContent='flex-start'>
      <Grid item xs={6}>
        <Dialog
          dialogId={`UpdateProductDialog.${productId}`}
          title={t('lists.updateProduct')}
          tooltipTitle={t('lists.updateProduct')}
          ButtonIcon={PencilIcon}
          maxWidth={productForm === ProductForm.general ? 'xl' : 'md'}
          Content={
            productForm === ProductForm.general ? (
              <CreateProductWizard
                onSubmit={createOrUpdateGeneralProduct}
                submitButtonLabel={t('general.update')}
                initialValues={getGeneralProductFormInitialValues(product as GeneralProductUI)}
              />
            ) : (
              <CreateRetailProductForm
                dialogId={`UpdateProductDialog.${productId}`}
                onSubmit={createOrUpdateRetailProduct}
                submitButtonLabel={t('general.update')}
                closeDialog={closeDialog}
                imageUrl={(product as RetailProduct).imageUrl}
                disableProductIdField
                initialValues={getRetailProductFormInitialValues(product as RetailProduct)}
              />
            )
          }
        />
      </Grid>
      <Grid item xs={6}>
        <Dialog
          dialogId={`removeProductDialog.${productId}`}
          title={`${t('product.delete')} (${name})`}
          ButtonIcon={DeleteIcon}
          ButtonIconProps={{ color: 'red' }}
          tooltipTitle={t('product.delete')}
          Content={
            <Fragment>
              <DialogContent>
                <DialogContentText>{t('product.deleteConfirm', { name })}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => closeDialog(`removeProductDialog.${productId}`)}
                  color='primary'>
                  {t('general.disagree')}
                </Button>
                <Button
                  onClick={() => deleteProduct({ productId, type })}
                  color='primary'
                  autoFocus>
                  {t('general.agree')}
                </Button>
              </DialogActions>
            </Fragment>
          }
        />
      </Grid>
    </Grid>
  )
}

interface OwnProps {
  products: RetailProduct[] | GeneralProductUI[]
  productsType: ListType
  searchTerm: string
  productForm: string
}

interface DispatchProps {
  createOrUpdateGeneralProduct: CreateOrUpdateGeneralProduct
  createOrUpdateRetailProduct: CreateOrUpdateRetailProduct
  deleteProduct: (params: DeleteProductParams) => void
  closeDialog: CloseDialog
}

type ProductsTableProps = OwnProps & DispatchProps & WithStyles<typeof styles> & WithTranslation

const ProductsTable: React.FC<ProductsTableProps> = ({
  classes,
  products,
  productsType,
  searchTerm,
  createOrUpdateGeneralProduct,
  createOrUpdateRetailProduct,
  closeDialog,
  deleteProduct,
  productForm,
  t
}) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [order, setOrder] = useState<SortDirection>(SortDirection.asc)
  const [orderBy, setOrderBy] = useState('name')

  const handleSortRequest = (property: string) => {
    sortTable(property, order, orderBy, setOrder, setOrderBy)
  }
  const tableHeadProps = {
    hasActions: true,
    columnData: getColumnData(),
    order,
    orderBy,
    handleSortRequest,
    t
  }

  const visibleProducts =
    searchTerm.length > 0
      ? (products as RetailProduct[]).filter(product => product.name.includes(searchTerm))
      : products
  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <EnhancedTableHead {...tableHeadProps} />
          <TableBody>
            {(visibleProducts as Array<GeneralProductUI | RetailProduct>)
              .sort(compareStrings(orderBy, order))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(product => (
                <RetailProductRow
                  key={product.productId}
                  product={product}
                  ActionsField={renderActions({
                    product,
                    createOrUpdateGeneralProduct,
                    createOrUpdateRetailProduct,
                    closeDialog,
                    deleteProduct,
                    type: productsType,
                    t,
                    productForm
                  })}
                  t={t}
                />
              ))}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        component='div'
        count={visibleProducts.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page'
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page'
        }}
        onPageChange={(event, page) => setPage(page)}
        onRowsPerPageChange={event => setRowsPerPage(parseInt(event.target.value, 10))}
      />
    </Paper>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeDialog: () => dispatch(closeDialog()),
  createOrUpdateGeneralProduct: bindSubmitActionToPromise(dispatch, createOrUpdateGeneralProduct),
  createOrUpdateRetailProduct: bindSubmitActionToPromise(dispatch, createOrUpdateRetailProduct),
  deleteProduct: (params: DeleteProductParams) => dispatch(deleteProduct(params))
})

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(ProductsTable)))
