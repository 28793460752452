import { initialize, change } from 'redux-form'
import { fabric } from 'fabric'
import {
  isFabricCircleObject,
  isFabricDateObject,
  isFabricIframeObject,
  isFabricImageObject,
  isFabricMediaCarouselObject,
  isFabricPolygonObject,
  isFabricQRCodeObject,
  isFabricRectObject,
  isFabricSocialMediaObject,
  isFabricStopScheduleObject,
  isFabricTableObject,
  isFabricTextboxObject,
  isFabricTriangleObject,
  isFabricVideoObject,
  isFabricWeatherObject
} from '@seesignage/seesignage-player-utils/lib'
import {
  deselectObject,
  selectObject,
  setContentModified,
  updateEditorOption
} from '../../actions/contents'
import { getStore } from '../../configureStore'
import { isUserSystemAdmin } from '../../selectors/users'
import { selectEditorOptions } from '../../selectors/contents'
import { EditorCursorMode, EditorOptions } from '../../types/contents'

import {
  generateCircleInitialValues,
  generateIframeInitialValues,
  generateImageInitialValues,
  generateRectangleInitialValues,
  generateTextBoxInitialValues,
  generateTriangleInitialValues,
  generateVideoInitialValues,
  generateStopScheduleInitialValues,
  generateTableInitialValues,
  generateDateWidgetInitialValues,
  generateWeatherWidgetInitialValues,
  generateMediaCarouselToolbarInitialValues,
  generateSocialMediaSettingsInitialValues,
  generateSocialMediaToolbarInitialValues,
  generateQRCodeInitialValues,
  generateMediaCarouselSettingsInitialValues
} from './canvasFormValues'
import { drawLineUtils, drawPolygonUtils, freeDrawingUtils } from './drawingUtils'
import { getActiveObjectGroup } from './canvas'
import canvasGuidelines from './canvasGuidelines'
import { moveViewArea } from './canvasZoom'

interface SetSelectedObjectPropertiesProps {
  selectedObjects: fabric.Object[]
  isSystemAdmin: boolean
}

const setSelectedObjectProperties = ({
  selectedObjects,
  isSystemAdmin
}: SetSelectedObjectPropertiesProps) => {
  for (const obj of selectedObjects) {
    if (isFabricTableObject(obj)) {
      // disable moving table when styles edit disabled and not admin user
      if (obj.customOptions.widgetProps.schema.disableEditStyles && !isSystemAdmin) {
        obj.lockMovementX = true
        obj.lockMovementY = true
        obj.lockScalingX = true
        obj.lockScalingY = true
      } else {
        obj.lockScalingX = false
        obj.lockScalingY = false
      }
    }
  }
}

const handleActiveSelection = () => {
  const activeObjectGroup = getActiveObjectGroup()
  if (activeObjectGroup) {
    // filter out locked objects from the selection
    const lockedObjects = activeObjectGroup._objects.filter(
      obj => obj.lockMovementX || obj.lockMovementY
    )

    lockedObjects.forEach(obj => {
      activeObjectGroup.removeWithUpdate(obj)
    })

    // hide controls so user cant scale the group
    activeObjectGroup.set({
      hasControls: false
    })

    window.canvas.renderAll()
  }
}

const handleObjectSelection = (e: fabric.IEvent<MouseEvent>) => {
  const selectedObjects = e.selected ?? undefined
  if (selectedObjects && selectedObjects.length > 0) {
    handleActiveSelection()
    const store = getStore()
    store.dispatch(selectObject(selectedObjects))
    const state = store.getState()
    const isSystemAdmin = isUserSystemAdmin(state as any)
    setSelectedObjectProperties({ selectedObjects, isSystemAdmin })
  }
}

const initCanvasEventHandlers = (canvas: fabric.Canvas) => {
  const store = getStore()
  const dispatchContentModified = () => store.dispatch(setContentModified())
  const canvasGuidelinesEvents = canvasGuidelines(canvas)
  const drawLine = drawLineUtils(canvas)
  const drawPolygon = drawPolygonUtils(canvas)
  const freeDrawing = freeDrawingUtils()

  // Render Events
  canvas.on('after:render', canvasGuidelinesEvents.afterRender)
  canvas.on('before:render', canvasGuidelinesEvents.beforeRender)

  // Mouse Events
  canvas.on('mouse:dblclick', e => {
    const { target } = e
    if (isFabricPolygonObject(target)) {
      const mode = !target.customOptions.pointEditMode
      store.dispatch(change('ContentPolygonForm', 'pointEditMode', mode))
    }
  })
  canvas.on('mouse:down', e => {
    const { cursorMode } = selectEditorOptions(store.getState()) as EditorOptions
    if (cursorMode === EditorCursorMode.drawingLine) {
      drawLine.start(e)
    } else if (cursorMode === EditorCursorMode.drawingPolygon) {
      drawPolygon.addPoint(e)
    } else if (cursorMode === EditorCursorMode.grab) {
      store.dispatch(updateEditorOption({ dragActive: true }))
    } else {
      canvasGuidelinesEvents.mouseDown(
        canvas.viewportTransform || [1, 0, 0, 1, 0, 0],
        canvas.getZoom()
      )
    }
  })
  canvas.on('mouse:move', e => {
    const { cursorMode, dragActive } = selectEditorOptions(store.getState() as any)

    if (cursorMode === EditorCursorMode.drawingLine) {
      drawLine.draw(e)
    } else if (cursorMode === EditorCursorMode.drawingPolygon) {
      drawPolygon.draw(e)
    } else if (cursorMode === EditorCursorMode.freeDrawing) {
      return
    } else if (cursorMode === EditorCursorMode.grab) {
      if (dragActive) {
        moveViewArea(e)
      }
    } else {
      canvasGuidelinesEvents.mouseMove(e)
    }
  })
  canvas.on('mouse:up:before', fEvent => {
    // when selected element's drag ends update toolbar form values
    // NOTE: for some reason fabricjs type char encoding does not match with strings in comparison
    if (isFabricImageObject(fEvent.target)) {
      store.dispatch(initialize('ContentImageForm', generateImageInitialValues(fEvent.target)))
    } else if (isFabricVideoObject(fEvent.target)) {
      store.dispatch(initialize('ContentVideoForm', generateVideoInitialValues(fEvent.target)))
    } else if (isFabricIframeObject(fEvent.target)) {
      store.dispatch(initialize('ContentIframeForm', generateIframeInitialValues(fEvent.target)))
    } else if (isFabricTableObject(fEvent.target)) {
      store.dispatch(initialize('ContentTableForm', generateTableInitialValues(fEvent.target)))
    } else if (isFabricDateObject(fEvent.target)) {
      store.dispatch(initialize('ContentDateForm', generateDateWidgetInitialValues(fEvent.target)))
    } else if (isFabricWeatherObject(fEvent.target)) {
      store.dispatch(
        initialize('ContentWeatherForm', generateWeatherWidgetInitialValues(fEvent.target))
      )
    } else if (isFabricStopScheduleObject(fEvent.target)) {
      store.dispatch(
        initialize('ContentStopScheduleForm', generateStopScheduleInitialValues(fEvent.target))
      )
    } else if (isFabricMediaCarouselObject(fEvent.target)) {
      store.dispatch(
        initialize(
          'ContentMediaCarouselToolbarForm',
          generateMediaCarouselToolbarInitialValues(fEvent.target)
        )
      )
      store.dispatch(
        initialize(
          'ContentMediaCarouselSettingsForm',
          generateMediaCarouselSettingsInitialValues(fEvent.target)
        )
      )
    } else if (isFabricSocialMediaObject(fEvent.target)) {
      store.dispatch(
        initialize(
          'ContentSocialMediaToolbarForm',
          generateSocialMediaToolbarInitialValues(fEvent.target)
        )
      )
      store.dispatch(
        initialize(
          'ContentSocialMediaSettingsForm',
          generateSocialMediaSettingsInitialValues(fEvent.target)
        )
      )
    } else if (isFabricQRCodeObject(fEvent.target)) {
      store.dispatch(initialize('ContentQRCodeForm', generateQRCodeInitialValues(fEvent.target)))
      store.dispatch(initialize('QRCodeWidgetForm', generateQRCodeInitialValues(fEvent.target)))
    } else if (isFabricTextboxObject(fEvent?.target)) {
      store.dispatch(initialize('ContentTextBoxForm', generateTextBoxInitialValues(fEvent.target)))
    } else if (isFabricRectObject(fEvent?.target)) {
      store.dispatch(
        initialize('ContentRectangleForm', generateRectangleInitialValues(fEvent.target))
      )
      // } else if (fEvent?.target?.isType('triangle')) {
    } else if (isFabricTriangleObject(fEvent?.target)) {
      store.dispatch(
        initialize('ContentTriangleForm', generateTriangleInitialValues(fEvent.target))
      )
    } else if (isFabricCircleObject(fEvent?.target)) {
      store.dispatch(initialize('ContentCircleForm', generateCircleInitialValues(fEvent.target)))
    }
  })
  canvas.on('mouse:up', () => {
    const { cursorMode } = selectEditorOptions(store.getState()) as EditorOptions
    if (cursorMode === EditorCursorMode.drawingLine) {
      drawLine.end()
    } else if (cursorMode === EditorCursorMode.grab) {
      store.dispatch(updateEditorOption({ dragActive: false }))
    } else {
      canvasGuidelinesEvents.mouseUp()
    }
  })

  // Object events
  canvas.on('object:added', dispatchContentModified)
  canvas.on('object:modified', dispatchContentModified)
  canvas.on('object:removed', dispatchContentModified)

  // Path events
  canvas.on('path:created', freeDrawing.end)

  // Selection events
  canvas.on('selection:cleared', () => store.dispatch(deselectObject()))
  canvas.on('selection:created', handleObjectSelection)
  canvas.on('selection:updated', handleObjectSelection)

  // Text events
  // enable save content after text changed
  canvas.on('text:changed', dispatchContentModified)
}

export { initCanvasEventHandlers }
