import React from 'react'
import { reduxForm, InjectedFormProps } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { ContentDateWidgetFormData } from '../../../../../types/contents'
import CommonContentFields from './CommonContentFields'

type FormProps = ContentDateWidgetFormData

const ContentDateForm: React.FC<InjectedFormProps<FormProps>> = () => {
  const [t] = useTranslation()
  return <CommonContentFields formName={'ContentDateForm'} enableSize t={t} />
}
export default reduxForm<FormProps>({
  form: 'ContentDateForm'
})(ContentDateForm)
