import { LinearProgress } from '@mui/material'
import {
  selectAvailableTemplateOrientations,
  InfopageExtensive,
  ContentMetadataByKey,
  TemplateOrientation
} from '@seesignage/seesignage-utils'
import React, { Fragment } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Prompt } from 'react-router'
import { Dispatch } from 'redux'
import { formValueSelector, isPristine, isSubmitting, submit } from 'redux-form'
import { updateInfopage } from '../../../actions/infopages'
import PageTitle from '../../../components/PageTitle'
import {
  selectInfopageById,
  selectIsInfopageSettingsModified,
  selectInfopageKeys
} from '../../../selectors/infopages'
import { selectContentIdFromPathname } from '../../../selectors/routing'
import { StateInterface } from '../../../types/states'
import { bindSubmitActionToPromise } from '../../../utils/forms'
import CreateInfopageForm from '../Forms/CreateInfopageForm'
import { BreadcrumbContentType } from '../../../types/breadcrumbs'
import EditInfopageToolbar from './EditInfopageToolbar'

interface StateProps {
  infopage?: InfopageExtensive
  infopageKeys?: ContentMetadataByKey
  selectedOrientation: TemplateOrientation
  isFormPristine: boolean
  submitting: boolean
  isSettingsModified: boolean
}

interface DispatchProps {
  updateInfopage: (formData: any) => void
  submitForm: () => void
}

type EditInfopageProps = StateProps & DispatchProps & WithTranslation

const EditInfopage: React.FC<EditInfopageProps> = ({
  selectedOrientation,
  updateInfopage,
  submitForm,
  isFormPristine,
  infopage,
  infopageKeys,
  submitting,
  isSettingsModified,
  t
}) => {
  if (!infopage || !infopage.template || !infopageKeys) {
    return <LinearProgress />
  }
  const orientations = selectAvailableTemplateOrientations(infopage.template)
  const { name, templateId, templateEnvironmentId, infopageId } = infopage
  return (
    <Fragment>
      <Prompt
        when={!isFormPristine || isSettingsModified}
        message={t('lists.unsavedChangesPrompt')}
      />
      <PageTitle
        contentType={BreadcrumbContentType.infopages}
        contentId={infopageId}
        contentName={name}
      />
      <EditInfopageToolbar
        initialValues={{
          name,
          template: { templateId, templateEnvironmentId }
        }}
        isFormPristine={isFormPristine}
        submitting={submitting}
        submitForm={submitForm}
        isSettingsModified={isSettingsModified}
      />
      <CreateInfopageForm
        submitAction={updateInfopage}
        infopageTemplate={infopage.template}
        initialValues={{
          infopageId: infopage.infopageId,
          content: infopage.content,
          name: infopage.name,
          template: { templateId, templateEnvironmentId },
          selectedOrientation: selectedOrientation || orientations[0]
        }}
        infopageKeys={infopageKeys}
      />
    </Fragment>
  )
}
const mapStateToProps = (state: StateInterface): StateProps => {
  const infopageId = selectContentIdFromPathname(state)
  return {
    infopage: selectInfopageById(infopageId)(state) as InfopageExtensive | undefined,
    infopageKeys: selectInfopageKeys(state),
    isFormPristine: isPristine('CreateInfopageForm')(state),
    submitting: isSubmitting('CreateInfopageForm')(state),
    isSettingsModified: selectIsInfopageSettingsModified(state),
    selectedOrientation: formValueSelector('CreateInfopageForm')(state, 'selectedOrientation')
  }
}
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  updateInfopage: bindSubmitActionToPromise(dispatch, updateInfopage),
  submitForm: () => dispatch(submit('CreateInfopageForm'))
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditInfopage))
