import React from 'react'
import { Divider, Typography } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  border: {
    borderBottom: `1px solid black`,
    width: '100%'
  },
  content: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    textTransform: 'uppercase',
    fontSize: 10
  }
}))

interface MenuDividerProps {
  label: string
  isMenuOpen: boolean
}

const MenuDivider: React.FC<MenuDividerProps> = ({ isMenuOpen, label }) => {
  const classes = useStyles()
  return isMenuOpen ? (
    <div className={classes.container}>
      <div className={classes.border} />
      <Typography variant='caption' className={classes.content}>
        {label}
      </Typography>
      <div className={classes.border} />
    </div>
  ) : (
    <Divider />
  )
}

export default MenuDivider
