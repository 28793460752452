import { Theme, TableCell } from '@mui/material'

import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white
    },
    body: {
      fontSize: 14
    }
  })
)(TableCell)

export default StyledTableCell
