import { Grid, MenuItem, Paper, Typography, Button, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useState, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, reduxForm, InjectedFormProps, formValueSelector, change } from 'redux-form'
import { TemplateType, CreateTemplateType } from '@seesignage/seesignage-utils'
import { TFunction } from 'i18next'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import DeleteIcon from 'mdi-react/DeleteIcon'
import PlusIcon from 'mdi-react/PlusIcon'
import DialogActions from '../../../components/DialogActions'
import ErrorMessage from '../../../components/Errors/ErrorMessage'
import { CloseDialog, CreateTemplate, UpdateTemplate } from '../../../types/actions'
import { required, customInterval } from '../../../validation'
import {
  ReduxTextField,
  ReduxDurationField,
  ReduxSwitch
} from '../../../components/FormInput/ReduxWrappers'
import { StateInterface } from '../../../types/states'
import { parseNumberField } from '../../../utils/forms'

const useStyles = makeStyles(theme => ({
  formContainer: {
    marginBottom: theme.spacing()
  },
  durationContainer: {
    display: 'flex'
  },
  removeDurationIcon: {
    margin: 8
  }
}))

interface TemplateTypeSelectionFieldProps {
  isUpdate?: boolean
  t: TFunction
}
const TemplateTypeSelectionField = ({ isUpdate, t }: TemplateTypeSelectionFieldProps) => (
  <Grid item xs={12}>
    <Field
      fullWidth
      name='type'
      label={t('lists.type')}
      component={ReduxTextField}
      select
      required
      validate={[required]}
      disabled={isUpdate}>
      {Object.values(isUpdate ? TemplateType : CreateTemplateType).map(templateType => (
        <MenuItem key={templateType} value={templateType}>
          {templateType}
        </MenuItem>
      ))}
    </Field>
  </Grid>
)

interface OwnProps {
  submitButtonLabel: string
  onSubmit: CreateTemplate | UpdateTemplate
  closeDialog: CloseDialog
  dialogId: string
  /**
   * Is form used to update template.
   * If so, disable changing template type.
   */
  isUpdate?: boolean
  initialValues?: any
}

interface StateProps {
  selectedType?: TemplateType
  selectedIsMasterTemplate?: boolean
}

interface DispatchProps {
  clearDuration: () => void
}

type CreateTemplateFormProps = OwnProps & StateProps & DispatchProps

const CreateTemplateForm: React.FC<CreateTemplateFormProps &
  InjectedFormProps<{}, CreateTemplateFormProps>> = ({
  submitButtonLabel,
  handleSubmit,
  onSubmit,
  submitting,
  closeDialog,
  dialogId,
  isUpdate,
  selectedType,
  selectedIsMasterTemplate,
  clearDuration,
  error,
  initialValues
}) => {
  const [durationVisible, setDurationVisible] = useState(initialValues?.duration ? true : false)
  const [t] = useTranslation()
  const classes = useStyles()

  const removeDuration = () => {
    clearDuration()
    setDurationVisible(false)
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} className={classes.formContainer}>
        <Grid item xs={12} sm={12}>
          <Field
            fullWidth
            name='name'
            label={t('general.name')}
            component={ReduxTextField}
            required
            validate={[required]}
          />
        </Grid>
        <TemplateTypeSelectionField t={t} isUpdate={isUpdate} />
        {(selectedType === TemplateType.retail || selectedType === TemplateType.lunch) && (
          <Fragment>
            <Grid item xs={12}>
              <Field
                disabled={isUpdate}
                name='isMasterTemplate'
                label={t('templates.supportMultipleProducts')}
                component={ReduxSwitch}
              />
            </Grid>
            {!selectedIsMasterTemplate && (
              <Grid item xs={12}>
                <Field
                  fullWidth
                  name='maxItems'
                  label={t('templates.maxItems')}
                  component={ReduxTextField}
                  type='number'
                />
              </Grid>
            )}
          </Fragment>
        )}
      </Grid>
      {durationVisible ? (
        <Grid item xs={5}>
          <Paper style={{ textAlign: 'center' }}>
            <Typography variant='caption'>{`${t('media.duration')} (${t(
              'general.optional'
            )})`}</Typography>
            <div className={classes.durationContainer}>
              <Field
                fullWidth
                name='duration'
                component={ReduxDurationField}
                parse={parseNumberField}
                type='number'
                required
                validate={customInterval}
              />
              <IconButton
                className={classes.removeDurationIcon}
                onClick={() => removeDuration()}
                size='large'>
                <DeleteIcon />
              </IconButton>
            </div>
          </Paper>
        </Grid>
      ) : (
        <Button startIcon={<PlusIcon />} onClick={() => setDurationVisible(true)}>
          {t('templates.forms.addDuration')}
        </Button>
      )}

      <ErrorMessage message={error} />
      <DialogActions
        submitButtonLabel={submitButtonLabel}
        submitting={submitting}
        closeDialog={closeDialog}
        dialogId={dialogId}
      />
    </form>
  )
}
const mapStateToProps = (state: StateInterface): StateProps => ({
  selectedType: formValueSelector('CreateTemplateForm')(state, 'type'),
  selectedIsMasterTemplate: formValueSelector('CreateTemplateForm')(state, 'isMasterTemplate')
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  clearDuration: () => dispatch(change('CreateTemplateForm', 'duration', null))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<{}, CreateTemplateFormProps>({
    form: 'CreateTemplateForm'
  })(CreateTemplateForm)
)
