import React from 'react'
import { useTranslation } from 'react-i18next'
import { FieldArray } from 'redux-form'
import { Grid, IconButton, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import DeleteIcon from 'mdi-react/DeleteIcon'
import TableField from './TableFields'

const useStyles = makeStyles(theme => ({
  subFieldsContainer: {
    padding: theme.spacing(1)
  },
  removeButton: {}
}))

interface SubFieldsProps {
  /** redux-form field name prefix */
  item: string
  /** redux-form fieldArray item index */
  index: number
  removeItem: () => void
  isFieldsDisabled: boolean
}
const SubFields: React.FC<SubFieldsProps> = ({ item, removeItem, isFieldsDisabled }) => {
  const [t] = useTranslation()
  const classes = useStyles()
  return (
    <Grid container spacing={2} key={`${item}.subFields`} className={classes.subFieldsContainer}>
      <Grid item xs={10}>
        <FieldArray
          name={`${item}.subFields`}
          component={TableField}
          props={{ t, isFieldsDisabled }}
        />
      </Grid>
      {!isFieldsDisabled && (
        <Grid item xs={2}>
          <Tooltip disableInteractive title={t('contents.widgets.table.deleteField')}>
            <IconButton onClick={() => removeItem()} size='small' disabled={isFieldsDisabled}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  )
}

export default SubFields
