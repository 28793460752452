import { LinearProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment'
import React, { useState } from 'react'
import { Calendar, momentLocalizer, View } from 'react-big-calendar'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { Channel, ChannelItemPriority, LogicalOperator } from '@seesignage/seesignage-utils'
import {
  createChannelItem,
  deleteChannelItem,
  openChannelsDialog,
  updateChannelItem,
  openEditChannelItem,
  closeEditChannelItem
} from '../../../actions/channels'
import Container from '../../../components/Container'
import PageTitle from '../../../components/PageTitle'
import {
  selectChannelById,
  selectSelectedCalendarEvent,
  selectIsEditChannelItemOpen
} from '../../../selectors/channels'
import { selectPlaylists } from '../../../selectors/playlists'
import { selectContentIdFromPathname } from '../../../selectors/routing'
import {
  CreateChannelItem,
  ForceRunTour,
  OpenDialog,
  UpdateChannelItem
} from '../../../types/actions'
import { CalendarEvent } from '../../../types/channels'
import { StateInterface } from '../../../types/states'
import {
  convertChannelToEvents,
  eventPropGetter,
  getRecurringRuleFormInitialValue,
  onSelectSlot
} from '../../../utils/channels'
import { bindSubmitActionToPromise } from '../../../utils/forms'
import Dialog from '../../Dialog'
import CreateChannelItemForm from '../Forms/CreateChannelItemForm'
import { BreadcrumbContentType } from '../../../types/breadcrumbs'
import { sortByPriority } from '../../../utils/sorting'
import { forceRunTour } from '../../../actions/tours'
import { Tour } from '../../../types/tours'
import { deselectPlaylist } from '../../../actions/playlists'
import { selectCampaigns } from '../../../selectors/campaigns'
import { deselectCampaign } from '../../../actions/campaigns'
import { validateDocumentWriteAccess } from '../../../utils/permissions'
import { selectUser } from '../../../selectors/users'
import { SortDirection } from '../../../types/sortings'
import EditChannelToolbar from './EditChannelToolbar'
import Event from './Event'
import EditChannelItem from './EditChannelItem'
import CalendarLegend from './CalendarLegend'

const localizer = momentLocalizer(moment)

const formats = {
  timeGutterFormat: 'HH:mm'
}

const useStyles = makeStyles({
  calendarContainer: {
    height: '72vh',
    margin: 10
  }
})

interface StateProps {
  channel?: Channel
  events: CalendarEvent[]
  selectedCalendarEvent?: CalendarEvent
  isEditChannelItemOpen: boolean
  hasWriteAccess: boolean
}

interface DispatchProps {
  openDialog: OpenDialog
  createItem: CreateChannelItem
  updateItem: UpdateChannelItem
  deleteItem: (itemId: string) => void
  openEditChannelItem: (params: any) => void
  closeEditChannelItem: () => void
  forceRunTour: ForceRunTour
}

type EditChannelProps = StateProps & DispatchProps

const EditChannel: React.FC<EditChannelProps> = ({
  channel,
  events,
  openDialog,
  createItem,
  updateItem,
  deleteItem,
  selectedCalendarEvent,
  openEditChannelItem,
  closeEditChannelItem,
  isEditChannelItemOpen,
  forceRunTour,
  hasWriteAccess
}) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const [view, setView] = useState<View>('month')
  if (!channel) {
    return <LinearProgress />
  }

  const { name, channelId, syncPlay } = channel

  const editChannelItemProps = {
    isEditChannelItemOpen,
    closeEditPlaylist: closeEditChannelItem,
    updateItem,
    deleteItem,
    selectedCalendarEvent
  }
  return (
    <Container>
      <PageTitle
        contentType={BreadcrumbContentType.channels}
        contentId={channelId}
        contentName={name}
      />
      <EditChannelToolbar
        createItem={createItem}
        forceRunTour={forceRunTour}
        syncPlay={syncPlay}
        hasWriteAccess={hasWriteAccess}
      />
      <div className={classes.calendarContainer} id='channel-calendar'>
        <Calendar
          selectable={hasWriteAccess}
          // Show truncated events in an overlay when you click the "+_x_ more" link.
          popup
          step={15}
          formats={formats}
          localizer={localizer}
          events={events}
          defaultView='month'
          scrollToTime={new Date()}
          defaultDate={new Date()}
          eventPropGetter={event => eventPropGetter(event)}
          onSelectEvent={event => openEditChannelItem({ event })}
          onSelectSlot={event => onSelectSlot(event, events, view, openDialog, t)}
          onView={view => setView(view)}
          views={{ month: true, week: true, day: true }}
          components={
            {
              event: Event
            } as any
          }
          messages={{
            month: t('calendar.month'),
            week: t('calendar.week'),
            day: t('calendar.day'),
            today: t('calendar.today'),
            agenda: t('calendar.agenda'),
            next: t('calendar.next'),
            previous: t('calendar.previous'),
            event: t('calendar.event'),
            time: t('calendar.time'),
            date: t('calendar.date'),
            showMore: count => t('calendar.more', { count })
          }}
        />
      </div>
      <CalendarLegend />
      <Dialog
        dialogId='createChannelItem'
        title={t('channels.actions.createChannelItem')}
        noOpenDialogButton
        Content={
          <CreateChannelItemForm
            name='CreateChannelItemForm'
            submitButtonLabel={t('general.save')}
            dialogId='createChannelItem'
            onSubmit={createItem}
            initialValues={{
              channelItem: {
                start: selectedCalendarEvent ? selectedCalendarEvent.start : undefined,
                end: selectedCalendarEvent ? selectedCalendarEvent.end : undefined,
                recurringRule: getRecurringRuleFormInitialValue(selectedCalendarEvent),
                priority: ChannelItemPriority.low,
                conditions: {
                  operator: LogicalOperator.or
                }
              },
              playlist: {
                defaultInterval: channel.defaultPlaylistInterval || 7,
                syncPlay
              },
              isNewPlaylist: false
            }}
          />
        }
      />

      <EditChannelItem {...editChannelItemProps} />
    </Container>
  )
}

const mapStateToProps = (state: StateInterface): StateProps => {
  const channelId = selectContentIdFromPathname(state)
  const channel = selectChannelById(channelId)(state)
  const playlists = selectPlaylists(state)
  const campaigns = selectCampaigns(state)
  const user = selectUser(state)
  return {
    channel,
    events: channel
      ? convertChannelToEvents(channel, playlists, campaigns).sort(
          sortByPriority(SortDirection.desc)
        )
      : [],
    selectedCalendarEvent: selectSelectedCalendarEvent(state),
    isEditChannelItemOpen: selectIsEditChannelItemOpen(state),
    hasWriteAccess: channel && user ? validateDocumentWriteAccess(channel, user) : false
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  openDialog: ({ event, id }: any) => dispatch(openChannelsDialog({ event, id })),
  createItem: bindSubmitActionToPromise(dispatch, createChannelItem),
  updateItem: bindSubmitActionToPromise(dispatch, updateChannelItem),
  deleteItem: (itemId: string) => dispatch(deleteChannelItem(itemId)),
  openEditChannelItem: ({ event }: any) => dispatch(openEditChannelItem({ event })),
  closeEditChannelItem: () => {
    dispatch(deselectPlaylist())
    dispatch(deselectCampaign())
    dispatch(closeEditChannelItem())
  },
  forceRunTour: (tour: Tour) => dispatch(forceRunTour(tour))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditChannel)
