import React, { Fragment } from 'react'
import { Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ArrowRightIcon from 'mdi-react/ArrowRightIcon'
import HtmlTooltip from '../../../../components/Tooltip/HtmlTooltip'
import { StyledTableRow, StyledTableCell } from '../../../../components/Table'
import { EMMiFolderReport } from '../../../../types/integrations'

const ImportReport: React.FC<EMMiFolderReport> = ({
  assetsByTags,
  assetsByScreenTypes,
  assetsByCustomSchedule,
  assets,
  extraContent
}) => {
  const [t] = useTranslation()
  const tagRows = Object.entries(assetsByTags).map(([group, value]) => ({
    group,
    tags: Object.entries(value).map(([tag, assetIds]) => ({
      tag,
      assetIds
    }))
  }))
  tagRows.sort((a, b) => a.group.localeCompare(b.group))
  tagRows.forEach(tagRow => tagRow.tags.sort((a, b) => a.tag.localeCompare(b.tag)))
  const customScheduleRows = Object.entries(assetsByCustomSchedule).map(([group, value]) => ({
    group,
    keys: Object.entries(value).map(([range, assetIds]) => ({
      range,
      assetIds
    }))
  }))
  return (
    <Table stickyHeader size='small'>
      <TableHead>
        <TableRow>
          <TableCell>
            <b>
              {t('environment.screenTypes')}
              <ArrowRightIcon />
            </b>
          </TableCell>
          {Object.keys(assetsByScreenTypes).map(type => (
            <TableCell key={type}>
              <b>{type}</b>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <StyledTableRow>
          <StyledTableCell>{t('integrations.emmi.contentAmount')}</StyledTableCell>
          {Object.entries(assetsByScreenTypes).map(([type, assetIds]) => (
            <HtmlTooltip
              key={type}
              title={
                <Fragment>
                  {assetIds.map((id, index) => (
                    <Typography key={id} variant='caption' display='block'>
                      <b>{index + 1}</b>. {assets[id].name}
                    </Typography>
                  ))}
                </Fragment>
              }>
              <StyledTableCell color={assetIds.length === 0 ? 'red' : undefined} key={type}>
                {assetIds.length === 0 ? (
                  <b style={{ color: 'orange' }}>{assetIds.length}</b>
                ) : (
                  assetIds.length
                )}
              </StyledTableCell>
            </HtmlTooltip>
          ))}
        </StyledTableRow>
        {tagRows.map(({ group, tags }) => {
          return (
            <Fragment key={group}>
              <StyledTableRow>
                <StyledTableCell>
                  <b>{group}</b>
                </StyledTableCell>
              </StyledTableRow>
              {tags.map(({ tag, assetIds }) => (
                <StyledTableRow key={tag}>
                  <StyledTableCell>{tag}</StyledTableCell>
                  {Object.entries(assetsByScreenTypes).map(([screenType, screenAssetIds]) => {
                    const typeAssetIds = assetIds.filter(assetId =>
                      screenAssetIds.some(screenAssetId => screenAssetId === assetId)
                    )
                    return (
                      <HtmlTooltip
                        key={`${tag}-${screenType}`}
                        title={
                          <Fragment>
                            {typeAssetIds.map((id, index) => (
                              <Typography key={id} variant='caption' display='block'>
                                <b>{index + 1}</b>. {assets[id].name}
                              </Typography>
                            ))}
                          </Fragment>
                        }>
                        <StyledTableCell key={`${tag}-${screenType}-cell`}>
                          {typeAssetIds.length === 0 ? (
                            <b style={{ color: 'orange' }}>{typeAssetIds.length}</b>
                          ) : (
                            typeAssetIds.length
                          )}
                        </StyledTableCell>
                      </HtmlTooltip>
                    )
                  })}
                </StyledTableRow>
              ))}
            </Fragment>
          )
        })}
        {customScheduleRows.map(({ group, keys }) => (
          <Fragment key={group}>
            <StyledTableRow>
              <StyledTableCell>
                <b>{group}</b>
              </StyledTableCell>
            </StyledTableRow>
            {keys.map(({ range, assetIds }) => (
              <StyledTableRow key={range}>
                <StyledTableCell>{range}</StyledTableCell>
                {Object.entries(assetsByScreenTypes).map(([screenType, screenAssetIds]) => {
                  const typeAssetIds = assetIds.filter(assetId =>
                    screenAssetIds.some(screenAssetId => screenAssetId === assetId)
                  )
                  return (
                    <HtmlTooltip
                      key={`${range}-${screenType}`}
                      title={
                        <Fragment>
                          {typeAssetIds.map((id, index) => (
                            <Typography key={id} variant='caption' display='block'>
                              <b>{index + 1}</b>. {assets[id].name}
                            </Typography>
                          ))}
                        </Fragment>
                      }>
                      <StyledTableCell key={`${range}-${screenType}-cell`}>
                        {typeAssetIds.length === 0 ? (
                          <b style={{ color: 'orange' }}>{typeAssetIds.length}</b>
                        ) : (
                          typeAssetIds.length
                        )}
                      </StyledTableCell>
                    </HtmlTooltip>
                  )
                })}
              </StyledTableRow>
            ))}
          </Fragment>
        ))}
        <StyledTableRow key={`Extra-content`}>
          <StyledTableCell>
            <b>Extra</b>
          </StyledTableCell>
          {Object.entries(assetsByScreenTypes).map(([screenType]) => {
            const typeAssetIds = extraContent.filter(({ screenTypes }) =>
              screenTypes.includes(screenType)
            )
            return (
              <HtmlTooltip
                key={`extra-content-${screenType}`}
                title={
                  <Fragment>
                    {typeAssetIds.map(({ assetId }, index) => (
                      <Typography key={assetId} variant='caption' display='block'>
                        <b>{index + 1}</b>. {assets[assetId].name}
                      </Typography>
                    ))}
                  </Fragment>
                }>
                <StyledTableCell key={`extra-content-${screenType}-cell`}>
                  {typeAssetIds.length === 0 ? (
                    <b style={{ color: 'orange' }}>{typeAssetIds.length}</b>
                  ) : (
                    typeAssetIds.length
                  )}
                </StyledTableCell>
              </HtmlTooltip>
            )
          })}
        </StyledTableRow>
      </TableBody>
    </Table>
  )
}

export default ImportReport
