import { LinearProgress, Typography } from '@mui/material'
import { WithStyles } from '@mui/styles'
import withStyles from '@mui/styles/withStyles'
import React, { Fragment, useCallback, useState } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Prompt } from 'react-router'
import { Dispatch } from 'redux'
import { DragDropContext } from 'react-beautiful-dnd'
import { LunchList } from '@seesignage/seesignage-utils/lib/types/lunchLists'
import { Template } from '@seesignage/seesignage-utils'
import { closeDialog } from '../../../../actions/dialogs'
import PageTitle from '../../../../components/PageTitle'
import {
  selectListById,
  selectListIsLoading,
  selectIsLunchListModified,
  selectSelectedLunchItem
} from '../../../../selectors/lists'
import { selectContentIdFromPathname } from '../../../../selectors/routing'
import { selectIsUserDeviceMobile } from '../../../../selectors/users'
import { bindSubmitActionToPromise } from '../../../../utils/forms'
import {
  CloseDialog,
  DeleteLunchListItem,
  CreateOrUpdateLunchListGroup,
  CreateOrUpdateLunchListProduct,
  CancelLunchListChanges,
  SetSelectedLunchItem,
  UpdateLunchListItems,
  ReorderLunchListItem,
  PublishLunchList,
  AddLunchListProductChanges,
  AddLunchListWeek,
  DeleteLunchListWeek
} from '../../../../types/actions'
import { StateInterface } from '../../../../types/states'
import {
  deleteLunchListItem,
  createOrUpdateLunchListGroup,
  createOrUpdateLunchListProduct,
  reorderLunchListItem,
  addLunchListWeek,
  deleteLunchListWeek,
  getList,
  setSelectedLunchListItem,
  updateLunchListItems,
  publishLunchList,
  addLunchListProductChanges
} from '../../../../actions/lists'
import { SelectedLunchItem } from '../../../../types/lists'
import { selectCurrentEnvironmentHasSubEnvironments } from '../../../../selectors/environments'
import { selectTemplateById } from '../../../../selectors/templates'
import { BreadcrumbContentType } from '../../../../types/breadcrumbs'
import Groups, { GroupsProps } from './Groups'
import LunchListToolbar, { LunchListToolbarProps } from './LunchListToolbar'
import Products, { ProductsProps } from './Products'
import Weeks, { WeeksProps } from './Weeks'
import WeeksInfo, { WeeksInfoProps } from './WeeksInfo'
import LunchListPreview, { LunchListPreviewProps } from './LunchListPreview'

const styles = () => ({
  mobileViewNotSupported: {
    padding: 10
  },
  leftToolbar: {
    top: 128, // height of the top toolbars
    left: 'auto' as 'auto',
    position: 'sticky' as 'sticky',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    width: 250,
    height: 1000,
    float: 'left' as 'left',
    padding: 10,
    zIndex: 999
  },
  rightContent: {
    marginLeft: 275,
    padding: 10
  }
})

interface StateProps {
  list?: LunchList
  listIsLoading: boolean
  isMobile: boolean
  lunchListModified: boolean
  selectedItem: SelectedLunchItem
  isSubList: boolean
  hasSubEnvironments: boolean
  template?: Template
}

interface DispatchProps {
  createOrUpdateLunchListGroup: CreateOrUpdateLunchListGroup
  createOrUpdateLunchListProduct: CreateOrUpdateLunchListProduct
  deleteLunchListItem: DeleteLunchListItem
  cancelLunchListChanges: CancelLunchListChanges
  reorderLunchListItem: ReorderLunchListItem
  addLunchListProductChanges: AddLunchListProductChanges
  addLunchListWeek: AddLunchListWeek
  deleteLunchListWeek: DeleteLunchListWeek
  updateLunchListItems: UpdateLunchListItems
  publishLunchList: PublishLunchList
  closeDialog: CloseDialog
  setSelectedItem: SetSelectedLunchItem
}

type EditLunchListProps = StateProps & DispatchProps & WithTranslation & WithStyles<typeof styles>

const EditLunchList: React.FC<EditLunchListProps> = ({
  classes,
  list,
  listIsLoading,
  closeDialog,
  lunchListModified,
  deleteLunchListItem,
  createOrUpdateLunchListGroup,
  createOrUpdateLunchListProduct,
  addLunchListProductChanges,
  updateLunchListItems,
  addLunchListWeek,
  deleteLunchListWeek,
  cancelLunchListChanges,
  setSelectedItem,
  selectedItem,
  reorderLunchListItem,
  publishLunchList,
  hasSubEnvironments,
  isSubList,
  template,
  isMobile,
  t
}) => {
  const [isPreviewOpen, openPreview] = useState<boolean>(false)
  const [currentDraggableProductId, setCurrentDraggableProductId] = useState<undefined | string>(
    undefined
  )

  const togglePreview = useCallback(() => {
    openPreview(!isPreviewOpen)
  }, [isPreviewOpen])

  if (!template) {
    return <Fragment />
  }

  if (isMobile) {
    return (
      <div className={classes.mobileViewNotSupported}>
        <Typography>{t('lists.lunch.mobileViewNotSupported')}</Typography>
      </div>
    )
  }

  if (!list || listIsLoading) {
    return <LinearProgress />
  }

  const { name: listName, groups, products, weeks, startDate, listId } = list

  const toolbarProps: LunchListToolbarProps = {
    selectedItem,
    t,
    createOrUpdateLunchListGroup,
    createOrUpdateLunchListProduct,
    addLunchListProductChanges,
    deleteLunchListItem,
    closeDialog,
    setSelectedItem,
    groups,
    isSubList,
    publishLunchList,
    hasSubEnvironments,
    list,
    products,
    weeks,
    togglePreview
  }

  const groupsProps: GroupsProps = {
    setSelectedItem,
    closeDialog,
    createOrUpdateLunchListGroup,
    groups: Object.values(groups),
    selectedItem,
    t
  }

  const productsProps: ProductsProps = {
    setSelectedItem,
    closeDialog,
    createOrUpdateLunchListProduct,
    products,
    groups,
    selectedItem,
    setCurrentDraggableProductId,
    weeks,
    isSubList,
    t
  }

  const weeksProps: WeeksProps = {
    setSelectedItem,
    closeDialog,
    addLunchListWeek,
    deleteLunchListWeek,
    weeks,
    groups,
    products,
    selectedItem,
    cancelLunchListChanges,
    updateLunchListItems,
    lunchListModified,
    isSubList,
    startDate,
    template,
    t
  }

  const infoProps: WeeksInfoProps = {
    isSubList,
    list,
    template,
    t
  }

  const previewProps: LunchListPreviewProps = {
    template,
    list,
    isPreviewOpen,
    togglePreview,
    t
  }

  return (
    <Fragment>
      <Prompt when={lunchListModified} message={t('lists.unsavedChangesPrompt')} />
      <PageTitle
        contentType={BreadcrumbContentType.lists}
        contentId={listId}
        contentName={listName}
      />
      <LunchListToolbar {...toolbarProps} />
      <DragDropContext
        onDragEnd={({ source, destination }) => {
          if (destination) {
            const sourceId = source.droppableId
            const destinationId = destination.droppableId
            const sourceIndex = source.index
            const destinationIndex = destination.index
            reorderLunchListItem({
              sourceId,
              destinationId,
              sourceIndex,
              destinationIndex,
              productId: currentDraggableProductId
            })
          }
        }}>
        <div className={classes.leftToolbar}>
          <Groups {...groupsProps} />
          <Products {...productsProps} />
        </div>
        <div className={classes.rightContent}>
          <Weeks {...weeksProps} />
          <WeeksInfo {...infoProps} />
        </div>
      </DragDropContext>
      {template && template.components && <LunchListPreview {...previewProps} />}
    </Fragment>
  )
}

const mapStateToProps = (state: StateInterface): StateProps => {
  const list = selectListById(selectContentIdFromPathname(state))(state) as LunchList | undefined
  return {
    list,
    lunchListModified: selectIsLunchListModified(state),
    listIsLoading: selectListIsLoading(state),
    isMobile: selectIsUserDeviceMobile(state),
    selectedItem: selectSelectedLunchItem(state),
    hasSubEnvironments: selectCurrentEnvironmentHasSubEnvironments(state),
    isSubList: !!list?.parentList,
    template: selectTemplateById(list?.templateId)(state) as Template | undefined
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  createOrUpdateLunchListGroup: bindSubmitActionToPromise(dispatch, createOrUpdateLunchListGroup),
  createOrUpdateLunchListProduct: bindSubmitActionToPromise(
    dispatch,
    createOrUpdateLunchListProduct
  ),
  addLunchListWeek: bindSubmitActionToPromise(dispatch, addLunchListWeek),
  addLunchListProductChanges: bindSubmitActionToPromise(dispatch, addLunchListProductChanges),
  updateLunchListItems: () => dispatch(updateLunchListItems()),
  deleteLunchListItem: (item: SelectedLunchItem) => dispatch(deleteLunchListItem(item)),
  deleteLunchListWeek: (index: number) => dispatch(deleteLunchListWeek(index)),
  reorderLunchListItem: (item: any) => dispatch(reorderLunchListItem(item)),
  closeDialog: (dialogId: string) => dispatch(closeDialog(dialogId)),
  publishLunchList: () => dispatch(publishLunchList()),
  setSelectedItem: (item: SelectedLunchItem) => dispatch(setSelectedLunchListItem(item)),
  cancelLunchListChanges: () => dispatch(getList())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withTranslation()(EditLunchList)))
