import React, { Fragment, useState, useEffect } from 'react'
import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  TablePagination,
  LinearProgress,
  Grid,
  MenuItem
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { WrappedFieldProps } from 'redux-form'
import { TFunction } from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ContentUI } from '@seesignage/seesignage-utils'
import { SelectedInfopageType } from '../../../types/infopages'
import {
  selectContentsAsArray,
  selectIsContentsLoading,
  sortContentsBySearch
} from '../../../selectors/contents'
import InfopagesInfiniteScroll from '../../../containers/Infopages/InfopagesInfiniteScroll'
import ErrorMessage from '../../Errors/ErrorMessage'
import { compareBySortType } from '../../../utils/sorting'
import { SortByValue, SortDirection } from '../../../types/sortings'
import { listContents } from '../../../actions/contents'
import SearchField from '../../SearchField'
import Sorter from '../../Sorting'

const useStyles = makeStyles(() => ({
  appBar: {
    marginTop: 8
  },
  gridContainer: {
    paddingTop: 8
  },
  imageList: { width: '100%' },
  imageListItem: {
    cursor: 'pointer',
    // overwrite material-ui's list item's image elment
    '& .MuiImageListItem-img': {
      display: 'block',
      margin: 'auto',
      height: '100%',
      width: '100%',
      objectFit: 'contain'
    }
  }
}))

interface SelectInfopagesProps extends WrappedFieldProps {
  label: string
  isMobile: boolean
  t: TFunction
  /** contentId of the content that user is currently editing. */
  currentContentId: string
}

const SelectInfopages: React.FC<SelectInfopagesProps> = ({
  input,
  isMobile,
  t,
  meta,
  currentContentId
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [itemsPerPage, setItemsPerPage] = useState(20)
  const [page, setPage] = React.useState(0)
  const [searchTerm, setSearchTerm] = React.useState('')
  const [sortBy, setSortBy] = useState<SortByValue>(SortByValue.name)
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.asc)
  const isContentsLoading = useSelector(selectIsContentsLoading)
  const isLoading = isContentsLoading
  const contents = useSelector(selectContentsAsArray)
  const contentsWithoutCurrentContent = contents.filter(
    content => content.contentId !== currentContentId
  )

  // initial load for contents
  useEffect(() => {
    dispatch(listContents(true))
  }, [dispatch])

  const visibleInfopages = sortContentsBySearch(contentsWithoutCurrentContent, searchTerm)
    .sort(compareBySortType(sortBy, sortDirection))
    .slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage)

  const { touched, error } = meta

  return (
    <Fragment>
      <Fragment>
        {isLoading && <LinearProgress />}
        <Grid container justifyContent='space-between'>
          <Grid item xs={4}>
            <SearchField
              placeholder={t('general.search')}
              onChange={e => setSearchTerm(e.target.value)}
              value={searchTerm}
            />
          </Grid>
          <Grid item xs={4}>
            <Sorter
              sortBy={sortBy}
              setSortBy={setSortBy}
              sortDirection={sortDirection}
              setSortDirection={setSortDirection}
              t={t}>
              <MenuItem value='name'>{t('sorting.sortBy.name')}</MenuItem>
              <MenuItem value='createdAt'>{t('sorting.sortBy.createdAt')}</MenuItem>
              <MenuItem value='updatedAt'>{t('sorting.sortBy.updatedAt')}</MenuItem>
            </Sorter>
          </Grid>
        </Grid>
        <div className={classes.gridContainer}>
          {visibleInfopages.length > 0 && (
            <InfopagesInfiniteScroll
              searchTerm={searchTerm}
              infopageType={SelectedInfopageType.content}
              hasMoreToLoad={false}
              itemsCount={visibleInfopages.length}
              scrollableTarget='infopages-container'
              t={t}
              height={isMobile ? 400 : 500}
              hasSearchTerm={searchTerm.length > 0}>
              <ImageList
                className={classes.imageList}
                rowHeight={isMobile ? 100 : 188}
                cols={isMobile ? 2 : 3}
                style={{ height: isMobile ? 300 : 500 }}
                id='infopages-container'>
                {(visibleInfopages as ContentUI[]).map(item => {
                  return (
                    <ImageListItem
                      key={item.contentId}
                      className={classes.imageListItem}
                      onClick={() => {
                        input.onBlur(undefined) // set form field as "touched"
                        input.onChange(item)
                      }}>
                      <img src={item?.thumbnailUrl} alt='' />
                      <ImageListItemBar title={item.name} position='top' />
                    </ImageListItem>
                  )
                })}
              </ImageList>
            </InfopagesInfiniteScroll>
          )}
        </div>
        {
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component='div'
            count={contents.length}
            rowsPerPage={itemsPerPage}
            page={page}
            labelRowsPerPage={t('general.show')}
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={(e: any) => setItemsPerPage(e.target.value)}
          />
        }
        {touched && typeof error === 'string' && <ErrorMessage message={error} />}
      </Fragment>
    </Fragment>
  )
}

export default SelectInfopages
