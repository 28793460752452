import React from 'react'
import { useTranslation } from 'react-i18next'
import { FieldArray } from 'redux-form'
import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import SimpleExtensionPanel from '../../../../../../../components/ExtensionPanels/SimpleExtensionPanel'
import TableField from './TableFields'
import StylesFields from './StylesFields'

const useStyles = makeStyles(theme => ({
  subFieldsContainer: {
    // width: '100%',
    margin: theme.spacing(1),
    padding: theme.spacing(1)
  },
  stylesContainer: {
    padding: theme.spacing(1)
  }
}))

interface SubFieldsProps {
  /** redux-form field name prefix */
  item: string
  /** redux-form fieldArray item index */
  index: number
  isFieldsDisabled: boolean
}

const SubFields: React.FC<SubFieldsProps> = ({ item, index, isFieldsDisabled }) => {
  const [t] = useTranslation()
  const classes = useStyles()
  return (
    <div className={classes.subFieldsContainer}>
      <Typography variant='h6' gutterBottom>
        {t('contents.widgets.table.mainFieldTitle', { fieldNumber: index + 1 })}
      </Typography>

      <SimpleExtensionPanel title={t('contents.widgets.table.mainFieldStyles')} isOpen={false}>
        <StylesFields item={item} t={t} isFieldsDisabled={isFieldsDisabled} />
      </SimpleExtensionPanel>

      <FieldArray
        name={`${item}.subFields`}
        component={TableField}
        props={{ t, isFieldsDisabled }}
      />
    </div>
  )
}

export default SubFields
