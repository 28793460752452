import { MediaType } from '@seesignage/seesignage-utils'
import { DialogProps } from '@mui/material'
import { ContentEditorDialog } from './contents'

export interface DialogVisibility {
  id?: string
  isVisible: boolean
}

export interface DialogSize {
  maxWidth?: DialogProps['maxWidth']
  fullScreen?: boolean
}

export enum OpenDialogButtonType {
  /** Opens dialog when image clicked */
  image = 'image',
  /** Rendered as MenuItem button */
  menuItem = 'menuItem',
  /** Common add button */
  add = 'add'
}

export interface OpenContentEditorDialogProps {
  dialogId: ContentEditorDialog
  /**
   * When opening media dialog.
   * If mediaType exists, it means we want to update existing media with certain type.
   */
  mediaType?: MediaType
  isUpdateMode?: boolean
}

export type OpenContentEditorDialog = (props: OpenContentEditorDialogProps) => void
