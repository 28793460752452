import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import { AutocompleteOption } from '@seesignage/seesignage-utils'
import FolderOpenIcon from 'mdi-react/FolderOpenIcon'
import { moveMediaToFolder, moveFolder } from '../../../actions/media'
import { selectAllFoldersAsOptions } from '../../../selectors/media'
import { bindSubmitActionToPromise } from '../../../utils/forms'
import { compareStrings } from '../../../utils/sorting'
import { required } from '../../../validation'
import { ReduxSelectWithAutocomplete } from '../../../components/FormInput/ReduxWrappers'
import { CloseDialog } from '../../../types/actions'
import { MoveFolderFormData } from '../../../types/media'
import { StateInterface } from '../../../types/states'
import ErrorMessage from '../../../components/Errors/ErrorMessage'

interface OwnProps {
  closeDialog: CloseDialog
  dialogId: string
  submitButtonLabel: string
  /** Are we moving a folder or files */
  isFolder?: boolean
  /** folderId that is being moved */
  folderId?: string
}

interface StateProps {
  folders: AutocompleteOption[]
}

interface DispatchProps {
  moveToFolder: (formData: any) => void
}

type ComponentProps = OwnProps & StateProps & DispatchProps

type MoveToFolderFormProps = ComponentProps & WithTranslation

const MoveToFolderForm: React.FC<MoveToFolderFormProps &
  InjectedFormProps<MoveFolderFormData, ComponentProps>> = ({
  handleSubmit,
  submitting,
  folders,
  moveToFolder,
  closeDialog,
  dialogId,
  submitButtonLabel,
  error,
  t
}) => {
  return (
    <form onSubmit={handleSubmit(moveToFolder)}>
      <Field
        name='folder'
        label={t('media.folder')}
        component={ReduxSelectWithAutocomplete}
        OptionIcon={FolderOpenIcon}
        options={[{ value: null, label: 'root' }, ...folders]}
        required
        validate={[required]}
        placeholder={t('general.select')}
      />
      <Typography variant='caption'>{t('media.selectFolderHelper')}</Typography>
      {submitting && <LinearProgress />}
      <ErrorMessage message={error} />
      <DialogActions>
        <Button onClick={() => closeDialog(dialogId)} color='primary'>
          {t('general.cancel')}
        </Button>
        <Button disabled={submitting} color='primary' type='submit'>
          {submitButtonLabel}
        </Button>
      </DialogActions>
    </form>
  )
}
const mapStateToProps = (state: StateInterface, ownProps: OwnProps): StateProps => ({
  folders: selectAllFoldersAsOptions(ownProps.folderId)(state).sort(compareStrings('label'))
})

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps): DispatchProps => ({
  moveToFolder: bindSubmitActionToPromise(
    dispatch,
    ownProps.isFolder ? moveFolder : moveMediaToFolder
  )
})

export default connect<StateProps, DispatchProps, OwnProps, StateInterface>(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<MoveFolderFormData, ComponentProps>({
    form: 'MoveToFolderForm'
  })(withTranslation()(MoveToFolderForm))
)
