import DownloadIcon from 'mdi-react/DownloadIcon'
import { AppBar, Box, Paper, Tab, Tabs, Typography, Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Media, MediaType } from '@seesignage/seesignage-utils'
import { TFunction } from 'i18next'
import React, { useState } from 'react'
import MediaDetails from './MediaDetails'

const useStyles = makeStyles(() => ({
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  downloadButton: {
    color: 'white'
  },
  buttonText: {
    fontSize: '13px',
    paddingRight: '5px',
    paddingLeft: '3px'
  }
}))

interface MediaDialogProps {
  selectedMedia: Media
  t: TFunction
  downloadMediaFiles: (mediaFiles: Media[]) => void
}

const MediaDialog: React.FC<MediaDialogProps> = ({ selectedMedia, t, downloadMediaFiles }) => {
  const classes = useStyles()
  const [currentTab, selectTab] = useState(0)
  const { url, type } = selectedMedia

  return (
    <Paper>
      <AppBar position='static'>
        <div className={classes.headerWrapper}>
          <Tabs
            value={currentTab}
            onChange={(event, newValue) => {
              selectTab(newValue)
            }}
            indicatorColor='secondary'
            textColor='inherit'>
            <Tab label={t('media.preview')} id={`tab-1`} />
            <Tab label={t('media.details')} id={`tab-2`} />
          </Tabs>
          <Button
            className={classes.downloadButton}
            onClick={() => downloadMediaFiles([selectedMedia])}>
            <DownloadIcon />
            <Typography className={classes.buttonText}>{t('media.download')}</Typography>
          </Button>
        </div>
      </AppBar>
      <TabPanel value={currentTab} index={0}>
        <div style={{ textAlign: 'center', width: '100%' }}>
          {type === MediaType.image ? (
            <img style={{ maxHeight: '80vh', maxWidth: '100%' }} src={url} alt='' />
          ) : (
            <video style={{ maxHeight: '80vh', maxWidth: '100%' }} controls src={url} />
          )}
        </div>
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <MediaDetails selectedMedia={selectedMedia} t={t} />
      </TabPanel>
    </Paper>
  )
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const TabPanel = ({ children, index, value }: TabPanelProps) => (
  <div
    role='tabpanel'
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}>
    {value === index && <Box p={3}>{children}</Box>}
  </div>
)

export default MediaDialog
