import { Storage } from 'aws-amplify'
import sanitize from 'sanitize-filename'
import replaceExt from 'replace-ext'
import nanoid from 'nanoid'
import { MediaType } from '@seesignage/seesignage-utils'
import config from '../../config/aws'
import { getStore } from '../../configureStore'
import { setUploadProgress } from '../../actions/media'
import { UploadFileToS3Response } from '../../types/media'
import { getCurrentCanvasBlob } from '../../utils/fabric/canvasFileUtils'

// https://aws.github.io/aws-amplify/media/storage_guide
// https://github.com/aws/aws-amplify/blob/master/docs/media/storage_guide.md

Storage.configure({ level: 'public' })

const customPrefix = {
  public: '',
  protected: 'myProtectedPrefix/',
  private: 'myPrivatePrefix/'
}

/** Dispatch upload progress to redux store */
const dispatchUploadProgress = (key: string, name: string, progress: any) => {
  const percentage = Math.round((progress.loaded / progress.total) * 100)
  getStore().dispatch(setUploadProgress({ key, name, progress: percentage }))
}

const uploadFileToS3 = async (file: any, type: MediaType | 'pdf', environmentId: string) => {
  const currentFilename = file.name
  const fileExtension = currentFilename.split('.').pop()
  const generatedFilename = `${nanoid()}.${fileExtension}`
  const key = `environments/${environmentId}/${type}s/${generatedFilename}`
  try {
    await Storage.vault.put(key, file, {
      bucket: config.s3.mediaUploads.BUCKET,
      level: 'public',
      customPrefix,
      contentType: file.type,
      progressCallback: (progress: any) => {
        dispatchUploadProgress(key, currentFilename, progress)
      }
    })
    return {
      currentFilename,
      key
    } as UploadFileToS3Response
  } catch (error) {
    throw new Error(`Failed to upload ${file} to storage`)
  }
}

const getSanitizedFilename = (name: string) => {
  const sanitizedName = sanitize(name)
  const newName = sanitizedName.replace(/ /g, '_')
  return newName
}

const getTranscodedFileKey = async (
  name: string,
  type: 'video' | 'image',
  environmentId: string
) => {
  const sanitizedFilename = getSanitizedFilename(name)
  if (type === 'image') {
    const transcodedFileName = replaceExt(sanitizedFilename, '.jpg')
    return `${environmentId}/${type}s/${transcodedFileName}`
  } else if (type === 'video') {
    const transcodedFileName = replaceExt(sanitizedFilename, '.mp4')
    return `${environmentId}/${type}s/${transcodedFileName}`
  }
}

const uploadCurrentCanvasToS3 = async (environmentId: string, contentId: string) => {
  const blob = getCurrentCanvasBlob()
  const generatedFilename = `${contentId}.png`
  const key = `contents/environments/${environmentId}/contents/${generatedFilename}`

  try {
    await Storage.vault.put(key, blob, {
      bucket: config.s3.mediaUploads.BUCKET,
      level: 'public',
      customPrefix,
      contentType: 'image/png'
      // progressCallback: (progress: any) => {
      //   dispatchUploadProgress(key, currentFilename, progress)
      // }
    })
    return key
  } catch (error) {
    throw new Error('Failed to upload canvas blob to storage')
  }
}

export default {
  uploadFileToS3,
  getTranscodedFileKey,
  uploadCurrentCanvasToS3
}
