import React, { ComponentType } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { MdiReactIconProps } from 'mdi-react'
import colors from '../../styles/common/colors'

const useStyles = makeStyles(() => ({
  icon: {
    margin: 'auto',
    display: 'block',
    paddingTop: 30
  },
  thumbnailContainer: {
    backgroundColor: 'white',
    height: 100,
    width: 50
  }
}))

interface ThumbnailWithIconProps {
  Icon: ComponentType<MdiReactIconProps>
}

const ThumbnailWithIcon = ({ Icon }: ThumbnailWithIconProps) => {
  const classes = useStyles()
  return (
    <div className={classes.thumbnailContainer}>
      <Icon className={classes.icon} size='40px' color={colors.seesignageColor} />
    </div>
  )
}

export default ThumbnailWithIcon
