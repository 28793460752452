import React, { Fragment } from 'react'
import { reduxForm, InjectedFormProps, formValueSelector } from 'redux-form'
import makeStyles from '@mui/styles/makeStyles'
import { TFunction } from 'i18next'
import { connect } from 'react-redux'
import SimpleExtensionPanel from '../../../../../../components/ExtensionPanels/SimpleExtensionPanel'
import { StateInterface } from '../../../../../../types/states'
import { ContentTableFormData } from '../../../../../../types/contents'
import TableSchemaFields from './TableSchemaFields/TableSchemaFields'
import TableStylesFields from './TableStylesFields'

const useStyles = makeStyles(() => ({
  accordionSummaryRootClass: {
    backgroundColor: '#F5F5F5',
    borderBottom: '1px solid rgba(0, 0, 0, 0.09)'
  },
  accordionDetailsRootClass: {
    backgroundColor: '#F5F5F5',
    padding: 0
  }
}))

type FormProps = ContentTableFormData

interface OwnProps {
  t: TFunction
  isSystemAdmin: boolean
}
interface StateProps {
  disableEditSchema?: boolean
  disableEditStyles?: boolean
}

type ComponentProps = OwnProps & StateProps

const TableSchemaForm: React.FC<ComponentProps & InjectedFormProps<FormProps, ComponentProps>> = ({
  t,
  isSystemAdmin,
  disableEditSchema,
  disableEditStyles
}) => {
  const classes = useStyles()
  return (
    <Fragment>
      <SimpleExtensionPanel
        title={t('contents.widgets.table.schemaSettings')}
        isOpen={false}
        accordionSummaryRootClass={classes.accordionSummaryRootClass}
        accordionDetailsRootClass={classes.accordionDetailsRootClass}>
        <TableSchemaFields
          isFieldsDisabled={isSystemAdmin ? false : !!disableEditSchema}
          t={t}
          isSystemAdmin={isSystemAdmin}
        />
      </SimpleExtensionPanel>
      <SimpleExtensionPanel
        title={t('contents.widgets.table.styleSettings')}
        isOpen={false}
        accordionSummaryRootClass={classes.accordionSummaryRootClass}
        accordionDetailsRootClass={classes.accordionDetailsRootClass}>
        <TableStylesFields
          isFieldsDisabled={isSystemAdmin ? false : !!disableEditStyles}
          t={t}
          isSystemAdmin={isSystemAdmin}
        />
      </SimpleExtensionPanel>
    </Fragment>
  )
}

const selector = formValueSelector('ContentTableSchemaForm')

const mapStateToProps = (state: StateInterface): StateProps => ({
  disableEditSchema: selector(state, 'cTableProps.schema.disableEditSchema'),
  disableEditStyles: selector(state, 'cTableProps.schema.disableEditStyles')
})

export default connect<StateProps, {}, OwnProps, StateInterface>(mapStateToProps)(
  reduxForm<FormProps, ComponentProps>({
    form: 'ContentTableSchemaForm',
    enableReinitialize: true
  })(TableSchemaForm)
)
