const getSelectedEnvironmentIdLocalStorage = () => {
  return localStorage.getItem('selectedEnvironmentId')
}

const saveSelectedEnvironmentIdLocalStorage = (environmentId: string) => {
  localStorage.setItem('selectedEnvironmentId', environmentId)
}

const deselectSelectedEnvironmentIdLocalStorage = () => {
  localStorage.removeItem('selectedEnvironmentId')
}

export {
  getSelectedEnvironmentIdLocalStorage,
  saveSelectedEnvironmentIdLocalStorage,
  deselectSelectedEnvironmentIdLocalStorage
}
