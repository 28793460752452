import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Chip,
  DialogActions,
  DialogContent,
  DialogContentText,
  Paper,
  Tooltip,
  Typography
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import dateFormat from 'dateformat'
import DeleteIcon from 'mdi-react/DeleteIcon'
import ExclamationIcon from 'mdi-react/ExclamationIcon'
import SettingsIcon from 'mdi-react/SettingsIcon'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import LockIcon from 'mdi-react/LockIcon'
import { Channel, Campaign, CampaignSub } from '@seesignage/seesignage-utils'
import SyncIcon from 'mdi-react/RayStartVertexEndIcon'
import PoundBoxIcon from 'mdi-react/PoundBoxIcon'
import PlaylistPlayIcon from 'mdi-react/PlaylistPlayIcon'
import TagIcon from 'mdi-react/TagIcon'
import { CloseDialog, DeleteChannel, Navigate, UpdateChannel } from '../../types/actions'
import { PlaylistsById } from '../../types/playlists'
import { generateChannelCardInformation } from '../../utils/channels'
import Dialog from '../Dialog'
import TruncatedChip from '../../components/Chips'
import colors from '../../styles/common/colors'
import { IndexById } from '../../types/states'
import { ChannelCardItem, ChannelItemType } from '../../types/channels'
import { validateDocumentWriteAccess } from '../../utils/permissions'
import { UserInterface } from '../../types/users'
import { convertTagsForFormField } from '../../utils/conversion'
import CreateChannelForm from './Forms/CreateChannelForm'

const styles = {
  card: {
    width: 300,
    height: 325,
    display: 'inline-block' as any,
    verticalAlign: 'middle' as any,
    margin: '0px 5px 15px',
    position: 'relative' as any
  },
  selectArea: {
    cursor: 'pointer' as any,
    userSelect: 'none' as any,
    '-moz-user-select': 'none' as any,
    '-ms-user-select': 'none' as any,
    '-webkit-user-select': 'none' as any,
    height: 240 as any
  },
  actions: {
    float: 'right' as any,
    position: 'absolute' as any,
    width: '100%',
    bottom: 0,
    right: 0
  },
  titleText: {
    height: '15%',
    fontSize: '1.25rem' as any,
    fontWeight: 600
  },
  subTitleText: {
    width: '100%',
    fontSize: '1rem' as any,
    padding: '4px 0 4px'
  },
  errorTitle: {
    width: '100%',
    fontSize: '0.9rem' as any,
    padding: '4px 0 4px',
    margin: '4px 0 4px',
    color: 'red'
  },
  bodyText: {
    width: '100%',
    height: '80%',
    padding: '4px',
    overflowY: 'auto' as 'auto'
  },
  playlistName: {
    fontSize: '0.8rem' as any,
    padding: '4px 0 4px'
  },
  paper: {
    padding: '4px 6px 4px',
    margin: '4px 0 4px'
  }
}

const useStyles = makeStyles(styles)

interface ItemTextProps {
  item: ChannelCardItem
  classes: Record<string, string>
}

const ItemText = ({ item: { name, startDate, endDate, type }, classes }: ItemTextProps) => (
  <div>
    <div className={classes.playlistName}>
      {dateFormat(startDate, 'dd.mm HH:MM')} - {dateFormat(endDate, 'dd.mm HH:MM')}
      <br />
      <div style={{ display: 'flex', width: '100%' }}>
        {type === ChannelItemType.playlist ? <PlaylistPlayIcon size={24} /> : <TagIcon size={24} />}
        <b style={{ marginLeft: 8 }}>{name}</b>
      </div>
    </div>
  </div>
)

interface OwnProps {
  playlistsById: PlaylistsById
  campaignsById: IndexById<Campaign | CampaignSub>
  channel: Channel
  navigate: Navigate
  closeDialog: CloseDialog
  update: UpdateChannel
  deleteC: DeleteChannel
  user: UserInterface
}

type ChannelCardProps = OwnProps
const ChannelCard: React.FC<ChannelCardProps> = ({
  channel,
  user,
  playlistsById,
  campaignsById,
  navigate,
  deleteC,
  update,
  closeDialog
}) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const {
    channelId,
    environmentId,
    name,
    tags,
    permissionTags,
    syncPlay,
    permissions,
    defaultPlaylistInterval
  } = channel
  const { currentItems, futureItems } = generateChannelCardInformation(
    channel,
    playlistsById,
    campaignsById
  )
  const currentItemsCount = currentItems.length
  const futurePlaylistItemsCount = futureItems.length
  const isWriteDisabled = !validateDocumentWriteAccess(channel, user)
  return (
    <Card className={classes.card}>
      <Tooltip disableInteractive placement='bottom' title={t('channels.actions.open')}>
        <CardActionArea>
          <CardContent
            onClick={() => navigate(`/environments/${environmentId}/channels/${channelId}`)}
            className={classes.selectArea}>
            <Typography noWrap variant='body1' className={classes.titleText} gutterBottom>
              {name}
            </Typography>
            <div className={classes.bodyText}>
              {currentItemsCount > 0 ? (
                <Paper className={classes.paper}>
                  <div className={classes.subTitleText}>
                    {t('channels.card.currentContent', { count: currentItemsCount })}
                  </div>
                  {currentItems.map((item, index: number) => (
                    <ItemText
                      key={`${channelId}-${item.id}-${index}`}
                      item={item}
                      classes={classes}
                    />
                  ))}
                </Paper>
              ) : (
                <Chip
                  icon={<ExclamationIcon />}
                  label={t('channels.card.noCurrentContent')}
                  className={classes.errorTitle}
                />
              )}
              {futurePlaylistItemsCount > 0 && (
                <Paper className={classes.paper}>
                  <div className={classes.subTitleText}>
                    {t('channels.card.futureContent', { count: futurePlaylistItemsCount })}
                  </div>
                  {futureItems.slice(0, 2).map((item, index: number) => (
                    <ItemText
                      key={`${channelId}-${item.id}-${index}`}
                      item={item}
                      classes={classes}
                    />
                  ))}
                  {futurePlaylistItemsCount > 2 && (
                    <div className={classes.playlistName}>
                      {t('channels.card.futureMore', { count: futureItems.length - 2 })}
                    </div>
                  )}
                </Paper>
              )}
            </div>
          </CardContent>
        </CardActionArea>
      </Tooltip>
      <CardActions className={classes.actions}>
        <div style={{ overflow: 'auto', height: 44, width: '100%', paddingLeft: '10px' }}>
          {permissionTags &&
            permissionTags.map(tag => (
              <TruncatedChip
                key={tag}
                tag={tag}
                Icon={LockIcon}
                color='default'
                truncateLimit={20}
              />
            ))}
          {tags &&
            tags.map(tag => (
              <TruncatedChip
                key={tag}
                tag={tag}
                Icon={PoundBoxIcon}
                color='default'
                truncateLimit={20}
              />
            ))}
        </div>
        {syncPlay && (
          <Tooltip disableInteractive title={t('channels.card.syncPlay')}>
            <span>
              <SyncIcon color={colors.seesignageColor} />
            </span>
          </Tooltip>
        )}
        <Dialog
          dialogId={`UpdateChannelDialog.${channelId}`}
          title={`${t('channels.actions.edit')} (${name})`}
          ButtonIcon={SettingsIcon}
          tooltipTitle={
            isWriteDisabled ? t('channels.actions.editNoPermission') : t('channels.actions.edit')
          }
          buttonDisabled={isWriteDisabled}
          Content={
            <CreateChannelForm
              dialogId={`UpdateChannelDialog.${channelId}`}
              submitButtonLabel={t('general.update')}
              onSubmit={update}
              closeDialog={closeDialog}
              isUpdate
              initialValues={{
                name,
                channelId,
                syncPlay,
                tags: tags ? tags.map(tag => ({ value: tag, label: tag })) : undefined,
                permissionTags: convertTagsForFormField(permissionTags),
                permissions,
                defaultPlaylistInterval
              }}
            />
          }
        />
        <Dialog
          dialogId={`removeChannelDialog.${channelId}`}
          title={`${t('channels.actions.removeChannel')} (${name})`}
          ButtonIcon={DeleteIcon}
          tooltipTitle={
            isWriteDisabled
              ? t('channels.actions.removeChannelNoPermission')
              : t('channels.actions.removeChannel')
          }
          buttonDisabled={isWriteDisabled}
          Content={
            <Fragment>
              <DialogContent>
                <DialogContentText>{t('channels.actions.deletionConfirmation')}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => closeDialog(`removeChannelDialog.${channelId}`)}
                  color='primary'>
                  {t('general.cancel')}
                </Button>
                <Button onClick={() => deleteC(channelId)} color='primary' autoFocus>
                  {t('general.delete')}
                </Button>
              </DialogActions>
            </Fragment>
          }
        />
      </CardActions>
    </Card>
  )
}

export default ChannelCard
