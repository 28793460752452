import { API } from 'aws-amplify'
import { Customer, CustomerFormValues } from '@seesignage/seesignage-utils'
import { UpdateCustomerRequestBody } from '../../types/customers'

/** Only system admin can list all customers */
const listAllCustomers = async () => {
  const customers = await API.get('customers', '/customers', {})
  return customers
}

const getCustomers = async (environmentId: string) => {
  const customers = await API.get('customers', `/environments/${environmentId}/customers`, {})
  return customers
}

const getCustomerById = async (environmentId: string, customerId: string) => {
  const customers = await API.get(
    'customers',
    `/environments/${environmentId}/customers/${customerId}`,
    {}
  )
  return customers
}

const createCustomer = async (environmentId: string, customer: CustomerFormValues) => {
  const newCustomer: Customer = await API.post(
    'customers',
    `/environments/${environmentId}/customers`,
    {
      body: customer
    }
  )
  return newCustomer
}

const updateCustomer = async (
  environmentId: string,
  customerId: string,
  customer: UpdateCustomerRequestBody
) => {
  const updatedCustomer: Customer = await API.put(
    'customers',
    `/environments/${environmentId}/customers/${customerId}`,
    { body: customer }
  )
  return updatedCustomer
}

const deleteCustomer = async (environmentId: string, customerId: string) => {
  await API.del('customers', `/environments/${environmentId}/customers/${customerId}`, {})
}

export default {
  listAllCustomers,
  getCustomers,
  getCustomerById,
  createCustomer,
  updateCustomer,
  deleteCustomer
}
