import React from 'react'
import { Snackbar, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Alert from '../../../components/Alert'

interface PlaylistUnsavedChangesProps {
  open: boolean
  closeNotification: () => void
}

const PlaylistUnsavedChanges: React.FC<PlaylistUnsavedChangesProps> = ({
  open,
  closeNotification
}) => {
  const [t] = useTranslation()
  // Note: use Snackbar content inside element. Otherwise, TypeError: Cannot read properties of null (reading 'scrollTop').
  return (
    <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'top' }} open={open}>
      <div>
        <Alert
          severity='error'
          action={
            <Button size='small' color='inherit' onClick={closeNotification}>
              {t('general.ok')}
            </Button>
          }>
          {t('playlists.unsavedChangesPrompt')}
        </Alert>
      </div>
    </Snackbar>
  )
}

export default PlaylistUnsavedChanges
