import { createAction, createAsyncActions } from './utils'

const reducer = 'ROUTES'

const navigate = createAction(reducer, 'NAVIGATE')

const {
  action: navigateToEnvironment,
  actionSuccess: navigateToEnvironmentSuccess,
  actionFail: navigateToEnvironmentFail
} = createAsyncActions(reducer, 'NAVIGATE_TO_ENVIRONMENT')

export { navigate, navigateToEnvironment, navigateToEnvironmentSuccess, navigateToEnvironmentFail }
