import React from 'react'
import { Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import SignalOff from 'mdi-react/SignalOffIcon'
import NetworkOn from 'mdi-react/NetworkIcon'
import NetworkOff from 'mdi-react/NetworkOffIcon'
import { TFunction } from 'i18next'
import { inlineBlockStyle } from '../../../styles/common/styles'
import SignalStrength from './SignalStrength'

const useStyles = makeStyles(() => ({
  connection: {
    minWidth: 50
  },
  inlineBlockStyle
}))

interface ConnectionProps {
  t: TFunction
  signalStrength?: number
  isEthernetConnected: boolean
}

const Connection = ({ signalStrength, isEthernetConnected, t }: ConnectionProps) => {
  const classes = useStyles()
  return (
    <div className={classes.connection}>
      <div className={classes.inlineBlockStyle}>
        {signalStrength ? (
          <SignalStrength signalStrength={signalStrength} />
        ) : (
          <Tooltip disableInteractive title={t('screens.wifiOffline')}>
            <span>
              <SignalOff />
            </span>
          </Tooltip>
        )}
      </div>
      <div className={classes.inlineBlockStyle}>
        {isEthernetConnected ? (
          <Tooltip disableInteractive title={t('screens.ethernetConnected')}>
            <span>
              <NetworkOn />
            </span>
          </Tooltip>
        ) : (
          <Tooltip disableInteractive title={t('screens.ethernetOffline')}>
            <span>
              <NetworkOff />
            </span>
          </Tooltip>
        )}
      </div>
    </div>
  )
}

export default Connection
