import { handleActions } from 'redux-actions'
import { Media, MediaFolder } from '@seesignage/seesignage-utils'
import {
  listFiles,
  listFilesFail,
  listFilesSuccess,
  selectMedia,
  deselectMedia,
  deleteMediaSuccess,
  getFileUrlsSuccess,
  clearSelection,
  selectFolder,
  deselectFolder,
  searchMedia,
  changeItemsPerPage,
  changePage,
  clearSearchMedia,
  renameMediaSuccess,
  selectAllKeys,
  setUploadProgress,
  addMediaSuccess,
  clearUploadProgress,
  addMediaFail,
  deleteMedia,
  deleteMediaFilesSuccess,
  deleteMediaFail,
  resetMedia,
  createFolderSuccess,
  deleteFolderSuccess,
  updateFolderSuccess,
  moveMediaToFolderSuccess,
  moveFolderSuccess,
  deleteFolder,
  deleteFolderFail,
  setDropzoneFiles,
  clearDropzone,
  listFolders,
  listFoldersFail,
  listFoldersSuccess
} from '../actions/media'
import { MediaState, IndexById } from '../types/states'
import { Action } from '../types/actions'
import { KeysById, SetDropzoneFilesActionParams } from '../types/media'
import { calculateTotalStorageSize, getMediaByKeyAndUrlIfExists } from '../utils/media'

const initialState: MediaState = {
  keys: {}, // indexed by id
  folders: {},
  listFilesIsLoading: false,
  selectedMediaKeys: [],
  pagination: {
    itemsPerPage: 10,
    page: 0
  },
  selectedFolderId: undefined,
  searchTerm: undefined,
  videosTranscoding: [],
  uploadProgress: {},
  storageUsed: 0,
  dropzone: {}
}

const media = handleActions<MediaState, any>(
  {
    [resetMedia]: () => initialState,
    [selectMedia]: (state, { payload: selectedMediaKey }: Action<string>) => {
      const selectedKeys = [...state.selectedMediaKeys, selectedMediaKey]
      return {
        ...state,
        selectedMediaKeys: selectedKeys
      }
    },
    [deselectMedia]: (state, { payload: deselectedKey }: Action<string>) => {
      const keys = state.selectedMediaKeys
      const deductedKeys = keys.filter(key => key !== deselectedKey)
      return {
        ...state,
        selectedMediaKeys: deductedKeys
      }
    },
    [clearSelection]: state => ({
      ...state,
      selectedMediaKeys: []
    }),
    [listFiles]: state => ({
      ...state,
      listFilesIsLoading: true
    }),
    [listFilesFail]: state => ({
      ...state,
      listFilesIsLoading: false
    }),
    [listFilesSuccess]: (
      state,
      { payload: { keys, folders } }: Action<{ keys: KeysById; folders: IndexById<MediaFolder> }>
    ) => {
      const storageUsed = calculateTotalStorageSize(keys)
      return {
        ...state,
        listFilesIsLoading: false,
        keys: getMediaByKeyAndUrlIfExists(state.keys, keys),
        folders,
        storageUsed
      }
    },
    [listFolders]: state => ({
      ...state,
      listFilesIsLoading: true
    }),
    [listFoldersFail]: state => ({
      ...state,
      listFilesIsLoading: false
    }),
    [listFoldersSuccess]: (state, { payload: folders }: Action<IndexById<MediaFolder>>) => {
      return {
        ...state,
        listFilesIsLoading: false,
        folders
      }
    },
    [deleteMedia]: state => ({
      ...state,
      mediaIsDeleting: true
    }),
    [deleteMediaSuccess]: (state, { payload: key }: Action<string>) => {
      const keys = { ...state.keys }
      delete keys[key]
      return {
        ...state,
        keys
      }
    },
    [deleteMediaFilesSuccess]: state => {
      const mediaFiles = state.keys
      const storageUsed = calculateTotalStorageSize(mediaFiles)
      return {
        ...state,
        mediaIsDeleting: undefined,
        storageUsed
      }
    },
    [deleteMediaFail]: state => ({
      ...state,
      mediaIsDeleting: undefined
    }),
    [changePage]: (state, { payload: page }: Action<number>) => ({
      ...state,
      pagination: {
        ...state.pagination,
        page
      }
    }),
    [changeItemsPerPage]: (state, { payload: itemsPerPage }: Action<number>) => ({
      ...state,
      pagination: {
        ...state.pagination,
        itemsPerPage
      }
    }),
    [selectFolder]: (state, { payload: selectedFolderId }: Action<string>) => ({
      ...state,
      selectedFolderId,
      searchTerm: undefined
    }),
    [deselectFolder]: state => ({
      ...state,
      selectedFolderId: undefined
    }),
    [searchMedia]: (state, { payload: searchTerm }: Action<string>) => ({
      ...state,
      pagination: {
        ...state.pagination,
        page: 0
      },
      searchTerm
    }),
    [clearSearchMedia]: state => ({
      ...state,
      searchTerm: undefined,
      pagination: {
        ...state.pagination,
        page: 0
      }
    }),
    [getFileUrlsSuccess]: (state, { payload: keys }: Action<KeysById>) => ({
      ...state,
      keys: {
        ...state.keys,
        ...keys
      }
    }),
    [renameMediaSuccess]: (
      state,
      { payload: { key, newName } }: Action<{ key: string; newName: string }>
    ) => ({
      ...state,
      keys: {
        ...state.keys,
        [key]: {
          ...state.keys[key],
          name: newName
        }
      }
    }),
    [selectAllKeys]: (state, { payload: keys }: Action<Media[]>) => {
      const keyStrings = keys.map(({ key }) => key)
      return {
        ...state,
        selectedMediaKeys: [...state.selectedMediaKeys, ...keyStrings]
      }
    },
    [setUploadProgress]: (
      state,
      {
        payload: { key, progress, name, error, isTranscoding }
      }: Action<{
        key: string
        progress: number
        name: string
        error?: string
        isTranscoding?: boolean
      }>
    ) => {
      return {
        ...state,
        uploadProgress: {
          ...state.uploadProgress,
          [key]: {
            key,
            progress,
            name,
            error,
            isTranscoding
          }
        }
      }
    },
    [addMediaSuccess]: (state, { payload: mediaFile }: Action<Media>) => ({
      ...state,
      keys: {
        ...state.keys,
        [mediaFile.key]: mediaFile
      }
    }),
    [addMediaFail]: state => ({
      ...state,
      uploadProgress: {}
    }),
    [clearUploadProgress]: (state, { payload: key }: Action<string>) => {
      const progressKeys = { ...state.uploadProgress }
      delete progressKeys[key]
      return {
        ...state,
        uploadProgress: progressKeys
      }
    },
    [createFolderSuccess]: (state, { payload: newFolder }: Action<MediaFolder>) => ({
      ...state,
      folders: {
        ...state.folders,
        [newFolder.folderId]: newFolder
      }
    }),
    [deleteFolder]: state => ({
      ...state,
      mediaIsDeleting: true
    }),
    [deleteFolderSuccess]: (state, { payload: folderId }: Action<string>) => {
      const folders = { ...state.folders }
      delete folders[folderId]
      return {
        ...state,
        folders,
        mediaIsDeleting: false
      }
    },
    [deleteFolderFail]: state => ({
      ...state,
      mediaIsDeleting: false
    }),
    [updateFolderSuccess]: (state, { payload: updatedFolder }: Action<MediaFolder>) => ({
      ...state,
      folders: {
        ...state.folders,
        [updatedFolder.folderId]: updatedFolder
      }
    }),
    [moveMediaToFolderSuccess]: (
      state,
      { payload: { folderId, keys } }: Action<{ folderId: string | null; keys: string[] }>
    ) => {
      const storeKeys = { ...state.keys }
      for (const key of keys) {
        storeKeys[key].parentFolderId = folderId
      }
      return {
        ...state,
        keys: storeKeys
      }
    },
    [moveFolderSuccess]: (state, { payload: updatedFolder }: Action<MediaFolder>) => ({
      ...state,
      folders: {
        ...state.folders,
        [updatedFolder.folderId]: updatedFolder
      }
    }),
    [setDropzoneFiles]: (
      state,
      {
        payload: { formName, formFieldName, parentFolderId, files }
      }: Action<SetDropzoneFilesActionParams>
    ) => ({
      ...state,
      dropzone: {
        ...state.dropzone,
        [formName]: {
          ...state.dropzone[formName],
          [formFieldName]: {
            parentFolderId,
            files
          }
        }
      }
    }),
    [clearDropzone]: state => ({
      ...state,
      dropzone: {}
    })
  },
  initialState
)

export default media
