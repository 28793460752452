import { createSelector } from 'reselect'
import { PlayerProduct } from '@seesignage/seesignage-utils'
import { StateInterface } from '../types/states'
import { MockProductType } from '../types/mocks'

const selectMocksDomain = (state: StateInterface) => state.mocks
const selectMockItems = createSelector(selectMocksDomain, domain => domain.mockItems)
const selectMockItemsByProductType = (mockProductType: MockProductType) =>
  createSelector(selectMockItems, mockItems => mockItems[mockProductType] as PlayerProduct[])

const selectMockProductType = createSelector(selectMocksDomain, domain => domain.mockProductType)

const selectMockAdditionalProps = createSelector(
  selectMocksDomain,
  domain => domain.mockAdditionalProps
)

export {
  selectMockItems,
  selectMockProductType,
  selectMockItemsByProductType,
  selectMockAdditionalProps
}
