import React, { Fragment } from 'react'
import { WrappedFieldArrayProps, Field } from 'redux-form'
import { TFunction } from 'i18next'
import { Grid, IconButton, Button, MenuItem, Tooltip } from '@mui/material'
import DeleteIcon from 'mdi-react/DeleteIcon'
import nanoid from 'nanoid'
import { TableFieldType, TableField } from '@seesignage/seesignage-utils'
import { ReduxTextField } from '../../../../../../../components/FormInput/ReduxWrappers'
import { required } from '../../../../../../../validation'

interface TableFormFieldsProps {
  /** Name of field */
  item: string
  rowNumber: number
  t: TFunction
  remove: () => void
  isFieldsDisabled: boolean
}

const TableFormFields: React.FC<TableFormFieldsProps> = ({
  t,
  remove,
  item,
  rowNumber,
  isFieldsDisabled
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Field
          fullWidth
          name={`${item}.name`}
          label={t('contents.widgets.table.name', { rowNumber })}
          component={ReduxTextField}
          size='small'
          required
          validate={[required]}
          disabled={isFieldsDisabled}
        />
      </Grid>
      <Grid item xs={4}>
        <Field
          label={t('contents.widgets.table.fieldtype')}
          name={`${item}.type`}
          component={ReduxTextField}
          required
          validate={[required]}
          fullWidth
          select
          disabled={isFieldsDisabled}
          size='small'>
          {Object.values(TableFieldType).map(type => (
            <MenuItem key={type} value={type}>
              {t(`contents.widgets.table.fieldTypes.${type}`)}
            </MenuItem>
          ))}
        </Field>
      </Grid>
      {!isFieldsDisabled && (
        <Grid item xs={2}>
          <Tooltip disableInteractive title={t('contents.widgets.table.deleteSubField')}>
            <IconButton onClick={() => remove()} size='small' disabled={isFieldsDisabled}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  )
}

interface TableFieldsProps extends WrappedFieldArrayProps<TableField> {
  t: TFunction
  isFieldsDisabled: boolean
}

const TableFields: React.FC<TableFieldsProps> = ({ fields, t, isFieldsDisabled }) => {
  return (
    <Fragment>
      {fields.map((item, index) => {
        const props = {
          rowNumber: index + 1,
          remove: () => fields.remove(index),
          item,
          t,
          isFieldsDisabled
        }
        return <TableFormFields key={`${item}.${index}`} {...props} />
      })}
      {!isFieldsDisabled && (
        <Button
          style={{ margin: 8 }}
          variant='contained'
          onClick={() =>
            fields.push({
              id: nanoid(),
              type: TableFieldType.string,
              name: '',
              styles: {}
            })
          }>
          {t('contents.widgets.table.addSubField')}
        </Button>
      )}
    </Fragment>
  )
}

export default TableFields
