import React from 'react'
import { AppBar, Toolbar, Tooltip, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { Customer } from '@seesignage/seesignage-utils'
import InfoIcon from 'mdi-react/InfoCircleIcon'
import { CloseDialog, ForceRunTour } from '../../types/actions'
import SearchField from '../../components/SearchField'
import Dialog from '../Dialog'
import { OpenDialogButtonType } from '../../types/dialogs'
import { toolbarStyles } from '../../styles/common'
import { Tour } from '../../types/tours'
import { EnvironmentType } from '../../types/environments'
import CreateCampaignForm from './Forms/CreateCampaignForm'

const useStyles = makeStyles(toolbarStyles)

interface CampaignsToolbarProps {
  search: React.Dispatch<React.SetStateAction<string>>
  closeDialog: CloseDialog
  createCampaign: (formData: any) => void
  campaignCustomer?: Customer
  forceRunTour: ForceRunTour
  environmentType: EnvironmentType
}

const CampaignsToolbar = ({
  search,
  createCampaign,
  closeDialog,
  campaignCustomer,
  forceRunTour,
  environmentType
}: CampaignsToolbarProps) => {
  const classes = useStyles()
  const [t] = useTranslation()
  return (
    <AppBar className={classes.appBar} position='sticky' color='default'>
      <Toolbar>
        <SearchField placeholder={t('general.search')} onChange={e => search(e.target.value)} />
        <div className={classes.spacer} />
        <div className={classes.actions}>
          <Tooltip disableInteractive title={t('tours.runTour')}>
            <IconButton
              onClick={() =>
                forceRunTour(
                  environmentType === EnvironmentType.hyper
                    ? Tour.createCampaign
                    : Tour.subCampaignView
                )
              }
              size='large'>
              <InfoIcon />
            </IconButton>
          </Tooltip>
          {environmentType === EnvironmentType.hyper && campaignCustomer && (
            <Dialog
              dialogId='CreateCampaignDialog'
              title={t('campaigns.createNew')}
              openDialogButtonType={OpenDialogButtonType.add}
              tooltipTitle={t('campaigns.createNew')}
              buttonId='create-campaign-button'
              Content={
                <CreateCampaignForm
                  dialogId='CreateCampaignDialog'
                  submitAction={createCampaign}
                  closeDialog={closeDialog}
                  submitButtonLabel={t('general.create')}
                  initialValues={{
                    customerId: campaignCustomer.customerId
                  }}
                />
              }
            />
          )}
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default CampaignsToolbar
