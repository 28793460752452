import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import SimpleExtensionPanel from '../../../../../../components/ExtensionPanels/SimpleExtensionPanel'
import ShadowControl from '../../../../../../components/FormInput/ReduxWrappers/Contents/ShadowControl'

const useStyles = makeStyles(() => ({
  accordionSummaryRootClass: {
    backgroundColor: '#F5F5F5',
    borderBottom: '1px solid rgba(0, 0, 0, 0.09)'
  },
  input: {
    maxWidth: '50px'
  },
  enableShadow: {
    paddingTop: '10px'
  },
  enableShadowRow: {
    display: 'flex'
  },
  shadowColor: {
    paddingTop: '5px'
  },
  labelAndFieldRow: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}))

const CommonBoxSettingsForm: React.FC = () => {
  const classes = useStyles()
  const [t] = useTranslation()
  return (
    <Grid container>
      <Grid item xs={12}>
        <SimpleExtensionPanel
          title={t('contents.properties.shadow')}
          isOpen={true}
          accordionSummaryRootClass={classes.accordionSummaryRootClass}>
          <Field name='shadow' component={ShadowControl} />
        </SimpleExtensionPanel>
      </Grid>
    </Grid>
  )
}

export default reduxForm({
  form: 'CommonBoxSettingsForm'
})(CommonBoxSettingsForm)
