import { Grid } from '@mui/material'
import React, { Fragment } from 'react'
import { Field, FieldArray, WrappedFieldArrayProps } from 'redux-form'
import { formatDecimalsToCurrency, parseCurrencyStringToNumber } from '@seesignage/seesignage-utils'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { gridItemProps } from '../Fields/utils'
import { ReduxTextField } from '../../../../components/FormInput/ReduxWrappers'
import { MockProductType } from '../../../../types/mocks'
import { required } from '../../../../validation'
import FieldsExpansionPanel from '../../../../components/ExtensionPanels/FieldsExpansionPanel'
import AdditionalPropsFields from './AdditionalPropsFields'

const productType = MockProductType.pharmacy
const stringProperties = [
  'name',
  'ean',
  'vnr',
  'packageSize',
  'packageUnit',
  'packageMultiplier',
  'description',
  'comparisonString',
  'normalComparisonString',
  'imagePath'
]

interface RenderItemsProps extends WrappedFieldArrayProps {
  t: TFunction
}

const renderItems = ({ t, fields, meta: { error, submitFailed } }: RenderItemsProps) => (
  <Fragment>
    {submitFailed && error && <span>{error}</span>}
    {fields.map((member: string, index: number) => (
      <FieldsExpansionPanel customLabel={`Product ${index + 1}`} key={`${index}-${member}`}>
        <Grid container key={`${index}-${member}`} spacing={2}>
          {stringProperties.map((name, i) => (
            <Grid key={`${i}-${name}`} {...gridItemProps}>
              <Field fullWidth name={`${member}.${name}`} label={name} component={ReduxTextField} />
            </Grid>
          ))}
          <Grid {...gridItemProps}>
            <Field
              name={`${member}.discountPrice`}
              format={formatDecimalsToCurrency}
              parse={parseCurrencyStringToNumber}
              type='number'
              component={ReduxTextField}
              label={t('products.pharmacy.discountPrice')}
              InputProps={{
                endAdornment: '€'
              }}
              helperText={t('general.optional')}
            />
          </Grid>
          <Grid {...gridItemProps}>
            <Field
              name={`${member}.price`}
              format={formatDecimalsToCurrency}
              parse={parseCurrencyStringToNumber}
              type='number'
              component={ReduxTextField}
              required
              validate={[required]}
              label={t('products.pharmacy.price')}
              InputProps={{
                endAdornment: '€'
              }}
            />
          </Grid>
        </Grid>
      </FieldsExpansionPanel>
    ))}
  </Fragment>
)

const DrugFormFields = () => {
  const [t] = useTranslation()
  return (
    <Grid container spacing={2} alignItems='center'>
      <AdditionalPropsFields />
      <FieldArray name={productType} component={renderItems} props={{ t }} />
    </Grid>
  )
}

export default DrugFormFields
