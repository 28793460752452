import React from 'react'
import { Link, Typography, Breadcrumbs as MuiBreadcrumbs } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useSelector, useDispatch } from 'react-redux'
import NavigateNextIcon from 'mdi-react/NavigateNextIcon'
import { useTranslation } from 'react-i18next'
import MediaIcon from 'mdi-react/FolderMultipleImageIcon'
import { getFoldersPathArray } from '../../../utils/media'
import { selectMediaFolders } from '../../../selectors/media'
import { selectFolder, deselectFolder } from '../../../actions/media'

const useStyles = makeStyles(theme => ({
  link: {
    display: 'flex',
    cursor: 'pointer' as any
  },
  breadcumbs: {
    padding: 8
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20
  }
}))

interface FoldersBreadcrumbsProps {
  selectedFolderId?: string
}

const FoldersBreadcrumbs: React.FC<FoldersBreadcrumbsProps> = ({ selectedFolderId }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const mediaFolders = useSelector(selectMediaFolders)
  const foldersPathArray = getFoldersPathArray(mediaFolders, selectedFolderId)
  return (
    <MuiBreadcrumbs
      maxItems={4}
      separator={<NavigateNextIcon fontSize='small' />}
      className={classes.breadcumbs}>
      {foldersPathArray.length > 0 && (
        <Link onClick={() => dispatch(deselectFolder())} className={classes.link}>
          <MediaIcon className={classes.icon} />
          {t('navigation.media')}
        </Link>
      )}
      {foldersPathArray.map((parent, i) => {
        if (i === foldersPathArray.length - 1) {
          return <Typography key={parent.id}>{parent.name}</Typography>
        }
        return (
          <Link
            key={parent.id}
            onClick={() => dispatch(selectFolder(parent.id))}
            className={classes.link}>
            {parent.name}
          </Link>
        )
      })}
    </MuiBreadcrumbs>
  )
}

export default FoldersBreadcrumbs
