import { Paper, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import dateFormat from 'dateformat'
import NetworkOn from 'mdi-react/NetworkIcon'
import NetworkOff from 'mdi-react/NetworkOffIcon'
import SignalOff from 'mdi-react/SignalOffIcon'
import React from 'react'
import { useTranslation } from 'react-i18next'
import SignalStrength from '../../components/Network/Connection/SignalStrength'

const useStyles = makeStyles(() => ({
  tooltip: {
    padding: 10,
    marginTop: 20
  }
}))
interface StatusTooltipProps {
  active: boolean
  payload: any
}

const StatusTooltip: React.FC<StatusTooltipProps> = ({ active, payload }) => {
  const [t] = useTranslation()
  const classes = useStyles()
  if (active) {
    const { date, isEthernetConnected, signalStrength, ssid } = payload[0].payload
    return (
      <Paper className={classes.tooltip}>
        <Typography variant='body2' gutterBottom>
          {t('connection.status')}
        </Typography>
        <Typography variant='body1' gutterBottom>
          {t('general.date')}: {dateFormat(date, 'HH:MM dd-mm')}
        </Typography>
        {signalStrength ? <SignalStrength signalStrength={signalStrength} /> : <SignalOff />}
        {isEthernetConnected ? <NetworkOn /> : <NetworkOff />}
        <Typography variant='body1' gutterBottom>
          SSID: {ssid ? `${ssid}` : 'unknown'}
        </Typography>
        <Typography variant='body1' gutterBottom>
          {t('wifi.sigStrength')}: {signalStrength ? `${signalStrength}` : 'unknown'}
        </Typography>
      </Paper>
    )
  }
  return null
}

export default StatusTooltip as any
